import * as R from 'ramda';
import React, { useState } from 'react';
import arrayMutators from 'final-form-arrays';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  formatTotal,
  removePropDeep,
} from 'poly-utils';
import { FormCreator, Text } from 'poly-book-admin';
import {
  CommonSidebarFormWrapper,
  useNotificationState,
  SidebarRow,
  MoneyInput,
  thirdWidth,
} from 'poly-admin-ui';

import {
  ColumnList,
  TabSectionWrapper,
  EditClientTabButton,
} from './commonComponents.js';
import { editClientTMRulesFormId } from '../constants.js';
import { commonModalLayout } from '../../../modules/forms/common.js';
import { ClientMarkupLineComponent } from './ClientMarkupRuleComponent.js';
import { BlockWithLabel } from '../../components/commonSidebarComponents.js';
import { PropertyFieldInheritanceMap } from '../../PropertySidebar/constants.js';

// getInvoiceAmount :: [ClientMarkupRule] -> [String]
// ClientMarkupRule = { upTo: Number, percent: Number }
// eslint-disable-next-line import/no-unused-modules
export const getInvoiceAmount = R.compose(
  (rules) =>
    rules.map((rule, index) =>
      R.converge(R.concat, [
        R.compose(
          formatTotal,
          R.ifElse(
            () => index === 0,
            R.always(0),
            R.pipe(() => rules[index - 1], R.prop('upTo'), R.add(0.01)),
          ),
        ),
        R.ifElse(
          R.propSatisfies(R.isNil, 'upTo'),
          R.always(' and up'),
          R.pipe(R.prop('upTo'), formatTotal, R.concat(' - ')),
        ),
      ])(rule),
    ),
  R.defaultTo([]),
);

// getMarkup :: [ClientMarkupRule] -> [String]
const getMarkup = R.compose(
  R.map(R.compose(R.concat(R.__, '%'), R.toString, R.prop('percent'))),
  R.defaultTo([]),
);

// prepareClientTMRulesInitialValues :: Client -> { tmMarkupRules: [ClientMarkupRule] }
const prepareClientTMRulesInitialValues = R.compose(
  R.applySpec({
    woCharge: R.compose(convertCentsToDollars, R.propOr(0, 'woCharge')),
    tmMarkupRules: R.compose(
      R.when(R.isEmpty, R.append({})),
      R.defaultTo([]),
      R.prop('tmMarkupRules'),
    ),
  }),
  removePropDeep('__typename'),
);

function WorkOrderChargeComponent({ woCharge }) {
  return (
    <Text size="12px" lineHeight="18px">
      {formatTotal(convertCentsToDollars(woCharge))}
    </Text>
  );
}

WorkOrderChargeComponent.defaultProps = { woCharge: 0 };
WorkOrderChargeComponent.propTypes = { woCharge: number };

function WarningText({ children }) {
  return (
    <Text size="12px" color={['notificator', 'warning', 'text']}>
      *{children}
    </Text>
  );
}

WarningText.propTypes = {
  children: oneOfType([string, node]),
};

function InheritedFormWarning({ warningPrefix, inheritedFrom }) {
  return (
    <WarningText>
      {warningPrefix} Inherited from {inheritedFrom}
    </WarningText>
  );
}

InheritedFormWarning.propTypes = {
  warningPrefix: string,
  inheritedFrom: string,
};

export function TMRulesTab({
  woCharge,
  tmMarkupRules,
  hasAccess,
  onSubmit,
  successMsgPrefix,
  woChargeInheritedFrom,
  markupRulesInheritedFrom,
  isChildProperty,
}) {
  const { showSuccessNotification } = useNotificationState();
  const [showEditForm, setShowEditForm] = useState(false);

  const onCancel = () => setShowEditForm(false);

  const onSubmitHandler = async (formData) => {
    await onSubmit(formData);
    showSuccessNotification(`${successMsgPrefix} Markup successfully updated`);
    onCancel();
  };

  const skipWOChargeWarning =
    woChargeInheritedFrom === PropertyFieldInheritanceMap.SELF;
  const skipMarkupRulesWarning =
    markupRulesInheritedFrom === PropertyFieldInheritanceMap.SELF;

  return (
    <TabSectionWrapper>
      {!skipWOChargeWarning && (
        <SidebarRow skipMargin justify={hasAccess}>
          <InheritedFormWarning
            warningPrefix="Work Order Charge"
            inheritedFrom={woChargeInheritedFrom}
          />
        </SidebarRow>
      )}
      {!skipMarkupRulesWarning && (
        <SidebarRow justify={hasAccess}>
          <InheritedFormWarning
            warningPrefix="T&M Rules"
            inheritedFrom={markupRulesInheritedFrom}
          />
        </SidebarRow>
      )}
      {isChildProperty && (
        <SidebarRow justify={hasAccess}>
          <WarningText>
            When used in master projects the Work Order Charge and Markup will
            be taken from the Master Property
          </WarningText>
        </SidebarRow>
      )}

      <SidebarRow justify={hasAccess}>
        <BlockWithLabel
          id="work-order-charge"
          label="Work Order Charge"
          woCharge={woCharge}
          {...(!hasAccess && { width: '50%' })}
          Component={WorkOrderChargeComponent}
        />
        {hasAccess && (
          <EditClientTabButton onClick={() => setShowEditForm(true)}>
            edit
          </EditClientTabButton>
        )}
      </SidebarRow>
      <SidebarRow justify={hasAccess}>
        <BlockWithLabel
          id="invoice-amount"
          label="Invoice Amount"
          list={getInvoiceAmount(tmMarkupRules)}
          {...(!hasAccess && { width: '50%' })}
          Component={ColumnList}
        />
        <BlockWithLabel
          id="markup"
          label="Markup"
          list={getMarkup(tmMarkupRules)}
          Component={ColumnList}
        />
      </SidebarRow>
      {showEditForm && (
        <CommonSidebarFormWrapper
          title="Edit T&M Markup Rules"
          formId={editClientTMRulesFormId}
          onCancel={onCancel}
        >
          <FormCreator
            validateOnBlur
            onSubmit={onSubmitHandler}
            initialValuesEqual={R.T}
            layout={commonModalLayout}
            id={editClientTMRulesFormId}
            mutators={{ ...arrayMutators }}
            initialValues={prepareClientTMRulesInitialValues({
              woCharge,
              tmMarkupRules,
            })}
            sections={[
              {
                order: 1,
                layout: { column: 1 },
                fields: [
                  {
                    order: 1,
                    layout: { row: 1 },
                    field: {
                      name: 'emptyComponent',
                      Component: () => null,
                    },
                  },
                  {
                    label: 'Work Order Charge',
                    order: 2,
                    layout: {
                      row: 1,
                      width: thirdWidth,
                      padding: '0 20px 10px 0',
                    },
                    field: {
                      name: 'woCharge',
                      Component: MoneyInput,
                    },
                    validators: [
                      [
                        R.compose(R.gte(R.__, 0), R.defaultTo(0)),
                        'Work Order Charge can not be negative.',
                      ],
                      [
                        R.compose(
                          R.lte(R.__, 999999999),
                          convertDollarsToCents,
                          R.defaultTo(0),
                        ),
                        'Work Order Charge should be less then $10,000,000.00',
                      ],
                    ],
                  },
                  {
                    order: 3,
                    layout: { row: 2 },
                    field: {
                      isArrayField: true,
                      name: 'tmMarkupRules',
                      Component: ClientMarkupLineComponent,
                    },
                  },
                ],
              },
            ]}
          />
        </CommonSidebarFormWrapper>
      )}
    </TabSectionWrapper>
  );
}

TMRulesTab.propTypes = {
  tmMarkupRules: arrayOf(
    shape({
      upTo: number,
      percent: number,
    }),
  ),
  woCharge: number,
  hasAccess: bool.isRequired,
  onSubmit: func.isRequired,
  successMsgPrefix: string.isRequired,
  woChargeInheritedFrom: string,
  markupRulesInheritedFrom: string,
  isChildProperty: bool,
};
