import * as R from 'ramda';
import React from 'react';
import {
  MailTo,
  mailToValidator,
  UserSelect,
  validateMailToField,
  DnDAreaWithMentions,
} from 'poly-admin-ui';
import {
  dNdTextAreaFileValidators,
  equalsOmitFields,
  validateFilesFunc,
  useDecoratePersistenceForOnChange,
} from 'poly-client-utils';
import { func } from 'prop-types';

import { halfWidth } from '../common.js';
import { NextTaskDueDateField } from '../fields/NextTaskDueDateField.js';

function DnDAreaWithPersist({ onChangePersistentValue, onChange, ...props }) {
  const onChangePersistent = useDecoratePersistenceForOnChange(
    onChangePersistentValue,
    onChange,
    R.equals({ text: '', attachments: [], mentions: {} }),
  );

  return (
    <DnDAreaWithMentions autoFocus {...props} onChange={onChangePersistent} />
  );
}

DnDAreaWithPersist.propTypes = {
  onChangePersistentValue: func.isRequired,
  onChange: func.isRequired,
};

export const projectUpdateSections = (projectId, onChangePersistentValue) => [
  {
    id: 'main',
    layout: { column: 1, margin: '15px 0 0 0' },
    order: 1,
    fields: [
      {
        order: 2,
        layout: { row: 2 },
        field: {
          name: 'details',
          Component: (props) => (
            <DnDAreaWithPersist
              {...props}
              onChangePersistentValue={onChangePersistentValue}
            />
          ),
          isEqual: equalsOmitFields([['mentions']]),
        },
        validators: dNdTextAreaFileValidators,
        validateFunction: validateFilesFunc,
      },
      {
        order: 4,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'nextTask',
          Component: (props) => (
            <NextTaskDueDateField {...props} position="top" />
          ),
        },
        renderIf: R.both(
          R.path(['nextTask']),
          R.complement(R.path(['nextTask', 'complete'])),
        ),
      },
      {
        label: 'Assigned to',
        order: 5,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'nextTask.managerId',
          Component: UserSelect,
        },
        validators: [[R.identity, 'Manager is required']],
        renderIf: R.both(
          R.path(['nextTask']),
          R.complement(R.path(['nextTask', 'complete'])),
        ),
      },
      {
        order: 7,
        field: {
          name: 'mailTo',
          Component: (props) => <MailTo {...props} projectDbId={projectId} />,
        },
        validators: mailToValidator,
        validateFunction: validateMailToField,
        renderIf: R.complement(R.propEq('isHQProject', true)),
      },
    ],
  },
];
