import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string, arrayOf, bool } from 'prop-types';
import { pathOrNothingUI, useOutSidebarContext } from 'poly-client-utils';
import { useUpdateQueryParams } from 'poly-client-routing';
import { LinkButton } from 'poly-book-admin';
import { SidebarRow } from 'poly-admin-ui';

import {
  SectionLabel,
  SectionWrapper,
  BlockWithLabel,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import { clientTabsSidebarId } from './constants.js';
import { clientSidebarTabs } from '../../routes/constants.js';
import { useOpenClientTabsSidebar } from './tabs/useOpenClientTabsSidebar.js';
import { showClientPortalEnabledInfo } from './tabs/clientSidebarTabsUtils.js';
import { useSidebarLogicContext } from '../SidebarLogicContext.js';
import {
  getSectionClientManagers,
  getSectionOutsideLink,
  getSectionSidebarLink,
  getSectionEmail,
  getSectionText,
  getSectionPhoneLink,
} from '../components/commonSidebarSectionFormatters.js';

const ClientPortalUsersLink = styled(LinkButton)`
  text-align: left;
`;

// getContractProp :: String -> Client -> String
const getContractProp = (prop) =>
  R.compose(R.prop(prop), R.defaultTo({}), R.prop('contractFile'));

// getClientPortalUsersLink :: Client -> _ -> ReactElement
const getClientPortalUsersLink = (client) =>
  function (props) {
    const updateQueryParams = useUpdateQueryParams();
    const { isSidebarOpened } = useOutSidebarContext();
    const openClientTabsSidebar = useOpenClientTabsSidebar();

    const onClick = () => {
      updateQueryParams({ sidebarTab: clientSidebarTabs.clientPortalUsers });

      if (!isSidebarOpened(clientTabsSidebarId)) {
        openClientTabsSidebar(client);
      }
    };

    return (
      <ClientPortalUsersLink {...props} onClick={onClick}>
        View
      </ClientPortalUsersLink>
    );
  };

export function ClientSidebarGeneralInfo({ client }) {
  const { website, clientManagers, parentClient } = client;

  const enabledClientPortal = showClientPortalEnabledInfo(client);

  const { ClientLink } = useSidebarLogicContext();

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>General information</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          id="phone"
          label="Phone"
          {...threeBlocksProps}
          Component={getSectionPhoneLink(client.phone)}
        />
        <BlockWithLabel
          id="fax"
          label="Fax"
          {...threeBlocksProps}
          Component={getSectionPhoneLink(client.fax)}
        />
        <BlockWithLabel
          id="nickname"
          label="Nick Name"
          {...threeBlocksProps}
          Component={getSectionText(pathOrNothingUI(['nickName'])(client))}
        />
        <BlockWithLabel
          id="website"
          label="Website"
          {...threeBlocksProps}
          Component={getSectionOutsideLink(website)}
        />
        <BlockWithLabel
          {...threeBlocksProps}
          id="client-managers"
          label="Client Managers"
          Component={getSectionClientManagers(clientManagers)}
        />
        <BlockWithLabel
          {...threeBlocksProps}
          id="client-contract"
          label="Client Contract"
          Component={getSectionOutsideLink(
            getContractProp('url')(client),
            getContractProp('fileName')(client),
          )}
        />
        {!!parentClient && (
          <BlockWithLabel
            id="parent"
            label="Parent Client"
            {...threeBlocksProps}
            Component={getSectionSidebarLink(parentClient, ClientLink)}
          />
        )}
        <BlockWithLabel
          id="manager"
          label="Assigned CSR"
          {...threeBlocksProps}
          Component={getSectionText(
            pathOrNothingUI(['manager', 'profile', 'fullName'])(client),
          )}
        />
        <BlockWithLabel
          id="group-email"
          {...threeBlocksProps}
          label="Client Group Email"
          Component={getSectionEmail(R.prop('clientGroupEmail')(client))}
        />
        {enabledClientPortal && (
          <BlockWithLabel
            id="portal-user-management"
            label="Portal User Management"
            {...threeBlocksProps}
            Component={getClientPortalUsersLink(client)}
          />
        )}
      </SidebarRow>
    </SectionWrapper>
  );
}

ClientSidebarGeneralInfo.propTypes = {
  client: shape({
    website: string,
    parentClient: shape({
      _id: string.isRequired,
      name: string.isRequired,
    }),
    enableGlCodes: bool,
    clientManagers: arrayOf(
      shape({
        _id: string.isRequired,
        fullName: string.isRequired,
      }),
    ),
  }),
};
