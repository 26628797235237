import { useEffect, useMemo, useRef } from 'react';
import { useInfiniteScrollQueryWithSubscription } from 'poly-client-utils';
import { DESC_SORT_ORDER, ELASTIC_SCORE_FIELD } from 'poly-constants';

import { getUserAlertsQueryByStatus } from '../../../modules/core/hocs/userAlerts/index.js';
import { USER_ALERTS_SEARCH_AND_ME_QUERY } from '../../../modules/core/hocs/userAlerts/queries.js';
import { USER_ALERTS_SEARCH_CHANGED } from '../../../modules/core/hocs/userAlerts/subscriptions.js';

export const useAlertsInfiniteScrollQuery = (status, searchText) => {
  const ref = useRef(status);
  const searchInput = useMemo(
    () => ({
      sort: [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
      query: getUserAlertsQueryByStatus(status),
      searchTerm: searchText,
    }),
    [status, searchText],
  );

  const { data, refetch, loading, tableProps } =
    useInfiniteScrollQueryWithSubscription(
      USER_ALERTS_SEARCH_AND_ME_QUERY,
      searchInput,
      {
        endpointName: 'searchUserAlerts',
        pageSize: 25,
        inputName: 'searchInput',
      },
      USER_ALERTS_SEARCH_CHANGED,
      { searchInput },
    );

  useEffect(() => {
    // we need to refetch every time tab changes
    refetch();
    ref.current = status;
  }, [status]);

  return {
    data,
    loading,
    tableProps,
    refetch: () => {
      refetch({
        searchInput: {
          sort: [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
          query: getUserAlertsQueryByStatus(ref.current),
          searchTerm: searchText,
          size: 25,
        },
      });
    },
  };
};
