import React from 'react';
import { LinkButton } from 'poly-book-admin';
import { openPrintWindowWithData } from 'poly-client-utils';
import { CommonPrintLayout } from 'poly-admin-ui';
import { AccountingMethods, PRINT_PDF_CAPTION } from 'poly-constants';

import styled from 'styled-components';

import { TrialBalanceTable } from './TrialBalanceTable.js';
import { FlexColumn } from '../../components/FlexContainer.js';
import { formatTrialBalanceDateHeader } from './trialBalanceHelpers.js';
import {
  trialBalanceFiltersPropTypes,
  trialBalanceReportPropTypes,
  dateRangePropType,
} from './trialBalancePropTypes.js';

const SubTitleS = styled.p`
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: -15px;
`;

function SubTitle({ dateRange }) {
  return <SubTitleS>{formatTrialBalanceDateHeader(dateRange)}</SubTitleS>;
}

SubTitle.propTypes = {
  dateRange: dateRangePropType.isRequired,
};

function TrialBalancePrintTable({ trialBalanceReport, filters }) {
  return (
    <FlexColumn>
      <SubTitle dateRange={filters.date} />
      <TrialBalanceTable
        trialBalanceReport={trialBalanceReport}
        filters={filters}
      />
    </FlexColumn>
  );
}

TrialBalancePrintTable.propTypes = {
  trialBalanceReport: trialBalanceReportPropTypes,
  filters: trialBalanceFiltersPropTypes.isRequired,
};

export function TrialBalancePrintBtn({ trialBalanceReport, filters }) {
  const { accountingMethod } = filters;

  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: TrialBalancePrintTable,
      fileName: 'trial_balance',
      metaData: {
        title: `Trial Balance (${
          accountingMethod === AccountingMethods.CASH_BASIS ? 'Cash' : 'Accrual'
        } Basis)`,
      },
      trialBalanceReport,
      filters,
    });
  return (
    <LinkButton disabled={!trialBalanceReport} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

TrialBalancePrintBtn.propTypes = {
  trialBalanceReport: trialBalanceReportPropTypes,
  filters: trialBalanceFiltersPropTypes,
};
