import styled, { css } from 'styled-components';
import { getThemeColor } from 'poly-book-admin';
import React from 'react';

const blockedBackgroundStyles = css`
  :after {
    display: flex;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500%;
    cursor: not-allowed;
  }
`;

// transitionByOffset :: Number -> String
const transitionByOffset = (offset, blurred) =>
  offset === 0 || blurred === 'true' ? '0.3s' : '0.6s';

const transformXByOffset = (offset) => (offset === 0 ? '100%' : '200%');

export const BaseSidebarLayout = styled(
  ({ isBlurredSidebar, isFullWidth, isHidden, ...rest }) => <div {...rest} />,
)`
  height: calc(100vh - 60px);
  padding: 10px 0 0 0;
  position: fixed;
  top: 60px;
  background-color: ${getThemeColor(['white'])};
  box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.15);
  transition: all
      ${({ offset, blurred }) => transitionByOffset(offset, blurred)},
    right 0.3s;
  transform: translateX(
    ${({ open, offset }) => (!open ? transformXByOffset(offset) : 0)}
  );
  z-index: ${({ isBlurredSidebar }) => (isBlurredSidebar ? '9' : '999')};
  overflow-y: ${({ blurred }) => (blurred === 'true' ? 'hidden' : 'auto')};
  width: ${({ width }) => (width ? `${width}px` : 'fit-content')};
  ${({ position, offset }) => `${position}: ${offset}px;`};
  ${({ blurred }) => blurred === 'true' && blockedBackgroundStyles};
  ${({ isHidden }) => isHidden && 'display: none'};
`;
