/* eslint-disable camelcase */
import * as R from 'ramda';
import { renameProp } from 'poly-utils';

const initialAddressParts = {
  street_number: '',
  postal_code: '',
};

const countriesConfig = process.env.GOOGLE_SEARCH_COUNTRY
  ? process.env.GOOGLE_SEARCH_COUNTRY.split(' ')
  : [];

// findAddressPart :: String -> [AddressPart] -> AddressPart
const findAddressPart = (partType) =>
  R.compose(
    R.unless(R.isNil, R.compose(R.objOf(partType), R.prop('short_name'))),
    R.find(R.propSatisfies(R.includes(partType), 'types')),
  );

const supportedAddressParts = [
  'street_number',
  'route',
  'locality',
  // eslint-disable-next-line @cspell/spellchecker
  'sublocality',
  'administrative_area_level_1',
  'postal_code',
  'postal_code_suffix',
  'country',
];

// getAddressPartsWeSupport :: [AddressPart] -> AddressPartsInput
//   AddressPart = {
//     short_name: String
//     types: [String]
//   }
// eslint-disable-next-line import/no-unused-modules
export const getAddressPartsWeSupport = R.compose(
  // eslint-disable-next-line @cspell/spellchecker
  R.omit(['sublocality']),
  R.when(
    R.propSatisfies(R.isNil, 'locality'),
    // eslint-disable-next-line @cspell/spellchecker
    renameProp('sublocality', 'locality'),
  ),
  R.mergeAll,
  R.reject(R.isNil),
  R.juxt(supportedAddressParts.map(findAddressPart)),
);

export const initAutocompleteField = (inputRef, cb) => {
  let timeout;
  if (window?.google?.maps) {
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef, {
      types: ['address'],
      componentRestrictions: {
        country: countriesConfig,
      },
    });
    cb(autocomplete);
  } else {
    timeout = setTimeout(() => initAutocompleteField(inputRef, cb), 1000);
  }

  return () => clearTimeout(timeout);
};

// eslint-disable-next-line import/no-unused-modules
export const getPlacePartsWeSupport = (place) => {
  if (!place.geometry) {
    return {};
  }

  const {
    vicinity,
    address_components,
    formatted_address,
    utc_offset_minutes,
    geometry: { location },
  } = place;

  return {
    utc_offset: utc_offset_minutes,
    vicinity,
    formatted_address,
    geo: {
      type: 'Point',
      coordinates: [location.lng(), location.lat()],
    },
    address_parts: {
      ...initialAddressParts,
      ...getAddressPartsWeSupport(address_components),
    },
  };
};

export const addAutocompleteListeners = (
  autocomplete,
  { onChange, onBlur },
) => {
  autocomplete.addListener('place_changed', () => {
    const placeParts = getPlacePartsWeSupport(autocomplete.getPlace());
    onChange(placeParts);
    onBlur();
  });
};
