import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { getThemeColor, getThemeFont } from 'poly-book-admin';
import { string } from 'prop-types';
import { FormPageHeader } from '../../../components/FomPageHeader.js';
import { FlexColumn } from '../../globalSearch/globalSearchResult/searchResultItems/common/wrappers.js';
import { useMasterSupplierInfoQuery } from './hooks/useMasterSupplierInfoQuery.js';

const BranchSupplierSubHeader = styled.div`
  display: flex;
  font-size: 17px;
  line-height: 20px;
  color: ${getThemeColor(['primary'])};
  font-weight: ${getThemeFont(['medium'])};
`;

function AddBranchSupplierHeaderTitle({ masterSupplierName }) {
  return (
    <FlexColumn>
      Create New Branch Supplier
      <BranchSupplierSubHeader>{masterSupplierName}</BranchSupplierSubHeader>
    </FlexColumn>
  );
}

AddBranchSupplierHeaderTitle.propTypes = {
  masterSupplierName: string,
};

export function AddBranchSupplierPageHeader({
  formId,
  masterSupplierId,
  redirectUrl,
}) {
  const { data } = useMasterSupplierInfoQuery(masterSupplierId);

  const masterSupplierName = R.path(
    ['masterSupplier', 'company', 'name'],
    data,
  );

  return (
    <FormPageHeader
      formId={formId}
      redirectUrl={redirectUrl}
      title={
        <AddBranchSupplierHeaderTitle masterSupplierName={masterSupplierName} />
      }
    />
  );
}

AddBranchSupplierPageHeader.propTypes = {
  formId: string.isRequired,
  masterSupplierId: string.isRequired,
  redirectUrl: string,
};
