import * as R from 'ramda';
import { isManualTotalTimesheet } from 'poly-constants';

// pickCommonTimesheetProps :: Object -> Object
export const pickCommonTimesheetProps = R.converge(R.mergeRight, [
  R.pick([
    '_id',
    'date',
    'type',
    'notes',
    'projectId',
    'ownerId',
    'withoutUser',
  ]),
  R.ifElse(
    isManualTotalTimesheet,
    R.compose(R.pick(['totalTimeInMinutes']), R.omit(['isManual'])),
    R.compose(
      R.over(R.lensProp('entries'), R.defaultTo([])),
      R.pick(['entries']),
    ),
  ),
]);

// propIsDate :: Object -> Boolean
export const propIsDate = R.propIs(Date);

// isValidTimesheetEntry :: TimesheetEntry -> Boolean
export const isValidTimesheetEntry = R.both(
  propIsDate('timeIn'),
  propIsDate('timeOut'),
);

// constructNewDate :: String -> Date
const constructNewDate = R.constructN(1, Date);

// constructNewDateForProp :: String -> Object -> Object
export const constructNewDateForProp = (prop) =>
  R.over(R.lensProp(prop), R.when(R.is(String), constructNewDate));

// constructDatePropsForTimeEntry :: TimesheetEntry -> TimesheetEntry
export const constructDatePropsForTimeEntry = R.compose(
  constructNewDateForProp('timeIn'),
  constructNewDateForProp('timeOut'),
);

// checkTimeRecordIsManual :: TimeRecord -> Boolean
export const checkTimeRecordIsManual = R.pipe(
  R.prop('entries'),
  R.anyPass([R.isNil, R.isEmpty]),
);
