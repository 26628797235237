import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { entityToOptionByLabelPath } from 'poly-client-utils';
import { getThemeColor, Select } from 'poly-book-admin';
import { commonPeopleFields } from 'poly-admin-ui';

const CLIENT_PEOPLE = gql`
  query CLIENT_PEOPLE($entityId: ID!, $searchInput: CollectionSearchParams!) {
    client(id: $entityId) {
      _id
      searchContacts(input: $searchInput) {
        hits {
          ...commonPeopleFields
        }
        total
      }
    }
  }

  ${commonPeopleFields}
`;

const FieldWarningText = styled.div`
  margin-top: 5px;
  color: ${getThemeColor(['notificator', 'warning', 'text'])};
  font-size: 14px;
`;

const getClientManagersQuery = (clientId) => ({
  bool: {
    must: [
      { term: { isClientManager: true } },
      {
        nested: {
          path: 'links',
          query: {
            match: {
              'links.clientId': clientId,
            },
          },
        },
      },
    ],
  },
});

// isSelectedManagerHasEmail :: SearchUsersData -> Email
const isSelectedManagerHasEmail = R.curry((data, managerId) =>
  R.compose(
    R.prop('email'),
    R.find(R.propEq('_id', managerId)),
    R.pathOr([], ['client', 'searchContacts', 'hits']),
  )(data),
);

// getClientManagersDropdownOptions :: SearchUsersData -> [Option]
const getClientManagersDropdownOptions = R.compose(
  R.map(entityToOptionByLabelPath(['fullName'])),
  R.pathOr([], ['client', 'searchContacts', 'hits']),
);

export function ProjectClientManagerSelect({ value, formData, ...props }) {
  const clientId = R.path(['client', '_id'], formData);

  const { data } = useQuery(CLIENT_PEOPLE, {
    variables: {
      entityId: clientId,
      searchInput: {
        query: getClientManagersQuery(clientId),
      },
    },
    skip: !clientId,
  });

  const options = getClientManagersDropdownOptions(data);

  const selectProps = {
    options,
    placeholder: 'Select client manager',
    value,
    ...props,
    disabled: !clientId,
    isClearable: true,
  };

  return (
    <div>
      <Select {...selectProps} />
      {value && !isSelectedManagerHasEmail(data, value) && (
        <FieldWarningText>
          Selected manager has no email address
        </FieldWarningText>
      )}
    </div>
  );
}

ProjectClientManagerSelect.propTypes = {
  value: string,
  formData: shape({
    client: shape({ _id: string }),
  }),
};
