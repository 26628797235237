import React from 'react';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import { EntityStatus, LinkButton } from 'poly-book-admin';
import { SidebarRow, FlexContainer } from 'poly-admin-ui';
import { AssetStatuses } from 'poly-constants';
import { pathOrNothingUI } from 'poly-client-utils';

import {
  SidebarLabel,
  BlockWithLabel,
  SectionWrapper,
  headerTextProps,
  SidebarDetailsLinks,
} from '../components/commonSidebarComponents.js';
import { SidebarIDs } from '../constants.js';
import { assetTabsSidebarId, assetStatusesColors } from './constants.js';
import { OpenFullInfoSidebarButton } from '../components/OpenFullInfoSidebarButton.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import { useOpenAssetTabsSidebar } from './tabs/useOpenAssetTabsSidebar.js';
import { getDetailsLinkProps } from '../components/commonSidebarUtils.js';
import { useEditAssetSidebar } from './forms/edit/useEditAssetSidebar.js';
import { formatAssetTitle } from './assetSidebarUtils.js';
import { useHasUserAccessToUpdateAsset } from './useHasUserAccessToUpdateAsset.js';

const SidebarDetailsLinksS = styled(SidebarDetailsLinks)`
  width: 100%;
`;

// getAssetReferences :: Asset -> _ -> ReactNode
const getAssetReferences = (asset) =>
  function (props) {
    return <SidebarDetailsLinksS {...props} {...getDetailsLinkProps(asset)} />;
  };

export function AssetSidebarHeader({ asset }) {
  const hasUpdateAssetPermission = useHasUserAccessToUpdateAsset();
  const openAssetTabsSidebar = useOpenAssetTabsSidebar();
  const openEditAssetSidebar = useEditAssetSidebar();

  const assetStatus =
    !!asset && !!asset.status ? asset.status : AssetStatuses.INACTIVE;

  return (
    <SectionWrapper>
      <SidebarRow justify align>
        <FlexContainer>
          <SidebarLabel margin={10}>{formatAssetTitle(asset)}</SidebarLabel>
          <EntityStatus
            title="Asset"
            status={{
              text: assetStatus,
              color: assetStatusesColors[assetStatus],
            }}
          />
        </FlexContainer>
        <OpenFullInfoSidebarButton
          sidebarId={SidebarIDs.asset}
          fullSidebarId={assetTabsSidebarId}
          openSidebarHandler={() => openAssetTabsSidebar(asset)}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          id="references"
          label="Client / Property"
          Component={getAssetReferences(asset)}
        />
      </SidebarRow>
      <SidebarRow justify>
        <BlockWithLabel
          margin="0"
          id="description"
          label="Description"
          Component={getSectionText(
            pathOrNothingUI(['description'])(asset),
            headerTextProps,
          )}
        />
        {hasUpdateAssetPermission && (
          <LinkButton onClick={() => openEditAssetSidebar(asset)}>
            Edit
          </LinkButton>
        )}
      </SidebarRow>
    </SectionWrapper>
  );
}

AssetSidebarHeader.propTypes = {
  asset: shape({
    name: string,
    status: string,
    description: string,
    qrCodeId: string,
    manufacturerDoc: shape({
      _id: string,
      name: string,
    }),
  }),
};
