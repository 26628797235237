import React from 'react';
import { LinkButton } from 'poly-book-admin';
import { arrayOf, shape, bool } from 'prop-types';

import { useOpenProcedureBulkAttachSidebar } from './useOpenProcedureBulkAttachSidebar.js';

export function ProcedureBulkAttachButton({
  entity,
  disabled,
  assetProcedures,
}) {
  const onBulkAttachProcedures = useOpenProcedureBulkAttachSidebar();

  const onClick = () => {
    if (!disabled) {
      onBulkAttachProcedures({ entity, assetProcedures });
    }
  };

  return (
    <LinkButton onClick={onClick} disabled={disabled}>
      Procedure Bulk Attach
    </LinkButton>
  );
}

ProcedureBulkAttachButton.propTypes = {
  entity: shape({}),
  disabled: bool.isRequired,
  assetProcedures: arrayOf(shape({})),
};
