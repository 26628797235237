import React from 'react';
import { useModalContext } from 'poly-admin-ui';
import {
  LinkButton,
  MainHeader,
  HeaderLinksWrapper,
  PageHeaderContainer,
} from 'poly-book-admin';

import { AssetTypesForm } from './AssetTypesForm.js';
import { addAssetTypesFormId } from './constants.js';

function AddAssetTypeButton() {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: addAssetTypesFormId,
      title: 'Create New Asset Type',
      formId: addAssetTypesFormId,
      btnCaption: 'Create',
      content: <AssetTypesForm isNewType />,
    });

  return <LinkButton onClick={onClick}>Add New</LinkButton>;
}

export function AssetTypesPageHeader() {
  return (
    <PageHeaderContainer>
      <MainHeader>Asset Types</MainHeader>
      <HeaderLinksWrapper>
        <AddAssetTypeButton />
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}
