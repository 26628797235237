import { gql } from '@apollo/client';
import { keywordSortQuery, useReactiveQuery } from 'poly-client-utils';
import { ASC_SORT_ORDER } from 'poly-constants';

import { useMasterSuppliersSearchInput } from './useMasterSuppliersSearchInput.js';

export const SUPPLIER_SEARCH_CHANGED = gql`
  subscription SUPPLIER_SEARCH_CHANGED($searchInput: CollectionSearchParams!) {
    searchSupplierChanged(input: $searchInput) {
      id
      type
    }
  }
`;

const MASTER_SUPPLIER_BRANCHES = gql`
  query MASTER_SUPPLIER_BRANCHES($id: ID!, $input: CollectionSearchParams!) {
    masterSupplier(id: $id) {
      _id
      searchSupplierBranches(input: $input) {
        hits {
          _id
          company {
            name
            address {
              formatted_address
            }
            services {
              _id
              name
            }
          }
        }
        total
      }
    }
  }
`;

export const useMasterSupplierBranchesQuery = (
  supplierId,
  status,
  sort,
  fetchPolicy,
) => {
  const input = useMasterSuppliersSearchInput({
    sort: sort || keywordSortQuery(['company', 'name'])(ASC_SORT_ORDER),
    query: { term: { status } },
  });

  const subscriptionOptions = {
    variables: { searchInput: input },
    fetchPolicy,
  };
  const queryOptions = {
    ...subscriptionOptions,
    variables: { id: supplierId, input },
  };

  const { data, loading } = useReactiveQuery(
    MASTER_SUPPLIER_BRANCHES,
    SUPPLIER_SEARCH_CHANGED,
    { queryOptions, subscriptionOptions },
  );

  return { data, loading };
};
