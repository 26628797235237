import * as R from 'ramda';
import { func, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { keywordSortQuery, entityToOptionByLabelPath } from 'poly-client-utils';
import { ASC_SORT_ORDER } from 'poly-constants';
import { MAX_ITEMS } from 'poly-admin-ui';
import { Select } from 'poly-book-admin';
import { debounce } from 'poly-utils';

const ASSET_MANUFACTURERS_QUERY = gql`
  query ASSET_MANUFACTURERS_QUERY($input: CollectionSearchParams) {
    searchAssetManufactures(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

// getManufacturerOptions :: SearchAssetManufacturerResult -> [Option]
const getManufacturerOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['searchAssetManufactures', 'hits']),
);

export function AssetManufacturerSelect({
  changeFieldValue,
  onChange: onChangeBase,
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useCallback(
    debounce(300)((search) => {
      setSearchTerm(search);

      if (search) {
        changeFieldValue('searchedManufacturer', search);
      }
    }),
    [],
  );

  const { data } = useQuery(ASSET_MANUFACTURERS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        searchTerm,
        size: MAX_ITEMS,
        sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      },
    },
  });

  const onChange = (value) => {
    changeFieldValue('modelDoc', null);
    changeFieldValue('modelId', null);
    onChangeBase(value);
  };

  const selectProps = {
    ...props,
    onChange,
    options: getManufacturerOptions(data),
    placeholder: 'Start typing manufacturers',
  };

  return <Select {...selectProps} onInputChange={debouncedSearch} />;
}

AssetManufacturerSelect.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
  value: string,
};
