import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { isNilOrEmpty } from 'poly-utils';
import { gql, useApolloClient } from '@apollo/client';
import { bool, func, oneOfType, shape, string } from 'prop-types';
import { Checkbox, Input, LinkButtonWithLoader } from 'poly-book-admin';

import { halfWidth } from '../common.js';

const PIN_CODE_LIMIT = 16;
const PIN_CODE_MAX_VALUE = R.compose(
  R.multiply(1),
  R.join(''),
  R.repeat('9'),
)(PIN_CODE_LIMIT);

const EnableAccessByPinCodeCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 180px;
`;

const PinCodeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComponentLabel = styled.div`
  font-size: 12px;
  padding-left: 10px;
  cursor: pointer;
`;

const LinkButtonWithLoaderS = styled(LinkButtonWithLoader)`
  align-self: flex-end;
`;

function EnableAccessByPinCodeCheckbox({ label, ...props }) {
  const { value, onChange } = props;

  return (
    <EnableAccessByPinCodeCheckboxWrapper onClick={() => onChange(!value)}>
      <Checkbox {...props} />
      <ComponentLabel>{label}</ComponentLabel>
    </EnableAccessByPinCodeCheckboxWrapper>
  );
}

EnableAccessByPinCodeCheckbox.propTypes = {
  value: oneOfType([bool, string]),
  label: string,
  onChange: func,
};

const GENERATE_ASSET_SCANNER_ACCESS_PIN_CODE_QUERY = gql`
  query GENERATE_ASSET_SCANNER_ACCESS_PIN_CODE_QUERY($id: ID) {
    generateAssetScannerAccessPinCode(id: $id)
  }
`;

// getGeneratedPin :: { generateAssetScannerAccessPinCode: String } -> String
const getGeneratedPin = R.propOr('', 'generateAssetScannerAccessPinCode');

const PinCodeInput = React.forwardRef(({ formData, ...props }, ref) => {
  const apolloClient = useApolloClient();
  const [generatingPin, setGeneratingPin] = useState(false);

  const { value, onChange } = props;

  const onGeneratePin = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setGeneratingPin(true);

      const { data } = await apolloClient.query({
        query: GENERATE_ASSET_SCANNER_ACCESS_PIN_CODE_QUERY,
        variables: { id: formData._id },
        fetchPolicy: 'network-only',
      });

      onChange(getGeneratedPin(data));
    } finally {
      setGeneratingPin(false);
    }
  };

  useEffect(() => {
    if (isNilOrEmpty(value)) {
      onGeneratePin();
    }
  }, []);

  return (
    <PinCodeInputWrapper>
      <Input
        showCharactersLeft
        charactersLimit={PIN_CODE_LIMIT}
        maxValue={PIN_CODE_MAX_VALUE}
        type="numberMask"
        {...props}
        allowLeadingZeros
        ref={ref}
      />
      <LinkButtonWithLoaderS disabled={generatingPin} onClick={onGeneratePin}>
        Regenerate
      </LinkButtonWithLoaderS>
    </PinCodeInputWrapper>
  );
});

PinCodeInput.propTypes = {
  value: string,
  onChange: func,
  formData: shape({ _id: string }),
};

export const assetScannerAccessSection = [
  {
    label: 'Asset Scanner Access',
    order: 5,
    layout: { column: 1 },
    fields: [
      {
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'assetScannerPinAccess.isEnabled',
          Component: EnableAccessByPinCodeCheckbox,
          additionalProps: {
            label: 'Enable access by pin code',
          },
        },
      },
      {
        order: 2,
        label: 'Pin Code',
        required: true,
        layout: { row: 2, width: halfWidth },
        field: {
          withFormData: true,
          name: 'assetScannerPinAccess.code',
          Component: PinCodeInput,
        },
        renderIf: R.path(['assetScannerPinAccess', 'isEnabled']),
        validators: [
          [
            R.compose(R.gte(R.__, 8), R.length),
            'PIN code should be from 8 to 16 length long',
          ],
        ],
      },
    ],
  },
];
