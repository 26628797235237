import copyToClipboard from 'copy-to-clipboard';
import { forceTitleCase } from 'poly-utils';

import { useGetJSONDataFromClipboard } from './useGetJSONDataFromClipboard.js';
import { useNotificationState } from './useNotificationState.js';

export const useCopyDataAsJSONToClipboard = () => {
  const { showSuccessNotification } = useNotificationState();
  const getDataFromClipboard = useGetJSONDataFromClipboard();

  return async (clipboardKey, data) => {
    const previousData = await getDataFromClipboard();

    const preparedData = { ...previousData, [clipboardKey]: data };

    copyToClipboard(JSON.stringify(preparedData));

    showSuccessNotification(
      `${forceTitleCase(clipboardKey)} were copied to the clipboard!`,
    );
  };
};
