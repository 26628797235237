import { ToolBarBtnDivider } from 'poly-book-admin';
import React from 'react';
import styled from 'styled-components';
import { useShowByAllowedTabs } from 'poly-admin-ui';
import { SecureProjectsExportXLSBtn } from './ExportXLSSupplierInvoicesBtn.js';
import { SupplierInvoicesPrintBtn } from './PrintSupplierInvoicesBtn.js';
import { FlexContainer } from '../../components/FlexContainer.js';
import { supplierPageTabs } from '../../routes/constants.js';

const ToolBarWrapperS = styled(FlexContainer)`
  align-items: center;
`;

export function PrintExportInvoicesToolBar() {
  const isAllowedToShow = useShowByAllowedTabs([
    supplierPageTabs.supplierInvoices,
  ]);
  return (
    isAllowedToShow && (
      <ToolBarWrapperS>
        <SupplierInvoicesPrintBtn />
        <ToolBarBtnDivider />
        <SecureProjectsExportXLSBtn />
      </ToolBarWrapperS>
    )
  );
}
