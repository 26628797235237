import React from 'react';
import { CommonSidebarFormWidth, entities } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';

import { editUserFormId } from '../../constants.js';
import { EditUserSidebarForm } from './EditUserSidebarForm.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';

export const useEditUserSidebar = (isCard) => {
  const { openOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar(isCard);

  return (user) =>
    openOutSidebar({
      alwaysFirst: true,
      id: editUserFormId,
      width: CommonSidebarFormWidth,
      content: (
        <EditUserSidebarForm
          staffUser={user}
          onClose={() => onClose(entities.USER, user._id)}
        />
      ),
    });
};
