import React from 'react';
import { SupplierSources } from 'poly-constants';
import { AddServiceTypeForm as AddServiceTypeFormImp } from 'poly-admin-ui';
import { ModalFormWrapper } from '../../modals/ModalFormWrapper.js';

const addServiceTypeFormId = 'addServiceTypeFormId';

export function AddServiceTypeForm(props) {
  return (
    <ModalFormWrapper
      {...props}
      formId={addServiceTypeFormId}
      supplierSource={SupplierSources.AAC}
      title="Create Service Type"
      buttonLabel="Create"
      Form={AddServiceTypeFormImp}
    />
  );
}
