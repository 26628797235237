import * as R from 'ramda';
import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { gql } from '@apollo/client';
import { insertParamIntoURL } from 'poly-utils';
import { Link } from 'poly-client-routing';
import { useReactiveQuery } from 'poly-client-utils';

const checkSupplierTaxFieldsQuery = gql`
  query checkSupplierTaxFieldsQuery($input: CollectionSearchParams!) {
    searchSuppliers(input: $input) {
      hits {
        _id
        company {
          name
        }
        tax {
          id
          socialSecurityNumber
        }
      }
      total
    }
  }
`;

const checkMasterSupplierTaxFieldsQuery = gql`
  query checkMasterSupplierTaxFieldsQuery($input: CollectionSearchParams!) {
    searchMasterSuppliers(input: $input) {
      hits {
        _id
        company {
          name
        }
        tax {
          id
          socialSecurityNumber
        }
      }
      total
    }
  }
`;

const searchSupplierSubscription = gql`
  subscription searchSupplierSubscription(
    $searchInput: CollectionSearchParams!
  ) {
    searchSupplierChanged(input: $searchInput) {
      id
      type
    }
  }
`;

const searchMasterSupplierSubscription = gql`
  subscription searchMasterSupplierSubscription(
    $searchInput: CollectionSearchParams!
  ) {
    searchMasterSupplierChanged(input: $searchInput) {
      id
      type
    }
  }
`;

// formatFieldName :: String -> String
const formatFieldName = R.ifElse(
  R.equals('socialSecurityNumber'),
  R.always('Social Security #'),
  R.always('ID'),
);

function DuplicateTaxIdErrMsg({ supplier, url, name }) {
  const companyName = R.path(['company', 'name'], supplier);
  const content = `This tax ${formatFieldName(name)} already used by `;

  return (
    <span>
      {content}
      &nbsp;
      <Link href={insertParamIntoURL('supplierId', url, supplier._id)}>
        {companyName}
      </Link>
    </span>
  );
}

DuplicateTaxIdErrMsg.propTypes = {
  supplier: shape({
    _id: string.isRequired,
    company: shape({
      name: string.isRequired,
    }),
  }),
  name: string.isRequired,
  url: string.isRequired,
};

// getMatchSupplierOrMasterSearchQuery :: Supplier -> SearchQuery
const getMatchSupplierOrMasterSearchQuery = R.compose(
  R.reject(R.isNil),
  R.converge(Array.of, [
    R.always({ exists: { field: 'masterSupplierId' } }),
    R.compose(
      R.ifElse(
        R.isEmpty,
        R.always(null),
        R.assocPath(['terms', '_id'], R.__, {}),
      ),
      R.reject(R.isNil),
      R.juxt([R.prop('_id'), R.path(['masterSupplier', '_id'])]),
    ),
  ]),
);

// isSearchMasterSuppliers :: [String] -> Boolean
const isSearchMasterSuppliers = R.propEq(0, 'searchMasterSuppliers');

// getTaxFieldByFormSupplier :: Supplier -> SupplierTax
const getTaxFieldByFormSupplier = R.compose(
  R.reject(R.isNil),
  R.pick(['id', 'socialSecurityNumber']),
  R.defaultTo({}),
  R.prop('tax'),
);

// getTaxFieldName :: Supplier -> String
const getTaxFieldName = R.compose(R.head, R.keys, getTaxFieldByFormSupplier);

// getTaxFieldValue :: Supplier -> String
const getTaxFieldValue = R.compose(
  R.defaultTo(''),
  R.head,
  R.values,
  getTaxFieldByFormSupplier,
);

const useTaxValidationBase = ({
  query,
  subscriptionQuery,
  path,
  skipMasterSupplierValidation,
  url,
  supplier,
}) => {
  const taxFieldNameToCheck = getTaxFieldName(supplier);
  const taxFieldValueToCheck = getTaxFieldValue(supplier);
  const taxQueryField = `tax.${taxFieldNameToCheck}`;

  const skipQuery =
    skipMasterSupplierValidation === true &&
    isSearchMasterSuppliers(path) === true;

  const queryOptions = {
    query,
    fetchPolicy: 'network-only',
    skip: skipQuery,
    variables: {
      input: {
        size: 1,
        query: {
          bool: {
            must: [
              {
                match: {
                  [taxQueryField]: taxFieldValueToCheck,
                },
              },
            ],
            must_not: getMatchSupplierOrMasterSearchQuery(supplier),
          },
        },
      },
    },
  };

  const { data } = useReactiveQuery(query, subscriptionQuery, {
    queryOptions,
    subscriptionOptions: { variables: { searchInput: {} } },
  });

  const existingSupplier = R.path([...path, 'hits', 0], data);

  if (existingSupplier) {
    return {
      supplier: existingSupplier,
      fieldName: taxFieldNameToCheck,
      errMsg: (
        <DuplicateTaxIdErrMsg
          supplier={existingSupplier}
          url={url}
          name={taxFieldNameToCheck}
        />
      ),
    };
  }

  return null;
};

export const useSupplierTaxValidation = (
  masterSupplierRootUrl,
  supplierUrl,
  skipMasterSupplierValidation,
  preSetSupplier = {},
) => {
  const [supplier, setSupplier] = useState(preSetSupplier);

  const supplierTaxValidationResult = useTaxValidationBase({
    query: checkSupplierTaxFieldsQuery,
    subscriptionQuery: searchSupplierSubscription,
    path: ['searchSuppliers'],
    url: supplierUrl,
    skipMasterSupplierValidation,
    supplier,
  });

  const masterSupplierTaxValidationResult = useTaxValidationBase({
    query: checkMasterSupplierTaxFieldsQuery,
    subscriptionQuery: searchMasterSupplierSubscription,
    path: ['searchMasterSuppliers'],
    url: masterSupplierRootUrl,
    skipMasterSupplierValidation,
    supplier,
  });

  return (values) => {
    const taxFieldValue = getTaxFieldValue(values);

    if (taxFieldValue.length < 9) {
      return {};
    }

    const currentSupplier = R.isEmpty(supplier) ? preSetSupplier : supplier;

    const supplierTaxIdAndSNN = getTaxFieldByFormSupplier(currentSupplier);
    const valuesTaxIdAndSNN = getTaxFieldByFormSupplier(values);

    if (!R.equals(supplierTaxIdAndSNN, valuesTaxIdAndSNN)) {
      setSupplier(values);
    }
    if (
      !!masterSupplierTaxValidationResult &&
      !!masterSupplierTaxValidationResult.errMsg
    ) {
      return {
        tax: {
          [masterSupplierTaxValidationResult.fieldName]:
            masterSupplierTaxValidationResult.errMsg,
        },
      };
    }

    if (!!supplierTaxValidationResult && !!supplierTaxValidationResult.errMsg) {
      return {
        tax: {
          [supplierTaxValidationResult.fieldName]:
            supplierTaxValidationResult.errMsg,
        },
      };
    }

    return {};
  };
};
