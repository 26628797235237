import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import { string } from 'prop-types';
import { debounce } from 'poly-utils';
import { SidebarWidth, entities } from 'poly-admin-ui';
import { useMutation, gql } from '@apollo/client';
import { useOutSidebarContext } from 'poly-client-utils';

import { PopoverLink } from 'poly-book-admin/src/Popover/index.js';
import { useNotificationState } from 'poly-admin-ui/src/hooks/useNotificationState.js';
import { assignSupplierFormId } from '../../../modules/forms/assignSupplierForm/constants.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';
import { AssignSupplierForm } from '../../../modules/forms/assignSupplierForm/AssignSupplierForm.js';
import { assignSupplierFormTypes } from '../constants.js';

const RESEND_WORK_ORDER_CONFIRMATION_MUTATION = gql`
  mutation RESEND_WORK_ORDER_CONFIRMATION_MUTATION(
    $input: ResendWorkOrderConfirmationInput!
  ) {
    resendWorkOrderConfirmation(input: $input) {
      existedWOCWasResent
    }
  }
`;

// isExistedWOCWasResent :: { resendWorkOrderConfirmation: ResendWorkOrderConfirmationResult } -> Boolean
const isExistedWOCWasResent = R.pathOr(false, [
  'resendWorkOrderConfirmation',
  'existedWOCWasResent',
]);

export function SendWOCPopoverLink({ projectId, supplierId }) {
  const { openOutSidebar } = useOutSidebarContext();
  const [loading, setLoading] = useState(false);
  const [resendWorkOrderConfirmation] = useMutation(
    RESEND_WORK_ORDER_CONFIRMATION_MUTATION,
  );
  const { showSuccessNotification } = useNotificationState();

  const setLoadingDebounced = useCallback(debounce(2000)(setLoading), []);

  const onClick = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const { data } = await resendWorkOrderConfirmation({
      variables: { input: { projectId, supplierId } },
      fetchPolicy: 'network-only',
    });

    setLoadingDebounced(false);

    if (isExistedWOCWasResent(data)) {
      showSuccessNotification('Supplier WOC was resent successfully.');
      return;
    }

    openOutSidebar({
      isSteady: true,
      width: SidebarWidth,
      id: assignSupplierFormId,
      Layout: SecondSidebarLayout,
      content: (
        <AssignSupplierForm
          supplierId={supplierId}
          entity={{ _id: projectId, name: entities.PROJECT }}
          formType={assignSupplierFormTypes.sendWOC}
        />
      ),
    });
  };

  return <PopoverLink onClick={onClick}>Send WOC</PopoverLink>;
}

SendWOCPopoverLink.propTypes = {
  projectId: string,
  supplierId: string,
};
