import React from 'react';
import * as R from 'ramda';
import { arrayOf, shape, string } from 'prop-types';
import { entityToOptionByLabelPath } from 'poly-client-utils';
import { Input, Select } from 'poly-book-admin';

// getSelectOptions :: [Supplier] -> [Option]
const getSelectOptions = R.map(entityToOptionByLabelPath(['company', 'name']));

export function InvoiceSupplierField({ suppliers, ...props }) {
  const selectProps = {
    ...props,
    name: 'InvoiceSupplierSelect',
    placeholder: 'Select Supplier',
    options: getSelectOptions(suppliers),
  };

  if (!suppliers || suppliers.length === 0)
    return (
      <Input
        {...props}
        readOnly
        hasError
        placeholder="You Must Assign a Supplier"
      />
    );

  if (suppliers.length === 1)
    return (
      <Input
        {...props}
        readOnly
        value={R.path([0, 'company', 'name'], suppliers)}
      />
    );

  return <Select {...selectProps} />;
}

InvoiceSupplierField.propTypes = {
  suppliers: arrayOf(
    shape({
      _id: string.isRequired,
      company: shape({
        name: string.isRequired,
      }).isRequired,
    }),
  ),
};
