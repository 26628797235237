import React from 'react';
import { useOutSidebarContext } from 'poly-client-utils';
import { SidebarWidth } from 'poly-admin-ui';

import { userGroupTabsSidebarId } from '../constants.js';
import { UserGroupTabsSidebar } from './UserGroupTabsSidebar.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';

export const useOpenUserGroupTabsSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (props) => {
    openOutSidebar({
      width: SidebarWidth,
      id: userGroupTabsSidebarId,
      Layout: SecondSidebarLayout,
      content: <UserGroupTabsSidebar {...props} />,
    });
  };
};
