import * as R from 'ramda';
import styled from 'styled-components';
import React from 'react';
import { bool, func, shape, string, oneOfType } from 'prop-types';
import { Input, Checkbox, CharacterCount, Select } from 'poly-book-admin';
import { isNilOrEmpty } from 'poly-utils';

import { StateSelect } from '../StateSelect.js';
import { CityInput } from '../CityInput.js';
import { GeoSelect } from '../../../../components/geoSelect/GeoSelect.js';
import { ZipCodeInput, ZipCodeSuffixInput } from '../ZipCodeInput.js';
import {
  halfWidth,
  oneEighthWidth,
  quarterWidth,
} from '../../supplierForm/form/common.js';
import { ResetInput } from '../../../../components/ResetInput.js';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div:not(:last-child) {
    padding-bottom: 20px;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const HalfLimitedInputWrapper = styled.div`
  width: ${halfWidth};
`;

const ZipInputWrapper = styled.div`
  width: ${R.prop('width')};
`;

const StateSelectWrapper = styled.div`
  display: flex;
  width: ${quarterWidth};
`;

const HalfInputWrapper = styled.div`
  width: ${halfWidth};
`;

// createFieldLabel :: (String, String, Boolean) -> String
const createFieldLabel = (prefix, label, required = false) =>
  R.compose(
    R.when(R.always(required), R.concat(R.__, ' *')),
    R.when(R.always(prefix), R.concat(`${prefix} `)),
  )(label);

const confirmResetContent = `Are you sure you want to clear this address? 
     (This action clears address only at you browser window. Submit form to save those changes)`;

export function AddressInput({ isClearable, handleReset, ...props }) {
  return isClearable && props.value ? (
    <ResetInput
      confirmContent={confirmResetContent}
      handleReset={handleReset}
      {...props}
      isConfirm
    />
  ) : (
    <Input {...props} />
  );
}

AddressInput.propTypes = {
  isClearable: bool,
  handleReset: func.isRequired,
  value: string,
};

// getValueLengthByPath :: [String] -> Object -> Int
const getValueLengthByPath = (path) => R.compose(R.length, R.pathOr('', path));

// isUSAddressOrEmpty :: Address -> Boolean
const isUSAddressOrEmpty = R.compose(
  R.either(R.equals('US'), isNilOrEmpty),
  R.path(['address_parts', 'country']),
);

// isUSFormatZipCode :: Address -> Boolean
const isUSFormatZipCode = R.either(
  R.pathEq(['address_parts', 'country'], 'PR'),
  isUSAddressOrEmpty,
);

const addressValuePropType = oneOfType([
  string,
  shape({
    formatted_address: string,
    address_parts: shape({
      route: string,
      country: string,
      locality: string,
      postal_code: string,
      postal_code_suffix: string,
      street_number: string,
      administrative_area_level_1: string,
    }),
  }),
]);

function USFormatZipInput({
  value,
  onBlur,
  onFocus,
  onKeyDown,
  setZipCode,
  setZipCodeSuffix,
  isRequiredZipCode,
}) {
  const width = isUSAddressOrEmpty(value) ? oneEighthWidth : quarterWidth;
  return (
    <>
      <ZipInputWrapper width={width}>
        <ZipCodeInput
          name="zip"
          label="Zip"
          onChange={setZipCode}
          onKeyDown={onKeyDown}
          {...{ onBlur, onFocus }}
          required={isRequiredZipCode}
          maxLength={5}
          value={R.pathOr('', ['address_parts', 'postal_code'], value)}
        />
        <CharacterCount
          limit={5}
          length={getValueLengthByPath(['address_parts', 'postal_code'])(value)}
        />
      </ZipInputWrapper>
      <ZipInputWrapper width={width}>
        <ZipCodeSuffixInput
          name="suffix"
          label="Suffix"
          onChange={setZipCodeSuffix}
          onKeyDown={onKeyDown}
          {...{ onBlur, onFocus }}
          value={R.pathOr('', ['address_parts', 'postal_code_suffix'], value)}
        />
        <CharacterCount
          limit={4}
          length={getValueLengthByPath(['address_parts', 'postal_code_suffix'])(
            value,
          )}
        />
      </ZipInputWrapper>
    </>
  );
}

USFormatZipInput.propTypes = {
  value: addressValuePropType,
  onBlur: func,
  onFocus: func,
  onKeyDown: func,
  setZipCode: func,
  setZipCodeSuffix: func,
  isRequiredZipCode: bool,
};

export const countrySelectOptions = [
  { value: 'US', label: 'USA' },
  { value: 'CA', label: 'Canada' },
  { value: 'PR', label: 'Puerto Rico' },
];

function CountrySelect({ value, ...props }) {
  return (
    <Select
      {...props}
      options={countrySelectOptions}
      value={R.pathOr('US', ['address_parts', 'country'], value)}
      label="Country"
      required
      width="20%"
    />
  );
}

CountrySelect.propTypes = {
  value: addressValuePropType,
};

export function AddressSectionComp({
  name,
  value,
  error,
  onBlur,
  onFocus,
  setCity,
  hasError,
  required,
  setZipCode,
  setCountry,
  setZipCodeSuffix,
  labelPrefix,
  onInputChange,
  setAddressTwo,
  setStreetName,
  isAddressManual,
  setStreetNumber,
  setAddressState,
  setAddressManual,
  setAddressSearch,
  isRequiredZipCode,
  isRequiredSearchAddressField,
  warning,
  isEmptyAddress,
  onChange,
  onKeyDown,
  isClearable,
  isAddressFieldsLimited,
}) {
  return (
    <Wrapper>
      <GeoSelect
        label={createFieldLabel(labelPrefix, 'Address Search', required)}
        {...{
          required: isRequiredSearchAddressField,
          onBlur,
          onFocus,
          onInputChange,
        }}
        onChange={setAddressSearch}
        onKeyDown={onKeyDown}
      />
      <RowWrapper>
        <HalfInputWrapper>
          <AddressInput
            readonly
            name="address1"
            label={createFieldLabel(labelPrefix, 'Address')}
            onChange={() => null}
            value={R.pathOr('', ['formatted_address'], value)}
            {...{ error, hasError, onFocus, onBlur, warning }}
            disabled
            handleReset={() => onChange(null)}
            onKeyDown={onKeyDown}
            isClearable={isClearable}
          />
        </HalfInputWrapper>

        <HalfLimitedInputWrapper>
          <Input
            name="address2"
            label={createFieldLabel(labelPrefix, 'Address 2')}
            {...{ onBlur, onFocus }}
            value={R.pathOr('', ['addressTwo'], value)}
            onChange={(e) => setAddressTwo(e.target.value)}
            onKeyDown={onKeyDown}
            {...(isAddressFieldsLimited ? { maxLength: 100 } : {})}
          />
          {isAddressFieldsLimited && (
            <CharacterCount
              limit={100}
              length={getValueLengthByPath(['addressTwo'])(value)}
            />
          )}
        </HalfLimitedInputWrapper>
      </RowWrapper>
      <RowWrapper>
        <Checkbox
          name={`${name}.isAddressManual`}
          value={isAddressManual}
          onChange={setAddressManual}
          label="Set Address Manually"
        />
      </RowWrapper>
      {!!isAddressManual && (
        <>
          <RowWrapper>
            <HalfLimitedInputWrapper>
              <Input
                name="streetNumber"
                label="Street Number"
                {...{ onBlur, onFocus }}
                onChange={(e) => setStreetNumber(e.target.value)}
                onKeyDown={onKeyDown}
                value={R.pathOr('', ['address_parts', 'street_number'], value)}
                {...(isAddressFieldsLimited ? { maxLength: 50 } : {})}
              />
              {isAddressFieldsLimited && (
                <CharacterCount
                  limit={50}
                  length={getValueLengthByPath([
                    'address_parts',
                    'street_number',
                  ])(value)}
                />
              )}
            </HalfLimitedInputWrapper>
            <HalfLimitedInputWrapper>
              <Input
                name="streetName"
                label="Street Name"
                {...{ onBlur, onFocus }}
                onChange={(e) => setStreetName(e.target.value)}
                onKeyDown={onKeyDown}
                value={R.pathOr('', ['address_parts', 'route'], value)}
                {...(isAddressFieldsLimited ? { maxLength: 50 } : {})}
              />
              {isAddressFieldsLimited && (
                <CharacterCount
                  limit={50}
                  length={getValueLengthByPath(['address_parts', 'route'])(
                    value,
                  )}
                />
              )}
            </HalfLimitedInputWrapper>
          </RowWrapper>
          <RowWrapper>
            <HalfLimitedInputWrapper>
              <CityInput
                name="city"
                label="City"
                {...{ onBlur, onFocus }}
                onChange={(e) => setCity(e.target.value)}
                onKeyDown={onKeyDown}
                value={R.pathOr('', ['address_parts', 'locality'], value)}
                {...(isAddressFieldsLimited ? { maxLength: 50 } : {})}
              />
              {isAddressFieldsLimited && (
                <CharacterCount
                  limit={50}
                  length={getValueLengthByPath(['address_parts', 'locality'])(
                    value,
                  )}
                />
              )}
            </HalfLimitedInputWrapper>
            {isUSAddressOrEmpty(value) && (
              <StateSelectWrapper>
                <StateSelect
                  name="state"
                  label="State"
                  placeholder=""
                  width="100%"
                  {...{ onBlur, onFocus }}
                  isClearable={!required || isEmptyAddress}
                  onChange={setAddressState}
                  onKeyDown={onKeyDown}
                  value={R.pathOr(
                    '',
                    ['address_parts', 'administrative_area_level_1'],
                    value,
                  )}
                />
              </StateSelectWrapper>
            )}
            {isUSFormatZipCode(value) ? (
              <USFormatZipInput
                {...{
                  value,
                  onBlur,
                  onFocus,
                  onKeyDown,
                  setZipCode,
                  setZipCodeSuffix,
                  isRequiredZipCode,
                }}
              />
            ) : (
              <HalfLimitedInputWrapper>
                <ZipCodeInput
                  name="zip"
                  label="Zip"
                  onChange={setZipCode}
                  onKeyDown={onKeyDown}
                  {...{ onBlur, onFocus }}
                  required={isRequiredZipCode}
                  maxLength={7}
                  value={R.pathOr('', ['address_parts', 'postal_code'], value)}
                />
                <CharacterCount
                  limit={7}
                  length={getValueLengthByPath([
                    'address_parts',
                    'postal_code',
                  ])(value)}
                />
              </HalfLimitedInputWrapper>
            )}
          </RowWrapper>
          <RowWrapper>
            <CountrySelect onChange={setCountry} value={value} />
          </RowWrapper>
        </>
      )}
    </Wrapper>
  );
}

AddressSectionComp.propTypes = {
  error: string,
  required: bool,
  labelPrefix: string,
  name: string.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  setCity: func.isRequired,
  onChange: func.isRequired,
  onKeyDown: func,
  hasError: bool.isRequired,
  setZipCode: func.isRequired,
  setZipCodeSuffix: func.isRequired,
  setStreetName: func.isRequired,
  setAddressTwo: func.isRequired,
  onInputChange: func.isRequired,
  isAddressManual: bool.isRequired,
  setStreetNumber: func.isRequired,
  setAddressState: func.isRequired,
  setAddressSearch: func.isRequired,
  setAddressManual: func.isRequired,
  value: addressValuePropType,
  isRequiredSearchAddressField: bool,
  isRequiredZipCode: bool,
  warning: string,
  isEmptyAddress: bool,
  isClearable: bool,
  isAddressFieldsLimited: bool,
  setCountry: func.isRequired,
};

AddressSectionComp.defaultProps = {
  labelPrefix: '',
  isAddressFieldsLimited: false,
};
