import * as R from 'ramda';
import { supplierEmailsTypes, supplierPhoneTypes } from 'poly-constants';
import {
  addNewLineToLastOpsToForm,
  removePropDeep,
  ensureIsDate,
  assocByPath,
} from 'poly-utils';

import { COMPANY_SERVICES_PATH } from '../form/constants.js';
import { prepareAddressObjectToForm } from '../../../../utils/address.js';
import { supplierInitialOfficeHours } from '../form/utils.js';
import { isNullRemark } from '../../../../utils/form.js';

/**
 * getPhoneByType :: String -> [Object] -> String
 */
// eslint-disable-next-line import/no-unused-modules
export const getPhoneByType = R.curry((type, phones) =>
  R.pipe(
    R.find(R.both(R.propEq('type', type), R.propEq('legacyFormat', false))),
    R.defaultTo({}),
    R.propOr(null, 'phone'),
  )(phones),
);

// withSupplierServiceTypesIds :: Object -> Object
export const withSupplierServiceTypesIds = assocByPath(
  ['company', 'servicesIds'],
  R.compose(
    R.map(({ _id, name }) => ({ value: _id, label: name })),
    R.defaultTo([]),
    R.path(COMPANY_SERVICES_PATH),
  ),
);

// withoutSupplierServices :: Object -> Object
export const withoutSupplierServices = R.dissocPath(COMPANY_SERVICES_PATH);

// prepareMultiEmailToDisplay :: String -> [SupplierEmail] -> Object
const prepareMultiEmailToDisplay = (type) =>
  R.compose(
    R.ifElse(
      R.isEmpty,
      R.always({}),
      R.compose(
        R.objOf(type),
        R.map(R.mergeAll),
        R.map(R.juxt([R.objOf('label'), R.objOf('value')])),
        R.map(R.prop('email')),
      ),
    ),
    R.filter(R.propEq('type', type)),
  );

const { SECONDARY_SERVICE, ACCOUNT } = supplierEmailsTypes;

const multiEmailTypes = [SECONDARY_SERVICE, ACCOUNT];

// prepareSupplierEmailsToEditForm :: Supplier -> Supplier
export const prepareSupplierEmailsToEditForm = R.over(
  R.lensPath(['company', 'emails']),
  R.compose(
    R.mergeAll,
    R.juxt([
      R.compose(
        R.reduce((acc, { type, email }) => ({ [type]: email, ...acc }), {}),
        R.reject(R.propSatisfies(R.includes(R.__, multiEmailTypes), 'type')),
      ),
      prepareMultiEmailToDisplay(SECONDARY_SERVICE),
      prepareMultiEmailToDisplay(ACCOUNT),
    ]),
    R.defaultTo([]),
  ),
);

// findPhoneByType :: String -> Supplier -> String
export const findPhoneByType = (type) =>
  R.compose(
    getPhoneByType(type),
    R.defaultTo([]),
    R.path(['company', 'phones']),
  );

// prepareSupplierPhonesToForm :: Supplier -> Object
export const prepareSupplierPhonesToForm = R.compose(
  R.dissocPath(['company', 'phones']),
  assocByPath(
    ['company', 'mobile'],
    findPhoneByType(supplierPhoneTypes.MOBILE),
  ),
  assocByPath(
    ['company', 'phone'],
    findPhoneByType(supplierPhoneTypes.GENERAL),
  ),
);

const setIsMBEIfMinoritySet = R.compose(
  R.dissocPath(['mwbe', 'isWBE']),
  assocByPath(['isWBE'], R.path(['mwbe', 'isWBE'])),
  R.when(R.path(['mwbe', 'minority']), R.assocPath(['mwbe', 'isMBE'], true)),
);

// ensurePropIsDate :: String -> Object -> Object
const ensurePropIsDate = (prop) => R.over(R.lensProp(prop), ensureIsDate);

// prepareSupplierRemittanceEmailsToForm :: Supplier -> Supplier
const prepareSupplierRemittanceEmailsToForm = R.over(
  R.lensPath(['bank', 'remittanceEmails']),
  R.compose(
    R.map(R.applySpec({ label: R.identity, value: R.identity })),
    R.defaultTo([]),
  ),
);

/**
 * supplierToEditForm :: Supplier -> Object
 * Supplier = Object
 */
export const supplierToEditForm = R.compose(
  withoutSupplierServices,
  withSupplierServiceTypesIds,
  prepareSupplierEmailsToEditForm,
  prepareSupplierPhonesToForm,
  prepareSupplierRemittanceEmailsToForm,
  prepareAddressObjectToForm('remit'),
  prepareAddressObjectToForm('tax'),
  prepareAddressObjectToForm('company'),
  setIsMBEIfMinoritySet,
  removePropDeep('__typename'),
  R.dissoc('masterSupplier'),
  addNewLineToLastOpsToForm('remarks'),
  R.ifElse(
    R.propSatisfies(R.isNil, 'officeHours'),
    R.assoc('officeHours', supplierInitialOfficeHours),
    R.over(
      R.lensProp('officeHours'),
      R.compose(
        R.map(
          R.compose(ensurePropIsDate('endTime'), ensurePropIsDate('startTime')),
        ),
        R.defaultTo([]),
      ),
    ),
  ),
  R.when(
    R.compose(isNullRemark, R.prop('remarks')),
    R.assocPath(['remarks'], ''),
  ),
  assocByPath(
    ['finance', 'acceptsCreditCard'],
    R.pathOr(false, ['finance', 'acceptsCreditCard']),
  ),
  assocByPath(
    ['foodBuy', 'vaPercentage'],
    R.pathOr(0, ['foodBuy', 'vaPercentage']),
  ),
);
