import React, { useEffect } from 'react';
import { string, shape } from 'prop-types';
import { SidebarWrapper, entities } from 'poly-admin-ui';
import { Loader } from 'poly-book-admin';

import { NotFoundEntity } from '../../components/NotFoundEntity.js';
import { MasterRecurringProjectSidebarTasks } from './MasterRecurringProjectSidebarTasks.js';
import { MasterRecurringProjectSidebarHeader } from './MasterRecurringProjectSidebarHeader.js';
import { MasterRecurringProjectSidebarDetails } from './MasterRecurringProjectSidebarDetails.js';
import { MasterRecurringProjectSidebarPlanning } from './MasterRecurringProjectSidebarPlanning.js';
import { MasterRecurringProjectSidebarFinancial } from './MasterRecurringProjectSidebarFinancial.js';
import { MasterRecurringProjectSidebarContactInfo } from './MasterRecurringProjectSidebarContactInfo.js';
import { useOpenMasterRecurringProjectSidebarTabs } from './tabs/useOpenMasterRecurringProjectSidebarTabs.js';
import { useMasterRecurringProjectSidebarData } from './useMasterRecurringProjectSidebarData.js';
import { prepareProjectHistoryRecordByEntity } from '../components/commonSidebarUtils.js';
import { useReactiveSidebarDocumentTitle } from '../useReactiveSidebarDocumentTitle.js';
import { useSidebarLogicContext } from '../SidebarLogicContext.js';
import { MasterRecurringProjectSuppliers } from './MasterRecurringProjectSuppliers.js';

// we want to separate this logic from other sidebar parts
// to avoid conflict with pristine logic work for forms
function MasterRecurringProjectSidebarRouterLogic({ project }) {
  const { setHistoryRecord } = useSidebarLogicContext();
  const openSidebarTabs = useOpenMasterRecurringProjectSidebarTabs(project);

  const historyRecord = prepareProjectHistoryRecordByEntity(
    entities.RECURRING_PROJECT,
  )(project);

  useEffect(() => {
    openSidebarTabs();
    setHistoryRecord(historyRecord);
  }, []);

  useReactiveSidebarDocumentTitle(project.projectId);

  return null;
}

MasterRecurringProjectSidebarRouterLogic.propTypes = {
  project: shape({ projectId: string.isRequired }).isRequired,
};

export function MasterProjectSidebarSections(props) {
  return (
    <>
      <MasterRecurringProjectSidebarHeader {...props} />
      <MasterRecurringProjectSidebarDetails {...props} />
      <MasterRecurringProjectSidebarContactInfo {...props} />
      <MasterRecurringProjectSidebarTasks {...props} />
      <MasterRecurringProjectSuppliers {...props} />
      <MasterRecurringProjectSidebarFinancial {...props} />
      <MasterRecurringProjectSidebarPlanning {...props} />
    </>
  );
}

export function MasterRecurringProjectSidebar({ projectLinkId }) {
  const { loading, ...sidebarProps } =
    useMasterRecurringProjectSidebarData(projectLinkId);

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarWrapper>
      {!sidebarProps.project.projectId ? (
        <NotFoundEntity entityName="master project" />
      ) : (
        <>
          <MasterProjectSidebarSections {...sidebarProps} />
          <MasterRecurringProjectSidebarRouterLogic {...sidebarProps} />
        </>
      )}
    </SidebarWrapper>
  );
}

MasterRecurringProjectSidebar.propTypes = { projectLinkId: string.isRequired };
