import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape, bool, func } from 'prop-types';
import { getThemeColor, getThemeFont } from 'poly-book-admin';

import { FlexRow } from '../../components/appBar/HistoryPopUp/historyComponents.js';
import { FooterContainer } from '../SpendReport/spendReportsTable/SpendReportsFooter.js';

const FooterContainerS = styled(FooterContainer)`
  justify-content: flex-end;
  padding: ${({ isPrintPDF }) => (isPrintPDF ? '0 20px' : '0 50px')};
`;

const FooterWrapper = styled(FlexRow)`
  justify-content: flex-end;
  gap: 60px;
`;

const FooterSection = styled(FlexRow)`
  gap: 5px;
`;

const FooterLabel = styled.div`
  font-weight: ${getThemeFont(['regular'])};
  color: ${getThemeColor(['secondary'])};
`;

export function QuoteDiscrepanciesPageFooter({
  tableRows,
  isPrintPDF,
  prepareFooterData,
}) {
  const { averageDiscrepancy, totalDiscrepancy } = prepareFooterData(tableRows);

  return (
    <FooterContainerS isPrintPDF={isPrintPDF}>
      <FooterWrapper>
        <FooterSection>
          <FooterLabel>Average Discrepancy %:</FooterLabel>
          <div>{averageDiscrepancy}%</div>
        </FooterSection>
        <FooterSection>
          <FooterLabel>Total Discrepancy:</FooterLabel>
          <div>{totalDiscrepancy}</div>
        </FooterSection>
      </FooterWrapper>
    </FooterContainerS>
  );
}

QuoteDiscrepanciesPageFooter.propTypes = {
  tableRows: arrayOf(shape({})),
  isPrintPDF: bool,
  prepareFooterData: func.isRequired,
};
