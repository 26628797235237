import * as R from 'ramda';
import { AccountTypeCategories, NormalBalanceTypes } from 'poly-constants';
import { calculateTotal, ramdaUseWith } from 'poly-utils';

const isCreditNormalAccount = R.pathSatisfies(
  R.equals(NormalBalanceTypes.CREDIT),
  ['type', 'normal_balance'],
);

// getAmount :: [String] -> Account -> Number
const getAmount = (balancePath) => R.pathOr(0, balancePath);

const CREDIT_AMOUNT = 'credit';
const DEBIT_AMOUNT = 'debit';

// getAccountClosingAmountType :: [String] -> Account -> String
const getAccountClosingAmountType = (balancePath) =>
  R.ifElse(
    R.either(
      R.both(
        isCreditNormalAccount,
        R.pathSatisfies(R.lt(R.__, 0), balancePath),
      ),
      R.both(
        R.complement(isCreditNormalAccount),
        R.pathSatisfies(R.gt(R.__, 0), balancePath),
      ),
    ),
    R.always(CREDIT_AMOUNT),
    R.always(DEBIT_AMOUNT),
  );

// getBalanceAmount :: [String] -> Account -> Number
const getBalanceAmount = (key) =>
  R.compose(R.ifElse(R.equals(0), R.always(null), Math.abs), R.prop(key));

// getAccountValues :: Account -> {_id: ID, code: String}
export const getAccountValues = R.ifElse(
  R.prop('_id'),
  R.pick(['_id', 'code', 'is_retained_earnings_account']),
  R.always(null),
);

// formatAccrualBasisClosingLine :: Account -> AccountClosingLine
// AccountClosingLine = {
//   isCreditNormal: Boolean
//   account: Account
//   balance: Number
// }
export const formatAccrualBasisClosingLine = R.compose(
  R.converge(R.mergeLeft, [
    R.converge(R.objOf, [
      getAccountClosingAmountType(['balance']),
      getBalanceAmount('balance'),
    ]),
    R.compose(
      R.mergeRight({ credit: null, debit: null }),
      R.applySpec({
        isCreditNormal: isCreditNormalAccount,
        account: getAccountValues,
        balance: R.prop('balance'),
      }),
    ),
  ]),
);

// formatCashBasisClosingLine :: Account -> AccountClosingLine
export const formatCashBasisClosingLine = R.converge(R.mergeLeft, [
  R.converge(R.objOf, [
    getAccountClosingAmountType(['cashBasisBalance']),
    getBalanceAmount('cashBasisBalance'),
  ]),
  R.compose(
    R.mergeRight({ credit: null, debit: null, isCashBasis: true }),
    R.applySpec({
      isCreditNormal: isCreditNormalAccount,
      account: getAccountValues,
      balance: R.prop('cashBasisBalance'),
    }),
  ),
]);

// calculateNetIncome :: ([String], [Account]) -> Number
const calculateNetIncome = (balancePath, accounts) =>
  calculateTotal(
    R.ifElse(
      R.pathEq(['type', 'category'], AccountTypeCategories.expense),
      R.compose(R.multiply(-1), getAmount(balancePath)),
      getAmount(balancePath),
    ),
    accounts,
  );

// formatCurrentEarningsLine :: [String] -> ([Account], Account) -> AccountClosingLine
// eslint-disable-next-line import/no-unused-modules
export const formatCurrentEarningsLine =
  (balancePath) => (closeAccounts, retainedEarningsAccount) => {
    const getBalance = getAmount(balancePath);
    const balance = getBalance(retainedEarningsAccount);
    const netIncome = calculateNetIncome(balancePath, closeAccounts);
    const amountKey = netIncome < 0 ? DEBIT_AMOUNT : CREDIT_AMOUNT;

    return {
      account: getAccountValues(retainedEarningsAccount),
      [amountKey]: Math.abs(netIncome),
      isCreditNormal: true,
      balance,
    };
  };

// formatCashBasisRetainedEarningsLine :: ([Account], Account) -> AccountClosingLine
export const formatCashBasisRetainedEarningsLine = formatCurrentEarningsLine([
  'cashBasisBalance',
]);

// formatAccrualBasisRetainedEarningsLine :: ([Account], Account) -> AccountClosingLine
export const formatAccrualBasisRetainedEarningsLine = formatCurrentEarningsLine(
  ['balance'],
);

// findAccountByCode :: String -> [Account] -> Account
export const findAccountByCode = (code, accounts) =>
  R.find(R.propEq('code', code), accounts);

// calculateClosingAmount :: (Number, Number, Boolean) -> Number
const calculateClosingAmount = (debit, credit, isCreditNormal) => {
  let amount;
  if (isCreditNormal) {
    amount = credit - debit;
  } else {
    amount = debit - credit;
  }
  return isCreditNormal && amount !== 0 ? amount * -1 : amount;
};

// calculateClosingLineAmount :: AccountClosingLine -> Number
const calculateClosingLineAmount = R.converge(calculateClosingAmount, [
  R.propOr(0, 'debit'),
  R.propOr(0, 'credit'),
  R.prop('isCreditNormal'),
]);

// formatCashBasisLine :: AccountClosingLine -> CloseAccountBalanceLine
const formatCashBasisLine = R.applySpec({
  accountCode: R.path(['account', 'code']),
  account_id: R.path(['account', '_id']),
  cash_amount: calculateClosingLineAmount,
  is_retained_earnings_account: R.path([
    'account',
    'is_retained_earnings_account',
  ]),
});

// formatAccrualBasisLine :: AccountClosingLine -> CloseAccountBalanceLine
const formatAccrualBasisLine = R.applySpec({
  accountCode: R.path(['account', 'code']),
  account_id: R.path(['account', '_id']),
  accrual_amount: calculateClosingLineAmount,
  is_retained_earnings_account: R.path([
    'account',
    'is_retained_earnings_account',
  ]),
});

// isZeroOrNill :: Number -> Boolean
const isZeroOrNill = R.either(R.equals(0), R.isNil);

// formatClosePeriodClosingLines :: FormValues -> [CloseAccountBalanceLine]
export const formatClosePeriodClosingLines = ramdaUseWith(
  R.compose(
    R.map(R.omit(['accountCode', 'is_retained_earnings_account'])),
    R.reject(
      R.either(
        R.prop('is_retained_earnings_account'),
        R.both(
          R.propSatisfies(isZeroOrNill, 'cash_amount'),
          R.propSatisfies(isZeroOrNill, 'accrual_amount'),
        ),
      ),
    ),
    R.map(R.ifElse(R.propEq('length', 1), R.nth(0), R.apply(R.mergeRight))),
    R.values,
    R.groupBy(R.prop('accountCode')),
    R.concat,
  ),
  [R.map(formatCashBasisLine), R.map(formatAccrualBasisLine)],
);
