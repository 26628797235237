import React from 'react';
import { useOutSidebarContext } from 'poly-client-utils';
import { SidebarRow } from 'poly-admin-ui';

import { routesNames } from '../../../routes/index.js';
import { TabSectionWrapper } from './commonComponents.js';
import { UnderlinedLink } from '../../components/commonSidebarComponents.js';

export function ReportsTab() {
  const { closeAllOutSidebars } = useOutSidebarContext();

  return (
    <TabSectionWrapper>
      <SidebarRow>
        <UnderlinedLink
          onClick={closeAllOutSidebars}
          href={routesNames.HOUSEKEEPING_SERVICE_TICKETS_REPORT}
        >
          Housekeeping Service Tickets report
        </UnderlinedLink>
      </SidebarRow>
    </TabSectionWrapper>
  );
}
