import React from 'react';
import * as R from 'ramda';
import { Quill } from 'react-quill';
import styled from 'styled-components';
import { bool, func, string, object, oneOfType, shape } from 'prop-types';
import { useTrimValueByComponentProps } from 'poly-client-utils';

import { LabelS } from '../InputLabel/index.js';
import { ColumnDirectionS } from '../lib/index.js';
import { CustomToolbar } from './customToolbar.js';
import { ReactQuillS, readOnlyStyles } from './styles.js';
import { onDoneClick, onBiggerClick, onSmallerClick } from './editorUtils.js';
import { quillEmptyValue, adjustQuillDelta } from './helpers.js';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

// preparedPastedDelta :: {attributes: Object} -> {attributes: Object}
const preparedPastedDelta = R.when(
  R.compose(R.is(Object), R.prop('attributes')),
  R.over(R.lensProp('attributes'), R.pick(['bold', 'italic', 'list'])),
);

const pastedTextType = {
  PLAIN: 'text/plain',
  HTML: 'text/html',
};

// getClipboardDataTextType :: ClipboardEvent -> String
const getClipboardDataTextType = R.compose(
  R.unless(
    R.includes(R.__, R.values(pastedTextType)),
    R.always(pastedTextType.PLAIN),
  ),
  R.path(['clipboardData', 'items', 0, 'type']),
);

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const textType = getClipboardDataTextType(e);

    const pastedData = e.clipboardData.getData(textType);

    const retainDelta = new Delta().retain(range.index).delete(range.length);

    this.quill.clipboard.addMatcher('span', (node, delta) =>
      delta.compose(
        new Delta().retain(delta.length(), {
          bold: node.style.fontWeight === 'bold' || node.style.fontWeight > 500,
        }),
      ),
    );

    const pastedDelta =
      textType === pastedTextType.HTML
        ? new Delta(
            this.quill.clipboard.convert(pastedData).map(preparedPastedDelta),
          )
        : new Delta().insert(pastedData);

    const delta = retainDelta.concat(pastedDelta);

    this.quill.updateContents(delta, Quill.sources.USER);
    this.quill.setSelection(
      delta.length() - range.length,
      Quill.sources.SILENT,
    );
    this.quill.scrollIntoView();
  }
}

Quill.register(
  {
    'modules/clipboard': PlainClipboard,
  },
  true,
);

const Italic = Quill.import('formats/italic');
Italic.tagName = 'i';
Quill.register(Italic, true);

const formats = [
  'bold',
  'italic',
  'underline',
  'color',
  'list',
  'link',
  'size',
];

export function Editor(props) {
  const toolbarId = `${props.id || props.name}-toolbar`;
  const modules = {
    toolbar: {
      container: `#${toolbarId}`,
      handlers: {
        done: onDoneClick,
        bigger: onBiggerClick,
        smaller: onSmallerClick,
      },
    },
  };

  const { value, onChange } = useTrimValueByComponentProps({
    ...props,
    skipTrim: props.readOnly,
  });

  const isEditorActive = props.meta?.visited || props.meta?.active;

  return (
    <ColumnDirectionS width={props.width}>
      {props.label && <LabelS>{props.label}</LabelS>}
      {props.readOnly || <CustomToolbar id={toolbarId} />}
      <ReactQuillS
        {...props}
        theme="bubble"
        formats={formats}
        bounds={`.quill-${toolbarId}`}
        isEditorActive={isEditorActive}
        value={adjustQuillDelta(value)}
        onChange={(content, delta, source, editor) =>
          typeof onChange === 'function' &&
          isEditorActive &&
          onChange(editor.getContents())
        }
        className={`${props.className} quill-${toolbarId}`}
        modules={props.readOnly ? { toolbar: false } : modules}
        placeholder={props.readOnly ? 'No remarks yet' : props.placeholder}
      />
    </ColumnDirectionS>
  );
}

Editor.formats = formats;
Editor.propTypes = {
  id: string,
  name: string,
  onChange: func,
  value: oneOfType([object, string]),
  className: string,
  readOnly: bool,
  label: string,
  placeholder: string,
  meta: shape({ active: bool }),
  width: string,
};
Editor.defaultProps = {
  id: 'editor',
  label: null,
  className: '',
  onChange: null,
  readOnly: false,
  value: quillEmptyValue,
  placeholder: 'Enter Remarks',
};

const ReadOnlyEditorC = styled(Editor)`
  ${readOnlyStyles};
`;

export function ReadOnlyEditor(props) {
  return <ReadOnlyEditorC readOnly {...props} />;
}
