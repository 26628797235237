import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';

import styled from 'styled-components';
import { FlexColumn, FlexContainer, useNotificationState } from 'poly-admin-ui';
import { Button, getThemeColor, LinkButton } from 'poly-book-admin';
import { SectionTitleContainer } from './PropertyHierarchyTreeBase.js.js';
import { PropertyHierarchySearch } from './PropertyHierarchySearch.js';

import { PropertyHierarchyTree } from './PropertyHierarchyTree.js';
import { flattenTree } from '../utils.js';
import { usePropertyHierarchyTreeLogic } from './usePropertyHierarchyTreeLogic.js';
import { EditPropertyHierarchyButton } from '../EditPropertyHierarchyBtn.js';
import { treeNodePropTypes } from '../../../components/Tree/propTypes.js';

const HierarchyContainer = styled.div`
  display: flex;
  height: 500px;
  width: 100%;
  position: relative;
  height: 100%;
`;

const TreeSectionContainer = styled(FlexContainer)`
  flex-direction: column;
  flex-grow: 1;
  border-right: 1px solid ${getThemeColor(['midLight'])};
`;

const TreeContainer = styled(FlexColumn)`
  width: 100%;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
`;

const ButtonsContainer = styled(FlexContainer)`
  padding: 15px;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid ${getThemeColor(['midLight'])};
`;

const updatePropertyHierarchyMutation = gql`
  mutation updatePropertyHierarchyMutation(
    $id: ID!
    $input: UpdatePropertyHierarchyInput!
  ) {
    updatePropertyHierarchy(id: $id, input: $input) {
      _id
    }
  }
`;

function SaveTreeButton({
  hierarchyId,
  treeData,
  validateTree,
  refetch,
  expandTree,
}) {
  const { showSuccessNotification } = useNotificationState();
  const [updatePropertyHierarchy, { loading }] = useMutation(
    updatePropertyHierarchyMutation,
  );
  const onSave = async () => {
    if (validateTree()) {
      await updatePropertyHierarchy({
        variables: {
          id: hierarchyId,
          input: { nodes: flattenTree(treeData).filter((node) => !!node) },
        },
      });
      refetch();
      showSuccessNotification('Hierarchy was saved successfully');
    }
    expandTree();
  };
  return (
    <Button size="small" onClick={onSave} loader={loading}>
      Save
    </Button>
  );
}

SaveTreeButton.propTypes = {
  hierarchyId: string.isRequired,
  treeData: arrayOf(treeNodePropTypes),
  refetch: func.isRequired,
  validateTree: func.isRequired,
  expandTree: func.isRequired,
};

export function PropertyHierarchyTreeWithSearch({
  clientId,
  hierarchyObj,
  refetch,
}) {
  const {
    treeData,
    setTreeData,
    addNode,
    onChangeNodeTitleHandler,
    onAddNodeHandler,
    onRemoveNodeHandler,
    validationErrors,
    validateTree,
    treePropertiesIds,
    expandTree,
  } = usePropertyHierarchyTreeLogic(hierarchyObj);

  return (
    <HierarchyContainer>
      <TreeSectionContainer>
        <SectionTitleContainer>
          <FlexContainer>{hierarchyObj.name}</FlexContainer>
          <EditPropertyHierarchyButton
            hierarchyObj={hierarchyObj}
            onSuccess={refetch}
          />
        </SectionTitleContainer>
        <TreeContainer>
          <PropertyHierarchyTree
            treeData={treeData}
            setTreeData={setTreeData}
            validationErrors={validationErrors}
            onChangeNodeTitleHandler={onChangeNodeTitleHandler}
            onAddNodeHandler={onAddNodeHandler}
            onRemoveNodeHandler={onRemoveNodeHandler}
          />
        </TreeContainer>
        <ButtonsContainer>
          <LinkButton onClick={addNode}>Add New Node</LinkButton>
          <SaveTreeButton
            hierarchyId={hierarchyObj._id}
            treeData={treeData}
            refetch={refetch}
            validateTree={validateTree}
            expandTree={expandTree}
          />
        </ButtonsContainer>
      </TreeSectionContainer>
      <PropertyHierarchySearch
        clientId={clientId}
        treePropertiesIds={treePropertiesIds}
      />
    </HierarchyContainer>
  );
}

PropertyHierarchyTreeWithSearch.propTypes = {
  clientId: string.isRequired,
  hierarchyObj: shape({
    name: string.isRequired,
  }),
  refetch: func.isRequired,
};

PropertyHierarchyTreeWithSearch.defaultProps = {
  hierarchyObj: {},
};
