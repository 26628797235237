import { MAX_ITEMS } from 'poly-admin-ui';
import { useReactiveQuery } from 'poly-client-utils';

import { INVOICES_BY_SEARCH } from '../../../modules/core/hooks/invoices/queries.js';
import { INVOICES_BY_SEARCH_SUB } from '../../../modules/core/hooks/invoices/subscriptions.js';

export const useSearchProjectInvoices = (projectId, searchTerm = '') => {
  const queryOptions = {
    variables: {
      searchInput: {
        searchTerm,
        size: MAX_ITEMS,
        query: {
          bool: {
            must: [{ match: { documentId: projectId } }],
          },
        },
      },
    },
    skip: !projectId,
    fetchPolicy: 'network-only',
  };

  const { data, loading } = useReactiveQuery(
    INVOICES_BY_SEARCH,
    INVOICES_BY_SEARCH_SUB,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  return {
    data,
    loading,
  };
};
