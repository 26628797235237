import * as R from 'ramda';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { bool } from 'prop-types';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { Loader, getThemeColor } from 'poly-book-admin';

import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { AgingReportTableCard, AgingReportTableS } from './styledComponents.js';
import { WhiteBodyWrapper } from '../PaySuppliersPage/PaySuppliersComponents.js';
import {
  APAgingReportFooterContainer,
  prepareInvoicesForTable,
  APAgingReportTableComp,
  getTotalByDaysPart,
  sumAndFormatCentsToDollars,
} from './APAgingReportTableComp.js';
import { invoicesPropTypes } from './prop-types.js';

const APAgingReportFooterContainerS = styled(APAgingReportFooterContainer)`
  background-color: ${getThemeColor(['white'])} !important;
`;

// getAPAgingReportTotalByMapper :: [APAgingReportSupplier] -> Function -> String
const getAPAgingReportTotalByMapper = (mapper) =>
  R.compose(
    sumAndFormatCentsToDollars,
    R.map(mapper),
    R.flatten,
    R.map(
      R.compose(
        prepareInvoicesForTable({ name: '' }),
        R.propOr([], 'invoices'),
      ),
    ),
  );

function TotalFooter({ rows }) {
  return (
    <APAgingReportFooterContainerS>
      <td>Total</td>
      <td>{getAPAgingReportTotalByMapper(getTotalByDaysPart(-1, 30))(rows)}</td>
      <td>{getAPAgingReportTotalByMapper(getTotalByDaysPart(30, 60))(rows)}</td>
      <td>{getAPAgingReportTotalByMapper(getTotalByDaysPart(60, 90))(rows)}</td>
      <td>
        {getAPAgingReportTotalByMapper(getTotalByDaysPart(90, Infinity))(rows)}
      </td>
      <td>{getAPAgingReportTotalByMapper(R.prop('total'))(rows)}</td>
      <td />
    </APAgingReportFooterContainerS>
  );
}

TotalFooter.propTypes = {
  rows: invoicesPropTypes,
};

const apAgingReportTableConfig = [['', APAgingReportTableComp]];

export function APAgingReportTable({ report, isPrint, ...restTableProps }) {
  const tableProps = useMapConfigToTableProps(
    R.map(R.assoc('isPrintPDF', isPrint)),
    apAgingReportTableConfig,
    report,
  );
  return (
    <AgingReportTableS
      {...tableProps}
      {...restTableProps}
      TableFooter={TotalFooter}
      moreScrollIconPosition="38px"
      showScrollBar
    />
  );
}

APAgingReportTable.propTypes = {
  report: invoicesPropTypes.isRequired,
  isPrint: bool,
};

export function APAgingReportTableContainer({
  report,
  loading,
  ...restTableProps
}) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  return loading ? (
    <Loader />
  ) : (
    <WhiteBodyWrapper height={contentHeight}>
      <AgingReportTableCard height="100%">
        {R.isEmpty(report) ? (
          <EmptyListMessage />
        ) : (
          <APAgingReportTable report={report} {...restTableProps} />
        )}
      </AgingReportTableCard>
    </WhiteBodyWrapper>
  );
}

APAgingReportTableContainer.propTypes = {
  loading: bool.isRequired,
  report: invoicesPropTypes.isRequired,
};
