import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { func, shape } from 'prop-types';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { pathOrNothingUI } from 'poly-client-utils';
import { Table } from 'poly-book-admin';
import { formatTimesheetDuration } from 'poly-utils';

import { projectPropTypes } from './projectTimePropTypes.js';
import {
  DeleteProjectTimeBtn,
  EditProjectTimeBtn,
} from './ProjectTimeButtons.js';
import { formatDateCurried } from '../../../../modules/tables/timesheetsTable/utils.js';

const clientStaffRate = {
  RATE: 'rate',
  OVERTIME: 'overtime',
  DOUBLE_TIME: 'doubleTime',
};

const clientStaffRateTypeMap = {
  [clientStaffRate.RATE]: 'Straight Time',
  [clientStaffRate.OVERTIME]: 'Over Time',
  [clientStaffRate.DOUBLE_TIME]: 'Double Time',
};

const TableS = styled(Table)`
  td:nth-child(1),
  th:nth-child(1) {
    width: 85px;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 60px;
  }

  td:nth-child(4),
  th:nth-child(4) {
    width: 100px;
  }

  td:nth-child(7),
  th:nth-child(7),
  td:nth-child(8),
  th:nth-child(8) {
    width: 20px;
  }

  thead,
  tbody {
    overflow-y: visible;
    overflow-x: visible;
  }

  tr {
    margin: 0;
    width: 100%;
  }
  font-size: 12px;
`;

// getProjectTimeEntriesRows :: TimeEntries -> TimeEntries
const getProjectTimeEntriesRows = R.compose(
  R.reverse,
  R.sortBy(R.prop('date')),
);

export function ProjectTimeEntriesList({ project, setEditTimeProps }) {
  const timeEntries = R.propOr([], 'timeEntries', project);

  const tableConfig = [
    ['Date', R.compose(formatDateCurried('MM/dd/yyyy'), R.prop('date'))],
    ['User', R.path(['owner', 'fullName'])],
    ['Hours', R.compose(formatTimesheetDuration, R.prop('totalTimeInMinutes'))],
    ['Type', R.compose(R.prop(R.__, clientStaffRateTypeMap), R.prop('type'))],
    ['Description', pathOrNothingUI(['rateDescription'])],
    ['Notes', pathOrNothingUI(['notes'])],
    [
      '',
      (props) => (
        <EditProjectTimeBtn
          project={project}
          setEditTimeProps={setEditTimeProps}
          {...props}
        />
      ),
    ],
    [
      '',
      (props) => (
        <DeleteProjectTimeBtn
          projectId={project._id}
          {...props}
          isSidebar={!!setEditTimeProps}
        />
      ),
    ],
  ];

  const tableProps = useMapConfigToTableProps(
    getProjectTimeEntriesRows,
    tableConfig,
    timeEntries,
  );

  return timeEntries.length ? <TableS {...tableProps} /> : null;
}

ProjectTimeEntriesList.propTypes = {
  project: shape(projectPropTypes).isRequired,
  setEditTimeProps: func,
};
