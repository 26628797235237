import * as R from 'ramda';
import React from 'react';
import { string, bool, shape, func, arrayOf } from 'prop-types';
import { Loader } from 'poly-book-admin';
import { TaskFormWrapper } from './form/index.js';
import { confirmCancelFormPayload } from '../../../components/common.js';
import { getTaskUpdateFormInitValues } from './form/taskFormUtils.js';
import { useModalContext } from '../../../components/Modal/ModalProvider.js';
import {
  getProjectEntityByCollection,
  prepareDetailsString,
} from '../../../utils/tasks.js';
import { useProcessState } from '../../../hooks/useProcessState.js';
import { useTaskUpdatesById } from '../../../hooks/tasks/index.js';
import { useProjectTaskInfo, useUpdateTaskHelpers } from './form/hooks.js';
import { WarningComponent } from './complete/index.js';

export function EditTaskForm(props) {
  const {
    formId,
    payload: { _id, onCancel: onCancelFromPayload, collection },
    onCancel: onCancelFromProps,
    isHQProject,
    customTasksFields,
  } = props;

  const modalContext = useModalContext();

  const { process } = useProcessState(formId);

  const { task, loading } = useTaskUpdatesById(_id);

  const { updateTaskMutation, isDefaultTask } = useUpdateTaskHelpers({
    task,
    customTasksFields,
  });

  const { data } = useProjectTaskInfo({
    collection,
    task,
  });

  const onCancel = () => {
    modalContext.closeModal(formId);

    const onCancelHandler = onCancelFromPayload || onCancelFromProps;

    if (onCancelHandler) {
      onCancelHandler();
    }
  };

  const openConfirmModal = () =>
    modalContext.openConfirmModal({
      ...confirmCancelFormPayload,
      onConfirm: (closeConfirmModal) => R.compose(closeConfirmModal, onCancel),
    });

  const projectId = R.path(
    ['document', getProjectEntityByCollection(collection), 'projectId'],
    task,
  );

  const projectDbId = R.path(
    ['document', getProjectEntityByCollection(collection), '_id'],
    task,
  );

  const mutate = (input) => updateTaskMutation(input);

  const initialValues = {
    ...getTaskUpdateFormInitValues({
      ...task,
      project: data?.project,
      collection,
      isEdit: true,
      isDefaultTask,
    }),
    isHQProject,
  };

  const additionalProps = isDefaultTask
    ? {
        title: R.prop('description', task),
        entityInfo: R.pipe(R.prop('document'), prepareDetailsString)(task),
      }
    : {};

  if (!_id) return null;
  if (loading) return <Loader />;

  if (!task && !loading) {
    return <WarningComponent onCancel={onCancel} />;
  }

  return (
    <TaskFormWrapper
      {...props}
      {...additionalProps}
      task={task}
      submitCaption="Update"
      updates={task?.documentUpdates}
      onCancel={onCancel}
      openConfirmModal={openConfirmModal}
      projectId={projectId}
      projectDbId={projectDbId}
      mutate={mutate}
      initialValues={initialValues}
      actionInProgress={process}
    />
  );
}

EditTaskForm.displayName = 'EditTaskForm';

EditTaskForm.propTypes = {
  formId: string.isRequired,
  isTaskUpdateMessageOptional: bool,
  payload: shape({
    _id: string,
    onCancel: func,
    collection: string,
  }),
  onCancel: func,
  isHQProject: bool,
  customTasksFields: arrayOf(string),
};
