import styled from 'styled-components';
import { Table } from 'poly-book-admin';

import {
  dateColumnStyles,
  descriptionColumnStyles,
  documentIdColumnStyles,
  pipColumnStyles,
} from '../tablesStyles.js';

export const ProjectsPageProjectsTableComp = styled(Table)`
  ${pipColumnStyles(1)};

  th:nth-child(${2}),
  td:nth-child(${2}) {
    width: 90px;
  }

  ${documentIdColumnStyles(3)};
  ${descriptionColumnStyles(4)};
  ${({ skipPropertyColumn }) => dateColumnStyles(skipPropertyColumn ? 7 : 8)};
  ${dateColumnStyles(8)};
  ${({ skipPropertyColumn }) => dateColumnStyles(skipPropertyColumn ? 8 : 10)};
`;

export const RecurringProjectsPageProjectsTableComp = styled(Table)`
  ${pipColumnStyles(1)};
  ${documentIdColumnStyles(2)};
  ${descriptionColumnStyles(3)};
  ${({ skipPropertyColumn }) => dateColumnStyles(skipPropertyColumn ? 6 : 7)};
  ${dateColumnStyles(7)};
  ${({ skipPropertyColumn }) => dateColumnStyles(skipPropertyColumn ? 7 : 9)};
`;
