import * as R from 'ramda';
import { paginationToQueryParams } from 'poly-client-utils';
import { getProjectTasksInput, ENTITY_TASKS } from 'poly-admin-ui';

// updateTaskById :: String -> (Task -> Task) -> [Task] -> [Task]
// Task = Object
const updateTaskById = R.curry((taskId, update, tasks) =>
  R.converge(R.over(R.__, update, R.__), [
    R.compose(R.lensIndex, R.findIndex(R.propEq('_id', taskId))),
    R.identity,
  ])(tasks),
);

// completeTaskByPath :: ([String], String) -> Object -> Object
const completeTaskByPath = (path, taskId) =>
  R.over(
    R.lensPath(path),
    updateTaskById(taskId, R.mergeLeft({ complete: true })),
  );

const updateCacheHandler = (query, variables, update) => (store) => {
  const data = store.readQuery({
    query,
    variables,
  });

  store.writeQuery({
    query,
    data: update(data),
  });
};

export const projectTaskCompleteCache = (projectId, taskId) =>
  R.always(
    updateCacheHandler(
      ENTITY_TASKS,
      {
        input: {
          sort: { createdAt: 1 },
          ...getProjectTasksInput(projectId),
          ...paginationToQueryParams({ itemsPerPage: 10000, currentPage: 1 }),
        },
      },
      completeTaskByPath(['tasks', 'hits'], taskId),
    ),
  );
