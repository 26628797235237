import React from 'react';
import { useApolloClient } from '@apollo/client';
import { CompleteTaskForm as CommonCompleteTaskForm } from 'poly-admin-ui';

import { completeTaskFormId } from './constants.js';

export function CompleteTaskForm(props) {
  const apolloClient = useApolloClient();

  return (
    <CommonCompleteTaskForm
      {...props}
      formId={completeTaskFormId}
      apolloClient={apolloClient}
    />
  );
}
