import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string } from 'prop-types';
import { openPrintWindowWithData } from 'poly-client-utils';
import { CommonPrintLayout, useNotificationState } from 'poly-admin-ui';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import { LinkButton } from 'poly-book-admin';

import { APAgingReportTable } from './APAgingReportTable.js';
import { commonAgingReportPrintTableStyles } from './styledComponents.js';
import { invoicesPropTypes } from './prop-types.js';

const APAgingReportPrintTable = styled(APAgingReportTable)`
  ${commonAgingReportPrintTableStyles};

  th,
  td {
    width: 100px !important;

    :nth-child(1),
    :nth-child(2),
    :nth-child(3) {
      width: auto !important;
    }

    :nth-child(5) {
      width: 40px !important;
    }
  }

  tfoot {
    td {
      :nth-child(1) {
        width: auto !important;
      }

      :nth-child(2),
      :nth-child(3),
      :nth-child(5) {
        width: 100px !important;
      }
      :last-child {
        width: 195px !important;
      }
    }
  }
`;

export function APAgingReportPrintBtn({ report, title }) {
  const { showErrorNotification } = useNotificationState();

  const onClick = async () => {
    if (R.isEmpty(report)) {
      showErrorNotification('No report records to print');
      return;
    }

    await openPrintWindowWithData({
      fileName: 'ap_aging_report',
      Layout: CommonPrintLayout,
      Table: APAgingReportPrintTable,
      metaData: { title },
      isPrint: true,
      report,
    });
  };
  return <LinkButton onClick={onClick}>{PRINT_PDF_CAPTION}</LinkButton>;
}

APAgingReportPrintBtn.propTypes = {
  title: string.isRequired,
  report: invoicesPropTypes.isRequired,
};
