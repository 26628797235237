import * as R from 'ramda';
import React from 'react';
import { halfWidth } from 'poly-admin-ui/src/modules/forms/common.js';
import {
  getAdminUsersByEmploymentStatusQuery,
  UserSelect,
} from 'poly-admin-ui';
import { SystemAccountTypes, UserStatuses } from 'poly-constants';

import { AccountsSelect } from '../../components/AccountsSelect.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { CardNumberInputS, CCUserStatusDropDown } from './components.js';

const prepareToValidation = R.replace(/[\\~#%&*{}/:<>?|"-]/g, '');

// validateCardNumber :: String -> Boolean
const validateCardNumber = R.compose(
  R.propEq('length', 4),
  prepareToValidation,
  R.defaultTo(''),
);

export const ccUsersFormCreatorSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'User',
        order: 1,
        layout: { row: 1, width: halfWidth },
        required: true,
        field: {
          name: 'userId',
          Component: (props) => (
            <UserSelect
              {...props}
              withoutSkip
              query={getAdminUsersByEmploymentStatusQuery(UserStatuses.ACTIVE)}
            />
          ),
        },
      },
      {
        label: 'Credit Card Account',
        order: 2,
        layout: { row: 1, width: halfWidth },
        required: true,
        field: {
          name: 'accountId',
          Component: (props) => (
            <AccountsSelect
              {...props}
              allowModalOverflow
              filterAccounts={filterAccountsBySystemType(
                SystemAccountTypes.CREDIT_CARD,
              )}
              extractValue={R.prop('_id')}
            />
          ),
        },
      },
      {
        label: 'Card Number',
        order: 3,
        layout: { row: 2, width: halfWidth },
        required: true,
        field: {
          name: 'cardNumber',
          Component: CardNumberInputS,
        },
        validators: [[validateCardNumber, 'Please provide 4 digits']],
      },
      {
        label: 'Status',
        order: 6,
        layout: { row: 2, width: halfWidth },
        required: true,
        field: {
          name: 'status',
          Component: CCUserStatusDropDown,
        },
      },
    ],
  },
];
