import React from 'react';
import * as R from 'ramda';
import { FormField, Input } from 'poly-book-admin';
import { bool, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import {
  AddressSection,
  BaseServiceTypesMultiSelectField,
  InternationalPhoneInput,
  MoneyInput,
  PhoneNumberInput,
  SocialSecurityInput,
  SupplierFormCheckbox,
  TaxClassSelect,
  TaxIDInput,
  isSoleProprietorship,
  EthnicitySelect,
} from 'poly-admin-ui';
import {
  MBECertificationUrl,
  WBECertificationUrl,
  supplierEmailsTypes,
  veteranCertificationUrl,
} from 'poly-constants';
import {
  addressValidators,
  ifNotEmpty,
  validateEmail,
  validateInternationalPhoneNumber,
  validatePhone,
} from 'poly-client-utils';
import { isNilOrEmpty, validateURL } from 'poly-utils';
import {
  RemitAddressInput,
  RemitNameInput,
} from 'poly-admin-ui/src/modules/forms/supplierForm/form/sections/supplierRemitFormSection.js';
import { MultiEmailsInput } from 'poly-admin-ui/src/components/MultiEmailsInput.js';

import {
  DocumentsFilePicker,
  ForSectionTitle,
  ForSectionWrapper,
  FormFieldWrapper,
  Label,
  TwoColumnWrapper,
  YesNoSelectWithPopover,
} from './components.js';

const { ACCOUNT, SERVICE, SECONDARY_SERVICE } = supplierEmailsTypes;

const TwoColumnWrapperS = styled(TwoColumnWrapper)`
  margin-bottom: 16px;
`;

function FormFieldComp({ label, name, ...formFieldProps }) {
  return (
    <FormFieldWrapper>
      <Label htmlFor={name}>{label}</Label>
      <FormField labelId={name} name={name} {...formFieldProps} />
    </FormFieldWrapper>
  );
}

FormFieldComp.propTypes = {
  label: string,
  name: string,
};

const MoneyInputS = styled(MoneyInput)`
  input {
    width: 100%;
  }
`;

const FormFieldWrapperS = styled.div`
  margin-bottom: 15px;
`;

const EthnicitySelectS = styled(EthnicitySelect)`
  width: 140px;
  margin-top: 8px;
`;

const FlexWrapper = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  display: flex;
  gap: 10px;
`;

const SupplierFormCheckboxS = styled(SupplierFormCheckbox)`
  margin-left: 15px;
`;

// getValidateFunc :: (Any -> Boolean, String) -> Any -> String
const getValidateFunc = (fn, errorMsg) =>
  R.ifElse(ifNotEmpty(fn), R.always(''), R.always(errorMsg));

// phoneValidate :: String -> String
const phoneValidate = getValidateFunc(validatePhone, 'Incorrect phone number');

// emailValidate :: String -> String
const emailValidate = getValidateFunc(validateEmail, 'Incorrect email');

// validateInternationalPhone :: String -> String
const validateInternationalPhone = getValidateFunc(
  validateInternationalPhoneNumber,
  'Incorrect mobile phone number',
);

// validateWebsite :: String -> String
const validateWebsite = getValidateFunc(
  validateURL,
  'URL should be in form http(s)://example.com',
);

// validateCompanyName :: String -> String
const validateCompanyName = R.ifElse(
  R.when(R.is(String), R.trim),
  R.always(''),
  R.always('Company Name is Required'),
);

// validateSupplierAddress :: String -> String
const validateSupplierAddress = R.compose(
  R.ifElse(isNilOrEmpty, R.always(''), R.head),
  R.reject(isNilOrEmpty),
  R.juxt(
    R.map(R.converge(getValidateFunc, [R.head, R.last]), addressValidators),
  ),
);

export function WBECertificateFilePiker(props) {
  return (
    <DocumentsFilePicker
      {...props}
      label="Attach WBE Certification"
      name="wbeCertificationFile"
      documentType="WBE"
    />
  );
}

export function MBECertificateFilePiker(props) {
  return (
    <DocumentsFilePicker
      {...props}
      label="Attach MBE Certification"
      name="mbeCertificationFile"
      documentType="MBE"
    />
  );
}

export function VeteranCertificateFilePiker(props) {
  return (
    <DocumentsFilePicker
      {...props}
      label="Attach Veteran Certification"
      name="veteranCertificationFile"
      documentType="Veteran SBA"
    />
  );
}

function CompanyNameInput({ changeFieldValue, onChange, ...props }) {
  const onChangeHandler = (e) => {
    onChange(e);
    // to update company name at Subcontractor Agreement as well
    changeFieldValue('subcontractorAgreement.companyName', e.target.value);
  };

  return <Input {...props} onChange={onChangeHandler} />;
}

CompanyNameInput.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};

export function DocumentRequestOnboardingFormSection({
  form,
  values,
  isReview,
}) {
  const isAfterHours = values?.service_24_7;

  const isW9Show = !isReview && !values?.tax?.exempt;

  const SocialSecurityComp = (
    <FormFieldComp
      label="Social Security #"
      name="tax.socialSecurityNumber"
      Component={SocialSecurityInput}
      additionalProps={{
        formData: values,
      }}
    />
  );

  return (
    <>
      <ForSectionWrapper>
        <ForSectionTitle>General Information</ForSectionTitle>
        <TwoColumnWrapperS>
          <FormFieldComp
            label="Company Name"
            name="company.name"
            additionalProps={{
              required: true,
              changeFieldValue: form.change,
              skipSupplierId: values?._id,
            }}
            Component={CompanyNameInput}
            validate={validateCompanyName}
          />
          <FormFieldComp label="DBA" name="company.dba" Component={Input} />
        </TwoColumnWrapperS>
        {isReview && (
          <FormFieldWrapperS>
            <FormField
              name="company.address"
              Component={AddressSection}
              additionalProps={{
                required: true,
              }}
              validate={validateSupplierAddress}
            />
          </FormFieldWrapperS>
        )}
        <TwoColumnWrapperS>
          <FormFieldComp
            label="Phone Number"
            name="company.phone"
            Component={PhoneNumberInput}
            validate={phoneValidate}
          />
          <FormFieldComp
            label="Service SMS Number"
            name="company.mobile"
            Component={InternationalPhoneInput}
            validate={validateInternationalPhone}
          />
        </TwoColumnWrapperS>
        <TwoColumnWrapperS>
          <FormFieldComp
            label="Fax Number"
            name="company.fax"
            Component={PhoneNumberInput}
            validate={phoneValidate}
          />
          <FormFieldComp
            label="Website"
            name="company.website"
            Component={Input}
            validate={validateWebsite}
          />
        </TwoColumnWrapperS>
        <TwoColumnWrapperS>
          <FormFieldComp
            label="Primary Service Email"
            name={`company.emails.${SERVICE}`}
            Component={Input}
            validate={emailValidate}
          />
          <FormFieldComp
            label="Secondary Service Emails"
            name={`company.emails.${SECONDARY_SERVICE}`}
            Component={MultiEmailsInput}
          />
        </TwoColumnWrapperS>
        <TwoColumnWrapperS>
          <FormFieldComp
            label="Accounting Emails"
            name={`company.emails.${ACCOUNT}`}
            Component={MultiEmailsInput}
          />
        </TwoColumnWrapperS>
        <TwoColumnWrapperS>
          <FormFieldComp
            label="Do you offer 24/7 Service?"
            name="service_24_7"
            Component={SupplierFormCheckboxS}
          />
          {isAfterHours && (
            <FormFieldComp
              label="After Hours Phone Number"
              name="company.afterHoursPhone"
              Component={PhoneNumberInput}
              validate={phoneValidate}
            />
          )}
        </TwoColumnWrapperS>
        <FormField
          name="company.servicesIds"
          Component={BaseServiceTypesMultiSelectField}
          additionalProps={{
            formData: values,
            label: 'Services Offered',
          }}
        />
      </ForSectionWrapper>
      <ForSectionWrapper>
        <TwoColumnWrapperS>
          <FlexWrapper fullWidth={isReview}>
            <YesNoSelectWithPopover
              name="mwbe.isMBE"
              label="Are you a MBE?"
              documentType="MBE"
              certificationUrl={MBECertificationUrl}
            />
            {values?.mwbe?.isMBE && (
              <FormFieldComp
                label="Which Ethnicity?"
                name="mwbe.minority"
                Component={EthnicitySelectS}
              />
            )}
          </FlexWrapper>
          {values?.mwbe?.isMBE && !isReview && <MBECertificateFilePiker />}
        </TwoColumnWrapperS>
        <TwoColumnWrapperS>
          <YesNoSelectWithPopover
            name="mwbe.isWBE"
            label="Are you a WBE?"
            documentType="WBE"
            certificationUrl={WBECertificationUrl}
          />
          {values?.mwbe?.isWBE && !isReview && <WBECertificateFilePiker />}
        </TwoColumnWrapperS>
        <TwoColumnWrapperS>
          <YesNoSelectWithPopover
            name="isVeteran"
            label="Are you a Veteran?"
            documentType="Veteran SBA"
            certificationUrl={veteranCertificationUrl}
          />
          {values?.isVeteran && !isReview && <VeteranCertificateFilePiker />}
        </TwoColumnWrapperS>
      </ForSectionWrapper>
      <ForSectionWrapper>
        <ForSectionTitle>Service Rates</ForSectionTitle>
        <TwoColumnWrapperS>
          <FormFieldComp
            label="Hourly"
            name="rates.hourly"
            Component={MoneyInputS}
          />
          <FormFieldComp
            label="After Hours"
            name="rates.overTime"
            Component={MoneyInputS}
          />
          <FormFieldComp
            label="Weekend"
            name="rates.weekend"
            Component={MoneyInputS}
          />
          <FormFieldComp
            label="Trip Charge"
            name="rates.trip"
            Component={MoneyInputS}
          />
        </TwoColumnWrapperS>
      </ForSectionWrapper>
      <ForSectionWrapper>
        <ForSectionTitle>Tax Information</ForSectionTitle>
        <TwoColumnWrapperS>
          <FormFieldComp
            label="Company Type"
            name="tax.classification"
            Component={TaxClassSelect}
            additionalProps={{
              changeFieldValue: form.change,
            }}
          />
          <FormFieldComp label="Tax Name" name="tax.name" Component={Input} />
        </TwoColumnWrapperS>
        <TwoColumnWrapperS>
          <FormFieldComp
            label="Tax ID"
            name="tax.id"
            Component={TaxIDInput}
            additionalProps={{
              formData: values,
            }}
          />
          {isW9Show && (
            <DocumentsFilePicker label="Attach W9" name="tax.w9File" />
          )}
          {isSoleProprietorship(values) && isReview && SocialSecurityComp}
        </TwoColumnWrapperS>
        {isSoleProprietorship(values) && !isReview && SocialSecurityComp}
        {isReview && (
          <>
            <TwoColumnWrapper>
              <FormFieldComp
                label="Is 1099"
                name="tax.is1099"
                Component={SupplierFormCheckboxS}
              />
            </TwoColumnWrapper>
            <ForSectionWrapper>
              <ForSectionTitle>Remit To Info</ForSectionTitle>
              <FormFieldComp
                label="Remit To Name"
                name="remit.name"
                Component={RemitNameInput}
              />
              <FormFieldWrapperS>
                <FormField
                  name="remit.address"
                  additionalProps={{
                    formData: values,
                  }}
                  Component={RemitAddressInput}
                  validate={validateSupplierAddress}
                />
              </FormFieldWrapperS>
            </ForSectionWrapper>
          </>
        )}
      </ForSectionWrapper>
    </>
  );
}

DocumentRequestOnboardingFormSection.propTypes = {
  form: shape({
    change: func.isRequired,
  }),
  values: shape({
    type: string.isRequired,
  }),
  isReview: bool,
};
