import React from 'react';
import * as R from 'ramda';
import { object } from 'prop-types';
import { Select, Input } from 'poly-book-admin';
import { UserStatuses } from 'poly-constants';
import { validateEmail } from 'poly-utils';
import { ADMIN_APP_NAME, TIAA_PORTAL_APP_NAME } from 'poly-security';
import {
  formatSelectOptionsByConstantsValue,
  ifNotEmpty,
} from 'poly-client-utils';

import { InternationalPhoneInput } from '../../../../components/InternationalAddressAndPhone/InternationalPhone/InternationalPhoneInput.js';
import { UserGroupSelect } from '../../../../components/UserGroupSelect.js';
import {
  Disable2FA,
  Off2FAStatus,
} from '../../../../components/Disabled2FA.js';
import { halfWidth } from '../../../forms/common.js';

function TwoFactorAuthentications({ formData }) {
  const { userId, twoFactorAuth } = formData;

  const is2FAVerified = R.propOr(false, 'isVerified', twoFactorAuth);

  return is2FAVerified ? (
    <Disable2FA userId={userId} twoFASettings={twoFactorAuth} />
  ) : (
    <Off2FAStatus />
  );
}

TwoFactorAuthentications.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  formData: object,
};

export const getSystemAccessSection = (column, hasPermissionToLoginViaSMS) => [
  {
    label: 'System Access',
    order: 4,
    layout: { column },
    fields: [
      {
        label: 'Email',
        order: 1,
        field: {
          name: 'systemAccess.email',
          Component: Input,
        },
        layout: {
          row: 1,
          width: halfWidth,
        },
        required: true,
        validators: [
          [R.identity, 'Email is required'],
          [ifNotEmpty(validateEmail), 'Incorrect email'],
        ],
      },
      {
        label: 'User Group',
        order: 2,
        field: {
          name: 'systemAccess.userGroupId',
          Component: UserGroupSelect,
          additionalProps: {
            filterUserGroups: R.filter(
              R.propSatisfies(
                R.includes(R.__, [ADMIN_APP_NAME, TIAA_PORTAL_APP_NAME]),
                'defaultLoginApp',
              ),
            ),
          },
        },
        layout: {
          row: 1,
          width: halfWidth,
        },
        required: true,
        validators: [[R.identity, 'User Group is required']],
      },
      {
        label: 'Status',
        order: 3,
        field: {
          name: 'systemAccess.status',
          Component: (props) => (
            <Select
              {...props}
              options={formatSelectOptionsByConstantsValue(UserStatuses)}
            />
          ),
        },
        layout: {
          row: 2,
          width: halfWidth,
        },
        required: true,
      },
      {
        label: '2 Factor Authentications',
        order: 4,
        field: {
          name: 'twoFactorAuth',
          withFormData: true,
          Component: TwoFactorAuthentications,
        },
        layout: {
          row: 2,
          width: halfWidth,
        },
        renderIf: R.prop('userId'),
      },
      {
        label: 'Login Cell Phone Number',
        order: 5,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'loginCellPhoneNumber',
          Component: InternationalPhoneInput,
        },
        renderIf: R.always(hasPermissionToLoginViaSMS),
      },
    ],
  },
];
