import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { bool, node, shape, string, number } from 'prop-types';
import { Checkbox, getThemeColor, Text, Popover } from 'poly-book-admin';
import { useLocation, useUpdateHash } from 'poly-client-routing';
import { pathOrNothingUI } from 'poly-client-utils';
import {
  InvoicesStatuses,
  taskActionTypes,
  collectionNames,
} from 'poly-constants';

import { FlexContainer } from '../../../components/common.js';
import { CompletedTaskPopoverContent } from './CompletedTaskPopoverContent.js';
import { useCompletedTaskUpdatesPreview } from './useCompletededTaskUpdatesPreview.js';
import { useSidebarTasksTableHandlers } from './useSidebarTasksTableHandlers.js';

const PopoverSectionWrapper = styled.div`
  width: 100%;
  padding: 5px 15px;
  border-bottom: 1px solid ${getThemeColor(['primaryLighter7'])};
`;

const TextS = styled(Text)`
  margin-bottom: 5px;
`;

const PopoverS = styled(Popover)`
  width: 100%;

  > div {
    width: calc(100% - 10px);
    margin-left: 10px;
  }

  button {
    text-align: left;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: ${({ complete, ...props }) =>
      complete
        ? getThemeColor(['midDark'])(props)
        : getThemeColor(['primaryLight'])(props)};

    :hover {
      ${({ complete, ...props }) =>
        complete && `color: ${getThemeColor(['midDark'])(props)}`};
    }
  }
`;

const OwnSupplierInvoiceNotePopover = styled(PopoverS)`
  p {
    color: ${getThemeColor(['midDark'])};
  }
  cursor: not-allowed;
`;

const CompletedTaskPopoverS = styled(PopoverS)`
  cursor: pointer;

  button:hover,
  button:focus {
    ${({ complete, ...props }) =>
      complete && `color: ${getThemeColor(['midDark'])(props)}`};
  }

  > div > div {
    left: -25px;

    > div {
      max-height: 200px;
      overflow-y: scroll;
    }

    ::after {
      left: ${({ position }) => (position === 'top' ? 22 : 30)}px;
    }
  }
`;

const OwnSupplierInvoiceNotePopoverWrapper = styled.div`
  width: 100%;
  padding: 10px 15px;
`;

const OwnSupplierInvoiceNoteText = styled(Text)`
  color: ${getThemeColor(['notificator', 'warning', 'text'])};
`;

const CheckboxS = styled(Checkbox)`
  flex-shrink: 0;
`;

const Description = styled(Text)`
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
  color: ${({ complete, ...props }) =>
    complete
      ? getThemeColor(['midDark'])(props)
      : getThemeColor(['primaryLight'])(props)};
  cursor: ${({ complete }) => (complete ? 'default' : 'pointer')};
`;

// isTaskHashed :: ID -> String -> Boolean
const isTaskHashed = (id) =>
  R.ifElse(
    R.either(R.isNil, R.isEmpty),
    R.F,
    R.compose(R.equals(id), R.last, R.split('_')),
  );

export function MultiSupplierInvoiceNote({ description, complete, ...props }) {
  return (
    <PopoverS
      title={description}
      complete={complete}
      maxWidth="250px"
      position="bottom"
      content={
        <>
          <PopoverSectionWrapper>
            <TextS size="12px" weight="semibold">
              Supplier:
            </TextS>
            <TextS size="12px">
              {pathOrNothingUI(['supplier', 'company', 'name'], props)}
            </TextS>
          </PopoverSectionWrapper>
          <PopoverSectionWrapper>
            <TextS size="12px" weight="semibold">
              Multi Invoice Note:
            </TextS>
            <TextS size="12px">
              {pathOrNothingUI(['multiInvoiceNote'], props)}
            </TextS>
          </PopoverSectionWrapper>
        </>
      }
    />
  );
}

MultiSupplierInvoiceNote.propTypes = {
  description: node.isRequired,
  supplier: shape({
    company: shape({
      name: string.isRequired,
    }),
  }).isRequired,
  complete: bool,
};

MultiSupplierInvoiceNote.defaultProps = {
  complete: false,
};

function OwnSupplierInvoiceNote({ description }) {
  return (
    <OwnSupplierInvoiceNotePopover
      isClickable={false}
      title={description}
      maxWidth="350px"
      position="bottom"
      content={
        <OwnSupplierInvoiceNotePopoverWrapper>
          <OwnSupplierInvoiceNoteText size="12px">
            You cannot approve an invoice you entered.
          </OwnSupplierInvoiceNoteText>
        </OwnSupplierInvoiceNotePopoverWrapper>
      }
    />
  );
}

OwnSupplierInvoiceNote.propTypes = {
  description: node.isRequired,
};

function CompletedTaskDescription({ taskId, description, rowIndex }) {
  const position = rowIndex <= 5 ? 'bottom' : 'top';

  return (
    <CompletedTaskPopoverS
      complete
      maxWidth="350px"
      position={position}
      bgColor="#12347a"
      title={description}
      content={<CompletedTaskPopoverContent taskId={taskId} />}
    />
  );
}

CompletedTaskDescription.propTypes = {
  taskId: string.isRequired,
  rowIndex: number.isRequired,
  description: node.isRequired,
};

// checkIsOwnReceivedSupplierInvoice :: { supplierInvoice: Invoice } -> Boolean
const checkIsOwnReceivedSupplierInvoice = R.both(
  R.pathOr(false, ['supplierInvoice', 'isOwnSupplierInvoice']),
  R.pathEq(['supplierInvoice', 'status'], InvoicesStatuses.RECEIVED),
);

export function TaskCompleteButton({
  taskId,
  action,
  rowIndex,
  complete,
  withHash,
  readOnly,
  collection,
  description,
  isSupplierInvoiceTaskDisabled,
  ...props
}) {
  const { hash } = useLocation();
  const clearHash = useUpdateHash();
  const isSupplierInvoiceTask = action === taskActionTypes.SUPPLIER_INVOICE;
  const isDisabledSupplierInvoiceTask =
    isSupplierInvoiceTask && isSupplierInvoiceTaskDisabled;
  const isEnabledSupplierInvoiceTask =
    isSupplierInvoiceTask && !isSupplierInvoiceTaskDisabled;
  const isOwnReceivedSupplierInvoice = checkIsOwnReceivedSupplierInvoice(props);
  const isCompletedProjectTask =
    !!complete && collection === collectionNames.projects;

  const { onCompleteTask } = useSidebarTasksTableHandlers({
    taskId,
    action,
    collection,
    description,
    ...props,
  });

  const previewCompletedTaskUpdates = useCompletedTaskUpdatesPreview();

  const onClick = () => {
    if (isCompletedProjectTask) {
      return previewCompletedTaskUpdates({ taskId, ...props });
    }

    if (
      (isSupplierInvoiceTask && isSupplierInvoiceTaskDisabled) ||
      isOwnReceivedSupplierInvoice ||
      complete ||
      readOnly
    ) {
      return null;
    }

    return onCompleteTask();
  };

  useEffect(() => {
    if (withHash && isTaskHashed(taskId)(hash)) {
      onCompleteTask(true);
      clearHash();
    }
  }, []);

  const isActiveSupplierInvoiceTask = isEnabledSupplierInvoiceTask && !complete;

  return (
    <FlexContainer onClick={onClick}>
      <CheckboxS
        name={taskId}
        value={complete}
        onChange={() => null}
        disabled={isOwnReceivedSupplierInvoice}
      />
      {isCompletedProjectTask && (
        <CompletedTaskDescription {...{ taskId, description, rowIndex }} />
      )}
      {isActiveSupplierInvoiceTask && !isOwnReceivedSupplierInvoice && (
        <MultiSupplierInvoiceNote
          description={description}
          complete={isSupplierInvoiceTaskDisabled}
          {...props}
        />
      )}
      {isActiveSupplierInvoiceTask && isOwnReceivedSupplierInvoice && (
        <OwnSupplierInvoiceNote description={description} />
      )}

      {isDisabledSupplierInvoiceTask && !complete && (
        <Description complete={isSupplierInvoiceTaskDisabled}>
          {description}
        </Description>
      )}
      {!isSupplierInvoiceTask && !isCompletedProjectTask && (
        <Description complete={complete}>{description}</Description>
      )}
    </FlexContainer>
  );
}

TaskCompleteButton.propTypes = {
  action: string,
  complete: bool,
  withHash: bool,
  readOnly: bool,
  description: string,
  taskId: string.isRequired,
  rowIndex: number.isRequired,
  collection: string.isRequired,
  isSupplierInvoiceTaskDisabled: bool,
};
