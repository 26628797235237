import * as R from 'ramda';
import { peopleStatuses } from 'poly-constants';
import React from 'react';
import { WhiteCard } from 'poly-book-admin';

import { peopleTabTypes } from './peopleTabTypes.js';
import { PageSubTabs } from '../../../components/Tabs.js';
import { TableCardWithPaginationContainer } from '../../../components/Table.js';
import { getPeopleContextFromProps, PeopleTableContext } from './hocs.js';
import { PeopleTable } from './PeopleTable.js';
import { PeopleTabTitle } from './PeopleTabTitle.js';
import { AddContactBtn } from './AddContactBtn.js';

function PeopleTableCard(props) {
  return (
    <TableCardWithPaginationContainer>
      <PeopleTable {...props} />
    </TableCardWithPaginationContainer>
  );
}

const peopleTabs = R.values(peopleStatuses);

const getTabs = (isInternational, isClientContact) =>
  peopleTabs.map((status) => [
    <PeopleTabTitle key={status} status={status} />,
    status,
    <PeopleTableCard
      key={status}
      status={status}
      isInternational={isInternational}
      showClientManagerColumn={isClientContact}
    />,
  ]);

function PeopleTabC({ isInternational, isClientContact, AddButton, ...props }) {
  const ButtonComponent = AddButton || AddContactBtn;

  return (
    <WhiteCard>
      <PageSubTabs
        toolBar={
          <ButtonComponent
            {...{ isInternational, isClientContact, ...props }}
          />
        }
        tabs={getTabs(isInternational, isClientContact)}
        {...props}
      />
    </WhiteCard>
  );
}

PeopleTabC.propTypes = peopleTabTypes;

export function PeopleTab(props) {
  const contextValue = getPeopleContextFromProps(props);

  return (
    <PeopleTableContext.Provider value={contextValue}>
      <PeopleTabC defaultValue={peopleStatuses.ACTIVE} {...props} />
    </PeopleTableContext.Provider>
  );
}

PeopleTab.displayName = 'PeopleTab';
PeopleTab.propTypes = peopleTabTypes;
