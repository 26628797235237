import React from 'react';
import * as R from 'ramda';
import { func } from 'prop-types';
import { FinancialEntityTypes } from 'poly-constants';

import { forceTitleCase } from 'poly-utils';
import { RadioButtons } from '../../components/RadioButtons.js';

export function BankDepositsTypeComponent({
  onChange,
  changeFieldValue,
  ...props
}) {
  const typeOptions = R.compose(
    R.map(
      R.applySpec({
        value: R.identity,
        label: (type) => forceTitleCase(type),
      }),
    ),
    R.values,
  )(FinancialEntityTypes);

  const onCheckboxChange = (type) => () => {
    onChange(type);
    changeFieldValue('documentId', null);
    changeFieldValue('payeeName', null);
  };

  return (
    <RadioButtons
      {...props}
      options={typeOptions}
      onChange={onCheckboxChange}
    />
  );
}

BankDepositsTypeComponent.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};
