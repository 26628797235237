import * as R from 'ramda';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { supplierTypes, SupplierWOCTypes } from 'poly-constants';
import { useOutSidebarContext } from 'poly-client-utils';
import { Loader } from 'poly-book-admin';
import {
  commonTaskDocumentFields,
  useNotificationState,
  entities,
} from 'poly-admin-ui';

import { assignSupplierFormId } from './constants.js';
import { AssignSupplierFormComp } from './AssignSupplierFormComp.js';
import { assignSupplierFormTypes } from '../../../sidebars/ProjectSidebar/constants.js';
import { COMPLETE_ASSIGN_SUPPLIERS_TO_PROJECT_MUTATION } from '../../core/hooks/projects/mutations.js';
import { prepareAssignSupplierMutationInput } from './AssignSupplierForm.js';
import { projectTaskCompleteCache } from '../utils.js';

// getNextTaskInitialValues :: Task -> Object
const getNextTaskInitialValues = R.compose(
  R.applySpec({
    nextTaskDescription: R.path(['nextTask', 'description']),
    dueDate: ({ nextTask }) =>
      nextTask.dueDate ? new Date(nextTask.dueDate) : new Date(),
    nextTaskId: R.path(['nextTask', '_id']),
  }),
  R.prop('task'),
);

const singleTaskQuery = gql`
  query SINGLE_TASK($id: ID!) {
    task(id: $id) {
      _id
      dueDate
      description
      complete
      document {
        ...commonTaskDocumentFields
      }
      manager {
        _id
      }
      nextTask {
        _id
        description
        dueDate
      }
      action
    }
  }

  ${commonTaskDocumentFields}
`;

export function CompleteAssignSupplierForm({ taskId }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const [noSupplierRequired, setNoSupplierRequired] = useState(false);

  const { showSuccessNotification } = useNotificationState();

  const { data, loading } = useQuery(singleTaskQuery, {
    variables: { id: taskId },
    skip: !taskId,
    fetchPolicy: 'network-only',
  });

  const [completeAssignSuppliersTask, { loading: disabled }] = useMutation(
    COMPLETE_ASSIGN_SUPPLIERS_TO_PROJECT_MUTATION,
  );

  if (loading || !data) {
    return <Loader />;
  }

  const projectId = R.path(['task', 'document', 'project', '_id'], data);

  const initialValues = {
    projectId,
    isComplete: true,
    [SupplierWOCTypes.confirm]: true,
    [SupplierWOCTypes.request]: false,
    suppliers: {
      [supplierTypes.SUBCONTRACTOR]: {},
      [supplierTypes.ADMINISTRATIVE]: {},
    },
    nextTask: getNextTaskInitialValues(data),
    entity: { _id: projectId, name: entities.PROJECT },
    managerId: R.path(['task', 'manager', '_id'], data),
    attachments: [],
  };

  const onSubmit = async ({
    suppliers,
    supplierNote,
    nextTask,
    managerId,
    ...props
  }) => {
    await completeAssignSuppliersTask({
      variables: {
        input: {
          assignSupplier: prepareAssignSupplierMutationInput({
            ...props,
            projectId,
            supplierNote,
            noSupplierRequired,
            suppliers,
          }),
          taskUpdatePayload: {
            projectId,
            nextTaskDueDate: nextTask.dueDate,
            assignNextTaskTo: managerId,
          },
        },
      },
      update: projectTaskCompleteCache(projectId, taskId),
    });
    closeOutSidebar(assignSupplierFormId);
    showSuccessNotification('Task was completed');
  };

  return (
    <AssignSupplierFormComp
      initialValues={initialValues}
      onSubmit={onSubmit}
      disabled={disabled}
      noSupplierRequired={noSupplierRequired}
      setNoSupplierRequired={setNoSupplierRequired}
      formType={assignSupplierFormTypes.assign}
    />
  );
}

CompleteAssignSupplierForm.propTypes = {
  taskId: string.isRequired,
};
