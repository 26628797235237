import * as R from 'ramda';
import styled from 'styled-components';
import {
  moneyColumnStyles,
  getThemeColor,
  getThemeFont,
  Button,
  WindowedTable,
  Text,
} from 'poly-book-admin';

import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';

const FOOTER_HEIGHT = '60px';

export const ReconciliationSection = styled(FlexColumn)`
  position: relative;
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? '100%' : '65px')};
`;

export const ReconciliationSectionTitle = styled(Text)`
  margin-right: 16px;
  font-size: 16px;
  padding: 5px 0;
  flex-shrink: 0;
`;

export const ReconciliationSectionTotal = styled(ReconciliationSectionTitle)`
  text-align: right;
  font-weight: ${getThemeFont(['regular'])};

  > span {
    padding-left: 20px;
  }
`;

export const ReconciliationTableComp = styled(WindowedTable)`
  height: 100%;

  table {
    min-height: auto;
  }

  tbody,
  thead {
    overflow-y: hidden;
    background-color: ${getThemeColor(['secondaryLighter3'])};
  }

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    width: 150px;
    text-align: center;
  }

  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(7),
  td:nth-child(7) {
    width: 250px;
  }

  ${moneyColumnStyles(6)};

  th:nth-child(1),
  td:nth-child(1) {
    width: 35px;
  }

  th {
    text-align: center;
  }

  tr {
    width: 100%;
    height: 25px;
    margin: 0;
  }

  td {
    padding: 0 5px;
    height: unset;
    vertical-align: middle;
  }

  th {
    padding: 5px;
    height: 20px;
  }
  tbody {
    height: auto;
  }
`;

export const ReconciliationFooterWrapper = styled(FlexColumn)`
  width: 100%;
  height: ${R.always(FOOTER_HEIGHT)};
  box-shadow: 0 -2px 2px 0 ${getThemeColor(['midLight'])};
  align-items: center;
  background-color: ${getThemeColor(['secondaryLighter3'])};
  padding: 4px 0 0 0;
  margin-top: 5px;
  flex-wrap: wrap;
`;

export const ReconciliationFooterRow = styled(FlexContainer)`
  align-items: flex-end;
  justify-content: space-between;
  max-width: 1424px;
  width: calc(100% - 80px);
  margin: 0 30px 0 50px;
  flex-shrink: 0;
  flex-wrap: wrap;
`;

export const ReconciliationFooterButton = styled(Button).attrs(() => ({
  size: 'tiny',
  type: 'button',
}))`
  margin-left: 15px;
  background: ${getThemeColor(['secondaryRegular'])};
`;

export const inputLabelProps = {
  color: 'midDark',
  size: 10,
};
