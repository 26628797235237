import React from 'react';
import { NumberInputHTML5 } from 'poly-book-admin';
import { bool, func } from 'prop-types';
import { SystemAccountTypes } from 'poly-constants';

import * as R from 'ramda';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';

const printCheckDetailsFilters = {
  bankAccountId: 'bankAccountId',
  checkNumber: 'checkNumber',
};

const defaultFilters = {
  [printCheckDetailsFilters.bankAccountId]: '',
  [printCheckDetailsFilters.checkNumber]: '',
};

const printChecksDetailsPageHeaderConfig = [
  {
    name: printCheckDetailsFilters.checkNumber,
    defaultValue: defaultFilters.checkNumber,
  },
  {
    name: printCheckDetailsFilters.bankAccountId,
    defaultValue: defaultFilters.bankAccountId,
  },
];

export function PrintChecksDetailsPageHeader({ setFilters, loading }) {
  const { searchFilters, onReset, handlers } = useSearchFilters(
    printChecksDetailsPageHeaderConfig,
  );

  const onResetSearch = () => {
    onReset();
    setFilters(null);
  };
  const onSearch = () => setFilters(searchFilters);

  return (
    <SearchPageHeaderContainer title="Print Check Details">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn title="Bank Name">
            <AccountsSelect
              filterAccounts={filterAccountsBySystemType(
                SystemAccountTypes.BANK_ACCOUNT,
              )}
              placeholder="Select Bank Name"
              value={searchFilters.bankAccountId}
              onChange={handlers.bankAccountId}
              extractValue={R.prop('_id')}
              includeAllOption={false}
              width="100%"
              isClearable
              required
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Check #">
            <NumberInputHTML5
              name="CheckNumberInput"
              placeholder="Enter Check Number"
              maxLength={7}
              width="100%"
              required
              onChange={handlers.checkNumber}
              value={searchFilters.checkNumber || ''}
              changeOnBlur
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn>
            <FlexCenterAlign>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
                disabled={
                  !searchFilters.checkNumber || !searchFilters.bankAccountId
                }
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

PrintChecksDetailsPageHeader.propTypes = {
  setFilters: func.isRequired,
  loading: bool,
};
