import styled from 'styled-components';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatDistance } from 'date-fns';
import { instanceOf, shape, string } from 'prop-types';
import { getThemeColor, UserAvatar } from 'poly-book-admin';
import { PolyLogo } from 'poly-admin-ui';
import {
  firstLettersOfEachWord,
  formatDateTime,
  ensureIsDate,
} from 'poly-utils';

import {
  MessageTime,
  MessageTitle,
  MessageWrapper,
  UserAlertWrapper,
  MessageWrapperEntry,
  FlexColumn,
} from './styles.js';
import {
  UpdateDocumentLink,
  EntityReferenceLinkTabs,
} from '../../../UpdateDocumentLink.js';
import { ToggleUserAlertArchivedButton } from './ToggleUserAlertArchivedButton.js';
import { setPopup } from '../../../../redux/popupReducer.js';

const isSystemUser = (alert) =>
  !alert.createdBy || alert.createdBy.fullName === 'Poly';

function AlertReferenceLink({ url, ...props }) {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        attachment
      </a>
    );
  }

  return (
    <UpdateDocumentLink {...props} tab={EntityReferenceLinkTabs.UPDATES} />
  );
}

AlertReferenceLink.propTypes = { url: string };

const CreatedBy = styled.span`
  color: ${getThemeColor(['secondaryMid'])};
  white-space: nowrap;
`;

export function UserAlertTableEntry(props) {
  const [isArchivedDone, setIsArchivedDone] = useState(false);
  const dispatch = useDispatch();

  return (
    <UserAlertWrapper isArchivedDone={isArchivedDone}>
      {isSystemUser(props) ? (
        <PolyLogo data-testid="poly-logo" />
      ) : (
        <UserAvatar>
          {firstLettersOfEachWord(props.createdBy.fullName)}
        </UserAvatar>
      )}
      <MessageWrapper>
        <MessageWrapperEntry>
          <MessageTitle>
            {props.message}
            &nbsp;
            <AlertReferenceLink
              {...props}
              onClick={() => dispatch(setPopup(''))}
            />
            {!!props.createdBy && (
              <>
                &nbsp;by&nbsp;
                <CreatedBy>@{props.createdBy.fullName}</CreatedBy>
              </>
            )}
          </MessageTitle>
        </MessageWrapperEntry>
      </MessageWrapper>
      <FlexColumn>
        <MessageTime title={formatDateTime(props.createdAt)}>
          {formatDistance(
            ensureIsDate(new Date()),
            ensureIsDate(props.createdAt),
          )}
          &nbsp;ago
        </MessageTime>
        <ToggleUserAlertArchivedButton
          {...{ isArchivedDone, setIsArchivedDone, ...props }}
        />
      </FlexColumn>
    </UserAlertWrapper>
  );
}

UserAlertTableEntry.propTypes = {
  message: string.isRequired,

  createdAt: instanceOf(Date).isRequired,
  createdBy: shape({ fullName: string.isRequired }),
};

UserAlertTableEntry.displayName = 'UserAlertTableEntry';
