import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { TextButton } from 'poly-book-admin';
import { AccountingMethods, EXPORT_XLS_CAPTION } from 'poly-constants';
import { arrayOf, instanceOf, oneOfType, shape, string } from 'prop-types';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
  formatFromToDateTitle,
} from 'poly-client-utils';

import {
  calculateTotalBalance,
  extractCogs,
  extractExpense,
  extractIncome,
} from './helpers.js';
import { profitLossPropTypes } from './propTypes.js';

const ProfitLossExportTextButton = styled(TextButton)`
  font-size: 14px;
  margin-left: 5px;
`;

const balanceTypes = {
  debit: 'debit',
  credit: 'credit',
};

// getExportExcelRow :: Array -> [ExcelExportDataCell]
const getExportExcelRow = R.zipWith(createExcelExportCell, [
  ExcelExportCellType.default,
  ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
]);

// getRow :: ReportsData -> [ExcelExportDataCell]
const getRow = (isParent, balanceType) =>
  R.compose(
    getExportExcelRow,
    R.ifElse(
      () => balanceType === balanceTypes.debit,
      R.append(''),
      R.juxt([R.head, R.always(''), R.last]),
    ),
    R.juxt([
      R.compose(
        R.when(
          R.complement(() => isParent),
          R.concat('    '),
        ),
        R.join(' - '),
        R.juxt([R.prop('name'), R.prop('code')]),
        R.prop('account'),
      ),
      R.prop('balance'),
    ]),
  );

// getAndMapReports :: String -> ReportsData -> [[ExcelExportDataCell]]
const getAndMapReports =
  (balanceType) =>
  ({ subAccounts, ...restOfFields }) =>
    [
      getRow(true, balanceType)(restOfFields),
      ...R.map(getRow(false, balanceType), subAccounts),
    ];

const emptyRow = [
  {
    value: '',
    cellType: ExcelExportCellType.default,
  },
];

// getTotalRowConfig :: String -> Array -> [ExcelExportDataCell]
const getTotalRowConfig = (balanceType) =>
  R.compose(
    getExportExcelRow,
    R.when(() => balanceType === balanceTypes.debit, R.remove(1, 1)),
  );

// getReportsConfig :: (String, [ReportsData], Number, String) -> [ExcelExportDataCell]
const getReportsConfig = (title, reportsData, totalBalance, balanceType) => [
  [
    {
      value: title,
      cellType: ExcelExportCellType.default,
    },
  ],
  ...R.compose(R.unnest, R.map(getAndMapReports(balanceType)))(reportsData),
  getTotalRowConfig(balanceType)([`Total ${title}`, '', totalBalance]),
  emptyRow,
];

// getProfitRowConfig :: (String Number) -> [ExcelExportDataCell]
const getProfitRowConfig = ({ title, profit }) =>
  R.zipWith(
    createExcelExportCell,
    [
      ...R.repeat(ExcelExportCellType.default, 2),
      ExcelExportCellType.defaultCurrency,
    ],
    [title, '', profit],
  );

// getProfitLossReportConfig :: ([ProfitLossReport], [String]) -> [ExcelExportDataCell]
const getProfitLossReportConfig = (profitLossReport, cogsAccountCodes) => {
  const incomeReports = extractIncome(profitLossReport);
  const cogsReports = extractCogs(cogsAccountCodes)(profitLossReport);
  const expenseReports = extractExpense(cogsAccountCodes)(profitLossReport);

  const totalIncome = calculateTotalBalance(incomeReports);
  const totalCogs = calculateTotalBalance(cogsReports);
  const totalOverHead = calculateTotalBalance(expenseReports);

  return [
    ...getReportsConfig(
      'Income',
      incomeReports,
      totalIncome,
      balanceTypes.credit,
    ),
    ...getReportsConfig(
      'COGS Facility Services',
      cogsReports,
      totalCogs,
      balanceTypes.debit,
    ),
    getProfitRowConfig({
      title: 'Gross Profit',
      profit: totalIncome - totalCogs,
    }),
    emptyRow,
    ...getReportsConfig(
      'Overhead',
      expenseReports,
      totalOverHead,
      balanceTypes.debit,
    ),
    getProfitRowConfig({
      title: 'Net Profit/(Loss)',
      profit: totalIncome - totalCogs - totalOverHead,
    }),
  ];
};

// getExcelTitleByProps -> FilterObj -> String
const getExcelTitleByProps = R.compose(
  R.concat('Profit and Loss ('),
  R.concat(R.__, ' Basis)'),
  R.ifElse(
    R.propEq('accountingMethod', AccountingMethods.CASH_BASIS),
    R.always('Cash'),
    R.always('Accrual'),
  ),
);

// eslint-disable-next-line import/no-unused-modules
export const getProfitLossExportExcelConfig = ({
  profitLossReport,
  filters,
  cogsAccountCodes,
}) => ({
  exportFileName: 'profit_and_loss.xlsx',
  columnWidths: [50, 15, 15],
  rows: [
    [
      {
        value: getExcelTitleByProps(filters),
        cellType: ExcelExportCellType.title,
      },
    ],
    [
      {
        value: formatFromToDateTitle(filters.startDate, filters.endDate),
        cellType: ExcelExportCellType.title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ExcelExportCellType.tableHeader,
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 2),
      ],
      ['Name', 'Debit', 'Credit'],
    ),
    ...getProfitLossReportConfig(profitLossReport, cogsAccountCodes),
  ],
});

export function ProfitLossExportExcelButton({
  filters,
  profitLossReport,
  cogsAccountCodes,
}) {
  const onClick = () => {
    const exportConfig = getProfitLossExportExcelConfig({
      profitLossReport,
      filters,
      cogsAccountCodes,
    });
    performExcelExport(exportConfig);
  };

  return (
    <ProfitLossExportTextButton onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </ProfitLossExportTextButton>
  );
}

ProfitLossExportExcelButton.propTypes = {
  profitLossReport: profitLossPropTypes,
  cogsAccountCodes: arrayOf(string),
  filters: shape({
    startDate: oneOfType([string, instanceOf(Date)]).isRequired,
    endDate: oneOfType([string, instanceOf(Date)]).isRequired,
    accountingMethod: string.isRequired,
  }),
};
