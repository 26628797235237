import React from 'react';
import * as R from 'ramda';
import { bool, shape, string } from 'prop-types';
import { RecurringProjectTypes } from 'poly-constants';

import { SidebarIDs } from '../../constants.js';
import { masterProjectSidebarTabsId } from '../constants.js';
import { SidebarTabs } from '../../components/SidebarTabs.js';
import { masterProjectSidebarTabs } from '../../../routes/constants.js';
import { MasterRecurringProjectSidebarChildTab } from './MasterRecurringProjectSidebarChildTab.js';
import { MasterRecurringProjectSidebarTasksTab } from './MasterRecurringProjectSidebarTasksTab.js';
import { MasterRecurringProjectSidebarFilesTab } from './MasterRecurringProjectSidebarFilesTab.js';
import { MasterRecurringProjectSidebarAssetsTab } from './MasterRecurringProjectSidebarAssetsTab.js';
import { MasterRecurringProjectSidebarUpdatesTab } from './MasterRecurringProjectSidebarUpdatesTab.js';

// showAssets :: RecurringProject -> Boolean
const showAssets = R.propEq(
  'type',
  RecurringProjectTypes.preventiveMaintenanceProject,
);

export function MasterRecurringProjectSidebarTabs({
  project,
  customTabQueryKey,
  isCard = false,
}) {
  return (
    <SidebarTabs
      tabs={[
        [
          'Child Projects',
          masterProjectSidebarTabs.masterProjectChild,
          <MasterRecurringProjectSidebarChildTab
            {...project}
            isCard={isCard}
          />,
        ],
        [
          'Updates',
          masterProjectSidebarTabs.masterProjectUpdates,
          <MasterRecurringProjectSidebarUpdatesTab {...project} />,
        ],
        [
          'Tasks',
          masterProjectSidebarTabs.masterProjectTasks,
          <MasterRecurringProjectSidebarTasksTab {...project} />,
        ],
        [
          'Files',
          masterProjectSidebarTabs.masterProjectFiles,
          <MasterRecurringProjectSidebarFilesTab {...project} />,
        ],
        ...(showAssets(project)
          ? [
              [
                'Assets',
                masterProjectSidebarTabs.masterProjectAssets,
                <MasterRecurringProjectSidebarAssetsTab {...project} />,
              ],
            ]
          : []),
      ]}
      hideCloseIcon={isCard}
      isCard={isCard}
      id={masterProjectSidebarTabsId}
      params={[SidebarIDs.masterProject]}
      defaultValue={masterProjectSidebarTabs.masterProjectUpdates}
      customTabQueryKey={customTabQueryKey}
    />
  );
}

MasterRecurringProjectSidebarTabs.propTypes = {
  isCard: bool,
  project: shape({ _id: string.isRequired }),
  customTabQueryKey: string,
};
