import * as R from 'ramda';
import { createContext, useState, useEffect } from 'react';

export const SuggestedInvoiceAmountContext = createContext({});

// prepareDefaultAmounts :: [Project] -> [AmountObj]
// AmountObj = { _id: ID, amount: Float }
const prepareDefaultAmounts = R.compose(
  R.map(
    R.applySpec({
      _id: R.prop('_id'),
      amount: R.prop('suggestedClientInvoiceAmount'),
    }),
  ),
  R.defaultTo([]),
);

// updateSuggestedAmountById :: (ID, Float) -> [AmountObj] -> [AmountObj]
const updateSuggestedAmountById = (id, amount) =>
  R.map(R.when(R.propEq('_id', id), R.assoc('amount', amount)));

export const useSuggestedInvoiceAmountLogic = (projects) => {
  const [amounts, setAmounts] = useState([]);

  useEffect(() => {
    setAmounts(prepareDefaultAmounts(projects));
  }, [projects]);

  const updateSuggestedAmount = (id, amount) =>
    setAmounts(updateSuggestedAmountById(id, amount));

  return { amounts, updateSuggestedAmount };
};
