import {
  bool,
  func,
  shape,
  number,
  string,
  arrayOf,
  oneOfType,
  object,
} from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { applyOr, formatCurrency } from 'poly-utils';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';
import { NOTHING_UI_STRING } from 'poly-constants';
import {
  moneyColumnStyles,
  getThemeColor,
  WindowedTable,
  Table,
  Cell,
  Row,
} from 'poly-book-admin';

import {
  accountTransactionPropType,
  accountTransactionsResultPropType,
} from './accountTransactionsPropTypes.js';
import { TableLinkRow } from '../../components/TableLinkRow.js';
import { formatAccountBalance } from '../GeneralLedgerPage/generalLedgerHelpers.js';
import {
  transactionsResultToTableRows,
  formatTransactionNumber,
  formatTransactionDate,
  formatTransactionType,
  formatTransactionName,
  getTransactionBalance,
  getDescription,
  getSplitAccount,
} from './accountTransactionsHelpers.js';
import { TransactionsDetailsSidebar } from '../../sidebars/TransactionDetailsSidebar/TransactionDetailsSidebar.js';
import {
  sidebarWidthByTransactionType,
  transactionsDetailsSidebarId,
} from '../../sidebars/TransactionDetailsSidebar/helpers.js';

const BalanceRow = styled(Row)`
  td {
    font-weight: bold;
  }
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

// formatTransactionAmount :: Number -> String
const formatTransactionAmount = applyOr(
  R.compose(formatCurrency, Math.abs),
  NOTHING_UI_STRING,
);

const ContainerS = styled.div`
  overflow: hidden;
`;

const SplitAccountsS = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
`;

function TruncateTexComp({ text }) {
  return (
    <ContainerS>
      <SplitAccountsS>{text}</SplitAccountsS>
    </ContainerS>
  );
}

TruncateTexComp.propTypes = {
  text: string,
};

// getTruncateTexComp :: (AccountTransaction -> String, Boolean) -> AccountTransaction -> ReactNode
const getTruncateTexComp = (getTextFn, isPrint) =>
  R.compose(
    R.unless(R.always(isPrint), R.compose(TruncateTexComp, R.objOf('text'))),
    getTextFn,
  );

const getAccountTransactionsTableConfig = (isPrint) => [
  ['Type', formatTransactionType],
  ['Date', formatTransactionDate],
  ['Num', getTruncateTexComp(formatTransactionNumber, isPrint)],
  ['Name', getTruncateTexComp(formatTransactionName, isPrint)],
  ['Memo', getTruncateTexComp(getDescription, isPrint)],
  ['Split', getTruncateTexComp(getSplitAccount, isPrint)],
  ['Debit', R.compose(formatTransactionAmount, R.prop('debitAmount'))],
  ['Credit', R.compose(formatTransactionAmount, R.prop('creditAmount'))],
  ['Balance', R.compose(formatAccountBalance, getTransactionBalance)],
];

const balanceRowStyles = css`
  td {
    font-weight: bold;
  }
`;

const AccountTransactionsTableS = styled(WindowedTable)`
  height: calc(100% - 20px);

  tr,
  th {
    width: 100%;
    margin: 0;
  }

  tr:first-child,
  tr:last-child {
    ${balanceRowStyles};
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 110px;
  }
  td:nth-child(2),
  th:nth-child(2) {
    width: 100px;
  }
  td:nth-child(3),
  th:nth-child(3) {
    width: 9%;
  }
  td:nth-child(4),
  th:nth-child(4),
  td:nth-child(5),
  th:nth-child(5) {
    width: 16%;
  }
  td:nth-child(6),
  th:nth-child(6) {
    width: auto;
  }
  ${moneyColumnStyles(7, 120)};
  ${moneyColumnStyles(8, 120)};
  ${moneyColumnStyles(9, 120)};
`;

const AccountTransactionsPrintTableS = styled(Table)`
  tr,
  th {
    width: 100%;
    margin: 0;
  }

  @media print {
    tr {
      border: none;

      &:first-child {
        border: none;
      }

      &:last-child {
        border: none;
      }
    }
  }

  tr:first-child,
  tr:last-child {
    ${balanceRowStyles};
  }

  ${moneyColumnStyles(7)};
  ${moneyColumnStyles(8)};
  ${moneyColumnStyles(9)};
`;

// formatBalanceIfNumber :: Number -> String
const formatBalanceIfNumber = R.ifElse(
  R.is(Number),
  formatAccountBalance,
  R.always(''),
);

function TransactionsTableRow({
  columns,
  index,
  row,
  style,
  className,
  headers,
}) {
  const { openOutSidebar } = useOutSidebarContext();
  const transactionNumber = R.path(['journal', 'transaction_number'], row);
  const transactionType = R.path(['journal', 'type'], row);
  return row.isBalanceRow ? (
    <BalanceRow
      className={className}
      key={`${row.title}${index}`}
      style={style}
    >
      <Cell>{row.title}</Cell>
      <Cell />
      <Cell />
      <Cell />
      <Cell />
      <Cell />
      <Cell>{formatBalanceIfNumber(row.debits)}</Cell>
      <Cell>{formatBalanceIfNumber(row.credits)}</Cell>
      <Cell>{formatAccountBalance(row.balance)}</Cell>
    </BalanceRow>
  ) : (
    <TableLinkRow
      columns={columns}
      index={index}
      row={row}
      style={style}
      className={className}
      headers={headers}
      onClick={() =>
        openOutSidebar({
          id: transactionsDetailsSidebarId,
          content: (
            <TransactionsDetailsSidebar transactionNumber={transactionNumber} />
          ),
          width: sidebarWidthByTransactionType(transactionType),
          alwaysFirst: true,
        })
      }
    />
  );
}

TransactionsTableRow.propTypes = {
  columns: arrayOf(func),
  index: number,
  row: oneOfType([
    shape({
      isBalanceRow: bool.isRequired,
      title: string.isRequired,
      credits: number,
      debits: number,
      balance: number.isRequired,
    }),
    accountTransactionPropType,
  ]).isRequired,
  className: string,
  style: oneOfType([string, object]),
  headers: arrayOf(shape({ title: string })),
};

export function AccountTransactionsTable({ result, isPrint, ...restProps }) {
  const rows = transactionsResultToTableRows(result);
  const tableProps = useMapConfigToTableProps(
    R.identity,
    getAccountTransactionsTableConfig(isPrint),
    rows,
  );

  const TableC = isPrint
    ? AccountTransactionsPrintTableS
    : AccountTransactionsTableS;
  return (
    <TableC
      {...tableProps}
      {...restProps}
      itemSize={55}
      RowComponent={TransactionsTableRow}
    />
  );
}

AccountTransactionsTable.propTypes = {
  result: accountTransactionsResultPropType.isRequired,
  isPrint: bool,
};
