import {
  array,
  arrayOf,
  bool,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { AccountStatuses } from 'poly-constants';

export const accountPropType = {
  _id: string,
  code: string,
  name: oneOfType([array, string]),
  accountType: shape({
    _id: string,
    name: oneOfType([array, string]),
  }),
  division: shape({
    _id: string,
    name: string,
  }),
  status: string,
  sub_account: bool,
  parentAccountId: string,
};

const highlightedAccountPropType = {
  ...accountPropType,
  code: oneOfType([array, string, object]),
  name: oneOfType([array, string]),
  type: shape({
    _id: string,
    name: oneOfType([array, string]),
  }),
  division: shape({
    _id: string,
    name: oneOfType([array, string]),
  }),
};

export const defaultChartOfAccountsPropTypes = {
  loading: bool,
  tab: string,
  parentAccounts: arrayOf(
    shape({
      _id: string,
      code: string,
      name: string,
    }),
  ),
  accountsByTabAndFilter: arrayOf(shape(accountPropType)),
  highlightedAccounts: arrayOf(shape(highlightedAccountPropType)),
  count: shape({
    [AccountStatuses.ACTIVE]: number,
    [AccountStatuses.INACTIVE]: number,
  }),
};
