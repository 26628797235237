import * as R from 'ramda';
import React from 'react';
import { NOTHING_UI_STRING } from 'poly-constants';
import { PopoverLink, useNotificationContext } from 'poly-book-admin';

// preparePinCodeMessage :: Supplier -> String
const preparePinCodeMessage = R.compose(
  R.join(' PIN code: '),
  R.juxt([
    R.path(['company', 'name']),
    R.pathOr(NOTHING_UI_STRING, ['assetScannerPinAccess', 'code']),
  ]),
);

export function ViewPinCodePopoverLink(props) {
  const { showWarningNotification } = useNotificationContext();

  const onClick = () => {
    showWarningNotification(preparePinCodeMessage(props));
  };

  return <PopoverLink onClick={onClick}>View pin</PopoverLink>;
}
