import React from 'react';
import * as R from 'ramda';
import { func, bool } from 'prop-types';
import { useLoadGoogleMapScript } from 'poly-client-utils';
import { Input, Loader } from 'poly-book-admin';

import {
  initAutocompleteField,
  addAutocompleteListeners,
} from './geoSelectUtils.js';

const { GOOGLE_API_KEY } = process.env;

function GeoSelectInputLoading({ loading }) {
  return (
    loading && (
      <div style={{ position: 'relative', marginTop: '1px' }}>
        <Loader size={25} />
      </div>
    )
  );
}

GeoSelectInputLoading.propTypes = {
  loading: bool.isRequired,
};

class GeoSelectClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.unsubscribe = initAutocompleteField(
      this.geoSelect,
      this.addAutocompleteListeners,
    );
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  addAutocompleteListeners = (autocomplete) => {
    // only adding listeners when component loaded
    this.setState({ loading: false }, () =>
      addAutocompleteListeners(autocomplete, this.props),
    );
  };

  resetGeoInputValue = () => {
    // GeoSelect is uncontrolled, because actual value is complex geo object
    // User input is isolated in component and updated
    // - on input
    // - on focus
    // - on geo-suggested select (updated by google's lib);
    this.geoSelect.value = '';
    this.props.onChange(null);
  };

  render() {
    const inputProps = R.omit([
      'meta',
      'value',
      'onFocus',
      'onChange',
      'defaultValue',
      'onInputChange',
    ])(this.props);

    return (
      <>
        <GeoSelectInputLoading loading={this.state.loading} />
        <Input
          {...inputProps}
          ref={(e) => {
            this.geoSelect = e;
          }}
          onFocus={this.resetGeoInputValue}
          autoComplete="false"
          // to override autocomplete
          name="geo-select"
          // to hide when loading. Need to keep in DOM to attach maps listeners
          style={{ display: this.state.loading ? 'none' : 'initial' }}
          onBlur={(e) => {
            this.props.onBlur(e);
            this.props.onInputChange(e.target.value);
          }}
        />
      </>
    );
  }
}

GeoSelectClass.propTypes = {
  onBlur: func,
  onInputChange: func,
  onChange: func.isRequired,
};

GeoSelectClass.defaultProps = { onInputChange: () => null };

export function GeoSelect(props) {
  const loading = useLoadGoogleMapScript(GOOGLE_API_KEY);

  if (loading) {
    return <Loader />;
  }

  return <GeoSelectClass {...props} />;
}
