import React from 'react';
import { entityToOptionByLabelPath } from 'poly-client-utils';
import { UserStatuses } from 'poly-constants';

import {
  USERS_BY_SEARCH,
  DEFAULT_USER_QUERY,
} from '../../../../../hocs/users/queries.js';
import { AsyncSearchSelect } from '../../../fields/multiSearchSelect/createAsyncSearchSelect.js';
import { getAdminUsersBySystemStatusQuery } from '../../../../../searchQueries/users.js';

export function AacContractMGRField(props) {
  const selectProps = {
    valueEndpointName: 'user',
    gqlOptionsQuery: USERS_BY_SEARCH,
    gqlValueQuery: DEFAULT_USER_QUERY,
    optionsEndpointName: 'searchUsers',
    placeholder: 'Start typing managers',
    hitToOption: entityToOptionByLabelPath(['fullName']),
    searchQuery: getAdminUsersBySystemStatusQuery(UserStatuses.ACTIVE),
    ...props,
  };

  return <AsyncSearchSelect {...selectProps} />;
}
