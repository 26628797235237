import React from 'react';
import { number, string } from 'prop-types';
import { entities } from 'poly-admin-ui';

import { SidebarContactsTab } from '../../components/commonTabs/SidebarContactsTab.js';

export function PurchaseOrderSidebarContactsTab({
  _id,
  doubleTabWidth,
  tabTitlePrefix,
}) {
  return (
    <SidebarContactsTab
      isCard={false}
      tabWidth={doubleTabWidth}
      entity={{ _id, name: entities.ADMIN_PURCHASE_ORDER }}
      tabTitlePrefix={tabTitlePrefix}
    />
  );
}

PurchaseOrderSidebarContactsTab.propTypes = {
  _id: string.isRequired,
  doubleTabWidth: number.isRequired,
  tabTitlePrefix: string,
};
