import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { EQUALS_AIT_OPERATOR } from 'poly-security';

const ASSIGN_USER_GROUP_MUTATION = gql`
  mutation ASSIGN_USER_GROUP_MUTATION($input: AssignUserGroupInput!) {
    assignUserGroup(input: $input) {
      userIds
    }
  }
`;

// getVariableValue :: [QueryVariable] -> AccessItemTypesObjectInput
// QueryVariable = {
//    scope: String
//    operator: String
//    entities: [SelectOption]
// }
const getVariableValue = ({ scope, operator, entities }) =>
  R.compose(
    R.assocPath([scope, operator], R.__, {}),
    R.ifElse(
      R.is(Number),
      R.identity,
      R.compose(
        R.when(() => operator === EQUALS_AIT_OPERATOR, R.head),
        R.map(R.prop('value')),
        R.defaultTo([]),
      ),
    ),
  )(entities);

// prepareAssignUserGroupInput :: FormData -> AssignUserGroupInput
const prepareAssignUserGroupInput = R.applySpec({
  userIds: R.compose(R.map(R.prop('value')), R.propOr([], 'usersToAssign')),
  userGroupId: R.path(['userGroup', '_id']),
  variables: R.compose(
    R.map(R.applySpec({ variableId: R.prop('id'), value: getVariableValue })),
    R.propOr([], 'variables'),
  ),
});

export const useAssignUserGroupMutation = () => {
  const [assignUserGroup] = useMutation(ASSIGN_USER_GROUP_MUTATION);

  const runMutation = async (formData) => {
    const input = prepareAssignUserGroupInput(formData);

    await assignUserGroup({ variables: { input } });
  };

  return runMutation;
};
