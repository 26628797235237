import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Table, moneyColumnStyles } from 'poly-book-admin';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { applyProp, formatDate, centsToDollarsWithFormat } from 'poly-utils';

import { clientInvoicesPropType } from './prop-types.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';

const clientInvoiceHistoryTableConfig = [
  ['Invoice #', R.prop('number')],
  ['Invoice Date', applyProp(formatDate)('invoiceDate')],
  ['Due Date', applyProp(formatDate)('dueDate')],
  ['Project #', ({ project }) => <ProjectLink {...project} target="_blank" />],
  ['Invoice Amount', applyProp(centsToDollarsWithFormat)('amount')],
  ['Balance', applyProp(centsToDollarsWithFormat)('balance')],
];

const ClientInvoicesHistoryTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: 120px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 120px;
  }

  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};

  th:nth-child(5),
  td:nth-child(5),
  th:nth-child(6),
  td:nth-child(6) {
    width: auto;
  }
`;

export function ClientInvoiceHistoryTable({ clientInvoices, ...restProps }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    clientInvoiceHistoryTableConfig,
    clientInvoices,
  );
  return <ClientInvoicesHistoryTableS {...tableProps} {...restProps} />;
}

ClientInvoiceHistoryTable.propTypes = {
  clientInvoices: clientInvoicesPropType.isRequired,
};
