import React from 'react';

import { Checkbox, getThemeColor } from 'poly-book-admin';
import { bool, func, number, oneOfType, string } from 'prop-types';
import styled from 'styled-components';

export const SelectCheckbox = styled(Checkbox)`
  .state-re-presenter {
    border: 1px solid ${getThemeColor(['mid'])};
    border-radius: 2.25px;
  }
`;

export function SelectInvoicesCheckBox({
  _id,
  isSelected,
  selectInvoice,
  paidAmount = 0,
  deductionsAmount = 0,
  currentBalance,
}) {
  const onChange = (checked) => {
    const invoiceUpdate = checked
      ? {
          isSelected: checked,
          paidAmount: paidAmount || currentBalance - deductionsAmount,
        }
      : {
          isSelected: checked,
          paidAmount: '',
          deductionsAmount: '',
          discountAccountId: '',
        };
    selectInvoice(invoiceUpdate);
  };

  return (
    <SelectCheckbox
      dataTestId="select-invoice-checkbox"
      name={`invoice-selects-${_id}`}
      value={!!isSelected}
      onChange={onChange}
    />
  );
}

SelectInvoicesCheckBox.propTypes = {
  selectInvoice: func.isRequired,
  paidAmount: oneOfType([number, string]),
  deductionsAmount: oneOfType([number, string]),
  currentBalance: number,
  isSelected: bool,
  _id: string.isRequired,
};
