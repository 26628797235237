import { LoginPageBaseAdmin } from 'poly-admin-ui';
import React from 'react';
import { setCurrentSentryUser } from 'poly-client-utils';

import { routesNames } from '../routes/index.js';
import { useUserAuthBroadcastChannel } from '../hooks/useBroadcastChannel.js';

export const APP_TITLE = 'ESFM Services';

export function LoginPage() {
  const { postIsLoggedIn } = useUserAuthBroadcastChannel();

  const onLoginComplete = (user) => {
    setCurrentSentryUser(user);
    postIsLoggedIn();
  };

  return (
    <LoginPageBaseAdmin
      title={APP_TITLE}
      homePageRoute={routesNames.HOME}
      onLoginComplete={onLoginComplete}
      forgotPasswordRoute={routesNames.FORGOT_PASSWORD}
    />
  );
}
