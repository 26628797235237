import * as R from 'ramda';
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { string, func, bool, arrayOf } from 'prop-types';
import { Button } from 'poly-book-admin';
import { useNotificationState, useModalContext } from 'poly-admin-ui';

import { SimpleModalLayout } from '../../components/SimpleModalLayout.js';
import { useUserHasAccessToUpdateAsset } from './useUserHasAccessToUpdateAsset.js';

const approveAssetsModalId = 'approveAssetsModalId';

const APPROVE_ASSETS_MUTATION = gql`
  mutation APPROVE_ASSETS_MUTATION($ids: [ID]!) {
    approveAssets(ids: $ids) {
      approvedAssetsCount
    }
  }
`;

export function ApproveAssetButton({ ids, onSuccess, hasError, disabled }) {
  const { openModal } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [approveAssets] = useMutation(APPROVE_ASSETS_MUTATION);
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();

  const hasPermissionToUpdateAsset = useUserHasAccessToUpdateAsset();

  const assetsNumberString = `${ids.length} Asset${
    ids.length === 1 ? '' : 's'
  }.`;

  const onConfirm = (closeConfirmModal) => async () => {
    setIsLoading(true);
    await approveAssets({ variables: { ids } });
    showSuccessNotification(`You have approved ${assetsNumberString}`);

    if (R.is(Function, onSuccess)) {
      onSuccess();
    }

    setIsLoading(false);
    closeConfirmModal();
  };

  const onApprove = () =>
    hasError
      ? showErrorNotification('You need to approve equipment for asset before')
      : openModal({
          onConfirm,
          loading: isLoading,
          btnCaption: 'Approve',
          title: 'Approve Asset',
          id: approveAssetsModalId,
          Layout: SimpleModalLayout,
          content: `You're going to approve ${assetsNumberString}`,
        });

  return (
    <Button
      size="small"
      loader={isLoading}
      disabled={disabled || !hasPermissionToUpdateAsset}
      onClick={onApprove}
    >
      Approve
    </Button>
  );
}

ApproveAssetButton.propTypes = {
  hasError: bool,
  disabled: bool,
  onSuccess: func,
  ids: arrayOf(string),
};
