import React from 'react';
import { CREATE_CONTACT_USER_PERMISSION } from 'poly-security';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { TextButton } from 'poly-book-admin';
import { bool, shape, string } from 'prop-types';
import { useModalContext } from '../../../components/Modal/ModalProvider.js';
import { AddPeopleForm } from '../../forms/peopleForm/add/AddPeopleForm.js';
import { addPeopleFormId } from './hocs.js';

export function AddContactBtn({
  isClientContact,
  isInternational,
  entity,
  ...restProps
}) {
  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_CONTACT_USER_PERMISSION,
  );

  const { openModalForm, closeModal } = useModalContext();

  const onClick = () =>
    openModalForm({
      id: addPeopleFormId,
      formId: addPeopleFormId,
      content: (
        <AddPeopleForm
          entity={entity}
          formId={addPeopleFormId}
          onCancel={closeModal}
          isInternational={isInternational}
          isClientContact={isClientContact}
        />
      ),
      title: 'Add Contact',
      btnCaption: 'Create',
      isOverflowAuto: true,
    });

  return ifHasPermission ? (
    <TextButton {...restProps} onClick={onClick}>
      Add New
    </TextButton>
  ) : null;
}

AddContactBtn.propTypes = {
  isClientContact: bool,
  isInternational: bool,
  entity: shape({
    name: string,
    _id: string,
  }),
};
