import * as R from 'ramda';
import { calculateTotal } from '../general.js';

// calculateTimeEntryCost :: TimeEntry -> Number
export const calculateTimeEntryCost = R.converge(R.multiply, [
  R.prop('rate'),
  R.compose(R.divide(R.__, 60), R.propOr(0, 'totalTimeInMinutes')),
]);

// calculateProjectTimeEntriesCost :: Project -> Number
export const calculateProjectTimeEntriesCost = R.compose(
  calculateTotal(calculateTimeEntryCost),
  R.reject(R.propEq('isInvoiced', true)),
  R.pathOr([], ['timeEntries']),
);
