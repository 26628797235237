import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { shape, string, bool, func, oneOfType, instanceOf } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';
import { formatDate } from 'poly-utils';
import {
  BooleanTextWithDot,
  SectionWithCard,
  LinkButton,
  Button,
} from 'poly-book-admin';

import { FileLink } from '../../components/Links.js';
import { useNotificationState } from '../../hooks/useNotificationState.js';

const fileShape = { fileName: string, url: string };
const fileName = R.path(['fileName']);
const fileUrl = R.path(['url']);

const prepareDate = R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), formatDate);

const sendSupplierDocumentsRequestMutation = gql`
  mutation sendSupplierDocumentsRequestMutation(
    $supplierId: ID!
    $collection: SupplierCollection!
  ) {
    sendSupplierDocumentsRequest(
      supplierId: $supplierId
      collection: $collection
    )
  }
`;

function SupplierDocumentsRequestButton({ supplierId, collection, refetch }) {
  const [isLoading, setIsLoading] = useState(false);

  const { showSuccessNotification } = useNotificationState();

  const [sendSupplierDocumentsRequest] = useMutation(
    sendSupplierDocumentsRequestMutation,
  );

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await sendSupplierDocumentsRequest({
        variables: {
          supplierId,
          collection,
        },
      });

      if (refetch) {
        await refetch();
      }

      showSuccessNotification(
        'Supplier documents request successfully created',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      disabled={isLoading}
      loader={isLoading}
      onClick={handleClick}
      size="tiny"
    >
      Documents Request
    </Button>
  );
}

SupplierDocumentsRequestButton.propTypes = {
  supplierId: string.isRequired,
  collection: string.isRequired,
  refetch: func.isRequired,
};

const LinkButtonS = styled(LinkButton)`
  text-decoration: underline;
`;

export function SupplierDocumentsInfoSection({
  wcFile,
  wcExp,
  glFile,
  glExp,
  scaFile,
  sqqFile,
  exempt,
  supplierId,
  collection,
  refetch,
  subcontractorAgreement,
  openSubcontractorAgreementSidebar,
  mbeCertificationFile,
  wbeCertificationFile,
  veteranCertificationFile,
  isVeteran,
  isWBE,
  minority,
  exemptDocumentsRequest,
  isBranchSupplier,
}) {
  const showDocumentsRequest = !exemptDocumentsRequest && !isBranchSupplier;
  return (
    <SectionWithCard
      title="Documents"
      list={[
        ...(showDocumentsRequest
          ? [
              {
                elem: (
                  <SupplierDocumentsRequestButton
                    collection={collection}
                    supplierId={supplierId}
                    refetch={refetch}
                  />
                ),
                width: 50,
              },
            ]
          : []),
        {
          title: 'Exempt from Document Requests',
          width: showDocumentsRequest ? 50 : 100,
          elem: <BooleanTextWithDot isTrue={exemptDocumentsRequest} />,
        },
        {
          width: exempt ? 100 : 50,
          title: 'Workers Comp Insurance',
          elem: exempt ? (
            <span>Exempt</span>
          ) : (
            <FileLink url={fileUrl(wcFile)} fileName={fileName(wcFile)} />
          ),
        },
        ...(exempt
          ? []
          : [{ title: 'Expires', elem: prepareDate(wcExp), key: 'wcExp' }]),
        {
          title: 'Liability Insurance',
          elem: <FileLink url={fileUrl(glFile)} fileName={fileName(glFile)} />,
        },
        { title: 'Expires', elem: prepareDate(glExp), key: 'glExp' },
        {
          title: 'Subcontractor Agreement',
          elem: subcontractorAgreement ? (
            <LinkButtonS onClick={openSubcontractorAgreementSidebar}>
              Show details
            </LinkButtonS>
          ) : (
            <FileLink url={fileUrl(scaFile)} fileName={fileName(scaFile)} />
          ),
        },
        {
          title: 'Subcontractor Questionnaire',
          elem: (
            <FileLink url={fileUrl(sqqFile)} fileName={fileName(sqqFile)} />
          ),
        },
        ...(minority
          ? [
              {
                title: 'MBE Certification',
                elem: (
                  <FileLink
                    url={fileUrl(mbeCertificationFile)}
                    fileName={fileName(mbeCertificationFile)}
                  />
                ),
              },
            ]
          : []),
        ...(isWBE
          ? [
              {
                title: 'WBE Certification',
                elem: (
                  <FileLink
                    url={fileUrl(wbeCertificationFile)}
                    fileName={fileName(wbeCertificationFile)}
                  />
                ),
              },
            ]
          : []),

        ...(isVeteran
          ? [
              {
                title: 'Veteran Certification',
                elem: (
                  <FileLink
                    url={fileUrl(veteranCertificationFile)}
                    fileName={fileName(veteranCertificationFile)}
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );
}

SupplierDocumentsInfoSection.propTypes = {
  wcFile: shape(fileShape),
  wcExp: string,
  glFile: shape(fileShape),
  glExp: string,
  scaFile: shape(fileShape),
  sqqFile: shape(fileShape),
  exempt: bool,
  supplierId: string.isRequired,
  collection: string.isRequired,
  refetch: func,
  subcontractorAgreement: shape({
    title: string,
    contactName: string,
    ip: string,
    downloadedAt: oneOfType([string, instanceOf(Date)]),
    date: oneOfType([string, instanceOf(Date)]),
  }),
  openSubcontractorAgreementSidebar: func,
  mbeCertificationFile: shape(fileShape),
  wbeCertificationFile: shape(fileShape),
  veteranCertificationFile: shape(fileShape),
  isVeteran: bool,
  isWBE: bool,
  minority: string,
  exemptDocumentsRequest: bool,
  isBranchSupplier: bool,
};

SupplierDocumentsInfoSection.defaultProps = {
  wcFile: {},
  wcExp: null,
  glFile: {},
  glExp: null,
  scaFile: {},
  sqqFile: {},
  exempt: false,
  mbeCertificationFile: {},
  wbeCertificationFile: {},
  veteranCertificationFile: {},
  exemptDocumentsRequest: false,
};
