import React from 'react';
import * as R from 'ramda';
import { arrayOf, func, number, shape, string } from 'prop-types';
import styled from 'styled-components';
import { FieldLayout, TextButton } from 'poly-book-admin';

import { HierarchySelector } from './HierarchySelector.js';
import { HierarchyNodesSelector } from './HierarchyNodesSelector.js';
import { DeleteLineIcon } from '../../../../../components/DeleteLineIcon.js';

const DeleteLineIconS = styled(DeleteLineIcon)`
  margin: 20px 0 0 10px;
`;

const LinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 20px;
  position: relative;
`;

const LineBottom = styled(Line)`
  align-items: center;
  flex-wrap: wrap;
`;

// getHierarchyId :: ([HierarchyLine], Number) -> ID
// HierarchyLine = {
//  hierarchy: ID,
//  hierarchyNode: ID
// }
const getHierarchyId = (value, index) => R.path([index, 'hierarchy'])(value);

// getSelectedHierarchies :: ([HierarchyLine], ID) -> [ID]
const getSelectedHierarchies = (value, currentId) =>
  R.compose(
    R.map(R.prop('hierarchy')),
    R.reject(
      R.either(R.isEmpty, R.compose(R.equals(currentId), R.prop('hierarchy'))),
    ),
  )(value);

export function HierarchiesSelector({
  changeFieldValue,
  formData,
  name,
  index,
  fields: { value, remove, push },
}) {
  const hierarchyId = getHierarchyId(value, index);
  const isLastLine = index === value.length - 1;
  const isAllHierarchiesShowed =
    formData.clientHierarchies.length === value.length;
  const selectedIds = getSelectedHierarchies(value, hierarchyId);

  const onHierarchyChange = (option) => {
    if (!option) {
      return changeFieldValue(name, {});
    }

    return changeFieldValue(name, { hierarchy: option });
  };
  return (
    <LinesWrapper>
      <Line>
        <FieldLayout
          label="Hierarchy"
          layout={{ width: 'calc(30% - 20px)' }}
          field={{
            name: `${name}.hierarchy`,
            additionalProps: {
              selectedIds,
              clientHierarchies: formData.clientHierarchies,
              onHierarchyChange,
              isClearable: true,
            },
            Component: HierarchySelector,
          }}
        />
        {hierarchyId && (
          <FieldLayout
            required
            layout={{ width: 'calc(70% - 20px)' }}
            validators={[[R.identity, 'Hierarchy node is required']]}
            field={{
              name: `${name}.hierarchyNode`,
              additionalProps: {
                clientId: formData.client._id,
                hierarchyId,
              },
              Component: HierarchyNodesSelector,
            }}
          />
        )}
        {index > 0 && (
          <DeleteLineIconS
            name="delete"
            size={14}
            onClick={() => remove(index)}
          />
        )}
      </Line>
      {isLastLine && !isAllHierarchiesShowed && (
        <LineBottom>
          <TextButton onClick={() => push({})}>Add Hierarchy</TextButton>
        </LineBottom>
      )}
    </LinesWrapper>
  );
}

const hierarchyLinePropTypes = shape({
  hierarchy: string,
  hierarchyNode: string,
});

HierarchiesSelector.propTypes = {
  formData: shape({
    clientHierarchies: arrayOf(
      shape({
        clientId: string,
        label: string,
        value: string,
      }),
    ),
  }),
  changeFieldValue: func.isRequired,
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    push: func.isRequired,
    remove: func.isRequired,
    value: arrayOf(hierarchyLinePropTypes).isRequired,
  }),
};
