import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Table } from 'poly-book-admin';
import { useMapConfigToTableProps, useModalContext } from 'poly-admin-ui';
import styled from 'styled-components';
import { arrayOf, bool } from 'prop-types';
import { isSearchTextMatchedAtPaths } from 'poly-client-utils';

import {
  ClientGLCodeForm,
  clientGLCodePropType,
  editClientGlCodeFormId,
} from './ClientGLCodeForm.js';

const TableS = styled(Table)`
  th:nth-child(3),
  td:nth-child(3) {
    width: 35px;
  }
`;

const searchTextPaths = [['code'], ['name']];

// filterGLCodesBySearchText :: String -> [ClientGLCode] -> [ClientGLCode]
const filterGLCodesBySearchText = R.curry((searchText, glCodes) =>
  R.when(
    R.always(searchText),
    R.filter(isSearchTextMatchedAtPaths(searchTextPaths, searchText)),
  )(glCodes),
);

function EditClientGLCodeButton({ glCode }) {
  const { openModalForm } = useModalContext();

  const onClick = () =>
    openModalForm({
      id: editClientGlCodeFormId,
      title: 'Edit Account',
      formId: editClientGlCodeFormId,
      btnCaption: 'Save',
      content: <ClientGLCodeForm glCode={glCode} />,
    });

  return <IconButton size={15} name="edit" onClick={onClick} />;
}

EditClientGLCodeButton.propTypes = {
  glCode: clientGLCodePropType,
};

const getClientGLCodeTableConfig = (hasAccess) => [
  ['Name', R.prop('name')],
  ['Code', R.prop('code')],
  [
    '',
    hasAccess
      ? (glCode) => <EditClientGLCodeButton glCode={glCode} />
      : R.always(null),
  ],
];

export function ClientGLCodesTable({ glCodes, hasAccessToUpdateClientGlCode }) {
  const searchText = useSelector(R.prop('searchText'));

  const tableProps = useMapConfigToTableProps(
    R.identity,
    getClientGLCodeTableConfig(hasAccessToUpdateClientGlCode),
    filterGLCodesBySearchText(searchText, glCodes),
  );

  return <TableS {...tableProps} />;
}

ClientGLCodesTable.propTypes = {
  glCodes: arrayOf(clientGLCodePropType),
  hasAccessToUpdateClientGlCode: bool,
};
