import styled from 'styled-components';
import { getThemeColor, LinkButton, Text } from 'poly-book-admin';

export const MapWrapper = styled.div`
  position: relative;
  width: calc(100% - 300px);
  height: 100%;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListWrapper = styled.div`
  display: flex;
  width: 300px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
  background-color: ${getThemeColor(['lightest'])};
  border-left: 1px solid ${getThemeColor(['newPrimary'])};
  z-index: 1;
`;

export const LinkButtonS = styled(LinkButton)`
  font-size: 16px;
  padding: 8px 15px;
  text-align: start;
`;

export const TooltipLine = styled(Text)`
  line-height: 20px;
`;
