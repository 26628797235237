import React from 'react';
import { arrayOf, instanceOf, shape, string, oneOfType } from 'prop-types';
import { openPrintWindowWithData } from 'poly-client-utils';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import { LinkButton } from 'poly-book-admin';

import { PrintHouseKeepingServiceTicketsReportTable } from './HouseKeepingServiceTicketsReportTable.js';
import { formatExportHeader } from './houseKeepingServiceTicketsHelpers.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';

export function HouseKeepingServiceTicketsReportPrintBtn({
  projects,
  startDate,
  endDate,
}) {
  const Layout = usePrintLayout();

  const onClick = () =>
    openPrintWindowWithData({
      Layout,
      projects,
      isPrint: true,
      fileName: 'bass_pro_service_tickets',
      Table: PrintHouseKeepingServiceTicketsReportTable,
      metaData: { title: formatExportHeader(startDate, endDate) },
    });
  return (
    <LinkButton disabled={projects.length === 0} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

HouseKeepingServiceTicketsReportPrintBtn.propTypes = {
  projects: arrayOf(
    shape({
      projectId: string.isRequired,
      property: shape({ name: string.isRequired }),
    }),
  ),
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
};
