import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';
import { getThemeColor, getThemeFont, Text } from 'poly-book-admin';
import { NOTHING_UI_STRING } from 'poly-constants';
import { forceTitleCase } from 'poly-utils';

import {
  materSupplierStatusLabelUI,
  masterSupplierStatusColorUI,
} from '../../../../constants/masterSuppliers.js';
import {
  FlexColumn,
  FlexFullWidthJustifyStart,
  SearchResultItemContainer,
} from './common/wrappers.js';
import {
  SearchResultItemDivider,
  SearchResultItemLinked,
  SearchResultItemSpace,
  SearchResultItemTitle,
  StatusS,
} from './common/components.js';
import { addressPath } from './common/selectors.js';
import { SearchResultNameWithPrefixItems } from './SuppliersSearchResult.js';
import { highlightedTextPropType } from './common/propTypes.js';

const MasterSupplierSourceTagS = styled(Text)`
  margin: 5px 0;
  font-size: 13px;
  color: ${getThemeColor(['newPrimary'])};
  font-weight: ${getThemeFont(['medium'])};
`;

export function MasterSuppliersSearchResult({
  company,
  status,
  cardNumber,
  type,
  remit,
  tax,
}) {
  return (
    <SearchResultItemContainer>
      <FlexColumn>
        <FlexFullWidthJustifyStart>
          <SearchResultItemLinked>{company.name}</SearchResultItemLinked>
          <SearchResultItemSpace />
          <SearchResultItemTitle>
            {cardNumber || NOTHING_UI_STRING}
          </SearchResultItemTitle>
        </FlexFullWidthJustifyStart>
        <SearchResultItemDivider />
        <SearchResultNameWithPrefixItems
          company={company}
          remit={remit}
          tax={tax}
        />
        <SearchResultItemTitle>{addressPath(company)}</SearchResultItemTitle>
        <MasterSupplierSourceTagS>
          {forceTitleCase(type)}
        </MasterSupplierSourceTagS>
      </FlexColumn>
      <StatusS
        text={materSupplierStatusLabelUI[status]}
        color={masterSupplierStatusColorUI[status]}
      />
    </SearchResultItemContainer>
  );
}

MasterSuppliersSearchResult.propTypes = {
  company: shape({
    name: highlightedTextPropType.isRequired,
    address: shape({
      formatted_address: highlightedTextPropType.isRequired,
    }).isRequired,
  }).isRequired,
  status: highlightedTextPropType.isRequired,
  cardNumber: highlightedTextPropType,
  type: highlightedTextPropType.isRequired,
  remit: shape({
    name: highlightedTextPropType,
  }),
  tax: shape({
    name: highlightedTextPropType,
  }),
};
