import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Checkbox } from 'poly-book-admin/src/Checkbox/index.js';
import { supplierTypes } from 'poly-constants/src/suppliers.js';
import { Icon } from 'poly-book-admin/src/Icon/index.js';
import { Popover } from 'poly-book-admin/src/Popover/index.js';
import { getThemeColor } from 'poly-book-admin/src/utils.js';

import { SupplierTypeSelect } from '../components/SupplierTypeSelect.js';
import { SupplierStatusSelect } from '../components/SupplierStatusSelect.js';
import { halfWidth } from '../common.js';

const PopoverContent = styled.div`
  padding: 10px;
  font-size: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  label {
    font-size: 12px;
    color: ${getThemeColor(['darkest'])};
  }
`;

function MakePrivateCheckbox(props) {
  return (
    <Wrapper>
      <Checkbox label="Make private" position="left" {...props} />{' '}
      <Popover
        title={<Icon name="confirm" size={12} />}
        content={
          <PopoverContent>
            When supplier is set to private only Accounting team and Super Admin
            can view and edit the supplier.
          </PopoverContent>
        }
      />
    </Wrapper>
  );
}

export const supplierPrivateFormFields = {
  order: 2,
  layout: { row: 2, width: halfWidth },
  field: {
    name: 'isPrivate',
    Component: MakePrivateCheckbox,
  },
  renderIf: R.propEq('type', supplierTypes.ADMINISTRATIVE),
};

export const accountSection = (
  supplier,
  isSupplierTypeEditable,
  hasAccessToCreatePrivateSupplier,
) => [
  {
    label: 'Supplier Settings',
    order: 1,
    layout: { column: 1 },
    fields: [
      {
        label: 'Supplier Type',
        order: 1,
        layout: { row: 1, width: halfWidth },
        required: true,
        field: {
          name: 'type',
          Component: (props) => (
            <SupplierTypeSelect
              {...props}
              supplier={supplier}
              isSupplierTypeEditable={isSupplierTypeEditable}
            />
          ),
          withFormData: true,
        },
      },
      {
        label: 'Status',
        order: 2,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'status',
          Component: SupplierStatusSelect,
        },
        required: true,
      },
      ...(hasAccessToCreatePrivateSupplier ? [supplierPrivateFormFields] : []),
    ],
  },
];
