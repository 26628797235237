import React from 'react';
import styled from 'styled-components';
import { PRINT_TYPES, PRINT_PDF_CAPTION } from 'poly-constants';

import { ITEMS_LIMIT } from '../../modules/core/constants/print.js';
import { TablePrintBtn } from '../../modules/core/tableToolbar/TablePrintPDFBtn.js';
import { tasksPropTypes } from './propTypes.js';

const MyTasksPrintPDFBtnS = styled(TablePrintBtn)`
  margin: 0 20px 8px 0;
`;

export function MyTasksPrintBtn({ tasks }) {
  const props = {
    tasks,
    showWarningModal: tasks > ITEMS_LIMIT,
    printType: PRINT_TYPES.MY_TASKS,
    metaData: {
      title: 'My Tasks',
    },
  };

  return (
    <MyTasksPrintPDFBtnS {...props}>{PRINT_PDF_CAPTION}</MyTasksPrintPDFBtnS>
  );
}

MyTasksPrintBtn.propTypes = {
  tasks: tasksPropTypes,
};
