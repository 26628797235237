import * as R from 'ramda';
import { roundTo, assocBy, formatDate } from 'poly-utils';

import {
  alwaysEmptyRow,
  alwaysTextHeaderRow,
  assocTargetTransactionLinesForProp,
  assocWithTransactionsTotal,
  getTotalTransactionsRow,
  getTransactionsSection,
  RECONCILIATION_CUSTOM_ROW_TYPE,
} from './generateTableRowsHelpers.js';

// getBeginningBalanceRow :: ReconciliationReportResult -> ReconciliationCustomRow
const getBeginningBalanceRow = (isCreditCard) =>
  R.compose(
    R.mergeRight({
      _id: 'beginning_balance',
      type: RECONCILIATION_CUSTOM_ROW_TYPE,
      isBold: true,
    }),
    R.applySpec({
      balance: R.prop('beginningBalance'),
      text: R.compose(
        R.concat(
          `Balance Per ${isCreditCard ? 'Card' : 'Bank'} Statement as of `,
        ),
        formatDate,
        R.prop('endingDate'),
      ),
    }),
  );

// alwaysClearedTransactionsRow :: _ -> ReconciliationCustomRow
const alwaysClearedTransactionsRow = alwaysTextHeaderRow(
  'Cleared Transactions',
  'cleared_transactions',
);

// alwaysOutstandingTransactionsRow :: _ -> ReconciliationCustomRow
const alwaysOutstandingTransactionsRow = alwaysTextHeaderRow(
  'Outstanding Transactions',
  'outstanding_transactions',
);

// getTotalClearedTransactionsRow :: ReconciliationReportResult -> ReconciliationCustomRow
const getTotalClearedTransactionsRow = R.compose(
  getTotalTransactionsRow(
    'Total Cleared Transactions',
    'cleared_transactions_total',
  ),
  roundTo(2),
  R.converge(R.add, [R.prop('depositsTotal'), R.prop('paymentsTotal')]),
);

// getGlCurrentBalanceRow :: ReconciliationReportResult -> ReconciliationCustomRow
const getGlCurrentBalanceRow = R.compose(
  R.mergeRight({
    _id: 'current_gl_balance_row',
    text: 'Balance per GL',
    type: RECONCILIATION_CUSTOM_ROW_TYPE,
    isBold: true,
    hasNoTopAmountBorder: true,
  }),
  R.objOf('balance'),
  R.prop('registerBalance'),
);

// getGlReconciledBalanceRow :: ReconciliationReportResult -> ReconciliationCustomRow
const getGlReconciledBalanceRow = (isCreditCard) =>
  R.compose(
    R.mergeRight({
      _id: 'reconciled_gl_balance_row',
      text: `Reconciled ${isCreditCard ? 'Card' : 'Bank'} Balance`,
      type: RECONCILIATION_CUSTOM_ROW_TYPE,
      isBold: true,
      hasAmountBorder: true,
      hasNoTopAmountBorder: true,
      indentLevel: 1,
    }),
    R.objOf('amount'),
    roundTo(2),
    R.converge(R.add, [
      R.prop('endingBalance'),
      R.prop('outstandingTransactionsTotal'),
    ]),
  );

// getGlDifferenceRow :: ReconciliationReportResult -> ReconciliationCustomRow
const getGlDifferenceRow = (isCreditCard) =>
  R.compose(
    R.mergeRight({
      _id: 'difference_row',
      text: 'Difference',
      subText: `(Reconciled ${
        isCreditCard ? 'Card' : 'Bank'
      } Balance And Balance Per GL)`,
      type: RECONCILIATION_CUSTOM_ROW_TYPE,
      isBold: true,
      hasAmountBorder: true,
      hasNoTopAmountBorder: true,
    }),
    R.objOf('amount'),
    roundTo(2),
    R.converge(R.subtract, [
      R.prop('registerBalance'),
      R.compose(
        R.when(R.always(isCreditCard), R.multiply(-1)),
        R.converge(R.add, [
          R.prop('endingBalance'),
          R.prop('outstandingTransactionsTotal'),
        ]),
      ),
    ]),
  );

// getClearedBalanceRow :: ReconciliationReportResult -> ReconciliationCustomRow
const getClearedBalanceRow = R.compose(
  R.mergeRight({
    _id: 'cleared_balance',
    type: RECONCILIATION_CUSTOM_ROW_TYPE,
    text: 'Cleared Balance',
  }),
  R.objOf('balance'),
  R.prop('clearedBalance'),
);

// formatTransactionsLines :: ReconciliationReportResult -> ReconciliationReportResult
const formatTransactionsLines = R.compose(
  assocTargetTransactionLinesForProp('outstandingPayments'),
  assocTargetTransactionLinesForProp('outstandingDeposits'),
  assocTargetTransactionLinesForProp('deposits'),
  assocTargetTransactionLinesForProp('payments'),
);

// calculateTransactionsTotals :: ReconciliationReportResult -> ReconciliationReportResult
const calculateTransactionsTotals = R.compose(
  assocWithTransactionsTotal('outstandingPaymentsTotal', 'outstandingPayments'),
  assocWithTransactionsTotal('outstandingDepositsTotal', 'outstandingDeposits'),
  assocWithTransactionsTotal('paymentsTotal', 'payments'),
  assocWithTransactionsTotal('depositsTotal', 'deposits'),
);

// assocWithOutstandingTransactionsTotal :: ReconciliationReportResult -> ReconciliationReportResult
const assocWithOutstandingTransactionsTotal = assocBy(
  'outstandingTransactionsTotal',
  R.converge(R.add, [
    R.prop('outstandingDepositsTotal'),
    R.prop('outstandingPaymentsTotal'),
  ]),
);

// getOutstandingTransactionsTotalRow :: ReconciliationReportResult -> ReconciliationCustomRow
const getOutstandingTransactionsTotalRow = R.compose(
  R.mergeRight({
    _id: 'outstanding_transactions_total',
    type: RECONCILIATION_CUSTOM_ROW_TYPE,
    text: 'Total Outstanding Transactions',
    isBold: true,
    hasAmountBorder: true,
    hasNoTopAmountBorder: true,
    indentLevel: 1,
  }),
  R.objOf('amount'),
  roundTo(2),
  R.converge(R.add, [
    R.prop('outstandingPaymentsTotal'),
    R.prop('outstandingDepositsTotal'),
  ]),
);

// generateReconciliationReportRows :: ([ReportSection], [ReportSection], Bool) ->
//   {reconciliationReport: ReconciliationReportResult} -> [ReconciliationReportRow]
//
//   ReconciliationReportRow = {
//     _id: String
//     type: String
//     isEmpty: Bool
//     ...MoneyTransaction
//   }
export const generateReconciliationReportRows = (
  reportSections,
  outstandingReportSections,
  isCreditCard,
) =>
  R.compose(
    R.flatten,
    R.juxt([
      getBeginningBalanceRow(isCreditCard),
      alwaysOutstandingTransactionsRow,
      ...getTransactionsSection(outstandingReportSections),
      getOutstandingTransactionsTotalRow,
      getGlReconciledBalanceRow(isCreditCard),
      getGlCurrentBalanceRow,
      alwaysEmptyRow,
      getGlDifferenceRow(isCreditCard),
      alwaysEmptyRow,
      alwaysClearedTransactionsRow,
      ...getTransactionsSection(reportSections),
      getTotalClearedTransactionsRow,
      getClearedBalanceRow,
    ]),
    assocWithOutstandingTransactionsTotal,
    calculateTransactionsTotals,
    formatTransactionsLines,
    R.propOr({}, 'reconciliationReport'),
  );
