import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { differenceInDays } from 'date-fns';
import { shape, string, arrayOf, number, bool } from 'prop-types';
import { applyProp, ensureIsDate, centsToDollarsWithFormat } from 'poly-utils';
import { moneyColumnStyles, Table } from 'poly-book-admin';
import { formatDateOrNothingUI } from 'poly-client-utils';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { NOTHING_UI_STRING } from 'poly-constants';

import {
  commonAgingReportInvoicesTableStyles,
  AgingReportInvoicesTableWrapper,
  AgingReportFooterContainer,
  AgingReportTableLabelRow,
} from './styledComponents.js';
import { invoicesPropTypes } from './prop-types.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { InvoiceDueDateWithWarning } from '../PaySuppliersPage/InvoiceDueDateWithWarning.js';

const APAgingReportInvoicesTable = styled(Table)`
  ${commonAgingReportInvoicesTableStyles};

  th:nth-child(5),
  td:nth-child(5) {
    width: 40px;
  }

  ${moneyColumnStyles(6)};
  ${moneyColumnStyles(7)};
  ${moneyColumnStyles(8)};
  ${moneyColumnStyles(9)};
  ${moneyColumnStyles(10)};

  th:nth-child(11),
  td:nth-child(12),
  th:nth-child(13),
  td:nth-child(14) {
    width: 110px;
  }
`;

export const APAgingReportFooterContainer = styled(AgingReportFooterContainer)`
  td:nth-child(7) {
    width: 220px;
  }
`;

// prepareInvoicesForTable :: (Company, String) -> APAgingReportInvoice -> Invoice
export const prepareInvoicesForTable = ({ name }, popoverPosition) =>
  R.map(
    R.converge(R.mergeDeepLeft, [
      R.applySpec({
        popoverPosition: R.always(popoverPosition),
        supplierName: R.always(name),
        dateInvoiced: R.path(['clientInvoice', 'createdAt']),
        paymentReceived: R.path(['clientInvoice', 'paidAt']),
        dueDate: R.path(['invoice', 'paymentDueDate']),
      }),
      R.prop('invoice'),
    ]),
  );

// getDaysFromNowByProp :: String -> Invoice -> Number
export const getDaysFromNowByProp = (prop) =>
  R.compose(
    (date) => differenceInDays(Date.now(), ensureIsDate(date)),
    R.prop(prop),
  );

// getTotalByDaysPart :: (Number, Number, String, String) -> Invoice -> Number
export const getTotalByDaysPart = (
  from,
  to,
  dateProp = 'invoiceDate',
  totalProp = 'total',
) =>
  R.ifElse(
    R.pipe(
      getDaysFromNowByProp(dateProp),
      R.both((days) => R.lt(from, days), R.gte(to)),
    ),
    R.prop(totalProp),
    R.always(0),
  );

// getCellAmountByDaysPartFormatted :: (Number, Number) -> Invoice -> String
const getCellAmountByDaysPartFormatted = (from, to) =>
  R.compose(centsToDollarsWithFormat, getTotalByDaysPart(from, to));

// sumAndFormatTotal :: [Number] -> String
export const sumAndFormatTotal = R.compose(
  centsToDollarsWithFormat,
  R.reduce(R.add, 0),
  R.defaultTo([]),
);

// sumAndFormatCentsToDollars :: [Number] -> String
export const sumAndFormatCentsToDollars = R.compose(
  centsToDollarsWithFormat,
  R.reduce(R.add, 0),
  R.defaultTo([]),
);

// getTotalAmountByDaysPartFormatted :: (Number, Number) -> [Invoice] -> String
const getTotalAmountByDaysPartFormatted = (from, to) =>
  R.compose(sumAndFormatCentsToDollars, R.map(getTotalByDaysPart(from, to)));

// getTotalInvoicesFormatted :: [Invoice] -> String
const getTotalInvoicesFormatted = R.compose(
  sumAndFormatCentsToDollars,
  R.map(R.prop('total')),
);

const apAgingReportInvoicesTableConfig = (isPrintPDF) => [
  ['Invoice #', R.prop('invoiceNumber')],
  ['Invoice Date', applyProp(formatDateOrNothingUI)('invoiceDate')],
  [
    'Project #',
    R.compose(
      R.ifElse(
        R.isNil,
        R.always(NOTHING_UI_STRING),
        R.compose(ProjectLink, R.mergeRight({ isPrintPDF })),
      ),
      R.prop('project'),
    ),
  ],
  ['Due Date', InvoiceDueDateWithWarning],
  ['Age', getDaysFromNowByProp('invoiceDate')],
  ['Current', getCellAmountByDaysPartFormatted(-1, 30)],
  ['>30 days', getCellAmountByDaysPartFormatted(30, 60)],
  ['>60 days', getCellAmountByDaysPartFormatted(60, 90)],
  ['>90 days', getCellAmountByDaysPartFormatted(90, Infinity)],
  ['Total', applyProp(centsToDollarsWithFormat)('total')],
  ['Date Invoiced', applyProp(formatDateOrNothingUI)('dateInvoiced')],
  ['Pmt. Rec`d', applyProp(formatDateOrNothingUI)('paymentReceived')],
];

function TotalFooter({ rows }) {
  const label = `Total ${R.path([0, 'supplierName'], rows)}`;
  return (
    <APAgingReportFooterContainer>
      <td>{label}</td>
      <td>{getTotalAmountByDaysPartFormatted(-1, 30)(rows)}</td>
      <td>{getTotalAmountByDaysPartFormatted(30, 60)(rows)}</td>
      <td>{getTotalAmountByDaysPartFormatted(60, 90)(rows)}</td>
      <td>{getTotalAmountByDaysPartFormatted(90, Infinity)(rows)}</td>
      <td>{getTotalInvoicesFormatted(rows)}</td>
      <td />
    </APAgingReportFooterContainer>
  );
}

TotalFooter.propTypes = {
  rows: arrayOf(
    shape({ supplierName: string.isRequired, total: number.isRequired }),
  ).isRequired,
};

export function APAgingReportTableComp({
  company,
  invoices,
  isPrintPDF,
  popoverPosition,
}) {
  const tableProps = useMapConfigToTableProps(
    prepareInvoicesForTable(company, popoverPosition),
    apAgingReportInvoicesTableConfig(isPrintPDF),
    invoices,
  );

  return (
    <AgingReportInvoicesTableWrapper>
      <AgingReportTableLabelRow>
        {R.prop('name', company)}
      </AgingReportTableLabelRow>
      <APAgingReportInvoicesTable
        {...tableProps}
        TableFooter={TotalFooter}
        minHeightLess
      />
    </AgingReportInvoicesTableWrapper>
  );
}

APAgingReportTableComp.propTypes = {
  company: shape({ name: string }).isRequired,
  invoices: invoicesPropTypes.isRequired,
  isPrintPDF: bool,
  popoverPosition: string,
};
