import * as R from 'ramda';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { shape, string, bool, number, oneOfType, node } from 'prop-types';
import { useTrimValueByComponentProps } from 'poly-client-utils';

import { Icon } from '../Icon/index.js';
import { LabelS } from '../InputLabel/index.js';
import { InputNumber } from '../InputNumber/index.js';
import { defaultTheme } from '../ThemeProvider/index.js';
import { ColumnDirection } from '../lib/ColumnDirection.js';
import { CharacterCount } from '../CharacterCount/CharacterCount.js';
import { inputHasError, markAsRequired } from '../lib/inputs.js';
import { getThemeColor, getThemeFont } from '../utils.js';

const { secondaryMid } = defaultTheme.colors;

export const getInputBorderColor = R.cond([
  [inputHasError, getThemeColor(['secondaryMid'])],
  [R.prop('warning'), getThemeColor(['notificator', 'warning', 'text'])],
  [R.T, getThemeColor(['midLight'])],
]);

const disabledInputStyle = css`
  cursor: not-allowed;
  background-color: ${getThemeColor(['secondaryLighter2'])};
`;

const inputCommonStyles = css`
  color: ${getThemeColor(['primary'])};
  background-color: ${getThemeColor(['white'])};
  ${markAsRequired};
  border: solid 1px ${getInputBorderColor};
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: ${getThemeFont(['regular'])};
  &::placeholder {
    color: ${getThemeColor(['midDark'])};
  }
  ${({ disabled }) => disabled && disabledInputStyle};
`;

const BigInput = css`
  height: 50px;
  line-height: 50px;
  padding: 15px;
  font-size: 15px;
  ${inputCommonStyles};
`;

const RegularInput = css`
  height: ${R.propOr('30px', 'height')};
  line-height: 18px;
  font-size: 12px;
  padding: 6px 10px;
  ${inputCommonStyles};
`;

const SmallInput = css`
  height: ${R.propOr('26px', 'height')};
  line-height: 12px;
  font-size: 10px;
  padding: 6px 10px;
  ${inputCommonStyles};
`;

const InputMsg = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: ${getThemeFont(['medium'])};
  padding-top: 5px;
  display: inline-block;
`;

export const InputErrorMsg = styled(InputMsg)`
  color: ${getThemeColor(['secondaryMid'])};
`;

export const InputWarningMsg = styled(InputMsg)`
  color: ${getThemeColor(['notificator', 'warning', 'text'])};
`;

const inputStyles = {
  big: BigInput,
  regular: RegularInput,
  small: SmallInput,
};

const withInputStyles = (component) => styled(component).attrs(
  ({ dataTestId }) => ({ 'data-testid': dataTestId }),
)`
  ${({ size }) => inputStyles[size]};
  ${({ fontSize }) =>
    fontSize
      ? css`
          font-size: ${fontSize};
        `
      : css``}
`;

const DefaultInput = styled(withInputStyles('input'))`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const inputComponents = {
  tel: withInputStyles(InputNumber),
  numberMask: withInputStyles(InputNumber),
  input: DefaultInput,
};

// getDefaultInputValueLength :: { value: String } -> Int
const getDefaultInputValueLength = R.compose(R.length, R.propOr('', 'value'));

// getRequiredIconPosition :: { label: String, size: String } -> String
const getRequiredIconPosition = R.cond([
  [R.prop('label'), R.always('33px')],
  [R.propEq('size', 'small'), R.always('5px')],
  [R.T, R.always('8px')],
]);

export const Input = React.forwardRef(
  (
    {
      name,
      size,
      type,
      meta,
      label,
      error,
      width,
      warning,
      hasError,
      required,
      className,
      dataTestId,
      spellCheck,
      labelProps,
      labelPosition,
      charactersLimit,
      showCharactersLeft,
      ...props
    },
    ref,
  ) => {
    const propsByTrimmedValue = useTrimValueByComponentProps(props);

    const [characterCount, setCharacterCount] = useState(
      getDefaultInputValueLength(propsByTrimmedValue),
    );

    const inputProps = R.omit(['mutators', 'skipTrim'], {
      ...props,
      ...propsByTrimmedValue,
    });

    const errorMsg = error || (meta?.touched && meta?.error);

    const InputComponentElement = inputComponents[type] || DefaultInput;

    const RequiredIconS = styled(Icon)`
      width: 16px;
      position: absolute;
      top: ${getRequiredIconPosition({ label, size })};
      right: 10px;
    `;

    const onKeyUp = (event) => {
      setCharacterCount(event.target.value.length);
    };

    return (
      <ColumnDirection {...{ labelPosition, width, className }}>
        {label ? (
          <LabelS {...labelProps} labelPosition={labelPosition} htmlFor={name}>
            {label}
          </LabelS>
        ) : null}
        <InputComponentElement
          {...{
            ref,
            size,
            type,
            name,
            error,
            warning,
            hasError,
            required,
            dataTestId,
            spellCheck,
            // put id only if component manages label
            ...(label ? { id: name } : {}),
            ...inputProps,
            ...(showCharactersLeft ? { maxLength: charactersLimit } : {}),
            onKeyUp,
          }}
        />
        {errorMsg && !inputProps.value && (
          <RequiredIconS name="required" color={secondaryMid} />
        )}
        {showCharactersLeft && (
          <CharacterCount limit={charactersLimit} length={characterCount} />
        )}
        {errorMsg && <InputErrorMsg>{errorMsg}</InputErrorMsg>}
        {warning && <InputWarningMsg>{warning}</InputWarningMsg>}
      </ColumnDirection>
    );
  },
);

Input.displayName = 'Input';
Input.propTypes = {
  name: string.isRequired,
  hasError: bool,
  label: string,
  labelPosition: string,
  error: oneOfType([string, bool, node]),
  className: string,
  size: string,
  type: string,
  required: bool,
  spellCheck: bool,
  width: string,
  warning: oneOfType([string, node]),
  dataTestId: string,
  meta: shape({
    touched: bool,
    error: oneOfType([string, bool, node]),
  }),
  labelProps: shape({ color: string, size: number }),
  showCharactersLeft: bool,
  charactersLimit: number,
};

Input.defaultProps = {
  hasError: false,
  error: '',
  className: '',
  label: '',
  size: 'regular',
  type: 'text',
  required: false,
  spellCheck: true,
  dataTestId: 'input',
  showCharactersLeft: false,
  charactersLimit: 100,
};
