import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { entityToOptionByLabelPath } from 'poly-client-utils';
import { Select } from 'poly-book-admin';

const clientProjectSubCategoriesQuery = gql`
  query clientProjectSubCategoriesQuery($clientId: ID!) {
    client(id: $clientId) {
      _id
      projectSubCategories {
        _id
        name
      }
    }
  }
`;

// getProjectSubCategoriesDropdownOptions :: QueryData -> [Option]
// QueryData = {client: Client}
const getProjectSubCategoriesDropdownOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['client', 'projectSubCategories']),
);

export function ProjectSubCategorySelect({ formData, ...props }) {
  const clientId = R.path(['client', '_id'], formData);

  const { data } = useQuery(clientProjectSubCategoriesQuery, {
    variables: { clientId },
    skip: !clientId,
  });

  const options = getProjectSubCategoriesDropdownOptions(data);

  const selectProps = {
    options,
    placeholder: 'Select subcategory',
    ...props,
  };

  return <Select {...selectProps} />;
}

ProjectSubCategorySelect.propTypes = {
  formData: shape({
    client: shape({
      _id: string.isRequired,
    }),
  }),
};
