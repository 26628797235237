import * as R from 'ramda';
import { ProjectType, RecurringProjectTypes } from 'poly-constants';
import {
  getEndDateByProjectWithClient,
  addNewLineToLastOpsToForm,
  applySpecWithFields,
  removePropDeep,
  renameProp,
  assocBy,
} from 'poly-utils';

import { EMPTY_PROPERTY_LOCATION_VALUE } from '../form/utils/constants.js';
import { ProjectOccurrence } from '../../../../modules/core/constants/projects.js';

// constructDate :: String -> Date
const constructDate = R.constructN(1, Date);

// projectToEditFormBase :: Project -> FormObject
const projectToEditFormBase = R.compose(
  R.when(
    R.propSatisfies(
      R.includes(R.__, [
        ProjectOccurrence.RECURRING_OCCURRENCE,
        ProjectOccurrence.PREVENTIVE_MAINTENANCE_OCCURRENCE,
      ]),
      'occurrence',
    ),
    R.compose(
      R.over(
        R.lensProp('masterProjectSuppliers'),
        R.compose(
          R.map(
            R.over(
              R.lensProp('additionalSupplierWOCEmails'),
              R.when(
                Array.isArray,
                R.map(R.applySpec({ value: R.identity, label: R.identity })),
              ),
            ),
          ),
          R.map(R.unless(R.prop('attachments'), R.assoc('attachments', []))),
          R.when(R.isEmpty, R.always([{ disableWOCEmail: true }])),
        ),
      ),
      R.compose(renameProp('suppliers', 'masterProjectSuppliers')),
    ),
  ),
  addNewLineToLastOpsToForm('invoiceDescription'),
  R.dissocPath(['preventativeRepair', 'childProject']),
  R.dissocPath(['preventativeRepair', 'relatedAsset']),
  R.converge(R.mergeRight, [
    R.pathOr({}, ['payload', ProjectType.HOUSEKEEPING]),
    applySpecWithFields({
      description: R.propOr('', 'description'),
      clientId: R.path(['client', '_id']),
      startDate: R.compose((_) => new Date(_), R.prop('startDate')),
      endDate: R.compose(
        getEndDateByProjectWithClient,
        assocBy(
          'endDate',
          R.compose(R.unless(R.isNil, constructDate), R.prop('endDate')),
        ),
      ),
      never: R.compose(R.not, R.prop('endDate')),
      serviceTypeId: R.path(['serviceType', '_id']),
      managerId: R.path(['manager', '_id']),
      supplierId: R.pathOr(null, ['suppliers', 0, '_id']),
      suppliers: R.propOr([], 'suppliers'),
      clientManagerId: R.path(['clientManager', '_id']),
      contactId: R.path(['contact', '_id']),
      priority: R.pathOr('', ['priority']),
      requester: R.pathOr({}, ['requester']),
      type: R.either(R.prop('childType'), R.prop('type')),
      occurrence: R.cond([
        [
          R.propEq('type', RecurringProjectTypes.recurringProject),
          R.always(ProjectOccurrence.RECURRING_OCCURRENCE),
        ],
        [
          R.propEq('type', RecurringProjectTypes.preventiveMaintenanceProject),
          R.always(ProjectOccurrence.PREVENTIVE_MAINTENANCE_OCCURRENCE),
        ],
        [R.T, R.always(ProjectOccurrence.ONE_TIME_OCCURRENCE)],
      ]),
      exemptSalesTax: R.propOr(false, 'exemptSalesTax'),
      check1: R.F,
      check2: R.F,
      feeAmount: R.ifElse(
        R.propIs(String, 'childType'),
        R.path(['payload', ProjectType.FEE, 'feeAmount']),
        R.prop('feeAmount'),
      ),
      feeType: R.ifElse(
        R.propIs(String, 'childType'),
        R.path(['payload', ProjectType.FEE, 'feeType']),
        R.prop('feeType'),
      ),
      customFeeDescription: R.ifElse(
        R.propIs(String, 'childType'),
        R.path(['payload', ProjectType.FEE, 'customFeeDescription']),
        R.prop('customFeeDescription'),
      ),
      bidAmount: R.ifElse(
        R.propIs(String, 'childType'),
        R.path(['payload', ProjectType.BID, 'bidAmount']),
        R.prop('bidAmount'),
      ),
      divisionAccountId: R.path(['division', '_id']),
      notificationConfig: {
        toSiteContact: R.pathOr(false, ['notificationConfig', 'toSiteContact']),
        toRequester: R.pathOr(false, ['notificationConfig', 'toRequester']),
        toClientManager: R.pathOr(false, [
          'notificationConfig',
          'toClientManager',
        ]),
      },
      propertyLocationId: R.compose(
        R.when(R.isNil, R.always(EMPTY_PROPERTY_LOCATION_VALUE)),
        R.path(['propertyLocation', 'id']),
      ),
      propertyLocation: R.prop('propertyLocation'),
      subCategoryId: R.path(['subCategory', '_id']),
      clientGLCodeId: R.compose(R.prop('_id'), R.prop('clientGLCode')),
      adminPurchaseOrderId: R.path(['adminPurchaseOrder', '_id']),
      preventativeRepair: {
        childProjectId: R.pathOr(null, [
          'preventativeRepair',
          'childProject',
          '_id',
        ]),
        relatedAssetId: R.pathOr(null, [
          'preventativeRepair',
          'relatedAsset',
          '_id',
        ]),
      },
      clientReferenceNumber: R.either(
        R.prop('clientReferenceNumber'),
        R.path(['adminPurchaseOrder', 'poNumber']),
      ),
      subPropertiesIds: R.compose(
        R.pluck('_id'),
        R.propOr([], 'subProperties'),
      ),
    }),
  ]),
  removePropDeep('__typename'),
);

// getProjectCustomFields :: Project -> Object
const getProjectCustomFields = R.ifElse(
  R.propEq('type', ProjectType.LISTING),
  R.propOr({}, 'listing'),
  R.always({}),
);

// projectToEditForm :: Project -> FormObject
export const projectToEditForm = R.converge(R.mergeRight, [
  projectToEditFormBase,
  getProjectCustomFields,
]);
