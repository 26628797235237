import * as R from 'ramda';
import { isSameDay } from 'date-fns';
import { TimesheetTypes } from 'poly-constants';
import { roundTimesheetMinutes } from 'poly-book-admin';
import { removePropDeep, ensureIsDate } from 'poly-utils';

import { constructDatePropsForTimeEntry } from './common.js';

// findTimeRecord :: [TimeDate, TimeType] -> [TimeSheet] -> TimeSheet
// eslint-disable-next-line import/no-unused-modules
export const findTimeRecord = ({ date, type }) =>
  R.compose(
    R.find(
      R.allPass([
        R.propSatisfies(
          (it) => isSameDay(ensureIsDate(it), ensureIsDate(date)),
          'date',
        ),
        R.propEq('type', type),
        R.propSatisfies(R.isNil, 'project'),
      ]),
    ),
    R.defaultTo([]),
  );

// findDefaultRecord :: [TimeDate, TimeType] -> [TimeSheet] -> TimeSheet
const findDefaultRecord = findTimeRecord({
  date: new Date(),
  type: TimesheetTypes.STRAIGHT_TIME,
});

const changeValueTemplate = (name, selectorFunc, props) => {
  const { record, changeHandler } = props;
  return changeHandler(name, selectorFunc(name)(record));
};

// entriesSelector :: (String, Date) -> TimeSheet -> [TimeEntries]
// eslint-disable-next-line import/no-unused-modules
export const entriesSelector = (name, holder) =>
  R.compose(
    R.concat(R.__, [{ timeIn: roundTimesheetMinutes(holder || new Date()) }]),
    R.ifElse(
      R.anyPass([R.isNil, R.propSatisfies(R.isNil, name)]),
      R.always([]),
      R.pipe(
        R.prop(name),
        R.defaultTo([]),
        removePropDeep('__typename'),
        R.map(constructDatePropsForTimeEntry),
      ),
    ),
  );

export const changeFormEntries = (dateAndType, timeSheets, changeHandler) => {
  const commonProps = {
    record: findTimeRecord(dateAndType)(timeSheets),
    changeHandler,
  };
  changeValueTemplate('_id', R.prop, commonProps);
  changeValueTemplate('entries', entriesSelector, commonProps);
  changeValueTemplate('notes', R.prop, commonProps);
  changeValueTemplate('totalTimeInMinutes', R.prop, commonProps);
};

// modifyInitialValuesWithMutators :: Date -> [TimeSheet] -> ModifiedInitialValuesObject
export const modifyInitialValuesWithMutators = (dateHolder) =>
  R.compose(
    R.applySpec({
      _id: R.prop('_id'),
      notes: R.prop('notes'),
      entries: entriesSelector('entries', dateHolder),
      totalTimeInMinutes: R.prop('totalTimeInMinutes'),
    }),
    findDefaultRecord,
  );
