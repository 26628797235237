import * as R from 'ramda';
import React from 'react';
import { Text } from 'poly-book-admin';
import { shape } from 'prop-types';
import { alwaysNewDate } from 'poly-utils';

import { PrintProjectFlexRow, PrintProjectFlexColumn } from '../components.js';

// getBase64SupplierInvoices :: Project -> [String]
const getBase64SupplierInvoices = R.propOr([], 'supplierInvoicesBase64Strings');

// getSupplierInvoiceIdByIndex :: Int -> Project -> ID
const getSupplierInvoiceIdByIndex = (index) =>
  R.pathOr(alwaysNewDate().getTime(), ['invoices', index, '_id']);

export function PrintProjectSupplierInvoices({ project }) {
  const base64SupplierInvoices = getBase64SupplierInvoices(project);

  if (R.isEmpty(base64SupplierInvoices)) {
    return null;
  }

  return (
    <PrintProjectFlexColumn>
      <PrintProjectFlexRow>
        <Text size="16px" weight="bold">
          Supplier Invoices
        </Text>
      </PrintProjectFlexRow>
      {base64SupplierInvoices.map((base64Invoice, index) => {
        const supplierInvoiceId = getSupplierInvoiceIdByIndex(index)(project);

        return (
          <img
            key={`${project._id}-${supplierInvoiceId}-key`}
            alt=""
            src={`data:image/png;base64,${base64Invoice}`}
          />
        );
      })}
    </PrintProjectFlexColumn>
  );
}

PrintProjectSupplierInvoices.propTypes = {
  project: shape({}),
};
