import React from 'react';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  MainHeader,
} from 'poly-book-admin';
import { ColumnWrapper, ColumnWrapperRight } from './styles.js';
import { RecentProjectsSearch } from './RecentProjectsSearch.js';
import { RecentProjectsFilter } from './RecentProjectsFilter.js';
import { RecentProjectsExportToXLSBtn } from '../../core/tableToolbar/TableExportToXLSBtn.js';
import { RecentProjectsPrintPDFBtn } from '../../tables/recentProjectsTable/RecentProjectsToolbar.js';

const headerStyles = `
height: 120px;
z-index: 3;
box-shadow: none;
`;

export function RecentProjectsPageHeader(props) {
  return (
    <PageHeaderContainer {...{ headerStyles, ...props }}>
      <ColumnWrapper>
        <MainHeader>Recent Projects</MainHeader>
        <RecentProjectsFilter />
      </ColumnWrapper>
      <ColumnWrapperRight>
        <HeaderLinksWrapper withoutMargin>
          <RecentProjectsPrintPDFBtn />
          <RecentProjectsExportToXLSBtn />
        </HeaderLinksWrapper>
        <RecentProjectsSearch />
      </ColumnWrapperRight>
    </PageHeaderContainer>
  );
}
