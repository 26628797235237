import {
  bool,
  node,
  func,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  useNotificationContext,
  getThemeColor,
  Checkbox,
} from 'poly-book-admin';

const BillingProfileConfigCheckboxS = styled(Checkbox)`
  margin-right: ${({ margin }) => margin || 15}px;
`;

export function BillingProfileConfigCheckbox({ blocked, onChange, ...props }) {
  const { showWarningNotification } = useNotificationContext();

  const onChangeCheckbox = (value) => {
    if (!blocked) {
      onChange(value);
    } else {
      showWarningNotification('This part of logic will be implemented later');
    }
  };

  return (
    <BillingProfileConfigCheckboxS {...props} onChange={onChangeCheckbox} />
  );
}

BillingProfileConfigCheckbox.propTypes = {
  blocked: bool,
  onChange: func.isRequired,
};

const columnPaddingStyles = css`
  &:nth-child(odd) {
    padding-right: 15px;
  }
  &:nth-child(even) {
    padding-left: 15px;
  }
`;

const borderStyles = css`
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid ${getThemeColor(['lighter'])};
`;

const WrapperContent = styled.div`
  display: flex;
  align-items: ${({ topAlign }) => (topAlign ? 'flex-start' : 'center')};
  width: 50%;

  > :first-child {
    ${({ topAlign }) => !!topAlign && 'margin-top: 5px'};
  }
  ${({ fullWidth }) => fullWidth && 'width: 100%'}
  ${({ fullWidth }) => !fullWidth && columnPaddingStyles} 
  ${({ withBorder }) => withBorder && borderStyles}
`;

export const ComponentTitle = styled.div`
  flex-shrink: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 120px;
  color: ${getThemeColor(['primary'])};
  ${({ width }) => width && `width: ${width}`}
  ${({ withBorder }) => withBorder && borderStyles}
`;

export function FieldWrapper({
  title,
  Component,
  topAlign,
  fullWidth,
  titleWidth,
  withBorder,
  ...props
}) {
  return (
    <WrapperContent
      topAlign={topAlign}
      fullWidth={fullWidth}
      withBorder={withBorder}
    >
      {title && <ComponentTitle width={titleWidth}>{title}</ComponentTitle>}
      <Component width="100%" {...props} />
    </WrapperContent>
  );
}

export const multiSelectorValueProperTypes = oneOfType([
  string,
  arrayOf(string),
]);

FieldWrapper.propTypes = {
  topAlign: bool,
  title: multiSelectorValueProperTypes,
  Component: oneOfType([node, func, object]).isRequired,
  fullWidth: bool,
  titleWidth: string,
  withBorder: bool,
};
