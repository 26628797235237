import * as R from 'ramda';
import React, { useMemo } from 'react';
import { number, shape, func, arrayOf, string } from 'prop-types';
import { useCopyDataAsJSONToClipboard } from 'poly-admin-ui/src/hooks/useCopyDataAsJSONToClipboard.js';
import { useGetJSONDataFromClipboard } from 'poly-admin-ui/src/hooks/useGetJSONDataFromClipboard.js';
import { LinkButton } from 'poly-book-admin/src/LinkButton/index.js';
import { Icon } from 'poly-book-admin/src/Icon/index.js';
import { EQUALS_AIT_OPERATOR } from 'poly-security';

import {
  ButtonsWrapper,
  UserGroupPermissionTitleS,
  MissingLoginPermissionWrapperS,
  UserGroupPermissionLineWrapperS,
} from './userGroupFormComponents.js';
import { UserGroupPermissionSection } from './UserGroupPermissionSection.js';
import { useUserGroupSchemaQuery } from '../useUserGroupSchemaQuery.js';
import {
  checkIfHasLoginPermission,
  checkIfLineInEditMode,
} from '../../userGroupManagementUtils.js';

// getErrorMessageByValue :: PermissionFormLine -> String
const getErrorMessageByValue = R.compose(
  R.cond([
    [
      R.complement(checkIfHasLoginPermission),
      R.always('Missing LOGIN permission for at least one app'),
    ],
    [
      checkIfLineInEditMode,
      R.always('Please finish variables editing before form submit'),
    ],
    [R.T, R.always('')],
  ]),
  R.objOf('accessItems'),
);

export function UserGroupPermissionLine({
  name,
  index,
  changeFieldValue,
  fields: { push, value, remove, update },
}) {
  const getDataFromClipboard = useGetJSONDataFromClipboard();
  const copyToClipboard = useCopyDataAsJSONToClipboard();
  const { schema } = useUserGroupSchemaQuery();

  const isFirstLine = index === 0;
  const isSingleLine = value.length === 1;

  const errorMessage = useMemo(() => getErrorMessageByValue(value), [value]);

  const onAddPermission = (e) => {
    e.preventDefault();
    push({ scopes: [{ operator: EQUALS_AIT_OPERATOR }] });
  };

  const onDeletePermission = (e) => {
    e.preventDefault();

    if (isFirstLine && isSingleLine) {
      update(index, { scopes: [{ operator: EQUALS_AIT_OPERATOR }] });
    } else {
      remove(index);
    }
  };

  const onCopyPermissions = async (e) => {
    e.preventDefault();
    await copyToClipboard('permissions', value);
  };

  const onCopySinglePermission = async (e) => {
    e.preventDefault();
    await copyToClipboard('permissions', [value[index]]);
  };

  const onPastePermissions = async (e) => {
    e.preventDefault();
    const { permissions } = await getDataFromClipboard();

    if (permissions) {
      permissions.forEach((permission) => {
        push(permission);
      });
    }
  };

  return (
    <UserGroupPermissionLineWrapperS>
      {isFirstLine && (
        <UserGroupPermissionTitleS>
          Permissions
          {!!errorMessage && (
            <MissingLoginPermissionWrapperS>
              <Icon name="round-warning" size={12} />
              {errorMessage}
            </MissingLoginPermissionWrapperS>
          )}
          <ButtonsWrapper>
            <LinkButton onClick={onAddPermission}>Add Permission</LinkButton>
            <LinkButton onClick={onCopyPermissions}>Copy</LinkButton>
            <LinkButton onClick={onPastePermissions}>Paste</LinkButton>
          </ButtonsWrapper>
        </UserGroupPermissionTitleS>
      )}
      <UserGroupPermissionSection
        {...{
          name,
          index,
          value,
          schema,
          changeFieldValue,
          onDeletePermission,
          onCopySinglePermission,
        }}
      />
    </UserGroupPermissionLineWrapperS>
  );
}

UserGroupPermissionLine.displayName = 'UserGroupPermissionLine';

UserGroupPermissionLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  changeFieldValue: func.isRequired,
  fields: shape({
    push: func.isRequired,
    remove: func.isRequired,
    update: func.isRequired,
    value: arrayOf(shape({ entity: string })).isRequired,
  }),
};
