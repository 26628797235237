import * as R from 'ramda';
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useModalContext, useNotificationState } from 'poly-admin-ui';
import { FormCreator } from 'poly-book-admin';
import { bool, shape, string } from 'prop-types';

import { usePristineSubscribe } from 'poly-client-routing';
import { getDivisionFormSections } from './DivisionFormSections.js';
import { addDivisionFormId, editDivisionFormId } from '../constants.js';

const createSuccessMessage = 'Division was created successfully';
const editSuccessMessage = 'Division was edited successfully';

// eslint-disable-next-line import/no-unused-modules
export const CREATE_DIVISION_MUTATION = gql`
  mutation CREATE_DIVISION($input: DivisionCreateInput!) {
    createDivision(input: $input) {
      _id
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const UPDATE_DIVISION_MUTATION = gql`
  mutation UPDATE_DIVISION($id: ID!, $input: DivisionUpdateInput!) {
    updateDivision(id: $id, input: $input) {
      _id
    }
  }
`;

// getMutationVariables :: String -> Boolean -> FormFields -> Promise _ _
// FormFields = {
//    name: String,
//    status: String,
// }
const getMutationVariables = R.curry((divisionId, isNewDivision, input) =>
  R.compose(
    R.objOf('variables'),
    R.assoc('id', divisionId),
    R.objOf('input'),
    R.reject(R.isEmpty),
    R.ifElse(() => isNewDivision, R.pick(['name']), R.pick(['name', 'status'])),
  )(input),
);

export function DivisionForm({ isNewDivision, document, ...props }) {
  const formId = isNewDivision ? addDivisionFormId : editDivisionFormId;
  const divisionId = R.propOr('', '_id', document);
  const successMessage = isNewDivision
    ? createSuccessMessage
    : editSuccessMessage;
  const submitMutation = isNewDivision
    ? CREATE_DIVISION_MUTATION
    : UPDATE_DIVISION_MUTATION;
  const initialValues = R.applySpec({
    name: R.prop('name'),
    status: R.prop('status'),
  })(document);

  const { showSuccessNotification } = useNotificationState();

  const { closeModal } = useModalContext();
  const [divisionMutation] = useMutation(submitMutation);

  const onSubmit = (input) =>
    divisionMutation(
      getMutationVariables(divisionId, isNewDivision, input),
    ).then(() => {
      showSuccessNotification(successMessage);
      closeModal(formId);
    });

  const pristineSubscribeProps = usePristineSubscribe();

  return (
    <FormCreator
      {...props}
      id={formId}
      formId={formId}
      sections={getDivisionFormSections(isNewDivision)}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={closeModal}
      successMessage={successMessage}
      {...pristineSubscribeProps}
    />
  );
}

DivisionForm.propTypes = {
  isNewDivision: bool.isRequired,
  document: shape({
    _id: string.isRequired,
    name: string.isRequired,
    status: string.isRequired,
  }),
};
