import React from 'react';
import { string } from 'prop-types';
import { entities, SidebarTasksTab } from 'poly-admin-ui';
import { taskCollections } from 'poly-constants';

export function ClientSidebarTasksTab({ clientId, clientName }) {
  const tasksProps = {
    entity: {
      _id: clientId,
      preview: 'client',
      name: entities.CLIENT,
      humanReadableId: clientName,
    },
    collection: taskCollections.CLIENTS,
  };

  return <SidebarTasksTab {...tasksProps} />;
}

ClientSidebarTasksTab.propTypes = {
  clientId: string.isRequired,
  clientName: string.isRequired,
};
