import React from 'react';
import { ForgotPasswordPageBaseAdmin } from 'poly-admin-ui';

import { APP_TITLE } from './LoginPage.js';
import { routesNames } from '../routes/index.js';

export function ForgotPasswordPage() {
  return (
    <ForgotPasswordPageBaseAdmin
      title={APP_TITLE}
      loginRoute={routesNames.LOGIN}
    />
  );
}
