import React from 'react';
import * as R from 'ramda';
import { Field } from 'react-final-form';
import { arrayOf, func, number, shape, string, bool } from 'prop-types';
import {
  FieldLayout,
  TextButton,
  Input,
  CharacterCount,
} from 'poly-book-admin';
import { SystemAccountTypes } from 'poly-constants';
import { MoneyInput } from 'poly-admin-ui';
import styled from 'styled-components';
import { commonFileValidators, validateFilesFunc } from 'poly-client-utils';

import {
  Line,
  LinesWrapper,
} from '../../../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/styled.js';
import { DeleteLineIcon } from '../../../components/DeleteLineIcon.js';
import { rejectReceivableAndPayableAccounts } from '../../../modules/accounting/GLCodeSelect/glCodeSelectUtils.js';
import { calculateJournalEntryTotalByProp } from '../journalEntryUtils.js';
import { isGLCodeOptionDisabled } from '../../../utils/account.js';
import { onKeyDownToPreventFormSubmit } from '../../../utils/form.js';
import { AttachDocumentField } from '../../../components/AttachDocumentField.js';
import {
  prepareAccountsToSelectBase,
  GLCodeSelect,
} from '../../../modules/accounting/GLCodeSelect/GLCodeSelect.js';
import {
  LineButtonsWrapper,
  LineAmountTotal,
  LineAmount,
  BottomLine,
  LineError,
} from '../styled.js';

// getOptionValue :: Account -> Object
const getOptionValue = R.applySpec({
  _id: R.prop('_id'),
  code: R.prop('code'),
  name: R.prop('name'),
  isBankAccount: R.pathEq(
    ['accountType', 'system_type'],
    SystemAccountTypes.BANK_ACCOUNT,
  ),
  isCCAccount: R.pathEq(
    ['accountType', 'system_type'],
    SystemAccountTypes.CREDIT_CARD,
  ),
  isClientPaymentDeduction: R.prop('is_client_payment_deduction'),
  isTravelHQ: R.prop('is_travel_hq'),
});

// checkLineTypeByPath :: [String] -> [FormLine] -> Boolean
const checkLineTypeByPath = (path) =>
  R.compose(R.both(R.is(Number), R.lte(0)), R.path(path));

// formatAccountsOptions :: [Account] -> [AccountOption]
const formatAccountsOptions = prepareAccountsToSelectBase(getOptionValue);

const ReceiptFileFieldWrapper = styled.div`
  width: 300px;
`;

function ReceiptFileField(props) {
  return (
    <ReceiptFileFieldWrapper>
      <AttachDocumentField {...props} dragAreaHeight="30px" label="Receipt" />
    </ReceiptFileFieldWrapper>
  );
}

const debitFieldName = 'debitAmount';
const creditFieldName = 'creditAmount';
const descriptionFieldName = 'description';

function JournalEntryMoneyInput({
  changeFieldValue,
  isFirstLine,
  arrFieldName,
  onChange,
  name,
  ...props
}) {
  const changeFieldByName = (filedName, value) =>
    changeFieldValue(`${arrFieldName}[1].${filedName}`, value);

  const onChangeInternal = (value) => {
    if (isFirstLine) {
      if (R.includes(debitFieldName, name)) {
        changeFieldByName(creditFieldName, value);
      }
      if (R.includes(creditFieldName, name)) {
        changeFieldByName(debitFieldName, value);
      }
    }
    onChange(value);
  };
  return (
    <MoneyInput
      name={name}
      onChange={onChangeInternal}
      {...props}
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

JournalEntryMoneyInput.propTypes = {
  changeFieldValue: func,
  isFirstLine: bool.isRequired,
  onChange: func.isRequired,
  arrFieldName: string.isRequired,
  name: string.isRequired,
};

function DescriptionInput({
  changeFieldValue,
  isFirstLine,
  arrFieldName,
  onChange,
  value,
  ...props
}) {
  const onChangeInternal = (e) => {
    if (isFirstLine) {
      changeFieldValue(
        `${arrFieldName}[1].${descriptionFieldName}`,
        e.target.value,
      );
    }
    onChange(e);
  };

  return (
    <>
      <Input
        {...props}
        onChange={onChangeInternal}
        maxLength="200"
        value={value}
      />
      <CharacterCount limit={200} length={value.length} />
    </>
  );
}

DescriptionInput.propTypes = {
  changeFieldValue: func,
  isFirstLine: bool.isRequired,
  onChange: func.isRequired,
  arrFieldName: string.isRequired,
  value: string.isRequired,
};

// getIsCCAccountSelected :: Int -> [LineValue] -> Boolean
// LineValue = {
//    accountCode: {
//      value: {
//        isCCAccount: Boolean
//      },
//    }
// }
const getIsCCAccountSelected = (lineIndex) =>
  R.compose(R.path(['accountCode', 'value', 'isCCAccount']), R.nth(lineIndex));

export function JournalEntryLineComponent({
  name,
  index,
  fields: { value, remove, push },
  changeFieldValue,
  arrFieldName,
}) {
  const isFirstLine = index === 0;
  const isLastLine = index === value.length - 1;
  const isDefaultLines = index === 0 || index === 1;
  const isDebitLine = checkLineTypeByPath([index, debitFieldName])(value);
  const isCreditLine = checkLineTypeByPath([index, creditFieldName])(value);

  const totalDebits = calculateJournalEntryTotalByProp(debitFieldName)(value);
  const totalCredits = calculateJournalEntryTotalByProp(creditFieldName)(value);
  const isCCAccountSelected = getIsCCAccountSelected(index)(value);
  const { isDisabled } = value[index];

  const additionalProps = {
    changeFieldValue,
    isFirstLine,
    arrFieldName,
  };

  return (
    <LinesWrapper>
      <Line>
        <FieldLayout
          required
          disabled={isDisabled}
          {...(isFirstLine && { label: 'GL Code' })}
          layout={{ width: 'calc(30% - 20px)' }}
          field={{
            name: `${name}.accountCode`,
            additionalProps: {
              formatAccountsOptions,
              isOptionDisabled: isGLCodeOptionDisabled(
                value,
                ['accountCode', 'code'],
                ['value', 'code'],
              ),
              filterAccounts: rejectReceivableAndPayableAccounts,
            },
            Component: GLCodeSelect,
          }}
          validators={[[R.identity, 'GL Code is required']]}
        />
        <FieldLayout
          required={!isCreditLine}
          disabled={isCreditLine || isDisabled}
          {...(isFirstLine && { label: 'Debit' })}
          layout={{ width: 'calc(15% - 20px)' }}
          field={{
            name: `${name}.${debitFieldName}`,
            Component: JournalEntryMoneyInput,
            additionalProps,
          }}
          {...(!isCreditLine && {
            validators: [[R.identity, 'Debit is required']],
          })}
        />
        <FieldLayout
          required={!isDebitLine}
          disabled={isDebitLine || isDisabled}
          {...(isFirstLine && { label: 'Credit' })}
          layout={{ width: 'calc(15% - 20px)' }}
          field={{
            name: `${name}.${creditFieldName}`,
            Component: JournalEntryMoneyInput,
            additionalProps: {
              ...additionalProps,
              disabled: isDisabled,
            },
          }}
          {...(!isDebitLine && {
            validators: [[R.identity, 'Credit is required']],
          })}
        />
        <FieldLayout
          {...(isFirstLine && { label: 'Description' })}
          layout={{ width: 'calc(40% - 20px)' }}
          disabled={isDisabled}
          field={{
            name: `${name}.${descriptionFieldName}`,
            Component: DescriptionInput,
            additionalProps: {
              ...additionalProps,
              onKeyDown: onKeyDownToPreventFormSubmit,
            },
          }}
        />
        {!isDefaultLines && !isDisabled && (
          <DeleteLineIcon
            name="delete"
            size={14}
            onClick={() => remove(index)}
          />
        )}
      </Line>
      {isCCAccountSelected && (
        <Line>
          <FieldLayout
            field={{
              name: `${name}.invisibleField`,
              Component: () => null,
            }}
          />
          <FieldLayout
            layout={{ width: 'calc(64% - 20px)' }}
            field={{
              name: `${name}.receiptFile`,
              Component: ReceiptFileField,
              additionalProps: {
                readOnly: isDisabled,
              },
            }}
            validators={commonFileValidators}
            validateFunction={validateFilesFunc}
          />
        </Line>
      )}
      {isLastLine && (
        <BottomLine>
          <LineButtonsWrapper>
            <TextButton onClick={() => push({ receiptFile: [] })}>
              Add Line
            </TextButton>
          </LineButtonsWrapper>
          <LineAmountTotal>
            <span>Total:</span>
            <span>{totalDebits}</span>
          </LineAmountTotal>
          <LineAmount>{totalCredits}</LineAmount>
          <Field name="total">
            {({ meta }) =>
              meta.error ? <LineError>{meta.error}</LineError> : null
            }
          </Field>
        </BottomLine>
      )}
    </LinesWrapper>
  );
}

JournalEntryLineComponent.displayName = 'JournalEntryLine';

JournalEntryLineComponent.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    push: func.isRequired,
    remove: func.isRequired,
    value: arrayOf(
      shape({
        isDisabled: bool,
        debitAmount: number,
        creditAmount: number,
        accountCode: shape({
          value: shape({
            isCCAccount: bool,
          }),
        }),
      }),
    ).isRequired,
  }),
  changeFieldValue: func,
  arrFieldName: string.isRequired,
};
