import { ToolBarBtnDivider } from 'poly-book-admin';
import React, { useEffect } from 'react';
import { func, bool, shape, number } from 'prop-types';
import styled from 'styled-components';
import { ClientSelect } from 'poly-admin-ui';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import {
  AACManagerSelect,
  AccountingStatusSelect,
  ButtonsContainerS,
} from '../WorkInProgressReport/WorkInProgressHeader.js';
import { WIPQCExportPDFBtn } from './WIPQCExportPDFBtn.js';
import { WIPQCExportXLSBtn } from './WIPQCExportXLSBtn.js';
import { wipQCRecordsPropTypes } from './WorkInProgressQCTable.js';

export const SearchHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
  @media (max-width: 1470px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const SelectsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const workInProgressQcFiltersConfig = [
  { name: 'clientId' },
  { name: 'projectManagerId' },
  { name: 'projectAccountingStatus' },
];

export function WorkInProgressQCHeader({
  setQueryFilters,
  wipQCRecords,
  loading,
  sorting,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    workInProgressQcFiltersConfig,
  );

  const onSearch = () => {
    setQueryFilters(searchFilters);
  };

  useEffect(onSearch, []);

  return (
    <SearchPageHeaderContainer title="Work In Progress QC Report">
      <SearchHeaderWrapper>
        <SelectsWrapper>
          <SearchHeaderColumn
            title="Client"
            titleWidth="60px"
            filterWidth="240px"
          >
            <ClientSelect
              value={searchFilters.clientId}
              onChange={handlers.clientId}
              width="100%"
              includeAllOption={false}
              withoutSkip
              isClearable
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Project Manager"
            titleWidth="120px"
            filterWidth="220px"
          >
            <AACManagerSelect
              value={searchFilters.projectManagerId}
              onChange={handlers.projectManagerId}
              width="100%"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Acct Status"
            titleWidth="80px"
            filterWidth="220px"
          >
            <AccountingStatusSelect
              value={searchFilters.projectAccountingStatus}
              onChange={handlers.projectAccountingStatus}
            />
          </SearchHeaderColumn>
        </SelectsWrapper>
        <ButtonsWrapper>
          <SearchHeaderColumn filterWidth="200px">
            <ButtonsContainerS>
              <WIPQCExportPDFBtn
                sorting={sorting}
                wipQCRecords={wipQCRecords}
                loading={loading}
              />
              <ToolBarBtnDivider />
              <WIPQCExportXLSBtn
                sorting={sorting}
                wipQCRecords={wipQCRecords}
                loading={loading}
              />
            </ButtonsContainerS>
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="190px">
            <SearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={onReset}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              onClick={onSearch}
              loader={loading}
            >
              Search
            </SearchHeaderButton>
          </SearchHeaderColumn>
        </ButtonsWrapper>
      </SearchHeaderWrapper>
    </SearchPageHeaderContainer>
  );
}

WorkInProgressQCHeader.propTypes = {
  setQueryFilters: func.isRequired,
  sorting: shape({
    columnKey: number.isRequired,
    dir: number.isRequired,
  }),
  loading: bool,
  wipQCRecords: wipQCRecordsPropTypes,
};
