import * as R from 'ramda';
import { assocBy, calculateTotal, mapIndexed, ramdaUseWith } from 'poly-utils';
import { AccountTypeCategories } from 'poly-constants';

export const BalanceSheetRowType = {
  category: 'categoryRowType',
  parent: 'parentRowType',
  child: 'childRowType',
  total: 'totalRowType',
  categoryTotal: 'categoryTotalRowType',
  spacer: 'spacerRowType',
  grandTotal: 'grandTotalRowType', // sum of equity and liability
};

// createParentAccountRow :: AccountTypeCategory -> BalanceSheetParentAccountEntry -> BalanceSheetRow
const createParentAccountRow = (category) =>
  R.compose(
    R.assoc('type', BalanceSheetRowType.parent),
    R.assoc('category', category),
    R.objOf('account'),
    R.prop('parentAccount'),
  );

// createParentAccountTotalRow :: BalanceSheetParentAccountEntry -> BalanceSheetRow
const createParentAccountTotalRow = (category) =>
  R.applySpec({
    type: R.always(BalanceSheetRowType.total),
    category: R.always(category),
    account: R.prop('parentAccount'),
    balance: R.compose(
      calculateTotal(R.propOr(0, 'balance')),
      R.propOr([], 'accounts'),
    ),
  });

// createChildAccountRow :: AccountTypeCategory -> BalanceSheetAccountEntry -> BalanceSheetRow
const createChildAccountRow = (category) =>
  R.compose(
    R.assoc('type', BalanceSheetRowType.child),
    R.assoc('category', category),
  );

// createBalanceSheetSpacerRow :: _ -> BalanceSheetRow
const createBalanceSheetSpacerRow = R.always({
  type: BalanceSheetRowType.spacer,
});

// convertBalanceByContraType :: { balance: Float, account: AccountPostgre } -> Float
const convertBalanceByContraType = R.ifElse(
  R.pathOr(false, ['account', 'accountType', 'is_contra_type']),
  R.compose(R.unless(R.equals(0), R.multiply(-1)), R.prop('balance')),
  R.prop('balance'),
);

// flattenCategoryParentAccounts :: BalanceSheetEntry -> [[BalanceSheetRow]]
const flattenCategoryParentAccounts = (accountGroup) =>
  R.compose(
    R.map(
      R.compose(
        R.juxt([
          createParentAccountRow(accountGroup.category),
          R.compose(
            R.map(createChildAccountRow(accountGroup.category)),
            R.sortBy(R.path(['account', 'code'])),
            R.propOr([], 'accounts'),
          ),
          createParentAccountTotalRow(accountGroup.category),
          createBalanceSheetSpacerRow,
        ]),
        R.over(
          R.lensProp('accounts'),
          R.map(assocBy('balance', convertBalanceByContraType)),
        ),
      ),
    ),
    R.sortBy(R.path(['parentAccount', 'code'])),
    R.propOr([], 'parentAccounts'),
  )(accountGroup);

// createCategoryTotalRow :: [BalanceSheetRow] -> BalanceSheetRow
const createCategoryTotalRow = R.compose(
  R.applySpec({
    type: R.always(BalanceSheetRowType.categoryTotal),
    category: R.compose(R.prop('category'), R.head),
    balance: calculateTotal(R.propOr(0, 'balance')),
  }),
  R.filter(R.propEq('type', BalanceSheetRowType.total)),
);

// flattenBalanceSheetCategory :: BalanceSheetEntry -> [[BalanceSheetRow]]
const flattenBalanceSheetCategory = R.compose(
  R.juxt([R.identity, createCategoryTotalRow, createBalanceSheetSpacerRow]),
  R.flatten,
  R.juxt([
    R.compose(
      R.assoc('type', BalanceSheetRowType.category),
      R.pick(['category']),
    ),
    flattenCategoryParentAccounts,
  ]),
);

const balanceSheetCategoryOrderMap = {
  [AccountTypeCategories.asset]: 1,
  [AccountTypeCategories.liability]: 2,
  [AccountTypeCategories.equity]: 3,
};

// getOrderByBalanceSheetEntry :: BalanceSheetEntry -> Number
const getOrderByBalanceSheetEntry = R.compose(
  R.prop(R.__, balanceSheetCategoryOrderMap),
  R.prop('category'),
);

// getBalanceSheetGrandTotalRow :: [BalanceSheetRow] -> BalanceSheetRow
const getBalanceSheetGrandTotalRow = R.compose(
  R.assoc('type', BalanceSheetRowType.grandTotal),
  R.objOf('balance'),
  calculateTotal(R.propOr(0, 'balance')),
  R.filter(
    R.both(
      R.propEq('type', BalanceSheetRowType.categoryTotal),
      R.complement(R.propEq('category', AccountTypeCategories.asset)),
    ),
  ),
);

// flattenBalanceSheetDataResult :: BalanceSheetData -> [BalanceSheetRow]
//   BalanceSheetData = { balanceSheet: [BalanceSheetEntry] }
//
//   BalanceSheetRow = {
//     type: BalanceSheetRowType
//     category: AccountTypeCategory
//     account: Account
//     balance: Float
//   }
//
//   BalanceSheetRowType = category | parent | child | total
export const flattenBalanceSheetDataResult = R.compose(
  mapIndexed(
    ramdaUseWith(R.mergeRight, [
      R.identity,
      R.compose(R.objOf('_id'), R.toString),
    ]),
  ),
  R.converge(R.append, [getBalanceSheetGrandTotalRow, R.identity]),
  R.flatten,
  R.map(flattenBalanceSheetCategory),
  R.sortBy(getOrderByBalanceSheetEntry),
  R.propOr([], 'balanceSheet'),
);
