import React from 'react';
import styled from 'styled-components';
import { Button } from 'poly-book-admin';
import * as R from 'ramda';
import {
  calculateTotal,
  centsToDollarsWithFormat,
  getPropertiesDeepByHierarchyNodeR,
} from 'poly-utils';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { TotalsTableWrapper } from '../BatchInvoicingTM/BatchInvoicingPageBody.js';
import { CollapseFooterTotalC } from '../SpendReport/spendReportsTable/SpendReportsHierarchyTreeTable.js';
import { batchSpendReportPropType } from './BatchSpendReportTable.js';
import { useOnCreateBatch } from './useOnCreateBatch.js';
import { getPropertyInvoicesReportWithChildren } from './helpers.js';

const FooterWrapper = styled(TotalsTableWrapper)`
  align-items: center;
  padding-right: 15px;
`;

// calculateClientInvoicesTotal :: [BatchHierarchySpendReportResult] -> Number
const calculateClientInvoicesTotal = R.compose(
  calculateTotal(R.propOr(0, 'clientInvoiceAmountWithTax')),
  R.unnest,
  R.map(getPropertyInvoicesReportWithChildren),
  R.unnest,
  R.map(getPropertiesDeepByHierarchyNodeR([])),
);

// calculateBatchSpendReportTotal :: [BatchHierarchySpendReportResult] -> Number
const calculateBatchSpendReportTotal = R.compose(
  R.sum,
  R.map(R.propOr(0, 'totalLevel')),
);

export function BatchSpendReportFooter({
  loading,
  reports,
  selectedProjects,
  setSelectedProjects,
}) {
  const spendReportTotal = calculateBatchSpendReportTotal(reports);
  const invoicesTotal = calculateClientInvoicesTotal(reports);

  const { isLoading, onCreateBatch } = useOnCreateBatch({
    selectedProjects,
    setSelectedProjects,
  });
  return (
    <FooterWrapper>
      <CollapseFooterTotalC
        marginRight="14px"
        isFooter
        label="Spend report total:"
        total={centsToDollarsWithFormat(spendReportTotal)}
      />
      <CollapseFooterTotalC
        marginRight="50px"
        isFooter
        label="Invoices total:"
        total={centsToDollarsWithFormat(invoicesTotal)}
      />
      <Button
        onClick={onCreateBatch}
        disabled={loading || spendReportTotal !== invoicesTotal || isLoading}
        loader={loading || isLoading}
      >
        Create Batch
      </Button>
    </FooterWrapper>
  );
}

BatchSpendReportFooter.propTypes = {
  loading: bool.isRequired,
  reports: arrayOf(batchSpendReportPropType),
  selectedProjects: arrayOf(shape({ _id: string })),
  setSelectedProjects: func.isRequired,
};
