import * as R from 'ramda';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import { Transition, TransitionGroup } from 'react-transition-group';
import React, { createContext, useState, useContext, useMemo } from 'react';
import {
  func,
  node,
  arrayOf,
  oneOfType,
  bool,
  number,
  object,
  string,
} from 'prop-types';

import { withReactContext } from './withReactContext.js';

const BackgroundDisabled = styled.div`
  height: calc(100vh - 60px);
  width: 100vw;
  position: fixed;
  left: 0;
  top: 60px;
  background-color: rgba(0, 0, 0, 0.15);
  cursor: not-allowed;
  z-index: 10;
`;

/**
 * SidebarConfig = { id: String!, width: Int }
 * FYI: width is required for the correct work of the next sidebar
 */

// filterSidebarConfig :: [SidebarConfig] -> ID -> [SidebarConfig]
const filterSidebarConfig = R.curry((config, id) =>
  R.compose(
    R.ifElse(
      R.isNil,
      R.always(config),
      R.compose(
        R.without(R.__, config),
        R.of,
        R.when(R.has('afterClose'), (item) =>
          R.compose(R.always(item), R.call, R.prop('afterClose'))(item),
        ),
      ),
    ),
    R.find(R.propEq('id', id)),
  )(config),
);

// configHasSteadySidebars :: [SidebarConfig] -> Boolean
const configHasSteadySidebars = R.compose(
  R.equals(2),
  R.length,
  R.filter(R.prop('alwaysFirst')),
);

// withSteadyConfig :: (SidebarConfig, Boolean) -> [SidebarConfig] -> [SidebarConfig]
const withSteadyConfig = (newItem, skipSteady) =>
  R.compose(
    R.when(() => !!skipSteady, R.reject(R.prop('isSteady'))),
    R.reject(R.isNil),
    R.append(newItem),
    R.filter(R.either(R.prop('isSteady'), R.prop('isHidden'))),
    R.reject(R.propEq('id', newItem?.id)),
  );

// isSidebarAlreadyOpened :: (SidebarConfig, [SidebarConfig]) -> Boolean
const isSidebarAlreadyOpened = (config, sidebarConfig) =>
  R.and(
    R.compose(R.includes(config.id), R.map(R.prop('id')))(sidebarConfig),
    R.equals(
      R.path(['content', 'props'], config),
      R.path(['0', 'content', 'props'], sidebarConfig),
    ),
  );

// onlyHiddenConfig :: [SidebarConfig] -> [SidebarConfig]
const onlyHiddenConfig = R.filter(R.has('isHidden'));

// prepareConfigsBeforeOpen :: SidebarConfig -> [SidebarConfig]
const prepareConfigsBeforeOpen = (config) =>
  R.compose(
    R.append(config),
    R.when(R.compose(R.gte(R.__, 2), R.length), R.reject(R.prop('isHidden'))),
    R.when(
      () => !!config.hideSidebarId,
      R.reject(R.propEq('id', config.hideSidebarId)),
    ),
  );

const useOutSidebar = () => {
  const [sidebarConfig, setSidebarConfig] = useState([]);

  // closeOutSidebar :: ID -> _
  const closeOutSidebar = R.compose(
    setSidebarConfig,
    filterSidebarConfig(sidebarConfig),
  );

  const closeAllOutSidebars = (closeSteady) => {
    R.reverse(sidebarConfig).forEach((item) => {
      const { afterClose } = item;
      if (!!afterClose && R.is(Function, afterClose)) {
        afterClose();
      }
    });

    if (R.complement(R.isEmpty)(sidebarConfig)) {
      const preparedConfig = closeSteady
        ? onlyHiddenConfig(sidebarConfig)
        : withSteadyConfig(null)(sidebarConfig);

      setSidebarConfig(preparedConfig);
    }
  };

  // openOutSidebar :: (SidebarConfig, Boolean) -> _
  const openOutSidebar = (config, skipSteady) => {
    if (
      isSidebarAlreadyOpened(config, sidebarConfig) &&
      config.isHidden === undefined
    )
      return;

    if (config.alwaysFirst) {
      setSidebarConfig(withSteadyConfig(config, skipSteady)(sidebarConfig));
      // we don't want to open more than 2 sidebars
      // when we're going to open more we just replace the second one
    } else if (
      sidebarConfig.length === 2 &&
      !configHasSteadySidebars(sidebarConfig) &&
      !config.isCustom
    ) {
      setSidebarConfig([sidebarConfig[0], config]);

      if (sidebarConfig[1].afterClose) {
        sidebarConfig[1].afterClose();
      }
    } else {
      const preparedConfig = prepareConfigsBeforeOpen(config)(sidebarConfig);
      setSidebarConfig(preparedConfig);
    }
  };

  const setSidebarHidden = (id) => (isHidden) => {
    const newConfig = sidebarConfig.map((config) =>
      config.id === id ? { ...config, isHidden } : config,
    );
    setSidebarConfig(newConfig);
  };

  return {
    sidebarConfig,
    openOutSidebar,
    closeOutSidebar,
    closeAllOutSidebars,
    setSidebarHidden,
  };
};

function SlideOutSidebar({
  width,
  Layout,
  offset,
  content,
  onClose,
  position,
  in: inProp,
  onSteadyClick,
  isBlurredSidebar,
  isHidden,
}) {
  return (
    <Transition
      in={inProp}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
      onEnter={({ scrollTop }) => scrollTop}
    >
      {(state) => (
        <Layout
          width={width}
          offset={offset}
          onClose={onClose}
          position={position}
          isHidden={isHidden}
          isBlurredSidebar={isBlurredSidebar}
          blurred={R.toString(isBlurredSidebar)}
          open={state === 'entering' || state === 'entered'}
          {...(position === 'left' ? { onClick: onSteadyClick } : {})}
        >
          {content}
        </Layout>
      )}
    </Transition>
  );
}

SlideOutSidebar.propTypes = {
  content: oneOfType([arrayOf(node), node]).isRequired,
  Layout: oneOfType([func, object]),
  isBlurredSidebar: bool.isRequired,
  position: string.isRequired,
  offset: number.isRequired,
  onSteadyClick: func,
  onClose: func,
  width: number,
  in: bool,
  isHidden: bool,
};

const OutSidebarContext = createContext();

// checkSidebarOpenedById :: ID -> [SidebarConfig] -> Boolean
const checkSidebarOpenedById = (sidebarId) =>
  R.compose(R.complement(R.isNil), R.find(R.propEq('id', sidebarId)));

// isSteadySidebar :: ID -> [SidebarConfig] -> Boolean
const isSteadySidebar = (id) =>
  R.compose(R.propEq('isSteady', true), R.find(R.propEq('id', id)));

// isConfigWithSteadySidebars :: [SidebarConfig] -> Boolean
const isConfigWithSteadySidebars = R.compose(
  configHasSteadySidebars,
  R.reject(R.prop('isHidden')),
);

// getSidebarOffsetById :: ID -> [SidebarConfig] -> Number
const getSidebarOffsetById = (id) =>
  R.compose(
    R.reduce(R.add, 0),
    R.map(R.propOr(0, 'width')),
    R.converge(R.remove(0), [
      R.compose(R.add(1), R.findIndex(R.propEq('id', id))),
      R.identity,
    ]),
    R.reject(R.prop('isCustom')),
    R.when(
      isConfigWithSteadySidebars,
      R.ifElse(
        isSteadySidebar(id),
        R.compose(R.reverse, R.filter(R.prop('isSteady'))),
        R.reject(R.prop('isSteady')),
      ),
    ),
  );

// getSidebarPositionById :: ID -> [SidebarConfig] -> String
const getSidebarPositionById = (id) =>
  R.ifElse(
    R.both(isConfigWithSteadySidebars, isSteadySidebar(id)),
    R.always('left'),
    R.always('right'),
  );

// checkToCloseSteadySidebars :: [SidebarConfig] -> Boolean
const checkToCloseSteadySidebars = R.converge(R.equals, [
  R.length,
  R.compose(R.length, R.filter(R.propEq('isSteady', true))),
]);

// isBlurredSidebar :: ID -> [SidebarConfig] -> Boolean
const isBlurredSidebar = (id) =>
  R.either(
    R.compose(R.equals('left'), getSidebarPositionById(id)),
    R.compose(R.complement(R.isNil), R.find(R.propEq('isCustom', true))),
  );

// isSidebarHidden :: [SidebarConfig] -> Boolean
// eslint-disable-next-line import/no-unused-modules
export const isSidebarHidden = R.all(R.prop('isHidden'));

export function OutSidebarProvider({
  Layout,
  children,
  InnerContext,
  onOutsideClick,
  disabled = false,
  disableSidebarIDs = [],
  disabledBackground = false,
}) {
  const {
    sidebarConfig,
    closeOutSidebar,
    openOutSidebar,
    closeAllOutSidebars,
    setSidebarHidden,
  } = useOutSidebar();

  const isSidebarOpened = (id) => checkSidebarOpenedById(id)(sidebarConfig);

  const closeSteady = checkToCloseSteadySidebars(sidebarConfig);

  const isHidden = isSidebarHidden(sidebarConfig);

  const outsideClickDisabled =
    disabled || R.any(isSidebarOpened)(disableSidebarIDs) || isHidden;

  const modalsContextValue = useMemo(
    () => ({
      sidebarConfig,
      openOutSidebar,
      closeOutSidebar,
      isSidebarOpened,
      closeAllOutSidebars,
      setSidebarHidden,
    }),
    [sidebarConfig, isSidebarOpened],
  );

  const isOpenedSidebar = sidebarConfig.length > 0;

  const onOutsideClickHandler = (e) => {
    if (
      isOpenedSidebar &&
      typeof e.target.closest === 'function' &&
      !e.target.closest('[data-testid=notificator]')
    ) {
      if (typeof onOutsideClick === 'function') {
        return onOutsideClick(closeAllOutSidebars, closeSteady, {
          event: e,
          isOpenedSidebar,
        });
      }

      closeAllOutSidebars(closeSteady);
    }
    return false;
  };

  const onSteadyClick = (e) => {
    if (typeof onOutsideClick === 'function') {
      return onOutsideClick(closeAllOutSidebars, closeSteady, {
        event: e,
        isOpenedSidebar,
      });
    }
    return closeAllOutSidebars(closeSteady);
  };

  return (
    <>
      <OutSidebarContext.Provider value={modalsContextValue}>
        <InnerContext>
          {children}
          <OutsideClickHandler
            disabled={outsideClickDisabled}
            onOutsideClick={onOutsideClickHandler}
          >
            <TransitionGroup>
              {isOpenedSidebar &&
                sidebarConfig.map(({ id, ...restConfig }) => (
                  <SlideOutSidebar
                    key={id}
                    {...restConfig}
                    onSteadyClick={onSteadyClick}
                    onClose={() => closeOutSidebar(id)}
                    Layout={restConfig.Layout || Layout}
                    offset={getSidebarOffsetById(id)(sidebarConfig)}
                    position={getSidebarPositionById(id)(sidebarConfig)}
                    isBlurredSidebar={isBlurredSidebar(id)(sidebarConfig)}
                  />
                ))}
            </TransitionGroup>
          </OutsideClickHandler>
        </InnerContext>
      </OutSidebarContext.Provider>
      {isOpenedSidebar && disabledBackground && !isHidden ? (
        <BackgroundDisabled />
      ) : null}
    </>
  );
}

OutSidebarProvider.propTypes = {
  disabled: bool,
  onOutsideClick: func,
  disabledBackground: bool,
  disableSidebarIDs: arrayOf(string),
  Layout: oneOfType([func, object]),
  InnerContext: oneOfType([func, object]),
  children: oneOfType([arrayOf(node), node]).isRequired,
};

OutSidebarProvider.defaultProps = {
  InnerContext: (props) => <>{R.prop('children', props)}</>,
};

export const useOutSidebarContext = () => useContext(OutSidebarContext);

export const withOutSidebarContext = withReactContext(
  'out_sidebar',
  OutSidebarContext,
);
