import React from 'react';
import * as R from 'ramda';
import { arrayOf, object, oneOfType, string } from 'prop-types';
import { Text } from 'poly-book-admin/src/Text/index.js';

import { SidebarIDs } from '../constants.js';
import { LinkToSidebar } from '../../components/LinkToSidebar.js';
import { useHasUserAccessToReadAdminPO } from './utils.js';

export function PurchaseOrderLink({
  _id,
  children,
  queryParams = {},
  ...restProps
}) {
  const hasPermission = useHasUserAccessToReadAdminPO();

  const linkParams = R.mergeDeepLeft(
    { query: { [SidebarIDs.purchaseOrder]: _id } },
    queryParams,
  );

  const linkProps = { linkParams, ...restProps };

  if (!hasPermission) {
    return <Text size="12px">{children || restProps?.poNumber}</Text>;
  }

  return (
    <LinkToSidebar {...linkProps}>
      {children || restProps?.poNumber}
    </LinkToSidebar>
  );
}

PurchaseOrderLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: object,
  _id: string.isRequired,
  children: oneOfType([object, string, arrayOf(oneOfType([string, object]))]),
};
