import * as R from 'ramda';
import React, { useMemo } from 'react';
import { func, string } from 'prop-types';
import {
  pathOrNothingUI,
  useHasUserAccessWithPermission,
} from 'poly-client-utils';
import { UPDATE_MASTER_SUPPLIER_PERMISSION } from 'poly-security';
import { MasterSupplierStatus } from 'poly-constants';

import {
  EntityStatus,
  MainHeader,
  PageHeaderContainer,
  Rating,
} from 'poly-book-admin';
import { forceTitleCase } from 'poly-utils';
import { BackBtn, SectionWithBackBtn } from '../../components/index.js';
import { useMasterSupplierDetailsQuery } from './hooks/useMasterSupplierDetailsQuery.js';
import {
  masterSupplierStatusColorUI,
  materSupplierStatusLabelUI,
} from '../../constants/masterSuppliers.js';
import { ActionButtons } from '../../components/Button/ActionButtons.js';

function ActionButtonsWithRender(props) {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_MASTER_SUPPLIER_PERMISSION,
  );

  return ifHasPermission ? (
    <ActionButtons {...props} permission={UPDATE_MASTER_SUPPLIER_PERMISSION} />
  ) : null;
}

export function MasterSupplierPageHeader({
  getFallBackLink,
  defaultTab,
  formId,
  supplierId,
}) {
  const { data } = useMasterSupplierDetailsQuery(supplierId);

  const masterSupplier = useMemo(
    () => R.propOr({}, 'masterSupplier', data),
    [data],
  );
  const header = pathOrNothingUI(['company', 'name'])(masterSupplier);
  const title = R.pipe(
    R.prop('type'),
    forceTitleCase,
    R.concat('Master Supplier / '),
  )(masterSupplier);
  const cardNumber = pathOrNothingUI(['cardNumber'])(masterSupplier);
  const status = R.propOr(
    MasterSupplierStatus.ACTIVE,
    'status',
    masterSupplier,
  );

  return (
    <PageHeaderContainer>
      <SectionWithBackBtn>
        <BackBtn fallbackLink={getFallBackLink(masterSupplier)} />
        <MainHeader>{header}</MainHeader>
        <EntityStatus
          title={title}
          cardNumber={cardNumber}
          status={{
            color: masterSupplierStatusColorUI[status],
            text: materSupplierStatusLabelUI[status],
          }}
        />
        <Rating value={null} readOnly />
      </SectionWithBackBtn>
      <ActionButtonsWithRender
        formId={formId}
        tab={defaultTab}
        processName={formId}
      />
    </PageHeaderContainer>
  );
}

MasterSupplierPageHeader.propTypes = {
  getFallBackLink: func,
  defaultTab: string.isRequired,
  formId: string.isRequired,
  supplierId: string.isRequired,
};
