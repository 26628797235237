import {
  AccountingMethods,
  RecurringJournalStatuses,
  ScheduleRepeats,
} from 'poly-constants';
import { endOfYear } from 'date-fns';

export const createJournalEntryFormId = 'createJournalEntryFormId';

export const JournalEntryLineTypes = {
  DEBIT: 'debit',
  CREDIT: 'credit',
};

export const JournalOccurrence = {
  ONE_TIME_OCCURRENCE: 'oneTimeJournalOccurrence',
  RECURRING_OCCURRENCE: 'recurringJournalOccurrence',
};

export const createJournalEntryInitialValues = (currentDate) => ({
  date: currentDate,
  lines: [{ receiptFile: [] }, { receiptFile: [] }],
  accounting_method: AccountingMethods.CASH_BASIS,
  occurrence: JournalOccurrence.ONE_TIME_OCCURRENCE,
  schedule: {
    repeats: ScheduleRepeats.MONTHLY,
    weekDay: false,
    every: 1,
    days: [],
  },
  startDate: currentDate,
  endDate: endOfYear(currentDate),
  status: RecurringJournalStatuses.active,
});

export const JournalEntryLedgerFilters = {
  checkFromDate: 'checkFromDate',
  checkToDate: 'checkToDate',
};
