import * as R from 'ramda';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { func, shape, string, arrayOf } from 'prop-types';
import { keywordSortQuery } from 'poly-client-utils';
import { ASC_SORT_ORDER, UserEmployeeInfoStatus } from 'poly-constants';
import { Button, useNotificationContext } from 'poly-book-admin';
import {
  getAdminUsersByEmploymentStatusQuery,
  ConfirmTooltipBtn,
  TooltipButton,
  setProcesses,
  getProcess,
} from 'poly-admin-ui';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import {
  UndoButton,
  TooltipSelect,
  ManagerLetter,
  ManagerOption,
} from './UnassignedProjectsComponents.js';
import { processes } from '../../redux/processes/processesReducer.js';
import { ASSIGN_MANAGER_TO_PROJECTS_MUTATION } from '../../modules/core/hooks/projects/mutations.js';
import {
  getSelectedRows,
  setSelectedRows,
} from '../../redux/selectedRowsReducer.js';

const useUnassignedProjects = () => {
  const selectedRows = useSelector(getSelectedRows);

  const process = useSelector(
    getProcess(processes.UNASSIGNED_PROJECTS_LOADING),
  );

  return {
    selectedRows,
    [processes.UNASSIGNED_PROJECTS_LOADING]: process,
  };
};

export const useUnassignedProjectsLogic = (
  managerId,
  notification,
  projectIds,
) => {
  const dispatch = useDispatch();
  const selectedRows = useSelector(getSelectedRows);
  const { showSuccessNotification } = useNotificationContext();
  const [assignManagerToProjects] = useMutation(
    ASSIGN_MANAGER_TO_PROJECTS_MUTATION,
  );

  return async () => {
    dispatch(setProcesses({ [processes.UNASSIGNED_PROJECTS_LOADING]: true }));
    await assignManagerToProjects({
      variables: {
        input: { managerId, projectIds: projectIds || selectedRows },
      },
    });
    dispatch(setSelectedRows([]));
    dispatch(setProcesses({ [processes.UNASSIGNED_PROJECTS_LOADING]: false }));

    showSuccessNotification(notification);
  };
};

// getManagerOption :: Option -> ReactNode
const getManagerOption = (option) => {
  const letter = R.compose(R.toUpper, R.head, R.prop('label'))(option);

  return (
    <ManagerOption>
      <ManagerLetter>{letter}</ManagerLetter>
      <span>{R.prop('label', option)}</span>
    </ManagerOption>
  );
};

function AssignTooltip({ closeTooltip }) {
  const [managerId, setManagerId] = useState(null);
  const assignProject = useUnassignedProjectsLogic(
    managerId,
    'Projects are successfully assigned',
  );

  const onClick = R.juxt([closeTooltip, assignProject]);

  // it fixes correct option change for open menu select
  const onBlur = () =>
    document.querySelector('[aria-label="UserSelectDropdown"]').focus();

  const selectProps = {
    query: getAdminUsersByEmploymentStatusQuery(UserEmployeeInfoStatus.ACTIVE),
    additionalSearchParams: {
      sort: keywordSortQuery(['profile', 'fullName'])(ASC_SORT_ORDER),
    },
    value: managerId,
    onChange: setManagerId,
    placeholder: 'Type Managers',
    menuIsOpen: true,
    withoutSkip: true,
    formatOptionLabel: getManagerOption,
    hideSelectedOption: false,
    autoFocus: true,
    onBlur,
  };

  return (
    <>
      <TooltipSelect {...selectProps} />
      {managerId && (
        <ConfirmTooltipBtn onClick={onClick}>Assign</ConfirmTooltipBtn>
      )}
    </>
  );
}

AssignTooltip.propTypes = { closeTooltip: func.isRequired };

export function AssignUnassignedProjectBtn() {
  const { selectedRows, [processes.UNASSIGNED_PROJECTS_LOADING]: loading } =
    useUnassignedProjects();

  const btnProps = {
    size: 'small',
    children: 'Assign',
    Tooltip: AssignTooltip,
    tooltipProps: { width: '225px' },
    disabled: !selectedRows.length || loading,
  };

  return <TooltipButton {...btnProps} />;
}

function PickupNotification({ payload }) {
  const { projectIds } = payload;
  const undoProjects = useUnassignedProjectsLogic(
    null,
    'Projects are unassigned',
    projectIds,
  );
  return (
    <>
      <span>Projects are successfully assigned</span>
      <UndoButton onClick={undoProjects}>Undo</UndoButton>
    </>
  );
}

PickupNotification.propTypes = {
  payload: shape({ projectIds: arrayOf(string) }),
};

export const getUnassignedProjectSuccessNotification = (projectIds) => ({
  time: 6,
  payload: { projectIds },
  component: PickupNotification,
});

export function PickupUnassignedProjectBtn() {
  const { user } = useCurrentUserByStoreOrQuery();
  const { selectedRows, [processes.UNASSIGNED_PROJECTS_LOADING]: loading } =
    useUnassignedProjects();

  const managerId = R.prop('_id', user);
  const notification = getUnassignedProjectSuccessNotification(selectedRows);

  const pickupProject = useUnassignedProjectsLogic(managerId, notification);

  const btnProps = {
    size: 'small',
    styleType: 'accent',
    onClick: pickupProject,
    disabled: !selectedRows.length || loading,
  };

  return <Button {...btnProps}>Pickup</Button>;
}
