import {
  bool,
  shape,
  string,
  arrayOf,
  objectOf,
  oneOfType,
  instanceOf,
} from 'prop-types';
import * as R from 'ramda';
import React, { memo } from 'react';
import { Select } from 'poly-book-admin';
import { isManualTotalTimesheet } from 'poly-constants';
import { formatTimesheetDuration } from 'poly-utils';

import { convertFormDataToTimesheet } from '../helpers/from-form-data.js';
import { getTimesheetDurationInMinutes } from '../helpers/duration.js';

// getTimesheetDurationValue :: {formData: Timesheet, value: Number} -> Number
const getTimesheetDurationValue = R.ifElse(
  R.compose(isManualTotalTimesheet, R.prop('formData')),
  R.prop('value'),
  R.compose(
    getTimesheetDurationInMinutes,
    convertFormDataToTimesheet,
    R.prop('formData'),
  ),
);

// generateTimesheetTotalOptions :: (Number, Number) -> [Pair String String]
const generateTimesheetTotalOptions = (optionsCount, stepInMinutes) =>
  R.compose(
    R.map(
      R.applySpec({
        value: R.identity,
        label: formatTimesheetDuration,
      }),
    ),
    R.times(R.compose(R.multiply(stepInMinutes), R.add(1))),
  )(optionsCount);

// MANUAL_TIME_OPTIONS :: [Pair Number String]
const MANUAL_TIME_OPTIONS = generateTimesheetTotalOptions(48, 15);

// AUTOMATIC_TIME_OPTIONS :: [Pair Number String]
const AUTOMATIC_TIME_OPTIONS = generateTimesheetTotalOptions(48 * 2, 15);

export const TimesheetTotalInput = memo((props) => {
  const disabled = !isManualTotalTimesheet(props.formData);

  const value = getTimesheetDurationValue(props);

  const activeOptionText = formatTimesheetDuration(value);

  const options = isManualTotalTimesheet(props.formData)
    ? MANUAL_TIME_OPTIONS
    : AUTOMATIC_TIME_OPTIONS;

  const selectProps = {
    ...props,
    value,
    options,
    disabled,
    activeOptionText,
  };

  return <Select {...selectProps} />;
});

TimesheetTotalInput.displayName = 'TimesheetTotalInput';

TimesheetTotalInput.propTypes = {
  formData: shape({
    type: string,
    isManual: bool,
    date: oneOfType([instanceOf(Date), string]),
    entries: arrayOf(objectOf(instanceOf(Date))),
  }),
};
