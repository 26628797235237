import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, bool, arrayOf } from 'prop-types';
import { SidebarTabs as CommonSidebarTabs } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';
import {
  parseQuery,
  useLocation,
  useSetQueryParams,
} from 'poly-client-routing';

import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';
import { usePreviousSidebarParams } from '../usePreviousSidebarParams.js';
import { SidebarIDs } from '../constants.js';

const CloseSidebarIcon = styled(SidebarCloseIcon)`
  position: absolute;
  top: 12px;
  right: 24px;
`;

// prepareQueryParams :: [String] -> Object
const prepareQueryParams = R.compose(
  R.fromPairs,
  R.map(R.compose(R.append(undefined), R.of)),
);

// preparePreviousQueryParams :: String -> Object
const preparePreviousQueryParams = R.compose(
  R.omit(R.values(SidebarIDs)),
  parseQuery,
);

export function CloseSidebarBtn({ hideCloseIcon, params, id }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const previousSidebarParams = usePreviousSidebarParams();
  const setQueryParams = useSetQueryParams();
  const location = useLocation();

  if (hideCloseIcon) return null;
  const onClose = () => {
    setQueryParams({
      ...preparePreviousQueryParams(location.search),
      ...prepareQueryParams(params),
      ...previousSidebarParams,
    });
    closeOutSidebar(id);
  };
  return <CloseSidebarIcon onClick={onClose} />;
}

CloseSidebarBtn.propTypes = {
  hideCloseIcon: bool,
  params: arrayOf(string).isRequired,
  id: string.isRequired,
};

export function SidebarTabs({ isCard, ...props }) {
  // add here custom sidebars ids which can overflow the default
  // apps/admin/src/layouts/MainLayout.js -> 115: disableSidebarIDs
  const omitSidebarByTabs = [
    SidebarIDs.contact,
    ...(isCard ? [SidebarIDs.project, SidebarIDs.masterProject] : []),
  ];

  return (
    <CommonSidebarTabs
      {...props}
      toolBar={<CloseSidebarBtn {...props} />}
      omitSidebarByTabs={omitSidebarByTabs}
    />
  );
}

SidebarTabs.propTypes = {
  isCard: bool,
};
