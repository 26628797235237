import React from 'react';
import styled from 'styled-components';
import { number, string, bool, oneOfType, element, func } from 'prop-types';
import { Icon, Popover, Text } from 'poly-book-admin';

const PopoverS = styled(Popover)`
  display: inline-block;

  > div > div > div {
    width: 100%;
    padding: 5px 15px 0 15px;

    &:last-child {
      padding-bottom: 5px;
    }
  }
`;

const TextS = styled(Text)`
  max-width: 350px;
`;

export function YellowWarningIcon({
  iconSize,
  popoverText,
  popoverTextSize,
  position,
  content,
  ...props
}) {
  const iconElement = <Icon name="yellowWarning" size={iconSize} />;

  return (
    <PopoverS
      {...props}
      position={position}
      title={iconElement}
      content={content || <TextS size={popoverTextSize}>{popoverText}</TextS>}
    />
  );
}

YellowWarningIcon.propTypes = {
  popoverText: string,
  popoverTextSize: string,
  iconSize: number,
  position: string,
  content: oneOfType([element, func]),
};

YellowWarningIcon.defaultProps = {
  iconSize: 20,
  popoverTextSize: '14px',
  position: 'left',
};

export function IconMore({ isActive }) {
  const color = isActive ? '#4676b4' : 'rgba(30, 36, 44, 0.4)';

  return <Icon size={18} name="three-dots" color={color} />;
}

IconMore.propTypes = {
  isActive: bool,
};
