import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useInfiniteScrollQueryWithSubscription } from 'poly-client-utils';
import { alwaysNewDate, assocBy, unwindByProp } from 'poly-utils';
import { getSupplierDocumentWarnings } from 'poly-admin-ui';
import { TableCardWithoutTabs } from 'poly-book-admin';
import { WorkOrderStatus } from 'poly-constants';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { supplierDocumentsFields } from '../../modules/core/hooks/suppliers/fragments.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../modules/core/hooks/projects/subscriptions.js';
import { SupplierDocumentExceptionsPageTable } from './SupplierDocumentExceptionsPageTable.js';
import { SupplierDocumentExceptionsPageHeader } from './SupplierDocumentExceptionsPageHeader.js';

const GET_PROJECTS_QUERY = gql`
  query GET_PROJECTS_QUERY($searchInput: CollectionSearchParams!) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        type
        projectId
        property {
          _id
          name
        }
        suppliers {
          _id
          type
          isVeteran
          company {
            name
          }
          tax {
            exempt
            w9File {
              url
            }
          }
          mwbe {
            isWBE
            minority
          }
          documents {
            ...supplierDocumentsFields
          }
        }
      }
    }
  }

  ${supplierDocumentsFields}
`;

const getESNotExistsField = (fieldPath) => ({
  must_not: { exists: { field: fieldPath } },
});

const getExpiredFieldQuery = (field) => ({
  bool: {
    must: [
      { exists: { field } },
      { bool: { must_not: { range: { [field]: { gte: alwaysNewDate() } } } } },
    ],
  },
});

const getDefaultQuery = () => ({
  bool: {
    must: [
      { match: { status: WorkOrderStatus.ACTIVE } },
      {
        nested: {
          path: 'suppliers',
          query: {
            bool: {
              should: [
                { bool: getESNotExistsField('suppliers.tax.w9File.url') },
                {
                  bool: {
                    must: { match: { 'suppliers.documents.exempt': false } },
                    ...getESNotExistsField('suppliers.documents.wcFile.url'),
                  },
                },
                { bool: getESNotExistsField('suppliers.documents.glFile.url') },
                {
                  bool: getESNotExistsField('suppliers.documents.scaFile.url'),
                },
                getExpiredFieldQuery('suppliers.documents.wcExp'),
                getExpiredFieldQuery('suppliers.documents.glExp'),
                {
                  bool: {
                    must: { match: { 'suppliers.isVeteran': true } },
                    ...getESNotExistsField(
                      'suppliers.documents.veteranCertificationFile.url',
                    ),
                  },
                },
                {
                  bool: {
                    must: { match: { 'suppliers.mwbe.isWBE': true } },
                    ...getESNotExistsField(
                      'suppliers.documents.wbeCertificationFile.url',
                    ),
                  },
                },
                {
                  bool: {
                    must: {
                      exists: { field: 'suppliers.mwbe.minority' },
                    },
                    ...getESNotExistsField(
                      'suppliers.documents.mbeCertificationFile.url',
                    ),
                  },
                },
              ],
            },
          },
        },
      },
    ],
  },
});

// getProjectSuppliers :: { searchProjects: { hits: [Project] } } -> [Project]
const getProjectSuppliers = R.compose(
  R.map(
    assocBy(
      '_id',
      R.compose(
        R.join('_'),
        R.juxt([R.prop('_id'), R.path(['suppliers', '_id'])]),
      ),
    ),
  ),
  R.reject(R.propSatisfies(R.isEmpty, 'supplierExceptions')),
  R.map(
    assocBy(
      'supplierExceptions',
      R.compose(
        R.join(',  '),
        getSupplierDocumentWarnings,
        R.prop('suppliers'),
      ),
    ),
  ),
  unwindByProp('suppliers'),
  R.pathOr([], ['searchProjects', 'hits']),
);

export function SupplierDocumentExceptionsPage() {
  const searchTerm = useSelector((state) => state.searchText);
  const [input, setInput] = useState({ searchTerm, query: getDefaultQuery() });

  useEffect(() => {
    setInput({ ...input, searchTerm });
  }, [searchTerm]);

  const { data, loading, tableProps, allItemsFetched, debouncedRefetch } =
    useInfiniteScrollQueryWithSubscription(
      GET_PROJECTS_QUERY,
      input,
      {
        inputName: 'searchInput',
        endpointName: 'searchProjects',
      },
      PROJECTS_BY_SEARCH_SUB,
      { searchInput: {} },
    );

  const projectSuppliers = getProjectSuppliers(data);

  return (
    <PageWithSearchHeader headerHeight="110px">
      <SupplierDocumentExceptionsPageHeader {...{ projectSuppliers }} />
      <TableCardWithoutTabs>
        <SupplierDocumentExceptionsPageTable
          isPrint={false}
          loading={loading}
          tableProps={tableProps}
          searchTerm={searchTerm}
          allItemsFetched={allItemsFetched}
          projectSuppliers={projectSuppliers}
          debouncedRefetch={debouncedRefetch}
        />
      </TableCardWithoutTabs>
    </PageWithSearchHeader>
  );
}
