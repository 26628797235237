import React from 'react';
import { func } from 'prop-types';
import { Select } from 'poly-book-admin';
import { ProjectSpendTypes } from 'poly-constants';

import { ProjectOccurrence } from '../modules/core/constants/projects.js';

const {
  ONE_TIME_OCCURRENCE,
  RECURRING_OCCURRENCE,
  PREVENTIVE_MAINTENANCE_OCCURRENCE,
} = ProjectOccurrence;

const recurrenceOptions = [
  { value: PREVENTIVE_MAINTENANCE_OCCURRENCE, label: 'Preventive Maintenance' },
  { value: RECURRING_OCCURRENCE, label: 'Recurring' },
  { value: ONE_TIME_OCCURRENCE, label: 'One Time' },
];

export function OccurrenceSelect({ changeFieldValue, onChange, ...props }) {
  const onChangeOccurrence = (occurrence) => {
    onChange(occurrence);

    if (!changeFieldValue) {
      return;
    }

    if (occurrence === ONE_TIME_OCCURRENCE) {
      changeFieldValue('spendType', ProjectSpendTypes.REACTIVE_REPAIR);
    }

    if (
      occurrence === PREVENTIVE_MAINTENANCE_OCCURRENCE ||
      occurrence === RECURRING_OCCURRENCE
    ) {
      changeFieldValue('spendType', ProjectSpendTypes.RECURRING_SERVICE);
    }
  };

  const selectProps = {
    options: recurrenceOptions,
    onChange: onChangeOccurrence,
    ...props,
  };

  return <Select {...selectProps} />;
}

OccurrenceSelect.propTypes = {
  onChange: func,
  changeFieldValue: func,
};
