import * as R from 'ramda';
import { useState, useEffect } from 'react';
import { useOutSidebarContext } from 'poly-client-utils';
import { useLocation, useRouterQuery } from 'poly-client-routing';
import { ProjectType } from 'poly-constants';
import { isNilOrEmpty } from 'poly-utils/src/general.js';

import { SidebarIDs } from '../sidebars/constants.js';
import { clientTabsSidebarId } from '../sidebars/ClientSidebar/constants.js';
import { projectSidebarTabsId } from '../sidebars/ProjectSidebar/constants.js';
import { propertyTabsSidebarId } from '../sidebars/PropertySidebar/constants.js';
import { useOpenUserSidebarByPermission } from '../sidebars/UserSidebar/useOpenUserSidebar.js';
import { useOpenPropertySidebar } from '../sidebars/PropertySidebar/useOpenPropertySidebar.js';
import { useOpenProjectSidebar } from '../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { useOpenContactSidebar } from '../sidebars/ContactSidebar/useOpenContactSidebar.js';
import { useOpenClientSidebar } from '../sidebars/ClientSidebar/useOpenClientSidebar.js';
import { useOpenAssetSidebar } from '../sidebars/AssetSidebar/useOpenAssetSidebar.js';
import { useSidebarFormByCache } from '../sidebars/useSidebarFormByCache.js';
import {
  useCloseSidebarByProps,
  useOpenSidebarByProps,
} from './useOpenSidebarByProps.js';
import { useOpenRequestedDocumentReviewSidebar } from '../pages/RequestedSupplierDocumentsReview/sidebar/useOpenRequestedDocumentReviewSidebar.js';
import { useOpenApproveSupplierInvoiceSidebar } from '../pages/ApproveSupplierInvoices/ApproveSupplierInvoiceSidebar/ApproveSupplierInvoiceSidebar.js';
import { useOpenPurchaseOrderSidebar } from '../sidebars/PurchaseOrderSidebar/useOpenPurchaseOrderSidebar.js';
import {
  editPurchaseOrderFormId,
  purchaseOrderTabsSidebarId,
} from '../sidebars/PurchaseOrderSidebar/constants.js';
import { useOpenUserGroupSidebar } from '../sidebars/UserGroupSidebar/useOpenUserGroupSidebar.js';

const useCloseSidebarsByURLParamsClean = ({
  assetSidebarId,
  clientSidebarId,
  contactSidebarId,
  projectSidebarId,
  propertySidebarId,
  masterProjectSidebarId,
  requestedSupplierDocumentSidebarId,
  approveSupplierInvoiceSidebarId,
  purchaseOrderSidebarId,
}) => {
  useCloseSidebarByProps({
    id: projectSidebarId,
    name: SidebarIDs.project,
  });

  useCloseSidebarByProps({
    id: masterProjectSidebarId,
    name: SidebarIDs.masterProject,
  });

  useCloseSidebarByProps({ id: clientSidebarId, name: SidebarIDs.client });

  useCloseSidebarByProps({ id: propertySidebarId, name: SidebarIDs.property });

  useCloseSidebarByProps({ id: assetSidebarId, name: SidebarIDs.asset });

  useCloseSidebarByProps({ id: contactSidebarId, name: SidebarIDs.contact });

  useCloseSidebarByProps({
    id: requestedSupplierDocumentSidebarId,
    name: SidebarIDs.requestedSupplierDocument,
  });

  useCloseSidebarByProps({
    id: approveSupplierInvoiceSidebarId,
    name: SidebarIDs.approveSupplierInvoice,
  });
  useCloseSidebarByProps({
    id: purchaseOrderSidebarId,
    name: SidebarIDs.purchaseOrder,
  });

  useCloseSidebarByProps({
    id: purchaseOrderSidebarId,
    name: editPurchaseOrderFormId,
  });
};

// isPristineStateEmpty :: Object -> Boolean
const isPristineStateEmpty = R.either(
  isNilOrEmpty,
  R.propEq('pristine', undefined),
);

export const useOpenSidebarsByURL = (isTokenValid) => {
  const {
    type,
    userSidebarId,
    assetSidebarId,
    clientSidebarId,
    contactSidebarId,
    projectSidebarId,
    propertySidebarId,
    userGroupSidebarId,
    masterProjectSidebarId,
    requestedSupplierDocumentSidebarId,
    approveSupplierInvoiceSidebarId,
    purchaseOrderSidebarId,
  } = useRouterQuery(['type', 'pristine', ...R.values(SidebarIDs)]);
  const { state } = useLocation();

  const [projectMounted, setProjectMounted] = useState(false);

  const { isSidebarOpened } = useOutSidebarContext();
  const openPropertySidebar = useOpenPropertySidebar();
  const openProjectSidebar = useOpenProjectSidebar();
  const openContactSidebar = useOpenContactSidebar();
  const openClientSidebar = useOpenClientSidebar();
  const openUserGroupSidebar = useOpenUserGroupSidebar();
  const openAssetSidebar = useOpenAssetSidebar();
  const openRequestedDocumentReviewSidebar =
    useOpenRequestedDocumentReviewSidebar();
  const openApproveSupplierInvoicesSidebar =
    useOpenApproveSupplierInvoiceSidebar();
  const openPurchaseOrderSidebar = useOpenPurchaseOrderSidebar();

  const skipSteady = isSidebarOpened(SidebarIDs.project) && !projectSidebarId;
  const isPristineEmpty = isPristineStateEmpty(state);
  const hasPermission = isTokenValid && projectMounted && isPristineEmpty;
  const isProjectOpened = isSidebarOpened(projectSidebarTabsId);

  // used for correct behavior of sidebar forms at browser history
  useSidebarFormByCache();

  // used for correct behavior of close sidebars at browser history
  // always should be first in order before open sidebar logic
  useCloseSidebarsByURLParamsClean({
    assetSidebarId,
    clientSidebarId,
    contactSidebarId,
    projectSidebarId,
    propertySidebarId,
    masterProjectSidebarId,
    requestedSupplierDocumentSidebarId,
    approveSupplierInvoiceSidebarId,
    purchaseOrderSidebarId,
  });

  useEffect(() => {
    if (!projectMounted && projectSidebarId && isProjectOpened) {
      setProjectMounted(true);
    }

    if (!projectMounted && !projectSidebarId) {
      setProjectMounted(true);
    }
  }, [projectSidebarId, isProjectOpened]);

  const isClientOpened = clientSidebarId
    ? isSidebarOpened(clientTabsSidebarId)
    : true;
  const isPropertyOpened = propertySidebarId
    ? isSidebarOpened(propertyTabsSidebarId)
    : true;

  const isPOOpened = purchaseOrderSidebarId
    ? isSidebarOpened(purchaseOrderTabsSidebarId)
    : true;

  const isContactActive = isClientOpened && isPropertyOpened && isPOOpened;

  useOpenSidebarByProps({
    skipSteady,
    id: projectSidebarId,
    hasPermission: isTokenValid,
    name: SidebarIDs.project,
    openSidebarHandler: openProjectSidebar,
    sidebarProps: {
      projectId: projectSidebarId,
      type: type || ProjectType.WORK_ORDER,
    },
  });

  useOpenSidebarByProps({
    skipSteady,
    hasPermission,
    id: masterProjectSidebarId,
    name: SidebarIDs.masterProject,
    openSidebarHandler: openProjectSidebar,
    sidebarProps: { projectId: masterProjectSidebarId },
  });

  useOpenSidebarByProps({
    skipSteady,
    hasPermission,
    id: clientSidebarId,
    name: SidebarIDs.client,
    openSidebarHandler: openClientSidebar,
  });

  useOpenSidebarByProps({
    skipSteady,
    hasPermission,
    id: userGroupSidebarId,
    name: SidebarIDs.userGroup,
    openSidebarHandler: openUserGroupSidebar,
  });

  useOpenSidebarByProps({
    skipSteady,
    hasPermission,
    id: propertySidebarId,
    name: SidebarIDs.property,
    openSidebarHandler: openPropertySidebar,
  });

  useOpenSidebarByProps({
    skipSteady,
    hasPermission,
    id: assetSidebarId,
    name: SidebarIDs.asset,
    openSidebarHandler: openAssetSidebar,
  });

  useOpenSidebarByProps({
    skipSteady,
    id: contactSidebarId,
    name: SidebarIDs.contact,
    openSidebarHandler: openContactSidebar,
    hasPermission: hasPermission && isContactActive,
  });

  useOpenSidebarByProps({
    skipSteady,
    hasPermission: isTokenValid,
    id: requestedSupplierDocumentSidebarId,
    name: SidebarIDs.requestedSupplierDocument,
    openSidebarHandler: openRequestedDocumentReviewSidebar,
  });

  useOpenSidebarByProps({
    skipSteady,
    hasPermission: isTokenValid,
    id: approveSupplierInvoiceSidebarId,
    name: SidebarIDs.approveSupplierInvoice,
    openSidebarHandler: openApproveSupplierInvoicesSidebar,
  });

  useOpenSidebarByProps({
    skipSteady,
    hasPermission: isTokenValid && isPristineEmpty,
    id: purchaseOrderSidebarId,
    name: SidebarIDs.purchaseOrder,
    openSidebarHandler: openPurchaseOrderSidebar,
  });

  useOpenUserSidebarByPermission(userSidebarId, hasPermission);
};
