import * as R from 'ramda';
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import {
  arrayOf,
  array,
  string,
  func,
  element,
  oneOfType,
  bool,
  any,
} from 'prop-types';

import { getThemeFont, getThemeColor, getTabsLength } from '../utils.js';

const TAB_QUERY_ROUTE = '1';
const TAB_COMPONENT = '2';

const activeHeaderItem = css`
  border-bottom: 2px solid ${getThemeColor(['primaryRegular'])};
  color: ${getThemeColor(['primaryRegular'])};
  z-index: 1;
`;

const TabsHeader = styled.div`
  display: flex;
  height: 32px;
  ${({ toolBar }) => !!toolBar && 'padding-right: 240px'};

  ${({ brokeAt }) => `@media (max-width: ${brokeAt}px) {
    justify-content: space-between;
    width: 100%;
  }`};
`;

// getTabItemColor :: Object -> String
const getTabItemColor = R.ifElse(
  R.prop('textColor'),
  R.prop('textColor'),
  getThemeColor(['midDark']),
);

const TabsHeaderItem = styled.div`
  font-weight: ${getThemeFont(['medium'])};
  font-size: 12px;
  letter-spacing: 1px;
  color: ${getTabItemColor};
  line-height: 18px;
  margin: 0 20px 0 0;
  padding: 5px 0 16px 0;
  cursor: pointer;

  ${({ isActive }) => isActive && activeHeaderItem};

  ${({ brokeAt }) => `@media (max-width: ${brokeAt}px) {
    margin-right: auto;
  }`};
`;

function TabsComp({
  tabs,
  value,
  onChange,
  className,
  HeaderWrapper,
  ToolBarWrapper,
  ActiveComponentContainer,
  toolBar,
  withPips,
}) {
  const ActiveComponent = R.pipe(
    R.find(R.propEq(TAB_QUERY_ROUTE, value)),
    R.defaultTo([]),
    R.prop(TAB_COMPONENT),
  )(tabs);

  const multiplier = withPips ? 20 : 14;
  const brokeAt = getTabsLength(tabs, multiplier, toolBar);

  return (
    <div className={className}>
      <HeaderWrapper>
        {toolBar && <ToolBarWrapper>{toolBar}</ToolBarWrapper>}
        <TabsHeader {...{ toolBar, brokeAt }}>
          {tabs.map(
            ([title, tabValue, , , ShowIfWrapper = Fragment, textColor]) => (
              <ShowIfWrapper key={tabValue}>
                <TabsHeaderItem
                  {...{ brokeAt }}
                  isActive={tabValue === value}
                  onClick={() => tabValue !== value && onChange(tabValue)}
                  textColor={textColor}
                >
                  {title}
                </TabsHeaderItem>
              </ShowIfWrapper>
            ),
          )}
        </TabsHeader>
      </HeaderWrapper>
      <ActiveComponentContainer>{ActiveComponent}</ActiveComponentContainer>
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
TabsComp.propTypes = {
  tabs: arrayOf(array).isRequired,
  value: string.isRequired,
  onChange: func,
  className: string,
  HeaderWrapper: any,
  ToolBarWrapper: any,
  ActiveComponentContainer: any,
  toolBar: oneOfType([arrayOf(element), element]),
  withPips: bool,
};

TabsComp.defaultProps = {
  className: '',
  onChange: () => null,
  HeaderWrapper: Fragment,
  ToolBarWrapper: Fragment,
  ActiveComponentContainer: Fragment,
  toolBar: null,
  withPips: false,
};

const propsAreEqual = (prevProps, props) =>
  prevProps.value === props.value &&
  prevProps.className === props.className &&
  R.equals(prevProps.tabs, props.tabs) &&
  R.equals(prevProps.toolBar, props.toolBar);

export const Tabs = React.memo(TabsComp, propsAreEqual);
