import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { bool, func, string } from 'prop-types';
import React, { useState, useCallback } from 'react';
import { debounce } from 'poly-utils';
import {
  useNotificationContext,
  defaultTheme,
  Loader,
  Icon,
} from 'poly-book-admin';

import { ArchiveAlertNotification } from './ArchiveAlertNotification.js';
import { TOGGLE_USER_ALERT_ARCHIVE } from '../../../../modules/core/hocs/userAlerts/mutations.js';

const ArchiveButton = styled.div`
  margin-left: 10px;

  ${({ isArchived }) => !isArchived && 'margin-top: 2px'};
`;

export function ToggleUserAlertArchivedButton({
  _id,
  isArchived,
  setIsArchivedDone,
  refetch,
}) {
  const [loading, setLoading] = useState(false);
  const { showSuccessNotification } = useNotificationContext();
  const [toggleUserAlertArchive] = useMutation(TOGGLE_USER_ALERT_ARCHIVE);

  const refetchDebounced = useCallback(debounce(2050)(refetch), []);

  const onClickHandler = async (e) => {
    e.stopPropagation();
    setLoading(true);
    await toggleUserAlertArchive({ variables: { id: _id } });
    setLoading(false);
    setIsArchivedDone(true);
    showSuccessNotification({
      time: 6,
      payload: {
        _id,
        isArchived,
        setIsArchivedDone,
        refetch: refetchDebounced,
      },
      component: ArchiveAlertNotification,
    });
    refetchDebounced();
  };

  return (
    <ArchiveButton
      isArchived={isArchived}
      data-testid={`toggle-archive-btn-${_id}`}
    >
      {loading ? (
        <Loader />
      ) : (
        <Icon
          name={isArchived ? 'archive-icon' : 'close'}
          color={defaultTheme.colors.primaryLight}
          size={isArchived ? 18 : 10}
          onClick={onClickHandler}
        />
      )}
    </ArchiveButton>
  );
}

ToggleUserAlertArchivedButton.displayName = 'ToggleUserAlertArchivedButton';

ToggleUserAlertArchivedButton.propTypes = {
  _id: string.isRequired,
  isArchived: bool.isRequired,
  setIsArchivedDone: func.isRequired,
  refetch: func.isRequired,
};
