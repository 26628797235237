import React from 'react';
import styled from 'styled-components';
import { string, func } from 'prop-types';
import { SubmitBtn, useProcessState } from 'poly-admin-ui';
import { formatBackLink, useLocation } from 'poly-client-routing';
import { ButtonsContainer } from 'poly-admin-ui/src/components/Button/Button.js';

import { routesNames } from '../routes/index.js';
import { CancelFormBtn } from './Buttons.js';

const ButtonsContainerS = styled(ButtonsContainer)`
  justify-content: flex-end;
  padding-top: 20px;
`;

export function FormPageSectionButtons({
  formId,
  fallbackLink,
  MiddleBtn,
  middleBtnAttr,
}) {
  const location = useLocation();
  const { process } = useProcessState(formId);

  const form = document.getElementById(formId);
  const isMiddleBtnActive =
    !!middleBtnAttr && !!form && form.hasAttribute(middleBtnAttr);

  const cancelRedirectUrl = formatBackLink({
    fallbackLink: fallbackLink || routesNames.FINANCIAL_DIRECTORY,
    router: location.state,
    withQuery: true,
  });

  return (
    <ButtonsContainerS>
      <SubmitBtn
        loader={process && !isMiddleBtnActive}
        disabled={process}
        form={formId}
      >
        Save
      </SubmitBtn>
      {!!MiddleBtn && (
        <MiddleBtn
          loader={process && isMiddleBtnActive}
          disabled={process}
          form={formId}
        />
      )}
      <CancelFormBtn disabled={process} redirectUrl={cancelRedirectUrl} />
    </ButtonsContainerS>
  );
}

FormPageSectionButtons.propTypes = {
  formId: string.isRequired,
  middleBtnAttr: string,
  fallbackLink: string,
  MiddleBtn: func,
};
