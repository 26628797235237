import * as R from 'ramda';
import { UpdateTypes } from 'poly-constants';
import { assocBy } from 'poly-utils';

import { mailToInitValues } from '../fields/MailTo/constants.js';

export const initialValues = {
  updateType: UpdateTypes.INTERNAL,
  updateDetails: {
    text: '',
    attachments: [],
  },
  mailTo: mailToInitValues,
};

export const mapAttachmentsBeforeUpdate = R.map(
  R.pipe(
    R.dissoc('_id'),
    assocBy(
      'fileName',
      R.ifElse(
        R.prop('fileName'),
        R.prop('fileName'),
        R.path(['upload', 'name']),
      ),
    ),
  ),
);
