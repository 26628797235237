import * as R from 'ramda';
import {
  SupplierSources,
  SupplierStatuses,
  SupplierTypes,
  ELASTIC_SCORE_FIELD,
} from 'poly-constants';
import { paginationToQueryParams } from 'poly-client-utils';
import { getSupplierSearchQueryByName } from 'poly-admin-ui';

import { NO_RADIUS_VALUE } from '../../forms/assignSupplierForm/constants.js';
import { getDistanceSortQuery } from '../../forms/assignSupplierForm/utils.js';

const { AAC } = SupplierSources;
const { ADMINISTRATIVE, SUBCONTRACTOR } = SupplierTypes;

/**
 * getSuppliersQuery :: (String, [String]) -> (String, [String]) -> ElasticQuery
 */
export const getSuppliersQuery =
  (source, types) =>
  (status, excludeIds = []) => ({
    bool: {
      must: [
        { terms: { type: types } },
        { term: { status } },
        { term: { 'misc.source': source } },
      ],
      must_not: { terms: { _id: excludeIds } },
    },
  });

export const getAdminSuppliersQuery = getSuppliersQuery(AAC, [ADMINISTRATIVE]);

export const getSubcontractorSuppliersQuery = getSuppliersQuery(AAC, [
  SUBCONTRACTOR,
]);

// getServiceFilterQuery :: String -> Object
const getServiceFilterQuery = R.ifElse(
  R.identity,
  (serviceId) => ({ match: { 'company.services.serviceId': serviceId } }),
  R.always({ match_all: {} }),
);

export const getAssignSupplierSearchInput = ({
  distance,
  serviceId,
  propertyCoordinates,
  alreadyUsedSupplierIds,
  previouslyUsedLocation,
  entitySupplierIds = [],
  searchText,
  selectedSupplierId,
}) => {
  const needDistanceQuery = distance !== NO_RADIUS_VALUE && propertyCoordinates;
  return {
    query: {
      bool: {
        must: [
          ...(previouslyUsedLocation
            ? [{ terms: { _id: alreadyUsedSupplierIds } }]
            : []),
          ...(selectedSupplierId
            ? [{ terms: { _id: [selectedSupplierId] } }]
            : []),
        ],
        ...(searchText.length > 2
          ? getSupplierSearchQueryByName(searchText).bool
          : {}),
        must_not: [
          { terms: { _id: entitySupplierIds } },
          { term: { status: SupplierStatuses.BLOCKED } },
        ],
        filter: [
          { term: { type: SUBCONTRACTOR } },
          ...(needDistanceQuery
            ? [
                {
                  geo_distance: {
                    distance: `${distance}miles`,
                    'company.address.location': propertyCoordinates,
                  },
                },
              ]
            : []),
          {
            nested: {
              path: 'company.services',
              query: getServiceFilterQuery(serviceId),
            },
          },
        ],
      },
    },
    ...paginationToQueryParams({ itemsPerPage: 30, currentPage: 1 }),
    sort: [
      ELASTIC_SCORE_FIELD,
      ...(needDistanceQuery ? [getDistanceSortQuery(propertyCoordinates)] : []),
    ],
  };
};
