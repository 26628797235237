import React from 'react';
import styled from 'styled-components';
import {
  bool,
  func,
  oneOfType,
  shape,
  string,
  objectOf,
  instanceOf,
} from 'prop-types';

import { DatePicker, getThemeColor, Text } from 'poly-book-admin';

const Label = styled(Text)`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ labelDark }) =>
    labelDark
      ? getThemeColor(['primary'])
      : getThemeColor(['primaryLighter2'])};
  display: inline-block;
`;

const NextTaskDescription = styled(Text)`
  display: inline;
  size: 14px;
`;

export function NextTaskDueDateField({
  value,
  onChange,
  error,
  position,
  labelDark,
  ...props
}) {
  return (
    <>
      <Label labelDark={labelDark}>
        Schedule Next Task
        <NextTaskDescription>
          {' '}
          ({value.nextTaskDescription}){' '}
        </NextTaskDescription>
      </Label>
      <DatePicker
        {...props}
        hasError={error.dueDate}
        error={error.dueDate}
        position={position}
        name="dueDate"
        value={value.dueDate}
        onChange={(dueDate) => onChange({ ...value, dueDate })}
      />
    </>
  );
}

NextTaskDueDateField.propTypes = {
  value: shape({
    nextTaskDescription: string,
    dueDate: instanceOf(Date),
  }),
  position: string,
  onChange: func.isRequired,
  error: oneOfType([string, objectOf(string)]),
  labelDark: bool,
};
