import * as R from 'ramda';
import { escapeSpecialChars } from 'poly-utils';

// mentionsToNames :: Object -> [String]
const mentionsToNames = R.compose(
  R.map(R.concat('@')),
  R.converge(R.concat, [
    R.compose(R.map(R.prop('fullName')), R.propOr([], 'users')),
    R.compose(R.map(R.prop('name')), R.propOr([], 'groups')),
  ]),
);

// constructMentionRegExpByUsers :: [String] -> RegExp
const constructMentionRegExpByUsers = R.compose(
  R.constructN(2, RegExp)(R.__, 'gm'),
  R.concat(R.__, ')'),
  R.concat('('),
  R.join('|'),
  R.map(escapeSpecialChars),
);

export const tokenizeMessageByMentions = (message, mentions) => {
  const mentionNames = mentionsToNames(mentions);

  if (mentionNames.length === 0) {
    return [{ text: message, isMention: false }];
  }

  const mentionsRegExp = constructMentionRegExpByUsers(mentionNames);
  const tokenizedMentions = [];

  const addPlainTextToken = (text) => {
    if (text.length > 0) {
      tokenizedMentions.push({ text, isMention: false });
    }
  };

  let lastProcessedIndex = 0;
  let nextMatchedMention = mentionsRegExp.exec(message);

  while (nextMatchedMention) {
    const textBefore = message.slice(
      lastProcessedIndex,
      nextMatchedMention.index,
    );
    addPlainTextToken(textBefore);

    const matchStr = nextMatchedMention[0];
    tokenizedMentions.push({ text: matchStr, isMention: true });

    lastProcessedIndex = nextMatchedMention.index + matchStr.length;
    nextMatchedMention = mentionsRegExp.exec(message);
  }

  // handle any text after all mentions
  const textAfterMentions = message.slice(lastProcessedIndex, message.length);
  addPlainTextToken(textAfterMentions);

  return tokenizedMentions;
};
