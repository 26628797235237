import React from 'react';
import styled from 'styled-components';
import { Input } from 'poly-book-admin';
import { bool } from 'prop-types';

const PercentInputS = styled(Input)`
  margin-right: 5px;
  width: 120px;
`;

const PercentInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export function PercentInput({ skipLabelPercent, ...props }) {
  return (
    <PercentInputWrapper>
      <PercentInputS
        {...props}
        type="numberMask"
        decimalScale={2}
        maxValue={100}
        floatValue
      />
      {!skipLabelPercent && <span>%</span>}
    </PercentInputWrapper>
  );
}

PercentInput.propTypes = {
  skipLabelPercent: bool,
};
