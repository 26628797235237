import React from 'react';
import * as R from 'ramda';
import { useReactiveDocumentTitle } from 'poly-client-utils';
import { useRouterParams, useRouterQuery } from 'poly-client-routing';
import { supplierTypes } from 'poly-constants';
import {
  MasterSupplierPageHeader,
  SearchInputTabsDisplay,
  TableSearchInput,
} from 'poly-admin-ui';

import {
  MasterSupplierFilesTab,
  MasterSupplierTasksTab,
  MasterSupplierPeopleTab,
  MasterSupplierUpdatesTab,
} from './masterSupplierTabs/commonMasterSupplierTabs.js';
import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { FlexContainer } from '../../components/FlexContainer.js';
import { routesNames, masterSupplierPageTabs } from '../../routes/constants.js';
import { MasterSupplierInvoicesToolbar } from './MasterSupplierInvoicesToolbar.js';
import { MasterSupplierInfoTab } from './masterSupplierTabs/MasterSupplierInfoTab.js';
import { MasterSupplierBranchesTab } from './masterSupplierTabs/MasterSupplierBranchesTab.js';
import { MasterSupplierProjectsTab } from './masterSupplierTabs/MasterSupplierProjectsTab.js';
import { MasterSupplierInvoicesTab } from './masterSupplierTabs/MasterSupplierInvoicesTab.js';
import { MasterSupplierRecurringTab } from './masterSupplierTabs/MasterSupplierRecurringTab.js';
import { usePrefetchMasterSupplierTabs } from './hooks/usePrefetchMasterSupplierTabs.js';
import { EditMasterSupplier } from './EditMasterSupplier.js';
import { NotFoundEntity } from '../../components/NotFoundEntity.js';

const {
  masterSupplierAccount,
  masterSupplierBranches,
  masterSupplierProjects,
  masterSupplierRecurring,
  masterSupplierTasks,
  masterSupplierPeople,
  masterSupplierUpdates,
  masterSupplierFiles,
  masterSupplierInvoices,
} = masterSupplierPageTabs;

const permit = R.compose(
  R.without([masterSupplierAccount]),
  R.values,
)(masterSupplierPageTabs);

const editMasterSupplierFormId = 'editMasterSupplierFormId';

// getFallBackLink :: MasterSupplier -> String
const getFallBackLink = R.compose(
  R.cond([
    [
      R.equals(supplierTypes.ADMINISTRATIVE),
      R.always(routesNames.MASTER_SUPPLIERS_ADMINISTRATIVE),
    ],
    [R.T, R.always(routesNames.MASTER_SUPPLIERS_SUBCONTRACTORS)],
  ]),
  R.prop('type'),
);

export function MasterSupplierPage() {
  const { data, loading } = usePrefetchMasterSupplierTabs();

  const { isEdit } = useRouterQuery(['isEdit']);
  const { supplierId } = useRouterParams(['supplierId']);

  const title = R.path(['masterSupplier', 'company', 'name'], data);
  useReactiveDocumentTitle(title);

  if (!loading && data && !data.masterSupplier) {
    return <NotFoundEntity entityName="Master Supplier" />;
  }

  return (
    <>
      <MasterSupplierPageHeader
        getFallBackLink={getFallBackLink}
        defaultTab={masterSupplierAccount}
        formId={editMasterSupplierFormId}
        supplierId={supplierId}
      />
      {isEdit ? (
        <EditMasterSupplier supplierId={supplierId} />
      ) : (
        <PageTabs
          toolBar={
            <SearchInputTabsDisplay {...{ permit }}>
              <FlexContainer>
                <TableSearchInput />
                <MasterSupplierInvoicesToolbar />
              </FlexContainer>
            </SearchInputTabsDisplay>
          }
          tabs={[
            [
              'Info',
              masterSupplierAccount,
              <MasterSupplierInfoTab
                supplierId={supplierId}
                key={masterSupplierAccount}
              />,
            ],
            [
              'Branches',
              masterSupplierBranches,
              <MasterSupplierBranchesTab key={masterSupplierBranches} />,
            ],
            [
              'Projects',
              masterSupplierProjects,
              <MasterSupplierProjectsTab key={masterSupplierProjects} />,
            ],
            [
              'Masters',
              masterSupplierRecurring,
              <MasterSupplierRecurringTab key={masterSupplierRecurring} />,
            ],
            [
              'Tasks',
              masterSupplierTasks,
              <MasterSupplierTasksTab key={masterSupplierTasks} />,
            ],
            [
              'Updates',
              masterSupplierUpdates,
              <MasterSupplierUpdatesTab key={masterSupplierUpdates} />,
            ],
            [
              'Contacts',
              masterSupplierPeople,
              <MasterSupplierPeopleTab key={masterSupplierPeople} />,
            ],
            [
              'Files',
              masterSupplierFiles,
              <MasterSupplierFilesTab key={masterSupplierFiles} />,
            ],
            [
              'Invoices',
              masterSupplierInvoices,
              <MasterSupplierInvoicesTab key={masterSupplierInvoices} />,
            ],
          ]}
          defaultValue={masterSupplierAccount}
        />
      )}
    </>
  );
}
