import styled from 'styled-components';
import { Table } from 'poly-book-admin';

export const RecentProjectsTableComp = styled(Table)`
  th,
  td {
    padding-right: 50px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 15%;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 30%;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }
`;
