import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { string, shape, func } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { ASC_SORT_ORDER } from 'poly-constants';
import { MAX_ITEMS } from 'poly-admin-ui';
import { Select } from 'poly-book-admin';
import { debounce } from 'poly-utils';
import {
  entityToOptionByLabelPath,
  getAssetModelsQuery,
  keywordSortQuery,
} from 'poly-client-utils';

const SEARCH_ASSET_MODELS_QUERY = gql`
  query SEARCH_ASSET_MODELS_QUERY($input: CollectionSearchParams) {
    searchAssetManufacturesModels(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

// prepareAssetModelOptions :: { assetManufacturer: AssetManufacturer } -> [Option]
const prepareAssetModelOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['searchAssetManufacturesModels', 'hits']),
  R.defaultTo({}),
);

export function AssetModelSelect({
  formData,
  changeFieldValue,
  value,
  ...props
}) {
  const { manufacturerId } = formData;
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useCallback(
    debounce(300)((search) => {
      setSearchTerm(search);

      if (search) {
        changeFieldValue('searchedModel', search);
      }
    }),
    [],
  );

  const { data } = useQuery(SEARCH_ASSET_MODELS_QUERY, {
    variables: {
      input: {
        searchTerm,
        size: MAX_ITEMS,
        query: getAssetModelsQuery(manufacturerId),
        sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      },
    },
    fetchPolicy: 'network-only',
    skip: !manufacturerId,
  });

  const options = prepareAssetModelOptions(data);

  const selectProps = {
    ...props,
    value,
    options,
    disabled: !manufacturerId,
    onInputChange: debouncedSearch,
    placeholder: 'Start typing models',
  };

  return <Select {...selectProps} />;
}

AssetModelSelect.propTypes = {
  changeFieldValue: func.isRequired,
  formData: shape({ manufacturerId: string }).isRequired,
  value: string,
};
