import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { string, arrayOf, func, oneOfType } from 'prop-types';
import { MultiSelectDropDown } from 'poly-admin-ui';
import { AccountStatuses } from 'poly-constants';
import { isNilOrEmpty } from 'poly-utils';

import { prepareAccountLabel } from './GLCodeSelect/GLCodeSelect.js';

const MultiSelectS = styled(MultiSelectDropDown)`
  .multiselect__control,
  .multiselect__control:hover,
  .multiselect__control:focus,
  .multiselect__control--is-focused {
    min-height: 32px;
  }

  .multiselect__control {
    padding: 0;
  }

  .multiselect__value-container {
    min-height: 30px;
    max-height: ${R.propOr('85px', 'maxHeight')};
    overflow-y: auto;

    > div:last-child {
      height: 22px;
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const ACCOUNTS_QUERY = gql`
  query ACCOUNTS_QUERY($filters: AccountFilters) {
    getAccounts(filters: $filters) {
      hits {
        _id
        code
        name
        childAccounts {
          _id
          code
          name
        }
      }
    }
  }
`;

// formatAccountOptions :: GetAccountsResult -> [Option]
const formatAccountOptions = R.compose(
  R.flatten,
  R.map(
    R.juxt([
      R.compose(
        R.of,
        R.applySpec({
          value: R.prop('_id'),
          label: prepareAccountLabel,
        }),
      ),
      R.compose(
        R.map(
          R.applySpec({
            value: R.prop('_id'),
            label: prepareAccountLabel,
          }),
        ),
        R.propOr([], 'childAccounts'),
      ),
    ]),
  ),
  R.pathOr([], ['getAccounts', 'hits']),
);

// prepareSelectValue :: [ID] -> [Option] -> [Option]
const prepareSelectValue = (value, options) => {
  const selectedValues = R.cond([
    [isNilOrEmpty, R.always([])],
    [R.is(String), R.of],
    [R.T, R.identity],
  ])(value);

  return R.filter(
    R.compose((id) => R.any(R.equals(id))(selectedValues), R.prop('value')),
  )(options);
};

export function MultipleAccountsSelect({
  name,
  error,
  value,
  onChange,
  maxHeight,
}) {
  const variables = {
    filters: {
      subAccount: false,
      status: AccountStatuses.ACTIVE,
    },
  };

  const { data } = useQuery(ACCOUNTS_QUERY, { variables });

  const handleChange = (codes) => {
    const preparedCodes = codes ? R.map(R.prop('value'), codes) : '';
    onChange(preparedCodes);
  };

  const options = useMemo(() => formatAccountOptions(data), [data]);

  const preparedValue = useMemo(
    () => prepareSelectValue(value, options),
    [value, options],
  );

  const selectProps = {
    name,
    error,
    options,
    handleChange,
    maxHeight,
    value: preparedValue,
    placeholder: 'Start typing GL Code',
  };

  return <MultiSelectS {...selectProps} />;
}

MultipleAccountsSelect.propTypes = {
  error: string,
  name: string.isRequired,
  onChange: func.isRequired,
  value: oneOfType([string, arrayOf(string)]),
  maxHeight: string,
};
