import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';
import { FormCreator } from 'poly-book-admin/src/Form/FormCreator.js';
import {
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  commonSidebarFormSectionLayout,
} from 'poly-admin-ui/src/sidebar/commonSidebarFormConstants.js';
import { usePristineSubscribe } from 'poly-client-routing/src/hooks.js';
import { useOnSubmitSetStopSubmitting } from 'poly-admin-ui/src/hooks/useOnSubmitSetStopSubmitting.js';
import { useNotificationState } from 'poly-admin-ui/src/hooks/useNotificationState.js';
import { useOutSidebarContext } from 'poly-client-utils/src/slideOutSidebar.js';

import { addPurchaseOrderFormId } from '../constants.js';
import { getPurchaseOrderFormSections } from './purchaseOrderFormSections.js';
import { preparePurchaseOrderBeforeMutation } from '../utils.js';
import { useSidebarLogicContext } from '../../SidebarLogicContext.js';

const FormCreatorS = styled(FormCreator)`
  min-height: calc(100vh - 190px);
`;

export const CREATE_ADMIN_PO_MUTATION = gql`
  mutation CREATE_ADMIN_PO_MUTATION($input: CreateAdminPurchaseOrderInput!) {
    createAdminPurchaseOrder(input: $input) {
      _id
    }
  }
`;

export function AddPurchaseOrderForm({ clientId }) {
  const [createPurchaseOrderMutation] = useMutation(CREATE_ADMIN_PO_MUTATION);

  const { showSuccessNotification } = useNotificationState();
  const { closeOutSidebar } = useOutSidebarContext();

  const pristineSubscribeProps = usePristineSubscribe({
    id: addPurchaseOrderFormId,
  });

  const { openPOSidebar } = useSidebarLogicContext();

  const initialValues = { clientId, notificationContactsEmails: [] };

  const onSubmitHandler = async (formData) => {
    const { data } = await createPurchaseOrderMutation({
      variables: { input: preparePurchaseOrderBeforeMutation(formData) },
    });

    showSuccessNotification('Purchase Order was successfully created');
    closeOutSidebar(addPurchaseOrderFormId);
    openPOSidebar(data?.createAdminPurchaseOrder?._id);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addPurchaseOrderFormId,
    onSubmitHandler,
  );

  return (
    <FormCreatorS
      id={addPurchaseOrderFormId}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      sections={getPurchaseOrderFormSections(false)}
      onSubmit={onSubmit}
      {...pristineSubscribeProps}
    />
  );
}

AddPurchaseOrderForm.propTypes = {
  clientId: string,
};
