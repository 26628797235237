import React, { useEffect } from 'react';
import { DefaultBodyWrapper, Loader } from 'poly-book-admin';
import { useReactiveDocumentTitle } from 'poly-client-utils';
import { entities, SidebarWrapperForTableForm } from 'poly-admin-ui';
import { useRouterParams } from 'poly-client-routing';
import { shape, string } from 'prop-types';

import {
  PageCardBody,
  PageCardFirstSection,
  PageCardSecondSection,
} from '../components/PageCardComponents.js';
import { NotFoundEntity } from '../components/NotFoundEntity.js';
import { ProjectSidebarSections } from '../sidebars/ProjectSidebar/ProjectSidebar.js';
import { ProjectSidebarTabs } from '../sidebars/ProjectSidebar/tabs/ProjectSidebarTabs.js';
import { prepareProjectHistoryRecordByEntity } from '../sidebars/components/commonSidebarUtils.js';
import { useProjectSidebarData } from '../sidebars/ProjectSidebar/useProjectSidebarData.js';
import { useSidebarLogicContext } from '../sidebars/SidebarLogicContext.js';
import { useCloseExtendedTab } from '../hooks/useCloseExtendedTab.js';

function ProjectCardUI({ project }) {
  const closeExtendedTab = useCloseExtendedTab();
  const { setHistoryRecord } = useSidebarLogicContext();

  const historyRecord = prepareProjectHistoryRecordByEntity(entities.PROJECT)(
    project,
  );

  useEffect(() => {
    setHistoryRecord(historyRecord);
  }, []);

  return (
    <DefaultBodyWrapper height="100%">
      <PageCardBody>
        {!project ? (
          <NotFoundEntity entityName="project" />
        ) : (
          <>
            <PageCardFirstSection onClick={closeExtendedTab}>
              <SidebarWrapperForTableForm>
                <ProjectSidebarSections project={project} isCard />
              </SidebarWrapperForTableForm>
            </PageCardFirstSection>
            <PageCardSecondSection>
              <ProjectSidebarTabs
                customTabQueryKey="cardTab"
                project={project}
                isCard
              />
            </PageCardSecondSection>
          </>
        )}
      </PageCardBody>
    </DefaultBodyWrapper>
  );
}

ProjectCardUI.propTypes = {
  project: shape({
    _id: string.isRequired,
    projectId: string.isRequired,
  }),
};

export function ProjectCard() {
  const { projectId } = useRouterParams(['projectId']);
  const { loading, project } = useProjectSidebarData(projectId);

  useReactiveDocumentTitle(project ? project.projectId : null);

  if (loading) {
    return <Loader />;
  }

  if (!project) {
    return <NotFoundEntity entityName="project" />;
  }

  return <ProjectCardUI project={project} />;
}
