import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { getThemeColor, Icon, Input } from 'poly-book-admin';
import { isNilOrEmpty } from 'poly-utils';

import { useApproveNewEquipmentPopup } from './useApproveNewEquipmentPopup.js';
import { getEquipmentTitle } from './useApproveNewEquipmentModal.js';

const firstParagraph =
  'We have found similar titles in the system, click on the tick if one of them is exactly what you need.';

const secondParagraph =
  'or enter your custom name, in case none of the above is suitable';

const noSuggestionsParagraph =
  'There are no similar titles found. Enter the name you would like to create';

const ApproveNewEquipmentWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  width: 320px;
`;

const ApproveNewEquipmentTitleS = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${getThemeColor(['darkest'])};
`;

const ApproveNewEquipmentTextS = styled(ApproveNewEquipmentTitleS)`
  font-size: 12px;
  margin: 8px 0 12px 0;
  word-break: normal;
`;

const ApproveNewEquipmentItemS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 12px;
  background-color: ${getThemeColor(['accent2Lighter4'])};
  margin-bottom: 8px;
  font-size: 10px;
  line-height: 14px;
  color: ${getThemeColor(['primaryLight'])};
  cursor: pointer;

  > svg {
    fill: ${getThemeColor(['midDark'])};
  }

  :focus,
  :hover {
    > svg {
      fill: ${getThemeColor(['primaryMid'])};
    }
  }
`;

const ApproveNewEquipmentSelectWrapperS = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  input {
    padding-right: 25px;
  }

  > svg {
    fill: ${getThemeColor(['midDark'])};
    position: absolute;
    right: 8px;
    top: 8px;
    width: 16px;
    padding: 4px;
    cursor: pointer;

    :focus,
    :hover {
      fill: ${getThemeColor(['primaryMid'])};
    }
  }
`;

const ApproveNewEquipmentSelectListS = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100px;
  position: absolute;
  top: 33px;
  right: 0;
  background-color: ${getThemeColor(['white'])};
  border: solid 1px ${getThemeColor(['midLight'])};
  border-radius: 4px;
  padding: 2px 0;
  overflow-y: auto;
`;

const ApproveNewEquipmentSelectPlaceholderS = styled.div`
  display: flex;
  width: 100%;
  color: ${getThemeColor(['midDark'])};
  line-height: 18px;
  font-size: 12px;
  padding: 6px 10px;
  justify-content: center;
`;

const ApproveNewEquipmentSelectItemS = styled(
  ApproveNewEquipmentSelectPlaceholderS,
)`
  color: ${getThemeColor(['primary'])};
  justify-content: flex-start;
  cursor: pointer;

  :hover,
  :focus {
    background-color: ${getThemeColor(['accent2Lighter4'])};
  }
`;

function CheckIcon(props) {
  return <Icon {...props} size={8} name="checkMark" />;
}

export function ApproveNewEquipmentContent({
  entity,
  assetId,
  equipmentName,
  manufacturerId,
}) {
  const {
    items,
    isOpen,
    onFocus,
    onChange,
    onSelect,
    selectRef,
    inputValue,
    suggestions,
    selectedItem,
    onItemSelect,
  } = useApproveNewEquipmentPopup({
    entity,
    assetId,
    equipmentName,
    manufacturerId,
  });

  const hasSuggestions = !isNilOrEmpty(suggestions);

  return (
    <ApproveNewEquipmentWrapperS>
      <ApproveNewEquipmentTitleS>
        {getEquipmentTitle(entity)}
      </ApproveNewEquipmentTitleS>
      {hasSuggestions ? (
        <>
          <ApproveNewEquipmentTextS>{firstParagraph}</ApproveNewEquipmentTextS>
          {suggestions?.map(({ _id, name }) => (
            <ApproveNewEquipmentItemS
              key={_id}
              onClick={() => onSelect(_id, name)}
            >
              {name}
              <CheckIcon />
            </ApproveNewEquipmentItemS>
          ))}
          <ApproveNewEquipmentTextS>{secondParagraph}</ApproveNewEquipmentTextS>
        </>
      ) : (
        <ApproveNewEquipmentTextS>
          {noSuggestionsParagraph}
        </ApproveNewEquipmentTextS>
      )}
      <ApproveNewEquipmentSelectWrapperS ref={selectRef}>
        <Input
          width="100%"
          onFocus={onFocus}
          value={inputValue}
          onChange={onChange}
          name="new-equipment-input"
        />
        {isOpen && (
          <ApproveNewEquipmentSelectListS>
            {R.isEmpty(items) ? (
              <ApproveNewEquipmentSelectPlaceholderS>
                No options
              </ApproveNewEquipmentSelectPlaceholderS>
            ) : (
              items.map(({ _id, name }) => (
                <ApproveNewEquipmentSelectItemS
                  key={_id}
                  onClick={() => onItemSelect({ _id, name })}
                >
                  {name}
                </ApproveNewEquipmentSelectItemS>
              ))
            )}
          </ApproveNewEquipmentSelectListS>
        )}
        <CheckIcon onClick={() => onSelect(selectedItem, inputValue)} />
      </ApproveNewEquipmentSelectWrapperS>
    </ApproveNewEquipmentWrapperS>
  );
}

ApproveNewEquipmentContent.propTypes = {
  manufacturerId: string,
  entity: string.isRequired,
  assetId: string.isRequired,
  equipmentName: string.isRequired,
};
