import * as R from 'ramda';
import { addHours } from 'date-fns';
import {
  DefaultProjectPrioritiesHours,
  DefaultProjectPrioritiesHoursMap,
  WorkOrderPriority,
} from 'poly-constants';
import { addBusinessDays, addBusinessHours } from '../dates-business.js';
import { wholeDays, alwaysNewDate } from '../dates.js';

// getHoursOffsetForPriority :: ProjectWithClient -> Number
//   ProjectWithClient = {
//     ...Project,
//     client: Client,
//   }
const getHoursOffsetForPriority = R.compose(
  R.converge(
    R.propOr(DefaultProjectPrioritiesHours[WorkOrderPriority.NORMAL]),
    [
      R.propOr(WorkOrderPriority.NORMAL, 'priority'),
      R.compose(
        R.mergeAll,
        R.map(({ priority, hours }) => ({ [priority]: hours })),
        R.pathOr(DefaultProjectPrioritiesHoursMap, [
          'client',
          'configs',
          'projectPrioritiesHours',
        ]),
      ),
    ],
  ),
);

// getStartDateByProject :: ProjectWithClient -> Date
const getStartDateByProject = R.compose(
  R.when(R.isNil, alwaysNewDate),
  R.prop('startDate'),
);

// addHoursAsBusinessDaysToStartDate :: ProjectWithClient -> Date
const addHoursAsBusinessDaysToStartDate = R.converge(addHours, [
  R.converge(addBusinessDays, [
    R.compose(wholeDays, getHoursOffsetForPriority),
    getStartDateByProject,
  ]),
  R.compose(R.mathMod(R.__, 24), getHoursOffsetForPriority),
]);

// addBusinessHoursToStartDate :: ProjectWithClient -> Date
const addBusinessHoursToStartDate = R.converge(addBusinessHours, [
  getHoursOffsetForPriority,
  getStartDateByProject,
]);

// shouldCountBusinessHoursForPriority :: ProjectWithClient -> Boolean
const shouldCountBusinessHoursForPriority = R.converge(R.includes, [
  R.prop('priority'),
  R.pathOr([], ['client', 'configs', 'countWorkingHoursForPriorities']),
]);

// getEndDateByProjectWithClient :: ProjectWithClient -> Date
export const getEndDateByProjectWithClient = R.ifElse(
  R.propIs(Date, 'endDate'),
  R.prop('endDate'),
  R.ifElse(
    shouldCountBusinessHoursForPriority,
    addBusinessHoursToStartDate,
    addHoursAsBusinessDaysToStartDate,
  ),
);
