import React from 'react';
import * as R from 'ramda';
import arrayMutators from 'final-form-arrays';
import { gql, useQuery } from '@apollo/client';
import { bool, string, shape, arrayOf, objectOf, oneOfType } from 'prop-types';
import { usePersistentFormValue } from 'poly-client-utils';
import { usePristineSubscribe } from 'poly-client-routing';
import { FormCreator, Loader } from 'poly-book-admin';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  useSaveContacts,
} from 'poly-admin-ui';

import { addProjectFormId } from '../../constants.js';
import { ProjectOccurrence } from '../../../../modules/core/constants/projects.js';
import { sideBarProjectFormSections } from '../form/sideBarProjectFormSections.js';
import { useDefaultClientInvoiceAccount } from '../../../../hooks/useDefaultClientInvoiceAccount.js';
import { isPropertyInactive } from '../../../PropertySidebar/propertySidebarUtils.js';
import { usePropertiesLimitByAdminPO } from '../form/usePropertiesLimitByAdminPO.js';
import { isClientInactive } from '../../../ClientSidebar/helpers.js';
import { useCloneFromProject } from './useCloneFromProject.js';
import { useOnSubmitProjectFrom } from './useOnSubmitForm.js';
import {
  clonedProjectInitialValues,
  createProjectInitialValues,
} from './helpers.js';

const propertyQuery = gql`
  query propertyQuery($propertyId: ID!) {
    property(id: $propertyId) {
      _id
      name
      costCenter
      isMaster
      address {
        address_parts {
          street_number
          route
          locality
          administrative_area_level_2
          administrative_area_level_1
          country
          postal_code
          postal_code_suffix
          neighborhood
        }
      }
      client {
        _id
        enableReportOnlyProjectType
        enablePropertyLocationHierarchy
        enableGlCodes
        enableCostCenter
        configs {
          defaultProjectType
          enableHousekeepingProjectType
          enableProjectSubCategory
          enablePurchaseOrder
        }
        financial {
          division {
            _id
          }
        }
      }
    }
  }
`;

const clientQuery = gql`
  query clientQuery($clientId: ID!) {
    client(id: $clientId) {
      _id
      enableReportOnlyProjectType
      enablePropertyLocationHierarchy
      enableGlCodes
      enableCostCenter
      configs {
        defaultProjectType
        enableHousekeepingProjectType
        enableProjectSubCategory
        enablePurchaseOrder
      }
      financial {
        costCenter
        division {
          _id
        }
      }
    }
  }
`;

const useProperty = (propertyId) => {
  const { data, loading } = useQuery(propertyQuery, {
    variables: { propertyId },
    skip: !propertyId,
  });
  const property = R.path(['property'], data);
  return {
    loading,
    property,
  };
};

const useClient = (clientId) => {
  const { data, loading } = useQuery(clientQuery, {
    variables: { clientId },
    skip: !clientId,
  });
  const client = R.path(['client'], data);
  return {
    loading,
    client,
  };
};

// getInvoiceDescriptionPersistKey :: {cloneFromProjectId: String, entityId: String} -> String
const getInvoiceDescriptionPersistKey = R.compose(
  R.concat('invoiceDescription'),
  R.ifElse(
    R.prop('cloneFromProjectId'),
    R.compose(R.concat('.cloneFrom.'), R.prop('cloneFromProjectId')),
    R.compose(R.concat('.new.'), R.defaultTo(''), R.prop('entityId')),
  ),
);

// checkIfClientOrPropertyActive :: (Client, Property, Project) -> Project
const checkIfClientOrPropertyActive = (client, property, initialValues) =>
  R.compose(
    R.when(
      R.always(isPropertyInactive(property)),
      R.omit(['propertyId', 'property']),
    ),
    R.when(
      R.always(isClientInactive(client)),
      R.omit(['clientId', 'propertyId', 'property', 'client']),
    ),
  )(initialValues);

export function AddProjectSideBarForm({
  type,
  assetId,
  clientId,
  formProps,
  propertyId,
  projectType,
  supplierIds,
  purchaseOrderId,
  disableOccurrence,
  isPropertyDisabled,
  cloneFromProjectId,
  cloneWithoutAssets,
  cloneWithoutSuppliers,
  cloneFromMasterProjectId,
  cloneAsPreventativeRepair,
  invoiceDescription,
}) {
  const { propertyLimitIds } = usePropertiesLimitByAdminPO(purchaseOrderId);

  const pristineSubscribeProps = usePristineSubscribe({
    type,
    assetId,
    clientId,
    formProps,
    propertyId,
    projectType,
    isPropertyDisabled,
    cloneFromProjectId,
    cloneWithoutAssets,
    id: addProjectFormId,
    cloneWithoutSuppliers,
    cloneFromMasterProjectId,
    invoiceDescription,
  });

  const invoiceDescriptionPersistKey = getInvoiceDescriptionPersistKey({
    cloneFromProjectId: cloneFromProjectId || cloneFromMasterProjectId,
    entityId: assetId || propertyId,
  });

  const { onChangePersistentValue, cleanupRetainedValue, retainedValue } =
    usePersistentFormValue(invoiceDescriptionPersistKey);

  const saveContacts = useSaveContacts();

  const { loading: accountLoading, divisionAccountId } =
    useDefaultClientInvoiceAccount();

  const { property, loading: propertyLoading } = useProperty(propertyId);

  const { client, loading: clientLoading } = useClient(clientId);

  const { project: cloneFromProject, loading: projectLoading } =
    useCloneFromProject(cloneFromProjectId, cloneFromMasterProjectId);

  const onSubmitHandler = useOnSubmitProjectFrom(
    type,
    saveContacts,
    cloneWithoutSuppliers,
    cloneFromProject,
    supplierIds,
    cleanupRetainedValue,
  );
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addProjectFormId,
    onSubmitHandler,
  );

  if (propertyLoading || projectLoading || clientLoading || accountLoading) {
    return <Loader />;
  }

  const projectTypeByClient = R.pathOr(
    projectType,
    ['client', 'configs', 'defaultProjectType'],
    client,
  );

  const clientIdByProperty = R.pathOr(clientId, ['client', '_id'], property);
  const baseInitialValues = cloneFromProject
    ? clonedProjectInitialValues(
        {
          type,
          cloneWithoutAssets,
          cloneWithoutSuppliers,
          cloneAsPreventativeRepair,
          invoiceDescription: retainedValue || invoiceDescription,
        },
        cloneFromProject,
      )
    : createProjectInitialValues(
        {
          invoiceDescription: retainedValue || invoiceDescription,
          projectType: projectTypeByClient,
          clientId: clientIdByProperty,
          isPropertyDisabled,
          divisionAccountId,
          propertyLimitIds,
          purchaseOrderId,
          propertyId,
          assetId,
          client,
          type,
        },
        client,
        property,
      );

  const initialValues = cloneFromProject
    ? checkIfClientOrPropertyActive(
        cloneFromProject?.client,
        cloneFromProject?.property,
        baseInitialValues,
      )
    : baseInitialValues;

  const disableOccurrenceField =
    disableOccurrence ||
    (!!cloneFromProject &&
      (initialValues.occurrence === ProjectOccurrence.RECURRING_OCCURRENCE ||
        initialValues.occurrence ===
          ProjectOccurrence.PREVENTIVE_MAINTENANCE_OCCURRENCE));

  return (
    <FormCreator
      onSubmit={onSubmit}
      id={addProjectFormId}
      mutators={arrayMutators}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      sections={sideBarProjectFormSections(
        [],
        disableOccurrenceField,
        onChangePersistentValue,
        true,
      )}
      {...pristineSubscribeProps}
    />
  );
}

AddProjectSideBarForm.propTypes = {
  type: string,
  assetId: string,
  clientId: string,
  propertyId: string,
  projectType: string,
  purchaseOrderId: string,
  disableOccurrence: bool,
  isPropertyDisabled: bool,
  cloneWithoutAssets: bool,
  cloneFromProjectId: string,
  cloneWithoutSuppliers: bool,
  supplierIds: arrayOf(string),
  cloneFromPMProjectId: string,
  cloneFromMasterProjectId: string,
  cloneAsPreventativeRepair: bool,
  formProps: shape({ entity: string, sidebarId: string, type: string })
    .isRequired,
  invoiceDescription: oneOfType([
    shape({
      ops: arrayOf(objectOf(string)),
    }),
    string,
  ]),
};
