import { shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { SidebarWrapper } from 'poly-admin-ui';
import { Loader } from 'poly-book-admin';

import { UserSidebarHeader } from './UserSidebarHeader.js';
import { UserSidebarRemarks } from './UserSidebarRemarks.js';
import { NotFoundEntity } from '../../components/NotFoundEntity.js';
import { UserSidebarSystemAccess } from './UserSidebarSystemAccess.js';
import { UserSidebarPersonalInfo } from './UserSidebarPersonalInfo.js';
import { UserSidebarEmploymentInfo } from './UserSidebarEmploymentInfo.js';
import { useReactiveSidebarDocumentTitle } from '../useReactiveSidebarDocumentTitle.js';
import { useOpenUserTabsSidebar } from './tabs/useOpenUserTabsSidebar.js';
import { useUserSidebarData } from './useUserSidebarData.js';

function UserSidebarRouterLogic({ user }) {
  const openUserTabsSidebar = useOpenUserTabsSidebar();

  useEffect(() => {
    openUserTabsSidebar(user);
  }, []);

  useReactiveSidebarDocumentTitle(user.profile.fullName);

  return null;
}

UserSidebarRouterLogic.propTypes = {
  user: shape({
    profile: shape({
      fullName: string,
    }),
  }),
};

export function UserSidebar({ userId }) {
  const { loading, ...sidebarProps } = useUserSidebarData(userId);

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarWrapper>
      {!sidebarProps.user ? (
        <NotFoundEntity entityName="user" />
      ) : (
        <>
          <UserSidebarHeader {...sidebarProps} />
          <UserSidebarPersonalInfo {...sidebarProps} />
          <UserSidebarSystemAccess {...sidebarProps} />
          <UserSidebarRemarks {...sidebarProps} />
          <UserSidebarEmploymentInfo {...sidebarProps} />
          <UserSidebarRouterLogic {...sidebarProps} />
        </>
      )}
    </SidebarWrapper>
  );
}

UserSidebar.displayName = 'UserSidebar';
UserSidebar.propTypes = { userId: string.isRequired };
