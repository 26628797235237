import { useState } from 'react';
import { useNotificationContext } from 'poly-book-admin';
import { useMutation, gql } from '@apollo/client';
import { useSidebarDocumentsNavigation } from '../../../components/PreviewDocumentsNavigation.js';
import { SidebarIDs } from '../../../sidebars/constants.js';

const rejectRequestedSupplierDocumentMutation = gql`
  mutation rejectRequestedSupplierDocument(
    $input: RejectRequestedSupplierDocumentInput!
  ) {
    rejectRequestedSupplierDocument(input: $input)
  }
`;

export const useRejectRequestedSupplierDocument = (
  documentId,
  handleCloseSidebar,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showSuccessNotification } = useNotificationContext();

  const [rejectRequestedSupplierDocument] = useMutation(
    rejectRequestedSupplierDocumentMutation,
  );

  const {
    goToNextDocument,
    goToPreviousDocument,
    isNexDocument,
    isPreviousDocument,
  } = useSidebarDocumentsNavigation(SidebarIDs.requestedSupplierDocument);

  const handleRejectDocument = async (rejectionReason) => {
    setIsSubmitting(true);
    await rejectRequestedSupplierDocument({
      variables: { input: { documentId, rejectionReason } },
    });
    showSuccessNotification('Document successfully rejected');
    setIsSubmitting(false);

    if (isNexDocument) {
      goToNextDocument();
      return;
    }

    if (isPreviousDocument) {
      goToPreviousDocument();
      return;
    }

    if (typeof handleCloseSidebar === 'function') {
      handleCloseSidebar();
    }
  };

  return {
    handleRejectDocument,
    isSubmitting,
  };
};
