import React from 'react';
import { string } from 'prop-types';
import { gql } from '@apollo/client';
import { commonUpdateFields, entities, SidebarUpdatesTab } from 'poly-admin-ui';
import { UpdateCollections } from 'poly-constants';

import { PrintLayoutProvider } from '../../../providers.js';

const ASSET_UPDATES = gql`
  query CLIENT_UPDATES($id: ID!, $documentUpdatesInput: DocumentUpdatesInput!) {
    asset(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export function AssetSidebarUpdatesTab({ assetId, assetName }) {
  const tabProps = {
    entity: {
      _id: assetId,
      preview: 'asset',
      name: entities.ASSET,
      humanReadableId: assetName,
    },
    updatesQuery: ASSET_UPDATES,
    PrintLayout: PrintLayoutProvider,
    collection: UpdateCollections.assets,
  };

  return <SidebarUpdatesTab {...tabProps} />;
}

AssetSidebarUpdatesTab.propTypes = {
  assetId: string.isRequired,
  assetName: string.isRequired,
};
