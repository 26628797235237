import * as R from 'ramda';
import { getFileNameWithoutExt, isNilOrEmpty } from 'poly-utils';

// getFileName :: Invoice -> String
const getFileName = R.pathOr('', ['invoiceFile', 'fileName']);

// getFileNameWithoutExt :: Invoice -> String
const getInvoiceFileNameWithoutExt = R.compose(
  getFileNameWithoutExt,
  getFileName,
);

// checkInvoiceFileIsOld :: Invoice -> Invoice -> Boolean
export const checkInvoiceFileIsOld = (invoice) =>
  R.allPass([
    R.always(invoice.invoiceFile),
    R.converge(R.equals, [
      getInvoiceFileNameWithoutExt,
      () => getInvoiceFileNameWithoutExt(invoice),
    ]),
    R.pathSatisfies(isNilOrEmpty, ['invoiceFile', 'upload']),
  ]);

// transformInvoiceFileToPicker :: InvoiceFile -> Object
export const transformInvoiceFileToPicker = R.applySpec({
  _id: R.prop('fileName'),
  fileName: R.pipe(R.prop('fileName'), getFileNameWithoutExt),
  url: R.prop('url'),
});
