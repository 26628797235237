import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopUp, useNotificationState } from 'poly-admin-ui';
import styled from 'styled-components';

import { AlertButton } from './AlertButton.js';
import { UserAlertsContent } from './UserAlertsContent.js';
import { setPopup } from '../../../redux/popupReducer.js';

const PopUpS = styled.div`
  position: relative;
`;

export function AlertPopup() {
  const { showWarningNotification } = useNotificationState();
  const outsideClickDisabled = useSelector(
    (state) => state.outsideClickDisabled,
  );
  const popupEntity = useSelector((store) => store.popupEntity);
  const dispatch = useDispatch();

  const handleAlertButtonClick = (alertsNumber, archivedAlertsNumber) => {
    if (!alertsNumber && !archivedAlertsNumber) {
      showWarningNotification("You don't have alerts yet");
      return;
    }
    dispatch(setPopup(popupEntity === 'alerts' ? '' : 'alerts'));
  };

  return (
    <PopUpS>
      <PopUp
        isOpen={popupEntity === 'alerts'}
        close={() => dispatch(setPopup(''))}
        outsideClickDisabled={outsideClickDisabled}
        trigger={<AlertButton onClick={handleAlertButtonClick} />}
      >
        <UserAlertsContent />
      </PopUp>
    </PopUpS>
  );
}

AlertPopup.displayName = 'AlertPopup';
