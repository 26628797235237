import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo } from 'react';
import { ensureIsDate } from 'poly-utils';
import { ESFMFullLogo, EmailLink } from 'poly-admin-ui';
import { useRouterQuery } from 'poly-client-routing';

import { signedAgreementValues } from '../DocumentsRequest/constants.js';
import { SubcontractorAgreementFrame } from './SubcontractorAgreementFrame.js';
import { useSubcontractorAgreementPrintLogic } from './useSubcontractorAgreementPrintLogic.js';

const PageWrapperS = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  max-width: 1424px;
  padding: 70px 25px 25px 25px;
  font-family: 'TTNormsRegular', sans-serif;
  page-break-before: always;
`;

const AACFullLogo = styled(ESFMFullLogo)`
  display: block;
  position: absolute;
  top: 25px;
  left: 45px;
`;

const SignatureBlockS = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media print {
    margin-top: 2100px;
    position: absolute;
  }
`;

const TitleS = styled.h4`
  margin: 0 0 100px 0;
`;

const SignatureRowS = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 0 50px 0;
  font-size: 14px;
  font-weight: 700;

  > div {
    width: 50%;
  }

  p {
    display: inline;
    font-weight: 400;
    font-style: italic;
    margin: 0 0 0 20px;
  }
`;

const AddressLinesBlockS = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 100px;

  > p {
    margin: ${({ isSigned }) => (isSigned ? '0 0 10px 0;' : '10px;')};
  }
`;

const FooterInfoS = styled(SignatureRowS)`
  display: inline;
  font-weight: 400;

  > a {
    margin-left: 3px;
  }
`;

function AddressPlaceholder() {
  return (
    <>
      <p>
        _____________________________________________________________________
      </p>
      <p>
        _____________________________________________________________________
      </p>
    </>
  );
}

// prepareSubcontractorInfo :: Object -> Object
const prepareSubcontractorInfo = R.applySpec({
  title: R.prop('title'),
  user: R.prop('contactName'),
  companyName: R.prop('companyName'),
  signedBy: R.compose(
    R.join(' '),
    R.juxt([R.prop('contactName'), R.prop('ipAddress')]),
  ),
  date: R.compose(
    R.ifElse(
      R.isNil,
      // (date) => date.toISOString() doesn't work Safari on iOS
      () => new Date().toISOString(),
      R.compose((date) => date.toISOString(), ensureIsDate),
    ),
    R.prop('date'),
  ),
  addressArr: R.compose(R.split('/'), R.propOr('', 'companyAddress')),
});

export function SubcontractorAgreementPage() {
  const { isPrint, ...agreementInfo } = useRouterQuery([
    ...signedAgreementValues,
    'isPrint',
  ]);

  const isAgreementSigned = useMemo(
    () => R.complement(R.isEmpty)(agreementInfo),
    [agreementInfo],
  );

  const signedAgreementInfo = useMemo(
    () =>
      prepareSubcontractorInfo({
        ...agreementInfo,
        ipAddress: agreementInfo.ip ? `(IP: ${agreementInfo.ip})` : null,
      }),
    [agreementInfo],
  );

  const { date, title, user, companyName, signedBy, addressArr } =
    signedAgreementInfo;

  useSubcontractorAgreementPrintLogic(isPrint || isAgreementSigned);

  return (
    <PageWrapperS>
      <SubcontractorAgreementFrame />
      <AACFullLogo skipBeta isAAC />
      <SignatureBlockS>
        <TitleS>Acceptance of Subcontractor Agreement</TitleS>
        <SignatureRowS>
          Subcontractor Name:
          <p>{companyName}</p>
        </SignatureRowS>
        <SignatureRowS>
          Address:
          <AddressLinesBlockS isSigned={isAgreementSigned}>
            {isAgreementSigned ? (
              addressArr.map((addressLine) => (
                <p key={addressLine}>{addressLine}</p>
              ))
            ) : (
              <AddressPlaceholder />
            )}
          </AddressLinesBlockS>
        </SignatureRowS>
        <SignatureRowS>
          <div>
            Signed By:
            <p>{signedBy}</p>
          </div>
          <div>
            Date:
            <p>{isAgreementSigned ? date : null}</p>
          </div>
        </SignatureRowS>
        <SignatureRowS>
          <div>
            Printed Name:
            <p>{user}</p>
          </div>
          <div>
            Title:
            <p>{title}</p>
          </div>
        </SignatureRowS>
        <FooterInfoS>
          Please fax this completed form to A. Anthony LLC at 704-625-8846 or
          email to <EmailLink email="suppliers@aanthonycorp.com" />
        </FooterInfoS>
      </SignatureBlockS>
    </PageWrapperS>
  );
}
