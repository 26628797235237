import React from 'react';
import * as R from 'ramda';
import { object, oneOfType, string } from 'prop-types';
import { highlightedTextPropType, SidebarWidth } from 'poly-admin-ui';
import { useUpdateQueryParams } from 'poly-client-routing';
import { useOutSidebarContext } from 'poly-client-utils';
import { insertParamsIntoURL } from 'poly-utils';

import { SidebarIDs } from '../constants.js';
import { PropertySidebar } from './PropertySidebar.js';
import { routesNames } from '../../routes/constants.js';
import { LinkToSidebar } from '../../components/LinkToSidebar.js';
import { useClearPristineState } from '../useClearPristineOnSidebarMount.js';

export function PropertyLink({
  _id,
  name,
  children,
  queryParams = {},
  ...restProps
}) {
  const linkParams = R.mergeDeepLeft(
    { query: { [SidebarIDs.property]: _id } },
    queryParams,
  );

  const linkProps = {
    linkParams,
    entityCardLink: insertParamsIntoURL(
      { propertyId: _id },
      routesNames.PROPERTY_CARD,
    ),
    ...restProps,
  };

  return <LinkToSidebar {...linkProps}>{children || name}</LinkToSidebar>;
}

PropertyLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: object,
  _id: string.isRequired,
  name: highlightedTextPropType.isRequired,
  children: oneOfType([string, object]),
};

export const useOpenPropertySidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (propertyId, skipSteady) =>
    openOutSidebar(
      {
        alwaysFirst: true,
        width: SidebarWidth,
        id: SidebarIDs.property,
        content: propertyId ? (
          <PropertySidebar propertyId={propertyId} />
        ) : null,
      },
      skipSteady,
    );
};

export const useNavigateToPropertySidebar = () => {
  const updateQueryParams = useUpdateQueryParams();
  const clearPristine = useClearPristineState();

  return (propertyId) => {
    clearPristine();
    updateQueryParams({
      [SidebarIDs.property]: propertyId,
      [SidebarIDs.client]: undefined,
    });
  };
};
