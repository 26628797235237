import * as R from 'ramda';
import {
  AccountingStatus,
  BillingProfileConsolidateBy,
  WorkOrderStatus,
} from 'poly-constants';
import {
  getCommonProjectsQueryByConfig,
  getConstantQuery,
  getShouldOneOfTermsQuery,
  getTargetFieldQuery,
} from './utils.js';

// generateProjectsQueryForConfigByTarget :: String -> BillingProfileUIConfig -> MongoQuery
export const generateProjectsQueryByFullConfig = R.compose(
  R.objOf('bool'),
  R.objOf('must'),
  R.reject(R.isNil),
  R.converge(R.concat, [
    R.converge(getTargetFieldQuery, [
      R.cond([
        [
          R.pathEq(
            ['consolidateBy', 0],
            BillingProfileConsolidateBy.COST_CENTER,
          ),
          R.always(['costCenter']),
        ],
        [
          R.pathSatisfies(
            R.includes(R.__, [
              BillingProfileConsolidateBy.REFERENCE_NUMBER,
              BillingProfileConsolidateBy.ADMIN_PO,
            ]),
            ['consolidateBy', 0],
          ),
          R.always(['clientReferenceNumber', 'adminPurchaseOrderId']),
        ],
        [R.T, R.always(null)],
      ]),
      R.converge(Array.of, [
        getShouldOneOfTermsQuery('costCenterNames', 'costCenter'),
        getShouldOneOfTermsQuery('pos', 'clientReferenceNumber'),
        getShouldOneOfTermsQuery('adminPOIds', 'adminPurchaseOrderId'),
        getConstantQuery('status', WorkOrderStatus.COMPLETED),
        getConstantQuery('accountingStatus', AccountingStatus.READY_TO_INVOICE),
      ]),
    ]),
    getCommonProjectsQueryByConfig,
  ]),
);
