import * as R from 'ramda';
import React from 'react';
import { shape } from 'prop-types';
import { SidebarRow } from 'poly-admin-ui';
import { NOTHING_UI_STRING } from 'poly-constants';

import {
  AACManager,
  ClientManager,
  SiteContactor,
} from '../components/commonSidebarDetailsList.js';
import { getSectionContact } from '../components/commonSidebarSectionFormatters.js';
import {
  BlockWithLabel,
  SectionLabel,
  SectionWrapper,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';

// getProjectContact :: Project -> Any
const getProjectContact = R.ifElse(
  R.pathSatisfies(R.anyPass([R.isEmpty, R.isNil]), ['contact', 'fullName']),
  R.always(NOTHING_UI_STRING),
  R.prop('contact'),
);

export function ProjectSidebarContactInfo({ project }) {
  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel width="100%">Contact Information</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <AACManager project={project} />
        <ClientManager project={project} />
        <SiteContactor project={project} />
        <BlockWithLabel
          id="requester"
          label="Requester"
          position="left"
          {...threeBlocksProps}
          width="calc(50% - 12px)"
          Component={getSectionContact(getProjectContact(project), false)}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

ProjectSidebarContactInfo.propTypes = {
  project: shape({}),
};
