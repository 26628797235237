import { UPDATE_ASSET_PERMISSION } from 'poly-security';
import { useHasUserAccessWithPermission } from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';

export const useHasUserAccessToUpdateAsset = () => {
  const hasUpdateAssetPermission = useHasUserAccessWithPermission(
    UPDATE_ASSET_PERMISSION,
  );

  return hasUpdateAssetPermission;
};
