import styled from 'styled-components';
import { Button } from 'poly-book-admin';

export const FlexContainer = styled.div`
  display: flex;
  ${({ gap }) => (gap ? `gap: ${gap};` : '')}
`;

export const CancelBtn = styled(Button).attrs(() => ({
  size: 'small',
  styleType: 'basicSecondary',
  type: 'button',
  children: 'Cancel',
}))``;

export const SubmitBtn = styled(Button).attrs(() => ({
  size: 'small',
  type: 'submit',
}))``;

export const confirmCancelFormPayload = {
  icon: 'confirm',
  cancelBtnCaption: 'No',
  id: 'confirm_close_form_id',
  btnCaption: 'Yes, Close Form',
  message: 'You have unsaved data. Are you sure you want to close form?',
  content: 'You have unsaved data. Are you sure you want to close form?',
};
