import styled from 'styled-components';
import { getThemeColor, getThemeFont } from 'poly-book-admin';
import * as R from 'ramda';
import React from 'react';
import {
  calculateTotal,
  convertCentsToDollars,
  formatTotal,
  getPropertiesDeepByHierarchyNodeR,
} from 'poly-utils';
import { arrayOf, number, shape } from 'prop-types';

import { FlexContainer } from '../../../components/FlexContainer.js';

export const FooterContainer = styled(FlexContainer)`
  width: 100%;
  height: 60px;
  box-shadow: 0 -2px 2px 0 ${getThemeColor(['midLight'])};
  align-items: center;
  justify-content: center;
  background-color: ${getThemeColor(['white'])};
`;

const TotalTitle = styled.div`
  font-weight: ${getThemeFont(['regular'])};
  color: ${getThemeColor(['secondary'])};
  margin-right: 5px;
`;

const TotalWrapper = styled(FlexContainer)`
  width: 1424px;
  justify-content: flex-end;
  padding-right: 50px;
`;

// getFooterTotal :: [HierarchyNode] -> String
// HierarchyNode = {
//    properties: [{ clientInvoicesTotal: Int }]
//    children: [HierarchyNode]
// }
const getFooterTotal = R.compose(
  formatTotal,
  convertCentsToDollars,
  calculateTotal(R.propOr(0, 'clientInvoicesTotal')),
  R.flatten,
  R.map(getPropertiesDeepByHierarchyNodeR([])),
);

export function SpendReportsFooterC({ reports }) {
  return (
    <FooterContainer>
      <TotalWrapper>
        <TotalTitle>Total:</TotalTitle>
        {getFooterTotal(reports)}
      </TotalWrapper>
    </FooterContainer>
  );
}

SpendReportsFooterC.defaultProps = {
  reports: [],
};
SpendReportsFooterC.propTypes = {
  reports: arrayOf(
    shape({
      properties: arrayOf(
        shape({
          clientInvoicesTotal: number,
        }),
      ),
    }),
  ),
};
