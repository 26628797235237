import { JournalTypes } from 'poly-constants';

export const transactionTypeUILabels = {
  [JournalTypes.ACC_PAYABLE]: 'Supplier Invoice',
  [JournalTypes.ACC_PAYABLE_PAYMENT]: 'Invoice Payment',
  [JournalTypes.ACC_PAYABLE_PAYMENT_VOID]: 'Void Payment',
  [JournalTypes.DEPOSIT]: 'Deposit',
  [JournalTypes.MANUAL]: 'Journal Entry',
  [JournalTypes.ACC_RECEIVABLE]: 'Client Invoice',
  [JournalTypes.ACC_RECEIVABLE_PAYMENT]: 'Client Payment',
  [JournalTypes.TRANSFER]: 'Transfer',
  [JournalTypes.PAYROLL]: 'Invoice Payment',
  [JournalTypes.WORK_IN_PROGRESS]: 'Work In Progress',
  [JournalTypes.CLOSING]: 'Closing Journal',
  [JournalTypes.RECURRING]: 'Recurring Journal',
};
