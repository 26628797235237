import * as R from 'ramda';

// unlinkContact :: ([Link], Entity) -> [Link]
// Link = Object
// Entity = Object
export const unlinkContact = R.curry((links, entity) =>
  R.reject(R.propEq(`${entity.name}Id`, entity._id), links),
);

// isLikedToProperties :: ([Link], ID) -> Boolean
export const isLikedToProperties = (links, clientId) =>
  R.find(R.both(R.propEq('clientId', clientId), R.prop('propertyId')), links);
