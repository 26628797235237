import React from 'react';
import styled from 'styled-components';
import { AdminSettingsTable } from 'poly-admin-ui';
import {
  READ_ASSET_MANUFACTURER_PERMISSION,
  READ_TIMESHEET_REPORT_PERMISSION,
  READ_STAFF_REPORT_PERMISSION,
  READ_USER_GROUP_PERMISSION,
  READ_ASSET_TYPE_PERMISSION,
  CREATE_ASSET_PERMISSION,
  CREATE_SERVICE_TYPE,
  UPDATE_SERVICE_TYPE,
  READ_PROCEDURE_PERMISSION,
} from 'poly-security';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  MainHeader,
} from 'poly-book-admin';

import { routesNames } from '../routes/index.js';

const ContentWrapper = styled.div`
  max-width: 1424px;
  width: 100%;
  margin: 0 30px 0 50px;
  display: flex;
`;

export function AdminSettingsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Admin Directory</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <ContentWrapper>
          <AdminSettingsTable
            items={[
              {
                title: 'Administrative',
                links: [
                  {
                    name: 'Asset Manufacturers',
                    link: routesNames.ASSET_MANUFACTURERS,
                    permissions: [READ_ASSET_MANUFACTURER_PERMISSION],
                  },
                  {
                    name: 'Service Types',
                    link: routesNames.SERVICE_TYPES,
                    permissions: [CREATE_SERVICE_TYPE, UPDATE_SERVICE_TYPE],
                  },
                  {
                    name: 'Generate Asset QR Codes',
                    link: routesNames.GENERATE_ASSET_QR_CODES,
                    permissions: [CREATE_ASSET_PERMISSION],
                  },
                  {
                    name: 'Asset Types',
                    link: routesNames.ASSET_TYPES,
                    permissions: [READ_ASSET_TYPE_PERMISSION],
                  },
                  {
                    name: 'Asset Review',
                    link: routesNames.ASSET_REVIEW,
                    permissions: [READ_ASSET_MANUFACTURER_PERMISSION],
                  },
                  {
                    name: 'User Group Management',
                    link: routesNames.USER_GROUP_MANAGEMENT,
                    permissions: [READ_USER_GROUP_PERMISSION],
                  },
                  {
                    name: 'Manage Procedures',
                    link: routesNames.MANAGE_PROCEDURES,
                    permissions: [READ_PROCEDURE_PERMISSION],
                  },
                ],
              },
              {
                title: 'Staff',
                links: [
                  {
                    name: 'Staff Activity Report',
                    link: routesNames.STAFF_ACTIVITY_REPORT,
                    permissions: [READ_STAFF_REPORT_PERMISSION],
                  },
                  {
                    name: 'Timesheet Report',
                    link: routesNames.TIME_SHEET_REPORT,
                    permissions: [READ_TIMESHEET_REPORT_PERMISSION],
                  },
                ],
              },
            ]}
          />
        </ContentWrapper>
      </DefaultBodyWrapper>
    </>
  );
}
