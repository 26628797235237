import * as R from 'ramda';
import React, { useState } from 'react';
import { object, oneOfType, string } from 'prop-types';
import { initialPagination } from 'poly-client-utils';
import { MailToFieldsComp } from './MailToFieldsComp.js';
import { generateMailToQuery } from './mailToUtils.js';
import { useMailToHelpers } from './hooks.js';
import { getMappingMailToProps, getMailToHandlers } from './helpers.js';
import { useProjectEmails } from '../../../../hooks/projects/index.js';
import { useUsersEmailsMailTo } from '../../../../hooks/users/index.js';

// getClientIdFormProject :: { client: { _id: ID } } -> ID
const getClientIdFormProject = R.path(['client', '_id']);

export function MailTo({
  projectDbId: projectId,
  error: formError,
  ...restProps
}) {
  const [searchText, setSearchText] = useState('');

  const { project } = useProjectEmails(projectId);
  const clientId = getClientIdFormProject(project);

  const { result } = useUsersEmailsMailTo({
    query: generateMailToQuery(clientId),
    pagination: initialPagination,
    skipQuery: !searchText || !clientId,
  });

  const { changeProp, addNewEmail, usersEmails, newEmail } = useMailToHelpers({
    searchUsers: result?.searchUsers,
    setSearch: setSearchText,
    onChange: restProps.onChange,
    onBlur: restProps.onBlur,
    value: restProps.value,
  });

  const additionalMailProps = getMappingMailToProps({
    ...restProps,
    project,
    newEmail,
    changeProp,
    addNewEmail,
    usersEmails,
    formError,
  });

  const mailHandlers = getMailToHandlers(changeProp);

  return <MailToFieldsComp {...additionalMailProps} {...mailHandlers} />;
}

MailTo.propTypes = {
  projectDbId: string.isRequired,
  error: oneOfType([string, object]),
};
