import React from 'react';
import { string, func, node, bool } from 'prop-types';
import { ModalActions, Text } from 'poly-book-admin';

import {
  SidebarFormWrapper,
  SidebarTableFormColumn,
} from './sidebarComponents.js';
import { CancelBtn, SubmitBtn } from '../components/common.js';
import { useScrollIntoViewByRef } from '../hooks/useScrollIntoViewByRef.js';
import { useProcessState } from '../hooks/useProcessState.js';

export function CommonSidebarFormWrapper({
  title,
  formId,
  onCancel,
  children,
  SubTitle,
  isTableRow,
  Additional,
  submitCaption,
  disableSubmitBtn,
}) {
  const { process } = useProcessState(formId);
  const { ref } = useScrollIntoViewByRef();

  const commonButtonProps = {
    form: formId,
    loader: process,
    disabled: process,
  };

  const submitButtonProps = {
    ...commonButtonProps,
    disabled: process || disableSubmitBtn,
  };

  const Content = formId ? (
    <>
      <Text size="18px" lineHeight="24px">
        {title}
      </Text>
      {SubTitle}
      {children}
      <ModalActions>
        <SubmitBtn {...submitButtonProps}>{submitCaption}</SubmitBtn>
        <CancelBtn {...commonButtonProps} onClick={onCancel} />
      </ModalActions>
      {Additional}
    </>
  ) : (
    children
  );

  return isTableRow ? (
    <SidebarTableFormColumn ref={ref} onCancel={onCancel} skipMargin>
      {Content}
    </SidebarTableFormColumn>
  ) : (
    <SidebarFormWrapper onCancel={onCancel} ref={ref} skipMargin>
      {Content}
    </SidebarFormWrapper>
  );
}

CommonSidebarFormWrapper.propTypes = {
  id: string,
  title: string,
  formId: string,
  SubTitle: node,
  Additional: node,
  isTableRow: bool,
  submitCaption: string,
  onCancel: func.isRequired,
  children: node.isRequired,
  disableSubmitBtn: bool,
};

CommonSidebarFormWrapper.defaultProps = {
  isTableRow: false,
  submitCaption: 'Save',
  disableSubmitBtn: false,
};
