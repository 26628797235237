import * as R from 'ramda';
import React from 'react';
import { LinkButton } from 'poly-book-admin';
import { performExcelExport, ExcelExportCellType } from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';

import { forceTitleCase } from 'poly-utils';
import { formatTrialBalanceDateHeader } from './trialBalanceHelpers.js';
import {
  trialBalanceFiltersPropTypes,
  trialBalanceReportPropTypes,
} from './trialBalancePropTypes.js';

// getCurrentYearEarningsRowConfigs :: TrialBalanceReportResult -> [{ type: Int, value: Any }]
const getCurrentYearEarningsRowConfigs = R.compose(
  R.map(
    R.ifElse(
      R.is(Number),
      (value) => ({ cellType: ExcelExportCellType.defaultCurrency, value }),
      (value) => ({ value }),
    ),
  ),
  R.juxt([
    R.always(''),
    R.always('Current Year Net Earnings'),
    R.always(''),
    R.ifElse(R.lt(0), R.always(''), Math.abs),
    R.ifElse(R.gt(0), R.always(''), Math.abs),
    R.always(''),
  ]),
  R.prop('current_year_earnings'),
);

// getCellWithType :: (String, Object -> Any) -> Object -> ExcelExportDataCell
const getCellWithType = (type, getterFn) =>
  R.compose(R.assoc('cellType', type), R.objOf('value'), getterFn);

// getExcelTableBody :: TrialBalanceReportResult -> [[ExcelExportDataCell]]
const getExcelTableBody = R.compose(
  R.map(
    R.juxt([
      R.compose(R.objOf('value'), R.path(['account', 'code'])),
      R.compose(R.objOf('value'), R.path(['account', 'name'])),
      getCellWithType(
        ExcelExportCellType.defaultCurrency,
        R.prop('forward_balance'),
      ),
      getCellWithType(ExcelExportCellType.defaultCurrency, R.prop('debits')),
      getCellWithType(ExcelExportCellType.defaultCurrency, R.prop('credits')),
      getCellWithType(
        ExcelExportCellType.defaultCurrency,
        R.prop('ending_balance'),
      ),
    ]),
  ),
  R.propOr([], 'entries'),
);

const getExcelExportConfig = (trialBalanceReport, relatedFilters) => ({
  exportFileName: 'trial-balance-report.xlsx',
  columnWidths: [10, 50, 15, 15, 15, 15],
  rows: [
    [
      {
        value: `Trial Balance (${forceTitleCase(
          relatedFilters.accountingMethod,
        )})`,
        cellType: ExcelExportCellType.title,
      },
    ],
    [
      {
        value: formatTrialBalanceDateHeader(relatedFilters.date),
        cellType: ExcelExportCellType.subTitle,
      },
    ],

    [
      'Code',
      'Account',
      'Forward Balance',
      'Debit',
      'Credit',
      'Ending Balance',
    ].map((t) => ({ value: t, cellType: ExcelExportCellType.tableHeader })),

    ...getExcelTableBody(trialBalanceReport),

    [
      { cellType: ExcelExportCellType.tableStringFooter },
      { value: 'Total', cellType: ExcelExportCellType.tableStringFooter },
      { cellType: ExcelExportCellType.tableStringFooter },
      {
        value: trialBalanceReport.debits_total,
        cellType: ExcelExportCellType.tableCurrencyFooter,
      },
      {
        value: trialBalanceReport.credits_total,
        cellType: ExcelExportCellType.tableCurrencyFooter,
      },
      { cellType: ExcelExportCellType.tableStringFooter },
    ],

    getCurrentYearEarningsRowConfigs(trialBalanceReport),
  ],
});

export function TrialBalanceExportXlBtn({ filters, trialBalanceReport }) {
  return (
    <LinkButton
      disabled={!trialBalanceReport}
      onClick={() =>
        performExcelExport(getExcelExportConfig(trialBalanceReport, filters))
      }
    >
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

TrialBalanceExportXlBtn.propTypes = {
  trialBalanceReport: trialBalanceReportPropTypes,
  filters: trialBalanceFiltersPropTypes,
};
