import React from 'react';
import styled from 'styled-components';
import { bool, shape, string } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants/src/misc.js';

import { ConvertPOPopover } from './ConvertPOPopover.js';
import { PurchaseOrderLink } from '../../../sidebars/PurchaseOrderSidebar/PurchaseOrderLink.js';
import { PurchaseOrderBalanceWarning } from '../../PurchaseOrdersPage/PurchaseOrderBalanceWarning.js';
import {
  getAdminPurchaseOrder,
  getClientReferenceNumber,
} from '../../../sidebars/components/ClientReferenceComp.js';

const PONumberRowS = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin: 0 10px;
  }
`;

export function PONumberTableComponent({ isPOEnabled, ...props }) {
  const isChildProject = !!props?.parent?._id;

  const adminPurchaseOrder = getAdminPurchaseOrder(props);

  if (isPOEnabled && adminPurchaseOrder?.poNumber) {
    return <PurchaseOrderLink {...adminPurchaseOrder} />;
  }

  const clientReferenceNumber = getClientReferenceNumber(props);

  const isProjectWithOldPO = isPOEnabled && clientReferenceNumber;

  return (
    <PONumberRowS>
      {clientReferenceNumber || NOTHING_UI_STRING}
      {!isChildProject && isProjectWithOldPO && <ConvertPOPopover {...props} />}
      {isChildProject && isProjectWithOldPO && (
        <PurchaseOrderBalanceWarning
          iconSize="15"
          warning="Current PO number is out of date. If you would like to update it, edit the PO number of the Master project"
        />
      )}
    </PONumberRowS>
  );
}

PONumberTableComponent.propTypes = {
  adminPurchaseOrder: shape({ _id: string.isRequired }),
  isPOEnabled: bool,
  parent: shape({ _id: string }),
  clientReferenceNumber: string,
};
