import React from 'react';
import { string, shape } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { useUpdateQueryParams } from 'poly-client-routing';
import {
  useHasUserAccessWithPermission,
  useOutSidebarContext,
} from 'poly-client-utils';
import { SidebarWrapper } from 'poly-admin-ui';
import { Loader } from 'poly-book-admin';
import { READ_SENSITIVE_FIELD_USER_PERMISSION } from 'poly-security';

import { SidebarIDs } from '../constants.js';
import { ContactSidebarInfo } from './ContactSidebarInfo.js';
import { ContactSidebarHeader } from './ContactSidebarHeader.js';
import { ContactSidebarRemarks } from './ContactSidebarRemarks.js';
import { NotFoundEntity } from '../../components/NotFoundEntity.js';
import { useReactiveSidebarDocumentTitle } from '../useReactiveSidebarDocumentTitle.js';
import { useContactSidebarData } from './useContactSidebarData.js';
import { usePreviousSidebarParams } from '../usePreviousSidebarParams.js';

function ContactSidebarRouterLogic({ contactUser }) {
  useReactiveSidebarDocumentTitle(contactUser.fullName);

  return null;
}

ContactSidebarRouterLogic.propTypes = {
  contactUser: shape({
    fullName: string,
  }),
};

export function ContactSidebar({ contactId }) {
  const { loading, ...sidebarProps } = useContactSidebarData(contactId);
  const { closeOutSidebar } = useOutSidebarContext();
  const updateQueryParams = useUpdateQueryParams();
  const queryParams = usePreviousSidebarParams();

  const hasPermissionToReadPrivateFields = useHasUserAccessWithPermission(
    READ_SENSITIVE_FIELD_USER_PERMISSION,
  );

  const onOutsideClick = () => {
    closeOutSidebar(SidebarIDs.contact);
    updateQueryParams({
      [SidebarIDs.contact]: undefined,
      sidebarTab: queryParams.sidebarTab,
    });
  };

  if (loading) {
    return <Loader />;
  }

  const showPrivateFields =
    !sidebarProps?.contactUser?.isStaff || hasPermissionToReadPrivateFields;

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <SidebarWrapper>
        {!sidebarProps.contactUser ? (
          <NotFoundEntity entityName="contact" />
        ) : (
          <>
            <ContactSidebarHeader
              {...sidebarProps}
              showPrivateFields={showPrivateFields}
            />
            <ContactSidebarInfo {...sidebarProps} />
            {showPrivateFields && <ContactSidebarRemarks {...sidebarProps} />}
            <ContactSidebarRouterLogic {...sidebarProps} />
          </>
        )}
      </SidebarWrapper>
    </OutsideClickHandler>
  );
}

ContactSidebar.displayName = 'ContactSidebar';
ContactSidebar.propTypes = { contactId: string.isRequired };
