import * as R from 'ramda';
import { bool, func, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { getThemeColor, LinkButton, Loader, Text } from 'poly-book-admin';
import { validateEmail } from 'poly-utils';

import { FIND_USER_CONTACT_BY_EMAIL } from '../../../../../hocs/users/queries.js';
import { UPDATE_CONTACT_USER_MUTATION } from '../../../../../hocs/users/mutations.js';
import { useNotificationState } from '../../../../../hooks/useNotificationState.js';
import { useOnSubmitSetStopSubmitting } from '../../../../../hooks/useOnSubmitSetStopSubmitting.js';
import {
  isContactExistsInEntity,
  prepareDataForAddContact,
} from '../peopleFormUtils.js';
import { ContactContext } from '../ContactContext.js';

const DuplicateMsgTextS = styled(Text)`
  color: ${getThemeColor(['accent1Lighter1'])};
  font-size: 12px;
  margin-right: 5px;
  display: inline-block;
`;

const AddBtnWrapperS = styled.div`
  position: relative;
  min-width: 50px;
  display: inline-block;
`;

const LinkButtonS = styled(LinkButton)`
  font-size: 12px;
`;

export function DuplicateContactMsgC({ onSubmit, loading }) {
  return (
    <>
      <DuplicateMsgTextS>
        A contact with this address already exists.
      </DuplicateMsgTextS>
      <AddBtnWrapperS>
        {!loading && (
          <LinkButtonS type="button" onClick={onSubmit}>
            Click here to add them
          </LinkButtonS>
        )}
        {loading && <Loader size={16} />}
      </AddBtnWrapperS>
    </>
  );
}

DuplicateContactMsgC.propTypes = {
  onSubmit: func.isRequired,
  loading: bool,
};

// getContact :: Props -> User
const getContact = R.prop(['findUserContactByEmail']);

const useOnSubmitAddContact = (entity, contact, formId, onCancel) => {
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();

  const [updateContactMutation] = useMutation(UPDATE_CONTACT_USER_MUTATION);

  return async () => {
    await updateContactMutation({
      variables: prepareDataForAddContact(entity, contact),
    })
      .then(() => {
        showSuccessNotification('Contact was added');
        onCancel(formId, true);
      })
      .catch((err) => {
        showErrorNotification(err);
      });
  };
};
export function DuplicateContactMsg({
  formData: { entity, email },
  changeFieldValue,
}) {
  const { formId, onCancel } = useContext(ContactContext);

  const { data, loading } = useQuery(FIND_USER_CONTACT_BY_EMAIL, {
    variables: { email },
    skip: R.compose(R.not, validateEmail)(email),
    fetchPolicy: 'network-only',
  });

  const contact = useMemo(() => getContact(data), [data]);

  useEffect(() => {
    changeFieldValue('contact', contact);
  }, [contact]);

  const onSubmitHandler = useOnSubmitAddContact(
    entity,
    contact,
    formId,
    onCancel,
  );
  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const showDuplicateMsg = contact && !isContactExistsInEntity(contact, entity);

  return showDuplicateMsg ? (
    <DuplicateContactMsgC onSubmit={onSubmit} loading={loading} />
  ) : null;
}
DuplicateContactMsg.displayName = 'DuplicateContactMsg';

DuplicateContactMsg.propTypes = {
  formData: shape({
    email: string,
    entity: shape({
      name: string.isRequired,
      _id: string.isRequired,
    }).isRequired,
  }),
  changeFieldValue: func.isRequired,
};
