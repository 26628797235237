import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { func, string, shape, bool } from 'prop-types';
import {
  NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
  FLIPPED_CONTAINS_AIT_OPERATOR,
  GREATER_OR_EQUAL_AIT_OPERATOR,
  LESS_OR_EQUAL_AIT_OPERATOR,
  GREATER_THAN_AIT_OPERATOR,
  LESS_THAN_AIT_OPERATOR,
  VARIABLE_AIT_OPERATOR,
  EQUALS_AIT_OPERATOR,
  PO_AMOUNT_AIT,
} from 'poly-security';

import { RadioButtons } from '../../../../components/RadioButtons.js';

const RadioButtonsS = styled(RadioButtons)`
  padding-top: 10px;

  > div > div {
    margin-right: 5%;
  }
`;

const mapOperatorToButtonLabel = {
  [EQUALS_AIT_OPERATOR]: 'EQ',
  [LESS_THAN_AIT_OPERATOR]: 'LT',
  [GREATER_THAN_AIT_OPERATOR]: 'GT',
  [LESS_OR_EQUAL_AIT_OPERATOR]: 'LTE',
  [FLIPPED_CONTAINS_AIT_OPERATOR]: 'IN',
  [GREATER_OR_EQUAL_AIT_OPERATOR]: 'GTE',
  [NOT_FLIPPED_CONTAINS_AIT_OPERATOR]: 'NIN',
};

// getRadioOptionsByScope :: Boolean -> String -> [Option]
const getRadioOptionsByScope = (skipVariableOption) =>
  R.compose(
    R.unless(
      R.always(skipVariableOption),
      R.append({
        label: 'Variable',
        value: VARIABLE_AIT_OPERATOR,
      }),
    ),
    R.map(
      R.applySpec({
        value: R.identity,
        label: R.prop(R.__, mapOperatorToButtonLabel),
      }),
    ),
    R.prepend(EQUALS_AIT_OPERATOR),
    R.ifElse(
      R.propEq('scope', PO_AMOUNT_AIT),
      R.always([
        LESS_THAN_AIT_OPERATOR,
        GREATER_THAN_AIT_OPERATOR,
        LESS_OR_EQUAL_AIT_OPERATOR,
        GREATER_OR_EQUAL_AIT_OPERATOR,
      ]),
      R.always([
        FLIPPED_CONTAINS_AIT_OPERATOR,
        NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
      ]),
    ),
  );

// checkShouldUpdateOperator :: { value: String, formLineData: ScopeFormLine } -> Boolean
const checkShouldClearEntities = R.compose(
  R.equals(1),
  R.length,
  R.filter(R.equals(VARIABLE_AIT_OPERATOR)),
  R.juxt([R.prop('value'), R.path(['formLineData', 'operator'])]),
);

export function UserGroupScopeRadioButtons({
  onChange,
  onLineChange,
  formLineData,
  skipVariableOption,
  ...props
}) {
  const handleOnChange = (value) => () => {
    const shouldClear = checkShouldClearEntities({ value, formLineData });

    if (shouldClear) {
      onLineChange({ ...formLineData, operator: value, entities: null });
    } else {
      onChange(value);
    }
  };

  const options = getRadioOptionsByScope(skipVariableOption)(formLineData);

  return (
    <RadioButtonsS {...props} options={options} onChange={handleOnChange} />
  );
}

UserGroupScopeRadioButtons.propTypes = {
  onLineChange: func,
  skipVariableOption: bool,
  onChange: func.isRequired,
  formLineData: shape({ scope: string, operator: string }).isRequired,
};
