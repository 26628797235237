import React from 'react';
import styled from 'styled-components';
import { FlexColumn } from 'poly-admin-ui';
import { useRedirectIfHasNoPermissions } from 'poly-client-utils';
import { READ_FINANCIAL_DIRECTORY_PERMISSION } from 'poly-security';

import { UserDashboard } from '../HomePage/components/UserDashboard.js';

const FinancialDirectoryContainer = styled(FlexColumn)`
  padding: 30px 0 30px 245px;
`;

export function FinancialDirectoryTempPage() {
  useRedirectIfHasNoPermissions([READ_FINANCIAL_DIRECTORY_PERMISSION]);

  return (
    <FinancialDirectoryContainer>
      <UserDashboard />
    </FinancialDirectoryContainer>
  );
}
