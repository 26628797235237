import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { useNotificationState, CREATE_PROPERTY_MUTATION } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';
import { isNilOrEmpty } from 'poly-utils';

import { addPropertyFormId } from '../../constants.js';
import { propertyFormObjectToMutation } from '../edit/formDataToMutation.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';

export const updatePropertyHierarchyPathMutation = gql`
  mutation updatePropertyHierarchyPathMutation(
    $id: ID!
    $nodeId: ID
    $propertyId: ID!
  ) {
    updatePropertyHierarchyPath(
      id: $id
      nodeId: $nodeId
      propertyId: $propertyId
    ) {
      _id
    }
  }
`;

const prepareHierarchiesToMutation = R.reject(isNilOrEmpty);

export const useOnSubmitPropertyFrom = ({
  saveContacts,
  client,
  parentProperty,
  isMaster,
}) => {
  const { showSuccessNotification } = useNotificationState();
  const { openPropertySidebar } = useSidebarLogicContext();
  const { closeOutSidebar } = useOutSidebarContext();

  const [updatePropertyHierarchyPath] = useMutation(
    updatePropertyHierarchyPathMutation,
  );

  const propertyCustomFields = R.pathOr(
    {},
    ['configs', 'propertyFields'],
    client,
  );

  const [createPropertyMutation] = useMutation(CREATE_PROPERTY_MUTATION);

  return async (formData) => {
    // first save contact (branchManager)
    const [branchManagerResult] = await saveContacts(formData, '', client._id, [
      'branchManager',
    ]);
    const branchManagerId = branchManagerResult.contactId;

    let toMutationObject = {
      ...propertyFormObjectToMutation(formData),
      branchManagerId,
    };

    if (propertyCustomFields.districtFacilityManager) {
      const [districtFacilityManagerResult] = await saveContacts(
        formData,
        '',
        client._id,
        ['districtFacilityManager'],
      );

      toMutationObject = {
        ...toMutationObject,
        districtFacilityManagerId: districtFacilityManagerResult.contactId,
      };
    }

    // then save property
    const response = await createPropertyMutation({
      variables: {
        input: {
          ...toMutationObject,
          isMaster,
          clientId: client._id,
          ...(parentProperty?._id
            ? { masterPropertyId: parentProperty?._id }
            : {}),
        },
      },
    });

    const propertyId = R.path(
      ['data', 'createProperty', 'property', '_id'],
      response,
    );
    if (propertyId) {
      const propertyBranchManager = {
        branchManager: {
          ...formData.branchManager,
          contact: {
            isCreateMode: false,
            isAddContactToProperty: true,
          },
          _id: branchManagerId,
        },
      };

      // then add contact (branchManager) to this property
      await saveContacts(propertyBranchManager, propertyId, client._id, [
        'branchManager',
      ]);

      if (propertyCustomFields.districtFacilityManager) {
        await saveContacts(
          {
            districtFacilityManager: {
              ...formData.branchManager,
              contact: {
                isCreateMode: false,
                isAddContactToProperty: true,
              },
              _id: toMutationObject.districtFacilityManagerId,
            },
          },
          propertyId,
          client._id,
          ['districtFacilityManager'],
        );
      }

      // then save hierarchy
      if (formData.hierarchies) {
        const hierarchiesToMutation = prepareHierarchiesToMutation(
          formData.hierarchies,
        );

        await hierarchiesToMutation.map((hierarchyLine) =>
          updatePropertyHierarchyPath({
            variables: {
              id: hierarchyLine.hierarchy,
              nodeId: hierarchyLine.hierarchyNode,
              propertyId,
            },
          }),
        );
      }

      // after success action
      showSuccessNotification('Property was successfully created');
      closeOutSidebar(addPropertyFormId);
      openPropertySidebar(propertyId);
    }
  };
};
