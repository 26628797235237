import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonsContainer } from 'poly-admin-ui/src/components/Button/Button.js';
import { useProcessState } from 'poly-admin-ui/src/hooks/useProcessState.js';
import { formatBackLink, useLocation } from 'poly-client-routing';
import { Checkbox } from 'poly-book-admin/src/Checkbox/index.js';
import { getThemeColor } from 'poly-book-admin/src/utils.js';
import { Button } from 'poly-book-admin/src/Button/index.js';
import {
  PageHeaderContainer,
  MainHeader,
} from 'poly-book-admin/src/Header/index.js';

import { routesNames } from '../../routes/constants.js';
import { createJournalEntryFormId } from './constants.js';
import { CancelFormBtn } from '../../components/Buttons.js';
import { setJournalEntry } from '../../redux/journalEntryReducer.js';

const ButtonsContainerS = styled(ButtonsContainer)`
  > div:first-child {
    margin-right: 20px;
    color: ${getThemeColor(['primary'])};
  }
`;

export function CreateJournalEntryPageHeader() {
  const journalEntry = useSelector((store) => store.journalEntry);
  const { process } = useProcessState(createJournalEntryFormId);
  const location = useLocation();
  const dispatch = useDispatch();

  const printJournalEntry = !!journalEntry && !!journalEntry.printJournalEntry;
  const showPrint = !!journalEntry && !!journalEntry.showPrint;

  const cancelRedirectUrl = formatBackLink({
    fallbackLink: routesNames.FINANCIAL_DIRECTORY,
    router: location.state,
    withQuery: true,
  });

  const onSaveAndCopyClick = () => {
    const form = document.getElementById(createJournalEntryFormId);
    form.setAttribute('dirty', 'true');
  };

  const onPrintChange = (value) => {
    dispatch(setJournalEntry({ printJournalEntry: value }));
  };

  return (
    <PageHeaderContainer>
      <MainHeader>Create Journal Entry</MainHeader>
      <ButtonsContainerS>
        {showPrint && (
          <Checkbox
            name="print"
            onChange={onPrintChange}
            value={printJournalEntry}
            label="Print Journal Entry"
          />
        )}
        <Button
          size="small"
          type="submit"
          loader={process}
          disabled={process}
          form={createJournalEntryFormId}
        >
          Save
        </Button>
        <Button
          size="small"
          type="submit"
          loader={process}
          disabled={process}
          styleType="basicSecondary"
          onClick={onSaveAndCopyClick}
          form={createJournalEntryFormId}
        >
          Save & Copy
        </Button>
        <CancelFormBtn disabled={process} redirectUrl={cancelRedirectUrl} />
      </ButtonsContainerS>
    </PageHeaderContainer>
  );
}
