import {
  SITE_APP_NAME,
  ADMIN_APP_NAME,
  MOBILE_APP_NAME,
  REQUESTS_APP_NAME,
  TIAA_PORTAL_APP_NAME,
  ASSET_SCANNER_APP_NAME,
  CLIENT_PORTAL_APP_NAME,
} from 'poly-security';

export const defaultLoginAppOptions = [
  { value: ADMIN_APP_NAME, label: 'Admin' },
  { value: SITE_APP_NAME, label: 'Site' },
  { value: MOBILE_APP_NAME, label: 'Mobile' },
  { value: TIAA_PORTAL_APP_NAME, label: 'TIAA Portal' },
  { value: CLIENT_PORTAL_APP_NAME, label: 'Client Portal' },
  { value: ASSET_SCANNER_APP_NAME, label: 'Asset Scanner' },
  { value: REQUESTS_APP_NAME, label: 'Requests' },
];
