import React from 'react';
import * as R from 'ramda';
import {
  string,
  number,
  shape,
  arrayOf,
  instanceOf,
  oneOfType,
  bool,
} from 'prop-types';
import { Rating } from 'poly-book-admin';
import {
  SupplierDocumentsWarning,
  supplierStatusesColors,
  isDisplaySupplierDocumentWarning,
} from 'poly-admin-ui';
import { supplierRatingShape } from 'poly-client-utils';
import styled from 'styled-components';
import { Link } from 'poly-client-routing';
import { insertParamsIntoURL } from 'poly-utils';

import { SupplierItemCheckBox } from './SupplierItemCheckBox.js';
import {
  StatusS,
  DarkText,
  LightText,
  FlexSpaceBetween,
  SupplierItemWrapper,
  SupplierItemGridRow,
} from '../../../../../styles.js';
import {
  distanceBetweenLocations,
  convertToCoordinates,
} from '../../../../../utils.js';
import { routesNames } from '../../../../../../../../routes/index.js';

const SupplierNameWrapperS = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LinkS = styled(Link)`
  line-height: 18px;
  font-size: 11px;
`;

const SupplierDocumentsWarningS = styled(SupplierDocumentsWarning)`
  display: inline-flex;
`;

export function SuppliersListItemComp({
  _id,
  name,
  status,
  rating,
  type,
  hourly,
  overTime,
  trip,
  emergency,
  coordinates,
  propertyCoordinates,
  tax,
  documents,
  mwbe,
  isVeteran,
}) {
  const distance = distanceBetweenLocations(
    propertyCoordinates,
    convertToCoordinates(coordinates),
  );

  const supplierUrl = insertParamsIntoURL(
    { supplierId: _id },
    routesNames.SUPPLIER,
  );

  return (
    <SupplierItemWrapper>
      <SupplierItemGridRow>
        <SupplierNameWrapperS>
          <SupplierItemCheckBox supplierId={_id} type={type} />
          <LinkS href={supplierUrl} target="_blank">
            {name}
            {isDisplaySupplierDocumentWarning({
              tax,
              documents,
              type,
              mwbe,
              isVeteran,
            }) ? (
              <SupplierDocumentsWarningS
                supplier={{ tax, documents, type, status, mwbe, isVeteran }}
              />
            ) : (
              <StatusS color={supplierStatusesColors[R.toLower(status)]} />
            )}
          </LinkS>
        </SupplierNameWrapperS>
        <DarkText>{hourly}</DarkText>
        <DarkText>{overTime}</DarkText>
        <DarkText>{emergency}</DarkText>
        <DarkText>{trip}</DarkText>
      </SupplierItemGridRow>
      <FlexSpaceBetween>
        <LightText lineHeight="18px" padding="0 0 0 24px">
          {distance ? `${distance} mi` : '–'}
        </LightText>
        <Rating value={rating} readOnly popUp={false} starSize={10} />
      </FlexSpaceBetween>
    </SupplierItemWrapper>
  );
}
SuppliersListItemComp.propTypes = {
  _id: string.isRequired,
  name: string.isRequired,
  status: string.isRequired,
  rating: supplierRatingShape,
  hourly: string,
  overTime: string,
  trip: string,
  emergency: string,
  type: string.isRequired,
  propertyCoordinates: shape({
    lat: number.isRequired,
    lon: number.isRequired,
  }),
  coordinates: arrayOf(number),
  tax: shape({ w9File: shape({ url: string }) }),
  documents: shape({
    wcFile: shape({ url: string }),
    wcExp: oneOfType([instanceOf(Date), string]),
    glFile: shape({ url: string }),
    glExp: oneOfType([instanceOf(Date), string]),
    scaFile: shape({ url: string }),
    sqqFile: shape({ url: string }),
  }),
  mwbe: shape({ isMBE: bool }),
  isVeteran: bool,
};
