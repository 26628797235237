import * as R from 'ramda';
import React, { useState } from 'react';
import { showBase64PDF } from 'poly-client-utils';
import { gql, useApolloClient } from '@apollo/client';
import { LinkButtonWithLoader } from 'poly-book-admin';
import { InvoicePDFLink } from 'poly-admin-ui';

const getPdfBase64StrByResponse = R.path([
  'data',
  'getClientInvoicePDF',
  'pdfBase64',
]);

// getPdfBase64StrByResponse :: ApolloResponse -> String
const GetClientInvoicePDFQuery = gql`
  query GET_CLIENT_INVOICE_PDF($clientInvoiceId: ID!) {
    getClientInvoicePDF(clientInvoiceId: $clientInvoiceId) {
      pdfBase64
    }
  }
`;

// isClientInvoiceWithFileUrl :: ReportOnlyClientInvoice -> Boolean
const isClientInvoiceWithFileUrl = R.pathSatisfies(R.is(String), [
  'invoiceFile',
  'url',
]);

// isReportOnlyClientInvoiceWithoutFile :: Input -> Boolean
// Input = {
//    isReportOnlyClientInvoice: Boolean
//    invoiceFile: Attachment
// }
const isReportOnlyClientInvoiceWithoutFile = R.both(
  R.propEq('isReportOnlyClientInvoice', true),
  R.pathSatisfies(R.isNil, ['invoiceFile', 'url']),
);

export function ClientInvoicePDFLink(clientInvoice) {
  const apolloClient = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  if (isReportOnlyClientInvoiceWithoutFile(clientInvoice)) {
    return clientInvoice.number;
  }

  const onInvoiceNumberClick = async () => {
    setIsLoading(true);
    try {
      const queryResp = await apolloClient.query({
        query: GetClientInvoicePDFQuery,
        variables: { clientInvoiceId: clientInvoice._id },
      });

      const base64PdfStr = getPdfBase64StrByResponse(queryResp);
      showBase64PDF(base64PdfStr);
    } finally {
      setIsLoading(false);
    }
  };

  return isClientInvoiceWithFileUrl(clientInvoice) ? (
    <InvoicePDFLink
      invoiceFileLink={clientInvoice.invoiceFile.url}
      invoiceNumber={clientInvoice.number}
    />
  ) : (
    <LinkButtonWithLoader loader={isLoading} onClick={onInvoiceNumberClick}>
      {clientInvoice.number}
    </LinkButtonWithLoader>
  );
}
