import { useMutation, gql } from '@apollo/client';
import { useModalContext, useNotificationState } from 'poly-admin-ui';

const confirmModalProps = {
  cancelBtnCaption: 'No',
  btnCaption: 'Yes, Delete',
  id: 'deleteAssetManufacturerModel',
  content: 'Are you sure you want to remove this model?',
};

const deleteAssetManufacturerModelMutation = gql`
  mutation deleteAssetManufacturerModel($id: ID!) {
    deleteAssetManufacturerModel(id: $id)
  }
`;

export const useDeleteAssetManufactureModel = (id) => {
  const { openConfirmModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();

  const [deleteAssetManufactureModel] = useMutation(
    deleteAssetManufacturerModelMutation,
    {
      variables: {
        id,
      },
    },
  );

  return () =>
    openConfirmModal({
      ...confirmModalProps,
      onConfirm: (closeConfirmModal) => async () => {
        await deleteAssetManufactureModel();
        showSuccessNotification('Model was successfully removed');
        closeConfirmModal();
      },
    });
};
