import React, { useEffect } from 'react';
import { MoneyInputAsCents } from 'poly-admin-ui';
import { costPropTypes, placeholder } from './common.js';

export function MaterialCostInput({ formData, onChange, value, ...props }) {
  useEffect(() => {
    if (!value && formData.labourCost > 0) {
      onChange(0);
    }
  }, [formData.labourCost]);

  const onFocus = (e) => {
    if (e.target.value === '$0.00') {
      onChange('');
    }
  };
  return (
    <MoneyInputAsCents
      {...{ placeholder, onChange, value, ...props, onFocus }}
    />
  );
}

MaterialCostInput.propTypes = costPropTypes;
