import React from 'react';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import { GalleryModalComp, SidebarRow, useModalContext } from 'poly-admin-ui';
import { NOTHING_UI_STRING } from 'poly-constants';
import { isNilOrEmpty, removeFileExtension } from 'poly-utils';
import { Text } from 'poly-book-admin';
import * as R from 'ramda';

import {
  SectionLabel,
  SectionWrapperWithoutBorder,
} from '../components/commonSidebarComponents.js';

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 10px;

  :hover {
    opacity: 0.7;
  }

  > img {
    height: 100px;
    width: 100px;
  }
`;

const GalleryWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);

  > div:last-child {
    width: calc(100% - 100px);
    background-color: #777777;
    border-radius: 5px;
  }

  .slick-slider {
    max-height: inherit;
    overflow: auto;
  }
`;

function AssetPhotoSection({ photo }) {
  const { openModal, closeModal } = useModalContext();

  const assetPhotoModalId = 'asset_photo_modal_id';

  const galleryProps = {
    settings: {
      speed: 500,
      initialSlide: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    },
    images: [photo],
  };

  const onPhotoClick = () =>
    openModal({
      simple: true,
      id: assetPhotoModalId,
      Layout: GalleryWrapper,
      content: (
        <GalleryModalComp
          {...galleryProps}
          closeGallery={() => closeModal(assetPhotoModalId)}
        />
      ),
    });

  if (!photo) return <Text size="12px">{NOTHING_UI_STRING}</Text>;

  return (
    <ImageWrapper onClick={onPhotoClick}>
      <img src={photo.url} alt={removeFileExtension(photo.fileName)} />
    </ImageWrapper>
  );
}

AssetPhotoSection.propTypes = {
  photo: shape({
    url: string.isRequired,
    fileName: string.isRequired,
  }),
};

// isAssetPhotoUploadingFromGoogleDrive :: Asset -> Boolean
const isAssetPhotoUploadingFromGoogleDrive = R.both(
  R.compose(isNilOrEmpty, R.path(['photo', 'url'])),
  R.prop('uploadPhotoUrl'),
);

export function AssetSidebarPhoto({ asset }) {
  const isUploading = isAssetPhotoUploadingFromGoogleDrive(asset);

  return (
    <SectionWrapperWithoutBorder>
      <SidebarRow>
        <SectionLabel>Asset Photo</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        {isUploading ? (
          <Text size="12px">Photo in queue for upload</Text>
        ) : (
          <AssetPhotoSection {...asset} />
        )}
      </SidebarRow>
    </SectionWrapperWithoutBorder>
  );
}

AssetSidebarPhoto.propTypes = {
  asset: shape({
    photo: shape({
      url: string.isRequired,
      fileName: string.isRequired,
    }),
  }),
};
