import * as R from 'ramda';
import { supplierEmailsTypes } from 'poly-constants';
import { searchAccessItemsByClientAppAndFullPermission } from 'poly-security';

/**
 * findSupplierServiceEmail :: [Suppliers] -> [Emails]
 */
export const findSupplierServiceEmail = R.pipe(
  R.pathOr([], ['company', 'emails']),
  R.find(
    R.anyPass([
      R.propEq('type', supplierEmailsTypes.SERVICE),
      R.propEq('type', supplierEmailsTypes.GENERAL),
    ]),
  ),
  R.prop('email'),
);

export const generateMailToQuery = (clientId) => ({
  bool: {
    should: [
      searchAccessItemsByClientAppAndFullPermission,
      ...(clientId
        ? [
            {
              nested: {
                path: 'links',
                query: { term: { 'links.clientId': clientId } },
              },
            },
          ]
        : []),
    ],
  },
});

/**
 * getEmailsToSendTo :: Object -> [String]
 */
export const getEmailsToSendTo = R.pipe(
  R.values,
  R.filter(R.prop('shouldSend')),
  R.map(R.prop('emails')),
  R.flatten,
  R.uniq,
  R.reject(R.isNil),
);

/**
 * getEntityValidators :: (String, [Validator]) -> Array
 *
 */
export const getEntityValidator = (entity, validators) => [
  entity,
  [
    ...R.map(
      ([validate, msg]) => [R.when(R.path(['shouldSend']), validate), msg],
      validators,
    ),
  ],
];

/**
 * validateMailToField :: ([Array], Object) -> Object
 */
export const validateMailToField = R.curry((validators, value) =>
  R.compose(
    R.when(R.isEmpty, R.always(undefined)),
    R.filter(R.identity),
    R.reduce(
      (acc, [subFieldName, subFieldValidators]) => ({
        ...acc,
        ...(subFieldName
          ? {
              [subFieldName]: R.compose(
                R.prop(1),
                R.find(([validator]) => !validator(value[subFieldName])),
              )(subFieldValidators),
            }
          : {
              mailTo: R.compose(
                R.prop(1),
                R.find(([validator]) => !validator(value)),
              )(subFieldValidators),
            }),
      }),
      {},
    ),
  )(validators),
);

/**
 * shouldSend :: (String, Object) -> Boolean
 */
export const shouldSend = (entity, value) =>
  R.path([entity, 'shouldSend'], value);

/**
 * getEmailByPath :: String -> Object -> [String]
 */
export const getEmailByPath = (path) =>
  R.pipe(
    R.pathOr(null, [...path, 'email']),
    R.ifElse(R.isNil, R.always([]), R.of),
  );

/**
 * getNamesByPath :: Project -> String
 */
export const getNamesByPath = (path, getName) =>
  R.pipe(
    R.pathOr([], path),
    R.pipe(R.map(getName), R.reject(R.isNil), R.join(' / ')),
  );

/**
 * getEmailsByPath :: Project -> String
 */
export const getEmailsByPath = (path, getEmail) =>
  R.pipe(R.pathOr([], path), R.map(getEmail));

export const projectMailToConfig = [
  [
    'Requestor',
    'requestor',
    getEmailByPath(['project', 'contact']),
    R.path(['project', 'contact', 'fullName']),
  ],
  [
    'Site Contact',
    'siteContact',
    getEmailByPath(['project', 'siteContact']),
    R.path(['project', 'siteContact', 'fullName']),
  ],
  [
    'Client Manager',
    'clientManager',
    getEmailsByPath(['project', 'client', 'clientManagers'], R.prop('email')),
    getNamesByPath(['project', 'client', 'clientManagers'], R.prop('fullName')),
  ],
  [
    'Supplier',
    'supplier',
    getEmailsByPath(['project', 'suppliers'], findSupplierServiceEmail),
    getNamesByPath(['project', 'suppliers'], R.path(['company', 'name'])),
  ],
];
