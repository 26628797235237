import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import { moneyColumnStyles, Table } from 'poly-book-admin';
import { formatDateOrNothingUI } from 'poly-client-utils';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { applyProp, centsToDollarsWithFormat } from 'poly-utils';
import { NOTHING_UI_STRING } from 'poly-constants/src/misc.js';

import {
  AgingReportTableLabelRow,
  AgingReportFooterContainer,
  AgingReportInvoicesTableWrapper,
  commonAgingReportInvoicesTableStyles,
} from '../APAgingReport/styledComponents.js';
import {
  getDaysFromNowByProp,
  getTotalByDaysPart,
  sumAndFormatTotal,
} from '../APAgingReport/APAgingReportTableComp.js';
import { invoicesPropTypes } from './prop-types.js';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';

// getMoneyColumnByNth :: Number -> String
const getMoneyColumnByNth = (col) => moneyColumnStyles(col, 120);

const ARAgingReportInvoicesTable = styled(Table)`
  ${commonAgingReportInvoicesTableStyles};

  th:nth-child(5),
  td:nth-child(5) {
    width: 40px;
  }

  ${getMoneyColumnByNth(6)};
  ${getMoneyColumnByNth(7)};
  ${getMoneyColumnByNth(8)};
  ${getMoneyColumnByNth(9)};
  ${getMoneyColumnByNth(10)};
  ${getMoneyColumnByNth(11)};
`;

export const ARAgingReportFooter = styled(AgingReportFooterContainer)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 380px;
  }
  ${getMoneyColumnByNth(2)};
  ${getMoneyColumnByNth(3)};
  ${getMoneyColumnByNth(4)};
  ${getMoneyColumnByNth(5)};
  ${getMoneyColumnByNth(6)};
  ${getMoneyColumnByNth(7)};
`;

// getClientInvoiceTotal :: (Number, Number) -> ClientInvoice -> Number
export const getClientInvoiceTotal = (from, to) =>
  getTotalByDaysPart(from, to, 'dueDate', 'amount');

// getClientInvoiceAmountFormatted :: (Number, Number) -> ClientInvoice -> String
const getClientInvoiceAmountFormatted = (from, to) =>
  R.compose(centsToDollarsWithFormat, getClientInvoiceTotal(from, to));

// getTotalClientInvoicesFormatted :: (Number, Number) -> [ClientInvoice] -> String
const getTotalClientInvoicesFormatted = (from, to) =>
  R.compose(sumAndFormatTotal, R.map(getClientInvoiceTotal(from, to)));

// getTotalInvoicesFormatted :: [ClientInvoice] -> String
const getTotalInvoicesFormatted = R.compose(
  sumAndFormatTotal,
  R.map(R.prop('amount')),
);

const getArAgingReportInvoicesTableConfig = (isPrintPDF) => [
  ['Invoice #', R.prop('number')],
  [
    'Project #',
    R.compose(
      R.ifElse(
        R.isNil,
        R.always(NOTHING_UI_STRING),
        R.compose(ProjectLink, R.mergeRight({ isPrintPDF })),
      ),
      R.prop('project'),
    ),
  ],
  ['Invoice Date', applyProp(formatDateOrNothingUI)('createdAt')],
  ['Due Date', applyProp(formatDateOrNothingUI)('dueDate')],
  ['Age', getDaysFromNowByProp('createdAt')],
  ['Current', getClientInvoiceAmountFormatted(-Infinity, 0)],
  ['1-30 days', getClientInvoiceAmountFormatted(0, 30)],
  ['31-60 days', getClientInvoiceAmountFormatted(30, 60)],
  ['61-90 days', getClientInvoiceAmountFormatted(60, 90)],
  ['more 91 days', getClientInvoiceAmountFormatted(90, Infinity)],
  ['Total', applyProp(centsToDollarsWithFormat)('amount')],
];

function TotalFooter({ rows, name }) {
  const label = `Total ${name}`;
  return (
    <ARAgingReportFooter>
      <td>{label}</td>
      <td>{getTotalClientInvoicesFormatted(-Infinity, 0)(rows)}</td>
      <td>{getTotalClientInvoicesFormatted(0, 30)(rows)}</td>
      <td>{getTotalClientInvoicesFormatted(30, 60)(rows)}</td>
      <td>{getTotalClientInvoicesFormatted(60, 90)(rows)}</td>
      <td>{getTotalClientInvoicesFormatted(90, Infinity)(rows)}</td>
      <td>{getTotalInvoicesFormatted(rows)}</td>
    </ARAgingReportFooter>
  );
}

TotalFooter.propTypes = {
  name: string,
  rows: invoicesPropTypes.isRequired,
};

export function ARAgingReportTableComp({ name, invoices, isPrintPDF }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    getArAgingReportInvoicesTableConfig(isPrintPDF),
    invoices,
  );

  return (
    <AgingReportInvoicesTableWrapper>
      <AgingReportTableLabelRow>{name}</AgingReportTableLabelRow>
      <ARAgingReportInvoicesTable
        {...tableProps}
        minHeightLess
        TableFooter={TotalFooter}
        footerProps={{ name }}
      />
    </AgingReportInvoicesTableWrapper>
  );
}

ARAgingReportTableComp.propTypes = {
  name: string,
  invoices: invoicesPropTypes.isRequired,
  isPrintPDF: bool,
};
