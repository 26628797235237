import React from 'react';
import * as R from 'ramda';
import StarRatings from 'react-star-ratings';
import { bool, func, number, string } from 'prop-types';
import { supplierRatingShape } from 'poly-client-utils';

import { Wrapper, LightStarsNumber } from './styles.js';
import { InputErrorMsg } from '../Input/index.js';
import { ColumnDirection } from '../lib/index.js';
import { PopUpRating } from './PopUpRating.js';
import { defaultTheme } from '../ThemeProvider/index.js';

const {
  colors: { gold, secondary },
} = defaultTheme;

const sumStars = R.compose(R.sum, R.propOr([], 'stars'));

export function Rating({
  name,
  value,
  error,
  readOnly,
  onChange,
  starSize,
  className,
  starSpacing,
  numberOfStars,
  onBlur,
  popUp,
}) {
  return (
    <ColumnDirection>
      <Wrapper {...{ className }} tabIndex={0}>
        <StarRatings
          name={name}
          starRatedColor={gold}
          starHoverColor={gold}
          starEmptyColor={secondary}
          numberOfStars={numberOfStars}
          starDimension={`${starSize}px`}
          starSpacing={`${starSpacing}px`}
          rating={R.prop('average', value)}
          {...(!readOnly && {
            changeRating: (average) => {
              onChange({ average });
              if (onBlur) {
                // fixes validation bug when validateOnBlur enabled
                onBlur({});
              }
            },
          })}
        />
        {readOnly && popUp && <PopUpRating {...value} />}
        {readOnly && !popUp && (
          <LightStarsNumber>({sumStars(value)})</LightStarsNumber>
        )}
      </Wrapper>
      {!!error && <InputErrorMsg>{error}</InputErrorMsg>}
    </ColumnDirection>
  );
}

Rating.propTypes = {
  name: string,
  error: string,
  readOnly: bool,
  onChange: func,
  starSize: number,
  className: string,
  starSpacing: number,
  numberOfStars: number,
  value: supplierRatingShape,
  onBlur: func,
  popUp: bool,
};

Rating.defaultProps = {
  value: { average: 0, stars: [0, 0, 0, 0, 0] },
  starSize: 12,
  starSpacing: 2,
  name: 'rating',
  onChange: null,
  numberOfStars: 5,
  readOnly: false,
  className: '',
  error: null,
  popUp: true,
};
