import React from 'react';
import { defaultTheme, Status, Loader } from 'poly-book-admin';
import * as R from 'ramda';
import { commonSortQuery } from 'poly-client-utils';
import {
  useSetInitialPaginationOnMount,
  useTableSorting,
  useSetItemsCount,
  useMapConfigToTableProps,
} from 'poly-admin-ui';
import { NOTHING_UI_STRING } from 'poly-constants';
import styled from 'styled-components';
import { bool, shape, string } from 'prop-types';
import { EditMentionGroupButton } from './EditMentionGroupButton.js';
import { TableS } from './styles.js';
import { useMentionGroupsBySearchTable } from '../../core/hooks/mentionGroups/index.js';
import { NoRecordsHeader } from '../../core/hocs/general.js';

// getUsersByMentionGroup :: MentionGroup -> String
const getUsersByMentionGroup = R.compose(
  R.join(', '),
  R.map(R.prop('fullName')),
  R.prop('users'),
);

const {
  colors: {
    statuses: { error, active },
  },
} = defaultTheme;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function NotificationsStatus({
  client,
  notifyOnExternalRequest,
  notifyOnUnassignedProject,
  notifyOnClientCommentAdded,
  notifyOnInternalProjectUpdateAdded,
}) {
  if (!client) return null;

  const isNotify =
    notifyOnExternalRequest ||
    notifyOnUnassignedProject ||
    notifyOnClientCommentAdded ||
    notifyOnInternalProjectUpdateAdded;

  const color = isNotify ? active : error;
  const text = isNotify ? 'Yes' : 'No';

  return (
    <StatusWrapper>
      <Status text={text} color={color} />
    </StatusWrapper>
  );
}

NotificationsStatus.propTypes = {
  notifyOnExternalRequest: bool,
  client: shape({ _id: string }),
  notifyOnUnassignedProject: bool,
  notifyOnClientCommentAdded: bool,
  notifyOnInternalProjectUpdateAdded: bool,
};

// getMentionGroupsTotal :: SearchMentionGroupsQueryResult -> Number
const getMentionGroupsTotal = R.pathOr(0, ['searchMentionGroups', 'total']);

// getMentionGroups :: SearchMentionGroupsQueryResult -> [MentionGroup]
const getMentionGroups = R.pathOr([], ['searchMentionGroups', 'hits']);

const tableConfig = [
  ['Name', R.prop('name'), commonSortQuery(['name'])],
  ['Description', R.prop('description')],
  ['Client', R.pathOr(NOTHING_UI_STRING, ['client', 'name'])],
  ['Notify', NotificationsStatus],
  ['Users', getUsersByMentionGroup],
  ['Actions', EditMentionGroupButton],
];

export function MentionGroupsTable({ query }) {
  useSetInitialPaginationOnMount();

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    column: 1,
    isSortable: false,
  });

  const { loading, result } = useMentionGroupsBySearchTable({
    sort,
    query,
  });

  useSetItemsCount(getMentionGroupsTotal, result);

  const tableProps = useMapConfigToTableProps(
    getMentionGroups,
    tableConfig,
    result,
  );

  if (loading) return <Loader />;

  if (getMentionGroupsTotal(result) === 0)
    return <NoRecordsHeader>No mention groups</NoRecordsHeader>;

  return <TableS {...tableSortingProps} {...tableProps} />;
}

MentionGroupsTable.displayName = 'MentionGroupsTable';

MentionGroupsTable.propTypes = {
  query: shape({
    term: shape({
      status: string,
    }),
  }),
};
