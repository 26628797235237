import * as R from 'ramda';
import { defaultTheme, IconButton } from 'poly-book-admin';
import { nullToUndefinedDeep, removePropDeep } from 'poly-utils';
import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { PeopleTableContext } from './hocs.js';
import { useModalContext } from '../../../components/Modal/ModalProvider.js';
import { isLikedToProperties, unlinkContact } from './utils.js';
import { entities } from '../../../constants/index.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';
import { UPDATE_CONTACT_USER_MUTATION } from '../../../hocs/users/mutations.js';

const successUnlinkContactMsg = 'Contact was removed from this card';
const confirmRemoveBtnCaption = 'Yes, Remove';
const unlinkClientPropertiesConfirmMsg =
  'You are about to delete a Client contact that is linked to Properties. This will delete them in all locations. OK to proceed?';
const unlinkContactConfirmMsg =
  'Are you sure you want to remove contact from this card?';

// isClientEntity :: Entity -> Boolean
// Entity = {
//  name: String
//  id: String
// }
const isClientEntity = R.propEq('name', entities.CLIENT);

const unlinkContactProcessId = 'unlinkContactProcessId';

// removeSystemProps :: Object -> Object
const removeSystemProps = removePropDeep('__typename');

// removeEntityLink :: (Entity, [Link]) -> [Link]
// Link = {
//  clientId: String
//  propertyId: String
//  supplierId: String
// }
const removeEntityLink = (entity, links) =>
  R.compose(
    nullToUndefinedDeep,
    removeSystemProps,
    unlinkContact(R.__, entity),
  )(links);

export function UnlinkContactBtn(contact) {
  const { openConfirmSubmitFormModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const { entity: contextEntity } = useContext(PeopleTableContext);
  const [updateContact] = useMutation(UPDATE_CONTACT_USER_MUTATION);

  const entity = contextEntity || contact.entity;

  const onUnlinkContact = () =>
    openConfirmSubmitFormModal({
      content:
        isClientEntity(entity) && isLikedToProperties(contact.links, entity._id)
          ? unlinkClientPropertiesConfirmMsg
          : unlinkContactConfirmMsg,
      btnCaption: confirmRemoveBtnCaption,
      processId: unlinkContactProcessId,
      onConfirm: (closeConfirmModal) => async () => {
        await updateContact({
          variables: {
            update: {
              links: removeEntityLink(entity, contact.links),
              userId: contact._id,
            },
          },
        });
        showSuccessNotification(successUnlinkContactMsg);
        closeConfirmModal();
      },
    });

  return (
    <IconButton
      onClick={onUnlinkContact}
      initialColor={R.path(['colors', 'primaryLight'], defaultTheme)}
      name="delete"
      size={11}
    />
  );
}
