import * as R from 'ramda';
import { pathOrNothingUI } from 'poly-client-utils';
import { getPhoneByType as getPhoneByTypeImp } from 'poly-admin-ui';

import { propOrNothingUI } from './general.js';

export const supplierName = pathOrNothingUI(['company', 'name']);
export const supplierCardNumber = pathOrNothingUI(['cardNumber']);

/**
 * getPhoneByType :: String -> [Object] -> String
 */
export const getPhoneByType = getPhoneByTypeImp;

export const getEmailByType = (emails, type) =>
  R.pipe(R.find(R.propEq('type', type)), propOrNothingUI('email'))(emails);
