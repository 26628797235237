import React from 'react';
import { LinkButton } from 'poly-book-admin';

import { addProcedureFormId } from './constants.js';
import { useOpenAddEditProcedureSidebar } from './hooks/useOpenAddEditProcedureSidebar.js';

export function AddNewProcedureButton() {
  const onAddNewProcedure = useOpenAddEditProcedureSidebar(addProcedureFormId);

  const onClick = () => onAddNewProcedure();

  return <LinkButton onClick={onClick}>Add New Procedure</LinkButton>;
}
