import React, { useState } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { LinkButton, SectionWithCard } from 'poly-book-admin';

const AssetScannerAccessPinWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const AssetScannerAccessPinCode = styled.span`
  letter-spacing: 1px;
  font-size: ${({ isViewing }) => (isViewing ? '12px' : '17px')};
`;

function AssetScannerAccessPin({ assetScannerPinAccessCode = '' }) {
  const [isViewing, setIsViewing] = useState(false);

  const hideCode = (code) =>
    code
      .split('')
      .map(() => '*')
      .join('');

  const onView = () => setIsViewing(!isViewing);

  return (
    <AssetScannerAccessPinWrapper>
      <AssetScannerAccessPinCode isViewing={isViewing}>
        {isViewing
          ? assetScannerPinAccessCode
          : hideCode(assetScannerPinAccessCode)}
      </AssetScannerAccessPinCode>
      <LinkButton onClick={onView}>
        {isViewing ? 'Hide Pin' : 'View Pin'}
      </LinkButton>
    </AssetScannerAccessPinWrapper>
  );
}

AssetScannerAccessPin.propTypes = {
  assetScannerPinAccessCode: string,
};

export function PinCodeAccessSection({ assetScannerPinAccessCode }) {
  return (
    <SectionWithCard
      list={[
        {
          elem: <span>Asset Scanner Access Pin</span>,
          isHidden: !assetScannerPinAccessCode,
        },
        {
          elem: (
            <AssetScannerAccessPin
              assetScannerPinAccessCode={assetScannerPinAccessCode}
            />
          ),
          isHidden: !assetScannerPinAccessCode,
        },
      ]}
    />
  );
}

PinCodeAccessSection.propTypes = {
  assetScannerPinAccessCode: string,
};
