import {
  AccountingStatus,
  ProjectType,
  propertyOccupancyTypes,
  RecurringProjectTypes,
  WorkOrderStatus,
} from 'poly-constants';

import { batchInvoiceFiltersToSearchQuery } from './filters-to-query.js';

// Hardcoded client IDs are dictated by business requirements
// and actually should not be here at first place. But there is
// no another simple way to have client specific links.
// Depending on further requirements changes we may opt in for
// backend based filters preset storage at client specific configs.
// But such solution will require extra effort comparing to this one.
const _BASS_PRO_CLIENT_ID = '5d54560dd145ba5d67a6827c';
const _BFS_CLIENT_ID = '6058b51d9d4f417bc3bdb8f5';
const _REGIONAL_MANAGEMENT_CLIENT_ID = '62ec310d65e0a2f657321c02';
export const _REGIONAL_MANAGEMENT_JANITORIAL_SERVICE_TYPE_ID =
  '5cb9e5a51fdf46139076bfa7';

export const housekeepingBatchInvoicingQuery = batchInvoiceFiltersToSearchQuery(
  {
    clientId: _BASS_PRO_CLIENT_ID,
    [WorkOrderStatus.COMPLETED]: true,
    [AccountingStatus.READY_TO_INVOICE]: true,
    type: ProjectType.HOUSEKEEPING,
  },
);

export const tmBassProBatchInvoicingQuery = batchInvoiceFiltersToSearchQuery({
  clientId: _BASS_PRO_CLIENT_ID,
  [WorkOrderStatus.COMPLETED]: true,
  [AccountingStatus.READY_TO_INVOICE]: true,
  type: ProjectType.WORK_ORDER,
});

export const bidBassProBatchInvoicingQuery = batchInvoiceFiltersToSearchQuery({
  clientId: _BASS_PRO_CLIENT_ID,
  [WorkOrderStatus.COMPLETED]: true,
  [AccountingStatus.READY_TO_INVOICE]: true,
  type: ProjectType.BID,
});

export const bfsDormantBatchInvoicingQuery = batchInvoiceFiltersToSearchQuery({
  clientId: _BFS_CLIENT_ID,
  [WorkOrderStatus.COMPLETED]: true,
  [AccountingStatus.READY_TO_INVOICE]: true,
  'property.buildingInfo.occupancy': propertyOccupancyTypes.vacant,
});

export const bfsActiveBatchInvoicingQuery = batchInvoiceFiltersToSearchQuery({
  clientId: _BFS_CLIENT_ID,
  [WorkOrderStatus.COMPLETED]: true,
  [AccountingStatus.READY_TO_INVOICE]: true,
  'property.buildingInfo.occupancy': propertyOccupancyTypes.occupied,
});

export const regionalManagementJanitorialBatchInvoicingQuery =
  batchInvoiceFiltersToSearchQuery({
    clientId: _REGIONAL_MANAGEMENT_CLIENT_ID,
    serviceTypeId: _REGIONAL_MANAGEMENT_JANITORIAL_SERVICE_TYPE_ID,
    'parent.type': RecurringProjectTypes.recurringProject,
    [WorkOrderStatus.COMPLETED]: true,
    [AccountingStatus.READY_TO_INVOICE]: true,
  });

export const regionalManagementBatchInvoicingQuery =
  batchInvoiceFiltersToSearchQuery({
    clientId: _REGIONAL_MANAGEMENT_CLIENT_ID,
    [WorkOrderStatus.COMPLETED]: true,
    [AccountingStatus.READY_TO_INVOICE]: true,
  });
