import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string, oneOfType, instanceOf } from 'prop-types';
import { firstLettersOfEachWord, formatDateTime } from 'poly-utils';
import { UserAvatar, Text, getThemeColor } from 'poly-book-admin';
import { SYSTEM_USER_NAME } from 'poly-constants';

export const PolyLogo = styled(UserAvatar).attrs({ children: 'P' })`
  background-color: ${getThemeColor(['secondaryMid'])};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const UserAndDateContainer = styled.div`
  padding-left: 10px;
`;

const UserAvatarS = styled(UserAvatar)`
  cursor: default;
`;

const DateText = styled(Text)`
  color: ${getThemeColor(['secondary'])};
  font-size: 10px;
  line-height: 12px;
`;

const fullName = R.pathOr(SYSTEM_USER_NAME, ['fullName']);
const avatarPlaceholder = R.compose(firstLettersOfEachWord, fullName);

export const getUserAvatarComp = (user) =>
  fullName(user) === SYSTEM_USER_NAME ? (
    <PolyLogo />
  ) : (
    <UserAvatarS data-testid="user-avatar">
      {avatarPlaceholder(user)}
    </UserAvatarS>
  );

export function UpdateMetaInfo({ createdBy, createdAt, prefix }) {
  const preparedDate = formatDateTime(createdAt);
  const dateText = prefix ? `${prefix}: ${preparedDate}` : preparedDate;
  return (
    <Container>
      {getUserAvatarComp(createdBy)}
      <UserAndDateContainer>
        <Text lineHeight="18px" size="12px">
          {fullName(createdBy)}
        </Text>
        {!!createdAt && <DateText>{dateText}</DateText>}
      </UserAndDateContainer>
    </Container>
  );
}

UpdateMetaInfo.propTypes = {
  createdBy: shape({ fullName: string }),
  createdAt: oneOfType([string, instanceOf(Date)]),
  prefix: string,
};

UpdateMetaInfo.defaultProps = {
  createdBy: {
    fullName: SYSTEM_USER_NAME,
  },
};
