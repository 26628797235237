import * as R from 'ramda';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useQuery, gql } from '@apollo/client';
import { getThemeColor, Text, Loader } from 'poly-book-admin';
import { useSubscriptionByChanges } from 'poly-client-utils';

import { MAX_ITEMS } from '../../../constants/general.js';
import { UPDATES_SEARCH_CHANGED } from '../../../hooks/updates/subscriptions.js';

const DisabledTaskPopoverContentWrapperS = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  text-align: start;
  border-radius: 5px;
  flex-direction: column;
  background-color: ${getThemeColor(['primaryRegular'])};
  min-width: 50px;
  min-height: 50px;

  > div:first-child {
    padding-bottom: 10px;
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const COMPLETED_TASK_POPOVER_QUERY = gql`
  query COMPLETED_TASK_POPOVER_QUERY($id: ID!, $input: DocumentUpdatesInput!) {
    task(id: $id) {
      _id
      description
      documentUpdates(input: $input) {
        hits {
          _id
          title
          message
        }
      }
    }
  }
`;

// getTitle :: TaskQueryResult -> String
const getTitle = R.pathOr('', ['task', 'description']);

// getMessage :: TaskQueryResult -> String
const getMessage = R.compose(
  R.propOr('', 'message'),
  R.find(R.propSatisfies(R.includes('Task Completed'), 'title')),
  R.pathOr([], ['task', 'documentUpdates', 'hits']),
);

export function CompletedTaskPopoverContent({ taskId }) {
  const input = {
    from: 0,
    size: MAX_ITEMS,
    includeAudit: false,
    sort: { createdAt: -1 },
  };

  const { data, refetch, loading } = useQuery(COMPLETED_TASK_POPOVER_QUERY, {
    variables: { id: taskId, input },
    skip: !taskId,
  });

  const title = useMemo(() => getTitle(data), [data]);

  const message = useMemo(() => getMessage(data), [data]);

  useSubscriptionByChanges({
    refetch,
    result: data,
    skipQuery: !taskId,
    gqlQueryChanged: UPDATES_SEARCH_CHANGED,
    extractQueryParamsFromProps: R.always({
      variables: { searchInput: { documentId: taskId } },
    }),
  });

  return (
    <DisabledTaskPopoverContentWrapperS>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Text size={18} color={['white']} weight="bold">
            {title}
          </Text>
          <Text size={12} color={['white']}>
            {message}
          </Text>
        </>
      )}
    </DisabledTaskPopoverContentWrapperS>
  );
}

CompletedTaskPopoverContent.propTypes = { taskId: string.isRequired };
