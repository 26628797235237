import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { SidebarFormWrapper } from 'poly-admin-ui';
import { Text } from 'poly-book-admin';

import { AddProjectTimeForm } from './ProjectTimeForm/AddProjectTimeForm.js';
import { EditProjectTimeForm } from './ProjectTimeForm/EditProjectTimeForm.js';

export function ProjectTimeForm({ projectId, handleCancel }) {
  const [editTimeProps, setEditTimeProps] = useState(null);

  const title = editTimeProps ? 'Edit Time' : 'Add Time';

  return (
    <SidebarFormWrapper onCancel={handleCancel}>
      <Text size="18px" lineHeight="24px">
        {title}
      </Text>
      {editTimeProps ? (
        <EditProjectTimeForm
          {...editTimeProps}
          handleCancel={handleCancel}
          setEditTimeProps={setEditTimeProps}
        />
      ) : (
        <AddProjectTimeForm
          projectId={projectId}
          setEditTimeProps={setEditTimeProps}
          handleCancel={handleCancel}
        />
      )}
    </SidebarFormWrapper>
  );
}

ProjectTimeForm.propTypes = {
  projectId: string.isRequired,
  handleCancel: func.isRequired,
};
