import React from 'react';
import { string } from 'prop-types';
import { gql } from '@apollo/client';
import { entities, SidebarUpdatesTab, commonUpdateFields } from 'poly-admin-ui';
import { UpdateCollections } from 'poly-constants';

import { PrintLayoutProvider } from '../../../providers.js';

const STAFF_UPDATES = gql`
  query STAFF_UPDATES($id: ID!, $documentUpdatesInput: DocumentUpdatesInput!) {
    user(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export function UserSidebarUpdatesTab({ userId, userName }) {
  const tabProps = {
    entity: {
      _id: userId,
      preview: 'user',
      name: entities.USER,
      humanReadableId: userName,
    },
    updatesQuery: STAFF_UPDATES,
    PrintLayout: PrintLayoutProvider,
    collection: UpdateCollections.users,
  };

  return <SidebarUpdatesTab {...tabProps} />;
}

UserSidebarUpdatesTab.propTypes = {
  userId: string.isRequired,
  userName: string.isRequired,
};
