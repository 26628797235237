import React from 'react';
import styled from 'styled-components';
import { Cell, Row, getThemeColor } from 'poly-book-admin';
import { bool, number, string } from 'prop-types';
import { convertCentsToDollars, formatTotal } from 'poly-utils';

const SectionRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ withBottomMargin }) => (withBottomMargin ? '10px' : '0')};
  border: 1px solid rgba(197, 198, 201, 0.35);
  background-color: ${getThemeColor(['midLight'])};
`;

const CellS = styled(Cell)`
  font-size: 14px;
  padding-right: 20px;
  width: 400px !important;
  font-weight: ${({ isBold }) => (isBold ? '800' : '400')};
  text-align: ${({ isCurrency }) => (isCurrency ? 'right' : 'left')};
`;

export function IncomeStatementSectionTotalTitle({ title }) {
  return (
    <SectionRow>
      <CellS>{title}</CellS>
    </SectionRow>
  );
}

IncomeStatementSectionTotalTitle.propTypes = {
  title: string,
};

export function IncomeStatementSectionTotal({ title, total, isBold }) {
  return (
    <SectionRow withBottomMargin>
      <CellS isBold={isBold}>{title}</CellS>
      <CellS isCurrency isBold={isBold}>
        {formatTotal(convertCentsToDollars(total))}
      </CellS>
    </SectionRow>
  );
}

IncomeStatementSectionTotal.propTypes = {
  title: string,
  total: number,
  isBold: bool,
};
