import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';
import { isAdminPOWithLowBalance } from 'poly-utils/src/adminPurchaseOrder.js';

import { BlockWithLabel, threeBlocksProps } from './commonSidebarComponents.js';
import { getSectionText } from './commonSidebarSectionFormatters.js';
import { PurchaseOrderBalanceWarning } from '../../pages/PurchaseOrdersPage/PurchaseOrderBalanceWarning.js';
import { PurchaseOrderLink } from '../PurchaseOrderSidebar/PurchaseOrderLink.js';

const POWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

// getAdminPurchaseOrder :: Project -> AdminPurchaseOrder
export const getAdminPurchaseOrder = R.ifElse(
  R.prop('parent'),
  R.path(['parent', 'adminPurchaseOrder']),
  R.prop('adminPurchaseOrder'),
);

// getClientReferenceNumber :: Project -> String
export const getClientReferenceNumber = R.either(
  R.ifElse(
    R.prop('parent'),
    R.path(['parent', 'clientReferenceNumber']),
    R.path(['clientReferenceNumber']),
  ),
  R.compose(R.prop('poNumber'), getAdminPurchaseOrder),
);

const getClientReferenceNumberOrNothingIU = R.compose(
  R.defaultTo(NOTHING_UI_STRING),
  getClientReferenceNumber,
);

function ClientPOComponent({ po }) {
  const isLowBalance = isAdminPOWithLowBalance(po);

  return (
    <POWrapper>
      <PurchaseOrderLink {...po}>
        {`${po?.poNumber} / ${po?.displayName}`}
      </PurchaseOrderLink>
      {isLowBalance && (
        <PurchaseOrderBalanceWarning warning="This is PO with a low balance." />
      )}
    </POWrapper>
  );
}

ClientPOComponent.propTypes = {
  po: shape({
    poNumber: string,
    displayName: string,
  }),
};

function getClientPOOrReferenceNumberComp(project) {
  const po = getAdminPurchaseOrder(project);
  const enablePurchaseOrder = R.path(
    ['client', 'configs', 'enablePurchaseOrder'],
    project,
  );

  if (enablePurchaseOrder && po?.poNumber) {
    return function () {
      return <ClientPOComponent po={po} />;
    };
  }

  return getSectionText(
    getClientReferenceNumberOrNothingIU(project) || po?.poNumber,
  );
}

export function ClientReferenceComp({ id, project }) {
  return (
    <BlockWithLabel
      id={id}
      {...threeBlocksProps}
      label="Client Ref # / PO"
      Component={getClientPOOrReferenceNumberComp(project)}
    />
  );
}

ClientReferenceComp.propTypes = {
  id: string.isRequired,
  project: shape({}).isRequired,
};
