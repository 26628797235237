import React from 'react';
import { DefaultBodyWrapper, Loader } from 'poly-book-admin';
import { useRouterParams, useRouterQuery } from 'poly-client-routing';
import { useReactiveDocumentTitle } from 'poly-client-utils';

import {
  PageCardBody,
  PageCardFirstSection,
  PageCardSecondSection,
} from '../components/PageCardComponents.js';
import { NotFoundEntity } from '../components/NotFoundEntity.js';
import { masterProjectSidebarTabs } from '../routes/constants.js';
import { MasterProjectSidebarSections } from '../sidebars/MasterRecurringProjectSidebar/MasterRecurringProjectSidebar.js';
import { MasterRecurringProjectSidebarTabs } from '../sidebars/MasterRecurringProjectSidebar/tabs/MasterRecurringProjectSidebarTabs.js';
import { useMasterRecurringProjectSidebarData } from '../sidebars/MasterRecurringProjectSidebar/useMasterRecurringProjectSidebarData.js';
import { useCloseExtendedTab } from '../hooks/useCloseExtendedTab.js';

export function MasterProjectCard() {
  const { projectId } = useRouterParams(['projectId']);
  const { cardTab } = useRouterQuery(['cardTab']);
  const { loading, project } = useMasterRecurringProjectSidebarData(projectId);

  const closeExtendedTab = useCloseExtendedTab();
  useReactiveDocumentTitle(project ? project.projectId : null);

  if (loading) {
    return <Loader />;
  }

  const fullWidth = cardTab === masterProjectSidebarTabs.masterProjectChild;

  return (
    <DefaultBodyWrapper height="100%">
      <PageCardBody>
        {!project.projectId ? (
          <NotFoundEntity entityName="master project" />
        ) : (
          <>
            <PageCardFirstSection
              fullWidth={fullWidth}
              onClick={closeExtendedTab}
            >
              <MasterProjectSidebarSections project={project} isCard />
            </PageCardFirstSection>
            <PageCardSecondSection fullWidth={fullWidth}>
              <MasterRecurringProjectSidebarTabs
                customTabQueryKey="cardTab"
                project={project}
                isCard
              />
            </PageCardSecondSection>
          </>
        )}
      </PageCardBody>
    </DefaultBodyWrapper>
  );
}
