import React from 'react';
import { string } from 'prop-types';
import { Icon } from 'poly-book-admin/src/Icon/index.js';
import { Popover } from 'poly-book-admin/src/Popover/index.js';
import styled from 'styled-components';

const PopoverContent = styled.div`
  padding: 12px 20px;
  height: auto;
  background: #12347a;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  max-width: 550px;
`;

export function PurchaseOrderBalanceWarning({ warning, iconSize, ...props }) {
  const iconElement = <Icon name="yellowWarning" size={iconSize} />;

  return (
    <Popover
      {...props}
      position="left"
      bgColor="#12347a"
      title={iconElement}
      content={<PopoverContent>{warning}</PopoverContent>}
      withPortalAnchor
    />
  );
}

PurchaseOrderBalanceWarning.propTypes = {
  warning: string.isRequired,
  iconSize: string,
};

PurchaseOrderBalanceWarning.defaultProps = {
  iconSize: '18',
};
