import {
  CREATE_JOURNAL_PERMISSION,
  PAY_CLIENT_INVOICE_PERMISSION,
  READ_ACCOUNTS_PAYABLE_REPORT_PERMISSION,
  READ_ACCOUNTS_RECEIVABLE_REPORT_PERMISSION,
  CREATE_ACCOUNT_PERMISSION,
  READ_ACCOUNT_TYPE_PERMISSION,
  READ_AP_AGING_REPORT_PERMISSION,
  READ_AR_AGING_REPORT_PERMISSION,
  READ_BALANCE_SHEET_PERMISSION,
  READ_CLIENT_HISTORY_PERMISSION,
  READ_DIVISION_PERMISSION,
  READ_GENERAL_LEDGER_PERMISSION,
  READ_PROFIT_LOSS_PERMISSION,
  READ_RECONCILIATION_REPORT_PERMISSION,
  READ_REGISTER_TRANSACTION_PERMISSION,
  READ_TRANSACTION_PERMISSION,
  READ_WIP_REPORT_PERMISSION,
  RECONCILE_JOURNAL_PERMISSION,
  TRANSFER_FUND_PERMISSION,
  UPDATE_JOURNAL_PERMISSION,
  VOID_SUPPLIER_PAYMENT_PERMISSION,
  READ_CREDIT_CARD_USER_PERMISSION,
  READ_TRIAL_BALANCE_PERMISSION,
  PAY_BY_BANK_PERMISSION,
  PAY_SUPPLIER_INVOICE_PERMISSION,
  INPUT_PAYROLL_PERMISSION,
  UPDATE_ACCOUNTING_PERIOD_PERMISSION,
  READ_SUPPLIERS_AAC_1099_REPORT_PERMISSION,
  READ_RECURRING_JOURNAL_PERMISSION,
  READ_SUPPLIER_BATCH_PERMISSION,
  READ_INCOME_STATEMENT_PERMISSION,
  EDIT_CLIENT_PAYMENT_PERMISSION,
  READ_SUB_CATEGORY_REPORT_PERMISSION,
  PRINT_SUPPLIER_CHECK_PERMISSION,
} from 'poly-security';

import { routesNames } from '../../routes/constants.js';

export const defaultWarningMessage =
  'You are not an authorized user of this feature. Contact your Admin for help.';

// add link prop for any links object when the appropriate page is ready
export const financialDirectoryPageLinks = [
  {
    column: 1,
    title: 'Search',
    links: [
      {
        name: 'Transaction Amount Search',
        link: routesNames.SEARCH_TRANSACTIONS,
        permissions: [READ_TRANSACTION_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
    ],
  },
  {
    column: 1,
    title: 'Banking',
    links: [
      {
        name: 'Bank Registers',
        link: routesNames.BANK_REGISTERS,
        permissions: [READ_REGISTER_TRANSACTION_PERMISSION],
      },
      {
        name: 'Bank Reconciliation',
        link: routesNames.BANK_RECONCILIATION,
        permissions: [RECONCILE_JOURNAL_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Bank Reconciliation Reports',
        link: routesNames.BANK_RECONCILIATION_REPORT,
        permissions: [READ_RECONCILIATION_REPORT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Transfer Funds (Bank To Bank)',
        link: routesNames.TRANSFER_FUNDS,
        permissions: [TRANSFER_FUND_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Bank Deposits',
        link: routesNames.BANK_DEPOSITS,
        permissions: [CREATE_JOURNAL_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Pay by Bank',
        link: routesNames.PAY_BY_BANK,
        permissions: [PAY_BY_BANK_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      { name: 'Print/Void Checks' },
    ],
  },
  {
    column: 1,
    title: 'Credit Cards',
    links: [
      {
        name: 'Credit Card Registers',
        link: routesNames.CREDIT_CARD_REGISTERS,
        permissions: [READ_REGISTER_TRANSACTION_PERMISSION],
      },
      {
        name: 'Credit Card Users',
        link: routesNames.CREDIT_CARD_USERS,
        permissions: [READ_CREDIT_CARD_USER_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Credit Card Reconciliation',
        link: routesNames.CREDIT_CARD_RECONCILIATION,
        permissions: [RECONCILE_JOURNAL_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Credit Card Reconciliation Reports',
        link: routesNames.CREDIT_CARD_RECONCILIATION_REPORT,
        permissions: [READ_RECONCILIATION_REPORT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Pay by Credit Card',
        link: routesNames.PAY_BY_CREDIT_CARD,
        permissions: [PAY_BY_BANK_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
    ],
  },
  {
    column: 2,
    title: 'Client Payments',
    links: [
      {
        name: 'Receive Payments',
        link: routesNames.PAY_CLIENT_INVOICES,
        permissions: [PAY_CLIENT_INVOICE_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      { name: 'Issue Credit Memo' },
      { name: 'Credit Memo Reports' },
      {
        name: 'Edit Client Payments',
        link: routesNames.EDIT_CLIENT_PAYMENTS,
        permissions: [EDIT_CLIENT_PAYMENT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Client History',
        link: routesNames.CLIENT_HISTORY,
        permissions: [READ_CLIENT_HISTORY_PERMISSION],
      },
      { name: 'Client Sales Reports', link: routesNames.CLIENT_SALES_REPORT },
    ],
  },
  {
    column: 2,
    title: 'Employee',
    links: [
      {
        name: 'Input Payroll',
        link: routesNames.INPUT_PAYROLL,
        permissions: [INPUT_PAYROLL_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      { name: 'Complete Payroll' },
    ],
  },
  {
    column: 3,
    title: 'Supplier Payments',
    links: [
      {
        name: 'Pay Suppliers',
        link: routesNames.PAY_SUPPLIERS,
        permissions: [PAY_SUPPLIER_INVOICE_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Print Checks',
        link: routesNames.PRINT_CHECKS,
        permissions: [PRINT_SUPPLIER_CHECK_PERMISSION],
      },
      {
        name: 'Search Supplier Invoices',
        link: routesNames.SEARCH_SUPPLIER_INVOICES,
      },
      {
        name: 'Enter Supplier Invoices',
        link: routesNames.ENTER_SUPPLIER_INVOICE,
      },
      {
        name: 'Void Supplier Payments',
        link: routesNames.VOID_SUPPLIER_PAYMENTS,
        permissions: [VOID_SUPPLIER_PAYMENT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Supplier Payment History',
        link: routesNames.SUPPLIER_PAYMENT_HISTORY,
      },
      {
        name: 'Print Check Detail - Pay Suppliers',
        link: routesNames.PRINT_CHECKS_DETAILS,
      },
      {
        name: 'Supplier 1099 Report',
        link: routesNames.SUPPLIER_1099_REPORT,
        permissions: [READ_SUPPLIERS_AAC_1099_REPORT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Sub-Category Report',
        link: routesNames.SUB_CATEGORY_REPORT,
        permissions: [READ_SUB_CATEGORY_REPORT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
    ],
  },
  {
    column: 3,
    title: 'Supplier Invoices',
    links: [
      {
        name: 'Daily Supplier Invoices Batch',
        link: routesNames.DAILY_SUPPLIER_INVOICE_BATCH,
        permissions: [READ_SUPPLIER_BATCH_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      { name: 'Missing Supplier Invoices' },
      { name: 'Disputed Supplier Invoice' },
      {
        name: 'Supplier Invoices  Ready for Approval',
        link: routesNames.APPROVE_SUPPLIER_INVOICES,
      },
    ],
  },
  {
    column: 3,
    title: 'Sales Tax Report',
    links: [
      {
        name: 'Wisconsin Sales Tax Report',
        link: routesNames.WISCONSIN_TAX_REPORT,
        permissions: [READ_TRANSACTION_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'New York Sales Tax Report',
        link: routesNames.NEW_YORK_TAX_REPORT,
        permissions: [READ_TRANSACTION_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Sales Tax Report By State',
        link: routesNames.SALES_TAX_REPORT_BY_STATE,
        permissions: [READ_TRANSACTION_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
    ],
  },
  {
    column: 4,
    title: 'Financial',
    links: [
      {
        name: 'Chart of Accounts',
        link: routesNames.CHART_OF_ACCOUNTS,
        permissions: [CREATE_ACCOUNT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Divisions',
        link: routesNames.DIVISIONS,
        permissions: [READ_DIVISION_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Account Types',
        link: routesNames.ACCOUNT_TYPES,
        permissions: [READ_ACCOUNT_TYPE_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'General Ledger',
        link: routesNames.GENERAL_LEDGER,
        permissions: [READ_GENERAL_LEDGER_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Balance Sheet',
        link: routesNames.BALANCE_SHEET,
        permissions: [READ_BALANCE_SHEET_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Income Statement Report',
        link: routesNames.INCOME_STATEMENT_REPORT,
        permissions: [READ_INCOME_STATEMENT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Profit and Loss',
        link: routesNames.PROFIT_LOSS_REPORT,
        permissions: [READ_PROFIT_LOSS_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Accounts Receivable',
        link: routesNames.ACCOUNTS_RECEIVABLE_REPORT,
        permissions: [READ_ACCOUNTS_RECEIVABLE_REPORT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'AR Aging Report',
        link: routesNames.AR_AGING_REPORT,
        permissions: [READ_AR_AGING_REPORT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Accounts Payable',
        link: routesNames.ACCOUNTS_PAYABLE_REPORT,
        permissions: [READ_ACCOUNTS_PAYABLE_REPORT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'AP Aging Report',
        link: routesNames.AP_AGING_REPORT,
        permissions: [READ_AP_AGING_REPORT_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Work In Progress Report',
        link: routesNames.WORK_IN_PROGRESS_REPORT,
        permissions: [READ_WIP_REPORT_PERMISSION],
      },
      {
        name: 'Create Journal Entry',
        link: routesNames.CREATE_JOURNAL_ENTRY,
        permissions: [CREATE_JOURNAL_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Journal Entry Ledger',
        link: routesNames.JOURNAL_ENTRY_LEDGER,
        permissions: [UPDATE_JOURNAL_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Recurring Journal Entries',
        link: routesNames.RECURRING_JOURNALS,
        permissions: [READ_RECURRING_JOURNAL_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Financial Year Closing',
        link: routesNames.CLOSE_FINANCIAL_YEAR,
        permissions: [UPDATE_ACCOUNTING_PERIOD_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Trial Balance Optimized',
        link: routesNames.TRIAL_BALANCE,
        permissions: [READ_TRIAL_BALANCE_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Re-Open Closed Financial Year',
        link: routesNames.REOPEN_FINANCIAL_YEAR,
        permissions: [UPDATE_ACCOUNTING_PERIOD_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
      {
        name: 'Lock Previous Month',
        link: routesNames.LOCK_PREVIOUS_MONTH,
        permissions: [UPDATE_ACCOUNTING_PERIOD_PERMISSION],
        warningMessage: defaultWarningMessage,
      },
    ],
  },
];
