import React from 'react';
import { AccountTypeStatus } from 'poly-constants';
import { Select, Table } from 'poly-book-admin';
import { capitalizeFirstLetter } from 'poly-client-utils';
import styled from 'styled-components';

const statusOptions = [
  {
    value: AccountTypeStatus.ACTIVE,
    label: capitalizeFirstLetter(AccountTypeStatus.ACTIVE),
  },
  {
    value: AccountTypeStatus.INACTIVE,
    label: capitalizeFirstLetter(AccountTypeStatus.INACTIVE),
  },
];

export function StatusDropDown(props) {
  return <Select {...props} options={statusOptions} />;
}

export const AccountTypesTableS = styled(Table)`
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: 300px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 100px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 40px;
  }
`;
