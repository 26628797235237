import React from 'react';
import * as R from 'ramda';
import { EXPORT_XLS_CAPTION, PRINT_PDF_CAPTION } from 'poly-constants';
import { LinkButton } from 'poly-book-admin/src/LinkButton/index.js';
import styled from 'styled-components';
import { arrayOf, shape } from 'prop-types';
import { openPrintWindowWithData } from 'poly-client-utils/src/print.js';
import { performExcelExport } from 'poly-client-utils/src/excel-export/export.js';
import { ExcelExportCellType } from 'poly-utils/src/excel-export/constants.js';
import { applyProp, isNilOrEmpty } from 'poly-utils/src/general.js';
import {
  centsToDollarsWithFormat,
  formatDate,
  createExcelExportCell,
} from 'poly-utils';

import { PrintLayoutProvider } from '../../providers.js';
import { PurchaseOrdersTable } from './PurchaseOrdersTable.js';
import { getExcelHeaderCells } from '../PaySuppliersPage/PaySupplierInvoicesXlsExportBtn.js';
import {
  getPurchaseOrdersStatusUI,
  getPurchaseOrdersTypeUI,
} from '../../sidebars/PurchaseOrderSidebar/utils.js';

const LinkButtonS = styled(LinkButton)`
  margin-right: 20px;
`;

export function PurchaseOrdersPDFButton({ purchaseOrders }) {
  const handleClick = async () => {
    await openPrintWindowWithData({
      Layout: PrintLayoutProvider,
      Table: PurchaseOrdersTable,
      metaData: {
        title: 'PO Balance',
      },
      fileName: 'po_balance',
      purchaseOrders,
    });
  };

  return (
    <LinkButton disabled={isNilOrEmpty(purchaseOrders)} onClick={handleClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

PurchaseOrdersPDFButton.propTypes = {
  purchaseOrders: arrayOf(shape({})),
};

const xlsExportHeaders = [
  ['PO number', 15, ExcelExportCellType.tableHeader],
  ['PO Display Name', 20, ExcelExportCellType.tableHeader],
  ['Status', 10, ExcelExportCellType.tableHeader],
  ['Start Date', 15, ExcelExportCellType.tableHeader],
  ['End Date', 15, ExcelExportCellType.tableHeader],
  ['PO Type', 20, ExcelExportCellType.tableHeader],
  ['Authorized By', 15, ExcelExportCellType.tableHeader],
  ['Initial Balance', 15, ExcelExportCellType.tableCurrencyHeader],
  ['Current Balance', 15, ExcelExportCellType.tableCurrencyHeader],
];

// getRowPurchaseOrdersXLSConfig :: PurchaseOrders -> [[ExcelExportDataCell]]
const getRowPurchaseOrdersXLSConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 7),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
  ]),
  R.juxt([
    R.prop('poNumber'),
    R.prop('displayName'),
    applyProp(getPurchaseOrdersStatusUI)('status'),
    applyProp(formatDate)('startDate'),
    applyProp(formatDate)('endDate'),
    getPurchaseOrdersTypeUI,
    R.path(['authorizedBy', 'fullName']),
    applyProp(centsToDollarsWithFormat)('initialBalance'),
    applyProp(centsToDollarsWithFormat)('currentBalance'),
  ]),
);

export const getPurchaseOrdersXLSConfig = (purchaseOrders) => ({
  exportFileName: 'po-balance.xlsx',
  columnWidths: xlsExportHeaders.map(R.nth(1)),
  rows: [
    [{ value: 'PO Balance', cellType: ExcelExportCellType.title }],
    getExcelHeaderCells(xlsExportHeaders),
    ...R.map(getRowPurchaseOrdersXLSConfig, purchaseOrders),
  ],
});

export function PurchaseOrdersXLSButton({ purchaseOrders }) {
  const handleClick = async () => {
    const config = getPurchaseOrdersXLSConfig(purchaseOrders);
    await performExcelExport(config);
  };

  return (
    <LinkButtonS disabled={isNilOrEmpty(purchaseOrders)} onClick={handleClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonS>
  );
}

PurchaseOrdersXLSButton.propTypes = {
  purchaseOrders: arrayOf(shape({})),
};
