import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { moneyColumnStyles, Table, Loader } from 'poly-book-admin';
import { assocByPath, assocBy } from 'poly-utils';
import { DESC_SORT_ORDER } from 'poly-constants';
import {
  useSetInitialPaginationOnMount,
  useMapConfigToTableProps,
  useSetItemsCount,
  useTableSorting,
} from 'poly-admin-ui';
import {
  useHighlightMatchesBySearch,
  commonSortQuery,
} from 'poly-client-utils';

import {
  InvoiceFileLink,
  invoicePdfColumn,
  invoiceDateColumn,
  invoiceAmountColumn,
  invoicePaidByColumn,
  invoiceStatusColumn,
  invoiceProjectColumn,
  invoiceDueDateColumn,
  invoiceDatePaidColumn,
  invoiceStatusColumnPDF,
  invoiceStatusToStringMap,
} from '../columns/invoices.js';
import { YellowWarningIcon } from '../../../components/Icons.js';
import { ProjectLink } from '../../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { InvoicePopoverButton } from '../../../pages/SearchSupplierInvoices/InvoicePopoverButton.js';
import { useCheckInvoiceNumberDuplicated } from '../../../hooks/useCheckInvoiceNumberDuplicated.js';
import { useSupplierInvoices } from '../../core/hooks/invoices/useSupplierInvoices.js';
import { setEntitySortAction } from '../../../redux/entitySort/entitySort.js';

export const SupplierInvoicesTableS = styled(Table)`
  td:nth-child(2),
  th:nth-child(2),
  td:nth-child(3),
  th:nth-child(3),
  td:nth-child(4),
  th:nth-child(4),
  td:nth-child(6),
  th:nth-child(6),
  td:nth-child(7),
  th:nth-child(7),
  td:nth-child(8),
  th:nth-child(8) {
    width: 150px;
  }

  ${moneyColumnStyles(5)};

  // don't use last-child because it messes up
  // printed version of table (no last column)
  td:nth-child(9),
  th:nth-child(9) {
    width: 35px;
  }
`;

const InvoiceLinkWrapper = styled.div`
  display: flex;
  gap: 15px;
`;
const PopoverContent = styled.div`
  padding: 10px;
  background: #12347a;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
`;

export function InvoiceLinkWithRejectionReason({ rejectionReason, ...props }) {
  const { isDuplicated } = useCheckInvoiceNumberDuplicated(props);

  const duplicatedInvoiceNumberMessage = isDuplicated
    ? 'Invoice number is already used on the supplier, master supplier or branch supplier'
    : null;

  const warningMessage = rejectionReason || duplicatedInvoiceNumberMessage;

  return (
    <InvoiceLinkWrapper>
      <InvoiceFileLink {...props} />
      {!!warningMessage && (
        <YellowWarningIcon
          iconSize={17}
          position="right"
          withPortalAnchor
          bgColor="#12347a"
          content={<PopoverContent>{warningMessage}</PopoverContent>}
        />
      )}
    </InvoiceLinkWrapper>
  );
}

InvoiceLinkWithRejectionReason.propTypes = {
  rejectionReason: string,
};

const commonInvoicesTableConfig = [
  invoiceProjectColumn(null, ProjectLink),
  invoiceDateColumn,
  invoiceDueDateColumn,
  invoiceAmountColumn,
  invoicePaidByColumn,
  invoiceDatePaidColumn,
];

export const printInvoicesTableConfig = [
  invoicePdfColumn,
  ...commonInvoicesTableConfig,
  invoiceStatusColumnPDF,
];

export const invoicesTableConfig = [
  [
    'Invoice #',
    InvoiceLinkWithRejectionReason,
    commonSortQuery(['invoiceNumber']),
  ],
  ...commonInvoicesTableConfig,
  invoiceStatusColumn,
  ['', InvoicePopoverButton],
];

// prepareInvoicesToDisplay :: [Invoice] -> [ModifiedInvoice]
// ModifiedInvoice = Object
const prepareInvoicesToDisplay = R.map(
  R.compose(
    assocByPath(['project', 'urlParam'], R.path(['project', 'projectId'])),
    assocBy(
      'statusUI',
      R.compose(R.prop(R.__, invoiceStatusToStringMap), R.prop('status')),
    ),
    assocBy('invoiceId', R.prop('_id')),
  ),
);

// getInvoicesTotal :: SearchInvoicesQueryResult -> Number
const getInvoicesTotal = R.pathOr(0, ['searchInvoices', 'total']);

// getInvoices :: SearchInvoicesQueryResult -> [Invoice]
const getInvoices = R.pathOr([], ['searchInvoices', 'hits']);

// getModifiedInvoice :: [Invoice] -> [ModifiedInvoice]
const getModifiedInvoice = R.compose(prepareInvoicesToDisplay, getInvoices);

export function SupplierInvoicesTable() {
  useSetInitialPaginationOnMount();

  const dispatch = useDispatch();

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: invoicesTableConfig,
    column: 1,
    defaultSort: commonSortQuery(['createdAt'])(DESC_SORT_ORDER),
  });

  useEffect(() => {
    dispatch(setEntitySortAction(sort));

    return () => {
      dispatch(setEntitySortAction(null));
    };
  }, [sort]);

  const { loading, result } = useSupplierInvoices({ sort });

  useSetItemsCount(getInvoicesTotal, result);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getModifiedInvoice,
    invoicesTableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [
      ['invoiceNumber'],
      ['project', 'projectId'],
      ['statusUI'],
      ['paidBy', 'fullName'],
    ],
    rows,
  );

  if (loading) return <Loader />;

  return (
    <SupplierInvoicesTableS
      {...restTableProps}
      {...tableSortingProps}
      rows={highlightedRows}
    />
  );
}

SupplierInvoicesTable.displayName = 'SupplierInvoicesTable';

export function SupplierInvoicesPrintTable(props) {
  const { rows, ...restTableProps } = useMapConfigToTableProps(
    R.prop('data'),
    printInvoicesTableConfig,
    props,
  );

  return <SupplierInvoicesTableS {...props} {...restTableProps} rows={rows} />;
}
