import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { READ_JOURNAL_PAYMENT_BATCH_PERMISSION } from 'poly-security';
import { bool, string } from 'prop-types';
import {
  moneyColumnStyles,
  WindowedTable,
  Table,
  Icon,
  getThemeColor,
  LinkButton,
} from 'poly-book-admin';
import {
  highlightTextToReactElement,
  useHasUserAccessWithPermission,
} from 'poly-client-utils';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import {
  formatDate,
  applyProp,
  formatTotal,
  formatMoneyTransactionPayeeNameColumn,
} from 'poly-utils';
import { JournalPaymentStatus, NOTHING_UI_STRING } from 'poly-constants';

import { convertQueryDataToTransactions } from './bankRegistersQuery.js';
import { bankRegistersQueryDataPropType } from './commonPropTypes.js';
import {
  isMoneyTransactionReconciled,
  getMoneyTransactionAmount,
} from './helpers.js';
import { useOpenTransactionDetailsSidebar } from '../../sidebars/TransactionDetailsSidebar/useOpenTransactionDetailsSidebar.js';

// isTransactionVoided :: MoneyTransaction -> Boolean
const isTransactionVoided = R.propEq(
  'payment_status',
  JournalPaymentStatus.VOIDED,
);

const BankRegistersTableS = styled(WindowedTable)`
  thead {
    height: 45px;
  }

  tr {
    width: 100%;
    margin: 0;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 40px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 100px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 130px;
    display: ${({ hideCheckNumber }) => (hideCheckNumber ? 'none' : 'visible')};
  }

  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};
  ${moneyColumnStyles(7)};

  th:nth-child(8),
  td:nth-child(8) {
    width: 65px;
  }
`;

const BankRegistersPrintTableS = BankRegistersTableS.withComponent(Table);

// getReconciledCellValue :: MoneyTransaction -> String
const getReconciledCellValue = R.ifElse(
  isMoneyTransactionReconciled,
  () => <Icon name="checkMark" size={15} />,
  R.always(null),
);

// formatNetAmountByTransaction :: (String -> ReactNode, Boolean) -> {netAmount: Number} -> ReactNode
const formatNetAmountByTransaction = (highlightMatches, isPayment) =>
  R.compose(
    highlightMatches,
    R.ifElse(R.equals(0), R.always(''), formatTotal),
    getMoneyTransactionAmount(isPayment),
  );

const VoidedColorWrapperS = styled.span`
  color: ${getThemeColor(['secondaryMid'])};
`;

// formatDateByTransaction :: (String -> ReactNode) -> { date: Date } -> ReactNode
const formatDateByTransaction = (highlightMatches) =>
  R.compose(highlightMatches, formatDate, R.prop('date'));

const formatDateColumn = (highlightMatches) =>
  function (transaction) {
    const dateValue = formatDateByTransaction(highlightMatches)(transaction);

    if (isTransactionVoided(transaction)) {
      return <VoidedColorWrapperS>{dateValue}</VoidedColorWrapperS>;
    }

    return dateValue;
  };

const formatAmountColumn = (highlightMatches, isPayment) =>
  function (transaction) {
    const amountValue = formatNetAmountByTransaction(
      highlightMatches,
      isPayment,
    )(transaction);

    if (isTransactionVoided(transaction)) {
      return <VoidedColorWrapperS>{amountValue}</VoidedColorWrapperS>;
    }

    return amountValue;
  };

function TransactionLink({
  transactionNumber,
  transactionType,
  name,
  ...props
}) {
  const openTransactionDetailsSidebar = useOpenTransactionDetailsSidebar();

  const hasPermission = useHasUserAccessWithPermission(
    READ_JOURNAL_PAYMENT_BATCH_PERMISSION,
  );

  if (hasPermission) {
    return (
      <LinkButton
        onClick={() =>
          openTransactionDetailsSidebar(transactionNumber, transactionType)
        }
        {...props}
      >
        {name}
      </LinkButton>
    );
  }
  return name;
}

TransactionLink.propTypes = {
  transactionNumber: string.isRequired,
  transactionType: string.isRequired,
  name: string.isRequired,
};

const getBankRegistersTableConfig = (isCredit, searchTerm) => {
  const highlightMatches = highlightTextToReactElement(searchTerm);

  return [
    ['Clr', getReconciledCellValue],
    ['Date', formatDateColumn(highlightMatches)],
    ['Check Number', R.compose(highlightMatches, R.prop('reference_column'))],
    [
      'Payee',
      R.compose(highlightMatches, formatMoneyTransactionPayeeNameColumn),
    ],
    [
      isCredit ? 'Charge' : 'Payment',
      formatAmountColumn(highlightMatches, true),
    ],
    [
      isCredit ? 'Payment' : 'Deposit',
      formatAmountColumn(highlightMatches, false),
    ],
    [
      'Balance',
      // if some transactions are missing
      // (because of full text search filtering)
      // then current balance is incorrect
      searchTerm
        ? R.always(NOTHING_UI_STRING)
        : applyProp(formatTotal)('currentBalance'),
    ],
    [
      '',
      (transaction) => (
        <TransactionLink
          transactionType={transaction.type}
          transactionNumber={transaction._id}
          target="_blank"
          name="View"
        />
      ),
    ],
  ];
};

export function BankRegistersTable({
  accountId,
  data,
  isPrintPDF,
  hideCheckNumber,
  searchTerm,
  ...restTableOptions
}) {
  const transactions = convertQueryDataToTransactions(accountId)(data);

  const initialConfig = getBankRegistersTableConfig(
    hideCheckNumber,
    searchTerm,
  );
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    isPrintPDF ? R.init(initialConfig) : initialConfig,
    transactions,
  );

  const TableComponent = isPrintPDF
    ? BankRegistersPrintTableS
    : BankRegistersTableS;

  return (
    <TableComponent
      headers={headers}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      hideCheckNumber={hideCheckNumber}
      isPrintPDF={isPrintPDF}
      moreScrollPosition="0px"
      {...restTableOptions}
    />
  );
}

BankRegistersTable.propTypes = {
  accountId: string,
  searchTerm: string,
  data: bankRegistersQueryDataPropType,
  isPrintPDF: bool,
  hideCheckNumber: bool,
};
