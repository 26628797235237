import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { number, shape, string, bool } from 'prop-types';
import { CREATE_CONTACT_USER_PERMISSION } from 'poly-security';
import { Loader, Status } from 'poly-book-admin';
import { peopleStatuses } from 'poly-constants';
import {
  useHasUserAccessWithPermission,
  useOutSidebarContext,
} from 'poly-client-utils';
import {
  SidebarTabTableWrapper,
  peopleStatusesColors,
  SidebarTabToolbar,
  useSearchTabLogic,
  SidebarTabHeader,
  SearchInputComp,
  SidebarSubTabs,
  SidebarWidth,
  entities,
} from 'poly-admin-ui';
import { formatCount, forceTitleCase } from 'poly-utils';

import {
  SectionLabel,
  DoubleTabsWrapper,
  CommonSidebarSubEntityTable,
} from '../commonSidebarComponents.js';
import { SidebarIDs } from '../../constants.js';
import { useSearchSubTabsContactsQuery } from './useSearchSubTabsContactsQuery.js';
import { AddContactButton } from '../../ContactSidebar/forms/add/AddContactButton.js';

const ContactTabs = R.map(R.concat('contact-'), peopleStatuses);

const ContactsSubTabTableS = styled(CommonSidebarSubEntityTable)`
  th:last-child,
  td:last-child {
    width: 30px;
  }
`;

const DoubleTabsWrapperS = styled(DoubleTabsWrapper)`
  overflow-y: auto;
`;

// checkIsClientContact :: Entity -> Boolean
const checkIsClientContact = (entity) =>
  R.any(R.equals(entity.name), [entities.CLIENT, entities.PROPERTY]);

function CommonContactsTabTitle({ status, ...props }) {
  const { count } = useSearchSubTabsContactsQuery({ status, ...props });

  const title = `${forceTitleCase(status)} (${formatCount(count)})`;

  return <Status color={peopleStatusesColors[status]} text={title} />;
}

CommonContactsTabTitle.propTypes = { status: string.isRequired };

function CommonContactsTab(props) {
  const { loading, tableProps } = useSearchSubTabsContactsQuery(props);

  if (loading) return <Loader />;

  return <ContactsSubTabTableS {...tableProps} />;
}

export function SidebarContactsTab({
  tabTitlePrefix,
  tabWidth,
  entity,
  isCard,
}) {
  const { isSidebarOpened } = useOutSidebarContext();
  const { searchTerm, ...inputProps } = useSearchTabLogic('contacts');

  const hasPermissionToAddContact = useHasUserAccessWithPermission(
    CREATE_CONTACT_USER_PERMISSION,
  );

  const commonProps = { entity, searchTerm };

  const isBlurred = isSidebarOpened(SidebarIDs.contact);

  const titlePrefix = tabTitlePrefix || forceTitleCase(entity.name);

  const title = `${titlePrefix} Contacts`;

  return (
    <DoubleTabsWrapperS width={tabWidth} isCard={isCard} isBlurred={isBlurred}>
      <SidebarTabHeader justify>
        <SectionLabel>{title}</SectionLabel>
        {hasPermissionToAddContact && (
          <AddContactButton
            entity={entity}
            isCard={isCard}
            isClientContact={checkIsClientContact(entity)}
          />
        )}
      </SidebarTabHeader>
      <SidebarTabToolbar>
        <SearchInputComp {...inputProps} />
      </SidebarTabToolbar>
      <SidebarTabTableWrapper offset={96}>
        <SidebarSubTabs
          tabs={[
            [
              <CommonContactsTabTitle
                {...commonProps}
                status={peopleStatuses.ACTIVE}
              />,
              ContactTabs.ACTIVE,
              <CommonContactsTab
                {...commonProps}
                status={peopleStatuses.ACTIVE}
              />,
            ],
            [
              <CommonContactsTabTitle
                {...commonProps}
                status={peopleStatuses.INACTIVE}
              />,
              ContactTabs.INACTIVE,
              <CommonContactsTab
                {...commonProps}
                status={peopleStatuses.INACTIVE}
              />,
            ],
          ]}
          defaultValue={ContactTabs.ACTIVE}
        />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapperS>
  );
}

SidebarContactsTab.propTypes = {
  isCard: bool,
  tabWidth: number,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  tabTitlePrefix: string,
};

SidebarContactsTab.defaultProps = { tabWidth: SidebarWidth * 2 };
