import * as R from 'ramda';
import { bool, number, object } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Loader, BodyRow } from 'poly-book-admin';

import { ReceivePaymentsTable } from './ReceivePaymentsTable.js';
import { ReceiveClientPaymentsBottomForm } from './ReceiveClientPaymentsBottomForm.js';
import { LoadMoreInvoicesBtn } from '../../components/PaymentsFromComponents/LoadMoreBtn.js';
import { useWindowedTablePropsForCustomLoadButton } from '../EditClientPayments/useWindowedTablePropsForCustomLoadButton.js';

// getRowColorStyle :: Row -> String
const getRowColorStyle = R.ifElse(
  R.pathSatisfies(R.lt(R.__, 0), ['row', 'total']),
  R.always('red'),
  R.always('black'),
);

const BodyRowS = styled(BodyRow)`
  color: ${getRowColorStyle};
`;

export function ReceiveClientPaymentsForm({
  tableProps,
  virtualized,
  loading,
  pageSize,
}) {
  const { windowedTableProps, buttonProps } =
    useWindowedTablePropsForCustomLoadButton(tableProps);

  return loading ? (
    <Loader />
  ) : (
    <>
      <ReceivePaymentsTable
        {...windowedTableProps}
        virtualized={virtualized}
        footerHeight={170}
        TableFooter={ReceiveClientPaymentsBottomForm}
        footerProps={{ loading }}
        RowComponent={BodyRowS}
      />
      <LoadMoreInvoicesBtn offset={135} {...buttonProps} pageSize={pageSize} />
    </>
  );
}

ReceiveClientPaymentsForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tableProps: object,
  loading: bool,
  virtualized: bool,
  pageSize: number,
};
