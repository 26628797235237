import * as R from 'ramda';
import React, { useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { arrayOf, number, shape, string, bool } from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { UPDATE_PROPERTY_PERMISSION } from 'poly-security';

import { BidRulesTab } from '../ClientSidebar/financialTabs/ClientBidRulesTab/BidRulesTab.js';

const editPropertyBidRulesMutation = gql`
  mutation editPropertyBidRulesMutation(
    $id: ID!
    $input: UpdateClientBidRulesInput!
  ) {
    updatePropertyBidRules(id: $id, input: $input) {
      _id
    }
  }
`;

// getBidRulesByProperty :: { property: Property } -> [ClientBidRule]
const getBidRulesByProperty = R.pathOr([], ['property', 'bidRules']);

// getBidRulesByClient :: { property: Property } -> [ClientBidRule]
const getBidRulesByClient = R.pathOr([], ['property', 'client', 'bidRules']);

// getPropertyId :: { property: Property } -> ID
const getPropertyId = R.path(['property', '_id']);

// checkBidRulesInheritedFromClient :: { property: Property } -> Boolean
const checkBidRulesInheritedFromClient = R.compose(
  R.isEmpty,
  R.reject(R.propEq('isRuleActive', false)),
  getBidRulesByProperty,
);

export function PropertyBidRulesTab(props) {
  const [editPropertyBidRules] = useMutation(editPropertyBidRulesMutation);
  const hasAccess = useHasUserAccessWithPermission(UPDATE_PROPERTY_PERMISSION);

  const propertyId = useMemo(() => getPropertyId(props), [props]);

  const isBidRulesInheritedFromClient = useMemo(
    () => checkBidRulesInheritedFromClient(props),
    [props],
  );

  const onSubmit = async (input) => {
    await editPropertyBidRules({ variables: { id: propertyId, input } });
  };

  const tabProps = {
    onSubmit,
    hasAccess,
    bidRules: isBidRulesInheritedFromClient
      ? getBidRulesByClient(props)
      : getBidRulesByProperty(props),
    rulesInheritedWarning: isBidRulesInheritedFromClient,
  };

  return <BidRulesTab {...tabProps} />;
}

const bidRulePropTypes = {
  type: string.isRequired,
  label: string.isRequired,
  amount: number.isRequired,
  isRuleActive: bool.isRequired,
};

PropertyBidRulesTab.propTypes = {
  property: shape({
    _id: string.isRequired,
    bidRules: arrayOf(shape(bidRulePropTypes)),
    client: shape({
      _id: string.isRequired,
      bidRules: arrayOf(shape(bidRulePropTypes)),
    }),
  }),
};
