import React from 'react';
import { LinkButton } from 'poly-book-admin';
import { useMutation, gql } from '@apollo/client';
import { useRouterQuery } from 'poly-client-routing';
import { useModalContext, useNotificationState } from 'poly-admin-ui';

import { userAlertsPopupTabs } from '../../../routes/constants.js';
import { useAlertsNumber } from './AlertButton.js';

const ARCHIVE_ALL_ALERTS_MUTATION = gql`
  mutation ARCHIVE_ALL_ALERTS_MUTATION {
    archiveAllAlerts {
      success
    }
  }
`;

export function ArchiveAllBtn() {
  const { openConfirmModal } = useModalContext();

  const [archiveAllAlerts] = useMutation(ARCHIVE_ALL_ALERTS_MUTATION);
  const { showSuccessNotification } = useNotificationState();

  const { sidebarTab } = useRouterQuery(['sidebarTab']);
  const alertsNumber = useAlertsNumber(false);

  const onArchiveAll = async () => {
    await archiveAllAlerts();
    showSuccessNotification('All alerts were archived');
  };

  const onOpenConfirmModal = () =>
    openConfirmModal({
      cancelBtnCaption: 'No',
      btnCaption: 'Yes',
      id: 'confirmArchiveAllModal',
      content: 'Are you sure you want to archive all alerts?',
      onConfirm: (closeConfirmModal) => () => {
        closeConfirmModal();
        onArchiveAll();
      },
    });

  if (
    sidebarTab === userAlertsPopupTabs.userAlertsPopupArchived ||
    !alertsNumber
  ) {
    return null;
  }

  return <LinkButton onClick={onOpenConfirmModal}>Archive All</LinkButton>;
}
