import * as R from 'ramda';
import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { LinkButtonWithLoader } from 'poly-book-admin';
import { useNotificationState } from 'poly-admin-ui';
import { convertCentsToDollars, formatDate, isNilOrEmpty } from 'poly-utils';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';

import { apAgingReportQuery } from './apAgingReportQuery.js';
import {
  prepareInvoicesForTable,
  getDaysFromNowByProp,
  getTotalByDaysPart,
} from './APAgingReportTableComp.js';

// xlsInvoicesTotalByMapper :: [Invoice] -> Function -> String
const invoicesTotalByMapper = (mapper) =>
  R.compose(R.reduce(R.add, 0), R.reject(isNilOrEmpty), R.map(mapper));

// prepareReportInvoices :: [APAgingReportSupplier] -> [Invoice]
const prepareReportInvoices = R.compose(
  R.flatten,
  R.map(
    R.compose(prepareInvoicesForTable({ name: '' }), R.propOr([], 'invoices')),
  ),
);

// getAPAgingReportRowConfig :: Array -> [ExcelExportDataCell]
const getAPAgingReportRowConfig = R.zipWith(createExcelExportCell, [
  ...R.repeat(ExcelExportCellType.default, 5),
  ...R.repeat(ExcelExportCellType.defaultCurrency, 5),
  ...R.repeat(ExcelExportCellType.default, 2),
]);

// getTotalByDaysPartWithConvertToDollars :: (Number, Number) -> Invoice -> Number
const getTotalByDaysPartWithConvertToDollars = (from, to) =>
  R.compose(convertCentsToDollars, getTotalByDaysPart(from, to));

// getInvoiceRowConfig :: Invoice -> [ExcelExportDataCell]
const getInvoiceRowConfig = R.compose(
  getAPAgingReportRowConfig,
  R.juxt([
    R.prop('invoiceNumber'),
    R.compose(formatDate, R.prop('invoiceDate')),
    R.path(['project', 'projectId']),
    R.compose(formatDate, R.prop('paymentDueDate')),
    getDaysFromNowByProp('invoiceDate'),
    getTotalByDaysPartWithConvertToDollars(-1, 30),
    getTotalByDaysPartWithConvertToDollars(30, 60),
    getTotalByDaysPartWithConvertToDollars(60, 90),
    getTotalByDaysPartWithConvertToDollars(90, Infinity),
    R.compose(convertCentsToDollars, R.prop('total')),
    R.compose(formatDate, R.prop('dateInvoiced')),
    R.compose(formatDate, R.prop('paymentReceived')),
  ]),
);

// getCompanyNameRow :: String -> [ExcelExportDataCell]
const getCompanyNameRow = (companyName) => [
  {
    value: companyName,
    cellType: ExcelExportCellType.subTitleRow,
  },
];

const reportHeaderRow = R.zipWith(
  createExcelExportCell,
  [
    ...R.repeat(ExcelExportCellType.tableHeader, 5),
    ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 5),
    ...R.repeat(ExcelExportCellType.tableHeader, 2),
  ],
  [
    'Invoice #',
    'Invoice Date',
    'Project #',
    'Due Date',
    'Age',
    'Current',
    '>30 Days',
    '>60 Days',
    '>90 Days',
    'Total',
    'Date Invoiced',
    'Pmt. Rec`d',
  ],
);

// getFooterRowConfig :: Array -> [ExcelExportDataCell]
const getFooterRowConfig = R.zipWith(createExcelExportCell, [
  ...R.repeat(ExcelExportCellType.tableStringFooter, 4),
  ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 5),
  ...R.repeat(ExcelExportCellType.tableStringFooter, 2),
]);

// invoicesTotalByMapperWithConvertToDollars :: (Number, Number) -> [Invoice] -> Number
const invoicesTotalByMapperWithConvertToDollars = (from, to) =>
  R.compose(
    convertCentsToDollars,
    invoicesTotalByMapper(getTotalByDaysPart(from, to)),
  );

// getTotalRowConfig :: [Invoice] -> [ExcelExportDataCell]
const getTotalRowConfig = (companyName) =>
  R.compose(
    getFooterRowConfig,
    R.unnest,
    R.juxt([
      R.always(`Total ${companyName}`),
      R.always(R.repeat('', 3)),
      invoicesTotalByMapperWithConvertToDollars(-1, 30),
      invoicesTotalByMapperWithConvertToDollars(30, 60),
      invoicesTotalByMapperWithConvertToDollars(60, 90),
      invoicesTotalByMapperWithConvertToDollars(90, Infinity),
      R.compose(convertCentsToDollars, invoicesTotalByMapper(R.prop('total'))),
      R.always(R.repeat('', 2)),
    ]),
  );

//  getCompanyReportRows :: ({String , [Invoice]}) -> [ExcelExportDataCell]
const getCompanyReportRows = ({ company, invoices }) => {
  const preparedInvoices = prepareInvoicesForTable(company)(invoices);
  return [
    getCompanyNameRow(company.name),
    reportHeaderRow,
    ...R.map(getInvoiceRowConfig, preparedInvoices),
    getTotalRowConfig(company.name)(preparedInvoices),
  ];
};

// getReportTotalRowConfig :: [ReportData] -> ExcelExportDataCell]
const getReportTotalRowConfig = R.compose(
  getTotalRowConfig(''),
  prepareReportInvoices,
);

// eslint-disable-next-line import/no-unused-modules
export const getAPAgingReportExportExcelConfig = ({ title, reportData }) => ({
  exportFileName: 'ap-aging-report.xlsx',
  columnWidths: [15, 15, 15, 15, 5, 15, 15, 15, 15, 15, 15, 15],
  rows: [
    [
      {
        value: title,
        cellType: ExcelExportCellType.title,
      },
    ],
    ...R.compose(R.unnest, R.map(getCompanyReportRows))(reportData),
    [
      {
        value: '',
        cellType: ExcelExportCellType.default,
      },
    ],
    getReportTotalRowConfig(reportData),
  ],
});

// eslint-disable-next-line import/no-unused-modules
export const getReportData = R.pathOr([], ['apAgingReport', 'hits']);

export function APAgingReportExportXLBtn({ title, query }) {
  const { showErrorNotification } = useNotificationState();
  const [isLoading, setIsLoading] = useState(false);

  const apolloClient = useApolloClient();

  const onClick = async () => {
    if (!query) {
      showErrorNotification('No report records to export');
      return;
    }
    setIsLoading(true);
    const { data } = await apolloClient.query({
      query: apAgingReportQuery,
      variables: { input: query },
      fetchPolicy: 'network-only',
    });
    const excelConfig = getAPAgingReportExportExcelConfig({
      title,
      reportData: getReportData(data),
    });
    performExcelExport(excelConfig);
    setIsLoading(false);
  };

  return (
    <LinkButtonWithLoader onClick={onClick} loader={isLoading}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}
APAgingReportExportXLBtn.propTypes = {
  title: string.isRequired,
  query: shape({
    supplierId: string,
    country: string,
  }),
};
