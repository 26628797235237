import * as R from 'ramda';
import { func } from 'prop-types';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOutSidebarContext } from 'poly-client-utils';
import { FileViewer, Text } from 'poly-book-admin';
import {
  useUpdateQueryParams,
  useClearQueryParams,
  useRouterQuery,
} from 'poly-client-routing';

import { SidebarIDs } from '../../../sidebars/constants.js';
import { ApproveSupplierInvoiceSidebarTabs } from './ApproveSupplierInvoiceSidebarTabs.js';
import { PreviewDocumentsNavigation } from '../../../components/PreviewDocumentsNavigation.js';
import { useApproveInvoiceQuery } from '../../../sidebars/ProjectSidebar/forms/ApproveSupplierInvoiceForm.js';
import { ApproveStandaloneSupplierInvoiceSidebarTabs } from './ApproveStandaloneSupplierInvoiceSidebarTabs.js';

const formId = 'approveSupplierInvoiceFormId';

const InvoicePreviewSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: relative;
  width: 500px;
  @media (min-width: 1200px) {
    width: 700px;
  }
  @media (min-width: 1400px) {
    width: 900px;
  }
`;

// getPreviewInvoiceInfo :: Invoice -> PreviewInvoiceFileDataObject
const getPreviewInvoiceInfo = R.applySpec({
  invoiceNumber: R.prop('invoiceNumber'),
  projectId: R.path(['project', 'projectId']),
  supplier: R.path(['supplier', 'company', 'name']),
  fileUrl: R.path(['invoiceFile', 'url']),
  fileType: R.path(['invoiceFile', 'fileType']),
});

const InvoicePreviewTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

const InvoiceViewer = styled(FileViewer)`
  width: 100%;
  height: 100%;
`;

const SidebarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  min-width: 550px;
  height: 100%;
  border-left: 1px solid #ebebeb;
`;

function ApproveSupplierInvoiceSidebar({ refetch }) {
  const { [SidebarIDs.approveSupplierInvoice]: invoiceId } = useRouterQuery([
    SidebarIDs.approveSupplierInvoice,
  ]);
  const invoicesIds = useSelector(R.propOr([], 'previewIds'));
  const clearQueryParams = useClearQueryParams();

  const { loading, invoice } = useApproveInvoiceQuery(invoiceId);

  const { invoiceNumber, fileUrl, fileType, projectId, supplier } =
    getPreviewInvoiceInfo(invoice);

  useEffect(() => {
    if (!invoicesIds.includes(invoiceId)) {
      clearQueryParams();
    }
  }, []);

  useEffect(() => {
    if (!loading && R.isEmpty(invoice) && refetch) {
      refetch();
    }
  }, [invoice, loading]);

  return invoiceId ? (
    <SidebarContainer>
      <InvoicePreviewSidebarContainer>
        {loading ? null : (
          <>
            <InvoicePreviewTitleContainer>
              <Text size="16px">
                {supplier} - {projectId} - {invoiceNumber}
              </Text>
              <PreviewDocumentsNavigation
                documentIdKey={SidebarIDs.approveSupplierInvoice}
              />
            </InvoicePreviewTitleContainer>
            {fileUrl ? (
              <InvoiceViewer url={fileUrl} fileType={fileType} />
            ) : (
              'No Invoice file attached'
            )}
          </>
        )}
      </InvoicePreviewSidebarContainer>
      <FormContainer>
        {projectId && invoice?._id && (
          <ApproveSupplierInvoiceSidebarTabs
            formId={formId}
            refetch={refetch}
            invoiceId={invoiceId}
            projectId={projectId}
          />
        )}
        {!projectId && invoice?._id && (
          <ApproveStandaloneSupplierInvoiceSidebarTabs
            refetch={refetch}
            invoiceId={invoiceId}
          />
        )}
      </FormContainer>
    </SidebarContainer>
  ) : null;
}

ApproveSupplierInvoiceSidebar.propTypes = { refetch: func };

export const useOpenApproveSupplierInvoiceSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();
  const updateQueryParams = useUpdateQueryParams();

  return ({ _id, refetch } = {}) => {
    updateQueryParams({
      sidebarTab: undefined,
      ...(_id ? { [SidebarIDs.approveSupplierInvoice]: _id } : {}),
    });
    openOutSidebar({
      id: SidebarIDs.approveSupplierInvoice,
      content: <ApproveSupplierInvoiceSidebar refetch={refetch} />,
      alwaysFirst: true,
    });
  };
};
