import styled, { css } from 'styled-components';
import { getThemeColor, moneyColumnStyles, Table } from 'poly-book-admin';

import { PayInvoicesTableCard } from '../PaySuppliersPage/PaySuppliersComponents.js';

export const AgingReportFooterContainer = styled.tr`
  padding: 13px 5px;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  background-color: ${getThemeColor(['lighter'])} !important;

  ${moneyColumnStyles(2)};
  ${moneyColumnStyles(3)};
  ${moneyColumnStyles(4)};
  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};
`;

export const AgingReportTableLabelRow = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  background-color: ${getThemeColor(['accent2Lighter5'])};
`;

export const AgingReportInvoicesTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const commonAgingReportInvoicesTableStyles = css`
  thead {
    tr {
      background-color: ${getThemeColor(['lighter'])} !important;
    }
    th {
      font-weight: 600;
    }
  }

  tbody {
    overflow: visible;
  }

  tr {
    border-left: none;
    border-right: none;

    :nth-child(even) {
      background-color: ${getThemeColor(['table', 'rowBg'])} !important;
    }
  }

  tr:first-child {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }

  td {
    padding-left: 5px !important;

    :first-child {
      padding-left: 10px !important;
    }
  }
`;

export const AgingReportTableCard = styled(PayInvoicesTableCard)`
  > div {
    bottom: 30px;
    border-radius: 5px;
  }
`;

export const AgingReportTableS = styled(Table)`
  tr {
    border-top: none;
    background-color: ${getThemeColor(['white'])};
    width: 100%;
    margin: 0;
  }

  td {
    padding: 0;

    :first-child {
      padding-left: 0;
    }
  }

  @media print {
    display: block !important;
  }
`;

export const commonAgingReportPrintTableStyles = css`
  tr,
  td {
    border: none !important;
  }

  > tfoot {
    margin-top: 30px;

    tr {
      background-color: ${getThemeColor(['lighter'])} !important;
    }
  }
`;
