import React from 'react';
import { string } from 'prop-types';

import { Status } from 'poly-book-admin/src/Status/index.js';
import { defaultTheme } from 'poly-book-admin/src/ThemeProvider/index.js';
import { useApproveInvoices } from './useApproveInvoices.js';
import { ApprovePostSupplierInvoicesTabsMap } from './constants.js';

const { TO_REVIEW, SUBMITTED_BY_ME, ALL } = ApprovePostSupplierInvoicesTabsMap;

const {
  colors: {
    statuses: { active, pending },
  },
} = defaultTheme;

const tabColorMap = {
  [TO_REVIEW]: active,
  [SUBMITTED_BY_ME]: pending,
  [ALL]: null,
};

const tabTextMap = {
  [TO_REVIEW]: 'To Review',
  [SUBMITTED_BY_ME]: 'Submitted by me',
  [ALL]: 'All',
};

export function ApproveSupplierInvoicesPageTabTitle({
  excludeUserId,
  includeUserId,
  userId,
  tab,
}) {
  const tabColor = tabColorMap[tab];
  const tabText = tabTextMap[tab];

  const { total } = useApproveInvoices({
    userId,
    includeUserId,
    excludeUserId,
  });

  const textWithUsersCount = `${tabText} (${total})`;
  return <Status text={textWithUsersCount} color={tabColor} />;
}

ApproveSupplierInvoicesPageTabTitle.propTypes = {
  tab: string.isRequired,
  userId: string,
  excludeUserId: string,
  includeUserId: string,
};
