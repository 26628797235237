import React, { useContext } from 'react';
import { bool, node, string } from 'prop-types';
import { Loader, DefaultBodyWrapper, TableCard } from 'poly-book-admin';

import { PageWithSearchHeaderContext } from './PageWithSearchHeader.js';

export function ReportPageTableWrapper({ loading, children, className }) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  if (loading) {
    return <Loader />;
  }

  return (
    <DefaultBodyWrapper height={contentHeight} className={className}>
      <TableCard>{children}</TableCard>
    </DefaultBodyWrapper>
  );
}

ReportPageTableWrapper.propTypes = {
  loading: bool,
  children: node,
  className: string,
};
