import * as R from 'ramda';
import React from 'react';
import { assocBy, applySpecWithFields } from 'poly-utils';
import { usePristineSubscribe } from 'poly-client-routing';
import { closeCurrentModal } from 'poly-admin-ui';
import { MentionGroupFromWithButtons } from './MentionGroupFromWithButtons.js';
import { useUpdateMentionGroupMutation } from '../../core/hooks/mentionGroups/index.js';

// mentionGroupToUpdateInput :: MentionGroup -> {id: ID, input: UpdateMentionGroupInput}
const mentionGroupToUpdateInput = R.converge(R.mergeRight, [
  R.compose(
    R.objOf('input'),
    R.omit(['_id', 'permissions']),
    assocBy(
      'usersIds',
      R.compose(R.map(R.prop('value')), R.defaultTo([]), R.prop('usersIds')),
    ),
    applySpecWithFields({
      description: R.propOr('', 'description'),
      clientId: R.propOr(null, 'clientId'),
    }),
  ),
  R.compose(R.objOf('id'), R.prop('_id')),
]);

// getTitleByProps :: Object -> Object
const getTitleByProps = R.compose(
  R.mergeRight({
    submitCaption: 'Update Group',
    successMessage: 'Mention group successfully updated',
    formDataToMutationVariables: mentionGroupToUpdateInput,
  }),
  R.objOf('title'),
  R.concat('Edit - '),
  R.path(['payload', 'name']),
);

export function UpdateMentionGroupForm(props) {
  const titleProps = getTitleByProps(props);

  const { mutate, result } = useUpdateMentionGroupMutation();

  const subscriptionProps = usePristineSubscribe();

  return (
    <MentionGroupFromWithButtons
      {...props}
      {...titleProps}
      {...subscriptionProps}
      result={result}
      mutate={mutate}
      closeCurrentModal={closeCurrentModal}
    />
  );
}

UpdateMentionGroupForm.displayName = 'UpdateMentionGroupForm';
