import React from 'react';
import { Select } from 'poly-book-admin';
import { RecurringJournalStatuses } from 'poly-constants';
import { onKeyDownToPreventFormSubmit } from '../../utils/form.js';

const recurringJournalStatusOptions = [
  { value: RecurringJournalStatuses.active, label: 'Active' },
  { value: RecurringJournalStatuses.inactive, label: 'Inactive' },
];

export function RecurringJournalStatusSelector(props) {
  const selectProps = {
    ...props,
    options: recurringJournalStatusOptions,
    onKeyDown: onKeyDownToPreventFormSubmit,
  };

  return <Select {...selectProps} />;
}
