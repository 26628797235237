import React from 'react';
import { startOfYear } from 'date-fns';
import { alwaysNewDate } from 'poly-utils';
import { ClientSelect } from 'poly-admin-ui';
import { bool, func } from 'prop-types';
import { DateRangePicker, NumberInputHTML5 } from 'poly-book-admin';
import styled from 'styled-components';

import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { JustifyFlexEnd } from '../../components/FlexContainer.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { searchClientInvoicesFilters } from './constants.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';

export const searchClientInvoicesPageHeaderConfig = [
  {
    name: searchClientInvoicesFilters.clientId,
    defaultValue: '',
  },
  {
    name: searchClientInvoicesFilters.invoiceDate,
    nestedFields: ['startDate', 'endDate'],
    defaultValue: {
      startDate: startOfYear(alwaysNewDate()).toDateString(),
      endDate: alwaysNewDate().toDateString(),
    },
  },
  {
    name: searchClientInvoicesFilters.invoiceNumber,
    defaultValue: '',
  },
  {
    name: searchClientInvoicesFilters.projectId,
    defaultValue: '',
  },
];

export const EmptyColumnS = styled.div`
  width: 390px;
`;

export function SearchClientInvoicesPageHeader({ setFilters, loading }) {
  const { searchFilters, onReset, handlers } = useSearchFilters(
    searchClientInvoicesPageHeaderConfig,
  );

  const onResetSearch = () => {
    onReset();
    setFilters(null);
  };
  const onSearch = () => setFilters(searchFilters);

  return (
    <SearchPageHeaderContainer title="Edit Client Invoice">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn titleWidth="120px" title="Client">
            <ClientSelect
              value={searchFilters.clientId}
              onChange={handlers.clientId}
              includeAllOption={false}
              width="100%"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="120px" title="Date">
            <DateRangePicker
              value={searchFilters.invoiceDate}
              onChange={handlers.invoiceDate}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="390px">
            <JustifyFlexEnd>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </JustifyFlexEnd>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn titleWidth="120px" title="Invoice #">
            <NumberInputHTML5
              width="100%"
              onChange={handlers.invoiceNumber}
              value={searchFilters.invoiceNumber}
              name="InvoiceNumber"
              maxLength={9}
              changeOnBlur
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="120px" title="Project #">
            <ProjectSelect
              width="100%"
              onChange={handlers.projectId}
              value={searchFilters.projectId}
            />
          </SearchHeaderColumn>
          <EmptyColumnS />
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

SearchClientInvoicesPageHeader.propTypes = {
  setFilters: func.isRequired,
  loading: bool.isRequired,
};
