import * as R from 'ramda';
import React from 'react';
import { assocBy } from 'poly-utils';

import { ProjectSidebarSuppliers } from '../ProjectSidebar/ProjectSidebarSuppliers.js';

// prepareRecurringProjectSuppliers :: Project -> [ProjectSupplier]
const prepareRecurringProjectSuppliers = R.compose(
  R.sortBy(R.path(['company', 'name'])),
  R.flatten,
  R.juxt([R.prop('addedSuppliers'), R.prop('suppliers')]),
);

// prepareProps :: { project: RecurringProject } -> { project: RecurringProject }
const prepareProps = R.over(
  R.lensProp('project'),
  assocBy('suppliers', prepareRecurringProjectSuppliers),
);

export function MasterRecurringProjectSuppliers(props) {
  const preparedProps = prepareProps(props);

  return (
    <ProjectSidebarSuppliers
      {...preparedProps}
      skipAssignSupplierButton
      isRecurringProject
    />
  );
}
