import React from 'react';
import { string, shape } from 'prop-types';
import { SidebarRow } from 'poly-admin-ui';

import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapperWithoutBorder,
} from '../components/commonSidebarComponents.js';
import { getSectionDivision } from '../components/commonSidebarSectionFormatters.js';
import {
  ClientGLCode,
  getProjectClientGLCode,
  isProjectClientGlCodeEnabled,
} from '../ProjectSidebar/ProjectSidebarFinancial.js';

function GLCode({ clientGLCode }) {
  return <ClientGLCode glCode={clientGLCode} />;
}

GLCode.propTypes = {
  clientGLCode: shape({
    _id: string,
    code: string,
    name: string,
  }),
};
export function MasterRecurringProjectSidebarFinancial({ project }) {
  const isGlCodeEnabled = isProjectClientGlCodeEnabled(project);
  const clientGLCode = getProjectClientGLCode(project);

  return (
    <SectionWrapperWithoutBorder>
      <SidebarRow>
        <SectionLabel>Financial</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          id="project-division"
          label="Division"
          Component={getSectionDivision(project)}
          width="50%"
        />
        {isGlCodeEnabled && (
          <BlockWithLabel
            id="client-gl-code"
            label="Client GL Code"
            clientGLCode={clientGLCode}
            Component={GLCode}
          />
        )}
      </SidebarRow>
    </SectionWrapperWithoutBorder>
  );
}

MasterRecurringProjectSidebarFinancial.propTypes = {
  project: shape({ _id: string.isRequired }),
};
