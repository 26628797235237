import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useMemo, useState, useEffect } from 'react';
import { MAKE_INVOICED_NON_BILLABLE_PROJECT_PERMISSION } from 'poly-security';
import { applySpecWithFields } from 'poly-utils';
import {
  useHasUserAccessWithPermission,
  useReactiveQuery,
  pathOrNothingUI,
} from 'poly-client-utils';
import {
  InvoicesStatuses,
  taskActionTypes,
  WorkOrderStatus,
  taskCollections,
  ProjectType,
  collectionNames,
} from 'poly-constants';

import { TaskDueDate } from './TaskDueDate.js';
import { TaskMoreButton } from './TaskMoreButton.js';
import { MAX_ITEMS } from '../../../constants/general.js';
import { TaskCompleteButton } from './TaskCompleteButton.js';
import { ENTITY_TASKS } from '../../../hocs/tasks/queries.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';
import {
  getTasksSubscriptionOptionsByQueryInput,
  TASK_SEARCH_CHANGED,
} from '../../../hocs/tasks/subscriptions.js';

export const PROJECT_INVOICES = gql`
  query PROJECT_INVOICES($id: ID) {
    project(id: $id) {
      _id
      status
      endDate
      startDate
      workCompletionDate
      weeklyServiceTickets {
        week
        hours
        status
        extraHours
        attachment {
          url
          fileName
          createdAt
          createdBy {
            _id
            fullName
          }
        }
      }
      invoices {
        _id
        total
        status
        invoiceNumber
        isOwnSupplierInvoice
        supplier {
          _id
        }
      }
      total
      nonBillable
    }
  }
`;

export const INVOICES_BY_SEARCH_SUB = gql`
  subscription INVOICES_BY_SEARCH_SUB($searchInput: CollectionSearchParams!) {
    searchInvoiceChanged(input: $searchInput) {
      id
      type
    }
  }
`;

export const PROJECT_DETAILS_SUB = gql`
  subscription PROJECT_DETAILS_SUB($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
    }
  }
`;

// isRowDisabled :: Task -> Boolean
const isRowDisabled = R.either(
  R.prop('complete'),
  R.propEq('projectStatus', WorkOrderStatus.BLOCKED),
);

const tasksOrder = {
  [taskActionTypes.CONFIRM_SCOPE]: 1,
  [taskActionTypes.SUPPLIER_ASSIGN]: 2,
  [taskActionTypes.SUPPLIER_SCHEDULING]: 3,
  [taskActionTypes.SUPPLIER_FOLLOWUP]: 4,
  [taskActionTypes.CONFIRM_SATISFACTION]: 5,
  [taskActionTypes.PROJECT_COMPLETE]: 6,
  [taskActionTypes.SUPPLIER_INVOICE]: 7,
  [taskActionTypes.REPORT_ONLY_CLIENT_INVOICE]: 8,
  [taskActionTypes.CLOSE_PROJECT]: 9,
  [taskActionTypes.RECALL_PROJECT]: 10,
};

// getTaskOrder :: Task -> Number
const getTaskOrder = R.compose(R.prop(R.__, tasksOrder), R.prop('action'));

// sortTasks :: [Task] -> [Task]
const sortTasks = R.sort(
  (task1, task2) => getTaskOrder(task1) - getTaskOrder(task2),
);

// mapRowIndexForTasks :: [Task] -> [Task]
const mapRowIndexForTasks = (tasks) =>
  tasks.map((task, index) => ({ ...task, rowIndex: index + 1 }));

// prepareTasksData :: (Boolean, Boolean) -> TasksSearchResult -> [Task]
// eslint-disable-next-line import/no-unused-modules
export const prepareTasksData = (onlyActive, isCard) =>
  R.compose(
    mapRowIndexForTasks,
    R.map(
      applySpecWithFields({
        taskId: R.prop('_id'),
        _id: R.compose(
          R.concat('task_form_row_'),
          R.join('_'),
          R.reject(R.isEmpty),
          R.juxt([
            R.prop('documentId'),
            R.prop('_id'),
            R.always(isCard ? 'card' : ''),
          ]),
        ),
      }),
    ),
    R.when(() => !!onlyActive, R.filter(R.complement(R.prop('complete')))),
    R.converge(R.concat, [
      R.compose(sortTasks, R.filter(R.complement(R.prop('complete')))),
      R.compose(
        R.reverse,
        R.sortBy(R.prop('completedAt')),
        R.filter(R.prop('complete')),
      ),
    ]),
    R.pathOr([], ['tasks', 'hits']),
  );

// getTaskDueDate :: Task -> String
const getTaskDueDate = R.cond([
  [isRowDisabled, R.always(null)],
  [
    R.both(
      R.propEq('action', taskActionTypes.SUPPLIER_INVOICE),
      R.pathEq(['supplierInvoice', 'status'], InvoicesStatuses.REQUESTED),
    ),
    R.always('Requested'),
  ],
  [
    R.both(
      R.propEq('action', taskActionTypes.SUPPLIER_INVOICE),
      R.pathEq(['supplierInvoice', 'status'], InvoicesStatuses.RECEIVED),
    ),
    R.always('Received'),
  ],
  [
    R.both(
      R.propEq('action', taskActionTypes.SUPPLIER_INVOICE),
      R.pathEq(
        ['supplierInvoice', 'status'],
        InvoicesStatuses.MANUAL_FOLLOW_UP,
      ),
    ),
    R.always('Manual Follow Up'),
  ],
  [R.T, R.compose(TaskDueDate, R.prop('dueDate'))],
]);

// isAllowedToRemoveSupplierInvoiceTask :: TasksSearchResult -> Boolean
const isAllowedToRemoveSupplierInvoiceTask = R.compose(
  R.gt(R.__, 1),
  R.length,
  R.filter(R.propEq('action', taskActionTypes.SUPPLIER_INVOICE)),
  R.pathOr([], ['tasks', 'hits']),
);

const tasksTableConfig = [
  ['Complete', TaskCompleteButton],
  ['Assigned', pathOrNothingUI(['manager', 'fullName'])],
  ['Due Date', getTaskDueDate],
  ['', TaskMoreButton],
];

const tasksQuerySearchParams = (documentId, collection) => ({
  collection,
  documentId,
  from: 0,
  size: MAX_ITEMS,
  sort: { createdAt: 1 },
});

const entityTasksCacheParams = (documentId, collection) => ({
  query: ENTITY_TASKS,
  variables: {
    input: tasksQuerySearchParams(documentId, collection),
  },
  path: ['tasks', 'hits'],
});

// getSkipProjectDetailsQuery :: {project: Project} -> Boolean
const getSkipProjectDetailsQuery = R.compose(
  R.complement(R.equals(ProjectType.HOUSEKEEPING)),
  R.path(['project', 'type']),
);

// checkIfIsNonBillable :: ProjectQueryResult -> Boolean
const checkIfIsNonBillable = R.pathEq(['project', 'nonBillable'], true);

export const useTasksTabLogic = (
  { collection, entity, additional, isHQProject, isCard },
  onlyActive,
  readOnly,
) => {
  const [isSupplierInvoiceTaskDisabled, setIsSupplierInvoiceTaskDisabled] =
    useState(false);

  const hasNonBillablePermission = useHasUserAccessWithPermission(
    MAKE_INVOICED_NON_BILLABLE_PROJECT_PERMISSION,
  );

  const queryOptions = {
    variables: {
      input: tasksQuerySearchParams(entity._id, collection),
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  };

  const isProjectEntity = collection === collectionNames.projects;
  const taskSubscription = isProjectEntity
    ? [TASK_SEARCH_CHANGED, PROJECT_DETAILS_SUB, INVOICES_BY_SEARCH_SUB]
    : TASK_SEARCH_CHANGED;
  const taskSubscriptionOptions = getTasksSubscriptionOptionsByQueryInput(
    queryOptions.variables.input,
  );
  const projectSubscriptionOptions = {
    variables: { input: { id: entity._id } },
    skip: !entity._id,
  };

  const invoiceSubscriptionOptions = {
    variables: {
      searchInput: { query: { term: { documentId: entity._id } } },
    },
    skip: !entity._id,
  };
  const subscriptionOptionsByEntity = isProjectEntity
    ? [
        taskSubscriptionOptions,
        projectSubscriptionOptions,
        invoiceSubscriptionOptions,
      ]
    : taskSubscriptionOptions;

  const { data } = useReactiveQuery(ENTITY_TASKS, taskSubscription, {
    queryOptions,
    subscriptionOptions: subscriptionOptionsByEntity,
    refetchDebounce: 1000,
  });

  const skipProjectInvoicesQuery = collection !== taskCollections.PROJECTS;

  const skipProjectDetailsQuery = getSkipProjectDetailsQuery(additional);

  const projectInvoicesQueryOptions = {
    variables: { id: entity._id },
    skip: skipProjectInvoicesQuery,
    fetchPolicy: 'network-only',
  };

  const invoicesSubOptions = {
    variables: {
      searchInput: {
        size: MAX_ITEMS,
        query: {
          bool: {
            must: [{ match: { documentId: entity._id } }],
          },
        },
      },
    },
    skip: skipProjectInvoicesQuery,
  };

  const { data: projectData } = useReactiveQuery(
    PROJECT_INVOICES,
    [INVOICES_BY_SEARCH_SUB, PROJECT_DETAILS_SUB],
    {
      queryOptions: projectInvoicesQueryOptions,
      subscriptionOptions: [
        invoicesSubOptions,
        {
          variables: { input: { id: entity._id } },
          skip: skipProjectDetailsQuery,
        },
      ],
    },
  );

  useEffect(() => {
    const isNonBillable = checkIfIsNonBillable(projectData);
    const shouldDisableSupplierInvoiceTask =
      isNonBillable && !hasNonBillablePermission;

    if (shouldDisableSupplierInvoiceTask) {
      setIsSupplierInvoiceTaskDisabled(true);
    }

    if (isSupplierInvoiceTaskDisabled && !shouldDisableSupplierInvoiceTask) {
      setIsSupplierInvoiceTaskDisabled(false);
    }
  }, [projectData, hasNonBillablePermission, isSupplierInvoiceTaskDisabled]);

  const rowAdditionalMemo = useMemo(
    () => R.mergeDeepRight(additional, R.defaultTo({}, projectData)),
    [projectData],
  );

  const tableDataMemo = useMemo(
    () => prepareTasksData(onlyActive, isCard)(data),
    [data],
  );

  const readFromCacheParams = entityTasksCacheParams(entity._id, collection);

  const allowedRemoveSupplierInvoiceTask =
    isAllowedToRemoveSupplierInvoiceTask(data);

  const tableProps = useMapConfigToTableProps(
    R.map(
      R.mergeDeepLeft({
        entity,
        isHQProject,
        collection,
        readFromCacheParams,
        withHash: !onlyActive,
        isSupplierInvoiceTaskDisabled,
        setIsSupplierInvoiceTaskDisabled,
        readOnly,
        allowedRemoveSupplierInvoiceTask,
        ...rowAdditionalMemo,
      }),
    ),
    tasksTableConfig,
    tableDataMemo,
  );

  return { tableProps, isRowDisabled, readFromCacheParams };
};
