import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, string } from 'prop-types';
import { FormFieldLabel, LinkButton, Text } from 'poly-book-admin';
import { mapIndexed } from 'poly-utils/src/ramda.js';

import { SectionWrapperWithoutBorder } from '../../components/commonSidebarComponents.js';

export const TabSectionWrapper = styled(SectionWrapperWithoutBorder)`
  padding-top: 24px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EditClientTabButton = styled(LinkButton)`
  height: fit-content;
`;

// prepareListWithKeys :: [String] -> [{value: String, key: String}]
const prepareListWithKeys = mapIndexed((value, index) => ({
  value,
  key: `${value}-${index}`,
}));

export function ColumnList({ id, list }) {
  const preparedList = prepareListWithKeys(list);

  return (
    <ColumnWrapper id={id}>
      {preparedList.map(({ value, key }) => (
        <Text key={key} size="12px" lineHeight="18px">
          {value}
        </Text>
      ))}
    </ColumnWrapper>
  );
}

ColumnList.propTypes = {
  id: string.isRequired,
  list: arrayOf(string),
};

const DEFAULT_MARGIN_PX = 10;

export const getCalcWidth = (width) =>
  `calc(${width}% - ${DEFAULT_MARGIN_PX}px)`;

export const CustomRateField = styled.div`
  display: inline-block;
  width: ${R.prop('width')};

  &:not(:last-child) {
    margin-right: ${DEFAULT_MARGIN_PX}px;
  }

  margin-bottom: ${DEFAULT_MARGIN_PX}px;
`;

export const CustomRateFieldGroup = styled.div`
  position: relative;
  width: 100%;
  padding-right: 10px;
`;

export const CustomRateLabel = styled(FormFieldLabel).attrs({ position: '' })`
  display: inline-block;
  &:not(:last-child) {
    margin-right: ${DEFAULT_MARGIN_PX}px;
  }
`;

export const CustomRateAddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${DEFAULT_MARGIN_PX}px;

  div {
    text-align: right;
    width: 50px;
  }
`;
