import React from 'react';
import * as R from 'ramda';
import {
  MainHeader,
  PageHeaderContainer,
  HeaderLinksWrapper,
  TextButton,
} from 'poly-book-admin';
import { gql, useQuery } from '@apollo/client';
import { bool, string } from 'prop-types';
import { useModalContext } from 'poly-admin-ui';
import { TitleWithBackButton } from 'poly-admin-ui/src/components/pagesHeadersComps.js';

import { ClientGLCodeForm, addClientGlCodeFormId } from './ClientGLCodeForm.js';

const CLIENT_QUERY = gql`
  query CLIENT_QUERY($id: ID!) {
    client(id: $id) {
      name
    }
  }
`;

function AddClientGLCodeButton({ clientId }) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: addClientGlCodeFormId,
      title: 'Add New GL Code',
      formId: addClientGlCodeFormId,
      btnCaption: 'Save',
      content: <ClientGLCodeForm clientId={clientId} />,
    });

  return <TextButton onClick={onClick}>Add New</TextButton>;
}

AddClientGLCodeButton.propTypes = {
  clientId: string.isRequired,
};

export function ClientGLCodesPageHeader({
  clientId,
  hasAccessToUpdateClientGlCode,
}) {
  const { data } = useQuery(CLIENT_QUERY, { variables: { id: clientId } });

  const clientName = R.path(['client', 'name'], data);

  return (
    <PageHeaderContainer>
      <MainHeader>
        <TitleWithBackButton title={`${clientName} GL Codes`} />
      </MainHeader>

      <HeaderLinksWrapper>
        {hasAccessToUpdateClientGlCode && (
          <AddClientGLCodeButton clientId={clientId} />
        )}
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}

ClientGLCodesPageHeader.propTypes = {
  clientId: string.isRequired,
  hasAccessToUpdateClientGlCode: bool,
};
