import React from 'react';
import * as R from 'ramda';
import { string, shape, bool, instanceOf, oneOfType } from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { formatSocialSecurity, formatDate } from 'poly-utils';
import { READ_SENSITIVE_FIELD_USER_PERMISSION } from 'poly-security';
import { SidebarRow } from 'poly-admin-ui';

import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapper,
  threeBlocksProps,
} from '../components/commonSidebarComponents.js';
import { getUserFormattedValue, titleCaseCurried } from './userSidebarUtils.js';
import {
  getSectionEmail,
  getSectionPhoneLink,
  getSectionText,
} from '../components/commonSidebarSectionFormatters.js';

export function UserSidebarPersonalInfo({ user }) {
  const hasViewPermission = useHasUserAccessWithPermission(
    READ_SENSITIVE_FIELD_USER_PERMISSION,
  );

  const isNotContractor = !user.isContractor;

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Personal Information</SectionLabel>
      </SidebarRow>
      {hasViewPermission && isNotContractor && (
        <SidebarRow>
          <BlockWithLabel
            label="Social Security #"
            id="social-security-number"
            Component={getSectionText(
              getUserFormattedValue(
                R.path(['profile', 'socialSecurityNumber'], user),
                formatSocialSecurity,
              ),
            )}
          />
        </SidebarRow>
      )}
      {isNotContractor && (
        <SidebarRow justify>
          <BlockWithLabel
            id="date-of-birth"
            label="Date Of Birth"
            {...threeBlocksProps}
            Component={getSectionText(
              getUserFormattedValue(
                R.path(['profile', 'dateOfBirth'], user),
                formatDate,
              ),
            )}
          />
          <BlockWithLabel
            id="gender"
            label="Gender"
            {...threeBlocksProps}
            Component={getSectionText(
              getUserFormattedValue(
                R.path(['profile', 'gender'], user),
                titleCaseCurried,
              ),
            )}
          />
          <BlockWithLabel
            id="marital-status"
            label="Marital Status"
            {...threeBlocksProps}
            Component={getSectionText(
              getUserFormattedValue(
                R.path(['profile', 'maritalStatus'], user),
                titleCaseCurried,
              ),
            )}
          />
        </SidebarRow>
      )}
      <SidebarRow justify>
        <BlockWithLabel
          id="personal-phone"
          label="Personal Phone"
          {...threeBlocksProps}
          Component={getSectionPhoneLink(user.profile.personalPhone)}
        />
        <BlockWithLabel
          id="personal-mobile"
          label="Personal Mobile"
          {...threeBlocksProps}
          Component={getSectionPhoneLink(user.profile.personalMobile)}
        />
        <BlockWithLabel
          id="personal-email"
          label="Personal Email"
          {...threeBlocksProps}
          Component={getSectionEmail(
            R.path(['profile', 'personalEmail'])(user),
          )}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

UserSidebarPersonalInfo.propTypes = {
  user: shape({
    isContractor: bool,
    profile: shape({
      gender: string,
      maritalStatus: string,
      personalPhone: string,
      personalEmail: string,
      socialSecurityNumber: string,
      dateOfBirth: oneOfType([instanceOf(Date), string]),
    }),
  }).isRequired,
};
