import React from 'react';
import { string } from 'prop-types';
import { SubmitBtn, useProcessState } from 'poly-admin-ui';
import { MainHeader, PageHeaderContainer } from 'poly-book-admin';
import { formatBackLink, useLocation } from 'poly-client-routing';
import { ButtonsContainer } from 'poly-admin-ui/src/components/Button/Button.js';

import { routesNames } from '../routes/index.js';
import { CancelFormBtn } from './Buttons.js';

export function FormPageHeaderCreator({ formId, title, fallbackLink }) {
  const location = useLocation();
  const { process } = useProcessState(formId);

  const cancelRedirectUrl = formatBackLink({
    fallbackLink: fallbackLink || routesNames.FINANCIAL_DIRECTORY,
    router: location.state,
    withQuery: true,
  });

  return (
    <PageHeaderContainer>
      <MainHeader>{title}</MainHeader>
      <ButtonsContainer>
        <SubmitBtn loader={process} disabled={process} form={formId}>
          Save
        </SubmitBtn>
        <CancelFormBtn disabled={process} redirectUrl={cancelRedirectUrl} />
      </ButtonsContainer>
    </PageHeaderContainer>
  );
}

FormPageHeaderCreator.propTypes = {
  formId: string.isRequired,
  title: string.isRequired,
  fallbackLink: string,
};

FormPageHeaderCreator.displayName = 'FormPageHeaderCreator';
