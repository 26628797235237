import * as R from 'ramda';
import React from 'react';
import { Link } from 'poly-client-routing';
import { ClientStatuses } from 'poly-constants';
import { Loader } from 'poly-book-admin';
import { bool, oneOfType, object, string } from 'prop-types';
import { EntityStatisticsS } from './entitiesStatisticsStyles.js';
import { routesNames } from '../../routes/index.js';
import { findCountByStatus } from './entitiesStatisticsUtils.js';

const { CLIENTS } = routesNames;
const { ACTIVE, INACTIVE, PROSPECT } = ClientStatuses;

function ClientsLink({ tab, ...restProps }) {
  const href = `${CLIENTS}?tab=${tab}`;
  return <Link {...restProps} href={href} />;
}

ClientsLink.propTypes = {
  tab: string,
};

function ProspectsLink(props) {
  return <Link {...props} href={routesNames.PROSPECTS} />;
}

// getClientsCountByStatuses :: AccountIndex -> [ClientStatusCount]
const getClientsCountByStatuses = R.pathOr([], ['clients', 'countByStatuses']);

export function ClientsStatistics(props) {
  const { loading, adminAccountIndex } = props;

  if (loading) return <Loader />;

  const countByStatuses = getClientsCountByStatuses(adminAccountIndex);

  const items = [
    [
      'Active',
      <ClientsLink key={ACTIVE} tab="active-clients">
        {findCountByStatus(ACTIVE, countByStatuses)}
      </ClientsLink>,
    ],
    [
      'Inactive',
      <ClientsLink key={INACTIVE} tab="inactive-clients">
        {findCountByStatus(INACTIVE, countByStatuses)}
      </ClientsLink>,
    ],
    [
      'Prospects',
      <ProspectsLink key={PROSPECT}>
        {findCountByStatus(PROSPECT, countByStatuses)}
      </ProspectsLink>,
    ],
  ];

  return <EntityStatisticsS {...props} header="Clients" items={items} />;
}

ClientsStatistics.displayName = 'ClientsStatistics';

ClientsStatistics.propTypes = {
  loading: bool,
  adminAccountIndex: oneOfType([object]),
};
