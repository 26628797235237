import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import { SidebarRow } from 'poly-admin-ui';
import {
  taskConfigsForAllProjectTypes,
  NOTHING_UI_STRING,
  RWOTaskListTypeUI,
  RWOTaskListType,
} from 'poly-constants';

import {
  SectionLabel,
  SectionWrapper,
  BlockWithLabel,
} from '../components/commonSidebarComponents.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';

// getTaskListTypeByMasterProject :: RecurringProject -> String
const getTaskListTypeByMasterProject = R.propOr(
  RWOTaskListType.LONG_LIST,
  'taskListType',
);

// getMRProjectSidebarTasksInfo :: Project -> Object
const getMRProjectSidebarTasksInfo = R.applySpec({
  taskType: R.compose(
    R.prop(R.__, RWOTaskListTypeUI),
    getTaskListTypeByMasterProject,
  ),
  taskDescription: R.compose(
    R.ifElse(
      R.isEmpty,
      R.always(NOTHING_UI_STRING),
      R.compose(R.join(' / '), R.map(R.prop('description'))),
    ),
    R.converge(R.pathOr([]), [
      R.compose(R.append(R.__, ['taskLists']), getTaskListTypeByMasterProject),
      R.compose(
        R.find(R.__, taskConfigsForAllProjectTypes),
        R.propEq('type'),
        R.prop('childType'),
      ),
    ]),
  ),
});

export function MasterRecurringProjectSidebarTasks({ project }) {
  const { taskType, taskDescription } = getMRProjectSidebarTasksInfo(project);

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Project Task Types</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          id={taskType}
          label={taskType}
          Component={getSectionText(taskDescription)}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

MasterRecurringProjectSidebarTasks.propTypes = {
  project: shape({ _id: string.isRequired }),
};
