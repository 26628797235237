import React from 'react';
import { useApolloClient } from '@apollo/client';
import { PostTaskUpdateForm as CommonPostTaskUpdateForm } from 'poly-admin-ui';

import { postTaskUpdateFormId } from './constants.js';

export function PostTaskUpdateForm(props) {
  const apolloClient = useApolloClient();

  return (
    <CommonPostTaskUpdateForm
      {...props}
      formId={postTaskUpdateFormId}
      apolloClient={apolloClient}
    />
  );
}
