import React from 'react';
import * as R from 'ramda';
import { shape, string, bool } from 'prop-types';
import { ProjectType } from 'poly-constants';

import { SidebarIDs } from '../../constants.js';
import { projectSidebarTabsId } from '../constants.js';
import { SidebarTabs } from '../../components/SidebarTabs.js';
import { projectSidebarTabs } from '../../../routes/constants.js';
import { ProjectSidebarTasksTab } from './ProjectSidebarTasksTab.js';
import { ProjectSidebarFilesTab } from './ProjectSidebarFilesTab.js';
import { ProjectSidebarAssetsTab } from './ProjectSidebarAssetsTab.js';
import { ProjectSidebarUpdatesTab } from './ProjectSidebarUpdatesTab.js';
import { ProjectSidebarInvoicesTab } from './ProjectSidebarInvoicesTab.js';
import { ProjectSidebarSuppliersTab } from './ProjectSidebarSuppliersTab.js';
import { checkIfShowAssetsByProject } from '../projectSidebarUtils.js';

// projectTypeIs :: (ProjectType, Project) -> Boolean
const projectTypeIs = R.propEq('type');

export function ProjectSidebarTabs({
  project,
  customTabQueryKey,
  isCard = false,
}) {
  const showAssets = checkIfShowAssetsByProject(project);
  const isFee = projectTypeIs(ProjectType.FEE, project);
  const isListing = projectTypeIs(ProjectType.LISTING, project);

  return (
    <SidebarTabs
      tabs={[
        [
          'Tasks',
          projectSidebarTabs.projectTasks,
          <ProjectSidebarTasksTab {...project} />,
        ],
        [
          'Updates',
          projectSidebarTabs.projectUpdates,
          <ProjectSidebarUpdatesTab {...project} />,
        ],
        ...(isFee
          ? []
          : [
              [
                'Suppliers',
                projectSidebarTabs.projectSuppliers,
                <ProjectSidebarSuppliersTab {...project} />,
              ],
            ]),
        [
          'Files',
          projectSidebarTabs.projectFiles,
          <ProjectSidebarFilesTab {...project} />,
        ],
        ...(isFee || isListing
          ? []
          : [
              [
                'Invoices',
                projectSidebarTabs.projectInvoices,
                <ProjectSidebarInvoicesTab {...project} />,
              ],
            ]),
        ...(showAssets
          ? [
              [
                'Assets',
                projectSidebarTabs.projectAssets,
                <ProjectSidebarAssetsTab {...project} />,
              ],
            ]
          : []),
      ]}
      defaultValue={projectSidebarTabs.projectUpdates}
      params={[SidebarIDs.project, 'type']}
      id={projectSidebarTabsId}
      hideCloseIcon={isCard}
      isCard={isCard}
      customTabQueryKey={customTabQueryKey}
    />
  );
}

ProjectSidebarTabs.propTypes = {
  isCard: bool,
  project: shape({ _id: string.isRequired }),
  customTabQueryKey: string,
};
