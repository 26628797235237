import React from 'react';

import * as R from 'ramda';
import styled from 'styled-components';

import {
  SidebarTabHeader,
  SidebarTabTitle,
  SidebarTabWrapper,
} from 'poly-admin-ui/src/sidebar/tabs/sidebarTabComponents.js';
import { useOutSidebarContext } from 'poly-client-utils/src/slideOutSidebar.js';
import { Loader } from 'poly-book-admin/src/Loader/index.js';
import { getUserAvatarComp } from 'poly-admin-ui/src/modules/tables/updatesTable/UpdateMetaInfo.js';
import {
  UpdatesTableInfoSection,
  UpdatesTableRow,
} from 'poly-admin-ui/src/modules/tables/updatesTable/UpdatesTableComponents.js';
import { useMapConfigToTableProps } from 'poly-admin-ui/src/hooks/useMapConfigToTableProps.js';
import { WindowedTable } from 'poly-book-admin/src/Table/WindowedTable.js';

import { useAccountingPeriodsUpdates } from './useAccountingPeriodsUpdates.js';
import { SidebarIDs } from '../../../sidebars/constants.js';
import { SidebarCloseIcon } from '../../../components/SidebarLayouts.js';

const SidebarTabHeaderS = styled(SidebarTabHeader)`
  padding-top: 0;
  padding-bottom: 0;
  z-index: 99999;
  height: 70px;
  background-color: #fff;
`;

const SidebarTabWrapperS = styled(SidebarTabWrapper)`
  padding-top: 0;
  height: calc(100vh - 80px);
`;

const AccountingUpdatesTableS = styled(WindowedTable)`
  margin-top: -70px;
  thead {
    display: none;
  }

  tr {
    padding: 0 24px;
  }

  th:first-child,
  td:first-child {
    width: 35px;
    padding-left: 0;
  }
`;

const accountingPeriodsTableConfig = [
  ['', R.compose(getUserAvatarComp, R.prop('createdBy'))],
  ['', (props) => <UpdatesTableInfoSection {...props} skipCopyLink />],
];

export function AccountingPeriodsUpdatesSidebar() {
  const { updates, loading, tableProps } = useAccountingPeriodsUpdates();
  const { closeOutSidebar } = useOutSidebarContext();

  const handleClose = () =>
    closeOutSidebar(SidebarIDs.requestedSupplierDocument);

  const updatesTableProps = useMapConfigToTableProps(
    R.identity,
    accountingPeriodsTableConfig,
    updates,
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarTabWrapperS>
      <SidebarTabHeaderS>
        <SidebarTabTitle>Updates</SidebarTabTitle>
        <SidebarCloseIcon onClick={handleClose} />
      </SidebarTabHeaderS>
      <AccountingUpdatesTableS
        {...updatesTableProps}
        {...tableProps}
        RowComponent={UpdatesTableRow}
        itemSize={70}
      />
    </SidebarTabWrapperS>
  );
}
