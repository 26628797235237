import { SupplierTypes } from 'poly-constants';
import * as R from 'ramda';

import { routesNames } from '../../routes/index.js';

const { SUPPLIERS_ADMINISTRATIVE_AAC, SUPPLIERS_SUBCONTRACTORS } = routesNames;

/**
 * makeSupplierBackLink :: Supplier -> URL
 */
export const makeSupplierBackLink = R.ifElse(
  R.propEq('type', SupplierTypes.ADMINISTRATIVE),
  R.always(SUPPLIERS_ADMINISTRATIVE_AAC),
  R.always(SUPPLIERS_SUBCONTRACTORS),
);
