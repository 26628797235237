import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import {
  commonEmailValidators,
  validateInternationalPhoneNumber,
} from 'poly-client-utils/src/formValidators.js';
import { Input } from 'poly-book-admin';
import {
  INVALID_SUPPLIER_NAME_MESSAGE,
  supplierEmailsTypes,
} from 'poly-constants';
import {
  hasOnlyUSKeyboardCharacters,
  validationFuncWithAllValues,
  isNilOrEmpty,
} from 'poly-utils';
import {
  optionalAddressValidators,
  addressValidators,
  validatePhone,
  ifNotEmpty,
} from 'poly-client-utils';
import { validateURL } from 'poly-utils/src/validators.js';

import { halfWidth } from '../common.js';
import { AddressSection } from '../../../fields/index.js';
import { ToggleDBAInput } from '../components/ToggleDBAInput.js';
import { PhoneNumberInput, SupplierNameInput } from '../components/index.js';
import { WebsiteFormInput } from '../../../../../components/WebsiteFormInput.js';
import { supplierGeneralSectionMWBEFields } from './supplierGeneralSectionMWBEFields.js';
import { InternationalPhoneInput } from '../../../../../components/InternationalAddressAndPhone/InternationalPhone/InternationalPhoneInput.js';
import {
  BaseServiceTypesMultiSelectField,
  isSubcontractorServiceType,
} from '../../../fields/BaseServiceTypesMultiSelectField.js';
import {
  getSupplierFormContext,
  isAddressesEquals,
  isAdministrative,
  isSubcontractor,
} from '../utils.js';
import { MultiEmailsInput } from '../../../../../components/MultiEmailsInput.js';

const { ACCOUNT, SERVICE, GENERAL, SECONDARY_SERVICE } = supplierEmailsTypes;

// validateServiceType :: [ID] -> FormData -> Boolean
const validateServiceType = R.curry((value, allValues) =>
  R.ifElse(
    R.compose(isSubcontractorServiceType, R.always(allValues)),
    R.complement(isNilOrEmpty),
    R.always(true),
  )(value),
);

export const serviceTypeValidator = [
  validateServiceType,
  'At least one service should be added',
];

function CompanyNameInput({ formData, ...props }) {
  const skipSupplierId = R.propOr('', '_id', formData);
  return (
    <SupplierNameInput
      skipSupplierId={skipSupplierId}
      showCharactersLeft
      {...props}
    />
  );
}

CompanyNameInput.propTypes = {
  formData: shape({
    _id: string,
  }),
};

const supplierGeneralSectionCompanyFields = (
  rootUrl,
  isAddressSectionRequired,
) => [
  {
    label: 'Company Name',
    order: 1,
    layout: { row: 1 },
    field: {
      name: 'company.name',
      withFormData: true,
      Component: (props) => <CompanyNameInput rootUrl={rootUrl} {...props} />,
    },
    required: true,
    validators: [
      [
        R.pipe(R.when(R.is(String), R.trim), R.identity),
        'Supplier name is required',
      ],
      [hasOnlyUSKeyboardCharacters, INVALID_SUPPLIER_NAME_MESSAGE],
    ],
  },
  {
    label: 'Add DBA',
    order: 2,
    layout: { row: 2 },
    field: {
      name: 'company.dba',
      Component: ToggleDBAInput,
    },
  },
  {
    order: 2,
    layout: { row: 3 },
    field: {
      name: 'company.address',
      Component: AddressSection,
      isEqual: isAddressesEquals,
    },
    required: isAddressSectionRequired,
    validators: isAddressSectionRequired
      ? addressValidators
      : optionalAddressValidators,
  },
];

export const generalSection = (rootUrl, isAddressSectionRequired) => [
  {
    label: 'General Information',
    order: 3,
    layout: { column: 1 },
    fields: [
      ...supplierGeneralSectionCompanyFields(rootUrl, isAddressSectionRequired),
      {
        label: 'Phone',
        order: 4,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'company.phone',
          Component: PhoneNumberInput,
        },
        validators: [
          [
            R.anyPass([validatePhone, R.isNil]),
            'Please provide correct phone number',
          ],
        ],
      },
      {
        label: 'Fax',
        order: 5,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'company.fax',
          Component: PhoneNumberInput,
        },
        validators: [[ifNotEmpty(validatePhone), 'Fax is not correct']],
      },
      {
        label: 'Email',
        order: 6,
        layout: { row: 5, width: halfWidth, isWrapped: true },
        field: {
          name: `company.emails.${GENERAL}`,
          Component: Input,
        },
        validators: commonEmailValidators,
        renderIf: isAdministrative,
      },
      {
        label: 'Primary Service Email',
        order: 7,
        layout: { row: 5, width: halfWidth },
        field: {
          name: `company.emails.${SERVICE}`,
          Component: Input,
        },
        renderIf: isSubcontractor,
        validators: commonEmailValidators,
      },
      {
        label: 'Secondary Service Emails',
        order: 8,
        layout: { row: 5, width: halfWidth },
        field: {
          name: `company.emails.${SECONDARY_SERVICE}`,
          Component: MultiEmailsInput,
        },
        renderIf: isSubcontractor,
      },
      {
        label: 'Accounting Emails',
        order: 8,
        layout: { row: 6, width: halfWidth },
        field: {
          name: `company.emails.${ACCOUNT}`,
          Component: MultiEmailsInput,
        },
        renderIf: isSubcontractor,
      },
      {
        label: 'Website',
        order: 10,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'company.website',
          Component: WebsiteFormInput,
        },
        validators: [
          [
            ifNotEmpty(validateURL),
            'URL should be in form http(s)://example.com',
          ],
        ],
        renderIf: isAdministrative,
        leaveValues: true,
      },
      {
        order: 9,
        layout: { row: 7, width: halfWidth },
        field: {
          name: 'company.servicesIds',
          withFormData: true,
          Component: getSupplierFormContext(BaseServiceTypesMultiSelectField),
        },
        validators: [serviceTypeValidator],
        validateFunction: validationFuncWithAllValues,
      },
      {
        label: 'Service SMS Number',
        order: 11,
        layout: { row: 7, width: halfWidth },
        field: {
          name: 'company.mobile',
          Component: InternationalPhoneInput,
        },
        renderIf: isSubcontractor,
        validators: [
          [
            R.anyPass([validateInternationalPhoneNumber, R.isNil]),
            'Please provide correct mobile phone number',
          ],
        ],
      },
      {
        label: 'Website',
        order: 12,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'company.website',
          Component: WebsiteFormInput,
        },
        validators: [
          [
            ifNotEmpty(validateURL),
            'URL should be in form http(s)://example.com',
          ],
        ],
        renderIf: isSubcontractor,
        leaveValues: true,
      },
      ...supplierGeneralSectionMWBEFields,
    ],
  },
];
