import { VENDOR_INFO_TYPES } from 'poly-admin-ui';
import { useNavigate } from 'poly-client-routing';
import { insertQueryParamsIntoURL } from 'poly-utils';
import { useEffect } from 'react';
import { routesNames } from '../routes/constants.js';

export function SupplierAttachInvoiceFilePageOld() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(
      insertQueryParamsIntoURL(
        { type: VENDOR_INFO_TYPES.TOKEN_EXPIRED },
        routesNames.VENDOR_INFO,
      ),
    );
  }, []);

  return null;
}
