import styled from 'styled-components';
import {
  moneyColumnStyles,
  WindowedTable,
  getThemeFont,
  Table,
} from 'poly-book-admin';

import {
  Line,
  TotalFieldErrorMsg,
} from '../../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/styled.js';

export const BottomLine = styled(Line)`
  width: 60%;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
`;

export const LineButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;

  > span {
    padding: 0 5px;
    font-size: 12px;
  }
`;

export const LineAmount = styled.div`
  display: flex;
  width: calc(25% + 20px);
  font-size: 20px;
  font-weight: ${getThemeFont(['medium'])};
  justify-content: flex-end;
`;

export const LineAmountTotal = styled(LineAmount)`
  justify-content: space-between;
`;

export const LineError = styled(TotalFieldErrorMsg)`
  text-align: left;
  padding-bottom: 3px;
  position: absolute;
  width: 200px;
  right: -240px;
`;

export const JournalEntryLedgerTableS = styled(WindowedTable)`
  thead {
    height: 42px;
  }

  th {
    padding-top: 0;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 85px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 100px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 70px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 90px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 360px;
  }

  th:nth-child(6),
  td:nth-child(6),
  th:nth-child(7),
  td:nth-child(7) {
    width: 170px;
  }

  ${moneyColumnStyles(8, 120)};
  ${moneyColumnStyles(9, 120)};

  th:nth-child(10),
  td:nth-child(10) {
    width: 90px;
    text-align: center;
  }

  @media (max-width: 1600px) {
    td {
      font-size: 0.75rem;
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 50px;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 330px;
    }

    th:nth-child(10),
    td:nth-child(10) {
      width: 90px;
      text-align: center;
    }
  }
`;

export const JournalEntryLedgerPrintTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2) {
    width: 100px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
  }

  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};

  th:nth-child(7),
  td:nth-child(7) {
    width: 80px;
    text-align: center;
  }
`;
