import * as R from 'ramda';
import { TimesheetTypes } from 'poly-constants';

import { timesheetTypesMap } from '../constants.js';

const { PTO, HOLIDAY } = TimesheetTypes;

// getTimeSheetTypeOptions :: {value: String isManual: Boolean } => [[String, String]]
export const getTimeSheetTypeOptions = ({ value, isManual }) =>
  R.compose(
    R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
    R.toPairs,
    R.ifElse(
      R.identity,
      R.compose(
        R.omit(R.reject(R.equals(value), [PTO, HOLIDAY])),
        R.always(timesheetTypesMap),
      ),
      R.always(timesheetTypesMap),
    ),
  )(isManual);
