import { InvoicesStatuses } from 'poly-constants';

import { ALL } from '../../modules/core/constants/general.js';

export const invoiceStatusSelectOptions = [
  { value: ALL, label: 'All' },
  { value: InvoicesStatuses.PAID, label: 'Paid' },
  { value: InvoicesStatuses.PARTIALLY_PAID, label: 'Partially Paid' },
  { value: InvoicesStatuses.PENDING, label: 'Pending' },
  { value: InvoicesStatuses.VOIDED, label: 'Voided' },
  { value: InvoicesStatuses.REPORT_ONLY, label: 'Report Only' },
];

export const searchSupplierInvoicesFilters = {
  supplier: 'supplier',
  invoiceNumber: 'invoiceNumber',
  invoiceStatus: 'invoiceStatus',
  creditsOnly: 'creditsOnly',
  invoiceDate: 'invoiceDate',
  startDate: 'startDate',
  endDate: 'endDate',
};
