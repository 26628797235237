import { endOfWeek, startOfWeek } from 'date-fns';
import { ensureIsDate } from 'poly-utils';

import { timesheetWeekEdgeOptions } from '../../forms/timesheetForm/constants.js';

// getStartOfWeek :: Date -> Date
export const getStartOfWeek = (date) =>
  startOfWeek(ensureIsDate(date), timesheetWeekEdgeOptions);

// getEndOfWeek :: Date -> Date
export const getEndOfWeek = (date) =>
  endOfWeek(ensureIsDate(date), timesheetWeekEdgeOptions);
