import * as R from 'ramda';
import { isSameDay } from 'date-fns';
import { formatDate, ensureIsDate, forceTitleCase } from 'poly-utils';

// prepareLabel :: String -> String
export const prepareLabel = R.compose(
  R.join(' '),
  R.over(R.lensIndex(0), forceTitleCase),
  R.split('_'),
);
/**
 * prepareProgressiveRangePickerOptions :: OptionConstants -> [Option]
 *   OptionConstants = { [String]: String }
 */
export const prepareProgressiveRangePickerOptions = R.compose(
  R.map(R.applySpec({ value: R.identity, label: prepareLabel })),
  R.values,
);

// prepareDateFormatByProp :: String -> RangePickerValue -> String
//   RangePickerValue = {
//     startDate: Date
//     endDate: Date
//     option: String
//   }
const prepareDateFormatByProp = (propName) =>
  R.compose(formatDate, R.prop(propName));

/**
 * constructPlaceholderByValue :: RangePickerValue -> String
 */
export const constructPlaceholderByValue = R.ifElse(
  R.prop('option'),
  R.always(''),
  R.compose(
    R.ifElse(
      ([startDate, endDate]) =>
        isSameDay(ensureIsDate(startDate), ensureIsDate(endDate)),
      R.prop(0),
      R.join(' - '),
    ),
    R.juxt([
      prepareDateFormatByProp('startDate'),
      prepareDateFormatByProp('endDate'),
    ]),
  ),
);
