import { usePagination } from 'poly-admin-ui/src/index.js';
import { useMutation } from '@apollo/client';
import { useEntitiesByReactiveReduxSearch } from 'poly-client-utils';
import { MENTION_GROUPS_SEARCH_QUERY } from './queries.js';
import { MENTION_GROUP_SEARCH_CHANGED } from './subscriptions.js';
import { UPDATE_MENTION_GROUP_MUTATION } from './mutations.js';

export const useMentionGroupsBySearchTable = ({ sort, query }) => {
  const { pagination } = usePagination();

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: MENTION_GROUPS_SEARCH_QUERY,
    gqlSearchChangedQuery: MENTION_GROUP_SEARCH_CHANGED,
    pagination,
    sort,
    query,
  });

  return { loading, refetch, result };
};

export const useUpdateMentionGroupMutation = () => {
  const [mutate, { called, client, loading, reset }] = useMutation(
    UPDATE_MENTION_GROUP_MUTATION,
    {
      alias: 'useUpdateMentionGroupMutation',
    },
  );

  return {
    mutate,
    result: { called, client, loading, reset },
  };
};
