import { useState } from 'react';
import { useNotificationContext } from 'poly-book-admin';
import { useMutation, gql } from '@apollo/client';

import { useSidebarDocumentsNavigation } from '../../../components/PreviewDocumentsNavigation.js';
import { SidebarIDs } from '../../../sidebars/constants.js';
import { prepareApproveOnboardingInput } from './prepareApproveOnboardingInput.js';

const approveRequestedSupplierDocumentMutation = gql`
  mutation approveRequestedSupplierDocument(
    $input: ApproveRequestedSupplierDocumentInput!
  ) {
    approveRequestedSupplierDocument(input: $input)
  }
`;

export const useApproveRequestedSupplierDocument = ({
  id,
  handleCloseSidebar,
  isOnboarding,
  documentExpiration,
  isMBEDocument,
  supplierMinority,
}) => {
  const [isApproving, setIsApproving] = useState(false);

  const { showSuccessNotification } = useNotificationContext();

  const [approveRequestedSupplierDocument] = useMutation(
    approveRequestedSupplierDocumentMutation,
  );

  const {
    goToNextDocument,
    goToPreviousDocument,
    isNexDocument,
    isPreviousDocument,
  } = useSidebarDocumentsNavigation(SidebarIDs.requestedSupplierDocument);

  const handleApproveDocument = async (formData) => {
    try {
      setIsApproving(true);
      await approveRequestedSupplierDocument({
        variables: {
          input: {
            id,
            ...(isOnboarding
              ? prepareApproveOnboardingInput(formData)
              : { expiration: documentExpiration }),
            ...(isMBEDocument ? { mbeMinority: supplierMinority } : {}),
          },
        },
      });
      showSuccessNotification('Document successfully approved');
      setIsApproving(false);
      if (isNexDocument) {
        goToNextDocument();
        return;
      }

      if (isPreviousDocument) {
        goToPreviousDocument();
        return;
      }

      handleCloseSidebar();
    } finally {
      setIsApproving(false);
    }
  };

  return {
    handleApproveDocument,
    isApproving,
  };
};
