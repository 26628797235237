import React from 'react';
import * as R from 'ramda';
import { useRouterParams } from 'poly-client-routing';
import { LinkButtonWithLoader } from 'poly-book-admin';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';

import { convertCentsToDollars, formatDate } from 'poly-utils/src/index.js';
import { useSearchSupplierInvoices } from './useSearchSupplierInvoices.js';
import { invoiceStatusToStringMap } from '../../modules/tables/columns/invoices.js';

const invoiceStatusOrEmptyString = R.compose(
  R.defaultTo(''),
  R.prop(R.__, invoiceStatusToStringMap),
  R.prop('status'),
);

// getSupplierInvoicesRowConfig :: [Invoice] -> [ExcelExportDataCell]
const getSupplierInvoicesRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 4),
    ExcelExportCellType.defaultCurrency,
    ...R.repeat(ExcelExportCellType.default, 3),
  ]),
  R.juxt([
    R.propOr('', 'invoiceNumber'),
    R.pathOr('', ['project', 'projectId']),
    R.compose(formatDate, R.prop('invoiceDate')),
    R.compose(formatDate, R.prop('paymentDueDate')),
    R.compose(convertCentsToDollars, R.propOr(0, 'total')),
    R.pathOr('', ['paidBy', 'fullName']),
    R.compose(formatDate, R.prop('paidAt')),
    invoiceStatusOrEmptyString,
  ]),
);

export const getSupplierInvoicesExportExcelConfig = ({
  fileName,
  invoices,
}) => ({
  exportFileName: `${fileName}.xlsx`,
  columnWidths: [25, 25, 25, 25, 25, 25, 25, 25],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 4),
        ExcelExportCellType.tableCurrencyHeader,
        ...R.repeat(ExcelExportCellType.tableHeader, 3),
      ],
      [
        'Invoice #',
        'Project #',
        'Invoice Date',
        'Due Date',
        'Amount',
        'Paid By',
        'Date Paid',
        'Status',
      ],
    ),
    ...R.map(getSupplierInvoicesRowConfig, invoices),
  ],
});

export function SecureProjectsExportXLSBtn() {
  const { supplierId } = useRouterParams(['supplierId']);

  const { data, loading } = useSearchSupplierInvoices(supplierId);

  const handleClick = () => {
    const invoices = R.pathOr([], ['searchInvoices', 'hits'], data);
    const excelConfig = getSupplierInvoicesExportExcelConfig({
      invoices,
      fileName: 'supplier_invoices',
    });
    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader loader={loading} onClick={handleClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}
