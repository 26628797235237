import * as R from 'ramda';
import { applySpecWithFields, isNilOrEmpty, formatDate } from 'poly-utils';
import { JournalPaymentMode, NOTHING_UI_STRING } from 'poly-constants';

import { formatJournalType } from '../../sidebars/TransactionDetailsSidebar/helpers.js';

// formatTransactionType :: AccountTransaction -> String
export const formatTransactionType = R.compose(
  formatJournalType,
  R.prop('journal'),
);

// formatTransactionDate :: AccountTransaction -> String
export const formatTransactionDate = R.compose(
  formatDate,
  R.path(['journal', 'date']),
);

// ifPathExists :: [String] -> Object -> Boolean
const ifPathExists = (path) => R.pathSatisfies(R.complement(R.isNil), path);

// formatTransactionNumber:: AccountTransaction -> String
export const formatTransactionNumber = R.cond([
  [
    R.pathEq(['journal', 'payment_mode'], JournalPaymentMode.ACH),
    R.always('ACH'),
  ],
  [
    R.pathEq(['journal', 'payment_mode'], JournalPaymentMode.WIRE),
    R.always('Wire'),
  ],
  [ifPathExists(['invoiceNumber']), R.prop('invoiceNumber')],
  [R.T, R.always(NOTHING_UI_STRING)],
]);

// formatTransactionName :: AccountTransaction -> String
export const formatTransactionName = R.cond([
  [
    ifPathExists(['journal', 'supplier']),
    R.path(['journal', 'supplier', 'company', 'name']),
  ],
  [ifPathExists(['journal', 'client']), R.path(['journal', 'client', 'name'])],
  [ifPathExists(['journal', 'payee_name']), R.path(['journal', 'payee_name'])],
  [R.T, R.always(NOTHING_UI_STRING)],
]);

// getDescription :: AccountTransaction -> String
export const getDescription = R.compose(
  R.when(isNilOrEmpty, R.always(NOTHING_UI_STRING)),
  R.path(['journal', 'description']),
);

// getSplitAccount :: AccountTransaction -> String
export const getSplitAccount = R.compose(
  R.join(', '),
  R.map(R.prop('name')),
  R.defaultTo([]),
  R.prop('split_accounts'),
);

// getTransactionBalance :: AccountTransaction -> String
export const getTransactionBalance = R.prop('balance');

// transactionEndingBalance :: [AccountTransaction] -> Number
const transactionEndingBalance = R.compose(getTransactionBalance, R.last);

const getTotalBalanceRowByResult = ({ credits, debits, hits }) => ({
  _id: 'total_balance_row',
  isBalanceRow: true,
  title: 'Total',
  debits,
  credits,
  balance: transactionEndingBalance(hits),
});

// appendTotalBalanceRow :: AccountTransaction -> AccountTransaction
const transformTransactionForDisplay = applySpecWithFields({
  _id: R.compose(
    R.join('_'),
    R.juxt([
      R.path(['journal', 'journal_number']),
      R.path(['journal', 'transaction_number']),
    ]),
  ),
  debitAmount: R.ifElse(
    R.propSatisfies(R.gt(R.__, 0), 'amount'),
    R.prop('amount'),
    R.always(null),
  ),
  creditAmount: R.ifElse(
    R.propSatisfies(R.lt(R.__, 0), 'amount'),
    R.prop('amount'),
    R.always(null),
  ),
});

// transactionsResultToTableRows :: AccountTransactionsResult -> [Object]
export const transactionsResultToTableRows = (result) =>
  R.compose(
    R.prepend({
      _id: 'balance_forward_row',
      isBalanceRow: true,
      title: 'Balance Forward',
      balance: result.forward_balance,
    }),
    R.unless(R.isEmpty, R.append(getTotalBalanceRowByResult(result))),
    R.map(transformTransactionForDisplay),
    R.propOr([], 'hits'),
  )(result);
