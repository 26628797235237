import * as R from 'ramda';
import { useEffect } from 'react';
import {
  useUpdateQueryParams,
  useRouterQuery,
  useLocation,
  useNavigate,
  parseQuery,
  stringifyQuery,
} from 'poly-client-routing';

// use it for all sidebars not forms to prevent confirmation modal on sidebar close
export const useClearPristineOnSidebarMount = () => {
  const routerQuery = useRouterQuery(['pristine']);
  const updateQueryParams = useUpdateQueryParams();

  useEffect(() => {
    if (R.has('pristine', routerQuery)) {
      updateQueryParams({ pristine: undefined });
    }
  }, []);
};

export const useClearPristineState = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (queryParamsToRemove = {}) => {
    const oldParams = parseQuery(location.search);
    const params = { ...oldParams, ...queryParamsToRemove };

    navigate(`${location.pathname}?${stringifyQuery(params)}${location.hash}`, {
      state: R.omit(['pristine'], location.state),
      replace: true,
    });
  };
};
