import { gql } from '@apollo/client';
import { commonUpdateFields } from 'poly-admin-ui';

export const CREATE_UPDATE_WITH_ACTION = gql`
  mutation CREATE_UPDATE_WITH_ACTION($input: CreateUpdateWithActionInput!) {
    createUpdateWithAction(input: $input) {
      update {
        ...commonUpdateFields
      }
    }
  }
  ${commonUpdateFields}
`;

export const ADD_UPDATE_MUTATION = gql`
  mutation ADD_UPDATE_MUTATION($input: CreateUpdateInput!) {
    createUpdate(input: $input) {
      ...commonUpdateFields
    }
  }
  ${commonUpdateFields}
`;
