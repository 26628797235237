import React from 'react';
import { DivisionStatus } from 'poly-constants';
import { Select } from 'poly-book-admin';
import { capitalizeFirstLetter } from 'poly-client-utils';

const divisionStatusOptions = [
  {
    value: DivisionStatus.ACTIVE,
    label: capitalizeFirstLetter(DivisionStatus.ACTIVE),
  },
  {
    value: DivisionStatus.INACTIVE,
    label: capitalizeFirstLetter(DivisionStatus.INACTIVE),
  },
];

export function DivisionStatusDropDown(props) {
  const selectProps = {
    ...props,
    width: '100%',
    options: divisionStatusOptions,
  };

  return <Select {...selectProps} />;
}
