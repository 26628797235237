import * as R from 'ramda';
import React, { useCallback } from 'react';
import { isNilOrEmpty, getHousekeepingWeekCountByProject } from 'poly-utils';
import { ProjectType, taskCollections } from 'poly-constants';
import { useOutSidebarContext } from 'poly-client-utils';
import { entities, SidebarWidth } from 'poly-admin-ui';

import { ClientInvoiceTaskForm } from './forms/ClientInvoiceForm.js';
import { projectSidebarTabsId } from './constants.js';
import { SupplierInvoiceForm } from './forms/SupplierInvoiceForm.js';
import { SecondSidebarLayout } from '../../components/SidebarLayouts.js';
import { assignSupplierFormId } from '../../modules/forms/assignSupplierForm/constants.js';
import { CompleteAssignSupplierForm } from '../../modules/forms/assignSupplierForm/CompleteAssignSupplierForm.js';
import { transformWeeklyServiceTickets } from './projectSidebarUtils.js';

// formatProjectTasksInfo :: Project -> Object
const formatProjectTasksInfo = R.applySpec({
  client: R.prop('client'),
  endDate: R.prop('endDate'),
  total: R.propOr(0, 'total'),
  startDate: R.prop('startDate'),
  invoices: R.propOr([], 'invoices'),
  supplierMonthlyFee: R.propOr(0, 'supplierMonthlyFee'),
  supplierHourlyRate: R.propOr(0, 'supplierHourlyRate'),
  creditedPorterHours: R.propOr(0, 'creditedPorterHours'),
  weeklyServiceTickets: R.compose(
    R.map(R.prop('ticket')),
    R.ifElse(
      R.propEq('type', ProjectType.HOUSEKEEPING),
      R.converge(transformWeeklyServiceTickets, [
        getHousekeepingWeekCountByProject,
        R.propOr([], 'weeklyServiceTickets'),
      ]),
      R.always([]),
    ),
  ),
});

export const useProjectSidebarTasksProps = (project, isCard = false) => {
  const { openOutSidebar, closeOutSidebar, isSidebarOpened } =
    useOutSidebarContext();

  const { _id, projectId, type, property } = project;

  const onCompleteHashedTask = () => {
    if (isSidebarOpened(projectSidebarTabsId)) {
      closeOutSidebar(projectSidebarTabsId);
    }
  };

  const openAssignSupplierForm = useCallback((taskId) => {
    openOutSidebar({
      isSteady: true,
      width: SidebarWidth,
      id: assignSupplierFormId,
      Layout: SecondSidebarLayout,
      content: <CompleteAssignSupplierForm taskId={taskId} />,
    });
  }, []);

  return {
    isCard,
    entity: {
      _id,
      preview: 'project',
      name: entities.PROJECT,
      humanReadableId: projectId,
    },
    isHQProject: isNilOrEmpty(property),
    collection: taskCollections.PROJECTS,
    additional: {
      openAssignSupplierForm,
      onCompleteHashedTask,
      SupplierInvoiceForm,
      ClientInvoiceTaskForm,
      documentId: _id,
      project: {
        _id,
        type,
        ...formatProjectTasksInfo(project),
      },
    },
  };
};
