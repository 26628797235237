import React from 'react';
import { useOutSidebarContext } from 'poly-client-utils';
import { func, string } from 'prop-types';

import { CustomSidebarLayout } from '../../../sidebars/components/CustomSidebarLayout.js';
import {
  ReportSendingConfigurationForm,
  reportSendingConfigsFormId,
} from './ReportSendingConfigurationForm.js';
import { SidebarFormLayout } from '../../../sidebars/components/SidebarFormLayout.js';

function ReportSendingConfigurationSidebar({ onClose, clientId }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Automated reports configs"
      formId={reportSendingConfigsFormId}
      submitButtonCaption="Apply"
    >
      <ReportSendingConfigurationForm clientId={clientId} onClose={onClose} />
    </SidebarFormLayout>
  );
}

ReportSendingConfigurationSidebar.propTypes = {
  onClose: func.isRequired,
  clientId: string.isRequired,
};

export const useOpenReportSendingConfigurationSidebar = () => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  return (clientId) => {
    openOutSidebar({
      id: reportSendingConfigsFormId,
      content: (
        <ReportSendingConfigurationSidebar
          onClose={closeOutSidebar}
          clientId={clientId}
        />
      ),
      Layout: CustomSidebarLayout,
      width: 600,
    });
  };
};
