import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { string, arrayOf } from 'prop-types';
import {
  DEFAULT_PROPERTY_HIERARCHY_NAME,
  DEFAULT_PROPERTY_HIERARCHY_OPTION,
} from 'poly-constants';

import { TableHeaderC } from '../../../modules/tables/commonTableComps.js';
import { clientHierarchiesQuery } from '../../PropertyHierarchyManagement/useHierarchyOptions.js';
import {
  SpendReportCollapse,
  SpendReportsHierarchyTreeTable,
} from './SpendReportsHierarchyTreeTable.js';
import { spendReportHierarchyBranchPropType } from '../SpendDetailsPageHeader/spendReportsPropTypes.js';
import {
  NoReportText,
  SpendReportTableS,
  SpendReportsTableC,
  spendReportTableConfig,
} from './SpendReportsTableC.js';

export const SpendReportHierarchyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-spacing: 0;
  height: calc(100% - 45px);
  overflow-y: auto;
`;

const SpendReportHierarchyTableS = styled(SpendReportTableS)`
  height: 45px;
`;

// isClientWithoutHierarchy :: { propertyHierarchies: [PropertyHierarchy] } -> Boolean
const isClientWithoutHierarchy = R.compose(
  R.isEmpty,
  R.propOr([], 'propertyHierarchies'),
  R.defaultTo({}),
);

// isDefaultHierarchy :: [{ name: String }] -> Boolean
const isDefaultHierarchy = R.both(
  R.compose(R.equals(1), R.length),
  R.pathEq(['0', 'name'], DEFAULT_PROPERTY_HIERARCHY_NAME),
);

export function SpendReportHierarchyTree({ reports, clientId, hierarchyId }) {
  const tableHeaders = spendReportTableConfig.map(([title]) => ({ title }));

  const { data } = useQuery(clientHierarchiesQuery, {
    variables: { input: { clientId } },
  });

  if (reports.length === 0) {
    return <NoReportText />;
  }

  return isClientWithoutHierarchy(data) ||
    (isDefaultHierarchy(reports) &&
      hierarchyId === DEFAULT_PROPERTY_HIERARCHY_OPTION) ? (
    <SpendReportsTableC withoutHierarchy reports={reports[0].properties} />
  ) : (
    <>
      <SpendReportHierarchyTableS>
        <TableHeaderC headers={tableHeaders} />
      </SpendReportHierarchyTableS>
      <SpendReportHierarchyWrapper>
        {reports.map((report) => (
          <SpendReportsHierarchyTreeTable
            key={`${report.name}_${report._id || 'no-hierarchy'}`}
            report={report}
            level={0}
            TableComponent={SpendReportsTableC}
            Collapse={SpendReportCollapse}
          />
        ))}
      </SpendReportHierarchyWrapper>
    </>
  );
}

SpendReportHierarchyTree.defaultProps = { reports: [] };
SpendReportHierarchyTree.propTypes = {
  reports: arrayOf(spendReportHierarchyBranchPropType),
  clientId: string,
  hierarchyId: string,
};
