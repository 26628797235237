import styled from 'styled-components';
import { Table } from 'poly-book-admin';

export const TableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 150px;
    text-align: center;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 100px;
    text-align: center;
  }
`;
