import React from 'react';
import * as R from 'ramda';
import { string, func, shape } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { mailToInitValues, commonAddressFields } from 'poly-admin-ui';
import { UPDATE_DUE_DATE_INVOICE_PERMISSION } from 'poly-security';
import { InvoicesStatuses } from 'poly-constants';
import { Loader } from 'poly-book-admin';
import {
  useHasUserAccessWithPermission,
  useReactiveQuery,
} from 'poly-client-utils';

import {
  supplierInvoiceFormId,
  SupplierInvoiceFormActions,
} from '../../../modules/forms/supplierInvoiceForm/constants.js';
import { commonInvoiceFields } from '../../../modules/core/hooks/invoices/fragments.js';
import { Form } from '../../../modules/forms/supplierInvoiceForm/supplierInvoiceForm.js';
import { isNotCreditCardOrBankExpenseInvType } from '../../../pages/SearchSupplierInvoices/VoidSupplierInvoiceButton.js';
import {
  prepareDataToMutation as formatRegularInvoiceMutationInput,
  getSupplierInvoiceDueDateByTerms,
  formatInvoiceFileUploadInput,
  modifyMutationDataForUpdate,
  getCommonProjectFields,
  prepareInvoiceToForm,
} from '../../../modules/forms/supplierInvoiceForm/supplierInvoiceFormUtils.js';

// eslint-disable-next-line import/no-unused-modules
export const updateInvoiceMutation = gql`
  mutation updateInvoiceMutation($id: ID!, $input: InvoiceUpdateInput!) {
    updateInvoice(id: $id, input: $input) {
      invoice {
        _id
      }
    }
  }
`;

const updateProjectExpenseInvoiceMutation = gql`
  mutation updateInvoiceMutation(
    $id: ID!
    $input: ProjectExpenseInvoiceUpdateInput!
  ) {
    updateProjectExpenseInvoice(id: $id, input: $input) {
      invoice {
        _id
      }
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const editInvoiceQuery = gql`
  query EDIT_INVOICE_QUERY($invoiceId: ID!) {
    invoice(id: $invoiceId) {
      ...commonInvoiceFields
      status
      paymentDueDate
      paymentDueDateChangeReason
      project {
        _id
        type
        projectId
        client {
          _id
          name
        }
        property {
          _id
          name
          address {
            ...commonAddressFields
          }
        }
        suppliers {
          _id
          type
          nte
          statusInProject
          company {
            name
          }
        }
        clientInvoices {
          _id
        }
        invoiceDescription
      }
      supplier {
        _id
        company {
          name
        }
        finance {
          terms
        }
      }
      balance
      paidAt
      paidBy {
        _id
        fullName
      }
    }
  }

  ${commonAddressFields}
  ${commonInvoiceFields}
`;

// eslint-disable-next-line import/no-unused-modules
export const invoiceChangedSubscription = gql`
  subscription INVOICE_CHANGED_SUB($input: SingleDocSubInput!) {
    invoiceChanged(input: $input) {
      id
      type
    }
  }
`;

// formatCreateCCExpenseInvoiceMutationInput :: FormValues -> UpdateInvoiceInput
const formatCreateCCExpenseInvoiceMutationInput = R.compose(
  formatInvoiceFileUploadInput,
  R.converge(R.mergeRight, [
    R.pickAll([
      'total',
      'invoiceDate',
      'invoiceFile',
      'projectInvoiceDescription',
    ]),
    R.compose(
      R.objOf('invoiceNumber'),
      R.defaultTo(null),
      R.prop('invoiceNumber'),
    ),
  ]),
);

// invoiceIsNotProjectExpensive :: Invoice -> Boolean
const invoiceIsNotProjectExpensive = R.compose(
  isNotCreditCardOrBankExpenseInvType,
  R.prop('type'),
);

// formatMutationInput :: Invoice -> FormValues -> UpdateInvoiceInput
const formatMutationInput = (invoice) =>
  R.compose(
    R.mergeDeepLeft({ id: invoice._id }),
    modifyMutationDataForUpdate(invoice),
    R.ifElse(
      invoiceIsNotProjectExpensive,
      formatRegularInvoiceMutationInput,
      formatCreateCCExpenseInvoiceMutationInput,
    ),
  );

// isWithDueDateField :: Invoice -> Boolean
const isWithDueDateField = R.compose(
  R.includes(R.__, [
    InvoicesStatuses.PENDING,
    InvoicesStatuses.PARTIALLY_PAID,
    InvoicesStatuses.PAID,
  ]),
  R.prop('status'),
);

export function EditProjectInvoiceForm({ onCancel, invoiceId, taskSupplier }) {
  const [updateInvoice] = useMutation(updateInvoiceMutation);
  const [updateProjectExpenseInvoice] = useMutation(
    updateProjectExpenseInvoiceMutation,
  );

  const hasAccessToUpdateAssetManufacture = useHasUserAccessWithPermission(
    UPDATE_DUE_DATE_INVOICE_PERMISSION,
  );

  const { loading, data } = useReactiveQuery(
    editInvoiceQuery,
    invoiceChangedSubscription,
    {
      queryOptions: {
        variables: { invoiceId },
        skip: !invoiceId,
      },
      subscriptionOptions: {
        variables: { input: { id: invoiceId } },
        skip: !invoiceId,
      },
    },
  );

  if (loading || !data) {
    return <Loader />;
  }

  const { invoice } = data;

  const mutate = invoiceIsNotProjectExpensive(invoice)
    ? updateInvoice
    : updateProjectExpenseInvoice;

  const updateInvoiceByInput = (input) => {
    const variables = formatMutationInput(invoice)(input);
    return mutate({ variables });
  };

  const invoiceToForm = prepareInvoiceToForm(invoice);

  const formProps = {
    mutate: updateInvoiceByInput,
    project: invoice.project,
    isEdit: true,
    submitCaption: 'Save',
    formId: supplierInvoiceFormId,
    getSuccessMessage: () => 'Invoice was edited',
    initialValues: {
      paidAmount: invoice.total - invoice.balance,
      ...getCommonProjectFields(invoice.project),
      ...invoiceToForm,
      mailTo: mailToInitValues,
      formAction: SupplierInvoiceFormActions.edit,
      paymentDueDate:
        invoice?.paymentDueDate ||
        getSupplierInvoiceDueDateByTerms(invoiceToForm),
      isDueDateChanged: !!invoice?.paymentDueDateChangeReason,
      invoice,
    },
    projectId: invoice.project._id,
    taskSupplier,
    onCancel,
    invoiceType: invoice.type,
    withDueDate:
      isWithDueDateField(invoice) && hasAccessToUpdateAssetManufacture,
  };

  return <Form {...formProps} />;
}

EditProjectInvoiceForm.propTypes = {
  onCancel: func,
  invoiceId: string.isRequired,
  taskSupplier: shape({ _id: string.isRequired }),
};
