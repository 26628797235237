import React, { useEffect } from 'react';
import { func, number } from 'prop-types';
import styled from 'styled-components';
import { Input } from 'poly-book-admin';

const PercentInputS = styled(Input)`
  margin-left: 15px;
  margin-right: 5px;
  width: 100px;
`;

export function PercentInput({ markupPercent, onChange, ...props }) {
  useEffect(() => {
    if (markupPercent) {
      onChange(markupPercent);
    }
  }, [markupPercent]);

  return (
    <>
      <PercentInputS
        {...props}
        type="numberMask"
        decimalScale={2}
        maxValue={99}
        floatValue
        onChange={onChange}
      />
      <span>%</span>
    </>
  );
}

PercentInput.propTypes = {
  onChange: func,
  markupPercent: number,
};
