import * as R from 'ramda';
import React from 'react';
import { bool } from 'prop-types';
import { Select } from 'poly-book-admin';

import { distanceOptionsValues, NO_RADIUS_VALUE } from '../constants.js';

// getSelectOptions :: Boolean -> [String] -> [Pair String String]
const getSelectOptions = (withNoRadiusOption) =>
  R.compose(
    R.when(
      R.always(withNoRadiusOption),
      R.append({ value: NO_RADIUS_VALUE, label: 'No Radius' }),
    ),
    R.map(R.applySpec({ value: R.identity, label: R.concat(R.__, ' mi') })),
  );

export function DistanceSelect({ withNoRadiusOption = true, ...restProps }) {
  const selectProps = {
    options: getSelectOptions(withNoRadiusOption)(distanceOptionsValues),
    name: 'distanceSelect',
    ...restProps,
  };

  return <Select {...selectProps} />;
}

DistanceSelect.displayName = 'DistanceSelect';

DistanceSelect.propTypes = {
  withNoRadiusOption: bool,
};
