import * as R from 'ramda';
import { endOfDay } from 'date-fns';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useRouterParams, useRouterQuery } from 'poly-client-routing';
import { paginationToQueryParams } from 'poly-client-utils';
import { NOTHING_UI_STRING } from 'poly-constants';
import {
  TableCardWithPaginationContainer,
  useSetInitialPaginationOnMount,
  useSetItemsCount,
} from 'poly-admin-ui';
import {
  DefaultBodyWrapper,
  Loader,
  MainHeader,
  PageHeaderContainer,
  ToolBarBtnDivider,
} from 'poly-book-admin';

import { forceTitleCase } from 'poly-utils';
import { VendorLedgerTypes } from './constants.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { VendorLedgerReportTable } from './VendorLedgerReportTable.js';
import { VendorLedgerReportPrintPDFBtn } from './VendorLedgerReportPrintPDFBtn.js';
import { VendorLedgerReportExportXLBtn } from './VendorLedgerReportExportXLBtn.js';
import { GET_CUSTOMER_HISTORY_QUERY } from '../ClientHistoryPage/ClientHistoryPage.js';
import { GET_SUPPLIER_TRANSACTION_HISTORY } from '../SupplierPaymentHistoryPage/SupplierPaymentHistoryPage.js';
import {
  getTransactionUserFriendlyNumber,
  getTransactionUserFriendlyName,
  getTransactionUserFriendlyType,
  getTransactionInvoiceNumber,
} from './vendorLedgerReportUtils.js';
import { getSupplierTransactionUserFriendlyType } from '../SupplierPaymentHistoryPage/SupplierPaymentHistoryTable.js';

// prepareQueryInput :: (ID, Boolean, DateTime) -> QueryInput
const prepareQueryInput = (vendorId, isPayableVendor, date) =>
  R.compose(
    R.mergeRight({
      // it is safe cause we don't have older invoices
      startDate: new Date('2015'),
      endDate: R.compose(
        endOfDay,
        R.constructN(1, Date),
        R.defaultTo(new Date()),
      )(date),
      // we are going to don't filter by status
      invoiceStatus: '',
    }),
    R.ifElse(
      R.always(isPayableVendor),
      R.objOf('supplierId'),
      R.objOf('clientId'),
    ),
  )(vendorId);

// getPageTitle :: PageProps -> String
// PageProps = { vendorName: String, type: String }
const getPageTitle = R.compose(
  R.join(' '),
  R.juxt([
    R.prop('vendorName'),
    R.compose(R.concat(R.__, ' Ledger'), forceTitleCase, R.prop('type')),
  ]),
);

// prepareVendorTransactions :: ([String], Transaction -> String) -> QueryResult -> [Transaction]
const prepareVendorTransactions = (transactionsPath, getTransaction) =>
  R.compose(
    R.map(
      R.compose(
        R.mergeAll,
        R.juxt([
          R.pick(['_id', 'date', 'invoiceAmount', 'paymentAmount']),
          R.applySpec({
            invoiceNumber: getTransactionInvoiceNumber,
            transactionName: getTransactionUserFriendlyName,
            transactionType: getTransaction,
            transactionNumber: getTransactionUserFriendlyNumber,
            projectNumber: R.pathOr(NOTHING_UI_STRING, [
              'project',
              'projectId',
            ]),
          }),
        ]),
      ),
    ),
    R.pathOr([], [...transactionsPath, 'hits']),
  );

export function VendorLedgerReportPage() {
  const pagination = useSelector(R.prop('pagination'));
  const { type, date } = useRouterQuery(['type', 'date']);
  const { vendorId } = useRouterParams(['vendorId']);

  const isPayableVendor = type === VendorLedgerTypes.PAYABLE;
  const vendorNamePath = isPayableVendor
    ? ['getSupplierTransactionHistory', 'supplier', 'company', 'name']
    : ['getCustomerHistory', 'client', 'name'];
  const vendorTransactionsPath = isPayableVendor
    ? ['getSupplierTransactionHistory', 'transactions']
    : ['getCustomerHistory', 'transactions'];

  const getTransactionType = isPayableVendor
    ? getSupplierTransactionUserFriendlyType
    : getTransactionUserFriendlyType;

  const gqlQuery = isPayableVendor
    ? GET_SUPPLIER_TRANSACTION_HISTORY
    : GET_CUSTOMER_HISTORY_QUERY;

  useSetInitialPaginationOnMount();

  const getMemoQueryInput = useMemo(
    () => prepareQueryInput(vendorId, isPayableVendor, date),
    [vendorId, isPayableVendor, date],
  );

  const variables = {
    input: {
      ...getMemoQueryInput,
      ...paginationToQueryParams(pagination),
    },
  };

  const { data, loading } = useQuery(gqlQuery, { variables });

  useSetItemsCount(R.pathOr(0, [...vendorTransactionsPath, 'total']), data);

  const vendorName = useMemo(() => R.pathOr('', vendorNamePath, data), [data]);
  const vendorTransactions = useMemo(
    () =>
      prepareVendorTransactions(
        vendorTransactionsPath,
        getTransactionType,
      )(data),
    [data],
  );
  const title = useMemo(() => getPageTitle({ vendorName, type }), [vendorName]);

  const exportProps = { type, title, transactions: vendorTransactions };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>{title}</MainHeader>
        <FlexCenterAlign>
          <VendorLedgerReportPrintPDFBtn {...exportProps} />
          <ToolBarBtnDivider />
          <VendorLedgerReportExportXLBtn {...exportProps} />
        </FlexCenterAlign>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCardWithPaginationContainer>
          <VendorLedgerReportTable transactions={vendorTransactions} />
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </>
  );
}
