import React from 'react';
import styled from 'styled-components';
import { arrayOf, number, string } from 'prop-types';
import { Icon, Popover } from 'poly-book-admin';

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  height: auto;
  background: #12347a;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  z-index: 9999;

  > span {
    margin: 5px 0;
  }
`;

const PopoverTitle = styled.p`
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 3px 0;
`;

export function SpendReportErrorPopover({ rowIndex, totalLevelErrors }) {
  const iconElement = <Icon name="yellowWarning" size={16} />;
  const position = rowIndex === 0 ? 'left' : 'top';

  return (
    <Popover
      position={position}
      maxWidth="169px"
      title={iconElement}
      bgColor="#12347a"
      content={
        <PopoverContent>
          <PopoverTitle>Missing data</PopoverTitle>
          {totalLevelErrors
            ? totalLevelErrors.map((error) => <span key={error}>{error}</span>)
            : 'Please check that GLCode is missing'}
        </PopoverContent>
      }
    />
  );
}

SpendReportErrorPopover.defaultProps = {
  rowIndex: 0,
};

SpendReportErrorPopover.propTypes = {
  rowIndex: number,
  totalLevelErrors: arrayOf(string),
};
