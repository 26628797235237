import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { func, string } from 'prop-types';
import { FormCreator } from 'poly-book-admin/src/Form/FormCreator.js';
import {
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  commonSidebarFormSectionLayout,
} from 'poly-admin-ui/src/sidebar/commonSidebarFormConstants.js';
import { useNotificationContext } from 'poly-book-admin/src/Notificator/NotificatorProvider.js';
import { usePristineSubscribe } from 'poly-client-routing/src/hooks.js';

import { updateTaskTemplateFormId } from './constants.js';
import { taskTemplateFromSections } from './taskTemplateFromSections.js';
import {
  getTaskTemplateFormInitialValues,
  useTaskTemplateDetails,
} from '../useTaskTemplateDetails.js';

export const updateTaskTemplateMutation = gql`
  mutation updateTaskTemplate($id: ID!, $update: TaskTemplateInput!) {
    updateTaskTemplate(id: $id, update: $update) {
      _id
    }
  }
`;

export const UPDATE_TASK_TEMPLATE_SUCCESS_MSG =
  'Task template was successfully updated';

export function UpdateTaskTemplateFrom({ taskTemplateId, onClose }) {
  const [updateTaskTemplate] = useMutation(updateTaskTemplateMutation);
  const { showSuccessNotification } = useNotificationContext();

  const { data, loading } = useTaskTemplateDetails(taskTemplateId);

  const pristineSubscribeProps = usePristineSubscribe({
    id: updateTaskTemplateFormId,
  });

  const handleSubmit = async (formData) => {
    await updateTaskTemplate({
      variables: { id: taskTemplateId, update: formData },
    });
    showSuccessNotification(UPDATE_TASK_TEMPLATE_SUCCESS_MSG);
    onClose();
  };

  if (loading) {
    return null;
  }

  const initialValues = getTaskTemplateFormInitialValues(data);

  return (
    <FormCreator
      id={updateTaskTemplateFormId}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      sections={taskTemplateFromSections}
      layout={commonSidebarFormLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      {...pristineSubscribeProps}
    />
  );
}

UpdateTaskTemplateFrom.propTypes = {
  onClose: func.isRequired,
  taskTemplateId: string.isRequired,
};
