import React from 'react';
import styled from 'styled-components';
import { string, func, bool, shape, oneOfType, node } from 'prop-types';
import { Text, Button } from 'poly-book-admin';

const ModalWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const ModalContentS = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  margin: 15px 0;
`;

const ModalButtonsWrapperS = styled.div`
  display: flex;
  flex-direction: row;

  > button:first-child {
    margin-right: 15px;
  }
`;

export function SimpleModalLayout({
  title,
  loading,
  children,
  onConfirm,
  btnCaption,
  btnContext = {},
  closeCurrentModal,
}) {
  return (
    <ModalWrapperS>
      <Text size="16px" lineHeight="24px">
        {title}
      </Text>
      <ModalContentS>{children}</ModalContentS>
      <ModalButtonsWrapperS>
        <Button
          size="small"
          type="button"
          disabled={loading}
          styleType="basicSecondary"
          onClick={closeCurrentModal}
        >
          Cancel
        </Button>
        <Button
          size="small"
          loader={loading}
          onClick={onConfirm(closeCurrentModal)}
          {...btnContext}
        >
          {btnCaption || 'Approve'}
        </Button>
      </ModalButtonsWrapperS>
    </ModalWrapperS>
  );
}

SimpleModalLayout.propTypes = {
  loading: bool,
  btnCaption: string,
  title: string.isRequired,
  onConfirm: func.isRequired,
  closeCurrentModal: func.isRequired,
  children: oneOfType([string, node]).isRequired,
  btnContext: shape({ formId: string, type: string }),
};
