import * as R from 'ramda';
import { assocBy } from 'poly-utils';

export const DEFAULT_MAX = 50;

// checkGroupsLength :: [String] => String
export const checkGroupsLength = R.ifElse(
  R.propSatisfies(R.lt(1), 'length'),
  R.last,
  R.F,
);

// mapLabelToIndex :: [Section] => [Section]
export const mapLabelToIndex = R.pipe(
  R.prop('sections'),
  R.map(R.when(R.propSatisfies(R.isNil, 'id'), assocBy('id', R.prop('label')))),
);

// withFormSpyCondition :: SectionOrField -> Boolean
const withFormSpyCondition = R.anyPass([
  R.has('renderIf'),
  R.prop('withFormData'),
  R.path(['field', 'withFormData']),
]);

// sortByOrder :: [SectionOrField] -> [SectionOrField]
export const sortByOrder = R.sortBy(R.propOr(DEFAULT_MAX, 'order'));

// withVisibleByValues :: FormValues -> SectionOrField -> SectionOrField
const withVisibleByValues = R.curry((values, field) =>
  R.ifElse(
    R.prop('renderIf'),
    ({ renderIf, ...props }) => ({
      ...props,
      isVisible: R.ifElse(renderIf, R.T, R.F)(values),
    }),
    R.assoc('isVisible', true),
  )(field),
);

// configRenderFields :: [Field] -> Object -> [Field]
export const configRenderFields = R.curry((fields, values) =>
  R.map(
    R.pipe(
      R.when(withFormSpyCondition, R.mergeRight({ values })),
      withVisibleByValues(values),
      R.when(
        R.path(['field', 'subFields']),
        R.converge(R.assocPath(['field', 'subFields']), [
          R.compose(
            configRenderFields(R.__, values),
            R.pathOr([], ['field', 'subFields']),
          ),
          R.identity,
        ]),
      ),
    ),
  )(fields),
);

// isNotAllowed :: Object -> SectionOrField -> Boolean
// eslint-disable-next-line import/no-unused-modules
export const isNotAllowed =
  (values) =>
  ({ renderIf, leaveValues }) => {
    if (renderIf && !leaveValues) {
      return !renderIf(values);
    }
    return false;
  };

// transformNameToPath :: Field -> [String]
// eslint-disable-next-line import/no-unused-modules
export const transformNameToPath = R.pipe(
  R.path(['field', 'name']),
  R.split('.'),
);

// pickAllFields :: [Section] -> [Field]
// eslint-disable-next-line import/no-unused-modules
export const pickAllFields = R.pipe(R.map(R.prop('fields')), R.flatten);

// nullToNotRendered :: [Section] -> Object -> Object
const nullToNotRendered = R.curry((config, values) =>
  R.compose(
    R.reduce(R.flip(R.assoc(R.__, null)), values),
    R.map(R.pipe(transformNameToPath, R.prop(0))),
    pickAllFields,
    R.filter(isNotAllowed(values)),
  )(config),
);

// nullToNotRendered :: [Section] -> Object -> Object
export const nullToNotRenderedDeep = R.curry((config, values) =>
  R.compose(
    nullToNotRendered(config),
    R.reduce(R.flip(R.assocPath(R.__, null)), values),
    R.map(transformNameToPath),
    R.filter(isNotAllowed(values)),
    pickAllFields,
  )(config),
);

// isSectionDependsOnValues :: Section -> Boolean
export const isSectionDependsOnValues = R.converge(R.or, [
  withFormSpyCondition,
  R.pipe(
    R.propOr([], 'fields'),
    R.filter(withFormSpyCondition),
    R.complement(R.isEmpty),
  ),
]);

// isEqual :: (Any, Any) -> Boolean
export const isEquals = (oldVal, newVal) =>
  R.all(R.isNil, [oldVal, newVal]) || R.equals(oldVal, newVal);

// trimFormValues :: FormValues -> FormValues
// FormValues = Object
export const trimFormValues = R.map(R.when(R.is(String), R.trim));

export const getFormData = (withFormData, values) =>
  withFormData ? { formData: values } : {};
