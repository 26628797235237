import * as R from 'ramda';
import {
  supplierInvoiceRequestOptions,
  taskActionTypes,
  ListingProjectActionsSpecificFields,
  ProjectSupplierStatus,
  ProjectType,
} from 'poly-constants';
import {
  isRMProject,
  alwaysNewDate,
  applySpecWithFields,
  isNilOrEmpty,
  nullToUndefinedDeep,
  supplierHaveAccountOrServiceEmail,
  assocBy,
} from 'poly-utils';

import {
  clientGLCodesNotFilledErrorMsg,
  COMPLETE_TASK_DEFAULT_UPDATE_MSG,
  CREATE_TASK_DEFAULT_UPDATE_MSG,
  initialCommonValues,
  projectPONotFilledErrorMsg,
  projectSubCategoryNotFilledErrorMsg,
  taskActionUpdateTypesMap,
  UPDATE_TASK_DEFAULT_MSG,
  workCompleteErrorMsg,
} from './constants.js';
import {
  getEmailsToSendTo,
  mailToInitValues,
} from '../../fields/MailTo/index.js';
import { mapAttachmentsBeforeUpdate } from '../../addUpdateForm/index.js';
import {
  defaultBlackList,
  getTaskProjectId,
  prepareDetailsString,
} from '../../../../utils/tasks.js';
import {
  getEmailByPath,
  getEmailsByPath,
} from '../../fields/MailTo/mailToUtils.js';

// prepareAttachments :: Object -> [Attachment]
export const prepareAttachments = R.pipe(
  R.path(['details', 'attachments']),
  mapAttachmentsBeforeUpdate,
);

// isSupplierInvoiceTasksWithInvoice :: [Task] -> ID -> Boolean
const isSupplierInvoiceTasksWithInvoice = (projectTasks) => (supplierId) =>
  R.compose(
    R.map(R.pathSatisfies(R.is(String), ['supplierInvoice', '_id'])),
    R.filter(R.pathEq(['supplier', '_id'], supplierId)),
  )(projectTasks);

// prepareSuppliersInitialRates :: Input -> [FormattedSuppliers]
// Input = {
//    suppliers: [Suppliers]
//    projectInvoices: [Invoice]
// }
// eslint-disable-next-line import/no-unused-modules
export const prepareSuppliersInitialRates = ({ suppliers, projectTasks }) =>
  R.map(
    R.compose(
      assocBy(
        'supplierRequest',
        R.ifElse(
          R.prop('supplierHaveAccountEmail'),
          R.always(supplierInvoiceRequestOptions.sendNow),
          R.always(supplierInvoiceRequestOptions.manualFollowUp),
        ),
      ),
      R.applySpec({
        supplierId: R.prop('_id'),
        supplierName: R.path(['company', 'name']),
        rating: R.always(0),
        readOnlyRating: R.prop('rating'),
        isRatedOnComplete: R.prop('isRatedOnComplete'),
        supplierHaveAccountEmail: supplierHaveAccountOrServiceEmail,
        hideRequestOptions: R.compose(
          R.all(R.identity),
          isSupplierInvoiceTasksWithInvoice(projectTasks),
          R.prop('_id'),
        ),
      }),
    ),
  )(suppliers);

const commonUpdateFields = {
  message: R.path(['details', 'text']),
  mentions: R.path(['details', 'mentions']),
  filesAttachments: prepareAttachments,
  emailTo: R.pipe(R.prop('mailTo'), getEmailsToSendTo),
};

/**
 * assocNextTaskFields :: FormFields -> FormattedFormFields
 */
// eslint-disable-next-line import/no-unused-modules
export const assocNextTaskFields = R.compose(
  R.omit(['dueDate', 'managerId', 'nextTask', 'isComplete']),
  R.when(
    R.prop('nextTask'),
    applySpecWithFields({
      nextTaskUpdate: {
        nextTaskDueDate: R.path(['nextTask', 'dueDate']),
        assignNextTaskTo: R.prop('managerId'),
      },
    }),
  ),
);

/**
 * prepareDataToMutation :: [String] => FormData -> MutationData
 */
// eslint-disable-next-line import/no-unused-modules
export const prepareDataToMutation = (customTaskFields) =>
  R.compose(
    R.objOf('input'),
    R.omit(customTaskFields),
    R.when(
      R.prop('isComplete'),
      R.omit([
        'dueDate',
        'managerId',
        'taskError',
        'isComplete',
        'description',
      ]),
    ),
    R.when(
      R.anyPass([R.prop('isEdit'), R.prop('isComplete')]),
      R.omit(['collection', 'isDefaultTask', 'isEdit', 'nextTask']),
    ),
    R.when(
      R.both(R.prop('isDefaultTask'), R.prop('isComplete')),
      assocNextTaskFields,
    ),
    R.omit([
      'isAdd',
      'entity',
      'mailTo',
      'details',
      'taskType',
      'workCompletionDate',
      'supplierUpdateDetails',
      'supplierRatings',
      'projectType',
    ]),
    R.when(
      R.both(
        R.pathSatisfies(R.isEmpty, ['update', 'message']),
        R.pathSatisfies(R.isEmpty, ['update', 'filesAttachments']),
      ),
      R.dissoc('update'),
    ),
    applySpecWithFields({ update: commonUpdateFields }),
    nullToUndefinedDeep,
  );

// getNotificationConfigByProp :: String -> { document: { project: Project } } -> Boolean
const getNotificationConfigByProp = (prop) =>
  R.pathOr(false, [
    'document',
    'project',
    'client',
    'configs',
    'notifications',
    'projectClientStatusUpdateDefaultMailTo',
    prop,
  ]);

// prepareMailTo :: { document: { project: Project } } -> MailToValue
// MailToValue = {
//    requestor: MailToValueConfig
//    siteContact: MailToValueConfig
//    clientManager: MailToValueConfig
// }
// MailToValueConfig = {
//    shouldSend: Boolean
//    emails: [String]
// }
export const prepareMailTo = R.applySpec({
  requestor: {
    shouldSend: getNotificationConfigByProp('toRequester'),
    emails: getEmailByPath(['document', 'project', 'contact']),
  },
  siteContact: {
    shouldSend: getNotificationConfigByProp('toSiteContact'),
    emails: getEmailByPath(['document', 'project', 'siteContact']),
  },
  clientManager: {
    shouldSend: getNotificationConfigByProp('toClientManager'),
    emails: getEmailsByPath(
      ['document', 'project', 'client', 'clientManagers'],
      R.prop('email'),
    ),
  },
});

const mailToTaskActionTypes = [
  taskActionTypes.CONFIRM_SATISFACTION,
  taskActionTypes.CONFIRM_SCOPE,
  taskActionTypes.SUPPLIER_SCHEDULING,
  taskActionTypes.PROJECT_COMPLETE,
];

// getTaskUpdateFormInitValuesBase :: Task -> FormInitialValues
const getTaskUpdateFormInitValuesBase = R.compose(
  R.omit(['manager', 'document', 'action', 'project']),
  applySpecWithFields({
    taskType: R.prop('action'),
    projectType: R.path(['project', 'type']),
    details: R.always(initialCommonValues),
    mailTo: R.ifElse(
      R.propSatisfies(R.includes(R.__, mailToTaskActionTypes), 'action'),
      R.compose(R.mergeRight(mailToInitValues), prepareMailTo),
      R.always(mailToInitValues),
    ),
    managerId: R.pathOr(null, ['manager', '_id']),
    nextTask: R.ifElse(
      R.both(R.prop('isDefaultTask'), R.prop('nextTask')),
      R.applySpec({
        dueDate: R.pipe(
          R.path(['nextTask', 'dueDate']),
          R.when(R.isNil, alwaysNewDate),
        ),
        managerId: R.path(['manager', '_id']),
        complete: R.path(['nextTask', 'complete']),
        nextTaskDescription: R.path(['nextTask', 'description']),
      }),
      R.always(null),
    ),
    entity: R.ifElse(
      R.complement(R.prop('isDefaultTask')),
      R.applySpec({
        entityInfo: R.converge(prepareDetailsString, [
          R.prop('document'),
          R.ifElse(
            R.propSatisfies(R.isNil, 'action'),
            R.always([...defaultBlackList, 'email']),
            R.always(null),
          ),
        ]),
        projectId: R.pipe(R.prop('document'), getTaskProjectId),
      }),
      R.always(null),
    ),
    workCompletionDate: R.cond([
      [
        R.path(['document', 'project', 'workCompletionDate']),
        R.path(['document', 'project', 'workCompletionDate']),
      ],
      [
        R.propSatisfies(
          R.includes(R.__, [
            taskActionTypes.PROJECT_COMPLETE,
            taskActionTypes.CLOSE_PROJECT,
          ]),
          'action',
        ),
        R.always(new Date()),
      ],
      [R.T, R.always(null)],
    ]),
    supplierRatings: R.compose(
      prepareSuppliersInitialRates,
      R.applySpec({
        suppliers: R.compose(
          R.filter(R.propEq('statusInProject', ProjectSupplierStatus.assigned)),
          R.pathOr([], ['project', 'suppliers']),
        ),
        projectTasks: R.pathOr([], ['project', 'tasks', 'hits']),
      }),
    ),
  }),
  R.pick([
    'collection',
    'description',
    'dueDate',
    'manager',
    'document',
    'action',
    'nextTask',
    'taskError',
    'isDefaultTask',
    'project',
    'isEdit',
    'isComplete',
    'scheduleDate',
    'priority',
  ]),
);

// getListingProjectTaskSpecificFields :: Task -> FormInitialValues
const getListingProjectTaskSpecificFields = R.converge(R.pick, [
  R.compose(
    R.propOr([], R.__, ListingProjectActionsSpecificFields),
    R.prop('action'),
  ),
  R.pathOr({}, ['project', 'listing']),
]);

// getTaskUpdateFormInitValues :: Task -> FormInitialValues
export const getTaskUpdateFormInitValues = R.converge(R.mergeRight, [
  getTaskUpdateFormInitValuesBase,
  getListingProjectTaskSpecificFields,
]);

/**
 * getActionGeneralParams :: FormData -> ActionParams
 */
// eslint-disable-next-line import/no-unused-modules
export const getActionGeneralParams = R.compose(
  R.mergeAll,
  R.juxt([
    R.ifElse(
      R.propEq('taskType', taskActionTypes.SUPPLIER_SCHEDULING),
      R.pick(['projectId', 'scheduleDate']),
      R.pick(['projectId']),
    ),
    R.applySpec({
      nextTaskDueDate: R.path(['nextTask', 'dueDate']),
      assignNextTaskTo: R.prop('managerId'),
    }),
  ]),
);

// getActionCustomFieldsNames :: TaskAction -> [String]
const getActionCustomFieldsNames = R.propOr(
  [],
  R.__,
  ListingProjectActionsSpecificFields,
);

// getActionCustomFields :: TaskAction -> FormData -> ActionParams
const getActionCustomFields = (action) =>
  R.pick(getActionCustomFieldsNames(action));

// getActionParamsWithCustomFields :: TaskAction -> FormData -> ActionParams
const getActionParamsWithCustomFields = (action) =>
  R.converge(R.mergeRight, [
    getActionGeneralParams,
    getActionCustomFields(action),
  ]);

/**
 * getActionParams :: FormData -> ActionParams
 */
// eslint-disable-next-line import/no-unused-modules
export const getActionParams = (action) =>
  R.cond([
    [
      R.always(action === taskActionTypes.CLOSE_PROJECT),
      R.pick(['projectId', 'workCompletionDate']),
    ],
    [
      R.always(action === taskActionTypes.PROJECT_COMPLETE),
      R.applySpec({
        projectId: R.prop('projectId'),
        nextTaskDueDate: R.path(['nextTask', 'dueDate']),
        assignNextTaskTo: R.prop('managerId'),
        workCompletionDate: R.prop('workCompletionDate'),
        supplierRatingsAndRequests: R.compose(
          R.map(
            R.compose(
              R.mergeAll,
              R.juxt([
                R.pick(['supplierId', 'supplierRequest']),
                R.ifElse(
                  R.compose(R.is(Number), R.prop('rating')),
                  R.pick(['rating']),
                  R.always({ rating: 0 }),
                ),
              ]),
            ),
          ),
          R.propOr([], 'supplierRatings'),
        ),
        referralFee: R.prop('referralFee'),
      }),
    ],
    [R.T, getActionParamsWithCustomFields(action)],
  ]);

// getUpdateActionName :: TaskAction -> String
const getUpdateActionName = R.ifElse(
  R.prop(R.__, taskActionUpdateTypesMap),
  R.prop(R.__, taskActionUpdateTypesMap),
  R.identity,
);

// prepareUpdateWithActionData :: Action -> FormData -> MutationData
export const prepareUpdateWithActionData = (action) =>
  R.compose(
    R.objOf('input'),
    R.applySpec({
      update: R.applySpec(commonUpdateFields),
      [getUpdateActionName(action)]: getActionParams(action),
    }),
  );

// validateProjectNotClosedTasks :: {tasks: [Task]} -> ErrorString
const validateProjectNotClosedTasks = R.compose(
  R.ifElse(
    R.complement(R.isEmpty),
    R.pipe(
      R.map((task) => `"${task}"`),
      R.join(', '),
      R.concat(
        'You can not close this project until the following tasks are complete: ',
      ),
    ),
    R.always(null),
  ),
  R.map(R.prop('description')),
  R.reject(
    R.anyPass([
      R.propEq('action', taskActionTypes.CLOSE_PROJECT),
      R.prop('complete'),
    ]),
  ),
  R.defaultTo([]),
  R.prop('tasks'),
);

// validateIfProjectPOIsRequired :: {client: Client, adminPurchaseOrder: AdminPurchaseOrder } -> ErrorString
const validateIfProjectPOIsRequired = R.ifElse(
  R.allPass([
    R.path(['client', 'configs', 'enablePurchaseOrder']),
    R.path(['client', 'configs', 'isPORequiredForProject']),
    R.complement(R.path(['adminPurchaseOrder', '_id'])),
  ]),
  R.always(projectPONotFilledErrorMsg),
  R.always(null),
);

/**
 * getCloseProjectError :: [Task] -> ErrorString
 */
export const getCloseProjectError = R.compose(
  R.ifElse(R.isEmpty, R.always(null), R.join(', ')),
  R.reject(R.isNil),
  R.juxt([validateProjectNotClosedTasks, validateIfProjectPOIsRequired]),
);

/**
 * getProjectCompleteError :: Project -> ErrorString
 */
export const getProjectCompleteError = R.compose(
  R.ifElse(R.isEmpty, R.always(null), R.join(', ')),
  R.reject(R.isNil),
  R.juxt([
    R.compose(
      R.ifElse(
        R.allPass([
          R.complement(R.propEq('type', ProjectType.LISTING)),
          R.compose(R.equals(false), R.propOr(false, 'noSupplierRequired')),
          R.compose(R.isEmpty, R.defaultTo([]), R.prop('suppliers')),
        ]),
        R.always(workCompleteErrorMsg),
        R.always(null),
      ),
      R.prop('project'),
    ),
    R.compose(
      R.ifElse(
        R.allPass([
          R.path(['client', 'enableGlCodes']),
          R.compose(isNilOrEmpty, R.prop('clientGLCode')),
          isRMProject,
        ]),
        R.always(clientGLCodesNotFilledErrorMsg),
        R.always(null),
      ),
      R.path(['task', 'document', 'project']),
    ),
    R.compose(
      R.ifElse(
        R.allPass([
          R.prop('enableProjectSubCategory'),
          R.compose(isNilOrEmpty, R.prop('subCategory')),
          isRMProject,
        ]),
        R.always(projectSubCategoryNotFilledErrorMsg),
        R.always(null),
      ),
      R.mergeAll,
      R.juxt([
        R.path(['task', 'document', 'project']),
        R.path(['payload', 'project', 'client', 'configs']),
      ]),
    ),
  ]),
);

/**
 * mapRatingToSupplier :: FormData -> InitialRates -> UpdatedRates
 */
export const mapRatingToSupplier = R.curry((data, rateInfo) =>
  R.map(
    R.when(
      R.propEq('supplierId', R.prop('supplierId', rateInfo)),
      R.mergeLeft({
        rating: R.path(['rating', 'average'], rateInfo),
        supplierRequest: R.prop('supplierRequest', rateInfo),
      }),
    ),
    data,
  ),
);

/**
 * validateUpdateFunc :: [[Validator, Error]] -> FieldValue -> FormData -> Error
 */
// eslint-disable-next-line import/no-unused-modules
export const validateUpdateFunc = R.curry((validators, value, allValues) =>
  R.ifElse(
    R.anyPass([R.isNil, () => R.prop('isAdd', allValues)]),
    R.always(undefined),
    R.pipe(
      R.find(([validator]) => !validator(value)),
      R.prop(1),
      R.when(R.isNil, R.always(undefined)),
    ),
  )(validators),
);

// updateTaskById :: String -> (Task -> Task) -> [Task] -> [Task]
// Task = Object
// eslint-disable-next-line import/no-unused-modules
export const updateTaskById = R.curry((taskId, update, tasks) =>
  R.converge(R.over(R.__, update, R.__), [
    R.compose(R.lensIndex, R.findIndex(R.propEq('_id', taskId))),
    R.identity,
  ])(tasks),
);

// completeTaskByPath :: ([String], String) -> Object -> Object
export const completeTaskByPath = (path, taskId) =>
  R.over(
    R.lensPath(path),
    updateTaskById(taskId, R.mergeLeft({ complete: true })),
  );

const assocDefaultUpdateMsg = (msg) =>
  R.when(
    R.pathSatisfies(isNilOrEmpty, ['details', 'text']),
    R.assocPath(['details', 'text'], msg),
  );

// prepareCreateTaskDataToMutation :: FormData -> TaskCreateInput
// FormData, TaskCreateInput = Object
export const prepareCreateTaskDataToMutation = R.compose(
  prepareDataToMutation([]),
  assocDefaultUpdateMsg(CREATE_TASK_DEFAULT_UPDATE_MSG),
);

// prepareCompleteTaskDataToMutation :: ID -> FormData -> TaskUpdateInput
// FormData, TaskUpdateInput = Object
export const prepareCompleteTaskDataToMutation = (taskId) =>
  R.compose(
    R.compose(
      R.assoc('id', taskId),
      prepareDataToMutation([]),
      assocDefaultUpdateMsg(COMPLETE_TASK_DEFAULT_UPDATE_MSG),
      R.mergeLeft({ complete: true }),
    ),
  );

// prepareUpdateTaskDataToMutation :: (ID, [String]) -> FormData -> TaskUpdateInput
// FormData, TaskUpdateInput = Object
export const prepareUpdateTaskDataToMutation = (
  taskId,
  customTaskFields = [],
) =>
  R.compose(
    R.assoc('id', taskId),
    prepareDataToMutation(customTaskFields),
    assocDefaultUpdateMsg(UPDATE_TASK_DEFAULT_MSG),
  );
