import React, { useState } from 'react';
import { isNilOrEmpty } from 'poly-utils';

import { useReactiveQuery } from 'poly-client-utils';
import { PrintChecksDetailsTable } from './PrintChecksDetailsTable.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { PrintChecksDetailsPageHeader } from './PrintChecksDetailsPageHeader.js';
import {
  GET_SUPPLIER_CHECKS_QUERY,
  GET_SUPPLIERS_SUBSCRIPTION,
} from '../PrintChecksPage/PrintChecksTable.js';
import { prepareSupplierChecksFilters } from '../PrintChecksPage/printChecksUtils.js';

export function PrintChecksDetailsPage() {
  const [filters, setFilters] = useState(null);

  const queryOptions = {
    variables: { input: prepareSupplierChecksFilters(filters) },
    skip: isNilOrEmpty(filters),
    fetchPolicy: 'network-only',
  };
  const subscriptionOptions = {
    variables: { input: prepareSupplierChecksFilters(filters) },
  };

  const { data, loading } = useReactiveQuery(
    GET_SUPPLIER_CHECKS_QUERY,
    GET_SUPPLIERS_SUBSCRIPTION,
    { queryOptions, subscriptionOptions },
  );

  return (
    <PageWithSearchHeader headerHeight="190px">
      <PrintChecksDetailsPageHeader loading={loading} setFilters={setFilters} />
      <PrintChecksDetailsTable data={data} loading={loading} />
    </PageWithSearchHeader>
  );
}
