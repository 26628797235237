import React from 'react';
import * as R from 'ramda';
import { arrayOf } from 'prop-types';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { formatCurrency, formatDate } from 'poly-utils';
import { JournalPaymentMode, NOTHING_UI_STRING } from 'poly-constants';

import {
  SupplierPaymentHistoryTableS,
  SupplierPaymentHistoryFooterContainer,
} from './components.js';
import { supplierPaymentHistoryTransactionPropType } from './propTypes.js';
import {
  getTransactionUserFriendlyName,
  getTransactionUserFriendlyNumber,
} from '../VendorLedgerReport/vendorLedgerReportUtils.js';

// isPaymentModeEquals :: String -> Transaction -> Boolean
const isPaymentModeEquals = (mode) => R.propEq('paymentMode', mode);

// getPaymentPrefixByPaymentMode :: Transaction -> String
const getPaymentPrefixByPaymentMode = R.cond([
  [isPaymentModeEquals(JournalPaymentMode.ACH), R.always('ACH / ')],
  [isPaymentModeEquals(JournalPaymentMode.CHECK), R.always('Ck / ')],
  [isPaymentModeEquals(JournalPaymentMode.CREDIT_CARD), R.always('CC / ')],
  [isPaymentModeEquals(JournalPaymentMode.WIRE), R.always('WIRE / ')],
  [R.T, R.always('')],
]);

// getSupplierTransactionUserFriendlyType :: Transaction -> String
export const getSupplierTransactionUserFriendlyType = R.ifElse(
  R.prop('paymentMode'),
  R.converge(R.concat, [getPaymentPrefixByPaymentMode, R.prop('accountName')]),
  R.always(NOTHING_UI_STRING),
);

const supplierTransactionHistoryConfig = [
  ['Transaction', getTransactionUserFriendlyName],
  ['Date', R.compose(formatDate, R.prop('date'))],
  ['Check Num', getTransactionUserFriendlyNumber],
  ['Type', getSupplierTransactionUserFriendlyType],
  ['Project #', R.pathOr(NOTHING_UI_STRING, ['project', 'projectId'])],
  ['Invoice Number', R.path(['invoice', 'invoiceNumber'])],
  ['Invoice', R.compose(formatCurrency, R.prop('invoiceAmount'))],
  ['Payment', R.compose(formatCurrency, R.prop('paymentAmount'))],
];

// getTotalByProp :: (String, [Transaction]) -> String
const getTotalByProp = (prop, rows) =>
  R.compose(formatCurrency, R.sum, R.map(R.propOr(0, prop)))(rows);

function TotalFooter({ rows }) {
  return (
    <SupplierPaymentHistoryFooterContainer>
      <td>Total</td>
      <td>{getTotalByProp('invoiceAmount', rows)}</td>
      <td>{getTotalByProp('paymentAmount', rows)}</td>
    </SupplierPaymentHistoryFooterContainer>
  );
}

TotalFooter.propTypes = {
  rows: arrayOf(supplierPaymentHistoryTransactionPropType),
};

export function SupplierPaymentHistoryTable({ transactions }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    supplierTransactionHistoryConfig,
    transactions,
  );

  return (
    <SupplierPaymentHistoryTableS
      {...tableProps}
      TableFooter={TotalFooter}
      footerHeight={60}
    />
  );
}

SupplierPaymentHistoryTable.propTypes = {
  transactions: arrayOf(supplierPaymentHistoryTransactionPropType),
};
