import * as R from 'ramda';
import { arrayOf, bool, shape, string } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';
import { pathOrNothingUI } from 'poly-client-utils';
import React from 'react';
import { formatTimesheetDuration } from 'poly-utils';
import { Loader } from 'poly-book-admin';
import { TimesheetTableS, TimesheetTableEmpty } from './styles.js';
import { DeleteTimesheetButton, EditTimeSheetBtn } from './TimesheetButtons.js';
import { timesheetTypesMap } from '../../forms/timesheetForm/constants.js';
import {
  formatTimesheetEntriesTimes,
  formatDateCurried,
  useMapMyTimesheetForTable,
} from './utils.js';
import { ProjectLink } from '../../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';

export const formatProjectLink = R.ifElse(
  R.identity,
  R.pipe(
    R.applySpec({
      projectId: R.prop('projectId'),
      children: R.prop('projectId'),
      type: R.prop('type'),
    }),
    ProjectLink,
  ),
  R.always(NOTHING_UI_STRING),
);

const timesheetsTableConfig = (myTimesheets) => [
  [
    'Day, Date',
    R.compose(formatDateCurried('EEE, MM/dd/yyyy'), R.prop('date')),
  ],
  ['Project ID', R.compose(formatProjectLink, R.prop('project'))],
  ['Type', R.compose(R.prop(R.__, timesheetTypesMap), R.prop('type'))],
  ['Time In', formatTimesheetEntriesTimes('timeIn')],
  ['Time Out', formatTimesheetEntriesTimes('timeOut')],
  ['Total', R.compose(formatTimesheetDuration, R.prop('totalTimeInMinutes'))],
  ['Notes', pathOrNothingUI(['notes'])],
  ['', (props) => <EditTimeSheetBtn {...props} myTimesheets={myTimesheets} />],
  [' ', DeleteTimesheetButton],
];

export function TimesheetsTable({ myTimesheets, loading, ...restProps }) {
  const myTimesheetsTableProps = useMapMyTimesheetForTable({ myTimesheets });

  if (loading) return <Loader />;
  if (!myTimesheets.length) return <TimesheetTableEmpty />;

  const headers = R.map(R.nth(0), timesheetsTableConfig(myTimesheets));
  const columns = R.map(R.nth(1), timesheetsTableConfig(myTimesheets));

  return (
    <TimesheetTableS
      {...restProps}
      {...myTimesheetsTableProps}
      myTimesheets={myTimesheets}
      headers={headers}
      columns={columns}
      enableRowHover
    />
  );
}

TimesheetsTable.displayName = 'TimesheetsTable';

TimesheetsTable.propTypes = {
  myTimesheets: arrayOf(
    shape({
      date: string,
    }),
  ),
  loading: bool,
};
