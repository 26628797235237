import * as R from 'ramda';
import React from 'react';
import { bool } from 'prop-types';
import { PopoverLink } from 'poly-book-admin';
import { taskActionTypes } from 'poly-constants';
import { gql, useQuery } from '@apollo/client';

import { ThreeDotsPopover } from '../../sidebarComponents.js';
import { useSidebarTasksTableHandlers } from './useSidebarTasksTableHandlers.js';

// displayRemoveTaskOption :: Task -> Boolean
const displayRemoveTaskOption = R.allPass([
  R.propEq('action', taskActionTypes.SUPPLIER_INVOICE),
  R.prop('allowedRemoveSupplierInvoiceTask'),
]);

// isCompletedReportOnlyClientInvoiceTask :: Task -> Boolean
const isCompletedReportOnlyClientInvoiceTask = R.both(
  R.propEq('complete', true),
  R.propEq('action', taskActionTypes.REPORT_ONLY_CLIENT_INVOICE),
);

const REPORT_ONLY_CLIENT_INVOICE_ID_QUERY = gql`
  query REPORT_ONLY_CLIENT_INVOICE_ID_QUERY($projectId: ID!) {
    reportOnlyClientInvoice(projectId: $projectId) {
      _id
    }
  }
`;

// isCompletedClientInvoiceTask :: Task -> Boolean
const isCompletedClientInvoiceTask = R.both(
  R.propEq('action', taskActionTypes.REPORT_ONLY_CLIENT_INVOICE),
  R.propEq('complete', true),
);

// getQueryProjectId :: { project: Project } -> ID
const getQueryProjectId = R.path(['project', '_id']);

// getReportOnlyClientInvoiceId :: { reportOnlyClientInvoice: ReportOnlyClientInvoice } -> ID
const getReportOnlyClientInvoiceId = R.path(['reportOnlyClientInvoice', '_id']);

export function TaskMoreButton(props) {
  const {
    loading,
    onEditTask,
    onAddTaskUpdate,
    onVoidClientInvoice,
    onUpdateClientInvoice,
    onRemoveSupplierInvoiceTask,
  } = useSidebarTasksTableHandlers(props);

  const { data } = useQuery(REPORT_ONLY_CLIENT_INVOICE_ID_QUERY, {
    variables: { projectId: getQueryProjectId(props) },
    fetchPolicy: 'network-only',
    skip: !isCompletedClientInvoiceTask(props),
  });

  const reportOnlyClientInvoiceId = getReportOnlyClientInvoiceId(data);

  if (props.readOnly) {
    return null;
  }

  const onRemovePopoverLinkClick = () => {
    if (loading) return;

    onRemoveSupplierInvoiceTask(props);
  };

  return (
    <ThreeDotsPopover
      content={
        <>
          {!props.complete && (
            <PopoverLink onClick={onEditTask}>Edit Task</PopoverLink>
          )}
          <PopoverLink onClick={onAddTaskUpdate}>Add an Update</PopoverLink>
          {displayRemoveTaskOption(props) && (
            <PopoverLink onClick={onRemovePopoverLinkClick}>Remove</PopoverLink>
          )}
          {isCompletedReportOnlyClientInvoiceTask(props) && (
            <>
              <PopoverLink
                onClick={() => onUpdateClientInvoice({ isEdit: true })}
              >
                Update Invoice
              </PopoverLink>
              <PopoverLink
                onClick={() => onVoidClientInvoice(reportOnlyClientInvoiceId)}
              >
                Void Invoice
              </PopoverLink>
            </>
          )}
        </>
      }
    />
  );
}

TaskMoreButton.propTypes = { complete: bool.isRequired, readOnly: bool };
