import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { paginationToQueryParams, useReactiveQuery } from 'poly-client-utils';
import { useTableSorting, prepareSortingWithSearchText } from 'poly-admin-ui';

// getProjectsByPath :: [String] -> Object -> [Project]
const getProjectsByPath = (path) => R.pathOr([], [...path, 'hits']);

// getProjectsTotalByPath :: [String] -> Object -> Number
const getProjectsTotalByPath = (path) => R.pathOr(0, [...path, 'total']);

export const useProjectsBySearch = ({
  gqlQuery,
  gqlSubscription,
  additionalVars,
  searchQuery,
  projectsPath,
  tableConfig,
  initialSorting,
}) => {
  const searchText = useSelector((state) => state.searchText);
  const pagination = useSelector((state) => state.pagination);
  const { sort, ...sortTableProps } = useTableSorting({
    order: initialSorting.order,
    column: initialSorting.column,
    tableConfig,
  });
  const variables = {
    ...additionalVars,
    searchInput: {
      query: searchQuery,
      searchTerm: searchText,
      sort: prepareSortingWithSearchText(searchText)(sort),
      ...paginationToQueryParams(pagination),
    },
  };
  const { data, loading } = useReactiveQuery(gqlQuery, gqlSubscription, {
    queryOptions: { variables },
    subscriptionOptions: { variables },
  });
  return {
    projects: getProjectsByPath(projectsPath)(data),
    total: getProjectsTotalByPath(projectsPath)(data),
    sortTableProps,
    loading,
  };
};
