import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { arrayOf, func, shape, string } from 'prop-types';
import { entityToOptionByLabelPath } from 'poly-client-utils';
import { isNilOrEmpty, renameProp } from 'poly-utils';
import { SpendReportFilters } from 'poly-constants';
import { ALL } from 'poly-admin-ui';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  MainHeader,
  TableCard,
  Loader,
  Select,
  Button,
} from 'poly-book-admin';

import {
  predefinedPropertyOptions,
  prependHierarchyDefaultOption,
} from '../SpendReport/SpendDetailsPageHeader/SpendDetailsPageHeaderC.js';
import { useHierarchyOptions } from '../PropertyHierarchyManagement/useHierarchyOptions.js';
import { prepareBatchSpendReport } from './prepareBatchSpendReport.js';
import { BatchSpendReportFooter } from './BatchSpendReportFooter.js';
import { BottomPanelContainerS } from '../BatchInvoicingTM/BatchInvoicingPageBody.js';
import { TableAndPanelWrapper } from '../VoidSupplierPayments/styles.js';
import { BatchSpendReportHierarchyTable } from './BatchSpendReportHierarchyTable.js';
import { PreviewBatchSpendReportPdf } from './PreviewBatchSpendReportPdf.js';

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const FilterContainerS = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const SelectContainerS = styled.div`
  width: 250px;
`;

const batchChildPropertySpendReportFragment = gql`
  fragment batchChildPropertySpendReportFragment on BatchPropertySpendReportResult {
    propertyName
    propertyId
    isMaster
    propertyInvoicesReport {
      _id
      projectNumber
      projectType
      projectFeeType
      projectCustomFeeDescription
      managementFee
      taxAmount
      clientInvoiceAmountWithTax
      supplierInvoicesAmount
      isAfterHoursCall
      timeEntries {
        _id
      }
      clientGLCode {
        code
      }
      approvedBy {
        fullName
      }
      invoices {
        invoiceAmount
        _id
        invoiceNumber
        invoiceDate
        supplier {
          _id
          company {
            name
          }
        }
        invoiceFileUrl
      }
    }
  }
`;

const batchHierarchySpendReportFragment = gql`
  fragment batchHierarchySpendReportFragment on BatchHierarchySpendReportResult {
    _id
    name
    properties {
      ...batchChildPropertySpendReportFragment
      childProperties {
        ...batchChildPropertySpendReportFragment
      }
    }
  }

  ${batchChildPropertySpendReportFragment}
`;

const clientInvoiceBatchSpendReportPreviewQuery = gql`
  query clientInvoiceBatchSpendReportPreviewQuery(
    $input: ClientInvoiceBatchSpendReportPreviewInput!
  ) {
    clientInvoiceBatchSpendReportPreview(input: $input) {
      ...batchHierarchySpendReportFragment
      children {
        ...batchHierarchySpendReportFragment
        children {
          ...batchHierarchySpendReportFragment
          children {
            ...batchHierarchySpendReportFragment
            children {
              ...batchHierarchySpendReportFragment
              children {
                ...batchHierarchySpendReportFragment
                children {
                  ...batchHierarchySpendReportFragment
                  children {
                    ...batchHierarchySpendReportFragment
                    children {
                      ...batchHierarchySpendReportFragment
                      children {
                        ...batchHierarchySpendReportFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${batchHierarchySpendReportFragment}
`;

function HierarchySelect({ propertyIds, clientId, ...props }) {
  const { options, loading } = useHierarchyOptions(
    clientId,
    false,
    propertyIds,
  );

  const selectProps = {
    ...props,
    loading,
    width: '250px',
    isClearable: true,
    placeholder: 'Start typing hierarchy',
    options: prependHierarchyDefaultOption(options),
  };

  return <Select {...selectProps} />;
}

const commonSelectPropTypes = {
  propertyIds: arrayOf(string),
  clientId: string,
};

HierarchySelect.propTypes = commonSelectPropTypes;

// getPropertyIdsByProjects :: [Project] -> [ID]
const getPropertyIdsByProjects = R.compose(
  R.uniq,
  R.reject(R.isNil),
  R.map(R.path(['property', '_id'])),
);

const propertiesQuery = gql`
  query propertiesQuery($input: CollectionSearchParams) {
    searchProperties(input: $input) {
      total
      hits {
        _id
        name
      }
    }
  }
`;

// getPropertySelectOptions :: SearchProperties -> SelectOptions
const getPropertySelectOptions = R.compose(
  R.concat(
    R.map(
      R.applySpec({ value: R.head, label: R.last }),
      predefinedPropertyOptions,
    ),
  ),
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['searchProperties', 'hits']),
);

function BatchSpendReportPropertiesSelect({ propertyIds, ...props }) {
  const { data, loading } = useQuery(propertiesQuery, {
    variables: {
      input: { query: { terms: { _id: propertyIds } } },
    },
  });

  const options = getPropertySelectOptions(data);

  const selectProps = {
    ...props,
    options,
    loading,
    width: '250px',
    placeholder: 'Start typing property',
  };

  return <Select {...selectProps} />;
}

BatchSpendReportPropertiesSelect.propTypes = commonSelectPropTypes;

// prepareSpendReportQueryInput :: BatchSpendReportFilters -> ClientInvoiceBatchSpendReportPreviewInput
const prepareSpendReportQueryInput = R.compose(
  R.evolve({ propertyId: R.of }),
  R.reject(isNilOrEmpty),
  R.when(
    R.compose(
      R.includes(R.__, [
        SpendReportFilters.all,
        SpendReportFilters.mainAccount,
      ]),
      R.prop('propertyId'),
    ),
    renameProp('propertyId', 'filterType'),
  ),
  R.when(R.propSatisfies(R.equals(ALL), 'propertyId'), R.dissoc('propertyId')),
);

export function BatchSpendReportPreview({
  selectedProjects,
  clientId,
  setSelectedProjects,
}) {
  const [filters, setFilters] = useState({
    hierarchyId: null,
    propertyId: ALL,
  });

  const { data, loading, refetch } = useQuery(
    clientInvoiceBatchSpendReportPreviewQuery,
    {
      variables: {
        input: {
          projectIds: R.pluck('_id', selectedProjects),
          ...prepareSpendReportQueryInput(filters),
        },
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const propertyIds = getPropertyIdsByProjects(selectedProjects);

  const onPropertyChange = (propertyValue) => {
    setFilters({ ...filters, propertyId: propertyValue });
  };

  const reports = prepareBatchSpendReport(filters.hierarchyId, data);

  return (
    <>
      <PageHeaderContainer>
        <FlexRow>
          <MainHeader>Mondelez Batch - Spend Report Preview </MainHeader>
          <FilterContainerS>
            <SelectContainerS>
              <BatchSpendReportPropertiesSelect
                loading={loading}
                propertyIds={propertyIds}
                onChange={onPropertyChange}
                value={filters.propertyId}
              />
            </SelectContainerS>
            <HierarchySelect
              clientId={clientId}
              propertyIds={propertyIds}
              value={filters.hierarchyId}
              onChange={(value) =>
                setFilters({ ...filters, hierarchyId: value })
              }
            />
          </FilterContainerS>
          <PreviewBatchSpendReportPdf reports={reports} />
          <Button size="small" onClick={() => refetch()} disabled={loading}>
            Refresh
          </Button>
        </FlexRow>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableAndPanelWrapper>
          <TableCard height="calc(100% - 90px)">
            {loading ? (
              <Loader />
            ) : (
              <BatchSpendReportHierarchyTable reports={reports} />
            )}
          </TableCard>
          <BottomPanelContainerS height="90px">
            <BatchSpendReportFooter
              loading={loading}
              reports={reports}
              setSelectedProjects={setSelectedProjects}
              selectedProjects={selectedProjects}
            />
          </BottomPanelContainerS>
        </TableAndPanelWrapper>
      </DefaultBodyWrapper>
    </>
  );
}

BatchSpendReportPreview.propTypes = {
  selectedProjects: arrayOf(shape({ _id: string })),
  clientId: string,
  setSelectedProjects: func.isRequired,
};
