import { useMutation, gql } from '@apollo/client';
import { useOutSidebarContext } from 'poly-client-utils';
import { useNotificationState } from 'poly-admin-ui';

import { editAssetFormId } from '../../constants.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';
import { prepareFormDataToMutation } from './editAssetFormUtils.js';

const UPDATE_ASSET_MUTATION = gql`
  mutation UPDATE_ASSET_MUTATION($id: ID!, $input: UpdateAssetInput) {
    updateAsset(id: $id, input: $input) {
      asset {
        _id
      }
    }
  }
`;

export const useOnSubmitEditAssetForm = (asset) => {
  const { showSuccessNotification } = useNotificationState();
  const { openAssetSidebar } = useSidebarLogicContext();
  const { closeOutSidebar } = useOutSidebarContext();

  const assetId = asset._id;

  const [updateAssetMutation] = useMutation(UPDATE_ASSET_MUTATION);

  return async (formData) => {
    const input = prepareFormDataToMutation(asset)(formData);

    await updateAssetMutation({ variables: { id: assetId, input } });

    showSuccessNotification('Asset was successfully updated');
    closeOutSidebar(editAssetFormId);
    openAssetSidebar(assetId);
  };
};
