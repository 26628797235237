import React from 'react';
import * as R from 'ramda';
import { bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import styled from 'styled-components';
import {
  halfWidth,
  thirdWidth,
  MultiselectDropDownWithManualOption,
  quarterWidth,
} from 'poly-admin-ui';
import { Checkbox } from 'poly-book-admin';

import { validateEmail } from 'poly-utils/src/validators.js';
import {
  accountNameFormField,
  addressFormField,
  clientContractFormField,
  faxFormField,
  nickNameFormField,
  phoneFormField,
  statusFormField,
  websiteFormField,
  managerAACFormField,
  clientGroupMailFormField,
} from './sections/generalInfoSection.js';
import { remarksFormField } from './sections/remarksSection.js';
import {
  ourVendorFormField,
  divisionFormField,
  invoiceToFormField,
  termsFormField,
  invoiceAttentionToFormField,
  costCenterFormField,
  accountingEmailFormField,
  clientSpendReportTypeField,
  clientInvoicingLogoFormField,
  clientInvoicingAddressFormField,
  invoiceAttentionToProjectRequestorFormField,
  sapGLCodeFormField,
  vaAccountNumberFormField,
  vaSplitPercentFormField,
} from './sections/financialSection.js';
import { appsFormField } from './sections/clientAppsSection.js';
import {
  clientBrandingFormField,
  clientLogoImageFormField,
  clientProjectCostTypeFormField,
  clientProjectFeeTypeFormField,
  clientProjectServiceTypeFormField,
  isAssetScannerAppEnabled,
  isClientPortalAppEnabled,
  isRequestsAppEnabled,
} from './sections/configsSection.js';
import {
  HelpTooltip,
  ShortExplanationKeys,
} from '../../../../components/HelpTooltip.js';

const sectionBorderLayout = {
  borderBottom: '1px solid',
  borderBottomColor: 'light',
};

const sectionWithoutMarginLayout = { margin: 0 };

function InvoiceEmailRecipients(props) {
  return (
    <MultiselectDropDownWithManualOption
      placeholder="Start typing emails"
      {...props}
    />
  );
}

InvoiceEmailRecipients.propTypes = {
  onChange: func,
};

const SubSectionLabelComponent = styled.div`
  font-size: 14px;
  margin: 10px 0;
`;

function SubSectionLabel({ title, ...props }) {
  return (
    <SubSectionLabelComponent {...props}>{title}</SubSectionLabelComponent>
  );
}

SubSectionLabel.propTypes = {
  title: string,
};

// wrapWithSubSection :: WrapperConfig -> [SectionConfig]
// WrapperConfig = {
//    row: Int
//    name: String
//    title: String
//    order: Int
//    subSectionFields: [SectionConfig]
//    renderIf: () -> Boolean
// }
// SectionConfig = {
//    order: Int
//    layout: { row: Int }
//    renderIf: () -> Boolean
//    field: {
//      name: String
//      Component: Node
//      additionalProps: { title: String },
//    },
// }
const wrapWithSubSection = ({
  row,
  name,
  title,
  order,
  subSectionFields,
  renderIf = R.T,
}) => [
  {
    order,
    layout: { row },
    field: {
      name,
      Component: SubSectionLabel,
      additionalProps: { title },
    },
    renderIf,
  },
  ...subSectionFields,
];

// getUMCCheckboxDisabledValue :: { formData: FormData } -> Boolean
const getUMCCheckboxDisabledValue = R.path([
  'formData',
  'isUMCCheckboxDisabled',
]);

function UMCAccountFormatCheckbox(props) {
  return <Checkbox {...props} disabled={getUMCCheckboxDisabledValue(props)} />;
}

function EnableChildrenCheckbox({ formData, ...props }) {
  return <Checkbox {...props} disabled={formData.clientHasChildren} />;
}

EnableChildrenCheckbox.propTypes = {
  formData: shape({
    clientHasChildren: bool,
  }),
};

const FieldWithTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ margin }) => margin || '10px 0'};
`;

const ComponentLabel = styled.div`
  font-size: 12px;
  color: #999999;
  padding-left: 10px;
  cursor: pointer;
`;

export function FieldWithTooltip({
  label,
  position,
  Component,
  wrapperProps,
  shortExplanation,
  ...props
}) {
  const { value, onChange } = props;

  return (
    <FieldWithTooltipWrapper {...wrapperProps}>
      <Component {...props} />
      <ComponentLabel onClick={() => onChange(!value)}>{label}</ComponentLabel>
      {shortExplanation && (
        <HelpTooltip position={position} shortExplanation={shortExplanation} />
      )}
    </FieldWithTooltipWrapper>
  );
}

FieldWithTooltip.propTypes = {
  label: string,
  value: oneOfType([bool, string]),
  position: string,
  onChange: func,
  Component: oneOfType([node, object, func]),
  shortExplanation: string,
  wrapperProps: shape({}),
};

export const sideBarClientFormSections = (onChangePersistentValue) => [
  {
    label: 'General Information',
    order: 1,
    layout: sectionBorderLayout,
    fields: [
      {
        ...accountNameFormField,
        order: 1,
        layout: { row: 1, width: '100%' },
      },
      {
        ...nickNameFormField,
        order: 2,
        layout: { row: 2, width: halfWidth },
      },
      {
        ...statusFormField,
        order: 3,
        layout: { row: 2, width: halfWidth },
      },
      {
        ...addressFormField,
        order: 4,
        layout: { row: 3, width: '100%' },
      },
      {
        ...phoneFormField,
        order: 5,
        layout: { row: 4, width: halfWidth },
      },
      {
        ...faxFormField,
        order: 6,
        layout: { row: 4, width: halfWidth },
      },
      {
        ...websiteFormField,
        order: 7,
        layout: { row: 5, width: halfWidth },
      },
      {
        ...clientContractFormField,
        order: 11,
        layout: { row: 6, width: halfWidth },
      },
      {
        ...managerAACFormField,
        order: 12,
        layout: { row: 6, width: halfWidth },
      },
      {
        ...clientGroupMailFormField,
        order: 13,
        layout: { row: 7, width: halfWidth },
      },
    ],
  },
  {
    label: 'Settings',
    order: 2,
    layout: sectionBorderLayout,
    fields: [
      ...wrapWithSubSection({
        row: 1,
        order: 1,
        name: 'financialSettingsTitle',
        title: 'Financial Settings',
        subSectionFields: [
          {
            layout: { row: 2, width: thirdWidth },
            order: 2,
            field: {
              name: 'enableGlCodes',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Enable GL Codes',
                Component: Checkbox,
                position: 'top',
                shortExplanation: ShortExplanationKeys.enableGlCodes,
              },
            },
          },
          {
            layout: { row: 2, width: thirdWidth },
            order: 3,
            field: {
              name: 'configs.enablePurchaseOrder',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Enable POs',
                Component: Checkbox,
                position: 'top',
                shortExplanation: ShortExplanationKeys.enablePOs,
              },
            },
          },
          {
            layout: { row: 2, width: thirdWidth },
            order: 4,
            field: {
              name: 'enableCostCenter',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Enable Cost Center',
                Component: Checkbox,
                position: 'top',
                shortExplanation: ShortExplanationKeys.enableCostCenter,
              },
            },
          },
          {
            layout: { row: 2, width: thirdWidth },
            order: 5,
            field: {
              name: 'configs.isPORequiredForProject',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'PO Required To Close Project',
                Component: Checkbox,
                position: 'left',
                shortExplanation: ShortExplanationKeys.isPORequiredForProject,
              },
            },
            renderIf: R.path(['configs', 'enablePurchaseOrder']),
          },
        ],
      }),
      ...wrapWithSubSection({
        row: 3,
        order: 5,
        name: 'clientSettingsTitle',
        title: 'Client Settings',
        subSectionFields: [
          {
            layout: { row: 4, width: thirdWidth },
            order: 6,
            field: {
              name: 'enableChildAccounts',
              withFormData: true,
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Enable Child Accounts',
                Component: EnableChildrenCheckbox,
                position: 'top',
                shortExplanation: ShortExplanationKeys.enableChildAccounts,
              },
            },
            renderIf: R.compose(R.not, R.prop('isChildClient')),
          },
          {
            layout: { row: 4, width: thirdWidth },
            order: 7,
            field: {
              name: 'isUMCAccountFormat',
              withFormData: true,
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'UMC Account Format',
                Component: UMCAccountFormatCheckbox,
                position: 'top',
                shortExplanation: ShortExplanationKeys.isUMCAccountFormat,
              },
            },
          },
          {
            layout: { row: 4, width: thirdWidth },
            order: 8,
            field: {
              name: 'configs.requesterSurvey.enabled',
              withFormData: true,
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Requester Survey',
                Component: Checkbox,
                position: 'top',
                shortExplanation: ShortExplanationKeys.requesterSurveyEnabled,
              },
            },
          },
          {
            layout: { row: 5 },
            order: 8,
            field: {
              name: 'enablePropertyLocationHierarchy',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Enable Property Location Hierarchy',
                Component: Checkbox,
                position: 'top',
                shortExplanation:
                  ShortExplanationKeys.enablePropertyLocationHierarchy,
              },
            },
          },
          {
            layout: { row: 6 },
            order: 9,
            field: {
              name: 'configs.requiresSignOffSheet',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Sign OFF sheet is required for WOC',
                Component: Checkbox,
                position: 'top',
                shortExplanation: ShortExplanationKeys.requiresSignOffSheet,
              },
            },
          },
          {
            layout: { row: 7, width: thirdWidth },
            order: 10,
            field: {
              name: 'enableReportOnlyProjectType',
              withFormData: true,
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Enable Report Only',
                Component: Checkbox,
                position: 'top',
                shortExplanation:
                  ShortExplanationKeys.enableReportOnlyProjectType,
              },
            },
          },
          {
            layout: { row: 7, width: thirdWidth },
            order: 11,
            field: {
              name: 'configs.clientInvoicing.reportOnlyClientInvoiceCreation',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Report Only Client Invoice Creation',
                Component: Checkbox,
                position: 'top',
                shortExplanation:
                  ShortExplanationKeys.reportOnlyClientInvoiceCreation,
              },
            },
          },
          {
            layout: { row: 7, width: thirdWidth },
            order: 11,
            field: {
              name: 'configs.adminApp.allowProjectCreation',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Allow project creation on the Admin',
                Component: Checkbox,
                position: 'top',
                shortExplanation:
                  ShortExplanationKeys.allowAdminProjectCreation,
              },
            },
          },
        ],
      }),
      ...wrapWithSubSection({
        row: 8,
        order: 12,
        name: 'portalSettingsTitle',
        title: 'Portal Settings',
        subSectionFields: [
          {
            layout: { row: 9, width: halfWidth },
            order: 13,
            ...appsFormField,
          },
        ],
      }),
      ...wrapWithSubSection({
        row: 10,
        order: 14,
        name: 'clientPortalConfigsTitle',
        title: 'Client Portal Configs',
        renderIf: isClientPortalAppEnabled,
        subSectionFields: [
          {
            layout: { row: 11, width: thirdWidth },
            order: 15,
            field: {
              name: 'configs.clientPortalApp.allowProjectCreation',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Allow Project Creation',
                Component: Checkbox,
                position: 'top',
                shortExplanation: ShortExplanationKeys.allowProjectCreation,
              },
            },
            renderIf: isClientPortalAppEnabled,
          },
          {
            layout: { row: 11, width: 'calc(66.66% - 4px)' },
            order: 16,
            field: {
              name: 'configs.clientPortalApp.showPMsAndAssets',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Show PMs and Assets',
                Component: Checkbox,
                position: 'top',
                shortExplanation: ShortExplanationKeys.showPMsAndAssets,
              },
            },
            renderIf: isClientPortalAppEnabled,
          },
          {
            layout: { row: 12, width: halfWidth },
            order: 17,
            ...clientProjectServiceTypeFormField,
            field: {
              ...clientProjectServiceTypeFormField.field,
              name: 'configs.clientPortalApp.projectServiceTypeId',
            },
            renderIf: R.both(
              isClientPortalAppEnabled,
              R.pathEq(
                ['configs', 'clientPortalApp', 'allowProjectCreation'],
                true,
              ),
            ),
          },
        ],
      }),
      ...wrapWithSubSection({
        row: 13,
        order: 18,
        name: 'requestConfigsTitle',
        title: 'Request Configs',
        renderIf: isRequestsAppEnabled,
        subSectionFields: [
          {
            layout: { row: 14, width: halfWidth },
            order: 19,
            ...clientBrandingFormField,
          },
          {
            layout: { row: 14, width: halfWidth },
            order: 20,
            ...clientLogoImageFormField,
          },
          {
            layout: { row: 15, width: halfWidth },
            order: 21,
            ...clientProjectCostTypeFormField,
          },

          {
            layout: { row: 15, width: halfWidth },
            order: 22,
            ...clientProjectServiceTypeFormField,
          },
          {
            layout: { row: 15, width: halfWidth },
            order: 23,
            ...clientProjectFeeTypeFormField,
          },
        ],
      }),
      ...wrapWithSubSection({
        row: 16,
        order: 19,
        name: 'requestConfigsTitle',
        title: 'Asset Scanner Configs',
        renderIf: isAssetScannerAppEnabled,
        subSectionFields: [
          {
            layout: { row: 17, width: halfWidth },
            order: 1,
            renderIf: isAssetScannerAppEnabled,
            field: {
              name: 'configs.assetScannerApp.allowReplacementCost',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Allow viewing Asset Replacement Cost',
                Component: Checkbox,
              },
            },
          },
          {
            layout: { row: 17, width: halfWidth },
            order: 17,
            field: {
              renderIf: isAssetScannerAppEnabled,
              name: 'configs.assetScannerApp.allowProjectCreation',
              Component: FieldWithTooltip,
              additionalProps: {
                label: 'Allow project creation on the Asset Scanner',
                Component: Checkbox,
                position: 'top',
                shortExplanation:
                  ShortExplanationKeys.allowAssetScannerProjectCreation,
              },
            },
          },
        ],
      }),
    ],
  },
  {
    order: 3,
    label: 'Remarks',
    layout: sectionWithoutMarginLayout,
    fields: [
      {
        ...remarksFormField(onChangePersistentValue),
        order: 1,
        layout: { row: 1, width: '100%' },
      },
    ],
  },
  {
    label: 'Financial',
    order: 4,
    layout: sectionBorderLayout,
    fields: [
      {
        ...ourVendorFormField,
        order: 1,
        layout: { row: 1, width: halfWidth },
      },
      {
        ...divisionFormField,
        order: 2,
        layout: { row: 1, width: halfWidth },
      },
      {
        ...invoiceToFormField,
        order: 3,
        layout: { row: 2, width: halfWidth },
      },
      {
        ...termsFormField,
        order: 4,
        layout: { row: 2, width: halfWidth },
      },
      {
        ...invoiceAttentionToFormField,
        order: 5,
        layout: { row: 3, width: quarterWidth },
      },
      {
        ...invoiceAttentionToProjectRequestorFormField,
        order: 6,
        layout: { row: 3, width: quarterWidth },
      },
      {
        ...costCenterFormField,
        order: 7,
        layout: { row: 3, width: halfWidth },
      },
      {
        ...accountingEmailFormField,
        order: 8,
        layout: { row: 4, width: halfWidth },
      },
      {
        ...clientSpendReportTypeField,
        order: 9,
        layout: { row: 4, width: halfWidth },
      },
      {
        ...clientInvoicingAddressFormField,
        order: 10,
        layout: { row: 5, width: '100%' },
      },
      {
        ...clientInvoicingLogoFormField,
        order: 11,
        layout: { row: 6, width: halfWidth },
      },
      {
        label: 'Invoice Email Recipients',
        field: {
          name: 'configs.clientInvoicing.invoiceEmailRecipients',
          Component: InvoiceEmailRecipients,
        },
        renderIf: R.prop('isInvoicingEmailEnabled'),
        validators: [
          [
            R.unless(R.isNil, R.all(R.propSatisfies(validateEmail, 'value'))),
            'Incorrect email',
          ],
        ],
        order: 1,
        layout: { row: 7, width: halfWidth },
      },
      {
        ...sapGLCodeFormField,
        order: 13,
        layout: { row: 7, width: halfWidth },
      },
      {
        ...vaAccountNumberFormField,
        order: 14,
        layout: { row: 7, width: halfWidth },
      },
      {
        ...vaSplitPercentFormField,
        order: 15,
        layout: { row: 8, width: halfWidth },
      },
    ],
  },
];
