import React from 'react';
import { shape, number } from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import { NotificationDotS, formatNotificatorDotValue } from 'poly-book-admin';

// getPastDueTasksCountByPayload :: DashboardResult -> String
const getPastDueTasksCountByPayload = R.compose(
  formatNotificatorDotValue,
  R.sum,
  R.juxt([
    R.pathOr(0, ['ownTaskCounts', 'dueToday']),
    R.pathOr(0, ['ownTaskCounts', 'overdue']),
  ]),
);

const NotificationDotComp = styled(NotificationDotS)`
  left: unset;
  right: 5px;
  top: 10px;
`;

export function AppBarTasksCountBubble({ menuPayload }) {
  const count = getPastDueTasksCountByPayload(menuPayload);
  return <NotificationDotComp>{count}</NotificationDotComp>;
}

AppBarTasksCountBubble.propTypes = {
  menuPayload: shape({ ownTaskCounts: shape({ dueToday: number }) }),
};
