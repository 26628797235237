import {
  HTTP_FORBIDDEN_STATUS,
  HTTP_UNAUTHORIZED_STATUS,
} from './httpStatuses.js';

export const TechnicianSkills = {
  generalMaintenance: 'generalMaintenance',
  carpentry: 'carpentry',
  plumbing: 'plumbing',
  hvac: 'hvac',
  movesAddsChanges: 'movesAddsChanges',
  cosmeticMaintenance: 'cosmeticMaintenance',
  preventiveMaintenance: 'preventiveMaintenance',
  escortVendors: 'escortVendors',
};

export const UserStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const UserEmployeeInfoStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  LEAVE: 'leave',
};

export const StaffReportMetricType = {
  ACTIVE_RECURRING_PROJECTS: 'activeRecurringProjects',
  ACTIVE_CHILDREN_PROJECTS: 'activeChildrenProjects',
  ACTIVE_PROJECTS: 'activeProjects',
  COMPLETED_PROJECTS: 'completedProjects',
  CREATED_PROJECTS: 'createdProjects',
  COMPLETED_PROJECT_TASKS: 'completedProjectTasks',
  POSTED_UPDATES: 'postedUpdates',
  CREATED_SUPPLIERS: 'createdSuppliers',
};

export const TWO_FA_CODE_INCORRECT_ERROR = {
  code: HTTP_FORBIDDEN_STATUS,
  message: 'Incorrect 2FA code',
};

export const SMS_CODE_REQUIRED_ERROR = {
  code: HTTP_FORBIDDEN_STATUS,
  message: 'SMS code is required',
};

export const USE_PASSWORD_LOGIN_ERROR = {
  code: HTTP_FORBIDDEN_STATUS,
  message: 'Use password login form',
};

export const TOKEN_EXPIRED_ERROR = {
  code: HTTP_UNAUTHORIZED_STATUS,
  message: 'Token expired',
};
