import React from 'react';
import * as R from 'ramda';
import { TableLayoutList } from 'poly-book-admin';
import { formatDate, getHousekeepingCalendarWeeks } from 'poly-utils';
import { arrayOf, instanceOf, oneOfType, shape, string } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';

// getHousekeepingCalendarYearsWeeks :: HousekeepingCalendar -> [String]
// HousekeepingCalendar = [{startOfYear : Date, description: String}]
export const getHousekeepingCalendarYearsWeeks = R.compose(
  R.append(NOTHING_UI_STRING),
  R.map(R.ifElse(Number.isFinite, R.toString, R.always(NOTHING_UI_STRING))),
  getHousekeepingCalendarWeeks,
);

export function HousekeepingCalendarList({ housekeepingCalendar }) {
  return (
    <TableLayoutList
      list={[
        {
          title: 'Start Of Year',
          width: '30%',
          elem: R.map(
            R.compose(formatDate, R.prop('startOfYear')),
            housekeepingCalendar,
          ),
        },
        {
          title: 'Description',
          elem: R.map(R.prop('description'), housekeepingCalendar),
          width: '30%',
        },
        {
          title: 'Weeks',
          elem: getHousekeepingCalendarYearsWeeks(housekeepingCalendar),
          width: '30%',
        },
      ]}
    />
  );
}

export const housekeepingCalendarPropTypes = arrayOf(
  shape({
    startOfYear: oneOfType([string, instanceOf(Date)]),
    description: string,
  }),
);

HousekeepingCalendarList.propTypes = {
  housekeepingCalendar: housekeepingCalendarPropTypes,
};
