import React, { useEffect } from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { isNilOrEmpty } from 'poly-utils/src/general.js';

import { any, bool, shape, func } from 'prop-types';
import {
  RWOTaskListType,
  ProjectType,
  ScheduleRepeats,
  ProjectTypeToNameMap,
} from 'poly-constants';
import { Select } from 'poly-book-admin';

import { ProjectOccurrence } from '../../../../../modules/core/constants/projects.js';
import { checkIsProjectWithProperty } from '../utils/sections.js';

const SEARCH_SERVICE_TYPES_QUERY = gql`
  query SEARCH_SERVICE_TYPES($input: CollectionSearchParams!) {
    searchServiceTypes(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

// getProjectTypeSelectOptions :: Boolean -> [Pair String String]
const getProjectTypeSelectOptions = (
  includeHousekeeping,
  includeReportOnly,
  isRecurringOccurrence,
  isPMOccurrence,
) =>
  R.compose(
    R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
    R.toPairs,
    R.when(
      R.always(isPMOccurrence),
      R.pick([
        ProjectType.REPORT_ONLY,
        ProjectType.PASS_THROUGH,
        ProjectType.WORK_ORDER,
        ProjectType.BID,
      ]),
    ),
    R.unless(R.always(includeReportOnly), R.omit([ProjectType.REPORT_ONLY])),
    R.unless(R.always(includeHousekeeping), R.omit([ProjectType.HOUSEKEEPING])),
    R.when(R.always(isRecurringOccurrence), R.omit([ProjectType.LISTING])),
  )(ProjectTypeToNameMap);

// getJanitorialServiceByData :: Object -> ServiceType
const getJanitorialServiceByData = R.path(['searchServiceTypes', 'hits', 0]);

// isHousekeepingEnabledByClient :: Client -> Boolean
const isHousekeepingEnabledByClient = R.pathEq(
  ['configs', 'enableHousekeepingProjectType'],
  true,
);

// isReportOnlyEnabledByClient :: Client -> Boolean
const isReportOnlyEnabledByClient = R.propEq(
  'enableReportOnlyProjectType',
  true,
);

const changeFieldValueByType = (
  type,
  formData,
  serviceTypesData,
  changeFieldValue,
) => {
  if (type === ProjectType.HOUSEKEEPING) {
    changeFieldValue('taskListType', RWOTaskListType.SHORT_LIST);
    changeFieldValue('schedule.repeats', ScheduleRepeats.MONTHLY);

    const janitorialService = getJanitorialServiceByData(serviceTypesData);
    if (janitorialService) {
      const { _id, name } = janitorialService;
      changeFieldValue('serviceTypeId', _id);
      changeFieldValue('serviceTypeName', name);
    }
  }

  // not all project types fit all service types
  if (formData.type === ProjectType.FEE || type === ProjectType.FEE) {
    changeFieldValue('serviceTypeId', null);
    changeFieldValue('serviceTypeName', null);
  }

  // by default end date today for Fee project type
  if (
    formData.occurrence === ProjectOccurrence.ONE_TIME_OCCURRENCE &&
    type === ProjectType.FEE
  ) {
    changeFieldValue('endDate', new Date());
  }
};

// isPMProjectOccurrence :: Project -> Boolean
const isPMProjectOccurrence = R.either(
  R.propEq('occurrence', ProjectOccurrence.PREVENTIVE_MAINTENANCE_OCCURRENCE),
  R.compose(R.complement(isNilOrEmpty), R.prop('assetIds')),
);

export function ProjectTypeSelect({
  formData,
  changeFieldValue,
  onChange,
  value,
  ...restProps
}) {
  const isHousekeepingEnabled = isHousekeepingEnabledByClient(formData.client);
  const isReportOnlyEnabled = isReportOnlyEnabledByClient(formData.client);

  const isRecurringOccurrence =
    formData.occurrence === ProjectOccurrence.RECURRING_OCCURRENCE;

  const isPMOccurrence = isPMProjectOccurrence(formData);

  const isProjectWithProperty = checkIsProjectWithProperty(formData);

  const isAllowedHousekeepingOption =
    isRecurringOccurrence && isHousekeepingEnabled && isProjectWithProperty;

  const { data: serviceTypesData, loading } = useQuery(
    SEARCH_SERVICE_TYPES_QUERY,
    {
      variables: { input: { searchTerm: 'Janitorial' } },
      skip: !isHousekeepingEnabled,
    },
  );

  const options = getProjectTypeSelectOptions(
    isAllowedHousekeepingOption,
    isReportOnlyEnabled,
    isRecurringOccurrence,
    isPMOccurrence,
  );

  useEffect(() => {
    if (!isAllowedHousekeepingOption && value === ProjectType.HOUSEKEEPING) {
      onChange(null);
    }
  }, [formData.clientId, formData.occurrence]);

  const onChangeWrapper = (type) => {
    onChange(type);
    changeFieldValueByType(type, formData, serviceTypesData, changeFieldValue);
  };

  const selectProps = {
    options,
    value,
    onChange: onChangeWrapper,
    ...restProps,
  };

  return loading ? null : <Select {...selectProps} />;
}

ProjectTypeSelect.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
  formData: shape({ configs: shape({ enableHousekeepingProjectType: bool }) }),
};
