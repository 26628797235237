import React from 'react';
import { Input, FormField } from 'poly-book-admin';
import { string, bool } from 'prop-types';

export function BasicInput({
  className,
  required = false,
  disabled,
  ...restProps
}) {
  const additionalProps = { className, required, disabled };

  return (
    <FormField
      {...restProps}
      Component={Input}
      additionalProps={additionalProps}
    />
  );
}

BasicInput.propTypes = {
  className: string,
  required: bool,
  disabled: bool,
};
