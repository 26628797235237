import * as R from 'ramda';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { func, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useReactiveQuery } from 'poly-client-utils';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from 'poly-admin-ui';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import { AddProjectTimeFormId } from './projectTimeConstants.js';
import { ProjectTimeEntriesList } from './ProjectTimeEntriesList.js';
import { clientStaffRate, ProjectTimeForm } from './ProjectTimeForm.js';
import {
  prepareProjectTimeEntryBeforeUpdate,
  validateProjectTime,
} from './projectTimeUtils.js';

const CREATE_PROJECT_TIME_ENTRY = gql`
  mutation CREATE_PROJECT_TIME_ENTRY($projectId: ID!, $input: TimeEntryInput!) {
    createProjectTimeEntry(projectId: $projectId, input: $input) {
      totalTimeInMinutes
    }
  }
`;

const PROJECT_DETAIL_QUERY = gql`
  query PROJECT_DETAIL_QUERY($id: ID, $projectId: ID) {
    project(id: $id, projectId: $projectId) {
      _id
      projectId
      timeEntries {
        _id
        type
        date
        rate
        rateDescription
        notes
        totalTimeInMinutes
        isInvoiced
        owner {
          _id
          fullName
        }
      }

      client {
        _id
        name
        nickName
        configs {
          propertyFields {
            alarmInfo
            financialCoding
          }
        }

        staffRates {
          customRates {
            description
            rate
            overtime
            doubleTime
          }
        }
      }
      property {
        _id
        name
        alarmInfo {
          alarmCode
          gateCode
        }
        financialCoding
      }
    }
  }
`;

const PROJECT_DETAILS_SUB = gql`
  subscription PROJECT_DETAILS_SUBS($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
    }
  }
`;

const MemoizedForm = React.memo(
  Form,
  (props, { initialValues, shouldRerender, setShouldRerender }) => {
    if (
      props.initialValues.project !== initialValues.project &&
      !shouldRerender
    ) {
      return true;
    }
    setShouldRerender(false);
    return false;
  },
);

export function AddProjectTimeForm({
  projectId,
  setEditTimeProps,
  handleCancel,
}) {
  const [shouldRerender, setShouldRerender] = useState(false);
  const { user } = useCurrentUserByStoreOrQuery();

  const [createProjectTimeEntry] = useMutation(CREATE_PROJECT_TIME_ENTRY);

  const { showSuccessNotification } = useNotificationState();

  const queryOptions = {
    variables: {
      id: projectId,
    },
    skip: !projectId,
    fetchPolicy: 'network-only',
  };

  const subscriptionOptions = {
    variables: {
      input: {
        id: projectId,
      },
    },
    skip: !projectId,
  };

  const { data: projectData, loading } = useReactiveQuery(
    PROJECT_DETAIL_QUERY,
    PROJECT_DETAILS_SUB,
    {
      queryOptions,
      subscriptionOptions,
    },
  );

  const addNewTimeToProject = async (values) => {
    setShouldRerender(true);
    await createProjectTimeEntry({
      variables: {
        projectId,
        input: prepareProjectTimeEntryBeforeUpdate(values),
      },
    });

    showSuccessNotification('Project time successfully added');
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    AddProjectTimeFormId,
    addNewTimeToProject,
  );

  if (loading) {
    return null;
  }

  const userId = R.prop('_id', user);
  const project = R.propOr({}, 'project', projectData);

  const initialValues = {
    isManual: true,
    ownerId: userId,
    date: new Date(),
    type: clientStaffRate.RATE,
    clientStaffRate: {},
    project,
  };

  return (
    <>
      <MemoizedForm
        initialValues={initialValues}
        render={ProjectTimeForm}
        onSubmit={onSubmit}
        formId={AddProjectTimeFormId}
        handleCancel={handleCancel}
        validate={validateProjectTime}
        shouldRerender={shouldRerender}
        setShouldRerender={setShouldRerender}
      />
      <ProjectTimeEntriesList
        project={project}
        setEditTimeProps={setEditTimeProps}
      />
    </>
  );
}

AddProjectTimeForm.propTypes = {
  projectId: string.isRequired,
  handleCancel: func,
  setEditTimeProps: func,
};
