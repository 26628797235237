import * as R from 'ramda';
import { arrayOf, string, node } from 'prop-types';
import { useRouterQuery } from 'poly-client-routing';
import React from 'react';

// shouldShowSearchInput :: ([String], String) -> Boolean
const shouldShowSearchInput = (array, tab) => R.not(R.includes(tab, array));

export function SearchInputTabsDisplay({ children, permit }) {
  const { tab } = useRouterQuery(['tab']);

  const showInput = shouldShowSearchInput(permit, tab);

  if (showInput) {
    return null;
  }

  return <div>{children}</div>;
}

SearchInputTabsDisplay.propTypes = {
  children: node.isRequired,
  permit: arrayOf(string),
};
