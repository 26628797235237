import { string } from 'prop-types';
import React, { useEffect } from 'react';
import { SidebarWrapper, SidebarRow } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';
import { Loader } from 'poly-book-admin';

import {
  SidebarLabel,
  SectionWrapper,
  SectionWrapperWithoutBorder,
} from '../components/commonSidebarComponents.js';
import { SidebarIDs } from '../constants.js';
import { userGroupTabsSidebarId } from './constants.js';
import { UserGroupPermissionsTable } from './UserGroupPermissionsTable.js';
import { OpenFullInfoSidebarButton } from '../components/OpenFullInfoSidebarButton.js';
import { useReactiveSidebarDocumentTitle } from '../useReactiveSidebarDocumentTitle.js';
import { useOpenUserGroupTabsSidebar } from './tabs/useOpenUserGroupTabsSidebar.js';
import { useUserGroupWithTypesUIQuery } from './useUserGroupWithTypesUIQuery.js';

function UserGroupSidebarRouterLogic({ userGroupId, userGroupName }) {
  const openUserGroupTabsSidebar = useOpenUserGroupTabsSidebar();
  const { isSidebarOpened } = useOutSidebarContext();

  useEffect(() => {
    if (!isSidebarOpened(userGroupTabsSidebarId)) {
      openUserGroupTabsSidebar({ userGroupId, userGroupName });
    }
  }, []);

  useReactiveSidebarDocumentTitle(userGroupName);

  return null;
}

UserGroupSidebarRouterLogic.propTypes = {
  userGroupId: string.isRequired,
  userGroupName: string.isRequired,
};

export function UserGroupSidebar({ userGroupId }) {
  const { userGroupTypesUI, userGroupName, loading } =
    useUserGroupWithTypesUIQuery(userGroupId);

  const openUserGroupTabsSidebar = useOpenUserGroupTabsSidebar();

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarWrapper>
      <SectionWrapper>
        <SidebarRow justify align>
          <SidebarLabel>{userGroupName}</SidebarLabel>
          <OpenFullInfoSidebarButton
            sidebarId={SidebarIDs.userGroup}
            fullSidebarId={userGroupTabsSidebarId}
            openSidebarHandler={() =>
              openUserGroupTabsSidebar({ userGroupId, userGroupName })
            }
          />
        </SidebarRow>
      </SectionWrapper>
      <SectionWrapperWithoutBorder>
        <UserGroupPermissionsTable
          userGroupId={userGroupId}
          userGroupTypesUI={userGroupTypesUI}
        />
      </SectionWrapperWithoutBorder>
      <UserGroupSidebarRouterLogic {...{ userGroupId, userGroupName }} />
    </SidebarWrapper>
  );
}

UserGroupSidebar.propTypes = { userGroupId: string.isRequired };
