import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string, bool } from 'prop-types';
import { EntityStatus, LinkButton, Text } from 'poly-book-admin';
import { FlexContainer, SidebarRow } from 'poly-admin-ui';
import { UPDATE_CLIENT_PERMISSION } from 'poly-security';
import {
  useHasUserAccessWithPermission,
  pathOrNothingUI,
} from 'poly-client-utils';

import {
  SidebarLabel,
  BlockWithLabel,
  SectionWrapper,
  headerTextProps,
} from '../components/commonSidebarComponents.js';
import { SidebarIDs } from '../constants.js';
import { clientPriorities, clientTabsSidebarId } from './constants.js';
import { clientStatusesColors } from '../../modules/core/constants/client.js';
import { OpenFullInfoSidebarButton } from '../components/OpenFullInfoSidebarButton.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import { useOpenClientTabsSidebar } from './tabs/useOpenClientTabsSidebar.js';
import { useEditClientSidebar } from './forms/edit/useEditClientSidebar.js';
import { getEntityAddressPreview } from '../utils/address.js';

const ClientPrioritiesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 0 24px;
`;

const TopRow = styled(SidebarRow)`
  align-items: baseline;
  justify-content: space-between;

  > div:first-child {
    align-items: baseline;
  }
`;

const TextS = styled(Text)`
  width: 30%;
`;

function ClientPrioritiesRow(props) {
  return (
    <ClientPrioritiesWrapper {...props}>
      {clientPriorities.map((priority) => (
        <TextS {...headerTextProps} key={priority}>
          {priority}
        </TextS>
      ))}
    </ClientPrioritiesWrapper>
  );
}

// getClientType :: Client -> String
const getClientType = R.cond([
  [R.propSatisfies(R.is(Object), 'parentClient'), R.always('Client Child')],
  [
    R.propSatisfies(R.complement(R.isEmpty), 'childClients'),
    R.always('Client Master'),
  ],
  [R.T, R.always('Client')],
]);

export function ClientSidebarHeader({ client, isCard = false }) {
  const { name, status } = client;
  const openEditClientSidebar = useEditClientSidebar(isCard);
  const openFullClient = useOpenClientTabsSidebar();

  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_CLIENT_PERMISSION,
  );

  return (
    <SectionWrapper>
      <TopRow>
        <FlexContainer>
          <SidebarLabel margin={10}>{name}</SidebarLabel>
          <EntityStatus
            title={getClientType(client)}
            status={{
              text: R.toLower(status),
              color: clientStatusesColors[status],
            }}
          />
        </FlexContainer>
        <OpenFullInfoSidebarButton
          isCard={isCard}
          sidebarId={SidebarIDs.client}
          fullSidebarId={clientTabsSidebarId}
          openSidebarHandler={() => openFullClient(client)}
        />
      </TopRow>
      <SidebarRow justify>
        <FlexContainer>
          {getSectionText(
            pathOrNothingUI(['cardNumber'])(client),
            headerTextProps,
          )()}
        </FlexContainer>
        {ifHasPermission && (
          <LinkButton onClick={() => openEditClientSidebar({ client })}>
            Edit
          </LinkButton>
        )}
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          id="address"
          label="Address"
          Component={getSectionText(
            getEntityAddressPreview(client),
            headerTextProps,
          )}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          width="100%"
          id="priorities"
          label="Priorities"
          Component={ClientPrioritiesRow}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

ClientSidebarHeader.propTypes = {
  isCard: bool,
  client: shape({
    name: string.isRequired,
    status: string.isRequired,
  }),
};
