import styled from 'styled-components';
import { getThemeColor } from 'poly-book-admin';

export const ClientRequesterFormFieldLabel = styled.label`
  display: flex;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  margin-bottom: 5px;
  color: ${getThemeColor(['midDark'])};
`;
