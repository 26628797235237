import React from 'react';
import styled from 'styled-components';
import { formatAddressString } from 'poly-client-utils';

import { string, oneOfType, number, element, shape } from 'prop-types';
import { getThemeColor, getThemeFont } from '../utils.js';
import { ColumnDirection } from '../lib/index.js';

const Title = styled.div`
  font-weight: ${getThemeFont(['regular'])};
  font-size: 10px;
  color: ${getThemeColor(['secondary'])};
  letter-spacing: 0.8px;
`;

const Value = styled.div`
  font-weight: ${getThemeFont(['semibold'])};
  color: ${getThemeColor(['primary'])};
  font-size: 14px;
  margin-top: 3px;
`;

export function EntityDetail({ className, title, value }) {
  return (
    <ColumnDirection className={className}>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </ColumnDirection>
  );
}

EntityDetail.propTypes = {
  className: string,
  title: string.isRequired,
  value: oneOfType([string, number, element]).isRequired,
};
EntityDetail.defaultProps = {
  className: '',
};

const AddressPart = styled.p`
  line-height: 1.42;
  margin: 0;
  padding: 0;
`;

/* eslint-disable react/no-array-index-key */
export function EntityAddress({ address, addressTwo }) {
  const formattedAddress = formatAddressString({
    address,
    addressTwo,
  });

  return (
    <div>
      {formattedAddress.split('\n').map((line) => (
        <AddressPart key={line}>{line}</AddressPart>
      ))}
    </div>
  );
}
/* eslint-disable react/no-array-index-key */

EntityAddress.propTypes = {
  address: shape({
    address_parts: shape({
      street_number: string,
      route: string,
      locality: string,
      administrative_area_level_1: string,
      postal_code: string,
      postal_code_suffix: string,
    }),
  }),
  addressTwo: string,
};

EntityAddress.defaultProps = {
  address: { address_parts: {} },
  addressTwo: '',
};
