import * as R from 'ramda';

export const getSupplierSearchQueryByName = (searchText) => ({
  bool: {
    should: [
      { match: { 'company.name': searchText } },
      { match: { 'company.dba': searchText } },
      { wildcard: { cardNumber: { value: `*${searchText}*` } } },
    ],
    minimum_should_match: 1,
  },
});

// getActiveOfficeHours :: Supplier -> [OfficeHour]
export const getActiveOfficeHours = R.ifElse(
  R.propEq('service_24_7', true),
  R.always(null),
  R.compose(
    R.filter(R.propEq('isActive', true)),
    R.defaultTo([]),
    R.prop('officeHours'),
  ),
);
