import React from 'react';
import * as R from 'ramda';
import { arrayOf, string } from 'prop-types';
import { calculateTotal, formatDate } from 'poly-utils';
import { LinkButton } from 'poly-book-admin';
import {
  createExcelExportCell,
  ExcelExportCellType,
  formatFromToDateTitle,
  performExcelExport,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';

import {
  getTransactionUserFriendlyName,
  getTransactionUserFriendlyType,
} from '../VendorLedgerReport/vendorLedgerReportUtils.js';
import {
  customerTransactionPropType,
  customerFilterPropType,
} from './propTypes.js';

// getCustomerRow :: CustomerHistoryTransaction -> [ExcelExportDataCell]
const getCustomerRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 4),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 3),
  ]),
  R.juxt([
    getTransactionUserFriendlyName,
    R.compose(formatDate, R.prop('date')),
    getTransactionUserFriendlyType,
    R.path(['project', 'projectId']),
    R.propOr(0, 'invoiceAmount'),
    R.propOr(0, 'paymentAmount'),
    R.propOr(0, 'creditMemo'),
  ]),
);

// getTotalByProp :: String -> [CustomerHistoryTransaction] -> Number
const getTotalByProp = (prop) => calculateTotal(R.propOr(0, prop));

// eslint-disable-next-line import/no-unused-modules
export const getCustomHistoryExcelExportConfig = (
  transactions,
  { invoiceDate },
  customerName,
) => ({
  exportFileName: 'customer-history.xlsx',
  columnWidths: [15, 15, 20, 15, 15, 15, 15],
  rows: [
    [{ value: 'Client History', cellType: ExcelExportCellType.title }],
    [{ value: customerName, cellType: ExcelExportCellType.subTitle }],
    [
      {
        value: formatFromToDateTitle(
          invoiceDate.startDate,
          invoiceDate.endDate,
        ),
        cellType: ExcelExportCellType.subTitle,
      },
    ],

    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 4),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 3),
      ],
      [
        'Transaction',
        'Date',
        'Type',
        'Project',
        'Invoice Amount',
        'Paid Amount',
        'Credit Memo',
      ],
    ),

    ...transactions.map(getCustomerRow),

    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableStringFooter, 4),
        ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 3),
      ],
      [
        'Total',
        ...R.repeat('', 3),
        getTotalByProp('invoiceAmount')(transactions),
        getTotalByProp('paymentAmount')(transactions),
        getTotalByProp('creditMemo')(transactions),
      ],
    ),
  ],
});

export function CustomerHistoryExportXLSBtn({
  transactions,
  filters,
  customerName,
}) {
  const onClick = () => {
    const config = getCustomHistoryExcelExportConfig(
      transactions,
      filters,
      customerName,
    );
    performExcelExport(config);
  };

  return (
    <LinkButton disabled={transactions.length === 0} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

CustomerHistoryExportXLSBtn.propTypes = {
  transactions: arrayOf(customerTransactionPropType),
  filters: customerFilterPropType,
  customerName: string,
};
