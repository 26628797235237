import React from 'react';
import * as R from 'ramda';
import { NOTHING_UI_STRING, WeeklyServiceTicketStatuses } from 'poly-constants';
import {
  getHousekeepingWeekCountByProject,
  supplierHaveServiceEmail,
  supplierHaveMobilePhone,
  formatDate,
} from 'poly-utils';

import { YellowWarningIcon } from '../../components/Icons.js';
import { WeekServiceTicketCheckbox } from './components/WeekServiceTicketCheckbox.js';
import { WeekServiceTicketApproveLink } from './components/WeekServiceTicketApproveLink.js';
import { WeekServiceTicketRequestedLink } from './components/WeekServiceTicketRequestedLink.js';

// getTicketColumnForPrint :: WeeklyServiceTicket -> String
const getTicketColumnForPrint = R.cond([
  [
    R.propEq('status', WeeklyServiceTicketStatuses.approved),
    R.always('Checked'),
  ],
  [
    R.propEq('status', WeeklyServiceTicketStatuses.requested),
    R.always('Requested'),
  ],
  [
    R.propEq('status', WeeklyServiceTicketStatuses.received),
    R.always('Approve'),
  ],
  [
    R.propEq('status', WeeklyServiceTicketStatuses.notCompleted),
    R.always('Not Completed'),
  ],
  [R.T, R.always(NOTHING_UI_STRING)],
]);

// getYellowWarningIconPopoverText :: { project: Project } -> String
const getYellowWarningIconPopoverText = R.compose(
  (missingProperties) => `Supplier's ${missingProperties} is missing`,
  R.join(' and '),
  R.reject(R.isNil),
  R.juxt([
    R.ifElse(
      supplierHaveServiceEmail,
      R.always(null),
      R.always('service email'),
    ),
    R.ifElse(supplierHaveMobilePhone, R.always(null), R.always('mobile phone')),
  ]),
  R.head,
  R.pathOr([], ['project', 'suppliers']),
);

const getYellowWarningIcon = (props) => {
  const popoverText = getYellowWarningIconPopoverText(props);

  return <YellowWarningIcon popoverText={popoverText} />;
};

const getTicketColumnForUI = (week, project, refetchTableData) =>
  R.cond([
    [
      R.propEq('status', WeeklyServiceTicketStatuses.approved),
      WeekServiceTicketCheckbox,
    ],
    [
      R.propEq('status', WeeklyServiceTicketStatuses.requested),
      () => WeekServiceTicketRequestedLink(project, week, refetchTableData),
    ],
    [
      R.propEq('status', WeeklyServiceTicketStatuses.received),
      () => WeekServiceTicketApproveLink(project, week, refetchTableData),
    ],
    [
      R.propEq('status', WeeklyServiceTicketStatuses.notCompleted),
      R.always('Not completed'),
    ],
    [
      R.compose(
        R.not,
        R.both(supplierHaveServiceEmail, supplierHaveMobilePhone),
        R.head,
        R.pathOr([], ['project', 'suppliers']),
      ),
      getYellowWarningIcon,
    ],
    [R.T, R.always(NOTHING_UI_STRING)],
  ]);

// checkTicketReceivedColumn :: (Number, Boolean, () -> _) -> Project -> String
export const checkTicketReceivedColumn =
  (week, isPrint, refetchTableData) => (project) =>
    R.ifElse(
      R.compose(R.lte(week), getHousekeepingWeekCountByProject),
      R.compose(
        R.ifElse(
          () => isPrint,
          getTicketColumnForPrint,
          getTicketColumnForUI(week, project, refetchTableData),
        ),
        R.assoc('project', project),
        R.defaultTo({}),
        R.find(R.propEq('week', week)),
        R.propOr([], 'weeklyServiceTickets'),
      ),
      R.always('–'),
    )(project);

// formatExportHeader :: (Date, Date) -> String
export const formatExportHeader = (startDate, endDate) =>
  `Bass Pro Service Tickets \n${formatDate(startDate)} - ${formatDate(
    endDate,
  )}`;
