import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { func, node, string, oneOfType, instanceOf } from 'prop-types';
import { DatePicker, Button, Text, Dot } from 'poly-book-admin';
import { EthnicitySelect } from 'poly-admin-ui';
import { formatDate } from 'poly-utils';
import {
  supplierDocumentRequestTypes,
  NOTHING_UI_STRING,
  MWBEEthnicitiesUI,
} from 'poly-constants';

export const ApproveButtonS = styled(Button).attrs(() => ({
  size: 'middle',
}))`
  min-width: 70px;
  font-size: 12px;
  line-height: 18px;
`;

export function ApproveButton(props) {
  return <ApproveButtonS {...props}>Approve</ApproveButtonS>;
}

export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${R.propOr('flex-start', 'justifyContent')};
  align-items: center;
  gap: ${R.propOr('0', 'gap')};
  padding: ${R.propOr('0', 'padding')};
`;

const Label = styled(Text)`
  color: #999999;
  margin-right: 5px;
`;

export function TextWithLabel({ label, children }) {
  return (
    <FlexContainer>
      <Label>{label}</Label>
      {typeof children === 'string' ? (
        <Text>{children}</Text>
      ) : (
        <div>{children}</div>
      )}
    </FlexContainer>
  );
}

TextWithLabel.propTypes = {
  label: string.isRequired,
  children: oneOfType([node, string]),
};

TextWithLabel.defaultProps = {
  children: NOTHING_UI_STRING,
};

export const documentsDotColors = {
  [supplierDocumentRequestTypes.GL]: '#014398',
  [supplierDocumentRequestTypes.SA]: '#5C9819',
  [supplierDocumentRequestTypes.WC]: '#FFB841',
  [supplierDocumentRequestTypes.W9]: '#989996',
  [supplierDocumentRequestTypes.MBE]: '#a85a32',
  [supplierDocumentRequestTypes.WBE]: '#a8329d',
  [supplierDocumentRequestTypes.VETERAN]: '#32a2a8',
  [supplierDocumentRequestTypes.W9_MANUALLY]: '#a7c2a7',
};

export function DocumentTypeWithDot({ documentType }) {
  const color = documentsDotColors[documentType];

  return (
    <FlexContainer gap="6px">
      <Dot color={color} />
      <Text>{documentType}</Text>
    </FlexContainer>
  );
}

DocumentTypeWithDot.propTypes = {
  documentType: string.isRequired,
};

const TextWithLabelWrapper = styled.div`
  cursor: pointer;
`;

export function ExpirationDateComponent({
  documentExpiration,
  setDocumentExpiration,
}) {
  const [isDatePickerEnabled, setIsDatePickerEnabled] = useState(false);

  const onChange = (date) => {
    const changedDocumentExpiration = R.isEmpty(date) ? null : date;

    setDocumentExpiration(changedDocumentExpiration);
  };

  return isDatePickerEnabled ? (
    <TextWithLabel label="Expiration date:">
      <DatePicker showResetBtn value={documentExpiration} onChange={onChange} />
    </TextWithLabel>
  ) : (
    <TextWithLabelWrapper onClick={() => setIsDatePickerEnabled(true)}>
      <TextWithLabel label="Expiration date:">
        {formatDate(documentExpiration) || NOTHING_UI_STRING}
      </TextWithLabel>
    </TextWithLabelWrapper>
  );
}

ExpirationDateComponent.propTypes = {
  setDocumentExpiration: func,
  documentExpiration: oneOfType([instanceOf(Date), string]),
};

const EthnicitySelectS = styled(EthnicitySelect)`
  width: 200px;
`;

export function EthnicitySelectComponent({ onChange, minority }) {
  const [isShowSelect, setIsShowSelect] = useState(false);

  const handleChange = (value) => {
    onChange(value);
    setIsShowSelect(false);
  };

  return isShowSelect ? (
    <TextWithLabel label="Ethnicity:">
      <EthnicitySelectS required value={minority} onChange={handleChange} />
    </TextWithLabel>
  ) : (
    <TextWithLabelWrapper onClick={() => setIsShowSelect(true)}>
      <TextWithLabel label="Ethnicity:">
        {MWBEEthnicitiesUI[minority]}
      </TextWithLabel>
    </TextWithLabelWrapper>
  );
}

EthnicitySelectComponent.propTypes = {
  onChange: func.isRequired,
  minority: string,
};
