import * as R from 'ramda';
import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { string, func, bool, shape, oneOfType } from 'prop-types';
import { TextButton } from 'poly-book-admin';

import {
  LabelWrapper,
  LabelsWrapper,
  TextBtnWrapper,
} from './StyleComponents.js';

function FindContactLabelComponent({
  onSwitch,
  onAddContactToProperty,
  labelSwitchAddNewMode,
  labelSwitchFindMode,
  labelNotBelongProperty,
  mainLabel,
  isShowBelongMessage,
  isAddedContactToProperty,
  isCreateMode,
}) {
  return (
    <label>
      <LabelsWrapper>
        {isShowBelongMessage && !isCreateMode ? (
          <LabelWrapper>
            {labelNotBelongProperty} &nbsp;
            <TextBtnWrapper>
              <TextButton
                onClick={onAddContactToProperty(true)}
                disabled={isAddedContactToProperty}
              >
                Yes
              </TextButton>
            </TextBtnWrapper>
            &nbsp; /
            <TextBtnWrapper>
              <TextButton
                onClick={onAddContactToProperty(false)}
                disabled={!isAddedContactToProperty}
              >
                No
              </TextButton>
            </TextBtnWrapper>
            &nbsp; .
          </LabelWrapper>
        ) : null}
        {!isCreateMode && (
          <>
            <LabelWrapper>{labelSwitchAddNewMode}</LabelWrapper>
            <LabelWrapper>
              <TextButton onClick={onSwitch}>{`+ Add ${mainLabel}`}</TextButton>
            </LabelWrapper>
          </>
        )}
        {isCreateMode && (
          <LabelsWrapper>
            <LabelWrapper>{labelSwitchFindMode} </LabelWrapper>
            <LabelWrapper>
              <TextButton onClick={onSwitch}>Cancel</TextButton>
            </LabelWrapper>
          </LabelsWrapper>
        )}
      </LabelsWrapper>
    </label>
  );
}

FindContactLabelComponent.propTypes = {
  onSwitch: func.isRequired,
  labelSwitchAddNewMode: string.isRequired,
  labelNotBelongProperty: string.isRequired,
  mainLabel: string.isRequired,
  onAddContactToProperty: func.isRequired,
  isAddedContactToProperty: bool.isRequired,
  isShowBelongMessage: bool.isRequired,
  labelSwitchFindMode: string.isRequired,
  isCreateMode: bool,
};

const USER_LINKS = gql`
  query USER_LINKS($userId: ID!) {
    user(id: $userId) {
      _id
      links {
        type
        clientId
        propertyId
      }
    }
  }
`;

// getUserBelongThisProperties :: User -> Bool
const getUserBelongThisProperties = (propertyId) =>
  R.compose(R.includes(propertyId), R.map(R.path(['propertyId'])));

export function FindContactLabel({
  value,
  onChange,
  userId,
  propertyId,
  ...props
}) {
  const { data, loading } = useQuery(USER_LINKS, {
    variables: { userId },
    fetchPolicy: 'network-only',
  });

  const user = R.propOr({}, 'user', data);

  const isUserBelongThisProperties = getUserBelongThisProperties(
    R.pathOr([], ['links'], user),
  )(propertyId);

  const isShowBelongMessage = !!(
    !loading &&
    !!userId &&
    !isUserBelongThisProperties
  );

  useEffect(() => {
    if (isShowBelongMessage) {
      onChange({
        ...value,
        isAddContactToProperty: isShowBelongMessage,
      });
    }
  }, []);

  const onSwitch = () =>
    onChange({ ...value, isCreateMode: !value.isCreateMode });

  const onAddContactToProperty = (isAddContactToProperty) => (e) => {
    e.preventDefault();
    onChange({
      ...value,
      isAddContactToProperty,
      contactLinks: user.links,
    });
  };

  const findContactLabelProps = {
    onSwitch,
    onAddContactToProperty,
    isShowBelongMessage,
    isAddedContactToProperty: R.propOr(false, 'isAddContactToProperty', value),
    isCreateMode: value.isCreateMode,
    isUserBelongThisProperties,
    ...props,
  };

  return <FindContactLabelComponent {...findContactLabelProps} />;
}

FindContactLabel.propTypes = {
  value: oneOfType([
    string,
    shape({
      isCreateMode: bool,
    }),
  ]).isRequired,
  onChange: func.isRequired,
  userId: string.isRequired,
  propertyId: string,
};
