import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

import { Icon } from 'poly-book-admin/src/Icon/index.js';
import { Popover } from 'poly-book-admin/src/Popover/index.js';

const IconS = styled(Icon)`
  padding-left: 5px;

  path {
    fill: #999999;
  }
`;

export const ShortExplanationKeys = {
  enableGlCodes: 'enableGlCodes',
  enablePOs: 'enablePOs',
  enableCostCenter: 'enableCostCenter',
  enableChildAccounts: 'enableChildAccounts',
  isUMCAccountFormat: 'isUMCAccountFormat',
  enablePropertyLocationHierarchy: 'enablePropertyLocationHierarchy',
  requiresSignOffSheet: 'requiresSignOffSheet',
  enableReportOnlyProjectType: 'enableReportOnlyProjectType',
  reportOnlyClientInvoiceCreation: 'reportOnlyClientInvoiceCreation',
  allowProjectCreation: 'allowProjectCreation',
  showPMsAndAssets: 'showPMsAndAssets',
  requesterSurveyEnabled: 'requesterSurveyEnabled',
  allowAdminProjectCreation: 'allowAdminProjectCreation',
  allowAssetScannerProjectCreation: 'allowAssetScannerProjectCreation',
  notifyOnUnassignedProject: 'notifyOnUnassignedProject',
  notifyOnInternalProjectUpdateAdded: 'notifyOnInternalProjectUpdateAdded',
  notifyOnClientCommentAdded: 'notifyOnClientCommentAdded',
  notifyOnExternalRequest: 'notifyOnExternalRequest',
  isPORequiredForProject: 'isPORequiredForProject',
};

const ShortExplanationsMap = {
  [ShortExplanationKeys.enableGlCodes]:
    'The field GL codes is available during the project creation.',
  [ShortExplanationKeys.enablePOs]:
    'Purchase orders can be created within the client.',
  [ShortExplanationKeys.enableCostCenter]:
    'When creating a project, Cost Center field will be available.',
  [ShortExplanationKeys.enableChildAccounts]:
    'Children company can be created within the client.',
  [ShortExplanationKeys.isUMCAccountFormat]:
    'United Methodist Church format adds additional fields to the property on building information.',
  [ShortExplanationKeys.enablePropertyLocationHierarchy]:
    'This feature allows to group the reports based on the hierarchy you set.',
  [ShortExplanationKeys.requiresSignOffSheet]:
    'When Workorder is sent to the supplier. Sign Off sheet is attached. Sign Off sheet must be filled on the site.',
  [ShortExplanationKeys.enableReportOnlyProjectType]:
    'Report Only types of projects can be created.',
  [ShortExplanationKeys.reportOnlyClientInvoiceCreation]:
    'Within the project an invoice for the client can be created as one of the tasks.',
  [ShortExplanationKeys.allowProjectCreation]:
    'Projects can be created through the Client Portal.',
  [ShortExplanationKeys.showPMsAndAssets]:
    'Assets and Preventative Maintenance projects are available in the Client Portal.',
  [ShortExplanationKeys.requesterSurveyEnabled]:
    'Feedback form will be sent to the requestor.',
  [ShortExplanationKeys.allowAdminProjectCreation]:
    'Projects can be created through the Admin.',
  [ShortExplanationKeys.allowAssetScannerProjectCreation]:
    'Projects can be created through the Asset Scanner.',
  [ShortExplanationKeys.notifyOnUnassignedProject]:
    'You will receive notifications on all the projects created on Admin app, that are not assigned.',
  [ShortExplanationKeys.notifyOnInternalProjectUpdateAdded]:
    'You will receive the notifications on all the activity of the internal team and system updates (like magic email) on the projects through Admin app, excluding project creation.',
  [ShortExplanationKeys.notifyOnClientCommentAdded]:
    'You will receive notifications only on client comments related to the project (updates done on Client Portal or Asset Scanner apps).',
  [ShortExplanationKeys.notifyOnExternalRequest]:
    'You will receive notifications when the projects are created from the Client Portal or Asset Scanner or Requests app.',
  [ShortExplanationKeys.isPORequiredForProject]:
    'The PO is mandatory for the project.',
};

const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: start;
  background-color: #12347a;
  color: #fff;
  border-radius: 5px;
  max-width: 230px;
  font-size: 12px;
`;

export function HelpTooltip({ position, shortExplanation }) {
  if (!shortExplanation) {
    return null;
  }

  return (
    <Popover
      position={position}
      isClickable={false}
      bgColor="#12347a"
      title={<IconS name="confirm" size={20} />}
      content={
        <PopoverContentWrapper>
          {ShortExplanationsMap[shortExplanation]}
        </PopoverContentWrapper>
      }
    />
  );
}

HelpTooltip.propTypes = {
  position: string,
  shortExplanation: string,
};
