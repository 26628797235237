import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Text } from 'poly-book-admin';
import { func, number, shape, string } from 'prop-types';
import {
  commonFetchHeaders,
  InputSection,
  PasswordInputSection,
  TwoFACodeInput,
} from 'poly-client-utils';
import { graphInit2FARoute, graphVerify2FARoute } from 'poly-utils';

export const twoFAFormId = 'twoFAFormId';

const ContentWrapper = styled.div`
  width: 500px;
  padding: 40px;
  background-color: #fff;
`;

const Title = styled.div`
  color: #1a1514;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TextS = styled(Text)`
  font-family: 'TTNormsRegular';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #5e6271;
`;

const ButtonS = styled(Button)`
  height: 34px;
  margin-top: 24px;
  width: 100%;
`;

const FormS = styled.form`
  width: 100%;
  margin-top: 20px;
`;

const ErrorText = styled(TextS)`
  padding-top: 5px;
  color: #dd6263;
`;

const settings = process.env;

const { GRAPHQL_HTTP_URL = '' } = settings;

const INIT_2FA_ROUTE = graphInit2FARoute(GRAPHQL_HTTP_URL);

const VERIFY_2FA_ROUTE = graphVerify2FARoute(GRAPHQL_HTTP_URL);

const fetchPost = async (route, data) => {
  const res = await fetch(route, {
    body: JSON.stringify(data),
    headers: commonFetchHeaders,
    method: 'POST',
  });

  const body = await res.json();
  if (!res.ok) {
    return Promise.reject(body.message);
  }

  return body;
};

function TwoFACredentialsForm({ setStep, setQrCodeBase64Url, setCredentials }) {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const credentials = {
        email: e.target.elements.email.value,
        password: e.target.elements.password.value,
      };

      const { qrCodeBase64Url } = await fetchPost(INIT_2FA_ROUTE, credentials);

      setQrCodeBase64Url(qrCodeBase64Url);
      setCredentials(credentials);
      setStep(2);
    } catch (error) {
      setErrorMsg(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TextS>
        Note that 2FA is mandatory for your account. Please, enter your
        credentials below to continue.
      </TextS>
      {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
      <FormS onSubmit={onSubmit}>
        <InputSection
          title="EMAIL"
          inputProps={{
            type: 'email',
            name: 'email',
            placeholder: 'Email Address',
            autoFocus: true,
            required: true,
            lower: true,
          }}
        />
        <PasswordInputSection
          inputProps={{
            placeholder: 'Password',
            type: 'password',
            name: 'password',
            required: true,
          }}
        />
        <ButtonS loader={loading} disabled={loading}>
          Next
        </ButtonS>
      </FormS>
    </>
  );
}

TwoFACredentialsForm.propTypes = {
  setStep: func.isRequired,
  setQrCodeBase64Url: func.isRequired,
  setCredentials: func.isRequired,
};

function TwoFACodeForm({ credentials, handleAfterSubmit }) {
  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const code = e.target.elements.code.value;

    try {
      await fetchPost(VERIFY_2FA_ROUTE, { ...credentials, code });
      setErrorMsg(null);
      handleAfterSubmit();
    } catch (err) {
      setErrorMsg(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TextS>Enter generated code below and click &quot;Confirm&quot;.</TextS>
      <TextS>Note that code changes every minute.</TextS>
      {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
      <FormS onSubmit={onSubmit}>
        <TwoFACodeInput />
        <ButtonS loader={loading} disabled={loading}>
          Confirm
        </ButtonS>
      </FormS>
    </>
  );
}

TwoFACodeForm.propTypes = {
  credentials: shape({ password: string, email: string }),
  handleAfterSubmit: func.isRequired,
};

const ImgWrapper = styled.div`
  display: flex;
  min-height: 180px;
  justify-content: center;
  align-items: center;
`;

const Ol = styled.ol`
  padding-inline-start: 15px;

  li {
    color: #5e6271;
    line-height: 35px;
  }
`;

function Two2FASetupInstructions({ qrCodeBase64Url, setStep }) {
  return (
    <div>
      <Ol>
        <li>
          <TextS>Open your favorite One Time Password (OTP) app.</TextS>
          <TextS>
            {/* eslint-disable-next-line @cspell/spellchecker */}
            If you don&apos;t have one we recommend:
            <ul>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.beemdevelopment.aegis"
                  target="blank"
                >
                  Aegis
                </a>{' '}
                (for Android)
              </li>
              <li>
                <a
                  href="https://apps.apple.com/us/app/raivo-otp/id1459042137"
                  target="blank"
                >
                  {/* eslint-disable-next-line @cspell/spellchecker */}
                  Raivo OTP{' '}
                </a>{' '}
                (for iOS).
              </li>
            </ul>
          </TextS>
        </li>
        <li>
          <TextS>Scan QR code bellow inside app.</TextS>
        </li>
      </Ol>
      <ImgWrapper>
        <img alt="QR code" src={qrCodeBase64Url} />
      </ImgWrapper>
      <ButtonS onClick={() => setStep(3)}>Next</ButtonS>
    </div>
  );
}
Two2FASetupInstructions.propTypes = {
  qrCodeBase64Url: string,
  setStep: func.isRequired,
};

const StepIndicatorS = styled.div`
  height: 3px;
  width: 14px;
  border-radius: 6px;
  background-color: ${({ item, step }) =>
    item > step ? '#F2F2F2' : '#014398'};
`;

const IndicatorsWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(71, 119, 216, 0.08);
  border-radius: 50%;
`;

function StepIndicators({ step }) {
  return (
    <IndicatorsWrapper>
      <StepIndicatorS item={1} step={step} />
      <StepIndicatorS item={2} step={step} />
      <StepIndicatorS item={3} step={step} />
    </IndicatorsWrapper>
  );
}

StepIndicators.propTypes = {
  step: number,
};

export function Setup2FAForm({ handleAfterSubmit }) {
  const [step, setStep] = useState(1);
  const [qrCodeBase64Url, setQrCodeBase64Url] = useState(null);
  const [credentials, setCredentials] = useState(null);

  const stepsMap = {
    1: (
      <TwoFACredentialsForm
        setStep={setStep}
        setQrCodeBase64Url={setQrCodeBase64Url}
        setCredentials={setCredentials}
      />
    ),
    2: (
      <Two2FASetupInstructions
        setStep={setStep}
        qrCodeBase64Url={qrCodeBase64Url}
      />
    ),
    3: (
      <TwoFACodeForm
        credentials={credentials}
        handleAfterSubmit={handleAfterSubmit}
      />
    ),
  };

  return (
    <ContentWrapper>
      <FlexBetween>
        <IconWrapper>
          <Icon name="lock" />
        </IconWrapper>
        <StepIndicators step={step} />
      </FlexBetween>
      <Title>Set up two factor auth (2FA)</Title>
      {stepsMap[step]}
    </ContentWrapper>
  );
}

Setup2FAForm.propTypes = {
  handleAfterSubmit: func.isRequired,
};
