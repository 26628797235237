import React from 'react';
import * as R from 'ramda';
import { LinkButton } from 'poly-book-admin';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import { prepareExcelWorkbookByConfig } from 'poly-utils';
import { func, string } from 'prop-types';

import { downloadExcelWorkbook } from 'poly-client-utils/src/excel-export/download.js';

// getReportXLSFileNameByTitle :: String -> String
const getReportXLSFileNameByTitle = R.compose(
  R.concat(R.__, '.xlsx'),
  R.replace(/\s/g, '-'),
  R.toLower,
);

export function QuoteDiscrepanciesXlsExportButton({
  getXLSConfig,
  title,
  ...props
}) {
  const generateXls = () => {
    const config = getXLSConfig(props);

    const workbook = prepareExcelWorkbookByConfig(config);

    return downloadExcelWorkbook(getReportXLSFileNameByTitle(title), workbook);
  };

  return <LinkButton onClick={generateXls}>{EXPORT_XLS_CAPTION}</LinkButton>;
}

QuoteDiscrepanciesXlsExportButton.propTypes = {
  getXLSConfig: func.isRequired,
  title: string,
};
