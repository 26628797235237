import React from 'react';
import { centsToDollarsWithFormat } from 'poly-utils';
import styled from 'styled-components';
import { number } from 'prop-types';

import { isAdminPOWithLowBalance } from 'poly-utils/src/adminPurchaseOrder.js';
import { PurchaseOrderBalanceWarning } from './PurchaseOrderBalanceWarning.js';

const CurrentBalanceWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 99999;
`;

export function CurrentBalanceComp(props) {
  const currentBalance = centsToDollarsWithFormat(props.currentBalance);
  const isLowBalance = isAdminPOWithLowBalance(props);

  return (
    <CurrentBalanceWrapper>
      {currentBalance}
      {isLowBalance && (
        <PurchaseOrderBalanceWarning warning="The balance is reaching its limit." />
      )}
    </CurrentBalanceWrapper>
  );
}

CurrentBalanceComp.propTypes = {
  currentBalance: number.isRequired,
};
