import React, { useContext } from 'react';
import styled from 'styled-components';
import { bool, object } from 'prop-types';
import { getThemeColor } from 'poly-book-admin';

import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import {
  WhiteBodyWrapper,
  PayInvoicesTableCard,
} from '../PaySuppliersPage/PaySuppliersComponents.js';
import { BankRegistersTable } from './BankRegistersTable.js';
import {
  bankRegistersQueryPropType,
  bankRegistersQueryDataPropType,
} from './commonPropTypes.js';
import { getAccountTransactionsByData } from './bankRegistersQuery.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';

const WhiteBodyWrapperS = styled(WhiteBodyWrapper)`
  background-color: ${getThemeColor(['lightest'])};

  thead {
    background-color: ${getThemeColor(['lightest'])};
  }
`;

export function BankRegistersBody({
  query,
  hideCheckNumber,
  data,
  tableProps,
}) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  return (
    <WhiteBodyWrapperS height={contentHeight}>
      <PayInvoicesTableCard height="100%">
        {getAccountTransactionsByData(data).length === 0 ? (
          <EmptyListMessage />
        ) : (
          <BankRegistersTable
            accountId={query.accountId}
            data={data}
            searchTerm={query.searchTerm}
            {...tableProps}
            hideCheckNumber={hideCheckNumber}
            showScrollBar
          />
        )}
      </PayInvoicesTableCard>
    </WhiteBodyWrapperS>
  );
}

BankRegistersBody.propTypes = {
  query: bankRegistersQueryPropType,
  hideCheckNumber: bool,
  data: bankRegistersQueryDataPropType,
  // we don't care about the one below
  // eslint-disable-next-line react/forbid-prop-types
  tableProps: object.isRequired,
};
