import React from 'react';
import { string, func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { TooltipButton } from 'poly-admin-ui';
import {
  useNotificationContext,
  LinkButton,
  Button,
  Text,
} from 'poly-book-admin';

import { TooltipWrapper } from './ClonePMProjectButton.js';

const GENERATE_CHILD_PMS = gql`
  mutation GENERATE_CHILD_PMS($id: ID!, $update: RecurringProjectUpdateInput!) {
    updateRecurringProject(id: $id, update: $update) {
      _id
    }
  }
`;

function GenerateButtonTooltip({ _id, closeTooltip }) {
  const [generateChildPMs] = useMutation(GENERATE_CHILD_PMS);
  const { showSuccessNotification } = useNotificationContext();

  const onGenerateChildPMs = async (e) => {
    closeTooltip(e);
    await generateChildPMs({
      variables: { id: _id, update: { disableChildrenGeneration: false } },
    });
    showSuccessNotification('Child PMs were successfully generated');
  };

  return (
    <TooltipWrapper>
      <Text>Are you sure you want to generate child PMs?</Text>
      <Button onClick={onGenerateChildPMs} size="small">
        Generate
      </Button>
    </TooltipWrapper>
  );
}

GenerateButtonTooltip.propTypes = {
  _id: string.isRequired,
  closeTooltip: func.isRequired,
};

export function GenerateChildPMsButton({ _id }) {
  const btnProps = {
    Wrapper: LinkButton,
    tooltipBtnProps: { _id },
    Tooltip: GenerateButtonTooltip,
    style: { position: 'relative' },
    tooltipProps: { position: 'right', top: '-20px' },
  };

  return <TooltipButton {...btnProps}>Generate Child PMs</TooltipButton>;
}

GenerateChildPMsButton.propTypes = { _id: string.isRequired };
