import React from 'react';
import { string } from 'prop-types';
import { filesCollections } from 'poly-constants';
import { SidebarFilesTab } from 'poly-admin-ui';

export function ClientSidebarFilesTab({ clientId }) {
  const tabProps = {
    collection: filesCollections.CLIENTS,
    entity: { _id: clientId, preview: 'client' },
  };

  return <SidebarFilesTab {...tabProps} />;
}

ClientSidebarFilesTab.propTypes = { clientId: string.isRequired };
