import styled from 'styled-components';
import { node, string, bool } from 'prop-types';
import React, { createContext, useState, useContext, useMemo } from 'react';
import {
  SearchPageHeaderContainer as SearchPageHeaderContainerImp,
  DefaultBodyWrapper,
  ContentWrapper,
  getThemeColor,
} from 'poly-book-admin';

export const PageWithSearchHeaderContext = createContext({});
const useSearchPageContext = () => useContext(PageWithSearchHeaderContext);

export function PageWithSearchHeader({ headerHeight, children }) {
  const [isOpen, setIsOpen] = useState(true);
  const onArrowClick = () => setIsOpen(!isOpen);
  const contentHeight = isOpen
    ? `calc(100% - ${headerHeight})`
    : 'calc(100% - 57px)';
  const contextValue = useMemo(
    () => ({
      onArrowClick,
      contentHeight,
      headerHeight,
      isOpen,
    }),
    [isOpen, headerHeight],
  );

  return (
    <PageWithSearchHeaderContext.Provider value={contextValue}>
      {children}
    </PageWithSearchHeaderContext.Provider>
  );
}

PageWithSearchHeader.propTypes = {
  headerHeight: string.isRequired,
  children: node.isRequired,
};

export function SearchPageHeaderContainer({ children, ...props }) {
  const { isOpen, onArrowClick, headerHeight } = useSearchPageContext();
  return (
    <SearchPageHeaderContainerImp
      isOpen={isOpen}
      onArrowClick={onArrowClick}
      height={headerHeight}
      {...props}
    >
      {children}
    </SearchPageHeaderContainerImp>
  );
}

const DefaultBodyWrapperS = styled(DefaultBodyWrapper)`
  background: ${getThemeColor(['white'])};
`;

export function SearchPageContentWrapper({ children, className }) {
  const { contentHeight } = useSearchPageContext();
  return (
    <DefaultBodyWrapperS className={className} height={contentHeight}>
      <ContentWrapper>{children}</ContentWrapper>
    </DefaultBodyWrapperS>
  );
}

SearchPageContentWrapper.propTypes = {
  children: node.isRequired,
  className: string,
};

SearchPageHeaderContainer.propTypes = {
  children: node.isRequired,
};

export function SearchPageConditionalWrapper({ condition, children }) {
  return condition ? (
    <SearchPageContentWrapper>{children}</SearchPageContentWrapper>
  ) : null;
}

SearchPageConditionalWrapper.propTypes = {
  condition: bool.isRequired,
  children: node.isRequired,
};
