import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { arrayOf, func, string, shape, number, bool } from 'prop-types';
import { pathOrNothingUI, useOutSidebarContext } from 'poly-client-utils';
import { WindowedTable, moneyColumnStyles, LinkButton } from 'poly-book-admin';
import { applyProp, formatTotal, formatDate } from 'poly-utils';
import { JournalPaymentStatus, NOTHING_UI_STRING } from 'poly-constants';
import {
  useSelectableTableRowsProps,
  useMapConfigToTableProps,
} from 'poly-admin-ui';

import { transactionsDetailsSidebarId } from '../../sidebars/TransactionDetailsSidebar/helpers.js';
import { TransactionsDetailsSidebar } from '../../sidebars/TransactionDetailsSidebar/TransactionDetailsSidebar.js';
import { RedSpan } from '../../modules/tables/tablesStyles.js';

const BatchPaymentsTableS = styled(WindowedTable)`
  tbody {
    vertical-align: middle;
  }

  thead {
    height: 45px;
  }

  tr {
    width: 100%;
    margin: 0;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 250px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 150px;
  }

  ${moneyColumnStyles(6)};

  th:nth-child(7),
  td:nth-child(7) {
    width: 45px;
  }
`;

const getMasterSupplierName = R.pathOr(NOTHING_UI_STRING, [
  'masterSupplier',
  'company',
  'name',
]);

function SplitPaymentComponent({ transaction_number }) {
  const { openOutSidebar } = useOutSidebarContext();

  const onClick = () =>
    openOutSidebar({
      id: transactionsDetailsSidebarId,
      content: (
        <TransactionsDetailsSidebar transactionNumber={transaction_number} />
      ),
      width: 950,
      alwaysFirst: true,
    });

  return <LinkButton onClick={onClick}>Split</LinkButton>;
}

SplitPaymentComponent.propTypes = {
  transaction_number: number,
};

const masterSupplierNameWithLink = (props) => (
  <div>
    {getMasterSupplierName(props)} (<SplitPaymentComponent {...props} />)
  </div>
);

function CreditAmountC({ amount }) {
  return <RedSpan>({formatTotal(amount)})</RedSpan>;
}

CreditAmountC.propTypes = {
  amount: number,
};

const batchPaymentsTableConfig = [
  ['Date', applyProp(formatDate)('date')],
  [
    'Supplier Name',
    R.ifElse(
      R.prop('is_split'),
      masterSupplierNameWithLink,
      R.path(['supplier', 'company', 'name']),
    ),
  ],
  ['Supplier Tax ID #', pathOrNothingUI(['supplier', 'tax', 'id'])],
  ['Bank', pathOrNothingUI(['account', 'name'])],
  [
    'Check Number',
    R.ifElse(
      R.propEq('payment_status', JournalPaymentStatus.RECONCILED),
      R.always('Reconciled'),
      pathOrNothingUI(['check_number']),
    ),
  ],
  [
    'Amount',
    R.ifElse(
      R.propSatisfies(R.gt(0), 'amount'),
      CreditAmountC,
      R.compose(formatTotal, R.prop('amount')),
    ),
  ],
];

// isPaymentReconciled :: MoneyTransaction -> Boolean
const isPaymentReconciled = R.propEq(
  'payment_status',
  JournalPaymentStatus.PENDING,
);

export function VoidSupplierPaymentsTable({
  batchPayments,
  selectedPayments,
  setSelectedPayments,
  isRefetching,
  ...restProps
}) {
  const [tableSort, setTableSort] = useState({ columnKey: 0, dir: 1 });

  const onHeaderCellClick = (columnKey) => {
    if (columnKey === tableSort.columnKey) {
      setTableSort({ columnKey: tableSort.columnKey, dir: tableSort.dir * -1 });
    }
  };

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    batchPaymentsTableConfig,
    tableSort.dir > 0 ? batchPayments : R.reverse(batchPayments),
  );

  const { toggleRow } = useSelectableTableRowsProps(
    selectedPayments,
    setSelectedPayments,
    batchPayments,
  );

  return (
    <BatchPaymentsTableS
      headers={headers}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      selectedRows={selectedPayments}
      toggleRow={isRefetching ? () => null : toggleRow}
      onHeaderCellClick={onHeaderCellClick}
      selectedRowsInEnd
      sorting={tableSort}
      isSortable
      isPartiallySelectable
      isRowSelectable={isPaymentReconciled}
      moreScrollPosition="0px"
      showScrollBar
      {...restProps}
    />
  );
}

VoidSupplierPaymentsTable.propTypes = {
  batchPayments: arrayOf(
    shape({
      _id: string.isRequired,
      transactionNumber: number,
      checkNumber: number,
      account: shape({ name: string.isRequired }).isRequired,
      supplier: shape({
        company: shape({ name: string.isRequired }).isRequired,
      }).isRequired,
      amount: number.isRequired,
    }),
  ).isRequired,
  selectedPayments: arrayOf(string).isRequired,
  setSelectedPayments: func.isRequired,
  isRefetching: bool.isRequired,
};
