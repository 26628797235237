/* eslint-disable node/file-extension-in-import */
/* eslint-disable import/extensions */
import styled from 'styled-components';
import ReactDOM from 'react-dom/client';
import React, { useEffect, useState, useCallback } from 'react';
import { graphqlAppStatusRoute, debounce } from 'poly-utils';

const SuccessNotificationMessage = 'The application is back to normal';
const MaintenanceNotificationMessage =
  'Unfortunately, Poly is temporarily unavailable due to maintenance. This notification will be closed automatically, when maintenance is finished. Thank you.';
const appMaintenanceId = 'application-maintenance';
const successNotificationId = 'success-notification';
const INTERVAL_TO_CHECK = 5000; // check every 5 seconds

const settings = process.env;

const { GRAPHQL_HTTP_URL = '' } = settings;

const APP_STATUS_ROUTE = graphqlAppStatusRoute(GRAPHQL_HTTP_URL);

// fetchAppStatus :: _ -> Boolean
const fetchAppStatus = async () => {
  const response = await fetch(APP_STATUS_ROUTE);
  return response.ok;
};

// renderElementToRoot :: (String, ReactElement) -> _
const renderElementToRoot = (id, ElementToRender) => {
  const renderedElement = document.getElementById(id);

  if (!renderedElement) {
    const div = document.createElement('div');
    div.setAttribute('id', id);
    const root = ReactDOM.createRoot(div);
    root.render(<ElementToRender />);

    const appRoot = document.getElementById('root');
    appRoot.prepend(div);
  }
};

const ApplicationMaintenanceNotificationWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.2vw;
  flex-wrap: nowrap;
  color: #000;
  background-color: #fee637;
  height: 4vw;
  max-height: 60px;
  position: relative;
  z-index: 10000;

  @media screen and (min-width: 1400px) {
    font-size: 17px;
  }
`;

const SuccessNotificationWrapperS = styled.div`
  width: 20.4vw;
  z-index: 20000;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 1.2vw;
  border-radius: 3px;
  background-color: #dfedce;
  border: 1px solid rgba(132, 188, 69, 0.31);
  color: #5c9819;
  padding: 1.2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 1400px) {
    font-size: 16px;
  }
`;

function SuccessNotification() {
  const onClose = () => {
    const successNotificationElement = document.getElementById(
      successNotificationId,
    );

    if (successNotificationElement) {
      successNotificationElement.remove();
    }
  };

  const onCloseDebounced = useCallback(
    debounce(INTERVAL_TO_CHECK)(onClose),
    [],
  );

  useEffect(() => {
    onCloseDebounced();
  }, []);

  return (
    <SuccessNotificationWrapperS onClick={onClose}>
      {SuccessNotificationMessage}
    </SuccessNotificationWrapperS>
  );
}

function ApplicationMaintenanceNotification() {
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const onCheckAppStatus = async () => {
      try {
        const isResponseOk = await fetchAppStatus();

        if (isResponseOk) {
          clearInterval(intervalId);
          setIntervalId(null);

          const appMaintenanceElement =
            document.getElementById(appMaintenanceId);

          if (appMaintenanceElement) {
            renderElementToRoot(successNotificationId, SuccessNotification);

            appMaintenanceElement.remove();
          }

          return false;
        }

        return true;
      } catch {
        return true;
      }
    };

    onCheckAppStatus().then((needToCheckPeriodically) => {
      if (needToCheckPeriodically) {
        const newIntervalId = setInterval(onCheckAppStatus, INTERVAL_TO_CHECK);

        setIntervalId(newIntervalId);
      }
    });
  }, []);

  useEffect(
    () => () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    },
    [intervalId],
  );

  return (
    <ApplicationMaintenanceNotificationWrapperS>
      {MaintenanceNotificationMessage}
    </ApplicationMaintenanceNotificationWrapperS>
  );
}

// handleApplicationMaintenance :: _ -> _
export const handleApplicationMaintenance = () => {
  renderElementToRoot(appMaintenanceId, ApplicationMaintenanceNotification);
};
