import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape, func } from 'prop-types';
import { commonFileValidators, validateFilesFunc } from 'poly-client-utils';
import { Input, DatePicker, FilePicker, Editor } from 'poly-book-admin';
import {
  halfWidth,
  MoneyInput,
  SupplierSelect,
  useNotificationState,
} from 'poly-admin-ui';
import { MIME_TYPES } from 'poly-constants/src/files.js';

import { AssetTypeSelect } from './components/AssetTypeSelect.js';
import { AssetModelSelect } from './components/AssetModelSelect.js';
import { AddNewAssetEntity } from './components/AddNewAssetEntity.js';
import { AssetClientSelect } from './components/AssetClientSelect.js';
import { AssetStatusSelect } from './components/AssetStatusSelect.js';
import { AssetPropertySelect } from './components/AssetPropertySelect.js';
import { AssetManufacturerSelect } from './components/AssetManufacturerSelect.js';
import { AssetReplacementCostInput } from './components/AssetReplacementCostInput.js';

function AssetPhotoPicker(props) {
  const { showErrorNotification } = useNotificationState();
  const acceptTypes = [
    MIME_TYPES.IMAGE_PNG,
    MIME_TYPES.IMAGE_JPG,
    MIME_TYPES.IMAGE_JPEG,
  ];

  const onTypeError = () =>
    showErrorNotification('Asset Photo accepts only: png, jpg, jpeg');

  return (
    <FilePicker
      {...props}
      multiple={false}
      acceptTypes={acceptTypes}
      onTypeError={onTypeError}
    />
  );
}

function InputWithLimit(props) {
  return <Input {...props} showCharactersLeft charactersLimit={255} />;
}

function AssetCodeInput(props) {
  return <Input {...props} showCharactersLeft charactersLimit={6} />;
}

function AssetDatePicker(props) {
  return <DatePicker {...props} showResetBtn width="100%" />;
}

function FocusedManufacturerInput(props) {
  return <InputWithLimit {...props} autoFocus />;
}

function AddNewManufacturer({
  changeFieldValue,
  onChange,
  formData,
  ...props
}) {
  const { searchedManufacturer } = formData;

  const onChangeHandler = (e) => {
    onChange(e);
    changeFieldValue('modelId', null);
    changeFieldValue('newManufacturerName', searchedManufacturer);

    if (!e) {
      changeFieldValue('searchedManufacturer', null);
    }
  };

  return (
    <AddNewAssetEntity
      {...props}
      entity="manufacturer"
      onChange={onChangeHandler}
    />
  );
}

AddNewManufacturer.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
  formData: shape({ searchedManufacturer: string }).isRequired,
};

function AddNewModel({ changeFieldValue, onChange, formData, ...props }) {
  const { searchedModel } = formData;

  const onChangeHandler = (e) => {
    onChange(e);
    changeFieldValue('newModelName', searchedModel);

    if (!e) {
      changeFieldValue('searchedModel', null);
    }
  };

  return (
    <AddNewAssetEntity
      {...props}
      entity="model"
      onChange={onChangeHandler}
      isNewManufacturer={formData.isNewManufacturer}
    />
  );
}

AddNewModel.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
  formData: shape({ searchedModel: string }).isRequired,
};

function NewModelInput({ formData, ...props }) {
  const { manufacturerId, newManufacturerName } = formData;

  return (
    <InputWithLimit
      {...props}
      disabled={!manufacturerId && !newManufacturerName}
    />
  );
}

NewModelInput.propTypes = {
  formData: shape({ manufacturerId: string, newManufacturerName: string }),
};

const EditorS = styled(Editor)`
  .ql-editor {
    max-height: none;
  }
`;

function AssetRemarksInput(props) {
  return (
    <EditorS {...props} id="asset-remarks-input" placeholder="Enter Remarks" />
  );
}

export const assetFormSections = [
  {
    order: 1,
    layout: { margin: '24px 0 0 0' },
    fields: [
      {
        order: 1,
        label: 'Display Name',
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'name',
          Component: Input,
        },
      },
      {
        order: 3,
        label: 'Status',
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'status',
          Component: AssetStatusSelect,
        },
        renderIf: R.prop('isEdit'),
      },
      {
        order: 3,
        label: 'Client',
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'clientId',
          withChangeFieldValue: true,
          Component: AssetClientSelect,
        },
        renderIf: R.prop('showClient'),
        validators: [[R.identity, 'Client is required']],
        required: true,
      },
      {
        order: 4,
        label: 'Property',
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'propertyId',
          withFormData: true,
          Component: AssetPropertySelect,
        },
        validators: [[R.identity, 'Property is required']],
        required: true,
      },
      {
        order: 5,
        label: 'Client Asset Code',
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'equipmentType',
          Component: InputWithLimit,
        },
      },
      {
        order: 6,
        label: 'Poly Asset Code',
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'qrCodeId',
          Component: AssetCodeInput,
        },
        validators: [
          [
            R.ifElse(R.isNil, R.T, R.compose(R.equals(6), R.length)),
            'Poly Asset Code is not correct',
          ],
        ],
      },
      {
        order: 7,
        label: 'Description',
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'description',
          Component: InputWithLimit,
        },
      },
      {
        order: 8,
        label: 'Manufacturer',
        layout: { row: 5, width: halfWidth },
        field: {
          withFormData: true,
          name: 'manufacturerId',
          withChangeFieldValue: true,
          Component: AssetManufacturerSelect,
        },
        validators: [[R.identity, 'Manufacturer is required']],
        renderIf: R.complement(R.prop('isNewManufacturer')),
        required: true,
      },
      {
        order: 8,
        label: 'Manufacturer',
        layout: { row: 5, width: halfWidth },
        field: {
          name: 'newManufacturerName',
          Component: FocusedManufacturerInput,
        },
        validators: [[R.identity, 'Manufacturer is required']],
        renderIf: R.prop('isNewManufacturer'),
        required: true,
      },
      {
        order: 9,
        label: 'Model',
        layout: { row: 5, width: halfWidth },
        field: {
          name: 'modelId',
          withFormData: true,
          withChangeFieldValue: true,
          Component: AssetModelSelect,
        },
        renderIf: R.both(
          R.complement(R.prop('isNewModel')),
          R.complement(R.prop('isNewManufacturer')),
        ),
      },
      {
        order: 9,
        label: 'Model',
        layout: { row: 5, width: halfWidth },
        field: {
          withFormData: true,
          name: 'newModelName',
          Component: NewModelInput,
        },
        renderIf: R.either(R.prop('isNewModel'), R.prop('isNewManufacturer')),
      },
      {
        order: 10,
        layout: { row: 6, width: halfWidth },
        field: {
          withFormData: true,
          name: 'isNewManufacturer',
          withChangeFieldValue: true,
          Component: AddNewManufacturer,
        },
      },
      {
        order: 11,
        layout: { row: 6, width: halfWidth },
        field: {
          withChangeFieldValue: true,
          withFormData: true,
          name: 'isNewModel',
          Component: AddNewModel,
        },
      },
      {
        order: 12,
        label: 'Serial Number',
        layout: { row: 7, width: halfWidth },
        field: {
          name: 'serial',
          Component: InputWithLimit,
        },
      },
      {
        order: 13,
        label: 'Warranty Expiration',
        layout: { row: 7, width: halfWidth },
        field: {
          name: 'warrantyEnd',
          Component: AssetDatePicker,
        },
      },
      {
        order: 14,
        label: 'Asset Type',
        layout: { row: 8, width: halfWidth },
        field: {
          name: 'typeId',
          withChangeFieldValue: true,
          Component: AssetTypeSelect,
        },
        validators: [[R.identity, 'Asset Type is required']],
        required: true,
      },
      {
        order: 15,
        label: 'Location',
        layout: { row: 8, width: halfWidth },
        field: {
          name: 'location',
          Component: InputWithLimit,
        },
        validators: [[R.identity, 'Location is required']],
        required: true,
      },
      {
        order: 16,
        label: 'Purchase Price',
        layout: { row: 9, width: halfWidth },
        field: {
          name: 'purchasePrice',
          Component: MoneyInput,
        },
      },
      {
        order: 17,
        label: 'Commissioning Date',
        layout: { row: 9, width: halfWidth },
        field: {
          name: 'commissioningDate',
          Component: AssetDatePicker,
        },
      },
      {
        order: 18,
        label: 'Asset Replacement Cost',
        layout: { row: 10, width: halfWidth },
        field: {
          name: 'replacementCost',
          withFormData: true,
          withChangeFieldValue: true,
          Component: AssetReplacementCostInput,
        },
      },
      {
        order: 19,
        label: 'Default Supplier',
        layout: { row: 10, width: halfWidth },
        field: {
          name: 'defaultSupplierId',
          Component: SupplierSelect,
        },
      },
      {
        order: 19,
        label: 'Remarks',
        layout: { row: 11, width: '100%' },
        field: {
          name: 'remarks',
          withFormData: true,
          withChangeFieldValue: true,
          Component: AssetRemarksInput,
        },
      },
      {
        order: 20,
        label: 'Asset Photo',
        layout: { row: 12, width: '100%' },
        field: {
          name: 'photo',
          Component: AssetPhotoPicker,
        },
        validators: commonFileValidators,
        validateFunction: validateFilesFunc,
      },
    ],
  },
];
