import React from 'react';
import * as R from 'ramda';
import { shape, instanceOf, string, func } from 'prop-types';
import { WorkOrderPriority, ProjectPrioritiesUIMap } from 'poly-constants';
import { getEndDateByProjectWithClient } from 'poly-utils';
import { Select } from 'poly-book-admin';

import { formTypeConstants } from '../utils/constants.js';

// prepareSelectOptions :: Object -> [SelectOption]
const prepareSelectOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.identity,
      label: R.prop(R.__, ProjectPrioritiesUIMap),
    }),
  ),
  R.values,
);

export function WorkPrioritySelectField({
  changeFieldValue,
  onChange,
  formData: { formType, startDate, endDate },
  ...restProps
}) {
  const onChangeHandler = (priority) => {
    onChange(priority);
    if (changeFieldValue && formType === formTypeConstants.CREATE) {
      const newEndDate =
        priority === WorkOrderPriority.CUSTOM
          ? endDate
          : getEndDateByProjectWithClient({ priority, startDate });
      changeFieldValue('endDate', newEndDate);
    }
  };

  const selectProps = {
    ...restProps,
    name: 'WorkPrioritySelectField',
    options: prepareSelectOptions(WorkOrderPriority),
    onChange: onChangeHandler,
  };

  return <Select {...selectProps} />;
}

WorkPrioritySelectField.displayNme = 'WorkPrioritySelectField';

WorkPrioritySelectField.propTypes = {
  formData: shape({
    formType: string,
    endDate: instanceOf(Date),
    startDate: instanceOf(Date),
  }),
  onChange: func,
  changeFieldValue: func,
};
