import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { PopoverLink } from 'poly-book-admin';
import {
  useNotificationState,
  ThreeDotsPopover,
  useModalContext,
} from 'poly-admin-ui';

import { useOpenEditUserGroupSidebar } from './form/useOpenEditUserGroupSidebar.js';
import { useOpenAddUserGroupSidebar } from './form/useOpenAddUserGroupSidebar.js';

const deleteUserGroupMutation = gql`
  mutation deleteUserGroupMutation($id: ID!) {
    deleteUserGroup(id: $id) {
      _id
    }
  }
`;

const PopoverLinkS = styled(PopoverLink)`
  text-align: left;
`;

function UserGroupActionsButtonComponent({ _id, name, refetch, setActive }) {
  const { openConfirmModal } = useModalContext();
  const handleAddUserGroup = useOpenAddUserGroupSidebar();
  const handleEditUserGroup = useOpenEditUserGroupSidebar();
  const { showSuccessNotification } = useNotificationState();
  const [deleteUserGroup] = useMutation(deleteUserGroupMutation);

  const onDuplicate = () => {
    handleAddUserGroup({ userGroupId: _id, refetch });
    setActive(false);
  };

  const onEdit = () => {
    handleEditUserGroup({ userGroupId: _id, refetch });
    setActive(false);
  };

  const handleDeleteUserGroup = async () => {
    await deleteUserGroup({ variables: { id: _id } });

    if (R.is(Function, refetch)) {
      await refetch();
    }

    showSuccessNotification('User Group was successfully deleted');
  };

  const onDelete = () =>
    openConfirmModal({
      btnCaption: 'Yes',
      cancelBtnCaption: 'No',
      id: 'confirmDeleteUserGroupModal',
      content: `Are you sure you want to delete ${name} user group?`,
      onConfirm: (closeConfirmModal) => () => {
        handleDeleteUserGroup();
        closeConfirmModal();
      },
    });

  return (
    <>
      <PopoverLinkS onClick={onEdit}>Edit</PopoverLinkS>
      <PopoverLinkS onClick={onDuplicate}>Duplicate</PopoverLinkS>
      <PopoverLinkS onClick={onDelete}>Delete</PopoverLinkS>
    </>
  );
}

UserGroupActionsButtonComponent.propTypes = {
  _id: string.isRequired,
  name: string.isRequired,
  refetch: func.isRequired,
  setActive: func.isRequired,
};

export function UserGroupActionsButton(props) {
  return (
    <ThreeDotsPopover
      contentProps={props}
      content={UserGroupActionsButtonComponent}
    />
  );
}
