import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import { getThemeColor } from 'poly-book-admin';
import { AssetStatuses } from 'poly-constants';
import { SidebarRow } from 'poly-admin-ui';
import { isNilOrEmpty } from 'poly-utils';

import { RejectAssetButton } from '../../pages/AssetReview/RejectAssetButton.js';
import { ApproveAssetButton } from '../../pages/AssetReview/ApproveAssetButton.js';
import { SectionWrapperWithoutBorder } from '../components/commonSidebarComponents.js';

const SectionWrapperS = styled(SectionWrapperWithoutBorder)`
  border-top: 1px solid ${getThemeColor(['secondaryLighter2'])};

  > div > button:first-child {
    margin-right: 15px;
  }
`;

// checkPendingAsset :: Asset -> Boolean
const checkPendingAsset = R.propEq('status', AssetStatuses.PENDING);

// checkNewEquipment :: Asset -> Boolean
const checkNewEquipment = R.compose(
  R.complement(R.isEmpty),
  R.reject(isNilOrEmpty),
  R.props(['newModelName', 'newManufacturerName']),
);

export function AssetSidebarButtons({ asset }) {
  const { _id, displayName } = asset;

  const showButtons = checkPendingAsset(asset);

  if (!showButtons) return null;

  const hasError = checkNewEquipment(asset);

  return (
    <SectionWrapperS>
      <SidebarRow>
        <ApproveAssetButton hasError={hasError} ids={[_id]} />
        <RejectAssetButton id={_id} assetName={displayName} />
      </SidebarRow>
    </SectionWrapperS>
  );
}

AssetSidebarButtons.propTypes = {
  asset: shape({
    _id: string.isRequired,
    displayName: string.isRequired,
  }).isRequired,
};
