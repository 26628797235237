import React from 'react';
import { string } from 'prop-types';
import { useOutSidebarContext } from 'poly-client-utils';
import { SidebarWidth } from 'poly-admin-ui';
import { LinkButton } from 'poly-book-admin';

import { addTimeSidebarFormId } from '../constants.js';
import { ProjectTimeForm } from '../forms/ProjectTimeForm.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';

export function AddTimeToProjectButton({ _id }) {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();
  const handleCancel = () => closeOutSidebar(addTimeSidebarFormId);

  const onClick = () =>
    openOutSidebar({
      width: SidebarWidth,
      id: addTimeSidebarFormId,
      Layout: SecondSidebarLayout,
      content: <ProjectTimeForm handleCancel={handleCancel} projectId={_id} />,
    });

  return <LinkButton onClick={onClick}>Add Time</LinkButton>;
}

AddTimeToProjectButton.propTypes = { _id: string.isRequired };
