import * as R from 'ramda';

import { formatDateMongo, getTimezoneByDate } from 'poly-client-utils';

export const buildQueryRangeForDateField = R.curry((field, range) =>
  R.compose(
    R.objOf('range'),
    R.objOf(field),
    R.applySpec({
      lte: R.compose(formatDateMongo, R.prop('endDate')),
      gte: R.compose(formatDateMongo, R.prop('startDate')),
      time_zone: R.compose(getTimezoneByDate, R.prop('startDate')),
    }),
  )(range),
);
