import * as R from 'ramda';
import { differenceInDays } from 'date-fns';
import { assocBy, ensureIsDate } from 'poly-utils';

// getMonthsFromCommissioningDate :: Asset -> Number
export const getMonthsFromCommissioningDate = R.ifElse(
  R.prop('commissioningDate'),
  R.compose(
    R.when(R.lt(R.__, 0), R.always(0)),
    Math.round,
    R.divide(R.__, 30),
    (date) => differenceInDays(new Date(), date),
    ensureIsDate,
    R.prop('commissioningDate'),
  ),
  R.always(null),
);

// getAssetLifeExpectancy :: Asset -> Number
export const getAssetLifeExpectancy = R.either(
  R.path(['modelDoc', 'lifeExpectancy']),
  R.path(['type', 'lifeExpectancy']),
);

// getAssetLifeExpectancyInfo :: Asset -> Number
export const getAssetLifeExpectancyInfo = R.ifElse(
  R.both(R.prop('commissioningDate'), getAssetLifeExpectancy),
  R.compose(
    R.applySpec({
      restOfMonths: R.compose(
        R.when(R.lt(R.__, 0), R.always(0)),
        R.prop('restOfMonths'),
      ),
      lifePercent: R.compose(
        Math.round,
        R.converge(R.divide, [
          R.prop('restOfMonths'),
          R.compose(R.divide(R.__, 100), R.prop('modelLifeMonth')),
        ]),
      ),
    }),
    assocBy(
      'restOfMonths',
      R.converge(R.subtract, [
        R.prop('modelLifeMonth'),
        R.prop('monthFromCommissioning'),
      ]),
    ),
    R.applySpec({
      modelLifeMonth: R.compose(
        Math.floor,
        R.multiply(12),
        getAssetLifeExpectancy,
      ),
      monthFromCommissioning: getMonthsFromCommissioningDate,
    }),
  ),
  R.always(null),
);
