import React from 'react';
import { number, bool, object } from 'prop-types';
import {
  SidebarTabTableWrapper,
  useSearchTabLogic,
  SearchInputComp,
  SidebarRow,
  entities,
} from 'poly-admin-ui';

import {
  SectionLabel,
  ToolbarButton,
  DoubleTabsWrapper,
} from '../../components/commonSidebarComponents.js';
import { CommonProjectsSubTabs } from '../../components/commonTabs/CommonProjectsSubTabs.js';
import { generateProjectInvoiceDescription } from '../../ProjectSidebar/forms/form/components/utils.js';
import { useAddProjectSidebar } from '../../ProjectSidebar/forms/add/useAddProjectSidebar.js';
import { isAllowProjectCreationOnAdminByClient } from '../../ClientSidebar/helpers.js';
import {
  isInactivePropertyOrClient,
  getProjectTypeByProperty,
} from '../propertySidebarUtils.js';

function AddPropertyProjectButton({ property }) {
  const openAddProjectSidebar = useAddProjectSidebar(
    false,
    entities.PROPERTY,
    property._id,
  );

  if (!isAllowProjectCreationOnAdminByClient(property?.client)) {
    return null;
  }

  const onAddProject = () =>
    openAddProjectSidebar({
      propertyId: property._id,
      projectType: getProjectTypeByProperty(property),
      invoiceDescription: generateProjectInvoiceDescription({
        property,
      }),
    });

  return (
    <ToolbarButton
      disabled={isInactivePropertyOrClient(property)}
      onClick={onAddProject}
    >
      Add Project
    </ToolbarButton>
  );
}

// eslint-disable-next-line react/forbid-prop-types
AddPropertyProjectButton.propTypes = { property: object.isRequired };

export function PropertySidebarProjectsTab({ isCard, property, doubleWidth }) {
  const { searchTerm, ...inputProps } = useSearchTabLogic('projects');

  const subTabsProps = {
    searchTerm,
    entity: {
      _id: property._id,
      name: entities.PROPERTY,
    },
    toolBar: <AddPropertyProjectButton property={property} />,
  };

  return (
    <DoubleTabsWrapper width={doubleWidth} isCard={isCard}>
      <SidebarRow justify align>
        <SectionLabel>Property Projects</SectionLabel>
        <SearchInputComp {...inputProps} />
      </SidebarRow>
      <SidebarTabTableWrapper offset={48}>
        <CommonProjectsSubTabs {...subTabsProps} />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapper>
  );
}

PropertySidebarProjectsTab.propTypes = {
  isCard: bool,
  // eslint-disable-next-line react/forbid-prop-types
  property: object,
  doubleWidth: number.isRequired,
};
