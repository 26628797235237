import * as R from 'ramda';
import {
  ELASTIC_RESULT_WINDOW_MAX_SIZE,
  NOTHING_UI_STRING,
} from 'poly-constants';

import { roundTo } from './numbers.js';
import { applyPath, applyOr } from './general.js';

// ensurePositiveIndex :: Number -> Number
const ensurePositiveIndex = R.when(R.gt(0), R.always(0));

// groupCharacters :: [Number] -> String -> [String]
const groupCharacters = (groupingMap) => (targetValue) => {
  const reversedMap = R.reverse(groupingMap);
  let mappedCount = 0;
  return reversedMap
    .reduce((acc, groupCount) => {
      const end = ensurePositiveIndex(targetValue.length - mappedCount);
      const start = ensurePositiveIndex(end - groupCount);
      const newAcc = acc.concat([targetValue.slice(start, end)]);
      mappedCount += groupCount;
      return newAcc;
    }, [])
    .reverse();
};

// formatRawNumber :: String -> String
export const formatRawNumber = R.compose(
  ([code, group1, group2]) => `(${code}) ${group1}-${group2}`,
  groupCharacters([3, 3, 4]),
  R.replace(/\D*/g, ''),
);

// formatPhoneNumberWithExtension :: (String, String?) -> PhoneNum -> String
// PhoneNum = { number: String, ext: String, type: String }
const formatPhoneNumberWithExtension =
  (divider, extDivider = `${divider} `) =>
  ({ number, ext }) =>
    `${formatRawNumber(number)} ${extDivider}${ext}`;

// formatPhoneNumber :: (String, String?) -> PhoneNum -> String
// PhoneNum = { number: String, ext: String, type: String }
export const formatPhoneNumber = (divider, extDivider) =>
  R.cond([
    [
      R.both(R.prop('number'), R.prop('ext')),
      formatPhoneNumberWithExtension(divider, extDivider),
    ],
    [R.prop('number'), R.pipe(R.prop('number'), formatRawNumber)],
    [R.T, R.always(NOTHING_UI_STRING)],
  ]);

const numberToUSLocaleString = (n) =>
  n.toLocaleString('en-US', { minimumFractionDigits: 2 });

// formatAmount :: Number -> String
export const formatAmount = R.compose(numberToUSLocaleString, roundTo(2));

// formatCurrency :: Number -> String
export const formatCurrency = R.compose(
  R.replace(/(\d)(?=(\d{3})+\.)/g, '$1,'),
  (_) => _.toFixed(2),
  R.when(Number.isNaN, R.always(0)),
  Number,
);

// formatTotal :: Number -> String
export const formatTotal = R.pipe(
  formatCurrency,
  (total) => `$${total}`,
  R.replace(/(\$)(-)(.+)/, '$2$1$3'),
);

// formatCount :: Number -> String
export const formatCount = R.ifElse(
  R.lte(ELASTIC_RESULT_WINDOW_MAX_SIZE),
  R.always(`${ELASTIC_RESULT_WINDOW_MAX_SIZE.toLocaleString('en-US')}+`),
  (count) => count.toLocaleString('en-US'),
);

// formatCurrency :: Any -> Number -> Any
export const formatCurrencyOr = applyOr(formatCurrency);

// formatCurrencyForViewOr :: Any -> Number -> Any
export const formatCurrencyForViewOr = applyOr(formatTotal);

// formatCurrencyForViewOr :: Any -> Number -> Any
export const formatCurrencyForViewPath = applyPath(
  formatCurrencyForViewOr('$0.00'),
);

// formatCurrencyPath :: [String] -> Object -> String
export const formatCurrencyPath = applyPath(formatCurrencyOr('0'));

/**
 * formatZip :: String -> String
 */
export const formatZip = R.replace(/(\d{5})(\d{4})/, '$1-$2');

/**
 * formatSocialSecurity :: String -> String
 */
export const formatSocialSecurity = R.compose(
  R.replace(/(\w{3})(\w{2})(\w{4})/, '$1-$2-$3'),
  R.defaultTo(''),
);

/**
 * formatTaxId :: String -> String
 */
export const formatTaxId = R.compose(
  R.replace(/(\w{2})(\w{7})/, '$1-$2'),
  R.defaultTo(''),
);

export const roundToCents = (x) => Math.round(x * 100) / 100;

// getAddressPartWithSeparator :: Document -> [AfterValueSeparator, GetValueFunction] -> String
const getAddressPartWithSeparator = (document) =>
  R.compose(
    R.join(''),
    R.map(([separator, getValue]) =>
      getValue(document) ? `${getValue(document)}${separator || ''}` : '',
    ),
  );

// formatStringByConfig :: [RowConfig] -> Document -> String
// Document = Object
// AfterValueSeparator = String
// GetValueFunction = Document -> String
// RowConfigs = [[AfterValueSeparator, GetValueFunction]]
export const formatStringByConfig = R.curry((configs, document) =>
  R.compose(
    R.when(R.isEmpty, R.always(NOTHING_UI_STRING)),
    R.join('\n'),
    R.reject(R.isEmpty),
    R.map(getAddressPartWithSeparator(document)),
  )(configs),
);
