import { useQuery, gql } from '@apollo/client';
import { NonVirtualInvoiceStatuses } from 'poly-constants';

export const clientInvoiceProjectDetailsFragment = gql`
  fragment clientInvoiceProjectDetailsFields on Project {
    _id
    projectId
    description
    invoiceDescription
    type
    feeAmount
    customFeeDescription
    exemptSalesTax
    isAfterHoursCall
    costCenter
    client {
      _id
      name
      woCharge
      enableCostCenter
      configs {
        clientInvoicing {
          email {
            enabled
          }
        }
      }
      tmMarkupRules {
        upTo
        percent
      }
      address {
        formatted_address
        address_parts {
          administrative_area_level_1
        }
      }
      financial {
        terms
        division {
          _id
          name
          code
        }
      }
      taxRate
      staffRates {
        afterHoursCall
      }
    }
    property {
      _id
      name
      woCharge
      address {
        formatted_address
        address_parts {
          administrative_area_level_1
        }
      }
      taxRate
      tmMarkupRules {
        upTo
        percent
      }
    }
    timeEntries {
      _id
      type
      date
      rate
      rateDescription
      notes
      totalTimeInMinutes
      isInvoiced
      owner {
        _id
        fullName
      }
    }
    clientReferenceNumber
    division {
      _id
      name
      code
    }
    listing {
      referralFee
    }
    jobCost
    adminPurchaseOrder {
      _id
      poNumber
    }
  }
`;

export const jobCostFragment = gql`
  fragment jobCostFragment on Invoice {
    _id
    type
    invoiceDate
    invoiceNumber
    total
    supplier {
      _id
      company {
        name
      }
    }
    glAccount {
      _id
      name
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const projectDetailsQuery = gql`
  query projectDetailsQuery(
    $projectId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    project(id: $projectId) {
      ...clientInvoiceProjectDetailsFields
      searchInvoices(input: $searchInput) {
        hits {
          ...jobCostFragment
        }
      }
    }
  }

  ${clientInvoiceProjectDetailsFragment}
  ${jobCostFragment}
`;

export const jobCostSearchInput = {
  query: { bool: { must: { terms: { status: NonVirtualInvoiceStatuses } } } },
  from: 0,
  size: 10000,
};

export const useProjectDetailsQuery = (projectId) => {
  const { data, loading } = useQuery(projectDetailsQuery, {
    variables: {
      projectId,
      searchInput: jobCostSearchInput,
    },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
  };
};
