import { defaultTheme } from 'poly-book-admin';
import { ClientStatuses } from 'poly-constants';

const {
  colors: {
    statuses: { active, inactive, pending },
  },
} = defaultTheme;

export const clientStatusesColors = {
  [ClientStatuses.ACTIVE]: active,
  [ClientStatuses.INACTIVE]: inactive,
  [ClientStatuses.PROSPECT]: pending,
};
