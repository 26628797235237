import * as R from 'ramda';
import { isNilOrEmpty } from 'poly-utils';

import { validateCanadaZipCode } from '../formValidators.js';

// combineTwoAddressPartsInString :: String -> String -> AddressObject -> String
// eslint-disable-next-line import/no-unused-modules
export const combineTwoAddressPartsInString = R.curry((part1, part2, data) =>
  R.compose(
    R.join(' '),
    R.reject(isNilOrEmpty),
    R.props([part1, part2]),
    R.defaultTo({}),
    R.prop('address_parts'),
  )(data),
);

// isEmptyAddressFields :: AddressObject -> Boolean
export const isEmptyAddressFields = R.compose(
  isNilOrEmpty,
  R.reject(isNilOrEmpty),
  R.unnest,
  R.juxt([
    R.compose(
      R.reject(isNilOrEmpty),
      R.pick(['locality', 'street_number', 'postal_code', 'route']),
      R.propOr({}, 'address_parts'),
    ),
    R.prop('addressTwo'),
  ]),
);
/**
 * generateFormattedAddress :: AddressObject -> String
 */
export const generateFormattedAddress = R.compose(
  R.join(', '),
  R.reject(isNilOrEmpty),
  R.juxt([
    combineTwoAddressPartsInString('street_number', 'route'),
    R.path(['address_parts', 'locality']),
    combineTwoAddressPartsInString(
      'administrative_area_level_1',
      'postal_code',
    ),
    R.pathOr('US', ['address_parts', 'country']),
  ]),
);

// isCanadianZip :: AddressObject -> Boolean
export const isCanadianZip = R.pipe(
  R.pathOr('', ['address_parts', 'postal_code']),
  validateCanadaZipCode,
);
