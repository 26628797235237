import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Table, Text, getThemeColor } from 'poly-book-admin';
import { ExecutedProcedureStatuses } from 'poly-constants';
import { FileLink, useMapConfigToTableProps } from 'poly-admin-ui';
import {
  arrayOf,
  func,
  instanceOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import {
  assocBy,
  isNilOrEmpty,
  formatDateTime,
  mergeObjectWithArrayOfObjects,
  mapIndexed,
} from 'poly-utils';

import { BlockWithLabel } from '../commonSidebarComponents.js';
import { SidebarCloseIcon } from '../../../components/SidebarLayouts.js';

const ProcedureDetailsSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 14px;
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
  gap: 10px;
  border-bottom: 1px solid #f5f6f7;
`;

const TextS = styled(Text)`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
`;

const TableS = styled(Table)`
  min-height: initial;

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
`;

const SkippedStep = styled.div`
  padding: 2px 8px;
  width: 64px;
  height: 20px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid ${getThemeColor(['secondaryLight'])};
  color: ${getThemeColor(['secondaryDark'])};
  background-color: rgba(255, 0, 0, 0.1);
`;

const attachmentsTableConfig = [
  ['Type', R.prop('fileType')],
  ['File name', FileLink],
  ['Posting Details', R.compose(formatDateTime, R.prop('completedAt'))],
];

// prepareTableRows :: DateTime -> [Attachment] -> [Row]
const prepareTableRows = (completedAt) =>
  R.map(R.compose(R.mergeLeft({ completedAt }), assocBy('_id', R.prop('url'))));

function ProcedureStepAttachmentsTable({ attachments, completedAt }) {
  const tableProps = useMapConfigToTableProps(
    prepareTableRows(completedAt),
    attachmentsTableConfig,
    attachments,
  );

  return <TableS {...tableProps} />;
}

const attachmentPropTypes = shape({
  fileName: string,
  fileType: string,
  url: string,
});

ProcedureStepAttachmentsTable.propTypes = {
  attachments: arrayOf(attachmentPropTypes),
  completedAt: oneOfType([string, instanceOf(Date)]),
};

function ProcedureDetailsStep({
  _id,
  name,
  status,
  comment,
  description,
  attachments,
  completedAt,
}) {
  const isStepSkipped = status === ExecutedProcedureStatuses.SKIPPED;
  const isStepCompleted = status === ExecutedProcedureStatuses.COMPLETED;

  return (
    <StepWrapper>
      {isStepSkipped && <SkippedStep>Skipped</SkippedStep>}
      <Text size="18px">{name}</Text>
      <BlockWithLabel
        label="Description"
        id={`related-procedure-${_id}-${description}`}
        width="100%"
        margin="10xp 0"
        Component={Text}
        size="12px"
      >
        {description}
      </BlockWithLabel>
      {isStepCompleted && (
        <>
          <BlockWithLabel
            label="Comment"
            id={`related-procedure-${comment}`}
            width="100%"
            margin="0"
            Component={Text}
            size="12px"
          >
            {comment}
          </BlockWithLabel>
          {!isNilOrEmpty(attachments) && (
            <>
              <TextS margin="10px 0 0 0" size="14px">
                Files
              </TextS>
              <ProcedureStepAttachmentsTable
                attachments={attachments}
                completedAt={completedAt}
              />
            </>
          )}
        </>
      )}
    </StepWrapper>
  );
}

ProcedureDetailsStep.propTypes = {
  _id: string,
  name: string,
  status: string,
  comment: string,
  description: string,
  attachments: arrayOf(attachmentPropTypes),
  completedAt: oneOfType([string, instanceOf(Date)]),
};

// prepareSteps :: ExecutedProcedure -> [PreparedStep]
// PreparedStep = {
//    ...ExecutedProcedureStep
//    completedAt: DateTime
// }
const prepareSteps = R.converge(mergeObjectWithArrayOfObjects, [
  R.pick(['completedAt']),
  R.compose(
    mapIndexed((step, index) => ({
      ...step,
      stepKey: `${step.name}-${index}`,
    })),
    R.propOr([], 'steps'),
  ),
]);

export function ProcedureDetailsSidebar({ executedProcedure, onSidebarClose }) {
  const steps = prepareSteps(executedProcedure);

  return (
    <ProcedureDetailsSidebarWrapper>
      <TitleRow>
        <TextS size="18px">Procedure Details</TextS>
        <SidebarCloseIcon onClick={onSidebarClose} />
      </TitleRow>
      <TextS padding="0 24px" size="14px" color={['midDark']}>
        You can view the list of steps included in the procedure
      </TextS>

      {steps.map((step) => (
        <ProcedureDetailsStep key={step.stepKey} {...step} />
      ))}
    </ProcedureDetailsSidebarWrapper>
  );
}

ProcedureDetailsSidebar.propTypes = {
  onSidebarClose: func,
  executedProcedure: shape({
    _id: string,
    completedAt: oneOfType([string, instanceOf(Date)]),
    steps: arrayOf(
      shape({
        name: string,
        status: string,
        comment: string,
        description: string,
        attachments: arrayOf(
          shape({
            url: string,
            fileName: string,
            fileType: string,
          }),
        ),
      }),
    ),
  }),
};
