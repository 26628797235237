import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import {
  MoneyInput,
  MoneyInputAsCents,
  NegativeMoneyInput,
  NegativeMoneyInputAsCents,
} from 'poly-admin-ui';
import { func, number, oneOfType, string, bool } from 'prop-types';
import { convertDollarsToCents } from 'poly-utils';

// extractAmount :: Object -> Number
const extractAmount = R.compose(
  (value) => +value,
  R.replace(/[,$]/g, ''),
  R.path(['target', 'value']),
);
export function AmountInput({
  currentBalance,
  value,
  onChange,
  asCents,
  ...props
}) {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (internalValue !== value) {
      setInternalValue(value);
    }
  }, [value]);

  const onBlur = (e) => {
    const amount = extractAmount(e);
    if (asCents) {
      onChange(convertDollarsToCents(amount));
    } else {
      onChange(amount);
    }
  };

  const inputProps = {
    ...props,
    onBlur,
    value: internalValue,
    onChange: setInternalValue,
  };

  if (asCents) {
    const InvoiceAmountInputAsCents =
      currentBalance > 0 ? MoneyInputAsCents : NegativeMoneyInputAsCents;

    return <InvoiceAmountInputAsCents {...inputProps} />;
  }

  const InvoiceAmountInput =
    currentBalance > 0 ? MoneyInput : NegativeMoneyInput;

  return <InvoiceAmountInput {...inputProps} />;
}

AmountInput.propTypes = {
  currentBalance: number.isRequired,
  value: oneOfType([number, string]),
  onChange: func.isRequired,
  asCents: bool,
};
