import * as R from 'ramda';
import { ramdaUseWith } from 'poly-utils';

/**
 * addUniqMention :: MentionOption -> [MentionOption] -> [MentionOption]
 *   MentionType = 'user' | 'group'
 *   MentionOption = {id: ID, type: MentionType, name: String}
 */
export const addUniqMention = R.compose(R.uniqBy(R.prop('id')), R.append);

// mentionExists :: MentionOption -> String -> Boolean
const mentionExists = ramdaUseWith(R.includes, [
  R.compose(R.concat('@'), R.prop('name')),
  R.identity,
]);

// omitRemovedMentions :: String -> [MentionOption] -> [MentionOption]
const omitRemovedMentions = ramdaUseWith(R.filter, [
  R.flip(mentionExists),
  R.identity,
]);

// groupMentionsByTypeAsIds :: [MentionOption] -> GroupedMentions
//   GroupedMentions = {usersIds: [ID], groupsIds: [ID]}
const groupMentionsByTypeAsIds = R.compose(
  R.map(R.map(R.prop('id'))),
  R.groupBy(R.compose(R.concat(R.__, 'sIds'), R.prop('type'))),
);

/**
 * prepareMentionsObject :: String -> [MentionOption] -> GroupedMentions
 */
export const prepareMentionsObject = R.compose(
  groupMentionsByTypeAsIds,
  omitRemovedMentions,
);
