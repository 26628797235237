import * as R from 'ramda';
import { taskCollections } from 'poly-constants';

import { taskTypes } from '../../../constants/tasks.js';

// getTaskTypeByCollection :: String -> String
export const getTaskTypeByCollection = R.cond([
  [R.equals(taskCollections.CLIENTS), R.always(taskTypes.CLIENT)],
  [R.equals(taskCollections.PROPERTIES), R.always(taskTypes.PROPERTY)],
  [R.equals(taskCollections.SUPPLIERS), R.always(taskTypes.SUPPLIER)],
  [R.equals(taskCollections.PROJECTS), R.always(taskTypes.PROJECT)],
]);
