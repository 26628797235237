import React from 'react';
import { formatSelectOptionsByConstants } from 'poly-client-utils';
import { supplierFinTermsUI } from 'poly-constants';
import { Select } from 'poly-book-admin';
import { bool } from 'prop-types';

export function FinancialTermsSelect(props) {
  const options = formatSelectOptionsByConstants(supplierFinTermsUI);

  return <Select {...props} options={options} name="FinancialTermsSelect" />;
}

FinancialTermsSelect.displayName = 'FinancialTermsSelect';
FinancialTermsSelect.defaultProps = {
  isClearable: true,
};
FinancialTermsSelect.propTypes = {
  isClearable: bool,
};
