import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo, useCallback, useEffect } from 'react';
import { string, shape, bool, arrayOf } from 'prop-types';
import { pathOrNothingUI } from 'poly-client-utils';
import { forceTitleCase } from 'poly-utils';
import {
  RecurringProjectStatuses,
  RecurringProjectTypes,
  WorkOrderStatus,
} from 'poly-constants';
import {
  SidebarWrapperForTableForm,
  useSidebarTableFormContext,
  useMapConfigToTableProps,
  CommonSidebarFormWrapper,
  SidebarTabTableWrapper,
  CommonSidebarTabTable,
  SidebarTabWrapper,
  SidebarTabHeader,
  FormPortalAnchor,
  entities,
} from 'poly-admin-ui';
import {
  ToolBarBtnDivider,
  defaultTheme,
  IconButton,
  LinkButton,
  Loader,
  Dot,
} from 'poly-book-admin';

import {
  ProcedureNameComponent,
  attachProcedureFormAnchorId,
} from './ProcedureNameComponent.js';
import { SectionLabel } from '../commonSidebarComponents.js';
import { assetStatusesColors } from '../../AssetSidebar/constants.js';
import { ProcedureBulkAttachButton } from './ProcedureBulkAttachButton.js';
import { AttachAssetForm } from '../../AssetSidebar/forms/AttachAssetForm.js';
import { AssetLinkWithWarning } from '../../AssetSidebar/useOpenAssetSidebar.js';
import { AssetsExportXLSBtn } from '../../../components/AssetsExportXLSButton/AssetsExportXLSBtn.js';
import { checkIfShowAssetsByProject } from '../../ProjectSidebar/projectSidebarUtils.js';
import { useSearchAssetsTabQuery } from './useSearchAssetsTabQuery.js';
import { useUnlinkAssetMutation } from './useUnlinkAssetMutation.js';

const attachAssetFormId = 'attach_asset_form_id';

const disabledProcedureStatuses = [
  WorkOrderStatus.BLOCKED,
  WorkOrderStatus.COMPLETED,
  RecurringProjectStatuses.CLOSED,
];

const AssetsToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    min-width: auto;
  }
`;

export function AssetStatusDot({ status }) {
  return <Dot color={assetStatusesColors[status]} />;
}

AssetStatusDot.propTypes = { status: string.isRequired };

// checkIfProcedureExecuted :: ID -> { assetProcedures: [ProjectAssetProcedure] } -> Boolean
const checkIfProcedureExecuted = (assetId) =>
  R.compose(
    R.pathSatisfies(R.complement(R.isNil), ['executedProcedure', '_id']),
    R.find(R.pathEq(['asset', '_id'], assetId)),
    R.propOr([], 'assetProcedures'),
  );

function UnlinkAssetButton({ entity, assetIds, _id, ...props }) {
  const onUnlinkContact = useUnlinkAssetMutation({ entity, assetIds, _id });

  const isProcedureExecuted = checkIfProcedureExecuted(_id)(props);

  if (isProcedureExecuted) {
    return null;
  }

  return (
    <IconButton
      initialColor={R.path(['colors', 'primaryLight'], defaultTheme)}
      onClick={onUnlinkContact}
      name="unlink"
      size={11}
    />
  );
}

UnlinkAssetButton.propTypes = {
  _id: string.isRequired,
  assetIds: arrayOf(string).isRequired,
  entity: shape({ name: string.isRequired }).isRequired,
};

const assetsTableConfig = [
  ['', AssetStatusDot],
  ['Asset Name', AssetLinkWithWarning],
  ['Model', pathOrNothingUI(['modelDoc', 'name'])],
  ['Procedure Name', ProcedureNameComponent],
];

function AttachAssetButton({ entity, assetIds, formAnchorId }) {
  const { activeForm, formSetter } = useSidebarTableFormContext();

  const onCancel = useCallback(() => {
    formSetter(null);
  }, []);

  const formProps = useMemo(
    () => ({ entity, assetIds, onCancel, formId: attachAssetFormId }),
    [assetIds, entity],
  );

  const wrapperProps = useMemo(
    () => ({
      onCancel,
      skipMargin: true,
      title: 'Attach Assets',
      submitCaption: 'Attach',
      formId: attachAssetFormId,
    }),
    [],
  );

  const onAttachAsset = () =>
    formSetter({
      id: formAnchorId,
      Content: (
        <CommonSidebarFormWrapper {...wrapperProps}>
          <AttachAssetForm {...formProps} />
        </CommonSidebarFormWrapper>
      ),
    });

  const subPropertiesIds = entity?.subPropertiesIds || [];

  useEffect(() => {
    if (activeForm?.id === formAnchorId) {
      onAttachAsset();
    }
  }, [activeForm?.id, subPropertiesIds]);

  return <LinkButton onClick={onAttachAsset}>Attach Asset</LinkButton>;
}

AttachAssetButton.propTypes = {
  entity: shape({
    propertyId: string,
    _id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  formAnchorId: string.isRequired,
  assetIds: arrayOf(string).isRequired,
};

const AssetsTable = styled(CommonSidebarTabTable)`
  td:nth-child(1),
  th:nth-child(1),
  td:nth-child(5),
  th:nth-child(5) {
    width: 25px;
    text-align: right;

    > svg {
      display: inline-block;
    }

    > div {
      margin-top: 4px;
    }
  }

  td:nth-child(2),
  th:nth-child(2) {
    overflow-wrap: anywhere;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 180px;
  }
`;

function AssetsTabTable({ loading, ...props }) {
  return (
    <SidebarTabTableWrapper offset={42}>
      {loading ? <Loader /> : <AssetsTable {...props} />}
    </SidebarTabTableWrapper>
  );
}

AssetsTabTable.propTypes = { loading: bool };

// getAssetIdsForEntity :: Entity -> SearchAssetsQueryResult -> [ID]
const getAssetIdsForEntity = (entity) =>
  R.compose(
    R.defaultTo([]),
    R.ifElse(
      R.compose(
        R.includes(R.__, R.values(RecurringProjectTypes)),
        R.prop('type'),
      ),
      (project) => R.path(['payload', project?.childType, 'assetIds'], project),
      R.prop('assetIds'),
    ),
    R.propOr({}, entity.name),
  );

// prepareAssetsDataByEntity :: (Entity, Object) -> SearchAssetsQueryResult -> [Asset]
const prepareAssetsDataByEntity = (entity, additionalProps) =>
  R.compose(
    R.map(R.mergeDeepLeft({ ...additionalProps, entity })),
    R.pathOr([], [entity.name, 'searchAssets', 'hits']),
  );

// showExportAssetsBtn :: { entity: Entity, data: QueryData } -> Boolean
const showExportAssetsBtn = R.cond([
  [
    R.pathEq(['entity', 'name'], entities.RECURRING_PROJECT),
    R.pathEq(
      ['data', 'recurringProject', 'type'],
      RecurringProjectTypes.preventiveMaintenanceProject,
    ),
  ],
  [
    R.pathEq(['entity', 'name'], entities.PROJECT),
    R.compose(checkIfShowAssetsByProject, R.pathOr({}, ['data', 'project'])),
  ],
  [R.T, R.F],
]);

// getAssetProceduresByEntity :: Entity -> SearchAssetsQueryResult -> [ProjectAssetProcedure]
const getAssetProceduresByEntity = (entity) =>
  R.pathOr([], [entity.name, 'assetProcedures']);

// checkProcedureDisabledByEntity :: Entity -> SearchAssetsQueryResult -> Boolean
const checkProcedureDisabledByEntity = (entity) =>
  R.pathSatisfies(R.includes(R.__, disabledProcedureStatuses), [
    entity.name,
    'status',
  ]);

// isEmptyAssetsData :: (String, SearchAssetsQueryResult) -> Boolean
const isEmptyAssetsData = (name, data) =>
  R.compose(R.isEmpty, R.prop(name))(data);

function CommonAssetsTab({ entity }) {
  const { name, preview, projectId } = entity;

  const title = preview || name;
  const formAnchorId = `attach_asset_${entity._id}`;
  const tabTitle = `${forceTitleCase(title)} Assets`;

  const { data, loading, tableProps, queryExportHandler } =
    useSearchAssetsTabQuery(entity);

  const showXLSBtn = showExportAssetsBtn({ entity, data });

  const assetIds = getAssetIdsForEntity(entity)(data);

  const assetProcedures = getAssetProceduresByEntity(entity)(data);

  const isProcedureDisabled = checkProcedureDisabledByEntity(entity)(data);

  const dataTableProps = useMapConfigToTableProps(
    prepareAssetsDataByEntity(entity, {
      assetIds,
      assetProcedures,
      isProcedureDisabled,
    }),
    [...assetsTableConfig, ...[[' ', UnlinkAssetButton]]],
    data,
  );

  return (
    <SidebarTabWrapper>
      <SidebarTabHeader>
        <SectionLabel>{tabTitle}</SectionLabel>
        <AssetsToolbarWrapper>
          {showXLSBtn && (
            <>
              <AssetsExportXLSBtn
                title={title}
                disabled={isEmptyAssetsData(name, data)}
                fileName={projectId}
                queryExportHandler={queryExportHandler}
                entityName={name}
              />
              <ToolBarBtnDivider />
            </>
          )}
          <ProcedureBulkAttachButton
            entity={entity}
            disabled={isProcedureDisabled}
            assetProcedures={assetProcedures}
          />
          <ToolBarBtnDivider />
          <AttachAssetButton
            entity={entity}
            assetIds={assetIds}
            formAnchorId={formAnchorId}
          />
        </AssetsToolbarWrapper>
      </SidebarTabHeader>
      <FormPortalAnchor id={formAnchorId} />
      <FormPortalAnchor id={`${attachProcedureFormAnchorId}-${projectId}`} />
      <AssetsTabTable {...{ loading, ...tableProps, ...dataTableProps }} />
    </SidebarTabWrapper>
  );
}

CommonAssetsTab.propTypes = {
  entity: shape({
    preview: string,
    propertyId: string,
    _id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
};

export function SidebarAssetsTab(props) {
  return (
    <SidebarWrapperForTableForm>
      <CommonAssetsTab {...props} />
    </SidebarWrapperForTableForm>
  );
}
