import React from 'react';
import { shape, string } from 'prop-types';
import { entities } from 'poly-admin-ui';
import { prepareInvoiceDescription } from 'poly-utils';

import { ToolbarButton } from '../../components/commonSidebarComponents.js';
import { ProjectOccurrence } from '../../../modules/core/constants/projects.js';
import { SidebarMastersTab } from '../../components/commonTabs/SidebarMastersTab.js';
import { useAddProjectSidebar } from '../../ProjectSidebar/forms/add/useAddProjectSidebar.js';
import { isAllowProjectCreationOnAdminByClient } from '../../ClientSidebar/helpers.js';
import { isPropertyOrClientInactive } from './AssetSidebarProjectsTab.js';

function AddAssetMasterProjectButton({ asset }) {
  const openAddProjectSidebar = useAddProjectSidebar(
    false,
    entities.ASSET,
    asset._id,
  );

  if (!isAllowProjectCreationOnAdminByClient(asset?.client)) {
    return null;
  }

  const onAddProject = () =>
    openAddProjectSidebar({
      assetId: asset._id,
      propertyId: asset.property._id,
      type: ProjectOccurrence.PREVENTIVE_MAINTENANCE_OCCURRENCE,
      invoiceDescription: prepareInvoiceDescription({ asset }),
    });

  return (
    <ToolbarButton
      disabled={isPropertyOrClientInactive(asset)}
      onClick={onAddProject}
    >
      Add Project
    </ToolbarButton>
  );
}

AddAssetMasterProjectButton.propTypes = {
  asset: shape({
    _id: string.isRequired,
    property: shape({ _id: string.isRequired }).isRequired,
  }).isRequired,
};

export function AssetSidebarPMsTab({ asset }) {
  const sidebarProps = {
    entity: {
      _id: asset._id,
      name: entities.ASSET,
      queryField: 'payload.assetIds',
    },
    toolBar: <AddAssetMasterProjectButton {...{ asset }} />,
  };

  return <SidebarMastersTab {...sidebarProps} />;
}

AssetSidebarPMsTab.propTypes = {
  asset: shape({ _id: string.isRequired }).isRequired,
};
