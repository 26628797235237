import React from 'react';
import * as R from 'ramda';
import { FlexContainer } from 'poly-admin-ui';
import { Loader } from 'poly-book-admin';
import { shape } from 'prop-types';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import {
  myProjectsTotalItems,
  getMyTasksTotalItems,
  myMasterProjectsTotalItems,
  myRecurringProjects,
} from '../totalItems.js';
import { TotalInfoSections } from './TotalInfoSections.js';
import { useDashboardQueryWorker } from '../useDashboardQueryWorker.js';
import { SectionTitleText, SubHeaderText } from '../styles.js';

// getUserCredentials :: User -> UserCredentials
// UserCredentials = { userId: ID, userMessage: String }
const getUserCredentials = R.applySpec({
  userId: R.prop('_id'),
  userMessage: R.compose(R.concat('Welcome '), R.propOr('', 'firstName')),
});

// getDashboardCounts :: DashboardQueryResult -> DashboardCounts
// DashboardCounts = { [CountName]: Int }
const getDashboardCounts = R.compose(
  R.pick([
    'ownTaskCounts',
    'ownMastersCounts',
    'ownProjectsCounts',
    'ownSupplierInvoicesCounts',
    'ownRecurringProjectsCounts',
  ]),
  R.defaultTo({}),
  R.prop('dashboard'),
  R.defaultTo({}),
);

export function UserDashboard({ dashboardData }) {
  const { user } = useCurrentUserByStoreOrQuery();

  const { data: dashboard, loading: dashboardLoading } =
    useDashboardQueryWorker(!!dashboardData);

  const loading = !user || dashboardLoading;

  const { userId, userMessage } = getUserCredentials(user);

  const {
    ownTaskCounts,
    ownMastersCounts,
    ownProjectsCounts,
    ownSupplierInvoicesCounts,
    ownRecurringProjectsCounts,
  } = getDashboardCounts(dashboardData || dashboard);

  const userDashboardSections = [
    {
      label: 'Projects',
      counts: ownProjectsCounts,
      sub: 'You own this stuff.',
      items: myProjectsTotalItems,
    },
    {
      label: 'Tasks',
      items: getMyTasksTotalItems(userId),
      sub: 'Tee them up. Knock them down!',
      counts: { ...ownTaskCounts, ...ownSupplierInvoicesCounts },
    },
    {
      label: 'Masters',
      counts: ownMastersCounts,
      sub: 'Better you be the master.',
      items: myMasterProjectsTotalItems,
    },
    {
      label: 'Reoccurring Projects',
      counts: ownRecurringProjectsCounts,
      sub: 'Repetitions is the mother of Mastery',
      items: myRecurringProjects,
    },
  ];

  return loading ? (
    <Loader />
  ) : (
    <>
      <SectionTitleText margin="10px">{userMessage}</SectionTitleText>
      <SubHeaderText>
        Thanks for being part of the best FM Team in the world!
      </SubHeaderText>
      <FlexContainer>
        <TotalInfoSections
          margin="0"
          width="210px"
          config={userDashboardSections}
        />
      </FlexContainer>
    </>
  );
}

UserDashboard.propTypes = {
  dashboardData: shape({}),
};
