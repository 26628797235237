import * as R from 'ramda';
import { assocByPath, removePropDeep } from 'poly-utils';

import {
  checkFiles,
  convertPhone,
  convertServiceTypes,
  prepareSupplierEmailsInput,
} from '../../forms/supplierForm/form/prepareFormDataForMutation.js';
import { prepareAddressDeepBeforeMutate } from '../../../utils/address.js';
import { omitSupplierProtectedFieldsIfNoPermission } from '../../forms/supplierForm/edit/formDataToMutation.js';

// prepareRemittanceEmailsIfExists :: { bank: SupplierBankInput } -> { bank: SupplierBankInput }
export const prepareRemittanceEmailsIfExists = R.unless(
  R.compose(R.isEmpty, R.pathOr([], ['bank', 'remittanceEmails'])),
  R.over(
    R.lensPath(['bank', 'remittanceEmails']),
    R.map(R.unless(R.is(String), R.prop('value'))),
  ),
);

// prepareBranchSupplierToMutation :: FormData -> FieldsAccess -> CreateBranchSupplierInput
export const prepareBranchSupplierToMutation = R.curry(
  (formData, protectedFieldsAccess) =>
    R.compose(
      removePropDeep('__typename'),
      R.when(R.compose(R.isEmpty, R.prop('bank')), R.dissoc('bank')),
      R.when(R.compose(R.isEmpty, R.prop('tax')), R.dissoc('tax')),
      omitSupplierProtectedFieldsIfNoPermission(protectedFieldsAccess),
      prepareRemittanceEmailsIfExists,
      R.when(R.prop('payByMaster'), R.omit(['finance', 'bank'])),
      R.when(R.prop('applyMasterRates'), R.omit(['rates'])),
      R.omit(['_id', 'cardNumber', 'type', 'supplierSource']),
      R.when(R.propEq('service_24_7', true), R.dissoc('officeHours')),
      prepareAddressDeepBeforeMutate('company'),
      prepareAddressDeepBeforeMutate('remit'),
      prepareAddressDeepBeforeMutate('tax'),
      R.dissocPath(['company', 'phone']),
      R.dissocPath(['company', 'mobile']),
      checkFiles,
      assocByPath(['company', 'servicesIds'], convertServiceTypes),
      assocByPath(['company', 'emails'], prepareSupplierEmailsInput),
      assocByPath(['company', 'phones'], convertPhone),
    )(formData),
);
