import { gql } from '@apollo/client';
import { CLIENTS_BY_SEARCH_SUB, MAX_ITEMS } from 'poly-admin-ui';
import { useReactiveQuery } from 'poly-client-utils';

import { clientAccountFields } from '../../modules/core/hooks/clients/fragments.js';

const CLIENT_ACCOUNT = gql`
  query CLIENT_ACCOUNT($clientId: ID!) {
    client(id: $clientId) {
      ...clientAccountFields
    }
  }
  ${clientAccountFields}
`;

const CLIENT_ACCOUNT_SUB = gql`
  subscription CLIENT_ACCOUNT_SUB($input: SingleDocSubInput!) {
    clientChanged(input: $input) {
      id
      document {
        ...clientAccountFields
      }
    }
  }
  ${clientAccountFields}
`;

export const useClientSidebarData = (clientId) => {
  const queryOptions = { variables: { clientId }, fetchPolicy: 'network-only' };

  const clientSubscriptionOptions = {
    variables: { input: { id: clientId } },
  };

  const clientChildClientsSubscriptionOptions = {
    variables: { searchInput: { query: null, from: 0, size: MAX_ITEMS } },
  };

  const { data, loading } = useReactiveQuery(
    CLIENT_ACCOUNT,
    [CLIENT_ACCOUNT_SUB, CLIENTS_BY_SEARCH_SUB],
    {
      queryOptions,
      subscriptionOptions: [
        clientSubscriptionOptions,
        clientChildClientsSubscriptionOptions,
      ],
    },
  );

  return { loading, ...data };
};
