import * as R from 'ramda';
import React from 'react';
import { LinkButton } from 'poly-book-admin';
import { openPrintWindowWithData } from 'poly-client-utils';
import { CommonPrintLayout, useNotificationState } from 'poly-admin-ui';
import { PRINT_PDF_CAPTION, EXPORT_XLS_CAPTION } from 'poly-constants';
import { string } from 'prop-types';

import { GeneralLedgerPrintTitle } from '../GeneralLedgerPage/GeneralLedgerPrintBtn.js';
import {
  accountTransactionsFiltersPropTypes,
  accountTransactionsResultPropType,
} from './accountTransactionsPropTypes.js';
import { AccountTransactionsTable } from './AccountTransactionsTable.js';

const MAX_ALLOWED_TRANSACTIONS_PER_PRINT = 2000;

export function AccountTransactionsPrintBtn({ accountName, filters, result }) {
  const { showWarningNotification } = useNotificationState();

  const transactionsCount = R.pathOr(0, ['hits', 'length'], result);

  const onClick = async () => {
    if (transactionsCount > MAX_ALLOWED_TRANSACTIONS_PER_PRINT) {
      showWarningNotification(
        `Amount of PDF transactions is limited to ${MAX_ALLOWED_TRANSACTIONS_PER_PRINT}. If you need more, click ${EXPORT_XLS_CAPTION} link to generate an Excel file`,
      );
      return;
    }

    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: AccountTransactionsTable,
      metaData: {
        title: (
          <GeneralLedgerPrintTitle
            title={accountName}
            accountingMethod={filters.accountingMethod}
            date={filters.date}
          />
        ),
      },
      fileName: 'account_transactions',
      isPrint: true,
      result,
    });
  };

  return (
    <LinkButton disabled={transactionsCount === 0} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

AccountTransactionsPrintBtn.propTypes = {
  result: accountTransactionsResultPropType,
  filters: accountTransactionsFiltersPropTypes,
  accountName: string,
};
