import React from 'react';
import { string, number, bool } from 'prop-types';
import { entities } from 'poly-admin-ui';

import { SidebarContactsTab } from '../../components/commonTabs/SidebarContactsTab.js';

export function PropertySidebarContactsTab({
  isCard,
  propertyId,
  doubleWidth,
}) {
  return (
    <SidebarContactsTab
      isCard={isCard}
      tabWidth={doubleWidth}
      entity={{ _id: propertyId, name: entities.PROPERTY }}
    />
  );
}

PropertySidebarContactsTab.propTypes = {
  isCard: bool,
  propertyId: string.isRequired,
  doubleWidth: number.isRequired,
};
