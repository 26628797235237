import React from 'react';
import { performExcelExport } from 'poly-client-utils/src/excel-export/export.js';
import { LinkButton } from 'poly-book-admin';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import { getSubCategoryReportXLSConfig } from 'poly-utils/src/getSubCategoryReportXLSConfig.js';
import { useSubCategoryReportLazyQuery } from './useSubCategoryReport.js';
import { subCategoryReportFilterPropTypes } from './SubCategoryReportPdfExportButton.js';

export function SubCategoryReportXlsExportButton({ filter, ...props }) {
  const queryHandler = useSubCategoryReportLazyQuery();
  const generateXls = async () => {
    const report = await queryHandler({ filter });

    const excelConfig = getSubCategoryReportXLSConfig(report);
    performExcelExport(excelConfig);
  };

  return (
    <LinkButton {...props} onClick={generateXls}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

SubCategoryReportXlsExportButton.propTypes = {
  filter: subCategoryReportFilterPropTypes,
};
