import React from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import { FinancialEntityTypes, JournalPaymentMode } from 'poly-constants';
import { getThemeColor, getThemeFont, Loader } from 'poly-book-admin';
import { AddressSection } from 'poly-admin-ui';
import { ifNotEmpty } from 'poly-client-utils';
import { optionalCommonAddressValidators } from 'poly-client-utils/src/formValidators.js';

const supplierAddressQuery = gql`
  query supplierAddressQuery($entityId: ID!) {
    supplier(id: $entityId) {
      _id
      company {
        address {
          formatted_address
        }
      }
    }
  }
`;

const clientAddressQuery = gql`
  query clientAddressQuery($entityId: ID!) {
    client(id: $entityId) {
      _id
      address {
        formatted_address
      }
    }
  }
`;

const employeeAddressQuery = gql`
  query employeeAddressQuery($entityId: ID!) {
    user(id: $entityId) {
      _id
      profile {
        address {
          formatted_address
        }
      }
    }
  }
`;

const addressQueryByTypeMap = {
  [FinancialEntityTypes.SUPPLIERS]: supplierAddressQuery,
  [FinancialEntityTypes.EMPLOYEES]: employeeAddressQuery,
  [FinancialEntityTypes.CLIENTS]: clientAddressQuery,
};

const addressQueryDataPathByTypeMap = {
  [FinancialEntityTypes.SUPPLIERS]: [
    'supplier',
    'company',
    'address',
    'formatted_address',
  ],
  [FinancialEntityTypes.EMPLOYEES]: [
    'user',
    'profile',
    'address',
    'formatted_address',
  ],
  [FinancialEntityTypes.CLIENTS]: ['client', 'address', 'formatted_address'],
};

const AddressViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AddressViewLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 18px;
  font-weight: ${getThemeFont(['regular'])};
  font-size: 14px;
  color: ${getThemeColor(['primaryLighter2'])};
`;

const AddressViewText = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: ${getThemeColor(['primary'])};
`;

function AddressViewSection({ entityId, type }) {
  const addressQuery = addressQueryByTypeMap[type];
  const { data, loading } = useQuery(addressQuery, { variables: { entityId } });

  const address = R.path(addressQueryDataPathByTypeMap[type], data);

  return (
    <AddressViewWrapper>
      <AddressViewLabel>Address</AddressViewLabel>
      {loading ? <Loader /> : <AddressViewText>{address}</AddressViewText>}
    </AddressViewWrapper>
  );
}

AddressViewSection.propTypes = {
  entityId: string,
  type: string.isRequired,
};

const ifCheckPaymentModeAndCustomType = (validator) => (value, allValues) =>
  R.either(
    R.either(
      R.propSatisfies(
        R.complement(R.equals(JournalPaymentMode.CHECK)),
        'paymentMode',
      ),
      R.propSatisfies(
        R.complement(R.equals(FinancialEntityTypes.OTHERS)),
        'type',
      ),
    ),
    () => validator(value),
  )(allValues);

export const payByFormAddressValidators = [
  [
    ifCheckPaymentModeAndCustomType(
      R.complement(R.anyPass([R.isNil, R.isEmpty])),
    ),
    'Select address or set it manually',
  ],
  [
    ifCheckPaymentModeAndCustomType(
      ifNotEmpty(
        R.pathSatisfies(R.complement(R.anyPass([R.isNil, R.isEmpty])), [
          'address_parts',
          'postal_code',
        ]),
      ),
    ),
    'Zip is required. You can set it manually',
  ],
  ...optionalCommonAddressValidators,
];

export function PayByFormAddressComponent({ formData, ...props }) {
  const isCustom = formData.type === FinancialEntityTypes.OTHERS;
  const isMandatory = formData.paymentMode === JournalPaymentMode.CHECK;
  const sectionProps = isMandatory
    ? {}
    : { required: false, zipNotRequired: true };

  return isCustom ? (
    <AddressSection {...props} {...sectionProps} isAddressFieldsLimited />
  ) : (
    <AddressViewSection entityId={formData.documentId} type={formData.type} />
  );
}

PayByFormAddressComponent.propTypes = {
  formData: shape({
    type: string,
    documentId: string,
    paymentMode: string,
  }).isRequired,
};
