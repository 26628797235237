import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { shape, string } from 'prop-types';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
  closeConfirmModal,
} from 'poly-admin-ui';

import { BasicConfirmButton } from './BasicConfirmButtonComp.js';
import { DELETE_TIMESHEET } from '../../../core/hooks/timesheets/mutations.js';

const PROJECT_TIME_DELETE_PROCESS = 'PROJECT_TIME_DELETE_PROCESS';

export function DeleteProjectTimeBtn({ confirmBtnPayload }) {
  const dispatch = useDispatch();

  const [deleteTime] = useMutation(DELETE_TIMESHEET, {
    refetchQueries: ['MY_TIMESHEETS'],
    awaitRefetchQueries: true,
  });

  const { showSuccessNotification } = useNotificationState();

  const handleSubmit = async () => {
    await deleteTime({
      variables: { id: confirmBtnPayload._id },
    });

    showSuccessNotification('Time record was successfully deleted');

    dispatch(closeConfirmModal());
  };
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    PROJECT_TIME_DELETE_PROCESS,
    handleSubmit,
  );

  return (
    <BasicConfirmButton
      onSubmit={onSubmit}
      id={PROJECT_TIME_DELETE_PROCESS}
      caption="Yes, Delete"
    />
  );
}

DeleteProjectTimeBtn.displayName = 'DeleteProjectTimeBtn';
DeleteProjectTimeBtn.propTypes = {
  confirmBtnPayload: shape({ _id: string }),
};
