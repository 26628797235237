import React from 'react';
import * as R from 'ramda';
import { prepareInvoiceDescription } from 'poly-utils';
import {
  SidebarTabTableWrapper,
  useSearchTabLogic,
  SearchInputComp,
  SidebarWidth,
  SidebarRow,
  entities,
} from 'poly-admin-ui';

import {
  SectionLabel,
  ToolbarButton,
  DoubleTabsWrapper,
} from '../../components/commonSidebarComponents.js';
import { CommonProjectsSubTabs } from '../../components/commonTabs/CommonProjectsSubTabs.js';
import { useAddProjectSidebar } from '../../ProjectSidebar/forms/add/useAddProjectSidebar.js';
import { isPropertyInactive } from '../../PropertySidebar/propertySidebarUtils.js';
import { assetPropTypes } from './assetPropTypes.js';
import {
  isAllowProjectCreationOnAdminByClient,
  isClientInactive,
} from '../../ClientSidebar/helpers.js';

// isPropertyOrClientInactive :: Asset -> Boolean
export const isPropertyOrClientInactive = R.either(
  R.compose(isPropertyInactive, R.prop('property')),
  R.compose(isClientInactive, R.prop('client')),
);

function AddAssetProjectButton({ asset }) {
  const openAddProjectSidebar = useAddProjectSidebar(
    false,
    entities.ASSET,
    asset._id,
  );

  if (!isAllowProjectCreationOnAdminByClient(asset?.client)) {
    return null;
  }
  const onAddProject = () =>
    openAddProjectSidebar({
      propertyId: asset.property._id,
      assetId: asset._id,
      invoiceDescription: prepareInvoiceDescription({ asset }),
    });

  return (
    <ToolbarButton
      disabled={isPropertyOrClientInactive(asset)}
      onClick={onAddProject}
    >
      Add Project
    </ToolbarButton>
  );
}

AddAssetProjectButton.propTypes = {
  asset: assetPropTypes,
};

export function AssetSidebarProjectsTab({ asset }) {
  const { searchTerm, ...inputProps } = useSearchTabLogic('projects');

  const subTabsProps = {
    searchTerm,
    entity: { _id: asset._id, name: entities.ASSET, queryField: 'assetIds' },
    toolBar: <AddAssetProjectButton asset={asset} />,
  };

  return (
    <DoubleTabsWrapper width={SidebarWidth * 2}>
      <SidebarRow justify align>
        <SectionLabel>Asset Projects</SectionLabel>
        <SearchInputComp {...inputProps} />
      </SidebarRow>
      <SidebarTabTableWrapper offset={48}>
        <CommonProjectsSubTabs {...subTabsProps} />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapper>
  );
}

AssetSidebarProjectsTab.propTypes = {
  asset: assetPropTypes,
};
