import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import { startOfDay, endOfDay } from 'date-fns';
import { alwaysNewDate, ensureIsDate } from 'poly-utils';
import { DatePicker, Loader, ToolBarBtnDivider } from 'poly-book-admin';

import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';
import { defaultFilterValues } from '../ProfitLossReportPage/ProfitLossReportPage.js';
import { AccountingMethodSelect } from '../../components/AccountingMethodSelect.js';
import { IncomeStatementsPDFButton } from './IncomeStatementsPDFButton.js';
import { IncomeStatementsXLSButton } from './IncomeStatementsXLSButton.js';
import { IncomeStatementReportTable } from './IncomeStatementReportTable.js';
import {
  PageWithSearchHeader,
  SearchPageHeaderContainer,
  SearchPageConditionalWrapper,
} from '../../components/PageWithSearchHeader.js';
import {
  FiscalYearSelect,
  getFiscalYearSubscribersByDate,
} from '../../components/FiscalYearSelect.js';
import { useCogsAccount } from '../../hooks/useCogsAccount.js';

const isReportNotEmpty = R.complement(
  R.propSatisfies(R.isEmpty, 'incomeStatements'),
);

function ExportButtonsWrapper(props) {
  return (
    isReportNotEmpty(props) && (
      <>
        <IncomeStatementsPDFButton {...props} />
        <ToolBarBtnDivider />
        <IncomeStatementsXLSButton {...props} />
      </>
    )
  );
}

const getPageFiltersConfig = (
  filters,
  currentDate,
  incomeStatements,
  cogsAccountCodes,
) => [
  {
    key: 'typeRow',
    columns: [
      {
        name: 'accountingMethod',
        title: 'Type',
        column: 1,
        Component: AccountingMethodSelect,
        defaultValue: defaultFilterValues.accountingMethod,
        filterProps: {
          width: '100%',
        },
      },
      {
        name: 'printButtons',
        column: 2,
        columnLayout: { filterWidth: '80px' },
        relatedFilters: ['accountingMethod', 'startDate', 'endDate'],
        Component: (props) => (
          <ExportButtonsWrapper
            {...props}
            filters={filters || defaultFilterValues}
            incomeStatements={incomeStatements}
            cogsAccountCodes={cogsAccountCodes}
          />
        ),
      },
    ],
  },
  {
    key: 'dateRow',
    rowLayout: { justifyContent: 'flex-start' },
    columns: [
      {
        name: 'startDate',
        title: 'Start Date',
        columnLayout: { filterWidth: '150px' },
        column: 1,
        defaultValue: defaultFilterValues.startDate,
        Component: DatePicker,
        filterProps: { width: '100%' },
      },
      {
        name: 'endDate',
        title: 'End Date',
        columnLayout: { titleWidth: '100px', filterWidth: '150px' },
        column: 2,
        defaultValue: defaultFilterValues.endDate,
        Component: DatePicker,
        filterProps: { width: '100%' },
      },
      {
        name: 'year',
        column: 3,
        Component: FiscalYearSelect,
        columnLayout: { filterWidth: '150px' },
        defaultValue: defaultFilterValues.year,
        filterProps: { width: '100%', required: true },
        subscribers: getFiscalYearSubscribersByDate(currentDate),
      },
    ],
  },
];

const incomeStatementReportQuery = gql`
  query incomeStatementReportQuery(
    $input: AccountingIncomeStatementReportInput
  ) {
    accountingIncomeStatementReport(input: $input) {
      _id
      code
      name
      balance
      notClientsBalance
      compass_gl_code
      compass_gl_code_description
      parentAccount {
        _id
        code
      }
      accountType {
        _id
        category
        normal_balance
      }
      clientsWithBalance {
        code
        balance
        client {
          _id
          name
          sapGLCode
        }
      }
    }
  }
`;

// prepareQueryInput :: Object -> AccountingIncomeStatementReportInput
const prepareQueryInput = R.compose(
  R.evolve({
    startDate: R.compose(startOfDay, ensureIsDate),
    endDate: R.compose(endOfDay, ensureIsDate),
  }),
  R.omit(['year']),
);

// getSortByField :: [String] -> [Object] -> [Object]
const getSortByField = (fieldPath) =>
  R.compose(R.toLower, R.pathOr('', fieldPath));

// getIncomeStatements :: { accountingIncomeStatementReport: [AccountingIncomeStatement] }
// -> [AccountingIncomeStatement]
const getIncomeStatements = R.compose(
  R.sortBy(getSortByField(['compass_gl_code'])),
  R.sortBy(getSortByField(['code'])),
  R.map(
    R.over(
      R.lensProp('clientsWithBalance'),
      R.sortBy(getSortByField(['client', 'name'])),
    ),
  ),
  R.propOr([], 'accountingIncomeStatementReport'),
);

export function IncomeStatementReportPage() {
  const [filters, setFilters] = useState(null);

  const { loading: cogsLoading, cogsAccountCodes } = useCogsAccount();

  const { data, loading } = useQuery(incomeStatementReportQuery, {
    variables: {
      input: prepareQueryInput(filters),
    },
    fetchPolicy: 'network-only',
    skip: !filters,
  });

  const incomeStatements = getIncomeStatements(data);

  return (
    <PageWithSearchHeader headerHeight="155px">
      <SearchPageHeaderContainer title="Income Statement">
        <SearchHeaderCreator
          config={getPageFiltersConfig(
            filters,
            alwaysNewDate(),
            incomeStatements,
            cogsAccountCodes,
          )}
          onSearch={setFilters}
          loading={loading || cogsLoading}
          searchBtnTitle="Filter"
        />
      </SearchPageHeaderContainer>
      {loading || cogsLoading ? (
        <Loader />
      ) : (
        <SearchPageConditionalWrapper condition={incomeStatements.length > 0}>
          <IncomeStatementReportTable
            incomeStatements={incomeStatements}
            filters={filters}
            cogsAccountCodes={cogsAccountCodes}
          />
        </SearchPageConditionalWrapper>
      )}
    </PageWithSearchHeader>
  );
}
