import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import {
  FlexContainer,
  MAX_ITEMS,
  SearchInputComp,
  useSearchTabLogic,
} from 'poly-admin-ui';
import { arrayOf, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { HIERARCHY_NODE_TYPES, HIERARCHY_TREE_TYPE } from '../constants.js';
import { SectionTitleContainer } from './PropertyHierarchyTreeBase.js.js';
import { TreeBase } from '../../../components/Tree/Tree.js';
import { PropertyTreeNode } from './PropertyTreeNode.js';

const PropertyHierarchySearchTree = styled(TreeBase)`
  .rst__lineBlock {
    display: none;
  }
  .rst__expandButton {
    display: none;
  }
  .rst__nodeContent {
    left: 0 !important;
  }
  .rst__rowTitle {
    font-weight: normal;
    font-size: 12px;
  }
  .rst__row,
  .rst__node,
  .rst__nodeContent {
    width: 100% !important;
  }
  flex-grow: 1;
  padding: 0 15px;
`;

const PropertiesContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 430px;
`;

const PropertySearchInput = styled(SearchInputComp)`
  flex-grow: 1;
`;

const clientPropertiesQuery = gql`
  query clientPropertiesQuery(
    $clientId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    client(id: $clientId) {
      _id
      searchProperties(input: $searchInput) {
        hits {
          _id
          name
        }
      }
    }
  }
`;

const useClientPropertiesQuery = (clientId, search) => {
  const { data, loading } = useQuery(clientPropertiesQuery, {
    variables: {
      clientId,
      searchInput: {
        from: 0,
        size: MAX_ITEMS,
        searchTerm: search,
      },
    },
    skip: !clientId,
  });
  const properties = R.pathOr([], ['client', 'searchProperties', 'hits'], data);
  return {
    loading,
    properties,
  };
};

// formatPropertyTreeNodes :: [Property] -> [TreeNode]
// TreeNode = { id: String, type: String, title: String }
const formatPropertyTreeNodes = R.map(
  R.applySpec({
    id: R.prop('_id'),
    title: R.prop('name'),
    type: R.always(HIERARCHY_NODE_TYPES.property),
  }),
);

// filterProperties :: ([Property], [ID]) -> [Property]
const filterProperties = (properties, blackListedPropertiesIds) =>
  properties.filter(
    (property) => !blackListedPropertiesIds.includes(property._id),
  );

export function PropertyHierarchySearch({ clientId, treePropertiesIds }) {
  const { searchTerm, ...inputProps } = useSearchTabLogic();
  const { properties } = useClientPropertiesQuery(clientId, searchTerm);
  const allowedProperties = filterProperties(properties, treePropertiesIds);
  const treeData = formatPropertyTreeNodes(allowedProperties);

  return (
    <PropertiesContainer>
      <SectionTitleContainer>
        <PropertySearchInput {...inputProps} placeholder="Property Search" />
      </SectionTitleContainer>
      <PropertyHierarchySearchTree
        nodeContentRenderer={PropertyTreeNode}
        dndType={HIERARCHY_TREE_TYPE}
        rowHeight={45}
        searchQuery={searchTerm}
        treeData={treeData}
        onChange={R.identity}
        canDrop={R.F}
      />
    </PropertiesContainer>
  );
}

PropertyHierarchySearch.propTypes = {
  clientId: string.isRequired,
  treePropertiesIds: arrayOf(string),
};
