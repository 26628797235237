import React from 'react';
import { string, bool, func } from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import { Icon, getThemeColor } from 'poly-book-admin';
import { gql, useMutation } from '@apollo/client';
import { removeFileExtension } from 'poly-utils';

import { useNotificationState } from '../../../hooks/useNotificationState.js';

const BanIconButton = styled(Icon).attrs(
  R.applySpec({
    color: getThemeColor(['secondaryDark']),
    name: R.always('delete'),
    size: R.always(14),
  }),
)`
  align-self: flex-start;
  visibility: hidden;

  position: absolute;
  top: 0;
  right: 0;
  background: white;
  padding: 2px;
  border-radius: 5px;
  height: 20px;
  width: 20px;
`;

const UpdateImageLinkWrapper = styled.div`
  position: relative;

  :hover {
    ${BanIconButton} {
      visibility: visible;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 10px;

  :hover {
    opacity: 0.7;
  }

  > img {
    height: 100px;
    width: 100px;
  }
`;

const banEmailAttachmentQuery = gql`
  mutation BAN_EMAIL_ATTACHMENT($input: BanEmailAttachmentInput!) {
    banEmailAttachment(input: $input) {
      banEmailAttachmentId
    }
  }
`;

const BAN_ATTACHMENT_CONFIRMATION_MESSAGE =
  'This action will delete the attachment and ban it from being received via email in future. Are you sure?';

export function UpdateImageLink({
  _id,
  url,
  fileName,
  isEmailAttachment,
  onClick,
}) {
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();

  const [banEmailAttachment] = useMutation(banEmailAttachmentQuery);

  const onBanClick = async (e) => {
    e.stopPropagation();
    // eslint-disable-next-line no-alert
    if (window.confirm(BAN_ATTACHMENT_CONFIRMATION_MESSAGE)) {
      try {
        await banEmailAttachment({ variables: { input: { fileId: _id } } });
        showSuccessNotification(`${fileName} was successfully banned`);
      } catch (err) {
        showErrorNotification(`Failed to ban image: ${err.toString()}`);
      }
    }
  };

  return (
    <UpdateImageLinkWrapper>
      <ImageWrapper onClick={onClick}>
        <img src={url} alt={removeFileExtension(fileName)} />
      </ImageWrapper>
      {isEmailAttachment && <BanIconButton onClick={onBanClick} />}
    </UpdateImageLinkWrapper>
  );
}

UpdateImageLink.propTypes = {
  _id: string.isRequired,
  url: string.isRequired,
  fileName: string.isRequired,
  isEmailAttachment: bool,
  onClick: func.isRequired,
};
