import * as R from 'ramda';
import React from 'react';
import { Loader } from 'poly-book-admin';
import { useMapConfigToTableProps } from 'poly-admin-ui';

import { chartOfAccountsTableConfig } from './chartOfAccountsTableConfig.js';
import { TableS } from './components.js';
import { defaultChartOfAccountsPropTypes } from './chartOfAccountsPropTypes.js';

export function ChartOfAccountsTable(props) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.propOr([], 'highlightedAccounts'),
    chartOfAccountsTableConfig(props),
    props,
  );

  return props.loading ? (
    <Loader />
  ) : (
    <TableS
      headers={headers}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      isRowDisabled={R.prop('isDisabled')}
      isLoading={props.loading}
    />
  );
}

ChartOfAccountsTable.propTypes = defaultChartOfAccountsPropTypes;
