import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import React, { useEffect, useCallback } from 'react';
import { Input, getThemeColor, getThemeFont, Icon } from 'poly-book-admin';
import { debounceWithDefaultThreshold } from 'poly-client-utils';
import { number } from 'prop-types';

import { setSearchText } from '../redux/search-text.js';

const disabledStyles = css`
  cursor: not-allowed;
  background-color: ${getThemeColor(['secondaryLighter2'])};
`;

const SearchInputWrapper = styled.div`
  width: ${R.propOr('144px', 'wrapperWidth')};
  display: flex;
  border-radius: 3px;
  padding-right: 10px;
  align-items: center;
  background-color: ${getThemeColor(['white'])};
  border: 1px solid ${getThemeColor(['midLight'])};

  ${({ disabled }) => !!disabled && disabledStyles};
`;

const InputS = styled(Input)`
  flex: 1 0 auto;

  & > input {
    height: 32px;
    width: ${R.propOr('122px', 'inputWidth')};
    padding: 7px 10px;
    letter-spacing: normal;
    background-color: transparent;
    border: none;
    font-size: 12px;
    line-height: 18px;
    font-weight: ${getThemeFont(['regular'])};
    color: ${getThemeColor(['primary'])};
  }
`;

export function SearchInputComp({ iconSize, ...props }) {
  return (
    <SearchInputWrapper {...R.omit(['placeholder', 'name'], props)}>
      <InputS {...props} />
      <Icon name="search" color="#bcbcbc" size={iconSize || 10} />
    </SearchInputWrapper>
  );
}

SearchInputComp.propTypes = {
  iconSize: number,
};

export function TableSearchInput(props) {
  const dispatch = useDispatch();

  const callSetSearchInput = useCallback(
    debounceWithDefaultThreshold((text) => dispatch(setSearchText(text))),
    [],
  );

  const inputProps = {
    name: 'table-search-input',
    placeholder: 'Search table',
    ...props,
    onChange: R.pipe(R.path(['target', 'value']), callSetSearchInput),
  };

  const clearSearchText = () => dispatch(setSearchText(''));

  useEffect(() => {
    clearSearchText();
    return () => clearSearchText();
  }, []);

  return <SearchInputComp {...inputProps} />;
}
TableSearchInput.displayName = 'TableSearchInput';
