import React, { useState } from 'react';
import * as R from 'ramda';
import { useRouterParams } from 'poly-client-routing';
import { ProjectType } from 'poly-constants';
import { PageSubTabs } from 'poly-admin-ui';
import { startOfToday } from 'date-fns';
import {
  EntityProjectsTabComp,
  EntityRecurringProjectsTabComp,
} from '../entityProjectsTab/EntityProjectsTabComp.js';
import {
  projectsSubTabsConfig,
  recurringQueries,
  recurringSubTabsConfig,
} from '../common.js';
import {
  SupplierPageProjectsTabTitle,
  SupplierPageRecurringProjectsTabTitle,
} from '../projectsTab/ProjectsTabTitle.js';
import { SupplierRecurringProjectsSubTab } from '../projectsTab/ProjectsTab.js';
import { getProjectsQueries } from '../../core/utils/projects.js';
import { SupplierProjectsSubTab } from '../projectsTab/SupplierProjectsTab.js';

const createProjectsTabs = (config, queries) =>
  R.map(
    ([status, title]) => [
      <SupplierPageProjectsTabTitle
        key={title}
        query={queries[status]}
        status={status}
      />,
      title,
      <SupplierProjectsSubTab key={status} query={queries[status]} />,
    ],
    config,
  );

const createRecurringProjectsTabs = (config, queries) =>
  R.map(
    ([status, title]) => [
      <SupplierPageRecurringProjectsTabTitle
        key={title}
        query={queries[status]}
        status={status}
      />,
      title,
      <SupplierRecurringProjectsSubTab key={status} query={queries[status]} />,
    ],
    config,
  );

const getSupplierProjectsQueries = (supplierId) =>
  getProjectsQueries(startOfToday(), [
    {
      nested: {
        path: 'suppliers',
        query: {
          bool: {
            must: [
              {
                match: {
                  'suppliers.supplierId': supplierId,
                },
              },
            ],
          },
        },
      },
    },
  ]);

export function SupplierProjectsTab(props) {
  const [defaultValue, setDefaultValue] = useState('all');
  const { supplierId } = useRouterParams(['supplierId']);

  const getAddEntityProjectQuery = R.pick(['supplierId', 'projectType']);
  const tabs = createProjectsTabs(
    projectsSubTabsConfig,
    getSupplierProjectsQueries(supplierId),
  );

  return (
    <EntityProjectsTabComp
      getAddEntityProjectQuery={getAddEntityProjectQuery}
      TabsWithPrefetch={PageSubTabs}
      projectType={ProjectType.WORK_ORDER}
      defaultValue={defaultValue}
      onChange={setDefaultValue}
      tabs={tabs}
      {...props}
    />
  );
}

export function SupplierRecurringProjectsTab(props) {
  const [defaultValue, setDefaultValue] = useState('all');

  const { supplierId } = useRouterParams(['supplierId']);

  const tabs = createRecurringProjectsTabs(
    recurringSubTabsConfig,
    recurringQueries,
  );

  const getAddEntityProjectQuery = R.pick(['projectId']);

  return (
    <EntityRecurringProjectsTabComp
      {...props}
      defaultValue={defaultValue}
      onChange={setDefaultValue}
      supplierId={supplierId}
      tabs={tabs}
      TabsWithPrefetch={PageSubTabs}
      getAddEntityProjectQuery={getAddEntityProjectQuery}
    />
  );
}

SupplierRecurringProjectsTab.displayName = 'SupplierRecurringProjectsTab';
