import React from 'react';
import * as R from 'ramda';
import downloadFile from 'downloadjs';
import { useQuery, gql } from '@apollo/client';
import { Loader } from 'poly-book-admin/src/Loader/index.js';
import { base64ToBlob } from 'poly-client-utils/src/print.js';
import { LinkButton } from 'poly-book-admin/src/LinkButton/index.js';

import { useAuthUserByTemporalAccess } from './DocumentsRequest/DocumentsRequestPage.js';

const generateWOCAssetsPDFByTemporalAccess = gql`
  query {
    generateWOCAssetsPDFByTemporalAccess {
      base64FileString
    }
  }
`;

export function WOCAssetsDownloadPage() {
  const { isAuthorized } = useAuthUserByTemporalAccess();

  const { data, loading } = useQuery(generateWOCAssetsPDFByTemporalAccess, {
    fetchPolicy: 'network-only',
    skip: !isAuthorized,
  });

  if (loading) {
    return <Loader />;
  }

  const base64String = R.path(
    ['generateWOCAssetsPDFByTemporalAccess', 'base64FileString'],
    data,
  );

  const handleDownload = () =>
    downloadFile(
      base64ToBlob(base64String, 'application/pdf'),
      'woc_assets.pdf',
    );

  const idDisabled = !base64String || loading;

  return (
    <LinkButton onClick={handleDownload} disabled={idDisabled}>
      Download Assets List
    </LinkButton>
  );
}
