import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';
import { useNotificationContext } from 'poly-book-admin';
import {
  useOnSubmitSetStopSubmitting,
  useModalContext,
  useProcessState,
} from 'poly-admin-ui';
import { upperCaseFirst } from 'poly-utils';

import { SimpleModalLayout } from '../SimpleModalLayout.js';

const approveNewEquipmentModalId = 'approveNewEquipmentModalId';

const ATTACH_ASSET_NEW_EQUIPMENT = gql`
  mutation ATTACH_ASSET_NEW_EQUIPMENT(
    $id: ID!
    $input: AttachAssetNewEquipmentInput!
  ) {
    attachAssetNewEquipment(id: $id, input: $input) {
      _id
    }
  }
`;

// getEquipmentTitle :: String -> String
export const getEquipmentTitle = (entity) =>
  `Approve ${upperCaseFirst(entity)} Name`;

// getEquipmentApproveContent :: (String, String) -> String
const getEquipmentApproveContent = (entity, name) =>
  `You're going to approve the ${upperCaseFirst(entity)} name ${name}.`;

// prepareAttachAssetNewEquipmentMutationInput :: AttachAssetNewEquipmentPayload -> AttachAssetNewEquipmentInput
// AttachAssetNewEquipmentPayload = {
//   name: String
//   entity: String
//   existedId: ID
// }
const prepareAttachAssetNewEquipmentMutationInput = R.ifElse(
  R.prop('existedId'),
  R.converge(R.objOf, [({ entity }) => `${entity}Id`, R.prop('existedId')]),
  R.converge(R.objOf, [
    ({ entity }) => `new${upperCaseFirst(entity)}Name`,
    R.prop('name'),
  ]),
);

export const useApproveNewEquipmentModal = () => {
  const { openModal } = useModalContext();
  const { showSuccessNotification } = useNotificationContext();
  const { process } = useProcessState(approveNewEquipmentModalId);
  const [attachAssetNewEquipment] = useMutation(ATTACH_ASSET_NEW_EQUIPMENT);

  const confirmHandler = async ({
    name,
    entity,
    assetId,
    existedId,
    closeConfirmModal,
  }) => {
    await attachAssetNewEquipment({
      variables: {
        id: assetId,
        input: prepareAttachAssetNewEquipmentMutationInput({
          name,
          entity,
          existedId,
        }),
      },
    });
    showSuccessNotification(
      `${upperCaseFirst(entity)} was successfully approved`,
    );
    closeConfirmModal();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    approveNewEquipmentModalId,
    confirmHandler,
  );

  return ({ entity, assetId, existedId, name }) =>
    openModal({
      loading: process,
      Layout: SimpleModalLayout,
      id: approveNewEquipmentModalId,
      title: getEquipmentTitle(entity),
      content: getEquipmentApproveContent(entity, name),
      onConfirm: (closeConfirmModal) => () =>
        onSubmit({ entity, assetId, existedId, name, closeConfirmModal }),
    });
};
