import * as R from 'ramda';
import { differenceInDays } from 'date-fns';
import { ProjectType, WeeklyServiceTicketStatuses } from 'poly-constants';

import { ensureIsDate } from '../dates.js';
import { calculateTotal } from '../general.js';
import { roundToCents } from '../formatters.js';
import { ramdaUseWith } from '../ramda.js';

// getHousekeepingWeekCountByProject :: Project -> Number
export const getHousekeepingWeekCountByProject = R.compose(
  Math.round,
  R.divide(R.__, 7),
  R.converge(differenceInDays, [
    R.compose(ensureIsDate, R.prop('endDate')),
    R.compose(ensureIsDate, R.prop('startDate')),
  ]),
);

// getTicketsNumber :: [WeeklyServiceTicket] -> Number
const getTicketsNumber = R.compose(
  R.length,
  R.filter(
    R.propSatisfies(
      R.includes(R.__, [
        WeeklyServiceTicketStatuses.approved,
        WeeklyServiceTicketStatuses.notCompleted,
      ]),
      'status',
    ),
  ),
  R.reject(R.isNil),
  R.defaultTo([]),
);

// hasHousekeepingMissingWeeklyServiceTickets :: Project -> Boolean
export const hasHousekeepingMissingWeeklyServiceTickets = ({
  type,
  weeklyServiceTickets,
  ...restOfProject
}) => {
  if (type !== ProjectType.HOUSEKEEPING) {
    return false;
  }

  const ticketsNumber = getTicketsNumber(weeklyServiceTickets);
  const weekNumbers = getHousekeepingWeekCountByProject(restOfProject);

  return ticketsNumber < weekNumbers;
};

// getHousekeepingProjectBaseTotalHours :: Project -> Number
export const getHousekeepingProjectBaseTotalHours = R.converge(R.subtract, [
  R.compose(
    calculateTotal(R.prop('hours')),
    R.propOr([], 'weeklyServiceTickets'),
  ),
  R.propOr(0, 'creditedPorterHours'),
]);

// getHousekeepingProjectExtraTotalHours :: Project -> Number
export const getHousekeepingProjectExtraTotalHours = R.compose(
  calculateTotal(R.propOr(0, 'extraHours')),
  R.propOr([], 'weeklyServiceTickets'),
);

// getHousekeepingSupplierHoursCost :: Project -> Number
export const getHousekeepingSupplierHoursCost = R.converge(R.multiply, [
  getHousekeepingProjectBaseTotalHours,
  R.propOr(0, 'supplierHourlyRate'),
]);

// getHousekeepingProjectSupplierTotal :: Project -> Number
const getHousekeepingProjectSupplierTotal = R.converge(R.add, [
  getHousekeepingSupplierHoursCost,
  R.propOr(0, 'supplierMonthlyFee'),
]);

// getHousekeepingSupplierExtraHoursTotal :: Project -> Number
export const getHousekeepingSupplierExtraHoursTotal = R.converge(R.multiply, [
  getHousekeepingProjectExtraTotalHours,
  R.propOr(0, 'supplierHourlyRate'),
]);

// isHousekeepingOverBilledBySupplier :: Number -> Project -> Bool
// first argument is supplier invoices total
export const isHousekeepingOverBilledBySupplier = ramdaUseWith(R.gt, [
  R.subtract(R.__, 5),
  R.compose(
    roundToCents,
    R.converge(R.add, [
      getHousekeepingSupplierExtraHoursTotal,
      getHousekeepingProjectSupplierTotal,
    ]),
  ),
]);

// getHousekeepingClientBaseHoursCost :: Project -> Number
export const getHousekeepingClientBaseHoursCost = R.converge(R.multiply, [
  R.propOr(0, 'clientHourlyRate'),
  getHousekeepingProjectBaseTotalHours,
]);

// getHousekeepingProjectClientTotal :: Project -> Number
export const getHousekeepingProjectClientTotal = R.converge(R.add, [
  getHousekeepingClientBaseHoursCost,
  R.propOr(0, 'clientMonthlyFee'),
]);

// getHousekeepingClientExtraHoursCost :: Project -> Number
export const getHousekeepingClientExtraHoursCost = R.ifElse(
  R.propEq('type', ProjectType.HOUSEKEEPING),
  R.compose(
    roundToCents,
    R.converge(R.multiply, [
      getHousekeepingProjectExtraTotalHours,
      R.propOr(0, 'clientHourlyRate'),
    ]),
  ),
  R.always(0),
);
