import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { instanceOf, oneOfType, shape, string } from 'prop-types';
import { formatDate, formatDateTime } from 'poly-utils';
import { formatAddressString } from 'poly-client-utils';
import { BooleanTextWithDot } from 'poly-book-admin';
import { SidebarRow } from 'poly-admin-ui';

import { BlockWithLabel } from '../../../sidebars/components/commonSidebarComponents.js';
import { SubcontractorAgreementDownloadBtn } from '../../DocumentsRequest/SubcontractorAgreementDownloadBtn.js';
import { getSectionText } from '../../../sidebars/components/commonSidebarSectionFormatters.js';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const SidebarRowS = styled(SidebarRow)`
  padding-left: 0;
`;

const twoBlocksProps = { width: '50%', margin: '10px 0' };

// getIsDownloadedSection :: Agreement -> _ -> ReactNode
const getIsDownloadedSection = ({ downloadedAt }) =>
  function (props) {
    return <BooleanTextWithDot isTrue={!!downloadedAt} {...props} />;
  };

// prepareSupplierDataForAgreement :: SupplierCompany -> SignedAgreementInfo
// SignedAgreementInfo :: { companyName: String, companyAddress: String }
const prepareSupplierDataForAgreement = R.compose(
  R.applySpec({
    companyName: R.prop('name'),
    companyAddress: R.compose(
      R.join('/'),
      R.split('\n'),
      formatAddressString,
      R.pick(['address', 'addressTwo']),
    ),
  }),
  R.defaultTo({}),
);

export function SubcontractorAgreementInfo({ agreement, company }) {
  if (!agreement) {
    return null;
  }

  const supplierAgreementInfo = prepareSupplierDataForAgreement(company);

  return (
    <Wrapper>
      <SidebarRowS>
        <BlockWithLabel
          {...twoBlocksProps}
          id="agreement-date"
          label="Agreement date"
          Component={getSectionText(formatDate(agreement?.date))}
        />
        <BlockWithLabel
          {...twoBlocksProps}
          id="agreed-by"
          label="Agreed By"
          Component={getSectionText(agreement?.contactName)}
        />
      </SidebarRowS>
      <SidebarRowS>
        <BlockWithLabel
          {...twoBlocksProps}
          id="title"
          label="Title"
          Component={getSectionText(agreement?.title)}
        />
        <BlockWithLabel
          id="ip"
          label="IP"
          Component={getSectionText(agreement?.ip)}
        />
      </SidebarRowS>
      <SidebarRowS>
        <BlockWithLabel
          {...twoBlocksProps}
          id="submission-timestamp"
          label="Submission timestamp"
          Component={getSectionText(formatDateTime(agreement?.date))}
        />
        <BlockWithLabel
          id="downloaded"
          label="Downloaded"
          Component={getIsDownloadedSection(agreement)}
        />
      </SidebarRowS>
      <SidebarRowS align>
        <BlockWithLabel
          id="version"
          width="calc(50% - 44px)"
          label="Accepted Version"
          Component={getSectionText(agreement?.version)}
        />
        <SubcontractorAgreementDownloadBtn
          values={{ ...supplierAgreementInfo, ...agreement, isAgree: true }}
        />
      </SidebarRowS>
    </Wrapper>
  );
}

export const subcontractorAgreementPropTypes = shape({
  ip: string.isRequired,
  title: string.isRequired,
  version: string.isRequired,
  contactName: string.isRequired,
  date: oneOfType([string, instanceOf(Date)]),
  downloadedAt: oneOfType([string, instanceOf(Date)]),
});

SubcontractorAgreementInfo.propTypes = {
  agreement: subcontractorAgreementPropTypes,
  company: shape({ name: string, addressTwo: string }),
};
