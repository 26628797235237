import React from 'react';
import styled from 'styled-components';
import { Checkbox, FormField } from 'poly-book-admin';
import { string } from 'prop-types';

const CheckBoxS = styled(Checkbox)`
  margin: 2px 10px 0 0;
  flex-shrink: 0;
`;

export function SupplierItemCheckBox({ supplierId, type, ...restProps }) {
  const name = `suppliers.${type}.${supplierId}`;

  return <FormField {...restProps} Component={CheckBoxS} name={name} />;
}

SupplierItemCheckBox.propTypes = {
  supplierId: string,
  type: string,
};
