import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { func, number, string, oneOfType, object } from 'prop-types';
import { useRouterQuery } from 'poly-client-routing';
import {
  PageHeaderContainer,
  getThemeColor,
  IconButton,
  MainHeader,
  Text,
} from 'poly-book-admin';
import { FlexContainer } from '../../components/FlexContainer.js';

const SearchPageTitle = styled(MainHeader)`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
  justify-content: space-between;
`;

export const SearchPageFilterRow = styled(FlexContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 0;
`;

SearchPageFilterRow.displayName = 'SearchPageFilterRow';

const TextS = styled(Text)`
  width: 130px;
`;

export function SearchPageLabel(props) {
  return (
    <TextS {...props} size="14px" weight="bold" color={['primaryLighter4']} />
  );
}

SearchPageLabel.displayName = 'SearchPageLabel';

// checkIfNeedToShow :: routerQuery -> Bool
const checkIfNeedToShow = R.pathSatisfies(R.complement(R.equals('true')), [
  'routerQuery',
  'hideFilterPanel',
]);

export function SearchPageHeader({ title, height, FilterComponent }) {
  const routerQuery = useRouterQuery(['hideFilterPanel', 'searchFilter']);

  const [isOpen, setIsOpen] = useState(checkIfNeedToShow(routerQuery));

  const toggleFilters = () => setIsOpen(!isOpen);

  const styleProps = {
    headerStyles: `
      height: ${isOpen ? height : 55}px;
      padding: 0;
      background-color: #fcfcfd;
      flex-shrink: 0;
      z-index: 3;
    `,

    contentStyles: `
      width: calc(100% - 60px);
      margin: 0 30px;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    `,
  };

  return (
    <PageHeaderContainer {...styleProps}>
      <SearchPageTitle>
        <Text>{title}</Text>
        <IconButton
          size={15}
          name={isOpen ? 'arrow-up' : 'arrow-down'}
          onClick={toggleFilters}
        />
      </SearchPageTitle>
      {isOpen && <FilterComponent />}
    </PageHeaderContainer>
  );
}

SearchPageHeader.displayName = 'SearchPageHeader';

SearchPageHeader.propTypes = {
  title: string.isRequired,
  height: number.isRequired,
  FilterComponent: oneOfType([func, object]).isRequired,
};
