import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { arrayOf, bool, func, string, shape } from 'prop-types';
import {
  Button,
  DatePicker,
  getThemeColor,
  InputErrorMsg,
} from 'poly-book-admin';
import { useReactiveQuery } from 'poly-client-utils';
import { useNavigate } from 'poly-client-routing';
import { useNotificationState, MoneyInput, FlexContainer } from 'poly-admin-ui';
import { roundTo } from 'poly-utils';

import styled from 'styled-components';
import {
  ReconciliationFooterRow,
  ReconciliationFooterButton,
  ReconciliationFooterWrapper,
  inputLabelProps,
} from './reconciliationEntityComponents.js';
import { routesNames } from '../../routes/index.js';
import {
  formatPendingReconcileTransactionsInput,
  formatReconcileTransactionsInput,
  getChangedTransactions,
  getReconciledPaymentsAmount,
  getRefetchDataTimeOut,
  isTransactionsNotChanged,
} from './bankReconciliationUtils.js';
import { moneyTransactionShapePropType } from '../BankRegistersPage/commonPropTypes.js';

const ACCOUNT_ERROR_MSG = 'Account is not balanced';
const REFETCH_DATA_TIMEOUT = 1.5e4;

function NegativeMoneyInput(props) {
  return <MoneyInput allowNegative {...props} />;
}

const commonReadOnlyProps = {
  width: '100%',
  readOnly: true,
  onChange: () => null,
};

const latestReconciliationStatementQuery = gql`
  query latestReconciliationStatementQuery($accountId: ID!) {
    latestReconciliationStatement(accountId: $accountId) {
      _id
      endingBalance
    }
  }
`;

const latestReconciliationStatementSubscription = gql`
  subscription latestReconciliationStatementSubscription {
    searchReconciliationStatementChanged {
      id
      type
    }
  }
`;

const reconcileJournalsMutation = gql`
  mutation reconcileJournalsMutation($input: ReconcileJournalsInput!) {
    reconcileJournals(input: $input) {
      transactionNumbersToReconcile
    }
  }
`;

const inputCommonProps = {
  width: '155px',
  labelProps: {
    ...inputLabelProps,
    marginBottom: '0',
  },
};

const InputsContainer = styled(FlexContainer)`
  & > div:not(:last-child) {
    margin-right: 15px;
  }
  align-items: flex-end;
  flex-grow: 1;
`;

const CancelBtn = styled(Button).attrs(() => ({
  size: 'tiny',
}))`
  background: #fff;
  border: 1px solid ${getThemeColor(['primaryLight'])};
  color: ${getThemeColor(['primaryLight'])};
  margin-left: 15px;
`;

const ButtonsContainer = styled(FlexContainer)`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  flex-wrap: wrap;
`;

const ErrMsg = styled(InputErrorMsg)`
  margin-left: 5px;
`;

export function ReconciliationPageFooter({
  skip,
  labels,
  payments,
  deposits,
  accountId,
  selectedPayments,
  selectedDeposits,
  refetchTablesData,
}) {
  const [endingDate, setEndingDate] = useState(null);
  const [endingDateHasError, setEndingDateHasError] = useState(false);
  const [endingBalance, setEndingBalance] = useState(0);
  const [accountError, setAccountError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onEndingDateChange = (date) => {
    setEndingDateHasError(false);
    return setEndingDate(date);
  };

  const navigate = useNavigate();
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();

  const { data } = useReactiveQuery(
    latestReconciliationStatementQuery,
    latestReconciliationStatementSubscription,
    {
      queryOptions: { variables: { accountId }, skip },
      subscriptionOptions: {},
    },
  );

  const [reconcileJournals] = useMutation(reconcileJournalsMutation);

  const openingBalance = R.pathOr(
    0,
    ['latestReconciliationStatement', 'endingBalance'],
    data,
  );

  const totalDeposits = useMemo(
    () => getReconciledPaymentsAmount(deposits)(selectedDeposits),
    [deposits, selectedDeposits],
  );
  const totalPayments = useMemo(
    () => getReconciledPaymentsAmount(payments)(selectedPayments),
    [payments, selectedPayments],
  );

  const clearedBalance = useMemo(
    () => roundTo(2)(openingBalance + totalDeposits - totalPayments),
    [openingBalance, totalDeposits, totalPayments],
  );
  const sentToStatement = endingBalance === clearedBalance;
  const disabledReconciliation = skip || isLoading;

  const onCancel = () => {
    setAccountError(false);
    navigate(routesNames.FINANCIAL_DIRECTORY);
  };

  const changedPayments = getChangedTransactions(payments, selectedPayments);
  const changedDeposit = getChangedTransactions(deposits, selectedDeposits);

  const isSaveButtonDisable =
    isTransactionsNotChanged(changedPayments) &&
    isTransactionsNotChanged(changedDeposit);

  const onSaveJournals = async () => {
    const refetchDataTimeOut = getRefetchDataTimeOut([
      ...changedPayments,
      ...changedDeposit,
    ]);

    setIsLoading(true);
    try {
      await reconcileJournals({
        variables: {
          input: {
            accountId,
            transactionNumbersToReconcile: [
              ...formatReconcileTransactionsInput(
                selectedPayments,
                changedPayments,
              ),
              ...formatReconcileTransactionsInput(
                selectedDeposits,
                changedDeposit,
              ),
            ],
            transactionNumbersToPending: [
              ...formatPendingReconcileTransactionsInput(
                selectedPayments,
                changedPayments,
              ),
              ...formatPendingReconcileTransactionsInput(
                selectedDeposits,
                changedDeposit,
              ),
            ],
          },
        },
      });
      setTimeout(() => {
        showSuccessNotification('Checked items Saved!');
        setAccountError(true);
        refetchTablesData();
        setIsLoading(false);
      }, refetchDataTimeOut);
    } catch {
      setAccountError(true);
      setIsLoading(false);
    }
  };

  const onReconcileJournals = async () => {
    if (!sentToStatement) {
      showErrorNotification('Account is not balanced');
      return;
    }
    if (!endingDate) {
      setEndingDateHasError(true);
      showErrorNotification('Statement Ending Date is required to reconcile');
      return;
    }
    setIsLoading(true);
    try {
      await reconcileJournals({
        variables: {
          input: {
            accountId,
            endingDate,
            endingBalance,
            transactionNumbersToReconcile: [
              ...formatReconcileTransactionsInput(selectedPayments, payments),
              ...formatReconcileTransactionsInput(selectedDeposits, deposits),
            ],
            transactionNumbersToPending: [
              ...formatPendingReconcileTransactionsInput(
                selectedPayments,
                payments,
              ),
              ...formatPendingReconcileTransactionsInput(
                selectedDeposits,
                deposits,
              ),
            ],
          },
        },
      });
      setTimeout(() => {
        showSuccessNotification('Account is successfully reconciled!');
        setEndingBalance(0);
        setEndingDate(null);
        setAccountError(false);
        showSuccessNotification(labels.confirmation);
        refetchTablesData();
        setIsLoading(false);
      }, REFETCH_DATA_TIMEOUT);
    } catch {
      setAccountError(true);
      setIsLoading(false);
    }
  };

  return (
    <ReconciliationFooterWrapper>
      <ReconciliationFooterRow>
        <InputsContainer>
          <MoneyInput
            label={labels.deposits}
            value={totalDeposits}
            name="totalDeposits"
            {...commonReadOnlyProps}
            {...inputCommonProps}
          />
          <MoneyInput
            label={labels.payments}
            name="totalPayments"
            value={totalPayments}
            {...commonReadOnlyProps}
            {...inputCommonProps}
          />
          <NegativeMoneyInput
            label="Cleared Balance"
            value={clearedBalance}
            name="clearedBalance"
            {...commonReadOnlyProps}
            {...inputCommonProps}
          />
          <NegativeMoneyInput
            label="Opening Balance"
            value={openingBalance}
            name="openingBalance"
            {...commonReadOnlyProps}
            {...inputCommonProps}
          />
          <DatePicker
            label="Statement Ending Date"
            width="100%"
            position="top"
            value={endingDate}
            onChange={onEndingDateChange}
            hasError={endingDateHasError}
            {...inputCommonProps}
          />
          <NegativeMoneyInput
            label="Statement Ending Balance"
            width="100%"
            value={endingBalance}
            name="clearedBalance"
            onChange={setEndingBalance}
            {...inputCommonProps}
          />
        </InputsContainer>
        <ButtonsContainer>
          <ReconciliationFooterButton
            loader={isLoading}
            onClick={onSaveJournals}
            disabled={disabledReconciliation || isSaveButtonDisable}
          >
            Save
          </ReconciliationFooterButton>
          <ReconciliationFooterButton
            loader={isLoading}
            onClick={onReconcileJournals}
            disabled={disabledReconciliation}
          >
            Reconcile
          </ReconciliationFooterButton>
          <CancelBtn onClick={onCancel}>Cancel</CancelBtn>
          {accountError && <ErrMsg>{ACCOUNT_ERROR_MSG}</ErrMsg>}
        </ButtonsContainer>
      </ReconciliationFooterRow>
    </ReconciliationFooterWrapper>
  );
}

ReconciliationPageFooter.propTypes = {
  skip: bool.isRequired,
  accountId: string,
  labels: shape({
    payments: string.isRequired,
    deposits: string.isRequired,
    confirmation: string.isRequired,
  }),
  refetchTablesData: func.isRequired,
  payments: arrayOf(moneyTransactionShapePropType).isRequired,
  deposits: arrayOf(moneyTransactionShapePropType).isRequired,
  selectedPayments: arrayOf(string).isRequired,
  selectedDeposits: arrayOf(string).isRequired,
};
