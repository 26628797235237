import React from 'react';
import { Select } from 'poly-book-admin';
import { AccountingMethods } from 'poly-constants';

const ledgerTypeOptions = [
  {
    value: AccountingMethods.ACCRUAL_BASIS,
    label: 'Accrual Basis',
  },
  {
    value: AccountingMethods.CASH_BASIS,
    label: 'Cash Basis',
  },
];

export function AccountingMethodSelect(props) {
  return <Select options={ledgerTypeOptions} required {...props} />;
}
