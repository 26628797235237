import { Checkbox, Input } from 'poly-book-admin';
import React, { useState } from 'react';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';

import { FlexColumn } from '../../../../globalSearch/globalSearchResult/searchResultItems/common/wrappers.js';

const CheckboxS = styled(Checkbox)`
  margin-bottom: 10px;
`;

export function ToggleDBAInput({ value, onChange, required, ...props }) {
  const [isChecked, setIsChecked] = useState(value);

  const onCheckBoxClick = () => {
    if (isChecked) {
      onChange(null);
    }
    setIsChecked(!isChecked);
  };

  return (
    <FlexColumn>
      <CheckboxS
        name="toggleInputCheckbox"
        value={isChecked}
        onChange={onCheckBoxClick}
      />
      {isChecked && (
        <Input
          {...props}
          label="DBA"
          value={value}
          onChange={onChange}
          required
        />
      )}
    </FlexColumn>
  );
}

ToggleDBAInput.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  required: bool,
};
