import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { endOfDay, isPast } from 'date-fns';
import { shape, string, bool, instanceOf, arrayOf } from 'prop-types';
import { pathOrNothingUI } from 'poly-client-utils';
import { applyPathOr, toDate } from 'poly-utils';
import {
  extractTablePropsFromConfig,
  taskStatusesColors,
  TaskDueDate,
} from 'poly-admin-ui';
import {
  TaskDueDateFilterPreset,
  NOTHING_UI_STRING,
  taskCollections,
  TaskPriorityUIMap,
} from 'poly-constants';
import {
  pipColumnStyles,
  TableCard,
  Dot,
  BodyRow,
  Loader,
  Table,
} from 'poly-book-admin';

import { tasksPropTypes } from './propTypes.js';
import { useSidebarLogicContext } from '../../sidebars/SidebarLogicContext.js';
import { useMyTasksRowHandler } from './useMyTasksRowHandler.js';

const { CLIENTS, PROJECTS, PROPERTIES, RECURRING_PROJECTS } = taskCollections;

const isProjectTask = R.propSatisfies(R.equals(R.__, PROJECTS), 'collection');

function TaskProjectLink(task) {
  const { ProjectLink } = useSidebarLogicContext();

  if (![PROJECTS, RECURRING_PROJECTS].includes(task.collection)) {
    return NOTHING_UI_STRING;
  }

  const project = R.ifElse(
    R.propEq('collection', RECURRING_PROJECTS),
    R.pathOr({}, ['document', 'recurringProject']),
    R.pathOr({}, ['document', 'project']),
  )(task);

  return <ProjectLink {...project} />;
}

const TaskProjectDescription = (task) => {
  if (task.collection === PROJECTS) {
    return pathOrNothingUI(['document', 'project', 'description'])(task);
  }

  if (task.collection === RECURRING_PROJECTS) {
    return pathOrNothingUI(['document', 'recurringProject', 'description'])(
      task,
    );
  }

  return NOTHING_UI_STRING;
};

function TaskProjectClientLink(task) {
  const { ClientLink } = useSidebarLogicContext();

  if (isProjectTask(task)) {
    const projectClient = R.pathOr({}, ['document', 'project', 'client'], task);
    return <ClientLink {...projectClient} />;
  }

  if (task.collection === PROPERTIES) {
    const propertyClient = R.pathOr(
      {},
      ['document', 'property', 'client'],
      task,
    );
    return <ClientLink {...propertyClient} />;
  }

  if (task.collection === CLIENTS) {
    const client = R.pathOr({}, ['document', 'client'], task);
    return <ClientLink {...client} />;
  }

  return NOTHING_UI_STRING;
}

function TaskProjectPropertyLink(task) {
  const { PropertyLink } = useSidebarLogicContext();

  if (isProjectTask(task)) {
    const property = R.pathOr({}, ['document', 'project', 'property'], task);
    return <PropertyLink {...property} />;
  }

  if (task.collection === PROPERTIES) {
    const property = R.pathOr({}, ['document', 'property'], task);
    return <PropertyLink {...property} />;
  }

  return NOTHING_UI_STRING;
}

const DotS = styled(Dot)`
  margin-top: 5px;
`;

function TaskStatusColumn({ dueDate }) {
  const date = endOfDay(toDate(dueDate));
  const status = isPast(date)
    ? TaskDueDateFilterPreset.PAST_DUE
    : TaskDueDateFilterPreset.ACTIVE;

  return <DotS color={taskStatusesColors[status]} />;
}

TaskStatusColumn.propTypes = { dueDate: instanceOf(Date) };

// projectDescriptionSort :: Task -> SortFunction
const projectDescriptionSort = R.ifElse(
  R.propEq('collection', RECURRING_PROJECTS),
  R.path(['document', 'recurringProject', 'description']),
  R.path(['document', 'project', 'description']),
);

// getTaskPriorityUI :: Task -> String
export const getTaskPriorityUI = R.compose(
  R.defaultTo(NOTHING_UI_STRING),
  R.prop(R.__, TaskPriorityUIMap),
  R.prop('priority'),
);

export const getProjectTasksTableConfig = [
  ['', TaskStatusColumn],
  ['Project ID', TaskProjectLink, R.path(['document', 'project', 'projectId'])],
  [
    'Due Date',
    applyPathOr(['dueDate'], TaskDueDate, 'No Date'),
    R.prop('dueDate'),
  ],
  ['Task', pathOrNothingUI(['description']), R.path(['description'])],
  ['Priority', getTaskPriorityUI, R.prop('priority')],
  ['Project Description', TaskProjectDescription, projectDescriptionSort],
  [
    'Client',
    TaskProjectClientLink,
    R.path(['document', 'project', 'client', 'nickName']),
  ],
  [
    'Property',
    TaskProjectPropertyLink,
    R.path(['document', 'project', 'property', 'name']),
  ],
];

const MyProjectsTasksTableS = styled(Table)`
  td:nth-child(1),
  th:nth-child(1) {
    padding-top: 11px;
    width: 30px;
  }
  ${pipColumnStyles(2)}

  td:nth-child(2), th:nth-child(2) {
    padding-left: 5px;
  }

  td:nth-child(3),
  th:nth-child(3),
  td:nth-child(4),
  th:nth-child(4) {
    width: 100px;
  }

  td:nth-child(5),
  th:nth-child(5) {
    width: 18%;
  }

  td:nth-child(6),
  th:nth-child(6) {
    width: 100px;
  }

  td:nth-child(8),
  th:nth-child(8),
  td:nth-child(9),
  th:nth-child(9) {
    width: 18%;
  }
`;

const MyTasksPrintTableS = styled(Table)`
  ${pipColumnStyles(1)}

  td:nth-child(1), th:nth-child(1) {
    padding-left: 5px;
    padding-top: 15px;
  }

  td:nth-child(2),
  th:nth-child(2),
  td:nth-child(3),
  th:nth-child(3) {
    width: 100px;
  }

  td:nth-child(4),
  th:nth-child(4) {
    width: 18%;
  }

  td:nth-child(5),
  th:nth-child(5) {
    width: 100px;
  }

  td:nth-child(7),
  th:nth-child(7),
  td:nth-child(8),
  th:nth-child(8) {
    width: 18%;
  }
`;

const TableCardS = styled(TableCard)`
  flex-direction: column;
  max-width: 1440px;
`;

export function MyTasksTablePrint({ tasks }) {
  const tableProps = extractTablePropsFromConfig(getProjectTasksTableConfig);

  return <MyTasksPrintTableS rows={tasks} {...tableProps} />;
}

MyTasksTablePrint.propTypes = { tasks: tasksPropTypes };

function ClickableRow({ row, ...props }) {
  const onClick = useMyTasksRowHandler(row);

  return (
    <BodyRow
      selectRowCheckboxOnClick={(e) => e.stopPropagation()}
      {...props}
      row={row}
      isClickable
      onClick={onClick}
    />
  );
}

ClickableRow.propTypes = { row: shape({ collection: string.isRequired }) };

export function MyTasksTable({ loading, selectedTasksIds, ...tableProps }) {
  return loading ? (
    <Loader />
  ) : (
    <TableCardS>
      <MyProjectsTasksTableS
        RowComponent={ClickableRow}
        selectedRows={selectedTasksIds}
        {...tableProps}
      />
    </TableCardS>
  );
}

MyTasksTable.propTypes = {
  loading: bool,
  searchFilters: shape({
    pastDue: string.isRequired,
    taskType: string.isRequired,
  }),
  searchText: string,
  selectedTasksIds: arrayOf(string),
};
