import React from 'react';
import styled from 'styled-components';
import { string, arrayOf, func } from 'prop-types';
import { WindowedTable, Loader } from 'poly-book-admin';

import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { useSearchAssetReviewQuery } from './useSearchAssetReviewQuery.js';

const AssetReviewTableS = styled(WindowedTable)`
  thead {
    height: 45px;
  }

  tr {
    width: 100%;
    margin: 0;
  }

  td {
    vertical-align: middle;
    padding: 5px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 220px;
  }

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4),
  th:nth-child(5),
  td:nth-child(5) {
    width: 250px;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 25px;
  }
`;

export function AssetReviewTable(props) {
  const { clientId, loading, tableProps } = useSearchAssetReviewQuery(props);

  if (!clientId) return <EmptyListMessage />;

  if (loading) return <Loader />;

  return <AssetReviewTableS {...tableProps} selectedRowsInEnd />;
}

AssetReviewTable.propTypes = {
  rejectedAsset: string,
  setHasError: func.isRequired,
  setRejectedAsset: func.isRequired,
  setSelectedAssets: func.isRequired,
  selectedAssets: arrayOf(string).isRequired,
};
