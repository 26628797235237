import * as R from 'ramda';
import { TWO_FA_CODE_INCORRECT_ERROR } from 'poly-constants';
import { graphqlTokenRoute, graphqlForgotPasswordRoute } from 'poly-utils';

import { jsonPostRequest } from './rest.js';

const settings = process.env;

const { GRAPHQL_HTTP_URL = '' } = settings;

const USER_TOKEN_ROUTE = graphqlTokenRoute(GRAPHQL_HTTP_URL);

export const FORGOT_PASSWORD_ROUTE =
  graphqlForgotPasswordRoute(GRAPHQL_HTTP_URL);

export const fetchUserToken = async (creds) => {
  try {
    await jsonPostRequest(USER_TOKEN_ROUTE, creds);

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

// isErrorEqualTwoFAError :: String -> Boolean
export const isErrorEqualTwoFAError = R.equals(
  R.prop('message', TWO_FA_CODE_INCORRECT_ERROR),
);

// getLoginError :: String | Object -> String
export const getLoginError = R.when(
  R.complement(R.is(String)),
  R.always('Unknown error'),
);
