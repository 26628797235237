import { useQuery, useMutation } from '@apollo/client';
import { useRouterParams } from 'poly-client-routing';
import { PROPERTY_FOR_ADD_SUPPLIERS } from './queries.js';
import { ADD_PROPERTY_SUPPLIER_MUTATION } from './mutations.js';

export const usePropertyForAddSupplier = () => {
  const { propertyId } = useRouterParams(['propertyId']);

  const { data, loading } = useQuery(PROPERTY_FOR_ADD_SUPPLIERS, {
    variables: {
      id: propertyId,
    },
  });

  return {
    data,
    loading,
    propertyId,
  };
};

export const useAddPropertySupplier = () => {
  const [mutate] = useMutation(ADD_PROPERTY_SUPPLIER_MUTATION, {
    alias: 'useAddPropertySupplier',
  });

  return {
    addPropertySupplier: (input) =>
      mutate({
        variables: {
          input,
        },
      }),
  };
};
