import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import { useSubscriptionByChanges } from 'poly-client-utils';
import { isNilOrEmpty } from 'poly-utils';

import { MAX_ITEMS } from '../../constants/general.js';
import {
  RECURRING_PROJECT_SUPPLIERS,
  PROJECT_SUPPLIERS,
  PROJECT_EMAILS,
} from '../../hocs/projects/queries.js';

export const SUPPLIER_SEARCH_CHANGED = gql`
  subscription SUPPLIER_SEARCH_CHANGED($input: CollectionSearchParams!) {
    searchSupplierChanged(input: $input) {
      id
      type
    }
  }
`;

// shouldSkipQuery :: SkipQueryParams -> Boolean
// SkipQueryParams = { projectId: ID, skipQuery: Boolean }
const shouldSkipQuery = R.either(
  R.propSatisfies(isNilOrEmpty, 'projectId'),
  R.prop('skipQuery'),
);

export const useProjectSuppliers = (projectId, skipQuery) => {
  const { data, loading, refetch } = useQuery(PROJECT_SUPPLIERS, {
    variables: { projectId, tasksInput: { size: 100 } },
    skip: shouldSkipQuery({ projectId, skipQuery }),
    fetchPolicy: 'network-only',
  });

  useSubscriptionByChanges({
    gqlQueryChanged: SUPPLIER_SEARCH_CHANGED,
    extractQueryParamsFromProps: R.applySpec({
      variables: { input: R.always({ size: MAX_ITEMS, from: 0 }) },
    }),
    refetch,
    result: data,
    skipQuery: shouldSkipQuery({ projectId, skipQuery }),
  });

  return { data, loading };
};

export const useRecurringProjectSuppliers = (projectId) => {
  const { data, loading } = useQuery(RECURRING_PROJECT_SUPPLIERS, {
    fetchPolicy: 'network-only',
    variables: { projectId },
    skip: !projectId,
  });

  return { data, loading };
};

// getProjects :: SearchProjectsQueryResult => {Project}
const getProjects = R.compose(
  R.defaultTo({}),
  R.ifElse(
    R.path(['searchProjects', 'hits', 'length']),
    R.path(['searchProjects', 'hits', 0]),
    R.path(['searchRecurringProjects', 'hits', 0]),
  ),
);

export const useProjectEmails = (projectId) => {
  const { data, loading } = useQuery(PROJECT_EMAILS, {
    variables: { input: { query: { match: { _id: projectId } } } },
    fetchPolicy: 'network-only',
    skip: !projectId,
  });

  return {
    project: getProjects(data),
    loading,
  };
};
