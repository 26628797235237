import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import { DESC_SORT_ORDER } from 'poly-constants';
import { Loader } from 'poly-book-admin';
import { assocBy } from 'poly-utils';
import {
  useHighlightMatchesBySearch,
  commonReverseSortQuery,
  keywordSortQuery,
  commonSortQuery,
  formatDateProp,
} from 'poly-client-utils';
import {
  useSetInitialPaginationOnMount,
  useMapConfigToTableProps,
  useSetItemsCount,
  useTableSorting,
} from 'poly-admin-ui';

import {
  projectIdColumn,
  ProjectStatusDot,
  projectPriorityColumn,
  projectStartDateColumn,
} from '../columns/projects.js';
import {
  MyProjectsTableComp,
  MyRecurringProjectsTableComp,
} from './MyProjectsTableComp.js';
import { clientColumn } from '../columns/clients.js';
import { useSortingByReduxSearch } from '../../core/hooks/useSortingByReduxSearch.js';
import { getProjectDaysOpen } from '../../../utils/project.js';
import {
  useMyRecurringProjects,
  useMyProjectsBySearch,
} from '../../core/hooks/projects/index.js';

const propertyPath = R.path(['property', 'name']);
const extPath = R.prop('ext');

const recurringProjectsTableConfig = [
  ['', ProjectStatusDot],
  projectIdColumn,
  ['Description', R.prop('description'), keywordSortQuery(['description'])],
  ['Property', propertyPath, keywordSortQuery(['property', 'name'])],
  clientColumn,
  projectStartDateColumn,
  ['End Date', formatDateProp('endDate'), commonSortQuery(['endDate'])],
];

export const projectsTableConfig = [
  ...R.insert(1, projectPriorityColumn, recurringProjectsTableConfig),
  ['Days Open', getProjectDaysOpen, commonReverseSortQuery(['createdAt'])],
  ['Ext', extPath, commonSortQuery(['ext'])],
];

// getManagerProjectsTotal :: SearchManagerProjectsQueryResult -> Number
const getManagerProjectsTotal = R.pathOr(0, [
  'me',
  'searchManagerProjects',
  'total',
]);

// getManagerProjects :: SearchManagerProjectsQueryResult -> [Project]
const getManagerProjects = R.compose(
  R.map(assocBy('projectLinkId', R.prop('projectId'))),
  R.pathOr([], ['me', 'searchManagerProjects', 'hits']),
);

export function MyProjectsTable({ query }) {
  useSetInitialPaginationOnMount();

  const { sort: tableSort, ...tableSortingProps } = useTableSorting({
    tableConfig: projectsTableConfig,
    order: DESC_SORT_ORDER,
    column: 2,
  });

  const { sort } = useSortingByReduxSearch({ sort: tableSort });

  const { loading, result } = useMyProjectsBySearch({ sort, query });

  useSetItemsCount(getManagerProjectsTotal, result);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getManagerProjects,
    projectsTableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [
      ['projectId'],
      ['description'],
      ['client', 'name'],
      ['property', 'name'],
      ['manager', 'fullName'],
    ],
    rows,
  );

  if (loading) return <Loader />;

  return (
    <MyProjectsTableComp
      {...tableSortingProps}
      {...restTableProps}
      rows={highlightedRows}
      paginationVisible
    />
  );
}

MyProjectsTable.displayName = 'MyProjectsTable';

MyProjectsTable.propTypes = {
  query: shape({ term: shape({ status: string }) }),
};

// getManagerRecurringProjectsTotal :: SearchManagerProjectsQueryResult -> Number
const getManagerRecurringProjectsTotal = R.pathOr(0, [
  'me',
  'searchManagerRecurringProjects',
  'total',
]);

// getManagerRecurringProjects :: SearchManagerRecurringProjectsQueryResult -> [Project]
const getManagerRecurringProjects = R.compose(
  R.map(assocBy('projectLinkId', R.prop('projectId'))),
  R.pathOr([], ['me', 'searchManagerRecurringProjects', 'hits']),
);

export function MyRecurringProjectsTable({ query }) {
  useSetInitialPaginationOnMount();

  const { sort: tableSort, ...tableSortingProps } = useTableSorting({
    tableConfig: recurringProjectsTableConfig,
    order: DESC_SORT_ORDER,
    column: 6,
  });

  const { sort } = useSortingByReduxSearch({ sort: tableSort });

  const { loading, result } = useMyRecurringProjects({ sort, query });

  useSetItemsCount(getManagerRecurringProjectsTotal, result);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getManagerRecurringProjects,
    recurringProjectsTableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [
      ['projectId'],
      ['description'],
      ['client', 'name'],
      ['property', 'name'],
      ['manager', 'fullName'],
    ],
    rows,
  );

  if (loading) return <Loader />;

  return (
    <MyRecurringProjectsTableComp
      {...tableSortingProps}
      {...restTableProps}
      rows={highlightedRows}
      paginationVisible
    />
  );
}

MyRecurringProjectsTable.displayName = 'MyRecurringProjectsTable';

MyRecurringProjectsTable.propTypes = {
  query: shape({
    term: shape({
      status: string,
    }),
  }),
};
