import React from 'react';
import * as R from 'ramda';
import { Input } from 'poly-book-admin';
import { AddressSection, PhoneNumberInput } from 'poly-admin-ui';
import {
  addressValidators,
  equalsOmitFields,
  ifNotEmpty,
  validatePhone,
} from 'poly-client-utils';

import { StatusSelect } from '../components/StatusSelect.js';
import { DivisionSelect } from '../components/DivisionSelect.js';
import { applyWidth } from '../../../../../modules/forms/formsStyles.js';

function StoreNumberInput(props) {
  return <Input {...props} type="tel" format="###" mask="_" />;
}

function DisabledInput(props) {
  return <Input {...props} disabled />;
}

export const nameFormField = {
  label: 'Name',
  field: {
    name: 'name',
    Component: Input,
  },
  required: true,
  validators: [[R.identity, 'Property name is required']],
};

export const mainAccountFormField = {
  label: 'Main account',
  field: {
    name: 'client.name',
    Component: DisabledInput,
  },
};

export const masterPropertyFormField = {
  label: 'Master Property',
  field: {
    name: 'masterProperty.name',
    Component: DisabledInput,
  },
  renderIf: R.path(['masterProperty', '_id']),
};

export const statusFormField = {
  label: 'Status',
  field: {
    name: 'status',
    Component: StatusSelect,
    withFormData: true,
  },
  required: true,
};

export const storeNumberFormField = {
  label: 'Store #',
  field: {
    name: 'storeNumber',
    Component: StoreNumberInput,
  },
  renderIf: R.path(['client', 'configs', 'propertyFields', 'storeNumber']),
};

export const divisionFormField = {
  label: 'Division',
  field: {
    name: 'division',
    withFormData: true,
    Component: DivisionSelect,
  },
  renderIf: R.path(['client', 'configs', 'propertyFields', 'division']),
};

export const addressFormField = {
  field: {
    name: 'address',
    Component: AddressSection,
    isEqual: equalsOmitFields([['formatted_address']]),
  },
  required: true,
  validators: addressValidators,
};

export const phoneFormField = {
  label: 'Phone',
  field: {
    name: 'phone',
    Component: applyWidth(PhoneNumberInput),
  },
  validators: [[ifNotEmpty(validatePhone), 'Incorrect phone number']],
};

export const faxFormField = {
  label: 'Fax',
  field: {
    name: 'fax',
    Component: applyWidth(PhoneNumberInput),
  },
  validators: [[ifNotEmpty(validatePhone), 'Incorrect fax number']],
};
