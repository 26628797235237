import React from 'react';
import * as R from 'ramda';
import { startOfYear } from 'date-fns';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import {
  formatFromToDateTitle,
  openPrintWindowWithData,
} from 'poly-client-utils';
import { SupplierSelect, CommonPrintLayout } from 'poly-admin-ui';
import {
  PRINT_PDF_CAPTION,
  SupplierPaymentTransactionType,
} from 'poly-constants';

import { alwaysNewDate } from 'poly-utils';
import {
  ToolBarBtnDivider,
  NumberInputHTML5,
  LinkButton,
  DateRangePicker,
  Select,
} from 'poly-book-admin';
import styled from 'styled-components';

import {
  SubTitleS,
  SupplierNameS,
  HeaderToolbarRow,
  FiltersContainerS,
  SearchHeaderButton,
} from './components.js';
import {
  invoiceStatusSelectOptions,
  searchSupplierInvoicesFilters,
} from './constants.js';
import { ALL } from '../../modules/core/constants/general.js';
import { FlexColumn } from '../../components/FlexContainer.js';
import {
  supplierPaymentHistoryFilterPropType,
  supplierPaymentHistoryTransactionPropType,
} from './propTypes.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { InvoicesSelect } from '../../modules/selects/InvoicesSelect.js';
import { SearchHeaderColumn } from '../../components/SearchHeaderColumn.js';
import { SupplierPaymentHistoryTable } from './SupplierPaymentHistoryTable.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { SupplierPaymentHistoryExportXLSBtn } from './SupplierPaymentHistoryExportXLSBtn.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';

const FlexEndRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`;

export const supplierPaymentHistoryPageHeaderConfig = [
  { name: searchSupplierInvoicesFilters.supplierId },
  { name: searchSupplierInvoicesFilters.invoiceId },
  { name: searchSupplierInvoicesFilters.projectId },
  { name: searchSupplierInvoicesFilters.checkNumber },
  {
    name: searchSupplierInvoicesFilters.invoiceStatus,
    defaultValue: ALL,
  },
  {
    name: searchSupplierInvoicesFilters.invoiceDate,
    nestedFields: ['startDate', 'endDate'],
    defaultValue: {
      startDate: startOfYear(alwaysNewDate()).toDateString(),
      endDate: alwaysNewDate().toDateString(),
    },
  },
  {
    name: searchSupplierInvoicesFilters.supplierTransactionType,
  },
];

function SupplierPaymentHistoryPrintTitle({ title, date, supplierName }) {
  return (
    <>
      <p>{title}</p>
      <SubTitleS>
        {formatFromToDateTitle(date.startDate, date.endDate)}
      </SubTitleS>
      <SupplierNameS>{`Supplier: ${supplierName}`}</SupplierNameS>
    </>
  );
}

SupplierPaymentHistoryPrintTitle.propTypes = {
  title: string.isRequired,
  date: shape({
    startDate: string.isRequired,
    endDate: string.isRequired,
  }).isRequired,
  supplierName: string.isRequired,
};

function SupplierPaymentHistoryPrintBtn({
  transactions,
  filters,
  supplierName,
}) {
  const disabled = R.isEmpty(transactions) || !filters.supplierId;

  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: SupplierPaymentHistoryTable,
      fileName: 'supplier_transaction_history',
      metaData: {
        title: (
          <SupplierPaymentHistoryPrintTitle
            title="Supplier Transaction History"
            date={filters.invoiceDate}
            supplierName={supplierName}
          />
        ),
      },
      transactions,
    });

  return (
    <LinkButton onClick={onClick} disabled={disabled}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

SupplierPaymentHistoryPrintBtn.propTypes = {
  transactions: arrayOf(supplierPaymentHistoryTransactionPropType),
  filters: supplierPaymentHistoryFilterPropType,
  supplierName: string.isRequired,
};

function SupplierTransactionTypeSelect(props) {
  const options = [
    { value: SupplierPaymentTransactionType.PAYMENT, label: 'Payment' },
    {
      value: SupplierPaymentTransactionType.SUPPLIER_INVOICE,
      label: 'Supplier Invoice',
    },
  ];

  return (
    <Select
      name={searchSupplierInvoicesFilters.supplierTransactionType}
      width="100%"
      options={options}
      {...props}
    />
  );
}

export function SupplierPaymentHistoryHeader({
  setFilters,
  exportProps,
  loading,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    supplierPaymentHistoryPageHeaderConfig,
  );

  const onResetFilters = () => {
    onReset();
    setFilters(null);
  };

  const onSearch = () => setFilters(searchFilters);

  const invoiceStatusSelectProps = {
    value: searchFilters.invoiceStatus,
    options: invoiceStatusSelectOptions,
    onChange: handlers.invoiceStatus,
    name: 'invoiceStatusSelect',
    width: '100%',
    required: true,
  };

  return (
    <SearchPageHeaderContainer title="Supplier Payment History">
      <FiltersContainerS>
        <FlexSpaceBetween>
          <FlexColumn>
            <SearchHeaderColumn titleWidth="120px" title="Supplier">
              <SupplierSelect
                value={searchFilters.supplierId}
                onChange={handlers.supplierId}
                withoutError
                isClearable
                required
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn titleWidth="120px" title="Check Number">
              <NumberInputHTML5
                placeholder="Enter Check Number"
                width="100%"
                maxLength={7}
                onChange={handlers.checkNumber}
                value={searchFilters.checkNumber || ''}
                name="checkNumber"
                changeOnBlur
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn titleWidth="120px" title="Date">
              <DateRangePicker
                value={searchFilters.invoiceDate}
                onChange={handlers.invoiceDate}
                width="100%"
              />
            </SearchHeaderColumn>
          </FlexColumn>
          <FlexColumn>
            <SearchHeaderColumn titleWidth="120px" title="Invoice Number">
              <InvoicesSelect
                value={searchFilters.invoiceId}
                onChange={handlers.invoiceId}
                width="100%"
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn titleWidth="120px" title="Project Number">
              <ProjectSelect
                value={searchFilters.projectId}
                onChange={handlers.projectId}
                withoutError
                width="100%"
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn titleWidth="120px" title="Transaction">
              <SupplierTransactionTypeSelect
                value={searchFilters.supplierTransactionType}
                onChange={handlers.supplierTransactionType}
              />
            </SearchHeaderColumn>
          </FlexColumn>
          <FlexColumn>
            <SearchHeaderColumn titleWidth="120px" title="Invoice Status">
              <Select {...invoiceStatusSelectProps} />
            </SearchHeaderColumn>
            <HeaderToolbarRow>
              <SupplierPaymentHistoryPrintBtn {...exportProps} />
              <ToolBarBtnDivider />
              <SupplierPaymentHistoryExportXLSBtn {...exportProps} />
            </HeaderToolbarRow>
            <SearchHeaderColumn filterWidth="360px" position="flex-end">
              <FlexEndRow>
                <SearchHeaderButton
                  size="small"
                  styleType="basicPrimary"
                  onClick={onResetFilters}
                >
                  Reset
                </SearchHeaderButton>
                <SearchHeaderButton
                  size="small"
                  loader={loading}
                  onClick={onSearch}
                  disabled={
                    !searchFilters.supplierId ||
                    searchFilters.supplierId === ALL
                  }
                >
                  Search
                </SearchHeaderButton>
              </FlexEndRow>
            </SearchHeaderColumn>
          </FlexColumn>
        </FlexSpaceBetween>
      </FiltersContainerS>
    </SearchPageHeaderContainer>
  );
}

SupplierPaymentHistoryHeader.propTypes = {
  setFilters: func.isRequired,
  exportProps: shape({
    transactions: arrayOf(supplierPaymentHistoryTransactionPropType),
    filters: supplierPaymentHistoryFilterPropType,
    supplierName: string,
  }),
  loading: bool,
};
