import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { shape, string, arrayOf } from 'prop-types';
import { useMapConfigToTableProps, SidebarRow } from 'poly-admin-ui';
import { Table, Row, Cell, LinkButton } from 'poly-book-admin';
import { NOTHING_UI_STRING } from 'poly-constants';

import { UserGroupVariableCompS } from '../../pages/UserGroupManagement/components.js';
import { useOpenEditUserGroupSidebar } from '../../pages/UserGroupManagement/form/useOpenEditUserGroupSidebar.js';
import { useUserGroupSchemaQuery } from '../../pages/UserGroupManagement/form/useUserGroupSchemaQuery.js';
import { preparePermissionScopes } from './helpers/prepare-permission-scopes.js';

const TableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2) {
    width: 125px;
  }
`;

const permissionsTableConfig = [
  ['Entity', R.identity],
  ['Actions', R.identity],
  ['Scope', R.identity],
];

function CellWithVariableWrapper(props) {
  return (
    <Cell>
      <UserGroupVariableCompS {...props} />
    </Cell>
  );
}

// getEntityWithActionUI :: String -> { getUserGroupPermissionSchema: [UserGroupPermissionSchemaEntry] }
// -> Pair String String
const getEntityWithActionUI = (permission) =>
  R.compose(
    R.juxt([
      R.propOr(NOTHING_UI_STRING, 'name'),
      R.pathOr(NOTHING_UI_STRING, ['actions', 'name']),
    ]),
    R.find(R.pathEq(['actions', 'permission'], permission)),
    R.unwind('actions'),
    R.defaultTo({}),
    R.find(
      R.propSatisfies(
        R.compose(R.includes(permission), R.map(R.prop('permission'))),
        'actions',
      ),
    ),
    R.propOr([], 'getUserGroupPermissionSchema'),
  );

function PermissionsTableBodyRow({ row }) {
  const [showAllScope, setShowAllScope] = useState(false);

  const { schema } = useUserGroupSchemaQuery();

  const [entityUI, actionUI] = getEntityWithActionUI(row?.permission)(schema);

  const [firstPermissionScope, ...tailPermissionScopes] =
    preparePermissionScopes(row);

  return (
    <>
      <Row>
        <Cell>{entityUI}</Cell>
        <Cell>{actionUI}</Cell>

        {firstPermissionScope?.isVariable ? (
          <CellWithVariableWrapper>
            {firstPermissionScope.value}
          </CellWithVariableWrapper>
        ) : (
          <Cell>{firstPermissionScope?.value || NOTHING_UI_STRING}</Cell>
        )}
        {!R.isEmpty(tailPermissionScopes) && (
          <Cell>
            <LinkButton onClick={() => setShowAllScope(!showAllScope)}>
              {showAllScope ? 'Hide' : 'View all'}
            </LinkButton>
          </Cell>
        )}
      </Row>
      {showAllScope &&
        tailPermissionScopes.map((permissionScope) => (
          <Row key={permissionScope.value}>
            <Cell />
            <Cell />
            {permissionScope.isVariable ? (
              <CellWithVariableWrapper>
                {permissionScope.value}
              </CellWithVariableWrapper>
            ) : (
              <Cell>{permissionScope.value}</Cell>
            )}
            <Cell />
          </Row>
        ))}
    </>
  );
}

PermissionsTableBodyRow.propTypes = {
  row: shape({}),
};

export function UserGroupPermissionsTable({ userGroupId, userGroupTypesUI }) {
  const handleEditUserGroup = useOpenEditUserGroupSidebar();

  const tableProps = useMapConfigToTableProps(
    R.identity,
    permissionsTableConfig,
    userGroupTypesUI,
  );

  const onEdit = () => {
    handleEditUserGroup({ userGroupId });
  };

  return (
    <>
      <SidebarRow justify align>
        Permissions
        <LinkButton onClick={onEdit}>Edit</LinkButton>
      </SidebarRow>
      <SidebarRow>
        <TableS {...tableProps} RowComponent={PermissionsTableBodyRow} />
      </SidebarRow>
    </>
  );
}

UserGroupPermissionsTable.propTypes = {
  userGroupId: string.isRequired,
  userGroupTypesUI: arrayOf(shape({})),
};
