import React from 'react';
import { useDispatch } from 'react-redux';
import { string, func } from 'prop-types';
import { AddPeopleForm } from 'poly-admin-ui/src/modules/forms/peopleForm/add/AddPeopleForm.js';
import { useOutSidebarContext } from 'poly-client-utils/src/slideOutSidebar.js';
import { useUpdateQueryParams } from 'poly-client-routing/src/hooks.js';

import { setCreatedContact } from '../../../../redux/contactSidebarReducer.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';

export const addContactFormId = 'add_contact_form_id';

export function AddContactFrom({ onClose, sidebarTab, ...props }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const dispatch = useDispatch();
  const updateQueryParams = useUpdateQueryParams();

  const onCancel = (contactId, isDuplicateContact) => {
    closeOutSidebar(addContactFormId);
    if (isDuplicateContact) {
      updateQueryParams({ sidebarTab });
    } else {
      onClose(contactId);
    }
  };

  const onSuccess = (contactId) => {
    dispatch(setCreatedContact(contactId));
    onCancel(contactId);
  };

  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Add Contact"
      formId={addContactFormId}
      submitButtonCaption="Create"
    >
      <AddPeopleForm {...{ onCancel, onSuccess, ...props }} />
    </SidebarFormLayout>
  );
}

AddContactFrom.propTypes = { onClose: func.isRequired, sidebarTab: string };
