import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useEffect, useMemo, useState, useCallback } from 'react';
import {
  ASC_SORT_ORDER,
  ELASTIC_SCORE_FIELD,
  JournalPaymentStatus,
  JournalQueryTypes,
  JournalTypes,
} from 'poly-constants';
import { useTableInfiniteScrollQuery } from 'poly-client-utils';
import { tryCallFunction, calculateTotal, formatTotal } from 'poly-utils';
import { moneyTransactionCommonFragment } from '../BankRegistersPage/bankRegistersQuery.js';
import { transactionChangedFieldName } from './bankReconciliationUtils.js';

const reconciliationMoneyTransactionsQuery = gql`
  query reconciliationMoneyTransactionsQuery($input: CollectionSearchParams!) {
    searchMoneyTransactions(input: $input) {
      hits {
        ...moneyTransactionCommonFragment
        creditCardUser {
          user {
            _id
            profile {
              fullName
            }
          }
        }
      }
      total
    }
  }

  ${moneyTransactionCommonFragment}
`;

const moneyTransactionSearchQueryByAccountId = (accountId, queryType) => ({
  bool: {
    filter: [
      {
        nested: {
          path: 'lines',
          query: {
            bool: {
              filter: [
                { term: { 'lines.account_id': accountId } },
                {
                  range: {
                    'lines.amount': {
                      [queryType === JournalQueryTypes.BANK_CREDIT
                        ? 'lt'
                        : 'gt']: 0,
                    },
                  },
                },
              ],
              must_not: [
                {
                  term: {
                    'lines.payment_status': JournalPaymentStatus.VOIDED,
                  },
                },
                { exists: { field: 'lines.reconciled_at' } },
              ],
            },
          },
        },
      },
      {
        bool: {
          must_not: {
            term: { type: JournalTypes.ACC_PAYABLE_PAYMENT_VOID },
          },
        },
      },
    ],
  },
});

const fetchMoreOptions = {
  pageSize: 2000,
  endpointName: 'searchMoneyTransactions',
};

const reconciliationTransactionsSort = [
  ELASTIC_SCORE_FIELD,
  { date: { order: ASC_SORT_ORDER } },
  { transaction_number: { order: ASC_SORT_ORDER } },
];

// prepareTableDataByAccountId :: ID -> Object -> [MoneyTransaction]
const prepareTableDataByAccountId = (accountId) =>
  R.compose(
    R.map(
      R.converge(R.mergeRight, [
        R.identity,
        R.compose(
          R.over(R.lensProp('amount'), Math.abs),
          R.defaultTo({}),
          R.find(R.propEq('account_id', accountId)),
          R.propOr([], 'lines'),
        ),
      ]),
    ),
    R.pathOr([], ['searchMoneyTransactions', 'hits']),
  );

// getReconciledTransactionIds :: [MoneyTransaction] -> [ID]
const getReconciledTransactionIds = R.compose(
  R.map(R.prop('_id')),
  R.filter(R.propEq('payment_status', JournalPaymentStatus.RECONCILED)),
);

// calculateAndFormatTransactionsTotal :: [MoneyTransaction] -> String
const calculateAndFormatTransactionsTotal = R.compose(
  formatTotal,
  calculateTotal(R.propOr(0, 'amount')),
);

// markIsTransactionChanged :: ([MoneyTransaction], [String]) -> [MoneyTransaction]
const markIsTransactionChanged = (transactions, numbers) =>
  R.map(
    R.when(
      R.compose(
        R.includes(R.__, numbers),
        R.toString,
        R.prop('transaction_number'),
      ),
      R.compose(R.assoc(transactionChangedFieldName, true)),
    ),
    transactions,
  );

// getSelectedNumbers :: ([String], [String]) -> [String]
const getSelectedNumbers = (newNumbers, oldNumbers) =>
  R.ifElse(
    R.converge(R.gt, [R.always(newNumbers.length), R.length]),
    R.difference(newNumbers),
    R.difference(R.__, newNumbers),
  )(oldNumbers);

function useReconciliationLogicBase({ query, queryType, skip }) {
  const [transactions, setTransactions] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const { accountId, searchTerm } = query;

  const input = useMemo(
    () => ({
      query: moneyTransactionSearchQueryByAccountId(accountId, queryType),
      searchTerm,
      sort: reconciliationTransactionsSort,
    }),
    [accountId, queryType],
  );

  const {
    data: transactionsData,
    loading,
    refetch,
    tableProps,
    allItemsFetched,
  } = useTableInfiniteScrollQuery(reconciliationMoneyTransactionsQuery, input, {
    ...fetchMoreOptions,
    skip,
  });

  const transactionsPrepared = useMemo(
    () => prepareTableDataByAccountId(accountId)(transactionsData),
    [transactionsData],
  );

  useEffect(() => {
    setTransactions(transactionsPrepared);
  }, [transactionsData]);

  const transactionsTotal = useMemo(
    () => calculateAndFormatTransactionsTotal(transactionsPrepared),
    [transactionsPrepared],
  );

  const reconciledTransactions = useMemo(
    () => getReconciledTransactionIds(transactionsPrepared),
    [transactionsPrepared],
  );

  useEffect(() => {
    setSelectedTransactions(reconciledTransactions);
  }, [transactionsData]);

  const setSelectedTransactionsWithMark = (nesSelected) => {
    setTransactions(
      markIsTransactionChanged(
        transactions,
        getSelectedNumbers(nesSelected, selectedTransactions),
      ),
    );
    setSelectedTransactions(nesSelected);
  };

  return {
    transactions,
    setTransactions,
    selectedTransactions,
    setSelectedTransactions: setSelectedTransactionsWithMark,
    loading,
    refetch,
    tableProps,
    transactionsTotal,
    allItemsFetched,
  };
}

export const useReconciliationLogic = (query) => {
  const skip = R.isNil(query.accountId);

  const {
    selectedTransactions: selectedPayments,
    setSelectedTransactions: setSelectedPayments,
    loading: paymentsLoading,
    refetch: refetchPayments,
    tableProps: paymentsTableProps,
    transactionsTotal: paymentsAmount,
    transactions: payments,
    setTransactions: setPayments,
    allItemsFetched: allPaymentsFetched,
  } = useReconciliationLogicBase({
    query,
    queryType: JournalQueryTypes.BANK_CREDIT,
    skip,
  });

  const {
    selectedTransactions: selectedDeposits,
    setSelectedTransactions: setSelectedDeposits,
    loading: depositsLoading,
    refetch: refetchDeposits,
    tableProps: depositsTableProps,
    transactionsTotal: depositsAmount,
    transactions: deposits,
    setTransactions: setDeposits,
    allItemsFetched: allDepositFetched,
  } = useReconciliationLogicBase({
    query,
    queryType: JournalQueryTypes.BANK_DEPOSIT,
    skip,
  });

  // we need to debounce this to get actual data after mutation
  const refetchTablesData = useCallback(() => {
    tryCallFunction(refetchDeposits)();
    tryCallFunction(refetchPayments)();
  }, [refetchDeposits, refetchPayments]);

  return {
    skip,
    payments,
    setPayments,
    paymentsAmount,
    paymentsLoading,
    selectedPayments,
    paymentsTableProps,
    setSelectedPayments,
    deposits,
    setDeposits,
    depositsAmount,
    depositsLoading,
    selectedDeposits,
    depositsTableProps,
    setSelectedDeposits,
    refetchTablesData,
    allPaymentsFetched,
    allDepositFetched,
  };
};
