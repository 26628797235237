import { FieldLayout, Icon, Input, NumberInputHTML5 } from 'poly-book-admin';
import React, { memo } from 'react';
import * as R from 'ramda';
import { MoneyInput } from 'poly-admin-ui';
import { arrayOf, func, number, shape, string } from 'prop-types';

import {
  LabelColumn,
  LineContainer,
  HeaderLineContainer,
  LineTotalAmount,
} from '../common.js';

function ManualEntriesLabel({ length }) {
  return (
    <HeaderLineContainer>
      <LabelColumn width="45%">Description</LabelColumn>
      <LabelColumn width="10%">Qty</LabelColumn>
      <LabelColumn width="20%">Cost Per</LabelColumn>
      <LabelColumn width="15%" align="end">
        Total
      </LabelColumn>
      {length > 1 && <LabelColumn width="15px" />}
    </HeaderLineContainer>
  );
}

ManualEntriesLabel.propTypes = {
  length: number.isRequired,
};

export const ManualEntryField = memo(
  ({ name, index, fields: { value, remove } }) => {
    const { quantity = 0, rate = 0 } = value[index];
    const total = quantity * rate;

    return (
      <>
        {index === 0 && <ManualEntriesLabel length={value.length} />}
        <LineContainer>
          <FieldLayout
            layout={{ width: '45%', padding: 0 }}
            field={{
              name: `${name}.description`,
              Component: Input,
            }}
            validators={[[R.identity, 'Description is required']]}
          />
          <FieldLayout
            layout={{ width: '10%', padding: 0 }}
            field={{
              name: `${name}.quantity`,
              Component: NumberInputHTML5,
            }}
            validators={[[R.identity, 'Quantity is required']]}
          />
          <FieldLayout
            layout={{ width: '20%', padding: 0 }}
            field={{
              name: `${name}.rate`,
              Component: MoneyInput,
            }}
            validators={[[R.identity, 'Cost is required']]}
          />
          <LineTotalAmount width="15%" value={total} />
          {value.length > 1 && (
            <Icon
              name="delete"
              size={12}
              color="red"
              onClick={() => remove(index)}
            />
          )}
        </LineContainer>
      </>
    );
  },
);

ManualEntryField.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    remove: func.isRequired,
  }),
  value: arrayOf(
    shape({
      description: string,
      quantity: number,
      rate: number,
    }),
  ),
};
