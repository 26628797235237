import React from 'react';
import * as R from 'ramda';
import { shape, string, number, oneOfType, object } from 'prop-types';
import { NOTHING_UI_STRING, InvoicesStatuses } from 'poly-constants';
import { InvoicePDFLink } from 'poly-admin-ui';
import { centsToDollarsWithFormat, getUIInvoiceNumber } from 'poly-utils';
import {
  pathOrNothingUI,
  commonSortQuery,
  formatDateProp,
} from 'poly-client-utils';

import { RedSpan } from '../tablesStyles.js';
import { SupplierLink } from '../../../components/Links.js';
import { InvoicePaymentsPopover } from './InvoicePaymentsPopover.js';
import { InvoiceDueDateComponent } from './components/InvoiceDueDateComponent.js';

export function InvoiceFileLink({ invoiceFile, ...invoice }) {
  const invoiceUINumber = getUIInvoiceNumber(invoice);
  return invoiceFile?.url ? (
    <InvoicePDFLink
      invoiceFileLink={invoiceFile.url}
      invoiceNumber={invoiceUINumber}
    />
  ) : (
    invoiceUINumber
  );
}
InvoiceFileLink.propTypes = {
  type: string,
  invoiceNumber: oneOfType([string, object]),
  invoiceFile: shape({ url: string }),
};

export const invoicePdfColumn = [
  'Invoice #',
  InvoiceFileLink,
  commonSortQuery(['invoiceNumber']),
];

export const invoiceProjectColumn = (target, ProjectLink, isPrintPDF) => [
  'Project #',
  R.ifElse(
    R.prop('project'),
    R.pipe(
      R.prop('project'),
      R.mergeRight({ target, isPrintPDF }),
      ProjectLink,
    ),
    R.always(NOTHING_UI_STRING),
  ),
  commonSortQuery(['project', 'projectId']),
];

export const invoiceSupplierColumn = [
  'Supplier',
  R.ifElse(
    R.prop('supplier'),
    R.pipe(
      R.prop('supplier'),
      R.applySpec({
        _id: R.prop('_id'),
        name: R.path(['company', 'name']),
      }),
      SupplierLink,
    ),
    R.always(NOTHING_UI_STRING),
  ),
];

export const invoiceDateColumn = [
  'Inv Date',
  formatDateProp('invoiceDate'),
  commonSortQuery(['invoiceDate']),
];

export const invoiceDueDateColumn = [
  'Due Date',
  InvoiceDueDateComponent,
  commonSortQuery(['paymentDueDate']),
];

function CreditNoteAmount({ total, ...restProps }) {
  return (
    <RedSpan {...restProps}>{`(${centsToDollarsWithFormat(total)})`}</RedSpan>
  );
}

CreditNoteAmount.propTypes = {
  total: number,
};

export const invoiceAmountColumn = [
  'Amount',
  R.ifElse(
    R.propEq('status', InvoicesStatuses.VOIDED),
    R.always(NOTHING_UI_STRING),
    R.ifElse(
      R.propSatisfies(R.gt(0), 'total'),
      CreditNoteAmount,
      R.compose(centsToDollarsWithFormat, R.prop('total')),
    ),
  ),
  commonSortQuery(['total']),
];

export const invoicePaidByColumn = [
  'Paid By',
  pathOrNothingUI(['paidBy', 'fullName']),
  commonSortQuery(['paidByName']),
];

export const invoiceDatePaidColumn = [
  'Date Paid',
  R.ifElse(
    R.propIs(String, 'paidAt'),
    formatDateProp('paidAt'),
    R.always(NOTHING_UI_STRING),
  ),
  commonSortQuery(['paidAt']),
];

export const invoiceStatusToStringMap = {
  [InvoicesStatuses.PENDING]: 'Pending',
  [InvoicesStatuses.PARTIALLY_PAID]: 'Partially Paid',
  [InvoicesStatuses.PAID]: 'Paid',
  [InvoicesStatuses.VOIDED]: 'Voided',
  [InvoicesStatuses.REPORT_ONLY]: 'Report Only',
  [InvoicesStatuses.REQUESTED]: 'Requested',
  [InvoicesStatuses.RECEIVED]: 'Received',
  [InvoicesStatuses.REJECTED]: 'Rejected',
  [InvoicesStatuses.MANUAL_FOLLOW_UP]: 'Manual Follow Up',
};

export const invoiceStatusColumn = [
  'Status',
  ({ status, statusUI, ...props }) =>
    R.includes(status, [
      InvoicesStatuses.PAID,
      InvoicesStatuses.PARTIALLY_PAID,
    ]) ? (
      <InvoicePaymentsPopover
        status={statusUI || invoiceStatusToStringMap[status]}
        {...props}
      />
    ) : (
      statusUI || invoiceStatusToStringMap[status]
    ),
  commonSortQuery(['status']),
];

export const invoiceStatusColumnPDF = [
  'Status',
  ({ status, statusUI }) => statusUI || invoiceStatusToStringMap[status],
];
