import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { SidebarRow } from 'poly-admin-ui';

import { TabSectionWrapper } from '../commonComponents.js';
import { ClientDistrictsTab } from './ClientDistrictsTab.js';

const SidebarRowS = styled(SidebarRow)`
  flex-direction: column;
`;

export function DistrictsSidebarTab(props) {
  const districtsProps = R.applySpec({
    clientId: R.path(['client', '_id']),
    clientName: R.path(['client', 'name']),
    districts: R.pathOr([], ['client', 'districts']),
  })(props);

  return (
    <TabSectionWrapper>
      <SidebarRowS>
        <ClientDistrictsTab {...districtsProps} />
      </SidebarRowS>
    </TabSectionWrapper>
  );
}
