import React, { useState } from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { bool, func, shape, string } from 'prop-types';
import { useReactiveQuery } from 'poly-client-utils';
import { AccountStatuses, SystemAccountTypes } from 'poly-constants';
import { Select } from 'poly-book-admin';
import { filterAccountsBySystemType } from '../pages/ChartOfAccounts/helper.js';

// formatAccountOptionText :: Account -> String
export const formatAccountOptionText = (account) =>
  `${account.name} (${account.code})`;

// toAccountsOptions :: [Account] -> [AccountOption]
// AccountOption = Pair String String
export const toAccountsOptions = (extractValue = R.prop('code')) =>
  R.compose(
    R.map(
      R.applySpec({
        value: extractValue,
        label: formatAccountOptionText,
      }),
    ),
    R.defaultTo([]),
  );

export const GET_ACCOUNTS_QUERY = gql`
  query GET_ACCOUNTS($filters: AccountFilters) {
    getAccounts(filters: $filters) {
      hits {
        _id
        code
        name
        accountType {
          _id
          name
          system_type
          category
        }
        division {
          _id
          name
        }
        is_supplier_payment_deduction
        is_client_payment_deduction
      }
    }
  }
`;

export const ACCOUNT_CHANGED_SUBSCRIPTION = gql`
  subscription ACCOUNT_CHANGED_SUBSCRIPTION($input: CollectionSearchParams) {
    searchAccountChanged(input: $input) {
      id
      type
    }
  }
`;

const defaultFilters = { subAccount: true, status: AccountStatuses.ACTIVE };

export function AccountsSelect({
  direction,
  filterAccounts,
  filters,
  extractValue,
  ...props
}) {
  const [searchText, setSearchText] = useState('');
  const queryOptions = {
    variables: {
      filters: filters || defaultFilters,
    },
  };
  const { data, loading } = useReactiveQuery(
    GET_ACCOUNTS_QUERY,
    ACCOUNT_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: {} },
  );

  const options = R.compose(
    toAccountsOptions(extractValue),
    (accounts) => (filterAccounts ? filterAccounts(accounts) : accounts),
    R.pathOr([], ['getAccounts', 'hits']),
  )(data);

  const selectProps = {
    options,
    loading,
    direction,
    searchText,
    setSearchText,
    name: 'AccountSelect',
    onInputChange: setSearchText,
    ...props,
  };

  return <Select {...selectProps} />;
}

AccountsSelect.propTypes = {
  direction: string,
  filterAccounts: func,
  filters: shape({ subAccount: bool }),
  extractValue: func,
};

export function BankAccountSelect(props) {
  return (
    <AccountsSelect
      {...props}
      filterAccounts={filterAccountsBySystemType(
        SystemAccountTypes.BANK_ACCOUNT,
      )}
      extractValue={R.prop('_id')}
    />
  );
}
