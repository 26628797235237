import * as R from 'ramda';
import {
  formatCurrency,
  insertQueryParamsIntoURL,
  ensureIsDate,
} from 'poly-utils';
import { endOfDay, format, startOfDay } from 'date-fns';

import { routesNames } from '../../routes/index.js';

// formatAccountBalance :: Number -> String
export const formatAccountBalance = R.compose(
  R.replace(/^-(.+)/, '($1)'),
  formatCurrency,
);

const HEADER_DATE_FORMAT = 'dd MMMM yyyy';

// toDateString :: Date -> String
const toDateString = R.invoker(0, 'toDateString');

// generateAccountTransactionsUrl :: GeneralLedgerFilters -> {account: AccountPostgre} -> String
export const generateAccountTransactionsUrl = (filters) =>
  R.compose(
    insertQueryParamsIntoURL(R.__, routesNames.ACCOUNT_TRANSACTIONS),
    R.evolve({
      startDate: R.compose(toDateString, startOfDay, ensureIsDate),
      endDate: R.compose(toDateString, endOfDay, ensureIsDate),
    }),
    R.mergeRight({
      accountingMethod: filters.accountingMethod,
      ...filters.date,
    }),
    R.objOf('accountId'),
    R.path(['account', '_id']),
  );

// convertDateToDateRange :: GeneralLedgerReportFilters -> GeneralLedgerReportFilters
// GeneralLedgerReportFilters = Object
export const convertDateToDateRange = R.when(
  R.prop('date'),
  R.evolve({
    date: {
      startDate: R.compose(startOfDay, R.constructN(1, Date)),
      endDate: R.compose(endOfDay, R.constructN(1, Date)),
    },
  }),
);

// formatDateTitle :: Date -> String
export const formatDateHeader = R.compose(
  (date) => format(date, HEADER_DATE_FORMAT),
  ensureIsDate,
);
