import * as R from 'ramda';
import {
  nullToUndefinedDeep,
  removePropDeep,
  assocBy,
  applySpecWithFields,
  assocByPath,
} from 'poly-utils';

import { prepareAddressDeepBeforeMutate } from '../../../../utils/address.js';

// prepareLinks :: Object -> Array
// eslint-disable-next-line import/no-unused-modules
export const prepareLinks = R.compose(
  R.of,
  R.omit(['_id']),
  ({ name, ...rest }) => assocBy(`${name}Id`, R.prop('_id'), rest),
  R.pick(['_id', 'name']),
);

// modifyLinks :: Object -> [Object]
// eslint-disable-next-line import/no-unused-modules
export const modifyLinks = R.compose(
  ({ links }) => R.map((item) => ({ ...item }), links),
  removePropDeep('__typename'),
  R.objOf('links'),
  R.propOr([], 'links'),
);

// prepareEmails :: Object -> Array
// eslint-disable-next-line import/no-unused-modules
export const prepareEmails = R.compose(
  R.defaultTo([]),
  R.when(R.identity, R.of),
  R.prop('email'),
);

// withCommonActions :: Object -> Object
const withCommonActions = R.compose(
  nullToUndefinedDeep,
  R.omit([
    '_id',
    'name',
    'email',
    'workPhone',
    'mobilePhone',
    'ext',
    'fax',
    'entity',
    'contact',
    'isCreateMode',
  ]),
  assocByPath(['profile', 'faxPhoneNumber'], R.prop('fax')),
  assocByPath(['profile', 'workPhoneNumberExt'], R.prop('ext')),
  assocByPath(['profile', 'workPhoneNumber'], R.prop('workPhone')),
  assocByPath(['profile', 'cellPhoneNumber'], R.prop('mobilePhone')),
  prepareAddressDeepBeforeMutate('profile'),
  assocBy('emails', prepareEmails),
);

// peopleCreateFormToInput :: Object -> Object
export const peopleCreateFormToInput = R.compose(
  R.objOf('input'),
  withCommonActions,
  assocBy('links', prepareLinks),
);

// peopleEditFormToInput :: Object -> Object
export const peopleEditFormToInput = R.compose(
  R.objOf('update'),
  withCommonActions,
  assocBy('links', modifyLinks),
  applySpecWithFields({
    lastName: R.propOr('', 'lastName'),
    title: R.propOr('', 'title'),
    email: R.propOr(null, 'email'),
    mobilePhone: R.propOr('', 'mobilePhone'),
    fax: R.propOr('', 'fax'),
    ext: R.propOr('', 'ext'),
  }),
  R.omit(['isStaff', 'isClientPortalUser']),
);

// entityToLink :: (Object, Boolean) -> Object
// eslint-disable-next-line import/no-unused-modules
export const entityToLink = (entity) => ({
  [`${entity.name}Id`]: entity._id,
});

// prepareDataForAddContact :: (Object, Boolean) -> Contact -> [Link]
// eslint-disable-next-line import/no-unused-modules
export const addEntityToContacts = (entity) =>
  R.compose(
    R.append(entityToLink(entity)),
    R.map(nullToUndefinedDeep),
    R.propOr([], 'links'),
  );

// prepareDataForAddContact :: (Object, Contact) -> Object
export const prepareDataForAddContact = (entity, contact) =>
  R.compose(
    removePropDeep('__typename'),
    R.applySpec({
      update: {
        userId: R.prop('_id'),
        links: addEntityToContacts(entity),
      },
    }),
  )(contact);

// prepareDataForAddContact :: (Contact, Object) -> Boolean
export const isContactExistsInEntity = R.curry((contact, entity) =>
  R.pathSatisfies(
    R.both(
      R.identity,
      R.find((link) => link[`${entity.name}Id`] === entity._id),
    ),
    ['links'],
    contact,
  ),
);

// validateContactExists :: (User, String) -> Errors
const validateContactExists = (contact, entity) =>
  R.cond([
    [R.isNil, R.F],
    [
      isContactExistsInEntity(R.__, entity),
      R.always({
        email: 'Contact with this address already exists in this card',
      }),
    ],
    [
      R.complement(isContactExistsInEntity(R.__, entity)),
      R.always({ email: true }),
    ],
  ])(contact);

// validateFormData :: FormState -> Errors
// FormState = Object
// Errors = Object
export const validateFormData = R.converge(validateContactExists, [
  R.prop('contact'),
  R.prop('entity'),
]);
