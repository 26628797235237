import React from 'react';
import { Select } from 'poly-book-admin';

const countrySelectOptions = [
  { value: '', label: 'All' },
  { value: 'US', label: 'USA' },
];

export function CountrySelect(props) {
  const selectProps = {
    width: '100%',
    isClearable: true,
    name: 'CountrySelect',
    options: countrySelectOptions,
    ...props,
  };

  return <Select {...selectProps} />;
}
