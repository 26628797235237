import * as R from 'ramda';
import { NOTHING_UI_STRING } from 'poly-constants';
import { applyPathOr, assocBy, mapObjectDeep } from 'poly-utils';

import { highlightTextToReactElement } from './strings.js';

const highlightPath = (object, search) => (newObj, path) =>
  R.assocPath(
    path,
    R.pipe(R.path(path), highlightTextToReactElement(search))(newObj),
    newObj,
  );

// highlightMatchesInObject :: Config -> Object -> Object
// Config = { object: Object, paths: [Path], search: String }
export const highlightMatchesInObject = ({ object, paths, search }) =>
  R.reduce(highlightPath(object, search), object, paths);

// highlightMatchesInObject :: [Path] -> Search -> Object -> Object
// Path = [String], Search  = String
export const highlightMatchesInObjectCurried = R.curry(
  (paths, search, object) =>
    highlightMatchesInObject({ object, search, paths }),
);

// pathOrNothingUI :: Path -> Object -> Any
export const pathOrNothingUI = R.curry((path, obj) =>
  R.pipe(
    R.path(path),
    R.when(R.either(R.isNil, R.isEmpty), R.always(NOTHING_UI_STRING)),
  )(obj),
);

// transformByPathOrNothingUI :: [String] -> Transformer -> Object -> String
// Transformer = String => String
export const transformByPathOrNothingUI = R.curry((path, transformer, obj) =>
  R.pipe(
    R.path(path),
    R.ifElse(
      R.either(R.isNil, R.isEmpty),
      R.always(NOTHING_UI_STRING),
      transformer,
    ),
  )(obj),
);

/**
 * duplicateField :: String ->  String -> Object -> Object
 */
export const duplicateField = R.curry((oldField, newField, obj) =>
  assocBy(newField, R.prop(oldField), obj),
);

/**
 * dissocObjFields :: (Object, [FieldPath]) -> Object
 * FieldPath = [String]
 */
const dissocObjFields = (obj, omitFields) =>
  R.reduce((newObj, path) => R.dissocPath(path, newObj), obj, omitFields);

/**
 * equalsOmitFields :: [[String]] -> (Object, Object) -> Boolean
 */
export const equalsOmitFields = (omitFields) => (obj1, obj2) =>
  R.equals(
    dissocObjFields(obj1, omitFields),
    dissocObjFields(obj2, omitFields),
  );

/**
 * undefinedAsNullDeep :: Object -> Object
 */
export const undefinedAsNullDeep = mapObjectDeep(
  R.when(R.isNil, R.always(null)),
);

// stringOrElement :: ReactElement -> String -> ReactElement
export const stringOrElement = (element) =>
  R.when(R.complement(R.is(String)), R.always(element));

// applyPathOrNothingUI :: [String] -> (a -> b) -> a -> b
// b = Any
// a = Object
export const applyPathOrNothingUI = applyPathOr(R.__, R.__, NOTHING_UI_STRING);

// parseFieldToNumberIfExists :: String -> Object -> Object
export const parseFieldToNumberIfExists = (field) =>
  R.over(R.lensProp(field), R.when(R.identity, parseFloat));
