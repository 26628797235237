import React from 'react';
import { func, string } from 'prop-types';
import { TableCard } from 'poly-book-admin';
import { TablePagination } from 'poly-admin-ui';
import { TableWithPaginationContainer } from 'poly-book-admin/src/Card.js';

import { ClientsTable } from '../../tables/clientsTable/ClientsTable.js';

export function ClientsTab({ status, setSort }) {
  return (
    <TableCard>
      <TableWithPaginationContainer {...{ paginationVisible: true }}>
        <ClientsTable {...{ status, setSort }} />
      </TableWithPaginationContainer>
      <TablePagination />
    </TableCard>
  );
}

ClientsTab.displayName = 'ClientsTab';

ClientsTab.propTypes = {
  status: string.isRequired,
  setSort: func.isRequired,
};
