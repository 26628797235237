import * as R from 'ramda';
import { mapIndexed } from 'poly-utils';
import React, { useMemo, useState } from 'react';
import { useTableInfiniteScrollQuery } from 'poly-client-utils';

import { APAgingReportHeader } from './APAgingReportHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { APAgingReportTableContainer } from './APAgingReportTable.js';
import { apAgingReportQuery } from './apAgingReportQuery.js';

// prepareReport :: { apAgingReport: { hits: [APAgingReportSupplier] } } -> APAgingReportSupplier
const prepareReport = R.compose(
  mapIndexed((report, index) => ({
    ...report,
    popoverPosition: index === 0 ? 'left' : 'top',
  })),
  R.pathOr([], ['apAgingReport', 'hits']),
);

export function APAgingReportPage() {
  const [query, setQuery] = useState(null);

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    apAgingReportQuery,
    query,
    {
      endpointName: 'apAgingReport',
      pageSize: 500,
      skip: !query,
    },
  );

  const report = useMemo(() => prepareReport(data), [data]);

  return (
    <PageWithSearchHeader headerHeight="185px">
      <APAgingReportHeader
        exportProps={{ report, query }}
        title="AP Aging Report"
        setQuery={setQuery}
        loading={loading}
      />
      <APAgingReportTableContainer {...{ loading, report, ...tableProps }} />
    </PageWithSearchHeader>
  );
}
