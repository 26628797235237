import { ClientInvoiceStatuses } from 'poly-constants';
import { alwaysNewDate } from 'poly-utils';
import { startOfYear } from 'date-fns';

import { ALL } from '../../modules/core/constants/general.js';

export const customerInvoiceStatusSelectOptions = [
  { value: ClientInvoiceStatuses.PENDING, label: 'Pending' },
  { value: ClientInvoiceStatuses.PAID, label: 'Paid' },
  { value: ClientInvoiceStatuses.PARTIALLY_PAID, label: 'Partially Paid' },
  { value: ALL, label: 'All' },
];

export const searchCustomerInvoicesFilters = {
  clientId: 'clientId',
  projectId: 'projectId',
  invoiceDate: 'invoiceDate',
  startDate: 'startDate',
  endDate: 'endDate',
  invoiceId: 'invoiceId',
  invoiceStatus: 'invoiceStatus',
  accountTypeId: 'accountTypeId',
};

export const defaultFilters = {
  [searchCustomerInvoicesFilters.invoiceStatus]: ALL,
  [searchCustomerInvoicesFilters.startDate]: startOfYear(
    alwaysNewDate(),
  ).toDateString(),
  [searchCustomerInvoicesFilters.endDate]: alwaysNewDate().toDateString(),
};
