import React from 'react';
import { useSelector } from 'react-redux';
import { TableSearchInput } from 'poly-admin-ui';

export function RecentProjectsSearch(props) {
  const { isDisabled } = useSelector((state) => state.recentProjects);

  return <TableSearchInput {...props} disabled={isDisabled} />;
}

RecentProjectsSearch.displayName = 'RecentProjectsSearch';
