import * as R from 'ramda';
import { applySpecWithFields, assocBy } from 'poly-utils';
import { formatAddress } from 'poly-admin-ui';

// prepareAddressObjectToForm :: AddressObject -> AddressForm
export const prepareAddressObjectToForm = R.compose(
  R.dissoc('addressTwo'),
  assocBy(
    'address',
    R.converge(R.mergeRight, [R.prop('address'), R.pick(['addressTwo'])]),
  ),
);

// prepareAddressFormToObject :: AddressForm -> AddressObject
export const prepareAddressFormToObject = R.compose(
  R.dissocPath(['address', 'addressTwo']),
  R.dissoc('accountsReceivableBalance'),
  applySpecWithFields({
    addressTwo: R.pathOr('', ['address', 'addressTwo']),
  }),
);

// getEntityAddressPreview :: Entity -> String
export const getEntityAddressPreview = R.compose(
  R.when(R.is(Array), R.join(', ')),
  formatAddress,
);
