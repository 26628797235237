import React from 'react';
import * as R from 'ramda';
import { func } from 'prop-types';
import { prepareAddressDeepBeforeMutate } from 'poly-admin-ui';
import { parseFieldToNumberIfExists } from 'poly-client-utils';
import { applySpecWithFields, assocBy } from 'poly-utils';
import {
  FinancialEntityTypes,
  SystemAccountTypes,
  JournalPaymentMode,
} from 'poly-constants';

import { halfWidth } from '../../modules/forms/common.js';
import { RadioButtons } from '../../components/RadioButtons.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { geCollectionByType } from '../BankDeposits/bankDepositsUtils.js';
import { onKeyDownToPreventFormSubmit } from '../../utils/form.js';

const commonAccountSelectProps = {
  width: '100%',
  isClearable: true,
};

// getAccountValue :: Account -> { _id: ID, code: String}
const getAccountValue = R.pick(['_id', 'code']);

function BankAccountSelect(props) {
  return (
    <AccountsSelect
      {...props}
      {...commonAccountSelectProps}
      placeholder="Select Bank Account"
      filterAccounts={filterAccountsBySystemType(
        SystemAccountTypes.BANK_ACCOUNT,
      )}
      onKeyDown={onKeyDownToPreventFormSubmit}
      extractValue={getAccountValue}
    />
  );
}

function CreditCardAccountSelect({ changeFieldValue, onChange, ...props }) {
  const handleOnChange = (value) => {
    changeFieldValue('creditCardUserId', null);
    onChange(value);
  };

  return (
    <AccountsSelect
      {...props}
      {...commonAccountSelectProps}
      placeholder="Select Credit Card Account"
      filterAccounts={filterAccountsBySystemType(
        SystemAccountTypes.CREDIT_CARD,
      )}
      onKeyDown={onKeyDownToPreventFormSubmit}
      extractValue={getAccountValue}
      onChange={handleOnChange}
    />
  );
}

CreditCardAccountSelect.propTypes = {
  onChange: func,
  changeFieldValue: func,
};

function BankPaymentModeComp({ onChange, ...props }) {
  const bankPaymentModeOptions = [
    { value: JournalPaymentMode.CHECK, label: 'Check' },
    { value: JournalPaymentMode.ACH, label: 'ACH' },
    { value: JournalPaymentMode.WIRE, label: 'Wire' },
  ];
  const onModeChange = (mode) => () => onChange(mode);

  return (
    <RadioButtons
      {...props}
      onChange={onModeChange}
      options={bankPaymentModeOptions}
    />
  );
}

BankPaymentModeComp.propTypes = { onChange: func.isRequired };

export const getAccountSelectFormSection = (isBankForm) => {
  const label = isBankForm ? 'Bank Account' : 'Credit Card Account';
  const error = `${label} is required`;

  return [
    {
      label,
      order: 1,
      layout: { row: 1, width: halfWidth },
      field: {
        name: 'bankAccount',
        withChangeFieldValue: true,
        Component: isBankForm ? BankAccountSelect : CreditCardAccountSelect,
      },
      validators: [[R.identity, error]],
      required: true,
    },
  ];
};

export const getPaymentModeFormSection = (isBankForm) => [
  {
    label: 'Payment mode',
    order: 3,
    layout: { row: 2 },
    field: {
      name: isBankForm ? 'paymentMode' : '',
      Component: BankPaymentModeComp,
    },
    renderIf: () => isBankForm,
  },
];

// preparePayByFormDataForMutation :: FormData -> CustomJournalInput
export const preparePayByFormDataForMutation = R.compose(
  prepareAddressDeepBeforeMutate('payeeAddress'),
  R.pick([
    'date',
    'glAccountId',
    'projectId',
    'netAmount',
    'payeeName',
    'documentId',
    'collection',
    'paymentMode',
    'description',
    'checkNumber',
    'payeeAddress',
    'bankAccountId',
    'creditCardUserId',
    'receiptFile',
    'invoiceNumber',
  ]),
  R.unless(
    R.propSatisfies(R.either(R.isEmpty, R.isNil), 'receiptFiles'),
    assocBy(
      'receiptFile',
      R.compose(R.pick(['fileName', 'upload']), R.head, R.prop('receiptFiles')),
    ),
  ),
  R.when(
    R.propSatisfies(
      R.complement(R.equals(JournalPaymentMode.CHECK)),
      'paymentMode',
    ),
    R.omit(['checkNumber']),
  ),
  R.when(
    R.propEq('type', FinancialEntityTypes.OTHERS),
    R.converge(R.mergeDeepLeft, [
      R.applySpec({ payeeAddress: R.pick(['address']) }),
      R.omit(['documentId', 'collection']),
    ]),
  ),
  parseFieldToNumberIfExists('checkNumber'),
  applySpecWithFields({
    glAccountId: R.prop('glAccount'),
    collection: geCollectionByType,
    bankAccountId: R.path(['bankAccount', '_id']),
  }),
);
