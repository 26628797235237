import { entities } from 'poly-admin-ui';

import {
  PROJECT_ASSETS_SUB,
  RECURRING_PROJECT_ASSETS_SUB,
} from '../../components/commonTabs/useSearchAssetsTabQuery.js';

const subscriptionQueryMap = {
  [entities.RECURRING_PROJECT]: RECURRING_PROJECT_ASSETS_SUB,
  [entities.PROJECT]: PROJECT_ASSETS_SUB,
};

export const useAssetsSelectEntitySubscription = ({ _id, name }) => {
  const projectSubQuery = subscriptionQueryMap[name] || PROJECT_ASSETS_SUB;

  const projectSubOptions = {
    variables: { input: { query: { match: { _id } } } },
    skip: !_id,
  };

  return { projectSubQuery, projectSubOptions };
};
