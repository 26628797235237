import { defaultTheme } from 'poly-book-admin/src/ThemeProvider/index.js';
import {
  aminPurchaseOrderStatuses,
  adminPurchaseOrderTypes,
  ELASTIC_SCORE_FIELD,
  DESC_SORT_ORDER,
  WorkOrderStatus,
  ASC_SORT_ORDER,
} from 'poly-constants';

export const addPurchaseOrderFormId = 'add_purchase_order_form_id';
export const editPurchaseOrderFormId = 'edit_purchase_order_form_id';
export const purchaseOrderTabsSidebarId = 'purchase_order_tabs_sidebar_id';

const {
  colors: {
    statuses: { active, blocked, pending },
    projectStatuses: { inactive },
  },
} = defaultTheme;

export const purchaseOrderStatusColors = {
  [aminPurchaseOrderStatuses.ACTIVE]: active,
  [aminPurchaseOrderStatuses.BLOCKED]: blocked,
  [aminPurchaseOrderStatuses.COMPLETED]: inactive,
  [aminPurchaseOrderStatuses.PENDING]: pending,
};

export const purchaseOrderTypesUI = {
  [adminPurchaseOrderTypes.STANDARD]: 'Standard Purchase Order',
  [adminPurchaseOrderTypes.PLANNED]: 'Planned Purchase Order',
  [adminPurchaseOrderTypes.BLANKED]: 'Blanket Purchase Order',
  [adminPurchaseOrderTypes.CONTRACT]: 'Contract Purchase Order',
};

export const purchaseOrderStatusUI = {
  [aminPurchaseOrderStatuses.ACTIVE]: 'active',
  [aminPurchaseOrderStatuses.BLOCKED]: 'blocked',
  [aminPurchaseOrderStatuses.COMPLETED]: 'completed',
  [aminPurchaseOrderStatuses.PENDING]: 'pending',
};

export const purchaseOrderTabsSidebarWidth = 560;

export const defaultPOProjectsSort = [
  ELASTIC_SCORE_FIELD,
  {
    _script: {
      type: 'number',
      script: {
        source:
          'params.statusOrder.containsKey(doc.status.value) ? params.statusOrder[doc.status.value] : Integer.MAX_VALUE',
        lang: 'painless',
        params: {
          statusOrder: {
            [WorkOrderStatus.ACTIVE]: 1,
            [WorkOrderStatus.ON_HOLD_PROJECT]: 1,
            [WorkOrderStatus.ON_HOLD_CLIENT]: 1,
            [WorkOrderStatus.ON_HOLD_PARTS]: 1,
          },
        },
      },
      order: DESC_SORT_ORDER,
    },
  },
  { clientInvoicedDate: { order: ASC_SORT_ORDER } },
  { createdAt: { order: ASC_SORT_ORDER } },
];
