import React from 'react';
import { bool, number, shape, string } from 'prop-types';
import { CardsWrapper, SectionsWrapper, SideSection } from 'poly-book-admin';
import { useHasUserAccessToSupplierPrivateFields } from 'poly-client-utils';
import { supplierTypes } from 'poly-constants';
import {
  SupplierDocumentsInfoSection,
  SupplierBankACHInfoSection,
  SupplierOfficeHoursSection,
  supplierAccountPropsTypes,
  SupplierRatesInfoSection,
  SupplierRemitInfoSection,
  RemarksSection,
} from 'poly-admin-ui';

import { FinancialSection } from './sections/FinancialSection.js';
import { GeneralInfoSection } from './sections/GeneralInfoSection.js';
import { useOpenSubcontractorAgreementSidebar } from '../../../sidebars/SubcontractorAgreementSidebar/useOpenSubcontractorAgreementSidebar.js';
import { PinCodeAccessSection } from './sections/PinCodeAccessSection.js';

export function SupplierAccountTabComp({
  type,
  mwbe,
  remarks,
  bankACH,
  refetch,
  remitInfo,
  ratesInfo,
  generalInfo,
  financialInfo,
  documentsInfo,
}) {
  const { hasUserTaxPrivateFieldsAccess, hasUserBankPrivateFieldsAccess } =
    useHasUserAccessToSupplierPrivateFields();

  const openSubcontractorAgreement = useOpenSubcontractorAgreementSidebar();

  const openSubcontractorAgreementSidebar = () =>
    openSubcontractorAgreement({ ...documentsInfo, company: generalInfo });

  return (
    <CardsWrapper>
      <SectionsWrapper>
        <SideSection>
          <GeneralInfoSection {...{ type, mwbe, ...generalInfo }} />
          <PinCodeAccessSection {...generalInfo} />
          <SupplierOfficeHoursSection {...generalInfo} />
          {type === supplierTypes.SUBCONTRACTOR && (
            <SupplierRatesInfoSection {...ratesInfo} />
          )}
        </SideSection>
        <SideSection>
          <RemarksSection {...{ remarks }} />
          <SupplierRemitInfoSection {...remitInfo} />
          <FinancialSection
            {...financialInfo}
            supplierName={generalInfo.name}
            hasUserTaxPrivateFieldsAccess={hasUserTaxPrivateFieldsAccess}
          />
          <SupplierBankACHInfoSection
            {...bankACH}
            hasUserBankPrivateFieldsAccess={hasUserBankPrivateFieldsAccess}
          />
          {type === supplierTypes.SUBCONTRACTOR && (
            <SupplierDocumentsInfoSection
              {...{ ...mwbe, ...generalInfo, ...documentsInfo }}
              refetch={refetch}
              openSubcontractorAgreementSidebar={
                openSubcontractorAgreementSidebar
              }
            />
          )}
        </SideSection>
      </SectionsWrapper>
    </CardsWrapper>
  );
}

SupplierAccountTabComp.propTypes = {
  type: string.isRequired,
  generalInfo: shape(supplierAccountPropsTypes.generalInfoPropTypes).isRequired,
  ...supplierAccountPropsTypes.remarksPropTypes,
  financialInfo: shape(supplierAccountPropsTypes.financialPropTypes).isRequired,
  bankACH: shape(supplierAccountPropsTypes.bankACHPropTypes).isRequired,
  ratesInfo: shape({
    hourly: number,
    overTime: number,
    weekend: number,
    trip: number,
  }).isRequired,
  mwbe: shape({
    minority: string,
    isWBE: bool,
  }).isRequired,
};
