import { useMutation, gql } from '@apollo/client';
import { useOutSidebarContext } from 'poly-client-utils';
import { useModalContext, useNotificationState } from 'poly-admin-ui';
import { editClientFormId } from '../../constants.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';
import { formDataToEditClientMutation } from './formDataToMutation.js';

const UPDATE_CLIENT_MUTATION = gql`
  mutation UPDATE_CLIENT_MUTATION($clientId: ID!, $update: UpdateClientInput!) {
    updateClient(id: $clientId, update: $update) {
      client {
        _id
      }
    }
  }
`;

export const useOnSubmitEditClientForm = (isCard, cleanupRetainedValue) => {
  const { openClientSidebar } = useSidebarLogicContext();
  const [editClient] = useMutation(UPDATE_CLIENT_MUTATION);
  const { showSuccessNotification } = useNotificationState();
  const { closeOutSidebar } = useOutSidebarContext();
  const { openConfirmModal } = useModalContext();

  return async ({ client, update }) => {
    const clientData = formDataToEditClientMutation(client, update);

    const mutateClient = async (changePropertiesStatus) => {
      await editClient({
        variables: {
          clientId: client._id,
          update: {
            ...clientData,
            ...(changePropertiesStatus ? { changePropertiesStatus: true } : {}),
          },
        },
      });

      showSuccessNotification('The client was successfully edited');
      cleanupRetainedValue();
      closeOutSidebar(editClientFormId);

      if (!isCard) {
        openClientSidebar(client._id);
      }
    };

    const confirmModalProps = {
      cancelBtnCaption: 'No',
      btnCaption: 'Yes',
      id: 'confirmEditClientPropertiesFormId',
      content: `Do you want to set properties to ${update?.status}?`,
      withCancel: true,
    };

    if (client?.status !== update?.status) {
      openConfirmModal({
        ...confirmModalProps,
        onConfirm: (closeConfirmModal) => async () => {
          await mutateClient(true);
          closeConfirmModal();
        },
        onReject: async () => {
          await mutateClient();
        },
      });

      return;
    }

    await mutateClient();
  };
};
