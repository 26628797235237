import * as R from 'ramda';
import React, { useState } from 'react';
import { validateEmail } from 'poly-utils';
import { arrayOf, func, oneOfType, shape, string } from 'prop-types';

import { MultiSelectDropDown } from './MultiSelectDropDown.js';

// prepareEmailsValue :: [Option] -> [Option]
const prepareEmailsValue = R.compose(
  R.filter(R.propSatisfies(validateEmail, 'value')),
  R.defaultTo([]),
);

export function MultiEmailsInput({ value, onChange, ...props }) {
  const [email, setEmail] = useState('');

  const options = email ? [{ label: email, value: email }] : [];

  return (
    <MultiSelectDropDown
      options={prepareEmailsValue(options)}
      onInputChange={setEmail}
      value={prepareEmailsValue(value)}
      handleChange={onChange}
      placeholder="Start typing emails"
      {...props}
    />
  );
}

MultiEmailsInput.propTypes = {
  value: oneOfType([
    arrayOf(
      shape({
        value: string,
        label: string,
      }),
    ),
    string,
  ]),
  onChange: func.isRequired,
};
