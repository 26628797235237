import React from 'react';
import { string } from 'prop-types';
import { filesCollections } from 'poly-constants';
import { SidebarFilesTab } from 'poly-admin-ui';

export function AssetSidebarFilesTab({ assetId }) {
  const tabProps = {
    collection: filesCollections.ASSETS,
    entity: { _id: assetId, preview: 'asset' },
  };

  return <SidebarFilesTab {...tabProps} />;
}

AssetSidebarFilesTab.propTypes = { assetId: string.isRequired };
