import * as R from 'ramda';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNilOrEmpty } from 'poly-utils';

import { setPagination } from '../redux/pagination.js';

export const useSetItemsCount = (getItemsCount, data) => {
  const dispatch = useDispatch();
  const pagination = useSelector(R.prop('pagination'));

  const isDataPresent = R.complement(isNilOrEmpty)(data);

  useEffect(() => {
    const { allItemsCount } = pagination;
    if (isDataPresent && allItemsCount !== getItemsCount(data)) {
      dispatch(
        setPagination({
          ...pagination,
          allItemsCount: getItemsCount(data),
        }),
      );
    }
  }, [data]);
};
