import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape, bool } from 'prop-types';
import { pathOrNothingUI } from 'poly-client-utils';
import { entities, SidebarRow } from 'poly-admin-ui';
import {
  RecurringProjectTypes,
  NOTHING_UI_STRING,
  ProjectType,
} from 'poly-constants';
import { gql, useMutation } from '@apollo/client';

import { forceTitleCase } from 'poly-utils';
import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapperWithoutBorder,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import { ClonePMProjectButton } from './ClonePMProjectButton.js';
import { GenerateChildPMsButton } from './GeneratePMChildProjectsButton.js';
import { CloneMasterRecurringProjectButton } from './CloneMasterRecurringProjectButton.js';
import { getRecurringSummary } from '../../components/RecurringScheduler/recurringSchedulerUtils.js';
import {
  getSectionSidebarLink,
  getSectionOutsideLink,
  getSectionEditor,
  getSectionText,
} from '../components/commonSidebarSectionFormatters.js';
import { useSidebarLogicContext } from '../SidebarLogicContext.js';
import { ConvertProjectButton } from '../ProjectSidebar/components/ConvertProjectButton.js';

const HalfRowWrapper = styled.div`
  display: flex;
  width: 50%;
`;

// getMRProjectSidebarPlanningInfo :: Project -> Object
const getMRProjectSidebarPlanningInfo = R.applySpec({
  isFee: R.propEq('childType', ProjectType.FEE),
  isPMProject: R.propEq(
    'type',
    RecurringProjectTypes.preventiveMaintenanceProject,
  ),
  repeats: R.compose(
    R.ifElse(
      R.isNil,
      R.always(NOTHING_UI_STRING),
      R.compose(forceTitleCase, R.prop('repeats')),
    ),
    R.prop('schedule'),
  ),
  repeatsEvery: R.ifElse(
    R.propSatisfies(R.isNil, 'schedule'),
    R.always(NOTHING_UI_STRING),
    getRecurringSummary,
  ),
  showChildGenerationBtn: R.both(
    R.prop('disableChildrenGeneration'),
    R.pathSatisfies(R.gt(R.__, 0), ['searchAssets', 'total']),
  ),
});

const convertMasterProjectTypeMutation = gql`
  mutation convertMasterProjectTypeMutation($input: ChangeProjectTypeInput!) {
    changeRecurringProjectType(input: $input) {
      _id
      projectId
    }
  }
`;

function ConvertMasterProjectButton({ project, isCard }) {
  const [mutate, { loading }] = useMutation(convertMasterProjectTypeMutation);
  return (
    <ConvertProjectButton
      mutate={mutate}
      loading={loading}
      type={project.childType}
      projectId={project._id}
      masterProject={project}
      isCard={isCard}
    />
  );
}

ConvertMasterProjectButton.propTypes = {
  isCard: bool,
  project: shape({
    _id: string.isRequired,
    childType: string.isRequired,
  }),
};

export function MasterRecurringProjectSidebarPlanning({ project, isCard }) {
  const { isFee, repeats, repeatsEvery, isPMProject, showChildGenerationBtn } =
    getMRProjectSidebarPlanningInfo(project);

  const { ProjectLink } = useSidebarLogicContext();

  return (
    <SectionWrapperWithoutBorder>
      <SidebarRow>
        <SectionLabel>Planning</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          margin="0"
          id={`invoice-description${isCard ? '-card' : ''}`}
          label="Invoice Description"
          Component={getSectionEditor(
            pathOrNothingUI(['invoiceDescription'])(project),
          )}
        />
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          {...threeBlocksProps}
          id="repeats"
          label="Repeats"
          Component={getSectionText(repeats)}
        />
        <BlockWithLabel
          {...threeBlocksProps}
          id="repeats-every"
          label="Repeats Every"
          Component={getSectionText(repeatsEvery)}
        />
      </SidebarRow>
      {!isFee && (
        <SidebarRow>
          <BlockWithLabel
            width="100%"
            id="contract"
            label="Contract"
            Component={getSectionOutsideLink(project.contractLink)}
          />
        </SidebarRow>
      )}
      {(!!project.parentClone || !!project.cloneFromProject) && (
        <SidebarRow>
          <BlockWithLabel
            id="reference-project"
            label="Reference Project"
            Component={getSectionSidebarLink(
              project.parentClone || project.cloneFromProject,
              ProjectLink,
            )}
          />
        </SidebarRow>
      )}
      <SidebarRow>
        <HalfRowWrapper>
          {isPMProject ? (
            <ClonePMProjectButton
              title="Clone Project"
              entity={{
                _id: project._id,
                projectId: project.projectId,
                name: entities.RECURRING_PROJECT,
              }}
            />
          ) : (
            <CloneMasterRecurringProjectButton {...project} />
          )}
        </HalfRowWrapper>
        {showChildGenerationBtn && <GenerateChildPMsButton {...project} />}
      </SidebarRow>
      <ConvertMasterProjectButton project={project} isCard={isCard} />
    </SectionWrapperWithoutBorder>
  );
}

MasterRecurringProjectSidebarPlanning.propTypes = {
  project: shape({ _id: string.isRequired }),
  isCard: bool,
};
