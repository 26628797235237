import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { func, string, shape, bool } from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import {
  useRouterQuery,
  useUpdateQueryParams,
  useLocation,
} from 'poly-client-routing';

import { useModalContext } from '../Modal/ModalProvider.js';
import { CancelBtn, confirmCancelFormPayload, SubmitBtn } from '../common.js';
import { ButtonsContainer, EditBtn } from './Button.js';

function EnableEditRouteTextButton({
  tab,
  onClick,
  permission,
  additionalQueryParams,
}) {
  const ifHasPermission = useHasUserAccessWithPermission(permission);

  const updateQueryParams = useUpdateQueryParams();

  const onClickHandler = () => {
    if (typeof onClick === 'function') {
      return onClick();
    }
    return updateQueryParams({
      isEdit: 'true',
      tab,
      ...(additionalQueryParams || {}),
    });
  };

  return ifHasPermission ? <EditBtn onClick={onClickHandler} /> : null;
}

EnableEditRouteTextButton.propTypes = {
  tab: string,
  onClick: func,
  permission: string,
  additionalQueryParams: shape({ isBranch: bool }),
};

function CancelEditRouteTextBtn() {
  const updateQueryParams = useUpdateQueryParams();
  const { state } = useLocation();
  const { openConfirmModal: openConformModalContext } = useModalContext();

  const onClick = () => {
    if (state?.pristine === false) {
      openConformModalContext({
        ...confirmCancelFormPayload,
        onConfirm: (closeConfirmModal) => () => {
          closeConfirmModal();
          updateQueryParams({ isEdit: false });
        },
      });
      return;
    }

    updateQueryParams({ isEdit: false });
  };
  return <CancelBtn onClick={onClick} />;
}

function SaveTextBtn({ formId, processName }) {
  const isProcess = useSelector(R.path(['processes', processName]));

  const buttonProps = {
    form: formId,
    children: 'Save Changes',
    disabled: isProcess,
    loader: isProcess,
  };

  return <SubmitBtn {...buttonProps} />;
}

SaveTextBtn.propTypes = {
  formId: string.isRequired,
  processName: string.isRequired,
};

function EditInProgressButtons({ formId, processName }) {
  return (
    <ButtonsContainer>
      <SaveTextBtn formId={formId} processName={processName} />
      <CancelEditRouteTextBtn />
    </ButtonsContainer>
  );
}

EditInProgressButtons.propTypes = {
  formId: string.isRequired,
  processName: string.isRequired,
};

export function ActionButtons({
  tab,
  formId,
  onClick,
  permission,
  processName,
  additionalQueryParams,
}) {
  const { isEdit } = useRouterQuery(['isEdit']);

  if (isEdit) {
    return (
      <EditInProgressButtons
        formId={formId}
        processName={processName || formId}
      />
    );
  }

  return (
    <EnableEditRouteTextButton
      tab={tab}
      onClick={onClick}
      permission={permission}
      additionalQueryParams={additionalQueryParams}
    />
  );
}

ActionButtons.propTypes = {
  tab: string,
  onClick: func,
  permission: string,
  processName: string,
  formId: string.isRequired,
  additionalQueryParams: shape({ isBranch: bool }),
};
