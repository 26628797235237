import * as R from 'ramda';
import React from 'react';
import { Text } from 'poly-book-admin';
import { shape } from 'prop-types';
import { alwaysNewDate } from 'poly-utils';

import { PrintProjectFlexRow, PrintProjectFlexColumn } from '../components.js';

// getBase64ClientInvoices :: Project -> [String]
const getBase64ClientInvoices = R.propOr([], 'clientInvoicesBase64Strings');

// getClientInvoiceIdByIndex :: Int -> Project -> ID
const getClientInvoiceIdByIndex = (index) =>
  R.pathOr(alwaysNewDate().getTime(), ['clientInvoices', index, '_id']);

export function PrintProjectClientInvoices({ project }) {
  const base64ClientInvoices = getBase64ClientInvoices(project);

  if (R.isEmpty(base64ClientInvoices)) {
    return null;
  }

  return (
    <PrintProjectFlexColumn>
      <PrintProjectFlexRow>
        <Text size="16px" weight="bold">
          Client Invoices
        </Text>
      </PrintProjectFlexRow>
      {base64ClientInvoices.map((base64Invoice, index) => {
        const clientInvoiceId = getClientInvoiceIdByIndex(index)(project);

        return (
          <img
            key={`${project._id}-${clientInvoiceId}-key`}
            alt=""
            src={`data:image/png;base64,${base64Invoice}`}
          />
        );
      })}
    </PrintProjectFlexColumn>
  );
}

PrintProjectClientInvoices.propTypes = {
  project: shape({}),
};
