import { gql } from '@apollo/client';
import { useReactiveQuery } from 'poly-client-utils';

import { commonAddressFields } from '../../../hocs/commonFragments.js';

export const masterSupplierDetailsFields = gql`
  fragment masterSupplierDetailsFields on MasterSupplier {
    _id
    type
    status
    cardNumber
    openingInvoicesTotal
    supplierSource
    isVeteran
    isPrivate
    service_24_7
    officeHours {
      day
      isActive
      startTime
      endTime
    }
    company {
      name
      dba
      address {
        ...commonAddressFields
      }
      addressTwo
      phones {
        type
        phone
        legacyFormat
      }
      fax
      emails {
        type
        email
      }
      services {
        _id
        name
      }
      website
    }
    mwbe {
      isWBE
      minority
    }
    foodBuy {
      isEnabled
      sapVendorNumber
      vendorType
      vaPercentage
      termSet
      vaEmail
    }
    rating {
      stars
      average
    }
    remarks
    finance {
      terms
      acceptsCreditCard
    }
    tax {
      id
      name
      is1099
      w9File {
        fileName
        url
      }
      classification
      socialSecurityNumber
      address {
        ...commonAddressFields
      }
      addressTwo
      exempt
    }
    bank {
      routingNumber
      accountNumber
      remittanceEmails
      payByACH
    }
    rates {
      hasEmergency
      hourly
      overTime
      trip
      emergency
      weekend
    }
    documents {
      exemptDocumentsRequest
      wcExp
      glExp
      wcFile {
        fileName
        fileType
        url
      }

      glFile {
        fileName
        fileType
        url
      }

      scaFile {
        fileName
        fileType
        url
      }

      sqqFile {
        fileName
        fileType
        url
      }

      subcontractorAgreement {
        title
        contactName
        ip
        downloadedAt
        date
        version
      }

      mbeCertificationFile {
        fileName
        fileType
        url
      }
      wbeCertificationFile {
        fileName
        fileType
        url
      }
      veteranCertificationFile {
        fileName
        fileType
        url
      }
    }
    remit {
      name
      address {
        ...commonAddressFields
      }
      addressTwo
    }
  }

  ${commonAddressFields}
`;

const MASTER_SUPPLIER_DETAILS = gql`
  query MASTER_SUPPLIER_DETAILS($supplierId: ID!) {
    masterSupplier(id: $supplierId) {
      ...masterSupplierDetailsFields
    }
  }

  ${masterSupplierDetailsFields}
`;

export const MASTER_SUPPLIER_DETAILS_SUB = gql`
  subscription MASTER_SUPPLIER_DETAILS_SUB($input: SingleDocSubInput!) {
    masterSupplierChanged(input: $input) {
      id
      document {
        ...masterSupplierDetailsFields
      }
    }
  }

  ${masterSupplierDetailsFields}
`;

export const useMasterSupplierDetailsQuery = (supplierId, fetchPolicy) => {
  const queryOptions = {
    variables: { supplierId },
    ...(!!fetchPolicy && { fetchPolicy }),
  };
  const subscriptionOptions = {
    variables: { input: { id: supplierId } },
    ...(!!fetchPolicy && { fetchPolicy }),
  };

  const { data, loading } = useReactiveQuery(
    MASTER_SUPPLIER_DETAILS,
    MASTER_SUPPLIER_DETAILS_SUB,
    { queryOptions, subscriptionOptions },
  );

  return { data, loading };
};
