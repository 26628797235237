import * as R from 'ramda';
import { v4 as uuidV4 } from 'uuid';
import styled from 'styled-components';
import React, { useState, useMemo } from 'react';
import { useMutation, gql } from '@apollo/client';
import { shape, string, arrayOf, func } from 'prop-types';
import { Button, getThemeColor, LinkButton } from 'poly-book-admin';
import { useNotificationState } from 'poly-admin-ui';
import { isNilOrEmpty } from 'poly-utils';

import { Tree } from '../../../../components/Tree/Tree.js';
import { TreeNode } from '../../../../components/Tree/TreeNode/TreeNode.js';
import { useTreeLogic } from '../../../../components/Tree/useTreeLogic.js';
import {
  isSingleNodeTree,
  flattenTreeBase,
  buildTreeBase,
} from '../../../../components/Tree/utils.js';

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TreeContainer = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 15px 24px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${getThemeColor(['midLight'])};
`;

// buildLocationNode :: PropertyLocationNode -> TreeNode
const buildLocationNode = R.applySpec({
  id: R.prop('id'),
  title: R.prop('name'),
  expanded: R.T,
});

// buildLocationTreeBase :: [PropertyLocationNode] -> [TreeNode]
const buildLocationTreeBase = buildTreeBase(buildLocationNode);

// buildLocationTree :: PropertyLocationHierarchy -> [TreeNode]
const buildLocationTree = R.compose(
  buildLocationTreeBase,
  R.propOr([], 'nodes'),
);

// flattenTreeNode :: (TreeNode, String) -> PropertyLocationNodeInput
const flattenTreeNode = (node, parentId) => ({
  id: node.id,
  name: node.title,
  parentId,
});

// flattenPropertyLocationTree :: [TreeNode] -> [PropertyLocationNodeInput]
const flattenPropertyLocationTree = flattenTreeBase(flattenTreeNode);

const getInitialNodeState = () => ({
  id: uuidV4(),
  title: '',
  expanded: true,
});

const updatePropertyLocationHierarchyMutation = gql`
  mutation updatePropertyLocationHierarchyMutation(
    $input: UpdatePropertyLocationHierarchyInput!
  ) {
    updatePropertyLocationHierarchy(input: $input) {
      _id
    }
  }
`;

function PropertyLocationNode(props) {
  return (
    <TreeNode
      isEditable={R.T}
      isAddBtnHidden={R.F}
      isDisabled={R.F}
      isRemoveBtnHidden={R.F}
      isRemoveButtonDisabled={R.F}
      {...props}
    />
  );
}

// isEmptyHierarchy :: PropertyLocationHierarchy -> Boolean
const isEmptyHierarchy = R.propSatisfies(isNilOrEmpty, 'nodes');

export function PropertyLocationTree({
  propertyId,
  locationHierarchy,
  refetch,
}) {
  const { showSuccessNotification } = useNotificationState();
  const [isEdited, setIsEdited] = useState(false);
  const [updatePropertyLocation, { loading }] = useMutation(
    updatePropertyLocationHierarchyMutation,
  );

  const initialTreeData = useMemo(
    () => buildLocationTree(locationHierarchy),
    [locationHierarchy],
  );

  const {
    addNode,
    treeData,
    expandTree,
    setTreeData,
    validateTree,
    validationErrors,
    onAddNodeHandler,
    onRemoveNodeHandler,
    onChangeNodeTitleHandler,
  } = useTreeLogic(initialTreeData, getInitialNodeState);

  const onSubmit = async () => {
    if (validateTree(treeData)) {
      await updatePropertyLocation({
        variables: {
          input: {
            propertyId,
            nodes: flattenPropertyLocationTree(treeData),
          },
        },
      });
      showSuccessNotification('Location hierarchy was saved successfully');
      setIsEdited(true);
      refetch();
    }
    expandTree();
  };

  const singleNodeTree = isSingleNodeTree(treeData);
  const isDisabled =
    treeData.length === 0 &&
    (!locationHierarchy || (isEmptyHierarchy(locationHierarchy) && !isEdited));

  return (
    <TabContainer>
      <TreeContainer>
        <Tree
          dndType="property-location-hierarchy"
          nodeContentRenderer={PropertyLocationNode}
          rowHeight={40}
          canDrop={R.T}
          treeData={treeData}
          validationErrors={validationErrors}
          setTreeData={setTreeData}
          onChangeNodeTitleHandler={onChangeNodeTitleHandler}
          onAddNodeHandler={onAddNodeHandler}
          onRemoveNodeHandler={onRemoveNodeHandler}
          hideRootLine={singleNodeTree}
        />
      </TreeContainer>
      <ButtonsContainer>
        <LinkButton onClick={addNode}>Add Building</LinkButton>
        <Button
          size="small"
          onClick={onSubmit}
          loader={loading}
          disabled={isDisabled}
        >
          Save
        </Button>
      </ButtonsContainer>
    </TabContainer>
  );
}

const propertyLocationNodes = arrayOf(
  shape({
    id: string.isRequired,
    parentId: string,
    name: string.isRequired,
  }),
);

PropertyLocationTree.propTypes = {
  locationHierarchy: shape({
    _id: string.isRequired,
    nodes: propertyLocationNodes,
  }),
  propertyId: string.isRequired,
  refetch: func.isRequired,
};
