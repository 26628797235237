import React from 'react';
import { SectionWithCard, EntityAddress } from 'poly-book-admin';

import { object, oneOfType, string } from 'prop-types';

export function SupplierRemitInfoSection({ name, address, addressTwo }) {
  return (
    <SectionWithCard
      title="Remit To Info"
      list={[
        {
          title: 'Remit To Name',
          elem: name,
        },
        {
          title: 'Address',
          elem: <EntityAddress {...{ address, addressTwo }} />,
        },
      ]}
    />
  );
}

SupplierRemitInfoSection.propTypes = {
  name: string,
  address: oneOfType([object, string]),
  addressTwo: string,
};
