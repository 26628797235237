import * as R from 'ramda';
import { ProjectPrioritiesUIMap } from 'poly-constants';
import {
  getDifferenceInDaysForCreatedOrNothingUI,
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
  formatDateProp,
} from 'poly-client-utils';

// getProjectReportRowConfig :: Project -> [ExcelExportDataCell]
const getProjectReportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 10),
  ]),
  R.juxt([
    R.propOr('', 'status'),
    R.compose(R.prop(R.__, ProjectPrioritiesUIMap), R.prop('priority')),
    R.propOr('', 'projectId'),
    R.propOr('', 'description'),
    R.pathOr('', ['property', 'name']),
    R.pathOr('', ['client', 'name']),
    formatDateProp('startDate'),
    formatDateProp('endDate'),
    getDifferenceInDaysForCreatedOrNothingUI,
    R.propOr('', 'ext'),
  ]),
);

// getXlsProjectsConfig :: [Project] -> Object
export const getXlsProjectsConfig = (projects) => ({
  exportFileName: 'my_projects_export.xlsx',
  columnWidths: [15, 15, 15, 25, 15, 15, 10, 10, 10, 10],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 10)],
      [
        'Status',
        'Priority',
        'Project Id',
        'Description',
        'Property',
        'Client',
        'Start Date',
        'End Date',
        'Days Open',
        'Ext',
      ],
    ),
    ...R.map(getProjectReportRowConfig, projects),
  ],
});

export const generateMyProjectsXlsReport = (projects) => {
  const exportConfig = getXlsProjectsConfig(projects);
  performExcelExport(exportConfig);
};
