import React from 'react';
import * as R from 'ramda';
import { CommonSidebarFormWidth, entities } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';

import { editProjectFormId } from '../../constants.js';
import { EditProjectSidebarForm } from './EditProjectSidebarForm.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';

// getCloseParamsForProject :: FormProps -> CloseParams
// FormProps = { project: Project }
// CloseParams = [String, String, String]
const getCloseParamsForProject = R.compose(
  R.ifElse(
    R.prop('childType'),
    R.juxt([R.always(entities.RECURRING_PROJECT), R.prop('projectId')]),
    R.juxt([R.always(entities.PROJECT), R.prop('projectId'), R.prop('type')]),
  ),
  R.prop('project'),
);

export const useEditProjectSidebar = (isCard) => {
  const { openOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar(isCard);

  return (formProps) => {
    const closeParams = getCloseParamsForProject(formProps);

    openOutSidebar({
      alwaysFirst: true,
      id: editProjectFormId,
      width: CommonSidebarFormWidth,
      content: (
        <EditProjectSidebarForm
          {...formProps}
          isCard={isCard}
          onClose={() =>
            onClose(closeParams[0], closeParams[1], closeParams[2])
          }
        />
      ),
    });
  };
};
