import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useUnsupportedWorkerMessage } from '../hooks/useUnsupportedWorkerMessage.js';
import { useRegisterSharedWorker } from '../pages/HomePage/useRegisterSharedWorker.js';
import { setCurrentUser } from '../redux/currentUserReducer.js';

export const useRegisterCurrentUserSharedWorker = () => {
  const currentUserWorkerRef = useRef(null);

  const showUnsupportedMessage = useUnsupportedWorkerMessage();

  const dispatch = useDispatch();

  const dispatchCurrentUser = (user) => {
    dispatch(setCurrentUser(user));
  };

  useEffect(() => {
    try {
      currentUserWorkerRef.current = new SharedWorker(
        new URL(
          'poly-client-utils/src/workers/currentUserSharedWorker.js',
          import.meta.url,
        ),
        {
          name: 'current-user',
        },
      );
    } catch (e) {
      showUnsupportedMessage();
    }
  }, []);

  useRegisterSharedWorker({
    setData: dispatchCurrentUser,
    workerInstance: currentUserWorkerRef.current,
    skip: !currentUserWorkerRef.current,
  });
};
