import React from 'react';
import styled from 'styled-components';
import { getThemeColor, Icon } from 'poly-book-admin';
import { useRouterQuery } from 'poly-client-routing';
import { BaseSidebarLayout } from 'poly-admin-ui';

import {
  useCloseExtendedTab,
  checkIsDoubleWidthSidebarTab,
} from '../hooks/useCloseExtendedTab.js';

const SecondSidebarLayoutComp = styled(BaseSidebarLayout)`
  box-shadow: none;
  background-color: ${getThemeColor(['lightest'])};
  border-left: 1px solid ${getThemeColor(['midLight'])};

  ${({ isFullWidth }) => isFullWidth && 'overflow: visible;'};
`;

export function GeneralSidebarLayout(props) {
  const closeExtendedTab = useCloseExtendedTab();

  return <BaseSidebarLayout {...props} onClick={closeExtendedTab} />;
}

export function SecondSidebarLayout(props) {
  const { sidebarTab } = useRouterQuery(['sidebarTab']);

  const isFullWidth = checkIsDoubleWidthSidebarTab(sidebarTab);

  return <SecondSidebarLayoutComp {...props} isFullWidth={isFullWidth} />;
}

export const SidebarCloseIcon = styled(Icon).attrs((props) => ({
  name: 'close',
  size: 16,
  color: getThemeColor(['primary'])(props),
}))`
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  padding: 3px;
`;

export const SidebarCloseIconDecor = styled(Icon).attrs((props) => ({
  name: 'close',
  size: 25,
  color: getThemeColor(['white'])(props),
}))`
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  padding: 8.5px;
  background-color: ${getThemeColor(['mid'])};
  border-radius: 5px;
  margin-left: 5px;
  height: 25px;
`;
