import * as R from 'ramda';
import { toDate, isNilOrEmpty } from 'poly-utils';
import { ALL } from 'poly-admin-ui/src/constants/general.js';

// toDateByProp :: String -> APAgingReportFilters -> APAgingReportFilters
const toDateByProp = (propName) =>
  R.when(R.prop(propName), R.over(R.lensProp(propName), toDate));

// generateSearchAgingReportQueryByKeyProp :: String -> APAgingReportFilters -> APAgingReportInput
export const generateSearchAgingReportQueryByKeyProp = (keyProp) =>
  R.compose(
    R.omit(['branchId', 'date']),
    R.reject(isNilOrEmpty),
    R.when(
      R.propEq('clientInvoiceStatus', ALL),
      R.dissoc('clientInvoiceStatus'),
    ),
    R.when(R.propEq(keyProp, ALL), R.dissoc(keyProp)),
    toDateByProp('invoiceStartDate'),
    toDateByProp('invoiceEndDate'),
    toDateByProp('invoicePaymentDueDate'),
    R.defaultTo({}),
  );
