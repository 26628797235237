import * as R from 'ramda';
import { formatStringByConfig, formatZip } from 'poly-utils';

// getPostalCode :: Address -> String
const getPostalCode = R.pathOr('', ['address', 'address_parts', 'postal_code']);

const addressConfigs = [
  [
    [' ', R.pathOr('', ['address', 'address_parts', 'street_number'])],
    [null, R.pathOr('', ['address', 'address_parts', 'route'])],
  ],
  [[null, R.path(['addressTwo'])]],
  [
    [', ', R.pathOr('', ['address', 'address_parts', 'locality'])],
    [
      ' ',
      R.pathOr('', ['address', 'address_parts', 'administrative_area_level_1']),
    ],
    [
      null,
      R.compose(
        formatZip,
        R.ifElse(
          R.compose(R.lte(R.__, 8), R.prop('length'), getPostalCode),
          R.converge(R.concat, [
            getPostalCode,
            R.pathOr('', ['address', 'address_parts', 'postal_code_suffix']),
          ]),
          getPostalCode,
        ),
      ),
    ],
  ],
];

// formatAddressString :: AddressObject -> String
// AddressObject = { address: Address, addressTwo: String  }
export const formatAddressString = formatStringByConfig(addressConfigs);
