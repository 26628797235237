import * as R from 'ramda';
import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Loader } from 'poly-book-admin/src/index.js';
import { TaskFormWrapper } from './form/index.js';
import { initialCommonValues } from './form/constants.js';
import { mailToInitValues } from '../fields/MailTo/index.js';
import {
  prepareDetailsString,
  getTaskProjectId,
} from '../../../utils/tasks.js';
import { confirmCancelFormPayload } from '../../../components/common.js';
import { useModalContext } from '../../../components/Modal/ModalProvider.js';
import { useProcessState } from '../../../hooks/useProcessState.js';
import { useTaskUpdatesById } from '../../../hooks/tasks/index.js';
import { useAddTaskUpdateHandler } from './form/hooks.js';
import { WarningComponent } from './complete/index.js';

// getProjectWorkCompletionDateWithProjectId :: { project: Project } -> DateTime
const getProjectWorkCompletionDateWithProjectId = R.applySpec({
  workCompletionDate: R.path(['project', 'workCompletionDate']),
  projectId: R.path(['project', '_id']),
});

export function PostTaskUpdateForm(props) {
  const {
    formId,
    payload: { _id, onCancel: onCancelFromPayload, collection },
    onCancel: onCancelFromProps,
    isHQProject,
  } = props;

  const modalContext = useModalContext();

  const { process } = useProcessState(formId);

  const { task, loading } = useTaskUpdatesById(_id);

  const { addTaskUpdate } = useAddTaskUpdateHandler({ task });

  const onCancel = () => {
    modalContext.closeModal(formId);

    const onCancelHandler = onCancelFromPayload || onCancelFromProps;

    if (onCancelHandler) {
      onCancelHandler();
    }
  };

  const openConfirmModal = () =>
    modalContext.openConfirmModal({
      ...confirmCancelFormPayload,
      onConfirm: (closeConfirmModal) => R.compose(closeConfirmModal, onCancel),
    });

  const initialValues = {
    details: { ...initialCommonValues, placeholder: null },
    mailTo: mailToInitValues,
    onlyUpdate: true,
    taskType: task?.action,
    collection,
    actionInProgress: process,
    isHQProject,
    ...getProjectWorkCompletionDateWithProjectId(props.payload),
  };

  const mutate = (data) => addTaskUpdate({ documentId: task?._id, ...data });

  if (!_id) return null;
  if (loading) return <Loader />;

  if (!task && !loading) {
    return <WarningComponent onCancel={onCancel} />;
  }

  return (
    <TaskFormWrapper
      {...props}
      task={task}
      updates={task?.documentUpdates}
      submitCaption="Update"
      successMessage="Task Update was added"
      projectId={getTaskProjectId(task?.document)}
      projectDbId={task?.document?.project?._id}
      title={`Post Update - ${task?.description}`}
      entityInfo={prepareDetailsString?.document}
      actionInProgress={process}
      mutate={mutate}
      initialValues={initialValues}
      openConfirmModal={openConfirmModal}
      onCancel={onCancel}
    />
  );
}

PostTaskUpdateForm.displayName = 'PostTaskUpdateForm';

PostTaskUpdateForm.propTypes = {
  formId: string.isRequired,
  payload: shape({
    collection: string,
    _id: string,
    onCancel: func,
  }),
  onCancel: func,
  isHQProject: bool,
};
