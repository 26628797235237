import * as R from 'ramda';
import { ProjectType, AccountingStatus, WorkOrderStatus } from 'poly-constants';

// extractTruthyPairs :: Object -> FiltersObject -> [Pair String Boolean]
const extractTruthyPairs = (valuesSet) =>
  R.compose(
    R.filter(R.compose(R.equals(true), R.last)),
    R.toPairs,
    R.pick(R.values(valuesSet)),
  );

// extractMultiValueFilter :: (a, String) -> FiltersObject -> {terms: [String]}
// a = Object
const extractMultiValueFilter = (valuesSet, filterProp) =>
  R.compose(
    R.ifElse(
      R.isEmpty,
      R.always(null),
      R.compose(R.assocPath(['terms', filterProp], R.__, {}), R.map(R.head)),
    ),
    extractTruthyPairs(valuesSet),
  );

// extractSingleValueFilter :: String -> FiltersObject -> {term: String}
const extractSingleValueFilter = (filterProp) =>
  R.compose(
    R.ifElse(R.isEmpty, R.always(null), R.objOf('term')),
    R.pick([filterProp]),
  );

// eslint-disable-next-line import/no-unused-modules
export const excludeNonBillableBatchInvoiceProjects = {
  bool: {
    must_not: {
      term: { type: ProjectType.REPORT_ONLY },
    },
  },
};

// batchInvoiceFiltersToSearchQuery :: FiltersObject -> SearchQuery
// FiltersObject = Object
export const batchInvoiceFiltersToSearchQuery = R.compose(
  R.assocPath(['bool', 'filter'], R.__, {}),
  R.append(excludeNonBillableBatchInvoiceProjects),
  R.reject(R.isNil),
  R.juxt([
    extractMultiValueFilter(WorkOrderStatus, 'status'),
    extractMultiValueFilter(AccountingStatus, 'accountingStatus'),
    extractSingleValueFilter('clientId'),
    extractSingleValueFilter('type'),
    extractSingleValueFilter('property.buildingInfo.occupancy'),
    extractSingleValueFilter('parent.type'),
    extractSingleValueFilter('serviceTypeId'),
    extractSingleValueFilter('notRM'),
  ]),
);
