import React from 'react';
import { string } from 'prop-types';
import { filesCollections } from 'poly-constants';
import { SidebarFilesTab } from 'poly-admin-ui';

export function MasterRecurringProjectSidebarFilesTab({ _id }) {
  const tabProps = {
    collection: filesCollections.RECURRING_PROJECTS,
    entity: { _id, preview: 'project' },
  };

  return <SidebarFilesTab {...tabProps} />;
}

MasterRecurringProjectSidebarFilesTab.propTypes = { _id: string.isRequired };
