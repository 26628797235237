import React from 'react';
import * as R from 'ramda';
import { array, oneOfType, shape, string } from 'prop-types';
import { formatTotal } from 'poly-utils';
import {
  SupplierTypes,
  SupplierPhoneTypes,
  NOTHING_UI_STRING,
} from 'poly-constants';
import { keywordSortQuery } from 'poly-client-utils';
import { Rating } from 'poly-book-admin';
import { PhoneLink } from 'poly-admin-ui';

import { SupplierLink } from '../../../components/Links.js';

export function SupplierLinkComp({ company, ...restProps }) {
  return <SupplierLink {...restProps} name={company.name} />;
}

SupplierLinkComp.propTypes = {
  company: shape({
    name: oneOfType([string, array]),
  }),
};

const getSupplierPhone = R.compose(
  R.prop('phone'),
  R.find(R.propEq('type', SupplierPhoneTypes.GENERAL)),
  R.pathOr([], ['company', 'phones']),
);

export const supplierNameColumn = [
  'Name',
  SupplierLinkComp,
  keywordSortQuery(['company', 'name']),
];

export const supplierPhoneColumn = [
  'Phone',
  (supplier) => <PhoneLink number={getSupplierPhone(supplier)} />,
];

function SupplierRating(props) {
  return <Rating {...props} readOnly />;
}

export const supplierRatingColumn = [
  'Rating',
  R.pipe(
    R.ifElse(
      R.propEq('type', R.toUpper(SupplierTypes.SUBCONTRACTOR)),
      R.pipe(R.prop('rating'), R.objOf('value'), SupplierRating),
      R.always(NOTHING_UI_STRING),
    ),
  ),
];

export const supplierHourlyRateColumn = [
  'Hourly Rate',
  R.pipe(R.pathOr(0, ['rates', 'hourly']), formatTotal),
];
