import * as R from 'ramda';
import React from 'react';
import { Select } from 'poly-book-admin';
import { AdminCheckTypes, AdminCheckTypesUI } from 'poly-constants';

// getCheckTypeSelectOptions :: InputObject -> [SelectOption]
// InputObject = {
//    manual: String,
//    remote: String,
// }
const getCheckTypeSelectOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.identity,
      label: R.prop(R.__, AdminCheckTypesUI),
    }),
  ),
  R.values,
);

export function AdminCheckTypeSelect(props) {
  const selectProps = {
    name: 'adminCheckTypeSelectName',
    options: getCheckTypeSelectOptions(AdminCheckTypes),
    ...props,
  };

  return <Select {...selectProps} />;
}
