import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { employeeStatusColors, highlightedTextPropType } from 'poly-admin-ui';
import { READ_SENSITIVE_FIELD_USER_PERMISSION } from 'poly-security';
import { Dot, getThemeColor } from 'poly-book-admin';
import { NOTHING_UI_STRING } from 'poly-constants';
import {
  useHasUserAccessWithPermission,
  keywordSortQuery,
  pathOrNothingUI,
  commonSortQuery,
} from 'poly-client-utils';

import { FlexContainer } from '../../../components/FlexContainer.js';
import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';

// getColor :: Object -> String
const getColor = R.compose(
  R.prop(R.__, employeeStatusColors),
  R.path(['employeeInfo', 'status']),
);

export function UserEmploymentStatus(props) {
  const color = getColor(props);

  return <Dot {...props} color={color} />;
}

function UserLinkComp({ _id, fullName }) {
  const hasPermissionToReadPrivateFields = useHasUserAccessWithPermission(
    READ_SENSITIVE_FIELD_USER_PERMISSION,
  );

  const { UserLink, ContactLink } = useSidebarLogicContext();

  const linkProps = hasPermissionToReadPrivateFields
    ? { _id, name: fullName }
    : { _id, fullName };

  const LinkComponent = hasPermissionToReadPrivateFields
    ? UserLink
    : ContactLink;

  return <LinkComponent {...linkProps} />;
}

UserLinkComp.propTypes = {
  _id: string.isRequired,
  fullName: highlightedTextPropType.isRequired,
};

// getUserGroup :: User -> String
const getUserGroup = R.compose(
  R.ifElse(R.isEmpty, R.always(NOTHING_UI_STRING), R.join(', ')),
  R.map(R.path(['userGroup', 'name'])),
  R.propOr([], 'userGroups'),
);

const ContractorLabel = styled.div`
  margin-left: 5px;
  font-size: 14px;
  color: ${getThemeColor(['secondary'])};
`;

function UserNameColumn(user) {
  const isContractor = R.prop('isContractor', user);
  return (
    <FlexContainer>
      <UserLinkComp {...user} />
      {isContractor && <ContractorLabel>- contractor</ContractorLabel>}
    </FlexContainer>
  );
}

export const usersNameColumn = [
  'Name',
  UserNameColumn,
  keywordSortQuery(['profile', 'fullName']),
];

export const usersLocationColumn = [
  'Office Location',
  pathOrNothingUI(['companyLocation']),
  commonSortQuery(['companyLocation']),
];
export const usersRoleColumn = ['User Group', getUserGroup];
