import * as R from 'ramda';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { string, arrayOf, shape } from 'prop-types';
import { commonSortQuery } from 'poly-client-utils';
import { DESC_SORT_ORDER } from 'poly-constants';
import { useTableSorting } from 'poly-admin-ui';

import { UserGroupActionsButton } from './UserGroupActionsButton.js';
import { formatUserGroupPermissions } from './userGroupManagementUtils.js';
import { UserGroupVariableCompS } from './components.js';
import { UserGroupLink } from '../../sidebars/UserGroupSidebar/useOpenUserGroupSidebar.js';
import { USER_GROUP_LIST_QUERY } from './queries/user-group-list-query.js';

function UserGroupVariables({ variables }) {
  return variables.map(({ id, name }) => {
    const formattedName = `[{${name}}]`;
    return (
      <UserGroupVariableCompS key={id}>{formattedName}</UserGroupVariableCompS>
    );
  });
}

UserGroupVariables.propTypes = {
  variables: arrayOf(shape({ id: string.isRequired, name: string.isRequired }))
    .isRequired,
};

export const userGroupManagementTableConfig = [
  ['Group Name', UserGroupLink, commonSortQuery(['name'])],
  ['Permissions', formatUserGroupPermissions],
  ['Variables', UserGroupVariables],
  ['Users', R.propOr(0, 'assignedUsersCount')],
  ['', UserGroupActionsButton],
];

// getQuerySortForName :: [Sorting] -> Int
const getQuerySortForName = R.compose(
  R.ifElse(R.equals(DESC_SORT_ORDER), R.always(1), R.always(-1)),
  R.path(['name', 'order']),
  R.last,
);

// prepareDataForTableProps :: (() => Promise _ _) -> ListUserGroupsQueryResult -> [UserGroup]
const prepareDataForTableProps = (refetch) =>
  R.compose(R.map(R.assoc('refetch', refetch)), R.propOr([], 'listUserGroups'));

export const useUserGroupListQuery = () => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: userGroupManagementTableConfig,
    order: DESC_SORT_ORDER,
    column: 1,
  });

  const nameSort = useMemo(() => getQuerySortForName(sort), [sort]);

  const { data, loading, refetch } = useQuery(USER_GROUP_LIST_QUERY, {
    variables: { input: { sort: { name: nameSort } } },
  });

  const userGroupList = useMemo(
    () => prepareDataForTableProps(refetch)(data),
    [data],
  );

  return { userGroupList, loading, refetch, tableSortingProps };
};
