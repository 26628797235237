import React from 'react';
import styled from 'styled-components';
import { TableSearchInput } from 'poly-admin-ui';
import { arrayOf } from 'prop-types';

import { ToolBarBtnDivider } from '../../../modules/core/tableToolbar/tableToolBarStyles.js';
import { preparedOSIProject } from '../propTypes.js';
import { OSIExportToPDFButton } from './OSIExportToPDFButton.js';
import { OSIExportToExcelButton } from './OSIExportToExcelButton.js';

const ToolBarWrapper = styled.div`
  margin: 10px auto 0 auto;
  width: calc(100% - 60px);
  justify-content: flex-end;
  max-width: 1424px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
`;

export function OSIToolBar({ projects }) {
  return (
    <ToolBarWrapper>
      <OSIExportToPDFButton projects={projects} />
      <ToolBarBtnDivider />
      <OSIExportToExcelButton projects={projects} />
      <TableSearchInput />
    </ToolBarWrapper>
  );
}

OSIToolBar.propTypes = {
  projects: arrayOf(preparedOSIProject),
};
