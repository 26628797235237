import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, func, bool } from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { FormCreator, Textarea, Button } from 'poly-book-admin';
import { AssetStatuses } from 'poly-constants';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
  useModalContext,
  useProcessState,
} from 'poly-admin-ui';

import { commonModalLayout } from '../../modules/forms/common.js';
import { SimpleModalLayout } from '../../components/SimpleModalLayout.js';
import { useUserHasAccessToUpdateAsset } from './useUserHasAccessToUpdateAsset.js';

const rejectAssetFormId = 'rejectAssetFormId';

const TextareaS = styled(Textarea).attrs({ rows: 3 })``;

const REJECT_ASSET_MUTATION = gql`
  mutation REJECT_ASSET_MUTATION($id: ID!, $input: UpdateAssetInput) {
    updateAsset(id: $id, input: $input) {
      asset {
        _id
      }
    }
  }
`;

// getRejectAssetModalTitle :: String -> String
const getRejectAssetModalTitle = (assetName) =>
  `You're going to reject the Asset ${assetName}. Please add rejection reason below.`;

function RejectAssetForm({ id, assetName, onSuccess }) {
  const { closeModal } = useModalContext();
  const [rejectAsset] = useMutation(REJECT_ASSET_MUTATION);
  const { showSuccessNotification } = useNotificationState();

  const onSubmitHandler = async ({ rejectReason }) => {
    await rejectAsset({
      variables: { id, input: { rejectReason, status: AssetStatuses.BLOCKED } },
    });
    showSuccessNotification(`Asset ${assetName} was rejected`);

    if (R.is(Function, onSuccess)) {
      onSuccess();
    }

    closeModal(id);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    rejectAssetFormId,
    onSubmitHandler,
  );

  const formProps = {
    onSubmit,
    initialValues: {},
    id: rejectAssetFormId,
    formId: rejectAssetFormId,
    layout: commonModalLayout,
    sections: [
      {
        order: 1,
        fields: [
          {
            order: 1,
            label: 'Reason',
            field: {
              name: 'rejectReason',
              Component: TextareaS,
            },
            validators: [[R.identity, 'Reason is required']],
            required: true,
          },
        ],
      },
    ],
  };

  return (
    <>
      {getRejectAssetModalTitle(assetName)}
      <FormCreator {...formProps} />
    </>
  );
}

RejectAssetForm.propTypes = {
  onSuccess: func,
  id: string.isRequired,
  assetName: string.isRequired,
};

export function RejectAssetButton({ id, assetName, onSuccess, disabled }) {
  const { openModal } = useModalContext();
  const { process } = useProcessState(rejectAssetFormId);

  const hasPermissionToUpdateAsset = useUserHasAccessToUpdateAsset();

  const onReject = () =>
    openModal({
      id,
      loading: process,
      btnCaption: 'Reject',
      title: 'Reject Asset',
      Layout: SimpleModalLayout,
      onConfirm: () =>
        function () {
          return null;
        },
      btnContext: { type: 'submit', form: rejectAssetFormId },
      content: <RejectAssetForm {...{ id, assetName, onSuccess }} />,
    });

  return (
    <Button
      size="small"
      loader={process}
      onClick={onReject}
      disabled={disabled || !hasPermissionToUpdateAsset}
      styleType="basicSecondary"
    >
      Reject
    </Button>
  );
}

RejectAssetButton.propTypes = {
  id: string,
  disabled: bool,
  onSuccess: func,
  assetName: string,
};
