import React from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { useRedirectIfHasNoPermissions } from 'poly-client-utils';
import { READ_CLIENT_BATCH_PERMISSION } from 'poly-security';
import { useRouterParams } from 'poly-client-routing';
import { Loader } from 'poly-book-admin';
import { assocBy } from 'poly-utils';

import { BatchInvoiceReportsDetailsTableWrapper } from './components.js';
import { BatchInvoiceReportsDetailsTable } from './BatchInvoiceReportsDetailsTable.js';
import { BatchInvoiceReportsDetailsHeader } from './BatchInvoiceReportsDetailsHeader.js';

const BATCH_INVOICE_REPORT_DETAILS_QUERY = gql`
  query BATCH_INVOICE_REPORT_DETAILS_QUERY($input: ClientInvoicesInput) {
    clientInvoices(input: $input) {
      hits {
        _id
        number
        status
        amount
        project {
          projectId
          description
          type
        }
        clientNotified {
          emails {
            to
            cc
          }
          date
        }
        consolidatedClientInvoice {
          _id
          invoiceNumber
          pdfFile {
            url
          }
          clientNotified {
            emails {
              to
              cc
            }
            date
          }
        }
      }
    }
  }
`;

// prepareClientInvoices :: ClientInvoicesResult -> [ClientInvoice]
const prepareClientInvoices = R.compose(
  R.map(
    R.when(
      R.prop('consolidatedClientInvoice'),
      R.compose(
        assocBy(
          'clientNotified',
          R.path(['consolidatedClientInvoice', 'clientNotified']),
        ),
        assocBy(
          'number',
          R.path(['consolidatedClientInvoice', 'invoiceNumber']),
        ),
      ),
    ),
  ),
  R.pathOr([], ['clientInvoices', 'hits']),
);

export function BatchInvoiceReportsDetailsPage() {
  const { batchId } = useRouterParams(['batchId']);

  const { data, loading } = useQuery(BATCH_INVOICE_REPORT_DETAILS_QUERY, {
    variables: { input: { clientBatchId: batchId } },
  });

  const batchInvoices = prepareClientInvoices(data);

  useRedirectIfHasNoPermissions([READ_CLIENT_BATCH_PERMISSION]);

  return (
    <>
      <BatchInvoiceReportsDetailsHeader batchInvoices={batchInvoices} />
      {loading ? (
        <Loader />
      ) : (
        <BatchInvoiceReportsDetailsTableWrapper>
          <BatchInvoiceReportsDetailsTable
            loading={loading}
            batchInvoices={batchInvoices}
          />
        </BatchInvoiceReportsDetailsTableWrapper>
      )}
    </>
  );
}
