import * as R from 'ramda';
import { FeeTypes, FeeTypesUI } from 'poly-constants';
import { getDocumentAddressLines, isNilOrEmpty } from 'poly-utils';

import { isFeeProjectType } from '../utils/sections.js';

// getFeeTypeDescription :: { formData: FormData, property: Property } -> String
const getFeeTypeDescription = R.cond([
  [
    R.pathEq(['formData', 'feeType'], FeeTypes.CUSTOM_FEE),
    R.compose(
      R.join(' - '),
      R.juxt([
        R.path(['property', 'name']),
        R.pathOr('', ['formData', 'customFeeDescription']),
      ]),
    ),
  ],
  [
    R.path(['formData', 'feeType']),
    R.compose(
      R.join(' - '),
      R.juxt([
        R.path(['property', 'name']),
        R.compose(R.prop(R.__, FeeTypesUI), R.path(['formData', 'feeType'])),
      ]),
    ),
  ],
  [R.T, R.always('')],
]);

// generateProjectDescription :: { formData: FormData, property: Property } -> String
export const generateProjectDescription = R.cond([
  [R.propSatisfies(isNilOrEmpty, 'property'), R.always('')],
  [R.propSatisfies(isFeeProjectType, 'formData'), getFeeTypeDescription],
  [R.T, R.compose(R.concat(R.__, ' - '), R.path(['property', 'name']))],
]);

// getFormattedAddressByProperty :: Property -> String
const getFormattedAddressByProperty = R.compose(
  R.join(', '),
  getDocumentAddressLines,
);

// getPropertyNameWithAddress :: { property: Property } -> String
const getPropertyNameWithAddress = R.compose(
  R.join(', '),
  R.juxt([R.prop('name'), getFormattedAddressByProperty]),
  R.prop('property'),
);

// getFeeTypeInvoiceDescription :: { formData: FormData, property: Property } -> String
const getFeeTypeInvoiceDescription = R.cond([
  [
    R.pathEq(['formData', 'feeType'], FeeTypes.CUSTOM_FEE),
    R.compose(
      R.join(' for '),
      R.juxt([
        R.pathOr('', ['formData', 'customFeeDescription']),
        getPropertyNameWithAddress,
      ]),
    ),
  ],
  [
    R.path(['formData', 'feeType']),
    R.compose(
      R.join(' for '),
      R.juxt([
        R.compose(R.prop(R.__, FeeTypesUI), R.path(['formData', 'feeType'])),
        getPropertyNameWithAddress,
      ]),
    ),
  ],
  [R.T, R.always('')],
]);

// generateProjectInvoiceDescription :: { formData: FormData, property: Property } -> String
export const generateProjectInvoiceDescription = R.cond([
  [R.propSatisfies(isNilOrEmpty, 'property'), R.always('')],
  [R.propSatisfies(isFeeProjectType, 'formData'), getFeeTypeInvoiceDescription],
  [
    R.T,
    R.compose(R.concat('Services Provided at '), getPropertyNameWithAddress),
  ],
]);
