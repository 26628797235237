import * as R from 'ramda';
import { ELASTIC_SCORE_FIELD } from 'poly-constants';

// removeScoreForSortingWithText :: [SortingParam] -> [SortingParam]
const removeScoreForSortingWithText = R.when(
  R.is(Array),
  R.reject(R.equals(ELASTIC_SCORE_FIELD)),
);

// prepareSortingWithSearchText :: String -> [SortingParam] -> [SortingParam]
export const prepareSortingWithSearchText = (searchText) => (sorting) =>
  searchText ? removeScoreForSortingWithText(sorting) : sorting;
