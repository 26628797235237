import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';
import {
  formDataToMutationBase,
  useModalContext,
  useNotificationState,
} from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';

import { editPropertyFormId } from '../../constants.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';
import {
  prepareHierarchiesToMutation,
  propertyFormObjectToMutation,
} from './formDataToMutation.js';
import { updatePropertyHierarchyPathMutation } from '../add/useOnSubmitForm.js';

const UPDATE_PROPERTY_MUTATION = gql`
  mutation UPDATE_PROPERTY_MUTATION($id: ID!, $input: UpdatePropertyInput) {
    updateProperty(input: $input, id: $id) {
      property {
        _id
      }
    }
  }
`;

// editPropertyToMutation :: Property -> FormValues -> UpdatePropertyInput
const editPropertyToMutation = formDataToMutationBase(
  propertyFormObjectToMutation,
  ['buildingInfo', 'alarmInfo', 'financialCoding'],
);

export const useOnSubmitEditPropertyForm = ({
  saveContacts,
  property,
  isCard,
}) => {
  const [updatePropertyHierarchyPath] = useMutation(
    updatePropertyHierarchyPathMutation,
  );
  const [updateProperty] = useMutation(UPDATE_PROPERTY_MUTATION);
  const { showSuccessNotification } = useNotificationState();
  const { openPropertySidebar } = useSidebarLogicContext();
  const { closeOutSidebar } = useOutSidebarContext();
  const { openConfirmModal } = useModalContext();

  const { client } = property;

  const propertyCustomFields = R.pathOr(
    {},
    ['configs', 'propertyFields'],
    client,
  );

  return async ({ formData, initialValues }) => {
    // first save contact (branchManager)
    const [branchManagerResult] = await saveContacts(
      formData,
      property._id,
      client._id,
      ['branchManager'],
    );

    let managers = {
      branchManagerId: branchManagerResult.contactId,
    };

    if (propertyCustomFields.districtFacilityManager) {
      const [districtFacilityManagerResult] = await saveContacts(
        formData,
        property._id,
        client._id,
        ['districtFacilityManager'],
      );

      managers = {
        ...managers,
        districtFacilityManagerId: districtFacilityManagerResult.contactId,
      };
    }

    if (formData.clientHierarchies.length) {
      const hierarchyToMutation = prepareHierarchiesToMutation(
        formData,
        property._id,
      );

      await hierarchyToMutation.map((mutationInput) =>
        updatePropertyHierarchyPath({
          variables: mutationInput,
        }),
      );
    }

    const toMutationObject = editPropertyToMutation(initialValues)({
      ...formData,
      ...managers,
    });

    const input = R.dissocPath(
      ['buildingInfo', 'customBuildingType'],
      toMutationObject,
    );

    const mutateProperty = async (changeChildPropertiesStatus) => {
      await updateProperty({
        variables: {
          id: property._id,
          input: {
            ...input,
            ...(changeChildPropertiesStatus
              ? { changeChildPropertiesStatus: true }
              : {}),
          },
        },
      });

      showSuccessNotification('Property was successfully updated');
      closeOutSidebar(editPropertyFormId);
    };

    const confirmModalProps = {
      cancelBtnCaption: 'No',
      btnCaption: 'Yes',
      id: 'confirmEditPropertyFormId',
      content: `Do you want to set sub properties to ${input?.status}?`,
      withCancel: true,
    };

    if (property?.isMaster && property?.status !== input?.status) {
      openConfirmModal({
        ...confirmModalProps,
        onConfirm: (closeConfirmModal) => async () => {
          await mutateProperty(true);
          closeConfirmModal();
          if (!isCard) {
            openPropertySidebar(property._id);
          }
        },
        onReject: async () => {
          await mutateProperty();
          if (!isCard) {
            openPropertySidebar(property._id);
          }
        },
      });

      return;
    }

    await mutateProperty();

    if (!isCard) {
      openPropertySidebar(property._id);
    }
  };
};
