import React from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { TableSearchInput } from 'poly-admin-ui';
import { AccountStatuses } from 'poly-constants';
import { defaultTheme, Status, TableCard } from 'poly-book-admin';
import { useSelector } from 'react-redux';
import { useRouterQuery } from 'poly-client-routing';
import {
  highlightMatchesInObjectCurried,
  isSearchTextMatchedAtPaths,
  useReactiveQuery,
} from 'poly-client-utils';
import { formatCount, forceTitleCase } from 'poly-utils';

import { ChartOfAccountsPageHeader } from './ChartOfAccountsPageHeader.js';
import { ChartOfAccountsTable } from './ChartOfAccountsTable.js';
import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { extractChildAccounts, getTableRows } from './tableRow.js';

const searchTextPaths = [
  ['code'],
  ['name'],
  ['accountType', 'name'],
  ['division', 'name'],
];

// filterAccountsBySearchText :: String -> [Account] -> [Accounts]
const filterAccountsBySearchText = (searchText) =>
  R.when(
    R.always(searchText),
    R.filter(isSearchTextMatchedAtPaths(searchTextPaths, searchText)),
  );

const GET_ACCOUNTS_QUERY = gql`
  query GET_ACCOUNTS_QUERY($filters: AccountFilters) {
    getAccounts(filters: $filters) {
      hits {
        _id
        code
        compass_gl_code
        compass_gl_code_description
        name
        accountType {
          _id
          name
        }
        division {
          _id
          name
        }
        status
        sub_account
        accrualBalance
        is_parent_tax_account
        childAccounts {
          _id
          code
          compass_gl_code
          compass_gl_code_description
          name
          state
          accountType {
            _id
            name
          }
          division {
            _id
            name
          }
          status
          sub_account
          accrualBalance
          parentAccount {
            _id
          }
        }
      }
    }
  }
`;

const ACCOUNT_CHANGED_SUBSCRIPTION = gql`
  subscription ACCOUNT_CHANGED_SUBSCRIPTION($input: CollectionSearchParams) {
    searchAccountChanged(input: $input) {
      id
      type
    }
  }
`;

const {
  colors: { statuses },
} = defaultTheme;

const PageTabTitleS = styled(Status)`
  font-size: 13px;
`;

const chartOfAccountsTabs = (props) =>
  R.compose(
    R.map((status) => [
      <PageTabTitleS
        key={status}
        text={`${forceTitleCase(status)} (${formatCount(props.count[status])})`}
        color={statuses[status]}
      />,
      status,
      <TableCard key={status}>
        <ChartOfAccountsTable {...props} />
      </TableCard>,
    ]),
    R.values,
  )(AccountStatuses);

// getAccountsByTabAndFilter :: (String, String) -> [AccountPostgre] -> [AccountPostgre]
const getAccountsByTabAndFilter = (tab, searchText) =>
  R.compose(filterAccountsBySearchText(searchText), getTableRows(tab));

// getHighlightedAccounts :: (String, String) -> [Account] -> [Account]
const getHighlightedAccounts = (tab, searchText) =>
  R.compose(
    R.map(highlightMatchesInObjectCurried(searchTextPaths, searchText)),
    getAccountsByTabAndFilter(tab, searchText),
  );

// getAccountsCountByStatus :: (String, String) -> [Account] -> AccountsCountByTab
// AccountsCountByTab = { active: Number } | { inactive: Number }
const getAccountsCountByStatus = (searchText, status) =>
  R.compose(
    R.objOf(status),
    R.length,
    R.filter(R.propEq('status', status)),
    filterAccountsBySearchText(searchText),
    extractChildAccounts,
  );

// getAccountsCountByTabs :: String -> [Account] -> CountObject
// CountObject = {
//    active: Number,
//    inactive: Number,
// }
const getAccountsCountByTabs = (searchText) =>
  R.compose(
    R.mergeAll,
    R.juxt([
      getAccountsCountByStatus(searchText, AccountStatuses.ACTIVE),
      getAccountsCountByStatus(searchText, AccountStatuses.INACTIVE),
    ]),
  );

// getParentAccountsForDropDown :: [Account] -> [ParentAccountForDropDown]
// ParentAccountForDropDown = {
//    _id: String,
//    code: String,
//    name: String,
//    is_parent_tax_account: Boolean
// }
const getParentAccountsForDropDown = R.compose(
  R.map(
    R.pick(['_id', 'code', 'name', 'is_parent_tax_account', 'accountType']),
  ),
  R.reject(R.propEq('status', AccountStatuses.INACTIVE)),
);

// getPageProps :: (Boolean, String, String, { getAccounts: [AccountPostgre] }) -> ChartOfAccountsPageProps
// ChartOfAccountsPageProps = {
//    loading: Boolean,
//    tab: String,
//    parentAccounts: [ParentAccountForDropDown],
//    accountsByTabAndFilter: [Account]
//    highlightedAccounts: [Account]
//    count: CountObject,
// }
const getPageProps = (loading, tab, searchText, data) =>
  R.compose(
    R.assoc('loading', loading),
    R.assoc('tab', tab),
    R.applySpec({
      parentAccounts: getParentAccountsForDropDown,
      accountsByTabAndFilter: getAccountsByTabAndFilter(tab, searchText),
      highlightedAccounts: getHighlightedAccounts(tab, searchText),
      count: getAccountsCountByTabs(searchText),
    }),
    R.pathOr([], ['getAccounts', 'hits']),
  )(data);

export function ChartOfAccountsPage() {
  const searchText = useSelector((state) => state.searchText);
  const { tab = AccountStatuses.ACTIVE } = useRouterQuery(['tab']);
  const queryOptions = { variables: { filters: { subAccount: false } } };
  const { data, loading } = useReactiveQuery(
    GET_ACCOUNTS_QUERY,
    ACCOUNT_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: {} },
  );

  const pageProps = getPageProps(loading, tab, searchText, data);

  return (
    <>
      <ChartOfAccountsPageHeader {...pageProps} />
      <PageTabs
        toolBar={<TableSearchInput />}
        tabs={chartOfAccountsTabs(pageProps)}
        defaultValue={AccountStatuses.ACTIVE}
      />
    </>
  );
}
