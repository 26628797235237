import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { Table, Button, WindowedTable } from 'poly-book-admin';
import { useMutation } from '@apollo/client';
import { supplierHaveServiceEmail, supplierHaveMobilePhone } from 'poly-utils';
import {
  extractTablePropsFromConfig,
  highlightedTextPropType,
  useNotificationState,
} from 'poly-admin-ui';
import { arrayOf, string, shape, bool, func, number } from 'prop-types';

import {
  BottomPanelBody,
  BottomPanelContainer,
} from '../../components/BottomPanel.js';
import { SupplierLink } from '../../components/Links.js';
import { FlexContainer } from '../../components/FlexContainer.js';
import { propertyEntityColumn } from '../../modules/tables/columns/property.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { SendSupplierServiceTicketRequestMutation } from './components/WeekServiceTicketRequestedLink.js';
import { checkTicketReceivedColumn } from './houseKeepingServiceTicketsHelpers.js';

const ButtonsContainer = styled(FlexContainer)`
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
`;

const BottomPanelBodyS = styled(BottomPanelBody)`
  padding: 15px 0 20px 0;
  width: calc(100% - 40px);
  margin: 0 0 0 50px;
`;

const TableS = styled(WindowedTable)`
  height: 85%;

  tr {
    margin: 0;
    width: 100%;
  }

  td {
    padding: 5px;
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 120px;
  }

  td:nth-child(2),
  th:nth-child(2),
  td:nth-child(3),
  th:nth-child(3) {
    width: 250px;
  }

  td:nth-child(4),
  th:nth-child(4),
  td:nth-child(5),
  th:nth-child(5),
  td:nth-child(6),
  th:nth-child(6),
  td:nth-child(7),
  th:nth-child(7),
  td:nth-child(8),
  th:nth-child(8),
  td:nth-child(9),
  th:nth-child(9) {
    text-align: center;

    div {
      display: inline-block;
    }
  }

  td:nth-child(10),
  th:nth-child(10) {
    width: 40px;
  }
`;

const PrintTableS = styled(Table)`
  tr {
    margin: 0;
    width: 100%;
  }

  td {
    padding: 5px;
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 120px;
  }

  td:nth-child(2),
  th:nth-child(2),
  td:nth-child(3),
  th:nth-child(3) {
    width: 250px;
  }

  td:nth-child(4),
  th:nth-child(4),
  td:nth-child(5),
  th:nth-child(5),
  td:nth-child(6),
  th:nth-child(6),
  td:nth-child(7),
  th:nth-child(7),
  td:nth-child(8),
  th:nth-child(8) {
    text-align: center;

    label {
      display: inline-block;
    }
  }

  td:nth-child(9),
  th:nth-child(9) {
    width: 40px;
  }

  @media print {
    tr {
      border: none;

      &:first-child {
        border: none;
      }

      &:last-child {
        border: none;
      }
    }
  }
`;

const getTableConfig = (isPrint, refetchTableData) => [
  ['Project #', ProjectLink],
  propertyEntityColumn,
  [
    'Supplier',
    R.compose(
      SupplierLink,
      R.applySpec({ name: R.path(['company', 'name']), _id: R.prop('_id') }),
      R.path(['suppliers', 0]),
    ),
  ],
  ['Week 1', checkTicketReceivedColumn(1, isPrint, refetchTableData)],
  ['Week 2', checkTicketReceivedColumn(2, isPrint, refetchTableData)],
  ['Week 3', checkTicketReceivedColumn(3, isPrint, refetchTableData)],
  ['Week 4', checkTicketReceivedColumn(4, isPrint, refetchTableData)],
  ['Week 5', checkTicketReceivedColumn(5, isPrint, refetchTableData)],
  ['Week 6', checkTicketReceivedColumn(6, isPrint, refetchTableData)],
];

// isSupplierWithEmail :: Project -> Boolean
const isSupplierWithEmail = R.compose(
  supplierHaveServiceEmail,
  R.head,
  R.propOr([], 'suppliers'),
);

// isSupplierWithPhone :: Project -> Boolean
const isSupplierWithPhone = R.compose(
  supplierHaveMobilePhone,
  R.head,
  R.propOr([], 'suppliers'),
);

// isRowSelectable :: Project -> Boolean
const isRowSelectable = R.either(isSupplierWithEmail, isSupplierWithPhone);

// prepareAllServiceTicketsForSelect :: [ID] -> Projects -> [ID]
const prepareAllServiceTicketsForSelect = (selectedProjects) =>
  R.compose(
    R.when(R.eqProps('length', selectedProjects), R.always([])),
    R.map(R.prop('_id')),
    R.filter(isRowSelectable),
  );

// toggleRow :: ([ID], SetFunction) -> ID -> _
// SetFunction = ([ID]) -> _
const toggleRow =
  (selectedProjects, setSelectedProjects) => (serviceTicketId) =>
    R.compose(
      setSelectedProjects,
      R.ifElse(
        R.includes(serviceTicketId),
        R.reject(R.equals(serviceTicketId)),
        R.append(serviceTicketId),
      ),
    )(selectedProjects);

export function HouseKeepingServiceTicketsReportTable({
  windowedTableProps,
  projects,
  isPrint,
  refetchTableData,
}) {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [submitting, setSubmitting] = useState(null);
  const { showSuccessNotification } = useNotificationState();
  const [sendSupplierServiceTicketRequest] = useMutation(
    SendSupplierServiceTicketRequestMutation,
  );

  const tableProps = extractTablePropsFromConfig(
    getTableConfig(isPrint, refetchTableData),
  );

  const toggleSelectAll = () =>
    setSelectedProjects(
      prepareAllServiceTicketsForSelect(selectedProjects)(projects),
    );

  const onClick = async () => {
    setSubmitting(true);
    await sendSupplierServiceTicketRequest({
      variables: { input: { projectIds: selectedProjects } },
    });
    showSuccessNotification('Supplier request was sent.');
    await refetchTableData();
    setSubmitting(false);
  };

  return (
    <>
      <TableS
        {...windowedTableProps}
        rows={projects}
        isRowSelectable={isRowSelectable}
        selectedRowsInEnd
        selectedRows={selectedProjects}
        toggleRow={toggleRow(selectedProjects, setSelectedProjects)}
        toggleSelectAll={toggleSelectAll}
        showScrollBar
        {...tableProps}
      />
      <BottomPanelContainer height="70px">
        <BottomPanelBodyS>
          <ButtonsContainer>
            <Button
              size="small"
              loader={submitting}
              onClick={onClick}
              disabled={submitting || R.isEmpty(selectedProjects)}
            >
              Send Missing Service Tickets Request
            </Button>
          </ButtonsContainer>
        </BottomPanelBodyS>
      </BottomPanelContainer>
    </>
  );
}

HouseKeepingServiceTicketsReportTable.propTypes = {
  isPrint: bool,
  refetchTableData: func,
  projects: arrayOf(
    shape({
      projectId: highlightedTextPropType,
    }),
  ),
  windowedTableProps: shape({
    loadMoreItems: func,
    itemCount: number,
    loadedCount: number,
  }),
};

export function PrintHouseKeepingServiceTicketsReportTable({
  projects,
  isPrint,
}) {
  const tableProps = extractTablePropsFromConfig(getTableConfig(isPrint));
  return <PrintTableS {...tableProps} rows={projects} />;
}

PrintHouseKeepingServiceTicketsReportTable.propTypes = {
  isPrint: bool,
  projects: arrayOf(
    shape({
      projectId: string.isRequired,
    }),
  ),
};
