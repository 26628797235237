import * as R from 'ramda';
import { WorkOrderStatus, ProjectSupplierStatus } from 'poly-constants';
import { isNilOrEmpty } from 'poly-utils';

// getSelectedProjects :: [{ projectId: ID }] -> [ID]
export const getSelectedProjects = R.compose(
  R.reject(isNilOrEmpty),
  R.map(R.prop('projectId')),
  R.defaultTo([]),
);

// getProjectsQueryBySupplierId :: ID -> ElasticQuery
const getProjectsQueryBySupplierId = (supplierId) => ({
  nested: {
    path: 'suppliers',
    query: {
      bool: {
        must: [
          { match: { 'suppliers.supplierId': supplierId } },
          {
            match: {
              'suppliers.statusInProject': ProjectSupplierStatus.assigned,
            },
          },
        ],
      },
    },
  },
});

// getProjectsQueryBySelectedProjects :: [ID] -> ElasticQuery
const getProjectsQueryBySelectedProjects = (selectedProjects) => ({
  terms: { _id: selectedProjects },
});

// getProjectsQueryByStatus :: [ID] -> ElasticQuery
const getProjectsQueryByStatus = () => ({
  terms: {
    status: [
      WorkOrderStatus.BLOCKED,
      WorkOrderStatus.COMPLETED,
      WorkOrderStatus.REJECTED,
    ],
  },
});

// generateProjectSelectQuery :: { supplierId: ID, selectedProjects: [ID] } -> ElasticQuery
export const generateProjectSelectQuery = R.ifElse(
  R.prop('supplierId'),
  R.compose(
    R.objOf('bool'),
    R.converge(R.mergeLeft, [
      R.compose(
        R.objOf('must'),
        getProjectsQueryBySupplierId,
        R.prop('supplierId'),
      ),
      R.compose(
        R.objOf('must_not'),
        R.reject(isNilOrEmpty),
        R.juxt([
          getProjectsQueryByStatus,
          R.compose(
            R.unless(R.isEmpty, getProjectsQueryBySelectedProjects),
            R.prop('selectedProjects'),
          ),
        ]),
      ),
    ]),
  ),
  R.always(null),
);

// calculateTotalByLine :: FormLine -> Float
export const calculateTotalByLine = R.compose(
  R.sum,
  R.juxt([
    R.propOr(0, 'taxAmount'),
    R.propOr(0, 'labourCost'),
    R.propOr(0, 'materialCost'),
  ]),
);
