import { prepareExcelWorkbookByConfig } from 'poly-utils';

import { downloadExcelWorkbook } from './download.js';

// eslint-disable-next-line import/no-unused-modules
export const DEFAULT_EXCEL_EXPORT_FILE_NAME = 'poly-excel-report.xlsx';

// performExcelExport :: Config -> Promise Error Boolean
//   Config = {
//      exportFileName: String
//      ...ExcelExportData
//   }
export const performExcelExport = ({
  exportFileName = DEFAULT_EXCEL_EXPORT_FILE_NAME,
  columnWidths = [],
  rows = [],
}) => {
  const workbook = prepareExcelWorkbookByConfig({ columnWidths, rows });

  return downloadExcelWorkbook(exportFileName, workbook);
};
