import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import React, { useState, useCallback, useMemo } from 'react';
import { string, shape, func, arrayOf, bool } from 'prop-types';
import {
  DEBOUNCE_USER_INPUT_THRESHOLD,
  useReactiveQuery,
} from 'poly-client-utils';
import { debounce, isNilOrEmpty } from 'poly-utils';
import { FormCreator } from 'poly-book-admin';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  useNotificationState,
  MultiSelectDropDown,
  MAX_ITEMS,
  entities,
} from 'poly-admin-ui';
import {
  ELASTIC_SCORE_FIELD,
  DESC_SORT_ORDER,
  AssetStatuses,
} from 'poly-constants';

import { ALL } from '../../../modules/core/constants/general.js';
import { assetsUpdateMutationByEntityMap } from '../../components/commonTabs/useUnlinkAssetMutation.js';
import { formatAssetTitle } from '../assetSidebarUtils.js';
import { ASSETS_BY_SEARCH_SUB } from '../../../components/AssetSelect.js';
import { useAssetsSelectEntitySubscription } from './useAssetsSelectEntitySubscription.js';

export const AllAssetsOption = { value: ALL, label: 'All' };

const SEARCH_ASSETS_LIST = gql`
  query SEARCH_ASSETS_LIST($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        _id
        type {
          _id
          name
        }
        displayName
        qrCodeId
        manufacturerDoc {
          _id
          name
        }
      }
      total
    }
  }
`;

// getAssetsByQueryWithoutAttached :: [ID] -> SearchAssetsResult -> [Asset]
const getAssetsByQueryWithoutAttached = (attachedAssets) =>
  R.compose(
    R.reject(R.propSatisfies(R.includes(R.__, attachedAssets), '_id')),
    R.pathOr([], ['searchAssets', 'hits']),
  );

// prepareAssetsOptions :: [ID] -> SearchAssetsResult -> [Option]
const prepareAssetsOptions = (attachedAssets) =>
  R.compose(
    R.unless(R.isEmpty, R.prepend(AllAssetsOption)),
    R.map(
      R.applySpec({
        value: R.prop('_id'),
        label: formatAssetTitle,
      }),
    ),
    getAssetsByQueryWithoutAttached(attachedAssets),
  );

// getAssetQueryByProperties :: FormData -> ElasticQuery
const getAssetQueryByProperties = R.compose(
  R.unless(R.isEmpty, R.compose(R.objOf('terms'), R.objOf('propertyId'))),
  R.reject(isNilOrEmpty),
  R.unnest,
  R.juxt([R.prop('propertyId'), R.propOr([], 'subPropertiesIds')]),
);

// getAssetQueryByType :: FormData -> ElasticQuery
const getAssetQueryByType = R.compose(
  R.ifElse(
    R.propEq('length', 1),
    R.always(null),
    R.compose(R.objOf('bool'), R.objOf('should')),
  ),
  R.reject(isNilOrEmpty),
  R.juxt([
    R.compose(
      R.unless(isNilOrEmpty, R.compose(R.objOf('terms'), R.objOf('type._id'))),
      R.prop('types'),
    ),
    R.always({ bool: { must_not: { exists: { field: 'type' } } } }),
  ]),
);

// prepareSearchAssetsQuery :: FormData -> ElasticQuery
const prepareSearchAssetsQuery = R.compose(
  R.objOf('bool'),
  R.objOf('must'),
  R.reject(isNilOrEmpty),
  R.juxt([
    R.always({ match: { status: AssetStatuses.ACTIVE } }),
    getAssetQueryByProperties,
    getAssetQueryByType,
  ]),
  R.defaultTo({}),
);

// checkIfAllOptionSelected :: [Option] -> Boolean
const checkIfAllOptionSelected = R.compose(
  R.includes(ALL),
  R.map(R.prop('value')),
  R.defaultTo([]),
);

// getAllAssetsIdsByQuery :: [ID] -> SearchAssetsResult -> [ID]
const getAllAssetsIdsByQuery = (attachedAssets) =>
  R.compose(
    R.map(R.prop('_id')),
    getAssetsByQueryWithoutAttached(attachedAssets),
  );

export function AssetsSelect({
  entity,
  onChange,
  formData,
  disabled,
  changeFieldValue,
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const { propertyId, attachedAssets } = formData;

  const { projectSubQuery, projectSubOptions } =
    useAssetsSelectEntitySubscription(entity);

  const query = useMemo(() => prepareSearchAssetsQuery(formData), [formData]);

  const queryOptions = {
    variables: {
      input: {
        query,
        searchTerm,
        size: MAX_ITEMS,
        sort: [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
      },
    },
    skip: !propertyId || !!disabled,
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_ASSETS_LIST,
    [ASSETS_BY_SEARCH_SUB, projectSubQuery],
    {
      queryOptions,
      subscriptionOptions: [queryOptions, projectSubOptions],
    },
  );

  const options = useMemo(
    () => prepareAssetsOptions(attachedAssets)(data),
    [data, attachedAssets],
  );

  const preparedAllAssetsIds = useMemo(
    () => getAllAssetsIdsByQuery(attachedAssets)(data),
    [attachedAssets, data],
  );

  const onSearchDebounced = useCallback(
    debounce(DEBOUNCE_USER_INPUT_THRESHOLD)(setSearchTerm),
    [],
  );

  const onInputChange = (value) => onSearchDebounced(value.trim());

  const handleChange = (val) => {
    const isAllOptionSelected = checkIfAllOptionSelected(val);

    onChange(isAllOptionSelected ? [AllAssetsOption] : val);

    if (isAllOptionSelected && R.is(Function, changeFieldValue)) {
      changeFieldValue('allAssetsIds', preparedAllAssetsIds);
    }
  };

  const selectProps = {
    ...props,
    loading,
    options,
    handleChange,
    onInputChange,
    required: true,
    maxMenuHeight: 250,
    isDisabled: disabled,
    onSearchChange: setSearchTerm,
    placeholder: 'Start typing assets',
  };

  return <MultiSelectDropDown {...selectProps} />;
}

AssetsSelect.propTypes = {
  disabled: bool,
  changeFieldValue: func,
  onChange: func.isRequired,
  entity: shape({ _id: string, name: string }),
  formData: shape({
    propertyId: string,
    types: arrayOf(string),
    allAssetsIds: arrayOf(string),
    attachedAssets: arrayOf(string).isRequired,
  }).isRequired,
};

const getAttachAssetSections = (entity) => [
  {
    order: 1,
    layout: { margin: '24px 0 0 0' },
    fields: [
      {
        order: 1,
        label: 'Assets',
        layout: { row: 1, with: '100%' },
        field: {
          name: 'assetIds',
          withFormData: true,
          Component: AssetsSelect,
          withChangeFieldValue: true,
          additionalProps: { entity },
        },
        validators: [
          [R.complement(R.isEmpty), 'At least one asset should be added'],
        ],
      },
    ],
  },
];

// prepareAttachedAssetsForMutation :: (ID, String, [ID]) -> FormData -> MutationVariables
const prepareAttachedAssetsForMutation = (id, name, assetIds) =>
  R.compose(
    R.mergeLeft({ id }),
    R.when(() => name === entities.PROJECT, R.objOf('update')),
    R.objOf('assetIds'),
    R.concat(assetIds),
    R.ifElse(
      R.compose(checkIfAllOptionSelected, R.prop('assetIds')),
      R.prop('allAssetsIds'),
      R.compose(R.map(R.prop('value')), R.prop('assetIds')),
    ),
  );

export function AttachAssetForm({ assetIds, formId, entity, onCancel }) {
  const { showSuccessNotification } = useNotificationState();

  const { _id, name, propertyId, subPropertiesIds } = entity;
  const MUTATION = assetsUpdateMutationByEntityMap[name];

  const [attachAssetsMutation] = useMutation(MUTATION);

  const onSubmitHandler = async (formData) => {
    const variables = prepareAttachedAssetsForMutation(
      _id,
      name,
      assetIds,
    )(formData);

    await attachAssetsMutation({ variables });

    showSuccessNotification('Assets were successfully attached');
    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const initialValues = {
    propertyId,
    assetIds: [],
    allAssetsIds: [],
    subPropertiesIds,
    attachedAssets: assetIds,
  };

  return (
    <FormCreator
      id={formId}
      onSubmit={onSubmit}
      initialValues={initialValues}
      sections={getAttachAssetSections(entity)}
      layout={commonSidebarFormLayout}
      fieldLayout={{ ...commonSidebarFormFieldLayout, width: '100%' }}
      sectionLayout={{
        ...commonSidebarFormSectionLayout,
        padding: '0 0 24px 0',
      }}
    />
  );
}

AttachAssetForm.propTypes = {
  formId: string.isRequired,
  onCancel: func.isRequired,
  assetIds: arrayOf(string).isRequired,
  entity: shape({
    payloadName: string,
    _id: string.isRequired,
    name: string.isRequired,
    propertyId: string,
  }).isRequired,
};
