import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useState, useMemo, useEffect } from 'react';
import { string, func, shape, oneOfType, arrayOf } from 'prop-types';
import { SelectComponents } from 'poly-book-admin';

import {
  EditVariableIconS,
  AddVariableOptionS,
  UserGroupVariablesSelectS,
} from './userGroupFormComponents.js';
import { userGroupScopeAddVariableOptionId } from '../../constants.js';
import { UserGroupScopeVariableInput } from './UserGroupScopeVariableInput.js';

// prepareUserGroupVariablesOptions :: ({
//   userGroupVariables: Object,
//   formLineData: { scope: String },
// }) -> [Option]
const prepareUserGroupVariablesOptions = R.compose(
  R.defaultTo([]),
  R.converge(R.prop, [
    R.path(['formLineData', 'scope']),
    R.prop('userGroupVariables'),
  ]),
);

// checkHasVariables :: { options: [Option] } -> Boolean
const checkHasVariables = R.compose(R.gt(R.__, 1), R.length, R.prop('options'));

function CustomOption(props) {
  const { value, setInputMode } = props;

  if (value === userGroupScopeAddVariableOptionId) {
    const withBorder = checkHasVariables(props);

    return (
      <AddVariableOptionS
        withBorder={withBorder}
        onClick={() => setInputMode('add')}
      >
        + Add new variable
      </AddVariableOptionS>
    );
  }
  return <SelectComponents.Option {...props} />;
}

CustomOption.propTypes = {
  value: string.isRequired,
  setInputMode: func.isRequired,
};

// getCustomOption :: Function -> OptionProps -> ReactComponent
const getCustomOption = (setInputMode) =>
  function (props) {
    return <CustomOption {...props} setInputMode={setInputMode} />;
  };

function CustomValue({ setInputMode, ...props }) {
  const onVariableEdit = () => setInputMode('save');

  return (
    <>
      <SelectComponents.SingleValue {...props} />
      <EditVariableIconS
        size={10}
        name="edit"
        color="#575757"
        onClick={onVariableEdit}
      />
    </>
  );
}

CustomValue.propTypes = {
  children: string.isRequired,
  setInputMode: func.isRequired,
};

// getCustomValue :: Function -> ValueProps -> ReactComponent
const getCustomValue = (setInputMode) =>
  function (props) {
    return <CustomValue {...props} setInputMode={setInputMode} />;
  };

// getPreparedValue :: Any -> String
const getPreparedValue = R.cond([
  [R.is(Array), R.head],
  [R.is(Object), R.prop('value')],
  [R.T, R.identity],
]);

// getSelectedOptionById :: ID -> [Option] -> Option
const getSelectedOptionById = (selectedId) =>
  R.compose(R.find(R.propEq('value', selectedId)), R.defaultTo([]));

export function UserGroupScopeVariablesSelect({
  value,
  onChange,
  onLineChange,
  formLineData,
  ...props
}) {
  const [inputMode, setInputMode] = useState(null);
  const userGroupVariables = useSelector((store) => store.userGroupVariables);

  const scope = useMemo(() => formLineData.scope, [formLineData]);

  const inEditMode = useMemo(() => formLineData.inEditMode, [formLineData]);

  const preparedValue = useMemo(() => getPreparedValue(value), [value]);

  const options = useMemo(
    () =>
      prepareUserGroupVariablesOptions({ formLineData, userGroupVariables }),
    [formLineData, userGroupVariables],
  );

  const selectedOption = useMemo(
    () => getSelectedOptionById(preparedValue)(options),
    [options, preparedValue],
  );

  useEffect(() => {
    if (R.is(Array, value) && selectedOption) {
      onChange(selectedOption);
    }
  }, [value, selectedOption]);

  useEffect(() => {
    if (inputMode && !inEditMode) {
      onLineChange({ ...formLineData, inEditMode: true });
    }

    if (!inputMode && inEditMode) {
      onLineChange({ ...formLineData, inEditMode: false });
    }
  }, [inputMode]);

  const userGroupVariablesSelectProps = {
    ...props,
    value: preparedValue,
    onChange: (_, option) => onChange(option),
    placeholder: 'Select variable name or add new',
    options: R.prepend({
      label: '',
      value: userGroupScopeAddVariableOptionId,
    })(options),
    components: {
      Option: getCustomOption(setInputMode),
      SingleValue: getCustomValue(setInputMode),
    },
  };

  return inputMode ? (
    <UserGroupScopeVariableInput
      value={preparedValue}
      {...{ setInputMode, options, scope, inputMode, onChange }}
    />
  ) : (
    <UserGroupVariablesSelectS {...userGroupVariablesSelectProps} />
  );
}

UserGroupScopeVariablesSelect.propTypes = {
  onChange: func.isRequired,
  onLineChange: func.isRequired,
  formLineData: shape({ scope: string }).isRequired,
  value: oneOfType([
    string,
    arrayOf(string),
    shape({ value: string, label: string }),
  ]),
};
