import React from 'react';
import styled from 'styled-components';
import { getThemeColor, Popover, PopoverLink } from 'poly-book-admin';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import {
  USER_GROUP_ID_AIT,
  CREATE_USER_PERMISSION,
  FLIPPED_CONTAINS_AIT_OPERATOR,
} from 'poly-security';

import { FlexColumn } from '../../../components/FlexContainer.js';
import { useAddUserSidebar } from '../../../sidebars/UserSidebar/forms/add/useAddUserSidebar.js';
import { useAllowedUserGroupIds } from './useAllowedUserGroupIds.js';

const AddUserPopoverContainerS = styled(FlexColumn)`
  z-index: 111;
`;

const PopoverS = styled(Popover)`
  button {
    padding: 0 10px 15px 0;
    margin-right: 0;
    font-size: 12px;
  }
`;

const PopoverLinkS = styled(PopoverLink)`
  padding: 4px 15px;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

export function AddNewStaffBtn() {
  const allowedUserGroupIds = useAllowedUserGroupIds();

  const hasPermission = useHasUserAccessWithPermission(CREATE_USER_PERMISSION, {
    [USER_GROUP_ID_AIT]: {
      [FLIPPED_CONTAINS_AIT_OPERATOR]: allowedUserGroupIds,
    },
  });

  const openUserForm = useAddUserSidebar();

  return hasPermission ? (
    <PopoverS
      title="Add New"
      position="left"
      content={
        <AddUserPopoverContainerS>
          <PopoverLinkS onClick={() => openUserForm({ isContractor: false })}>
            Staff
          </PopoverLinkS>
          <PopoverLinkS onClick={() => openUserForm({ isContractor: true })}>
            Contractor
          </PopoverLinkS>
        </AddUserPopoverContainerS>
      }
    />
  ) : null;
}
