import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import {
  DatePicker,
  FieldLayout,
  Input,
  LinkButton,
  RadioButton,
  useFormSubscription,
} from 'poly-book-admin';
import { gql, useMutation } from '@apollo/client';
import {
  MoneyInput,
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from 'poly-admin-ui';
import { useNavigate, usePristineSubscribe } from 'poly-client-routing';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { number, func, shape, string, arrayOf } from 'prop-types';
import { alwaysNewDate, calculateTotal } from 'poly-utils';
import { JournalPaymentMode } from 'poly-constants';

import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FlexColumn } from '../../components/FlexContainer.js';
import { halfWidth } from '../../modules/forms/common.js';
import { BankAccountSelect } from '../../components/AccountsSelect.js';
import { scheduleRepeatsBy } from '../../modules/core/constants/schedule.js';
import { PayRollProviderSelect } from './PayrollProviderSelect.js';
import { InputPayRollLIneField } from './InputPayrollLineField.js';
import { onKeyDownToPreventFormSubmit } from '../../utils/form.js';

const InputPayRollFormS = styled.form`
  width: 100%;
`;

const HalfSectionS = styled(FlexColumn)`
  width: ${halfWidth};
`;

function PaymentModeInput({ value, ...props }) {
  return (
    <RadioButton
      name={scheduleRepeatsBy.month}
      value={value === JournalPaymentMode.ACH}
      label="ACH"
      {...props}
    />
  );
}

PaymentModeInput.propTypes = {
  value: string.isRequired,
};

const FlexSpaceBetweenS = styled(FlexSpaceBetween)`
  width: 100%;
`;

const bigInputWidth = 'calc(40% - 15px)';
const smallInputWidth = 'calc(30% - 15px)';

const TotalContainer = styled(FlexSpaceBetween)`
  margin-bottom: 15px;
  padding-right: 37px;
`;

export const inputPayrollFormId = 'inputPayrollFormId';

function PayrollDataPicker(props) {
  return <DatePicker {...props} onKeyDown={onKeyDownToPreventFormSubmit} />;
}

function InputPayrollFormRendered({ form, handleSubmit, values }) {
  const totalAmount = calculateTotal(R.prop('amount'), values.lines);
  const { formSubscription } = usePristineSubscribe();
  useFormSubscription(form.subscribe, formSubscription);
  return (
    <InputPayRollFormS id={inputPayrollFormId} onSubmit={handleSubmit}>
      <FlexSpaceBetween>
        <HalfSectionS>
          <FlexSpaceBetweenS>
            <FieldLayout
              required
              label="Supplier"
              layout={{ width: bigInputWidth }}
              field={{
                name: 'supplierId',
                additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
                Component: PayRollProviderSelect,
              }}
              validators={[[R.identity, 'Supplier is required']]}
            />
            <FieldLayout
              required
              label="Invoice Number"
              layout={{ width: smallInputWidth }}
              field={{
                name: 'invoiceNumber',
                additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
                Component: Input,
              }}
              validators={[[R.identity, 'Invoice Number is required']]}
            />
            <FieldLayout
              required
              label="Invoice Date"
              layout={{ width: smallInputWidth }}
              field={{
                name: 'invoiceDate',
                Component: PayrollDataPicker,
                additionalProps: { width: '100%' },
              }}
              validators={[[R.identity, 'Invoice Date is required']]}
            />
          </FlexSpaceBetweenS>
          <FlexSpaceBetweenS>
            <FieldLayout
              required
              label="Bank Name"
              layout={{ width: bigInputWidth }}
              field={{
                name: 'bankAccountId',
                additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
                Component: BankAccountSelect,
              }}
              validators={[[R.identity, 'Bank is required']]}
            />
            <FieldLayout
              required
              label="Payment Date"
              layout={{ width: smallInputWidth }}
              field={{
                name: 'paymentDate',
                Component: PayrollDataPicker,
                additionalProps: { width: '100%' },
              }}
              validators={[[R.identity, 'Payment Date is required']]}
            />
            <FieldLayout
              required
              label="Payment Mode"
              layout={{ width: smallInputWidth }}
              field={{
                name: 'paymentType',
                additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
                Component: PaymentModeInput,
              }}
            />
          </FlexSpaceBetweenS>
        </HalfSectionS>

        <HalfSectionS>
          <FieldLayout
            layout={{ padding: 0 }}
            field={{
              name: 'lines',
              isArrayField: true,
              Component: InputPayRollLIneField,
            }}
          />
          <TotalContainer>
            <LinkButton
              type="button"
              onClick={() => form.mutators.push('lines', {})}
            >
              Add New
            </LinkButton>
            <MoneyInput
              disabled
              height="30px"
              width="30%"
              name="totalAmount"
              value={totalAmount}
            />
          </TotalContainer>
        </HalfSectionS>
      </FlexSpaceBetween>
    </InputPayRollFormS>
  );
}

InputPayrollFormRendered.propTypes = {
  form: shape({
    subscribe: func.isRequired,
    mutators: shape({
      push: func.isRequired,
      remove: func.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    lines: arrayOf(shape({ glCode: string, amount: number })).isRequired,
  }).isRequired,
  handleSubmit: func.isRequired,
};

const PAYROLL_CODES = [
  '61301',
  '61302',
  '61304',
  '61305',
  '61306',
  '61311',
  '61313',
  '61315',
  '61316',
  '61317',
  '61318',
  '61319',
  '61320',
  '61321',
  '61325',
  '61330',
  '61333',
];

// inputPayrollInitialValues :: [Account] -> InputPayrollInput
const inputPayrollInitialValues = R.applySpec({
  invoiceDate: alwaysNewDate,
  paymentDate: alwaysNewDate,
  paymentType: R.always(JournalPaymentMode.ACH),
  lines: R.map(R.applySpec({ glCode: R.identity })),
});

const inputPayrollMutation = gql`
  mutation inputPayroll($input: InputPayrollInput!) {
    inputPayroll(input: $input) {
      _id
    }
  }
`;

// formatPayrollInput :: InputPayrollInput -> InputPayrollInput
const formatPayrollInput = R.over(
  R.lensProp('lines'),
  R.filter(R.propSatisfies(R.gt(R.__, 0), 'amount')),
);

export function InputPayrollForm() {
  const [inputPayroll] = useMutation(inputPayrollMutation);
  const { showSuccessNotification } = useNotificationState();
  const navigate = useNavigate();

  const submitHandler = async (values) => {
    await inputPayroll({
      variables: { input: formatPayrollInput(values) },
    });
    showSuccessNotification('Payroll successfully processed and paid');
    navigate(0);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    inputPayrollFormId,
    submitHandler,
  );

  return (
    <Form
      subscription={{ values: true }}
      keepDirtyOnReinitialize
      mutators={arrayMutators}
      formId={inputPayrollFormId}
      onSubmit={onSubmit}
      initialValues={inputPayrollInitialValues(PAYROLL_CODES)}
    >
      {({ handleSubmit, form, values }) => (
        <InputPayrollFormRendered
          handleSubmit={handleSubmit}
          form={form}
          values={values}
        />
      )}
    </Form>
  );
}
