import React from 'react';
import { string } from 'prop-types';
import { useModalContext } from 'poly-admin-ui';
import { Icon, LinkButton } from 'poly-book-admin';

import {
  CreateClientDistrictForm,
  createClientDistrictFormId,
  EditClientDistrictForm,
  editClientDistrictFormId,
} from './ClientDistrictForm.js';

export function AddClientDistrictBtn({ clientId, clientName }) {
  const { openModalForm } = useModalContext();

  const openAddDistrictForm = () =>
    openModalForm({
      id: createClientDistrictFormId,
      formId: createClientDistrictFormId,
      title: 'Add client district',
      subTitle: clientName,
      btnCaption: 'Save',
      content: <CreateClientDistrictForm clientId={clientId} />,
    });

  return <LinkButton onClick={openAddDistrictForm}>Add District</LinkButton>;
}

AddClientDistrictBtn.propTypes = {
  clientId: string.isRequired,
  clientName: string.isRequired,
};

export function EditClientDistrictBtn({
  clientId,
  clientName,
  districtId,
  ...props
}) {
  const { openModalForm } = useModalContext();

  const iconProps = {
    size: 12,
    color: '#4676b4',
  };

  const openEditDistrictForm = () =>
    openModalForm({
      id: editClientDistrictFormId,
      formId: editClientDistrictFormId,
      title: 'Edit client district',
      subTitle: clientName,
      btnCaption: 'Save',
      content: (
        <EditClientDistrictForm
          clientId={clientId}
          districtId={districtId}
          {...props}
        />
      ),
    });

  return (
    <Icon
      data-testid={`edit-district${districtId}`}
      name="edit"
      onClick={openEditDistrictForm}
      {...iconProps}
    />
  );
}

EditClientDistrictBtn.propTypes = {
  clientId: string.isRequired,
  clientName: string.isRequired,
  districtId: string.isRequired,
};
