import React from 'react';
import styled from 'styled-components';
import { Input } from 'poly-book-admin';

const SmallInput = styled(Input)`
  width: 100%;
`;

const ExtWrapperS = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export function ExtInput(props) {
  return (
    <ExtWrapperS>
      <SmallInput
        {...props}
        placeholder="Enter Ext"
        type="numberMask"
        format="######"
      />
    </ExtWrapperS>
  );
}
