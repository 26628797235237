import React from 'react';
import {
  string,
  shape,
  func,
  bool,
  arrayOf,
  number,
  oneOfType,
  node as nodePropType,
} from 'prop-types';
import { isDescendant } from '@nosferatu500/react-sortable-tree';
import { getThemeColor } from 'poly-book-admin';

import styled from 'styled-components';

import { classnames } from './utils.js';
import { TreeNodeContent } from './TreeNodeContent.js';
import { TreeNodeButtons } from './TreeNodeButtons.js';
import { ExpandButton } from './TreeNodeExpandButton.js';
import { treeNodePropTypes } from '../propTypes.js';

const ErrorContainer = styled.div`
  position: absolute;
  right: -210px;
  width: 200px;
  height: 35px;
  display: flex;
  align-items: center;
  color: ${getThemeColor(['accent1Lighter1'])};
  font-size: 12px;
`;

export function TreeNode({
  scaffoldBlockPxWidth,
  toggleChildrenVisibility,
  connectDragPreview,
  connectDragSource,
  isDragging,
  canDrop,
  canDrag,
  node,
  title,
  draggedNode,
  path,
  treeIndex,
  isSearchMatch,
  isSearchFocus,
  buttons,
  className,
  style,
  isOver,
  didDrop,
  treeId,
  rowDirection,
  parentNode,

  onChangeTitle,
  onAddNewNode,
  onRemoveNode,
  isEditable,
  isDisabled,
  isAddBtnHidden,
  isRemoveBtnHidden,
  isRemoveButtonDisabled,
  NodeContentRenderer,
  error,

  ...otherProps
}) {
  const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;

  const handle = connectDragSource(
    <div
      className="rst__moveHandle"
      style={{
        display: 'flex',
        flexShrink: 0,
      }}
    />,
    {
      dropEffect: 'copy',
    },
  );

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
  if (rowDirection === 'rtl') {
    buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
  }

  const addButtonHidden = isAddBtnHidden(node);
  const removeButtonHidden = isRemoveBtnHidden(node, parentNode);
  const removeButtonDisabled = isRemoveButtonDisabled(node);
  const editable = isEditable(node);
  const disabled = isDisabled(node);
  const borderHidden = removeButtonHidden && addButtonHidden;

  return (
    <div style={{ height: '100%' }} {...otherProps}>
      {toggleChildrenVisibility &&
        node.children &&
        (node.children.length > 0 || typeof node.children === 'function') && (
          <ExpandButton
            toggleChildrenVisibility={toggleChildrenVisibility}
            node={node}
            rowDirectionClass={rowDirectionClass}
            path={path}
            treeIndex={treeIndex}
            scaffoldBlockPxWidth={scaffoldBlockPxWidth}
            isDragging={isDragging}
            buttonStyle={buttonStyle}
          />
        )}

      <div className={classnames('rst__rowWrapper', rowDirectionClass)}>
        {connectDragPreview(
          <div
            className={classnames(
              'rst__row',
              isLandingPadActive && 'rst__rowLandingPad',
              isLandingPadActive && !canDrop && 'rst__rowCancelPad',
              isSearchMatch && 'rst__rowSearchMatch',
              isSearchFocus && 'rst__rowSearchFocus',
              rowDirectionClass,
              className,
            )}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style,
            }}
          >
            {handle}

            <div
              className={classnames(
                'rst__rowContents',
                !canDrag && 'rst__rowContentsDragDisabled',
                rowDirectionClass,
              )}
              style={{ width: '300px' }}
            >
              <TreeNodeContent
                node={node}
                onChangeTitle={onChangeTitle}
                rowDirectionClass={rowDirectionClass}
                editable={editable}
                disabled={disabled}
                borderHidden={borderHidden}
                NodeContentRenderer={NodeContentRenderer}
              />
              <TreeNodeButtons
                node={node}
                onAddNewNode={onAddNewNode}
                onRemoveNode={onRemoveNode}
                addButtonHidden={addButtonHidden}
                removeButtonHidden={removeButtonHidden}
                removeButtonDisabled={removeButtonDisabled}
              />
              {error && <ErrorContainer>{error}</ErrorContainer>}
            </div>
          </div>,
        )}
      </div>
    </div>
  );
}

TreeNode.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: '',
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  rowDirection: 'ltr',
};

TreeNode.propTypes = {
  node: treeNodePropTypes,
  title: oneOfType([func, nodePropType]),
  path: arrayOf(oneOfType([string, number])).isRequired,
  treeIndex: number.isRequired,
  treeId: string.isRequired,
  isSearchMatch: bool,
  isSearchFocus: bool,
  canDrag: bool,
  scaffoldBlockPxWidth: number.isRequired,
  toggleChildrenVisibility: func,
  buttons: arrayOf(nodePropType),
  className: string,
  style: shape({}),
  connectDragPreview: func.isRequired,
  connectDragSource: func.isRequired,
  parentNode: shape({}),
  isDragging: bool.isRequired,
  didDrop: bool.isRequired,
  draggedNode: shape({}),
  canDrop: bool,
  isOver: bool,
  rowDirection: string,
  onChangeTitle: func,
  onAddNewNode: func,
  onRemoveNode: func,
  error: string,
  isEditable: func,
  isDisabled: func,
  isAddBtnHidden: func,
  isRemoveBtnHidden: func,
  isRemoveButtonDisabled: func,
  NodeContentRenderer: func,
};
