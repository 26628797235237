import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'poly-client-routing';

import { routesNames } from '../../routes/constants.js';
import { Setup2FAForm } from './components/Setup2FAForm.js';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
`;

export function RequiredSetup2FAPage() {
  const navigate = useNavigate();

  const handleAfterSubmit = () => {
    navigate(routesNames.HOME);
  };

  return (
    <Wrapper>
      <Setup2FAForm handleAfterSubmit={handleAfterSubmit} />
    </Wrapper>
  );
}
