import { gql } from '@apollo/client';
import { contactDetailsFields } from 'poly-admin-ui';
import { useReactiveQuery } from 'poly-client-utils';

const CONTACT_DETAILS = gql`
  query CONTACT_DETAILS($contactId: ID!) {
    contactUser(id: $contactId) {
      isStaff
      isClientPortalUser
      userGroups {
        userGroup {
          _id
        }
      }
      ...contactDetailsFields
    }
  }

  ${contactDetailsFields}
`;

const CONTACT_CHANGED = gql`
  subscription CONTACT_CHANGED($input: SingleDocSubInput!) {
    userChanged(input: $input) {
      id
      type
      document {
        _id
      }
    }
  }
`;

export const useContactSidebarData = (contactId) => {
  const queryOptions = {
    variables: { contactId },
    fetchPolicy: 'network-only',
  };

  const subscriptionOptions = { variables: { input: { id: contactId } } };

  const { data, loading } = useReactiveQuery(CONTACT_DETAILS, CONTACT_CHANGED, {
    queryOptions,
    subscriptionOptions,
  });

  return { loading, ...data };
};
