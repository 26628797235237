import React, { useContext } from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { Status } from 'poly-book-admin';
import { peopleStatuses } from 'poly-constants';
import { forceTitleCase } from 'poly-utils';

import { PeopleTableContext } from './hocs.js';
import { peopleStatusesColors } from '../../../constants/index.js';
import { useContactUsersSearchByQuery } from '../../../hooks/users/index.js';

const statusesTitles = R.pipe(
  R.values,
  R.reduce(
    (acc, status) => ({
      // eslint-disable-next-line
      [status]: ({ count }) => (
        <Status
          text={`${forceTitleCase(status)} (${count})`}
          color={peopleStatusesColors[status]}
        />
      ),
      ...acc,
    }),
    {},
  ),
)(peopleStatuses);

// getContactsTotalCount :: (SearchContactsQueryResult, Name) -> Number
const getContactsTotalCount = (data, name) =>
  R.pathOr(0, [name, 'searchContacts', 'total'], data);

export function PeopleTabTitle({ status }) {
  const { entity, gqlSearchQuery } = useContext(PeopleTableContext);

  const { result } = useContactUsersSearchByQuery({
    gqlSearchQuery,
    entity,
    status,
  });

  const TitleComp = statusesTitles[status];

  return <TitleComp count={getContactsTotalCount(result, entity?.name)} />;
}

PeopleTabTitle.displayName = 'PeopleTabTitle';

PeopleTabTitle.propTypes = {
  status: string.isRequired,
};
