import { UserEmployeeInfoStatus } from 'poly-constants';
import { searchAccessItemsByClientAppAndFullPermission } from 'poly-security';

export const adminUserQuery = [
  {
    nested: {
      path: 'userGroups',
      query: { exists: { field: 'userGroups.userGroupId' } },
    },
  },
  searchAccessItemsByClientAppAndFullPermission,
];

/**
 * getAdminUsersBySystemStatusQuery :: String -> ElasticQuery
 */
export const getAdminUsersBySystemStatusQuery = (status) => ({
  bool: {
    must: [...(status ? [{ term: { status } }] : []), ...adminUserQuery],
  },
});

/**
 * getAdminUsersQueryBase :: [Object] -> ElasticQuery
 */
export const getAdminUsersQueryBase = (mustQuery) => ({
  bool: {
    must: mustQuery,
    filter: adminUserQuery,
  },
});

/**
 * getAdminUsersByEmploymentStatusQuery :: String -> ElasticQuery
 */
export const getAdminUsersByEmploymentStatusQuery = (status) =>
  getAdminUsersQueryBase([{ term: { 'employeeInfo.status': status } }]);

export const adminActiveStaffQuery = getAdminUsersQueryBase([
  {
    bool: {
      should: [
        { term: { isContractor: false } },
        { bool: { must_not: [{ exists: { field: 'isContractor' } }] } },
      ],
    },
  },
  { term: { 'employeeInfo.status': UserEmployeeInfoStatus.ACTIVE } },
]);

export const adminActiveContractorsQuery = getAdminUsersQueryBase([
  {
    term: { isContractor: true },
  },
  { term: { 'employeeInfo.status': UserEmployeeInfoStatus.ACTIVE } },
]);
