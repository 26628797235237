import * as R from 'ramda';
import React from 'react';
import { Text } from 'poly-book-admin';
import { shape } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';
import {
  formatDate,
  formatTotal,
  ensureIsDate,
  convertCentsToDollars,
} from 'poly-utils';

import { isLastElement } from '../helpers.js';
import { getAssetLifeExpectancy } from '../../../sidebars/AssetSidebar/utils.js';
import {
  PrintProjectFlexRow,
  PrintProjectArrayText,
  PrintProjectFlexColumn,
  PrintProjectAssetImage,
  PrintProjectBlockWithLabel,
} from '../components.js';

// getProjectAssets :: Project -> [Asset]
const getProjectAssets = R.pathOr([], ['searchAssets', 'hits']);

// getAssetNameUI :: Asset -> String
const getAssetNameUI = R.compose(
  R.join(' '),
  R.reject(R.isEmpty),
  R.juxt([
    R.compose(
      R.join(' - '),
      R.reject(R.isNil),
      R.juxt([R.path(['type', 'name']), R.path(['manufacturerDoc', 'name'])]),
    ),
    R.ifElse(
      R.propSatisfies(R.isNil, 'qrCodeId'),
      R.always(''),
      R.compose(R.concat(R.__, ')'), R.concat('('), R.prop('qrCodeId')),
    ),
  ]),
);

// getAssetModelUI :: Asset -> String
const getAssetModelUI = R.pathOr(NOTHING_UI_STRING, ['modelDoc', 'name']);

// getAssetPhotoUrl :: Asset -> String
const getAssetPhotoUrl = R.path(['photo', 'url']);

// getClientAssetCodeUI :: Asset -> String
const getClientAssetCodeUI = R.propOr(NOTHING_UI_STRING, 'equipmentType');

// getAssetSerialNumberUI :: Asset -> String
const getAssetSerialNumberUI = R.propOr(NOTHING_UI_STRING, 'serial');

// getAssetTypeUI :: Asset -> String
const getAssetTypeUI = R.pathOr(NOTHING_UI_STRING, ['type', 'name']);

// getAssetLocationUI :: Asset -> String
const getAssetLocationUI = R.propOr(NOTHING_UI_STRING, 'location');

// getWarrantyExpirationUI :: Asset -> String
const getWarrantyExpirationUI = R.ifElse(
  R.propSatisfies(R.isNil, 'warrantyEnd'),
  R.always(NOTHING_UI_STRING),
  R.compose(formatDate, ensureIsDate, R.prop('warrantyEnd')),
);

// getAssetPurchasePriceUI :: Asset -> String
const getAssetPurchasePriceUI = R.ifElse(
  R.propSatisfies(R.isNil, 'purchasePrice'),
  R.always(NOTHING_UI_STRING),
  R.compose(formatTotal, R.propOr(0, 'purchasePrice')),
);

// getAssetCommissioningDateUI :: Asset -> String
const getAssetCommissioningDateUI = R.ifElse(
  R.propSatisfies(R.isNil, 'commissioningDate'),
  R.always(NOTHING_UI_STRING),
  R.compose(formatDate, R.prop('commissioningDate')),
);

// getAssetReplacementCostUI :: Asset -> String
const getAssetReplacementCostUI = R.ifElse(
  R.propSatisfies(R.isNil, 'replacementCost'),
  R.always(NOTHING_UI_STRING),
  R.compose(formatTotal, convertCentsToDollars, R.prop('replacementCost')),
);

// getAssetLifeExpectancyUI :: Asset -> String
const getAssetLifeExpectancyUI = R.compose(
  R.defaultTo(NOTHING_UI_STRING),
  getAssetLifeExpectancy,
);

export function PrintProjectAssetDetails({ project, config }) {
  const { isIncludeAssetDetailsInfo } = config;

  const projectAssets = getProjectAssets(project);

  if (R.isEmpty(projectAssets)) {
    return null;
  }

  return (
    <PrintProjectFlexColumn withBottomBorder>
      <PrintProjectFlexRow>
        <Text size="16px" weight="bold">
          Attached Assets
        </Text>
      </PrintProjectFlexRow>
      <PrintProjectFlexRow withBottomBorder={isIncludeAssetDetailsInfo}>
        <PrintProjectBlockWithLabel
          label="Asset Name"
          Component={PrintProjectArrayText}
          arrayValue={projectAssets.map(getAssetNameUI)}
          arrayKeyPart={project?.projectId}
        />
        <PrintProjectBlockWithLabel
          label="Model"
          Component={PrintProjectArrayText}
          arrayValue={projectAssets.map(getAssetModelUI)}
          arrayKeyPart={project?.projectId}
        />
      </PrintProjectFlexRow>
      {isIncludeAssetDetailsInfo &&
        projectAssets.map((asset, index) => (
          <PrintProjectFlexRow
            key={`${asset._id}-key`}
            withBottomBorder={!isLastElement(index, projectAssets)}
          >
            <PrintProjectFlexColumn>
              <PrintProjectAssetImage url={getAssetPhotoUrl(asset)} />
            </PrintProjectFlexColumn>
            <PrintProjectFlexColumn>
              <PrintProjectFlexRow width="770px">
                <PrintProjectBlockWithLabel
                  label="Asset Name"
                  value={getAssetNameUI(asset)}
                />
              </PrintProjectFlexRow>
              <PrintProjectFlexRow width="770px">
                <PrintProjectBlockWithLabel
                  label="Client Asset Code"
                  value={getClientAssetCodeUI(asset)}
                />
                <PrintProjectBlockWithLabel
                  label="Manufacturer"
                  value={getAssetNameUI(asset)}
                />
                <PrintProjectBlockWithLabel
                  label="Model"
                  value={getAssetModelUI(asset)}
                />
              </PrintProjectFlexRow>
              <PrintProjectFlexRow width="770px">
                <PrintProjectBlockWithLabel
                  label="Serial Number"
                  value={getAssetSerialNumberUI(asset)}
                />
                <PrintProjectBlockWithLabel
                  label="Asset Type"
                  value={getAssetTypeUI(asset)}
                />
                <PrintProjectBlockWithLabel
                  label="Location"
                  value={getAssetLocationUI(asset)}
                />
              </PrintProjectFlexRow>
              <PrintProjectFlexRow width="770px">
                <PrintProjectBlockWithLabel
                  label="Warranty Expiration"
                  value={getWarrantyExpirationUI(asset)}
                />
                <PrintProjectBlockWithLabel
                  label="Purchase Price"
                  value={getAssetPurchasePriceUI(asset)}
                />
                <PrintProjectBlockWithLabel
                  label="Commissioning Date"
                  value={getAssetCommissioningDateUI(asset)}
                />
              </PrintProjectFlexRow>
              <PrintProjectFlexRow width="770px">
                <PrintProjectBlockWithLabel
                  label="Asset Replacement Cost"
                  value={getAssetReplacementCostUI(asset)}
                />
                <PrintProjectBlockWithLabel
                  label="Life Expectancy (years)"
                  value={getAssetLifeExpectancyUI(asset)}
                />
                <PrintProjectBlockWithLabel label="" value={null} />
              </PrintProjectFlexRow>
            </PrintProjectFlexColumn>
          </PrintProjectFlexRow>
        ))}
    </PrintProjectFlexColumn>
  );
}

PrintProjectAssetDetails.propTypes = {
  config: shape({}),
  project: shape({}),
};
