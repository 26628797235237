import * as R from 'ramda';
import { ProjectPrioritiesUIMap } from 'poly-constants';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
  formatDateProp,
} from 'poly-client-utils';

const RECURRING_COLUMN_WIDTH = [15, 15, 25, 15, 15, 15, 15, 15];
const PROJECTS_COLUMN_WIDTH = R.insert(1, 15, RECURRING_COLUMN_WIDTH);

const RECURRING_TITLE_ROW = [
  'Status',
  'Project Id',
  'Description',
  'Property',
  'Client',
  'Manager',
  'Start Date',
  'End Date',
];
const PROJECTS_TITLE_ROW = R.insert(1, 'Priority', RECURRING_TITLE_ROW);

// getProjectReportRowConfig :: Project -> [ExcelExportDataCell]
const getProjectReportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 9),
  ]),
  R.juxt([
    R.propOr('', 'status'),
    R.compose(R.prop(R.__, ProjectPrioritiesUIMap), R.prop('priority')),
    R.propOr('', 'projectId'),
    R.propOr('', 'description'),
    R.pathOr('', ['property', 'name']),
    R.pathOr('', ['client', 'name']),
    R.pathOr('', ['manager', 'fullName']),
    formatDateProp('startDate'),
    formatDateProp('endDate'),
  ]),
);

// getRecurringProjectReportRowConfig :: Project -> [ExcelExportDataCell]
const getRecurringProjectReportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
  ]),
  R.juxt([
    R.propOr('', 'status'),
    R.propOr('', 'projectId'),
    R.propOr('', 'description'),
    R.pathOr('', ['property', 'name']),
    R.pathOr('', ['client', 'name']),
    R.pathOr('', ['manager', 'fullName']),
    formatDateProp('startDate'),
    formatDateProp('endDate'),
  ]),
);

// getXlsProjectsConfig :: (String, [Project]) -> Config
// eslint-disable-next-line import/no-unused-modules
export const getXlsProjectsConfig = (fileName, projects) => ({
  exportFileName: `${fileName}.xlsx`,
  columnWidths: PROJECTS_COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 9)],
      PROJECTS_TITLE_ROW,
    ),
    ...R.map(getProjectReportRowConfig, projects),
  ],
});

// getXlsRecurringProjectsConfig :: (String, [Project]) -> Config
// eslint-disable-next-line import/no-unused-modules
export const getXlsRecurringProjectsConfig = (fileName, projects) => ({
  exportFileName: `${fileName}.xlsx`,
  columnWidths: RECURRING_COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      RECURRING_TITLE_ROW,
    ),
    ...R.map(getRecurringProjectReportRowConfig, projects),
  ],
});

export const generateProjectsXlsReport = (fileName) => (projects) => {
  const exportConfig = getXlsProjectsConfig(fileName, projects);
  performExcelExport(exportConfig);
};

export const generateRecurringProjectsXlsReport = (fileName) => (projects) => {
  const exportConfig = getXlsRecurringProjectsConfig(fileName, projects);
  performExcelExport(exportConfig);
};
