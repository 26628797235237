import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  useHasUserAccessToUpdateDocuments,
  useOutSidebarContext,
} from 'poly-client-utils';
import { FileViewer, Loader } from 'poly-book-admin';
import { supplierDocumentRequestTypes } from 'poly-constants';
import { useSetQueryParams, useRouterQuery } from 'poly-client-routing';
import { bool, shape, string } from 'prop-types';
import * as R from 'ramda';

import {
  ApproveButton,
  FlexContainer,
  TextWithLabel,
  DocumentTypeWithDot,
  ExpirationDateComponent,
  EthnicitySelectComponent,
} from './components.js';
import {
  SubcontractorAgreementInfo,
  subcontractorAgreementPropTypes,
} from './SubcontractorAgreementInfo.js';
import {
  approveSupplierOnboardingFormId,
  ReviewSupplierOnboardingSidebarForm,
} from './ReviewSupplierOnboardingSidebarForm.js';
import { SidebarIDs } from '../../../sidebars/constants.js';
import { SupplierContactsPopover } from './SupplierContactsPopover.js';
import { SidebarCloseIcon } from '../../../components/SidebarLayouts.js';
import { RejectRequestedDocumentButton } from './RejectRequestedDocumentButton.js';
import { supplierDocumentNamesUI } from '../RequestedSupplierDocumentsReviewTable.js';
import { PreviewDocumentsNavigation } from '../../../components/PreviewDocumentsNavigation.js';
import { useApproveRequestedSupplierDocument } from './useApproveRequestedSupplierDocument.js';
import { useRequestedSupplierDocument } from './useRequestedSupplierDocument.js';

const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SidebarWrapper = styled(FlexColumnWrapper)`
  display: flex;
  flex-direction: ${({ isOnboarding }) => (isOnboarding ? 'row' : 'column')};
  height: 100%;
  font-size: 12px;
  overflow: hidden;
  width: ${R.prop('width')};
`;

const LeftSideWrapper = styled.div`
  height: 100%;
`;

const RightSideWrapper = styled.div`
  height: 100%;
  width: 900px;
  padding: 0 25px;
  border-right: 1px solid #e4e8eb;
`;

const ContentWrapper = styled(FlexColumnWrapper)`
  padding: 14px 24px 0 24px;
  height: calc(100% - 82px);
`;

const BottomPanelContainer = styled(FlexContainer)`
  height: 82px;
  padding: 24px;
  box-shadow: 1px 4px 40px 11px rgba(58, 56, 56, 0.1);
`;

const FileLinkS = styled.a`
  color: #333333;
  text-decoration: none;
  cursor: pointer;
`;

const FileViewerS = styled(FileViewer)`
  margin-top: 20px;
  height: 100%;
  img {
    max-width: 100%;
  }
`;

function RequestedSupplierDocumentFileViewer({ file }) {
  if (!file) {
    return null;
  }
  return <FileViewerS {...file} width="auto" />;
}

RequestedSupplierDocumentFileViewer.propTypes = {
  file: shape({
    fileType: string.isRequired,
    url: string.isRequired,
  }),
};

// isOnboardingDocumentType : String -> Boolean
const isOnboardingDocumentType = R.either(
  R.equals(supplierDocumentRequestTypes.W9),
  R.equals(supplierDocumentRequestTypes.W9_MANUALLY),
);

function DocumentReviewSidebarContent({
  documentType,
  agreement,
  file,
  ...props
}) {
  if (documentType === supplierDocumentRequestTypes.SA) {
    return (
      <SubcontractorAgreementInfo
        agreement={agreement}
        company={props.supplier.company}
      />
    );
  }

  if (isOnboardingDocumentType(documentType)) {
    return <ReviewSupplierOnboardingSidebarForm {...props} />;
  }

  return <RequestedSupplierDocumentFileViewer file={file} />;
}

DocumentReviewSidebarContent.propTypes = {
  supplier: shape({ company: shape({ name: string }) }),
  agreement: subcontractorAgreementPropTypes,
  documentType: string.isRequired,
  file: shape({
    fileType: string.isRequired,
    url: string.isRequired,
  }),
  isOnboarding: bool,
};

// getSidebarWidth :: RequestedSupplierDocument -> String
const getSidebarWidth = R.cond([
  [
    R.both(R.prop('isOnboarding'), R.path(['onboarding', 'tax', 'w9File'])),
    R.always('1450px'),
  ],
  [
    R.both(
      R.prop('isOnboarding'),
      R.complement(R.path(['onboarding', 'tax', 'w9File'])),
    ),
    R.always('570px'),
  ],
  [R.T, R.always('900px')],
]);

export function RequestedSupplierDocumentReviewSidebar() {
  const [documentExpiration, setDocumentExpiration] = useState(null);
  const [supplierMinority, setSupplierMinority] = useState(null);
  const { closeOutSidebar } = useOutSidebarContext();

  const { requestedSupplierDocumentSidebarId } = useRouterQuery([
    SidebarIDs.requestedSupplierDocument,
  ]);

  const { document, loading } = useRequestedSupplierDocument(
    requestedSupplierDocumentSidebarId,
  );

  const setQueryParams = useSetQueryParams();

  const { hasUserAccessToUpdateDocuments, docAccessLoading } =
    useHasUserAccessToUpdateDocuments();

  const {
    file,
    supplier,
    agreement,
    expiration,
    onboarding,
    mbeMinority,
    documentType,
  } = document;

  const isMBEDocument = documentType === supplierDocumentRequestTypes.MBE;

  const isOnboarding = isOnboardingDocumentType(documentType);

  const handleCloseSidebar = () => {
    closeOutSidebar(SidebarIDs.requestedSupplierDocument);
    setQueryParams({});
  };

  const { handleApproveDocument, isApproving } =
    useApproveRequestedSupplierDocument({
      id: requestedSupplierDocumentSidebarId,
      handleCloseSidebar,
      isOnboarding,
      documentExpiration,
      isMBEDocument,
      supplierMinority,
    });

  useEffect(() => {
    setDocumentExpiration(expiration || null);
    setSupplierMinority(mbeMinority || null);
  }, [expiration, documentType, mbeMinority]);

  if (loading || docAccessLoading) {
    return <Loader />;
  }

  if (!documentType) {
    return null;
  }

  const approveBtnProps = {
    disabled: loading || isApproving,
    ...(isOnboarding
      ? { form: approveSupplierOnboardingFormId }
      : { onClick: handleApproveDocument }),
  };

  return (
    <SidebarWrapper
      isOnboarding={isOnboarding}
      width={getSidebarWidth({ ...document, isOnboarding })}
    >
      {isOnboarding && onboarding?.tax?.w9File && (
        <RightSideWrapper>
          <RequestedSupplierDocumentFileViewer file={onboarding?.tax?.w9File} />
        </RightSideWrapper>
      )}
      <LeftSideWrapper>
        <ContentWrapper>
          <FlexColumnWrapper>
            <FlexContainer justifyContent="space-between" padding="0 0 12px 0">
              <SupplierContactsPopover supplier={supplier} />
              <SidebarCloseIcon onClick={handleCloseSidebar} />
            </FlexContainer>
            <FlexContainer justifyContent="space-between">
              <FlexContainer gap="20px">
                {!isOnboarding && !isMBEDocument && (
                  <ExpirationDateComponent
                    documentExpiration={documentExpiration}
                    setDocumentExpiration={setDocumentExpiration}
                  />
                )}
                {isMBEDocument && (
                  <EthnicitySelectComponent
                    minority={supplierMinority}
                    onChange={setSupplierMinority}
                  />
                )}
                <TextWithLabel label="Document name:">
                  <FileLinkS href={file?.url} target="_blank">
                    {supplierDocumentNamesUI[documentType]}
                  </FileLinkS>
                </TextWithLabel>
                {!isOnboarding && (
                  <TextWithLabel label="Expiration type:">
                    <DocumentTypeWithDot documentType={documentType} />
                  </TextWithLabel>
                )}
              </FlexContainer>
              <PreviewDocumentsNavigation
                documentIdKey={SidebarIDs.requestedSupplierDocument}
              />
            </FlexContainer>
          </FlexColumnWrapper>
          <DocumentReviewSidebarContent
            file={file}
            documentType={documentType}
            agreement={agreement}
            onboarding={onboarding}
            handleApproveDocument={handleApproveDocument}
            supplier={supplier}
          />
        </ContentWrapper>
        {hasUserAccessToUpdateDocuments && (
          <BottomPanelContainer gap="12px">
            <RejectRequestedDocumentButton
              disabled={loading || isApproving}
              handleCloseSidebar={handleCloseSidebar}
              documentId={requestedSupplierDocumentSidebarId}
            />
            <ApproveButton {...approveBtnProps} />
          </BottomPanelContainer>
        )}
      </LeftSideWrapper>
    </SidebarWrapper>
  );
}
