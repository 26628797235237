import React from 'react';
import * as R from 'ramda';
import {
  useNavigate,
  usePristineSubscribe,
  useRouterParams,
} from 'poly-client-routing';
import { FormPage, Loader } from 'poly-book-admin';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from 'poly-admin-ui';
import { gql, useMutation, useQuery } from '@apollo/client';

import { assocBy, removePropDeep, ramdaUseWith } from 'poly-utils';
import { enterSupplierInvoiceSections } from './sections.js';
import { editSupplierInvoiceFormId } from '../../constants.js';
import { transformInvoiceFileToPicker } from '../../../core/utils/invoices.js';
import { routesNames } from '../../../../routes/index.js';
import {
  supplierInvoiceFormCommonConfig,
  editSupplierInvoiceDefaultValues,
} from './constants.js';
import { isNotValidNewInvoiceAmount } from './enterSupplierInvoiceUtils.js';

export const supplierInvoiceEditQuery = gql`
  query SUPPLIER_INVOICE_EDIT_QUERY($invoiceId: ID!) {
    invoice(id: $invoiceId) {
      _id
      supplier {
        _id
      }
      invoiceNumber
      invoiceDate
      paymentDueDate
      paymentDueDateChangeReason
      invoiceFile {
        url
        fileName
      }
      lines {
        account {
          _id
        }
        description
        amount
      }
      total
      balance
    }
  }
`;

// getSupplierInvoiceLinesTotal :: Invoice -> Number
const getSupplierInvoiceLinesTotal = R.compose(
  R.reduce(ramdaUseWith(R.add, [R.identity, R.prop('amount')]), 0),
  R.propOr([], 'lines'),
);

// prepareInvoiceToEdit :: Invoice -> FormData
// FormData = Object
export const prepareInvoiceToEdit = R.compose(
  removePropDeep('__typename'),
  R.mergeLeft({ isEdit: true }),
  assocBy('supplierId', R.path(['supplier', '_id'])),
  R.evolve({
    lines: R.map(
      R.compose(
        R.dissoc('account'),
        assocBy('accountId', R.path(['account', '_id'])),
      ),
    ),
    invoiceFile: R.ifElse(
      R.isNil,
      R.always([]),
      R.pipe(transformInvoiceFileToPicker, R.of),
    ),
  }),
);

// prepareInvoiceToMutation :: (FormData) -> SupplierInvoiceUpdateInput
export const prepareInvoiceToMutation = (formData) =>
  R.compose(
    R.evolve({
      invoiceFile: R.compose(
        R.unless(R.isNil, R.pick(['fileName', 'upload'])),
        R.head,
      ),
    }),
    R.pick([
      'supplierId',
      'invoiceNumber',
      'invoiceDate',
      'lines',
      'invoiceFile',
      'paymentDueDate',
      'paymentDueDateChangeReason',
    ]),
  )(formData);

const UPDATE_SUPPLIER_INVOICE = gql`
  mutation UPDATE_SUPPLIER_INVOICE(
    $id: ID!
    $input: SupplierInvoiceUpdateInput!
  ) {
    updateSupplierInvoice(id: $id, input: $input) {
      invoice {
        _id
      }
    }
  }
`;

export function EditSupplierInvoiceForm() {
  const { showSuccessNotification } = useNotificationState();
  const navigate = useNavigate();
  const { invoiceId } = useRouterParams(['invoiceId']);
  const { data, loading } = useQuery(supplierInvoiceEditQuery, {
    variables: { invoiceId },
    fetchPolicy: 'network-only',
  });
  const [updateSupplierInvoice] = useMutation(UPDATE_SUPPLIER_INVOICE);

  const onSubmitHandler = (formData) =>
    updateSupplierInvoice({
      variables: {
        id: invoiceId,
        input: prepareInvoiceToMutation(formData),
      },
    }).then(() => {
      showSuccessNotification('Supplier Invoice Successfully Updated');

      navigate(routesNames.SEARCH_SUPPLIER_INVOICES);
    });

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editSupplierInvoiceFormId,
    onSubmitHandler,
  );
  const pristineSubscribeProps = usePristineSubscribe();

  const validateInvoiceNumberAndTotal = async (invoice) => {
    const oldInvoice = data.invoice;
    const newTotal = getSupplierInvoiceLinesTotal(invoice);
    const totalRes = isNotValidNewInvoiceAmount(newTotal, oldInvoice)
      ? { total: 'Total should be greater or equal to paid amount' }
      : {};

    return totalRes;
  };

  const formProps = {
    id: editSupplierInvoiceFormId,
    onSubmit,
    initialValuesEqual: R.T,
    validate: validateInvoiceNumberAndTotal,
    ...supplierInvoiceFormCommonConfig,
    ...pristineSubscribeProps,
    resetFormOnSubmit: false,
    sections: enterSupplierInvoiceSections,
    initialValues: data?.invoice
      ? prepareInvoiceToEdit(data.invoice)
      : editSupplierInvoiceDefaultValues(),
  };

  return !loading && data ? <FormPage {...formProps} /> : <Loader />;
}

EditSupplierInvoiceForm.displayName = 'EditSupplierInvoiceForm';
