import * as R from 'ramda';
import {
  AccountingStatus,
  collectionNames,
  taskActionTypes,
  UpdateTypes,
  UpdateWithActionsTypes,
  WorkOrderStatus,
} from 'poly-constants';
import {
  alwaysNewDate,
  applySpecWithFields,
  nullToUndefinedDeep,
  isNilOrEmpty,
} from 'poly-utils';
import {
  getEmailsToSendTo,
  mailToInitValues,
  mapAttachmentsBeforeUpdate,
  prepareAttachments,
} from 'poly-admin-ui';
import { isNotFeeProjectType } from '../../../sidebars/ProjectSidebar/forms/form/utils/sections.js';

const normalUpdateOption = [UpdateTypes.INTERNAL, 'Normal'];
const clientStatusReportOption = [
  UpdateTypes.CLIENT_REPORT,
  'Client Status Report',
];

const projectUpdateTypesOptions = [
  [taskActionTypes.CONFIRM_SCOPE, 'Confirm Scope'],
  [taskActionTypes.SUPPLIER_ASSIGN, 'Assign Supplier'],
  [taskActionTypes.SUPPLIER_SCHEDULING, 'Confirm Scheduling'],
  [taskActionTypes.SUPPLIER_FOLLOWUP, 'Supplier Follow Up'],
  [taskActionTypes.CONFIRM_SATISFACTION, 'Confirm Satisfaction'],
  [taskActionTypes.PROJECT_COMPLETE, 'Work Completed'],
  [taskActionTypes.SUPPLIER_INVOICE, 'Supplier Invoice'],
  [taskActionTypes.CLOSE_PROJECT, 'Close Project'],
  [taskActionTypes.RECALL_PROJECT, 'Recall Project'],
];

// optionForTask :: Task -> Option
// Task = Object
// Option = [String, String]
const optionForTask = R.ifElse(
  R.propEq('action', taskActionTypes.SUPPLIER_INVOICE),
  ({ action, description }) => [action, description],
  (task) =>
    R.find(
      ([action]) => R.propEq('action', action, task),
      projectUpdateTypesOptions,
    ),
);

const projectTasksOrder = {
  [taskActionTypes.CONFIRM_SCOPE]: 1,
  [taskActionTypes.SUPPLIER_ASSIGN]: 2,
  [taskActionTypes.SUPPLIER_SCHEDULING]: 3,
  [taskActionTypes.SUPPLIER_FOLLOWUP]: 4,
  [taskActionTypes.CONFIRM_SATISFACTION]: 5,
  [taskActionTypes.PROJECT_COMPLETE]: 6,
  [taskActionTypes.SUPPLIER_INVOICE]: 7,
  [taskActionTypes.CLOSE_PROJECT]: 8,
  [taskActionTypes.RECALL_PROJECT]: 9,
};

// getTaskOrder :: Task -> Number
const getTaskOrder = R.compose(
  R.prop(R.__, projectTasksOrder),
  R.prop('action'),
);

// sortProjectUpdateOptions :: [Task] -> [Task]
// eslint-disable-next-line import/no-unused-modules
export const sortProjectTasks = R.sort(
  (task1, task2) => getTaskOrder(task1) - getTaskOrder(task2),
);

// hideSupplierInvoiceTask :: () -> Task -> Boolean
const hideSupplierInvoiceTask = (status, accountingStatus) =>
  R.ifElse(
    R.propEq('action', taskActionTypes.SUPPLIER_INVOICE),
    R.either(
      R.pipe(() => R.equals(accountingStatus, AccountingStatus.PENDING), R.not),
      () => status === WorkOrderStatus.BLOCKED,
    ),
    R.F,
  );

// getUpdateTypeOptions :: [Task] -> [Option]
// Task = Object
// Option = [String, String]
export const getUpdateTypeOptions = R.curry(
  ({ status, accountingStatus, type }, tasks) => {
    const isNotFeeType = isNotFeeProjectType({ type });
    return R.compose(
      R.map(
        R.applySpec({
          value: R.nth(0),
          label: R.nth(1),
        }),
      ),
      R.when(R.always(isNotFeeType), R.append(clientStatusReportOption)),
      R.prepend(normalUpdateOption),
      R.reject(R.isNil),
      R.map(optionForTask),
      sortProjectTasks,
      R.reject(hideSupplierInvoiceTask(status, accountingStatus)),
      R.when(
        () => status === WorkOrderStatus.COMPLETED && isNotFeeType,
        R.append({ _id: 'recall', action: taskActionTypes.RECALL_PROJECT }),
      ),
      R.defaultTo([]),
    )(tasks);
  },
);

// getUpdateDescription :: Object -> String
const getUpdateDescription = R.cond([
  [
    R.propEq('updateType', taskActionTypes.PROJECT_COMPLETE),
    R.always('Work has been reported as completed'),
  ],
  [
    R.pathSatisfies(R.both(R.is(String), R.complement(R.isEmpty)), [
      'details',
      'text',
    ]),
    R.path(['details', 'text']),
  ],
  [R.T, R.pathOr('', ['projectTask', 'description'])],
]);

// getProjectUpdateInitValues :: Task -> FormInitValues
// Task = Object
// FormInitValues = Object
export const getProjectUpdateInitValues = R.applySpec({
  updateType: R.prop('updateType'),
  taskId: R.path(['projectTask', '_id']),
  details: R.applySpec({
    text: getUpdateDescription,
    attachments: R.always([]),
  }),
  mailTo: R.always(mailToInitValues),
  nextTask: R.ifElse(
    R.path(['projectTask', 'nextTask']),
    R.applySpec({
      dueDate: R.pipe(
        R.path(['projectTask', 'nextTask', 'dueDate']),
        R.when(R.isNil, alwaysNewDate),
      ),
      managerId: R.path(['projectTask', 'nextTask', 'manager', '_id']),
      complete: R.path(['projectTask', 'nextTask', 'complete']),
      nextTaskDescription: R.path(['projectTask', 'nextTask', 'description']),
    }),
    R.always(null),
  ),
  workCompletionDate: R.cond([
    [
      R.path(['project', 'workCompletionDate']),
      R.path(['project', 'workCompletionDate']),
    ],
    [
      R.propEq('updateType', taskActionTypes.PROJECT_COMPLETE),
      R.always(new Date()),
    ],
    [R.T, R.always(null)],
  ]),
  supplierUpdateDetails: R.ifElse(
    R.propEq('updateType', taskActionTypes.RECALL_PROJECT),
    R.applySpec({
      textValue: R.always(''),
      label: {
        projectId: R.path(['project', 'projectId']),
      },
    }),
    R.always(null),
  ),
  isHQProject: R.compose(isNilOrEmpty, R.path(['project', 'property'])),
});

// prepareUpdateData :: FormValues -> CreateUpdateInput
// FormValues = Object
// CreateUpdateInput = Object
export const prepareUpdateData = (updateType) =>
  R.applySpec({
    collection: R.always(collectionNames.projects),
    documentId: R.prop('projectId'),
    message: R.path(['details', 'text']),
    mentions: R.path(['details', 'mentions']),
    filesAttachments: R.compose(
      mapAttachmentsBeforeUpdate,
      R.path(['details', 'attachments']),
    ),
    emailTo: R.pipe(R.prop('mailTo'), getEmailsToSendTo),
    type: R.always(updateType),
  });

export const prepareProjectTaskDataToMutation = R.compose(
  R.objOf('input'),
  R.omit([
    'mailTo',
    'details',
    'projectId',
    'taskId',
    'dueDate',
    'managerId',
    'nextTask',
    'workCompletionDate',
  ]),
  R.when(
    R.prop('nextTask'),
    applySpecWithFields({
      nextTaskUpdate: {
        nextTaskDueDate: R.path(['nextTask', 'dueDate']),
        assignNextTaskTo: R.path(['nextTask', 'managerId']),
      },
    }),
  ),
  applySpecWithFields({
    update: R.applySpec({
      filesAttachments: prepareAttachments,
      message: R.path(['details', 'text']),
      mentions: R.path(['details', 'mentions']),
      emailTo: R.pipe(R.prop('mailTo'), getEmailsToSendTo),
    }),
  }),
  nullToUndefinedDeep,
);

export const prepareRecallProjectDataToMutation = R.compose(
  R.applySpec({
    update: R.applySpec({
      filesAttachments: prepareAttachments,
      message: R.path(['details', 'text']),
      mentions: R.path(['details', 'mentions']),
    }),
    [UpdateWithActionsTypes.recall]: R.applySpec({
      projectId: R.prop('projectId'),
      emails: R.pipe(R.prop('mailTo'), getEmailsToSendTo),
    }),
  }),
  nullToUndefinedDeep,
);

// taskByAction :: String -> [Task] -> Task
export const taskByAction = R.curry((action, tasks) =>
  R.find(R.propEq('action', action), tasks),
);
