import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape, oneOfType, arrayOf, node } from 'prop-types';
import { NOTHING_UI_STRING, ProjectPrioritiesUIMap } from 'poly-constants';
import { forceTitleCase, formatDate } from 'poly-utils';
import { Dot, Text } from 'poly-book-admin';
import {
  keywordSortQuery,
  commonSortQuery,
  formatDateProp,
} from 'poly-client-utils';

import { EXPIRED_LABEL } from '../../core/constants/general.js';
import { projectPrioritiesColors } from '../../core/constants/projects.js';
import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';
import { getCommonProjectStatusColor } from '../../core/utils/projects.js';
import { getDateColor } from '../projectsTable/projectsTableUtils.js';
import { getExpiredDateInWords } from '../../core/utils/general.js';

export const commonSortWithId = (sortFn) => (order) =>
  [...sortFn(order), { mongo_id: { order: 'desc' } }];

export function ProjectStatusDot(props) {
  const color = getCommonProjectStatusColor(props);

  return <Dot {...props} color={color} />;
}

export function ProjectSidebarLink(props) {
  const { ProjectLink } = useSidebarLogicContext();

  return <ProjectLink {...props} />;
}

export const projectIdColumn = [
  'Project ID',
  ProjectSidebarLink,
  keywordSortQuery(['projectId']),
];

function ClientSidebarLink({ client }) {
  const { ClientLink } = useSidebarLogicContext();

  const clientLinkProps = {
    _id: client._id,
    name: client.name || client.nickName,
  };

  return <ClientLink {...clientLinkProps} />;
}

ClientSidebarLink.propTypes = {
  client: shape({
    name: oneOfType([string, arrayOf(node)]),
    nickName: string,
    _id: string.isRequired,
  }).isRequired,
};

export const projectClientColumn = [
  'Client',
  ClientSidebarLink,
  keywordSortQuery(['client', 'name']),
];

export const projectStartDateColumn = [
  'Start Date',
  formatDateProp('startDate'),
  commonSortWithId(commonSortQuery(['startDate'])),
];

function EndDateComp({ endDate }) {
  return endDate ? (
    <Text color={getDateColor(endDate)}>{formatDate(endDate)}</Text>
  ) : (
    NOTHING_UI_STRING
  );
}

EndDateComp.propTypes = {
  endDate: string,
};

export const projectEndDateColumn = [
  'End Date',
  EndDateComp,
  commonSortWithId(commonSortQuery(['endDate'])),
];

export const projectDescriptionColumn = ['Description', R.prop('description')];

const manager = R.path(['manager', 'fullName']);
export const projectManagerColumn = [
  'Assigned CSR',
  manager,
  keywordSortQuery(['manager', 'profile', 'fullName']),
];

// getCapitalizedStatus :: Project -> String
const getCapitalizedStatus = R.compose(forceTitleCase, R.prop('status'));

export const projectStatusColumn = [
  'Status',
  getCapitalizedStatus,
  commonSortQuery(['status']),
];

const PriorityText = styled(Text)`
  color: ${({ priority }) => R.prop(priority, projectPrioritiesColors)};
`;

function PriorityComp({ priority }) {
  return (
    <PriorityText {...{ priority }} weight="bold">
      {ProjectPrioritiesUIMap[priority]}
    </PriorityText>
  );
}

PriorityComp.propTypes = { priority: string.isRequired };

export const projectPriorityColumn = [
  'Priority',
  PriorityComp,
  commonSortQuery(['priority']),
];

function ExpiredComp({ expiresAt }) {
  return (
    <Text
      color={
        getExpiredDateInWords(expiresAt) === EXPIRED_LABEL
          ? ['secondaryMid']
          : ['primary']
      }
    >
      {getExpiredDateInWords(expiresAt)}
    </Text>
  );
}

ExpiredComp.propTypes = { expiresAt: string.isRequired };

export const projectExpiresColumn = [
  'Expires',
  ExpiredComp,
  commonSortQuery(['expiresAt']),
];
