import { useEffect } from 'react';
import { useNavigate } from 'poly-client-routing';

import { routesNames } from '../../routes/constants.js';

export function RedirectToSubcontractorAgreementPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(routesNames.SUBCONTRACTOR_AGREEMENT);
  }, []);
  return null;
}
