import React from 'react';
import {
  DefaultBodyWrapper,
  MainHeader,
  PageHeaderContainer,
  TableCard,
} from 'poly-book-admin';
import { ClientStatuses } from 'poly-constants';
import { TableSearchInput } from 'poly-admin-ui';
import { TablePagination } from 'poly-admin-ui/src/components/Pagination.js';
import { TableWithPaginationContainer } from 'poly-book-admin/src/Card.js';

import { ClientsTable } from '../modules/tables/clientsTable/ClientsTable.js';

export function ProspectsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Prospects</MainHeader>
        <TableSearchInput />
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCard>
          <TableWithPaginationContainer paginationVisible>
            <ClientsTable status={ClientStatuses.PROSPECT} />
          </TableWithPaginationContainer>
          <TablePagination />
        </TableCard>
      </DefaultBodyWrapper>
    </>
  );
}
