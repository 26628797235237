import * as R from 'ramda';
import styled from 'styled-components';
import { number, string } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { getThemeColor, Popover, Select, Icon } from 'poly-book-admin';
import { useNotificationState, useModalContext } from 'poly-admin-ui';
import { useQueryWithSearch } from 'poly-client-utils';

import { generatePOsQueryByProject } from './generatePOsQueryByProject.js';

const convertPOInfoText =
  'The current PO Number is out of date, please select a new one. ';

const convertPoSameItemText = 'We found a new one with the same name';

const convertPoSelectItemText = 'or choose another in the dropdown below.';

const convertPoNoFoundText =
  'There is no similar PO Number found. Please choose another in the dropdown below.';

// eslint-disable-next-line import/no-unused-modules
export const adminPOsSearchQuery = gql`
  query adminPOsSearchQuery($searchInput: CollectionSearchParams) {
    searchAdminPurchaseOrders(input: $searchInput) {
      hits {
        _id
        poNumber
        displayName
      }
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const adminPOsSearchSubscription = gql`
  subscription adminPOsSearchSubscription(
    $searchInput: CollectionSearchParams
  ) {
    searchAdminPurchaseOrderChanged(input: $searchInput) {
      id
      type
    }
  }
`;

const convertToAdminPurchaseOrderMutation = gql`
  mutation convertToAdminPurchaseOrderMutation(
    $input: ConvertToAdminPurchaseOrderInput!
  ) {
    convertToAdminPurchaseOrder(input: $input) {
      _id
    }
  }
`;

const ConvertPOWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  width: 300px;
`;

const ConvertPOTitleS = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${getThemeColor(['darkest'])};
`;

const ConvertPOTextS = styled(ConvertPOTitleS)`
  font-size: 12px;
  margin: 8px 0 12px 0;
  word-break: normal;
`;

const ConvertPOItemS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 12px;
  background-color: ${getThemeColor(['accent2Lighter4'])};
  margin-bottom: 8px;
  font-size: 10px;
  line-height: 14px;
  color: ${getThemeColor(['primaryLight'])};
  cursor: pointer;

  > svg {
    fill: ${getThemeColor(['midDark'])};
  }

  :focus,
  :hover {
    > svg {
      fill: ${getThemeColor(['primaryMid'])};
    }
  }
`;

const ConvertPOSelectWrapperS = styled.div`
  display: flex;
  position: relative;
`;

const SelectPOIconWrapperS = styled.div`
  display: flex;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 2px;
  right: 3px;
  align-items: center;
  justify-content: center;
  background-color: white;

  > svg {
    fill: ${getThemeColor(['midDark'])};
  }

  :focus,
  :hover {
    > svg {
      fill: ${getThemeColor(['primaryMid'])};
    }
  }
`;

function CheckIcon(props) {
  return <Icon size={8} name="checkMark" {...props} />;
}

function SelectPOIcon(props) {
  return (
    <SelectPOIconWrapperS>
      <CheckIcon {...props} />
    </SelectPOIconWrapperS>
  );
}

// getSuggestedAdminPO :: SearchAdminPurchaseOrdersQueryResult -> AdminPurchaseOrder
const getSuggestedAdminPO = R.pathOr({}, [
  'searchAdminPurchaseOrders',
  'hits',
  0,
]);

// getAdminPOLabel :: AdminPurchaseOrder -> String
const getAdminPOLabel = R.compose(
  R.join('/'),
  R.juxt([R.prop('poNumber'), R.prop('displayName')]),
);

// getAdminPOsFromSearch :: SearchAdminPurchaseOrdersQueryResult -> [AdminPurchaseOrder]
const getAdminPOsFromSearch = R.pathOr(
  [],
  ['searchAdminPurchaseOrders', 'hits'],
);

// getAdminPOsOptions :: SearchAdminPurchaseOrdersQueryResult -> [Option]
const getAdminPOsOptions = R.compose(
  R.map(
    R.applySpec({
      label: getAdminPOLabel,
      value: R.prop('_id'),
    }),
  ),
  getAdminPOsFromSearch,
);

// getAdminPOBySelectedOption :: ID -> SearchAdminPurchaseOrdersQueryResult -> AdminPurchaseOrder
const getAdminPOBySelectedOption = (selectedId) =>
  R.compose(R.find(R.propEq('_id', selectedId)), getAdminPOsFromSearch);

// getConfirmationTextByPONumber :: String -> String
const getConfirmationTextByPONumber = (poNumber) =>
  `All the projects with this PO will be assigned to the PO ${poNumber}.`;

function ConvertPOContent({ clientReferenceNumber, ...props }) {
  const { openConfirmModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [selectedAdminPO, setSelectedAdminPO] = useState(null);
  const [convertToAdminPurchaseOrder] = useMutation(
    convertToAdminPurchaseOrderMutation,
  );

  const suggestedAdminPOQuery = useMemo(
    () => generatePOsQueryByProject(clientReferenceNumber)(props),
    [props, clientReferenceNumber],
  );

  const adminPOOptionsQuery = useMemo(
    () => generatePOsQueryByProject()(props),
    [props],
  );

  const { data } = useQuery(adminPOsSearchQuery, {
    variables: { searchInput: { query: suggestedAdminPOQuery } },
  });

  const { result, onSearchChange } = useQueryWithSearch({
    withoutSkip: true,
    query: adminPOOptionsQuery,
    gqlSearchQuery: adminPOsSearchQuery,
    gqlSearchChangedQuery: adminPOsSearchSubscription,
  });

  const suggestedAdminPO = useMemo(() => getSuggestedAdminPO(data), [data]);

  const adminPOOptions = useMemo(() => getAdminPOsOptions(result), [result]);

  const hasSuggestedAdminPO = R.complement(R.isEmpty)(suggestedAdminPO);

  const onConvertPONumber = async (id) => {
    await convertToAdminPurchaseOrder({
      variables: { input: { id, poNumberToConvert: clientReferenceNumber } },
    });
    showSuccessNotification('All projects were successfully converted');
  };

  const onSubmitCheck = (isSuggested) => {
    const assignedAdminPO = isSuggested
      ? suggestedAdminPO
      : getAdminPOBySelectedOption(selectedAdminPO)(result);

    if (assignedAdminPO) {
      openConfirmModal({
        btnCaption: 'Proceed',
        cancelBtnCaption: 'Cancel',
        id: 'confirmConvertPONumberModal',
        content: getConfirmationTextByPONumber(assignedAdminPO.poNumber),
        onConfirm: (closeConfirmModal) => () => {
          onConvertPONumber(assignedAdminPO._id);
          closeConfirmModal();
        },
      });
    }
  };

  return (
    <ConvertPOWrapperS>
      <ConvertPOTitleS>Reassign PO Number</ConvertPOTitleS>
      <ConvertPOTextS>
        {convertPOInfoText}
        {hasSuggestedAdminPO ? convertPoSameItemText : convertPoNoFoundText}
      </ConvertPOTextS>
      {hasSuggestedAdminPO && (
        <>
          <ConvertPOItemS onClick={() => onSubmitCheck(true)}>
            {getAdminPOLabel(suggestedAdminPO)}
            <CheckIcon />
          </ConvertPOItemS>
          <ConvertPOTextS>{convertPoSelectItemText}</ConvertPOTextS>
        </>
      )}
      <ConvertPOSelectWrapperS>
        <Select
          maxMenuHeight={110}
          name="po-number-select"
          value={selectedAdminPO}
          options={adminPOOptions}
          onChange={setSelectedAdminPO}
          onInputChange={onSearchChange}
        />
        <SelectPOIcon onClick={() => onSubmitCheck(false)} />
      </ConvertPOSelectWrapperS>
    </ConvertPOWrapperS>
  );
}

ConvertPOContent.propTypes = {
  clientReferenceNumber: string.isRequired,
};

export function ConvertPOPopover({ rowIndex, ...props }) {
  const position = rowIndex <= 5 ? 'right' : 'top';

  return (
    <Popover
      position={position}
      title={<Icon name="yellowWarning" size={15} />}
      content={<ConvertPOContent {...props} />}
    />
  );
}

ConvertPOPopover.propTypes = { rowIndex: number.isRequired };
