import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { number, bool, func } from 'prop-types';
import { Table, Button } from 'poly-book-admin';
import { applyProp, centsToDollarsWithFormat } from 'poly-utils';
import { useMapConfigToTableProps } from 'poly-admin-ui';

import { batchInvoicingCommonTotalsTableStyles } from '../BatchInvoicingTM/BatchInvoicingTMBassProTotalsTable.js';
import { housekeepingBatchInvoicingTableStyles } from './HousekeepingBatchInvoicingTable.js';

const HousekeepingBatchTotalsTableS = styled(Table)`
  ${housekeepingBatchInvoicingTableStyles}
  ${batchInvoicingCommonTotalsTableStyles}
`;

const housekeepingTotalsTableConfig = [
  ['', R.always('')],
  ['', R.always('')],
  ['Job Cost', applyProp(centsToDollarsWithFormat)('jobCost')],
  [
    'Inv. Base',
    applyProp(centsToDollarsWithFormat)('suggestedClientInvoiceAmount'),
  ],
  [
    'Inv. Extra hrs',
    applyProp(centsToDollarsWithFormat)('extraHoursClientInvoiceAmount'),
  ],
  [
    'Total',
    R.compose(
      centsToDollarsWithFormat,
      R.converge(R.add, [
        R.prop('suggestedClientInvoiceAmount'),
        R.prop('extraHoursClientInvoiceAmount'),
      ]),
    ),
  ],
  [
    '',
    ({ onCreateBatch, disabled, loader }) => (
      <Button
        size="small"
        onClick={onCreateBatch}
        disabled={disabled}
        loader={loader}
      >
        Create Batch
      </Button>
    ),
  ],
];

export function HousekeepingBatchTotalsTable({
  suggestedClientInvoiceAmount,
  extraHoursClientInvoiceAmount,
  jobCost,
  onCreateBatch,
  disabled,
  loader,
}) {
  const { rows, headers, columns } = useMapConfigToTableProps(
    R.identity,
    housekeepingTotalsTableConfig,
    [
      {
        _id: 'totals',
        suggestedClientInvoiceAmount,
        extraHoursClientInvoiceAmount,
        jobCost,
        onCreateBatch,
        disabled,
        loader,
      },
    ],
  );

  return (
    <HousekeepingBatchTotalsTableS
      headers={headers}
      rows={rows}
      columns={columns}
      enableHoverEffect={false}
      minHeightLess
    />
  );
}

HousekeepingBatchTotalsTable.propTypes = {
  suggestedClientInvoiceAmount: number.isRequired,
  extraHoursClientInvoiceAmount: number.isRequired,
  jobCost: number.isRequired,
  onCreateBatch: func.isRequired,
  disabled: bool,
  loader: bool,
};
