import * as R from 'ramda';
import { aminPurchaseOrderStatuses } from 'poly-constants';

// generatePOsQueryByProject :: String -> Project -> ElasticQuery
export const generatePOsQueryByProject = (filteredPONumber) =>
  R.ifElse(
    R.either(
      R.pathSatisfies(R.isNil, ['client', '_id']),
      R.pathSatisfies(R.isNil, ['property', '_id']),
    ),
    R.always(null),
    R.compose(
      R.objOf('bool'),
      R.objOf('must'),
      R.reject(R.isNil),
      R.juxt([
        R.always({ term: { status: aminPurchaseOrderStatuses.ACTIVE } }),
        R.compose(
          R.objOf('term'),
          R.objOf('clientId'),
          R.path(['client', '_id']),
        ),
        R.ifElse(
          () => !!filteredPONumber,
          R.always({ term: { poNumber: filteredPONumber } }),
          R.always(null),
        ),
        R.compose(
          R.objOf('bool'),
          R.objOf('should'),
          R.juxt([
            R.always({
              bool: { must_not: { exists: { field: 'propertiesIds' } } },
            }),
            R.compose(
              R.objOf('bool'),
              R.objOf('must'),
              R.objOf('term'),
              R.objOf('propertiesIds'),
              R.path(['property', '_id']),
            ),
          ]),
        ),
      ]),
    ),
  );
