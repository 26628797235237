import React from 'react';
import * as R from 'ramda';
import { shape, arrayOf, string, number, func, bool } from 'prop-types';
import {
  applyProp,
  applyPath,
  calculateTotal,
  forceTitleCase,
  formatDate,
  centsToDollarsWithFormat,
  convertCentsToDollars,
} from 'poly-utils';
import { useMapConfigToTablePropsWithSorting } from 'poly-admin-ui';
import { NOTHING_UI_STRING, ProjectTypeToNameMap } from 'poly-constants';

import { SupplierLink } from '../../components/Links.js';
import { WorkInProgressTableS } from './WorkInProgressTableS.js';
import { ClientLink } from '../../sidebars/ClientSidebar/useOpenClientSidebar.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { WorkInProgressFooter } from '../WorkInProgressQCReport/WorkInProgressQCTableS.js';

export const wipRecordsPropTypes = arrayOf(
  shape({
    _id: string.isRequired,
    suggestedClientInvoiceAmount: number,
    markupAmount: number,
    supplierInvoicesTotal: number,
    client: shape({
      _id: string.isRequired,
      name: string.isRequired,
    }),
    supplier: shape({
      _id: string,
      company: shape({
        name: string,
      }),
    }),
    project: shape({
      type: string.isRequired,
      projectId: string.isRequired,
      endDate: string.isRequired,
    }),
    manager: shape({
      fullName: string.isRequired,
    }),
    lines: arrayOf(
      shape({
        name: string,
        markup: number,
        total: number,
        supplierId: string,
      }),
    ),
  }),
);

function ProjectLinkC({ project, isPrintPDF }) {
  return <ProjectLink isPrintPDF={isPrintPDF} {...project} />;
}

ProjectLinkC.propTypes = {
  project: shape({
    _id: string.isRequired,
    type: string.isRequired,
    projectId: string.isRequired,
  }),
  isPrintPDF: bool,
};

function ClientLinkC({ client }) {
  return <ClientLink {...client} />;
}

ClientLinkC.propTypes = {
  client: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }),
};

function SupplierLinkC({ supplier }) {
  if (!supplier) {
    return NOTHING_UI_STRING;
  }
  return (
    <SupplierLink
      _id={supplier._id}
      name={supplier.company.name}
      target="_blank"
      key={supplier._id}
    />
  );
}

SupplierLinkC.propTypes = {
  supplier: shape({
    _id: string,
    company: shape({
      name: string,
    }),
  }),
};

export const propOrEmptyUi = (getProp) =>
  R.ifElse(R.has('projectAccountingStatus'), getProp, R.always(''));

const workInProgressTableConfig = (isPrintPDF = false) => [
  [
    'Project #',
    R.compose(propOrEmptyUi(ProjectLinkC), R.mergeRight({ isPrintPDF })),
    R.path(['project', 'projectId']),
  ],
  [
    'Project Type',
    R.compose(R.prop(R.__, ProjectTypeToNameMap), R.path(['project', 'type'])),
    R.path(['project', 'type']),
  ],
  ['Client', propOrEmptyUi(ClientLinkC), R.path(['client', 'name'])],
  ['Acct Status', applyProp(forceTitleCase)('projectAccountingStatus')],
  [
    'End Date',
    propOrEmptyUi(applyPath(formatDate)(['project', 'endDate'])),
    R.path(['project', 'endDate']),
  ],
  [
    'Suppliers',
    R.ifElse(
      R.compose(R.isNil, R.path(['supplier', 'company', 'name'])),
      R.always('N/A'),
      SupplierLinkC,
    ),
    R.path(['supplier', 'company', 'name']),
  ],
  ['Amount', applyProp(centsToDollarsWithFormat)('total')],
  ['Markup', applyProp(centsToDollarsWithFormat)('markup')],
  [
    /* eslint-disable-next-line @cspell/spellchecker */
    'Sugg.Total',
    propOrEmptyUi(
      applyProp(centsToDollarsWithFormat)('suggestedClientInvoiceAmount'),
    ),
  ],
  [
    'Manager',
    propOrEmptyUi(R.path(['manager', 'fullName'])),
    R.path(['manager', 'fullName']),
  ],
];

// wipTableSortQueries :: [TableConfig] -> [Function]
export const wipTableSortQueries = R.map(R.nth(2), workInProgressTableConfig());

// calculateWIPReportTotal :: [WipLines] -> WIPTotals
//   WIPTotals = {
//     supplierInvoice: Number,
//     markup: Number,
//     total: Number,
//   }
export const calculateWIPReportTotal = R.applySpec({
  supplierInvoiceTotal: R.compose(
    convertCentsToDollars,
    calculateTotal(R.propOr(0, 'total')),
  ),
  markup: R.compose(
    convertCentsToDollars,
    calculateTotal(R.propOr(0, 'markup')),
  ),
  total: R.compose(
    convertCentsToDollars,
    calculateTotal(R.propOr(0, 'suggestedClientInvoiceAmount')),
  ),
});

function WipTableFooter({ preparedRows }) {
  const { supplierInvoiceTotal, markup, total } =
    calculateWIPReportTotal(preparedRows);

  return (
    <WorkInProgressFooter
      total={total}
      markup={markup}
      supplierInvoiceTotal={supplierInvoiceTotal}
    />
  );
}

WipTableFooter.propTypes = {
  preparedRows: arrayOf(
    shape({
      markup: number,
      total: number,
      suggestedClientInvoiceAmount: number,
    }),
  ),
};

// prepareWipLine :: WipRecord -> [WipLines]
const prepareWipLine = (wipRecord) =>
  R.compose(
    R.converge(R.concat, [
      R.compose(R.of, R.mergeRight(wipRecord), R.head),
      R.compose(
        R.map(
          R.converge(R.mergeRight, [
            R.identity,
            R.applySpec({
              project: R.always(wipRecord.project),
              client: R.always(wipRecord.client),
              manager: R.always(wipRecord.manager),
            }),
          ]),
        ),
        R.tail,
      ),
    ]),
    R.propOr([], 'lines'),
  )(wipRecord);

// prepareWipRows :: [WipRecord] -> [WipRow]
export const prepareWipRows = R.compose(R.unnest, R.map(prepareWipLine));

export function WorkInProgressTable({
  wipRecords,
  updateSorting,
  initialSorting,
  isPrintPDF,
}) {
  const preparedRows = prepareWipRows(wipRecords);

  const {
    rows,
    headers,
    columns,
    sortQueries,
    sorting,

    onHeaderCellClick,
  } = useMapConfigToTablePropsWithSorting({
    tableConfig: workInProgressTableConfig(isPrintPDF),
    items: preparedRows,
    onHeaderCellClick: updateSorting,
    initialSorting,
  });

  return (
    <WorkInProgressTableS
      isSortable
      headers={headers}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      sorting={sorting}
      footerHeight={38}
      onHeaderCellClick={onHeaderCellClick}
      moreScrollIconPosition="48px"
      TableFooter={WipTableFooter}
      footerProps={{ preparedRows }}
      showScrollBar
    />
  );
}

WorkInProgressTable.propTypes = {
  wipRecords: wipRecordsPropTypes,
  updateSorting: func.isRequired,
  initialSorting: shape({
    columnKey: number,
    dir: number,
  }),
  isPrintPDF: bool,
};
