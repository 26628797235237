import * as R from 'ramda';
import {
  getUserWorkPhoneObject,
  getUserCellPhoneObject,
  isNilOrEmpty,
  assocBy,
} from 'poly-utils';

/*
 *  pickGeneralInfo :: UserProfile -> UserProfile
 */
const pickGeneralInfo = R.pick([
  'firstName',
  'lastName',
  'middleName',
  'socialSecurityNumber',
  'gender',
  'maritalStatus',
  'dateOfBirth',
]);

// isNillOrEmptyProp :: String -> Object -> Boolean
const isNillOrEmptyProp = (propName) => R.propSatisfies(isNilOrEmpty, propName);

// prepareUserContacts :: UserProfile -> UserProfile
const prepareUserContacts = R.compose(
  R.dissoc('addressTwo'),
  assocBy(
    'address',
    R.ifElse(
      R.both(isNillOrEmptyProp('address'), isNillOrEmptyProp('addressTwo')),
      R.always(null),
      R.converge(R.mergeRight, [
        R.propOr({}, 'address'),
        R.pick(['addressTwo']),
      ]),
    ),
  ),
  R.pick([
    'personalEmail',
    'personalPhone',
    'personalMobile',
    'address',
    'addressTwo',
  ]),
  R.prop('profile'),
);

// staffUserToEditForm :: User -> UserFormData
export const staffUserToEditForm = R.applySpec({
  general: R.compose(pickGeneralInfo, R.prop('profile')),
  contacts: prepareUserContacts,
  company: {
    phones: {
      cell: R.compose(R.reject(R.isNil), getUserCellPhoneObject),
      work: R.compose(R.reject(R.isNil), getUserWorkPhoneObject),
    },
    companyLocation: R.prop('companyLocation'),
  },
  employeeInfo: R.converge(R.mergeRight, [
    R.prop('employeeInfo'),
    R.pick(['title']),
  ]),
  systemAccess: {
    status: R.prop('status'),
    userGroupId: R.path(['userGroups', '0', 'userGroup', '_id']),
    email: R.prop('email'),
  },
  remarks: R.prop('remarks'),
  isContractor: R.prop('isContractor'),
  userId: R.prop('_id'),
  twoFactorAuth: R.prop('twoFactorAuth'),
  loginCellPhoneNumber: R.pathOr(null, ['profile', 'loginCellPhoneNumber']),
});
