import React from 'react';
import { mentionGroupStatuses } from 'poly-constants';
import { TableSearchInput } from 'poly-admin-ui';

import { PageTabs } from '../modules/tabs/TabsWithRouter.js';
import { MentionGroupsPageHeader } from '../modules/pagesHeaders/MentionGroupsPageHeader.js';
import { MentionGroupsTabTitle } from '../modules/tabs/mentionGroupsTab/MentionGroupsTabTitle.js';
import { getMentionGroupQueryByStatus } from '../modules/core/searchQueries/mentionGroups.js';
import { MentionGroupsTabComp } from '../modules/tabs/mentionGroupsTab/MentionGroupsTabComp.js';

const { ACTIVE, INACTIVE } = mentionGroupStatuses;
const statusesList = [ACTIVE, INACTIVE];

const statusToTabEntry = (status) => {
  const query = getMentionGroupQueryByStatus(status);
  return [
    <MentionGroupsTabTitle
      status={status}
      key={`${status}-mention-groups-title`}
    />,
    status,
    <MentionGroupsTabComp
      query={query}
      status={status}
      key={`${status}-mention-groups-tab`}
    />,
  ];
};

export function MentionGroupsPage() {
  return (
    <>
      <MentionGroupsPageHeader />
      <PageTabs
        toolBar={<TableSearchInput />}
        tabs={statusesList.map(statusToTabEntry)}
        defaultValue={ACTIVE}
      />
    </>
  );
}
