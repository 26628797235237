import { useUpdateQueryParams } from 'poly-client-routing';

import { SidebarIDs } from '../../../sidebars/constants.js';
import { useClearPristineState } from '../../../sidebars/useClearPristineOnSidebarMount.js';

export const useNavigateToRequestedDocumentReviewSidebar = () => {
  const updateQueryParams = useUpdateQueryParams();
  const clearPristine = useClearPristineState();

  return (requestedDocumentId, documentType) => {
    clearPristine();
    updateQueryParams({
      [SidebarIDs.requestedSupplierDocument]: requestedDocumentId,
      documentType,
    });
  };
};
