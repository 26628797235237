import React from 'react';
import * as R from 'ramda';
import { bool, shape, string } from 'prop-types';
import { useQuery } from '@apollo/client';
import { usePristineSubscribe } from 'poly-client-routing';
import { FormCreator, Loader } from 'poly-book-admin';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  CLIENT_CONFIGS_QUERY,
  useSaveContacts,
} from 'poly-admin-ui';
import {
  propertyOccupancyTypes,
  propertyOwnershipTypes,
  propertyStatuses,
  invoiceToTypes,
} from 'poly-constants';
import arrayMutators from 'final-form-arrays';

import { addPropertyFormId } from '../../constants.js';
import { sideBarPropertyFormSections } from '../form/sideBarPropertyFormSections.js';
import { useOnSubmitPropertyFrom } from './useOnSubmitForm.js';
import { useHierarchyOptions } from '../../../../pages/PropertyHierarchyManagement/useHierarchyOptions.js';

const useClientConfigQuery = (clientId) => {
  const { data, loading } = useQuery(CLIENT_CONFIGS_QUERY, {
    variables: { id: clientId },
    skip: !clientId,
  });
  const client = R.path(['client'], data);

  return {
    loading,
    client,
  };
};

// getDefaultInvoiceTo :: Client -> String
const getDefaultInvoiceTo = R.pathOr(invoiceToTypes.MAIN_ACCOUNT, [
  'financial',
  'invoiceto',
]);

export function AddPropertySideBarForm({ clientId, parentProperty, isMaster }) {
  const saveContacts = useSaveContacts();

  const pristineSubscribeProps = usePristineSubscribe(
    {
      clientId,
      id: addPropertyFormId,
    },
    { clientId },
  );

  const { client, loading: clientLoading } = useClientConfigQuery(clientId);
  const { options: clientHierarchies, loading } = useHierarchyOptions(
    clientId,
    true,
  );

  const onSubmitHandler = useOnSubmitPropertyFrom({
    saveContacts,
    client,
    parentProperty,
    isMaster,
  });
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addPropertyFormId,
    onSubmitHandler,
  );

  if (clientLoading || loading) {
    return <Loader />;
  }

  const initialValues = {
    client,
    masterProperty: parentProperty,
    costCenter: parentProperty?.costCenter || client?.financial?.costCenter,
    status: propertyStatuses.ACTIVE,
    invoiceTo: getDefaultInvoiceTo(client),
    branchManager: {
      contact: { isCreateMode: false },
    },
    occupancy: propertyOccupancyTypes.occupied,
    ownership: propertyOwnershipTypes.owned,
    clientHierarchies,
    hierarchies: [{}],
    requesterSurveyEnabled: client?.configs?.requesterSurvey?.enabled || false,
  };

  return (
    <FormCreator
      id={addPropertyFormId}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      initialValues={initialValues}
      sections={sideBarPropertyFormSections(false)}
      onSubmit={onSubmit}
      {...pristineSubscribeProps}
      mutators={arrayMutators}
    />
  );
}

AddPropertySideBarForm.propTypes = {
  clientId: string.isRequired,
  parentProperty: shape({}),
  isMaster: bool.isRequired,
};
