import React from 'react';
import * as R from 'ramda';
import { arrayOf, string, shape, func, node } from 'prop-types';
import styled, { css } from 'styled-components';
import {
  useHasUserAccessWithPermission,
  useHasUserAccessWithPermissionByProps,
} from 'poly-client-utils';
import { getThemeColor, getThemeFont } from 'poly-book-admin';
import { Link } from 'poly-client-routing';

import { useNotificationState } from '../hooks/useNotificationState.js';

const linkPropTypes = {
  name: string,
  permissions: arrayOf(string),
  link: string,
  warningMessage: string,
  Counter: func,
};

const linkStyles = css`
  font-size: 14px;
  padding-bottom: 15px;
  font-weight: ${getThemeFont(['medium'])};
`;

const AdminSettingsCategory = styled.div`
  display: flex;
  flex-shrink: 0;
  width: ${({ width }) => width || '30%'};
  flex-direction: column;
  padding: 30px 30px 30px 0;
  margin: 0 3% 0 0;
`;

const AdminSettingsCategoryTitle = styled.h3`
  font-size: 20px;
  font-weight: ${getThemeFont(['medium'])};
  color: ${getThemeColor(['newPrimary'])};
  padding-bottom: 25px;
  line-height: 1.15;
  margin: 0;
  min-height: 48px;
`;

const AdminSettingsItemLinkC = styled(Link)`
  ${linkStyles};
  display: block;
  position: relative;

  &:hover {
    color: ${getThemeColor(['newDarkAccent2'])};
  }
`;

const ItemCountWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const AdminSettingsItemText = styled.div`
  ${linkStyles};
  color: ${getThemeColor(['secondary'])};
`;

function AdminSettingsItemLink({ permissions, ...restProps }) {
  const ifHasPermission = useHasUserAccessWithPermissionByProps(permissions);

  return ifHasPermission ? <AdminSettingsItemLinkC {...restProps} /> : null;
}

AdminSettingsItemLink.propTypes = {
  permissions: arrayOf(string),
};

function LinkWithWarningMessageComponent({
  name,
  link,
  permissions,
  warningMessage,
  Counter,
}) {
  const { showWarningNotification } = useNotificationState();
  const hasAccess = R.compose(
    R.all(R.equals(true)),
    R.map(useHasUserAccessWithPermission),
  )(permissions);

  const onClick = () => showWarningNotification(warningMessage);
  const linkHref = hasAccess ? link : '';
  const customProps = hasAccess ? {} : { onClick };

  return (
    <AdminSettingsItemLinkC href={linkHref} key={name} {...customProps}>
      {name}
      {Counter && (
        <ItemCountWrapper>
          <Counter />
        </ItemCountWrapper>
      )}
    </AdminSettingsItemLinkC>
  );
}

LinkWithWarningMessageComponent.propTypes = linkPropTypes;

function LinkComponent({ name, permissions, link }) {
  return (
    <AdminSettingsItemLink permissions={permissions} href={link} key={name}>
      {name}
    </AdminSettingsItemLink>
  );
}

LinkComponent.propTypes = {
  name: string,
  permissions: arrayOf(string),
  link: string,
};

function TextComponent({ name }) {
  return <AdminSettingsItemText key={name}>{name}</AdminSettingsItemText>;
}

TextComponent.propTypes = {
  name: string,
};

// isString :: Any -> Boolean
const isString = R.is(String);

function CondLinkComponent(props) {
  const { link, warningMessage } = props;

  if (isString(warningMessage)) {
    return <LinkWithWarningMessageComponent {...props} />;
  }

  if (isString(link)) {
    return <LinkComponent {...props} />;
  }

  return <TextComponent {...props} />;
}

CondLinkComponent.propTypes = {
  link: string,
  warningMessage: string,
};

const SubLinksWrapper = styled.div`
  margin-left: 25px;
`;

const SubLinksTitle = styled.div`
  ${linkStyles};
  color: ${getThemeColor(['newPrimary'])};
`;

function ItemWithSubLinks({ name, subLinks }) {
  return (
    <div key={name}>
      <SubLinksTitle>{name}</SubLinksTitle>
      <SubLinksWrapper>
        {subLinks.map((props) => (
          <CondLinkComponent key={props.name} {...props} />
        ))}
      </SubLinksWrapper>
    </div>
  );
}

ItemWithSubLinks.propTypes = {
  name: string.isRequired,
  subLinks: arrayOf(shape(linkPropTypes)).isRequired,
};

function ParentLinkComponent({ subLinks, ...props }) {
  if (subLinks) {
    return <ItemWithSubLinks {...{ subLinks, ...props }} />;
  }

  return <CondLinkComponent {...props} />;
}

const subLinksPropTypes = {
  name: string,
  link: string,
  permissions: arrayOf(string),
  warningMessage: string,
  Counter: node,
};

ParentLinkComponent.propTypes = {
  subLinks: arrayOf(shape(subLinksPropTypes)),
};

export function AdminSettingsTable({ items, width }) {
  return (
    <>
      {items.map(({ title, links }) => (
        <AdminSettingsCategory key={title} width={width}>
          <AdminSettingsCategoryTitle>{title}</AdminSettingsCategoryTitle>
          {links.map(({ name, ...props }) => (
            <ParentLinkComponent key={name} {...{ name, ...props }} />
          ))}
        </AdminSettingsCategory>
      ))}
    </>
  );
}

AdminSettingsTable.propTypes = {
  width: string,
  items: arrayOf(
    shape({
      title: string,
      links: arrayOf(
        shape({
          name: string,
          link: string,
          permissions: arrayOf(string),
          subLinks: arrayOf(shape(subLinksPropTypes)),
        }),
      ),
    }),
  ),
};
