import React from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { shape, string, bool } from 'prop-types';
import { SidebarUpdatesTab, entities, commonUpdateFields } from 'poly-admin-ui';
import { UpdateCollections } from 'poly-constants';

import { PrintLayoutProvider } from '../../../providers.js';
import { AddProjectUpdateFormWithSelectAction } from '../../../modules/forms/addProjectUpdateForm/AddProjectUpdateFormWithSelectAction.js';
import { useSidebarLogicContext } from '../../SidebarLogicContext.js';

export const PROJECT_UPDATES = gql`
  query PROJECT_UPDATES(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    project(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
      client {
        _id
        name
      }
      property {
        _id
        name
      }
    }
  }

  ${commonUpdateFields}
`;

function AddProjectUpdateFormWithSelectActionSidebar(props) {
  const projectId = R.path(['entity', '_id'], props);
  return (
    <AddProjectUpdateFormWithSelectAction
      width="100%"
      projectId={projectId}
      {...props}
    />
  );
}

export function LinkToParentClone({ cloneFromProject, parentClone, inNewTab }) {
  const { ProjectLink } = useSidebarLogicContext();

  const linkProps = cloneFromProject || parentClone;
  return linkProps ? (
    <>
      {' '}
      {ProjectLink ? (
        <ProjectLink {...linkProps} inNewTab={inNewTab} />
      ) : (
        linkProps.projectId
      )}
    </>
  ) : null;
}

LinkToParentClone.propTypes = {
  cloneFromProject: shape({
    projectId: string,
    type: string,
  }),
  parentClone: shape({
    projectId: string,
    _id: string,
  }),
  inNewTab: bool,
};

export function ProjectSidebarUpdatesTab({ _id, projectId, cloneFromProject }) {
  const tabProps = {
    entity: {
      _id,
      preview: 'project',
      name: entities.PROJECT,
      humanReadableId: projectId,
    },
    collection: UpdateCollections.projects,
    updatesQuery: PROJECT_UPDATES,
    PrintLayout: PrintLayoutProvider,
    AddUpdateFormComponent: AddProjectUpdateFormWithSelectActionSidebar,
    LinkToParentClone: {
      Component: LinkToParentClone,
      props: { cloneFromProject },
    },
  };

  return <SidebarUpdatesTab {...tabProps} />;
}

ProjectSidebarUpdatesTab.propTypes = {
  _id: string.isRequired,
  projectId: string.isRequired,
  cloneFromProject: shape({
    projectId: string,
    type: string,
  }),
};
