import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape, bool, func } from 'prop-types';
import { useMapConfigToTableProps } from 'poly-admin-ui';

import { Table } from 'poly-book-admin/src/Table/Table.js';
import { WindowedTable } from 'poly-book-admin/src/Table/WindowedTable.js';

import { QuoteDiscrepanciesPageFooter } from './QuoteDiscrepanciesPageFooter.js';

const QuoteDiscrepanciesTableS = styled(WindowedTable)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 10%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 10%;
  }

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    width: 12%;
    text-align: right;
    padding-right: 20px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 35%;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 12%;
    text-align: right;
    padding-right: 20px;
  }

  th:nth-child(7),
  td:nth-child(7) {
    width: 12%;
    text-align: right;
    padding-right: 20px;
  }
`;

const QuoteDiscrepanciesPrintTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 10%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 10%;
  }

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    width: 12%;
    text-align: right;
    padding-right: 20px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 33%;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 12%;
    text-align: right;
    padding-right: 20px;
  }

  th:nth-child(7),
  td:nth-child(7) {
    width: 12%;
    text-align: right;
    padding-right: 20px;
  }
`;

export function QuoteDiscrepanciesPageTable({
  isPrintPDF,
  tableRows,
  tableSortingProps,
  getTableConfig,
  prepareFooterData,
}) {
  const tableConfigProps = useMapConfigToTableProps(
    R.identity,
    getTableConfig(isPrintPDF),
    tableRows,
  );

  const TableComponent = isPrintPDF
    ? QuoteDiscrepanciesPrintTableS
    : QuoteDiscrepanciesTableS;

  return (
    <>
      <TableComponent {...tableConfigProps} {...tableSortingProps} />
      {isPrintPDF && (
        <QuoteDiscrepanciesPageFooter
          tableRows={tableRows}
          prepareFooterData={prepareFooterData}
          isPrintPDF
        />
      )}
    </>
  );
}

QuoteDiscrepanciesPageTable.propTypes = {
  tableRows: arrayOf(shape({})),
  tableSortingProps: shape({}),
  isPrintPDF: bool,
  getTableConfig: func.isRequired,
  prepareFooterData: func.isRequired,
};
