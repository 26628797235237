import React from 'react';
import { READ_TASK_PERMISSION, FULL_ACCESS_PERMISSION } from 'poly-security';
import { UserStatuses } from 'poly-constants';

import { UserSelect } from './UserSelect/index.js';

export function TaskAssignUsersSelect(props) {
  const queryProps = {
    withoutSkip: true,
    query: {
      bool: {
        must: [
          {
            nested: {
              path: 'userGroups',
              query: { exists: { field: 'userGroups.userGroupId' } },
            },
          },
          { term: { status: UserStatuses.ACTIVE } },
          {
            nested: {
              path: 'accessItems',
              query: {
                terms: {
                  'accessItems.permission': [
                    READ_TASK_PERMISSION,
                    FULL_ACCESS_PERMISSION,
                  ],
                },
              },
            },
          },
        ],
      },
    },
  };

  return <UserSelect {...props} {...queryProps} />;
}

TaskAssignUsersSelect.displayName = 'TaskAssignUsersSelect';
