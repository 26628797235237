import * as R from 'ramda';
import React, { useState } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { LinkButton } from 'poly-book-admin';
import { useNotificationState } from 'poly-admin-ui';
import { isNilOrEmpty } from 'poly-utils';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';

// getSupplier1099ReportRow :: PreparedSupplier -> [ExcelExportDataCell]
const getSupplier1099ReportRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 13),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 3),
  ]),
  R.juxt([
    R.either(R.prop('taxName'), R.propOr('', 'name')),
    R.propOr('', 'taxId'),
    R.propOr('', 'is1099'),
    R.propOr('', 'address'),
    R.propOr('', 'addressTwo'),
    R.propOr('', 'city'),
    R.propOr('', 'state'),
    R.propOr('', 'zip'),
    R.prop('isWbe'),
    R.prop('mbe'),
    R.prop('isFoodBuy'),
    R.prop('foodBuyVaPercentage'),
    R.prop('foodBuyVendorType'),
    R.propOr(0, 'paidByCC'),
    R.propOr(0, 'paidByOthers'),
    R.propOr(0, 'total'),
  ]),
);

// eslint-disable-next-line import/no-unused-modules
export const getSupplier1099ExportExcelConfig = (supplierRows, year) => ({
  exportFileName: 'supplier-1099-report.xlsx',
  columnWidths: [20, 10, 8, 20, 15, 15, 5, 10, 5, 10, 5, 5, 10, 12, 15, 10],
  rows: [
    [{ value: 'Supplier 1099 Report', cellType: ExcelExportCellType.title }],
    [{ value: year, cellType: ExcelExportCellType.subTitle }],

    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 13),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 3),
      ],
      [
        'Name',
        'Tax ID',
        '1099',
        'Address',
        'Address Two',
        'City',
        'State',
        'Zip',
        'WBE',
        'MBE',
        'Foodbuy',
        'Foodbuy VA %',
        'Foodbuy Vendor Type',
        'Paid By CC',
        'Paid By Others',
        'Amount',
      ],
    ),

    ...supplierRows.map(getSupplier1099ReportRow),
  ],
});

export function ExportXLSSupplier1099ReportBtn({
  disabled,
  year,
  filteredRows,
}) {
  const { showErrorNotification } = useNotificationState();
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    if (isNilOrEmpty(filteredRows)) {
      showErrorNotification('No report records to export');
      return;
    }

    setIsLoading(true);

    const config = getSupplier1099ExportExcelConfig(filteredRows, year);
    await performExcelExport(config);

    setIsLoading(false);
  };

  return (
    <LinkButton onClick={onClick} loader={isLoading} disabled={disabled}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

export const filteredRowsPropTypes = arrayOf(
  shape({
    name: string,
    taxName: string,
    paidByCC: number,
    paidByOthers: number,
    taxId: string,
    addressTwo: string,
    state: string,
  }),
);

ExportXLSSupplier1099ReportBtn.propTypes = {
  disabled: bool,
  year: number,
  filteredRows: filteredRowsPropTypes,
};
