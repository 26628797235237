import React from 'react';
import * as R from 'ramda';
import { Select } from 'poly-book-admin';

export function DivisionSelect(props) {
  const options = R.compose(
    R.map((item) => ({ value: item, label: item })),
    R.pathOr(
      [],
      ['formData', 'client', 'configs', 'propertyFields', 'division'],
    ),
  )(props);

  return <Select {...props} options={options} />;
}
