import React from 'react';
import * as R from 'ramda';
import { formatTotal } from 'poly-utils';
import { getThemeColor } from 'poly-book-admin';
import { number, string } from 'prop-types';
import styled from 'styled-components';
import { FlexSpaceBetween } from '../../../modules/forms/assignSupplierForm/styles.js';

const LineTotalAmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: ${R.prop('width')};
`;

export function LineTotalAmount({ value, width }) {
  return (
    <LineTotalAmountContainer width={width}>
      {formatTotal(value)}
    </LineTotalAmountContainer>
  );
}

LineTotalAmount.propTypes = {
  value: number,
  width: string,
};

export const LabelColumn = styled.span`
  width: ${R.prop('width')};
  color: ${getThemeColor(['mid'])};
  font-size: 12px;
  text-align: ${({ align }) => align};
`;

export const LineContainer = styled(FlexSpaceBetween)`
  align-items: center;
  font-size: 12px;
  padding: 15px 0;
  border-bottom: 1px solid ${getThemeColor(['light'])};
`;

export const HeaderLineContainer = styled(LineContainer)`
  padding-top: 0;
`;
