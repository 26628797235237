import * as R from 'ramda';
import React from 'react';
import { startOfYear } from 'date-fns';
import { alwaysNewDate } from 'poly-utils';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { DateRangePicker, Select, ToolBarBtnDivider } from 'poly-book-admin';
import { READ_ACCOUNT_TYPE_PERMISSION } from 'poly-security';
import {
  formatFromToDateTitle,
  openPrintWindowWithData,
  useHasUserAccessWithPermission,
} from 'poly-client-utils';
import { ClientSelect, CommonPrintLayout } from 'poly-admin-ui';
import { PRINT_PDF_CAPTION } from 'poly-constants';

import { ClientInvoiceSelect } from '../../components/ClientInvoiceSelect.js';
import { ALL } from '../../modules/core/constants/general.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FlexColumn } from '../../components/FlexContainer.js';
import { SearchHeaderColumn } from '../../components/SearchHeaderColumn.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { CustomerHistoryExportXLSBtn } from './CustomerHistoryExportXLSBtn.js';
import { CustomerHistoryTable } from './CustomerHistoryTable.js';
import { TypeDropDown } from '../ChartOfAccounts/components.js';
import {
  customerTransactionPropType,
  customerFilterPropType,
} from './propTypes.js';
import {
  CustomerFiltersContainerS,
  CustomerSearchHeaderButton,
  CustomerHistoryLinkButtonS,
  HeaderToolbarRow,
  SubTitleS,
  CustomerNameS,
} from './components.js';
import {
  searchCustomerInvoicesFilters,
  customerInvoiceStatusSelectOptions,
} from './constants.js';

export const customerHistoryPageHeaderConfig = [
  { name: searchCustomerInvoicesFilters.clientId },
  { name: searchCustomerInvoicesFilters.projectId },
  { name: searchCustomerInvoicesFilters.invoiceId },
  { name: searchCustomerInvoicesFilters.accountTypeId },
  {
    name: searchCustomerInvoicesFilters.invoiceStatus,
    defaultValue: ALL,
  },
  {
    name: searchCustomerInvoicesFilters.invoiceDate,
    nestedFields: ['startDate', 'endDate'],
    defaultValue: {
      startDate: startOfYear(alwaysNewDate()).toDateString(),
      endDate: alwaysNewDate().toDateString(),
    },
  },
];

function CustomerHistoryPrintTitle({ title, date, customerName }) {
  return (
    <>
      <p>{title}</p>
      <SubTitleS>
        {formatFromToDateTitle(date.startDate, date.endDate)}
      </SubTitleS>
      <CustomerNameS>{`Client: ${customerName}`}</CustomerNameS>
    </>
  );
}

CustomerHistoryPrintTitle.propTypes = {
  title: string.isRequired,
  date: shape({
    startDate: string.isRequired,
    endDate: string.isRequired,
  }).isRequired,
  customerName: string.isRequired,
};

function CustomerHistoryPrintBtn({ transactions, filters, customerName }) {
  const disabled = R.isEmpty(transactions) || !filters?.clientId;
  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: CustomerHistoryTable,
      fileName: 'client_history',
      metaData: {
        title: (
          <CustomerHistoryPrintTitle
            title="Client History"
            date={filters.invoiceDate}
            customerName={customerName}
          />
        ),
      },
      transactions,
    });

  return (
    <CustomerHistoryLinkButtonS onClick={onClick} disabled={disabled}>
      {PRINT_PDF_CAPTION}
    </CustomerHistoryLinkButtonS>
  );
}

CustomerHistoryPrintBtn.propTypes = {
  transactions: arrayOf(customerTransactionPropType),
  filters: customerFilterPropType,
  customerName: string.isRequired,
};

export function CustomerHistoryPageHeader({
  setFilters,
  exportProps,
  loading,
}) {
  const hasPermission = useHasUserAccessWithPermission(
    READ_ACCOUNT_TYPE_PERMISSION,
  );

  const { searchFilters, handlers, onReset } = useSearchFilters(
    customerHistoryPageHeaderConfig,
  );

  const onResetFilters = () => {
    onReset();
    setFilters(null);
  };

  const onSearch = () => setFilters(searchFilters);

  const selectProps = {
    value: searchFilters.invoiceStatus,
    options: customerInvoiceStatusSelectOptions,
    onChange: handlers.invoiceStatus,
    name: 'invoiceStatusSelect',
    width: '100%',
    required: true,
  };

  return (
    <SearchPageHeaderContainer title="Client History">
      <CustomerFiltersContainerS>
        <FlexSpaceBetween>
          <FlexColumn>
            <SearchHeaderColumn titleWidth="120px" title="Client">
              <ClientSelect
                value={searchFilters.clientId}
                onChange={handlers.clientId}
                includeAllOption={false}
                width="100%"
                required
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn titleWidth="120px" title="Account Type">
              <TypeDropDown
                isClearable
                width="100%"
                name="accountTypeId"
                onChange={handlers.accountTypeId}
                value={searchFilters.accountTypeId}
              />
            </SearchHeaderColumn>
          </FlexColumn>
          <FlexColumn>
            <SearchHeaderColumn titleWidth="120px" title="Invoice Number">
              <ClientInvoiceSelect
                value={searchFilters.invoiceId}
                onChange={handlers.invoiceId}
                input={{}}
                name="invoiceId"
                width="100%"
              />
            </SearchHeaderColumn>
            <SearchHeaderColumn titleWidth="120px" title="Project Number">
              <ProjectSelect
                value={searchFilters.projectId}
                onChange={handlers.projectId}
                width="100%"
              />
            </SearchHeaderColumn>
          </FlexColumn>
          <FlexColumn>
            <SearchHeaderColumn
              titleWidth="120px"
              title="Invoice Status"
              margin="0 0 10px 0"
            >
              <Select {...selectProps} />
            </SearchHeaderColumn>
            <HeaderToolbarRow padding="7px 0 0 0">
              <CustomerHistoryPrintBtn {...exportProps} />
              <ToolBarBtnDivider />
              <CustomerHistoryExportXLSBtn {...exportProps} />
            </HeaderToolbarRow>
          </FlexColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          {hasPermission ? (
            <SearchHeaderColumn titleWidth="120px" title=" Date">
              <DateRangePicker
                value={searchFilters.invoiceDate}
                onChange={handlers.invoiceDate}
              />
            </SearchHeaderColumn>
          ) : (
            <SearchHeaderColumn />
          )}
          <SearchHeaderColumn filterWidth="190px">
            <CustomerSearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={onResetFilters}
            >
              Reset
            </CustomerSearchHeaderButton>
            <CustomerSearchHeaderButton
              size="small"
              loader={loading}
              onClick={onSearch}
              disabled={!searchFilters.clientId}
            >
              Search
            </CustomerSearchHeaderButton>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </CustomerFiltersContainerS>
    </SearchPageHeaderContainer>
  );
}

CustomerHistoryPageHeader.propTypes = {
  setFilters: func.isRequired,
  exportProps: shape({
    transactions: arrayOf(customerTransactionPropType),
    filters: customerFilterPropType,
    customerName: string,
  }),
  loading: bool,
};
