import * as R from 'ramda';
import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EXPORT_XLS_CAPTION, DESC_SORT_ORDER } from 'poly-constants';
import { commonSortQuery } from 'poly-client-utils';
import {
  CommonOuterButton,
  useTableSearch,
  usePagination,
} from 'poly-admin-ui';

import {
  MY_PROJECTS,
  PROJECTS_BY_SEARCH,
  MY_RECURRING_PROJECTS,
  RECURRING_PROJECTS_BY_SEARCH,
} from '../hooks/projects/queries.js';
import { generateMyProjectsXlsReport } from '../../tables/myProjectsTable/exportToXlsUtils.js';
import { getRecentProjectsXLS } from '../../tables/recentProjectsTable/exportToXLSUtils.js';
import {
  generateRecurringProjectsXlsReport,
  generateProjectsXlsReport,
} from '../../tables/projectsTable/exportToXlsUtils.js';
import {
  activeSearchQueryParamsTypes,
  setActiveSearchQueryParams,
} from '../../../redux/activeSearchQueryParamsReducer.js';

const { XLS_EXPORT } = activeSearchQueryParamsTypes;

// exportRecalledProjectsToXls :: SearchProjectsQueryResult -> [Project]
const exportRecalledProjectsToXls = R.compose(
  generateProjectsXlsReport('recalled_project_export'),
  R.pathOr([], ['data', 'searchProjects', 'hits']),
  R.prop('queryData'),
);

// exportMasterProjectsToXls :: SearchRecurringProjectsQueryResult -> [Project]
const exportMasterProjectsToXls = R.compose(
  generateRecurringProjectsXlsReport('recurring_projects_export'),
  R.pathOr([], ['data', 'searchRecurringProjects', 'hits']),
  R.prop('queryData'),
);

// exportMyRecurringProjectsToXls :: SearchMyRecurringProjectsQueryResult -> [Project]
const exportMyRecurringProjectsToXls = R.compose(
  generateRecurringProjectsXlsReport('my_recurring_projects_export'),
  R.pathOr([], ['data', 'me', 'searchManagerRecurringProjects', 'hits']),
  R.prop('queryData'),
);

// exportMyProjectsToXls :: SearchMyProjectsQueryResult -> [Project]
const exportMyProjectsToXls = R.compose(
  generateMyProjectsXlsReport,
  R.pathOr([], ['data', 'me', 'searchManagerProjects', 'hits']),
  R.prop('queryData'),
);

// CommonOuterButtonContainer :: Properties -> ReactComponent
function CommonOuterButtonContainer(props) {
  return <CommonOuterButton {...props}>{EXPORT_XLS_CAPTION}</CommonOuterButton>;
}

export const useSaveQueryForXLSExport = (props) => {
  const queryFromProps = R.pick(['query']);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setActiveSearchQueryParams({
        [XLS_EXPORT]: queryFromProps(props),
      }),
    );

    return () => {
      if (props.query) {
        dispatch(
          setActiveSearchQueryParams({
            [XLS_EXPORT]: null,
          }),
        );
      }
    };
  }, [props]);
};

const useTableExportToXLSBtn = ({ graphQuery, exportFunction, sort }) => {
  // we don't want to use `useLazyQuery` hook here cause it has unexpected calls
  // it can trigger problem for running tests with ApolloMockedProvider
  // https://stackoverflow.com/questions/76951882/why-is-my-apollo-uselazyquery-being-called-unexpectedly-in-a-react-hook
  const apolloClient = useApolloClient();
  const { pagination } = usePagination();
  const { searchText } = useTableSearch();
  const [isLoading, setIsLoading] = useState(false);

  const XLS_EXPORT_VALUE = useSelector(
    (state) => state.activeSearchQueryParams.XLS_EXPORT,
  );

  const onClick = async () => {
    setIsLoading(true);
    try {
      const queryData = await apolloClient.query({
        query: graphQuery,
        variables: {
          searchInput: {
            searchTerm: searchText,
            size: pagination.allItemsCount,
            from: 0,
            query: XLS_EXPORT_VALUE?.query,
            ...(sort ? { sort } : {}),
          },
        },
      });
      exportFunction({ queryData, query: XLS_EXPORT_VALUE?.query });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    onClick,
    searchText,
    pagination,
    setIsLoading,
    loading: isLoading,
    disabled: !XLS_EXPORT_VALUE,
  };
};

export function RecalledProjectTableExportToXLSBtn() {
  const exportToXLSProps = useTableExportToXLSBtn({
    graphQuery: PROJECTS_BY_SEARCH,
    exportFunction: exportRecalledProjectsToXls,
    sort: commonSortQuery(['priority'])(DESC_SORT_ORDER),
  });

  return <CommonOuterButtonContainer {...exportToXLSProps} />;
}

export function RecurringProjectTableExportToXLSBtn() {
  const exportToXLSProps = useTableExportToXLSBtn({
    graphQuery: RECURRING_PROJECTS_BY_SEARCH,
    exportFunction: exportMasterProjectsToXls,
  });

  return <CommonOuterButtonContainer {...exportToXLSProps} />;
}

export function RecentProjectsExportToXLSBtn() {
  const exportToXLSProps = useTableExportToXLSBtn({
    graphQuery: PROJECTS_BY_SEARCH,
    exportFunction: getRecentProjectsXLS,
  });

  return <CommonOuterButtonContainer {...exportToXLSProps} />;
}

export function MyRecurringProjectTableExportToXLSBtn() {
  const exportToXLSProps = useTableExportToXLSBtn({
    graphQuery: MY_RECURRING_PROJECTS,
    exportFunction: exportMyRecurringProjectsToXls,
  });

  return <CommonOuterButtonContainer {...exportToXLSProps} />;
}

export function MyProjectsTableExportToXLSBtn() {
  const exportToXLSProps = useTableExportToXLSBtn({
    graphQuery: MY_PROJECTS,
    exportFunction: exportMyProjectsToXls,
  });

  return <CommonOuterButtonContainer {...exportToXLSProps} />;
}
