import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useOutSidebarContext } from 'poly-client-utils';
import { getThemeColor } from 'poly-book-admin';
import { SidebarWrapper } from 'poly-admin-ui';
import { BillingProfileConfigurationSidebarId } from '../constants.js';
import { SidebarCloseIcon } from '../../../components/SidebarLayouts.js';
import {
  setBillingProfileConfig,
  getBillingProfileConfig,
} from '../../../redux/searchFilters/billingProfileConfig.js';
import { BillingProfileConfigurationForm } from './BillingProfileConfigurationForm.js';
import { useClientPOEnabled } from './useClientConfig.js';

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
`;

const PageTitle = styled.h3`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${getThemeColor(['primary'])};
  margin: 0;
`;

const BillingProfileConfigurationSidebarWrapperS = styled(SidebarWrapper)`
  overflow-x: hidden;
  height: 100%;
`;

export function BillingProfileConfigurationSidebar({ clearSelectedProjects }) {
  const dispatch = useDispatch();
  const { closeOutSidebar } = useOutSidebarContext();

  const enableClientPurchaseOrder = useClientPOEnabled();

  const billingProfileConfig = useSelector(getBillingProfileConfig);

  const onClose = () => closeOutSidebar(BillingProfileConfigurationSidebarId);

  const onSubmit = (values) => {
    clearSelectedProjects();
    dispatch(setBillingProfileConfig(values));
    onClose();
  };

  const initialValues = {
    ...(billingProfileConfig || {}),
    enableClientPurchaseOrder,
  };

  return (
    <BillingProfileConfigurationSidebarWrapperS>
      <SidebarHeader>
        <PageTitle>Billing Profile Configuration</PageTitle>
        <SidebarCloseIcon onClick={onClose} />
      </SidebarHeader>
      <BillingProfileConfigurationForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        enablePurchaseOrder={enableClientPurchaseOrder}
      />
    </BillingProfileConfigurationSidebarWrapperS>
  );
}

BillingProfileConfigurationSidebar.propTypes = {
  clearSelectedProjects: func.isRequired,
};
