import React, { useEffect } from 'react';
import * as R from 'ramda';
import { func, bool } from 'prop-types';
import { Select } from 'poly-book-admin';
import { ClientSelect } from 'poly-admin-ui';
import { TitleWithBackButton } from 'poly-admin-ui/src/components/pagesHeadersComps.js';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { clientInvoicesPropType } from './prop-types.js';
import { InvoiceHistoryExportPDFBtn } from './InvoiceHistoryExportPDFBtn.js';
import { customerInvoiceStatusSelectOptions } from '../ClientHistoryPage/constants.js';
import { ALL } from '../../modules/core/constants/general.js';

const clientInvoiceHistoryFilterConfig = [
  { name: 'clientId' },
  { name: 'status', defaultValue: ALL },
];

// convertFiltersToQueryInput :: Object -> ClientInvoiceInput
const convertFiltersToQueryInput = R.ifElse(
  R.propEq('status', ALL),
  R.omit(['status']),
  R.over(R.lensProp('status'), R.of),
);

function TitleComp() {
  return <TitleWithBackButton title="Client Invoice History" />;
}

export function ClientInvoiceHistoryHeader({
  setClientInvoicesFilters,
  loading,
  clientInvoices,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    clientInvoiceHistoryFilterConfig,
  );

  const onSearch = () => {
    const input = convertFiltersToQueryInput(searchFilters);
    if (searchFilters.clientId) {
      // clientId is required
      setClientInvoicesFilters(input);
    }
  };

  const onResetSearch = () => {
    onReset();
    setClientInvoicesFilters(null);
  };

  useEffect(onSearch, []);

  const selectProps = {
    value: searchFilters.status,
    options: customerInvoiceStatusSelectOptions,
    onChange: handlers.status,
    name: 'invoiceStatusSelect',
    width: '100%',
  };

  return (
    <SearchPageHeaderContainer TitleComp={TitleComp}>
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Client"
            titleWidth="100px"
            filterWidth="260px"
          >
            <ClientSelect
              value={searchFilters.clientId}
              onChange={handlers.clientId}
              width="100%"
              includeAllOption={false}
              withoutSkip
              required
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="120px" title="Invoice Status">
            <Select {...selectProps} />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="0px" filterWidth="300px">
            <FlexCenterAlign>
              <InvoiceHistoryExportPDFBtn
                clientInvoices={clientInvoices}
                clientId={searchFilters.clientId}
              />
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

ClientInvoiceHistoryHeader.propTypes = {
  setClientInvoicesFilters: func.isRequired,
  clientInvoices: clientInvoicesPropType.isRequired,
  loading: bool,
};
