import * as R from 'ramda';
import { v4 as uuidV4 } from 'uuid';
import { forceTitleCase, assocBy, isNilOrEmpty } from 'poly-utils';
import {
  NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
  FLIPPED_CONTAINS_AIT_OPERATOR,
  LOGIN_VIA_SMS_APP_PERMISSION,
  FULL_ACCESS_PERMISSION,
  LOGIN_APP_PERMISSION,
  VARIABLE_AIT_OPERATOR,
} from 'poly-security';

// getPermissionsStringLineByAccessItems :: [AccessItem] -> String
const getPermissionsStringLineByAccessItems = R.compose(
  R.join(' | '),
  R.values,
  R.mapObjIndexed((actions, entity) =>
    R.compose(
      R.join(' '),
      R.append(entity),
      R.of,
      R.join(', '),
      R.map(R.nth(0)),
      R.uniq,
      R.defaultTo([]),
    )(actions),
  ),
  R.groupBy(R.nth(1)),
  R.map(
    R.compose(
      R.map(forceTitleCase),
      R.over(R.lensProp(1), R.compose(R.join(' '), R.split('_'))),
      R.split('__'),
      R.prop('permission'),
    ),
  ),
);

// formatUserGroupPermissions :: UserGroup -> String
export const formatUserGroupPermissions = R.compose(
  R.ifElse(
    R.both(
      R.propSatisfies(R.equals(1), 'length'),
      R.pathSatisfies(R.equals(FULL_ACCESS_PERMISSION), [0, 'permission']),
    ),
    R.always('All'),
    getPermissionsStringLineByAccessItems,
  ),
  R.defaultTo([]),
  R.prop('accessItems'),
);

// checkIfHasLoginPermission :: { accessItems: [PermissionFormLine] } -> Boolean
// PermissionFormLine = { action: AccessPermission }
export const checkIfHasLoginPermission = R.compose(
  R.complement(R.isNil),
  R.find(
    R.propSatisfies(
      R.includes(R.__, [
        LOGIN_APP_PERMISSION,
        FULL_ACCESS_PERMISSION,
        LOGIN_VIA_SMS_APP_PERMISSION,
      ]),
      'action',
    ),
  ),
  R.propOr([], 'accessItems'),
);

// checkIfLineInEditMode :: { accessItems: [PermissionFormLine] } -> Boolean
// PermissionFormLine = { action: AccessPermission }
export const checkIfLineInEditMode = R.compose(
  R.includes(true),
  R.flatten,
  R.map(
    R.compose(R.map(R.prop('inEditMode')), R.defaultTo([]), R.prop('scopes')),
  ),
  R.defaultTo([]),
  R.prop('accessItems'),
);

// getFormLineOperatorAndEntities :: AccessItemType -> FormScope
const getFormLineOperatorAndEntities = R.compose(
  R.converge(R.mergeDeepLeft, [
    R.compose(R.objOf('operator'), R.path([0, 0])),
    R.compose(R.objOf('entities'), R.unless(R.is(Array), R.of), R.path([0, 1])),
  ]),
  R.toPairs,
  R.reject(R.isNil),
  R.dissoc('__typename'),
  R.last,
);

// getFormScopeLinesByTypes :: [AccessItemType] -> [FormScope]
const getFormScopeLinesByTypes = R.unless(
  R.isEmpty,
  R.map(
    R.converge(R.mergeDeepLeft, [
      R.compose(R.objOf('scope'), R.head),
      getFormLineOperatorAndEntities,
    ]),
  ),
);

// getFormScopesByAccessItemTypes :: AccessItem -> { scopes: [FormScope] }
// FormScope :: { scope: String, operator: String, entities: [ID] }
const getFormScopesByAccessItemTypes = R.compose(
  R.ifElse(
    R.isNil,
    R.always({}),
    R.compose(
      R.objOf('scopes'),
      getFormScopeLinesByTypes,
      R.toPairs,
      R.reject(R.isNil),
      R.dissoc('__typename'),
    ),
  ),
  R.prop('types'),
);

// getFormPermissionEntityBySchema :: UserGroupPermissionSchemaQueryResult
// -> AccessItem -> { entity: ID }
const getFormPermissionEntityBySchema = (schema) =>
  R.compose(
    R.ifElse(
      R.isNil,
      R.always({}),
      R.compose(R.objOf('entity'), R.prop('_id')),
    ),
    (permission) =>
      R.compose(
        R.find(
          R.compose(
            R.includes(permission),
            R.map(R.prop('permission')),
            R.prop('actions'),
          ),
        ),
        R.defaultTo([]),
        R.prop('getUserGroupPermissionSchema'),
      )(schema),
    R.prop('permission'),
  );

// prepareUserGroupForFormValues :: (UserGroupPermissionSchemaQueryResult)
// -> UserGroup -> UserGroupFormValues
export const prepareUserGroupForFormValues = (schema) =>
  R.compose(
    R.when(
      R.prop('accessItems'),
      R.over(
        R.lensProp('accessItems'),
        R.compose(
          R.map(
            R.compose(
              R.mergeAll,
              R.juxt([
                getFormPermissionEntityBySchema(schema),
                R.compose(R.objOf('action'), R.prop('permission')),
                getFormScopesByAccessItemTypes,
              ]),
            ),
          ),
          R.defaultTo([]),
        ),
      ),
    ),
    R.pick([
      'name',
      'accessItems',
      'defaultLoginApp',
      'isTwoFactorAuthRequired',
    ]),
  );

// getSelectOptionValue :: Option -> ID
const getSelectOptionValue = R.when(R.is(Object), R.prop('value'));

// getAccessItemTypesObjectByOperator :: FormScope -> { [AITOperator]: [ID] || ID }
const getAccessItemTypesObjectByOperator = R.converge(R.objOf, [
  R.prop('operator'),
  R.cond([
    [
      R.propSatisfies(
        R.includes(R.__, [
          NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
          FLIPPED_CONTAINS_AIT_OPERATOR,
        ]),
        'operator',
      ),
      R.compose(R.map(getSelectOptionValue), R.prop('entities')),
    ],
    [
      R.propSatisfies(R.is(Array), 'entities'),
      R.compose(getSelectOptionValue, R.path(['entities', 0])),
    ],
    [R.T, R.compose(getSelectOptionValue, R.prop('entities'))],
  ]),
]);

// prepareAccessItemTypesByFormValues :: { scopes: [FormScope] } -> { types: AccessItemTypes }
const prepareAccessItemTypesByFormValues = R.compose(
  R.ifElse(
    R.isEmpty,
    R.always({}),
    R.compose(
      R.objOf('types'),
      R.fromPairs,
      R.map(R.juxt([R.prop('scope'), getAccessItemTypesObjectByOperator])),
    ),
  ),
  R.reject(R.propSatisfies(isNilOrEmpty, 'scope')),
  R.defaultTo([]),
  R.prop('scopes'),
);

// prepareAccessItemTypesByFormValues :: UserGroupFormValues -> [UserGroupVariableInput]
const prepareVariablesTypesByFormValues = R.compose(
  R.map(
    R.applySpec({
      type: R.prop('scope'),
      id: R.path(['entities', 'value']),
      name: R.path(['entities', 'label']),
    }),
  ),
  R.uniq,
  R.filter(R.propEq('operator', VARIABLE_AIT_OPERATOR)),
  R.reject(R.propSatisfies(isNilOrEmpty, 'scope')),
  R.flatten,
  R.reject(isNilOrEmpty),
  R.map(R.prop('scopes')),
  R.reject(R.propSatisfies(isNilOrEmpty, 'entity')),
  R.defaultTo([]),
  R.prop('accessItems'),
);

// prepareUserGroupFormForMutation :: UserGroupFormValues -> CreateUserGroupInput
export const prepareUserGroupFormForMutation = R.compose(
  R.when(
    R.prop('accessItems'),
    R.over(
      R.lensProp('accessItems'),
      R.compose(
        R.map(
          R.compose(
            R.mergeAll,
            R.juxt([
              R.always({ uid: uuidV4() }),
              R.compose(R.objOf('permission'), R.prop('action')),
              prepareAccessItemTypesByFormValues,
            ]),
          ),
        ),
        R.reject(R.propSatisfies(R.isNil, 'entity')),
        R.defaultTo([]),
      ),
    ),
  ),
  assocBy('variables', prepareVariablesTypesByFormValues),
  R.pick(['name', 'accessItems', 'defaultLoginApp', 'isTwoFactorAuthRequired']),
);

// prepareUserGroupVariablesSelector :: UserGroup -> Object
export const prepareUserGroupVariablesSelector = R.compose(
  R.ifElse(
    R.isEmpty,
    R.always(null),
    R.compose(
      R.map(R.map(R.applySpec({ value: R.prop('id'), label: R.prop('name') }))),
      R.groupBy(R.prop('type')),
    ),
  ),
  R.defaultTo([]),
  R.prop('variables'),
);
