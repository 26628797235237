import React from 'react';
import { string } from 'prop-types';
import { gql } from '@apollo/client';
import { commonUpdateFields, entities, SidebarUpdatesTab } from 'poly-admin-ui';
import { UpdateCollections } from 'poly-constants';

import { PrintLayoutProvider } from '../../../providers.js';

const USER_GROUP_UPDATES = gql`
  query USER_GROUP_UPDATES(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    userGroup(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export function UserGroupSidebarUpdatesTab({ userGroupId, userGroupName }) {
  const tabProps = {
    entity: {
      _id: userGroupId,
      preview: 'user group',
      name: entities.USER_GROUP,
      humanReadableId: userGroupName,
    },
    updatesQuery: USER_GROUP_UPDATES,
    PrintLayout: PrintLayoutProvider,
    collection: UpdateCollections.userGroups,
  };

  return <SidebarUpdatesTab {...tabProps} />;
}

UserGroupSidebarUpdatesTab.propTypes = {
  userGroupId: string.isRequired,
  userGroupName: string.isRequired,
};
