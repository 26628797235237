import React from 'react';
import styled, { css } from 'styled-components';
import { node, string, bool } from 'prop-types';
import { getThemeColor, Text } from 'poly-book-admin';
import { BlockWithLabel } from '../components/commonSidebarComponents.js';

const longTextStyles = css`
  max-height: 42px;
  overflow: auto;
`;

const Info = styled(Text).attrs(() => ({
  lineHeight: '21px',
}))`
  flex-grow: 1;
  justify-content: flex-start;
  & > span {
    color: ${getThemeColor(['secondaryMid'])};
  }
  word-wrap: break-word;
  min-width: 0;

  ${({ withLongText }) => withLongText && longTextStyles};
`;

export function HeaderBlock({ title, width, content, withLongText }) {
  const additionalProps = {
    content,
    withLongText,
    title,
    children: content,
  };
  return (
    <BlockWithLabel
      {...additionalProps}
      id={title}
      label={title}
      width={width}
      Component={Info}
    />
  );
}

HeaderBlock.propTypes = {
  title: string.isRequired,
  content: node.isRequired,
  width: string,
  withLongText: bool,
};

HeaderBlock.defaultProps = {
  width: '30%',
  withLongText: false,
};
