import React from 'react';
import styled from 'styled-components';
import { UserSelect, getAdminUsersBySystemStatusQuery } from 'poly-admin-ui';
import { UserStatuses } from 'poly-constants';
import { Button } from 'poly-book-admin';

import {
  SearchPageLabel,
  SearchPageFilterRow,
} from '../commonSearchPageHeaderComps.js';

const FieldWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 220px;
`;

export const FilterWrapper = styled(SearchPageFilterRow)`
  justify-content: space-between;
`;

export const BlockWrapper = styled(FilterWrapper)`
  width: auto;
  padding: 0;
`;

export const FieldLabel = styled(SearchPageLabel)`
  width: 90px;
`;

export function PersonnelSelect(props) {
  const query = getAdminUsersBySystemStatusQuery(UserStatuses.ACTIVE);

  return (
    <FieldWrapper>
      <UserSelect {...props} width="100%" query={query} withoutSkip />
    </FieldWrapper>
  );
}

export function FilterButton(props) {
  return (
    <Button {...props} size="small">
      Filter
    </Button>
  );
}
