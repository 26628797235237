import * as R from 'ramda';
import { isManualTotalTimesheet } from 'poly-constants';
import { applySpecWithFields } from 'poly-utils';

import { timesheetInitialValues } from '../constants.js';
import {
  pickCommonTimesheetProps,
  constructNewDateForProp,
  constructDatePropsForTimeEntry,
} from './common.js';

// convertTimesheetToFormData :: Object -> Timesheet
export const convertTimesheetToFormData = R.compose(
  // ensures required time entry exists after change of type
  R.when(
    isManualTotalTimesheet,
    R.assoc('entries', timesheetInitialValues.entries),
  ),
  constructNewDateForProp('date'),
  R.over(
    R.lensProp('entries'),
    R.compose(R.map(constructDatePropsForTimeEntry), R.defaultTo([])),
  ),
  // omit uneditable fields
  R.unless(isManualTotalTimesheet, R.omit(['totalTimeInMinutes'])),
  R.omit(['projectId']),
  pickCommonTimesheetProps,
  applySpecWithFields({
    ownerId: R.path(['owner', '_id']),
  }),
);
