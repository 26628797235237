import React from 'react';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';
import { MainHeader, PageHeaderContainer, TextButton } from 'poly-book-admin';

import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { ApprovePostSupplierInvoicesTabsMap } from './constants.js';
import { ApproveInvoicesTable } from './ApproveSupplierInvoicesTable.js';
import { ApproveSupplierInvoicesPageTabTitle } from './ApproveSupplierInvoicesPageTabTitle.js';
import { useAddSupplierInvoicesSidebar } from './AddSupplierInvoicesForm/useAddSupplierInvoicesSidebar.js';

const { TO_REVIEW, SUBMITTED_BY_ME, ALL } = ApprovePostSupplierInvoicesTabsMap;

export function ApproveSupplierInvoicesPage() {
  const { user } = useCurrentUserByStoreOrQuery();
  const openAddSupplierInvoicesSidebar = useAddSupplierInvoicesSidebar();

  const userId = user?._id;

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Approve/Post Supplier Invoices</MainHeader>
        <TextButton onClick={openAddSupplierInvoicesSidebar}>
          Add Supplier Invoice
        </TextButton>
      </PageHeaderContainer>
      <PageTabs
        tabs={[
          [
            <ApproveSupplierInvoicesPageTabTitle
              excludeUserId={userId}
              tab={TO_REVIEW}
              key="to-review-tab"
            />,
            TO_REVIEW,
            <ApproveInvoicesTable
              excludeUserId={userId}
              tab={TO_REVIEW}
              key="to-review-tab"
            />,
          ],
          [
            <ApproveSupplierInvoicesPageTabTitle
              includeUserId={userId}
              tab={SUBMITTED_BY_ME}
              key="submitted-by-me-tab"
            />,
            SUBMITTED_BY_ME,
            <ApproveInvoicesTable
              includeUserId={userId}
              tab={SUBMITTED_BY_ME}
              key="submitted-by-me-tab"
            />,
          ],
          [
            <ApproveSupplierInvoicesPageTabTitle
              tab={ALL}
              userId={userId}
              key="all-tab"
            />,
            ALL,
            <ApproveInvoicesTable tab={ALL} userId={userId} key="all-tab" />,
          ],
        ]}
        defaultValue={TO_REVIEW}
      />
    </>
  );
}
