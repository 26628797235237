import { entities } from 'poly-admin-ui';

import { useSidebarLogicContext } from './SidebarLogicContext.js';

export const useOnCloseFormSidebar = (isCard) => {
  const {
    openUserSidebar,
    openAssetSidebar,
    openClientSidebar,
    openContactSidebar,
    openProjectSidebar,
    openPropertySidebar,
    openUserGroupSidebar,
    openPOSidebar,
  } = useSidebarLogicContext();

  return (entity, sidebarId, type) => {
    if (isCard && entity !== entities.CONTACT) return null;

    if (entity === entities.CLIENT) {
      return openClientSidebar(sidebarId);
    }

    if (entity === entities.PROPERTY) {
      return openPropertySidebar(sidebarId);
    }

    if (entity === entities.PROJECT || entity === entities.RECURRING_PROJECT) {
      return openProjectSidebar({ projectId: sidebarId, type });
    }

    if (entity === entities.USER) {
      return openUserSidebar(sidebarId);
    }

    if (entity === entities.CONTACT) {
      return openContactSidebar(sidebarId);
    }

    if (entity === entities.ASSET) {
      return openAssetSidebar(sidebarId);
    }

    if (entity === entities.ADMIN_PURCHASE_ORDER) {
      return openPOSidebar(sidebarId);
    }

    if (entity === entities.USER_GROUP) {
      return openUserGroupSidebar(sidebarId);
    }

    return null;
  };
};
