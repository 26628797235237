import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import styled from 'styled-components';
import { Button, Loader, Text } from 'poly-book-admin';
import { useModalContext } from 'poly-admin-ui';

const TASK_RESULT_MODAL_ID = 'long-running-task-modal-id';

const TaskMessage = styled(Text)`
  margin-bottom: 20px;
`;

const ResultContentWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

function LongRunningTaskResult({
  taskAction,
  onViewResult,
  viewResultCaption,
  loadingMessage,
  doneMessage,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);
  const { closeModal } = useModalContext();

  useEffect(() => {
    async function performAction() {
      const res = await taskAction();
      setResult(res);
      setIsLoading(false);
    }

    performAction();
  }, []);

  const onViewResultInternal = () => {
    onViewResult(result);
    closeModal(TASK_RESULT_MODAL_ID);
  };

  if (isLoading) {
    return (
      <ResultContentWrapper>
        <Loader />
        <TaskMessage>{loadingMessage}</TaskMessage>
      </ResultContentWrapper>
    );
  }

  return (
    <ResultContentWrapper>
      <TaskMessage>{doneMessage}</TaskMessage>
      <Button onClick={onViewResultInternal}>{viewResultCaption}</Button>
    </ResultContentWrapper>
  );
}

LongRunningTaskResult.defaultProps = {
  viewResultCaption: 'View',
  loadingMessage: 'Loading...',
  doneMessage: 'Completed!',
};

LongRunningTaskResult.propTypes = {
  taskAction: func.isRequired,
  onViewResult: func.isRequired,
  viewResultCaption: string,
  loadingMessage: string,
  doneMessage: string,
};

export const useShowLongRunningTaskResult = () => {
  const { openModal } = useModalContext();

  return function openLongRunningTaskResultModal({ title, ...props }) {
    openModal({
      id: TASK_RESULT_MODAL_ID,
      title,
      content: <LongRunningTaskResult {...props} />,
    });
  };
};
