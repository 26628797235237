import React from 'react';
import { SubmitBtn } from 'poly-admin-ui';
import { MainHeader, PageHeaderContainer } from 'poly-book-admin';
import { addSupplierFormId } from './constants.js';
import { useFormButtonProps } from '../../core/hooks/users/form.js';
import {
  ButtonsContainer,
  CancelFormBtn,
} from '../../../components/Buttons.js';
import { routesNames } from '../../../routes/constants.js';

function SubmitButton(props) {
  const formProps = useFormButtonProps(addSupplierFormId);
  return <SubmitBtn {...props} {...formProps} />;
}

function CancelButton(props) {
  const formProps = useFormButtonProps(addSupplierFormId, false);
  return <CancelFormBtn {...props} {...formProps} />;
}

export function AddSupplierFormHeader() {
  return (
    <PageHeaderContainer>
      <MainHeader>Create a New Supplier</MainHeader>
      <ButtonsContainer>
        <SubmitButton>Create</SubmitButton>
        <CancelButton redirectUrl={routesNames.SUPPLIERS_ADMINISTRATIVE_AAC} />
      </ButtonsContainer>
    </PageHeaderContainer>
  );
}
AddSupplierFormHeader.displayName = 'AddSupplierFormHeader';
