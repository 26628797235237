import styled from 'styled-components';
import { getThemeColor, WhiteCard } from 'poly-book-admin';

export const PageCardBody = styled(WhiteCard).attrs(() => ({
  id: 'page-card-id',
}))``;

export const PageCardFirstSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  max-height: 100%;
  overflow: ${({ fullWidth }) => (fullWidth ? 'hidden' : 'auto')};
  align-self: flex-start;
`;

export const PageCardSecondSection = styled(PageCardFirstSection)`
  height: 100%;
  background-color: ${getThemeColor(['lightest'])};
  overflow: ${({ fullWidth }) => (fullWidth ? 'visible' : 'hidden')};
  z-index: 1;

  > div > div:first-child > div > div {
    ${({ adjustWidth }) => (adjustWidth ? 'padding: 10px 6px' : '')};
  }

  > div > div:last-child {
    height: calc(100vh - 165px);
  }
`;
