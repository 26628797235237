import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, shape, string, node, func, number, object } from 'prop-types';
import { upperCaseFirst, removeFileExtension } from 'poly-utils';
import { Text, Icon, defaultTheme } from 'poly-book-admin';
import {
  highlightTextToReactElement,
  UpdateSentEmailsPreview,
  isEmailFile,
  isFileImage,
} from 'poly-client-utils';

import {
  mentionsShape,
  MessageWithMentions,
} from '../../../components/MessageWithMentions.js';
import { UpdateEmailData } from './UpdateEmailData.js';
import { UpdateImagesSection } from './UpdateImagesSection.js';

const FlexCenterAlign = styled.div`
  display: flex;
  align-items: center;
`;

const IconS = styled(Icon)`
  margin-right: 10px;
`;

const attachmentTypes = {
  url: string.isRequired,
  fileName: string.isRequired,
  size: string,
};

const FileLink = styled.a`
  font-size: ${R.prop('size')};
`;

export function UpdatesFileLink({ url, iconSize, fileName, size }) {
  return (
    <FlexCenterAlign>
      <IconS
        name="send-to"
        size={iconSize}
        color={defaultTheme.colors.accent2}
      />
      <FileLink
        size={size}
        rel="noopener noreferrer"
        target="_blank"
        href={url}
      >
        {removeFileExtension(fileName)}
      </FileLink>
    </FlexCenterAlign>
  );
}

UpdatesFileLink.propTypes = {
  ...attachmentTypes,
  size: string,
  iconSize: number,
};
UpdatesFileLink.defaultProps = {
  iconSize: 12,
};

// prepareImageAttachments :: [File] -> [File]
const prepareImageAttachments = R.compose(
  R.sortWith([R.descend(R.prop('fileSize')), R.ascend(R.prop('fileName'))]),
  R.filter(isFileImage),
  R.defaultTo([]),
);

export function UpdateDetails({
  title = '',
  message = '',
  mentions = {},
  attachments = [],
  searchText = '',
  emailData = null,
  LinkToParentClone,
}) {
  const images = prepareImageAttachments(attachments);
  const emailFiles = R.filter(isEmailFile, attachments);
  const imagesAndEmails = R.concat(images, emailFiles);
  const restAttachments = R.without(imagesAndEmails, attachments);

  return (
    <div>
      <Text size="12px" lineHeight="18px" weight="semibold">
        {highlightTextToReactElement(searchText, upperCaseFirst(title))}
      </Text>
      {!!emailData && <UpdateEmailData {...emailData} />}
      <MessageWithMentions
        {...{ message, mentions, searchText, size: '12px', LinkToParentClone }}
      />
      {R.complement(R.isEmpty)(images) && (
        <UpdateImagesSection isEmailAttachment={!!emailData} images={images} />
      )}
      {R.complement(R.isEmpty)(restAttachments) &&
        restAttachments.map((file) => (
          <UpdatesFileLink key={file.url} {...file} />
        ))}
      <UpdateSentEmailsPreview emailFiles={emailFiles} />
    </div>
  );
}

const emailAddressesPropTypes = arrayOf(
  shape({
    name: string,
    address: string,
  }),
);

UpdateDetails.propTypes = {
  title: node,
  message: string,
  mentions: mentionsShape,
  attachments: arrayOf(
    shape({
      _id: string.isRequired,
      fileType: string.isRequired,
      fileName: string.isRequired,
      url: string.isRequired,
    }),
  ),
  searchText: string,
  onImageClick: func,
  emailData: shape({
    from: emailAddressesPropTypes,
    to: emailAddressesPropTypes,
    cc: emailAddressesPropTypes,
    subject: string,
    receivedDate: string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  LinkToParentClone: object,
};
