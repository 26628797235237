import { Select } from 'poly-book-admin';
import React from 'react';

const printingStatusOptions = [
  { value: true, label: 'Is Printed - Yes' },
  { value: false, label: 'Is Printed - No' },
];

export function PrintingStatusSelector(props) {
  const selectProps = {
    options: printingStatusOptions,
    isClearable: true,
    ...props,
  };

  return <Select {...selectProps} />;
}
