import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { centsToDollarsWithFormat, insertParamsIntoURL } from 'poly-utils';
import { Link } from 'poly-client-routing';
import {
  useMapConfigToTablePropsWithSorting,
  TableSearchInput,
  useModalContext,
  useSelectableTableRowsProps,
} from 'poly-admin-ui';
import {
  WindowedTable,
  getThemeColor,
  IconButton,
  Loader,
} from 'poly-book-admin';

import { AssetManufacturersForm } from './AssetManufacturersForm.js';
import { editAssetManufacturerFormId } from './constants.js';
import { routesNames } from '../../routes/constants.js';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

const WindowedTableS = styled(WindowedTable)`
  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(4),
  td:nth-child(4) {
    width: 40px;
  }
  thead {
    background-color: ${getThemeColor(['lightest'])};
  }
`;

function AssetManufacturerModelsLink({ _id, name }) {
  const assetModelsHref = insertParamsIntoURL({ manufacturerId: _id })(
    routesNames.ASSET_MANUFACTURER_MODELS,
  );
  return <Link href={assetModelsHref}>{name}</Link>;
}

AssetManufacturerModelsLink.propTypes = {
  _id: string.isRequired,
  name: string.isRequired,
};

function EditButton({ refetch, ...props }) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: editAssetManufacturerFormId,
      title: 'Edit Manufacturer',
      formId: editAssetManufacturerFormId,
      btnCaption: 'Save',
      content: (
        <AssetManufacturersForm
          isNewManufacturer={false}
          document={props}
          refetch={refetch}
        />
      ),
    });

  return <IconButton size={15} name="edit" onClick={onClick} />;
}

EditButton.propTypes = {
  refetch: func.isRequired,
};

const assetManufacturersTableConfig = [
  ['Name', AssetManufacturerModelsLink, R.prop('name')],
  [
    'Asset Replacement Cost',
    R.compose(centsToDollarsWithFormat, R.prop('replacementCost')),
  ],
  ['', EditButton],
];

export function AssetManufacturersPageTable({
  loading,
  selectedRows,
  setSelectedRows,
  assetManufacturers,
}) {
  const tableProps = useMapConfigToTablePropsWithSorting({
    items: assetManufacturers,
    tableConfig: assetManufacturersTableConfig,
    initialSorting: {
      columnKey: 0,
      dir: 1,
    },
  });

  const { toggleRow } = useSelectableTableRowsProps(
    selectedRows,
    setSelectedRows,
    assetManufacturers,
  );

  return (
    <Container>
      <TableSearchInput />
      {loading ? (
        <Loader />
      ) : (
        <WindowedTableS
          {...tableProps}
          isLoading={loading}
          showScrollBar
          isPartiallySelectable
          selectedRows={selectedRows}
          isRowSelectable={R.T}
          toggleRow={toggleRow}
        />
      )}
    </Container>
  );
}

AssetManufacturersPageTable.propTypes = {
  loading: bool,
  selectedRows: arrayOf(string),
  setSelectedRows: func,
  assetManufacturers: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ),
};
