import styled from 'styled-components';
import React, { useState, useMemo } from 'react';
import { AccountingStatus, WorkOrderStatus } from 'poly-constants';
import { useRedirectIfHasNoPermissions } from 'poly-client-utils';
import { CREATE_CLIENT_BATCH_PERMISSION } from 'poly-security';
import { ClientSelect } from 'poly-admin-ui';
import { MainHeader } from 'poly-book-admin';

import { BatchInvoicingTMPage } from './BatchInvoicingTMPage.js';
import { batchInvoiceFiltersToSearchQuery } from './filters-to-query.js';
import { SearchHeaderColumn } from '../../components/SearchHeaderColumn.js';

const UmcBatchInvoicingHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export function UmcBatchInvoicingPage() {
  const [clientId, setClientId] = useState(null);

  const query = useMemo(
    () =>
      // Note: when search input is empty
      // ClientSelect produces '' on backspace click
      // which breaks BatchInvoicingTMPage
      clientId
        ? batchInvoiceFiltersToSearchQuery({
            clientId,
            [WorkOrderStatus.COMPLETED]: true,
            [AccountingStatus.READY_TO_INVOICE]: true,
          })
        : null,
    [clientId],
  );

  useRedirectIfHasNoPermissions([CREATE_CLIENT_BATCH_PERMISSION]);

  return (
    <BatchInvoicingTMPage query={query}>
      <UmcBatchInvoicingHeaderWrapper>
        <MainHeader>UMC Batch Invoice</MainHeader>
        <SearchHeaderColumn
          margin="0"
          title="UMC Client"
          titleWidth="120px"
          filterWidth="260px"
        >
          <ClientSelect
            required
            withoutSkip
            width="100%"
            changeOnBlur
            value={clientId}
            onChange={setClientId}
            includeAllOption={false}
            query={{ term: { isUMCAccountFormat: true } }}
          />
        </SearchHeaderColumn>
      </UmcBatchInvoicingHeaderWrapper>
    </BatchInvoicingTMPage>
  );
}
