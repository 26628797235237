import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Link } from 'poly-client-routing';
import {
  getThemeColor,
  getThemeProp,
  moneyColumnStyles,
} from 'poly-book-admin';
import {
  calculateTotal,
  formatTotal,
  insertQueryParamsIntoURL,
} from 'poly-utils';
import { bool, string } from 'prop-types';

import { FlexColumn, FlexContainer } from '../../components/FlexContainer.js';
import { routesNames } from '../../routes/index.js';
import {
  profitLossFiltersPropTypes,
  profitLossPropTypes,
} from './propTypes.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';

const AccountReportLinkWithTextContainerS = styled.td`
  ${({ isParent }) => !isParent && 'padding-left: 15px'};
  a {
    ${({ isParent }) => isParent && 'font-weight: bold'};
  }
`;

const AccountReportRowS = styled.tr`
  display: table;
  table-layout: fixed;
  font-size: 14px;
  height: 35px;
  border: 1px solid rgba(197, 198, 201, 0.35);
  border-top: none;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    box-shadow: ${getThemeProp(['defaultBoxShadow'])};
  }
`;

function AccountReportLinkWithText({ _id, name, code, filters, isParent }) {
  const queryParams = {
    accountId: _id,
    startDate: filters.startDate,
    endDate: filters.endDate,
    accountingMethod: filters.accountingMethod,
  };
  const url = insertQueryParamsIntoURL(
    queryParams,
    routesNames.ACCOUNT_TRANSACTIONS,
  );
  return (
    <AccountReportLinkWithTextContainerS isParent={isParent}>
      <Link target="_blank" href={url}>
        {`${name} - ${code}`}
      </Link>
    </AccountReportLinkWithTextContainerS>
  );
}

AccountReportLinkWithText.propTypes = {
  name: string.isRequired,
  code: string.isRequired,
  _id: string.isRequired,
  filters: profitLossFiltersPropTypes,
  isParent: bool,
};

function AccountReportLink({ title, _id, filters }) {
  const queryParams = {
    accountId: _id,
    startDate: filters.startDate,
    endDate: filters.endDate,
    accountingMethod: filters.accountingMethod,
  };
  const url = insertQueryParamsIntoURL(
    queryParams,
    routesNames.ACCOUNT_TRANSACTIONS,
  );
  return (
    <Link target="_blank" href={url}>
      {title}
    </Link>
  );
}

AccountReportLink.propTypes = {
  title: string.isRequired,
  _id: string.isRequired,
  filters: profitLossFiltersPropTypes,
};

const ProfitLossReportTableS = styled.table`
  ${moneyColumnStyles(2, 500)}
  border-spacing: 0;
  th:nth-child(2),
  td:nth-child(2) {
    width: 500px;
    padding-right: ${({ isCreditNormal }) =>
      isCreditNormal ? '20px' : '250px'};
  }
`;

export const TotalContainer = styled(FlexContainer)`
  width: 500px;
  justify-content: flex-end;
  padding-right: ${({ isCreditNormal }) => (isCreditNormal ? '20px' : '250px')};
`;

export const SectionTitleContainer = styled(FlexSpaceBetween)`
  width: 100%;
  height: 35px;
  border: 1px solid rgba(197, 198, 201, 0.35);
  align-items: center;
  padding: 5px 0 5px 10px;
  background-color: ${getThemeColor(['lightest'])};
`;

const CateGoryReportSectionContainerS = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 5px;
`;

const BoldColumnLinkS = styled.td`
  a {
    font-weight: bold;
  }
`;

export function CategoryReportSection({
  title,
  profitLossReports,
  totalTitle,
  isCreditNormal,
  filters,
}) {
  const total = calculateTotal(R.prop('balance'), profitLossReports);
  return (
    <CateGoryReportSectionContainerS>
      <SectionTitleContainer>{title}</SectionTitleContainer>
      <ProfitLossReportTableS isCreditNormal={isCreditNormal}>
        {profitLossReports.map(({ account, subAccounts, balance }) => (
          <tbody key={account._id}>
            <AccountReportRowS>
              <AccountReportLinkWithText
                isParent
                {...account}
                filters={filters}
              />
              <BoldColumnLinkS>
                <AccountReportLink
                  {...account}
                  filters={filters}
                  title={formatTotal(balance)}
                />
              </BoldColumnLinkS>
            </AccountReportRowS>
            {subAccounts.map((subAccountReport) => (
              <AccountReportRowS key={subAccountReport.account._id}>
                <AccountReportLinkWithText
                  {...subAccountReport.account}
                  filters={filters}
                />
                <td>
                  <AccountReportLink
                    {...subAccountReport.account}
                    filters={filters}
                    title={formatTotal(subAccountReport.balance)}
                  />
                </td>
              </AccountReportRowS>
            ))}
          </tbody>
        ))}
      </ProfitLossReportTableS>
      <SectionTitleContainer>
        {totalTitle}
        <TotalContainer isCreditNormal={isCreditNormal}>
          {formatTotal(total)}
        </TotalContainer>
      </SectionTitleContainer>
    </CateGoryReportSectionContainerS>
  );
}

CategoryReportSection.propTypes = {
  title: string.isRequired,
  profitLossReports: profitLossPropTypes,
  filters: profitLossFiltersPropTypes,
  totalTitle: string.isRequired,
  isCreditNormal: bool,
};
