import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { string, object, bool } from 'prop-types';
import { Text, Icon } from 'poly-book-admin';

import { SupplierInvoiceFormActions } from '../constants.js';
import { isProjectWithClientInvoice } from './common.js';

const SubTitle = styled(Text)`
  margin: 5px 0;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;

  > :last-child {
    margin-left: 5px;
  }
`;

const TitleExt = styled(Text)`
  margin-left: 5px;
`;

const YellowWarning = styled(Icon)`
  margin-left: 5px;
`;

// getFormTitleByFormData :: FormData -> String
const getFormTitleByFormData = R.cond([
  [
    R.propEq('formAction', SupplierInvoiceFormActions.approve),
    R.always('Approve Invoice'),
  ],
  [
    R.propEq('formAction', SupplierInvoiceFormActions.edit),
    R.always('Edit Invoice'),
  ],
  [
    R.propEq('formAction', SupplierInvoiceFormActions.reject),
    R.always('Reject Invoice'),
  ],
  [R.T, R.always('Add Invoice')],
]);

// getTitleExtensionByFormData :: FormData -> String
const getTitleExtensionByFormData = (formData) =>
  formData.taskSupplier ? `(${formData.taskSupplier.company.name})` : null;

export function NonBillableProjectInvoiceWarning() {
  return (
    <TitleRow>
      <YellowWarning size={15} name="yellowWarning" />
      <Text size="12px" lineHeight="26px" color={['secondaryMid']}>
        This is non-billable project, are you sure you want to proceed
      </Text>
    </TitleRow>
  );
}

function ApproveInvoiceFormTitle({
  title,
  titleExt,
  clientInvoiceExists,
  subTitle,
  isNonBillableProject,
}) {
  return (
    <>
      <TitleRow>
        <Text size="18px" lineHeight="24px">
          {title}
        </Text>
        {!!titleExt && (
          <TitleExt size="12px" lineHeight="26px">
            {titleExt}
          </TitleExt>
        )}
        {clientInvoiceExists && (
          <>
            <YellowWarning size={15} name="yellowWarning" />
            <Text size="12px" lineHeight="26px" color={['secondaryMid']}>
              Client Invoice Exists
            </Text>
          </>
        )}
      </TitleRow>
      <SubTitle size="12px" lineHeight="18px">
        {subTitle}
      </SubTitle>
      {isNonBillableProject && <NonBillableProjectInvoiceWarning />}
    </>
  );
}

ApproveInvoiceFormTitle.propTypes = {
  title: string.isRequired,
  titleExt: string,
  clientInvoiceExists: bool,
  subTitle: string.isRequired,
  isNonBillableProject: bool,
};

// checkIsProjectNonBillable :: { project: Project } -> Boolean
const checkIsProjectNonBillable = R.pathOr(false, ['project', 'nonBillable']);

export function InvoiceFormTitle({ formData, value }) {
  const title = useMemo(() => getFormTitleByFormData(formData), [formData]);
  const titleExt = useMemo(
    () => getTitleExtensionByFormData(formData),
    [formData],
  );
  const clientInvoicesExists = isProjectWithClientInvoice(formData);
  const isNonBillableProject = checkIsProjectNonBillable(formData);

  return (
    <ApproveInvoiceFormTitle
      title={title}
      titleExt={titleExt}
      clientInvoiceExists={clientInvoicesExists}
      subTitle={value}
      isNonBillableProject={isNonBillableProject}
    />
  );
}

InvoiceFormTitle.propTypes = {
  value: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formData: object.isRequired,
};
