import * as R from 'ramda';
import React, { useMemo, useRef } from 'react';
import { bool, func, string } from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { usePristineSubscribe } from 'poly-client-routing';
import { FormCreator, Loader } from 'poly-book-admin';

import { useOnSubmitSetStopSubmitting } from '../../../../hooks/useOnSubmitSetStopSubmitting.js';
import { UPDATE_CONTACT_USER_MUTATION } from '../../../../hocs/users/mutations.js';
import { useNotificationState } from '../../../../hooks/useNotificationState.js';
import { prepareContactDataToForm } from './editPeopleUtils.js';
import {
  peopleEditFormToInput,
  validateFormData,
} from '../form/peopleFormUtils.js';

import {
  peopleFormSections,
  internationalPeopleFormSections,
} from '../form/sections.js';
import { commonModalLayout } from '../../common.js';
import { preparedInternationalPhones } from '../add/addPeopleUtils.js';
import {
  commonSidebarFormSectionLayout,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
} from '../../../../sidebar/commonSidebarFormConstants.js';
import { CONTACT_DETAILS } from '../../../../hocs/users/queries.js';

// isClientContact :: User -> Boolean
export const isClientContact = R.compose(
  R.complement(R.isEmpty),
  R.reject(R.isNil),
  R.pluck('clientId'),
  R.propOr([], 'links'),
);

const useOnSubmitContactUpdate = (user, onSuccess, setFinished) => {
  const { showSuccessNotification } = useNotificationState();

  const [updateContactMutation] = useMutation(UPDATE_CONTACT_USER_MUTATION);

  return async (input) => {
    const preparedInput = peopleEditFormToInput({
      ...preparedInternationalPhones(input),
      ...R.pick(['links'], user),
      userId: R.prop('_id', user),
    });

    await updateContactMutation({
      variables: preparedInput,
    });

    setFinished();
    showSuccessNotification('Contact was successfully edited');
    return onSuccess();
  };
};

export function EditPeopleForm({
  formId,
  contactId,
  onSuccess,
  isInternational = false,
  isCardLayout = true,
}) {
  const isFinished = useRef(false);

  const pristineSubscribeProps = usePristineSubscribe({
    id: formId,
    contactId,
  });

  const { data, loading } = useQuery(CONTACT_DETAILS, {
    variables: { id: contactId },
    skip: !!isFinished.current,
  });

  const setFinished = () => {
    isFinished.current = true;
  };

  const user = useMemo(() => R.prop('contactUser', data), [data]);

  const onSubmitHandler = useOnSubmitContactUpdate(
    user,
    onSuccess,
    setFinished,
  );
  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const initialValues = useMemo(() => prepareContactDataToForm(user), [user]);

  const showClientManagerCheckbox = useMemo(
    () => isClientContact(user),
    [user],
  );

  const sections = useMemo(
    () =>
      isInternational
        ? internationalPeopleFormSections
        : peopleFormSections(showClientManagerCheckbox),
    [showClientManagerCheckbox, isInternational],
  );

  const layouts = isCardLayout
    ? { layout: commonModalLayout }
    : {
        layout: commonSidebarFormLayout,
        fieldLayout: commonSidebarFormFieldLayout,
        sectionLayout: commonSidebarFormSectionLayout,
      };

  if (loading || !data) {
    return <Loader />;
  }

  return (
    <FormCreator
      id={formId}
      validate={validateFormData}
      initialValues={initialValues}
      sections={sections}
      onSubmit={onSubmit}
      {...layouts}
      {...pristineSubscribeProps}
    />
  );
}

EditPeopleForm.displayName = 'EditPeopleForm';

EditPeopleForm.propTypes = {
  contactId: string.isRequired,
  formId: string.isRequired,
  onSuccess: func.isRequired,
  isInternational: bool,
  isCardLayout: bool,
};
