import * as R from 'ramda';
import { useState } from 'react';
import { isNilOrEmpty } from 'poly-utils';

import { HistoryStorageName } from './constants.js';

// prepareRecordsToPush :: HistoryRecord -> [HistoryRecord] -> [HistoryRecord]
// HistoryRecord = {
//    id: ID!,
//    entity: String!,
//    linkProps: Object,
//    description: String,
// }
const prepareRecordsToPush = (newRecord) =>
  R.compose(
    // we save only the last 50 history records due to requirements
    R.when(R.propSatisfies(R.lte(50), 'length'), R.dropLast(1)),
    R.prepend(newRecord),
    R.reject(R.propEq('id', newRecord.id)),
  );

export const useHistoryLocalStorageLogic = () => {
  const getHistoryRecords = () => {
    try {
      const historyJSON = localStorage.getItem(HistoryStorageName);

      return isNilOrEmpty(historyJSON) ? [] : JSON.parse(historyJSON);
    } catch (e) {
      localStorage.clear();
      return [];
    }
  };

  const [historyRecords, setHistoryRecords] = useState(getHistoryRecords());

  const updateHistoryRecords = () => {
    const newRecords = getHistoryRecords();
    setHistoryRecords(newRecords);
    return newRecords;
  };

  const setHistoryRecord = (newRecord) => {
    const preparedRecords = prepareRecordsToPush(newRecord)(
      getHistoryRecords(),
    );

    setHistoryRecords(preparedRecords);
    localStorage.setItem(HistoryStorageName, JSON.stringify(preparedRecords));
  };

  const removeHistoryRecord = (id) => {
    const updatedRecords = R.reject(R.propEq('id', id))(historyRecords);

    setHistoryRecords(updatedRecords);
    localStorage.setItem(HistoryStorageName, JSON.stringify(updatedRecords));
  };

  const clearHistoryRecords = () => {
    setHistoryRecords([]);
    localStorage.removeItem(HistoryStorageName);
  };

  return {
    historyRecords,
    setHistoryRecord,
    removeHistoryRecord,
    clearHistoryRecords,
    updateHistoryRecords,
  };
};
