import styled from 'styled-components';
import {
  Table,
  getThemeColor,
  moneyColumnStyles,
  Button,
} from 'poly-book-admin';

import { FlexColumn, JustifyFlexEnd } from '../../components/FlexContainer.js';

export const HeaderToolbarRow = styled(JustifyFlexEnd)`
  align-items: center;
  padding-right: 15px;
`;

export const FiltersContainerS = styled(FlexColumn)`
  width: 100%;
`;

export const SubTitleS = styled.p`
  font-size: 16px;
`;

export const SupplierNameS = styled.p`
  font-size: 16px;
  text-align: left;
  margin: 0 0 -20px 10px;
`;

export const SearchHeaderButton = styled(Button)`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  padding: 5px 10px;
  line-height: 12px;
  min-width: 90px;
`;

export const SupplierPaymentHistoryTableS = styled(Table)`
  tr {
    margin: 0 30px;
    width: calc(100% - 60px) !important;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 100px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 100px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 150px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 150px;
  }
  th:nth-child(6),
  td:nth-child(6) {
    width: 150px;
  }
  ${moneyColumnStyles(7)};
  ${moneyColumnStyles(8)};
  @media print {
    tbody > tr {
      border: none;
      border-bottom: 1px solid ${getThemeColor(['secondary'])};
    }
  }
`;

export const SupplierPaymentHistoryFooterContainer = styled.tr`
  padding: 20px 0;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  td:nth-child(1) {
    padding-left: 10px;
    width: 600px;
  }
  ${moneyColumnStyles(2)};
  ${moneyColumnStyles(3)};
  td:nth-child(2),
  td:nth-child(3) {
    padding-right: 20px;
  }
`;
