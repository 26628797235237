import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import React, { useCallback } from 'react';
import { formatDateOrNothingUI, pathOrNothingUI } from 'poly-client-utils';
import {
  allowedCancelInvoiceStatuses,
  InvoicesStatuses,
  NOTHING_UI_STRING,
} from 'poly-constants';
import { applySpecWithFields, isAllowedToDeleteInvoice } from 'poly-utils';
import {
  SidebarWrapperForTableForm,
  useMapConfigToTableProps,
  CommonSidebarFormWrapper,
  CommonSidebarTabTable,
  SidebarFormWrapper,
  useSearchTabLogic,
  SidebarTabWrapper,
  SidebarTabToolbar,
  SidebarTabHeader,
  ThreeDotsPopover,
  SearchInputComp,
  useSidebarTableFormContext,
  FormPortalAnchor,
  MultiSupplierInvoiceNote,
} from 'poly-admin-ui';
import {
  getThemeColor,
  PopoverLink,
  LinkButton,
  Loader,
} from 'poly-book-admin';

import {
  invoicePdfColumn,
  invoiceAmountColumn,
  invoiceStatusColumn,
} from '../../../modules/tables/columns/invoices.js';
import { SectionLabel } from '../../components/commonSidebarComponents.js';
import { SupplierLinkComp } from '../../../modules/tables/columns/suppliers.js';
import { EditProjectInvoiceForm } from '../forms/EditProjectInvoiceForm.js';
import { AddSupplierInvoiceForm } from '../forms/AddSupplierInvoiceForm.js';
import { ApproveSupplierInvoiceForm } from '../forms/ApproveSupplierInvoiceForm.js';
import { useVoidInvoiceMutation } from './useVoidInvoiceMutation.js';
import { useCancelInvoiceRequest } from './useCancelInvoiceRequest.js';
import { useSearchProjectInvoices } from './useSearchProjectInvoices.js';
import { useDeleteInvoiceMutation } from './useDeleteInvoiceMutation.js';
import { isNotCreditCardOrBankExpenseInvType } from '../../../pages/SearchSupplierInvoices/VoidSupplierInvoiceButton.js';

const InvoiceSupplierWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    font-size: 10px;
    line-height: 12px;
    color: ${getThemeColor(['midDark'])};
  }
`;

export const InvoicesTable = styled(CommonSidebarTabTable)`
  min-height: unset;
  height: auto;
  tbody {
    height: auto;
    min-height: 220px;
    overflow: unset;
  }

  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    width: 85px;
  }

  th:last-child,
  td:last-child {
    width: 20px;

    > svg {
      right: 0;
    }
  }
`;

function InvoiceSupplier({ supplier, invoiceDate, multiInvoiceNote }) {
  if (!supplier) {
    return NOTHING_UI_STRING;
  }
  return (
    <InvoiceSupplierWrapper>
      <MultiSupplierInvoiceNote
        description={
          <>
            <SupplierLinkComp {...supplier} />
            <div>{formatDateOrNothingUI(invoiceDate)}</div>
          </>
        }
        complete={false}
        supplier={supplier}
        multiInvoiceNote={multiInvoiceNote}
      />
    </InvoiceSupplierWrapper>
  );
}

InvoiceSupplier.propTypes = {
  invoiceDate: string,
  supplier: shape({ _id: string.isRequired }),
  multiInvoiceNote: string.isRequired,
};

// checkIsApproveInvoiceAvailable :: Invoice -> Boolean
const checkIsApproveInvoiceAvailable = R.both(
  R.propEq('status', InvoicesStatuses.RECEIVED),
  R.complement(R.prop('isOwnSupplierInvoice')),
);

function InvoiceMoreBtn(props) {
  const { status, _id, invoiceId, type } = props;

  const { formSetter } = useSidebarTableFormContext();
  const voidInvoice = useVoidInvoiceMutation(props);
  const cancelInvoice = useCancelInvoiceRequest(props);
  const deleteInvoice = useDeleteInvoiceMutation(props);

  const isApproveInvoiceAvailable = checkIsApproveInvoiceAvailable(props);
  const allowedToDeleteInvoice = isAllowedToDeleteInvoice(props);

  const onCancel = () => formSetter(null);

  if (status === InvoicesStatuses.VOIDED) return null;

  const commonFormProps = { invoiceId, onCancel };

  const onEdit = () =>
    formSetter({
      elementId: _id,
      Content: (
        <CommonSidebarFormWrapper isTableRow onCancel={onCancel}>
          <EditProjectInvoiceForm {...commonFormProps} />
        </CommonSidebarFormWrapper>
      ),
    });

  const onApprove = () =>
    formSetter({
      elementId: _id,
      Content: (
        <CommonSidebarFormWrapper isTableRow onCancel={onCancel}>
          <ApproveSupplierInvoiceForm {...commonFormProps} />
        </CommonSidebarFormWrapper>
      ),
    });
  return (
    <ThreeDotsPopover
      content={
        <>
          <PopoverLink onClick={onEdit}>Edit Invoice</PopoverLink>
          {allowedToDeleteInvoice && (
            <PopoverLink onClick={deleteInvoice}>Delete Invoice</PopoverLink>
          )}
          {isNotCreditCardOrBankExpenseInvType(type) && (
            <PopoverLink onClick={voidInvoice}>Void Invoice</PopoverLink>
          )}
          {isApproveInvoiceAvailable && (
            <PopoverLink onClick={onApprove}>Approve Invoice</PopoverLink>
          )}
          {R.includes(status, allowedCancelInvoiceStatuses) && (
            <PopoverLink onClick={cancelInvoice}>
              Cancel Invoice Request
            </PopoverLink>
          )}
        </>
      }
    />
  );
}

InvoiceMoreBtn.propTypes = {
  type: string,
  _id: string.isRequired,
  status: string.isRequired,
  invoiceId: string.isRequired,
};

export const commonInvoicesTableConfig = [
  ['Supplier', InvoiceSupplier],
  invoicePdfColumn,
  invoiceAmountColumn,
  invoiceStatusColumn,
];

const invoicesTableConfig = [
  ...commonInvoicesTableConfig,
  ['', InvoiceMoreBtn],
];

function AddInvoiceButton({ _id }) {
  const { formSetter } = useSidebarTableFormContext();

  const onFormCancel = useCallback(() => {
    formSetter(null);
  }, []);

  const onAddInvoice = () =>
    formSetter({
      id: _id,
      Content: (
        <SidebarFormWrapper skipMargin onCancel={onFormCancel}>
          <AddSupplierInvoiceForm onCancel={onFormCancel} entity={{ _id }} />
        </SidebarFormWrapper>
      ),
    });

  return <LinkButton onClick={onAddInvoice}>Add Invoice</LinkButton>;
}

AddInvoiceButton.propTypes = { _id: string.isRequired };

// prepareInvoicesData :: [Invoice] -> [Invoice]
export const prepareInvoicesData = R.map(
  applySpecWithFields({
    multiInvoiceNote: pathOrNothingUI([
      'supplierInvoiceTask',
      'multiInvoiceNote',
    ]),
    invoiceId: R.prop('_id'),
    _id: R.compose(R.concat('invoice_form_row_'), R.prop('_id')),
  }),
);

function ProjectSidebarInvoicesTabContent({ _id }) {
  const { searchTerm, ...inputProps } = useSearchTabLogic('invoices');

  const { data, loading } = useSearchProjectInvoices(_id, searchTerm);
  const tableProps = useMapConfigToTableProps(
    prepareInvoicesData,
    invoicesTableConfig,
    R.pathOr([], ['searchInvoices', 'hits'], data),
  );

  return (
    <SidebarTabWrapper>
      <SidebarTabHeader skipMargin>
        <SectionLabel>Project Invoices</SectionLabel>
        <AddInvoiceButton _id={_id} />
      </SidebarTabHeader>
      <SidebarTabToolbar>
        <SearchInputComp {...inputProps} />
      </SidebarTabToolbar>
      <FormPortalAnchor id={_id} />
      {loading ? <Loader /> : <InvoicesTable {...tableProps} />}
    </SidebarTabWrapper>
  );
}

ProjectSidebarInvoicesTabContent.propTypes = { _id: string.isRequired };

export function ProjectSidebarInvoicesTab(props) {
  return (
    <SidebarWrapperForTableForm>
      <ProjectSidebarInvoicesTabContent {...props} />
    </SidebarWrapperForTableForm>
  );
}
