import styled from 'styled-components';
import { Checkbox, getThemeFont, getThemeColor } from 'poly-book-admin';

import { FlexContainer } from '../../../../components/FlexContainer.js';

export const CheckboxS = styled(Checkbox)`
  width: 170px;
`;

CheckboxS.displayName = 'StyledCheckbox';

export const ErrorMessage = styled(FlexContainer)`
  align-items: center;
  font-weight: ${getThemeFont(['medium'])};
  color: ${getThemeColor(['secondaryMid'])};
`;
