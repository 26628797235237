import { Select } from 'poly-book-admin';
import React from 'react';
import { ClientInvoiceStatuses } from 'poly-constants';
import { ALL } from '../../modules/core/constants/general.js';

const clientInvoiceStatusOptions = [
  { value: ClientInvoiceStatuses.PENDING, label: 'Pending' },
  { value: ClientInvoiceStatuses.PAID, label: 'Paid' },
  { value: ClientInvoiceStatuses.PARTIALLY_PAID, label: 'Partially Paid' },
  { value: ALL, label: 'All' },
];

export function InvoiceStatusSelector(props) {
  const selectProps = {
    options: clientInvoiceStatusOptions,
    ...props,
  };

  return <Select {...selectProps} />;
}
