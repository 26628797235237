import React from 'react';
import { shape } from 'prop-types';
import { SidebarWidth } from 'poly-admin-ui/src/constants/general.js';

import { SidebarIDs } from '../../constants.js';
import { SidebarTabs } from '../../components/SidebarTabs.js';
import { PurchaseOrderSidebarUpdatesTab } from './PurchaseOrderSidebarUpdatesTab.js';
import { PurchaseOrderSidebarFilesTab } from './PurchaseOrderSidebarFilesTab.js';
import { PurchaseOrderSidebarContactsTab } from './PurchaseOrderSidebarContactsTab.js';
import { PurchaseOrderSidebarTasksTab } from './PurchaseOrderSidebarTasksTab.js';
import {
  purchaseOrderTabsSidebarId,
  purchaseOrderTabsSidebarWidth,
} from '../constants.js';
import { PurchaseOrderSidebarMastersTab } from './PurchaseOrderSidebarMastersTab.js';
import { PurchaseOrderSidebarProjectsTab } from './PurchaseOrderSidebarProjectsTab.js';
import { purchaseOrderSidebarTabs } from '../../../routes/constants.js';

const doubleWidth = SidebarWidth + purchaseOrderTabsSidebarWidth;

export function PurchaseOrderSidebarTabs({ purchaseOrder }) {
  const tabTitlePrefix = 'PO';

  return (
    <SidebarTabs
      id={purchaseOrderTabsSidebarId}
      params={[SidebarIDs.purchaseOrder]}
      defaultValue={purchaseOrderSidebarTabs.purchaseOrderUpdates}
      tabs={[
        [
          'Projects',
          purchaseOrderSidebarTabs.purchaseProjects,
          <PurchaseOrderSidebarProjectsTab {...purchaseOrder} />,
        ],
        [
          'Masters',
          purchaseOrderSidebarTabs.purchaseMastersProjects,
          <PurchaseOrderSidebarMastersTab
            {...purchaseOrder}
            doubleTabWidth={doubleWidth}
            tabTitlePrefix={tabTitlePrefix}
          />,
        ],
        [
          'Tasks',
          purchaseOrderSidebarTabs.purchaseOrderTasks,
          <PurchaseOrderSidebarTasksTab
            {...purchaseOrder}
            tabTitlePrefix={tabTitlePrefix}
          />,
        ],
        [
          'Updates',
          purchaseOrderSidebarTabs.purchaseOrderUpdates,
          <PurchaseOrderSidebarUpdatesTab
            {...purchaseOrder}
            tabTitlePrefix={tabTitlePrefix}
          />,
        ],
        [
          'Contacts',
          purchaseOrderSidebarTabs.purchaseOrderContacts,
          <PurchaseOrderSidebarContactsTab
            {...purchaseOrder}
            doubleTabWidth={doubleWidth}
            tabTitlePrefix={tabTitlePrefix}
          />,
        ],
        [
          'Files',
          purchaseOrderSidebarTabs.purchaseOrderFiles,
          <PurchaseOrderSidebarFilesTab
            {...purchaseOrder}
            tabTitlePrefix={tabTitlePrefix}
          />,
        ],
      ]}
    />
  );
}

PurchaseOrderSidebarTabs.propTypes = {
  purchaseOrder: shape({}),
};
