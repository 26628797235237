import * as R from 'ramda';
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { FormCreator } from 'poly-book-admin';
import { useModalContext, useNotificationState } from 'poly-admin-ui';
import { bool, shape, string } from 'prop-types';

import { ccUsersFormCreatorSections } from './CreditCardUsersFormSections.js';
import { addCCUserFormId, editCCUserFormId } from './constants.js';

const createSuccessMessage = 'Credit Card User was created successfully';
const editSuccessMessage = 'Credit Card User was edited successfully';

const CREATE_CREDIT_CARD_USER_MUTATION = gql`
  mutation CREATE_CREDIT_CARD_USER_MUTATION(
    $input: CreateCreditCardUserInput!
  ) {
    createCreditCardUser(input: $input) {
      _id
    }
  }
`;

const UPDATE_CREDIT_CARD_USER_MUTATION = gql`
  mutation UPDATE_CREDIT_CARD_USER_MUTATION(
    $id: ID!
    $input: UpdateCreditCardUserInput!
  ) {
    updateCreditCardUser(id: $id, input: $input) {
      _id
    }
  }
`;

// getMutationVariables :: (ID, Input) -> CreateCreditCardUserInput
// Input = {
//    status: String
//    cardNumber: String
//    userId: ID
//    accountId: String
// }
const getMutationVariables = (ccUserId, input) =>
  R.compose(
    R.objOf('variables'),
    R.assoc('id', ccUserId),
    R.objOf('input'),
  )(input);

// getInitialValues :: Document -> FormValues
// Document = {
//    _id: String,
//    status: String,
//    cardNumber: String,
//    ccUserName: String,
//    ccName: String,
//    isCCUser: Boolean,
//    userId: String,
//    accountId: String,
// }
// FormValues = {
//    status: String
//    cardNumber: String
//    userId: ID
//    accountId: ID
// }
const getInitialValues = R.applySpec({
  status: R.propOr('', 'status'),
  cardNumber: R.propOr('', 'cardNumber'),
  userId: R.propOr('', 'userId'),
  accountId: R.propOr('', 'accountId'),
});

export function CreditCardUsersForm({ isNewCCUser, document, ...props }) {
  const ccUserId = R.propOr(null, '_id', document);
  const formId = isNewCCUser ? addCCUserFormId : editCCUserFormId;
  const successMessage = isNewCCUser
    ? createSuccessMessage
    : editSuccessMessage;
  const submitMutation = isNewCCUser
    ? CREATE_CREDIT_CARD_USER_MUTATION
    : UPDATE_CREDIT_CARD_USER_MUTATION;

  const { showSuccessNotification } = useNotificationState();

  const { closeModal } = useModalContext();
  const [ccUserMutation] = useMutation(submitMutation);

  const onSubmit = (input) =>
    ccUserMutation(getMutationVariables(ccUserId, input)).then(() => {
      showSuccessNotification(successMessage);
      closeModal(formId);
    });

  return (
    <FormCreator
      {...props}
      id={formId}
      formId={formId}
      sections={ccUsersFormCreatorSections}
      initialValues={getInitialValues(document)}
      onSubmit={onSubmit}
      onCancel={closeModal}
      successMessage={successMessage}
    />
  );
}

CreditCardUsersForm.propTypes = {
  isNewCCUser: bool,
  document: shape({
    _id: string,
    status: string,
    cardNumber: string,
    ccUserName: string,
    ccName: string,
    isCCUser: bool,
    userId: string,
    accountCode: string,
  }),
};
