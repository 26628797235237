import * as R from 'ramda';
import { removePropDeep, assocBy, overPropUnlessNil } from 'poly-utils';
import { ClientBranding, ClientSpendReportTypes } from 'poly-constants';

import { transformInvoiceFileToPicker } from '../../../../modules/core/utils/invoices.js';
import { prepareAddressObjectToForm } from '../../../utils/address.js';

// requestAppConfigsToEditForm :: RequestAppConfigs -> RequestAppConfigsInput
const requestAppConfigsToEditForm = R.compose(
  R.dissoc('projectServiceType'),
  R.over(
    R.lensProp('branding'),
    R.when(R.isNil, R.always(ClientBranding.POLY)),
  ),
  assocBy('projectServiceTypeId', R.path(['projectServiceType', '_id'])),
);

// clientPortalAppConfigsToEditForm :: ClientPortalAppConfigs -> ClientPortalAppConfigs
const clientPortalAppConfigsToEditForm = R.compose(
  R.over(R.lensProp('allowProjectCreation'), R.when(R.isNil, R.T)),
  assocBy('projectServiceTypeId', R.path(['projectServiceType', '_id'])),
);

// clientInvoicingConfigsToEditForm :: String -> LogoConfigs -> LogoConfigsInput
// LogoConfigs = {
//  [String]: {
//    fileName: String,
//    url: String,
//  }
// }
// LogoConfigsInput = {
//  [String]: [{
//    fileName: String,
//    url: String,
//    _id: String
//  }]
// }
const logoToEditFormByFieldName = (fieldName) =>
  R.compose(
    R.over(
      R.lensProp(fieldName),
      R.ifElse(
        R.isNil,
        R.always([]),
        R.compose(R.of, transformInvoiceFileToPicker),
      ),
    ),
  );

const defaultValues = {
  configs: {
    clientPortalApp: {
      showPMsAndAssets: true,
    },
  },
};

// clientToEditForm :: Client -> FormObject
export const clientToEditForm = R.compose(
  R.mergeDeepRight(defaultValues),
  R.unless(
    R.path(['financial', 'spendReportType']),
    R.assocPath(
      ['financial', 'spendReportType'],
      ClientSpendReportTypes.NON_TRANSPARENT,
    ),
  ),
  R.unless(
    R.path(['financial', 'vaSplitPercent']),
    R.assocPath(['financial', 'vaSplitPercent'], 0),
  ),
  assocBy(
    'isUMCCheckboxDisabled',
    R.both(
      R.compose(R.equals(false), R.propOr(false, 'isUMCAccountFormat')),
      R.compose(
        R.complement(R.isEmpty),
        R.reject(R.isNil),
        R.values,
        R.path(['configs', 'propertyFields', 'buildingInfo']),
      ),
    ),
  ),
  R.over(
    R.lensProp('contractFile'),
    R.ifElse(R.isNil, R.always([]), R.pipe(transformInvoiceFileToPicker, R.of)),
  ),
  R.compose(
    R.dissocPath(['financial', 'division']),
    R.converge(R.assocPath(['financial', 'divisionAccountId']), [
      R.path(['financial', 'division', '_id']),
      R.identity,
    ]),
  ),
  R.omit([
    'tmMarkupRules',
    'childClients',
    'cardNumber',
    'staffRates',
    'bidRules',
    'manager',
  ]),
  prepareAddressObjectToForm,
  assocBy('managerId', R.path(['manager', '_id'])),
  removePropDeep('__typename'),
  R.over(
    R.lensProp('configs'),
    R.compose(
      logoToEditFormByFieldName('logoImage'),
      R.over(
        R.lensProp('clientInvoicing'),
        R.compose(
          overPropUnlessNil(
            'invoiceEmailRecipients',
            R.map(R.applySpec({ value: R.identity, label: R.identity })),
          ),
          prepareAddressObjectToForm,
          logoToEditFormByFieldName('companyLogo'),
        ),
      ),
      R.over(R.lensProp('requestsApp'), requestAppConfigsToEditForm),
      R.over(R.lensProp('clientPortalApp'), clientPortalAppConfigsToEditForm),
    ),
  ),
);
