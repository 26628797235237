import React from 'react';
import styled from 'styled-components';
import { bool, func, instanceOf, oneOfType, shape } from 'prop-types';
import { Icon, Text } from 'poly-book-admin';

import { SubcontractorAgreementFrame } from '../SubcontractorAgreementPage/SubcontractorAgreementFrame.js';

const PositionRelativeContainer = styled.div`
  position: relative;
  margin: 0 0 15px 0;
`;

const Wrapper = styled.div`
  margin-top: ${({ isScrolledToBottom }) =>
    isScrolledToBottom ? '0' : '-60px'};
  border: 2px solid #f1f1f1;
  border-top: none;
`;

const ScrollToBottomMessageContainer = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  top: 93%;
  text-transform: uppercase;
  background-color: #fff;
  height: 60px;
  border: 2px solid #f1f1f1;
  border-top: none;
  z-index: 1;
`;

const IconS = styled(Icon)`
  cursor: pointer;
`;

function ScrollToBottomMessage({ isScrolledToBottom, isAgree, bottomFormRef }) {
  if (isScrolledToBottom || isAgree) {
    return null;
  }

  const scrollToBottom = () => {
    bottomFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ScrollToBottomMessageContainer>
      <Text>scroll down</Text>
      <IconS name="arrow-down" onClick={scrollToBottom} />
    </ScrollToBottomMessageContainer>
  );
}

ScrollToBottomMessage.propTypes = {
  isScrolledToBottom: bool,
  isAgree: bool,
  bottomFormRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
};

export function SubcontractorAgreementDocument(props) {
  return (
    <PositionRelativeContainer>
      <ScrollToBottomMessage {...props} />
      <Wrapper {...props}>
        <SubcontractorAgreementFrame />
      </Wrapper>
    </PositionRelativeContainer>
  );
}
