import {
  useWith as ramdaUseWith,
  curry,
  cond,
  compose,
  map,
  is,
  all,
  identity,
  toLower,
  toString,
  T,
  sort as sortArr,
  ifElse,
  nth,
  pipe,
  objOf,
  assoc,
} from 'ramda';
import { ASC_SORT_ORDER, DESC_SORT_ORDER } from 'poly-constants';

// listIsType :: [Any] -> Boolean
const listIsType = ramdaUseWith(all, [is, identity]);

const applySort = curry((sort, a, b) =>
  cond([
    // pass numbers straight to sorting
    [listIsType(Number), sort],
    // convert dates to millisecond equiv
    [
      listIsType(Date),
      compose(
        sort,
        map((x) => x.getTime()),
      ),
    ],
    // set strings to lower case
    [listIsType(String), compose(sort, map(toLower))],
    // user wants to sort it nevertheless there are equal types of values or not
    [T, compose(sort, map(compose(toLower, toString)))],
  ])([a, b]),
);

const getSortOrder =
  (order) =>
  ([a, b]) =>
    ((a > b) - (b > a)) * order;

/**
 * Takes a sort direction (1 or -1) and two values and returns 1 or -1
 * to indicate which value is to be higher in a sorted list
 * (used when a function requires a sorting function as param)
 */
const getSort = ramdaUseWith(applySort, [getSortOrder, identity, identity]);

const getSortOrderForQuery = (dir) =>
  dir === 1 ? ASC_SORT_ORDER : DESC_SORT_ORDER;

export const updateSorting = (columnKey, sorting = {}) => ({
  columnKey,
  dir: sorting.columnKey === columnKey ? -sorting.dir : 1,
});

export const sortTableRows = (
  columnsValuesGetters,
  { columnKey, dir: order },
) =>
  sortArr((row1, row2) =>
    getSort(order)(
      columnsValuesGetters[columnKey](row1),
      columnsValuesGetters[columnKey](row2),
    ),
  );

export const isScrolledToBottom = (el, threshold = 0) => {
  const offset = el.scrollTop + el.clientHeight + threshold;
  return offset >= el.scrollHeight;
};

// eslint-disable-next-line react/forbid-foreign-prop-types
export const isFuncComponent = (func) => !!(func.displayName || func.propTypes);

export const updateGraphSorting = (sortQuery, columnKey, sorting) => {
  if (typeof sortQuery !== 'function') {
    return sorting;
  }
  const dir = sorting.columnKey === columnKey ? -sorting.dir : 1;
  const order = getSortOrderForQuery(dir);
  const query = sortQuery(order);
  return {
    columnKey,
    dir,
    query,
  };
};

// toTableHeaders ::TableConfig -> TableHeader
export const toTableHeaders = map(
  ifElse(
    nth(2),
    pipe(nth(0), objOf('title'), assoc('hasSortingValue', true)),
    pipe(nth(0), objOf('title')),
  ),
);
// toTableColumns ::TableConfig -> [TableColumn]
export const toTableColumns = map(nth(1));

// toSortQueries ::TableConfig -> [TableColumn]
export const toSortQueries = map(nth(2));
