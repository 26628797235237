import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { DESC_SORT_ORDER, ProjectType } from 'poly-constants';
import { Loader } from 'poly-book-admin';
import {
  useMapConfigToTableProps,
  useSetItemsCount,
  useTableSorting,
} from 'poly-admin-ui';
import {
  ProjectsTabTitleComp,
  MasterSupplierSubTabComp,
  MasterSupplierProjectsSubTabs,
  prepareMasterSupplierProjectsToTableByPath,
} from './commonMasterSupplierProjectsTabComps.js';
import { projectsSubTabsConfig } from '../../../modules/tabs/common.js';
import { commonProjectTableConfig } from '../../../modules/tables/projectsTable/ProjectsTable.js';
import { ProjectsPageProjectsTableComp } from '../../../modules/tables/projectsTable/ProjectsTableComp.js';
import { useMasterSupplierProjectsQuery } from '../hooks/useMasterSupplierProjectsQuery.js';

function MasterSupplierProjectsSubTabTitle({ status }) {
  const { data } = useMasterSupplierProjectsQuery(status);

  const count = R.pathOr(
    0,
    ['masterSupplier', 'searchProjects', 'total'],
    data,
  );

  return <ProjectsTabTitleComp count={count} status={status} />;
}

MasterSupplierProjectsSubTabTitle.propTypes = { status: string.isRequired };

function MasterSupplierProjectsTabTable({ status }) {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: commonProjectTableConfig,
    order: DESC_SORT_ORDER,
    column: 8,
  });

  const { data, loading } = useMasterSupplierProjectsQuery(status, sort);

  useSetItemsCount(
    R.pathOr(0, ['masterSupplier', 'searchProjects', 'total']),
    data,
  );

  const tableProps = useMapConfigToTableProps(
    prepareMasterSupplierProjectsToTableByPath([
      'masterSupplier',
      'searchProjects',
      'hits',
    ]),
    commonProjectTableConfig,
    data,
  );

  return loading ? (
    <Loader />
  ) : (
    <ProjectsPageProjectsTableComp {...tableSortingProps} {...tableProps} />
  );
}

MasterSupplierProjectsTabTable.propTypes = { status: string.isRequired };

const masterSupplierProjectsTabs = R.map(
  ([status, tabName]) => [
    <MasterSupplierProjectsSubTabTitle key={tabName} status={status} />,
    tabName,
    <MasterSupplierSubTabComp
      key={status}
      status={status}
      Table={MasterSupplierProjectsTabTable}
    />,
  ],
  projectsSubTabsConfig,
);

export function MasterSupplierProjectsTab() {
  const subTabsProps = {
    tabs: masterSupplierProjectsTabs,
    newItemProps: { projectType: ProjectType.WORK_ORDER },
  };

  return <MasterSupplierProjectsSubTabs {...subTabsProps} />;
}
