import React from 'react';
import { CommonPrintLayout } from 'poly-admin-ui';
import { openPrintWindowWithData } from 'poly-client-utils';
import { bool, number, shape } from 'prop-types';
import { alwaysNewDate, formatDate } from 'poly-utils';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import {
  WorkInProgressQCTable,
  wipQCRecordsPropTypes,
} from './WorkInProgressQCTable.js';
import {
  CenteredSubtitle,
  ExportPDFBtn,
} from '../WorkInProgressReport/WorkInProgressExportPDFBtn.js';

export function WIPQCExportPDFBtn({ sorting, wipQCRecords, loading }) {
  const onPrintPDFClick = async () => {
    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: WorkInProgressQCTable,
      fileName: 'work_in_progress_qc_report',
      metaData: {
        title: 'Work In Progress QC Report',
        additional: (
          <CenteredSubtitle>
            {`As of ${formatDate(alwaysNewDate())}`}
          </CenteredSubtitle>
        ),
      },
      initialSorting: sorting,
      wipQCRecords,
      isLoading: loading,
    });
  };

  return (
    <ExportPDFBtn loader={loading} onClick={onPrintPDFClick}>
      {PRINT_PDF_CAPTION}
    </ExportPDFBtn>
  );
}

WIPQCExportPDFBtn.propTypes = {
  sorting: shape({
    columnKey: number.isRequired,
    dir: number.isRequired,
  }),
  wipQCRecords: wipQCRecordsPropTypes,
  loading: bool,
};
