import * as R from 'ramda';
import {
  useUpdateQueryParams,
  useRouterQuery,
  useLocation,
} from 'poly-client-routing';

import { SidebarIDs } from '../sidebars/constants.js';
import { useCloseSidebarForm } from '../sidebars/components/commonSidebarFormComponents.js';
import { usePreviousSidebarParams } from '../sidebars/usePreviousSidebarParams.js';
import { useClearPristineState } from '../sidebars/useClearPristineOnSidebarMount.js';

// prepareQueryParamsToRemove :: Boolean -> QueryObject -> QueryObject
const prepareQueryParamsToRemove = (closeSteady) =>
  R.compose(
    R.map(R.always(undefined)),
    R.unless(R.always(closeSteady), R.omit(['type', SidebarIDs.project])),
    R.defaultTo({}),
  );

// isClickedAppBarItem :: OutsideClickEvent -> Boolean
const isClickedAppBarItem = (e) => {
  const appBarElement = document.getElementById('app-bar');
  const targetElement = e.target;

  const isClickableItem = !!targetElement.closest('[data-testid]');
  const isItemInAppBar = appBarElement.contains(targetElement);

  return isClickableItem && isItemInAppBar;
};

export const useOnOutsideClick = () => {
  const query = useRouterQuery([...R.values(SidebarIDs), 'type']);
  const { state } = useLocation();
  const clearPristine = useClearPristineState();

  const previousParams = usePreviousSidebarParams();
  const updateQueryParams = useUpdateQueryParams();

  const onClose = (closeAllOutSidebars, closeSteady) => {
    closeAllOutSidebars(closeSteady);
    const paramsToRemove = prepareQueryParamsToRemove(closeSteady)(query);

    const tabParamsToRemove = closeSteady
      ? { sidebarTab: undefined, sidebarSubTab: undefined }
      : {};

    const isEmptyToRemoveParams = R.isEmpty(paramsToRemove);

    if (!isEmptyToRemoveParams) {
      updateQueryParams({
        ...paramsToRemove,
        ...previousParams,
        ...tabParamsToRemove,
      });
    }

    if (R.has('pristine', state)) {
      const queryParamsToRemove = isEmptyToRemoveParams
        ? {}
        : { ...paramsToRemove, ...tabParamsToRemove };

      clearPristine(queryParamsToRemove);
    }
  };

  const closeSidebarForm = useCloseSidebarForm(onClose);

  const handleCloseSidebarForm = (
    closeAllOutSidebars,
    closeSteady,
    { event, isOpenedSidebar },
  ) => {
    if (!isClickedAppBarItem(event) && isOpenedSidebar) {
      closeSidebarForm(closeAllOutSidebars, closeSteady, {
        event,
        isOpenedSidebar,
      });
    }
  };

  return (...args) =>
    R.has('pristine', state)
      ? handleCloseSidebarForm(...args)
      : onClose(...args);
};
