import * as R from 'ramda';
import { applySpecWithFields } from 'poly-utils';
import {
  FinancialEntityTypes,
  SystemAccountTypes,
  collectionNames,
} from 'poly-constants';

// geCollectionByType :: FormData -> CollectionName
export const geCollectionByType = R.compose(
  R.cond([
    [R.equals(FinancialEntityTypes.CLIENTS), R.always(collectionNames.clients)],
    [R.equals(FinancialEntityTypes.EMPLOYEES), R.always(collectionNames.users)],
    [
      R.equals(FinancialEntityTypes.SUPPLIERS),
      R.always(collectionNames.suppliers),
    ],
    [R.T, R.always(null)],
  ]),
  R.prop('type'),
);

/**
 * prepareDepositInputForMutation :: FormData -> CreateJournalDepositInput
 */
export const prepareDepositInputForMutation = R.compose(
  R.pick([
    'date',
    'glAccountId',
    'netAmount',
    'payeeName',
    'documentId',
    'collection',
    'description',
    'depositNumber',
    'bankAccountId',
  ]),
  R.when(
    R.propEq('type', FinancialEntityTypes.OTHERS),
    R.omit(['documentId', 'collection']),
  ),
  applySpecWithFields({
    glAccountId: R.prop('glAccount'),
    collection: geCollectionByType,
  }),
);

/**
 * filterBankAndCCAccounts :: [Account] -> [Account]
 */
export const filterBankAndCCAccounts = R.reject(
  R.compose(
    R.either(
      R.equals(SystemAccountTypes.BANK_ACCOUNT),
      R.equals(SystemAccountTypes.CREDIT_CARD),
    ),
    R.path(['accountType', 'system_type']),
  ),
);
