import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { useReactiveQuery } from 'poly-client-utils';
import { Loader } from 'poly-book-admin';
import {
  useMapConfigToTableProps,
  SUPPLIER_SEARCH_CHANGED,
  SidebarTabTableWrapper,
  CommonSidebarTabTable,
  commonAddressFields,
  SidebarTabHeader,
  RemoveIcon,
  MAX_ITEMS,
} from 'poly-admin-ui';

import {
  SectionLabel,
  SectionWrapperWithoutBorder,
} from '../../components/commonSidebarComponents.js';
import { supplierPhoneColumn } from '../../../modules/tables/columns/suppliers.js';
import { AddSupplierToProjectButton } from '../components/AddSupplierToProjectButton.js';
import { useRemoveProjectSupplier } from './useRemoveProjectSupplier.js';
import { AssignSupplierToProjectButton } from '../components/AssignSupplierToProjectButton.js';
import { propOrNothingUI } from '../../../modules/core/utils/general.js';
import {
  supplierSidebarColumn,
  VerticalAddAssignSupplierDividerS,
  SupplierStatusInProjectUI,
  SupplierNTEComp,
} from '../ProjectSidebarSuppliers.js';
import { isListingProjectType } from '../forms/form/utils/sections.js';

const projectSuppliersFields = gql`
  fragment projectSuppliersFields on ProjectSupplier {
    _id
    status
    nte
    type
    statusInProject
    rating {
      average
      stars
    }
    company {
      name
      address {
        ...commonAddressFields
      }
      phones {
        phone
        type
      }
      emails {
        email
        type
      }
      services {
        _id
        name
      }
    }
  }

  ${commonAddressFields}
`;

const PROJECT_INFO_TO_SUPPLIERS = gql`
  query PROJECT_INFO_TO_SUPPLIERS($projectId: ID, $id: ID) {
    project(projectId: $projectId, id: $id) {
      _id
      type
      accountingStatus
      serviceType {
        _id
        name
      }
      adminPurchaseOrder {
        _id
        currentBalance
      }
      property {
        _id
        tmMarkupRules {
          upTo
          percent
        }
      }
      client {
        _id
        tmMarkupRules {
          upTo
          percent
        }
      }
      suppliers {
        ...projectSuppliersFields
      }
    }
  }

  ${projectSuppliersFields}
`;

const PROJECT_SUPPLIERS_SUB = gql`
  subscription PROJECT_SUPPLIERS_SUB($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
      document {
        _id
        serviceType {
          _id
          name
        }
        suppliers {
          ...projectSuppliersFields
        }
      }
    }
  }

  ${projectSuppliersFields}
`;

const SuppliersTableS = styled(CommonSidebarTabTable)`
  td:last-child,
  th:last-child {
    width: 15px;

    > svg {
      margin-top: 2px;
      cursor: pointer;
    }
  }

  td:nth-child(2),
  th:nth-child(2),
  td:nth-child(3),
  th:nth-child(3) {
    width: 100px;
  }

  td:nth-child(4),
  th:nth-child(4) {
    width: 105px;
  }

  td:nth-child(5),
  th:nth-child(5) {
    width: 65px;
  }
`;

// prepareProjectSuppliers :: Project -> QueryData -> [Supplier]
const prepareProjectSuppliers = (project) => (data) =>
  R.compose(
    R.map(R.mergeRight({ project })),
    R.map(
      R.assoc('serviceType', R.path(['project', 'serviceType', 'name'], data)),
    ),
    R.map(R.assoc('projectId', R.path(['project', '_id'], data))),
    R.pathOr([], ['project', 'suppliers']),
  )(data);

function RemoveSupplierButton({ projectId, _id }) {
  const onDelete = useRemoveProjectSupplier({ projectId, supplierId: _id });

  return <RemoveIcon onClick={onDelete} />;
}

RemoveSupplierButton.propTypes = {
  _id: string.isRequired,
  projectId: string.isRequired,
};

const projectSuppliersTableConfig = [
  supplierSidebarColumn,
  ['Service Type', R.prop('serviceType')],
  supplierPhoneColumn,
  ['NTE', SupplierNTEComp],
  [
    'Status',
    R.compose(
      propOrNothingUI(R.__, SupplierStatusInProjectUI),
      R.prop('statusInProject'),
    ),
  ],
  ['', RemoveSupplierButton],
];

const getProjectFromResponse = R.prop('project');

export function ProjectSidebarSuppliersTab({ _id }) {
  const { data, loading } = useReactiveQuery(
    PROJECT_INFO_TO_SUPPLIERS,
    [PROJECT_SUPPLIERS_SUB, SUPPLIER_SEARCH_CHANGED],
    {
      queryOptions: { variables: { id: _id } },
      subscriptionOptions: [
        { variables: { input: { id: _id } } },
        { variables: { searchInput: { size: MAX_ITEMS, from: 0 } } },
      ],
    },
  );
  const project = getProjectFromResponse(data);

  const tableProps = useMapConfigToTableProps(
    prepareProjectSuppliers(project),
    projectSuppliersTableConfig,
    data,
  );

  return (
    <SectionWrapperWithoutBorder skipMargin>
      <SidebarTabHeader>
        <SectionLabel>Project Suppliers</SectionLabel>
        <div>
          <AddSupplierToProjectButton _id={_id} />
          {!isListingProjectType(project) && (
            <>
              <VerticalAddAssignSupplierDividerS />
              <AssignSupplierToProjectButton _id={_id} />
            </>
          )}
        </div>
      </SidebarTabHeader>
      <SidebarTabTableWrapper offset={42}>
        {loading ? <Loader /> : <SuppliersTableS {...tableProps} />}
      </SidebarTabTableWrapper>
    </SectionWrapperWithoutBorder>
  );
}

ProjectSidebarSuppliersTab.propTypes = { _id: string.isRequired };
