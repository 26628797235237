import * as R from 'ramda';
import { useMemo } from 'react';

import { useQuery, gql } from '@apollo/client';
import { DESC_SORT_ORDER } from 'poly-constants';
import { useTableSorting } from 'poly-admin-ui';
import {
  prepareQuoteDiscrepanciesQueryInput,
  quoteDiscrepanciesInputQueryProps,
} from '../QuoteDiscrepancies/hooks/useQuoteDiscrepanciesReportQuery.js';
import { getSuppliersQuoteDiscrepanciesTable } from './getSuppliersQuoteDiscrepanciesTable.js';

const SUPPLIER_QUOTE_DISCREPANCIES_REPORT_QUERY = gql`
  query SUPPLIER_QUOTE_DISCREPANCIES_REPORT_QUERY(
    $input: SuppliersQuoteDiscrepanciesReportInput!
  ) {
    suppliersQuoteDiscrepanciesReport(input: $input) {
      _id
      invoiceNumber
      total
      discrepancy
      discrepancyPercentage
      supplierNTE
      project {
        _id
        type
        projectId
      }
      supplier {
        _id
        company {
          name
        }
      }
    }
  }
`;

const inputQueryProps = [...quoteDiscrepanciesInputQueryProps, 'supplierId'];

export const useSuppliersQuoteDiscrepanciesReportQuery = (queryInput) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: getSuppliersQuoteDiscrepanciesTable(),
    order: DESC_SORT_ORDER,
    column: 6,
  });

  const skip = !queryInput?.endDate || !queryInput?.startDate;
  const input = useMemo(
    () =>
      prepareQuoteDiscrepanciesQueryInput(
        { sort, queryInput },
        inputQueryProps,
      ),
    [sort, queryInput],
  );

  const { data, loading } = useQuery(
    SUPPLIER_QUOTE_DISCREPANCIES_REPORT_QUERY,
    {
      variables: { input },
      fetchPolicy: 'network-only',
      skip,
    },
  );

  const tableRows = useMemo(
    () => R.propOr([], 'suppliersQuoteDiscrepanciesReport', data),
    [data],
  );

  return { tableRows, loading, tableSortingProps };
};
