import React from 'react';
import { SystemAccountTypes } from 'poly-constants';

import { AccountRegistersPage } from '../BankRegistersPage/AccountRegistersPage.js';

export function CreditCardRegistersPage() {
  return (
    <AccountRegistersPage
      title="Credit Card Registers"
      exportTitle="Credit Card Register"
      systemAccountType={SystemAccountTypes.CREDIT_CARD}
      hideCheckNumber
    />
  );
}
