import React from 'react';
import { SystemAccountTypes } from 'poly-constants';
import { BankReconciliationPageRedesignedBase } from './BankReconcilationPageBase.js';

const paymentsSection = {
  title: 'Checks and Payments',
  totalLabel: 'Total Checks & Payments Cleared',
  tableHeight: '65%',
  tableKey: 'payments',
};

const depositsSection = {
  title: 'Deposits and Other Credits',
  totalLabel: 'Total Deposits & Credits Cleared',
  tableHeight: '35%',
  tableKey: 'deposits',
};

const confirmationMessage =
  'Bank Reconciliation Statement is successfully created';

export function BankReconciliationPage() {
  return (
    <BankReconciliationPageRedesignedBase
      paymentsSection={paymentsSection}
      depositsSection={depositsSection}
      accountTypeLabel="Bank"
      systemAccountType={SystemAccountTypes.BANK_ACCOUNT}
      confirmationMessage={confirmationMessage}
    />
  );
}
