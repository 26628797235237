import * as R from 'ramda';
import qs from 'query-string';
import { insertParamIntoURL } from 'poly-utils';

/**
 * formatBackLink :: Object -> oneOf([String, Object])
 */
export const formatBackLink = ({ fallbackLink, router, withQuery }) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.propSatisfies(R.equals(R.prop('pathname', router), R.__), 'pathname'),
    ]),
    R.always(fallbackLink),
    R.ifElse(
      R.always(withQuery),
      R.converge(R.concat, [R.prop('pathname'), R.propOr('', 'search')]),
      R.prop('pathname'),
    ),
  )(R.prop('previous', router));

// urlParamName :: Url -> String
const urlParamName = R.compose(R.nth(1), R.match(/:(\w+)($|\/)/));

// documentUrlGeneratorById :: (Document -> ID) -> Url -> Document -> Url
export const documentUrlGenerator = (documentIdExtractor) => (route) =>
  R.compose(
    insertParamIntoURL(urlParamName(route), route),
    documentIdExtractor,
  );

// documentUrlGenerator :: Url -> Document -> Url
export const documentUrlGeneratorById = documentUrlGenerator(R.prop('_id'));

// parseQuery :: QueryString -> QueryObject
export const parseQuery = (queryString) =>
  qs.parse(queryString, { parseBooleans: true });

// stringifyQuery :: QueryObject -> QueryString
export const stringifyQuery = (queryObject) => qs.stringify(queryObject);

// extractPreviousUrl :: Location -> String
// Location = Object
export const extractPreviousUrl = R.converge(R.concat, [
  R.pathOr('', ['previous', 'pathname']),
  R.pathOr('', ['previous', 'search']),
]);
