import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import React, { useCallback } from 'react';
import { string, bool, number } from 'prop-types';
import { Loader, Dot, LinkButton } from 'poly-book-admin';
import { useReactiveQuery } from 'poly-client-utils';
import {
  getSupplierCityStateAddress,
  useSidebarTableFormContext,
  SidebarWrapperForTableForm,
  CommonSidebarFormWrapper,
  useMapConfigToTableProps,
  SupplierDocumentsWarning,
  SUPPLIER_SEARCH_CHANGED,
  SidebarTabTableWrapper,
  supplierStatusesColors,
  CommonSidebarTabTable,
  SidebarTabHeader,
  getSupplierPhone,
  FormPortalAnchor,
  getServiceTypes,
  FlexContainer,
  MAX_ITEMS,
} from 'poly-admin-ui';

import { forceTitleCase } from 'poly-utils';
import {
  SectionLabel,
  DoubleTabsWrapper,
} from '../../components/commonSidebarComponents.js';
import {
  PropertySupplierForm,
  propertySupplierFormId,
} from '../forms/PropertySupplierForm.js';
import { SupplierLinkComp } from '../../../modules/tables/columns/suppliers.js';
import { propertySupplierFields } from '../../../modules/core/hooks/properties/fragments.js';

const PROPERTY_SUPPLIERS = gql`
  query PROPERTY_SUPPLIERS($id: ID!) {
    property(id: $id) {
      _id
      suppliers {
        ...propertySupplierFields
      }
    }
  }
  ${propertySupplierFields}
`;

const PROPERTY_SUPPLIERS_SUB = gql`
  subscription PROPERTY_SUB($input: SingleDocSubInput!) {
    propertyChanged(input: $input) {
      id
      document {
        _id
        suppliers {
          ...propertySupplierFields
        }
      }
    }
  }
  ${propertySupplierFields}
`;

function AddSupplierButton(props) {
  const { formSetter } = useSidebarTableFormContext();

  const onFormCancel = useCallback(() => {
    formSetter(null);
  }, []);

  const onAddSupplier = () =>
    formSetter({
      id: props.propertyId,
      Content: (
        <CommonSidebarFormWrapper
          onCancel={onFormCancel}
          submitCaption="Add Supplier"
          formId={propertySupplierFormId}
        >
          <PropertySupplierForm {...props} onCancel={onFormCancel} />
        </CommonSidebarFormWrapper>
      ),
    });

  return <LinkButton onClick={onAddSupplier}>Add Supplier</LinkButton>;
}

AddSupplierButton.propTypes = { propertyId: string.isRequired };

const SuppliersTableS = styled(CommonSidebarTabTable)`
  td:nth-child(1),
  th:nth-child(1) {
    width: 25px;
  }

  td:nth-child(4),
  th:nth-child(4),
  td:nth-child(5),
  th:nth-child(5) {
    width: 150px;
  }

  td:nth-child(6),
  th:nth-child(6) {
    width: 100px;
  }
`;

function SupplierStatusDot({ status }) {
  return <Dot color={supplierStatusesColors[R.toLower(status)]} />;
}

SupplierStatusDot.propTypes = { status: string.isRequired };

function SupplierStatusPopover(supplier) {
  return (
    <FlexContainer>
      {forceTitleCase(supplier.status)}{' '}
      <SupplierDocumentsWarning supplier={supplier} position="left" />
    </FlexContainer>
  );
}

const propertySuppliersTableConfig = [
  ['', SupplierStatusDot],
  ['Supplier', SupplierLinkComp],
  ['Supplier Type', getServiceTypes],
  ['City/State', getSupplierCityStateAddress],
  ['Phone', getSupplierPhone],
  ['Status', SupplierStatusPopover],
];

// preparePropertySuppliers :: PropertyQueryResult -> [Supplier]
const preparePropertySuppliers = R.compose(
  R.map(R.prop('supplier')),
  R.pathOr([], ['property', 'suppliers']),
);

// getPropertySupplierIds :: PropertyQueryResult -> [ID]
const getPropertySupplierIds = R.compose(
  R.map(R.prop('_id')),
  preparePropertySuppliers,
);

export function PropertySidebarSuppliersTab({
  isCard,
  propertyId,
  doubleWidth,
}) {
  const { data, loading } = useReactiveQuery(
    PROPERTY_SUPPLIERS,
    [PROPERTY_SUPPLIERS_SUB, SUPPLIER_SEARCH_CHANGED],
    {
      queryOptions: { variables: { id: propertyId } },
      subscriptionOptions: [
        { variables: { input: { id: propertyId } } },
        { variables: { searchInput: { size: MAX_ITEMS, from: 0 } } },
      ],
    },
  );

  const tableProps = useMapConfigToTableProps(
    preparePropertySuppliers,
    propertySuppliersTableConfig,
    data,
  );

  const addBtnProps = {
    propertyId,
    propertySupplierIds: getPropertySupplierIds(data),
  };

  return (
    <SidebarWrapperForTableForm>
      <DoubleTabsWrapper width={doubleWidth} isCard={isCard}>
        <SidebarTabHeader>
          <SectionLabel>Property Suppliers</SectionLabel>
          <AddSupplierButton {...addBtnProps} />
        </SidebarTabHeader>
        <FormPortalAnchor id={propertyId} />
        <SidebarTabTableWrapper offset={42}>
          {loading ? <Loader /> : <SuppliersTableS {...tableProps} />}
        </SidebarTabTableWrapper>
      </DoubleTabsWrapper>
    </SidebarWrapperForTableForm>
  );
}

PropertySidebarSuppliersTab.propTypes = {
  isCard: bool,
  propertyId: string.isRequired,
  doubleWidth: number.isRequired,
};
