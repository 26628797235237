import React from 'react';
import { DatePicker } from 'poly-book-admin';
import { oneOfType, string, instanceOf, func, bool } from 'prop-types';

export function ReceivedDatePicker({
  _id,
  submitting,
  receivedDate,
  onReceivedDateChange,
}) {
  const inputName = `payment-received-date-input-${_id}`;

  return (
    <DatePicker
      width="100%"
      leftMove="-225px"
      name={inputName}
      value={receivedDate}
      disabled={submitting}
      onChange={onReceivedDateChange}
      dataTestId={inputName}
    />
  );
}

ReceivedDatePicker.propTypes = {
  _id: string,
  submitting: bool,
  onReceivedDateChange: func,
  receivedDate: oneOfType([string, instanceOf(Date)]),
};
