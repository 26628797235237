import * as R from 'ramda';
import { ASSET_SCANNER_APP_NAME, CLIENT_PORTAL_APP_NAME } from 'poly-security';
import { SidebarWidth } from 'poly-admin-ui';

// showClientChildren :: Client -> Boolean
export const showClientChildren = R.both(
  R.prop('enableChildAccounts'),
  R.propSatisfies(R.isNil, 'parentClient'),
);

// showClientPortal :: Client -> Boolean
export const showClientPortal = R.compose(
  R.both(
    R.complement(R.isEmpty),
    R.compose(
      R.complement(R.isEmpty),
      R.intersection([CLIENT_PORTAL_APP_NAME, ASSET_SCANNER_APP_NAME]),
    ),
  ),
  R.pathOr([], ['apps']),
);

// showClientPortalEnabledInfo :: Client -> Boolean
export const showClientPortalEnabledInfo = R.compose(
  R.includes(CLIENT_PORTAL_APP_NAME),
  R.pathOr([], ['apps']),
);

// showAssetScannerEnabledInfo :: Client -> Boolean
export const showAssetScannerEnabledInfo = R.compose(
  R.includes(ASSET_SCANNER_APP_NAME),
  R.pathOr([], ['apps']),
);

// getClientTabsWidth :: (Boolean, Boolean) -> Number
export const getClientTabsWidth = (showChildren, showPortal) => {
  let clientTabsWidth = SidebarWidth;

  if (showChildren) {
    clientTabsWidth += 80;
  }

  if (showPortal) {
    clientTabsWidth += 100;
  }

  return clientTabsWidth;
};
