import * as R from 'ramda';
import { AccountingMethods } from 'poly-constants';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useState, useEffect } from 'react';

import { formTypeConstants } from 'poly-admin-ui';
import { AccountingMethodSelect } from '../../../components/AccountingMethodSelect.js';
import { onKeyDownToPreventFormSubmit } from '../../../utils/form.js';

export function JournalEntryAccountingTypeSelector({ formData, ...props }) {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (
      R.any(
        R.pathEq(['accountCode', 'value', 'isBankAccount'], true),
        formData.lines,
      )
    ) {
      props.onChange(AccountingMethods.CASH_BASIS);
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [formData.lines]);

  return (
    <AccountingMethodSelect
      {...props}
      disabled={isDisabled || formData.formType === formTypeConstants.EDIT}
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

JournalEntryAccountingTypeSelector.propTypes = {
  formData: shape({
    lines: arrayOf(
      shape({
        accountCode: shape({
          value: shape({
            code: string.isRequired,
            isBankAccount: bool,
          }),
        }),
      }),
    ),
  }),
  onChange: func.isRequired,
};
