import * as R from 'ramda';
import React from 'react';

import { RWOTaskListType, RWOTaskListTypeUI } from 'poly-constants';
import { Select } from 'poly-book-admin';

// getOptions :: Object :: Array
const getOptions = R.pipe(
  R.values,
  R.flip(R.zip)(R.values(RWOTaskListTypeUI)),
  R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
);

export function RecurringTaskSelect(props) {
  const options = getOptions(RWOTaskListType);

  const selectProps = {
    ...props,
    options,
    name: 'RecurringTaskSelect',
  };

  return <Select {...selectProps} />;
}

RecurringTaskSelect.displayName = 'RecurringTaskSelect';
