import React from 'react';
import { arrayOf, oneOfType, string, object, bool } from 'prop-types';
import { isNilOrEmpty } from 'poly-utils/src/general.js';

import { MasterSupplierLink, SupplierLink } from '../../components/Links.js';

const searchHighlightedTextPropType = oneOfType([
  string,
  object,
  arrayOf(oneOfType([string, object])),
]);

export function Supplier1099ReportLink({
  _id,
  name,
  taxName,
  isMasterSupplier,
}) {
  const linkName = isNilOrEmpty(taxName) ? name : taxName;

  if (isMasterSupplier) {
    return <MasterSupplierLink _id={_id} name={linkName} />;
  }

  return <SupplierLink _id={_id} name={linkName} />;
}

Supplier1099ReportLink.propTypes = {
  name: searchHighlightedTextPropType,
  taxName: searchHighlightedTextPropType,
  _id: string,
  isMasterSupplier: bool,
};
