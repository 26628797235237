import React from 'react';
import * as R from 'ramda';
import { arrayOf } from 'prop-types';
import styled from 'styled-components';
import { SidebarRow, useMapConfigToTableProps } from 'poly-admin-ui';
import {
  calculateTotal,
  centsToDollarsWithFormat,
  formatTotal,
  formatDate,
} from 'poly-utils';
import { formatDateOrNothingUI, useOutSidebarContext } from 'poly-client-utils';
import {
  SystemAccountTypes,
  JournalPaymentStatus,
  NOTHING_UI_STRING,
} from 'poly-constants';
import { moneyColumnStyles, Table } from 'poly-book-admin';
import { DatePicker } from 'poly-book-admin/src/DatePicker/index.js';

import { HeaderBlock } from './HeaderBlock.js';
import { journalPropTypes } from './transactionDetailsPropTypes.js';
import {
  isBankJournalLine,
  isCashBasisLine,
  getPaidByForJournal,
  transactionsDetailsSidebarId,
  getCreatorNameUIByJournal,
} from './helpers.js';
import {
  TransactionDetailsContainer,
  TransactionHeader,
  TransactionLinesContainer,
  TransactionTotalFooterContainer,
} from './ClientPaymentTransactionDetailsSidebar.js';
import { useEditJournalPostingDate } from './useEditJournalPostingDate.js';
import { BlockWithLabel } from '../components/commonSidebarComponents.js';
import { TransactionDetailsButtons } from './TransactionDetailsButtons.js';

const transactionLinesTableConfig = [
  ['Ref No', R.path(['invoiceId'])],
  ['Inv No', R.path(['invoiceNumber'])],
  ['Name', R.path(['supplier', 'company', 'name'])],
  ['GL Code', R.pathOr(NOTHING_UI_STRING, ['glAccount', 'name'])],
  ['Project No', R.path(['project', 'projectId'])],
  ['Inv Date', R.compose(formatDate, R.path(['invoiceDate']))],
  ['Inv Amount', R.compose(centsToDollarsWithFormat, R.path(['total']))],
  ['Deductions', R.compose(formatTotal, R.prop('deductionsAmount'))],
  ['Paid Amount', R.compose(formatTotal, R.prop('paidAmount'))],
];

// isApJournalLine :: JournalLine -> Boolean
const isApJournalLine = R.pathEq(
  ['account', 'accountType', 'system_type'],
  SystemAccountTypes.ACCOUNTS_PAYABLE,
);

// getInvoiceWithAmountsByJournal :: [Journal] -> [Invoice]
const getInvoiceWithAmountsByJournal = R.converge(R.mergeRight, [
  R.prop('invoice'),
  R.applySpec({
    paidAmount: R.compose(
      R.multiply(-1),
      R.propOr(0, 'cash_amount'),
      R.find(isBankJournalLine),
      R.prop('lines'),
    ),
    deductionsAmount: R.compose(
      R.multiply(-1),
      R.propOr(0, 'cash_amount'),
      R.find(
        R.complement(
          R.anyPass([isCashBasisLine, isBankJournalLine, isApJournalLine]),
        ),
      ),
      R.prop('lines'),
    ),
  }),
]);

// transformJournalsToTransactionInfo :: [Journal] -> Object
const transformJournalsToTransactionInfo = R.converge(R.mergeRight, [
  R.compose(
    R.applySpec({
      invoices: R.identity,
      paidTotal: calculateTotal(R.prop('paidAmount')),
      deductionsTotal: calculateTotal(R.prop('deductionsAmount')),
    }),
    R.map(getInvoiceWithAmountsByJournal),
  ),
  R.compose(
    R.applySpec({
      transaction_number: R.prop('transaction_number'),
      date: R.prop('date'),
      voidedAt: R.prop('voided_at'),
      account: R.compose(
        R.path(['account', 'name']),
        R.find(isBankJournalLine),
        R.prop('lines'),
      ),
      status: R.compose(
        R.prop('payment_status'),
        R.head,
        R.reject(R.propSatisfies(R.isNil, 'payment_status')),
        R.prop('lines'),
      ),
      bankClearedDate: R.compose(
        R.prop('bank_cleared_date'),
        R.find(isBankJournalLine),
        R.prop('lines'),
      ),
      paidBy: getPaidByForJournal,
      checkNumber: R.prop('check_number'),
      createdBy: getCreatorNameUIByJournal,
    }),
    R.head,
  ),
]);

const PaymentTransactionTableS = styled(Table)`
  ${moneyColumnStyles(7, 100)};
  ${moneyColumnStyles(8, 100)};
  ${moneyColumnStyles(9, 100)};

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }
`;

const VoidedTextS = styled.span`
  font-size: 17px;
`;

function PaymentTransactionHeaderBlock(props) {
  return <HeaderBlock width="25%" {...props} />;
}

export function PaymentTransactionDetailsSidebar({ journals }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const transactionInfo = transformJournalsToTransactionInfo(journals);

  const {
    handleEditJournalPostingDate,
    isEditMode,
    toggleEditMode,
    postingDate,
    setPostingDate,
  } = useEditJournalPostingDate(transactionInfo);

  const tableProps = useMapConfigToTableProps(
    R.always(transactionInfo.invoices),
    transactionLinesTableConfig,
  );

  return (
    <TransactionDetailsContainer>
      <SidebarRow justify>
        <TransactionHeader>Payment Details</TransactionHeader>
        <TransactionDetailsButtons
          toggleEditMode={toggleEditMode}
          isEditMode={isEditMode}
          handleEditJournalPostingDate={handleEditJournalPostingDate}
          closeOutSidebar={() => closeOutSidebar(transactionsDetailsSidebarId)}
        />
      </SidebarRow>
      <SidebarRow>
        <PaymentTransactionHeaderBlock
          title="Paid By"
          content={transactionInfo.paidBy}
        />
        <PaymentTransactionHeaderBlock
          title="Account"
          content={transactionInfo.account}
        />
        {transactionInfo.status === JournalPaymentStatus.VOIDED && (
          <PaymentTransactionHeaderBlock
            title="Status"
            content={<VoidedTextS>Voided</VoidedTextS>}
          />
        )}
        <PaymentTransactionHeaderBlock
          title="Bank Clearing Date"
          content={formatDateOrNothingUI(transactionInfo.bankClearedDate)}
        />
        <PaymentTransactionHeaderBlock
          title="Created By"
          content={transactionInfo.createdBy}
        />
      </SidebarRow>
      <SidebarRow>
        {isEditMode ? (
          <BlockWithLabel
            id="postingDate"
            label="Posting Date"
            width="25%"
            onChange={setPostingDate}
            value={postingDate}
            leftMove="0"
            Component={DatePicker}
          />
        ) : (
          <PaymentTransactionHeaderBlock
            title="Posting Date"
            content={formatDate(transactionInfo.date)}
          />
        )}

        <PaymentTransactionHeaderBlock
          title="Amount"
          content={formatTotal(transactionInfo.paidTotal)}
        />
        {transactionInfo.checkNumber && (
          <PaymentTransactionHeaderBlock
            title="Chk #"
            content={transactionInfo.checkNumber}
          />
        )}
        {transactionInfo.status === JournalPaymentStatus.VOIDED && (
          <PaymentTransactionHeaderBlock
            title="Chk voiding date"
            content={formatDateOrNothingUI(transactionInfo.voidedAt)}
          />
        )}
      </SidebarRow>
      <TransactionLinesContainer>
        <SidebarRow>
          <PaymentTransactionTableS {...tableProps} />
        </SidebarRow>
      </TransactionLinesContainer>
      <TransactionTotalFooterContainer>
        <HeaderBlock
          title="Total Deductions"
          content={formatTotal(transactionInfo.deductionsTotal)}
          width="auto"
          id="deductions-total"
        />
        <HeaderBlock
          title="Total Payment"
          content={formatTotal(transactionInfo.paidTotal)}
          width="auto"
          id="deductions-total"
        />
      </TransactionTotalFooterContainer>
    </TransactionDetailsContainer>
  );
}

PaymentTransactionDetailsSidebar.propTypes = {
  journals: arrayOf(journalPropTypes),
};
