import * as R from 'ramda';
import { AccountingMethods, NOTHING_UI_STRING } from 'poly-constants';
import {
  ExcelExportCellType,
  XLS_CURRENCY_FORMAT,
  convertCentsToDollars,
  createExcelExportCell,
} from 'poly-utils';

// eslint-disable-next-line import/no-unused-modules
export const TABLE_PARENT_SECTION_STRING_STYLES = {
  style: {
    font: { bold: true },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'c8c8c8' },
    },
  },
};

// eslint-disable-next-line import/no-unused-modules
export const TABLE_PARENT_SECTION_CURRENCY_STYLES = {
  style: {
    ...TABLE_PARENT_SECTION_STRING_STYLES.style,
    numFmt: XLS_CURRENCY_FORMAT,
    alignment: { horizontal: 'right' },
  },
};

const TABLE_PARENT_STRING_STYLES = {
  style: {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e6e6e6' },
    },
  },
};

const TABLE_PARENT_CURRENCY_STYLES = {
  style: {
    ...TABLE_PARENT_STRING_STYLES.style,
    numFmt: XLS_CURRENCY_FORMAT,
    alignment: { horizontal: 'right' },
  },
};

// createXLSCellWithCustomStyles :: Object -> Any -> Object
const createXLSCellWithCustomStyles = R.curry((styles, value) =>
  R.assoc('value', value, styles),
);

// getExcelTitle :: FilterObj -> String
export const getExcelTitle = R.compose(
  R.concat('Income Statement ('),
  R.concat(R.__, ' Basis)'),
  R.ifElse(
    R.propEq('accountingMethod', AccountingMethods.CASH_BASIS),
    R.always('Cash'),
    R.always('Accrual'),
  ),
);

// getCellValue :: String -> (AccountingIncomeStatement -> Any) -> ExcelExportDataCell
const getCellValue = (cellType) => (getValue) =>
  R.applySpec({
    value: getValue,
    cellType: R.always(cellType),
  });

// getDefaultCellValueByParent :: (Object -> String) -> AccountingIncomeStatement -> ExcelExportDataCell
const getDefaultCellValueByParent = (getValueFunc) =>
  R.ifElse(
    R.prop('isParentGLCodeRow'),
    R.compose(
      createXLSCellWithCustomStyles(TABLE_PARENT_STRING_STYLES),
      getValueFunc,
    ),
    getCellValue(ExcelExportCellType.default)(getValueFunc),
  );

// getCurrencyCellValueByParent :: (Object -> Float) -> AccountingIncomeStatement -> ExcelExportDataCell
const getCurrencyCellValueByParent = (getValueFunc) =>
  R.ifElse(
    R.prop('isParentGLCodeRow'),
    R.compose(
      createXLSCellWithCustomStyles(TABLE_PARENT_CURRENCY_STYLES),
      getValueFunc,
    ),
    getCellValue(ExcelExportCellType.defaultCurrency)(getValueFunc),
  );

// getExcelTableData :: [AccountingIncomeStatement] -> [ExcelExportDataCell]
export const getExcelTableData = R.map(
  R.juxt([
    getDefaultCellValueByParent(R.propOr('', 'compass_gl_code')),
    getDefaultCellValueByParent(R.pathOr('', ['client', 'sapGLCode'])),
    getDefaultCellValueByParent(R.propOr('', 'compass_gl_code_description')),
    getDefaultCellValueByParent(R.propOr('', 'code')),
    getDefaultCellValueByParent(
      R.compose(
        R.defaultTo(NOTHING_UI_STRING),
        R.either(R.prop('name'), R.path(['client', 'name'])),
      ),
    ),
    getCurrencyCellValueByParent(
      R.compose(convertCentsToDollars, R.propOr(0, 'clientBalance')),
    ),
    getCurrencyCellValueByParent(
      R.compose(convertCentsToDollars, R.propOr(0, 'balance')),
    ),
  ]),
);

// getSectionTitle :: String -> [ExcelExportDataCell]
export const getSectionTitle = (title) =>
  R.zipWith(
    createXLSCellWithCustomStyles,
    R.repeat(TABLE_PARENT_SECTION_STRING_STYLES, 7),
    [title, ...R.repeat('', 6)],
  );

export const getSectionTotal = (title, total) =>
  R.zipWith(
    createXLSCellWithCustomStyles,
    [
      ...R.repeat(TABLE_PARENT_SECTION_STRING_STYLES, 6),
      TABLE_PARENT_SECTION_CURRENCY_STYLES,
    ],
    [title, ...R.repeat('', 5), total],
  );

export const EMPTY_XLS_ROW = R.repeat(
  createExcelExportCell(ExcelExportCellType.default, ''),
  7,
);
