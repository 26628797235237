import React from 'react';
import * as R from 'ramda';
import { Select } from 'poly-book-admin';
import { gql, useQuery } from '@apollo/client';
import { entityToOptionByLabelPath } from 'poly-client-utils';
import { string, bool, oneOfType, arrayOf, shape } from 'prop-types';

import { MultiSelectDropDown } from 'poly-admin-ui/src/components/MultiSelectDropDown.js';

export const ASSET_TYPES_QUERY = gql`
  query ASSET_TYPES_QUERY {
    assetTypes {
      _id
      name
    }
  }
`;

// formatAssetTypeOptions :: { assetTypes: [AssetType] } -> [Option]
const formatAssetTypeOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.propOr([], 'assetTypes'),
  R.defaultTo({}),
);

export function AssetTypeSelect({
  isMultipleSelect,
  value,

  ...props
}) {
  const { data } = useQuery(ASSET_TYPES_QUERY, {
    fetchPolicy: 'network-only',
  });

  const selectProps = {
    ...props,
    value,
    placeholder: 'Start typing types',
    options: formatAssetTypeOptions(data),
  };

  const Component = isMultipleSelect ? MultiSelectDropDown : Select;

  return <Component {...selectProps} />;
}

AssetTypeSelect.propTypes = {
  value: oneOfType([string, arrayOf(shape)]),
  isMultipleSelect: bool,
};
