import * as R from 'ramda';
import React from 'react';
import { CommonPrintLayout, useMapConfigToTableProps } from 'poly-admin-ui';
import { PRINT_PDF_CAPTION } from 'poly-constants';

import { arrayOf, shape } from 'prop-types';
import {
  openPrintWindowWithData,
  capitalizeFirstLetter,
} from 'poly-client-utils';

import { commonTableConfig } from '../chartOfAccountsTableConfig.js';
import { TableS, TextButtonS } from '../components.js';
import {
  accountPropType,
  defaultChartOfAccountsPropTypes,
} from '../chartOfAccountsPropTypes.js';

function ExportTable({ data }) {
  const { rows, headers, columns } = useMapConfigToTableProps(
    R.identity,
    commonTableConfig,
    data,
  );

  return (
    <TableS
      rows={rows}
      headers={headers}
      columns={columns}
      isRowDisabled={R.prop('isDisabled')}
      isPrintPDF
    />
  );
}

ExportTable.propTypes = {
  data: arrayOf(shape(accountPropType)),
};

export function ExportToPDFButton({ accountsByTabAndFilter, tab }) {
  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: ExportTable,
      data: accountsByTabAndFilter,
      metaData: { title: `Chart Of Accounts / ${capitalizeFirstLetter(tab)}` },
      fileName: `chart_of_${tab}_accounts`,
    });

  return <TextButtonS onClick={onClick}>{PRINT_PDF_CAPTION}</TextButtonS>;
}

ExportToPDFButton.propTypes = defaultChartOfAccountsPropTypes;
