import React from 'react';
import * as R from 'ramda';
import { string, bool, func } from 'prop-types';
import { LinkButtonWithLoader } from 'poly-book-admin';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from 'poly-client-utils';
import { format } from 'date-fns';

// getAssetsRowsConfig :: Asset -> [ExcelExportDataCell]
const getAssetsRowsConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 16)),
  R.juxt([
    R.propOr('', 'displayName'),
    R.propOr('', 'qrCodeId'),
    R.pathOr('', ['client', 'name']),
    R.pathOr('', ['property', 'name']),
    R.converge(R.concat, [
      R.compose(
        R.unless(R.isEmpty, R.concat(R.__, ' ')),
        R.pathOr('', ['property', 'address', 'address_parts', 'street_number']),
      ),
      R.pathOr('', ['property', 'address', 'address_parts', 'route']),
    ]),
    R.pathOr('', ['property', 'addressTwo']),
    R.pathOr('', ['property', 'address', 'address_parts', 'locality']),
    R.pathOr('', [
      'property',
      'address',
      'address_parts',
      'administrative_area_level_1',
    ]),
    R.pathOr('', ['property', 'address', 'address_parts', 'postal_code']),
    R.pathOr('', ['property', 'address', 'address_parts', 'country']),
    R.pathOr('', ['type', 'name']),
    R.pathOr('', ['manufacturerDoc', 'name']),
    R.pathOr('', ['modelDoc', 'name']),
    R.propOr('', 'serial'),
    R.propOr('', 'location'),
    R.compose(
      R.join(', '),
      R.pluck('projectId'),
      R.propOr([], 'recurringProjects'),
    ),
  ]),
);

const mergedTableHeaderStyle = {
  font: { bold: true },
  fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00C5D9F6' } },
  border: {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  },
};

const mergedTableSubHeaderStyle = {
  ...mergedTableHeaderStyle,
  alignment: { vertical: 'middle', horizontal: 'center' },
};

const mergedTableHeader = { style: mergedTableHeaderStyle };

const getCurrentDate = () => format(new Date(), 'MM-dd-yyyy');

// getAssetsExportExcelConfig :: ([Asset], String) -> ExcelExportConfig
// eslint-disable-next-line import/no-unused-modules
export const getAssetsExportExcelConfig = (assets, fileName) => ({
  exportFileName: `Asset List - ${fileName} - ${getCurrentDate()}.xlsx`,
  columnWidths: [
    20, 10, 25, 30, 20, 20, 15, 10, 10, 10, 25, 20, 20, 25, 25, 25,
  ],
  rows: [
    [
      {
        value: 'Display Name',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
      {
        value: 'QR Code',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
      {
        value: 'Client Name',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
      {
        value: 'Property Name',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
      {
        value: 'Property Address',
        _polyInternal: { mergeRight: 6 },
        style: mergedTableSubHeaderStyle,
      },
      mergedTableHeader,
      mergedTableHeader,
      mergedTableHeader,
      mergedTableHeader,
      mergedTableHeader,
      {
        value: 'Asset Type',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
      {
        value: 'Manufacturer',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
      {
        value: 'Model',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
      {
        value: 'Serial Number',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
      {
        value: 'Location Description',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
      {
        value: 'Master PMs',
        _polyInternal: { mergeDown: 2 },
        ...mergedTableHeader,
      },
    ],
    [
      mergedTableHeader,
      mergedTableHeader,
      mergedTableHeader,
      mergedTableHeader,
      {
        value: 'Address',
        ...mergedTableHeader,
      },
      {
        value: 'Address 2',
        ...mergedTableHeader,
      },
      {
        value: 'City',
        ...mergedTableHeader,
      },
      {
        value: 'State',
        ...mergedTableHeader,
      },
      {
        value: 'Zip',
        ...mergedTableHeader,
      },
      {
        value: 'Country',
        ...mergedTableHeader,
      },
      mergedTableHeader,
      mergedTableHeader,
      mergedTableHeader,
      mergedTableHeader,
      mergedTableHeader,
      mergedTableHeader,
    ],
    ...R.map(getAssetsRowsConfig, assets),
  ],
});

export function AssetsExportXLSBtn({
  fileName,
  disabled,
  entityName,
  queryExportHandler,
}) {
  const onClick = async () => {
    const { data } = await queryExportHandler();

    const assets = R.pathOr(
      [],
      [...(entityName ? [entityName] : []), 'searchAssets', 'hits'],
      data,
    );
    const excelConfig = getAssetsExportExcelConfig(assets, fileName);

    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader disabled={disabled} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}

AssetsExportXLSBtn.propTypes = {
  fileName: string.isRequired,
  disabled: bool,
  entityName: string,
  queryExportHandler: func.isRequired,
};
