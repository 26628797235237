import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import { isFuture, isToday } from 'date-fns';
import { SUPPLIERS_INSURANCE_ROUTE } from 'poly-constants';
import { DatePicker, FormField } from 'poly-book-admin';
import { isNilOrEmpty } from 'poly-utils';

import {
  Label,
  LinkS,
  ForSectionTitle,
  FormFieldWrapper,
  TwoColumnWrapper,
  ForSectionWrapper,
  DocumentsFilePicker,
} from './components.js';

// validateExpirationDate :: Date -> String Boolean
const validateExpirationDate = R.cond([
  [R.anyPass([isToday, isFuture]), R.F],
  [isNilOrEmpty, R.always('Expiration date is required')],
  [R.T, R.always('Expiration date is incorrect')],
]);

const Link = styled(LinkS)`
  margin-left: 7px;
  font-size: 12px;
`;

export function DocumentRequestFormSection({
  title,
  name,
  expName,
  withInsuranceLink,
}) {
  return (
    <ForSectionWrapper>
      <ForSectionTitle>
        {title}
        {withInsuranceLink && (
          <Link target="_blank" href={SUPPLIERS_INSURANCE_ROUTE}>
            View Insurance Requirements
          </Link>
        )}
      </ForSectionTitle>
      <TwoColumnWrapper>
        <FormFieldWrapper>
          <Label>Expiration Date *</Label>
          <FormField
            name={expName}
            additionalProps={{
              position: 'top',
              leftMove: '5px',
              width: '292px',
              size: 'big',
              disabledDays: [
                {
                  before: new Date(),
                },
              ],
            }}
            Component={DatePicker}
            validate={validateExpirationDate}
          />
        </FormFieldWrapper>
        <DocumentsFilePicker label="Attach Document" name={name} />
      </TwoColumnWrapper>
    </ForSectionWrapper>
  );
}

DocumentRequestFormSection.propTypes = {
  title: string.isRequired,
  name: string.isRequired,
  expName: string.isRequired,
  withInsuranceLink: bool,
};
