import styled from 'styled-components';
import React, { useState } from 'react';
import { string, number, bool } from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { LinkButton, Status, Loader } from 'poly-book-admin';
import { formatCount, forceTitleCase } from 'poly-utils';
import { CREATE_ASSET_PERMISSION } from 'poly-security';
import { AssetStatuses } from 'poly-constants';
import {
  SidebarTabTableWrapper,
  CommonSidebarTabTable,
  useSearchTabLogic,
  SearchInputComp,
  SidebarSubTabs,
  SidebarRow,
  MAX_ITEMS,
} from 'poly-admin-ui';
import { useLazyQuery } from '@apollo/client';

import {
  SectionLabel,
  DoubleTabsWrapper,
} from '../../components/commonSidebarComponents.js';
import { assetStatusesColors } from '../../AssetSidebar/constants.js';
import { AssetsExportXLSBtn } from '../../../components/AssetsExportXLSButton/AssetsExportXLSBtn.js';
import { useOnImportAssetsSidebar } from '../../AssetSidebar/forms/import/useOnImportAssetsSidebar.js';
import { useAddAssetSidebar } from '../../AssetSidebar/forms/add/useAddAssetSidebar.js';
import {
  SEARCH_ASSETS_QUERY,
  usePropertyAssetsQuery,
} from './usePropertyAssetsQuery.js';

const AssetsTable = styled(CommonSidebarTabTable)`
  td:nth-child(1),
  th:nth-child(1) {
    width: 15px;
    padding-top: 15px;
  }

  td:nth-child(3),
  th:nth-child(3),
  td:nth-child(5),
  th:nth-child(5) {
    width: 150px;
  }

  td:nth-child(8),
  th:nth-child(8) {
    width: 100px;
  }

  td:nth-child(9),
  th:nth-child(9) {
    width: 20px;
  }
`;

const AssetsTableToolbarWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 4px;

  > button:first-child {
    margin-right: 25px;
  }
`;

function AddAssetButton({ propertyId }) {
  const openAddAssetSidebar = useAddAssetSidebar();

  const onAddAsset = () => openAddAssetSidebar({ propertyId });

  return <LinkButton onClick={onAddAsset}>Add Asset</LinkButton>;
}

AddAssetButton.propTypes = { propertyId: string.isRequired };

const LinkButtonS = styled(LinkButton)`
  margin-right: 20px;
`;

function ImportAssetButton({ propertyId }) {
  const openImportAssetSidebar = useOnImportAssetsSidebar();

  const onImportAsset = () => openImportAssetSidebar({ propertyId });

  return <LinkButtonS onClick={onImportAsset}>Import Asset</LinkButtonS>;
}

ImportAssetButton.propTypes = { propertyId: string.isRequired };

const assetsSubTabsConfig = [
  [AssetStatuses.ACTIVE, 'active-assets'],
  [AssetStatuses.INACTIVE, 'inactive-assets'],
  [AssetStatuses.PENDING, 'pending-assets'],
  [AssetStatuses.BLOCKED, 'blocked-assets'],
];

function CommonAssetsTabTitle({ status, ...props }) {
  const { count } = usePropertyAssetsQuery({ status, ...props });

  const title = `${forceTitleCase(status)} (${formatCount(count)})`;

  return <Status color={assetStatusesColors[status]} text={title} />;
}

CommonAssetsTabTitle.propTypes = { status: string.isRequired };

function CommonAssetsTab(props) {
  const { loading, tableProps } = usePropertyAssetsQuery(props);

  if (loading) return <Loader />;

  return <AssetsTable loading={loading} {...tableProps} />;
}

export function PropertySidebarAssetsTab({
  isCard,
  doubleWidth,
  propertyId,
  propertyName,
}) {
  const [queryInput, setQueryInput] = useState(null);
  const { searchTerm, ...inputProps } = useSearchTabLogic('assets');

  const hasCreateAssetPermission = useHasUserAccessWithPermission(
    CREATE_ASSET_PERMISSION,
  );

  const [queryExportHandler] = useLazyQuery(SEARCH_ASSETS_QUERY, {
    variables: {
      input: { ...queryInput, size: MAX_ITEMS },
    },
    fetchPolicy: 'network-only',
  });

  return (
    <DoubleTabsWrapper isCard={isCard} width={doubleWidth}>
      <SidebarRow justify align>
        <SectionLabel>Property Assets</SectionLabel>
        <SearchInputComp {...inputProps} />
      </SidebarRow>
      <SidebarTabTableWrapper offset={48}>
        <SidebarSubTabs
          tabs={assetsSubTabsConfig.map(([status, title]) => [
            <CommonAssetsTabTitle
              {...{ status, searchTerm, propertyId, isCard }}
            />,
            title,
            <CommonAssetsTab
              {...{ status, searchTerm, propertyId, isCard, setQueryInput }}
            />,
          ])}
          defaultValue="active-assets"
          toolBar={
            <AssetsTableToolbarWrapper>
              <AssetsExportXLSBtn
                queryExportHandler={queryExportHandler}
                fileName={propertyName}
                disabled={!queryInput}
              />
              {hasCreateAssetPermission && (
                <ImportAssetButton propertyId={propertyId} />
              )}
              {hasCreateAssetPermission && (
                <AddAssetButton propertyId={propertyId} />
              )}
            </AssetsTableToolbarWrapper>
          }
        />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapper>
  );
}

PropertySidebarAssetsTab.propTypes = {
  isCard: bool,
  propertyId: string.isRequired,
  doubleWidth: number.isRequired,
  propertyName: string.isRequired,
};
