import React from 'react';
import { useOutSidebarContext } from 'poly-client-utils';
import { SidebarWidth } from 'poly-admin-ui';

import { masterProjectSidebarTabsId } from '../constants.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';
import { MasterRecurringProjectSidebarTabs } from './MasterRecurringProjectSidebarTabs.js';

export const useOpenMasterRecurringProjectSidebarTabs = (project) => {
  const { openOutSidebar } = useOutSidebarContext();

  return () =>
    openOutSidebar({
      width: SidebarWidth,
      Layout: SecondSidebarLayout,
      id: masterProjectSidebarTabsId,
      content: <MasterRecurringProjectSidebarTabs project={project} />,
    });
};
