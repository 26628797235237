import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { bool, shape, string, object, arrayOf } from 'prop-types';
import { CancelBtn, closeCurrentModal } from 'poly-admin-ui';
import { Text, Button, ModalActions } from 'poly-book-admin';
import { openPrintWindowWithData } from 'poly-client-utils';

import {
  printTables,
  printTableFileNames,
} from '../core/tableToolbar/TablePrintPDFBtn.js';
import { ITEMS_LIMIT } from '../core/constants/print.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';

const defaultConfirmText = (
  <>
    <p>Print PDF is limited to 100 line items. If you need more, hit</p>
    <p>Cancel and use the Export link to generate and XL file.</p>
  </>
);

export function ConfirmPrintModal({ payload, inProgress }) {
  const { data, metaData, printType } = payload;

  const Layout = usePrintLayout();
  const dispatch = useDispatch();

  const onCancel = () => dispatch(closeCurrentModal());

  const onConfirm = async () => {
    await openPrintWindowWithData({
      Layout,
      metaData,
      Table: printTables[printType],
      data: R.take(ITEMS_LIMIT, data),
      fileName: printTableFileNames[printType],
    });
    onCancel();
  };

  return (
    <>
      <Text size="16px">{defaultConfirmText}</Text>
      <ModalActions>
        <CancelBtn onClick={onCancel}>Cancel</CancelBtn>
        <Button
          disabled={inProgress}
          loader={inProgress}
          size="small"
          type="submit"
          onClick={onConfirm}
        >
          Yes, print 100
        </Button>
      </ModalActions>
    </>
  );
}

ConfirmPrintModal.displayName = 'ConfirmPrintModal';
ConfirmPrintModal.propTypes = {
  inProgress: bool,
  payload: shape({
    // eslint-disable-next-line react/forbid-prop-types
    data: arrayOf(object),
    // eslint-disable-next-line react/forbid-prop-types
    metaData: object,
    printType: string.isRequired,
  }),
};
ConfirmPrintModal.defaultProps = { inProgress: false };
