import * as R from 'ramda';
import React from 'react';
import { TextButton } from 'poly-book-admin';
import { instanceOf, shape } from 'prop-types';
import { useNotificationState } from 'poly-admin-ui';
import { applyPath, formatDate, formatManualJournalNumber } from 'poly-utils';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
  formatFromToDateTitle,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';

import { journalEntryLedgerResultPropTypes } from './prop-types.js';
import { JournalEntryLineTypes } from './constants.js';
import {
  calculateJournalEntryTotalByType,
  extractLinesAccountCodes,
  prepareJournalEntryLinesByPath,
} from './journalEntryUtils.js';

// getJournalEntryRow :: JournalEntry -> [ExcelExportDataCell]
const getJournalEntryRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 6),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
    ExcelExportCellType.default,
  ]),
  R.juxt([
    R.compose(
      R.when(R.complement(R.isEmpty), formatManualJournalNumber),
      R.prop('transaction_number'),
    ),
    R.pathOr('', ['created_by', 'profile', 'fullName']),
    R.compose(R.when(R.complement(R.isEmpty), formatDate), R.prop('date')),
    R.path(['lines', 0, 'description']),
    extractLinesAccountCodes(R.propEq('type', JournalEntryLineTypes.DEBIT)),
    extractLinesAccountCodes(R.propEq('type', JournalEntryLineTypes.CREDIT)),
    calculateJournalEntryTotalByType(JournalEntryLineTypes.DEBIT),
    calculateJournalEntryTotalByType(JournalEntryLineTypes.CREDIT),
    applyPath(formatDate, ['reverse_journal', 'date']),
  ]),
);

// eslint-disable-next-line import/no-unused-modules
export const getJournalEntryLedgerExportExcelConfig = ({
  journalEntries,
  query,
}) => ({
  exportFileName: 'journal_entry_ledger.xlsx',
  columnWidths: [10, 10, 10, 50, 15, 15, 15, 15, 15],
  rows: [
    [
      {
        value: 'Journal Entry Ledger',
        cellType: ExcelExportCellType.title,
      },
    ],
    [
      {
        value: formatFromToDateTitle(query.checkFromDate, query.checkToDate),
        cellType: ExcelExportCellType.title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 6),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 2),
        ExcelExportCellType.tableHeader,
      ],
      [
        'JE #',
        'Created By',
        'Date',
        'Description',
        'Debit account codes',
        'Credit account codes',
        'Debit',
        'Credit',
        'Date of reversal',
      ],
    ),
    ...R.map(getJournalEntryRow, journalEntries),
  ],
});

export function JournalEntryLedgerExportExcelButton({ query, data }) {
  const { showErrorNotification } = useNotificationState();
  const onClick = async () => {
    const journalEntries = prepareJournalEntryLinesByPath([
      'getManualJournals',
      'hits',
    ])(data);

    if (R.isEmpty(journalEntries)) {
      showErrorNotification('No records to export');
      return;
    }

    const exportConfig = getJournalEntryLedgerExportExcelConfig({
      query,
      journalEntries,
    });
    performExcelExport(exportConfig);
  };

  return <TextButton onClick={onClick}>{EXPORT_XLS_CAPTION}</TextButton>;
}

JournalEntryLedgerExportExcelButton.propTypes = {
  query: shape({
    checkFromDate: instanceOf(Date),
    checkToDate: instanceOf(Date),
  }),
  data: shape({
    getManualJournals: journalEntryLedgerResultPropTypes,
  }),
};
