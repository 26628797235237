import React, { useState } from 'react';
import { useRouterParams } from 'poly-client-routing';
import {
  useHasUserAccessWithPermission,
  useSortableTable,
} from 'poly-client-utils';
import { UPDATE_ASSET_MANUFACTURER_PERMISSION } from 'poly-security';
import { TableCardWithPaginationContainer } from 'poly-admin-ui';
import { DefaultBodyWrapper, Loader } from 'poly-book-admin';

import { AssetManufactureModelPageHeader } from './AssetManufactureModelPageHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { useAssetManufactureModels } from './useAssetManufactureModels.js';
import {
  AssetManufacturerModelsTable,
  assetModelsTableConfig,
} from './AssetManufactureModelsTable.js';

export function AssetManufactureModelsPage() {
  const { manufacturerId } = useRouterParams(['manufacturerId']);
  const [selectedModels, setSelectedModels] = useState([]);

  const hasAccessToUpdateAssetManufacture = useHasUserAccessWithPermission(
    UPDATE_ASSET_MANUFACTURER_PERMISSION,
  );

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: assetModelsTableConfig,
  });

  const { assetModels, loading } = useAssetManufactureModels(
    manufacturerId,
    sort,
  );

  return (
    <PageWithSearchHeader headerHeight="100px">
      <AssetManufactureModelPageHeader
        manufacturerId={manufacturerId}
        selectedModels={selectedModels}
        setSelectedModels={setSelectedModels}
        assetModels={assetModels}
        hasAccessToUpdateAssetManufacture={hasAccessToUpdateAssetManufacture}
      />
      <DefaultBodyWrapper>
        <TableCardWithPaginationContainer>
          {loading ? (
            <Loader />
          ) : (
            <AssetManufacturerModelsTable
              assetModels={assetModels}
              selectedModels={selectedModels}
              setSelectedModels={setSelectedModels}
              {...tableSortProps}
            />
          )}
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </PageWithSearchHeader>
  );
}
