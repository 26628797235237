import * as R from 'ramda';
import { MagicEmailSeparators } from 'poly-utils';

// prepareEmailString :: String -> String
export const prepareEmailString = (email) => `<${email}>`;

// findCollectionRelation :: Collection -> [String] -> String
const findCollectionRelation = (collection) =>
  R.find((str) => R.any(R.equals(str), R.values(collection)));

const { PAYLOAD, NAME } = MagicEmailSeparators;
const magicEmailSeparatorRegexp = new RegExp(`[${NAME}${PAYLOAD}]`, 'ig');

// prepareEmailStringByCollection :: Collection -> String -> String
export const prepareEmailStringByCollection = (collection) =>
  R.ifElse(
    R.compose(
      R.isNil,
      findCollectionRelation(collection),
      R.split(magicEmailSeparatorRegexp),
    ),
    prepareEmailString,
    R.compose(prepareEmailString, R.always('Magic Email')),
  );
