import React from 'react';
import { string, func } from 'prop-types';
import { LoginPageBase } from 'poly-common-ui';
import {
  stringifyQuery,
  useLocation,
  useNavigate,
  parseQuery,
} from 'poly-client-routing';

export function LoginPageBaseAdmin({
  title,
  forgotPasswordRoute,
  homePageRoute,
  onLoginComplete,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { previousUrl, forgotPasswordMessage, ...rest } = parseQuery(
    location.search,
  );

  const getNavigationLink = () => {
    if (previousUrl) {
      const queryString = stringifyQuery(rest);

      if (queryString) return `${previousUrl}&${queryString}`;

      return previousUrl;
    }
    return homePageRoute;
  };

  const onSubmitSuccess = (loginFormData) => {
    navigate(getNavigationLink());
    if (onLoginComplete) {
      onLoginComplete(loginFormData);
    }
  };

  const goToForgotPassword = () => navigate(forgotPasswordRoute);

  return (
    <LoginPageBase
      title={title}
      onSubmitSuccess={onSubmitSuccess}
      goToForgotPassword={goToForgotPassword}
      forgotPasswordMessage={forgotPasswordMessage}
    />
  );
}

LoginPageBaseAdmin.propTypes = {
  title: string.isRequired,
  forgotPasswordRoute: string.isRequired,
  homePageRoute: string.isRequired,
  onLoginComplete: func,
};
