import { func } from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useOutSidebarContext } from 'poly-client-utils';
import { useLocation } from 'poly-client-routing';
import { getThemeColor } from 'poly-book-admin';
import { debounce } from 'poly-utils';

import {
  GLOBAL_SEARCH_RESULT_CONTAINER_ID,
  GLOBAL_SEARCH_RESULT_ACTIVE_TAB_ID,
} from './globalSearchConstants.js';
import { GlobalSearchInputWrapper } from './GlobalSearchInputWrapper.js';
import { setGlobalSearchText } from './globalSearchReducer.js';
import {
  useGlobalSearchResultSidebar,
  GLOBAL_SEARCH_SIDEBAR_ID,
} from '../../hooks/useGlobalSearchResultSidebar.js';

const SearchInputS = styled.input`
  height: 100%;
  overflow: hidden;
  padding: 8px;
  width: 95%;
  background-color: transparent;
  border: none;
  color: ${getThemeColor(['primary'])};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  &::placeholder {
    color: ${getThemeColor(['white'])};
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
`;

export function GlobalSearchInput({ GlobalSearchResult }) {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const [currentUrl, setCurrentUrl] = useState(null);
  const { setSidebarHidden } = useOutSidebarContext();
  const [internalValue, setInternalValue] = useState('');
  const [isGlobalSearchHidden, setIsGlobalSearchHidden] = useState(false);
  const [isGlobalSearchFocused, setIsGlobalSearchFocused] = useState(false);

  const setGlobalSearchResultHidden = setSidebarHidden(
    GLOBAL_SEARCH_SIDEBAR_ID,
  );

  useEffect(() => () => dispatch(setGlobalSearchText('')), []);

  useEffect(() => {
    if (isGlobalSearchFocused) {
      setCurrentUrl(`${pathname}${search}`);
    } else {
      setCurrentUrl(null);
    }
  }, [isGlobalSearchFocused]);

  useEffect(() => {
    if (currentUrl && currentUrl !== `${pathname}${search}`) {
      setIsGlobalSearchHidden(true);
    }
  }, [pathname, search, currentUrl]);

  useEffect(() => {
    setGlobalSearchResultHidden(isGlobalSearchHidden);
  }, [isGlobalSearchHidden]);

  useGlobalSearchResultSidebar(GlobalSearchResult, isGlobalSearchFocused);

  const setGlobalText = (text) => dispatch(setGlobalSearchText(text));

  const setGlobalFocusedDebounced = useCallback(
    debounce(300)((isFocused) => {
      setIsGlobalSearchFocused(isFocused);
    }),
    [],
  );

  const setGlobalTextDebounced = useCallback(
    debounce(1000)((searchText) => {
      setGlobalText(searchText);
    }),
    [],
  );

  const onChangeInternal = (e) => {
    const { value } = e.target;
    setInternalValue(value);
    setGlobalTextDebounced(value);
  };

  const setFocusInput = () => {
    inputRef.current.focus();
    setIsGlobalSearchFocused(true);
    setIsGlobalSearchHidden(false);
  };

  const closeSearch = (e) => {
    const globalSearchResultContainer = document.getElementById(
      GLOBAL_SEARCH_RESULT_CONTAINER_ID,
    );
    const globalSearchResultActiveTabContainer = document.getElementById(
      GLOBAL_SEARCH_RESULT_ACTIVE_TAB_ID,
    );

    const isClickSearchResult =
      e.target.id === GLOBAL_SEARCH_RESULT_CONTAINER_ID ||
      globalSearchResultContainer?.contains(e.target);

    const isClickSearchActiveTab =
      e.target.id === GLOBAL_SEARCH_RESULT_ACTIVE_TAB_ID ||
      globalSearchResultActiveTabContainer?.contains(e.target);

    if (!isClickSearchActiveTab && !isClickSearchResult) {
      setGlobalFocusedDebounced(false);
      setGlobalText('');
    }
  };

  const handleSearchOnFocus = () => {
    if (internalValue) {
      setGlobalSearchResultHidden(false);
      setIsGlobalSearchHidden(false);
    }
  };

  const handleSearch = () => {
    if (internalValue) {
      setGlobalText(internalValue);
      setIsGlobalSearchFocused(true);
      setIsGlobalSearchHidden(false);
    }
  };

  const clearSearchText = () => {
    setInternalValue('');
    setFocusInput();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <GlobalSearchInputWrapper
      {...{
        closeSearch,
        isGlobalSearchFocused,
        isGlobalSearchHidden,
        handleSearch,
        clearSearchText,
      }}
      searchText={internalValue}
    >
      <SearchInputS
        {...{
          ref: inputRef,
          value: internalValue,
          placeholder: 'Search',
          onClick: setFocusInput,
          onChange: onChangeInternal,
          onFocus: handleSearchOnFocus,
          onKeyPress: handleKeyPress,
        }}
      />
    </GlobalSearchInputWrapper>
  );
}

GlobalSearchInput.propTypes = { GlobalSearchResult: func.isRequired };
