import styled from 'styled-components';
import React, { useState } from 'react';
import { bool, object } from 'prop-types';
import { DefaultBodyWrapper, Loader } from 'poly-book-admin';
import {
  TableCardWithPaginationContainer,
  useSearchQueryWithPagination,
  useTableSorting,
} from 'poly-admin-ui';

import {
  SearchSupplierInvoicesTable,
  searchSupplierInvoicesTableConfig,
} from './SearchSupplierInvoicesTable.js';
import { searchSupplierInvoicesDataPropType } from './propTypes.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { INVOICES_BY_SEARCH } from '../../modules/core/hooks/invoices/queries.js';
import { SearchSupplierInvoicesPageHeader } from './SearchSupplierInvoicesPageHeader.js';
import { INVOICES_BY_SEARCH_SUB } from '../../modules/core/hooks/invoices/subscriptions.js';

const DefaultBodyWrapperS = styled(DefaultBodyWrapper)`
  overflow: auto;

  > div {
    > div:first-child {
      height: auto;
    }

    > div:last-child {
      margin: 0;
      padding: 25px 0;
    }
  }

  tbody {
    overflow: hidden;
  }
`;

function PageContent({ data, loading, tableSortingProps }) {
  return (
    <DefaultBodyWrapperS height="calc(100% - 145px)">
      <TableCardWithPaginationContainer>
        {loading ? (
          <Loader />
        ) : (
          <SearchSupplierInvoicesTable
            data={data}
            tableSortingProps={tableSortingProps}
          />
        )}
      </TableCardWithPaginationContainer>
    </DefaultBodyWrapperS>
  );
}

PageContent.propTypes = {
  data: searchSupplierInvoicesDataPropType,
  // eslint-disable-next-line react/forbid-prop-types
  tableSortingProps: object,
  loading: bool,
};

export function SearchSupplierInvoicesPage() {
  const [query, setQuery] = useState(null);

  const { sort: sortQuery, ...tableSortingProps } = useTableSorting({
    tableConfig: searchSupplierInvoicesTableConfig(),
    column: 1,
  });

  const { data, loading } = useSearchQueryWithPagination(
    INVOICES_BY_SEARCH,
    INVOICES_BY_SEARCH_SUB,
    {
      queryName: 'searchInvoices',
      fetchPolicy: 'network-only',
      elasticQuery: query,
      skip: !query,
      sortQuery,
    },
  );

  return (
    <PageWithSearchHeader headerHeight="145px">
      <SearchSupplierInvoicesPageHeader
        {...{ setQuery, loading }}
        data={data}
      />
      <PageContent {...{ loading, data, tableSortingProps }} />
    </PageWithSearchHeader>
  );
}
