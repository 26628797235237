import React from 'react';
import styled from 'styled-components';

import { Loader, getThemeFont, getThemeColor } from 'poly-book-admin';
import { shape, string } from 'prop-types';

const Container = styled.div`
  color: ${getThemeColor(['white'])};
  font-weight: ${getThemeFont(['semibold'])};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoaderS = styled(Loader)`
  position: relative;
  left: 0;
  top: 0;
  margin-bottom: 25px;

  circle {
    stroke: ${getThemeColor(['white'])};
  }
`;

export function LoadingModal({ payload: { text } }) {
  return (
    <Container>
      <LoaderS size={50} />
      <div>{text}</div>
    </Container>
  );
}

LoadingModal.propTypes = {
  payload: shape({ text: string.isRequired }).isRequired,
};
