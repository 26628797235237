import * as R from 'ramda';
import { css } from 'styled-components';
import React, { memo } from 'react';
import { NOTHING_UI_STRING } from 'poly-constants';
import { formatDate, formatTotal } from 'poly-utils';
import { Table, moneyColumnStyles } from 'poly-book-admin';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { arrayOf, bool, func, object } from 'prop-types';

import { DueAmountInput } from './components/DueAmountInput.js';
import { RefNumberInput } from './components/RefNumberInput.js';
import { DeductionGLSelect } from './components/DeductionGLSelect.js';
import { PaymentTypeSelect } from './components/PaymentTypeSelect.js';
import { ReceivedDatePicker } from './components/ReceivedDatePicker.js';
import { PaymentReceivedInput } from './components/PaymentReceivedInput.js';
import { DepositAccountSelect } from './components/DepositAccountSelect.js';
import { SelectPaymentCheckBox } from './components/SelectPaymentCheckBox.js';
import { PaymentDeductionInput } from './components/PaymentDeductionInput.js';
import { SelectAllPaymentsCheckBox } from './components/SelectAllPaymentsCheckBox.js';
import { PayInvoicesWindowedTableS } from '../PayClientPayments/ReceivePaymentsTable.js';
import { useEditablePaymentsHandlers } from './hooks/useEditablePaymentsHandlers.js';

const editClientPaymentsTableStyles = css`
  width: calc(100% - 100px);
  height: calc(100% - 50px);

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: 60px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 50px;
  }

  ${moneyColumnStyles(5, 80)};

  th:nth-child(6),
  td:nth-child(6),
  th:nth-child(7),
  td:nth-child(7),
  th:nth-child(8),
  td:nth-child(8),
  th:nth-child(9),
  td:nth-child(9),
  th:nth-child(10),
  td:nth-child(10),
  th:nth-child(11),
  td:nth-child(11),
  th:nth-child(12),
  td:nth-child(12) {
    width: 80px;
  }
  th:nth-child(13),
  td:nth-child(13) {
    width: 75px;
  }
  th:nth-child(14),
  td:nth-child(14) {
    width: 20px;
  }
`;

const getEditClientPaymentsTableConfig = (
  isAllSelected,
  onSelectAllPayment,
) => [
  ['Client Name', R.propOr(NOTHING_UI_STRING, 'clientName')],
  [
    'Date',
    R.compose(
      R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), formatDate),
      R.prop('clientInvoiceDate'),
    ),
  ],
  ['Project No', R.propOr(NOTHING_UI_STRING, 'projectNumber')],
  ['Invoice No', R.propOr(NOTHING_UI_STRING, 'clientInvoiceNumber')],
  ['Amount', R.compose(formatTotal, R.propOr(0, 'clientInvoiceAmount'))],
  ['Deduction', PaymentDeductionInput],
  ['Deduction GL', DeductionGLSelect],
  ['Amount Received', PaymentReceivedInput],
  ['Due Amount', DueAmountInput],
  ['Payment Type', PaymentTypeSelect],
  ['Ref Number', RefNumberInput],
  ['Deposit Account', DepositAccountSelect],
  ['Received Date', ReceivedDatePicker],
  [
    <SelectAllPaymentsCheckBox
      key="select-all-payments-checkbox"
      isAllSelected={isAllSelected}
      onSelectAllPayment={onSelectAllPayment}
    />,
    SelectPaymentCheckBox,
  ],
];

export const EditClientPaymentsTable = memo(
  ({ virtualized, submitting, ...props }) => {
    const { rows, isAllSelected, onSelectAllPayment } =
      useEditablePaymentsHandlers(submitting);

    const tableConfig = getEditClientPaymentsTableConfig(
      isAllSelected,
      onSelectAllPayment,
    );

    const tableProps = useMapConfigToTableProps(R.identity, tableConfig, rows);

    const TableC = virtualized ? PayInvoicesWindowedTableS : Table;

    return (
      <TableC
        itemSize={60}
        {...tableProps}
        {...props}
        tableStyles={editClientPaymentsTableStyles}
        moreScrollPosition="0px"
        showScrollBar
      />
    );
  },
);

EditClientPaymentsTable.propTypes = {
  submitting: bool,
  virtualized: bool,
  // eslint-disable-next-line react/forbid-prop-types
  transactions: arrayOf(object),
  setTransactions: func,
  // eslint-disable-next-line react/forbid-prop-types
  editablePayments: arrayOf(object),
};
