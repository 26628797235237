import * as R from 'ramda';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { formatAddressString } from 'poly-client-utils';
import { Button, FormField } from 'poly-book-admin';
import { useNavigate } from 'poly-client-routing';
import {
  supplierDocumentRequestTypes,
  veteranCertificationUrl,
  supplierDocumentNames,
  WBECertificationUrl,
  MBECertificationUrl,
  supplierPhoneTypes,
  TaxClassification,
  supplierTypes,
} from 'poly-constants';
import {
  prepareSupplierContactsAndServices,
  prepareSupplierPhonesToForm,
  prepareSupplierEmailsToEditForm,
  withSupplierServiceTypesIds,
  withoutSupplierServices,
  VENDOR_INFO_TYPES,
  findPhoneByType,
} from 'poly-admin-ui';
import {
  insertQueryParamsIntoURL,
  isNilOrEmpty,
  assocByPath,
  assocBy,
} from 'poly-utils';
import { useUploadAttachment } from 'poly-client-utils/src/files/useUploadAttachment.js';
import { emptyStringAsNullForSupplierInput } from 'poly-admin-ui/src/modules/forms/supplierForm/edit/formDataToMutation.js';

import {
  WBECertificateFilePiker,
  MBECertificateFilePiker,
  VeteranCertificateFilePiker,
  DocumentRequestOnboardingFormSection,
} from './DocumentRequestOnboardingFormSection.js';
import { routesNames } from '../../routes/constants.js';
import { SubcontractorAgreement } from './SubcontractorAgreement.js';
import { ForSectionTitle, ForSectionWrapper } from './components.js';
import { DocumentRequestFormSection } from './DocumentRequestFormSection.js';
import { useLogTokenFromSession } from './useLogTokenFromSession.js';

export const submitRequestedSupplierDocumentsMutation = gql`
  mutation submitRequestedSupplierDocuments(
    $input: SubmitRequestedSupplierDocumentsInput!
  ) {
    submitRequestedSupplierDocuments(input: $input)
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

const ButtonS = styled(Button)`
  padding: 6px 30px;
  font-weight: 500;
`;

// validateAgreement :: {isAgree: Boolean} -> String
const validateAgreement = R.ifElse(
  R.prop('isAgree'),
  R.F,
  R.always('Please read and agree to the Subcontractor Agreement'),
);

const documentsRequestFormConfig = [
  {
    documentType: supplierDocumentRequestTypes.W9,
    Component: (props) => <DocumentRequestOnboardingFormSection {...props} />,
  },
  {
    documentType: supplierDocumentRequestTypes.WC,
    Component: () => (
      <DocumentRequestFormSection
        title={supplierDocumentNames[supplierDocumentRequestTypes.WC]}
        name="wcFile"
        expName="wcExp"
        withInsuranceLink
      />
    ),
  },
  {
    documentType: supplierDocumentRequestTypes.GL,
    Component: () => (
      <DocumentRequestFormSection
        title={supplierDocumentNames[supplierDocumentRequestTypes.GL]}
        name="glFile"
        expName="glExp"
        withInsuranceLink
      />
    ),
  },
  {
    documentType: supplierDocumentRequestTypes.WBE,
    Component: () => (
      <ForSectionWrapper>
        <ForSectionTitle>WBE Certification</ForSectionTitle>
        <WBECertificateFilePiker
          maxWidth="100%"
          certificationUrl={WBECertificationUrl}
        />
      </ForSectionWrapper>
    ),
  },
  {
    documentType: supplierDocumentRequestTypes.MBE,
    Component: () => (
      <ForSectionWrapper>
        <ForSectionTitle>MBE Certification</ForSectionTitle>
        <MBECertificateFilePiker
          maxWidth="100%"
          certificationUrl={MBECertificationUrl}
        />
      </ForSectionWrapper>
    ),
  },
  {
    documentType: supplierDocumentRequestTypes.VETERAN,
    Component: () => (
      <ForSectionWrapper>
        <ForSectionTitle>Veteran Certification</ForSectionTitle>
        <VeteranCertificateFilePiker
          maxWidth="100%"
          certificationUrl={veteranCertificationUrl}
        />
      </ForSectionWrapper>
    ),
  },
  {
    documentType: supplierDocumentRequestTypes.SA,
    Component: (props) => (
      <FormField
        additionalProps={props}
        validate={validateAgreement}
        name="subcontractorAgreement"
        Component={SubcontractorAgreement}
      />
    ),
  },
];

// rejectDuplicateDocumentsForOnboarding :: [DocRequestConfig] -> [DocRequestConfig]
const rejectDuplicateDocumentsForOnboarding = R.when(
  R.includes(supplierDocumentRequestTypes.W9),
  R.reject(
    R.compose(
      R.includes(R.__, [
        supplierDocumentRequestTypes.WBE,
        supplierDocumentRequestTypes.MBE,
        supplierDocumentRequestTypes.VETERAN,
      ]),
    ),
  ),
);

// getFormComponentsByDocumentType :: [String] -> {documentType: String, Component: ReactNode}
const getFormComponentsByDocumentType = (documentTypes) =>
  R.filter(
    R.compose(
      R.includes(
        R.__,
        rejectDuplicateDocumentsForOnboarding(documentTypes || []),
      ),
      R.prop('documentType'),
    ),
    documentsRequestFormConfig,
  );

function DocumentsRequestFormComp({
  form,
  valid,
  handleSubmit,
  documentTypes,
  ...props
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formComponents = getFormComponentsByDocumentType(documentTypes);
  const onSubmit = async (values) => {
    setIsSubmitting(true);

    if (valid) {
      form.pauseValidation();
    }

    await handleSubmit(values);
    if (valid) {
      form.restart();
    }
    setIsSubmitting(false);
  };
  return (
    <form onSubmit={onSubmit}>
      {formComponents.map(({ Component, documentType }) => (
        <Component key={documentType} {...{ ...props, form }} />
      ))}

      <ButtonWrapper>
        <ButtonS disabled={isSubmitting} loader={isSubmitting} size="small">
          Submit
        </ButtonS>
      </ButtonWrapper>
    </form>
  );
}

DocumentsRequestFormComp.propTypes = {
  valid: bool,
  handleSubmit: func.isRequired,
  documentTypes: arrayOf(string.isRequired).isRequired,
  form: shape({
    restart: func.isRequired,
    pauseValidation: func.isRequired,
  }),
};

const TaxClassificationFor1099 = [
  TaxClassification.SOLE_PROPRIETORSHIP,
  TaxClassification.PARTNERSHIP,
  TaxClassification.LLC_PARTNERSHIP,
];

// prepareRequestedDocumentsBeforeMutation :: FormData -> SubmitRequestedSupplierDocumentsInput
export const prepareRequestedDocumentsBeforeMutation = R.curry(
  (with1099, formData) =>
    R.compose(
      R.reject(isNilOrEmpty),
      R.applySpec({
        wcExp: R.prop('wcExp'),
        glExp: R.prop('glExp'),
        subcontractorAgreement: R.compose(
          R.pick(['title', 'contactName', 'isDownloaded']),
          R.propOr({}, 'subcontractorAgreement'),
        ),
        onboarding: R.compose(
          emptyStringAsNullForSupplierInput,
          R.over(
            R.lensProp('company'),
            R.compose(
              R.unless(
                R.compose(R.when(R.is(String), R.trim), R.prop('dba')),
                R.dissoc('dba'),
              ),
              R.omit(['mobile', 'phone', 'afterHoursPhone']),
            ),
          ),
          R.over(
            R.lensProp('tax'),
            R.compose(
              R.omit(['exempt']),
              R.when(
                R.always(with1099),
                assocBy(
                  'is1099',
                  R.compose(
                    R.includes(R.__, TaxClassificationFor1099),
                    R.prop('classification'),
                  ),
                ),
              ),
              R.dissoc('w9File'),
            ),
          ),
          prepareSupplierContactsAndServices,
          R.dissocPath(['mwbe', 'isMBE']),
          R.pick([
            'company',
            'service_24_7',
            'rates',
            'isVeteran',
            'tax',
            'mwbe',
            'remit',
          ]),
        ),
      }),
    )(formData),
);

// prepareSupplierOnboardingPhones :: PartialSupplier -> PartialSupplier
const prepareSupplierOnboardingPhones = R.compose(
  prepareSupplierPhonesToForm,
  assocByPath(
    ['company', 'afterHoursPhone'],
    findPhoneByType(supplierPhoneTypes.AFTER_HOURS),
  ),
);

// prepareOnboardingInitialValues :: PartialSupplier -> PartialSupplier
export const prepareOnboardingInitialValues = R.compose(
  R.map(R.when(R.is(Object), R.dissoc('__typename'))),
  R.over(R.lensProp('company'), R.omit(['address', 'addressTwo'])),
  R.assoc('type', supplierTypes.SUBCONTRACTOR),
  R.assocPath(['tax', 'w9File'], []),
  withoutSupplierServices,
  withSupplierServiceTypesIds,
  prepareSupplierOnboardingPhones,
  prepareSupplierEmailsToEditForm,
  assocBy('isVeteran', R.propOr(false, 'isVeteran')),
  assocBy(
    'mwbe',
    R.applySpec({
      isWBE: R.pathOr(false, ['mwbe', 'isWBE']),
      isMBE: R.compose(
        R.complement(isNilOrEmpty),
        R.path(['mwbe', 'minority']),
      ),
      minority: R.path(['mwbe', 'minority']),
    }),
  ),
);

// prepareOnboardingInitialValues :: PartialSupplier -> String
const prepareSupplierCompanyAddress = R.compose(
  R.join('/'),
  R.split('\n'),
  formatAddressString,
  R.pick(['address', 'addressTwo']),
  R.propOr({}, 'company'),
);

// getSupplierDocumentsFiles :: FormData -> [[String, File]]
const getSupplierDocumentsFiles = R.compose(
  R.toPairs,
  R.reject(isNilOrEmpty),
  R.applySpec({
    wcFileId: R.path(['wcFile', 0, 'upload']),
    glFileId: R.path(['glFile', 0, 'upload']),
    mbeCertificationFileId: R.path(['mbeCertificationFile', 0, 'upload']),
    wbeCertificationFileId: R.path(['wbeCertificationFile', 0, 'upload']),
    veteranCertificationFileId: R.path([
      'veteranCertificationFile',
      0,
      'upload',
    ]),
    w9FileId: R.path(['tax', 'w9File', 0, 'upload']),
  }),
);

export function DocumentsRequestForm({ supplierInfo, documentTypes }) {
  const navigate = useNavigate();
  const [submitRequestedSupplierDocuments] = useMutation(
    submitRequestedSupplierDocumentsMutation,
  );
  const logSessionToken = useLogTokenFromSession();

  const uploadFile = useUploadAttachment();

  const uploadDocumentFiles = async ([key, file]) => {
    const fileId = await uploadFile(file);
    return { [key]: fileId };
  };

  const handleSubmit = async (values) => {
    logSessionToken();

    const input = prepareRequestedDocumentsBeforeMutation(true, values);

    const files = getSupplierDocumentsFiles(values);

    const filesIds = await Promise.all(files.map(uploadDocumentFiles));

    await submitRequestedSupplierDocuments({
      variables: { input: { ...input, ...R.mergeAll(filesIds) } },
    });

    navigate(
      insertQueryParamsIntoURL(
        { type: VENDOR_INFO_TYPES.DOCUMENTS_SUBMITTED },
        routesNames.VENDOR_INFO,
      ),
    );
  };

  const initialValues = {
    wcFile: [],
    glFile: [],
    mbeCertificationFile: [],
    wbeCertificationFile: [],
    veteranCertificationFile: [],
    subcontractorAgreement: {
      title: '',
      contactName: '',
      date: new Date(),
      isScrolledToBottom: false,
      companyName: supplierInfo?.company?.name,
      companyAddress: prepareSupplierCompanyAddress(supplierInfo),
    },
    ...prepareOnboardingInitialValues(supplierInfo),
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      documentTypes={documentTypes}
      render={DocumentsRequestFormComp}
      supplierName={supplierInfo?.company?.name}
    />
  );
}

DocumentsRequestForm.propTypes = {
  supplierInfo: shape({
    supplierId: string,
    company: shape({ name: string }),
  }),
  documentTypes: arrayOf(string.isRequired).isRequired,
};
