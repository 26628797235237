import React from 'react';
import * as R from 'ramda';
import { PRINT_TYPES } from 'poly-constants';

import {
  projectStatusesUI,
  projectStatusesColors,
} from '../../modules/core/constants/projects.js';
import { TablePrintBtn } from '../../modules/core/tableToolbar/TablePrintPDFBtn.js';
import { showWarningModal } from '../../modules/tables/projectsTable/ProjectsTableToolBar.js';
import { useMasterProjectsQueryByFilter } from './useMasterProjectsQueryByFilter.js';

// getMasterProjectsByResult :: SearchRecurringProjectsResult -> [RecurringProject]
const getMasterProjectsByResult = R.pathOr(
  [],
  ['searchRecurringProjects', 'hits'],
);

export function MasterProjectsPrintPDFBtn() {
  const { result, status } = useMasterProjectsQueryByFilter();

  const printTableProps = {
    printType: PRINT_TYPES.PROJECTS,
    data: getMasterProjectsByResult(result),
    showWarningModal: showWarningModal(getMasterProjectsByResult, result),
    metaData: {
      title: status ? 'Master Projects' : 'Search Master Projects',
      ...(status
        ? {
            status: {
              text: projectStatusesUI[status],
              color: projectStatusesColors[status],
            },
          }
        : {}),
    },
  };

  return <TablePrintBtn {...printTableProps} />;
}
