import React, { useMemo } from 'react';
import { useRouterQuery } from 'poly-client-routing/src/hooks.js';
import { aminPurchaseOrderStatuses } from 'poly-constants/src/adminPurchaseOrder.js';
import { func } from 'prop-types';

import { useAdminPOSelectOptions } from '../../../../sidebars/ProjectSidebar/forms/form/components/AdminPOSelector.js';
import { BillingProfileConfigMultiSelect } from './BillingProfileConfigMultiSelect.js';
import {
  onMultipleSelectValueChange,
  prepareMultipleSelectValue,
} from '../utils.js';
import { multiSelectorValueProperTypes } from './common.js';

export function BillingProfilePOSelect({ value, onChange, ...props }) {
  const { clientId } = useRouterQuery(['clientId']);

  const query = {
    bool: {
      must: [
        { term: { clientId } },
        { term: { status: aminPurchaseOrderStatuses.ACTIVE } },
      ],
    },
  };

  const skipQuery = !clientId;

  const { options, loading } = useAdminPOSelectOptions({
    query,
    skipQuery,
  });

  const preparedValue = useMemo(
    () => prepareMultipleSelectValue(value, options),
    [value, options],
  );

  const selectProps = {
    ...props,
    loading,
    options,
    value: preparedValue,
    placeholder: 'Start typing PO',
    handleChange: onMultipleSelectValueChange(onChange),
  };

  return <BillingProfileConfigMultiSelect {...selectProps} />;
}

BillingProfilePOSelect.propTypes = {
  value: multiSelectorValueProperTypes,
  onChange: func.isRequired,
};
