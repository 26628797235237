import React from 'react';
import styled from 'styled-components';
import { func, string, shape } from 'prop-types';
import {
  DO_NOT_HIDE_NOTIFICATION_TIME,
  LinkButton,
  useNotificationContext,
} from 'poly-book-admin';
import {
  OldVersionDetectorMessage,
  useOldVersionDetector,
} from 'poly-client-utils/src/useOldVersionDetector.js';

const UPDATE_AVAILABLE_NOTIFICATION_ID = 'update-available-notification-id';

const OldVersionDetectorMessageS = styled(OldVersionDetectorMessage)`
  margin-bottom: 10px;
`;

const LinkButtonS = styled(LinkButton)`
  width: 100%;
`;

function NewVersionAvailableNotification({ payload: { version, reloadPage } }) {
  return (
    <>
      <OldVersionDetectorMessageS version={version} />
      <LinkButtonS onClick={reloadPage}>Reload</LinkButtonS>
    </>
  );
}

NewVersionAvailableNotification.propTypes = {
  payload: shape({
    version: string.isRequired,
    reloadPage: func.isRequired,
  }).isRequired,
};

export function OldVersionDetector({ beforePageReload }) {
  const { showWarningNotification } = useNotificationContext();

  const notifyUserAboutNewVersion = (latestVersion, reloadPage) => {
    const handlePageReload = () => {
      if (beforePageReload) {
        beforePageReload();
      }
      reloadPage();
    };

    showWarningNotification({
      id: UPDATE_AVAILABLE_NOTIFICATION_ID,
      component: NewVersionAvailableNotification,
      payload: { version: latestVersion, reloadPage: handlePageReload },
      time: DO_NOT_HIDE_NOTIFICATION_TIME,
      hideCloseBtn: true,
    });
  };

  useOldVersionDetector(notifyUserAboutNewVersion);

  return null;
}

OldVersionDetector.propTypes = {
  beforePageReload: func,
};
