import React from 'react';
import * as R from 'ramda';
import { shape, string, bool } from 'prop-types';
import { optionalAddressValidators } from 'poly-client-utils';
import { Input } from 'poly-book-admin';

import { AddressSection } from '../../../fields/index.js';
import { isAddressesEquals } from '../utils.js';
import { renderIfPayByBranch } from '../../../../supplier/BranchSupplier/formSections/financialSection.js';

// eslint-disable-next-line import/no-unused-modules
export function RemitAddressInput({ formData, ...props }) {
  const required = !!R.path(['remit', 'name'], formData);
  return (
    <AddressSection
      {...props}
      required={required}
      labelPrefix="Remit To"
      isClearable
    />
  );
}

RemitAddressInput.propTypes = {
  formData: shape({
    remit: shape({
      name: string,
    }),
  }),
};

// isRemitRequired :: FormData -> Boolean
const isRemitRequired = R.compose(
  R.complement(R.isEmpty),
  R.pathOr('', ['remit', 'address', 'formatted_address']),
);

// eslint-disable-next-line import/no-unused-modules
export function RemitNameInput({ formData, meta, value, ...props }) {
  const required = isRemitRequired(formData);
  return (
    <Input
      {...props}
      meta={meta}
      error={
        required && !value && meta.touched ? 'Remit to Name is required' : null
      }
      formData={formData}
      required={required}
      value={value}
    />
  );
}

RemitNameInput.propTypes = {
  formData: shape({
    remit: shape({
      address: shape({
        formatted_address: string,
      }),
    }),
  }),
  value: string,
  meta: shape({
    touched: bool,
  }),
};

export const supplierRemitFormSection = [
  {
    label: 'Remit To Info',
    order: 5,
    layout: { column: 2 },
    fields: [
      {
        label: 'Remit To Name',
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'remit.name',
          withFormData: true,
          Component: RemitNameInput,
        },
        renderIf: renderIfPayByBranch,
      },
      {
        order: 2,
        layout: { row: 2 },
        field: {
          withFormData: true,
          name: 'remit.address',
          Component: RemitAddressInput,
          isEqual: isAddressesEquals,
        },
        validators: optionalAddressValidators,
        renderIf: renderIfPayByBranch,
      },
    ],
  },
];
