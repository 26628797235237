import React from 'react';
import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';
import { string, func, shape } from 'prop-types';
import { useLazyQueryWithSubscription } from 'poly-client-utils/src/hooks/useLazyQueryWithSubscription.js';
import { useNotificationState } from 'poly-admin-ui/src/hooks/useNotificationState.js';
import { useModalContext } from 'poly-admin-ui/src/components/Modal/ModalProvider.js';
import { LinkButton } from 'poly-book-admin/src/LinkButton/index.js';
import { ClientInvoiceStatuses } from 'poly-constants';

// eslint-disable-next-line import/no-unused-modules
export const writeOffClientInvoiceMutation = gql`
  mutation writeOffClientInvoiceMutation($id: ID!) {
    writeOffClientInvoice(id: $id) {
      id
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const CLIENT_INVOICES_BY_PROJECT_QUERY = gql`
  query CLIENT_INVOICES_BY_PROJECT_QUERY($input: ClientInvoicesInput) {
    clientInvoices(input: $input) {
      hits {
        _id
        number
        status
      }
    }
  }
`;

// eslint-disable-next-line import/no-unused-modules
export const CLIENT_INVOICES_SUBSCRIPTION = gql`
  subscription CLIENT_INVOICES_SUBSCRIPTION($input: CollectionSearchParams!) {
    searchClientInvoiceChanged(input: $input) {
      id
      type
    }
  }
`;

const writeOffClientInvoiceProcessId = 'writeOffClientInvoiceProcessId';
const ClientInvoicesPath = ['clientInvoices', 'hits'];
const PaidClientInvoicesStatusArr = [
  ClientInvoiceStatuses.PARTIALLY_PAID,
  ClientInvoiceStatuses.PAID,
];

// getWriteOffInvoicesMessage :: ClientInvoicesResult -> String
const getWriteOffInvoicesMessage = R.compose(
  R.concat(R.__, ' off?'),
  R.concat('Are you sure you would like to Write Invoice'),
  R.ifElse(
    R.propSatisfies(R.gt(R.__, 1), 'length'),
    R.compose(R.concat('s '), R.join(', ')),
    R.compose(R.concat(' '), R.head),
  ),
  R.map(R.compose(R.concat('#'), R.toString, R.prop('number'))),
  R.reject(R.propEq('status', ClientInvoiceStatuses.VOIDED)),
  R.pathOr([], ClientInvoicesPath),
);

// checkIfOneOfInvoicesIsPaid :: ClientInvoicesResult -> Boolean
const checkIfOneOfInvoicesIsPaid = R.compose(
  R.complement(R.isNil),
  R.find(
    R.propSatisfies(
      (status) => R.any(R.equals(status), PaidClientInvoicesStatusArr),
      'status',
    ),
  ),
  R.pathOr([], ClientInvoicesPath),
);

export function WriteOffClientInvoiceButton({ _id, refetch, project }) {
  const { openConfirmSubmitFormModal } = useModalContext();
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();
  const [writeOffClientInvoice] = useMutation(writeOffClientInvoiceMutation);

  const projectId = project._id;
  const queryOptions = {
    variables: { input: { projectId } },
    fetchPolicy: 'network-only',
    skip: !projectId,
  };

  const { queryHandler } = useLazyQueryWithSubscription(
    CLIENT_INVOICES_BY_PROJECT_QUERY,
    CLIENT_INVOICES_SUBSCRIPTION,
    {
      queryOptions,
      subscriptionOptions: queryOptions,
    },
  );

  const onClick = async () => {
    const { data } = await queryHandler();

    const isOneOfInvoicesPaid = checkIfOneOfInvoicesIsPaid(data);

    if (isOneOfInvoicesPaid)
      return showErrorNotification(
        "One of the project's client invoices is already paid or partially paid",
      );

    const content = getWriteOffInvoicesMessage(data);

    return openConfirmSubmitFormModal({
      content,
      btnCaption: 'Yes',
      processId: writeOffClientInvoiceProcessId,
      onConfirm: (closeConfirmModal) => async () => {
        await writeOffClientInvoice({
          variables: { id: _id },
        });
        refetch();
        showSuccessNotification('Invoice successfully written off!');
        closeConfirmModal();
      },
    });
  };

  return <LinkButton onClick={onClick}>Write Off Invoice</LinkButton>;
}

WriteOffClientInvoiceButton.propTypes = {
  _id: string.isRequired,
  refetch: func.isRequired,
  project: shape({ _id: string.isRequired }).isRequired,
};
