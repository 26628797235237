import React from 'react';
import * as R from 'ramda';
import { InfoWindow } from 'google-maps-react';
import { arrayOf, shape, string } from 'prop-types';
import { RouterProvider } from 'poly-client-routing';
import { PhoneLink } from 'poly-admin-ui';

import { StoreProvider } from '../../providers.js';
import { TooltipWrapper, TooltipLine } from './styles.js';
import { SupplierLink } from '../../components/Links.js';
import { getSupplierTooltipInfo } from './googleMapUtils.js';

const tooltipTypes = {
  _id: string,
  name: string,
  phone: string,
  address: string,
};

function TooltipInfo({ _id, name, address, phone }) {
  return (
    <TooltipWrapper>
      {!!_id && (
        <StoreProvider>
          <RouterProvider>
            <SupplierLink {...{ _id, name }} target="_blank" />
          </RouterProvider>
        </StoreProvider>
      )}
      <TooltipLine>{address}</TooltipLine>
      <TooltipLine>
        Phone: <PhoneLink number={phone} />
      </TooltipLine>
    </TooltipWrapper>
  );
}

TooltipInfo.propTypes = tooltipTypes;

function GoogleMapTooltipComp({ tooltipInfo, ...props }) {
  return (
    <InfoWindow {...props}>
      <TooltipInfo {...tooltipInfo} />
    </InfoWindow>
  );
}

GoogleMapTooltipComp.propTypes = { tooltipInfo: shape(tooltipTypes) };

// getToolTipInfo :: (ActiveMarker, Suppliers ) => Object
const getToolTipInfo = (activeMarker, suppliers) =>
  R.pipe(
    R.find(R.propEq('_id', activeMarker.name)),
    getSupplierTooltipInfo,
  )(suppliers);

export function GoogleMapTooltip({ activeMarker, suppliers, ...restProps }) {
  const tooltipInfo = getToolTipInfo(activeMarker, suppliers);

  return (
    <GoogleMapTooltipComp
      {...restProps}
      marker={activeMarker}
      visible={!!activeMarker}
      tooltipInfo={tooltipInfo}
    />
  );
}

GoogleMapTooltip.displayName = 'GoogleMapTooltip';

GoogleMapTooltip.propTypes = {
  activeMarker: shape({ name: string }),
  suppliers: arrayOf(
    shape({
      company: shape({
        name: string,
      }),
      _id: string,
    }),
  ).isRequired,
};
