import * as R from 'ramda';
import React from 'react';
import { Text } from 'poly-book-admin';
import { shape } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';

import {
  PrintProjectFlexRow,
  PrintProjectArrayText,
  PrintProjectFlexColumn,
  PrintProjectBlockWithLabel,
} from '../components.js';
import { isLastElement } from '../helpers.js';

// getProjectTasks :: Project -> [Task]
const getProjectTasks = R.pathOr([], ['tasks', 'hits']);

// getProjectTaskNameUI :: Task -> String
const getProjectTaskNameUI = R.propOr(NOTHING_UI_STRING, 'description');

// getProjectTaskStatusUI :: Task -> String
const getProjectTaskStatusUI = R.ifElse(
  R.prop('complete'),
  R.always('Closed'),
  R.always('Opened'),
);

// getProjectTaskAssignedToUI :: Task -> String
const getProjectTaskAssignedToUI = R.pathOr(NOTHING_UI_STRING, [
  'manager',
  'fullName',
]);

// getProjectTaskCommentsUI :: Task -> [String]
const getProjectTaskCommentsUI = R.compose(
  R.when(R.isEmpty, R.always([NOTHING_UI_STRING])),
  R.map(R.prop('message')),
  R.pathOr([], ['documentUpdates', 'hits']),
);

export function PrintProjectProjectTasks({ project, config }) {
  const { isIncludeCompletedTaskComments } = config;

  const projectTasks = getProjectTasks(project);

  if (R.isEmpty(projectTasks)) {
    return null;
  }

  return (
    <PrintProjectFlexColumn withBottomBorder>
      <PrintProjectFlexRow>
        <Text size="16px" weight="bold">
          Project Tasks
        </Text>
      </PrintProjectFlexRow>
      {projectTasks.map((task, index) => (
        <PrintProjectFlexColumn key={`${task._id}-key`}>
          <PrintProjectFlexRow
            withBottomBorder={
              !isLastElement(index, projectTasks) &&
              !isIncludeCompletedTaskComments
            }
          >
            <PrintProjectBlockWithLabel
              label="Task Name"
              value={getProjectTaskNameUI(task)}
            />
            <PrintProjectBlockWithLabel
              label="Status"
              value={getProjectTaskStatusUI(task)}
            />
            <PrintProjectBlockWithLabel
              label="Assigned to"
              value={getProjectTaskAssignedToUI(task)}
            />
          </PrintProjectFlexRow>
          {isIncludeCompletedTaskComments && (
            <PrintProjectFlexRow
              withBottomBorder={!isLastElement(index, projectTasks)}
            >
              <PrintProjectBlockWithLabel
                label="Comments"
                Component={PrintProjectArrayText}
                arrayValue={getProjectTaskCommentsUI(task)}
                arrayKeyPart={project?.projectId}
              />
            </PrintProjectFlexRow>
          )}
        </PrintProjectFlexColumn>
      ))}
    </PrintProjectFlexColumn>
  );
}

PrintProjectProjectTasks.propTypes = {
  config: shape({}),
  project: shape({}),
};
