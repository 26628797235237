import React from 'react';
import * as R from 'ramda';
import { bool, number, string } from 'prop-types';
import { defaultTheme, Status, Loader, Icon } from 'poly-book-admin';
import { UserStatuses } from 'poly-constants';
import { formatCount, formatRawNumber, forceTitleCase } from 'poly-utils';
import {
  useOutSidebarContext,
  applyPathOrNothingUI,
  pathOrNothingUI,
  useHasUserAccessWithPermission,
} from 'poly-client-utils';
import {
  useMapConfigToTableProps,
  SidebarTabTableWrapper,
  useSearchTabLogic,
  SidebarTabToolbar,
  SidebarTabHeader,
  SearchInputComp,
  SidebarSubTabs,
  EmailLink,
  entities,
} from 'poly-admin-ui';
import { CREATE_USER_PERMISSION, UPDATE_USER_PERMISSION } from 'poly-security';

import {
  SectionLabel,
  ToolbarButton,
  DoubleTabsWrapper,
  CommonSidebarSubEntityTable,
} from '../../components/commonSidebarComponents.js';
import { addClientUserFormId } from '../forms/constants.js';
import { AddClientUsersForm } from '../forms/AddClientUserForm.js';
import { useOnCloseFormSidebar } from '../../useOnCloseFormSidebar.js';
import { useClientPortalUsers } from './useClientPortalUsersQuery.js';
import {
  commonClientUserSidebarProps,
  useOpenUpdateClientUser,
} from './useOpenUpdateClientUser.js';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

const PortalUserStatusColor = {
  [UserStatuses.ACTIVE]: active,
  [UserStatuses.INACTIVE]: inactive,
};

function AddClientPortalUserButton({
  clientId,
  isCard,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar(isCard);

  const closeForm = () => closeOutSidebar(addClientUserFormId);

  const onCancel = () => {
    closeForm();
    onClose(entities.CLIENT, clientId);
  };

  const onClick = () =>
    openOutSidebar({
      ...commonClientUserSidebarProps,
      id: addClientUserFormId,
      content: (
        <AddClientUsersForm
          clientId={clientId}
          onCancel={onCancel}
          closeForm={closeForm}
          isAssetScannerEnabled={isAssetScannerEnabled}
          isClientPortalEnabled={isClientPortalEnabled}
        />
      ),
    });

  return <ToolbarButton onClick={onClick}>Add New</ToolbarButton>;
}

AddClientPortalUserButton.propTypes = {
  isCard: bool,
  clientId: string.isRequired,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};

function ClientPortalUsersTabTitle({ status, clientId, searchTerm }) {
  const { count } = useClientPortalUsers(clientId, status, searchTerm);

  const title = `${forceTitleCase(status)} (${formatCount(count)})`;

  return <Status color={PortalUserStatusColor[status]} text={title} />;
}

ClientPortalUsersTabTitle.propTypes = {
  searchTerm: string,
  status: string.isRequired,
  clientId: string.isRequired,
};

function EditClientUserBtn({
  _id,
  clientId,
  isCard,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const onClick = useOpenUpdateClientUser(
    clientId,
    isCard,
    isAssetScannerEnabled,
    isClientPortalEnabled,
  );

  return <Icon name="edit" size={11} onClick={() => onClick(_id, false)} />;
}

EditClientUserBtn.propTypes = {
  _id: string.isRequired,
  clientId: string.isRequired,
  isCard: bool,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};

const portalUsersTableConfig = (hasUpdateUserPermission) => [
  ['Name', R.prop('fullName')],
  ['User Group', R.path(['userGroups', '0', 'userGroup', 'name'])],
  [
    'Phone',
    applyPathOrNothingUI(['profile', 'workPhoneNumber'], formatRawNumber),
  ],
  ['Ext', pathOrNothingUI(['profile', 'workPhoneNumberExt'])],
  [
    'Mobile',
    applyPathOrNothingUI(['profile', 'cellPhoneNumber'], formatRawNumber),
  ],
  [
    'Login Cell Phone Number',
    pathOrNothingUI(['profile', 'loginCellPhoneNumber']),
  ],
  ['Email', EmailLink],
  ...(hasUpdateUserPermission ? [['', EditClientUserBtn]] : []),
];

function ClientPortalUsersTab({
  status,
  clientId,
  searchTerm,
  isCard,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const { loading, users } = useClientPortalUsers(clientId, status, searchTerm);
  const hasUpdateUserPermission = useHasUserAccessWithPermission(
    UPDATE_USER_PERMISSION,
  );
  const tableProps = useMapConfigToTableProps(
    R.sortBy(R.prop('fullName')),
    portalUsersTableConfig(hasUpdateUserPermission),
    R.map(
      R.mergeRight({
        clientId,
        isCard,
        isAssetScannerEnabled,
        isClientPortalEnabled,
      }),
      users,
    ),
  );

  if (loading) return <Loader />;

  return (
    <CommonSidebarSubEntityTable
      {...tableProps}
      showEditBtn={hasUpdateUserPermission}
    />
  );
}

ClientPortalUsersTab.propTypes = {
  status: string.isRequired,
  clientId: string.isRequired,
  searchTerm: string.isRequired,
  isCard: bool.isRequired,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};

// getClientPortalUsersTab :: (String, ID, String, Boolean, Boolean, Boolean) -> [ReactComponent, String, ReactComponent]
const getClientPortalUsersTab = (
  status,
  clientId,
  searchTerm,
  isCard,
  isAssetScannerEnabled,
  isClientPortalEnabled,
) => [
  <ClientPortalUsersTabTitle {...{ status, clientId, searchTerm }} />,
  status,
  <ClientPortalUsersTab
    {...{
      status,
      clientId,
      searchTerm,
      isCard,
      isAssetScannerEnabled,
      isClientPortalEnabled,
    }}
  />,
];

export function ClientSidebarPortalUsersTab({
  isCard,
  clientId,
  doubleTabWidth,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const { searchTerm, ...inputProps } = useSearchTabLogic('users');
  const hasCreateUserPermission = useHasUserAccessWithPermission(
    CREATE_USER_PERMISSION,
  );

  return (
    <DoubleTabsWrapper width={doubleTabWidth} isCard={isCard}>
      <SidebarTabHeader justify>
        <SectionLabel>Client Portal Users</SectionLabel>
        {hasCreateUserPermission && (
          <AddClientPortalUserButton
            clientId={clientId}
            isCard={isCard}
            isAssetScannerEnabled={isAssetScannerEnabled}
            isClientPortalEnabled={isClientPortalEnabled}
          />
        )}
      </SidebarTabHeader>
      <SidebarTabToolbar>
        <SearchInputComp {...inputProps} />
      </SidebarTabToolbar>
      <SidebarTabTableWrapper offset={48}>
        <SidebarSubTabs
          tabs={[
            getClientPortalUsersTab(
              UserStatuses.ACTIVE,
              clientId,
              searchTerm,
              isCard,
              isAssetScannerEnabled,
              isClientPortalEnabled,
            ),
            getClientPortalUsersTab(
              UserStatuses.INACTIVE,
              clientId,
              searchTerm,
              isCard,
              isAssetScannerEnabled,
              isClientPortalEnabled,
            ),
          ]}
          defaultValue={UserStatuses.ACTIVE}
        />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapper>
  );
}

ClientSidebarPortalUsersTab.propTypes = {
  isCard: bool.isRequired,
  clientId: string.isRequired,
  doubleTabWidth: number.isRequired,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};
