import React from 'react';
import { shape, string } from 'prop-types';
import {
  useSidebarTableFormContext,
  CommonSidebarFormWrapper,
} from 'poly-admin-ui';

import { updateAddedSupplierFormId } from '../constants.js';
import { SendAddedSupplierUpdateForm } from './SendAddedSupplierUpdateForm.js';

export function UpdateAddedSupplierForm({ _id, project }) {
  const { formSetter } = useSidebarTableFormContext();

  const onCancel = () => formSetter(null);

  return (
    <CommonSidebarFormWrapper
      isTableRow
      onCancel={onCancel}
      formId={updateAddedSupplierFormId}
      title="Update"
    >
      <SendAddedSupplierUpdateForm
        supplierId={_id}
        onCancel={onCancel}
        projectId={project._id}
      />
    </CommonSidebarFormWrapper>
  );
}

UpdateAddedSupplierForm.propTypes = {
  _id: string.isRequired,
  project: shape({ _id: string.isRequired }).isRequired,
};
