import React from 'react';
import styled from 'styled-components';
import { CancelFormBtn as CancelFormBtnImp } from 'poly-admin-ui/src/components/Button/CancelFormBtn.js';
import { CancelModalFormBtn as CancelModalFormBtnImp } from 'poly-admin-ui/src/components/Button/CancelModalFormBtn.js';
import { ActionButtons as ActionButtonsImp } from 'poly-admin-ui/src/components/Button/ActionButtons.js';

import { confirmButtonNames } from '../modules/modals/confirmButtons/constants.js';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export function ActionButtons(props) {
  const confirmButtonName = confirmButtonNames.CONFIRM_CLOSE_EDIT_FORM;

  return <ActionButtonsImp {...props} confirmButtonName={confirmButtonName} />;
}

export function CancelFormBtn(props) {
  const confirmButtonName = confirmButtonNames.CONFIRM_CLOSE_FORM;

  return <CancelFormBtnImp {...props} confirmButtonName={confirmButtonName} />;
}

export function CancelModalFormBtn(props) {
  const confirmButtonName = confirmButtonNames.CONFIRM_CLOSE_MODAL;

  return (
    <CancelModalFormBtnImp {...props} confirmButtonName={confirmButtonName} />
  );
}
