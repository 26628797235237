import React from 'react';
import * as R from 'ramda';
import { optionalAddressValidators, ifNotEmpty } from 'poly-client-utils';
import { Input } from 'poly-book-admin';

import { halfWidth } from '../common.js';
import { SupplierFormCheckbox } from '../components/Checkbox.js';
import { AddressSection } from '../../../fields/AddressSection/index.js';
import { ChooseFileField } from '../../../fields/ChooseFileField.js';
import { SocialSecurityInput } from '../components/SocialSecurityInput.js';
import { TaxIDInput } from '../components/TaxIDInput.js';
import { TaxClassSelect } from '../components/TaxClassSelect.js';
import { isAddressesEquals, isSoleProprietorship } from '../utils.js';

const taxIdRegExp = /^(?:(\d{2}-\d{7})|(\d{3}-\d{2}-\d{4})|(\d{9}))$/;

function TaxAddress(props) {
  return (
    <AddressSection labelPrefix="Tax" required={false} isClearable {...props} />
  );
}

export const supplierTaxSection = (ConvertSupplierComponent) => [
  {
    label: 'Tax Info',
    order: 6,
    layout: { column: 2 },
    fields: [
      {
        label: 'Is 1099',
        order: 1,
        layout: { row: 1, width: '50%' },
        field: {
          name: 'tax.is1099',
          Component: SupplierFormCheckbox,
        },
      },
      {
        label: 'W9',
        order: 8,
        layout: { row: 1, width: '50%' },
        field: {
          name: 'tax.w9File',
          withFormData: true,
          Component: R.ifElse(
            R.path(['formData', 'tax', 'exempt']),
            R.always('Exempt'),
            ChooseFileField,
          ),
        },
      },
      {
        label: 'Tax Class',
        order: 2,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'tax.classification',
          withChangeFieldValue: true,
          Component: TaxClassSelect,
        },
      },
      {
        label: 'Tax ID NO',
        order: 3,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'tax.id',
          withFormData: true,
          Component: TaxIDInput,
        },
        validators: [
          [ifNotEmpty(R.test(taxIdRegExp)), 'Tax id is not correct'],
        ],
      },
      {
        label: 'Social Security #',
        order: 4,
        layout: { row: 3 },
        field: {
          name: 'tax.socialSecurityNumber',
          withFormData: true,
          Component: SocialSecurityInput,
        },
        validators: [
          [ifNotEmpty(R.propEq('length', 9)), 'Social security is not correct'],
        ],
        renderIf: isSoleProprietorship,
      },
      {
        order: 5,
        layout: { row: 4 },
        field: {
          name: 'tax.address',
          Component: TaxAddress,
          isEqual: isAddressesEquals,
        },
        validators: optionalAddressValidators,
      },
      {
        label: 'Tax Name',
        order: 6,
        layout: { row: 5, width: halfWidth },
        field: {
          name: 'tax.name',
          Component: Input,
        },
      },
      {
        label: 'Exempt/Utility',
        order: 7,
        layout: { row: 5, width: halfWidth },
        field: {
          name: 'tax.exempt',
          Component: SupplierFormCheckbox,
        },
      },
      {
        order: 8,
        layout: { row: 6 },
        field: {
          name: 'allowToConvert',
          Component: ConvertSupplierComponent,
        },
        renderIf: R.prop('allowToConvert'),
      },
    ],
  },
];
