import * as R from 'ramda';
import React, { useState } from 'react';
import { string, shape, object, oneOf, func, oneOfType } from 'prop-types';
import { usePersistentFormValue } from 'poly-client-utils/src/hooks/persists/usePersistentFormValue.js';
import { UPDATE_PERSIST_KEY_PREFIX, UpdateCollections } from 'poly-constants';
import { forceTitleCase } from 'poly-utils';
import {
  ToolBarBtnDivider,
  TextButton,
  LinkButton,
  Loader,
} from 'poly-book-admin';

import {
  SidebarTabTitle,
  SidebarTabHeader,
  SidebarTabToolbar,
  SidebarTabWrapper,
  SidebarTabTableWrapper,
  SidebarTabToolbarButtons,
} from '../sidebarTabComponents.js';
import { CopyMagicEmailButton } from './CopyMagicEmailButton.js';
import { PrintUpdatesTabButton } from './PrintUpdatesTabButton.js';
import { SearchInputComp } from '../../../components/TableSearchInput.js';
import { CommonSidebarFormWrapper } from '../../CommonSidebarFormWrapper.js';
import { UpdatesTable } from '../../../modules/tables/updatesTable/UpdatesTable.js';
import { AddUpdateForm } from '../../../modules/forms/addUpdateForm/AddUpdateForm.js';
import { useUpdatesTabLogic } from './useUpdatesTabLogic.js';

const addUpdatesFormId = 'add_updates_form_id';

export function SidebarUpdatesTab({
  entity,
  collection,
  PrintLayout,
  updatesQuery,
  tabTitlePrefix,
  LinkToParentClone,
  AddUpdateFormComponent,
}) {
  const titlePrefix = tabTitlePrefix || forceTitleCase(entity.preview);

  const titleByEntity = `${titlePrefix} Updates`;

  const [showAddUpdate, setShowAddUpdate] = useState(false);

  const {
    loading,
    updates,
    showAudit,
    printInfo,
    inputProps,
    toggleAudit,
    updateCacheParams,
  } = useUpdatesTabLogic({
    entity,
    collection,
    updatesQuery,
    LinkToParentClone,
  });

  const { cleanupRetainedValue } = usePersistentFormValue(
    `${UPDATE_PERSIST_KEY_PREFIX}${entity._id}`,
  );

  const onCloseAddUpdate = () => {
    cleanupRetainedValue();
    setShowAddUpdate(false);
  };

  return (
    <SidebarTabWrapper>
      <SidebarTabHeader>
        <SidebarTabTitle>{titleByEntity}</SidebarTabTitle>
        <LinkButton onClick={() => setShowAddUpdate(true)}>
          Add Update
        </LinkButton>
      </SidebarTabHeader>
      {showAddUpdate && (
        <CommonSidebarFormWrapper
          title="Add Update"
          formId={addUpdatesFormId}
          submitCaption="Add Update"
          onCancel={onCloseAddUpdate}
        >
          <AddUpdateFormComponent
            entity={entity}
            collection={collection}
            formId={addUpdatesFormId}
            onCancel={onCloseAddUpdate}
            successMessage="Update was created"
            updateCacheParams={updateCacheParams}
          />
        </CommonSidebarFormWrapper>
      )}
      <SidebarTabToolbar>
        <SearchInputComp {...inputProps} />
        <SidebarTabToolbarButtons>
          <CopyMagicEmailButton
            {...{ entity, collection, withClientReport: true }}
          />
          <ToolBarBtnDivider />
          <TextButton
            active={showAudit}
            onClick={() => toggleAudit(!showAudit)}
          >
            Audit
          </TextButton>
          <ToolBarBtnDivider />
          <PrintUpdatesTabButton
            {...{ updates, PrintLayout, entity, printInfo }}
          />
        </SidebarTabToolbarButtons>
      </SidebarTabToolbar>
      <SidebarTabTableWrapper offset={96}>
        {loading ? <Loader /> : <UpdatesTable updates={updates} />}
      </SidebarTabTableWrapper>
    </SidebarTabWrapper>
  );
}

SidebarUpdatesTab.propTypes = {
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
    preview: string.isRequired,
    humanReadableId: string.isRequired,
  }),
  PrintLayout: func.isRequired,
  AddUpdateFormComponent: oneOfType([func, object]),
  // eslint-disable-next-line react/forbid-prop-types
  LinkToParentClone: object,
  // eslint-disable-next-line react/forbid-prop-types
  updatesQuery: object.isRequired,
  collection: oneOf(R.values(UpdateCollections)).isRequired,
  tabTitlePrefix: string,
};

SidebarUpdatesTab.defaultProps = {
  AddUpdateFormComponent: AddUpdateForm,
};
