import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'poly-book-admin';

const CheckboxS = styled(Checkbox)`
  cursor: default;

  input {
    cursor: default;
  }
`;

export function WeekServiceTicketCheckbox() {
  return (
    <CheckboxS
      value
      name="weeklyServiceTicketApproveCheckbox"
      onChange={() => null}
    />
  );
}
