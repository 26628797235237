import React from 'react';
import styled from 'styled-components';
import { Text } from 'poly-book-admin';
import { arrayOf, shape } from 'prop-types';

import { isProjectWithClientInvoice } from './common.js';

const TextS = styled(Text)`
  margin-right: 5px;
  text-align: end;
`;

export function ProjectAlreadyInvoicedText({ formData }) {
  if (!isProjectWithClientInvoice(formData)) {
    return null;
  }

  return (
    <TextS size="12px" lineHeight="26px" color={['secondaryMid']}>
      Note: This project has already been invoiced to the client!
    </TextS>
  );
}

ProjectAlreadyInvoicedText.propTypes = {
  formData: shape({
    project: shape({
      clientInvoices: arrayOf(shape({})),
    }),
  }),
};
