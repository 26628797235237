import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { number, object, string } from 'prop-types';
import { TitleWithoutPip, TitleWithPip } from 'poly-admin-ui';
import { useReactiveQuery } from 'poly-client-utils';
import { useParams } from 'poly-client-routing';

import {
  projectStatusesUI,
  projectUIStatuses,
  projectStatusesColors,
} from '../../core/constants/projects.js';
import {
  PROJECTS_BY_SEARCH_SUB,
  RECURRING_PROJECTS_BY_SEARCH_SUB,
} from '../../core/hooks/projects/subscriptions.js';
import {
  PROJECTS_BY_SEARCH,
  MY_RECURRING_PROJECTS,
  RECURRING_PROJECTS_BY_SEARCH,
  SUPPLIER_RECURRING_PROJECTS_BY_SEARCH,
} from '../../core/hooks/projects/queries.js';
import { useSupplierProjectsQuery } from './SupplierProjectsTab.js';

const { ALL, ACTIVE } = projectUIStatuses;

// isStatusAllOrActive :: String -> Boolean
const isStatusAllOrActive = (status) => R.find(R.equals(status), [ALL, ACTIVE]);
function TabTitleBase({ status, count, text, ...props }) {
  if (!status || isStatusAllOrActive(status)) {
    return (
      <TitleWithoutPip
        text={text || projectStatusesUI[status]}
        {...props}
        count={count}
      />
    );
  }

  return (
    <TitleWithPip
      count={count}
      text={projectStatusesUI[status]}
      color={projectStatusesColors[status]}
    />
  );
}

TabTitleBase.propTypes = {
  status: string,
  count: number.isRequired,
  text: string,
};

function getProjectsTabTitle(
  gqlSearchQuery,
  gqlSearchChangedQuery,
  additionalVars = {},
  projectsPath = ['searchProjects'],
) {
  function TabTitle({ query, status, ...props }) {
    const searchText = useSelector((state) => state.searchText);
    const { data } = useReactiveQuery(gqlSearchQuery, gqlSearchChangedQuery, {
      queryOptions: {
        variables: {
          ...additionalVars,
          searchInput: { query, searchTerm: searchText, size: 0 },
        },
      },
      subscriptionOptions: { variables: { searchInput: { query } } },
    });

    const count = R.pathOr(0, [...projectsPath, 'total'], data);

    return <TabTitleBase status={status} count={count} {...props} />;
  }
  TabTitle.propTypes = {
    status: string,
    // eslint-disable-next-line react/prop-types,react/forbid-prop-types
    query: object,
  };
  return TabTitle;
}

export const MasterProjectsTabTitle = getProjectsTabTitle(
  RECURRING_PROJECTS_BY_SEARCH,
  RECURRING_PROJECTS_BY_SEARCH_SUB,
  {},
  ['searchRecurringProjects'],
);

export const ProjectsTabTitle = getProjectsTabTitle(
  PROJECTS_BY_SEARCH,
  PROJECTS_BY_SEARCH_SUB,
);

export function MyProjectsTabTitle({ status, count, ...props }) {
  return <TabTitleBase status={status} count={count} {...props} />;
}

MyProjectsTabTitle.propTypes = {
  count: number.isRequired,
  status: string.isRequired,
};

export const MyMasterProjectsTabTitle = getProjectsTabTitle(
  MY_RECURRING_PROJECTS,
  RECURRING_PROJECTS_BY_SEARCH_SUB,
  {},
  ['me', 'searchManagerRecurringProjects'],
);

export function SupplierPageProjectsTabTitle({ query, ...props }) {
  const { total } = useSupplierProjectsQuery(query);
  return <TabTitleBase count={total} {...props} />;
}

SupplierPageProjectsTabTitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object,
};

export function SupplierPageRecurringProjectsTabTitle(props) {
  const { supplierId } = useParams();

  const TabTitle = getProjectsTabTitle(
    SUPPLIER_RECURRING_PROJECTS_BY_SEARCH,
    RECURRING_PROJECTS_BY_SEARCH_SUB,
    { documentId: supplierId },
    ['supplier', 'searchRecurringProjects'],
  );

  return <TabTitle {...props} />;
}
