import React, { useState } from 'react';
import * as R from 'ramda';
import { Select } from 'poly-book-admin';
import { shape, string } from 'prop-types';

import { toAccountsOptions } from '../../components/AccountsSelect.js';
import { useClosingAccountsQuery } from './useClosingAccountsQuery.js';
import { findAccountByCode, getAccountValues } from './helpers.js';

const formatOptions = toAccountsOptions(getAccountValues);

export function ClosingAccountsSelector({ value, ...props }) {
  const [searchText, setSearchText] = useState('');

  const { closingAccounts, retainedEarningsAccount, loading } =
    useClosingAccountsQuery(setSearchText);

  const accountCode = R.prop('code', value);

  const selectedAccount = findAccountByCode(accountCode, [
    ...closingAccounts,
    retainedEarningsAccount,
  ]);

  let accounts = selectedAccount ? [selectedAccount] : [];

  // show all options only if typing to speed up initial page rendering
  if (searchText) {
    accounts = closingAccounts;
  }

  const options = formatOptions(accounts);

  const selectProps = {
    value,
    options,
    loading,
    searchText,
    setSearchText,
    name: 'AccountSelect',
    onInputChange: setSearchText,
    ...props,
  };

  return <Select {...selectProps} />;
}

ClosingAccountsSelector.propTypes = {
  value: shape({ _id: string.isRequired, code: string.isRequired }),
};
