import {
  func,
  node,
  bool,
  object,
  string,
  number,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import React, { useState, useMemo, useCallback } from 'react';
import { Select } from 'poly-book-admin';
import { debounce } from 'poly-utils';
import {
  DEBOUNCE_USER_INPUT_THRESHOLD,
  entityToOptionByLabelPath,
} from 'poly-client-utils';

// excludeOptions :: Array -> Func -> Array
const excludeOptions = (exclude) =>
  R.compose(R.reject(R.compose(exclude, R.head)), R.defaultTo([]));

// defaultHitToOption :: Object -> Option
const defaultHitToOption = entityToOptionByLabelPath(['name']);

// prepareOptions :: PrepareOptionsParams -> QueryResult -> [Option]
// PrepareOptionsParams :: {
//   exclude: Func
//   hitToOption: Func
//   activeOption: Option
//   optionsEndpointName: String
// }
const prepareOptions = ({
  exclude,
  hitToOption,
  activeOption,
  optionsEndpointName,
}) =>
  R.compose(
    excludeOptions(exclude),
    R.when(() => !!activeOption, R.prepend(activeOption)),
    R.map(hitToOption),
    R.propOr([], 'hits'),
    R.prop(optionsEndpointName),
  );

// getActiveOption :: ({ hitToOption: Func, valueEndpointName: String }) -> QueryData -> Option
const getActiveOption = ({ hitToOption, valueEndpointName }) =>
  R.compose(
    R.unless(R.isNil, hitToOption),
    R.path(['data', valueEndpointName]),
  );

export function AsyncSearchSelect({
  sort,
  size,
  value,
  searchQuery,
  gqlValueQuery,
  exclude = R.F,
  componentSize,
  gqlOptionsQuery,
  valueEndpointName,
  optionsEndpointName,
  defaultValue = null,
  withoutSkip = false,
  componentProps = {},
  hitToOption = defaultHitToOption,
  ...props
}) {
  const [searchText, setSearchText] = useState('');

  const optionLinks = useQuery(gqlValueQuery, {
    variables: { id: value || defaultValue },
    skip: !value && !defaultValue,
  });

  const activeOption = getActiveOption({ hitToOption, valueEndpointName })(
    optionLinks,
  );

  const { data, loading } = useQuery(gqlOptionsQuery, {
    variables: {
      searchInput: {
        searchTerm: searchText,
        query: searchQuery,
        size,
        sort,
      },
    },
    skip: !searchText && !withoutSkip,
  });

  const options = useMemo(
    () =>
      prepareOptions({
        exclude,
        hitToOption,
        activeOption,
        optionsEndpointName,
      })(data),
    [data, activeOption],
  );

  const onSearchDebounced = useCallback(
    debounce(DEBOUNCE_USER_INPUT_THRESHOLD)(setSearchText),
    [],
  );

  const onInputChange = (inputText) => onSearchDebounced(inputText.trim());

  return (
    <Select
      {...componentProps}
      {...props}
      value={value}
      options={options}
      loading={loading}
      size={componentSize}
      onInputChange={onInputChange}
      name={`AsyncSearchSelect_${optionsEndpointName}`}
    />
  );
}

AsyncSearchSelect.displayName = 'AsyncSelect';

AsyncSearchSelect.propTypes = {
  withoutSkip: bool,
  exclude: func,
  optionsEndpointName: string,
  valueEndpointName: string,
  Component: oneOfType([node, func, object]),
  hitToOption: func,
  value: oneOfType([string, object]),
  componentSize: string,
  size: number,
  sort: oneOfType([string, object]),
  /* eslint-disable react/forbid-prop-types */
  gqlOptionsQuery: object,
  gqlValueQuery: object,
  searchQuery: object,
  defaultValue: object,
  componentProps: object,
};
