import * as R from 'ramda';
import { propertyStatuses } from 'poly-constants/src/properties.js';
import { pathOrNothingUI } from 'poly-client-utils';
import { ProjectType } from 'poly-constants';

import { isClientInactive } from '../ClientSidebar/helpers.js';

// isPropertyFieldActive :: [String] -> Client -> Boolean
export const isPropertyFieldActive = (fieldPath) =>
  R.pathOr(false, R.concat(['configs', 'propertyFields'], fieldPath));

// isDistrictsEnabled :: Client -> Boolean
export const isDistrictsEnabled = R.pathOr(false, [
  'configs',
  'enableDistricts',
]);

// isDistrictFacilityManager :: Client -> Boolean
export const isDistrictFacilityManager = isPropertyFieldActive([
  'buildingInfo',
  'districtFacilityManager',
]);

// showPropertyInfoRow :: Client -> Boolean
export const showPropertyInfoRow = R.anyPass([
  isPropertyFieldActive(['storeNumber']),
  isPropertyFieldActive(['division']),
  isDistrictsEnabled,
]);

// getOneOfOptionsIfExist :: [Function] -> User -> String
const getOneOfOptionsIfExist = (options) =>
  R.compose(R.defaultTo({}), R.head, R.reject(R.isNil), R.juxt(options));

// formatManagerToUser :: String -> Property -> User
export const formatManagerToUser = (managerField) =>
  R.compose(
    R.when(
      R.is(Object),
      R.compose(
        R.mergeAll,
        R.juxt([
          R.compose(
            R.when(R.complement(R.isEmpty), R.objOf('email')),
            getOneOfOptionsIfExist([
              R.prop('email'),
              R.path(['emails', '0', 'address']),
              R.path(['profile', 'personalEmail']),
            ]),
          ),
          R.compose(
            R.when(
              R.complement(R.isEmpty),
              R.compose(R.objOf('profile'), R.objOf('cellPhoneNumber')),
            ),
            getOneOfOptionsIfExist([
              R.prop('phone'),
              R.path(['profile', 'personalPhone']),
            ]),
          ),
          R.pick(['_id', 'fullName']),
        ]),
      ),
    ),
    pathOrNothingUI([managerField]),
  );

// getProjectTypeByProperty :: Property -> String
export const getProjectTypeByProperty = R.pathOr(ProjectType.WORK_ORDER, [
  'client',
  'configs',
  'defaultProjectType',
]);

// isPropertyInactive :: Property -> Boolean
export const isPropertyInactive = R.propEq('status', propertyStatuses.INACTIVE);

// isInactivePropertyOrClient :: Property -> Boolean
export const isInactivePropertyOrClient = R.either(
  isPropertyInactive,
  R.compose(isClientInactive, R.prop('client')),
);
