import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {
  string,
  shape,
  arrayOf,
  number,
  oneOfType,
  instanceOf,
} from 'prop-types';
import { Table, getThemeColor } from 'poly-book-admin';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { formatDateOr } from 'poly-client-utils';
import { applyProp, formatManualJournalNumber } from 'poly-utils';

import { prepareJournalDataForTable } from './journalEntryUtils.js';

const JournalEntryReportTableComp = styled(Table)`
  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2) {
    width: 120px;
  }

  tr {
    border: none !important;
  }

  tr:last-child > td:nth-child(5),
  tr:last-child > td:nth-child(6) {
    border-top: 1px solid ${getThemeColor(['black'])};
  }
`;

const journalEntryReportTableConfig = [
  ['JE #', R.compose(formatManualJournalNumber, R.prop('transaction_number'))],
  ['Date', applyProp(formatDateOr(null))('date')],
  ['GL Code', R.prop('accountCode')],
  ['Description', R.prop('description')],
  ['Debit', R.prop('debitAmount')],
  ['Credit', R.prop('creditAmount')],
];

export function JournalEntryReportTablePDF({ data }) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    prepareJournalDataForTable,
    journalEntryReportTableConfig,
    data,
  );
  return (
    <JournalEntryReportTableComp {...{ rows, headers, columns, sortQueries }} />
  );
}

JournalEntryReportTablePDF.propTypes = {
  data: shape({
    _id: string,
    type: string,
    date: oneOfType([instanceOf(Date), string]),
    description: string,
    transactionNumber: number,
    lines: arrayOf(
      shape({
        accountCode: shape({ label: string }),
        netAmount: number,
      }),
    ).isRequired,
  }).isRequired,
};
