import * as R from 'ramda';
import { insertParamIntoURL } from 'poly-utils';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'poly-client-routing';

import { CREATE_SUPPLIER_MUTATION } from '../../../../hocs/suppliers/mutations.js';
import { useNotificationState } from '../../../../hooks/useNotificationState.js';

export const useCreateSupplier = (url) => {
  const [mutate] = useMutation(CREATE_SUPPLIER_MUTATION);
  const { showSuccessNotification } = useNotificationState();
  const navigate = useNavigate();

  return async (input) => {
    const mutationResult = await mutate({
      variables: {
        input,
      },
    });

    const supplierId = R.path(
      ['data', 'createSupplier', 'supplier', '_id'],
      mutationResult,
    );
    showSuccessNotification('Supplier was successfully created');
    const redirectUrl = insertParamIntoURL('supplierId', url, supplierId);
    navigate(redirectUrl);
  };
};
