import React from 'react';
import { MainHeader, PageHeaderContainer } from 'poly-book-admin';
import { BackBtn, SectionWithBackBtn } from 'poly-admin-ui';

import { routesNames } from '../../../routes/index.js';
import { editSettingsFormId } from './constants.js';
import { ActionButtons } from '../../../components/Buttons.js';

export function UserSettingsPageHeader() {
  return (
    <PageHeaderContainer>
      <SectionWithBackBtn>
        <BackBtn fallbackLink={routesNames.HOME} />
        <MainHeader>Settings</MainHeader>
      </SectionWithBackBtn>
      <ActionButtons
        formId={editSettingsFormId}
        processName={editSettingsFormId}
      />
    </PageHeaderContainer>
  );
}
