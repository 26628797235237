import React from 'react';
import * as R from 'ramda';
import { shape, string, func } from 'prop-types';
import { UserEmployeeInfoStatus, FinancialEntityTypes } from 'poly-constants';
import { Input } from 'poly-book-admin';
import {
  getAdminUsersByEmploymentStatusQuery,
  ClientSelect,
  UserSelect,
  SupplierSelect,
} from 'poly-admin-ui';

import { onKeyDownToPreventFormSubmit } from '../../utils/form.js';

const commonSelectProps = {
  includeAllOption: false,
  selectedOptionAtOnChange: true,
};

function ClientNameSelect(props) {
  return <ClientSelect {...props} {...commonSelectProps} />;
}

function SupplierNameSelect(props) {
  return <SupplierSelect {...props} {...commonSelectProps} />;
}

function EmployeeNameSelect(props) {
  return (
    <UserSelect
      {...props}
      {...commonSelectProps}
      query={getAdminUsersByEmploymentStatusQuery(
        UserEmployeeInfoStatus.ACTIVE,
      )}
    />
  );
}

const payeeComponentsMap = {
  [FinancialEntityTypes.CLIENTS]: ClientNameSelect,
  [FinancialEntityTypes.SUPPLIERS]: SupplierNameSelect,
  [FinancialEntityTypes.EMPLOYEES]: EmployeeNameSelect,
  [FinancialEntityTypes.OTHERS]: (props) => (
    <Input {...props} showCharactersLeft charactersLimit={200} />
  ),
};

export function BankDepositsPayeeNameComponent({
  formData,
  onChange,
  changeFieldValue,
  ...props
}) {
  const ComponentByType = payeeComponentsMap[formData.type];

  const onChangeModified = (e, option) => {
    const payeeName = option
      ? option.label
      : R.pathOr('', ['target', 'value'], e);
    changeFieldValue('payeeName', payeeName);
    onChange(e);
  };

  return (
    <ComponentByType
      {...props}
      onChange={onChangeModified}
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

BankDepositsPayeeNameComponent.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
  formData: shape({ type: string }).isRequired,
};
