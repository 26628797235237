import React from 'react';
import { string } from 'prop-types';
import { gql } from '@apollo/client';
import { commonUpdateFields, entities, SidebarUpdatesTab } from 'poly-admin-ui';
import { UpdateCollections } from 'poly-constants';

import { PrintLayoutProvider } from '../../../providers.js';

const PO_UPDATES = gql`
  query PO_UPDATES($id: ID!, $documentUpdatesInput: DocumentUpdatesInput!) {
    adminPurchaseOrder(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export function PurchaseOrderSidebarUpdatesTab({
  _id,
  displayName,
  tabTitlePrefix,
}) {
  const tabProps = {
    tabTitlePrefix,
    entity: {
      _id,
      preview: 'Purchase Order',
      name: entities.ADMIN_PURCHASE_ORDER,
      humanReadableId: displayName,
    },
    updatesQuery: PO_UPDATES,
    PrintLayout: PrintLayoutProvider,
    collection: UpdateCollections.adminPurchaseOrders,
  };

  return <SidebarUpdatesTab {...tabProps} />;
}

PurchaseOrderSidebarUpdatesTab.propTypes = {
  _id: string,
  displayName: string,
  tabTitlePrefix: string,
};
