import React from 'react';
import styled from 'styled-components';
import { arrayOf, oneOfType, element, string } from 'prop-types';
import { AppBar } from 'poly-book-admin';

const AppBarS = styled(AppBar)`
  @media (max-width: 1279px) {
    height: 120px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  padding: 0 25px;
  flex-wrap: wrap;
`;

export function AppBarWrapper({ children, id }) {
  return (
    <AppBarS id={id}>
      <ContentWrapper>{children}</ContentWrapper>
    </AppBarS>
  );
}

AppBarWrapper.propTypes = {
  id: string.isRequired,
  children: oneOfType([arrayOf(element), element]).isRequired,
};

export const AppBarPart = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`;
