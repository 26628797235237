import React from 'react';
import * as R from 'ramda';
import arrayMutators from 'final-form-arrays';
import { usePristineSubscribe } from 'poly-client-routing';
import { FormPage } from 'poly-book-admin';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from 'poly-admin-ui';

import { enterSupplierInvoiceSections } from './sections.js';
import { enterSupplierInvoiceFormId } from '../../constants.js';
import { prepareSupplierInvoiceToMutation } from './enterSupplierInvoiceUtils.js';
import { withCreateSupplierInvoice } from '../withCreateSupplierInvoice.js';

import {
  supplierInvoiceFormCommonConfig,
  editSupplierInvoiceDefaultValues,
} from './constants.js';

export const EnterSupplierInvoiceForm = withCreateSupplierInvoice(
  ({ createSupplierInvoice, formSubscription }) => {
    const id = enterSupplierInvoiceFormId;
    const { showSuccessNotification } = useNotificationState();

    const onMutationSuccess = () => {
      showSuccessNotification('Supplier Invoice Successfully Created');
    };

    const onSubmitHandler = R.compose(
      createSupplierInvoice(onMutationSuccess),
      prepareSupplierInvoiceToMutation,
    );

    const { onSubmit } = useOnSubmitSetStopSubmitting(id, onSubmitHandler);

    const pristineSubscribeProps = usePristineSubscribe();

    const formProps = {
      id,
      onSubmit,
      formSubscription,
      initialValuesEqual: R.T,
      commonFormConfig: supplierInvoiceFormCommonConfig,
      mutators: { ...arrayMutators },
      ...supplierInvoiceFormCommonConfig,
      ...pristineSubscribeProps,
      sections: enterSupplierInvoiceSections,
      initialValues: editSupplierInvoiceDefaultValues(),
    };

    return <FormPage {...formProps} />;
  },
);

EnterSupplierInvoiceForm.displayName = 'EnterSupplierInvoiceForm';
