import * as R from 'ramda';
import { isNilOrEmpty } from 'poly-utils';
import { gql, useLazyQuery } from '@apollo/client';

const USER_ACCESS_ROLE_QUERY = gql`
  query USER_ACCESS_ROLE_QUERY($userId: ID!) {
    user(id: $userId) {
      _id
      userGroups {
        userGroup {
          _id
        }
      }
    }
  }
`;

// checkIsContactUser :: UserQueryResult -> Boolean
const checkIsContactUser = R.both(
  R.propSatisfies(R.complement(R.isEmpty), 'user'),
  R.pathSatisfies(isNilOrEmpty, ['user', 'userGroups']),
);

export const useUserAccessRoleQuery = () => {
  const [queryHandler] = useLazyQuery(USER_ACCESS_ROLE_QUERY);

  return async (userId) => {
    const { data } = await queryHandler({
      variables: { userId },
      fetchPolicy: 'network-only',
    });

    const isContactUser = checkIsContactUser(data);

    return !!userId && isContactUser;
  };
};
