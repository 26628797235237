import * as R from 'ramda';
import React, { useState, useMemo } from 'react';
import { gql } from '@apollo/client';
import { Loader } from 'poly-book-admin';
import { useSelector } from 'react-redux';
import { InvoicesStatuses } from 'poly-constants';
import { supplierHaveAccountOrServiceEmail } from 'poly-utils';
import {
  useTableInfiniteScrollQuery,
  highlightMatchesInObjectCurried,
} from 'poly-client-utils';
import { useTableSorting } from 'poly-admin-ui';

import { OSIToolBar } from './components/OSIToolBar.js';
import { OSIPageHeader } from './OSIPageHeader.js';
import { OSIPageTable, tableConfig } from './OSIPageTable.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { getOSISearchInputQuery } from './helpers.js';

export const OPEN_SUPPLIER_INVOICES_QUERY = gql`
  query OPEN_SUPPLIER_INVOICES_QUERY($input: CollectionSearchParams) {
    openSupplierInvoices(input: $input) {
      hits {
        _id
        status
        reminder {
          requestNumber
          nextRequestDate
        }
        project {
          _id
          type
          projectId
          description
          workCompletionDate
        }
        client {
          _id
          name
        }
        supplierInvoiceTask {
          _id
          manager {
            _id
            profile {
              fullName
            }
          }
        }
        supplier {
          _id
          company {
            name
            emails {
              email
              type
            }
          }
        }
      }
    }
  }
`;

const InvoiceStatusFormats = {
  [InvoicesStatuses.REQUESTED]: 'Requested',
  [InvoicesStatuses.RECEIVED]: 'Received',
  [InvoicesStatuses.REJECTED]: 'Rejected',
  [InvoicesStatuses.MANUAL_FOLLOW_UP]: 'Manual Follow Up',
};

// getTableInvoiceStatus :: Project -> String
const getTableInvoiceStatus = R.compose(
  R.prop(R.__, InvoiceStatusFormats),
  R.prop('status'),
);

const searchTextPaths = [
  ['projectId'],
  ['description'],
  ['supplierName'],
  ['clientName'],
  ['managerName'],
];

// prepareProjectsToDisplay :: String -> { searchProjects: { hits: [Projects] } }
// -> [OSIPreparedRow]
// OSIPreparedRow = {
//    _id: ID
//    id: ID
//    projectId: String
//    type: String
//    workCompletionDate: Date
//    description: String
//    supplierId: ID
//    supplierName: String
//    clientName: String
//    managerName: String
//    invoiceStatus: String
//    supplierHaveEmail: Boolean
// }
const prepareProjectsToDisplay = (searchText) =>
  R.compose(
    R.applySpec({
      projects: R.identity,
      highlightedProjects: R.map(
        highlightMatchesInObjectCurried(searchTextPaths, searchText),
      ),
    }),
    R.map(
      R.applySpec({
        _id: R.prop('_id'),
        projectId: R.path(['project', 'projectId']),
        projectDBId: R.path(['project', '_id']),
        type: R.path(['project', 'type']),
        workCompletionDate: R.path(['project', 'workCompletionDate']),
        description: R.path(['project', 'description']),
        supplierId: R.path(['supplier', '_id']),
        supplierName: R.path(['supplier', 'company', 'name']),
        clientName: R.path(['client', 'name']),
        managerName: R.path([
          'supplierInvoiceTask',
          'manager',
          'profile',
          'fullName',
        ]),
        invoiceStatus: getTableInvoiceStatus,
        reminder: R.prop('reminder'),
        supplierHaveEmail: R.compose(
          supplierHaveAccountOrServiceEmail,
          R.prop('supplier'),
        ),
        urlParam: R.path(['project', 'projectId']),
      }),
    ),
    R.pathOr([], ['openSupplierInvoices', 'hits']),
  );

const CURRENT_PAGE_SIZE = 500;

export function OpenSupplierInvoicesPage() {
  const searchText = useSelector((state) => state.searchText);
  const [filters, setFilters] = useState({});
  const [refetching, setRefetching] = useState(null);

  const { sort, ...sortProps } = useTableSorting({ tableConfig, column: 1 });

  const projectsSearchInput = useMemo(
    () => ({
      searchTerm: searchText,
      query: getOSISearchInputQuery(filters),
      size: CURRENT_PAGE_SIZE,
      sort,
    }),
    [searchText, filters, sort],
  );

  const { data, loading, refetch, tableProps } = useTableInfiniteScrollQuery(
    OPEN_SUPPLIER_INVOICES_QUERY,
    projectsSearchInput,
    {
      endpointName: 'openSupplierInvoices',
      pageSize: CURRENT_PAGE_SIZE,
    },
  );

  const { projects, highlightedProjects } =
    prepareProjectsToDisplay(searchText)(data);

  const refetchTableData = async () => {
    setRefetching(true);
    await refetch();
    setRefetching(false);
  };

  return (
    <PageWithSearchHeader headerHeight="210px">
      <OSIPageHeader
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        refetching={refetching}
        refetchTableData={refetchTableData}
      />
      <OSIToolBar projects={projects} />
      {loading || refetching ? (
        <Loader />
      ) : (
        <OSIPageTable
          highlightedProjects={highlightedProjects}
          windowedTableProps={tableProps}
          refetchTableData={refetchTableData}
          {...sortProps}
        />
      )}
    </PageWithSearchHeader>
  );
}
