import React from 'react';
import { useRouterParams } from 'poly-client-routing';
import { SUPPLIER_PEOPLE_QUERY } from 'poly-admin-ui';
import { AddContactButton } from '../../../sidebars/ContactSidebar/forms/add/AddContactButton.js';

import { PeopleTab } from '../peopleTab/PeopleTab.js';

export function SupplierPeopleTab(props) {
  const { supplierId } = useRouterParams(['supplierId']);

  const entity = {
    _id: supplierId,
    name: 'supplier',
  };

  return (
    <PeopleTab
      {...props}
      entity={entity}
      supplierId={supplierId}
      gqlSearchQuery={SUPPLIER_PEOPLE_QUERY}
      AddButton={AddContactButton}
    />
  );
}

SupplierPeopleTab.displayName = 'SupplierPeopleTab';
