import React from 'react';
import { string } from 'prop-types';
import { ChangePasswordPageBase } from 'poly-common-ui';
import { useNavigate, useRouterParams } from 'poly-client-routing';

import { useNotificationState } from '../hooks/useNotificationState.js';

export function ChangePasswordPageBaseAdmin({
  title,
  homeUrl,
  forgotPasswordUrl,
}) {
  const { token } = useRouterParams(['token']);
  const navigate = useNavigate();
  const { showSuccessNotification } = useNotificationState();

  const onSuccess = () => {
    showSuccessNotification('Your password is successfully changed');
    navigate(homeUrl);
  };

  const navigateToForgotPassword = () => navigate(forgotPasswordUrl);

  return (
    <ChangePasswordPageBase
      token={token}
      title={title}
      onSuccess={onSuccess}
      navigateToForgotPassword={navigateToForgotPassword}
    />
  );
}

ChangePasswordPageBaseAdmin.propTypes = {
  title: string.isRequired,
  homeUrl: string.isRequired,
  forgotPasswordUrl: string.isRequired,
};
