import React from 'react';
import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { validateEmail } from 'poly-utils';
import { validatePhone } from 'poly-client-utils';
import styled from 'styled-components';
import { func, object, shape, string } from 'prop-types';

import { FindContactLabel } from './components/FindContactLabel.js';
import { ProjectContactsByClientSelect } from './components/ContactSearch.js';
import { BasicInput } from '../../common.js';
import { PhoneNumberInput } from '../PhoneNumberInput.js';
import { DuplicateContactMsgC } from '../../peopleForm/form/components/DuplicateContactMsg.js';

const isCreateMode = (name) =>
  R.pathOr(false, [name, 'contact', 'isCreateMode']);

const getOrder = (order, newOrder) => ({ order: order + newOrder / 10 });

// getLayout :: (Object, Number) => Object
const getLayout = (layout, newRow) => ({
  ...layout,
  row: R.prop('row', layout) + newRow,
});

const FIND_USER_CONTACT_BY_EMAIL = gql`
  query FIND_USER_CONTACT_BY_EMAIL($email: Email!) {
    findUserContactByEmail(email: $email) {
      _id
      fullName
    }
  }
`;

const DuplicateContactMsgWrapper = styled.div`
  margin-top: 5px;
`;

function EmailInputWithSearch({
  value,
  parentFiledName,
  changeFieldValue,
  ...props
}) {
  const { data, loading } = useQuery(FIND_USER_CONTACT_BY_EMAIL, {
    variables: {
      email: value,
    },
    skip: !validateEmail(value),
    fetchPolicy: 'network-only',
  });

  const userContact = R.prop('findUserContactByEmail', data);

  const addUserContact = () => changeFieldValue(parentFiledName, userContact);

  return (
    <>
      <BasicInput value={value} {...props} />
      {userContact && (
        <DuplicateContactMsgWrapper>
          <DuplicateContactMsgC loading={loading} onSubmit={addUserContact} />
        </DuplicateContactMsgWrapper>
      )}
    </>
  );
}

EmailInputWithSearch.propTypes = {
  value: string.isRequired,
  parentFiledName: string.isRequired,
  changeFieldValue: func.isRequired,
};

function ProjectContact({ placeholder, getClientId, formData, ...props }) {
  return (
    <ProjectContactsByClientSelect
      placeholder={placeholder}
      clientId={getClientId(formData)}
      {...props}
    />
  );
}

ProjectContact.propTypes = {
  getClientId: func.isRequired,
  placeholder: string,
  formData: shape({
    client: shape({
      _id: string,
    }),
  }),
};

function FindContactLabelWrapper({ getPropertyId, name, formData, ...props }) {
  const labelProps = {
    propertyId: getPropertyId(formData),
    userId: R.pathOr('', [name, '_id'], formData),
    ...props,
  };
  return <FindContactLabel {...labelProps} />;
}

FindContactLabelWrapper.propTypes = {
  getPropertyId: func.isRequired,
  name: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formData: object,
};

/**
 * Contact Select
 *   - For form section usage.
 *   - See real example in Project Edit form.
 *@example
 ...getContactSelect({
      label: 'REQUESTER',
      labelSwitchAddNewMode: "Did't find the requester?",
      labelSwitchFindMode: 'New requester will be ...',
      labelNotBelongProperty: 'This contact ...',
      order: 11,
      name: 'requester', // <- Object where save contact
      getClientId: R.pathOr('', ['clientId']), // <- @required
      getPropertyId: R.pathOr('', ['propertyId']), <- @required
   }),
 */
export const getContactSelect = ({
  label,
  order,
  name,
  getClientId,
  getPropertyId,
  placeholder = null,
  labelSwitchAddNewMode,
  labelSwitchFindMode,
  labelNotBelongProperty,
  layout = null,
  renderIf = R.T,
}) => [
  {
    renderIf: R.allPass([R.complement(isCreateMode(name)), renderIf]),
    label,
    ...getOrder(order, 1),
    key: `${name}._id`,
    layout: layout || {
      padding: '0 0 0 0',
      row: order,
    },
    field: {
      name: `${name}._id`,
      withFormData: true,
      Component: (props) => (
        <ProjectContact
          placeholder={placeholder}
          getClientId={getClientId}
          {...props}
        />
      ),
    },
  },

  {
    renderIf: isCreateMode(name),
    ...getOrder(order, 3),
    label: `New ${label}`,
    required: true,
    field: {
      name: `${name}.fullName`,
      key: `${name}.fullName`,
      Component: BasicInput,
    },
    validators: [[R.identity, 'Name is required']],
    layout: layout || { row: order },
  },
  {
    renderIf: isCreateMode(name),
    label: 'Email',
    ...getOrder(order, 4),
    required: true,
    field: {
      name: `${name}.email`,
      withChangeFieldValue: true,
      additionalProps: { parentFiledName: name },
      Component: EmailInputWithSearch,
    },

    validators: [[validateEmail, 'Incorrect email']],
    layout: layout ? getLayout(layout, 1) : { row: order },
  },
  {
    renderIf: isCreateMode(name),
    required: true,
    label: 'Phone',
    ...getOrder(order, 5),
    key: `${name}.phone`,
    field: {
      name: `${name}.phone`,
      Component: PhoneNumberInput,
    },
    validators: [[validatePhone, 'Incorrect phone number']],
    layout: layout ? getLayout(layout, 2) : { row: order },
  },
  {
    ...getOrder(order, 6),
    label: ' ',
    key: `${name}-contact`,
    field: {
      name: `${name}.contact`,
      withFormData: true,
      Component: (props) => (
        <FindContactLabelWrapper
          getPropertyId={getPropertyId}
          labelSwitchAddNewMode={labelSwitchAddNewMode}
          labelSwitchFindMode={labelSwitchFindMode}
          labelNotBelongProperty={labelNotBelongProperty}
          mainLabel={label}
          name={name}
          {...props}
        />
      ),
    },
    layout: layout ? getLayout(layout, 4) : { row: order },
    renderIf,
  },
];
