import React from 'react';
import { string } from 'prop-types';
import { filesCollections } from 'poly-constants';
import { SidebarFilesTab } from 'poly-admin-ui';

export function PropertySidebarFilesTab({ propertyId }) {
  const tabProps = {
    collection: filesCollections.PROPERTIES,
    entity: { _id: propertyId, preview: 'property' },
  };

  return <SidebarFilesTab {...tabProps} />;
}

PropertySidebarFilesTab.propTypes = { propertyId: string.isRequired };
