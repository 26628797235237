import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useMemo } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import { useTableInfiniteScrollQuery } from 'poly-client-utils';
import { useStateInputForInfiniteListQuery } from './useStateInputForInfiniteListQuery.js';

const CURRENT_PAGE_SIZE = 500;

const CONFIRM_SATISFACTION_REPORT_QUERY = gql`
  query CONFIRM_SATISFACTION_REPORT_QUERY($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        type
        projectId
        description
        workCompletionDate
        contact {
          _id
          profile {
            fullName
          }
        }
      }
    }
  }
`;

// prepareQueryByFilters :: Filters -> Query
const prepareQueryByFilters = R.compose(
  R.assocPath(['bool', 'must'], R.__, {}),
  R.reject(R.isNil),
  R.juxt([
    R.always({ match: { hasUncompletedConfirmSatisfactionTask: true } }),
    R.always({ exists: { field: 'workCompletionDate' } }),
    R.ifElse(
      R.propSatisfies(R.isNil, 'projectId'),
      R.always(null),
      R.applySpec({ term: { _id: R.prop('projectId') } }),
    ),
    R.ifElse(
      R.propSatisfies(R.isNil, 'contactId'),
      R.always(null),
      R.applySpec({ term: { contactId: R.prop('contactId') } }),
    ),
    R.ifElse(
      R.both(
        R.pathSatisfies(R.isNil, ['workCompletionDate', 'startDate']),
        R.pathSatisfies(R.isNil, ['workCompletionDate', 'endDate']),
      ),
      R.always(null),
      R.compose(
        ({ startDate, endDate }) => ({
          range: {
            workCompletionDate: {
              ...(!startDate ? {} : { gte: startOfDay(new Date(startDate)) }),
              ...(!endDate ? {} : { lte: endOfDay(new Date(endDate)) }),
              format: 'strict_date_optional_time',
            },
          },
        }),
        R.prop('workCompletionDate'),
      ),
    ),
  ]),
);

export const useConfirmSatisfactionReportQuery = (filters) => {
  const preparedFilters = prepareQueryByFilters(filters || {});

  const input = useStateInputForInfiniteListQuery(
    CURRENT_PAGE_SIZE,
    preparedFilters,
  );

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    CONFIRM_SATISFACTION_REPORT_QUERY,
    input,
    {
      endpointName: 'searchProjects',
      pageSize: CURRENT_PAGE_SIZE,
      skip: !filters,
    },
  );

  const projects = useMemo(
    () => R.pathOr([], ['searchProjects', 'hits'], data),
    [data],
  );

  return { projects, loading, tableProps };
};
