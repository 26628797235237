import React from 'react';
import { string, shape } from 'prop-types';
import { keywordSortQuery } from 'poly-client-utils';
import { ASC_SORT_ORDER } from 'poly-constants';

import { PropertySelect } from '../../../../../modules/selects/PropertySelect/PropertySelect.js';

export function AssetPropertySelect({ formData, ...props }) {
  const { clientId, showClient } = formData;

  const disabled = showClient && !clientId;

  const selectorProps = {
    disabled,
    fetchSize: 1000,
    withoutSkip: true,
    isClearable: true,
    includeAllOption: false,
    additionalSearchParams: {
      sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      ...(clientId ? { query: { term: { clientId } } } : {}),
    },
  };

  return <PropertySelect {...props} {...selectorProps} />;
}

AssetPropertySelect.propTypes = {
  formData: shape({ clientId: string }).isRequired,
};
