import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { instanceOf, shape, func } from 'prop-types';
import { DatePicker, Select } from 'poly-book-admin';
import { ClientSelect } from 'poly-admin-ui';
import { alwaysNewDate } from 'poly-utils';

import { JournalOccurrence } from '../constants.js';
import { halfWidth, quarterWidth } from '../../../modules/forms/common.js';
import { JournalEntryLineComponent } from './JournalEntryLineComponent.js';
import { JournalEntryAccountingTypeSelector } from './JournalEntryAccountingTypeSelector.js';
import { RecurringScheduler } from '../../../components/RecurringScheduler/RecurringScheduler.js';
import { RecurringJournalStatusSelector } from '../../RecurringJournal/RecurringJournalStatusSelector.js';
import { CheckboxWithLabel } from '../../../sidebars/ProjectSidebar/forms/form/components/CheckboxWithLabel.js';
import { setJournalEntry } from '../../../redux/journalEntryReducer.js';
import { onKeyDownToPreventFormSubmit } from '../../../utils/form.js';

function OccurrenceJournalSelect({ onChange, ...props }) {
  const dispatch = useDispatch();

  const selectProps = {
    ...props,
    onChange: (value) => {
      onChange(value);
      const showPrint = value !== JournalOccurrence.RECURRING_OCCURRENCE;
      dispatch(setJournalEntry({ showPrint }));
    },
    options: [
      { value: JournalOccurrence.RECURRING_OCCURRENCE, label: 'Recurring' },
      { value: JournalOccurrence.ONE_TIME_OCCURRENCE, label: 'One Time' },
    ],
  };

  return <Select {...selectProps} />;
}

OccurrenceJournalSelect.propTypes = { onChange: func.isRequired };

function CreateJournalDatePicker(props) {
  return (
    <DatePicker
      {...props}
      width="100%"
      leftMove="0px"
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

const DatePickerS = styled(DatePicker)`
  > div:first-child {
    width: 100%;

    > div:first-child {
      width: 100%;
    }
  }
`;

// isNotNeverEnding :: ProjectFormObject -> Boolean
const isNotNeverEnding = R.compose(R.not, R.prop('never'), R.defaultTo({}));

function EndDatePicker({ formData: { startDate }, ...rest }) {
  return <DatePickerS {...rest} disabledDays={{ before: startDate }} />;
}

EndDatePicker.propTypes = {
  formData: shape({
    startDate: instanceOf(Date),
  }),
};

// isRecurringJournal :: FormData -> Boolean
const isRecurringJournal = R.propEq(
  'occurrence',
  JournalOccurrence.RECURRING_OCCURRENCE,
);

function ManualJEClientSelector(props) {
  return (
    <ClientSelect
      {...props}
      includeAllOption={false}
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

export const journalEntryFromSections = [
  {
    order: 1,
    layout: { column: 1, justifyContent: 'flex-start' },
    fields: [
      {
        order: 2,
        label: 'Type',
        layout: { row: 1, width: quarterWidth },
        required: true,
        field: {
          name: 'accounting_method',
          Component: JournalEntryAccountingTypeSelector,
          withFormData: true,
        },
      },
      {
        order: 3,
        label: 'Occurrence',
        layout: { row: 1, width: quarterWidth },
        field: {
          name: 'occurrence',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: OccurrenceJournalSelect,
        },
        required: true,
      },
      {
        order: 3,
        label: 'Date',
        layout: { row: 1, width: quarterWidth },
        field: {
          name: 'date',
          Component: CreateJournalDatePicker,
        },
        renderIf: R.propEq('occurrence', JournalOccurrence.ONE_TIME_OCCURRENCE),
        validators: [[R.identity, 'Date is required']],
      },
      {
        order: 4,
        label: 'Client',
        layout: { row: 1, width: quarterWidth },
        field: {
          name: 'client_id',
          Component: ManualJEClientSelector,
          withFormData: true,
        },
      },
      {
        order: 3,
        label: 'Status',
        layout: { row: 1, width: quarterWidth },
        required: true,
        field: {
          name: 'status',
          Component: RecurringJournalStatusSelector,
        },
        renderIf: R.propEq(
          'occurrence',
          JournalOccurrence.RECURRING_OCCURRENCE,
        ),
      },
      {
        label: 'Start Date',
        layout: { row: 2, width: quarterWidth },
        order: 1,
        field: {
          name: 'startDate',
          additionalProps: {
            onKeyDown: onKeyDownToPreventFormSubmit,
            disabledDays: { before: alwaysNewDate() },
          },
          Component: DatePickerS,
        },
        renderIf: isRecurringJournal,
      },
      {
        leaveValues: true,
        label: 'End Date',
        layout: { row: 2, width: quarterWidth },
        order: 2,
        field: {
          name: 'endDate',
          withFormData: true,
          additionalProps: {
            onKeyDown: onKeyDownToPreventFormSubmit,
            disabledDays: { before: alwaysNewDate() },
          },
          Component: (props) => <EndDatePicker {...props} />,
        },
        renderIf: R.both(isNotNeverEnding, isRecurringJournal),
      },
      {
        order: 2,
        layout: { row: 2, width: quarterWidth },
        field: {
          name: 'space',
          Component: () => null,
        },
        renderIf: R.prop('never'),
      },
      {
        layout: { row: 2, width: quarterWidth, padding: '28px 0' },
        order: 3,
        field: {
          name: 'never',
          Component: (props) => (
            <CheckboxWithLabel {...props} label="Never Ends'" />
          ),
        },
        renderIf: isRecurringJournal,
      },
      {
        order: 4,
        layout: { row: 2, width: quarterWidth },
        field: {
          name: 'space',
          Component: () => null,
        },
      },
      {
        order: 1,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'schedule',
          withFormData: true,
          Component: (props) => (
            <RecurringScheduler
              {...props}
              onKeyDown={onKeyDownToPreventFormSubmit}
            />
          ),
        },
        renderIf: R.propEq(
          'occurrence',
          JournalOccurrence.RECURRING_OCCURRENCE,
        ),
      },
      {
        order: 1,
        layout: { row: 4 },
        field: {
          name: 'lines',
          isArrayField: true,
          withChangeFieldValue: true,
          Component: JournalEntryLineComponent,
        },
      },
    ],
  },
];
