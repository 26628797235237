import React from 'react';
import { useOutSidebarContext } from 'poly-client-utils';

import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';
import { PurchaseOrderSidebarTabs } from './PurchaseOrderSidebarTabs.js';
import {
  purchaseOrderTabsSidebarId,
  purchaseOrderTabsSidebarWidth,
} from '../constants.js';

export const useOpenPurchaseOrderTabsSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (purchaseOrder) => {
    openOutSidebar({
      width: purchaseOrderTabsSidebarWidth,
      id: purchaseOrderTabsSidebarId,
      Layout: SecondSidebarLayout,
      content: <PurchaseOrderSidebarTabs purchaseOrder={purchaseOrder} />,
    });
  };
};
