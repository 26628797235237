import React from 'react';
import { Text } from 'poly-book-admin';
import { bool, shape } from 'prop-types';

import { PrintProjectSuppliers } from './parts/PrintProjectSuppliers.js';
import { PrintProjectAssetDetails } from './parts/PrintProjectAssetDetails.js';
import { PrintProjectProjectTasks } from './parts/PrintProjectProjectTasks.js';
import { PrintProjectFinancialInfo } from './parts/PrintProjectFinancialInfo.js';
import { PrintProjectGeneralDetails } from './parts/PrintProjectGeneralDetails.js';
import { PrintProjectClientInvoices } from './parts/PrintProjectClientInvoices.js';
import { PrintProjectProjectDetails } from './parts/PrintProjectProjectDetails.js';
import { PrintProjectSupplierInvoices } from './parts/PrintProjectSupplierInvoices.js';
import { PrintProjectContactInformation } from './parts/PrintProjectContactInformation.js';
import {
  PrintProjectFlexColumn,
  ProjectPrintToPDFTableWrapper,
} from './components.js';

export function ProjectPrintToPDFTable({ printProject, config }) {
  const {
    isSupplierInfo,
    isProjectTasks,
    isFinancialInfo,
    isAttachedAssets,
    isGeneralDetails,
    isProjectDetails,
    isContactInformation,
    isIncludeClientInvoiceFiles,
    isIncludeSupplierInvoiceFiles,
  } = config;

  return (
    <ProjectPrintToPDFTableWrapper>
      <PrintProjectFlexColumn>
        <Text size="18px" weight="bold">
          {printProject.projectId}
        </Text>

        {isGeneralDetails && (
          <PrintProjectGeneralDetails project={printProject} />
        )}

        {isProjectDetails && (
          <PrintProjectProjectDetails project={printProject} />
        )}

        {isContactInformation && (
          <PrintProjectContactInformation project={printProject} />
        )}

        {isSupplierInfo && <PrintProjectSuppliers project={printProject} />}

        {isAttachedAssets && (
          <PrintProjectAssetDetails project={printProject} config={config} />
        )}

        {isProjectTasks && (
          <PrintProjectProjectTasks project={printProject} config={config} />
        )}

        {isFinancialInfo && (
          <PrintProjectFinancialInfo project={printProject} config={config} />
        )}

        {isIncludeSupplierInvoiceFiles && (
          <PrintProjectSupplierInvoices project={printProject} />
        )}

        {isIncludeClientInvoiceFiles && (
          <PrintProjectClientInvoices project={printProject} />
        )}
      </PrintProjectFlexColumn>
    </ProjectPrintToPDFTableWrapper>
  );
}

ProjectPrintToPDFTable.propTypes = {
  printProject: shape({}),
  config: shape({
    isSupplierInfo: bool.isRequired,
    isProjectTasks: bool.isRequired,
    isFinancialInfo: bool.isRequired,
    isAttachedAssets: bool.isRequired,
    isGeneralDetails: bool.isRequired,
    isProjectDetails: bool.isRequired,
    isContactInformation: bool.isRequired,
    isIncludeAssetDetailsInfo: bool.isRequired,
    isIncludeClientEstimateFile: bool.isRequired,
    isIncludeClientInvoiceFiles: bool.isRequired,
    isIncludeSupplierInvoiceFiles: bool.isRequired,
    isIncludeCompletedTaskComments: bool.isRequired,
  }).isRequired,
};
