import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { Link } from 'poly-client-routing';
import {
  DefaultBodyWrapper,
  Loader,
  MainHeader,
  PageHeaderContainer,
  singleButtonColumnStyles,
  Table,
  TableCard,
} from 'poly-book-admin';
import { gql, useQuery } from '@apollo/client';
import {
  applyPath,
  formatDate,
  formatTotal,
  insertParamsIntoURL,
} from 'poly-utils';
import { applyPathOrNothingUI } from 'poly-client-utils';
import { extractTablePropsFromConfig } from 'poly-admin-ui';
import { RecurringJournalStatuses } from 'poly-constants';
import { routesNames } from '../../routes/index.js';
import {
  calculateLineTotalBase,
  isDebitLine,
} from './recurringJournalEntryHelpers.js';

const recurringJournalQuery = gql`
  query recurringJournalsQuery($input: RecurringJournalsInput) {
    recurringJournals(input: $input) {
      hits {
        _id
        start_date
        end_date
        reference_id
        status
        schedule {
          every
          repeats
          days
          weekDay
        }
        lines {
          cash_amount
          accrual_amount
          account {
            code
            accountType {
              _id
              normal_balance
            }
          }
        }
      }
    }
  }
`;

const getRecurringJournals = R.pathOr([], ['recurringJournals', 'hits']);

function EditRecurringJournalBtn({ _id }) {
  const href = insertParamsIntoURL(
    { journalId: _id },
    routesNames.EDIT_RECURRING_JOURNAL,
  );
  return <Link href={href}>Edit</Link>;
}

EditRecurringJournalBtn.propTypes = {
  _id: string.isRequired,
};

const recurringJournalsStatusesUI = {
  [RecurringJournalStatuses.active]: 'Active',
  [RecurringJournalStatuses.inactive]: 'Inactive',
};

const recurringJournalsTableConfig = [
  ['JE #', R.compose(R.concat('RJE-'), R.prop('reference_id'))],
  [
    'Status',
    R.compose(R.prop(R.__, recurringJournalsStatusesUI), R.prop('status')),
  ],
  ['Start Date', applyPath(formatDate, ['start_date'])],
  ['End Date', applyPathOrNothingUI(['end_date'], formatDate)],
  ['Debit', R.compose(formatTotal, calculateLineTotalBase(isDebitLine))],
  [
    'Credit',
    R.compose(formatTotal, calculateLineTotalBase(R.complement(isDebitLine))),
  ],
  ['', EditRecurringJournalBtn],
];
const tableProps = extractTablePropsFromConfig(recurringJournalsTableConfig);

const TableS = styled(Table)`
  ${singleButtonColumnStyles(7)}
`;

export function RecurringJournalsPage() {
  const { data, loading } = useQuery(recurringJournalQuery, {
    variables: { input: {} },
    fetchPolicy: 'network-only',
  });

  const recurringJournals = getRecurringJournals(data);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Recurring Journal Entries</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCard>
          <TableS {...tableProps} rows={recurringJournals} />
        </TableCard>
      </DefaultBodyWrapper>
    </>
  );
}
