import React from 'react';
import styled from 'styled-components';
import { bool, func, shape, string } from 'prop-types';
import { LinkButton, useNotificationContext } from 'poly-book-admin';
import { useToggleUserAlertArchive } from '../../../../modules/core/hooks/userAlerts/useToggleUserAlertArchive.js';

const LinkButtonS = styled(LinkButton)`
  margin-left: 10px;
`;
function ArchiveAlertNotificationC({ onClick, isArchived }) {
  return (
    <>
      <span>{isArchived ? 'Alert is unarchived.' : 'Alert is archived.'}</span>
      <LinkButtonS onClick={onClick}>Undo</LinkButtonS>
    </>
  );
}

ArchiveAlertNotificationC.propTypes = {
  isArchived: bool.isRequired,
  onClick: func.isRequired,
};

export function ArchiveAlertNotification(props) {
  const { id, payload, closeNotificationById } = props;

  const { showSuccessNotification } = useNotificationContext();

  const { toggleUserAlertArchive } = useToggleUserAlertArchive();

  const unArchiveAlert = () => toggleUserAlertArchive(payload._id);

  const isArchived = payload?.isArchived;

  const onClick = () =>
    unArchiveAlert()
      .then(() => payload.setIsArchivedDone(false))
      .then(() => closeNotificationById(id))
      .finally(() => {
        payload.refetch();
        showSuccessNotification(
          isArchived ? 'Unarchived alert canceled' : 'Archived alert canceled',
        );
      });

  return (
    <ArchiveAlertNotificationC isArchived={isArchived} onClick={onClick} />
  );
}

ArchiveAlertNotification.displayName = 'ArchiveAlertNotification';

ArchiveAlertNotification.propTypes = {
  id: string.isRequired,
  payload: shape({
    _id: string.isRequired,
    isArchived: bool.isRequired,
    setIsArchivedDone: func.isRequired,
  }).isRequired,
  refetch: func,
  closeNotificationById: func,
};
