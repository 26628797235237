import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { applyPathOr, getHousekeepingWeekCountByProject } from 'poly-utils';
import { ProjectType } from 'poly-constants';
import {
  useSidebarTableFormContext,
  useMapConfigToTableProps,
  FlexContainer,
  SidebarRow,
} from 'poly-admin-ui';
import { getThemeColor, Checkbox, Text, Cell } from 'poly-book-admin';

import {
  SectionLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';
import { SidebarWeeklyServiceTickets } from './components/projectSidebarComponents.js';
import {
  WeeklyServiceTicketForm,
  ticketPropTypes,
} from './forms/WeeklyServiceTicketForm.js';
import {
  isTicketApprovedOrNotCompleted,
  transformWeeklyServiceTickets,
  sumWeeklyServiceTicketHours,
  calculateExtraTotalHours,
  calculateBaseTotalHours,
  formatTicketHours,
  getWeekTicketInfo,
} from './projectSidebarUtils.js';

const Description = styled(Text)`
  color: ${getThemeColor(['primaryLight'])};
  margin-left: 10px;
  cursor: pointer;
`;

const useWeeklyServiceTicketForm = () => {
  const { formSetter } = useSidebarTableFormContext();

  return (ticket) =>
    formSetter({
      elementId: ticket._id,
      Content: <WeeklyServiceTicketForm {...ticket} />,
    });
};

function ServiceTicketsTableFooter({ rows }) {
  const totalBase = calculateBaseTotalHours(rows);
  const totalExtra = calculateExtraTotalHours(rows);
  return (
    <tr>
      <Cell disabled>Total</Cell>
      <Cell disabled>{formatTicketHours(totalBase)}</Cell>
      <Cell disabled>{formatTicketHours(totalExtra)}</Cell>
      <Cell disabled>{formatTicketHours(totalBase + totalExtra)}</Cell>
      <Cell disabled />
    </tr>
  );
}

ServiceTicketsTableFooter.propTypes = {
  rows: arrayOf(
    shape({
      ticket: shape({
        hours: number.isRequired,
        extraHours: number.isRequired,
      }),
    }),
  ).isRequired,
};

function AddTicketSectionBtn(props) {
  const { week, ticket } = props;
  const openForm = useWeeklyServiceTicketForm();

  const onAdd = () => openForm(props);
  const onEdit = () => openForm({ ...props, isEdit: true });

  const onClick = !ticket ? onAdd : onEdit;

  return (
    <FlexContainer onClick={onClick}>
      <Checkbox
        key={week}
        onChange={() => null}
        name={`add-ticket-btn-${week}`}
        value={isTicketApprovedOrNotCompleted({ ticket })}
      />
      <Description>{getWeekTicketInfo({ week, ticket })}</Description>
    </FlexContainer>
  );
}

AddTicketSectionBtn.propTypes = {
  week: number.isRequired,
  ticket: ticketPropTypes,
};

const ticketsListConfig = [
  ['', AddTicketSectionBtn],
  ['Base', applyPathOr(['ticket', 'hours'], formatTicketHours, null)],
  ['Extra', applyPathOr(['ticket', 'extraHours'], formatTicketHours, null)],
  [
    'Total',
    R.compose(
      R.ifElse(R.equals(0), R.always(null), formatTicketHours),
      sumWeeklyServiceTicketHours,
      R.prop('ticket'),
    ),
  ],
];

// getProjectSidebarWeeklyTicketsInfo :: (Project, Boolean) -> Object
const getProjectSidebarWeeklyTicketsInfo = (project, isCard) =>
  R.applySpec({
    _id: R.prop('_id'),
    weeklyPorterHours: R.propOr(0, 'weeklyPorterHours'),
    isHousekeeping: R.propEq('type', ProjectType.HOUSEKEEPING),
    weeklyServiceTickets: R.ifElse(
      R.propEq('type', ProjectType.HOUSEKEEPING),
      R.compose(
        R.when(
          R.always(isCard),
          R.map(
            R.over(R.lensProp('_id'), R.compose(R.concat('card-'), R.toString)),
          ),
        ),
        R.converge(transformWeeklyServiceTickets, [
          getHousekeepingWeekCountByProject,
          R.propOr([], 'weeklyServiceTickets'),
        ]),
      ),
      R.always([]),
    ),
  })(project);

export function ProjectSidebarWeeklyTickets({ project, isCard }) {
  const { _id, isHousekeeping, weeklyPorterHours, weeklyServiceTickets } =
    getProjectSidebarWeeklyTicketsInfo(project, isCard);

  const tableProps = useMapConfigToTableProps(
    R.map(R.mergeDeepLeft({ projectId: _id, weeklyPorterHours })),
    ticketsListConfig,
    weeklyServiceTickets,
  );

  if (!isHousekeeping) return null;

  return (
    <SectionWrapper skipMargin>
      <SidebarRow>
        <SectionLabel>Weekly Service Tickets</SectionLabel>
      </SidebarRow>
      <SidebarWeeklyServiceTickets
        {...tableProps}
        omitColumn={4}
        isRowDisabled={isTicketApprovedOrNotCompleted}
        TableFooter={ServiceTicketsTableFooter}
        minHeightLess
      />
    </SectionWrapper>
  );
}

ProjectSidebarWeeklyTickets.propTypes = {
  project: shape({ _id: string.isRequired }),
  isCard: bool,
};
