import React from 'react';
import styled from 'styled-components';
import { Text, DefaultBodyWrapper } from 'poly-book-admin';
import { Link } from 'poly-client-routing';

const NotFoundContainer = styled(DefaultBodyWrapper)`
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const NotFoundTitle = styled(Text)`
  margin: 25px 0;
`;

export function NotFoundPage() {
  return (
    <NotFoundContainer>
      <Text
        spacing="5px"
        weight="hairline"
        size="100px"
        color={['primaryLighter3']}
      >
        Not Found
      </Text>
      <NotFoundTitle spacing="1px" size="32px">
        This isn&#39;t page you&#39;re looking for
      </NotFoundTitle>
      <Text weight="regular" size="22px" color={['primaryLighter3']}>
        <Link href="/">Go To Homepage</Link>
      </Text>
    </NotFoundContainer>
  );
}
