import React from 'react';
import { useSelector } from 'react-redux';
import { TableCardWithPaginationContainer } from 'poly-admin-ui';
import { DefaultBodyWrapper } from 'poly-book-admin';

import { RecurringProjectsPageTable } from '../../modules/tables/projectsTable/ProjectsTable.js';
import { useSaveQueryForXLSExport } from '../../modules/core/tableToolbar/TableExportToXLSBtn.js';
import { generateSearchMasterProjectsQueryByFilter } from './masterProjectsDirectoryUtils.js';

export function SearchMasterProjectsTable() {
  const filter = useSelector((state) => state.searchFilters.projectsFilter);

  const query = generateSearchMasterProjectsQueryByFilter(filter);

  useSaveQueryForXLSExport({ query });

  return (
    <DefaultBodyWrapper height="calc(100% - 106px)">
      <TableCardWithPaginationContainer>
        <RecurringProjectsPageTable query={query} />
      </TableCardWithPaginationContainer>
    </DefaultBodyWrapper>
  );
}
