import React from 'react';
import { NotificatorProvider as NotificatorProviderC } from 'poly-book-admin';

import { useNotificationState } from '../hooks/useNotificationState.js';

export function NotificatorProvider(props) {
  const { notifications, hideNotification } = useNotificationState();

  return (
    <NotificatorProviderC
      onClose={hideNotification}
      {...{ notifications, ...props }}
    />
  );
}
