import React from 'react';
import { bool, func, shape } from 'prop-types';
import styled from 'styled-components';
import { BillingProfileConsolidateBy } from 'poly-constants';
import { Select } from 'poly-book-admin';

import { multiSelectorValueProperTypes } from './common.js';

const ConsolidateBySelectS = styled(Select)`
  .select__control {
    width: 162px;
  }
`;

export function ConsolidateBySelect({ values, value, onChange, ...props }) {
  const options = [
    { value: BillingProfileConsolidateBy.COST_CENTER, label: 'Cost Center' },
    {
      value: BillingProfileConsolidateBy.REFERENCE_NUMBER,
      label: 'PO #',
    },
  ];

  const onConsolidateByChange = (val) => onChange([val]);

  const selectProps = {
    ...props,
    options,
    required: true,
    withoutErrorMessage: true,
    value: value ? value[0] : '',
    onChange: onConsolidateByChange,
    placeholder: 'Select consolidation',
  };

  return <ConsolidateBySelectS {...selectProps} />;
}

ConsolidateBySelect.propTypes = {
  value: multiSelectorValueProperTypes,
  onChange: func.isRequired,
  values: shape({ enableClientPurchaseOrder: bool }),
};
