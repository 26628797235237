import styled from 'styled-components';
import { defaultTheme } from 'poly-book-admin';

const { colors } = defaultTheme;

export const LabelsWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  color: ${colors.secondary};
`;

export const LabelWrapper = styled.span`
  font-size: 12px;
  min-height: 10px;
  padding: 5px 0 5px 0;
  div {
    font-size: 12px;
  }
`;

export const TextBtnWrapper = styled.span`
  div {
    display: contents;
  }
`;
