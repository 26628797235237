import React from 'react';
import * as R from 'ramda';
import arrayMutators from 'final-form-arrays';
import { bool, func, shape, string } from 'prop-types';
import { useHasUserAccessToProtectedSupplier } from 'poly-client-utils/src/suppliers/useHasUserAccessToUpdateSupplierProtected.js';
import { useHasUserAccessToUpdateSupplierProtected } from 'poly-client-utils';
import { UPDATE_SUPPLIER_PERMISSION } from 'poly-security/src/permissions.js';
import { usePristineSubscribe } from 'poly-client-routing';
import { FormPage, Loader } from 'poly-book-admin';
import { SupplierSources } from 'poly-constants';

import { supplierFormSections } from '../form/sections/index.js';
import { useOnSubmitSetStopSubmitting } from '../../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useSupplierTaxValidation } from '../form/useTaxIdValidation.js';
import { formSupplierDataToMutation } from './formDataToMutation.js';
import { supplierToEditForm } from './supplierToEditForm.js';
import { useEditSupplier } from './editSupplierMutation.js';

export function EditSupplierForm({
  formId,
  supplier,
  supplierUrl,
  supplierSource,
  masterSupplierRootUrl,
  isSupplierTypeEditable,
  isAddressSectionRequired,
  ConvertSupplierComponent,
  skipMasterSupplierValidation,
}) {
  const { protectedFieldsAccess, accessLoading } =
    useHasUserAccessToUpdateSupplierProtected();

  const hasAccessToPrivateSupplier = useHasUserAccessToProtectedSupplier(
    UPDATE_SUPPLIER_PERMISSION,
  );

  const editSupplier = useEditSupplier();
  const pristineSubscribeProps = usePristineSubscribe();

  const submitHandler = (supplierUpdate) =>
    editSupplier({
      supplierId: supplier?._id,
      update: R.compose(
        R.dissocPath(['misc', 'source']),
        formSupplierDataToMutation(protectedFieldsAccess),
      )(supplierUpdate),
      taxInitialAddress: R.pathOr({}, ['tax', 'address'], supplier),
      initialAddress: R.pathOr({}, ['company', 'address'], supplier),
      remitInitialAddress: R.pathOr({}, ['remit', 'address'], supplier),
    });

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, submitHandler);

  const validate = useSupplierTaxValidation(
    masterSupplierRootUrl,
    supplierUrl,
    skipMasterSupplierValidation,
    supplier,
  );

  const initialValues = supplierToEditForm({
    ...supplier,
    ...([SupplierSources.AAC, SupplierSources.TIAA].includes(supplierSource)
      ? { allowToConvert: true }
      : {}),
    supplierSource,
  });

  if (!supplier || accessLoading) {
    return <Loader />;
  }

  return (
    <FormPage
      id={formId}
      onSubmit={onSubmit}
      validate={validate}
      mutators={arrayMutators}
      initialValues={initialValues}
      sections={supplierFormSections(
        ConvertSupplierComponent,
        supplierUrl,
        supplier,
        isSupplierTypeEditable,
        protectedFieldsAccess,
        isAddressSectionRequired,
        { supplierSource, hasAccessToPrivateSupplier },
      )}
      {...pristineSubscribeProps}
    />
  );
}

EditSupplierForm.propTypes = {
  supplierSource: string.isRequired,
  supplierUrl: string.isRequired,
  masterSupplierRootUrl: string,
  formId: string.isRequired,
  ConvertSupplierComponent: func,
  supplier: shape({
    _id: string.isRequired,
    company: shape({
      name: string,
    }),
  }),
  skipMasterSupplierValidation: bool,
  isSupplierTypeEditable: bool,
  isAddressSectionRequired: bool,
};

EditSupplierForm.defaultProps = {
  ConvertSupplierComponent: () => null,
  isAddressSectionRequired: true,
};
