import * as R from 'ramda';
import styled from 'styled-components';
import React, { useCallback, useMemo } from 'react';
import { func, shape, string, bool, object } from 'prop-types';
import { useUpdateQueryParams, useRouterQuery } from 'poly-client-routing';
import { taskActionTypes, WorkOrderStatus } from 'poly-constants';
import { useOutSidebarContext } from 'poly-client-utils';
import { LinkButton, Text, ToolBarBtnDivider } from 'poly-book-admin';
import { isNilOrEmpty } from 'poly-utils';
import {
  useSidebarTableFormContext,
  CommonSidebarFormWrapper,
  useScrollIntoViewByRef,
  SidebarFormWrapper,
  useTasksTabLogic,
  FormPortalAnchor,
  FlexContainer,
  SidebarRow,
} from 'poly-admin-ui';
import { ApplyTaskTemplateButton } from 'poly-admin-ui/src/taskTemplate/ApplyTaskTemplateButton.js';

import {
  SectionLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';
import { projectSidebarTabsId } from './constants.js';
import { projectSidebarTabs } from '../../routes/constants.js';
import { SidebarTasks } from './components/projectSidebarComponents.js';
import { RecallProjectButton } from './components/RecallProjectButton.js';
import { AddTaskForm } from '../../modules/forms/taskForm/AddTaskForm.js';
import { AddProjectUpdateForm } from '../../modules/forms/addProjectUpdateForm/AddProjectUpdateForm.js';
import { updateProjectFormId } from '../../modules/forms/addProjectUpdateForm/AddProjectUpdateFormWithSelectAction.js';
import { useOpenProjectTabsSidebar } from './tabs/useOpenProjectTabsSidebar.js';
import { useProjectSidebarTasksProps } from './useProjectSidebarTasksProps.js';
import { addTaskFormId } from '../../modules/forms/taskForm/constants.js';

const ButtonsWrapper = styled(FlexContainer)`
  > div {
    display: flex;
  }
  align-items: center;
`;

const ToolBarBtnDividerS = styled(ToolBarBtnDivider)`
  height: 14px;
`;

function ViewAllTasksButton({ isCard, ...props }) {
  const updateQueryParams = useUpdateQueryParams();
  const openSidebarTabs = useOpenProjectTabsSidebar(props);

  const onViewAllClick = () => {
    const queryParamKey = isCard ? 'cardTab' : 'sidebarTab';

    updateQueryParams({ [queryParamKey]: projectSidebarTabs.projectTasks });

    if (!isCard) {
      openSidebarTabs();
    }
  };

  return <LinkButton onClick={onViewAllClick}>View All</LinkButton>;
}

ViewAllTasksButton.propTypes = { isCard: bool };

function AddTaskFormComp(props) {
  const { ref } = useScrollIntoViewByRef();
  const {
    payload: { onCancel },
  } = props;

  return (
    <SidebarFormWrapper skipMargin ref={ref} onCancel={onCancel}>
      <AddTaskForm {...props} />
    </SidebarFormWrapper>
  );
}

AddTaskFormComp.propTypes = {
  payload: shape({ onCancel: func.isRequired }).isRequired,
};

// getProjectRelationsString :: Project -> String
const getProjectRelationsString = R.compose(
  R.join(' - '),
  R.juxt([
    R.pathOr('', ['client', 'name']),
    R.pathOr('', ['property', 'name']),
  ]),
);

function ProjectSidebarTasksButtons({
  isCard,
  entity,
  project,
  collection,
  formAnchorId,
  readFromCacheParams,
}) {
  const { formSetter } = useSidebarTableFormContext();

  const { _id, status, projectId, property } = project;

  const showRecallBtn = status === WorkOrderStatus.COMPLETED;

  const onFormCancel = useCallback(() => {
    formSetter(null);
  }, []);

  const addTaskPayload = useMemo(
    () => ({
      entity,
      collection,
      _id: entity._id,
      isHQProject: isNilOrEmpty(property),
      onCancel: onFormCancel,
      payload: { entity, collection, _id: entity._id },
    }),
    [],
  );

  const onAddTask = () =>
    formSetter({
      id: formAnchorId,
      Content: (
        <CommonSidebarFormWrapper skipMargin onCancel={onFormCancel}>
          <AddTaskForm {...addTaskPayload} />
        </CommonSidebarFormWrapper>
      ),
    });

  const setRecallForm = () =>
    formSetter({
      id: formAnchorId,
      Content: (
        <CommonSidebarFormWrapper
          title="Recall Project"
          onCancel={onFormCancel}
          formId={updateProjectFormId}
          submitCaption="Recall Project"
          SubTitle={
            <>
              <Text size="12px" lineHeight="18px">
                {projectId}
              </Text>
              <Text size="12px" lineHeight="18px">
                {getProjectRelationsString(project)}
              </Text>
            </>
          }
        >
          <AddProjectUpdateForm
            projectId={_id}
            project={project}
            onCancel={onFormCancel}
            formId={updateProjectFormId}
            updateType={taskActionTypes.RECALL_PROJECT}
            updateCacheParams={readFromCacheParams}
          />
        </CommonSidebarFormWrapper>
      ),
    });

  return (
    <ButtonsWrapper>
      <ApplyTaskTemplateButton
        projectId={projectId}
        addTaskPayload={{ ...addTaskPayload, formId: addTaskFormId }}
        formAnchorId={formAnchorId}
      />
      <ToolBarBtnDividerS />
      {showRecallBtn && (
        <>
          <RecallProjectButton
            project={project}
            setRecallForm={setRecallForm}
          />
          <ToolBarBtnDividerS />
        </>
      )}
      <LinkButton onClick={onAddTask}>Add Task</LinkButton>
      <ToolBarBtnDividerS />
      <ViewAllTasksButton project={project} isCard={isCard} />
    </ButtonsWrapper>
  );
}

ProjectSidebarTasksButtons.propTypes = {
  isCard: bool,
  project: shape({
    _id: string.isRequired,
    status: string.isRequired,
    projectId: string.isRequired,
  }),
  collection: string.isRequired,
  formAnchorId: string.isRequired,
  entity: shape({ _id: string.isRequired }),
  readFromCacheParams: shape({
    // eslint-disable-next-line react/forbid-prop-types
    query: object.isRequired,
  }),
};

// isTaskRelatedTab :: {sidebarTab: String, cardTab: String} -> Boolean
const isTaskRelatedTab = R.anyPass([
  R.propEq('sidebarTab', projectSidebarTabs.projectTasks),
  R.propEq('cardTab', projectSidebarTabs.projectTasks),
  R.propEq('sidebarTab', projectSidebarTabs.projectInvoices),
  R.propEq('cardTab', projectSidebarTabs.projectInvoices),
]);

export function ProjectSidebarTasks({ project, isCard = false }) {
  const tasksProps = useProjectSidebarTasksProps(project, isCard);

  const { tableProps, isRowDisabled, readFromCacheParams } = useTasksTabLogic(
    tasksProps,
    true,
  );
  const tabs = useRouterQuery(['sidebarTab', 'cardTab']);
  const { isSidebarOpened } = useOutSidebarContext();

  const formAnchorId = `${project._id}${isCard ? '-card' : ''}`;

  const isTasksTabOpen = isSidebarOpened(projectSidebarTabsId);

  const isRelatedTab = isTaskRelatedTab(tabs);

  const hideCardTasks = isCard && !isTasksTabOpen && isRelatedTab;

  const hideSidebarTasks = !isCard && isTasksTabOpen && isRelatedTab;

  if (hideCardTasks || hideSidebarTasks) return null;

  return (
    <SectionWrapper skipMargin>
      <SidebarRow justify>
        <SectionLabel>Project Tasks</SectionLabel>
        <ProjectSidebarTasksButtons
          isCard={isCard}
          project={project}
          formAnchorId={formAnchorId}
          readFromCacheParams={readFromCacheParams}
          {...tasksProps}
        />
      </SidebarRow>
      <FormPortalAnchor id={formAnchorId} />
      <SidebarTasks
        {...tableProps}
        isRowDisabled={isRowDisabled}
        minHeightLess
      />
    </SectionWrapper>
  );
}

ProjectSidebarTasks.propTypes = {
  isCard: bool,
  project: shape({ _id: string.isRequired }),
};
