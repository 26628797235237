import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState, useMemo } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { commonEmailValidators } from 'poly-client-utils/src/formValidators.js';
import { CLIENT_PORTAL_APP_NAME, ASSET_SCANNER_APP_NAME } from 'poly-security';
import { isNilOrEmpty, validateEmail, assocBy } from 'poly-utils';
import { UserStatuses } from 'poly-constants';
import {
  useMapConfigToTablePropsWithSorting,
  useOnSubmitSetStopSubmitting,
  highlightedTextPropType,
  InternationalPhoneInput,
  useNotificationState,
  PhoneNumberInput,
  SearchInputComp,
  FlexContainer,
  FlexColumn,
  halfWidth,
  MAX_ITEMS,
  ExtInput,
  UserGroupSelect,
} from 'poly-admin-ui';
import {
  highlightMatchesInObjectCurried,
  filterDocumentsMatchedByPaths,
  useForgotPasswordLogic,
  capitalizeFirstLetter,
  validatePhone,
  ifNotEmpty,
} from 'poly-client-utils';
import {
  useNotificationContext,
  FormFieldLabel,
  getThemeColor,
  defaultTheme,
  FormCreator,
  LinkButton,
  BodyRow,
  Select,
  Button,
  Loader,
  Input,
  Table,
  Icon,
  Text,
} from 'poly-book-admin';

import {
  CloseSidebarFormIcon,
  CloseSidebarFormButton,
} from '../../components/commonSidebarFormComponents.js';
import { ExistingContactMessage } from './components/ExistingContactMessage.js';
import { FlexRow } from '../../../components/appBar/HistoryPopUp/historyComponents.js';
import { ErrorMessage } from '../../../modules/pagesHeaders/SupplierSearchPageHeader/filter/styles.js';
import {
  isClientPortalUserGroupUser,
  isKitchenUserGroupUser,
} from './form/formatUserInput.js';

const {
  colors: { primaryLight },
} = defaultTheme;

const MULTIPLE_USER_GROUPS_ASSIGNED_ERROR = `
  Unable edit this user,
  because it has multiple user groups assigned.
  Please, use User Groups management UI or contact Super Admin
`;

const statusesOptions = [
  {
    value: UserStatuses.ACTIVE,
    label: capitalizeFirstLetter(UserStatuses.ACTIVE),
  },
  {
    value: UserStatuses.INACTIVE,
    label: capitalizeFirstLetter(UserStatuses.INACTIVE),
  },
];

function StatusDropDown(props) {
  return <Select {...props} options={statusesOptions} />;
}

// isEmailRequiredByFormValues :: [UserGroup] -> FormValues -> Bool
const isEmailRequiredByFormValues = (userGroupList) =>
  isClientPortalUserGroupUser(userGroupList);

const EmailInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const EmailLabel = styled(Text)`
  font-size: 12px;
  padding-bottom: 8px;
`;

// validateUserEmail :: String -> Boolean
const validateUserEmail = R.complement(validateEmail);

export function SendPasswordResetButton({ email }) {
  const { showSuccessNotification, showErrorNotification } =
    useNotificationContext();

  const showSuccessMessage = () =>
    showSuccessNotification(`Password reset has been sent to ${email}`);

  const { loading, onSubmit, error } =
    useForgotPasswordLogic(showSuccessMessage);

  useEffect(() => {
    if (error) {
      showErrorNotification(error);
    }
  }, [error]);

  const disabled = validateUserEmail(email);

  const handleClick = async (e) => {
    e.preventDefault();
    await onSubmit(email);
  };

  return (
    <LinkButton onClick={handleClick} loader={loading} disabled={disabled}>
      Send Password Reset
    </LinkButton>
  );
}

SendPasswordResetButton.propTypes = {
  email: string,
};

function ClientUserEmailInput({
  formData,
  withResetPasswordBtn,
  userGroupList,
  ...props
}) {
  const showResetPasswordBtn =
    withResetPasswordBtn && formData.isClientPortalUser;

  return (
    <EmailInputWrapper>
      <LabelWrapper>
        <EmailLabel>Email</EmailLabel>
        {showResetPasswordBtn && <SendPasswordResetButton {...formData} />}
      </LabelWrapper>

      <Input
        {...props}
        placeholder="Email"
        required={isEmailRequiredByFormValues(userGroupList)(formData)}
      />
    </EmailInputWrapper>
  );
}

ClientUserEmailInput.propTypes = {
  formData: shape({}),
  withResetPasswordBtn: bool,
  userGroupList: arrayOf(shape({})),
};

// filterOptionsForEnabledApps :: (Boolean, Boolean) -> [UserGroup] -> [UserGroup]
const filterOptionsForEnabledApps = (
  isAssetScannerEnabled,
  isClientPortalEnabled,
) =>
  R.compose(
    R.flatten,
    R.juxt([
      R.ifElse(
        R.always(isAssetScannerEnabled),
        R.filter(R.propEq('defaultLoginApp', ASSET_SCANNER_APP_NAME)),
        R.always([]),
      ),
      R.ifElse(
        R.always(isClientPortalEnabled),
        R.filter(R.propEq('defaultLoginApp', CLIENT_PORTAL_APP_NAME)),
        R.always([]),
      ),
    ]),
  );

const getClientPortalUsersFormSections = (
  onCancel,
  onConvertUser,
  withResetPasswordBtn,
  userGroupList,
  isAssetScannerEnabled,
  isClientPortalEnabled,
) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        order: 0,
        layout: { row: 0 },
        renderIf: R.prop('isUserWithMoreThenOneUserGroup'),
        field: {
          name: 'warningMessage',
          Component: ErrorMessage,
          additionalProps: { children: MULTIPLE_USER_GROUPS_ASSIGNED_ERROR },
        },
      },
      {
        label: 'Status',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'status',
          Component: StatusDropDown,
        },
        required: true,
      },
      {
        label: 'User Group',
        order: 2,
        layout: { row: 1, width: halfWidth, padding: '0 0 5px 0' },
        required: true,
        field: {
          name: 'userGroupId',
          withFormData: true,
          Component: UserGroupSelect,
          additionalProps: {
            filterUserGroups: filterOptionsForEnabledApps(
              isAssetScannerEnabled,
              isClientPortalEnabled,
            ),
          },
        },
      },
      {
        order: 1,
        layout: { row: 2, width: halfWidth, padding: '0 0 5px 0' },
        field: {
          name: 'email',
          withFormData: true,
          additionalProps: { withResetPasswordBtn, userGroupList },
          Component: ClientUserEmailInput,
        },
        validators: commonEmailValidators,
      },
      {
        label: 'Login Cell Phone Number',
        order: 2,
        layout: { row: 2, width: halfWidth, padding: '0 0 5px 0' },
        field: {
          name: 'loginCellPhoneNumber',
          Component: InternationalPhoneInput,
        },
        renderIf: isKitchenUserGroupUser(userGroupList),
        required: true,
      },
      {
        order: 3,
        layout: { row: 3 },
        field: {
          name: 'contact',
          withFormData: true,
          withChangeFieldValue: true,
          additionalProps: { onCancel, onConvertUser, userGroupList },
          Component: ExistingContactMessage,
        },
        renderIf: R.prop('isCreateMode'),
      },
      {
        label: 'First Name',
        order: 4,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'firstName',
          Component: (props) => <Input {...props} placeholder="First Name" />,
        },
        validators: [[R.identity, 'You must enter a name']],
        required: true,
      },
      {
        label: 'Last Name',
        order: 5,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'lastName',
          Component: (props) => <Input {...props} placeholder="Last Name" />,
        },
        validators: [[R.identity, 'Last name is required']],
        required: true,
      },
      {
        label: 'Work Phone',
        order: 7,
        layout: {
          row: 6,
          width: 'calc(30% - 10px)',
        },
        field: {
          name: 'workPhone',
          Component: (props) => (
            <PhoneNumberInput
              name="workPhone"
              placeholder="Work Phone"
              {...props}
            />
          ),
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
      {
        label: 'Ext',
        order: 8,
        layout: {
          row: 6,
          width: 'calc(20% - 20px)',
        },
        field: {
          name: 'ext',
          Component: ExtInput,
        },
      },
      {
        label: 'Mobile Phone',
        order: 9,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'mobilePhone',
          Component: (props) => (
            <PhoneNumberInput
              name="mobilePhone"
              placeholder="Mobile Phone"
              {...props}
            />
          ),
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
    ],
  },
];

const clientPropertiesQuery = gql`
  query clientPropertiesQuery(
    $clientId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    client(id: $clientId) {
      _id
      apps
      searchProperties(input: $searchInput) {
        hits {
          _id
          name
          isMaster
          masterProperty {
            _id
            name
            isMaster
            address {
              formatted_address
            }
          }
          address {
            formatted_address
          }
        }
        total
      }
    }
  }
`;

const PropertyNameComponentS = styled(LinkButton)`
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    margin-right: 5px;
  }
`;

function PropertyNameComponent({
  isMaster,
  isExpanded,
  toggleExpanded,
  highlightedName,
}) {
  return isMaster ? (
    <PropertyNameComponentS onClick={() => toggleExpanded(!isExpanded)}>
      <Icon
        size={10}
        color={primaryLight}
        name={isExpanded ? 'arrow-up' : 'arrow-down'}
      />
      {highlightedName}
    </PropertyNameComponentS>
  ) : (
    highlightedName
  );
}

PropertyNameComponent.propTypes = {
  isExpanded: bool,
  toggleExpanded: func,
  isMaster: bool.isRequired,
  highlightedName: highlightedTextPropType.isRequired,
};

const tableConfig = [
  ['Property Name', PropertyNameComponent, R.prop('name')],
  [
    'Address',
    R.prop(['highlightedAddress']),
    R.path(['address', 'formatted_address']),
  ],
];

const PropertiesListContainer = styled(FlexContainer)`
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const Container = styled(FlexColumn)`
  height: 100%;
  padding: 24px 24px 10px 24px;
`;

const SelectPropertiesTableBase = styled(Table)`
  td:nth-child(1),
  th:nth-child(1) {
    width: 30px;
  }
`;

const SubPropertyTableRowS = styled(BodyRow)`
  padding-left: 20px;
`;

// getSubPropertiesForMaster :: { row: Property } -> [Property]
const getSubPropertiesForMaster = R.pathOr([], ['row', 'subProperties']);

function MasterPropertyTableRow(props) {
  const { row } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const subProperties = getSubPropertiesForMaster(props);

  return (
    <>
      <BodyRow
        {...props}
        row={{ ...row, isExpanded, toggleExpanded: setIsExpanded }}
      />
      {isExpanded &&
        subProperties.map((subProperty) => (
          <SubPropertyTableRowS
            {...props}
            row={subProperty}
            key={subProperty._id}
          />
        ))}
    </>
  );
}

MasterPropertyTableRow.propTypes = { row: shape({ _id: string.isRequired }) };

// checkIsMasterPropertyRow :: { row: Property } -> Boolean
const checkIsMasterPropertyRow = R.pathEq(['row', 'isMaster'], true);

function PropertyTableRow(props) {
  const isMasterProperty = checkIsMasterPropertyRow(props);

  const RowComponent = isMasterProperty ? MasterPropertyTableRow : BodyRow;

  return <RowComponent {...props} />;
}

// prepareMasterPropertiesWithSub :: [Property] -> [Property]
const prepareMasterPropertiesWithSub = (list) =>
  R.compose(
    R.filter(R.propSatisfies(isNilOrEmpty, 'masterProperty')),
    R.map(
      R.when(
        R.prop('isMaster'),
        assocBy(
          'subProperties',
          R.compose(
            (id) => R.filter(R.pathEq(['masterProperty', '_id'], id))(list),
            R.prop('_id'),
          ),
        ),
      ),
    ),
  )(list);

// getSelectedPropertyWithSubs :: ID -> [Property] -> [ID]
const getSelectedPropertyWithSubs = (rowId) =>
  R.converge(R.concat, [
    R.compose(R.of, R.always(rowId)),
    R.compose(
      R.map(R.prop('_id')),
      R.defaultTo([]),
      R.prop('subProperties'),
      R.find(R.propEq('_id', rowId)),
    ),
  ]);

// checkAllRowsSelected :: { selectedRows: [ID], rows: [Property] } -> Boolean
const checkAllRowsSelected = R.compose(
  R.isEmpty,
  R.converge(R.without, [
    R.prop('selectedRows'),
    R.compose(R.map(R.prop('_id')), R.prop('rows')),
  ]),
);

const checkRowsArePartiallySelected = R.both(
  R.propSatisfies(R.complement(R.isEmpty), 'selectedRows'),
  R.compose(R.not, checkAllRowsSelected),
);

function SelectPropertiesTable({
  properties,
  selectedProperties,
  setSelectedProperties,
}) {
  const preparedProperties = useMemo(
    () => prepareMasterPropertiesWithSub(properties),
    [properties],
  );

  const toggleRow = (rowId) => {
    const selectedWithSubs =
      getSelectedPropertyWithSubs(rowId)(preparedProperties);
    if (selectedProperties.includes(rowId)) {
      setSelectedProperties(R.without(selectedWithSubs, selectedProperties));
    } else {
      setSelectedProperties(R.concat(selectedProperties, selectedWithSubs));
    }
  };

  const toggleSelectAll = () => {
    if (selectedProperties.length === properties.length) {
      setSelectedProperties([]);
    } else {
      setSelectedProperties(properties.map((x) => x._id));
    }
  };

  const tableProps = useMapConfigToTablePropsWithSorting({
    tableConfig,
    items: preparedProperties,
    initialSorting: { columnKey: 0, dir: 1 },
  });

  return (
    <SelectPropertiesTableBase
      isSortable
      showAllSelector
      toggleRow={toggleRow}
      rows={tableProps.rows}
      columns={tableProps.columns}
      headers={tableProps.headers}
      sorting={tableProps.sorting}
      RowComponent={PropertyTableRow}
      selectedRows={selectedProperties}
      toggleSelectAll={toggleSelectAll}
      sortQueries={tableProps.sortQueries}
      checkAllRowsSelected={checkAllRowsSelected}
      onHeaderCellClick={tableProps.onHeaderCellClick}
      checkRowsArePartiallySelected={checkRowsArePartiallySelected}
    />
  );
}

SelectPropertiesTable.propTypes = {
  selectedProperties: arrayOf(string),
  setSelectedProperties: func.isRequired,
  properties: arrayOf(shape({ _id: string.isRequired })),
};

const ButtonsContainer = styled(FlexContainer)`
  height: 40px;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
`;

const FormCreatorBase = styled(FormCreator)`
  height: auto;
`;

const NoteContainer = styled.span`
  font-size: 11px;
  color: ${getThemeColor(['secondary'])};
`;

const FlexRowS = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
`;

// highLevelValidateEmail :: [UserGroup] -> FormValues -> {email: String}
const highLevelValidateEmail = (userGroupList) =>
  R.ifElse(
    R.compose(
      R.both(
        isEmailRequiredByFormValues(userGroupList),
        R.propSatisfies(R.either(R.isEmpty, R.isNil), 'email'),
      ),
    ),
    R.always({ email: 'Email is required' }),
    R.always({}),
  );

// getClientApps :: { client: Client } -> [String]
const getClientApps = R.pathOr([], ['client', 'apps']);

const searchTermPaths = [['name'], ['address', 'formatted_address']];

// prepareProperties :: String -> { client: Client } -> [Property]
const prepareProperties = (searchTerm) =>
  R.compose(
    R.map(
      R.compose(
        assocBy(
          'highlightedName',
          R.compose(
            R.prop('name'),
            highlightMatchesInObjectCurried(searchTermPaths, searchTerm),
          ),
        ),
        assocBy(
          'highlightedAddress',
          R.compose(
            R.path(['address', 'formatted_address']),
            highlightMatchesInObjectCurried(searchTermPaths, searchTerm),
          ),
        ),
      ),
    ),
    R.uniqBy(R.prop('_id')),
    R.converge(R.concat, [
      R.compose(R.reject(isNilOrEmpty), R.map(R.prop('masterProperty'))),
      R.identity,
    ]),
    filterDocumentsMatchedByPaths(searchTermPaths, searchTerm),
    R.pathOr([], ['client', 'searchProperties', 'hits']),
  );

export function ClientUserFormBase({
  title,
  formId,
  mutate,
  clientId,
  onCancel,
  initialValues,
  userGroupList,
  onConvertUser,
  successMessage,
  withResetPasswordBtn,
  isAssetScannerEnabled,
  isClientPortalEnabled,
  selectedPropertiesInitial,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const { showSuccessNotification } = useNotificationState();
  const { [formId]: loading } = useSelector(R.prop('processes'));
  const [selectedProperties, setSelectedProperties] = useState(
    selectedPropertiesInitial,
  );

  const { isUserWithMoreThenOneUserGroup } = initialValues;

  useEffect(() => {
    if (!clientId) {
      onCancel();
    }
  }, [clientId]);

  const { data, loading: fetchingClient } = useQuery(clientPropertiesQuery, {
    variables: {
      clientId,
      searchInput: {
        from: 0,
        size: MAX_ITEMS,
      },
    },
    skip: !clientId,
  });
  const properties = prepareProperties(searchTerm)(data);
  const clientApps = getClientApps(data);

  const onSubmitHandler = async (values) => {
    await mutate({ ...values, selectedProperties });
    showSuccessNotification(successMessage);
    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  if (fetchingClient) {
    return <Loader />;
  }

  return (
    <Container>
      <HeaderContainer>
        <span>{title}</span>
        <CloseSidebarFormIcon onCancel={onCancel} />
      </HeaderContainer>
      <FormCreatorBase
        id={formId}
        onSubmit={onSubmit}
        layout={{ card: false }}
        validate={highLevelValidateEmail(userGroupList)}
        initialValues={{ ...initialValues, clientApps }}
        sections={getClientPortalUsersFormSections(
          onCancel,
          onConvertUser,
          withResetPasswordBtn,
          userGroupList,
          isAssetScannerEnabled,
          isClientPortalEnabled,
        )}
      />
      <FlexColumn>
        <FormFieldLabel padding="0">Properties</FormFieldLabel>
        <FlexRowS>
          <NoteContainer>
            Note: if no properties are selected, user will be granted access to
            all properties.
          </NoteContainer>
          <SearchInputComp
            name="search-client-portal-properties"
            placeholder="Search properties"
            onChange={(a) => setSearchTerm(a.target.value)}
            value={searchTerm}
          />
        </FlexRowS>
      </FlexColumn>
      <PropertiesListContainer>
        <SelectPropertiesTable
          properties={properties}
          selectedProperties={selectedProperties}
          setSelectedProperties={setSelectedProperties}
        />
      </PropertiesListContainer>
      <ButtonsContainer>
        <Button
          size="small"
          form={formId}
          loader={loading}
          disabled={loading || isUserWithMoreThenOneUserGroup}
        >
          {title}
        </Button>
        <CloseSidebarFormButton size="small" onCancel={onCancel} />
      </ButtonsContainer>
    </Container>
  );
}

ClientUserFormBase.propTypes = {
  clientId: string.isRequired,
  formId: string.isRequired,
  initialValues: shape({
    status: string.isRequired,
  }).isRequired,
  mutate: func.isRequired,
  title: string.isRequired,
  selectedPropertiesInitial: arrayOf(string),
  successMessage: string,
  onCancel: func.isRequired,
  onConvertUser: func,
  withResetPasswordBtn: bool,
  userGroupList: arrayOf(shape({})),
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};

ClientUserFormBase.defaultProps = {
  selectedPropertiesInitial: [],
};
