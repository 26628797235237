import * as R from 'ramda';
import React from 'react';
import { insertParamsIntoURL } from 'poly-utils';
import { func } from 'prop-types';
import { InputWithSimilarItemsComp } from '../../../../fields/InputWithDuplicateSearch/InputWithSimilarItemsComp.js';
import { SUPPLIERS_SEARCH_NAME } from '../../../../../../hocs/suppliers/queries.js';
import { useEntitySimilarItems } from '../../../../fields/InputWithDuplicateSearch/index.js';

const itemsFromProps = ({ data, ownProps }) => ({
  items: R.pipe(
    R.pathOr([], ['searchSuppliers', 'hits']),
    R.reject(R.propEq('_id', ownProps.skipSupplierId)),
    R.map(
      R.applySpec({
        url: ({ _id }) =>
          insertParamsIntoURL({ supplierId: _id }, ownProps.rootUrl),
        name: R.path(['company', 'name']),
      }),
    ),
  )(data),
});

const queryVariablesFromProps = ({ nameSearch }) => ({
  searchInput: {
    size: 3,
    query: {
      bool: {
        should: [
          {
            term: {
              'company.name.keyword': { value: nameSearch, boost: 3 },
            },
          },
          {
            match: {
              'company.name': {
                query: nameSearch,
                operator: 'and',
                fuzziness: 'AUTO',
              },
            },
          },
        ],
      },
    },
  },
});

export function SupplierNameInput(props) {
  const entityProps = useEntitySimilarItems({
    query: SUPPLIERS_SEARCH_NAME,
    itemsFromProps,
    variablesFromProps: queryVariablesFromProps,
    onChange: props.onChange,
    propsOfComponent: props,
  });

  return <InputWithSimilarItemsComp {...entityProps} />;
}

SupplierNameInput.propTypes = {
  onChange: func,
};
