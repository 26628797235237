import * as R from 'ramda';
import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { useSetItemsCount, usePaginationParams } from 'poly-admin-ui';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { ClientInvoiceHistoryHeader } from './ClientInvoiceHistoryHeader.js';
import { ClientInvoiceHistoryBody } from './ClientInvoiceHistoryBody.js';

const clientInvoiceHistoryQuery = gql`
  query CLIENT_INVOICE_HISTORY($input: ClientInvoicesInput!) {
    clientInvoices(input: $input) {
      hits {
        _id
        amount
        number
        status
        paidAt
        createdAt
        invoiceDate
        balance
        dueDate
        project {
          type
          projectId
        }
      }
      total
    }
  }
`;

const invoicesSortConfig = { status: -1, invoiceDate: 1 };

export function ClientInvoiceHistoryPage() {
  const [filters, setFilters] = useState(null);

  const paginationParams = usePaginationParams();

  const { data, loading } = useQuery(clientInvoiceHistoryQuery, {
    variables: {
      input: { sort: invoicesSortConfig, ...filters, ...paginationParams },
    },
    skip: !filters,
    fetchPolicy: 'network-only',
  });

  useSetItemsCount(R.pathOr(0, ['clientInvoices', 'total']), data);

  const clientInvoices = R.pathOr([], ['clientInvoices', 'hits'], data);

  return (
    <PageWithSearchHeader headerHeight="115px">
      <ClientInvoiceHistoryHeader
        setClientInvoicesFilters={setFilters}
        clientInvoices={clientInvoices}
        loading={loading}
      />
      <ClientInvoiceHistoryBody
        loading={loading}
        clientInvoices={clientInvoices}
      />
    </PageWithSearchHeader>
  );
}
