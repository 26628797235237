import { InputHTML5 } from 'poly-book-admin';
import { bool, func, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { treeNodePropTypes } from '../propTypes.js';
import { classnames } from './utils.js';

const InputBase = styled(InputHTML5)`
  height: 100%;
  width: 100%;
  input {
    border: none;
    width: 100%;
    height: 100%;
  }
`;

const styles = {
  overflow: 'hidden',
};

export function TreeNodeContent({
  node,
  onChangeTitle,
  rowDirectionClass,
  editable,
  disabled,
  borderHidden,
  NodeContentRenderer,
}) {
  return (
    <div
      className={classnames('rst__rowLabel', rowDirectionClass)}
      style={{
        ...(borderHidden ? { ...styles, borderRight: 'none' } : styles),
      }}
    >
      {editable ? (
        <InputBase
          changeOnBlur
          value={node.title}
          onChange={onChangeTitle}
          disabled={disabled}
          name="tree-node-title-input"
        />
      ) : (
        <NodeContentRenderer {...node} />
      )}
    </div>
  );
}

function DefaultContentRenderer(node) {
  return node.title;
}

TreeNodeContent.propTypes = {
  node: treeNodePropTypes,
  onChangeTitle: func,
  rowDirectionClass: string,
  editable: bool,
  disabled: bool,
  borderHidden: bool,
  NodeContentRenderer: func,
};

TreeNodeContent.defaultProps = {
  NodeContentRenderer: DefaultContentRenderer,
};
