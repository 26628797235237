import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { defaultTheme, Status } from 'poly-book-admin';
import { mentionGroupStatuses } from 'poly-constants';
import { useSelector } from 'react-redux';
import { useReactiveQuery } from 'poly-client-utils';
import { formatCount, forceTitleCase } from 'poly-utils';

import { MENTION_GROUPS_SEARCH_QUERY } from '../../core/hooks/mentionGroups/queries.js';
import { MENTION_GROUP_SEARCH_CHANGED } from '../../core/hooks/mentionGroups/subscriptions.js';
import { getMentionGroupQueryByStatus } from '../../core/searchQueries/mentionGroups.js';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

const { ACTIVE, INACTIVE } = mentionGroupStatuses;

const statusColorMap = {
  [ACTIVE]: active,
  [INACTIVE]: inactive,
};

const useGetMentionGroups = (query) => {
  const searchText = useSelector(R.prop('searchText'));

  const searchInputParams = {
    size: 0,
    ...(searchText ? { searchTerm: searchText } : {}),
    query,
  };

  return useReactiveQuery(
    MENTION_GROUPS_SEARCH_QUERY,
    MENTION_GROUP_SEARCH_CHANGED,
    {
      queryOptions: {
        variables: {
          searchInput: searchInputParams,
        },
      },
      subscriptionOptions: {
        variables: {
          searchInput: searchInputParams,
        },
      },
    },
  );
};

// getMentionGroupsCount :: SearchMentionGroups -> String
const getMentionGroupsCount = R.compose(
  formatCount,
  R.pathOr(0, ['searchMentionGroups', 'total']),
);

export function MentionGroupsTabTitle({ status }) {
  const { data } = useGetMentionGroups(getMentionGroupQueryByStatus(status));

  const textWithCount = `${forceTitleCase(status)} (${getMentionGroupsCount(
    data,
  )})`;
  return <Status text={textWithCount} color={statusColorMap[status]} />;
}

MentionGroupsTabTitle.propTypes = { status: string.isRequired };
