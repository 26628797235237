import React from 'react';
import * as R from 'ramda';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { gql, useMutation } from '@apollo/client';
import { FieldArray } from 'react-final-form-arrays';
import { arrayOf, number, shape, string, func } from 'prop-types';
import { FormFieldLabel, TextButton } from 'poly-book-admin';
import { useNotificationState } from 'poly-admin-ui';
import { removePropDeep } from 'poly-utils';

import {
  getCalcWidth,
  CustomRateField,
  CustomRateLabel,
  CustomRateFieldGroup,
  CustomRateAddButtonWrapper,
} from './commonComponents.js';
import { RateInputField } from './RateInputField.js';
import { CustomRateLine } from './CustomRateLine.js';

export const updateClientRatesMutation = gql`
  mutation editClientRatesMutation($input: UpdateClientStaffRatesInput!) {
    updateClientRates(input: $input) {
      _id
    }
  }
`;

// prepareClientRatesInitialValues :: ClientStaffRates -> ClientStaffRates
const prepareClientRatesInitialValues = R.compose(
  removePropDeep('__typename'),
  R.over(
    R.lensProp('customRates'),
    R.compose(R.when(R.isEmpty, R.append({})), R.defaultTo([])),
  ),
);

// prepareCustomRatesInput :: [ClientCustomStaffRate] -> [ClientCustomStaffRate]
const prepareCustomRatesInput = R.compose(
  R.map(R.pick(['description', 'rate', 'overtime', 'doubleTime'])),
  R.defaultTo([]),
);

export function EditClientRatesForm({
  formId,
  clientId,
  staffRates,
  onCancel,
}) {
  const { showSuccessNotification } = useNotificationState();
  const [updateClientRates] = useMutation(updateClientRatesMutation);

  const onSubmit = async ({ afterHoursCall, customRates }) => {
    const input = {
      clientId,
      afterHoursCall,
      customRates: prepareCustomRatesInput(customRates),
    };
    await updateClientRates({ variables: { input } });
    showSuccessNotification('Client Rates successfully updated');
    onCancel();
  };

  return (
    <Form
      onSubmit={onSubmit}
      validateOnBlur
      initialValues={prepareClientRatesInitialValues(staffRates)}
      mutators={{ ...arrayMutators }}
    >
      {({
        handleSubmit,
        form: {
          mutators: { push },
        },
      }) => (
        <form
          onSubmit={handleSubmit}
          id={formId}
          {...{ 'data-testid': 'editClientRatesFormTestId' }}
        >
          <CustomRateField>
            <FormFieldLabel htmlFor="afterHoursCall">
              After Hours Call
            </FormFieldLabel>
            <RateInputField
              id="afterHoursCall"
              name="afterHoursCall"
              dataTestId="afterHoursCall"
              requiredName="After Hours Call"
            />
          </CustomRateField>
          <CustomRateFieldGroup>
            <CustomRateLabel width={getCalcWidth(34)}>
              Description
            </CustomRateLabel>
            <CustomRateLabel width={getCalcWidth(22)}>Rate</CustomRateLabel>
            <CustomRateLabel width={getCalcWidth(22)}>Overtime</CustomRateLabel>
            <CustomRateLabel width={getCalcWidth(22)}>
              Double Time
            </CustomRateLabel>
          </CustomRateFieldGroup>
          <FieldArray name="customRates">
            {({ fields }) =>
              fields.map((name, index) => (
                <CustomRateLine
                  key={name}
                  id={name}
                  name={name}
                  index={index}
                  value={R.pathOr({}, ['value', index], fields)}
                  remove={fields.remove}
                  update={fields.update}
                />
              ))
            }
          </FieldArray>
          <CustomRateAddButtonWrapper>
            <TextButton onClick={() => push('customRates', undefined)}>
              Add
            </TextButton>
          </CustomRateAddButtonWrapper>
        </form>
      )}
    </Form>
  );
}

export const staffRatesPropType = shape({
  afterHoursCall: number,
  customRates: arrayOf(
    shape({
      description: string.isRequired,
      rate: number.isRequired,
      overtime: number.isRequired,
      doubleTime: number.isRequired,
    }),
  ),
});

EditClientRatesForm.propTypes = {
  formId: string.isRequired,
  clientId: string.isRequired,
  staffRates: staffRatesPropType,
  onCancel: func.isRequired,
};
