import { UpdateTypes } from 'poly-constants';

export const MagicEmailSeparators = {
  NAME: '+',
  PAYLOAD: '=',
};

export const getMagicEmailForDocument =
  (emailAddress) =>
  ({ collection, documentId, isClientUpdate = false }) => {
    const [prefix, suffix] = emailAddress.split('@');
    const { NAME, PAYLOAD } = MagicEmailSeparators;
    const collectionPart = collection ? `${collection}${PAYLOAD}` : '';
    const commonPart = `${prefix}${NAME}${collectionPart}${documentId}`;
    const updateTypePart = isClientUpdate
      ? `${PAYLOAD}${UpdateTypes.CLIENT_REPORT}`
      : '';
    return `${commonPart}${updateTypePart}@${suffix}`;
  };
