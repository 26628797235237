import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo } from 'react';
import { func } from 'prop-types';
import { ProjectTypeToNameMap, ProjectType } from 'poly-constants';
import { getThemeColor } from 'poly-book-admin';

import { BillingProfileConfigMultiSelect } from './BillingProfileConfigMultiSelect.js';
import {
  onMultipleSelectValueChange,
  prepareMultipleSelectValue,
} from '../utils.js';
import { multiSelectorValueProperTypes } from './common.js';

const BillingProfileConfigMultiSelectS = styled(
  BillingProfileConfigMultiSelect,
)`
  .multiselect__placeholder {
    color: ${getThemeColor(['primary'])};
  }
`;

// getProjectTypeOptions :: ProjectTypeToNameMap -> [Option]
const getProjectTypeOptions = R.compose(
  R.map(R.applySpec({ value: R.head, label: R.last })),
  R.toPairs,
  // we exclude housekeeping projects for consolidation
  // https://gitlab.com/askpoly/poly-apps/-/issues/4021#note_1327308727
  R.dissoc(ProjectType.HOUSEKEEPING),
);

export function MultipleProjectTypeSelect({ value, onChange, ...props }) {
  const options = getProjectTypeOptions(ProjectTypeToNameMap);

  const preparedValue = useMemo(
    () => prepareMultipleSelectValue(value, options),
    [value, options],
  );

  const selectProps = {
    ...props,
    options,
    placeholder: 'All',
    value: preparedValue,
    handleChange: onMultipleSelectValueChange(onChange),
  };

  return <BillingProfileConfigMultiSelectS {...selectProps} />;
}

MultipleProjectTypeSelect.propTypes = {
  value: multiSelectorValueProperTypes,
  onChange: func.isRequired,
};
