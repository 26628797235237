import React from 'react';
import { useForgotPasswordLogic, InputSection } from 'poly-client-utils';
import { func, string } from 'prop-types';
import styled from 'styled-components';

import {
  ErrorText,
  Footer,
  Label,
  LoginButton,
  LoginPageLayout,
} from '../Components.js';
import { ForgotPasswordLink } from '../LoginPageBase/LoginForm.js';
import { ButtonLoader } from '../Loader.js';

const LoginLinkContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export function ForgotPasswordPageBase({ onSuccess, title, goToLogin }) {
  const { loading, error, onSubmit } = useForgotPasswordLogic(onSuccess);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(event.target.elements.email.value);
  };

  return (
    <LoginPageLayout onSubmit={handleSubmit} title={title}>
      <InputSection
        title="Email"
        inputProps={{
          type: 'email',
          name: 'email',
          placeholder: 'Email Address',
          autoFocus: true,
          required: true,
          lower: true,
        }}
      />
      {error && <ErrorText>{error}</ErrorText>}
      <Footer>
        <LoginLinkContainer>
          <Label>Have an account?</Label>
          <ForgotPasswordLink onClick={goToLogin}>
            Login here
          </ForgotPasswordLink>
        </LoginLinkContainer>
        <LoginButton type="submit" disabled={loading}>
          {loading && <ButtonLoader />}
          Reset Password
        </LoginButton>
      </Footer>
    </LoginPageLayout>
  );
}

ForgotPasswordPageBase.propTypes = {
  onSuccess: func.isRequired,
  title: string.isRequired,
  goToLogin: func.isRequired,
};
