import * as R from 'ramda';
import { isEqual } from 'date-fns';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useApolloClient, gql } from '@apollo/client';
import { useNotificationState, useModalContext } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';
import { useNavigate } from 'poly-client-routing';
import {
  ProjectSpendTypes,
  InvoicesStatuses,
  WorkOrderStatus,
} from 'poly-constants';
import {
  applySpecWithFields,
  insertParamsIntoURL,
  ensureIsDate,
  debounce,
} from 'poly-utils';

import {
  UPDATE_RWO_MUTATION,
  UPDATE_PROJECT_MUTATION,
} from '../../../../modules/core/hooks/projects/mutations.js';
import { editProjectFormId } from '../../constants.js';
import { routesNames } from '../../../../routes/constants.js';
import { setPreventativeRepairChanged } from '../../../../redux/preventativeRepairChangedReducer.js';
import { useClearPristineState } from '../../../useClearPristineOnSidebarMount.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';
import { isRecurringOrPMFormData } from '../form/utils/sections.js';
import { checkUniqueSuppliers } from '../add/useOnSubmitForm.js';
import {
  recurringWOFormDataToMutationData,
  woFormDataToMutationData,
} from '../add/formDataToMutationData.js';

const searchInvoicesQuery = gql`
  query searchInvoicesQuery($input: CollectionSearchParams!) {
    searchInvoices(input: $input) {
      total
    }
  }
`;

// prepareFormDataBeforeMutation :: Object -> Object
const prepareFormDataBeforeMutation = applySpecWithFields({
  nte: R.propOr(null, 'nte'),
  contractLink: R.propOr(null, 'contractLink'),
  clientManagerId: R.propOr(null, 'clientManagerId'),
  scheduleDate: R.propOr(null, 'scheduleDate'),
});

// getRecurringProjectSidebar :: MutationResult -> Object
const getRecurringProjectSidebar = R.compose(
  R.pick(['projectId', 'type']),
  R.path(['data', 'updateRecurringProject']),
);

// getIsPreventativeRepairByKey :: String -> Project -> Boolean
const getIsPreventativeRepairByKey = (key) =>
  R.compose(
    R.equals(ProjectSpendTypes.PREVENTIVE_REPAIR),
    R.path([key, 'spendType']),
  );

// isPreventativeRepairChanged :: { oldProjectFields: Project, newProjectFields: Project } -> Boolean
const isPreventativeRepairChanged = R.converge(R.complement(R.equals), [
  getIsPreventativeRepairByKey('newProjectFields'),
  getIsPreventativeRepairByKey('oldProjectFields'),
]);

// checkDatePropWasChanged :: String -> Boolean
const checkDatePropWasChanged = (propName) =>
  R.converge(R.complement(isEqual), [
    R.compose(ensureIsDate, R.path(['project', propName])),
    R.compose(ensureIsDate, R.path(['update', propName])),
  ]);

// checkCompletedProjectWasBlocked :: { project: Project, update: FormValues } -> Boolean
const checkCompletedProjectWasBlocked = R.both(
  R.either(
    R.pathEq(['project', 'status'], WorkOrderStatus.COMPLETED),
    R.path(['project', 'isRecalled']),
  ),
  R.pathEq(['update', 'status'], WorkOrderStatus.BLOCKED),
);

// getRequestedInvoicesQueryByProject :: Project -> ElasticQuery
const getRequestedInvoicesQueryByProject = R.compose(
  R.objOf('bool'),
  R.objOf('must'),
  R.append({ match: { status: InvoicesStatuses.REQUESTED } }),
  R.of,
  R.objOf('match'),
  R.objOf('documentId'),
  R.prop('_id'),
);

export const useOnSubmitEditProjectForm = (
  saveContacts,
  isCard,
  cleanupRetainedValue,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const clearPristine = useClearPristineState();
  const { openConfirmModal } = useModalContext();
  const { closeOutSidebar } = useOutSidebarContext();
  const { openProjectSidebar } = useSidebarLogicContext();
  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION);
  const [updateMasterProject] = useMutation(UPDATE_RWO_MUTATION);
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();

  const pristineClearDebounced = useCallback(
    debounce(100)(() => clearPristine()),
    [],
  );

  const setPreventativeRepairChangedDebounced = useCallback(
    debounce(300)(() => dispatch(setPreventativeRepairChanged(false))),
    [],
  );

  const navigateToProjectCardDebounced = useCallback(
    debounce(200)((projectId) =>
      navigate(insertParamsIntoURL({ projectId })(routesNames.PROJECT_CARD)),
    ),
    [],
  );

  return async ({ project, update }) => {
    // first save contacts
    const propertyId = R.path(['property', '_id'], update);
    const clientId = R.path(['client', '_id'], update);
    const [siteContactResult, contactResult] = await saveContacts(
      update,
      propertyId,
      clientId,
      ['siteContact', 'contact'],
    );

    if (!checkUniqueSuppliers(update)) {
      return showErrorNotification('Not allowed duplicated suppliers');
    }

    const [toMutation, mutate] = isRecurringOrPMFormData(update)
      ? [recurringWOFormDataToMutationData, updateMasterProject]
      : [woFormDataToMutationData, updateProject];

    const mutationObject = toMutation({
      ...prepareFormDataBeforeMutation(update),
      contactId: R.pathOr(null, ['contactId'], contactResult),
      siteContactId: R.pathOr(null, ['contactId'], siteContactResult),
      isEndDateChanged: checkDatePropWasChanged('endDate')({ project, update }),
    });

    const isProjectPreventativeRepairChanged = isPreventativeRepairChanged({
      oldProjectFields: project,
      newProjectFields: mutationObject,
    });

    if (isProjectPreventativeRepairChanged) {
      dispatch(setPreventativeRepairChanged(true));
    }

    const handleMutationCall = async () => {
      const mutationResult = await mutate({
        variables: { id: project._id, update: mutationObject },
      });

      if (
        isProjectPreventativeRepairChanged &&
        !isRecurringOrPMFormData(update) &&
        !project?.parent?._id
      ) {
        setPreventativeRepairChangedDebounced();
        navigateToProjectCardDebounced(
          mutationResult?.data?.updateProject?.projectId,
        );
      }

      showSuccessNotification('The project was successfully edited');
      cleanupRetainedValue();
      closeOutSidebar(editProjectFormId);

      if (isRecurringOrPMFormData(update) && !isCard) {
        return openProjectSidebar(getRecurringProjectSidebar(mutationResult));
      }

      return pristineClearDebounced();
    };

    const isCompletedProjectBlocked = checkCompletedProjectWasBlocked({
      project,
      update,
    });

    const queryResp = await apolloClient.query({
      query: searchInvoicesQuery,
      variables: {
        input: { query: getRequestedInvoicesQueryByProject(project) },
      },
      fetchPolicy: 'network-only',
    });

    const requestedInvoicesNumber = R.pathOr(
      0,
      ['data', 'searchInvoices', 'total'],
      queryResp,
    );

    if (isCompletedProjectBlocked && requestedInvoicesNumber > 0) {
      openConfirmModal({
        btnCaption: 'Yes',
        cancelBtnCaption: 'No',
        id: 'confirm-blocked-status-modal-id',
        content: `Project you're about to block has ${requestedInvoicesNumber} pending supplier invoice requests. Those would be canceled while blocking. Are you sure?`,
        onConfirm: (closeConfirmModal) => async () => {
          await handleMutationCall();
          closeConfirmModal();
        },
      });
    } else {
      await handleMutationCall();
    }

    return null;
  };
};
