import * as R from 'ramda';
import { INFINITE_SCROLL_MODE_VARIABLE } from 'poly-constants';

// nested fields at keyArgs (missing at docs at this point)
// https://github.com/apollographql/apollo-client/issues/7314#issuecomment-726331129
// support CollectionSearchParams by default
const DEFAULT_KEY_ARGS = ['input', ['searchTerm', 'query', 'sort']];

const getFromSizeCacheKey = (from, size = 'default') =>
  `from=${from},size=${size}`;

const getInputByKeyAndArgs = (keyArgs, args) => {
  const inputProp = keyArgs[0];
  if (args) {
    return args[inputProp];
  }
  return { from: 0, size: 10 };
};

export const fromSizeHitsPagination = (keyArgs = DEFAULT_KEY_ARGS) => ({
  keyArgs,

  read(existing, { args, variables }) {
    if (variables[INFINITE_SCROLL_MODE_VARIABLE] === true) {
      return existing;
    }

    const inputArgs = getInputByKeyAndArgs(keyArgs, args);
    const { from = 0, size } = inputArgs;
    return existing?.[getFromSizeCacheKey(from, size)];
  },

  // based on https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
  merge(existing, incoming, { args, variables }) {
    const inputArgs = getInputByKeyAndArgs(keyArgs, args);
    const { from = 0, size } = inputArgs;

    if (variables[INFINITE_SCROLL_MODE_VARIABLE] === true) {
      const incomingHits = R.propOr([], 'hits', incoming);

      const existingCopy = existing?.hits?.slice(0) || [];
      const merged = existing ? existingCopy : [];
      // Assume an offset of 0 if inputArgs.from is omitted.
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < incomingHits.length; ++i) {
        merged[from + i] = incomingHits[i];
      }

      return { hits: merged, total: incoming?.total };
    }

    return { ...(existing || {}), [getFromSizeCacheKey(from, size)]: incoming };
  },
});

export const accountTransactionsPagination = () => ({
  keyArgs: ['input', ['startDate', 'endDate', 'accountId', 'accountingMethod']],
  merge(existing, incoming) {
    const existingHits = R.propOr([], 'hits', existing);
    const existingCredits = R.propOr(0, 'credits', existing);
    const existingDebits = R.propOr(0, 'debits', existing);
    return {
      // covers any custom fields
      ...incoming,
      // existing has higher priority due to potentially outdated Forward Balance
      ...(existing || {}),
      // accumulate credits and debits
      credits: existingCredits + incoming.credits,
      debits: existingDebits + incoming.debits,
      hits: [...existingHits, ...incoming.hits],
    };
  },
});
