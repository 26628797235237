import React from 'react';
import { useSelector } from 'react-redux';
import { PRINT_TYPES, WorkOrderStatus } from 'poly-constants';
import { useRouterQuery } from 'poly-client-routing';
import { formatDateProp } from 'poly-client-utils';

import { ITEMS_LIMIT } from '../../core/constants/print.js';
import { recentProjectsStatus } from '../../tabs/RecentProjectsTab.js';
import { TablePrintBtn } from '../../core/tableToolbar/TablePrintPDFBtn.js';
import { generateRecentProjectsQuery } from './generateRecentProjectsQuery.js';
import { useProjectsBySearch } from '../../core/hooks/projects/index.js';

const { ACTIVE } = WorkOrderStatus;

export function RecentProjectsPrintPDFBtn() {
  const recentProjects = useSelector((state) => state.recentProjects);

  const { startDate, endDate, clientId } = recentProjects;

  const { tab } = useRouterQuery(['tab']);

  const status = recentProjectsStatus(tab);

  const query = generateRecentProjectsQuery({
    startDate,
    clientId,
    endDate,
    status,
  });

  const skipQuery = !clientId || !startDate || !endDate;

  const { result } = useProjectsBySearch({
    query,
    skipQuery,
  });

  const isActive = status === ACTIVE;

  const data = result.searchProjects?.hits || [];

  const isTooLong = data.length > ITEMS_LIMIT;

  const showWarningModal = result.searchProjects && isTooLong;

  const printType = isActive
    ? PRINT_TYPES.RECENT_ACTIVE_PROJECTS
    : PRINT_TYPES.RECENT_COMPLETED_PROJECTS;

  const metaData = {
    title: `${isActive ? 'Active' : 'Completed'} Projects: ${
      isTooLong ? ITEMS_LIMIT : result.searchProjects?.hits.length || 0
    }`,
  };

  const additional = `${formatDateProp('startDate')(
    recentProjects,
  )} - ${formatDateProp('endDate')(recentProjects)}`;

  return (
    <TablePrintBtn
      isActive={isActive}
      isTooLong={isTooLong}
      data={data}
      showWarningModal={showWarningModal}
      printType={printType}
      metaData={metaData}
      additional={additional}
    />
  );
}
