import { usePagination } from 'poly-admin-ui';
import {
  keywordSortQuery,
  useSubscriptionByChanges,
  useEntitiesByReactiveReduxSearch,
} from 'poly-client-utils';
import { ASC_SORT_ORDER } from 'poly-constants';
import { CLIENTS_BY_SEARCH } from './queries.js';
import { CLIENTS_BY_SEARCH_SUB } from './subscriptions.js';

import { PROPERTIES_BY_SEARCH_SUB } from '../properties/subscriptions.js';

export const useClientsBySearch = ({
  status,
  sort = keywordSortQuery(['name'])(ASC_SORT_ORDER),
  fetchPolicy = 'network-only',
  propsOfComponent = {},
  alias = 'useClientsBySearch',
}) => {
  const { pagination } = usePagination();

  const query = { match: { status } };

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: CLIENTS_BY_SEARCH,
    gqlSearchChangedQuery: CLIENTS_BY_SEARCH_SUB,
    pagination,
    sort,
    query,
    fetchPolicy,
    propsOfComponent,
    alias,
  });

  useSubscriptionByChanges({
    gqlQueryChanged: PROPERTIES_BY_SEARCH_SUB,
    refetch,
    result,
  });

  return {
    loading,
    refetch,
    result,
  };
};
