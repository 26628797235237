import * as R from 'ramda';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { shape, string, func, bool } from 'prop-types';
import React, { useRef, useEffect, memo } from 'react';
import { Checkbox, FormCreator } from 'poly-book-admin';
import { alwaysNewDate } from 'poly-utils';
import {
  useDecoratePersistenceForOnChange,
  usePersistDnDAreaWithMentions,
  commonFileValidators,
  validateFilesFunc,
  equalsOmitFields,
  isEmailFile,
  usePersistentFormValue,
} from 'poly-client-utils';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';
import { UPDATE_PERSIST_KEY_PREFIX } from 'poly-constants/src/updates.js';

import { UpdateMetaInfo } from './UpdateMetaInfo.js';
import { CancelBtn, SubmitBtn, ButtonsContainer } from '../../forms/common.js';
import { initialValues } from '../../forms/addUpdateForm/addUpdateFormUtils.js';
import { useUpdateReplySectionHook } from './useUpdateReplySectionHook.js';
import {
  DnDAreaWithMentionsS,
  ContentWrapper,
  SectionWrapper,
} from './components.js';

const ContentWrapperS = styled(ContentWrapper)`
  flex-direction: column;

  > div {
    margin: 18px 0;
  }
`;

const ButtonsContainerS = styled(ButtonsContainer)`
  & > :not(:last-child) {
    margin-right: 15px;
  }
`;

function DnDAreaWithPersistS({ onChange, onChangePersistentValue, ...props }) {
  const onChangePersistent = useDecoratePersistenceForOnChange(
    onChangePersistentValue,
    onChange,
    R.equals({ text: '', attachments: [], mentions: {} }),
  );

  return <DnDAreaWithMentionsS {...props} onChange={onChangePersistent} />;
}

DnDAreaWithPersistS.propTypes = {
  onChange: func.isRequired,
  onChangePersistentValue: func.isRequired,
};

function FormButtons({ _id, formId, setActiveReply, showSendEmail, ...props }) {
  const { cleanupRetainedValue } = usePersistentFormValue(
    `${UPDATE_PERSIST_KEY_PREFIX}${_id}`,
  );

  const { isLoading, onCancel } = useUpdateReplySectionHook(
    _id,
    setActiveReply,
  );

  const handleCancel = () => {
    cleanupRetainedValue();
    onCancel();
  };

  const disabled = R.pathSatisfies(R.isEmpty, [
    'formData',
    'updateDetails',
    'text',
  ])(props);

  return (
    <ButtonsContainerS>
      <SubmitBtn
        disabled={disabled || isLoading}
        form={formId}
        loader={isLoading}
      >
        Comment
      </SubmitBtn>
      <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
      {showSendEmail && (
        <Checkbox label="Send comment for users via email" {...props} />
      )}
    </ButtonsContainerS>
  );
}

FormButtons.propTypes = {
  _id: string.isRequired,
  formId: string.isRequired,
  showSendEmail: bool.isRequired,
  setActiveReply: func.isRequired,
};

// checkShowSendEmail :: Update -> Boolean
const checkShowSendEmail = R.compose(
  R.complement(R.isEmpty),
  R.filter(isEmailFile),
  R.defaultTo([]),
  R.prop('attachments'),
);

const getFormSections = ({
  _id,
  formId,
  apolloClient,
  showSendEmail,
  setActiveReply,
  onChangePersistentValue,
}) => [
  {
    order: 1,
    id: 'main',
    layout: { column: 1, margin: 0 },
    fields: [
      {
        order: 1,
        field: {
          name: 'updateDetails',
          Component: memo((props) => (
            <DnDAreaWithPersistS
              autoFocus
              disableMentions={false}
              apolloClient={apolloClient}
              onChangePersistentValue={onChangePersistentValue}
              {...props}
            />
          )),
          isEqual: equalsOmitFields([['mentions']]),
        },
        validators: commonFileValidators,
        validateFunction: validateFilesFunc,
      },
      {
        order: 2,
        field: {
          name: 'sendEmails',
          withFormData: true,
          Component: memo((props) => (
            <FormButtons
              _id={_id}
              formId={formId}
              showSendEmail={showSendEmail}
              setActiveReply={setActiveReply}
              {...props}
            />
          )),
        },
      },
    ],
  },
];

function UpdateReplyForm({
  _id,
  setActiveReply,
  customUpdateDetails,
  ...rest
}) {
  const {
    onChangePersistentValue,
    cleanupRetainedValue,
    retainedValue: updateDetails,
  } = usePersistDnDAreaWithMentions(`${UPDATE_PERSIST_KEY_PREFIX}${_id}`);
  const apolloClient = useApolloClient();
  const { formId, onSubmit } = useUpdateReplySectionHook(
    _id,
    setActiveReply,
    cleanupRetainedValue,
  );

  const showSendEmail = checkShowSendEmail(rest);

  const formProps = {
    id: formId,
    onSubmit,
    sections: getFormSections({
      _id,
      formId,
      apolloClient,
      showSendEmail,
      setActiveReply,
      onChangePersistentValue,
    }),
    initialValues: R.compose(
      R.mergeDeepLeft({
        sendEmails: false,
        updateDetails: customUpdateDetails.text
          ? customUpdateDetails
          : updateDetails,
      }),
      R.pick(['updateDetails']),
    )(initialValues),
    layout: { card: false },
  };

  return <FormCreator {...formProps} />;
}

UpdateReplyForm.defaultProps = { customUpdateDetails: {} };

UpdateReplyForm.propTypes = {
  _id: string.isRequired,
  // this is used to set text of content editable at tests
  customUpdateDetails: shape({ text: string }),
  setActiveReply: func,
};

const UpdateReplyMetaInfo = memo(() => {
  const { user } = useCurrentUserByStoreOrQuery();

  return <UpdateMetaInfo createdBy={user} createdAt={alwaysNewDate()} />;
}, R.T);

export function UpdateReplySection(props) {
  const replyEl = useRef(null);

  useEffect(() => {
    if (replyEl?.current?.scrollIntoView) {
      replyEl.current.scrollIntoView();
    }
  }, []);

  return (
    <SectionWrapper data-testid="update-reply-section" ref={replyEl}>
      <ContentWrapperS>
        <UpdateReplyMetaInfo />
        <UpdateReplyForm {...props} />
      </ContentWrapperS>
    </SectionWrapper>
  );
}
