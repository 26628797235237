import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { useMutation, useQuery, gql } from '@apollo/client';
import { usePristineSubscribe, useSetQueryParams } from 'poly-client-routing';
import { commonFileValidators, validateFilesFunc } from 'poly-client-utils';
import { debounce } from 'poly-utils';
import {
  useOnSubmitSetStopSubmitting,
  SupplierSelect,
  CancelBtn,
} from 'poly-admin-ui';
import {
  useNotificationContext,
  FormCreator,
  DatePicker,
} from 'poly-book-admin';

import { halfWidth } from '../../../modules/forms/common.js';
import { SidebarIDs } from '../../../sidebars/constants.js';
import { FlexColumn } from '../../../components/FlexContainer.js';
import { CloseSidebarBtn } from '../../../sidebars/components/SidebarTabs.js';
import { AttachDocumentField } from '../../../components/AttachDocumentField.js';
import { ApproveInvoiceFormContainer } from './ApproveSupplierInvoiceSidebarTabs.js';
import { FlexRow } from '../../../components/appBar/HistoryPopUp/historyComponents.js';
import { SidebarLabel } from '../../../sidebars/components/commonSidebarComponents.js';
import { InvoiceNumberInput } from '../../../modules/forms/supplierInvoiceForm/components/InvoiceNumberInput.js';
import { supplierInvoiceFormCommonConfig } from '../../../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/constants.js';
import { SupplierInvoiceLineEntry } from '../../../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/SupplierInvoiceLineEntry.js';
import { useSidebarDocumentsNavigation } from '../../../components/PreviewDocumentsNavigation.js';
import { useFormButtonProps } from '../../../modules/core/hooks/users/form.js';
import { onKeyDownToPreventFormSubmit } from '../../../utils/form.js';
import {
  prepareInvoiceToMutation,
  supplierInvoiceEditQuery,
  prepareInvoiceToEdit,
} from '../../../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/EditSupplierInvoiceForm.js';
import {
  RejectButton,
  SubmitButton,
} from '../../../modules/forms/supplierInvoiceForm/sections.js';

const formId = 'approveStandaloneSupplierInvoiceSidebarTabsFormId';

const FlexColumnS = styled(FlexColumn)`
  width: 100%;
  overflow-y: auto;
`;

const SidebarLabelS = styled(SidebarLabel)`
  margin: 0 0 0 30px;
`;

const SidebarSubLabel = styled(SidebarLabelS)`
  font-size: 14px;
`;

const FormCreatorS = styled(FormCreator)`
  > div > div {
    box-shadow: none;
    margin: 0;
    ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  }
`;

const ActionButtonsWrapper = styled(FlexRow)`
  margin-top: 20px;
  justify-content: flex-start;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const deleteStandaloneInvoiceMutation = gql`
  mutation deleteStandaloneInvoiceMutation($id: ID!) {
    deleteStandaloneInvoice(id: $id) {
      _id
    }
  }
`;

const approveStandaloneInvoiceMutation = gql`
  mutation approveStandaloneInvoiceMutation(
    $id: ID!
    $input: SupplierInvoiceUpdateInput!
  ) {
    approveStandaloneInvoice(id: $id, input: $input) {
      _id
    }
  }
`;

function ApproveStandaloneSupplierInvoiceActionButtons({ value, formData }) {
  const submitting = useSelector((state) => state.processes[formId]);
  const formProps = useFormButtonProps(formId, false);

  return (
    <ActionButtonsWrapper>
      <CancelBtn onClick={value.onCancel} {...formProps} />
      <RejectButton onClick={value.onReject} {...formProps} />
      <SubmitButton
        styleType="accentDark"
        onClick={() => value.onSubmit(formData)}
        disabled={submitting}
        {...formProps}
      >
        Approve
      </SubmitButton>
    </ActionButtonsWrapper>
  );
}

ApproveStandaloneSupplierInvoiceActionButtons.propTypes = {
  formData: shape({}),
  value: shape({
    onCancel: func,
    onSubmit: func,
    onReject: func,
  }),
};

const standAloneSupplierInvoiceSections = [
  {
    order: 1,
    layout: { column: 1 },
    fields: [
      {
        label: 'Supplier',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'supplierId',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: SupplierSelect,
        },
        validators: [[R.identity, 'Supplier is required']],
        required: true,
      },
      {
        label: 'Invoice Date',
        order: 2,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'invoiceDate',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: (props) => <DatePicker {...props} width="100%" />,
        },
        validators: [[R.identity, 'Invoice Date is required']],
      },
      {
        order: 3,
        required: true,
        label: 'Invoice #',
        layout: { row: 2, width: halfWidth },
        field: {
          withFormData: true,
          name: 'invoiceNumber',
          Component: InvoiceNumberInput,
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
        },
        validators: [[R.identity, 'Invoice is required']],
      },
      {
        order: 4,
        layout: { row: 3 },
        field: {
          name: 'lines',
          isArrayField: true,
          Component: SupplierInvoiceLineEntry,
        },
      },
      {
        label: 'Invoice File',
        order: 5,
        layout: { row: 4 },
        field: {
          name: 'invoiceFile',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: AttachDocumentField,
        },
        validators: commonFileValidators,
        validateFunction: validateFilesFunc,
      },
      {
        order: 6,
        layout: { row: 5 },
        field: {
          name: 'actionButtons',
          withFormData: true,
          Component: ApproveStandaloneSupplierInvoiceActionButtons,
        },
      },
    ],
  },
];

// getInitialValues :: { invoice: Invoice } -> FormData
const getInitialValues = R.compose(
  prepareInvoiceToEdit,
  R.propOr({}, 'invoice'),
);

export function ApproveStandaloneSupplierInvoiceForm({
  invoiceId,
  onCancel,
  isRefetchPassed,
  refetchInvoices,
  closeSidebar,
  formPadding,
}) {
  const pristineSubscribeProps = usePristineSubscribe();
  const { showSuccessNotification } = useNotificationContext();
  const [rejectInvoice] = useMutation(deleteStandaloneInvoiceMutation);
  const [approveInvoice] = useMutation(approveStandaloneInvoiceMutation);

  const { data, loading } = useQuery(supplierInvoiceEditQuery, {
    variables: { invoiceId },
    fetchPolicy: 'network-only',
  });

  const onSubmitHandler = async (formData) => {
    const mutationInput = {
      variables: { id: invoiceId, input: prepareInvoiceToMutation(formData) },
    };

    await approveInvoice(mutationInput);
    showSuccessNotification('Invoice was approved.');

    if (onCancel) {
      onCancel(null, true);
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const onReject = async () => {
    await rejectInvoice({ variables: { id: invoiceId } });

    if (isRefetchPassed) {
      refetchInvoices();
    }

    if (closeSidebar) {
      closeSidebar();
    }

    showSuccessNotification('Invoice was successfully rejected.');
  };

  if (loading) {
    return null;
  }

  const formCreatorProps = {
    id: formId,
    onSubmit: () => null,
    initialValuesEqual: R.T,
    ...supplierInvoiceFormCommonConfig,
    ...pristineSubscribeProps,
    resetFormOnSubmit: false,
    sections: standAloneSupplierInvoiceSections,
    initialValues: {
      ...getInitialValues(data),
      actionButtons: {
        onCancel,
        onSubmit,
        onReject,
      },
    },
  };

  return (
    <ApproveInvoiceFormContainer padding="0">
      <FormCreatorS padding={formPadding} {...formCreatorProps} />
    </ApproveInvoiceFormContainer>
  );
}

ApproveStandaloneSupplierInvoiceForm.propTypes = {
  invoiceId: string,
  onCancel: func,
  isRefetchPassed: bool,
  refetchInvoices: func,
  closeSidebar: func,
  formPadding: string,
};

export function ApproveStandaloneSupplierInvoiceSidebarTabs({
  refetch,
  invoiceId,
}) {
  const setQueryParams = useSetQueryParams();
  const { goToNextDocument, isNexDocument } = useSidebarDocumentsNavigation(
    SidebarIDs.approveSupplierInvoice,
  );

  const isRefetchPassed = R.is(Function, refetch);

  const refetchInvoices = useCallback(
    debounce(2000)(() => (isRefetchPassed ? refetch() : null)),
    [],
  );

  const closeSidebar = () =>
    setQueryParams({ [SidebarIDs.approveSupplierInvoice]: undefined });

  const onCancel = (__, submit) => {
    if (isRefetchPassed) {
      refetchInvoices();
    }

    if (isNexDocument && submit) {
      return goToNextDocument();
    }
    return closeSidebar();
  };

  return (
    <FlexColumnS>
      <SidebarLabelS>Approve Invoice</SidebarLabelS>
      <CloseSidebarBtn
        id={formId}
        params={[SidebarIDs.approveSupplierInvoice]}
      />
      <SidebarSubLabel>Non project supplier invoice</SidebarSubLabel>
      <ApproveStandaloneSupplierInvoiceForm
        invoiceId={invoiceId}
        onCancel={onCancel}
        closeSidebar={closeSidebar}
        isRefetchPassed={isRefetchPassed}
        refetchInvoices={refetchInvoices}
      />
    </FlexColumnS>
  );
}

ApproveStandaloneSupplierInvoiceSidebarTabs.propTypes = {
  refetch: func,
  invoiceId: string,
};
