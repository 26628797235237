import React from 'react';
import * as R from 'ramda';
import { NonBillableReasons } from 'poly-constants';
import { Select } from 'poly-book-admin';

// getNonBillableSelectOptions :: [String] -> Option
const getNonBillableSelectOptions = R.map(
  R.converge(R.mergeLeft, [R.objOf('value'), R.objOf('label')]),
);

export function NonBillableReasonSelect(props) {
  const selectProps = {
    ...props,
    name: 'NonBillableReasonSelect',
    options: getNonBillableSelectOptions(NonBillableReasons),
  };

  return <Select {...selectProps} />;
}

NonBillableReasonSelect.displayName = 'NonBillableReasonSelect';
