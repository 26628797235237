import { useLocation } from 'poly-client-routing';
import { useOutSidebarContext } from 'poly-client-utils';

import { useCloseSidebarForm } from '../../sidebars/components/commonSidebarFormComponents.js';

export const useAppBarItemClick = (handleClick) => {
  const { state } = useLocation();
  const { closeAllOutSidebars, sidebarConfig } = useOutSidebarContext();

  const handleClickWithCloseForm = (props) => {
    closeAllOutSidebars();
    handleClick(props);
  };

  const closeSidebarForm = useCloseSidebarForm(handleClickWithCloseForm);

  return state && state.pristine === false && sidebarConfig.length > 0
    ? closeSidebarForm
    : handleClick;
};
