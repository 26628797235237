import React from 'react';
import * as R from 'ramda';
import { noCase } from 'change-case';
import styled from 'styled-components';
import { bool, shape, string } from 'prop-types';
import { getThemeColor, getThemeFont } from 'poly-book-admin';
import { pathOrNothingUI } from 'poly-client-utils';
import { NOTHING_UI_STRING } from 'poly-constants';

import { SupplierDocumentsWarning } from '../../../../components/SupplierDocumentsWarning/SupplierDocumentsWarning.js';
import { supplierStatusesColors } from '../../../../constants/suppliers.js';
import { SupplierSourceTag } from '../../../../components/SupplierSourceTag.js';
import {
  FlexColumn,
  FlexFullWidthJustifyStart,
  SearchResultItemContainer,
} from './common/wrappers.js';
import {
  SearchResultItemDivider,
  SearchResultItemLinked,
  SearchResultItemSpace,
  SearchResultItemTitle,
  StatusS,
} from './common/components.js';
import { addressPath } from './common/selectors.js';
import { highlightedTextPropType } from './common/propTypes.js';

const SupplierSourceTagS = styled(SupplierSourceTag)`
  margin: 5px 0;
  color: ${getThemeColor(['newPrimary'])};
  font-weight: ${getThemeFont(['medium'])};
`;

const SearchResultItemTitleWithPrefixContainerS = styled(SearchResultItemTitle)`
  margin-right: 15px;
`;

const PrefixContainer = styled.span`
  color: ${getThemeColor(['secondary'])};
`;

const IndicatorsS = styled.div`
  display: flex;

  div:first-child {
    color: ${getThemeColor(['primary'])};
  }

  div:nth-child(2) {
    margin-left: 5px;
  }
`;

function SearchResultItemTitleWithPrefix({ prefix, children }) {
  return (
    <SearchResultItemTitleWithPrefixContainerS>
      <PrefixContainer>{`${prefix}: `}</PrefixContainer>
      {children}
    </SearchResultItemTitleWithPrefixContainerS>
  );
}

SearchResultItemTitleWithPrefix.propTypes = {
  prefix: string.isRequired,
  children: highlightedTextPropType.isRequired,
};

// hasValue :: [Any] -> Boolean
const hasValue = R.compose(
  R.complement(R.isEmpty),
  R.reject(R.equals(NOTHING_UI_STRING)),
);

export function SearchResultNameWithPrefixItems({ company, remit, tax }) {
  const dbaName = pathOrNothingUI(['dba'], company);
  const remitToName = pathOrNothingUI(['name'], remit);
  const taxName = pathOrNothingUI(['name'], tax);

  const showNameWithPrefixItems = hasValue([dbaName, remitToName, taxName]);

  return showNameWithPrefixItems ? (
    <>
      <FlexFullWidthJustifyStart>
        <SearchResultItemTitleWithPrefix prefix="DBA">
          {dbaName}
        </SearchResultItemTitleWithPrefix>
        <SearchResultItemTitleWithPrefix prefix="Remit To">
          {remitToName}
        </SearchResultItemTitleWithPrefix>
        <SearchResultItemTitleWithPrefix prefix="Tax">
          {taxName}
        </SearchResultItemTitleWithPrefix>
      </FlexFullWidthJustifyStart>
      <SearchResultItemDivider />
    </>
  ) : null;
}

SearchResultNameWithPrefixItems.propTypes = {
  company: shape({
    name: highlightedTextPropType.isRequired,
  }).isRequired,
  remit: shape({
    name: highlightedTextPropType,
  }),
  tax: shape({
    name: highlightedTextPropType,
  }),
};

export function SuppliersSearchResult({
  company,
  status,
  cardNumber,
  misc: { source },
  type,
  remit,
  tax,
  documents,
  isVeteran,
  mwbe,
}) {
  const supplier = {
    documents,
    tax,
    type,
    status,
    isVeteran,
    mwbe,
  };

  return (
    <SearchResultItemContainer>
      <FlexColumn>
        <FlexFullWidthJustifyStart>
          <SearchResultItemLinked>{company.name}</SearchResultItemLinked>
          <SearchResultItemSpace />
          <SearchResultItemTitle>
            {cardNumber || NOTHING_UI_STRING}
          </SearchResultItemTitle>
        </FlexFullWidthJustifyStart>
        <SearchResultItemDivider />
        <SearchResultNameWithPrefixItems
          company={company}
          remit={remit}
          tax={tax}
        />
        <SearchResultItemTitle>{addressPath(company)}</SearchResultItemTitle>
        <SupplierSourceTagS source={source} type={type} size="13px" />
      </FlexColumn>
      <IndicatorsS>
        <SupplierDocumentsWarning supplier={supplier} position="left" />
        <StatusS
          text={noCase(status)}
          color={supplierStatusesColors[R.toLower(status)]}
        />
      </IndicatorsS>
    </SearchResultItemContainer>
  );
}

SuppliersSearchResult.propTypes = {
  company: shape({
    name: highlightedTextPropType.isRequired,
    address: shape({
      formatted_address: highlightedTextPropType.isRequired,
    }).isRequired,
  }).isRequired,
  status: highlightedTextPropType.isRequired,
  cardNumber: highlightedTextPropType,
  misc: shape({
    source: highlightedTextPropType.isRequired,
  }).isRequired,
  type: highlightedTextPropType.isRequired,
  remit: shape({
    name: highlightedTextPropType,
  }),
  tax: shape({
    name: highlightedTextPropType,
    w9File: shape({
      url: string,
    }),
  }),
  documents: shape({
    wcFile: shape({
      url: string,
    }),
    wcExp: string,
    glFile: shape({
      url: string,
    }),
    glExp: string,
    scaFile: shape({
      url: string,
    }),
    mbeCertificationFile: shape({
      url: string,
    }),
    wbeCertificationFile: shape({
      url: string,
    }),
    veteranCertificationFile: shape({
      url: string,
    }),
  }),
  isVeteran: bool,
  mwbe: shape({ isMBE: bool }),
};
