import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Text } from 'poly-book-admin';
import { string, node } from 'prop-types';

const ModalWrapper = styled.div`
  min-width: ${R.propOr('600px', 'minWidth')};
  width: ${R.prop('width')};
`;

export function DefaultModalLayout({ title, width, minWidth, children }) {
  return (
    <ModalWrapper {...{ width, minWidth }}>
      <Text size="20px">{title}</Text>
      {children}
    </ModalWrapper>
  );
}

DefaultModalLayout.propTypes = {
  title: string,
  children: node.isRequired,
  width: string,
  minWidth: string,
};
