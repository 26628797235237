import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { AccountingPeriodStatuses } from 'poly-constants';
import { useNotificationState } from 'poly-admin-ui/src/hooks/useNotificationState.js';

const updateMonthlyAccountingPeriodsMutation = gql`
  mutation updateMonthlyAccountingPeriodsMutation(
    $input: UpdateMonthlyAccountingPeriodsInput!
  ) {
    updateMonthlyAccountingPeriods(input: $input) {
      _id
    }
  }
`;

export const useLockUnlockMonthlyPeriods = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const { showSuccessNotification } = useNotificationState();

  const [mutate] = useMutation(updateMonthlyAccountingPeriodsMutation);

  const handleSubmit = (status, msgSuffix) => async (ids) => {
    setIsUpdating(true);
    await mutate({
      variables: {
        input: {
          status,
          accountingPeriodIds: ids,
        },
      },
    });
    setIsUpdating(false);
    showSuccessNotification(`Accounting periods was successfully ${msgSuffix}`);
  };

  return {
    lockMonth: handleSubmit(AccountingPeriodStatuses.CLOSED, 'locked'),
    unlockMonth: handleSubmit(AccountingPeriodStatuses.OPENED, 'reopened'),
    isUpdating,
  };
};
