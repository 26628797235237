import React from 'react';
import { FormCreator } from 'poly-book-admin';
import { func, string } from 'prop-types';
import { getFileUploadErrorMessageByResult } from 'poly-client-utils';

import { useMutation } from '@apollo/client';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { ADD_FILES_MUTATION } from '../../../hocs/files/mutations.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';

import { filesSections } from './sections.js';
import { commonModalLayout } from '../common.js';
import { mapAttachmentsBeforeUpdate } from '../addUpdateForm/index.js';

const useOnSubmitFilesForm = (
  collection,
  documentId,
  onCancel,
  successMessage,
) => {
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();
  const [addFiles] = useMutation(ADD_FILES_MUTATION);

  return async ({ files }) => {
    try {
      const preparedInput = {
        addFilesInput: {
          collection,
          documentId,
          files: mapAttachmentsBeforeUpdate(files),
        },
      };
      const result = await addFiles({
        variables: preparedInput,
      });
      const uploadError = getFileUploadErrorMessageByResult(result);
      if (uploadError) {
        showErrorNotification(uploadError);
      } else {
        showSuccessNotification(successMessage);
      }
      onCancel();
    } catch (err) {
      showErrorNotification(err.message);
    }
  };
};

export function AddFilesForm({
  formId,
  onCancel,
  collection,
  documentId,
  successMessage = 'File was successfully added',
}) {
  const onSubmitHandler = useOnSubmitFilesForm(
    collection,
    documentId,
    onCancel,
    successMessage,
  );

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  return (
    <FormCreator
      id={formId}
      sections={filesSections}
      layout={commonModalLayout}
      initialValues={{ files: [] }}
      onSubmit={onSubmit}
    />
  );
}

AddFilesForm.displayName = 'AddFilesForm';

AddFilesForm.propTypes = {
  formId: string.isRequired,
  onCancel: func.isRequired,
  collection: string.isRequired,
  documentId: string.isRequired,
  successMessage: string,
};
