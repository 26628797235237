import * as R from 'ramda';
import { applyProp, assocBy, formatAmount } from 'poly-utils';
import { extractTablePropsFromConfig } from 'poly-admin-ui';
import { getThemeColor, moneyColumnStyles, Table } from 'poly-book-admin';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'poly-client-routing';

import {
  formatAccountBalance,
  generateAccountTransactionsUrl,
} from '../GeneralLedgerPage/generalLedgerHelpers.js';
import {
  trialBalanceReportPropTypes,
  trialBalanceFiltersPropTypes,
} from './trialBalancePropTypes.js';

// applyFormatAccountBalanceProp :: String -> Object -> String
const applyFormatAccountBalanceProp = applyProp(formatAccountBalance);

const trialBalanceTableConfig = [
  ['', R.path(['account', 'code'])],
  ['Account Name', R.path(['account', 'name'])],
  ['Forward Balance', applyFormatAccountBalanceProp('forward_balance')],
  [
    'Debit',
    ({ url, debits }) => (
      <Link href={url} target="_blank">
        {formatAmount(debits)}
      </Link>
    ),
  ],
  [
    'Credit',
    ({ url, credits }) => (
      <Link href={url} target="_blank">
        {formatAmount(credits)}
      </Link>
    ),
  ],
  ['Ending Balance', applyFormatAccountBalanceProp('ending_balance')],
];

const tableProps = extractTablePropsFromConfig(trialBalanceTableConfig);

const TrialBalanceTableS = styled(Table)`
  ${moneyColumnStyles(3, 200)}
  ${moneyColumnStyles(4, 200)}
  ${moneyColumnStyles(5, 200)}
  ${moneyColumnStyles(6, 200)}

  th:nth-child(1), td:nth-child(1) {
    width: 90px;
  }

  tr {
    width: 100%;
    margin: 0;
    font-size: 14px;
  }

  td {
    height: 40px;
  }

  @media print {
    padding: 0 5px;
    tr {
      border: none;

      &:first-child {
        border: none;
      }

      &:last-child {
        border: none;
      }
    }
  }
`;

const TrialBalanceTotalRow = styled.tr`
  font-weight: bold;
  border-top: 1px solid ${getThemeColor(['midLight'])};
`;

function TrialBalanceTotalFooter({
  trialBalanceReport: { debits_total, credits_total, current_year_earnings },
}) {
  const formattedCYEBalance = formatAmount(Math.abs(current_year_earnings));
  return (
    <>
      <TrialBalanceTotalRow>
        <td />
        <td>Total</td>
        <td />
        <td>{formatAmount(debits_total)}</td>
        <td>{formatAmount(credits_total)}</td>
        <td />
      </TrialBalanceTotalRow>
      <tr>
        <td />
        <td>Current Year Net Earnings</td>
        <td />
        <td>{current_year_earnings < 0 ? formattedCYEBalance : null}</td>
        <td>{current_year_earnings > 0 ? formattedCYEBalance : null}</td>
        <td />
      </tr>
    </>
  );
}

TrialBalanceTotalFooter.propTypes = {
  trialBalanceReport: trialBalanceReportPropTypes,
};

// prepareTrialBalanceTableRows :: TrialBalanceFilters ->
//   TrialBalanceReportResult -> [GeneralLedgerReportEntry]
const prepareTrialBalanceTableRows = (filters) =>
  R.map(
    R.compose(
      assocBy('url', generateAccountTransactionsUrl(filters)),
      assocBy('_id', R.path(['account', '_id'])),
    ),
  );

export function TrialBalanceTable({ filters, trialBalanceReport }) {
  return (
    <TrialBalanceTableS
      {...tableProps}
      rows={prepareTrialBalanceTableRows(filters)(trialBalanceReport.entries)}
      TableFooter={TrialBalanceTotalFooter}
      footerProps={{ trialBalanceReport }}
      footerHeight={50}
      moreScrollIconPosition="75px"
      overflow="auto"
      showScrollBar
    />
  );
}

TrialBalanceTable.propTypes = {
  trialBalanceReport: trialBalanceReportPropTypes,
  filters: trialBalanceFiltersPropTypes,
};
