import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, bool, shape, string } from 'prop-types';
import { LinkButton, useMoreScroll } from 'poly-book-admin';
import { ClientInvoiceStatuses, NOTHING_UI_STRING } from 'poly-constants';
import { calculateTotal, centsToDollarsWithFormat } from 'poly-utils';
import {
  useMapConfigToTableProps,
  InvoicePDFLink,
  EmailLink,
  FlexContainer,
  FlexColumn,
} from 'poly-admin-ui';

import {
  getVoidColumn,
  BatchInvoiceReportsDetailsTableS,
  BatchInvoiceReportsDetailsFooterContainer,
} from './components.js';
import { batchInvoicesPropType } from './propTypes.js';
import { ClientInvoicePDFLink } from '../../components/ClientInvoicePDFLink.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { formatDateCurried } from '../../modules/tables/timesheetsTable/utils.js';

// getFormattedTotalByProp :: String -> [Object] -> String
const getFormattedTotalByProp = R.curry((prop, rows) =>
  R.compose(
    centsToDollarsWithFormat,
    calculateTotal(R.propOr(0, prop)),
    R.reject(R.propEq('status', ClientInvoiceStatuses.VOIDED)),
  )(rows),
);

// checkConsolidatedInvoiceChild :: ClientInvoice -> Boolean
const checkConsolidatedInvoiceChild = R.propSatisfies(
  R.complement(R.isNil),
  'consolidatedClientInvoice',
);

// checkConsolidatedInvoiceFileValid :: ConsolidatedClientInvoice -> Boolean
const checkConsolidatedInvoiceFileValid = R.pathSatisfies(
  R.complement(R.isNil),
  ['pdfFile', 'url'],
);

function PrintInvoiceLink(props) {
  return <LinkButton>{R.prop('number', props)}</LinkButton>;
}

function ConsolidatedClientInvoicePDFLink({ consolidatedClientInvoice }) {
  const isConsolidatedInvoiceFileValid = checkConsolidatedInvoiceFileValid(
    consolidatedClientInvoice,
  );

  return isConsolidatedInvoiceFileValid ? (
    <InvoicePDFLink
      invoiceFileLink={consolidatedClientInvoice.pdfFile.url}
      invoiceNumber={consolidatedClientInvoice.invoiceNumber}
    />
  ) : (
    <PrintInvoiceLink number={consolidatedClientInvoice.invoiceNumber} />
  );
}

ConsolidatedClientInvoicePDFLink.propTypes = {
  consolidatedClientInvoice: shape({
    invoiceNumber: string.isRequired,
    pdfFile: shape({ url: string }).isRequired,
  }).isRequired,
};

function PreviewPDFInvoiceLink(props) {
  const isConsolidatedInvoiceChild = checkConsolidatedInvoiceChild(props);

  return isConsolidatedInvoiceChild ? (
    <ConsolidatedClientInvoicePDFLink {...props} />
  ) : (
    <ClientInvoicePDFLink {...props} />
  );
}

const EmailLinkWrapper = styled.span`
  display: flex;
  gap: 5px;
  flex-grow: 1;
  flex-wrap: wrap;
  max-height: 50px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #cfcece;
  }
`;

const EmailsListContainer = styled(FlexColumn)`
  margin-left: 5px;
`;

export function ClientInvoiceEmailDetails(props) {
  const emails = R.pathOr({}, ['clientNotified', 'emails'], props);
  const toEmails = R.propOr([], 'to', emails);
  const ccEmail = R.prop('cc', emails);

  return (
    <EmailLinkWrapper>
      <FlexContainer>
        To:
        <EmailsListContainer>
          {toEmails.length
            ? toEmails.map((email) => <EmailLink key={email} email={email} />)
            : NOTHING_UI_STRING}
        </EmailsListContainer>
      </FlexContainer>
      <FlexContainer>
        CC:
        <EmailsListContainer>
          <EmailLink email={ccEmail} />
        </EmailsListContainer>
      </FlexContainer>
    </EmailLinkWrapper>
  );
}

const batchInvoiceReportsDetailsTableConfig = [
  ['Invoice Number', PreviewPDFInvoiceLink],
  [
    'Project #',
    ({ project = {} }) => (
      <ProjectLink {...project} target="_blank">
        {project.projectId || ''}
      </ProjectLink>
    ),
  ],
  ['Description', R.pathOr('', ['project', 'description'])],
  ['Email Details', ClientInvoiceEmailDetails],
  [
    '',
    R.compose(
      R.unless(R.isNil, formatDateCurried('MM/dd/yyyy')),
      R.path(['clientNotified', 'date']),
    ),
  ],
  [
    'Invoice Amount',
    R.ifElse(
      R.propEq('status', ClientInvoiceStatuses.VOIDED),
      getVoidColumn,
      R.compose(getFormattedTotalByProp('amount'), R.of),
    ),
  ],
];

function BatchInvoiceReportsDetailsTableFooter({ rows }) {
  return (
    <BatchInvoiceReportsDetailsFooterContainer>
      <td>Total</td>
      <td>{getFormattedTotalByProp('amount', rows)}</td>
    </BatchInvoiceReportsDetailsFooterContainer>
  );
}

BatchInvoiceReportsDetailsTableFooter.propTypes = {
  rows: arrayOf(batchInvoicesPropType),
};

export function BatchInvoiceReportsDetailsTable({ loading, batchInvoices }) {
  const { MoreScrollIcon, onScroll } = useMoreScroll('8vh');

  const tableProps = useMapConfigToTableProps(
    R.identity,
    batchInvoiceReportsDetailsTableConfig,
    batchInvoices,
  );

  return (
    <>
      <BatchInvoiceReportsDetailsTableS
        {...tableProps}
        footerHeight={60}
        isLoading={loading}
        onScroll={onScroll}
        TableFooter={BatchInvoiceReportsDetailsTableFooter}
      />
      <MoreScrollIcon />
    </>
  );
}

BatchInvoiceReportsDetailsTable.propTypes = {
  loading: bool,
  batchInvoices: arrayOf(batchInvoicesPropType),
};
