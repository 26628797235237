import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { EXPORT_XLS_CAPTION, PRINT_PDF_CAPTION } from 'poly-constants';
import { CommonOuterButton, TableSearchInput } from 'poly-admin-ui';
import {
  openPrintWindowWithData,
  useOutSidebarContext,
} from 'poly-client-utils';
import {
  HeaderLinksWrapper,
  getThemeColor,
  getThemeProp,
  LinkButton,
} from 'poly-book-admin';

import { ProjectsFilter } from './ProjectsFilter.js';
import { ProjectsDirectoryFilterId } from './constants.js';
import { ProjectsPageCommonTable } from '../../modules/tables/projectsTable/ProjectsTable.js';
import { useProjectsToolbarLogic } from './useProjectsToolbarLogic.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';
import {
  generateRecurringProjectsXlsReport,
  generateProjectsXlsReport,
} from '../../modules/tables/projectsTable/exportToXlsUtils.js';

const FilterBnt = styled(LinkButton)`
  font-size: 12px;
  line-height: 18px;
  color: ${getThemeColor(['midDark'])};
  text-decoration: none;
  transition: color ${getThemeProp(['defaultTransition'])};
  margin-left: 35px;
  cursor: pointer;
  width: max-content;

  :hover,
  :focus {
    color: ${getThemeColor(['accent5'])};
  }
`;

function FilterButton() {
  const { openOutSidebar } = useOutSidebarContext();

  const onFilterClick = () =>
    openOutSidebar({
      width: 610,
      alwaysFirst: true,
      content: <ProjectsFilter />,
      id: ProjectsDirectoryFilterId,
    });

  return <FilterBnt onClick={onFilterClick}>Filter</FilterBnt>;
}

function ProjectsPrintBtn(props) {
  const Layout = usePrintLayout();
  const { loading, projects, fileName, metaData, isRecurringSearch } =
    useProjectsToolbarLogic(props);

  const onClick = () =>
    openPrintWindowWithData({
      Layout,
      projects,
      fileName,
      metaData,
      isPrint: true,
      isRecurringSearch,
      Table: ProjectsPageCommonTable,
    });

  return (
    <CommonOuterButton
      onClick={onClick}
      disabled={R.isEmpty(projects)}
      loading={loading}
    >
      {PRINT_PDF_CAPTION}
    </CommonOuterButton>
  );
}

function ProjectsExportBtn(props) {
  const { loading, projects, fileName, isRecurringSearch } =
    useProjectsToolbarLogic(props);

  const onClick = () =>
    isRecurringSearch
      ? generateRecurringProjectsXlsReport(fileName)(projects)
      : generateProjectsXlsReport(fileName)(projects);

  return (
    <CommonOuterButton
      onClick={onClick}
      disabled={R.isEmpty(projects)}
      loading={loading}
    >
      {EXPORT_XLS_CAPTION}
    </CommonOuterButton>
  );
}

export function ProjectsDirectoryToolbar(props) {
  return (
    <HeaderLinksWrapper {...props}>
      <ProjectsPrintBtn {...props} />
      <ProjectsExportBtn {...props} />
      <FilterButton />
      <TableSearchInput />
    </HeaderLinksWrapper>
  );
}
