import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { object, string } from 'prop-types';
import { employeeStatusColors, USER_SEARCH_CHANGED } from 'poly-admin-ui';
import { useReactiveQuery } from 'poly-client-utils';
import { formatCount, forceTitleCase } from 'poly-utils';
import { Status } from 'poly-book-admin';

import { STAFF_USERS_BY_SEARCH_TABLE } from '../../core/hooks/users/queries.js';

// getStaffCount :: SearchUsers -> String
const getStaffCount = R.compose(
  formatCount,
  R.pathOr(0, ['searchUsers', 'total']),
);

export function CustomTabTitle({ query, text, color }) {
  const searchText = useSelector(R.prop('searchText'));

  const searchInputParams = {
    ...(searchText ? { searchTerm: searchText } : {}),
    query,
    size: 0,
  };

  const { data } = useReactiveQuery(
    STAFF_USERS_BY_SEARCH_TABLE,
    USER_SEARCH_CHANGED,
    {
      queryOptions: {
        variables: {
          searchInput: searchInputParams,
        },
      },
      subscriptionOptions: {
        variables: {
          searchInput: searchInputParams,
        },
      },
    },
  );

  const textWithUsersCount = `${text} (${getStaffCount(data)})`;
  return <Status text={textWithUsersCount} color={color} />;
}

CustomTabTitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object.isRequired,
  text: string.isRequired,
  color: string.isRequired,
};

export function StaffTabTitle({ status, query }) {
  return (
    <CustomTabTitle
      query={query}
      text={forceTitleCase(status)}
      color={employeeStatusColors[status]}
    />
  );
}

StaffTabTitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: object.isRequired,
  status: string.isRequired,
};

StaffTabTitle.displayName = 'StaffTabTitle';
