import { useMemo } from 'react';
import { useTableInfiniteScrollQuery } from 'poly-client-utils';
import { gql } from '@apollo/client';

import {
  getWorkInProgressQCSearchQueryByFilters,
  WORK_IN_PROGRESS_QC_SORT_INPUT,
} from './search-query.js';

export const workInProgressQCInvoicesQuery = gql`
  query WIP_QC_REPORT($input: CollectionSearchParams) {
    searchInvoices(input: $input) {
      hits {
        _id
        total
        client {
          _id
          name
        }
        supplier {
          _id
          company {
            name
          }
        }
        project {
          _id
          projectId
          type
          accountingStatus
          endDate
          total
          suggestedClientInvoiceTotalWithoutTime
          jobCost
          clientInvoiceMarkupAmount
          manager {
            _id
            fullName
          }
        }
      }
      total
    }
  }
`;

export const useWipQCReportQuery = (queryFilters) => {
  const currentWipInput = useMemo(
    () => ({
      query: getWorkInProgressQCSearchQueryByFilters(queryFilters),
      sort: WORK_IN_PROGRESS_QC_SORT_INPUT,
    }),
    [queryFilters],
  );

  const currentWipQCResponse = useTableInfiniteScrollQuery(
    workInProgressQCInvoicesQuery,
    currentWipInput,
    {
      endpointName: 'searchInvoices',
      pageSize: 500,
    },
  );

  return currentWipQCResponse;
};
