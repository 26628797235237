import * as R from 'ramda';
import styled from 'styled-components';
import { string, bool } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Icon, Loader, Popover, defaultTheme } from 'poly-book-admin';

import { FlexRow } from '../../components/appBar/HistoryPopUp/historyComponents.js';
import { AssetLink } from '../../sidebars/AssetSidebar/useOpenAssetSidebar.js';
import { FlexColumn } from '../../components/FlexContainer.js';

const {
  colors: { primaryLighter8 },
} = defaultTheme;

const DisabledDeleteIconS = styled(Icon)`
  cursor: default !important;
`;

const FlexColumnS = styled(FlexColumn)`
  margin: 10px;
`;

const LoaderWrapper = styled(FlexColumnS)`
  margin: 20px 50px;
`;

const PopoverContentTitle = styled(FlexRow)`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
`;

const AssetLinkS = styled(AssetLink)`
  margin-bottom: 5px;
`;

function DisabledDeleteIcon() {
  return (
    <DisabledDeleteIconS
      size={15}
      name="delete"
      color={primaryLighter8}
      onClick={() => null}
    />
  );
}

const ASSETS_BY_MODEL_ID_QUERY = gql`
  query ASSETS_BY_MODEL_ID_QUERY($input: AssetsByModelIdInput!) {
    assetsByModelId(input: $input) {
      total
      hits {
        _id
        displayName
      }
    }
  }
`;

// getAssets :: { assetsByModelId: [Asset] } -> [Asset]
const getAssets = R.applySpec({
  total: R.pathOr(0, ['assetsByModelId', 'total']),
  assets: R.pathOr([], ['assetsByModelId', 'hits']),
});

function PopoverContent({ isActive, modelId }) {
  const memoModelId = useMemo(() => modelId, [isActive, modelId]);

  const { data, loading } = useQuery(ASSETS_BY_MODEL_ID_QUERY, {
    variables: { input: { modelId: memoModelId, size: 5 } },
    skip: !isActive,
    fetchPolicy: 'network-only',
  });

  const { assets, total } = getAssets(data);

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader size={20} />
      </LoaderWrapper>
    );
  }

  return (
    <FlexColumnS>
      {total > 0 && (
        <PopoverContentTitle>
          Unable to delete while in use ({total} assets)
        </PopoverContentTitle>
      )}
      {assets.map(({ _id, displayName }) => (
        <AssetLinkS _id={_id} key={displayName}>
          {displayName}
        </AssetLinkS>
      ))}
    </FlexColumnS>
  );
}

PopoverContent.propTypes = {
  isActive: bool,
  modelId: string,
};

export function DeleteAssetModelInUse({ modelId }) {
  return (
    <Popover
      position="left"
      Icon={DisabledDeleteIcon}
      content={PopoverContent}
      contentProps={{ modelId }}
    />
  );
}

DeleteAssetModelInUse.propTypes = {
  modelId: string.isRequired,
};
