import React, { useEffect } from 'react';
import { func, string, shape, bool } from 'prop-types';
import { DatePicker, HeaderLinksWrapper } from 'poly-book-admin';
import { useRouterQuery } from 'poly-client-routing';
import { ClientSelect } from 'poly-admin-ui';
import { alwaysNewDate } from 'poly-utils';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { ARAgingReportPrintBtn } from './ARAgingReportPrintPDFBtn.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { CountrySelect } from '../../modules/selects/CountrySelect.js';
import { ARAgingReportExportXLBtn } from './ARAgingReportExportXLBtn.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { commonAgingReportConfig } from '../APAgingReport/APAgingReportHeader.js';
import { invoicesPropTypes } from './prop-types.js';
import { generateSearchAgingReportQueryByKeyProp } from '../APAgingReport/generateSearchAgingReportQueryByKeyProp.js';

const arAgingReportHeaderConfig = [
  { name: 'clientId' },
  { name: 'date', defaultValue: alwaysNewDate() },
  ...commonAgingReportConfig,
];

export function ARAgingReportHeader({
  title,
  TitleComp,
  setQuery,
  exportProps,
  loading,
}) {
  const { clientId, country } = useRouterQuery(['clientId', 'country']);
  const { searchFilters, handlers, onReset } = useSearchFilters(
    arAgingReportHeaderConfig,
  );

  const onSearch = () => {
    if (!loading) {
      setQuery(
        generateSearchAgingReportQueryByKeyProp('clientId')(searchFilters),
      );
    }
  };

  const onFiltersReset = () => {
    onReset();
    setQuery(null);
  };

  useEffect(() => {
    if (!!clientId || !!country) {
      onSearch();
    }
  }, []);

  return (
    <SearchPageHeaderContainer title={title} TitleComp={TitleComp}>
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Client Account"
            titleWidth="130px"
            filterWidth="250px"
          >
            <ClientSelect
              width="100%"
              name="clientId"
              value={searchFilters.clientId}
              onChange={handlers.clientId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="135px">
            <HeaderLinksWrapper>
              <ARAgingReportPrintBtn {...exportProps} title={title} />
              <ARAgingReportExportXLBtn {...exportProps} title={title} />
            </HeaderLinksWrapper>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Client Branch"
            titleWidth="130px"
            filterWidth="250px"
          >
            <ClientSelect
              disabled
              width="100%"
              name="branchId"
              value={searchFilters.branchId}
              onChange={handlers.branchId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date"
            titleWidth="75px"
            filterWidth="150px"
          >
            <DatePicker
              disabled
              width="150px"
              value={searchFilters.date}
              onChange={handlers.date}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Country"
            titleWidth="75px"
            filterWidth="150px"
          >
            <CountrySelect
              value={searchFilters.country}
              onChange={handlers.country}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            titleWidth="0px"
            filterWidth="200px"
            position="flex-end"
          >
            <FlexCenterAlign>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onFiltersReset}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

ARAgingReportHeader.propTypes = {
  setQuery: func.isRequired,
  title: string.isRequired,
  exportProps: shape({
    report: invoicesPropTypes,
    query: shape({
      clientId: string,
      country: string,
    }),
  }),
  loading: bool,
  TitleComp: func,
};
