import { onError } from '@apollo/client/link/error/index.js';
import { debounce } from 'poly-utils';

import { isNotAuthenticatedApolloError } from './error-helpers.js';

export const authAfterWare = (logOutHandler) =>
  onError((error) => {
    if (isNotAuthenticatedApolloError(error)) {
      logOutHandler();
    }
  });

export const errorAfterWare = (handleApolloError) => {
  // debounce function to avoid multiple processing on batch queries
  const processErrors = debounce(50)((error) => {
    // eslint-disable-next-line
    console.error('GraphQL error occurred:', error);

    handleApolloError(error);
  });

  return onError(processErrors);
};
