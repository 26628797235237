import React from 'react';
import * as R from 'ramda';
import { bool, shape } from 'prop-types';
import {
  REQUESTS_APP_NAME,
  CLIENT_PORTAL_APP_NAME,
  ASSET_SCANNER_APP_NAME,
} from 'poly-security';
import { formatSelectOptions, ServiceTypesSelect } from 'poly-admin-ui';
import { Select } from 'poly-book-admin';
import { commonFileValidators, validateFilesFunc } from 'poly-client-utils';
import {
  ProjectTypeToNameMap,
  ClientBranding,
  ProjectType,
  FeeTypesUI,
} from 'poly-constants';

import { ImageLogoInput } from '../components/ImageLogoInput.js';

function ClientBrandingSelect(props) {
  const options = formatSelectOptions(ClientBranding);

  return <Select {...props} options={options} />;
}

// isAppEnabled :: String -> FormData -> Boolean
const isAppEnabled = (app) => R.compose(R.includes(app), R.propOr([], 'apps'));

// isRequestsAppEnabled :: FormData -> Boolean
export const isRequestsAppEnabled = isAppEnabled(REQUESTS_APP_NAME);

// isAssetScannerAppEnabled :: FormData -> Boolean
export const isAssetScannerAppEnabled = isAppEnabled(ASSET_SCANNER_APP_NAME);

// isClientPortalAppEnabled :: FormData -> Boolean
export const isClientPortalAppEnabled = isAppEnabled(CLIENT_PORTAL_APP_NAME);

export const clientBrandingFormField = {
  label: 'Client Branding',
  field: {
    name: 'configs.requestsApp.branding',
    Component: ClientBrandingSelect,
  },
  renderIf: isRequestsAppEnabled,
};

export const clientLogoImageFormField = {
  label: 'Client Logo',
  field: {
    name: 'configs.logoImage',
    Component: ImageLogoInput,
  },
  renderIf: isRequestsAppEnabled,
  validators: commonFileValidators,
  validateFunction: validateFilesFunc,
};

// getProjectCostTypeOptions :: Client -> [String, String]
const getProjectCostTypeOptions = (client) =>
  R.compose(
    R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
    R.toPairs,
    R.unless(
      R.always(R.prop('enableReportOnlyProjectType', client)),
      R.dissoc(ProjectType.REPORT_ONLY),
    ),
    R.unless(
      R.always(R.path(['configs', 'enableHousekeepingProjectType'], client)),
      R.dissoc(ProjectType.HOUSEKEEPING),
    ),
  )(ProjectTypeToNameMap);

function ClientProjectCostType({ formData, ...props }) {
  const selectProps = {
    options: getProjectCostTypeOptions(formData),
    ...props,
  };

  return <Select {...selectProps} />;
}

ClientProjectCostType.propTypes = {
  formData: shape({
    enableReportOnlyProjectType: bool,
    configs: shape({
      enableHousekeepingProjectType: bool,
    }),
  }),
};

export const clientProjectCostTypeFormField = {
  label: 'Cost type',
  field: {
    name: 'configs.requestsApp.projectType',
    Component: ClientProjectCostType,
    withFormData: true,
  },
  renderIf: isRequestsAppEnabled,
  required: true,
};

// isProjectFeeTypeSelected :: FormData -> Boolean
export const isProjectFeeTypeSelected = R.pathEq(
  ['configs', 'requestsApp', 'projectType'],
  ProjectType.FEE,
);

export const clientProjectServiceTypeFormField = {
  label: 'Service Type',
  field: {
    name: 'configs.requestsApp.projectServiceTypeId',
    Component: ServiceTypesSelect,
  },
  renderIf: R.both(
    isRequestsAppEnabled,
    R.complement(isProjectFeeTypeSelected),
  ),
  required: true,
};

// prepareFeeTypeSelectOptions :: Object -> [SelectOption]
const prepareFeeTypeSelectOptions = R.compose(
  R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
  R.toPairs,
);

function FeeTypeSelect(props) {
  const selectProps = {
    ...props,
    options: prepareFeeTypeSelectOptions(FeeTypesUI),
  };

  return <Select {...selectProps} />;
}

export const clientProjectFeeTypeFormField = {
  label: 'Fee Type',
  field: {
    name: 'configs.requestsApp.projectFeeType',
    Component: FeeTypeSelect,
  },
  renderIf: R.both(isRequestsAppEnabled, isProjectFeeTypeSelected),
  required: true,
};
