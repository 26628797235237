import * as R from 'ramda';
import { gql, useApolloClient } from '@apollo/client';
import { bool, object } from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';

import { showBase64PDF } from 'poly-client-utils';
import { Button, Table, moneyColumnStyles } from 'poly-book-admin';
import { useMapConfigToTableProps } from 'poly-admin-ui';

import { FlexContainer } from '../../components/FlexContainer.js';
import { printChecksTableConfig } from '../PrintChecksPage/PrintChecksTableConfig.js';
import { isCheckRowSelectable } from '../PrintChecksPage/PrintChecksTable.js';
import {
  BottomPanelBody,
  BottomPanelContainer,
} from '../../components/BottomPanel.js';

const ButtonsContainer = styled(FlexContainer)`
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
`;

const GENERATE_CHECKS_DETAILS_QUERY = gql`
  query GENERATE_CHECK_DETAILS_PDF($checkIds: [ID]!) {
    generateAACCheckDetailsPDF(checkIds: $checkIds) {
      aacCheckDetailsPDF
    }
  }
`;

const PrintChecksDetailsTableS = styled(Table)`
  tbody {
    vertical-align: middle;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 50px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 230px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 150px;
  }

  ${moneyColumnStyles(7)};
`;

// getBase64String :: MutationResult -> String
// MutationResult = { data: { generateAACCheckDetailsPDF: GenerateAACCheckDetailsPDFResponse } }
const getBase64String = R.path([
  'data',
  'generateAACCheckDetailsPDF',
  'aacCheckDetailsPDF',
]);

// toggleRow :: ([ID], SetFunction) -> ID -> _
// SetFunction = ([ID]) -> _
const toggleRow =
  (selectedChecksDetails, setSelectedChecksDetails) => (checkId) =>
    R.compose(
      setSelectedChecksDetails,
      R.ifElse(
        R.includes(checkId),
        R.reject(R.equals(checkId)),
        R.append(checkId),
      ),
    )(selectedChecksDetails);

export function PrintChecksDetailsTable({ data, loading }) {
  const [selectedChecksDetails, setSelectedChecksDetails] = useState([]);
  const client = useApolloClient();

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.pathOr([], ['supplierChecks', 'hits']),
    printChecksTableConfig,
    data,
  );

  const generateSupplierCheckDetailsPDF = async (checkIds) => {
    const queryResult = await client.query({
      query: GENERATE_CHECKS_DETAILS_QUERY,
      variables: { checkIds },
    });
    const pdfString = getBase64String(queryResult);

    await showBase64PDF(pdfString);
  };

  return (
    <>
      <PrintChecksDetailsTableS
        isPartiallySelectable
        selectedRows={selectedChecksDetails}
        isRowSelectable={isCheckRowSelectable}
        toggleRow={toggleRow(selectedChecksDetails, setSelectedChecksDetails)}
        headers={headers}
        rows={rows}
        columns={columns}
        sortQueries={sortQueries}
        isLoading={loading}
      />
      <BottomPanelContainer height="70px">
        <BottomPanelBody>
          <ButtonsContainer>
            <Button
              size="small"
              onClick={() =>
                generateSupplierCheckDetailsPDF(selectedChecksDetails)
              }
              disabled={R.isEmpty(selectedChecksDetails)}
            >
              Print Check Details
            </Button>
          </ButtonsContainer>
        </BottomPanelBody>
      </BottomPanelContainer>
    </>
  );
}

PrintChecksDetailsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object,
  loading: bool,
};
