import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { FormCreator, Input } from 'poly-book-admin';
import { usePristineSubscribe } from 'poly-client-routing';
import {
  MoneyInputAsCents,
  useModalContext,
  useNotificationState,
} from 'poly-admin-ui';
import { commonModalLayout } from 'poly-admin-ui/src/modules/forms/common.js';

export function LifeExpectancyInput(props) {
  return (
    <Input
      {...props}
      maxValue={1000}
      decimalScale={2}
      type="numberMask"
      floatValue
    />
  );
}

const assetManufacturerModelFormSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Name',
        field: {
          name: 'name',
          Component: Input,
        },
        validators: [[R.identity, 'Name is required']],
      },
      {
        label: 'Life Expectancy (years)',
        field: {
          name: 'lifeExpectancy',
          Component: LifeExpectancyInput,
        },
      },
      {
        label: 'Asset Replacement Cost',

        field: {
          name: 'replacementCost',
          Component: MoneyInputAsCents,
        },
      },
    ],
  },
];

export const addAssetManufacturerModelFormId =
  'addAssetManufacturerModelFormId';

export const editAssetManufacturerModelFormId =
  'editAssetManufacturerModelFormId';

const createManufacturerModelMutation = gql`
  mutation createAssetManufacturerModel(
    $input: CreateAssetManufacturerModelInput!
  ) {
    createAssetManufacturerModel(input: $input) {
      _id
    }
  }
`;

const updateAssetManufacturerModelMutation = gql`
  mutation updateAssetManufacturerModel(
    $id: ID!
    $input: UpdateAssetManufacturerModelInput!
  ) {
    updateAssetManufacturerModel(id: $id, input: $input) {
      _id
    }
  }
`;

// getAssetModelValues :: AssetModel- > AssetModel
const getAssetModelValues = R.applySpec({
  name: R.prop('name'),
  lifeExpectancy: R.prop('lifeExpectancy'),
  replacementCost: R.propOr(null, 'replacementCost'),
});

export function AssetManufacturerModelForm({ manufacturerId, ...assetModel }) {
  const isEdit = assetModel?._id;

  const successMessage = `Asset model was successfully ${
    isEdit ? 'edited' : 'added'
  }`;

  const formId = isEdit
    ? editAssetManufacturerModelFormId
    : addAssetManufacturerModelFormId;

  const mutation = isEdit
    ? updateAssetManufacturerModelMutation
    : createManufacturerModelMutation;

  const { showSuccessNotification } = useNotificationState();
  const { closeModal } = useModalContext();

  const pristineSubscribeProps = usePristineSubscribe();

  const [mutateClientGLCode] = useMutation(mutation);

  const handleSubmit = async (values) => {
    await mutateClientGLCode({
      variables: {
        ...(isEdit ? { id: assetModel._id } : {}),
        input: {
          ...getAssetModelValues(values),
          ...(isEdit ? {} : { manufacturerId }),
        },
      },
    });
    showSuccessNotification(successMessage);
    closeModal(formId);
  };

  AssetManufacturerModelForm.propTypes = {
    manufacturerId: string,
  };

  const initialValues = {
    ...(isEdit ? R.omit(['_id', '__typename'], assetModel) : {}),
  };

  return (
    <FormCreator
      id={formId}
      formId={formId}
      sections={assetManufacturerModelFormSections}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      layout={commonModalLayout}
      {...pristineSubscribeProps}
    />
  );
}
