import React from 'react';
import * as R from 'ramda';
import { string, arrayOf, func, bool } from 'prop-types';
import {
  pathOrNothingUI,
  highlightTextToReactElement,
  filterItemsBySearchText,
} from 'poly-client-utils';
import {
  applyProp,
  formatTotal,
  formatMoneyTransactionPayeeNameColumn,
  assocBy,
  formatDate,
  isNilOrEmpty,
} from 'poly-utils';
import { Loader, DatePicker } from 'poly-book-admin';
import {
  useSelectableTableRowsProps,
  useMapConfigToTableProps,
} from 'poly-admin-ui';
import styled from 'styled-components';

import { ReconciliationTableComp } from './reconciliationEntityComponents.js';
import { moneyTransactionShapePropType } from '../BankRegistersPage/commonPropTypes.js';
import { onChangeClearedDateHandler } from './bankReconciliationUtils.js';

// numberColumn :: String -> Pair String (MoneyTransaction -> String)
const numberColumn = R.cond([
  [
    R.equals('payments'),
    R.always(['Check Number', R.prop('reference_column')]),
  ],
  [
    R.equals('creditCharges'),
    R.always([
      'CC User',
      pathOrNothingUI(['creditCardUser', 'user', 'profile', 'fullName']),
    ]),
  ],
  [R.T, R.always(['Reference #', R.prop('reference_column')])],
]);

const DatePickerWithMarginS = styled(DatePicker)`
  margin: 1px 0;
`;

const reconciliationTableConfig = (tableKey, searchTerm, selectedRows) => {
  const highlightMatches = highlightTextToReactElement(searchTerm);
  const [numberColumnTitle, numberColumnGetter] = numberColumn(tableKey);

  return [
    [
      'Clearing Date',
      ({ onChangeClearingDate, bank_cleared_date, ...row }) => (
        <DatePickerWithMarginS
          position="top"
          width="100%"
          isMultiple={false}
          onChange={onChangeClearingDate}
          value={bank_cleared_date}
          overlay
          openOnIconClick
          withOnChange
          showResetBtn={!R.includes(row._id, selectedRows)}
          {...row}
        />
      ),
    ],
    ['Date', applyProp(R.compose(highlightMatches, formatDate))('date')],
    [numberColumnTitle, R.compose(highlightMatches, numberColumnGetter)],
    [
      'Payee',
      R.compose(highlightMatches, formatMoneyTransactionPayeeNameColumn),
    ],
    ['Amount', applyProp(R.compose(highlightMatches, formatTotal))('amount')],
  ];
};

// formatRowTransactions :: ([MoneyTransaction -> MoneyTransaction]) -> [MoneyTransaction] -> [MoneyTransaction]
const formatRowTransactions = (setData) => (rows) =>
  R.map(
    assocBy(
      'onChangeClearingDate',
      (row) => (value) => setData(onChangeClearedDateHandler(row, value)),
    ),
    rows,
  );

const fieldPaths = [
  ['filter_bank_cleared_date'],
  ['filter_date'],
  ['filter_reference_column'],
  ['filter_payee_name'],
  ['filter_amount'],
  ['filter_user_profile_fullName'],
];

const prepareDataToFilter = R.map(
  R.compose(
    assocBy(
      'filter_user_profile_fullName',
      R.path(['creditCardUser', 'user', 'profile', 'fullName']),
    ),
    assocBy(
      'filter_bank_cleared_date',
      R.ifElse(
        R.propIs(Date, 'bank_cleared_date'),
        R.compose(formatDate, R.prop('bank_cleared_date')),
        R.always(''),
      ),
    ),
    assocBy('filter_date', R.compose(formatDate, R.prop('date'))),
    assocBy('filter_reference_column', R.prop('reference_column')),
    assocBy(
      'filter_payee_name',
      R.compose(formatMoneyTransactionPayeeNameColumn),
    ),
    assocBy('filter_amount', R.compose(formatTotal, R.prop('amount'))),
  ),
);

export function ReconciliationEntityTable({
  data,
  setData,
  loading,
  tableKey,
  selectedData,
  setSelectedData,
  searchTerm,
  loadMoreItems,
  allItemsFetched,
  ...restProps
}) {
  const filteredData = isNilOrEmpty(searchTerm)
    ? data
    : filterItemsBySearchText(
        searchTerm,
        fieldPaths,
        prepareDataToFilter(data),
      );

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    formatRowTransactions(setData),
    reconciliationTableConfig(tableKey, searchTerm, selectedData),
    filteredData,
  );

  const { toggleRow, toggleSelectAll } = useSelectableTableRowsProps(
    selectedData,
    setSelectedData,
    data,
  );

  return loading ? (
    <Loader />
  ) : (
    <ReconciliationTableComp
      selectedRows={selectedData}
      isRowSelectable={() => true}
      toggleSelectAll={toggleSelectAll}
      toggleRow={toggleRow}
      headers={headers}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      isLoading={loading}
      showScrollBar
      itemSize={35}
      {...restProps}
    />
  );
}

ReconciliationEntityTable.propTypes = {
  loading: bool,
  tableKey: string.isRequired,
  searchTerm: string,
  data: arrayOf(moneyTransactionShapePropType).isRequired,
  setData: func.isRequired,
  setSelectedData: func.isRequired,
  selectedData: arrayOf(string).isRequired,
  loadMoreItems: func.isRequired,
  allItemsFetched: bool.isRequired,
};

ReconciliationEntityTable.defaultProps = { loading: false };
