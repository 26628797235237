import React from 'react';
import { useRedirectIfHasNoPermissions } from 'poly-client-utils';
import { CREATE_CLIENT_BATCH_PERMISSION } from 'poly-security';

import { BatchInvoicingTMPage } from './BatchInvoicingTMPage.js';
import { tmBassProBatchInvoicingQuery } from './client-filters-presets.js';

export function BatchInvoicingTMBassProPage() {
  useRedirectIfHasNoPermissions([CREATE_CLIENT_BATCH_PERMISSION]);

  return (
    <BatchInvoicingTMPage
      title="Bass Pro T&M Batch Invoice"
      query={tmBassProBatchInvoicingQuery}
      isClientNTE
    />
  );
}
