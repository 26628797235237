import * as R from 'ramda';
import { endOfDay } from 'date-fns';
import styled, { css } from 'styled-components';
import React, { useEffect, useMemo, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { useRouterQuery } from 'poly-client-routing';
import { assocBy, toDate } from 'poly-utils';
import {
  TaskDueDateFilterPreset,
  UserEmployeeInfoStatus,
  taskCollections,
} from 'poly-constants';
import {
  getAdminUsersByEmploymentStatusQuery,
  useMapConfigToTablePropsWithSorting,
  taskStatusesUI,
  FlexContainer,
  UserSelect,
  ALL,
  useSelectableTableRowsProps,
} from 'poly-admin-ui';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  getThemeColor,
  MainHeader,
  Select,
  TextButton,
  ToolBarBtnDivider,
} from 'poly-book-admin';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';
import { TaskPrioritySelect } from 'poly-admin-ui/src/modules/forms/taskForm/form/components/TaskPrioritySelect.js';
import { usePersistDnDAreaWithMentions } from 'poly-client-utils/src/hooks/persists/usePersistDnDAreaWithMentions.js';

import { customTaskTypeOption, searchFiltersNames } from './constants.js';
import { MyTasksPrintBtn } from './MyTasksPrintBtn.js';
import { MyTasksTable, getProjectTasksTableConfig } from './MyTasksTable.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { useMyTasksQuery } from './useMyTasksSubscription.js';
import { useOpenUpdateTasksSidebar } from './sidebar/useOpenUpdateTasksSidebar.js';
import { generateTasksInput } from './generateTasksInput.js';
import { getTasksPersistKey } from './sidebar/UpdateTasksSidebarForm.js';

const { PROJECTS, CLIENTS, PROPERTIES, MASTER_SUPPLIERS, SUPPLIERS } =
  taskCollections;

const SearchToolbarContainer = styled(FlexContainer)`
  height: 100%;
  position: relative;
`;

const ToolbarContainerS = styled(FlexContainer)`
  align-items: flex-end;
  position: absolute;
  right: 0;
  bottom: -10px;

  label {
    color: ${getThemeColor(['midDark'])};
  }
`;

const selectStyles = css`
  width: 140px;
  margin-right: 20px;
`;

const SelectS = styled(Select)`
  ${selectStyles};
`;

const UserSelectS = styled(UserSelect)`
  ${selectStyles};
`;

const TaskPrioritySelectS = styled(TaskPrioritySelect)`
  ${selectStyles};
`;

const dueDateSelectorOptions = [
  {
    value: TaskDueDateFilterPreset.DUE_TODAY,
    label: taskStatusesUI[TaskDueDateFilterPreset.DUE_TODAY],
  },
  {
    value: TaskDueDateFilterPreset.DUE_TOMORROW,
    label: taskStatusesUI[TaskDueDateFilterPreset.DUE_TOMORROW],
  },
  {
    value: TaskDueDateFilterPreset.PAST_DUE,
    label: taskStatusesUI[TaskDueDateFilterPreset.PAST_DUE],
  },
  {
    value: TaskDueDateFilterPreset.READY_TO_CLOSE,
    label: taskStatusesUI[TaskDueDateFilterPreset.READY_TO_CLOSE],
  },
  {
    value: TaskDueDateFilterPreset.ALL,
    label: taskStatusesUI[TaskDueDateFilterPreset.ALL],
  },
];

function DueDateSelector(props) {
  const selectProps = {
    ...props,
    name: 'due-date-selector',
    options: dueDateSelectorOptions,
    required: true,
  };

  return <SelectS {...selectProps} />;
}

const taskTypeOptions = [
  { value: PROJECTS, label: 'Project Task' },
  { value: CLIENTS, label: 'Client Task' },
  { value: PROPERTIES, label: 'Property Task' },
  { value: SUPPLIERS, label: 'Supplier Task' },
  { value: MASTER_SUPPLIERS, label: 'Master Supplier Task' },
  { value: customTaskTypeOption, label: 'Custom Task' },
  { value: ALL, label: 'All' },
];

function TaskTypeSelector(props) {
  const selectProps = {
    ...props,
    name: 'task-type-selector',
    options: taskTypeOptions,
    required: true,
  };

  return <SelectS {...selectProps} />;
}

const searchFiltersConfig = [
  { name: searchFiltersNames.dueDate },
  { name: searchFiltersNames.taskType, defaultValue: ALL },
  { name: searchFiltersNames.manager },
  { name: searchFiltersNames.priority, defaultValue: ALL },
];

// prepareTableRows :: [Task] -> [Task]
const prepareTableRows = R.compose(
  R.map(
    R.compose(
      assocBy('project', R.path(['document', 'project'])),
      R.over(
        R.lensProp('dueDate'),
        R.unless(R.isNil, R.compose(endOfDay, toDate)),
      ),
    ),
  ),
  R.defaultTo([]),
);

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  height: 29px;
`;

// getSelectedTasks :: ([Task], [String]) -> [Task]
const getSelectedTasks = (tasks, ids) =>
  R.filter(R.compose(R.includes(R.__, ids), R.prop('_id')), tasks);

function BulkUpdateTasksSidebarButton({
  tasks,
  selectedTasksIds,
  unselectTasks,
}) {
  const openSidebar = useOpenUpdateTasksSidebar();

  const handleClick = () => {
    const selectedTasks = getSelectedTasks(tasks, selectedTasksIds);
    openSidebar(selectedTasks, unselectTasks);
  };

  const isDisable = R.isEmpty(selectedTasksIds);

  return (
    <TextButton disabled={isDisable} onClick={handleClick}>
      Bulk Update
    </TextButton>
  );
}

BulkUpdateTasksSidebarButton.propTypes = {
  tasks: arrayOf(shape({})),
  selectedTasksIds: arrayOf(string),
  unselectTasks: func.isRequired,
};

// getSelectedTasksIds :: ([ID], [Task]) -> [ID]
const getSelectedTasksIds = (ids, tasks) =>
  R.compose(R.filter(R.includes(R.__, ids)), R.pluck('_id'))(tasks);

export function MyTasksPage() {
  const [selectedTasksIds, setSelectedTasksIds] = useState([]);

  const { managerId } = useRouterQuery(['managerId']);

  const { cleanupRetainedValue } = usePersistDnDAreaWithMentions(
    getTasksPersistKey(managerId),
  );

  const unselectTasks = () => setSelectedTasksIds([]);

  const { searchFilters, handlers } = useSearchFilters(searchFiltersConfig);

  const { user } = useCurrentUserByStoreOrQuery();
  const userId = useMemo(() => R.prop('_id')(user), [user]);

  useEffect(() => {
    if (userId && !managerId) {
      handlers[searchFiltersNames.manager](userId);
    }
    return () => cleanupRetainedValue();
  }, [userId, managerId]);

  const tasksInput = generateTasksInput(searchFilters);

  const { tasks, loading } = useMyTasksQuery(tasksInput);

  const preparedTasks = prepareTableRows(tasks);

  const tableProps = useMapConfigToTablePropsWithSorting({
    tableConfig: getProjectTasksTableConfig,
    items: preparedTasks,
    initialSorting: {
      columnKey: 4,
      dir: -1,
    },
  });

  useEffect(() => {
    if (!loading) {
      const newIds = getSelectedTasksIds(selectedTasksIds, tasks);
      setSelectedTasksIds(newIds);
    }
  }, [tasks]);

  const { toggleRow, toggleSelectAll } = useSelectableTableRowsProps(
    selectedTasksIds,
    setSelectedTasksIds,
    preparedTasks,
  );

  return (
    <>
      <PageHeaderContainer headerStyles="position:relative">
        <MainHeader>Tasks</MainHeader>
        <SearchToolbarContainer>
          <ToolbarContainerS>
            <UserSelectS
              query={getAdminUsersByEmploymentStatusQuery(
                UserEmployeeInfoStatus.ACTIVE,
              )}
              value={searchFilters[searchFiltersNames.manager]}
              onChange={handlers[searchFiltersNames.manager]}
              placeholder="Filter by manager"
              isClearable={false}
              label="Assigned CSR"
              withoutSkip
              required
            />
            <TaskTypeSelector
              value={searchFilters[searchFiltersNames.taskType]}
              onChange={handlers[searchFiltersNames.taskType]}
              placeholder="Filter by task type"
              label="Task Type"
            />
            <DueDateSelector
              value={searchFilters[searchFiltersNames.dueDate]}
              onChange={handlers[searchFiltersNames.dueDate]}
              placeholder="Filter by due date"
              label="Due"
            />
            <TaskPrioritySelectS
              value={searchFilters[searchFiltersNames.priority]}
              onChange={handlers[searchFiltersNames.priority]}
              placeholder="Filter by priority"
              label="Priority"
              withAllOption
            />
            <ButtonsWrapper>
              <BulkUpdateTasksSidebarButton
                tasks={preparedTasks}
                selectedTasksIds={selectedTasksIds}
                unselectTasks={unselectTasks}
              />
              <ToolBarBtnDivider />
              <MyTasksPrintBtn tasks={tableProps.rows} />
            </ButtonsWrapper>
          </ToolbarContainerS>
        </SearchToolbarContainer>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <MyTasksTable
          {...tableProps}
          toggleRow={toggleRow}
          toggleSelectAll={toggleSelectAll}
          loading={loading}
          selectedTasksIds={selectedTasksIds}
        />
      </DefaultBodyWrapper>
    </>
  );
}
