import { useProcessState } from 'poly-admin-ui/src/index.js';

export const useFormButtonProps = (formId, isSubmit = true) => {
  const { process } = useProcessState(formId);

  const baseProps = { loader: process, disabled: process, formId };

  return isSubmit
    ? {
        ...baseProps,
        form: formId,
        type: 'submit',
      }
    : {
        ...baseProps,
      };
};
