import React, { useState, useCallback } from 'react';
import { func, string } from 'prop-types';
import { Input } from 'poly-book-admin';
import styled from 'styled-components';
import { debounce } from 'poly-utils';

const BankRegistersSearchInputS = styled(Input).attrs({
  name: 'search_term',
  placeholder: 'Search',
})`
  width: 140px;
  margin-right: 10px;
`;

export function BankRegistersSearchInput({
  onChange,
  value: initialValue,
  ...restProps
}) {
  const [internalValue, setInternalValue] = useState(initialValue);

  const onChangeDebounced = useCallback(
    debounce(500)((searchTerm) => {
      onChange(searchTerm);
    }),
    [onChange],
  );

  const onChangeInternal = (event) => {
    const { value } = event.target;
    setInternalValue(value);
    onChangeDebounced(value);
  };

  return (
    <BankRegistersSearchInputS
      {...restProps}
      value={internalValue}
      onChange={onChangeInternal}
    />
  );
}

BankRegistersSearchInput.propTypes = {
  value: string,
  onChange: func.isRequired,
};
