import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { string, number, oneOfType } from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useUploadAttachment } from 'poly-client-utils/src/files/useUploadAttachment.js';
import { jsonPostRequest, validateFilesFunc } from 'poly-client-utils';
import { WeeklyServiceTicketStatuses } from 'poly-constants';
import { setTemporalUserToken } from 'poly-apollo-client';
import { FormCreator, Loader } from 'poly-book-admin';
import {
  graphqlTemporalTokenRoute,
  insertQueryParamsIntoURL,
} from 'poly-utils';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
  VENDOR_INFO_TYPES,
  useModalContext,
  HoursInput,
  thirdWidth,
  halfWidth,
} from 'poly-admin-ui';
import {
  useRouterParams,
  useRouterQuery,
  useNavigate,
} from 'poly-client-routing';

import {
  CheckboxWithLabelS,
  WeeklyTicketPicker,
  TotalHoursDisplayInput,
  notCompletedIsNotChecked,
} from '../../sidebars/ProjectSidebar/forms/form/getWeeklyServiceTicketFormSections.js';
import { weeklyTicketValidators } from '../../sidebars/ProjectSidebar/forms/form/weeklyServiceTicketFormValidators.js';
import { editWeeklyServiceTicketMutation } from '../../sidebars/ProjectSidebar/forms/WeeklyServiceTicketForm.js';
import { useHandleTemporalTokenError } from '../../hooks/useHandleTemporalTokenError.js';
import { routesNames } from '../../routes/index.js';

const { GRAPHQL_HTTP_URL = '' } = process.env;

const SupplierAttachServiceTicketFileFormId =
  'supplierAttachServiceTicketFileFormId';

const SUPPLIER_SERVICE_TICKET_TOKEN_QUERY = gql`
  query SUPPLIER_SERVICE_TICKET_TOKEN_QUERY {
    supplierServiceTicketByToken {
      _id
      projectId
      weeklyPorterHours
      clientName
      propertyName
      week
    }
  }
`;

// getInitialValues :: Account -> FormData
const getInitialValues = R.applySpec({
  projectId: R.propOr('', 'projectId'),
  clientName: R.propOr('', 'clientName'),
  propertyName: R.propOr('', 'propertyName'),
  week: R.propOr('', 'week'),
  attachment: R.always([]),
});

const ReadOnlyFields = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #1a1514;
`;

function ReadOnlyField({ value }) {
  return <ReadOnlyFields>{value}</ReadOnlyFields>;
}

ReadOnlyField.propTypes = {
  value: oneOfType([string, number]),
};

const formCreatorSections = ({ weeklyPorterHours }) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Branch',
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'propertyName',
          Component: ReadOnlyField,
        },
      },
      {
        label: 'Client',
        order: 2,
        layout: { row: 2 },
        field: {
          name: 'clientName',
          Component: ReadOnlyField,
        },
      },
      {
        label: 'Project',
        order: 3,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'projectId',
          Component: ReadOnlyField,
        },
      },
      {
        label: 'Week',
        order: 4,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'week',
          Component: ReadOnlyField,
        },
      },
      {
        order: 5,
        layout: { row: 4, padding: '5px 0 20px 0' },
        field: {
          name: 'isNotCompleted',
          Component: (props) => (
            <CheckboxWithLabelS {...props} label="Not completed" />
          ),
        },
      },
      {
        label: 'Weekly Porter Hours',
        order: 6,
        layout: { row: 5, width: thirdWidth },
        field: {
          name: 'hours',
          Component: HoursInput,
        },
        required: true,
        validators: [
          [R.either(R.is(Number), R.identity), 'Hours is required field'],
          [R.lte(R.__, weeklyPorterHours), 'Can`t exceeds Weekly Porter Hours'],
        ],
        renderIf: notCompletedIsNotChecked,
      },
      {
        label: 'Extra Porter Hours',
        order: 7,
        layout: { row: 5, width: thirdWidth },
        field: {
          name: 'extraHours',
          Component: HoursInput,
        },
        required: true,
        validators: [
          [R.either(R.is(Number), R.identity), 'Hours is required field'],
        ],
        renderIf: notCompletedIsNotChecked,
      },
      {
        label: 'Total Porter Hours',
        order: 8,
        layout: { row: 5, width: thirdWidth },
        field: {
          name: 'totalHours',
          withFormData: true,
          Component: TotalHoursDisplayInput,
        },
        renderIf: notCompletedIsNotChecked,
      },
      {
        label: 'Attach Weekly Ticket',
        order: 9,
        layout: { row: 6 },
        field: {
          name: 'attachment',
          Component: WeeklyTicketPicker,
        },
        required: true,
        validators: weeklyTicketValidators,
        validateFunction: validateFilesFunc,
        renderIf: notCompletedIsNotChecked,
      },
    ],
  },
];

const getVerifySupplierTokenResult = R.prop('supplierServiceTicketByToken');

// getUploadWithName :: FormData -> {upload: File, fileName: String}
const getUploadWithName = R.compose(
  R.unless(R.isNil, R.pick(['upload', 'fileName'])),
  R.path(['attachment', 0]),
);

export function SupplierAttachServiceTicketFilePage() {
  const navigate = useNavigate();
  const { randomId } = useRouterParams(['randomId']);
  // to keep compatibility with old implementation, can be deleted starting from 01/11/2021
  const { token } = useRouterQuery(['token']);
  const [isAuthorized, setIsAuthorized] = useState(!!token);
  const { openModalForm, closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [addWeeklyServiceTicket] = useMutation(editWeeklyServiceTicketMutation);
  const handleTemporalTokenError = useHandleTemporalTokenError();

  if (token) {
    setTemporalUserToken(token);
  }

  const { data, loading } = useQuery(SUPPLIER_SERVICE_TICKET_TOKEN_QUERY, {
    fetchPolicy: 'network-only',
    skip: !isAuthorized,
  });

  const uploadFile = useUploadAttachment();

  const formProps = getVerifySupplierTokenResult(data);

  const onSubmitHandler = async (values) => {
    let uploadedFileId = null;

    const attachment = getUploadWithName(values);

    const isTicketCompleted = notCompletedIsNotChecked(values);

    if (attachment) {
      uploadedFileId = await uploadFile(attachment.upload, attachment.fileName);
    }

    await addWeeklyServiceTicket({
      variables: {
        input: {
          projectId: formProps._id,
          week: values.week,
          ticket: {
            hours: isTicketCompleted ? values.hours : 0,
            extraHours: isTicketCompleted ? values.extraHours : 0,
            status: isTicketCompleted
              ? WeeklyServiceTicketStatuses.received
              : WeeklyServiceTicketStatuses.notCompleted,
            ...(uploadedFileId ? { uploadedFileId } : {}),
          },
        },
      },
    });
    closeModal(SupplierAttachServiceTicketFileFormId);
    // max time success message shown - 24h
    showSuccessNotification('Ticket Was Added.', 86400);
    navigate(
      insertQueryParamsIntoURL(
        { type: VENDOR_INFO_TYPES.DOCUMENTS_SUBMITTED },
        routesNames.VENDOR_INFO,
      ),
    );
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    SupplierAttachServiceTicketFileFormId,
    onSubmitHandler,
  );

  useEffect(() => {
    if (isAuthorized && !loading && formProps) {
      openModalForm({
        id: SupplierAttachServiceTicketFileFormId,
        title: 'Attach Project Service Ticket',
        formId: SupplierAttachServiceTicketFileFormId,
        btnCaption: 'Submit',
        content: (
          <FormCreator
            id={SupplierAttachServiceTicketFileFormId}
            formId={SupplierAttachServiceTicketFileFormId}
            sections={formCreatorSections(formProps)}
            initialValues={getInitialValues(formProps)}
            onSubmit={onSubmit}
            layout={{ card: false }}
          />
        ),
      });
    }
  }, [formProps]);

  useEffect(() => {
    if (!isAuthorized) {
      const fetchToken = async () => {
        const { token: authToken } = await jsonPostRequest(
          graphqlTemporalTokenRoute(GRAPHQL_HTTP_URL),
          {
            randomId,
          },
        );
        setTemporalUserToken(authToken);
        setIsAuthorized(true);
      };
      fetchToken().catch((err) => handleTemporalTokenError(err));
    }
  }, []);

  return <div>{loading ? <Loader /> : null}</div>;
}

export function SupplierAttachServiceTicketFilePageOld() {
  const navigate = useNavigate();
  const { token } = useRouterParams(['token']);

  useEffect(() => {
    navigate(
      insertQueryParamsIntoURL(
        { token },
        routesNames.SUPPLIER_ATTACH_SERVICE_TICKET_FILE,
      ),
    );
  }, []);
  return null;
}
