import React from 'react';
import { formatSelectOptionsByConstantsValue } from 'poly-client-utils';
import { peopleStatuses } from 'poly-constants';
import { Select } from 'poly-book-admin';

export function StatusDropDown(props) {
  const statusesOptions = formatSelectOptionsByConstantsValue(peopleStatuses);

  return <Select {...props} options={statusesOptions} />;
}
