import styled from 'styled-components';
import {
  pipColumnStyles,
  singleButtonColumnStyles,
  Table,
} from 'poly-book-admin';

export const TableS = styled(Table)`
  ${pipColumnStyles(1)} th:nth-child(2), td:nth-child(2) {
    width: 300px;
  }
  ${singleButtonColumnStyles(4)};
`;
