import React from 'react';
import { shape, string } from 'prop-types';
import { pathOrNothingUI } from 'poly-client-utils';
import { SidebarRow } from 'poly-admin-ui';

import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapper,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import {
  getSectionPhoneLink,
  getSectionText,
} from '../components/commonSidebarSectionFormatters.js';

export function PropertySidebarAlarmInfo({ property }) {
  const { alarmInfo } = property;

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Alarm Information</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          id="alarm-code"
          label="Alarm Code"
          {...threeBlocksProps}
          Component={getSectionText(pathOrNothingUI(['alarmCode'])(alarmInfo))}
        />
        <BlockWithLabel
          id="alarm-system"
          label="Alarm System"
          {...threeBlocksProps}
          Component={getSectionText(
            pathOrNothingUI(['alarmSystem'])(alarmInfo),
          )}
        />
        <BlockWithLabel
          id="account-number"
          label="Account Number"
          {...threeBlocksProps}
          Component={getSectionText(
            pathOrNothingUI(['accountNumber'])(alarmInfo),
          )}
        />
        <BlockWithLabel
          id="gate-code"
          label="Gate Code"
          {...threeBlocksProps}
          Component={getSectionText(pathOrNothingUI(['gateCode'])(alarmInfo))}
        />
        <BlockWithLabel
          id="password"
          label="Password"
          {...threeBlocksProps}
          Component={getSectionText(pathOrNothingUI(['password'])(alarmInfo))}
        />
        <BlockWithLabel
          id="alarm-company-phone"
          label="Alarm Company Phone"
          {...threeBlocksProps}
          Component={getSectionPhoneLink(alarmInfo?.alarmCompanyPhone)}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

PropertySidebarAlarmInfo.propTypes = {
  property: shape({
    alarmInfo: shape({
      password: string,
      gateCode: string,
      alarmCode: string,
      alarmSystem: string,
      accountNumber: string,
      alarmCompanyPhone: string,
    }),
  }),
};
