import * as R from 'ramda';
import { EQUALS_AIT_OPERATOR } from 'poly-security';
import { assocBy, forceTitleCase, renameProp } from 'poly-utils';

import { userGroupVariablesMap } from '../constants.js';

// prepareVariableLine :: { name: String, type: String } -> String
const prepareVariableLine = assocBy(
  'scopeText',
  R.compose(
    R.join(' | '),
    R.juxt([
      R.compose(
        forceTitleCase,
        R.prop(R.__, userGroupVariablesMap),
        R.prop('type'),
      ),
      R.prop('name'),
    ]),
  ),
);

// prepareInitialValues :: UserGroup -> Object
export const prepareInitialValues = (userGroup) =>
  R.applySpec({
    usersToAssign: R.always([]),
    variables: R.compose(
      R.map(R.assoc('operator', EQUALS_AIT_OPERATOR)),
      R.map(renameProp('type', 'scope')),
      R.apply(R.concat),
      R.juxt([
        R.ifElse(
          R.isEmpty,
          R.identity,
          R.compose(
            R.of,
            R.mergeLeft({ userGroup }),
            prepareVariableLine,
            R.head,
          ),
        ),
        R.compose(R.map(prepareVariableLine), R.tail),
      ]),
      R.propOr([], 'variables'),
    ),
  })(userGroup);
