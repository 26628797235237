import * as R from 'ramda';
import React from 'react';
import { object, string, shape, arrayOf, oneOfType } from 'prop-types';
import { Loader } from 'poly-book-admin';
import { FULL_ACCESS_PERMISSION } from 'poly-security';
import {
  applyPathOrNothingUI,
  useHasUserAccessWithPermission,
  useHighlightMatchesBySearch,
} from 'poly-client-utils';
import {
  usersEmailColumn,
  usersPhoneColumn,
  userWorkPhoneExtColumn,
  useSetItemsCount,
  useTableSorting,
  useMapConfigToTableProps,
  useSetInitialPaginationOnMount,
} from 'poly-admin-ui';
import { formatDate } from 'poly-utils';

import { UsersTableComp } from './UsersTableComp.js';
import {
  UserEmploymentStatus,
  usersLocationColumn,
  usersNameColumn,
  usersRoleColumn,
} from '../columns/users.js';
import { useSaveQueryForXLSExport } from '../../core/tableToolbar/TableExportToXLSBtn.js';
import { useStaffUsersBySearchTable } from '../../core/hooks/users/index.js';

const staffUsersTableConfig = [
  ['', UserEmploymentStatus],
  usersNameColumn,
  usersRoleColumn,
  usersPhoneColumn,
  userWorkPhoneExtColumn,
  usersEmailColumn,
  [
    'Hire Date',
    applyPathOrNothingUI(['employeeInfo', 'startDate'], formatDate),
  ],
  usersLocationColumn,
];

// getUsersTotal :: SearchUsersQueryResult -> Number
const getUsersTotal = R.pathOr(0, ['searchUsers', 'total']);

// getUsers :: SearchUsersQueryResult :: -> [User]
const getUsers = R.pathOr([], ['searchUsers', 'hits']);

export function StaffTable(props) {
  const ifHasPermission = useHasUserAccessWithPermission(
    FULL_ACCESS_PERMISSION,
  );

  useSetInitialPaginationOnMount();

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: staffUsersTableConfig,
    column: 2,
  });

  const { loading, result } = useStaffUsersBySearchTable({
    query: props.query,
    sort,
    alias: 'useStaffUsersBySearchTable',
  });

  useSaveQueryForXLSExport(props);

  useSetItemsCount(getUsersTotal, result);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getUsers,
    staffUsersTableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['fullName'], ['address', 'formatted_address'], ['status'], ['email']],
    R.map(R.mergeRight({ asText: R.equals(ifHasPermission, false) }), rows),
  );

  if (loading) return <Loader />;

  return (
    <UsersTableComp
      {...props}
      {...tableSortingProps}
      {...restTableProps}
      rows={highlightedRows}
    />
  );
}

StaffTable.displayNAme = 'UsersTable';

StaffTable.propTypes = {
  query: shape({
    bool: shape({
      filter: arrayOf(oneOfType([string, object])),
      must: arrayOf(oneOfType([object])),
    }),
  }),
};
