import * as R from 'ramda';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Icon, IconButton } from 'poly-book-admin';
import { useLocation } from 'poly-client-routing';
import { ensureIsDate } from 'poly-utils';

import {
  RepliesTable,
  SectionWrapper,
  ContentWrapper,
  AdditionalReplyInfo,
  RepliesTableWrapper,
  ToggleRepliesButton,
  ToggleRepliesWrapper,
} from './components.js';
import { UpdateDetails } from './UpdateDetails.js';
import { UpdateMetaInfo } from './UpdateMetaInfo.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';
import { useMultipleRefs } from '../../../hooks/useMultipleRefs.js';
import { useCopyLinkHook } from './useCopyLinkHook.js';

const SectionWrapperS = styled(SectionWrapper)`
  padding: 0 !important;
`;

const ContentWrapperS = styled(ContentWrapper)`
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 16px;
`;

function ToggleButton({ isOpen, setIsOpen, updateComments }) {
  const { count, user, time } = R.converge(R.mergeDeepLeft, [
    R.pipe(R.length, R.objOf('count')),
    R.pipe(
      R.last,
      R.applySpec({
        user: R.path(['createdBy', 'fullName']),
        time: R.pipe(R.prop('createdAt'), ensureIsDate, formatDistanceToNow),
      }),
    ),
  ])(updateComments);

  const countsEnding = count === 1 ? 'y' : 'ies';

  const buttonText = isOpen
    ? 'Collapse replies'
    : `${count} repl${countsEnding}`;

  const toggleReplies = () => setIsOpen(!isOpen);

  return (
    <ToggleRepliesWrapper data-testid="update-comments-toggle" {...{ isOpen }}>
      <ToggleRepliesButton
        data-testid="update-comments-toggle-button"
        onClick={toggleReplies}
      >
        <IconButton size={12} name={isOpen ? 'arrow-down' : 'arrow-forward'} />
        <div>{buttonText}</div>
      </ToggleRepliesButton>
      {!isOpen && (
        <AdditionalReplyInfo>
          Last reply by <span>{user}</span> {time} ago
        </AdditionalReplyInfo>
      )}
    </ToggleRepliesWrapper>
  );
}

const updateCommentsPropTypes = arrayOf(shape({ _id: string.isRequired }));

ToggleButton.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  updateComments: updateCommentsPropTypes,
};

function CopyLinkIcon({ _id }) {
  const copyLink = useCopyLinkHook('comment', _id);

  return (
    <IconWrapper id="updates-icons">
      <Icon color="#bcbcbc" size={12} name="copy-link" onClick={copyLink} />
    </IconWrapper>
  );
}

CopyLinkIcon.propTypes = { _id: string.isRequired };

const tableConfig = [
  ['User', UpdateMetaInfo],
  ['Message', UpdateDetails],
  ['Copy', CopyLinkIcon],
];

export function UpdateCommentsSection({ updateComments }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const { setRef, getRef } = useMultipleRefs();
  const tableProps = useMapConfigToTableProps(
    R.map(R.mergeDeepLeft({ setRef })),
    tableConfig,
    updateComments,
  );

  const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    return elemTop >= 0 && elemBottom <= window.innerHeight;
  };

  useEffect(() => {
    const commentHashId = R.compose(
      R.last,
      R.split('_'),
      R.prop('hash'),
    )(location);
    const refRowByHash = getRef(commentHashId);

    if (refRowByHash) {
      refRowByHash.setAttribute('active', 'true');

      if (!isElementVisible(refRowByHash)) {
        refRowByHash.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isOpen]);

  return (
    <SectionWrapperS data-testid="update-comments-section">
      <ContentWrapperS>
        <ToggleButton {...{ isOpen, setIsOpen, updateComments }} />
        {isOpen && (
          <RepliesTableWrapper data-testid="update-comments-table">
            <RepliesTable minHeightLess {...tableProps} />
          </RepliesTableWrapper>
        )}
      </ContentWrapperS>
    </SectionWrapperS>
  );
}

UpdateCommentsSection.propTypes = {
  updateComments: updateCommentsPropTypes,
};
