import React from 'react';
import { func, string } from 'prop-types';
import { CommonSidebarFormWidth, entities } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';

import { addClientFormId } from '../../constants.js';
import { AddClientSideBarForm } from './AddClientSideBarForm.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';

function AddClientFrom({ onClose, parentClientId }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      formId={addClientFormId}
      submitButtonCaption="Create"
      title={`Create a New ${parentClientId ? 'Child Client' : 'Client'}`}
    >
      <AddClientSideBarForm parentClientId={parentClientId} />
    </SidebarFormLayout>
  );
}

AddClientFrom.propTypes = {
  parentClientId: string,
  onClose: func.isRequired,
};

export const useAddClientSidebar = (isCard, parentClientId) => {
  const { openOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar(isCard);

  const openAddClientSidebar = () =>
    openOutSidebar({
      alwaysFirst: true,
      id: addClientFormId,
      width: CommonSidebarFormWidth,
      content: (
        <AddClientFrom
          parentClientId={parentClientId}
          onClose={() => onClose(entities.CLIENT, parentClientId)}
        />
      ),
    });
  return openAddClientSidebar;
};
