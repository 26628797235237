import {
  arrayOf,
  bool,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { supplierEmailsTypes } from 'poly-constants';

const servicesPropTypes = {
  services: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ).isRequired,
};

const generalInfoPropTypes = {
  name: string.isRequired,
  masterSupplier: shape({ _id: string }),
  phone: string,
  fax: string,
  emails: shape({
    [supplierEmailsTypes.ACCOUNT]: arrayOf(string),
    [supplierEmailsTypes.SERVICE]: string,
    [supplierEmailsTypes.GENERAL]: string,
    [supplierEmailsTypes.SECONDARY_SERVICE]: oneOfType([
      string,
      arrayOf(string),
    ]),
  }).isRequired,
  website: string.isRequired,
  isVeteran: bool,
  foodBuy: shape({
    isEnabled: bool,
    sapVendorNumber: string,
    vendorType: string,
    vaPercentage: number,
  }),
  ...servicesPropTypes,
};

const bankACHPropTypes = {
  payByACH: bool.isRequired,
  routingNumber: string.isRequired,
  accountingNumber: string.isRequired,
  remittanceEmails: arrayOf(string),
};

const financialPropTypes = {
  is1099: bool.isRequired,
  taxClass: string.isRequired,
  taxId: string.isRequired,
  w9FileName: string.isRequired,
  w9FileUrl: string,
  taxAddress1: oneOfType([object, string]).isRequired,
  taxAddress2: string.isRequired,
  taxName: string.isRequired,
  terms: string,
  acceptsCreditCard: bool.isRequired,
  openingInvoicesTotal: number,
  isMaster: bool,
};

const remarksPropTypes = {
  remarks: oneOfType([object, string]).isRequired,
};

export const supplierAccountPropsTypes = {
  servicesPropTypes,
  generalInfoPropTypes,
  bankACHPropTypes,
  financialPropTypes,
  remarksPropTypes,
};
