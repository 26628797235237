import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, element, oneOfType } from 'prop-types';
import { parseQuery, useLocation, useNavigate } from 'poly-client-routing';
import { AuthRoutesRedirect } from 'poly-client-utils';
import { ModalProvider } from 'poly-admin-ui';
import {
  SUPPLIER_ATTACH_INVOICE_ROUTE,
  REQUESTOR_SUBMIT_SURVEY_ROUTE,
  SUBCONTRACTOR_AGREEMENT_ROUTE,
  ADD_SERVICE_TICKET_ROUTE,
  DOCUMENTS_REQUEST_ROUTE,
  VENDOR_INFO_PAGE_ROUTE,
  COMPLETE_CONFIRM_SATISFACTION_ROUTE,
} from 'poly-constants';

import { LayoutContainer } from './styles.js';
import { authRoutesList, routesNames } from '../routes/constants.js';
import { useUserAuthBroadcastChannel } from '../hooks/useBroadcastChannel.js';

const CLEAN_DOCUMENTS_REQUEST_ROUTE = DOCUMENTS_REQUEST_ROUTE.replace(
  '/:randomId',
  '',
);

const PublicLayoutContainer = styled(LayoutContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: min-content;
`;

// shouldSkipTokenValidation :: String -> Boolean
const shouldSkipTokenValidation = R.anyPass([
  R.includes(VENDOR_INFO_PAGE_ROUTE),
  R.includes(ADD_SERVICE_TICKET_ROUTE),
  R.includes(SUPPLIER_ATTACH_INVOICE_ROUTE),
  R.includes(COMPLETE_CONFIRM_SATISFACTION_ROUTE),
  R.includes(CLEAN_DOCUMENTS_REQUEST_ROUTE),
  R.includes(REQUESTOR_SUBMIT_SURVEY_ROUTE),
  R.includes(SUBCONTRACTOR_AGREEMENT_ROUTE),
]);

export function PublicLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { onLoggedInListener } = useUserAuthBroadcastChannel();
  const skipTokenValidation = shouldSkipTokenValidation(location.pathname);

  const { previousUrl } = parseQuery(location.search);

  onLoggedInListener(() => navigate(previousUrl || routesNames.HOME));

  return (
    <ModalProvider>
      <PublicLayoutContainer>{children}</PublicLayoutContainer>
      <AuthRoutesRedirect
        authRoutesList={authRoutesList}
        homeRoute={routesNames.HOME}
        skipTokenValidation={skipTokenValidation}
      />
    </ModalProvider>
  );
}

PublicLayout.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
};
