import styled, { keyframes } from 'styled-components';
import React from 'react';

const calcSize =
  (div = 1, mul = 1) =>
  ({ size }) =>
    (size / div) * mul;

const rotate = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

const Loader = styled.svg.attrs((props) => ({
  width: calcSize()(props),
  height: calcSize()(props),
  'data-testid': 'loader',
}))`
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.3s ease;
  animation: ${rotate} 2s linear infinite;
  transform: translate(-50%, -50%) rotate(360deg);
`;

Loader.Circle = styled.circle.attrs((props) => ({
  cx: calcSize(2)(props),
  cy: calcSize(2)(props),
  r: calcSize(5, 2)(props),
}))`
  fill: none;
  stroke: #4676b4;
  stroke-width: 2;
  stroke-dashoffset: 0;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-dasharray: 1, 500;
  animation: ${dash} 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
`;

export function ButtonLoader(props) {
  return (
    <Loader {...props}>
      <Loader.Circle {...props} />
    </Loader>
  );
}

ButtonLoader.defaultProps = {
  size: 20,
};
