import React from 'react';
import * as R from 'ramda';
import { func, instanceOf, shape } from 'prop-types';
import { DatePicker } from 'poly-book-admin/src/DatePicker/index.js';
import { alwaysNewDate } from 'poly-utils/src/dates.js';
import { isNilOrEmpty } from 'poly-utils/src/general.js';
import { Input } from 'poly-book-admin/src/Input/index.js';

import { RecurringScheduler } from '../../../components/RecurringScheduler/RecurringScheduler.js';
import { NotificationContacts } from '../../../sidebars/PurchaseOrderSidebar/form/purchaseOrderFormSections.js';

export function WOAgeInput({ onChange, ...props }) {
  const handleChange = (e) => {
    const value = e?.target?.value;
    const preparedValue = value ? parseInt(value, 10) : null;

    onChange(preparedValue);
  };

  const onKeyPress = (e) => {
    if (!R.test(/\d/, e.key) || e.target.value > 10000) {
      e.preventDefault();
    }
  };

  return (
    <Input
      onChange={handleChange}
      {...props}
      type="number"
      onKeyPress={onKeyPress}
    />
  );
}

WOAgeInput.propTypes = {
  onChange: func.isRequired,
};

function EndDatePicker({ formData: { startDate }, ...rest }) {
  return (
    <DatePicker {...rest} disabledDays={{ before: startDate }} showResetBtn />
  );
}

EndDatePicker.propTypes = {
  formData: shape({
    startDate: instanceOf(Date),
  }),
};

export const reportSendingConfigurationFormSections = [
  {
    order: 1,
    fields: [
      {
        label: 'Start Sending Report From',
        layout: { label: { position: 'left' } },
        order: 1,
        field: {
          name: 'startDate',
          Component: DatePicker,
          additionalProps: {
            disabledDays: { before: alwaysNewDate() },
            showResetBtn: true,
          },
        },
      },
      {
        label: 'Stop Sending Report On',
        layout: { label: { position: 'left' } },
        order: 2,
        field: {
          name: 'endDate',
          withFormData: true,
          Component: EndDatePicker,
        },
      },
    ],
  },
  {
    order: 2,
    label: 'Report Sent',
    fields: [
      {
        layout: { row: 1, width: '100%' },
        order: 1,
        field: {
          name: 'schedule',
          withFormData: true,
          Component: RecurringScheduler,
        },
      },
    ],
  },
  {
    order: 3,
    label: 'Project Age',
    layout: { justifyContent: 'flex-start' },
    fields: [
      {
        label: 'From',
        layout: {
          row: 1,
          width: '49%',
          label: { position: 'left', width: '50px' },
        },
        order: 1,
        field: {
          name: 'reportFilter.ageFrom',
          Component: WOAgeInput,
        },
      },
      {
        label: '(in days)  To',
        layout: {
          row: 1,
          width: '49%',
          label: { position: 'left', width: '75px' },
        },
        order: 2,
        field: {
          name: 'reportFilter.ageTo',
          Component: WOAgeInput,
        },
      },
      {
        label: 'Emails',
        layout: { row: 2 },
        order: 2,
        field: {
          name: 'emails',
          Component: NotificationContacts,
        },
        validators: [[R.complement(isNilOrEmpty), 'Emails is required']],
        required: true,
      },
    ],
  },
];
