import { useState } from 'react';
import * as R from 'ramda';
import { ASC_SORT_ORDER, DESC_SORT_ORDER } from 'poly-constants';

const getInitialSort = (column, order, tableConfig, sortQueryOptionOrder) => {
  const columnKey = column - 1;
  const getSortQuery = R.path([columnKey, sortQueryOptionOrder], tableConfig);
  if (typeof getSortQuery !== 'function') {
    return {};
  }
  return {
    dir: order === ASC_SORT_ORDER ? 1 : -1,
    query: getSortQuery(order),
    columnKey,
  };
};

const updateGraphSorting = (sortQuery, columnKey, sorting) => {
  if (typeof sortQuery !== 'function') {
    return sorting;
  }
  const dir = sorting.columnKey === columnKey ? -sorting.dir : 1;
  const order = dir === 1 ? ASC_SORT_ORDER : DESC_SORT_ORDER;
  const query = sortQuery(order);
  return {
    columnKey,
    dir,
    query,
  };
};

export const useSortableTable = ({
  order = ASC_SORT_ORDER,
  column,
  tableConfig,
  sortQueryOptionOrder = 2,
}) => {
  const [sorting, setSorting] = useState(
    getInitialSort(column, order, tableConfig, sortQueryOptionOrder),
  );
  return {
    onHeaderCellClick: (columnKey, getSortQuery) => {
      if (typeof getSortQuery === 'function') {
        const newSorting = updateGraphSorting(getSortQuery, columnKey, sorting);
        setSorting(newSorting);
      }
    },
    isServerSorting: true,
    isSortable: true,
    sort: sorting.query,
    sorting,
    setSorting,
  };
};
