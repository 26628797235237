import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { useModalContext, useNotificationState } from 'poly-admin-ui';
import { IconButton, defaultTheme } from 'poly-book-admin';

const UNLINK_PROCEDURE_MUTATION = gql`
  mutation UNLINK_PROCEDURE_MUTATION($input: UnlinkProcedureInput!) {
    unlinkProcedure(input: $input)
  }
`;

export function UnlinkProcedureButton({
  assetId,
  projectId,
  collection,
  procedureId,
}) {
  const { openConfirmSubmitFormModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [unlinkProcedure] = useMutation(UNLINK_PROCEDURE_MUTATION);

  const onUnlinkProcedure = () =>
    openConfirmSubmitFormModal({
      content: 'Are you sure you want to unlink procedure from asset?',
      btnCaption: 'Yes, Unlink',
      processId: 'unlinkProcedureProcessId',
      onConfirm: (closeConfirmModal) => async () => {
        await unlinkProcedure({
          variables: { input: { procedureId, assetId, projectId, collection } },
        });
        showSuccessNotification('Procedure was unlinked from asset');
        closeConfirmModal();
      },
    });

  return (
    <IconButton
      initialColor={R.path(['colors', 'primaryLight'], defaultTheme)}
      onClick={onUnlinkProcedure}
      name="unlink"
      size={11}
    />
  );
}

UnlinkProcedureButton.propTypes = {
  assetId: string,
  projectId: string,
  collection: string,
  procedureId: string,
};
