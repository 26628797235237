import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'poly-client-routing';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from 'poly-admin-ui';

import { routesNames } from '../../routes/index.js';
import { ClientInvoiceForm } from './ClientInvoiceForm.js';
import { clientInvoicePropTypes, projectInfoPropTypes } from './prop-types.js';
import { usePrintInvoicesMutation } from '../SearchClientInvoices/usePrintInvoices.js';

const editClientInvoiceMutation = gql`
  mutation editClientInvoiceMutation(
    $id: ID!
    $input: EditClientInvoiceInput!
  ) {
    editClientInvoice(id: $id, input: $input) {
      clientInvoice {
        _id
      }
    }
  }
`;

// getAmountByPropIfNotExempt :: (Boolean, String) -> Int
const getAmountByPropIfNotExempt = (exempt, propName) =>
  R.ifElse(R.always(exempt), R.always(null), R.propOr(0, propName));

// checkIfTaxCustom :: ClientInvoice -> Boolean
const checkIfTaxCustom = R.either(
  R.compose(R.gt(R.__, 0), R.propOr(0, 'taxRawAmount')),
  R.compose(R.gt(R.__, 0), R.propOr(0, 'freightRawAmount')),
);

// formatInitialValues :: Boolean -> ClientInvoice -> FormValues
const formatInitialValues = (exemptSalesTax) =>
  R.applySpec({
    customTax: checkIfTaxCustom,
    description: R.prop('description'),
    invoiceDate: R.prop('invoiceDate'),
    taxPercent: getAmountByPropIfNotExempt(exemptSalesTax, 'taxPercent'),
    taxRawAmount: getAmountByPropIfNotExempt(exemptSalesTax, 'taxRawAmount'),
    freightRawAmount: getAmountByPropIfNotExempt(
      exemptSalesTax,
      'freightRawAmount',
    ),
    markupPercent: R.propOr(0, 'markupPercent'),
    lines: R.compose(
      R.map(
        R.pick(['rate', 'quantity', 'name', 'isNonMarkup', 'isProjectTime']),
      ),
      R.prop('lines'),
    ),
  });

// prepareInputValues :: FormValues -> FormValues
const prepareInputValues = R.compose(
  R.omit(['divisionAccountCode', 'customTax']),
  R.ifElse(
    R.prop('customTax'),
    R.dissoc('taxPercent'),
    R.omit(['freightRawAmount', 'taxRawAmount']),
  ),
  R.converge(R.mergeRight, [
    R.identity,
    R.compose(
      R.objOf('lines'),
      R.sortBy(R.propOr(false, 'isProjectTime')),
      R.propOr([], 'lines'),
    ),
  ]),
);

export function EditClientInvoiceForm({
  formId,
  invoiceId,
  projectInfo,
  clientInvoice,
}) {
  const [editClientInvoice] = useMutation(editClientInvoiceMutation);
  const { showSuccessNotification } = useNotificationState();
  const navigate = useNavigate();
  const printClientInvoice = usePrintInvoicesMutation();

  const initialValues = formatInitialValues(projectInfo.exemptSalesTax)(
    clientInvoice,
  );

  const submitHandler = async (values) => {
    const formElement = document.getElementById(formId);
    const isPrint = formElement.hasAttribute('print');
    const res = await editClientInvoice({
      variables: {
        id: invoiceId,
        input: prepareInputValues(values),
      },
    });
    formElement.removeAttribute('print');
    const clientInvoiceId = R.path(
      ['data', 'editClientInvoice', 'clientInvoice', '_id'],
      res,
    );
    showSuccessNotification('Invoice successfully edited');
    if (isPrint) {
      printClientInvoice([clientInvoiceId]);
    }
    navigate(routesNames.SEARCH_CLIENT_INVOICES);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, submitHandler);
  return (
    <Form
      formId={formId}
      onSubmit={onSubmit}
      subscription={{ values: true }}
      mutators={arrayMutators}
      initialValues={initialValues}
    >
      {({ handleSubmit, form, values }) => (
        <ClientInvoiceForm
          onSubmit={handleSubmit}
          form={form}
          values={values}
          projectInfo={projectInfo}
          formId={formId}
        />
      )}
    </Form>
  );
}

EditClientInvoiceForm.propTypes = {
  projectInfo: projectInfoPropTypes.isRequired,
  clientInvoice: clientInvoicePropTypes,
  formId: string.isRequired,
  invoiceId: string.isRequired,
};
