import React from 'react';
import { PageHeaderContainer, MainHeader } from 'poly-book-admin';
import { UserEmployeeInfoStatus } from 'poly-constants';
import {
  getAdminUsersByEmploymentStatusQuery,
  adminActiveContractorsQuery,
  adminActiveStaffQuery,
  employeeStatusColors,
  TableSearchInput,
} from 'poly-admin-ui';
import { PageTabs } from '../modules/tabs/TabsWithRouter.js';
import { StaffTab } from '../modules/tabs/staffTab/StaffTab.js';
import {
  StaffTabTitle,
  CustomTabTitle,
} from '../modules/tabs/staffTab/StaffTabTitle.js';
import { AddNewStaffBtn } from '../modules/tabs/staffTab/StaffTabToolbar.js';

const { ACTIVE, INACTIVE, LEAVE } = UserEmployeeInfoStatus;

export function StaffDirectoryPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Staff Directory</MainHeader>
        <AddNewStaffBtn />
      </PageHeaderContainer>
      <PageTabs
        toolBar={<TableSearchInput />}
        tabs={[
          [
            <StaffTabTitle
              key={`staff-table-${ACTIVE}`}
              query={getAdminUsersByEmploymentStatusQuery(ACTIVE)}
              status={ACTIVE}
            />,
            ACTIVE,
            <StaffTab
              key={ACTIVE}
              query={getAdminUsersByEmploymentStatusQuery(ACTIVE)}
            />,
          ],
          [
            <CustomTabTitle
              query={adminActiveStaffQuery}
              text="Staff"
              color={employeeStatusColors[UserEmployeeInfoStatus.ACTIVE]}
              key="staff-tab-title"
            />,
            'Staff',
            <StaffTab query={adminActiveStaffQuery} key="staff-tab" />,
          ],
          [
            <CustomTabTitle
              query={adminActiveContractorsQuery}
              text="Contractors"
              color={employeeStatusColors[UserEmployeeInfoStatus.ACTIVE]}
              key="contractors-tab-title"
            />,
            'Contractors',
            <StaffTab
              query={adminActiveContractorsQuery}
              key="contractors-tab"
            />,
          ],
          [
            <StaffTabTitle
              key={`staff-table-${INACTIVE}`}
              query={getAdminUsersByEmploymentStatusQuery(INACTIVE)}
              status={INACTIVE}
            />,
            INACTIVE,
            <StaffTab
              key={INACTIVE}
              query={getAdminUsersByEmploymentStatusQuery(INACTIVE)}
            />,
          ],
          [
            <StaffTabTitle
              key={`staff-table-${LEAVE}`}
              query={getAdminUsersByEmploymentStatusQuery(LEAVE)}
              status={LEAVE}
            />,
            LEAVE,
            <StaffTab
              key={LEAVE}
              query={getAdminUsersByEmploymentStatusQuery(LEAVE)}
            />,
          ],
        ]}
        defaultValue={ACTIVE}
      />
    </>
  );
}
