import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { isBefore, endOfDay } from 'date-fns';
import { ensureIsDate, alwaysNewDate, isNilOrEmpty } from 'poly-utils';
import { formatDateOrNothingUI } from 'poly-client-utils';
import { getThemeColor } from 'poly-book-admin';

const RedSpan = styled.span`
  color: ${getThemeColor(['accent'])};
`;

// checkIsDatePast :: Date -> Boolean
const checkIsDatePast = R.ifElse(
  isNilOrEmpty,
  R.F,
  R.converge(isBefore, [ensureIsDate, R.compose(endOfDay, alwaysNewDate)]),
);

export function TaskDueDate(date) {
  const isDueDatePast = checkIsDatePast(date);

  return isDueDatePast ? (
    <RedSpan>{formatDateOrNothingUI(date)}</RedSpan>
  ) : (
    formatDateOrNothingUI(date)
  );
}
