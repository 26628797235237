import * as R from 'ramda';
import { format } from 'date-fns';
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ensureIsDate } from 'poly-utils';
import {
  useInfiniteScrollQueryWithSubscription,
  paginationToQueryParams,
  useReactiveQuery,
} from 'poly-client-utils';
import { Loader } from 'poly-book-admin';

import { FileMenu } from './FileMenu.js';
import { TabsViews } from '../../../constants/tabs.js';
import { FileLink } from '../../../components/Links.js';
import { ENTITY_FILES_QUERY } from '../../../hooks/files/queries.js';
import { FILES_SEARCH_CHANGED } from '../../../hooks/files/subscriptions.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';
import { useSearchTabLogic } from '../../../hooks/useSearchTabLogic.js';
import { getFileLinkProps } from '../../../utils/file.js';
import { PinnedIcon } from './PinnedIcon.js';
import { getFilesSearchQuery, useToggleFilePin } from './hooks.js';
import { useSetItemsCount } from '../../../hooks/useSetItemsCount.js';

// prepareFiles :: RowProps -> SearchFilesResult -> [File]
// RowProps = { documentId: ID, collection: String, withModals: Boolean }
const prepareFiles = ({ collection, documentId, tabView, queryVariables }) =>
  R.map(
    R.compose(
      R.mergeRight({
        documentId,
        collection,
        queryVariables,
        tabView,
      }),
    ),
  );

// getFileTypeString :: File -> String
const getFileTypeString = R.compose(
  R.toUpper,
  R.last,
  R.split('-'),
  R.last,
  R.split('.'),
  R.prop('url'),
);

// formatPostingDetailsDate :: Date -> String
const formatPostingDetailsDate = (date) =>
  format(ensureIsDate(date), "MM/dd/yyyy 'at' h:mm aa");

// getPostingDetails :: File -> String
const getPostingDetails = R.compose(
  R.join(', '),
  R.reject(R.isNil),
  R.juxt([
    R.path(['createdBy', 'fullName']),
    R.compose(
      R.when(R.complement(R.isNil), formatPostingDetailsDate),
      R.prop('createdAt'),
    ),
  ]),
);

function PinnedMark(file) {
  const [toggleFilePin, loading] = useToggleFilePin(file);
  if (loading) {
    return <Loader size={10} />;
  }
  return file.pinned && !loading ? (
    <PinnedIcon disabled onClick={toggleFilePin} />
  ) : null;
}

const filesTableConfig = [
  ['Type', getFileTypeString],
  ['Description', (file) => <FileLink {...getFileLinkProps(file)} />],
  ['Posting Details', getPostingDetails],
  ['', PinnedMark],
  ['', FileMenu],
];

const useSidebarFiles = ({ collection, documentId }) => {
  const { searchTerm, ...inputProps } = useSearchTabLogic('files');
  const searchInput = useMemo(
    () =>
      getFilesSearchQuery({
        searchTerm,
        collection,
        documentId,
      }),
    [searchTerm],
  );
  const queryVariables = {
    searchInput: {
      ...searchInput,
      size: 100,
    },
    __infiniteScrollMode__: true,
  };
  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    ENTITY_FILES_QUERY,
    searchInput,
    {
      endpointName: 'searchFiles',
      inputName: 'searchInput',
    },
    FILES_SEARCH_CHANGED,
    { searchInput },
    !documentId,
  );
  const files = R.pathOr([], ['searchFiles', 'hits'], data);
  return {
    files,
    loading,
    inputProps,
    tableProps,
    queryVariables,
  };
};

const useCardFiles = ({ collection, documentId }) => {
  const searchTerm = useSelector(R.prop('searchText'));
  const pagination = useSelector(R.prop('pagination'));
  const searchInput = useMemo(
    () =>
      getFilesSearchQuery({
        searchTerm,
        collection,
        documentId,
      }),
    [searchTerm],
  );
  const queryVariables = {
    searchInput: {
      ...searchInput,
      ...paginationToQueryParams(pagination || {}),
    },
  };
  const queryOptions = {
    variables: queryVariables,
  };
  const { data, loading } = useReactiveQuery(
    ENTITY_FILES_QUERY,
    FILES_SEARCH_CHANGED,
    {
      queryOptions,
      subscriptionOptions: {
        ...queryOptions,
        skip: !documentId,
      },
    },
  );
  const files = R.pathOr([], ['searchFiles', 'hits'], data);

  useSetItemsCount(R.pathOr(0, ['searchFiles', 'total']), data);

  return {
    files,
    loading,
    queryVariables,
    inputProps: {},
    tableProps: {},
  };
};

export const useFilesTabLogic = ({
  entity,
  collection,
  tabView = TabsViews.sidebar,
}) => {
  const [showAddFile, setShowAddFile] = useState(false);

  const documentId = entity._id;

  const useFetchFiles =
    tabView === TabsViews.card ? useCardFiles : useSidebarFiles;

  const { files, loading, queryVariables, tableProps, inputProps } =
    useFetchFiles({ documentId, collection });

  const tableConfigProps = useMapConfigToTableProps(
    prepareFiles({
      queryVariables,
      documentId,
      tabView,
      collection,
    }),
    filesTableConfig,
    files,
  );

  return {
    inputProps,
    setAddFile: setShowAddFile,
    showAddFile,
    tableProps: {
      ...tableConfigProps,
      ...tableProps,
    },
    loading,
  };
};
