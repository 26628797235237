import * as R from 'ramda';
import { pathOrNothingUI } from 'poly-client-utils';

import { initialNewYorkCoords } from '../core/constants/general.js';

/**
 * getSupplierCoordinates :: Supplier -> Coordinates
 * Coordinates = { lat: Float, lng: Float }
 */
export const getSupplierCoordinates = R.compose(
  R.ifElse(
    R.anyPass([R.isNil, R.isEmpty, R.propSatisfies(R.gt(2), 'length')]),
    R.always(initialNewYorkCoords),
    R.converge(R.mergeDeepLeft, [
      R.pipe(R.nth(0), R.objOf('lng')),
      R.pipe(R.nth(1), R.objOf('lat')),
    ]),
  ),
  R.pathOr([], ['company', 'address', 'geo', 'coordinates']),
);

/**
 * prepareMapBounds :: (GoogleObj, [Supplier]) -> Bounds
 * GoogleObj = { maps: Object }
 * Bounds = { Coordinates }
 */
export const prepareMapBounds = (google, suppliers) => {
  // eslint-disable-next-line
  let bounds = new google.maps.LatLngBounds();
  const preparedCoords = R.map(getSupplierCoordinates, suppliers);
  R.forEach((coords) => bounds.extend(coords), preparedCoords);
  return bounds;
};

/**
 * getSupplierTooltipInfo :: Supplier -> TooltipInfo
 */
export const getSupplierTooltipInfo = R.compose(
  R.defaultTo({}),
  R.applySpec({
    _id: R.prop('_id'),
    name: pathOrNothingUI(['company', 'name']),
    address: pathOrNothingUI(['company', 'address', 'formatted_address']),
    phone: R.path(['company', 'phones', 0, 'phone']),
  }),
);
