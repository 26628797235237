import * as R from 'ramda';
import React, { Fragment } from 'react';
import { shape, arrayOf, string, bool, number, func } from 'prop-types';
import { useMapConfigToTablePropsWithSorting } from 'poly-admin-ui';
import {
  applyProp,
  applyPath,
  formatTotal,
  centsToDollarsWithFormat,
  forceTitleCase,
  formatDate,
} from 'poly-utils';
import { NOTHING_UI_STRING } from 'poly-constants';
import {
  WorkInProgressFooter,
  WorkInProgressQCTableS,
  WorkInProgressQCPrintTableS,
} from './WorkInProgressQCTableS.js';
import { SupplierLink } from '../../components/Links.js';
import { ClientLink } from '../../sidebars/ClientSidebar/useOpenClientSidebar.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import {
  calculateWIPQCReportTotalsByInvoices,
  getClientInvoiceTotal,
  getMarkupAmount,
} from './helpers.js';

export const wipQCRecordsPropTypes = arrayOf(
  shape({
    _id: string.isRequired,
    client: shape({
      _id: string.isRequired,
      name: string.isRequired,
    }),
    suppliers: arrayOf(
      shape({
        _id: string.isRequired,
        company: shape({
          name: string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
    project: shape({
      _id: string.isRequired,
      projectId: string.isRequired,
      endDate: string.isRequired,
      total: number.isRequired,
    }).isRequired,
  }),
);

export const suppliersTableItemConfig = [
  'Suppliers',
  ({ suppliers }) => (
    <>
      {suppliers.map((supplier, index) => (
        <Fragment key={supplier._id}>
          <SupplierLink
            _id={supplier._id}
            name={supplier.company.name}
            target="_blank"
          />
          {suppliers.length !== index + 1 && ', '}
        </Fragment>
      ))}
    </>
  ),
];

const workInProgressQCTableConfig = (isPrintPDF = false) => [
  [
    'Project #',
    ({ project }) => (
      <ProjectLink {...{ ...project, isPrintPDF }} target="_blank" />
    ),
    R.path(['project', 'projectId']),
  ],
  [
    'Client',
    ({ client }) => <ClientLink {...client} target="_blank" />,
    R.path(['client', 'name']),
  ],
  [
    ...suppliersTableItemConfig,
    R.compose(
      R.join(','),
      R.map(R.path(['company', 'name'])),
      R.prop('suppliers'),
    ),
  ],
  ['Acct Status', applyProp(forceTitleCase)('projectAccountingStatus')],
  [
    'End Date',
    applyPath(formatDate)(['project', 'endDate']),
    R.path(['project', 'endDate']),
  ],
  [
    'Supplier Inv',
    applyProp(centsToDollarsWithFormat)('supplierInvoiceAmount'),
  ],
  [
    'Markup',
    R.compose(
      R.ifElse(R.equals(0), R.always(NOTHING_UI_STRING), formatTotal),
      getMarkupAmount,
    ),
  ],
  ['Total', R.compose(formatTotal, getClientInvoiceTotal)],
  ['Manager', R.path(['manager', 'fullName']), R.path(['manager', 'fullName'])],
];

// wipQCTableSortQueries :: [TableConfig] -> [Function]
export const wipQCTableSortQueries = R.map(
  R.nth(2),
  workInProgressQCTableConfig(),
);

export const wipQCRecordsTableInitialSorting = {
  columnKey: 0,
  dir: 1,
};

function WorkInProgressTableFooter({ rows }) {
  const { supplierInvoiceTotal, markup, total } =
    calculateWIPQCReportTotalsByInvoices(rows);
  return <WorkInProgressFooter {...{ supplierInvoiceTotal, markup, total }} />;
}

WorkInProgressTableFooter.propTypes = {
  rows: wipQCRecordsPropTypes.isRequired,
};

export function WorkInProgressQCTable({
  wipQCRecords,
  isPrintPDF,
  updateSorting,
  initialSorting,
  ...restTableProps
}) {
  const { rows, headers, columns, sortQueries, sorting, onHeaderCellClick } =
    useMapConfigToTablePropsWithSorting({
      tableConfig: workInProgressQCTableConfig(isPrintPDF),
      onHeaderCellClick: updateSorting,
      items: wipQCRecords,
      initialSorting,
    });

  const TableComponent = isPrintPDF
    ? WorkInProgressQCPrintTableS
    : WorkInProgressQCTableS;

  return (
    <TableComponent
      isSortable
      headers={headers}
      rows={rows}
      columns={columns}
      sortQueries={sortQueries}
      sorting={sorting}
      TableFooter={WorkInProgressTableFooter}
      footerHeight={38}
      isPrintPDF={isPrintPDF}
      onHeaderCellClick={onHeaderCellClick}
      {...restTableProps}
    />
  );
}

WorkInProgressQCTable.propTypes = {
  wipQCRecords: wipQCRecordsPropTypes,
  isPrintPDF: bool,
  updateSorting: func,
  initialSorting: shape({
    columnKey: number,
    dir: number,
  }),
};

WorkInProgressQCTable.defaultProps = {
  initialSorting: wipQCRecordsTableInitialSorting,
};
