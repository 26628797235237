import React from 'react';
import { Input } from 'poly-book-admin';

export function PhoneNumberInput(props) {
  return <Input {...props} type="tel" format="(###) ###-####" mask="_" />;
}

export function StoreNumberInput(props) {
  return <Input {...props} type="tel" format="###" mask="_" />;
}
