import React from 'react';
import * as R from 'ramda';
import { useModalContext } from 'poly-admin-ui';
import { IconButton } from 'poly-book-admin';
import { Link } from 'poly-client-routing';

import { editAccountFormId } from './constants.js';
import { AccountForm } from './forms/AccountForm.js';
import { defaultChartOfAccountsPropTypes } from './chartOfAccountsPropTypes.js';
import {
  BoldSpan,
  SpanWithPadding,
  BoldLinkButtonS,
  LinkButtonWithPaddingS,
  SpanS,
} from './components.js';
import { formatAccountBalance } from '../GeneralLedgerPage/generalLedgerHelpers.js';

const FIELD_COMPONENTS_CONFIGS = {
  code: {
    hasChild: BoldLinkButtonS,
    isChild: LinkButtonWithPaddingS,
    default: Link,
  },
  name: {
    hasChild: BoldSpan,
    isChild: SpanWithPadding,
    default: SpanS,
  },
};

const getStyledComponent = (prop) => (account) =>
  R.compose(
    (Component) => (
      <Component href={account.href} target="_blank">
        {account[prop]}
      </Component>
    ),
    R.cond([
      [() => account.hasChild, R.prop('hasChild')],
      [() => account.isChild, R.prop('isChild')],
      [R.T, R.prop('default')],
    ]),
  )(FIELD_COMPONENTS_CONFIGS[prop]);

function EditButton({ defaultProps, ...props }) {
  const { openModalForm } = useModalContext();
  const existingAccount = defaultProps.accountsByTabAndFilter.find(
    (account) => account._id === props._id,
  );
  const document = R.mergeRight(props, existingAccount);

  const onClick = () =>
    openModalForm({
      id: editAccountFormId,
      title: 'Edit Account',
      formId: editAccountFormId,
      btnCaption: 'Save',
      content: (
        <AccountForm
          isNewAccount={false}
          document={document}
          {...defaultProps}
          {...props}
        />
      ),
    });

  return <IconButton size={15} name="edit" onClick={onClick} />;
}

EditButton.propTypes = defaultChartOfAccountsPropTypes;

export const commonTableConfig = [
  ['GL Code', getStyledComponent('code')],
  ['Name', getStyledComponent('name')],
  ['Type', R.pathOr('', ['accountType', 'name'])],
  ['Division', R.pathOr('', ['division', 'name'])],
  ['Balance', R.compose(formatAccountBalance, R.prop('accrualBalance'))],
];

export const chartOfAccountsTableConfig = (defaultProps) => [
  ...commonTableConfig,
  ['', (props) => <EditButton defaultProps={defaultProps} {...props} />],
];
