import { useHasUserAccessWithPermission } from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';
import {
  UPDATE_ASSET_PERMISSION,
  UPDATE_ASSET_MANUFACTURER_PERMISSION,
  CREATE_ASSET_MANUFACTURER_PERMISSION,
} from 'poly-security/src/permissions.js';

export const useUserHasAccessToUpdateAsset = () => {
  const hasPermissionToUpdateAsset = useHasUserAccessWithPermission(
    UPDATE_ASSET_PERMISSION,
  );

  return hasPermissionToUpdateAsset;
};

export const useUserHasAccessToApproveNewEquipment = () => {
  const hasPermissionToUpdateAsset = useUserHasAccessToUpdateAsset();

  const hasPermissionToUpdateAssetManufacture = useHasUserAccessWithPermission(
    UPDATE_ASSET_MANUFACTURER_PERMISSION,
  );

  const hasPermissionToCreateAssetManufacture = useHasUserAccessWithPermission(
    CREATE_ASSET_MANUFACTURER_PERMISSION,
  );

  return (
    hasPermissionToUpdateAsset &&
    hasPermissionToUpdateAssetManufacture &&
    hasPermissionToCreateAssetManufacture
  );
};
