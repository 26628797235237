import styled from 'styled-components';
import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useHasUserAccessWithPermissionByProps } from 'poly-client-utils';
import { FlexColumn, useNotificationState } from 'poly-admin-ui';
import { Link, useLocation } from 'poly-client-routing';
import { getThemeColor, Icon } from 'poly-book-admin';

const FinancialDirectorySectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  flex-grow: 1;
`;

const FinancialDirectorySectionTitle = styled.span`
  color: ${getThemeColor(['primaryRegular'])};
  font-size: 18px;
  line-height: 21px;
`;

const FinancialDirectorySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const LinkText = styled.span`
  color: ${getThemeColor(['secondary'])};
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
`;

const MenuLink = styled(Link)`
  color: ${getThemeColor(['darkest'])};
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
  color: ${({ isActive, ...props }) =>
    isActive && getThemeColor(['newDarkAccent2'])(props)};
  &:hover {
    color: ${getThemeColor(['newDarkAccent2'])};
  }
  position: relative;
`;

const ItemCountWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

function MenuLinkWithCheckPermissions({
  name,
  link,
  Counter,
  isActive,
  permissions,
  warningMessage,
}) {
  const { showWarningNotification } = useNotificationState();
  const hasAccess = useHasUserAccessWithPermissionByProps(
    permissions,
    name === 'Bank Registers',
  );

  const onClick = () => showWarningNotification(warningMessage);
  const linkHref = hasAccess ? link : '';
  const customProps = hasAccess ? {} : { onClick };

  return (
    <MenuLink href={linkHref} key={name} isActive={isActive} {...customProps}>
      {name}
      {Counter && (
        <ItemCountWrapper>
          <Counter />
        </ItemCountWrapper>
      )}
    </MenuLink>
  );
}

MenuLinkWithCheckPermissions.propTypes = {
  permissions: arrayOf(string),
  warningMessage: string,
  link: string.isRequired,
  name: string.isRequired,
  isActive: bool,
  Counter: func,
};

function MenuItemLink(props) {
  const location = useLocation();
  const linkProps = {
    isActive: location.pathname === props.link,
    ...props,
  };

  if (props.link && props.permissions) {
    return <MenuLinkWithCheckPermissions {...linkProps} />;
  }

  if (props.link) {
    return (
      <MenuLink href={props.link} {...linkProps}>
        {props.name}
      </MenuLink>
    );
  }
  return <LinkText>{props.name}</LinkText>;
}

const SubLinksContainer = styled(FlexColumn)`
  margin-left: 25px;
`;

MenuItemLink.propTypes = {
  permissions: arrayOf(string),
  link: string,
  name: string,
};

function MenuItem({ subLinks, ...props }) {
  if (subLinks) {
    return (
      <>
        <MenuItemLink {...props} />
        <SubLinksContainer>
          {subLinks.map((link) => (
            <MenuItemLink key={link.name} {...link} />
          ))}
        </SubLinksContainer>
      </>
    );
  }
  return <MenuItemLink {...props} />;
}

MenuItem.propTypes = {
  subLinks: arrayOf(shape({ name: string.isRequired })),
};

export function FinancialDirectorySidebarSection({ title, links }) {
  const [isOpened, setIsOpened] = useState(true);
  const onClick = () => setIsOpened(!isOpened);
  return (
    <FinancialDirectorySectionContainer>
      {title && (
        <FinancialDirectorySectionHeaderContainer onClick={onClick}>
          <FinancialDirectorySectionTitle>
            {title}
          </FinancialDirectorySectionTitle>
          <Icon size={17} name={isOpened ? 'arrow-up' : 'arrow-down'} />
        </FinancialDirectorySectionHeaderContainer>
      )}
      {isOpened && (
        <FlexColumn>
          {links.map((linkProps) => (
            <MenuItem key={linkProps.name} {...linkProps} />
          ))}
        </FlexColumn>
      )}
    </FinancialDirectorySectionContainer>
  );
}

FinancialDirectorySidebarSection.propTypes = {
  title: string.isRequired,
  links: arrayOf(shape({ name: string.isRequired, link: string })),
};
