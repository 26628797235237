import React from 'react';
import { arrayOf, string } from 'prop-types';
import { entityToOptionByLabelPath } from 'poly-client-utils';
import { AsyncSearchSelect } from 'poly-admin-ui';
import { FormField } from 'poly-book-admin';
import {
  SupplierStatuses,
  SupplierSources,
  SupplierTypes,
} from 'poly-constants';

import {
  SUPPLIER_OPTION_QUERY,
  SUPPLIERS_BY_SEARCH_FOR_OPTIONS,
} from '../../../../core/hooks/suppliers/queries.js';
import { getSuppliersQuery } from '../../../../core/searchQueries/suppliers.js';

function SuppliersSearchSelect(props) {
  const asyncSearchSelectProps = {
    valueEndpointName: 'supplier',
    placeholder: 'Search Suppliers',
    gqlValueQuery: SUPPLIER_OPTION_QUERY,
    optionsEndpointName: 'searchSuppliers',
    gqlOptionsQuery: SUPPLIERS_BY_SEARCH_FOR_OPTIONS,
    hitToOption: entityToOptionByLabelPath(['company', 'name']),
    ...props,
  };

  return <AsyncSearchSelect {...asyncSearchSelectProps} />;
}

export function PropertySuppliersSelectField({
  propertySupplierIds,
  ...props
}) {
  const formFieldProps = {
    Component: SuppliersSearchSelect,
    additionalProps: {
      searchQuery: getSuppliersQuery(SupplierSources.AAC, [
        SupplierTypes.ADMINISTRATIVE,
        SupplierTypes.SUBCONTRACTOR,
      ])(SupplierStatuses.ACTIVE, propertySupplierIds),
      size: 50,
    },
    name: 'supplierId',
    ...props,
  };

  return <FormField {...formFieldProps} />;
}

PropertySuppliersSelectField.propTypes = {
  propertySupplierIds: arrayOf(string),
};
