import React from 'react';
import * as R from 'ramda';
import {
  setDate,
  subYears,
  setMonth,
  endOfDay,
  endOfYear,
  subMonths,
  startOfDay,
  endOfMonth,
  startOfYear,
  startOfMonth,
} from 'date-fns';
import { Select } from 'poly-book-admin';
import { ensureIsDate } from 'poly-utils';

export const THIS_YEAR_VALUE = 'thisYear';
const LAST_YEAR_VALUE = 'lastYear';
const COMPASS_FISCAL_YEAR = 'compassFiscalYear';
export const CURRENT_MONTH = 'currentMonth';
const PREVIOUS_MONTH = 'previousMonth';

// subMonthsCurry :: Int -> Date -> Date
const subMonthsCurry = R.curry((months, date) => subMonths(date, months));

// subYearsCurry :: Int -> Date -> Date
const subYearsCurry = R.curry((years, date) => subYears(date, years));

// setDateCurry :: Int -> Date -> Date
const setDateCurry = R.curry((day, date) => setDate(date, day));

// setMonthCurry :: Int -> Date -> Date
const setMonthCurry = R.curry((month, date) => setMonth(date, month));

const yearOptions = [
  { value: THIS_YEAR_VALUE, label: 'This Calendar Year' },
  { value: LAST_YEAR_VALUE, label: 'Last Calendar Year' },
  { value: COMPASS_FISCAL_YEAR, label: "Compass' Fiscal Year" },
  { value: CURRENT_MONTH, label: 'Current Month' },
  { value: PREVIOUS_MONTH, label: 'Previous Month' },
];

// getThisYearDateFilter :: (Boolean, Date) -> _ -> Date
export const getThisYearDateFilter = (isForStartDate, date) =>
  R.compose(
    R.ifElse(R.always(isForStartDate), startOfYear, endOfYear),
    ensureIsDate,
    R.always(date),
  );

// getLastYearDateFilter :: (Boolean, Date) -> _ -> Date
export const getLastYearDateFilter = (isForStartDate, date) =>
  R.compose(
    R.ifElse(R.always(isForStartDate), startOfYear, endOfYear),
    subYearsCurry(1),
    ensureIsDate,
    R.always(date),
  );

// getCompassFYearDateFilter :: (Boolean, Date) -> _ -> Date
export const getCompassFYearDateFilter = (isForStartDate, date) =>
  R.compose(
    R.ifElse(
      R.always(isForStartDate),
      R.compose(
        startOfDay,
        setDateCurry(1),
        setMonthCurry(9),
        subYearsCurry(1),
      ),
      R.compose(endOfDay, setDateCurry(30), setMonthCurry(8)),
    ),
    ensureIsDate,
    R.always(date),
  );

// getCurrentMonthDateFilter :: (Boolean, Date) -> _ -> Date
export const getCurrentMonthDateFilter = (isForStartDate, date) =>
  R.compose(
    R.ifElse(R.always(isForStartDate), startOfMonth, endOfMonth),
    ensureIsDate,
    R.always(date),
  );

// getPreviousMonthDateFilter :: (Boolean, Date) -> _ -> Date
export const getPreviousMonthDateFilter = (isForStartDate, date) =>
  R.compose(
    R.ifElse(R.always(isForStartDate), startOfMonth, endOfMonth),
    subMonthsCurry(1),
    ensureIsDate,
    R.always(date),
  );

// getFiscalYearSubscribersByDate :: Date -> [Subscriber]
// Subscriber = [String, Date]
export const getFiscalYearSubscribersByDate = (date) => [
  [
    'startDate',
    R.cond([
      [R.equals(THIS_YEAR_VALUE), getThisYearDateFilter(true, date)],
      [R.equals(LAST_YEAR_VALUE), getLastYearDateFilter(true, date)],
      [R.equals(COMPASS_FISCAL_YEAR), getCompassFYearDateFilter(true, date)],
      [R.equals(CURRENT_MONTH), getCurrentMonthDateFilter(true, date)],
      [R.T, getPreviousMonthDateFilter(true, date)],
    ]),
  ],
  [
    'endDate',
    R.cond([
      [R.equals(THIS_YEAR_VALUE), getThisYearDateFilter(false, date)],
      [R.equals(LAST_YEAR_VALUE), getLastYearDateFilter(false, date)],
      [R.equals(COMPASS_FISCAL_YEAR), getCompassFYearDateFilter(false, date)],
      [R.equals(CURRENT_MONTH), getCurrentMonthDateFilter(false, date)],
      [R.T, getPreviousMonthDateFilter(false, date)],
    ]),
  ],
];

export function FiscalYearSelect(props) {
  const selectProps = {
    options: yearOptions,
    ...props,
  };

  return <Select {...selectProps} />;
}
