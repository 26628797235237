import styled from 'styled-components';
import { func, bool } from 'prop-types';
import React, { useEffect } from 'react';
import { useRouterQuery, useUpdateQueryParams } from 'poly-client-routing';
import { PageHeaderContainer, MainHeader, Button } from 'poly-book-admin';
import { ClientSelect } from 'poly-admin-ui';
import { isNilOrEmpty } from 'poly-utils';

import { BillingProfileSelect } from './BillingProfileConfigurationSidebar/components/BillingProfileSelect.js';
import { BillingProfileConfigurationBtn } from './BillingProfileConfigurationBtn.js';
import { createClearInvoicesAction } from '../BatchInvoicingTM/batch-invoicing-state.js';
import { useSetBillingProfileConfigById } from './useSetBillingProfileConfigById.js';

const RefetchButtonS = styled(Button)`
  position: absolute;
  right: 0;
`;

const WrapperS = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const ClientInvoicingCollectionHeaderWrapperS = styled(WrapperS)`
  width: auto;

  > div:not(:first-child) {
    margin-left: 15px;
    width: 200px;
    flex-shrink: 0;
  }
`;

export function ClientInvoicingCollectionHeader({
  refetch,
  dispatch,
  showRefetch,
}) {
  const { clientId, billingProfileId } = useRouterQuery([
    'clientId',
    'billingProfileId',
  ]);
  const updateQueryParams = useUpdateQueryParams();
  const setBillingProfileConfig = useSetBillingProfileConfigById();

  const clearSelectedProjects = () => dispatch(createClearInvoicesAction());

  const onChange = (name) => (value) => {
    const preparedValue = isNilOrEmpty(value) ? undefined : value;

    clearSelectedProjects();

    updateQueryParams({
      [name]: preparedValue,
      ...(name === 'clientId' ? { billingProfileId: undefined } : {}),
    });
  };

  const onBillingProfileChange = (value) => {
    onChange('billingProfileId')(value);
    setBillingProfileConfig(value);
  };

  const onClientChange = (value) => {
    onChange('clientId')(value);
    setBillingProfileConfig(null);
  };

  const onRefreshClick = () => {
    refetch();
    clearSelectedProjects();
  };

  useEffect(() => {
    if (billingProfileId) {
      setBillingProfileConfig(billingProfileId);
    }

    return () => {
      setBillingProfileConfig(null);
    };
  }, []);

  return (
    <PageHeaderContainer>
      <ClientInvoicingCollectionHeaderWrapperS>
        <MainHeader>Client Invoicing Collection</MainHeader>
        <ClientSelect
          value={clientId}
          includeAllOption={false}
          onChange={onClientChange}
        />
        {!!clientId && (
          <BillingProfileSelect
            value={billingProfileId}
            onChange={onBillingProfileChange}
          />
        )}
      </ClientInvoicingCollectionHeaderWrapperS>
      <WrapperS>
        {!!clientId && (
          <BillingProfileConfigurationBtn
            clearSelectedProjects={clearSelectedProjects}
          />
        )}
        {showRefetch && (
          <RefetchButtonS size="small" onClick={onRefreshClick}>
            Refresh
          </RefetchButtonS>
        )}
      </WrapperS>
    </PageHeaderContainer>
  );
}

ClientInvoicingCollectionHeader.propTypes = {
  refetch: func.isRequired,
  dispatch: func.isRequired,
  showRefetch: bool.isRequired,
};
