import * as R from 'ramda';
import { css } from 'styled-components';
import React, { useState } from 'react';
import { string, shape } from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { supplierTypes, SupplierWOCTypes } from 'poly-constants';
import {
  entities,
  CommonPrintLayout,
  useNotificationState,
} from 'poly-admin-ui';
import {
  HtmlParser,
  useOutSidebarContext,
  openPrintWindowWithData,
} from 'poly-client-utils';

import {
  ADD_SUPPLIER_TO_PROJECT_MUTATION,
  ASSIGN_SUPPLIERS_TO_PROJECT_MUTATION,
} from '../../core/hooks/projects/mutations.js';
import { assignSupplierFormId } from './constants.js';
import { assignSupplierFormTypePropTypes } from './propTypes.js';
import { AssignSupplierFormComp } from './AssignSupplierFormComp.js';
import { getSupplierWOCTypeByForm, pickSelectedSuppliers } from './utils.js';
import { assignSupplierFormTypes } from '../../../sidebars/ProjectSidebar/constants.js';
import { parseHtmlString } from '../../../utils/html.js';

const REQUIRED_FIELDS_WARNING =
  '"Send Supplier WOC" or "Free Quote" should be selected';

// prepareAssignSupplierMutationInput :: FormDataWithProjectId -> AssignSuppliersToProjectInput
// FormDataWithProjectId = {
//    ...FormData
//    projectId: ID
// }
export const prepareAssignSupplierMutationInput = R.compose(
  R.when(
    R.prop('noSupplierRequired'),
    R.pick(['projectId', 'noSupplierRequired']),
  ),
  R.mergeAll,
  R.juxt([
    R.pick(['projectId', 'supplierNote', 'nte', 'noSupplierRequired']),
    R.applySpec({
      additionalSupplierWOCEmails: R.compose(
        R.pluck('value'),
        R.defaultTo([]),
        R.prop('additionalSupplierWOCEmails'),
      ),
      supplierWOCType: getSupplierWOCTypeByForm,
      supplierId: R.compose(R.head, pickSelectedSuppliers, R.prop('suppliers')),
      attachments: R.compose(
        R.map(R.pick(['upload', 'fileName'])),
        R.propOr([], 'attachments'),
      ),
    }),
    // in case assigning supplier that added, `supplierId` will exist
    // so we need to override upper declaration of `supplierId`
    // Note: R.mergeAll merge items from the end
    R.pick(['supplierId']),
  ]),
);

// prepareWOCMutationInput ::  (FormDataWithProjectId, Boolean) -> SendWorkOrderConfirmationInput
const prepareWOCMutationInput = (formData, withEmails) =>
  R.compose(
    R.pick([
      'projectId',
      'supplierNote',
      'nte',
      'supplierId',
      'attachments',
      ...(withEmails ? ['additionalSupplierWOCEmails'] : []),
    ]),
    prepareAssignSupplierMutationInput,
  )(formData);

// prepareAddSupplierMutationInput :: Object -> FormData -> AddSuppliersToProjectInput
const prepareAddSupplierMutationInput = (entityObj) =>
  R.compose(
    R.mergeLeft(entityObj),
    R.objOf('supplierId'),
    R.head,
    pickSelectedSuppliers,
    R.prop('suppliers'),
  );

const ADD_SUPPLIER_TO_ASSET_MUTATION = gql`
  mutation ADD_SUPPLIER_TO_ASSET_MUTATION($input: SupplierToAssetInput!) {
    addSupplierToAsset(input: $input) {
      _id
    }
  }
`;

const ADD_SUPPLIER_TO_RECURRING_PROJECT_MUTATION = gql`
  mutation ADD_SUPPLIER_TO_RECURRING_PROJECT_MUTATION(
    $input: AddSupplierToRecurringProjectInput!
  ) {
    addSupplierToRecurringProject(input: $input) {
      _id
    }
  }
`;

const SEND_WORK_ORDER_CONFIRMATION_MUTATION = gql`
  mutation SEND_WORK_ORDER_CONFIRMATION_MUTATION(
    $input: SendWorkOrderConfirmationInput!
  ) {
    sendWorkOrderConfirmation(input: $input)
  }
`;

const GENERATE_WORK_ORDER_CONFIRMATION_QUERY = gql`
  mutation GENERATE_WORK_ORDER_CONFIRMATION_QUERY(
    $input: GenerateWorkOrderConfirmationInput!
  ) {
    generateWorkOrderConfirmation(input: $input) {
      wocHtml
    }
  }
`;

const getMutationByEntity = R.cond([
  [R.pathEq(['entity', 'name'], entities.ASSET), R.prop('addSupplierToAsset')],
  [
    R.pathEq(['entity', 'name'], entities.RECURRING_PROJECT),
    R.prop('addSupplierToRecurringProject'),
  ],
  [R.T, R.prop('addSupplierToProject')],
]);

// getSubmitHandlerByFormType :: Input -> Function
// Input = {
//    formType: String
//    onSendWOCSubmit: Function
//    onAddSupplierSubmit: Function
//    onAssignSupplierSubmit: Function
// }
const getSubmitHandlerByFormType = R.cond([
  [
    R.propEq('formType', assignSupplierFormTypes.assign),
    R.prop('onAssignSupplierSubmit'),
  ],
  [
    R.propEq('formType', assignSupplierFormTypes.sendWOC),
    R.prop('onSendWOCSubmit'),
  ],
  [
    R.propEq('formType', assignSupplierFormTypes.generateWOC),
    R.prop('onGenerateWOCSubmit'),
  ],
  [R.T, R.prop('onAddSupplierSubmit')],
]);

// isSkipSupplierWOCType :: { supplierWOCType: String } -> Boolean
const isSkipSupplierWOCType = R.propEq(
  'supplierWOCType',
  SupplierWOCTypes.skip,
);

export const printWOCCustomStyles = css`
  .asset-section {
    td {
      display: flex;
      width: 100%;
    }
    a {
      margin-left: 115px !important;
    }
  }
`;

// getWOCHtml :: { generateWorkOrderConfirmation: GenerateWorkOrderConfirmationResult } -> String
const getWOCHtml = R.path(['generateWorkOrderConfirmation', 'wocHtml']);

export function AssignSupplierForm({ formType, supplierId, entity }) {
  const { closeOutSidebar } = useOutSidebarContext();
  const { showSuccessNotification, showWarningNotification } =
    useNotificationState();
  const [noSupplierRequired, setNoSupplierRequired] = useState(false);

  const [assignSuppliersToProject, { loading: assignDisabled }] = useMutation(
    ASSIGN_SUPPLIERS_TO_PROJECT_MUTATION,
  );
  const [addSupplierToProject, { loading: addProjectDisabled }] = useMutation(
    ADD_SUPPLIER_TO_PROJECT_MUTATION,
  );
  const [sendWorkOrderConfirmation, { loading: sendWOCDisabled }] = useMutation(
    SEND_WORK_ORDER_CONFIRMATION_MUTATION,
  );
  const [generateWorkOrderConfirmation, { loading: generateWOCDisabled }] =
    useMutation(GENERATE_WORK_ORDER_CONFIRMATION_QUERY);
  const [
    addSupplierToRecurringProject,
    { loading: addRecurringProjectDisabled },
  ] = useMutation(ADD_SUPPLIER_TO_RECURRING_PROJECT_MUTATION);
  const [addSupplierToAsset, { loading: addAssetDisabled }] = useMutation(
    ADD_SUPPLIER_TO_ASSET_MUTATION,
  );

  const { _id, name } = entity;
  const entityObject = { [`${name}Id`]: _id };

  const initialValues = {
    entity,
    [SupplierWOCTypes.confirm]: true,
    [SupplierWOCTypes.request]: false,
    suppliers: {
      [supplierTypes.SUBCONTRACTOR]: {},
      [supplierTypes.ADMINISTRATIVE]: {},
    },
    attachments: [],
  };

  const onAssignSupplierSubmit = async (props) => {
    await assignSuppliersToProject({
      variables: {
        input: prepareAssignSupplierMutationInput({
          ...props,
          ...entityObject,
          ...(supplierId ? { supplierId } : {}),
          noSupplierRequired,
        }),
      },
    });
    closeOutSidebar(assignSupplierFormId);
    showSuccessNotification('Supplier was successfully assigned');
  };

  const onAddSupplierSubmit = async (props) => {
    const mutate = getMutationByEntity({
      entity,
      addSupplierToAsset,
      addSupplierToProject,
      addSupplierToRecurringProject,
    });

    await mutate({
      variables: {
        input: prepareAddSupplierMutationInput(entityObject)(props),
      },
    });
    closeOutSidebar(assignSupplierFormId);
    showSuccessNotification('Supplier was successfully added');
  };

  const onSendWOCSubmit = async (formData) => {
    const input = prepareWOCMutationInput(
      {
        ...formData,
        ...entityObject,
        ...(supplierId ? { supplierId } : {}),
        noSupplierRequired,
      },
      true,
    );

    if (isSkipSupplierWOCType(input)) {
      showWarningNotification(REQUIRED_FIELDS_WARNING);
      return;
    }

    await sendWorkOrderConfirmation({ variables: { input } });

    closeOutSidebar(assignSupplierFormId);

    showSuccessNotification('Supplier WOC was successfully sent');
  };

  const onGenerateWOCSubmit = async (formData) => {
    const input = prepareWOCMutationInput(
      {
        ...formData,
        ...entityObject,
        ...(supplierId ? { supplierId } : {}),
        noSupplierRequired,
      },
      false,
    );

    if (isSkipSupplierWOCType(input)) {
      showWarningNotification(REQUIRED_FIELDS_WARNING);
      return;
    }

    const { data } = await generateWorkOrderConfirmation({
      variables: { input },
    });

    closeOutSidebar(assignSupplierFormId);

    const wocHtml = getWOCHtml(data);

    if (R.is(String, wocHtml)) {
      const { body } = parseHtmlString(wocHtml);

      openPrintWindowWithData({
        Layout: CommonPrintLayout,
        Table: HtmlParser,
        metaData: { title: 'Supplier WOC' },
        fileName: 'work-order-confirmation',
        html: body,
        customStyles: printWOCCustomStyles,
        skipFirefoxStyles: true,
      });
    }
  };

  return (
    <AssignSupplierFormComp
      formType={formType}
      supplierId={supplierId}
      initialValues={initialValues}
      noSupplierRequired={noSupplierRequired}
      setNoSupplierRequired={setNoSupplierRequired}
      disabled={
        assignDisabled ||
        addProjectDisabled ||
        addAssetDisabled ||
        sendWOCDisabled ||
        generateWOCDisabled ||
        addRecurringProjectDisabled
      }
      onSubmit={getSubmitHandlerByFormType({
        formType,
        onSendWOCSubmit,
        onAddSupplierSubmit,
        onGenerateWOCSubmit,
        onAssignSupplierSubmit,
      })}
    />
  );
}

AssignSupplierForm.propTypes = {
  supplierId: string,
  formType: assignSupplierFormTypePropTypes.isRequired,
  entity: shape({ _id: string.isRequired, name: string.isRequired }).isRequired,
};
