import React from 'react';
import { useOutSidebarContext } from 'poly-client-utils';
import { SidebarWidth } from 'poly-admin-ui';

import { projectSidebarTabsId } from '../constants.js';
import { ProjectSidebarTabs } from './ProjectSidebarTabs.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';

export const useOpenProjectTabsSidebar = (props) => {
  const { openOutSidebar } = useOutSidebarContext();

  return () =>
    openOutSidebar({
      isSteady: true,
      width: SidebarWidth,
      id: projectSidebarTabsId,
      Layout: SecondSidebarLayout,
      content: <ProjectSidebarTabs {...props} />,
    });
};
