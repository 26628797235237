import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { arrayOf, func, bool, shape, string } from 'prop-types';
import {
  useMapConfigToTableProps,
  useModalContext,
  useSelectableTableRowsProps,
} from 'poly-admin-ui';
import { IconButton, Table } from 'poly-book-admin';
import { commonSortQuery } from 'poly-client-utils';
import { NOTHING_UI_STRING } from 'poly-constants';
import { centsToDollarsWithFormat } from 'poly-utils/src/converters.js';

import { DeleteAssetModelInUse } from './DeleteAssetModelInUse.js';
import { useDeleteAssetManufactureModel } from './useDeleteAssetManufactureModel.js';
import {
  AssetManufacturerModelForm,
  editAssetManufacturerModelFormId,
} from './AssetManufacturerModelForm.js';

const AssetManufacturerModelsTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(5),
  td:nth-child(5),
  th:nth-child(6),
  td:nth-child(6) {
    width: 40px;
  }
`;

function EditAssetManufacturerModel(props) {
  const { openModalForm } = useModalContext();
  const handleClick = () =>
    openModalForm({
      id: editAssetManufacturerModelFormId,
      title: 'Edit Asset Model',
      formId: editAssetManufacturerModelFormId,
      btnCaption: 'Save',
      content: <AssetManufacturerModelForm {...props} />,
    });

  return <IconButton size={15} name="edit" onClick={handleClick} />;
}

function DeleteAssetManufacturerModel({ _id, isInUseByAsset }) {
  const handleDeleteAssetModel = useDeleteAssetManufactureModel(_id);

  if (isInUseByAsset) {
    return <DeleteAssetModelInUse modelId={_id} />;
  }

  return (
    <IconButton
      size={15}
      name="delete"
      hoverColor="red"
      onClick={handleDeleteAssetModel}
    />
  );
}

DeleteAssetManufacturerModel.propTypes = {
  _id: string.isRequired,
  isInUseByAsset: bool,
};

export const assetModelsTableConfig = [
  ['Name', R.prop('name'), commonSortQuery(['name'])],
  [
    'Life Expectancy',
    R.propOr(NOTHING_UI_STRING, 'lifeExpectancy'),
    commonSortQuery(['lifeExpectancy']),
  ],
  [
    'Asset Replacement Cost',
    R.compose(centsToDollarsWithFormat, R.prop('replacementCost')),
  ],
  ['', EditAssetManufacturerModel],
  ['', DeleteAssetManufacturerModel],
];

export function AssetManufacturerModelsTable({
  assetModels,
  selectedModels,
  setSelectedModels,
  ...props
}) {
  const pagination = useSelector(R.prop('pagination'));

  const tableProps = useMapConfigToTableProps(
    R.identity,
    assetModelsTableConfig,
    assetModels,
  );

  const { toggleRow } = useSelectableTableRowsProps(
    selectedModels,
    setSelectedModels,
    assetModels,
  );

  return (
    <AssetManufacturerModelsTableS
      {...tableProps}
      pagination={pagination}
      showScrollBar
      isPartiallySelectable
      selectedRows={selectedModels}
      isRowSelectable={R.T}
      toggleRow={toggleRow}
      {...props}
    />
  );
}

AssetManufacturerModelsTable.propTypes = {
  selectedModels: arrayOf(string),
  setSelectedModels: func.isRequired,
  assetModels: arrayOf(
    shape({ _id: string.isRequired, name: string.isRequired }),
  ),
};
