import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';
import { UserStatuses } from 'poly-constants';

import { CREATE_CONTACT_USER_MUTATION } from '../../../../hocs/users/mutations.js';
import {
  saveContractActionNames,
  getSaveContactAction,
  pickContactLinks,
  getContact,
} from './utils.js';

const UPDATE_USER_CONTACT_MUTATION = gql`
  mutation UPDATE_USER_CONTACT_MUTATION($update: UpdateContactUserInput!) {
    updateContactUser(update: $update) {
      _id
    }
  }
`;

export const useSaveContacts = () => {
  const [updateContactMutation] = useMutation(UPDATE_USER_CONTACT_MUTATION);
  const [createContactMutation] = useMutation(CREATE_CONTACT_USER_MUTATION);
  const createContact = (contact) =>
    createContactMutation({
      variables: {
        input: {
          firstName: contact.fullName,
          profile: { workPhoneNumber: contact.phone },
          emails: [contact.email],
          links: pickContactLinks(contact),
          status: UserStatuses.ACTIVE,
        },
      },
    }).then(
      R.compose(
        R.objOf('contactId'),
        R.path(['data', 'createContactUser', 'user', '_id']),
      ),
    );
  const addContactToProperty = async ({
    contactId,
    propertyId,
    contactLinks = [],
  }) => {
    if (propertyId) {
      await updateContactMutation({
        variables: {
          update: {
            userId: contactId,
            links: [...contactLinks, { propertyId }],
          },
        },
      });
      return { contactId };
    }
    return { contactId };
  };

  return (formObject, propertyId, clientId, fieldNames) => {
    const saveContactPromise = {
      [saveContractActionNames.ADD_TO_PROPERTY]: addContactToProperty,
      [saveContractActionNames.SAVE]: createContact,
      [saveContractActionNames.RETAIN_EXISTING_ID]: async ({ _id }) => ({
        contactId: _id,
      }),
    };

    return Promise.all(
      R.map((fieldName) => {
        const contact = getContact(R.path([fieldName], formObject));
        const payload = getSaveContactAction(propertyId, clientId)(contact);

        return saveContactPromise[payload.actionName](payload.data, fieldName);
      }, fieldNames),
    );
  };
};
