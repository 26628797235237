import * as R from 'ramda';
import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { MultiSelectDropDown } from 'poly-admin-ui/src/components/MultiSelectDropDown.js';
import { MAX_ITEMS } from 'poly-admin-ui/src/constants/general.js';
import { useRouterQuery } from 'poly-client-routing';
import { ASC_SORT_ORDER } from 'poly-constants';
import {
  entityToOptionByLabelPath,
  useReactiveQuery,
  keywordSortQuery,
} from 'poly-client-utils';

import { multiSelectorValueProperTypes } from './common.js';
import { ALL } from '../../../../modules/core/constants/general.js';
import { BillingProfileConfigMultiSelect } from './BillingProfileConfigMultiSelect.js';
import {
  onMultipleSelectValueChange,
  prepareMultipleSelectValue,
} from '../utils.js';

export const SEARCH_PROPERTIES_QUERY = gql`
  query SEARCH_PROPERTIES_QUERY($input: CollectionSearchParams!) {
    searchProperties(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

const SEARCH_PROPERTIES_SUBSCRIPTION = gql`
  subscription SEARCH_PROPERTIES_SUBSCRIPTION($input: CollectionSearchParams!) {
    searchPropertyChanged(input: $input) {
      id
      type
    }
  }
`;

// formatPropertyOptions :: Boolean -> SearchPropertiesQueryResult -> [Option]
const formatPropertyOptions = (withAllOption) =>
  R.compose(
    R.when(
      R.both(R.always(withAllOption), R.complement(R.isEmpty)),
      R.prepend({ value: ALL, label: 'All' }),
    ),
    R.map(entityToOptionByLabelPath(['name'])),
    R.pathOr([], ['searchProperties', 'hits']),
  );

export function MultiplePropertySelect({
  skip,
  value,
  onChange,
  formData,
  customQuery,
  withAllOption = false,
  withBaseSelect = false,
  ...props
}) {
  const { clientId: routeClientId } = useRouterQuery(['clientId']);

  const clientId = routeClientId || formData?.clientId;

  const queryOptions = {
    variables: {
      input: {
        size: MAX_ITEMS,
        query: customQuery || { term: { clientId } },
        sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      },
    },
    skip: skip || !clientId,
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_PROPERTIES_QUERY,
    SEARCH_PROPERTIES_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const options = useMemo(
    () => formatPropertyOptions(withAllOption)(data),
    [data],
  );

  const preparedValue = useMemo(
    () => prepareMultipleSelectValue(value, options),
    [value, options],
  );

  const selectProps = {
    ...props,
    loading,
    options,
    value: preparedValue,
    placeholder: 'Start typing properties',
    handleChange: onMultipleSelectValueChange(onChange),
  };

  const Component = withBaseSelect
    ? MultiSelectDropDown
    : BillingProfileConfigMultiSelect;

  return <Component {...selectProps} />;
}

MultiplePropertySelect.propTypes = {
  skip: bool,
  withAllOption: bool,
  withBaseSelect: bool,
  customQuery: shape({}),
  onChange: func.isRequired,
  value: multiSelectorValueProperTypes,
  formData: shape({ clientId: string }),
};
