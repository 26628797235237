import { StaffReportMetricType } from 'poly-constants';

export const staffReportMetricTitles = [
  [StaffReportMetricType.ACTIVE_RECURRING_PROJECTS, 'Master Projects'],
  [StaffReportMetricType.ACTIVE_CHILDREN_PROJECTS, 'Children Projects'],
  [StaffReportMetricType.ACTIVE_PROJECTS, 'Regular Projects'],
  [StaffReportMetricType.COMPLETED_PROJECTS, 'Completed Projects'],
  [StaffReportMetricType.CREATED_PROJECTS, 'Created Projects'],
  [StaffReportMetricType.COMPLETED_PROJECT_TASKS, 'Completed Tasks'],
  [StaffReportMetricType.POSTED_UPDATES, 'Posted Updates'],
  [StaffReportMetricType.CREATED_SUPPLIERS, 'Created Suppliers'],
];
