import { bool, func, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'poly-book-admin';

function BasicConfirmButtonComp({ onSubmit, loading, caption }) {
  return (
    <Button size="small" disabled={loading} loader={loading} onClick={onSubmit}>
      {caption}
    </Button>
  );
}

BasicConfirmButtonComp.propTypes = {
  onSubmit: func.isRequired,
  loading: bool,
  caption: string.isRequired,
};

BasicConfirmButtonComp.defaultProps = {
  loading: false,
};

export function BasicConfirmButton({ id, ...restProps }) {
  const processes = useSelector((state) => state.processes);

  return <BasicConfirmButtonComp {...restProps} loading={processes[id]} />;
}

BasicConfirmButton.propTypes = {
  id: string,
};
