import React from 'react';
import * as R from 'ramda';
import { shape, string, bool } from 'prop-types';
import { useRouterQuery } from 'poly-client-routing';
import { removePropDeep } from 'poly-utils';

import { UserSettingsForm } from './components/UserSettingsForm.js';
import { UserSettingsList } from './components/UserSettingsList.js';

// getUserNotificationSettingsByQuery :: {user: User} -> UserSettings
const getUserNotificationSettingsByQuery = R.compose(
  // apply default configuration that is assumed by backend
  // to show relevant info if `settings` are missing
  R.over(
    R.lensPath(['notifications', 'updateCommentEmail']),
    R.defaultTo(true),
  ),
  removePropDeep('__typename'),
  R.propOr({}, 'settings'),
);

// getUser2FASettingsByQuery :: User -> TwoFactorAuthSettings
const getUser2FASettingsByQuery = R.compose(
  R.over(R.lensPath(['isVerified']), R.defaultTo(false)),
  removePropDeep('__typename'),
  R.propOr({}, 'twoFactorAuth'),
);

// getIsTwoFactorAuthRequired :: User -> Boolean
const getIsTwoFactorAuthRequired = R.compose(
  R.any(R.pathOr(false, ['userGroup', 'isTwoFactorAuthRequired'])),
  R.propOr([], 'userGroups'),
);

// getUserLinksSettings :: User -> UserLinksSettings
const getUserLinksSettings = R.path(['settings', 'links']);

export function UserSettingsContent({ user }) {
  const { isEdit } = useRouterQuery(['isEdit']);

  const notificationSettings = getUserNotificationSettingsByQuery(user);
  const twoFASettings = getUser2FASettingsByQuery(user);
  const isTwoFactorAuthRequired = getIsTwoFactorAuthRequired(user);
  const linksSettings = getUserLinksSettings(user);

  const userId = R.propOr(null, '_id', user);

  return isEdit ? (
    <UserSettingsForm {...{ notificationSettings }} />
  ) : (
    <UserSettingsList
      {...{
        notificationSettings,
        twoFASettings,
        userId,
        isTwoFactorAuthRequired,
        linksSettings,
      }}
    />
  );
}

UserSettingsContent.propTypes = {
  user: shape({
    _id: string.isRequired,
    settings: shape({
      notifications: shape({ updateCommentEmail: bool }),
    }),
    twoFactorAuth: shape({
      isVerified: bool,
    }),
  }),
};
