import * as R from 'ramda';
import React from 'react';
import { ProjectTypeToNameMap } from 'poly-constants';
import { arrayOf, shape, string } from 'prop-types';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { format } from 'date-fns';

import {
  BatchInvoiceReportsTableS,
  FileLinkPDF,
  DerailsLinkAR,
} from './components.js';

const batchInvoiceReportsTableConfig = [
  [
    'Batch Date',
    ({ createdAt, invoicesCount }) =>
      `${format(new Date(createdAt), 'MM/dd/yyyy')} (${invoicesCount})`,
  ],
  [
    'Cost Type',
    R.compose(
      R.join(', '),
      R.map((prop) => R.propOr('', prop, ProjectTypeToNameMap)),
      R.propOr([], 'projectTypes'),
    ),
  ],
  ['Invoice PDF File', R.compose(FileLinkPDF, R.propOr({}, 'batchPDFFile'))],
  [
    'Misc Attachments',
    R.compose(R.map(FileLinkPDF), R.propOr([], 'attachments')),
  ],
  ['A/R', DerailsLinkAR],
];

export function BatchInvoiceReportsTable({ batches }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    batchInvoiceReportsTableConfig,
    batches,
  );

  return <BatchInvoiceReportsTableS {...tableProps} />;
}

BatchInvoiceReportsTable.propTypes = {
  batches: arrayOf(
    shape({
      batchDate: string,
      type: string,
      pdfFileName: string,
      pdfFileUrl: string,
    }),
  ),
};
