import React from 'react';
import * as R from 'ramda';
import { bool, object } from 'prop-types';
import styled from 'styled-components';
import { Table, moneyColumnStyles } from 'poly-book-admin';
import { commonSortQuery } from 'poly-client-utils';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { assocBy } from 'poly-utils/src/ramda.js';

import {
  invoiceDateColumn,
  invoicePaidByColumn,
  invoiceStatusColumn,
  invoiceAmountColumn,
  invoiceProjectColumn,
  invoiceDatePaidColumn,
  invoiceSupplierColumn,
  invoiceStatusColumnPDF,
} from '../../modules/tables/columns/invoices.js';
import { InvoicePopoverButton } from './InvoicePopoverButton.js';
import { searchSupplierInvoicesDataPropType } from './propTypes.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { InvoiceLinkWithRejectionReason } from '../../modules/tables/supplierInvoicesTable/SupplierInvoicesTable.js';

export const searchSupplierInvoicesTableConfig = (isPrintPDF = false) => [
  [
    'Invoice #',

    R.ifElse(
      R.always(isPrintPDF),
      R.prop('invoiceNumber'),
      InvoiceLinkWithRejectionReason,
    ),
    commonSortQuery(['invoiceNumber']),
  ],
  invoiceProjectColumn('_blank', ProjectLink, isPrintPDF),
  invoiceSupplierColumn,
  invoiceDateColumn,
  invoiceAmountColumn,
  invoicePaidByColumn,
  invoiceDatePaidColumn,
  ...(isPrintPDF ? [invoiceStatusColumnPDF] : [invoiceStatusColumn]),
  ['', InvoicePopoverButton],
];

const TableS = styled(Table)`
  td:last-child,
  th:last-child {
    width: 35px;
  }

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(5),
  td:nth-child(5),
  th:nth-child(6),
  td:nth-child(6) {
    width: 150px;
  }
  th:nth-child(4),
  td:nth-child(4),
  th:nth-child(7),
  td:nth-child(7),
  th:nth-child(8),
  td:nth-child(8) {
    width: 140px;
  }

  ${moneyColumnStyles(4)};
`;

// prepareSupplierInvoices :: QueryData -> [SupplierInvoice]
const prepareSupplierInvoices = R.compose(
  R.map(assocBy('invoiceId', R.prop('_id'))),
  R.pathOr([], ['searchInvoices', 'hits']),
);

export function SearchSupplierInvoicesTable({
  data,
  isPrintPDF,
  tableSortingProps,
  ...restTableProps
}) {
  const tableProps = useMapConfigToTableProps(
    prepareSupplierInvoices,
    isPrintPDF
      ? R.init(searchSupplierInvoicesTableConfig(true))
      : searchSupplierInvoicesTableConfig(),
    data,
  );

  const allTableProps = {
    ...tableProps,
    ...tableSortingProps,
    ...restTableProps,
    isPrintPDF,
  };

  return <TableS {...allTableProps} />;
}

SearchSupplierInvoicesTable.propTypes = {
  data: searchSupplierInvoicesDataPropType,
  // eslint-disable-next-line react/forbid-prop-types
  tableSortingProps: object,
  isPrintPDF: bool,
};
