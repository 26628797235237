import * as R from 'ramda';
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { bool, string, func } from 'prop-types';
import { ProjectType } from 'poly-constants';
import { entities } from 'poly-admin-ui';
import { Select } from 'poly-book-admin';
import {
  entityToOptionByLabelPath,
  getAppendDefaultEntity,
  useQueryWithSearch,
} from 'poly-client-utils';

import { INVOICES_BY_SEARCH_SUB } from '../core/hooks/invoices/subscriptions.js';

export const INVOICES_BY_SEARCH_SELECT = gql`
  query INVOICES_BY_SEARCH_SELECT($searchInput: CollectionSearchParams!) {
    searchInvoices(input: $searchInput) {
      hits {
        _id
        invoiceNumber
      }
      total
    }
  }
`;

const INVOICE_DEFAULT_OPTION_QUERY = gql`
  query INVOICE_DEFAULT_OPTION_QUERY($id: ID!) {
    invoice(id: $id) {
      _id
      invoiceNumber
    }
  }
`;

// getOptionsFromInvoices :: SearchInvoicesQueryResult -> [Option]
const getOptionsFromInvoices = R.compose(
  R.map(entityToOptionByLabelPath(['invoiceNumber'])),
  R.reject(R.isNil),
  R.flatten,
  R.juxt([R.prop('invoice'), R.pathOr([], ['searchInvoices', 'hits'])]),
);

export function InvoicesSelect({
  isClearable = true,
  includeAllOption = true,
  name = 'InvoiceSelect',
  placeholder = 'Start typing invoices',
  filterOption = () => true,
  ...restProps
}) {
  const { isReportOnly, value, onChange } = restProps;

  const query = {
    bool: isReportOnly
      ? {
          must: [{ exists: { field: 'invoiceNumber' } }],
        }
      : {
          must_not: [{ match: { 'project.type': ProjectType.REPORT_ONLY } }],
          must: [{ exists: { field: 'invoiceNumber' } }],
        },
  };

  const { result, error, onSearchChange } = useQueryWithSearch({
    gqlSearchQuery: INVOICES_BY_SEARCH_SELECT,
    gqlSearchChangedQuery: INVOICES_BY_SEARCH_SUB,
    query,
    entity: entities.INVOICE,
    propsOfComponent: {
      name,
      placeholder,
      isClearable,
      isReportOnly,
      filterOption,
      includeAllOption,
      ...restProps,
    },
  });

  const { data } = useQuery(INVOICE_DEFAULT_OPTION_QUERY, {
    variables: { id: value },
    skip: !value,
  });

  const updatedEntityPath = getAppendDefaultEntity({
    entityPath: ['invoice'],
    allEntitiesPath: ['searchInvoices'],
    props: {
      invoice: data?.invoice,
      searchInvoices: result?.searchInvoices,
    },
  });

  const selectProps = {
    ...data,
    ...result,
    ...restProps,
    error,
    value,
    onChange,
    onInputChange: onSearchChange,
    options: getOptionsFromInvoices({ ...result, ...updatedEntityPath }),
  };

  return <Select {...selectProps} />;
}

InvoicesSelect.displayName = 'InvoicesSelect';

InvoicesSelect.propTypes = {
  name: string,
  value: string,
  isClearable: bool,
  isReportOnly: bool,
  filterOption: func,
  placeholder: string,
  includeAllOption: bool,
  withoutReportOnly: bool,
  onChange: func.isRequired,
};
