import * as R from 'ramda';
import { applySpecWithFields } from 'poly-utils';

// replacePhoneNumber :: String -> String
const replacePhoneNumber = R.replace(/\D*/g, '');

// getCountryIsoByAddress :: Address -> String
// Address = Object
export const getCountryIsoByAddress = R.compose(
  R.defaultTo('US'),
  R.path(['address', 'address_parts', 'country']),
);

// getBackgroundPositionByIso :: (String, FlagConfig) -> String
// FlagConfig = Object
export const getBackgroundPositionByIso = R.curry((iso, backgroundOptions) =>
  R.pathOr('none', [R.toLower(iso), 'position'])(backgroundOptions),
);

// formattingInternationalPhone :: String -> String
export const formattingInternationalPhone = R.compose(
  R.concat('+'),
  replacePhoneNumber,
);

/**
 * preparePhoneNumberBeforeSubmit :: FormData -> ModifiedFormData
 * FormData = Object
 * ModifiedFormData = Object
 */
export const preparePhoneNumberBeforeSubmit = applySpecWithFields({
  phone: R.compose(
    R.unless(R.isEmpty, formattingInternationalPhone),
    R.propOr('', 'phone'),
  ),
});
