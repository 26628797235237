import React from 'react';
import { formatSelectOptionsWithTitleCased } from 'poly-client-utils';
import { Select } from 'poly-book-admin';

import { supplierStatuses } from '../../../../../constants/suppliers.js';

export function SupplierStatusSelect(props) {
  const options = formatSelectOptionsWithTitleCased(supplierStatuses);

  return <Select {...props} name="SupplierStatusSelect" options={options} />;
}
