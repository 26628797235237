import React from 'react';
import { bool } from 'prop-types';
import { LinkButton } from 'poly-book-admin';
import { CommonPrintLayout } from 'poly-admin-ui';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import { openPrintWindowWithData } from 'poly-client-utils';

import { SearchSupplierInvoicesTable } from './SearchSupplierInvoicesTable.js';
import { searchSupplierInvoicesDataPropType } from './propTypes.js';

export function SupplierInvoicesExportPDFBtn({ data, disabled }) {
  const onClick = () => {
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: SearchSupplierInvoicesTable,
      metaData: {
        title: 'Search Supplier Invoices',
      },
      fileName: 'search_supplier_invoices',
      data,
    });
  };

  return (
    <LinkButton onClick={onClick} disabled={disabled}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

SupplierInvoicesExportPDFBtn.propTypes = {
  data: searchSupplierInvoicesDataPropType,
  disabled: bool.isRequired,
};
