import styled from 'styled-components';
import { Table, TextButton } from 'poly-book-admin';

export const DivisionTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 50%;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 20%;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 10%;
  }
`;

export const DivisionsExportTextButton = styled(TextButton)`
  font-size: 14px;
`;
