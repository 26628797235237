import * as R from 'ramda';
import { useMemo } from 'react';
import { gql } from '@apollo/client';
import {
  MAX_ITEMS,
  useMapConfigToTableProps,
  useTableSorting,
} from 'poly-admin-ui';
import { NOTHING_UI_STRING } from 'poly-constants';
import {
  useInfiniteScrollQueryWithSubscription,
  highlightMatchesInObjectCurried,
} from 'poly-client-utils';
import { assocBy } from 'poly-utils/src/ramda.js';
import { useReactiveQuery } from 'poly-client-utils/src/hooks/useReactiveQuery.js';

import {
  addressColumn,
  propertyColumn,
} from '../../../modules/tables/columns/clients.js';
import { clientPropertyFields } from '../../../modules/core/hooks/properties/fragments.js';
import { PROPERTIES_BY_SEARCH_SUB } from '../../../modules/core/hooks/properties/subscriptions.js';

export const CLIENT_PROPERTIES_BY_SEARCH = gql`
  query PROPERTIES_BY_SEARCH(
    $searchInput: CollectionSearchParams!
    $subPropertySearchInput: CollectionSearchParams
  ) {
    searchProperties(input: $searchInput) {
      hits {
        ...clientPropertyFields
        searchSubProperties(input: $subPropertySearchInput) {
          hits {
            ...clientPropertyFields
          }
          total
        }
      }
      total
    }
  }

  ${clientPropertyFields}
`;

const clientPropertiesTableConfig = [
  propertyColumn,
  addressColumn,
  ['Division', R.propOr(NOTHING_UI_STRING, 'division')],
];

export const searchTextPaths = [
  ['name'],
  ['address', 'formatted_address'],
  ['division'],
];

// prepareSubProperties :: String ->  SearchPropertiesResult -> [Property]
const prepareSubProperties = (searchTerm) =>
  R.compose(
    R.map(highlightMatchesInObjectCurried(searchTextPaths, searchTerm)),
    R.path(['searchSubProperties', 'hits']),
  );

// preparePropertiesForTable :: String -> SearchPropertiesResult -> [Property]
// eslint-disable-next-line import/no-unused-modules
export const preparePropertiesForTable = (searchTerm, status) =>
  R.compose(
    R.map(highlightMatchesInObjectCurried(searchTextPaths, searchTerm)),
    R.unnest,
    R.map(
      R.when(
        R.prop('isMaster'),
        R.ifElse(
          R.propEq('status', status),
          R.compose(
            R.dissoc('searchSubProperties'),
            assocBy('childrenRows', prepareSubProperties(searchTerm)),
          ),
          R.path(['searchSubProperties', 'hits']),
        ),
      ),
    ),
    R.pathOr([], ['searchProperties', 'hits']),
  );

const getSearchPropertiesQuery = ({ status, clientId, parentProperty }) => ({
  bool: {
    filter: {
      bool: {
        must: [
          {
            bool: {
              should: [{ term: { status } }, { term: { isMaster: true } }],
            },
          },
          { term: { clientId } },
          ...(parentProperty?._id
            ? [{ term: { masterPropertyId: parentProperty._id } }]
            : []),
        ],
        ...(!parentProperty?._id
          ? { must_not: { exists: { field: 'masterPropertyId' } } }
          : {}),
      },
    },
  },
});

export const useClientPropertiesQuery = (
  clientId,
  status,
  searchTerm,
  parentProperty,
) => {
  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: clientPropertiesTableConfig,
    column: 1,
  });

  const searchInput = useMemo(
    () => ({
      sort,
      searchTerm,
      query: getSearchPropertiesQuery({ status, clientId, parentProperty }),
    }),
    [sort, status, clientId, searchTerm, parentProperty?._id],
  );

  const subPropertySearchInput = useMemo(() => ({
    query: { term: { status } },
    sort,
    searchTerm,
    size: MAX_ITEMS,
  }));

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    CLIENT_PROPERTIES_BY_SEARCH,
    searchInput,
    {
      endpointName: 'searchProperties',
      inputName: 'searchInput',
      variables: { subPropertySearchInput },
    },
    PROPERTIES_BY_SEARCH_SUB,
    { searchInput },
  );

  const dataTableProps = useMapConfigToTableProps(
    preparePropertiesForTable(searchTerm, status),
    clientPropertiesTableConfig,
    data,
  );

  const count = R.pathOr(0, ['searchProperties', 'total'], data);

  return {
    sort,
    count,
    loading,
    tableProps: {
      ...tableProps,
      ...dataTableProps,
      ...tableSortingProps,
    },
  };
};
const CLIENT_PROPERTIES_COUNT = gql`
  query PROPERTIES_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchProperties(input: $searchInput) {
      total
    }
  }
`;

export const useClientPropertiesCountQuery = ({
  clientId,
  status,
  searchTerm,
  parentProperty,
}) => {
  const queryOptions = {
    variables: {
      searchInput: {
        searchTerm,
        query: {
          bool: {
            must: [
              { term: { status } },
              { term: { clientId } },
              ...(parentProperty?._id
                ? [{ term: { masterPropertyId: parentProperty._id } }]
                : []),
            ],
          },
        },
      },
    },
    fetchPolicy: 'network-only',
  };

  const { data } = useReactiveQuery(
    CLIENT_PROPERTIES_COUNT,
    PROPERTIES_BY_SEARCH_SUB,
    {
      queryOptions,
      subscriptionOptions: queryOptions,
    },
  );

  return R.pathOr(0, ['searchProperties', 'total'], data);
};
