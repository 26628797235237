import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { Loader } from 'poly-book-admin';
import { SummaryBlock } from './TimeSheetReportSummaryBlock.js';
import { TimeSheetReportTableComp } from './TimeSheetReportTableComp.js';
import { setTimeSheetReport } from '../../pagesHeaders/TimeSheetReportPageHeader/TimeSheetReportReducer.js';

import { useMapTimeSheetReportForTable, tableConfig } from './helpers.js';
import { useTimeSheetReport } from '../../core/hooks/timesheets/index.js';

export function TimeSheetReportTable() {
  const timeSheetReport = useSelector((state) => state.timeSheetReport);
  const { filteredConfig } = timeSheetReport;

  const input = R.when(
    R.propSatisfies(R.isEmpty, 'ownerId'),
    R.dissoc('ownerId'),
  )(filteredConfig);

  const { data, loading } = useTimeSheetReport({ input });

  const timeSheetsTableProps = useMapTimeSheetReportForTable({
    timesheetsReport: data,
  });

  if (loading) return <Loader />;

  const headers = R.map(R.nth(0), tableConfig);
  const columns = R.map(R.nth(1), tableConfig);

  return (
    <TimeSheetReportTableComp
      {...timeSheetsTableProps}
      SummaryBlock={SummaryBlock}
      headers={headers}
      columns={columns}
      setTimeSheetReport={setTimeSheetReport}
      enableInnerRowHover
    />
  );
}

TimeSheetReportTable.displayName = 'TimeSheetReportTable';
