import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo, useState } from 'react';
import { object, func, string } from 'prop-types';
import { usePersistentFormValue } from 'poly-client-utils';

import { Button } from '../Button/index.js';

const FormValuesByDBDataButtonS = styled(Button)`
  width: 130px;
  margin-bottom: 10px;
`;

// getFormDBDataForPersistentValues :: Object -> Object -> Object
const getFormDBDataForPersistentValues = (initialValues) =>
  R.compose(
    R.unless(
      R.isEmpty,
      R.compose(
        R.pick(R.__, initialValues),
        R.map(R.head),
        R.converge(R.difference, [
          R.toPairs,
          R.compose(R.toPairs, R.always(initialValues)),
        ]),
      ),
    ),
    R.defaultTo({}),
  );

export function FormValuesByDBDataButton({
  batch,
  change,
  persistKey,
  formValuesByDB,
}) {
  const { getRetainedValue } = usePersistentFormValue(persistKey);

  const persistentFormValues = useMemo(() =>
    persistKey ? getRetainedValue() : {},
  );

  const preparedFormValuesByDB = useMemo(
    () =>
      getFormDBDataForPersistentValues(formValuesByDB)(persistentFormValues),
    [persistentFormValues, formValuesByDB],
  );

  const defaultShowButton = useMemo(
    () => R.complement(R.isEmpty)(preparedFormValuesByDB),
    [preparedFormValuesByDB],
  );

  const [showButton, setShowButton] = useState(defaultShowButton);

  if (!showButton) {
    return null;
  }

  const onClick = (e) => {
    e.preventDefault();

    const formValuesByBDArr = R.toPairs(preparedFormValuesByDB);

    batch(() => {
      formValuesByBDArr.forEach(([fieldName, value]) =>
        change(fieldName, value),
      );
    });

    setShowButton(false);
  };

  return (
    <FormValuesByDBDataButtonS size="tiny" onClick={onClick}>
      Use DB data version
    </FormValuesByDBDataButtonS>
  );
}

FormValuesByDBDataButton.propTypes = {
  persistKey: string,
  // eslint-disable-next-line react/forbid-prop-types
  formValuesByDB: object,
  batch: func.isRequired,
  change: func.isRequired,
};
