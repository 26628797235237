import * as R from 'ramda';
import React from 'react';
import { gql } from '@apollo/client';
import { useTableInfiniteScrollQuery } from 'poly-client-utils';
import { Loader, PageHeaderContainer, MainHeader } from 'poly-book-admin';

import { DailySupplierInvoicesBatchTable } from './DailySupplierInvoicesBatchTable.js';

// eslint-disable-next-line import/no-unused-modules
export const DAILY_SUPPLIER_INVOICE_BATCH_PAGE_SIZE = 1000;

// eslint-disable-next-line import/no-unused-modules
export const printedSupplierBatchesQuery = gql`
  query printedSupplierBatchesQuery {
    printedSupplierBatches {
      _id
      isPrinted
      date
      printedAt
      printedBy {
        profile {
          fullName
        }
      }
      supplierBatchFile {
        fileName
        url
      }
    }
  }
`;

// getSupplierInvoiceBatches :: { printedSupplierBatches: [PrintedSupplierBatch] } -> [PrintedSupplierBatch]
const getSupplierInvoiceBatches = R.propOr([], 'printedSupplierBatches');

export function DailySupplierInvoicesBatchPage() {
  const { data, loading, refetch, tableProps } = useTableInfiniteScrollQuery(
    printedSupplierBatchesQuery,
    null,
    {
      endpointName: 'printedSupplierBatches',
      pageSize: DAILY_SUPPLIER_INVOICE_BATCH_PAGE_SIZE,
    },
  );

  const batches = getSupplierInvoiceBatches(data);

  return loading ? (
    <Loader />
  ) : (
    <>
      <PageHeaderContainer height="120px">
        <MainHeader>Daily Supplier Invoices Batch</MainHeader>
      </PageHeaderContainer>
      <DailySupplierInvoicesBatchTable
        windowedTableProps={tableProps}
        batches={batches}
        refetch={refetch}
      />
    </>
  );
}
