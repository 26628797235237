import React, { memo } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func, number, string, oneOfType, shape } from 'prop-types';
import {
  SupplierDocumentsWarning,
  isDisplaySupplierDocumentWarning,
  useMapConfigToTableProps,
} from 'poly-admin-ui';
import { Checkbox, getThemeColor, InputOutForm } from 'poly-book-admin';
import { centsToDollarsWithFormat, formatDate } from 'poly-utils';
import { keywordSortQuery, commonSortQuery } from 'poly-client-utils';

import {
  PaySupplierInvoicesTableS,
  PaySupplierInvoicesWindowedTableS,
} from './PaySuppliersComponents.js';
import { PaymentInput } from '../../components/PaymentsFromComponents/PaymentInput.js';
import { BalanceInput } from '../../components/PaymentsFromComponents/BalanceInput.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { DeductionsInput } from '../../components/PaymentsFromComponents/DeductionsInput.js';
import { SelectInvoicesCheckBox } from '../../components/PaymentsFromComponents/SelectInvoiceCheckBox.js';
import { filterSupplierDiscountAccounts } from './paySuppliersUtils/payInvoicesUtils.js';
import { useInvoicesHandlers } from './usePaymentsHandlers.js';
import { ProjectSidebarLink } from '../../modules/tables/columns/projects.js';
import { SupplierLink } from '../../components/Links.js';
import { InvoiceFileLink } from '../../modules/tables/columns/invoices.js';
import { InvoiceDueDateWithWarning } from './InvoiceDueDateWithWarning.js';

const inputCommonProps = {
  height: '32px',
  width: '100%',
};

function GlDiscountCodeSelect({
  discountAccountId,
  changeDiscountAccountId,
  deductionsAmount,
  currentBalance,
  filterAccounts,
  direction,
}) {
  return (
    <InputOutForm
      {...inputCommonProps}
      Component={AccountsSelect}
      isOutForm
      size="small"
      direction={direction}
      value={discountAccountId}
      onChange={changeDiscountAccountId}
      required={deductionsAmount > 0}
      disabled={currentBalance < 0}
      filterAccounts={filterAccounts}
      placeholder="Select GL Code"
      extractValue={R.prop('_id')}
    />
  );
}

GlDiscountCodeSelect.propTypes = {
  discountAccountId: string,
  changeDiscountAccountId: func.isRequired,
  deductionsAmount: oneOfType([number, string]),
  currentBalance: number.isRequired,
  filterAccounts: func,
  direction: string,
};

const InvoiceAmountContainerS = styled.span`
  color: ${({ isNegative }) => isNegative && getThemeColor(['secondaryMid'])};
`;

function InvoiceAmountContainer({ amount }) {
  return (
    <InvoiceAmountContainerS isNegative={amount < 0}>
      {centsToDollarsWithFormat(amount)}
    </InvoiceAmountContainerS>
  );
}

InvoiceAmountContainer.propTypes = {
  amount: number.isRequired,
};

const LinkWrapper = styled.div`
  display: flex;
`;

const RedSupplierLink = styled(SupplierLink)`
  color: ${getThemeColor(['secondaryMid'])};
`;

function SupplierLinkCompWithWarning({ supplier }) {
  const isWarning = isDisplaySupplierDocumentWarning(supplier);

  const LinkComp = isWarning ? RedSupplierLink : SupplierLink;

  return (
    <LinkWrapper>
      <SupplierDocumentsWarning supplier={supplier} />
      <LinkComp
        _id={supplier?._id}
        name={supplier?.company?.name}
        target="_blank"
      />
    </LinkWrapper>
  );
}

SupplierLinkCompWithWarning.propTypes = {
  supplier: shape({
    _id: string.isRequired,
    company: shape({ name: string.isRequired }).isRequired,
  }),
};

export const paySuppliersTableConfig = (isAllSelected, onSelectAll = R.T) => [
  ['Ref No.', R.prop('invoiceId'), commonSortQuery(['invoiceId'])],
  ['Inv No.', InvoiceFileLink],
  [
    'Supplier',
    SupplierLinkCompWithWarning,
    keywordSortQuery(['supplier', 'company', 'name']),
  ],
  ['Project', R.compose(ProjectSidebarLink, R.prop('project'))],
  [
    'Inv Date',
    R.compose(formatDate, R.prop('invoiceDate')),
    commonSortQuery(['invoiceDate']),
  ],
  ['Due Date', InvoiceDueDateWithWarning, commonSortQuery(['paymentDueDate'])],
  [
    'Inv Amount',
    ({ total }) => <InvoiceAmountContainer amount={total} />,
    commonSortQuery(['total']),
  ],
  ['Deductions', (props) => <DeductionsInput asCents {...props} />],
  [
    'GL Disc Code',
    (invoice) => (
      <GlDiscountCodeSelect
        {...invoice}
        direction={invoice.dropdownOrientation}
        filterAccounts={filterSupplierDiscountAccounts}
      />
    ),
  ],
  [
    <Checkbox
      key="select-all-invoices-checkbox"
      name="select-all-invoices-checkbox"
      dataTestId="select-all-invoices-checkbox"
      value={!!isAllSelected}
      onChange={onSelectAll}
    />,
    SelectInvoicesCheckBox,
  ],
  ['Payment', (props) => <PaymentInput asCents {...props} />],
  ['Balance', (props) => <BalanceInput asCents {...props} />],
];
export const PaySupplierInvoicesTable = memo(({ virtualized, ...props }) => {
  const { onSelectAll, isAllSelected, rows } = useInvoicesHandlers();
  const tableConfig = paySuppliersTableConfig(isAllSelected, onSelectAll);
  const tableProps = useMapConfigToTableProps(() => rows, tableConfig);

  const TableC = virtualized
    ? PaySupplierInvoicesWindowedTableS
    : PaySupplierInvoicesTableS;

  return <TableC {...tableProps} {...props} itemSize={60} showScrollBar />;
});

PaySupplierInvoicesTable.propTypes = { virtualized: bool };
