import React from 'react';
import { useModalContext } from 'poly-admin-ui';
import { LinkButton } from 'poly-book-admin';
import styled from 'styled-components';
import { string } from 'prop-types';

import {
  editHousekeepingCalendarFormId,
  HousekeepingCalendarForm,
} from './HousekeepingCalendarForm.js';
import { housekeepingCalendarPropTypes } from './HousekeepingCalendarList.js';

const EditBtnS = styled(LinkButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export function EditHousekeepingCalendarBtn({
  clientId,
  clientName,
  housekeepingCalendar,
}) {
  const { openModalForm } = useModalContext();

  const onClick = () =>
    openModalForm({
      id: editHousekeepingCalendarFormId,
      formId: editHousekeepingCalendarFormId,
      title: 'Edit Housekeeping Calendar',
      subTitle: clientName,
      btnCaption: 'Save',
      content: (
        <HousekeepingCalendarForm
          clientId={clientId}
          housekeepingCalendar={housekeepingCalendar}
        />
      ),
    });

  return <EditBtnS onClick={onClick}>edit</EditBtnS>;
}

EditHousekeepingCalendarBtn.propTypes = {
  clientId: string,
  clientName: string,
  housekeepingCalendar: housekeepingCalendarPropTypes,
};
