import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func, shape, string } from 'prop-types';
import { getThemeColor } from 'poly-book-admin';
import { useOutSidebarContext } from 'poly-client-utils';
import { UPDATE_CONTACT_USER_PERMISSION } from 'poly-security';
import {
  CommonSidebarFormWidth,
  EditPeopleForm,
  entities,
} from 'poly-admin-ui';
import { EditBtn } from 'poly-admin-ui/src/components/Button/Button.js';

import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';
import { useHasEditUserPermission } from './useHasEditUserPermission.js';

export const editContactFormId = 'edit_contact_form_id';

function EditContactForm({ onClose, ...props }) {
  const { closeOutSidebar } = useOutSidebarContext();

  const onSuccess = () => {
    closeOutSidebar(editContactFormId);
    onClose();
  };

  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Edit Contact"
      formId={editContactFormId}
      submitButtonCaption="Save"
    >
      <EditPeopleForm {...props} onSuccess={onSuccess} />
    </SidebarFormLayout>
  );
}

EditContactForm.propTypes = { onClose: func.isRequired };

export const useOpenEditContactForm = () => {
  const onClose = useOnCloseFormSidebar();
  const { openOutSidebar } = useOutSidebarContext();

  return ({ contactId }) =>
    openOutSidebar({
      alwaysFirst: true,
      id: editContactFormId,
      width: CommonSidebarFormWidth,
      content: (
        <EditContactForm
          isCardLayout={false}
          contactId={contactId}
          formId={editContactFormId}
          onClose={() => onClose(entities.CONTACT, contactId)}
        />
      ),
    });
};

const notAuthorizedTextMsg =
  'This contact is also a user and can only be edited by users with higher access levels.\nPlease ask your manager to make the required changes.';

const MsgContainer = styled.div`
  display: flex;
  color: ${getThemeColor(['accent'])};
  flex-grow: 1;
  font-size: 12px;
  text-align: justify;
  padding: 0 10px;
  line-height: 18px;
`;

// getUserGroupIdsByContactUser :: ContactUser -> [ID]
const getUserGroupIdsByContactUser = R.compose(
  R.map(R.path(['userGroup', '_id'])),
  R.propOr([], 'userGroups'),
);

export function EditContactButton({ contactId, contactUser }) {
  const openEditContactForm = useOpenEditContactForm();

  const userGroupIds = getUserGroupIdsByContactUser(contactUser);

  const hasPermissionToUpdateUser = useHasEditUserPermission(userGroupIds);

  const onClick = () => openEditContactForm({ contactId });

  if (contactUser?.isStaff && !hasPermissionToUpdateUser) {
    return <MsgContainer>{notAuthorizedTextMsg}</MsgContainer>;
  }

  return (
    <EditBtn onClick={onClick} permissions={[UPDATE_CONTACT_USER_PERMISSION]}>
      Edit
    </EditBtn>
  );
}

EditContactButton.displayName = 'EditPeopleBtn';

EditContactButton.propTypes = {
  contactId: string.isRequired,
  contactUser: shape({ isStaff: bool }),
};
