import * as R from 'ramda';
import styled from 'styled-components';
import { BodyRow } from 'poly-book-admin';
import React, { useState } from 'react';

import { PopoverBox } from 'poly-book-admin/src/Popover/index.js';
import { ClickableRow } from './ClickableRow.js';

export const popoverText =
  'You can not approve this invoice, because you uploaded or requested it';

const OwnInvoiceBodyRow = styled(BodyRow)`
  opacity: 0.5;
  cursor: not-allowed;

  a {
    cursor: not-allowed;
  }
`;

const PopoverTR = styled.tr`
  position: absolute;
  margin-top: -50px;
`;

const PopoverBoxS = styled(PopoverBox)`
  padding: 10px;
  font-size: 14px;
  left: 450px;

  :after {
    top: 40px;
    left: 200px;
    box-shadow: none;
    transform: rotate(225deg);
    border-color: ${R.propOr('none', 'bgColor')};
  }
`;

// checkIsOwnInvoice :: { isUserWithFullAccess: Boolean, row: { isOwnInvoice: Boolean } } -> Boolean
const checkIsOwnInvoice = R.both(
  R.path(['row', 'isOwnInvoice']),
  R.complement(R.prop('isUserWithFullAccess')),
);

export function MixedBodyRow(props) {
  const [showPopup, setShowPopup] = useState(false);

  const isOwnInvoice = checkIsOwnInvoice(props);

  const RowComponent = isOwnInvoice ? OwnInvoiceBodyRow : ClickableRow;

  const onMouseHandlerProps = {
    onMouseEnter: () => setShowPopup(true),
    onMouseLeave: () => setShowPopup(false),
  };

  return !isOwnInvoice ? (
    <RowComponent {...props} />
  ) : (
    <>
      {showPopup && (
        <PopoverTR>
          <td>
            <PopoverBoxS
              isActive={showPopup}
              maxWidth="500px"
              {...onMouseHandlerProps}
            >
              {popoverText}
            </PopoverBoxS>
          </td>
        </PopoverTR>
      )}
      <RowComponent {...props} {...onMouseHandlerProps} />
    </>
  );
}
