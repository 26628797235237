import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { DatePicker } from 'poly-book-admin';
import { bool, func, instanceOf, oneOfType, shape, string } from 'prop-types';
import { isSameDay } from 'date-fns';

import { getSupplierInvoiceDueDateByTerms } from '../supplierInvoiceFormUtils.js';

// checkIfTaskForm :: FormFieldProps -> Boolean
const checkIfTaskForm = R.pathSatisfies(R.complement(R.isNil), [
  'formData',
  'taskSupplier',
]);

const DatePickerS = styled(DatePicker)`
  > div:last-child {
    left: ${(props) => (checkIfTaskForm(props) ? '0' : '-88px')};
  }
`;

export function InvoiceDateField(props) {
  return (
    <DatePickerS
      {...props}
      width="100%"
      label="Invoice Date"
      disableOverflowChange
    />
  );
}

export function InvoiceDueDateField({
  onChange,
  formData,
  changeFieldValue,
  ...props
}) {
  const paymentDueDate = getSupplierInvoiceDueDateByTerms({
    ...formData.invoice,
    invoiceDate: formData?.invoiceDate,
  });

  const handleChange = (value) => {
    onChange(value);
    if (!isSameDay(value, paymentDueDate)) {
      changeFieldValue('isDueDateChanged', true);
    }
  };

  useEffect(() => {
    if (!formData?.isDueDateChanged && !formData?.paymentDueDateChangeReason) {
      onChange(paymentDueDate);
    }
  }, [formData?.invoiceDate, formData?.invoice?.supplier]);

  return (
    <DatePicker
      {...props}
      width="100%"
      label="Payment Due Date"
      onChange={handleChange}
      disableOverflowChange
      leftMove="0px"
    />
  );
}

InvoiceDueDateField.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
  formData: shape({
    isDueDateChanged: bool,
    invoiceDate: oneOfType([instanceOf(Date), string]),
  }),
};
