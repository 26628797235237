import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { string } from 'prop-types';
import { Select } from 'poly-book-admin';
import {
  NOTHING_UI_STRING,
  RequesterSurveyQuestionTypes,
  RequesterSurveyQuestionTypesUI,
} from 'poly-constants';

import { isRequiredValidation } from './validation.js';
import { ClientRequesterFormFieldLabel } from './common.js';

const QuestionTypeFormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 20%;
  min-width: 130px;
`;

// prepareOption :: RequesterSurveyQuestionTypes -> SelectOption
const prepareOption = R.applySpec({
  value: R.identity,
  label: R.propOr(NOTHING_UI_STRING, R.__, RequesterSurveyQuestionTypesUI),
});

const questionTypeFormFieldOptions = [
  prepareOption(RequesterSurveyQuestionTypes.open),
  prepareOption(RequesterSurveyQuestionTypes.yesNo),
  prepareOption(RequesterSurveyQuestionTypes.scoring),
  prepareOption(RequesterSurveyQuestionTypes.submitMethod),
];

export function QuestionTypeFormField({ name }) {
  const selectProps = {
    required: true,
    options: questionTypeFormFieldOptions,
  };

  return (
    <QuestionTypeFormFieldWrapper width="20%">
      <ClientRequesterFormFieldLabel>Answer Type</ClientRequesterFormFieldLabel>
      <Field
        name={`${name}.type`}
        validate={isRequiredValidation('Type is required')}
      >
        {({ input, meta }) => (
          <Select
            {...input}
            {...selectProps}
            meta={meta}
            error={meta.touched ? meta.error : undefined}
            dataTestId={`${name}.type`}
          />
        )}
      </Field>
    </QuestionTypeFormFieldWrapper>
  );
}

QuestionTypeFormField.propTypes = {
  name: string.isRequired,
};
