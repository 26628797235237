import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { useLocation } from 'poly-client-routing';
import { usePersistentFormValue } from 'poly-client-utils/src/hooks/persists/usePersistentFormValue.js';
import { UPDATE_PERSIST_KEY_PREFIX } from 'poly-constants/src/updates.js';

import { useCloseCurrentModal } from '../../redux/modal.js';
import { useConfirmModal } from '../../redux/confirmModal.js';
import { CancelBtn, confirmCancelFormPayload } from '../common.js';

export function CancelModalFormBtn({
  confirmButtonName,
  formId,
  [formId]: loading,
  ...props
}) {
  const { state } = useLocation();
  const processes = useSelector(R.prop('processes'));

  const entity = useSelector(
    (reduxState) => reduxState?.modal?.payload?.entity,
  );

  const closeCurrentModal = useCloseCurrentModal();
  const { openModal: openConfirmModal } = useConfirmModal();

  const { cleanupRetainedValue } = usePersistentFormValue(
    `${UPDATE_PERSIST_KEY_PREFIX}${entity?._id}`,
  );

  const afterCloseModal = () => {
    if (entity?._id) {
      cleanupRetainedValue();
    }
  };

  const onClick = () => {
    if (state?.pristine === false) {
      openConfirmModal({
        payload: {
          ...confirmCancelFormPayload,
          confirmButtonName,
        },
        afterConfirm: afterCloseModal,
      });

      return;
    }
    afterCloseModal();
    closeCurrentModal();
  };

  return (
    <CancelBtn
      disabled={loading}
      onClick={onClick}
      processes={processes}
      {...props}
    />
  );
}

CancelModalFormBtn.propTypes = {
  confirmButtonName: string.isRequired,
  formId: string.isRequired,
};
