import React, { useState, useRef } from 'react';
import { Map } from 'google-maps-react';
import { useLoadGoogleMapScript } from 'poly-client-utils';
import { Loader } from 'poly-book-admin';
import { arrayOf, shape, string } from 'prop-types';

import { MapWrapper } from './styles.js';
import { GoogleMapList } from './GoogleMapList.js';
import { GoogleMapMarker } from './GoogleMapMarker.js';
import { GoogleMapTooltip } from './GoogleMapTooltip.js';
import { initialNewYorkCoords, settings } from '../core/constants/general.js';
import { prepareMapBounds } from './googleMapUtils.js';

const { GOOGLE_API_KEY } = settings;

const useGoogleMapProps = (suppliers) => {
  const [activeMarker, setActiveMarker] = useState({});
  const loading = useLoadGoogleMapScript(GOOGLE_API_KEY);

  const refs = useRef({});

  return {
    loading,
    zoom: 5,
    google: window.google,
    initialCenter: initialNewYorkCoords,
    style: { width: '100%', height: '100%' },
    bounds: loading ? {} : prepareMapBounds(window.google, suppliers),
    activeMarker,
    setActiveMarker,
    setRef: (ref, id) => {
      refs.current[id] = ref;
    },
    getRef: (id) => refs.current[id],
  };
};

export function GoogleMap({ suppliers }) {
  const {
    loading,
    activeMarker,
    setActiveMarker,
    setRef,
    getRef,
    google,
    ...props
  } = useGoogleMapProps(suppliers);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <MapWrapper>
        <Map {...props} google={google}>
          {suppliers.map((supplier) => (
            <GoogleMapMarker
              key={supplier._id}
              supplier={supplier}
              setter={setActiveMarker}
              setRef={setRef}
            />
          ))}
          <GoogleMapTooltip {...{ suppliers, activeMarker }} />
        </Map>
      </MapWrapper>
      <GoogleMapList {...{ suppliers, getRef, google }} />
    </>
  );
}

GoogleMap.propTypes = {
  suppliers: arrayOf(shape({ _id: string })),
};
