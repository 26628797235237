import React from 'react';
import { SystemAccountTypes } from 'poly-constants';

import { BankReconciliationPageRedesignedBase } from '../BankReconciliation/BankReconcilationPageBase.js';

const paymentsSection = {
  title: 'Charges',
  tableHeight: '50%',
  tableKey: 'creditCharges',
  totalLabel: 'Total Charges Cleared',
};

const depositsSection = {
  title: 'Payments',
  tableHeight: '50%',
  tableKey: 'creditPayments',
  totalLabel: 'Total Payments Cleared',
};

const confirmationMessage =
  'Credit Card Reconciliation Statement is successfully created';

export function CreditCardReconciliationPage() {
  return (
    <BankReconciliationPageRedesignedBase
      paymentsSection={paymentsSection}
      depositsSection={depositsSection}
      accountTypeLabel="Credit Card"
      systemAccountType={SystemAccountTypes.CREDIT_CARD}
      confirmationMessage={confirmationMessage}
    />
  );
}
