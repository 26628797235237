import { defaultTheme } from 'poly-book-admin';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

export const propertyStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const propertyStatusesColors = {
  [propertyStatuses.ACTIVE]: active,
  [propertyStatuses.INACTIVE]: inactive,
};
