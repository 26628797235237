import React from 'react';
import styled from 'styled-components';
import { string, bool, func } from 'prop-types';
import { useNavigate } from 'poly-client-routing';
import {
  DefaultPopUpContentWrapper,
  getThemeColor,
  PopUpMenuItem,
  getThemeFont,
  UserAvatar,
  PopUp,
} from 'poly-book-admin';

import { routesNames } from '../../routes/index.js';
import { useAppBarItemClick } from './useAppBarItemClick.js';

const PopUpS = styled(PopUp)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
`;

const DefaultPopUpContentWrapperS = styled(DefaultPopUpContentWrapper)`
  right: -10px;
`;

const MenuItemText = styled.span`
  font-size: 13px;
  line-height: 17px;
  font-weight: ${getThemeFont(['regular'])};
`;

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const UserCredentials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
`;

const UserCredentialsText = styled.div`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90px;
  font-size: 10px;
  line-height: 12px;
  color: ${getThemeColor(['white'])};
  font-weight: 400;
`;

const UserAvatarS = styled(UserAvatar)`
  background-color: ${getThemeColor(['accent2Lighter2'])};
`;

function UserInfo({ role, initials, fullName, toggleOpen }) {
  const onClick = useAppBarItemClick(toggleOpen);

  return (
    <UserInfoWrapper onClick={onClick} id="user-info" data-testid="user-info">
      <UserAvatarS>{initials}</UserAvatarS>
      <UserCredentials>
        <UserCredentialsText>{fullName}</UserCredentialsText>
        <UserCredentialsText>{role}</UserCredentialsText>
      </UserCredentials>
    </UserInfoWrapper>
  );
}

UserInfo.propTypes = {
  role: string.isRequired,
  initials: string.isRequired,
  fullName: string,
  toggleOpen: func.isRequired,
};

export function UserPopupComp({
  close,
  isOpen,
  onLogout,
  toggleOpen,
  ...props
}) {
  const navigate = useNavigate();

  const goToSettingsPage = () => {
    navigate(routesNames.SETTINGS);
    toggleOpen();
  };

  return (
    <PopUpS
      onBlur={close}
      isOpen={isOpen}
      trigger={<UserInfo {...props} toggleOpen={toggleOpen} />}
    >
      <DefaultPopUpContentWrapperS>
        <PopUpMenuItem onClick={goToSettingsPage}>
          <MenuItemText>Settings</MenuItemText>
        </PopUpMenuItem>
        <PopUpMenuItem onClick={onLogout}>
          <MenuItemText>Log Out</MenuItemText>
        </PopUpMenuItem>
      </DefaultPopUpContentWrapperS>
    </PopUpS>
  );
}

UserPopupComp.propTypes = {
  close: func.isRequired,
  isOpen: bool.isRequired,
  onLogout: func.isRequired,
  toggleOpen: func.isRequired,
};
