import React from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { usePristineSubscribe } from 'poly-client-routing';
import { FormPage } from 'poly-book-admin';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from 'poly-admin-ui';

import { payByFormSections } from './payByFormSections.js';
import { useShowLongRunningTaskResult } from '../../components/LongRunningTaskResult.js';
import { preparePayByFormDataForMutation } from './payByFormUtils.js';
import {
  AAC_CHECKS_MUTATION,
  getBase64Strings,
} from '../PrintChecksPage/PrintChecksTable.js';

const GET_CHECK_QUERY = gql`
  query GET_CHECK_QUERY($input: SupplierChecksInput) {
    supplierChecks(input: $input) {
      hits {
        _id
      }
    }
  }
`;

const createCustomJournalMutation = gql`
  mutation createCustomJournalMutation($input: CustomJournalInput!) {
    createCustomJournal(input: $input) {
      _id
    }
  }
`;

// prepareCheckToMutation :: SupplierChecksResult -> CustomJournalInput
const prepareCheckToMutation = R.compose(
  R.objOf('checkIds'),
  R.reject(R.isNil),
  R.of,
  R.path(['data', 'supplierChecks', 'hits', 0, '_id']),
);

export function PayByForm({
  formId,
  initialPaymentMode,
  showInvoiceNumberInput,
}) {
  const client = useApolloClient();
  const { showSuccessNotification } = useNotificationState();
  const showLongRunningTaskResult = useShowLongRunningTaskResult();
  const [createJournal] = useMutation(createCustomJournalMutation);
  const [generateCheckPdf] = useMutation(AAC_CHECKS_MUTATION);

  const onSubmitHandler = async (formData) => {
    const input = preparePayByFormDataForMutation(formData);
    await createJournal({
      variables: { input },
    });
    showSuccessNotification('Payment is successfully created!');
    const formElement = document.getElementById(formId);
    const isPrint = formElement.hasAttribute('print');
    if (isPrint) {
      const queryResult = await client.query({
        query: GET_CHECK_QUERY,
        variables: { input: { checkNumber: input.checkNumber } },
      });

      formElement.removeAttribute('print');

      showLongRunningTaskResult({
        title: 'Print Checks',
        viewResultCaption: 'Open PDF',
        loadingMessage: 'Generating checks...',
        doneMessage: 'PDF is ready to open',
        taskAction: async () => {
          const mutationResult = await generateCheckPdf({
            variables: { input: prepareCheckToMutation(queryResult) },
          });
          return getBase64Strings(mutationResult);
        },
        onViewResult: (pdfUrls) => {
          pdfUrls.forEach((pdfUrl) => window.open(pdfUrl, '_blank'));
        },
      });
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const pristineSubscribeProps = usePristineSubscribe();

  const formProps = {
    onSubmit,
    id: formId,
    resetFormOnSubmit: true,
    initialValuesEqual: R.T,
    keepDirtyOnReinitialize: false,
    sections: payByFormSections(formId),
    initialValues: {
      paymentMode: initialPaymentMode,
      receiptFiles: [],
      showInvoiceNumberInput,
    },
    ...pristineSubscribeProps,
  };

  return <FormPage {...formProps} />;
}

PayByForm.propTypes = {
  formId: string.isRequired,
  initialPaymentMode: string.isRequired,
  showInvoiceNumberInput: bool,
};
