import React from 'react';
import { css } from 'styled-components';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import { shape, string } from 'prop-types';
import { openPrintWindowWithData } from 'poly-client-utils/src/print.js';
import { CommonPrintLayout } from 'poly-admin-ui/src/components/CommonPrintLayout.js';

import { useSubCategoryReportLazyQuery } from './useSubCategoryReport.js';
import { SubCategoryReportTable } from './SubCategoryReportTable.js';
import { ExportPDFBtn } from '../WorkInProgressReport/WorkInProgressExportPDFBtn.js';

const subCategoryReportPdfStyles = css`
  @page {
    size: landscape;
  }
`;

export function SubCategoryReportPdfExportButton({ filter, ...props }) {
  const queryHandler = useSubCategoryReportLazyQuery();

  const onPrintPDFClick = async () => {
    const report = await queryHandler({ filter });

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: SubCategoryReportTable,
      fileName: 'sub_category_report',
      metaData: {
        title: 'Sub-Category Report',
      },
      reportItems: report,
      isPrint: true,
      customStyles: subCategoryReportPdfStyles,
    });
  };

  return (
    <ExportPDFBtn {...props} onClick={onPrintPDFClick}>
      {PRINT_PDF_CAPTION}
    </ExportPDFBtn>
  );
}

export const subCategoryReportFilterPropTypes = shape({
  client: string,
  project: string,
  supplier: string,
  invoiceDate: shape({ startDate: string, endDate: string }),
});

SubCategoryReportPdfExportButton.propTypes = {
  filter: subCategoryReportFilterPropTypes,
};
