import styled from 'styled-components';
import React, { useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { FlexColumn, FlexContainer } from 'poly-admin-ui';
import { getThemeColor, Loader } from 'poly-book-admin';

import {
  tasksTotalItems,
  projectsTotalItems,
  masterProjectsTotalItems,
} from '../../pages/HomePage/totalItems.js';
import { getClientInvoicingPageConfig } from '../../pages/ClientInvoicing/constants.js';
import { TotalInfoSection } from '../../pages/HomePage/components/TotalInfoSections.js';
import { FinancialDirectorySidebarSection } from './FinancialDirectorySidebarSection.js';
import { financialDirectoryPageLinks } from '../../pages/FinancialDirectory/constants.js';
import { useDashboardQueryWorker } from '../../pages/HomePage/useDashboardQueryWorker.js';
import { useBillingProfileList } from '../../pages/ClientInvoicing/useBillingProfileList.js';

const activeTabs = {
  clientInvoicingTab: 'clientInvoicingTab',
  directoryTab: 'directoryTab',
  workflowTab: 'workflowTab',
};

const tabsTitles = {
  [activeTabs.clientInvoicingTab]: 'Invoicing',
  [activeTabs.directoryTab]: 'Financial',
  [activeTabs.workflowTab]: 'Workflow',
};

const TabHeader = styled(FlexContainer)`
  padding: 10px;
  color: ${({ isActive, ...props }) =>
    getThemeColor([isActive ? 'primaryRegular' : 'midDark'])(props)};
  cursor: pointer;
  font-size: 12px;
  border-bottom: ${({ isActive, ...props }) =>
    isActive && `2px solid ${getThemeColor(['primaryRegular'])(props)}`};
`;

function FinancialDirectoryTabHeader({ activeTab, setActiveTab, tab }) {
  const onClick = () => setActiveTab(tab);
  const isActive = activeTab === tab;
  return (
    <TabHeader isActive={isActive} onClick={onClick}>
      {tabsTitles[tab]}
    </TabHeader>
  );
}

FinancialDirectoryTabHeader.propTypes = {
  activeTab: string.isRequired,
  setActiveTab: func.isRequired,
  tab: string.isRequired,
};

function MenuItemsTab({ config }) {
  return (
    <>
      {config.map(({ title, links }) => (
        <FinancialDirectorySidebarSection
          key={title}
          title={title}
          links={links}
        />
      ))}
    </>
  );
}

MenuItemsTab.propTypes = {
  config: arrayOf(
    shape({
      title: string.isRequired,
      links: arrayOf(
        shape({
          name: string.isRequired,
          link: string,
          permissions: arrayOf(string),
        }),
      ),
    }),
  ),
};

const TabsHeaderContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
`;

const SectionContentContainer = styled(FlexColumn)`
  padding: 0 30px;
  margin-bottom: 15px;
  min-height: 300px;
  position: relative;
`;

function GlobalProjectsInfo(props) {
  return (
    <TotalInfoSection
      label="Global Projects"
      items={projectsTotalItems}
      margin="30px"
      {...props}
    />
  );
}

function GlobalMasterProjectsInfo(props) {
  return (
    <TotalInfoSection
      label="Masters"
      items={masterProjectsTotalItems}
      margin="30px"
      {...props}
    />
  );
}

function TotalTasksInfo(props) {
  return (
    <TotalInfoSection
      label="Tasks"
      items={tasksTotalItems}
      margin="30px"
      {...props}
    />
  );
}

function WorkflowTab() {
  const { data, loading } = useDashboardQueryWorker();

  if (loading) {
    return <Loader />;
  }

  const {
    dashboard: {
      globalProjectsCounts,
      globalMastersCounts,
      supplierInvoicesCounts,
    },
  } = data;

  return (
    <FlexColumn>
      <GlobalProjectsInfo counts={globalProjectsCounts} />
      <GlobalMasterProjectsInfo counts={globalMastersCounts} />
      <TotalTasksInfo counts={supplierInvoicesCounts} />
    </FlexColumn>
  );
}

function ClientInvoicingTab() {
  const { billingProfileConfigs } = useBillingProfileList();
  return (
    <MenuItemsTab
      config={getClientInvoicingPageConfig(billingProfileConfigs)}
    />
  );
}

function DirectoryTab() {
  return <MenuItemsTab config={financialDirectoryPageLinks} />;
}

const tabsComponents = {
  [activeTabs.clientInvoicingTab]: ClientInvoicingTab,
  [activeTabs.directoryTab]: DirectoryTab,
  [activeTabs.workflowTab]: WorkflowTab,
};

export function FinancialDirectorySidebarTabs() {
  const [activeTab, setActiveTab] = useState(activeTabs.directoryTab);

  const TabComponent = tabsComponents[activeTab];

  return (
    <>
      <TabsHeaderContainer>
        {Object.values(activeTabs).map((tab) => (
          <FinancialDirectoryTabHeader
            tab={tab}
            key={tab}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        ))}
      </TabsHeaderContainer>
      <SectionContentContainer>
        <TabComponent />
      </SectionContentContainer>
    </>
  );
}
