import { useSelector, useDispatch } from 'react-redux';
import {
  getCommonRouterProps,
  useNavigate,
  useLocation,
  useParams,
} from 'poly-client-routing';
import {
  closeCurrentModal,
  closeConfirmModal,
  useProcessState,
  showSuccess,
  showError,
} from 'poly-admin-ui';

export const useBasicActions = () => {
  const dispatch = useDispatch();

  const { processes, setProcesses } = useProcessState();

  const modal = useSelector((state) => state.modal);
  const confirmModal = useSelector((state) => state.confirmModal);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const routerProps = {
    push: navigate,
    router: getCommonRouterProps({ params, location }),
  };

  return {
    dispatch,
    processes,
    setProcesses,
    modal,
    confirmModal,
    closeCurrentModal,
    closeConfirmModal,
    showError,
    showSuccess,
    ...routerProps,
  };
};
