import React, { useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import {
  aminPurchaseOrderStatuses,
  adminPurchaseOrderTypes,
} from 'poly-constants';
import { halfWidth } from 'poly-admin-ui/src/modules/forms/common.js';
import { Select } from 'poly-book-admin/src/Select/Select.js';
import { Input } from 'poly-book-admin/src/Input/index.js';
import { DatePicker } from 'poly-book-admin/src/DatePicker/index.js';
import { ClientSelect } from 'poly-admin-ui/src/modules/forms/fields/ClientSelect/ClientSelect.js';
import { UserSelect } from 'poly-admin-ui/src/modules/forms/fields/UserSelect/UserSelect.js';
import { MoneyInputAsCents } from 'poly-admin-ui/src/modules/forms/fields/MoneyInput.js';
import { PercentInput } from 'poly-admin-ui/src/components/PercentInput.js';
import { Editor } from 'poly-book-admin/src/Editor/index.js';
import { MultiSelectDropDown } from 'poly-admin-ui/src/components/MultiSelectDropDown.js';
import { useUsersEmailsMailTo } from 'poly-admin-ui/src/hooks/users/index.js';
import { initialPagination } from 'poly-client-utils/src/pagination.js';
import { generateMailToQuery } from 'poly-admin-ui/src/modules/forms/fields/MailTo/mailToUtils.js';
import { validateEmail } from 'poly-utils/src/validators.js';

import { MultiplePropertySelect } from '../../../pages/ClientInvoicingCollection/BillingProfileConfigurationSidebar/components/MultiplePropertySelect.js';
import { purchaseOrderTypesUI } from '../constants.js';
import { getPurchaseOrdersStatusUI } from '../utils.js';

const PercentInputS = styled(PercentInput)`
  width: 100%;
  margin-right: 0;
`;

// getPurchaseOrderStatusOptions :: PurchaseOrderStatuses -> [{ value: String, label: String }]
const getPurchaseOrderStatusOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.identity,
      label: getPurchaseOrdersStatusUI,
    }),
  ),
  R.values,
);

function PurchaseOrderStatusSelect(props) {
  const options = getPurchaseOrderStatusOptions(aminPurchaseOrderStatuses);

  return <Select options={options} {...props} />;
}

// getPurchaseOrderTypesOptions :: PurchaseOrderTypes -> [{ value: String, label: String }]
const getPurchaseOrderTypesOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.identity,
      label: R.prop(R.__, purchaseOrderTypesUI),
    }),
  ),
  R.values,
);

function PurchaseOrderTypeSelect(props) {
  const options = getPurchaseOrderTypesOptions(adminPurchaseOrderTypes);

  return <Select options={options} {...props} />;
}

function PurchaseOrderStartDatePicker({ formData: { endDate }, ...props }) {
  return (
    <DatePicker {...props} width="100%" disabledDays={{ after: endDate }} />
  );
}

PurchaseOrderStartDatePicker.propTypes = {
  formData: shape({
    endDate: oneOfType([instanceOf(Date), string]),
  }),
};

function PurchaseOrderEndDatePicker({ formData: { startDate }, ...props }) {
  return (
    <DatePicker {...props} width="100%" disabledDays={{ before: startDate }} />
  );
}

PurchaseOrderEndDatePicker.propTypes = {
  formData: shape({
    startDate: oneOfType([instanceOf(Date), string]),
  }),
};

export function PurchaseOrderClientSelect({
  changeFieldValue,
  isDisabled,
  onChange: onChangeBase,
  ...props
}) {
  const onChange = (value) => {
    if (changeFieldValue) {
      changeFieldValue('propertyIds', null);
    }
    onChangeBase(value);
  };

  return (
    <ClientSelect
      {...props}
      withoutSkip
      onChange={onChange}
      includeAllOption={false}
      query={{ term: { 'configs.enablePurchaseOrder': true } }}
      disabled={isDisabled}
    />
  );
}

PurchaseOrderClientSelect.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func,
  isDisabled: bool,
};

const EditorS = styled(Editor)`
  .ql-editor {
    max-height: none;
    min-height: 110px;
  }
`;

function PurchaseOrderRemarksInput(props) {
  return (
    <EditorS
      {...props}
      id="purchase-order-remarks-input"
      placeholder="You can add notes or comments related to the purchase order."
    />
  );
}

function AuthorizedBySelect({ formData, ...props }) {
  const query = {
    bool: {
      should: {
        nested: {
          path: 'links',
          query: {
            term: {
              'links.clientId': formData.clientId,
            },
          },
        },
      },
    },
  };

  return <UserSelect {...props} query={query} withoutSkip />;
}

AuthorizedBySelect.propTypes = {
  formData: shape({}),
};

// prepareNotificationContactsValue :: [Option] -> [Option]
const prepareNotificationContactsValue = R.compose(
  R.filter(R.propSatisfies(validateEmail, 'value')),
  R.defaultTo([]),
);

// getNotificationContactOptions :: QueryData -> [Option]
const getNotificationContactOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.prop('email'),
      label: ({ email, fullName }) => `${fullName} (${email})`,
    }),
  ),
  R.pathOr([], ['searchUsers', 'hits']),
);

export function NotificationContacts({ value, onChange, skipQuery, ...props }) {
  const [searchText, setSearchText] = useState('');

  const { result } = useUsersEmailsMailTo({
    searchText,
    query: generateMailToQuery(),
    pagination: initialPagination,
    skipQuery: !searchText || skipQuery,
  });

  const options = [
    ...(searchText ? [{ label: searchText, value: searchText }] : []),
    ...getNotificationContactOptions(result),
  ];

  return (
    <MultiSelectDropDown
      options={prepareNotificationContactsValue(options)}
      onInputChange={setSearchText}
      value={prepareNotificationContactsValue(value)}
      handleChange={onChange}
      placeholder="Start typing emails"
      {...props}
    />
  );
}

NotificationContacts.propTypes = {
  value: oneOfType([
    string,
    arrayOf(
      shape({
        value: string,
        label: string,
      }),
    ),
  ]),
  onChange: func.isRequired,
  skipQuery: bool,
};

export const getPurchaseOrderFormSections = (isEdit) => [
  {
    order: 1,
    layout: { margin: '24px 0 0 0' },
    fields: [
      {
        order: 1,
        label: 'PO Number',
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'poNumber',
          Component: Input,
          additionalProps: { showCharactersLeft: true, charactersLimit: 30 },
        },
        validators: [[R.identity, 'PO Number is required']],
        required: true,
      },
      {
        order: 2,
        label: 'PO Display Name',
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'displayName',
          Component: Input,
          additionalProps: { showCharactersLeft: true, charactersLimit: 100 },
        },
        validators: [[R.identity, 'Display Name is required']],
        required: true,
      },
      {
        order: 3,
        label: 'Status',
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'status',
          Component: PurchaseOrderStatusSelect,
        },
        validators: [[R.identity, 'Status is required']],
        required: true,
      },
      {
        order: 4,
        label: 'PO Type',
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'type',
          Component: PurchaseOrderTypeSelect,
        },
        validators: [[R.identity, 'PO Type is required']],
        required: true,
      },
      {
        order: 5,
        label: 'Start Date',
        layout: { row: 3, width: halfWidth },
        field: {
          withFormData: true,
          name: 'startDate',
          Component: PurchaseOrderStartDatePicker,
          additionalProps: { leftMove: '0px' },
        },
        validators: [[R.identity, 'Start Date is required']],
        required: true,
      },
      {
        order: 6,
        label: 'End Date',
        layout: { row: 3, width: halfWidth },
        field: {
          withFormData: true,
          name: 'endDate',
          Component: PurchaseOrderEndDatePicker,
        },
      },
      {
        order: 7,
        label: 'Client',
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'clientId',
          withChangeFieldValue: true,
          Component: PurchaseOrderClientSelect,
          additionalProps: { isDisabled: isEdit },
        },
        validators: [[R.identity, 'Client is required']],
        required: true,
      },
      {
        order: 8,
        label: 'Authorized By',
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'authorizedBy',
          withFormData: true,
          Component: AuthorizedBySelect,
        },
        validators: [[R.identity, 'Authorized By is required']],
        required: true,
      },
      {
        order: 9,
        label: 'Initial Balance',
        layout: { row: 5, width: halfWidth },
        field: {
          name: 'initialBalance',
          Component: MoneyInputAsCents,
        },
        validators: [[R.identity, 'Initial Balance is required']],
        required: true,
      },
      {
        order: 10,
        label: 'Low Balance %',
        layout: { row: 5, width: halfWidth },
        field: {
          name: 'lowBalancePercent',
          Component: PercentInputS,
          additionalProps: { skipLabelPercent: true },
        },
      },
      {
        order: 11,
        label: 'Notification Contacts',
        layout: { row: 6, width: '100% ' },
        field: {
          withFormData: true,
          name: 'notificationContactsEmails',
          Component: NotificationContacts,
        },
      },
      {
        order: 12,
        label: 'Properties',
        layout: { row: 7, width: '100% ' },
        field: {
          name: 'propertiesIds',
          withFormData: true,
          Component: MultiplePropertySelect,
          additionalProps: { withBaseSelect: true },
        },
      },
      {
        order: 13,
        label: 'Remarks',
        layout: { row: 8, width: '100%' },
        field: {
          name: 'remarks',
          Component: PurchaseOrderRemarksInput,
        },
      },
    ],
  },
];
