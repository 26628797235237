import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { arrayOf, func, shape, string } from 'prop-types';
import { useOnSubmitSetStopSubmitting, showSuccess } from 'poly-admin-ui';
import { entityToOptionByLabelPath } from 'poly-client-utils';
import { usePristineSubscribe } from 'poly-client-routing';
import { assocBy, renameProp } from 'poly-utils';
import { FormCreator } from 'poly-book-admin';

import { commonModalLayout } from '../common.js';
import { defaultMentionGroup } from './constants.js';
import { mentionGroupFormSections } from './sections.js';

const mentionGroupShape = shape({
  _id: string,
  name: string,
  description: string,
  status: string,
  users: arrayOf(
    shape({
      _id: string.isRequired,
      fullName: string.isRequired,
    }),
  ),
});

// prepareMentionGroupForForm :: Object -> MentionGroup
const prepareMentionGroupForForm = R.compose(
  R.dissoc('client'),
  assocBy('clientId', R.path(['client', '_id'])),
  renameProp('users', 'usersIds'),
  R.over(R.lensProp('users'), R.map(entityToOptionByLabelPath(['fullName']))),
  R.omit(['__typename']),
  R.mergeRight(defaultMentionGroup),
);

export const mentionGroupFormId = 'mentionGroupFrom';

export function MentionGroupForm({
  mutate,
  payload,
  onCancel,
  successMessage,
  formDataToMutationVariables,
  ...restProps
}) {
  const dispatch = useDispatch();

  const onSubmitHandler = (submittedData) => {
    const mutationVariables = formDataToMutationVariables(submittedData);

    return mutate({
      variables: mutationVariables,
    }).then(() => {
      dispatch(showSuccess(successMessage));
      dispatch(onCancel());
    });
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    mentionGroupFormId,
    onSubmitHandler,
  );

  const subscriptionProps = usePristineSubscribe();

  return (
    <FormCreator
      {...restProps}
      {...subscriptionProps}
      validateOnBlur
      onSubmit={onSubmit}
      id={mentionGroupFormId}
      layout={commonModalLayout}
      sections={mentionGroupFormSections}
      initialValues={prepareMentionGroupForForm(payload)}
    />
  );
}

MentionGroupForm.displayName = 'MentionGroupForm';

MentionGroupForm.propTypes = {
  payload: mentionGroupShape.isRequired,
  successMessage: string.isRequired,
  formDataToMutationVariables: func.isRequired,
  mutate: func.isRequired,
  onCancel: func.isRequired,
};
