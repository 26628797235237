import * as R from 'ramda';
import React from 'react';
import { LinkButton } from 'poly-book-admin';
import { arrayOf, shape, string } from 'prop-types';
import { convertCentsToDollars } from 'poly-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
  formatFromToDateTitle,
} from 'poly-client-utils';

import { IncomeStatementTableHeaders } from './constants.js';
import {
  getCogsReports,
  getIncomeReports,
  getExpenseReports,
  getReportDataTotal,
} from './helpers.js';
import {
  incomeStatementsPropTypes,
  incomeStatementsFiltersPropTypes,
} from './prop-types.js';
import {
  EMPTY_XLS_ROW,
  getExcelTitle,
  getSectionTitle,
  getSectionTotal,
  getExcelTableData,
} from './xls-button-helpers.js';

const {
  UNIT_HEADER,
  TOTAL_HEADER,
  SUBTOTAL_HEADER,
  POLY_ACCOUNT_HEADER,
  SAP_DESCRIPTION_HEADER,
  COMPASS_GL_CODE_HEADER,
  POLY_ACCOUNT_AND_CLIENT_NAME_HEADER,
} = IncomeStatementTableHeaders;

// eslint-disable-next-line import/no-unused-modules
export const getIncomeStatementsExcelConfig = (
  filters,
  incomeReports,
  cogsReports,
  expenseReports,
  incomeReportsTotal,
  cogsReportsTotal,
  expenseReportsTotal,
) => ({
  exportFileName: 'income_statement_report.xlsx',
  columnWidths: [20, 10, 35, 12, 35, 15, 15],
  rows: [
    [
      {
        value: getExcelTitle(filters),
        cellType: ExcelExportCellType.title,
      },
    ],
    [
      {
        value: formatFromToDateTitle(filters.startDate, filters.endDate),
        cellType: ExcelExportCellType.title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 5),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 2),
      ],
      [
        COMPASS_GL_CODE_HEADER,
        UNIT_HEADER,
        SAP_DESCRIPTION_HEADER,
        POLY_ACCOUNT_HEADER,
        POLY_ACCOUNT_AND_CLIENT_NAME_HEADER,
        SUBTOTAL_HEADER,
        TOTAL_HEADER,
      ],
    ),

    getSectionTitle('Income'),
    ...getExcelTableData(incomeReports),
    getSectionTotal('Total Income', convertCentsToDollars(incomeReportsTotal)),
    EMPTY_XLS_ROW,

    getSectionTitle('COGS Facility Services'),
    ...getExcelTableData(cogsReports),
    getSectionTotal(
      'Total COGS Facility Services',
      convertCentsToDollars(cogsReportsTotal),
    ),
    EMPTY_XLS_ROW,

    getSectionTotal(
      'Gross Profit',
      convertCentsToDollars(incomeReportsTotal + cogsReportsTotal),
    ),
    EMPTY_XLS_ROW,

    getSectionTitle('Overhead'),
    ...getExcelTableData(expenseReports),
    getSectionTotal(
      'Total Overhead',
      convertCentsToDollars(expenseReportsTotal),
    ),
    EMPTY_XLS_ROW,

    getSectionTotal(
      'Net Profit/(Loss)',
      convertCentsToDollars(
        incomeReportsTotal + cogsReportsTotal + expenseReportsTotal,
      ),
    ),
  ],
});

export function IncomeStatementsXLSButton({
  filters,
  incomeStatements,
  cogsAccountCodes,
}) {
  const onClick = () => {
    const incomeReports = getIncomeReports(incomeStatements);
    const cogsReports = getCogsReports(cogsAccountCodes)(incomeStatements);
    const expenseReports =
      getExpenseReports(cogsAccountCodes)(incomeStatements);

    const incomeReportsTotal = getReportDataTotal(incomeReports);
    const cogsReportsTotal = getReportDataTotal(cogsReports);
    const expenseReportsTotal = getReportDataTotal(expenseReports);

    const exportConfig = getIncomeStatementsExcelConfig(
      filters,
      incomeReports,
      cogsReports,
      expenseReports,
      incomeReportsTotal,
      cogsReportsTotal,
      expenseReportsTotal,
    );
    performExcelExport(exportConfig);
  };

  return <LinkButton onClick={onClick}>{EXPORT_XLS_CAPTION}</LinkButton>;
}

IncomeStatementsXLSButton.propTypes = {
  filters: shape(incomeStatementsFiltersPropTypes),
  incomeStatements: arrayOf(shape(incomeStatementsPropTypes)),
  cogsAccountCodes: arrayOf(string),
};
