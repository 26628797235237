import React from 'react';
import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { string, func, shape, bool } from 'prop-types';
import { FormCreator, FormPage } from 'poly-book-admin';
import { UserStatuses } from 'poly-constants';
import {
  usePristineSubscribe,
  useUpdateQueryParams,
} from 'poly-client-routing';

import {
  commonSidebarFormLayout,
  commonSidebarFormFieldLayout,
  commonSidebarFormSectionLayout,
} from '../../../sidebar/commonSidebarFormConstants.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';
import { useResentEnrollmentEmail } from './useResentEnrollmentEmail.js';
import { getUserFormSections } from './sections/userFormSections.js';
import { prepareStaffUserFormDataForMutation } from './utils.js';
import { staffUserToEditForm } from './staffUserToEditForm.js';
import { useUserHasPermissionToLoginViaSms } from './useUserHasPermissionToLoginViaSms.js';

// canSendEnrollmentEmail :: UpdateUserMutationResult -> Boolean
const canSendEnrollmentEmail = R.allPass([
  R.pathEq(['data', 'updateUserWithUserGroups', 'isSignedUp'], false),
  R.pathEq(['data', 'updateUserWithUserGroups', 'status'], UserStatuses.ACTIVE),
]);

const UPDATE_USER_WITH_USER_GROUPS_MUTATION = gql`
  mutation UPDATE_USER_WITH_USER_GROUPS_MUTATION(
    $input: UpdateUserWithUserGroupsInput!
  ) {
    updateUserWithUserGroups(update: $input) {
      _id
      isSignedUp
      status
    }
  }
`;

export function EditStaffUserForm({
  formId,
  staffUser,
  onMutationSuccess,
  isCardLayout = true,
}) {
  const { showSuccessNotification } = useNotificationState();
  const resendEnrollmentEmail = useResentEnrollmentEmail();
  const [updateUser] = useMutation(UPDATE_USER_WITH_USER_GROUPS_MUTATION);
  const pristineSubscribeProps = usePristineSubscribe({
    id: formId,
    ...staffUser,
  });
  const updateQueryParams = useUpdateQueryParams();

  const hasPermissionToLoginViaSMS = useUserHasPermissionToLoginViaSms(
    staffUser?._id,
  );

  const onSubmitHandler = async (formData) => {
    const update = prepareStaffUserFormDataForMutation(formData);

    const mutationResult = await updateUser({
      variables: {
        input: {
          userId: staffUser._id,
          ...update,
        },
      },
    });

    if (canSendEnrollmentEmail(mutationResult)) {
      resendEnrollmentEmail(staffUser._id);
    }

    showSuccessNotification('User was successfully updated');

    if (onMutationSuccess) {
      onMutationSuccess();
    } else {
      updateQueryParams({ isEdit: false });
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const formProps = {
    onSubmit,
    id: formId,
    initialValues: staffUserToEditForm(staffUser),
    sections: getUserFormSections({
      isEdit: true,
      isCardLayout,
      isContractor: staffUser.isContractor,
      hasPermissionToLoginViaSMS,
    }),
    ...pristineSubscribeProps,
    ...(isCardLayout
      ? {}
      : {
          layout: commonSidebarFormLayout,
          fieldLayout: commonSidebarFormFieldLayout,
          sectionLayout: commonSidebarFormSectionLayout,
        }),
  };

  const FormComponent = isCardLayout ? FormPage : FormCreator;

  return <FormComponent {...formProps} />;
}

EditStaffUserForm.displayName = 'EditStaffUserForm';
EditStaffUserForm.propTypes = {
  isCardLayout: bool,
  onMutationSuccess: func,
  formId: string.isRequired,
  staffUser: shape({ _id: string.isRequired }).isRequired,
};
