import React from 'react';
import * as R from 'ramda';
import { LinkButton } from 'poly-book-admin';
import { bool } from 'prop-types';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
  pathOrNothingUI,
} from 'poly-client-utils';
import {
  EXPORT_XLS_CAPTION,
  InvoicesStatuses,
  NOTHING_UI_STRING,
} from 'poly-constants';
import {
  formatDate,
  getUIInvoiceNumber,
  convertCentsToDollars,
} from 'poly-utils';

import { searchSupplierInvoicesDataPropType } from './propTypes.js';
import { invoiceStatusToStringMap } from '../../modules/tables/columns/invoices.js';

// getTableRowByInvoice :: Invoice -> [ExcelExportDataCell]
const getTableRowByInvoice = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 4),
    ExcelExportCellType.defaultCurrency,
    ...R.repeat(ExcelExportCellType.default, 3),
  ]),
  R.juxt([
    getUIInvoiceNumber,
    R.path(['project', 'projectId']),
    R.path(['supplier', 'company', 'name']),
    R.compose(formatDate, R.prop('invoiceDate')),
    R.ifElse(
      R.propEq('status', InvoicesStatuses.VOIDED),
      R.always(NOTHING_UI_STRING),
      R.compose(convertCentsToDollars, R.prop('total')),
    ),
    pathOrNothingUI(['paidBy', 'fullName']),
    R.compose(
      R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), formatDate),
      R.prop('paidAt'),
    ),
    R.compose(R.prop(R.__, invoiceStatusToStringMap), R.prop('status')),
  ]),
);

// getInvoicesRowsByData :: QueryData -> [[ExcelExportDataCell]]
const getInvoicesRowsByData = R.compose(
  R.map(getTableRowByInvoice),
  R.pathOr([], ['searchInvoices', 'hits']),
);
// eslint-disable-next-line import/no-unused-modules
export const getSearchInvoicesExportXlsConfig = (data) => ({
  exportFileName: 'search-supplier-invoices.xlsx',
  columnWidths: [15, 15, 35, 15, 20, 20, 15, 15],
  rows: [
    [
      {
        value: 'Search Supplier Invoices',
        cellType: ExcelExportCellType.title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 4),
        ExcelExportCellType.tableCurrencyHeader,
        ...R.repeat(ExcelExportCellType.tableHeader, 3),
      ],
      [
        'Invoice #',
        'Project #',
        'Supplier',
        'Inv Date',
        'Amount',
        'Paid By',
        'Paid Date',
        'Status',
      ],
    ),

    ...getInvoicesRowsByData(data),
  ],
});

export function SupplierInvoicesExportXlsBtn({ data, disabled }) {
  const onClick = async () => {
    const config = getSearchInvoicesExportXlsConfig(data);
    await performExcelExport(config);
  };

  return (
    <LinkButton onClick={onClick} disabled={disabled}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

SupplierInvoicesExportXlsBtn.propTypes = {
  data: searchSupplierInvoicesDataPropType,
  disabled: bool.isRequired,
};
