export const WorkOrderPriority = {
  CUSTOM: 'a_customPriority',
  PLANNED: 'b_plannedPriority',
  NORMAL: 'c_normalPriority',
  URGENT: 'd_urgentPriority',
  EMERGENCY: 'e_emergencyPriority',
};

export const ProjectPrioritiesUIMap = {
  [WorkOrderPriority.EMERGENCY]: 'Emergency',
  [WorkOrderPriority.PLANNED]: 'Planned',
  [WorkOrderPriority.URGENT]: 'Urgent',
  [WorkOrderPriority.NORMAL]: 'Normal',
  [WorkOrderPriority.CUSTOM]: 'Custom',
};

export const DefaultProjectPrioritiesHours = {
  [WorkOrderPriority.PLANNED]: 10 * 24,
  [WorkOrderPriority.NORMAL]: 5 * 24,
  [WorkOrderPriority.EMERGENCY]: 4,
  [WorkOrderPriority.URGENT]: 24,
};

export const DefaultProjectPrioritiesHoursMap = [
  { priority: WorkOrderPriority.PLANNED, hours: 10 * 24 },
  { priority: WorkOrderPriority.NORMAL, hours: 5 * 24 },
  { priority: WorkOrderPriority.EMERGENCY, hours: 4 },
  { priority: WorkOrderPriority.URGENT, hours: 24 },
];
