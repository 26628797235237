import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { WorkOrderStatus } from 'poly-constants';

import { recentProjectsPageTabs } from '../../routes/constants.js';
import { RecentProjectsCommonTab } from './projectsTab/ProjectsTab.js';
import { RecentProjectsTablePlaceholder } from '../tables/recentProjectsTable/RecentProjectsTablePlaceholder.js';
import { generateRecentProjectsQuery } from '../tables/recentProjectsTable/generateRecentProjectsQuery.js';

const { recentProjectsActive } = recentProjectsPageTabs;

export const recentProjectsStatus = R.cond([
  [R.equals(recentProjectsActive), R.always(WorkOrderStatus.ACTIVE)],
  [R.T, R.always(WorkOrderStatus.COMPLETED)],
]);

export function RecentProjectsTab(props) {
  const recentProjects = useSelector((state) => state.recentProjects);

  const status = recentProjectsStatus(props.tabName);

  const isEmpty =
    !recentProjects.clientId ||
    !recentProjects.startDate ||
    !recentProjects.endDate;

  if (isEmpty) return <RecentProjectsTablePlaceholder />;

  const query = generateRecentProjectsQuery({ ...recentProjects, status });

  return (
    <RecentProjectsCommonTab
      {...props}
      query={query}
      additionalProps={{ status }}
    />
  );
}

RecentProjectsTab.displayName = 'RecentProjectsTab';

RecentProjectsTab.propTypes = {
  tabName: string,
};
