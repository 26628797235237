import React from 'react';
import { string, bool, shape } from 'prop-types';
import { SidebarWidth } from 'poly-admin-ui';

import { SidebarIDs } from '../../constants.js';
import { clientTabsSidebarId } from '../constants.js';
import { SidebarTabs } from '../../components/SidebarTabs.js';
import { clientSidebarTabs } from '../../../routes/constants.js';
import { ClientSidebarTasksTab } from './ClientSidebarTasksTab.js';
import { ClientSidebarFilesTab } from './ClientSidebarFilesTab.js';
import { ClientSidebarUpdatesTab } from './ClientSidebarUpdatesTab.js';
import { ClientSidebarMastersTab } from './ClientSidebarMastersTab.js';
import { ClientSidebarProjectsTab } from './ClientSidebarProjectsTab.js';
import { ClientSidebarChildrenTab } from './ClientSidebarChildrenTab.js';
import { ClientSidebarContactsTab } from './ClientSidebarContactsTab.js';
import { ClientSidebarPropertiesTab } from './ClientSidebarProperties/ClientSidebarPropertiesTab.js';
import { ClientSidebarPortalUsersTab } from './ClientSidebarPortalUsersTab.js';
import { getClientTabsWidth } from './clientSidebarTabsUtils.js';
import {
  isAssetScannerAppEnabled,
  isClientPortalAppEnabled,
} from '../forms/form/sections/configsSection.js';

export function ClientSidebarTabs({
  client,
  showPortal,
  clientName,
  showChildren,
  customTabQueryKey,
  isCard = false,
}) {
  const clientId = client._id;
  const doubleTabWidth =
    getClientTabsWidth(showChildren, showPortal) + SidebarWidth;

  const isAssetScannerEnabled = isAssetScannerAppEnabled(client);
  const isClientPortalEnabled = isClientPortalAppEnabled(client);

  return (
    <SidebarTabs
      customTabQueryKey={customTabQueryKey}
      hideCloseIcon={isCard}
      id={clientTabsSidebarId}
      params={[SidebarIDs.client]}
      defaultValue={clientSidebarTabs.clientUpdates}
      isCard={isCard}
      tabs={[
        ...(showChildren
          ? [
              [
                'Children',
                clientSidebarTabs.clientChildAccounts,
                <ClientSidebarChildrenTab
                  {...{ doubleTabWidth, clientId, isCard }}
                />,
              ],
            ]
          : []),
        [
          'Properties',
          clientSidebarTabs.clientProperties,
          <ClientSidebarPropertiesTab
            isClientProperties
            title="Client Properties"
            {...{ doubleTabWidth, clientId, isCard }}
          />,
        ],
        [
          'Projects',
          clientSidebarTabs.clientProjects,
          <ClientSidebarProjectsTab {...{ doubleTabWidth, client, isCard }} />,
        ],
        [
          'Masters',
          clientSidebarTabs.clientMasters,
          <ClientSidebarMastersTab
            {...{ doubleTabWidth, client, clientId, isCard }}
          />,
        ],
        [
          'Tasks',
          clientSidebarTabs.clientTasks,
          <ClientSidebarTasksTab {...{ clientId, clientName }} />,
        ],
        [
          'Updates',
          clientSidebarTabs.clientUpdates,
          <ClientSidebarUpdatesTab {...{ clientId, clientName }} />,
        ],
        [
          'Contacts',
          clientSidebarTabs.clientContacts,
          <ClientSidebarContactsTab
            {...{ doubleTabWidth, clientId, isCard }}
          />,
        ],
        [
          'Files',
          clientSidebarTabs.clientFiles,
          <ClientSidebarFilesTab
            key={clientSidebarTabs.clientFiles}
            clientId={clientId}
          />,
        ],
        ...(showPortal
          ? [
              [
                'Portal Users',
                clientSidebarTabs.clientPortalUsers,
                <ClientSidebarPortalUsersTab
                  {...{
                    doubleTabWidth,
                    clientId,
                    isCard,
                    isAssetScannerEnabled,
                    isClientPortalEnabled,
                  }}
                />,
              ],
            ]
          : []),
      ]}
    />
  );
}

ClientSidebarTabs.propTypes = {
  isCard: bool,
  showPortal: bool,
  showChildren: bool,
  client: shape({ _id: string.isRequired }).isRequired,
  clientName: string.isRequired,
  customTabQueryKey: string,
};
