import React, { useEffect } from 'react';
import * as R from 'ramda';
import { Loader } from 'poly-book-admin/src/Loader/index.js';
import {
  SidebarRow,
  SidebarWrapper,
} from 'poly-admin-ui/src/sidebar/sidebarComponents.js';
import { shape, string } from 'prop-types';
import { FlexContainer } from 'poly-admin-ui/src/components/common.js';
import { EntityStatus } from 'poly-book-admin/src/Status/index.js';
import { Text } from 'poly-book-admin/src/Text/index.js';
import { formatDateOrNothingUI } from 'poly-client-utils/src/dates.js';
import { centsToDollarsWithFormat } from 'poly-utils/src/converters.js';
import { LinkButton } from 'poly-book-admin/src/LinkButton/index.js';
import styled from 'styled-components';
import { isNilOrEmpty } from 'poly-utils/src/general.js';
import { EmailLink } from 'poly-admin-ui/src/components/Links.js';
import { ESFM_INFO } from 'poly-constants/src/aanthonycorp.js';
import { useOutSidebarContext } from 'poly-client-utils/src/slideOutSidebar.js';
import { NOTHING_UI_STRING } from 'poly-constants';

import { usePurchaseOrderDetail } from './usePurchaseOrderDetail.js';
import { NotFoundEntity } from '../../components/NotFoundEntity.js';
import {
  BlockWithLabel,
  SectionLabel,
  SectionWrapper,
  SidebarDetailsLinks,
  SidebarLabel,
} from '../components/commonSidebarComponents.js';
import { OpenFullInfoSidebarButton } from '../components/OpenFullInfoSidebarButton.js';
import { EntityRemarks } from '../components/commonSidebarDetailsList.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import { ContactLink } from '../ContactSidebar/useOpenContactSidebar.js';
import { PropertyLink } from '../PropertySidebar/useOpenPropertySidebar.js';
import {
  purchaseOrderStatusColors,
  purchaseOrderTabsSidebarId,
} from './constants.js';
import {
  getPurchaseOrdersStatusUI,
  getPurchaseOrdersTypeUI,
  useHasUserAccessToEditAdminPO,
} from './utils.js';
import { useOpenPurchaseOrderTabsSidebar } from './tabs/useOpenPurchaseOrderTabsSidebar.js';
import { SidebarIDs } from '../constants.js';
import { useEditPurchaseOrderSidebar } from './form/useEditPurchaseOrderSidebar.js';

const BlockWithLabelS = styled(BlockWithLabel)`
  div {
    width: 100%;
    a:first-child {
      display: block;
      word-break: break-all;
      white-space: normal;
    }
  }
`;

function PurchaseOrderSidebarHeader({
  displayName,
  status,
  poNumber,
  ...props
}) {
  const openPurchaseOrderTabs = useOpenPurchaseOrderTabsSidebar();

  const { isSidebarOpened } = useOutSidebarContext();

  useEffect(() => {
    if (!isSidebarOpened(purchaseOrderTabsSidebarId)) {
      openPurchaseOrderTabs({
        displayName,
        status,
        poNumber,
        ...props,
      });
    }
  }, []);

  return (
    <SectionWrapper>
      <SidebarRow justify align>
        <FlexContainer>
          <SidebarLabel margin={10}>{displayName}</SidebarLabel>
          <EntityStatus
            title="PO"
            status={{
              text: getPurchaseOrdersStatusUI(status),
              color: purchaseOrderStatusColors[status],
            }}
          />
        </FlexContainer>
        <OpenFullInfoSidebarButton
          sidebarId={SidebarIDs.purchaseOrder}
          fullSidebarId={purchaseOrderTabsSidebarId}
          openSidebarHandler={() =>
            openPurchaseOrderTabs({ displayName, ...props })
          }
        />
      </SidebarRow>
      <SidebarRow>
        <Text>{poNumber}</Text>
      </SidebarRow>
    </SectionWrapper>
  );
}

PurchaseOrderSidebarHeader.propTypes = {
  displayName: string,
  status: string,
  poNumber: string,
};

// getClientReferences :: PurchaseOrder -> _ -> ReactNode
const getClientReferences = (purchaseOrder) =>
  function (props) {
    return <SidebarDetailsLinks {...props} {...purchaseOrder} />;
  };

// getAuthorizedReferences :: PurchaseOrder -> _ -> ReactNode
const getAuthorizedReferences = (purchaseOrder) =>
  function (props) {
    return <ContactLink {...props} {...purchaseOrder?.authorizedBy} />;
  };

// formatDateByProp :: (String, PurchaseOrder) -> String
const formatDateByProp = (propName, po) =>
  R.compose(formatDateOrNothingUI, R.prop(propName))(po);

// centsToDollarsByProp :: (String, PurchaseOrder) -> String
const centsToDollarsByProp = (propName, po) =>
  R.compose(centsToDollarsWithFormat, R.prop(propName))(po);

// getPurchaseOrderLowBalance :: PurchaseOrder -> String
const getPurchaseOrderLowBalance = R.ifElse(
  R.prop('lowBalancePercent'),
  R.compose(R.concat(R.__, ' %'), R.toString, R.prop('lowBalancePercent')),
  R.always(NOTHING_UI_STRING),
);

const PropertyLinkS = styled(PropertyLink)`
  margin-top: 7px;
`;

// getPropertiesReferences :: PurchaseOrder -> _ -> ReactNode
const getPropertiesReferences = ({ properties }) =>
  function () {
    if (!properties) {
      return null;
    }
    return (
      <>
        {properties.map((property) => (
          <PropertyLinkS key={property._id} {...property} />
        ))}
      </>
    );
  };

// getNotificationContactsEmails :: PurchaseOrder  -> _ -> ReactNode
const getNotificationContactsEmails = R.compose(
  R.reject(isNilOrEmpty),
  R.unnest,
  R.juxt([
    R.path(['client', 'clientGroupEmail']),
    R.propOr([], 'notificationContactsEmails'),
    R.always(ESFM_INFO.email),
  ]),
);

// getNotificationContactsSection :: PurchaseOrder -> _ -> ReactNode
const getNotificationContactsSection = (purchaseOrder) =>
  function (props) {
    const emails = getNotificationContactsEmails(purchaseOrder);
    return (
      <>
        {emails.map((email) => (
          <EmailLink key={email} email={email} {...props} />
        ))}
      </>
    );
  };

function PurchaseOrderSidebarDetails({ purchaseOrder }) {
  const hasAccessToEditPO = useHasUserAccessToEditAdminPO();

  const openEditPOSidebar = useEditPurchaseOrderSidebar();

  return (
    <SectionWrapper>
      <SidebarRow justify>
        <SectionLabel>PO Details</SectionLabel>
        {hasAccessToEditPO && (
          <LinkButton onClick={() => openEditPOSidebar(purchaseOrder)}>
            Edit
          </LinkButton>
        )}
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabelS
          margin="0"
          id="client"
          width="calc(33% - 12px)"
          label="Client"
          Component={getClientReferences(purchaseOrder)}
        />
        <BlockWithLabelS
          margin="0"
          id="authorized"
          width="calc(33% - 12px)"
          label="Authorized By"
          Component={getAuthorizedReferences(purchaseOrder)}
        />
        <BlockWithLabelS
          margin="0"
          id="po-type"
          width="calc(33% - 12px)"
          label="PO Type"
          Component={getSectionText(getPurchaseOrdersTypeUI(purchaseOrder))}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabelS
          margin="0"
          id="start-date"
          width="calc(33% - 12px)"
          label="Start Date"
          Component={getSectionText(
            formatDateByProp('startDate', purchaseOrder),
          )}
        />
        <BlockWithLabelS
          margin="0"
          id="end-date"
          width="calc(33% - 12px)"
          label="End Date"
          Component={getSectionText(formatDateByProp('endDate', purchaseOrder))}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabelS
          margin="0"
          id="initial-balance"
          width="calc(33% - 12px)"
          label="Initial Balance"
          Component={getSectionText(
            centsToDollarsByProp('initialBalance', purchaseOrder),
          )}
        />
        <BlockWithLabelS
          margin="0"
          id="current-balance"
          width="calc(33% - 12px)"
          label="Current Balance"
          Component={getSectionText(
            centsToDollarsByProp('currentBalance', purchaseOrder),
          )}
        />
        <BlockWithLabelS
          margin="0"
          id="low-balance"
          width="calc(33% - 12px)"
          label="Low Balance %"
          Component={getSectionText(getPurchaseOrderLowBalance(purchaseOrder))}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabelS
          margin="0"
          id="notification-contacts"
          width="100%"
          label="Notification Contacts"
          Component={getNotificationContactsSection(purchaseOrder)}
        />
      </SidebarRow>
      {!isNilOrEmpty(purchaseOrder?.properties) && (
        <SidebarRow>
          <BlockWithLabelS
            margin="0"
            id="properties"
            width="100%"
            label="Properties"
            Component={getPropertiesReferences(purchaseOrder)}
          />
        </SidebarRow>
      )}
    </SectionWrapper>
  );
}

PurchaseOrderSidebarDetails.propTypes = {
  purchaseOrder: shape({}),
};

export function PurchaseOrderSidebar({ purchaseOrderId }) {
  const { purchaseOrder, loading } = usePurchaseOrderDetail({
    purchaseOrderId,
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <SidebarWrapper>
      {!purchaseOrder ? (
        <NotFoundEntity entityName="PO" />
      ) : (
        <>
          <PurchaseOrderSidebarHeader {...purchaseOrder} />
          <PurchaseOrderSidebarDetails purchaseOrder={purchaseOrder} />
          <EntityRemarks entity={purchaseOrder} withBorder />
        </>
      )}
    </SidebarWrapper>
  );
}

PurchaseOrderSidebar.propTypes = {
  purchaseOrderId: string,
};
