import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { gql } from '@apollo/client';
import { assocBy, isNilOrEmpty, removePropDeep } from 'poly-utils';

import { useCurrentUserQuery } from './useCurrentUserQuery.js';

export const CURRENT_USER_WITH_SETTINGS_QUERY = gql`
  query USER_SETTINGS {
    user {
      _id
      fullName
      firstName
      accessItems {
        permission
        types {
          clientId {
            equals
            flippedContains
          }
          userGroupId {
            equals
            flippedContains
          }
          privateSupplier {
            equals
          }
        }
      }
      settings {
        notifications {
          updateCommentEmail
        }
        links {
          openProjectInNewTab
        }
      }
      twoFactorAuth {
        isVerified
        createdAt
      }
      userGroups {
        userGroup {
          _id
          name
          isTwoFactorAuthRequired
        }
      }
    }
  }
`;

// cleanupUserAccessItemTypes :: UserGroupAccessItem -> UserGroupAccessItem
const cleanupUserAccessItemTypes = assocBy(
  'types',
  R.compose(
    R.unless(R.isEmpty, R.map(R.reject(R.isNil))),
    R.reject(R.isNil),
    R.propOr({}, 'types'),
  ),
);

// cleanupUserAccessItems :: User -> User
const cleanupUserAccessItems = assocBy(
  'accessItems',
  R.compose(R.map(cleanupUserAccessItemTypes), R.propOr([], 'accessItems')),
);

// prepareUser :: {user: User} -> User
const prepareUser = R.compose(
  R.unless(
    isNilOrEmpty,
    R.compose(cleanupUserAccessItems, removePropDeep('__typename')),
  ),
  R.prop('user'),
);

export const useCurrentUserByStoreOrQuery = (query, queryOptions = {}) => {
  const currentUser = useSelector((state) => state?.currentUser);

  const { user, loading } = useCurrentUserQuery(
    query || CURRENT_USER_WITH_SETTINGS_QUERY,
    {
      ...queryOptions,
      skip: currentUser?.user || queryOptions.skip || currentUser?.isLoading,
    },
  );

  return {
    user: prepareUser(currentUser || { user }),
    loading: currentUser?.isLoading || loading,
  };
};
