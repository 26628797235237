import React from 'react';
import styled from 'styled-components';
import { Pagination } from 'poly-book-admin';

import { usePagination } from '../redux/pagination.js';

export const PaginationS = styled(Pagination)`
  width: 100%;
  margin: 25px 0;
`;

export function TablePagination(props) {
  const { pagination, setPagination } = usePagination();

  const paginationProps = {
    onChange: setPagination,
    ...pagination,
    ...props,
  };

  return <PaginationS {...paginationProps} />;
}

TablePagination.displayName = 'TablePagination';
