import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { string } from 'prop-types';
import {
  formatBackLink,
  Link,
  useLocation,
  useRouterQuery,
} from 'poly-client-routing';
import { getThemeColor, IconButton, MainHeader, Text } from 'poly-book-admin';

export const SectionWithBackBtn = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
`;

export const HeaderTitle = styled(MainHeader)`
  color: ${getThemeColor(['primaryLighter2'])};
  margin-right: 40px;
`;

HeaderTitle.displayName = 'PageHeaderTitle';

const LinkS = styled(Link)`
  ${({ isFileTab }) =>
    isFileTab
      ? css`
          padding-right: 5px;
        `
      : css`
          position: absolute;
          left: -20px;
        `}
`;

export function BackBtn({ fallbackLink }) {
  const location = useLocation();
  const { tab = '' } = useRouterQuery(['tab']);
  const isFileTab = R.includes('-files', tab);

  const href = formatBackLink({
    router: location.state,
    withQuery: true,
    fallbackLink,
  });
  return (
    <LinkS href={href} isFileTab={isFileTab}>
      <IconButton
        onClick={R.T}
        name="arrow-back"
        size={14}
        initialColor="rgba(30, 36, 44, 0.45)"
      />
    </LinkS>
  );
}

BackBtn.propTypes = {
  fallbackLink: string,
};
const BtnWrapper = styled.div`
  height: 100%;
  padding-bottom: 5px;
`;

export function TitleWithBackButton({ title }) {
  return (
    <SectionWithBackBtn>
      <BtnWrapper>
        <BackBtn />
      </BtnWrapper>
      <Text>{title}</Text>
    </SectionWithBackBtn>
  );
}
TitleWithBackButton.propTypes = {
  title: string.isRequired,
};
