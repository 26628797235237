import { useRef, useEffect, useCallback } from 'react';
import { debounce } from 'poly-utils';

export const useScrollIntoViewByRef = (allowScroll = true) => {
  const ref = useRef(null);

  const debouncedScrollIntoView = useCallback(
    debounce(200)(() => {
      if (!!ref && !!ref.current && !!ref.current.scrollIntoView) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }),
    [],
  );

  useEffect(() => {
    if (allowScroll) {
      debouncedScrollIntoView();
    }
  }, [ref, allowScroll]);

  return { ref };
};
