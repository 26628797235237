import React from 'react';
import { string } from 'prop-types';
import { filesCollections } from 'poly-constants';
import { SidebarFilesTab } from 'poly-admin-ui';

export function PurchaseOrderSidebarFilesTab({ _id, tabTitlePrefix }) {
  const tabProps = {
    tabTitlePrefix,
    collection: filesCollections.ADMIN_PURCHASE_ORDERS,
    entity: { _id, preview: 'Purchase Order' },
  };

  return <SidebarFilesTab {...tabProps} />;
}

PurchaseOrderSidebarFilesTab.propTypes = {
  _id: string.isRequired,
  tabTitlePrefix: string,
};
