import React from 'react';
import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Form } from 'react-final-form';
import { FormFieldLabel, TextButton } from 'poly-book-admin';
import { func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { useModalContext, useNotificationState } from 'poly-admin-ui';

import {
  HousekeepingCalendarLine,
  LineWrapper,
} from './HousekeepingCalendarLine.js';
import {
  getHousekeepingCalendarYearsWeeks,
  housekeepingCalendarPropTypes,
} from './HousekeepingCalendarList.js';

const FormFieldLabelS = styled(FormFieldLabel)`
  margin-right: 20px;
  width: ${R.prop('width')};
`;

const AddBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// eslint-disable-next-line import/no-unused-modules
export const updateHousekeepingCalendarMutation = gql`
  mutation UPDATE_HOUSEKEEPING_CALENDAR(
    $input: UpdateClientHousekeepingCalendarInput!
  ) {
    updateClientHousekeepingCalendar(input: $input) {
      _id
    }
  }
`;

// prepareHousekeepingCalendarInitialValues :: HousekeepingCalendar -> HousekeepingCalendar
// HousekeepingCalendar = [{startOfYear : Date, description: String}]
const prepareHousekeepingCalendarInitialValues = R.compose(
  R.objOf('housekeepingCalendar'),
  R.map(R.mergeAll),
  R.converge(R.zip, [
    R.map(R.omit(['__typename'])),
    R.compose(R.map(R.objOf('weeks')), getHousekeepingCalendarYearsWeeks),
  ]),
);

// prepareHousekeepingCalendarBeforeMutation :: FormData -> HousekeepingCalendar
const prepareHousekeepingCalendarBeforeMutation = R.compose(
  R.map(R.omit(['weeks'])),
  R.propOr([], 'housekeepingCalendar'),
);

export const editHousekeepingCalendarFormId = 'editHousekeepingCalendarFormId';

function HousekeepingCalendarFormComp({
  handleSubmit,
  form: {
    mutators: { push },
  },
}) {
  return (
    <form onSubmit={handleSubmit} id={editHousekeepingCalendarFormId}>
      <LineWrapper>
        <FormFieldLabelS width="37%">Start Of Year</FormFieldLabelS>
        <FormFieldLabelS width="36%">Description</FormFieldLabelS>
        <FormFieldLabelS width="15%">Weeks</FormFieldLabelS>
      </LineWrapper>

      <FieldArray name="housekeepingCalendar">
        {({ fields }) =>
          fields.map((name, index) => (
            <HousekeepingCalendarLine
              key={name}
              name={name}
              index={index}
              value={R.pathOr({}, ['value', index], fields)}
              remove={fields.remove}
              update={fields.update}
              values={R.propOr([], 'value', fields)}
            />
          ))
        }
      </FieldArray>

      <AddBtnWrapper>
        <TextButton
          data-testid="add-new-year"
          onClick={() => push('housekeepingCalendar', undefined)}
        >
          Add
        </TextButton>
      </AddBtnWrapper>
    </form>
  );
}

HousekeepingCalendarFormComp.propTypes = {
  handleSubmit: func.isRequired,
  form: shape({
    mutators: shape({
      push: func.isRequired,
    }),
  }),
};

export function HousekeepingCalendarForm({ clientId, housekeepingCalendar }) {
  const [updateHousekeepingCalendar] = useMutation(
    updateHousekeepingCalendarMutation,
  );

  const { closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();

  const onSubmit = async (values) => {
    await updateHousekeepingCalendar({
      variables: {
        input: {
          housekeepingCalendar:
            prepareHousekeepingCalendarBeforeMutation(values),
          clientId,
        },
      },
    });
    closeModal(editHousekeepingCalendarFormId);
    showSuccessNotification('Housekeeping Calendar was successfully edited');
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={prepareHousekeepingCalendarInitialValues(
        housekeepingCalendar,
      )}
      validateOnBlur
      mutators={{ ...arrayMutators }}
      render={HousekeepingCalendarFormComp}
    />
  );
}

HousekeepingCalendarForm.propTypes = {
  clientId: string,
  housekeepingCalendar: housekeepingCalendarPropTypes,
};
