import React from 'react';
import styled from 'styled-components';
import { func, shape, string } from 'prop-types';
import { AccountingStatus, ProjectType } from 'poly-constants';
import { pathOrNothingUI } from 'poly-client-utils';
import { LinkButton } from 'poly-book-admin';
import {
  TooltipDefaultButtons,
  useNotificationState,
  TooltipConfirmText,
  TooltipButton,
} from 'poly-admin-ui';

import { useAddProjectSidebar } from '../forms/add/useAddProjectSidebar.js';

const TooltipWrapper = styled(LinkButton).attrs({ withLine: true })`
  position: relative;
`;

function RecallButtonTooltip({ _id, closeTooltip }) {
  const openAddProjectSidebar = useAddProjectSidebar(true);

  const tooltipText =
    'Project has been invoiced. \nWould you like to clone this project? ';

  const onCloneClick = (e) => {
    closeTooltip(e);
    openAddProjectSidebar({
      cloneFromProjectId: _id,
      cloneWithoutSuppliers: false,
    });
  };

  return (
    <>
      <TooltipConfirmText>{tooltipText}</TooltipConfirmText>
      <TooltipDefaultButtons onConfirm={onCloneClick} onCancel={closeTooltip} />
    </>
  );
}

RecallButtonTooltip.propTypes = {
  _id: string.isRequired,
  closeTooltip: func.isRequired,
};

export function RecallProjectButton({ project, setRecallForm }) {
  const { showWarningNotification } = useNotificationState();

  const { _id, parent, type } = project;
  const accountingStatus = pathOrNothingUI(['accountingStatus'])(project);

  const isHousekeeping = type === ProjectType.HOUSEKEEPING;

  const onRecallClick = () => {
    if (
      accountingStatus === AccountingStatus.INVOICED &&
      isHousekeeping &&
      !!parent
    ) {
      return showWarningNotification('You can not recall an Invoiced Project');
    }
    return setRecallForm(true);
  };

  return accountingStatus === AccountingStatus.INVOICED ? (
    <TooltipButton
      Wrapper={TooltipWrapper}
      tooltipBtnProps={{ _id }}
      Tooltip={RecallButtonTooltip}
    >
      Recall
    </TooltipButton>
  ) : (
    <LinkButton onClick={onRecallClick}>Recall</LinkButton>
  );
}

RecallProjectButton.propTypes = {
  setRecallForm: func.isRequired,
  project: shape({
    _id: string.isRequired,
    type: string.isRequired,
    accountingStatus: string,
    parent: shape({ _id: string.isRequired }),
  }),
};
