import * as R from 'ramda';
import React from 'react';
import { entities } from 'poly-admin-ui';
import { gql, useQuery } from '@apollo/client';
import { bool, func, string } from 'prop-types';

import { Select } from 'poly-book-admin/src/Select/Select.js';
import {
  useQueryWithSearch,
  entityToOptionByLabelPath,
} from 'poly-client-utils/src/select.js';

const ASSETS_BY_SEARCH = gql`
  query ASSETS_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchAssets(input: $searchInput) {
      hits {
        _id
        displayName
      }
    }
  }
`;

const ASSET_DEFAULT_OPTION_QUERY = gql`
  query ASSET_DEFAULT_OPTION_QUERY($id: ID!) {
    asset(id: $id) {
      _id
      displayName
    }
  }
`;

export const ASSETS_BY_SEARCH_SUB = gql`
  subscription ASSETS_BY_SEARCH_SUB($input: CollectionSearchParams!) {
    searchAssetChanged(input: $input) {
      id
      type
    }
  }
`;

// prepareOptions :: Input -> [SelectOption]
// SelectOption = {
//    value: ID
//    label: String
// }
// Input = {
//    asset: Asset
//    searchAssets: { hits: [Asset] }
// }
const prepareOptions = R.compose(
  R.map(entityToOptionByLabelPath(['displayName'])),
  R.reject(R.isNil),
  R.flatten,
  R.juxt([R.prop('asset'), R.pathOr([], ['searchAssets', 'hits'])]),
);

export function AssetSelect({
  isClearable = true,
  includeAllOption = false,
  name = 'AssetSelect',
  placeholder = 'Start typing asset',
  filterOption = () => true,
  getQuery = () => null,
  getSkipQuery = () => null,
  ...restProps
}) {
  const { value, onChange } = restProps;

  const {
    result,
    error,
    onSearchChange,
    loading: assetsLoading,
  } = useQueryWithSearch({
    gqlSearchQuery: ASSETS_BY_SEARCH,
    gqlSearchChangedQuery: ASSETS_BY_SEARCH_SUB,
    query: getQuery(restProps),
    skipQuery: getSkipQuery(restProps),
    entity: entities.ASSET,
    propsOfComponent: {
      name,
      placeholder,
      isClearable,
      filterOption,
      includeAllOption,
      ...restProps,
    },
  });

  const { data, loading: defaultAssetLoading } = useQuery(
    ASSET_DEFAULT_OPTION_QUERY,
    {
      variables: { id: value },
      skip: !value,
    },
  );

  const entityProps = { ...data, ...result };

  const selectProps = {
    ...restProps,
    ...entityProps,
    error,
    loading: assetsLoading || defaultAssetLoading,
    onChange,
    onInputChange: onSearchChange,
    options: prepareOptions(entityProps),
  };

  return <Select {...selectProps} />;
}

AssetSelect.propTypes = {
  name: string,
  getQuery: func,
  placeholder: string,
  isClearable: bool,
  getSkipQuery: func,
  filterOption: func,
  includeAllOption: bool,
};
