import * as R from 'ramda';
import { isSameDay } from 'date-fns';
import { ensureIsDate, formatDate, isValidDate } from 'poly-utils';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// getDefaultNavBarText :: Date -> String
export const getDefaultNavBarText = (currentDate) =>
  `${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;

// getModifiers :: SelectedDays -> Modifiers
// SelectedDays = [Date, { from: Date, to: Date }]
export const getModifiers = (selectedDays) => ({
  outPoint: (day) =>
    isSameDay(ensureIsDate(day), ensureIsDate(selectedDays[1].from)) ||
    isSameDay(ensureIsDate(day), ensureIsDate(selectedDays[1].to)),
});

/**
 * toDate :: Value -> Date
 */
export const toDate = R.when(R.identity, R.constructN(1, Date));

/**
 * toString :: Date -> String
 */
export const toString = R.ifElse(isValidDate, formatDate, R.always(''));

/**
 * modifyStringToDate :: String || ObjectOfStrings -> Date || ObjectOfDates
 */
export const modifyStringToDate = R.ifElse(
  R.both(R.complement(R.isNil), R.has('startDate')),
  R.map(toDate),
  toDate,
);

/**
 * modifyDateToString :: Date || ObjectOfDates -> String || ObjectOfStrings
 */
export const modifyDateToString = R.ifElse(
  R.has('startDate'),
  R.map(toString),
  toString,
);

/**
 * modifyInputValue :: String || ObjectOfStrings -> String
 */
export const modifyInputValue = R.when(
  R.has('startDate'),
  R.pipe(
    R.values,
    R.reject(R.anyPass([R.isNil, R.isEmpty])),
    R.ifElse(R.propEq('length', 2), R.join(' - '), R.always('')),
  ),
);

/**
 * isValueEmpty :: String || ObjectOfStrings -> Boolean
 */
export const isValueEmpty = R.ifElse(
  R.has('startDate'),
  R.pipe(R.reject(R.anyPass([R.isNil, R.isEmpty])), R.isEmpty),
  R.anyPass([R.isNil, R.isEmpty]),
);
