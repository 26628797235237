import React from 'react';
import { DefaultBodyWrapper } from 'poly-book-admin';

import { CreateJournalEntryForm } from './JournalEntryForm/CreateJournalEntryForm.js';
import { CreateJournalEntryPageHeader } from './CreateJournalEntryPageHeader.js';

export function CreateJournalEntryPage() {
  return (
    <>
      <CreateJournalEntryPageHeader />
      <DefaultBodyWrapper>
        <CreateJournalEntryForm />
      </DefaultBodyWrapper>
    </>
  );
}
