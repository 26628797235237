import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape, bool, arrayOf } from 'prop-types';
import { Status } from 'poly-book-admin';
import {
  systemAccessStatusesColors,
  Confirmed2FAStatus,
  Off2FAStatus,
  SidebarRow,
} from 'poly-admin-ui';
import { pathOrNothingUI } from 'poly-client-utils/src/objects.js';
import { useUserHasPermissionToLoginViaSms } from 'poly-admin-ui/src/modules/user/staffUserForm/useUserHasPermissionToLoginViaSms.js';

import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapper,
  threeBlocksWrapperProps,
  threeBlocksProps,
} from '../components/commonSidebarComponents.js';
import {
  getSectionEmail,
  getSectionText,
} from '../components/commonSidebarSectionFormatters.js';
import {
  getUserEmailOrNothingUI,
  getUserFormattedValue,
  titleCaseCurried,
} from './userSidebarUtils.js';

const StatusWrapper = styled.div`
  font-size: 12px;
`;

// getUserSectionStatus :: String -> _ -> ReactNode
const getUserSectionStatus = (status) =>
  function () {
    return (
      <StatusWrapper>
        <Status
          text={titleCaseCurried(status)}
          color={systemAccessStatusesColors[status]}
        />
      </StatusWrapper>
    );
  };

// getUserSectionStatus :: TwoFactorAuth -> _ -> ReactNode
// TwoFactorAuth = { isVerified: Boolean }
const getUserSection2FactorAuth = (twoFactorAuth) =>
  function () {
    return (
      <StatusWrapper>
        {!!twoFactorAuth && twoFactorAuth.isVerified ? (
          <Confirmed2FAStatus twoFASettings={twoFactorAuth} />
        ) : (
          <Off2FAStatus />
        )}
      </StatusWrapper>
    );
  };

export function UserSidebarSystemAccess({ user }) {
  const hasPermissionToLoginViaSMS = useUserHasPermissionToLoginViaSms(
    user?._id,
  );

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>System Access</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          id="user-category"
          label="User Category"
          {...threeBlocksProps}
          Component={getSectionText(
            getUserFormattedValue(
              R.path(['userGroups', '0', 'userGroup', 'name'], user),
              titleCaseCurried,
            ),
          )}
        />
        <BlockWithLabel
          id="email"
          label="Email"
          {...threeBlocksProps}
          width="33%"
          Component={getSectionEmail(getUserEmailOrNothingUI(user))}
        />
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          id="status"
          label="Status"
          {...threeBlocksProps}
          Component={getUserSectionStatus(user.status)}
        />
        <BlockWithLabel
          id="two-factor-auth"
          label="2 Factor Authentications"
          {...threeBlocksProps}
          width="33%"
          Component={getUserSection2FactorAuth(user.twoFactorAuth)}
        />
      </SidebarRow>
      {hasPermissionToLoginViaSMS && (
        <SidebarRow {...threeBlocksWrapperProps}>
          <BlockWithLabel
            id="user-login-cell-phone"
            label="Login Cell Phone Number"
            Component={getSectionText(
              pathOrNothingUI(['profile', 'loginCellPhoneNumber'])(user),
            )}
          />
        </SidebarRow>
      )}
    </SectionWrapper>
  );
}

UserSidebarSystemAccess.propTypes = {
  user: shape({
    status: string.isRequired,
    twoFactorAuth: shape({ isVerified: bool }),
    emails: arrayOf(shape({ address: string })),
  }).isRequired,
};
