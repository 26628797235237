import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { getThemeColor } from 'poly-book-admin';

import { PropertyRemarksInput } from '../components/PropertyRemarksInput.js';
import { ConvertToSubPropertyButton } from '../components/ConvertToSubPropertyButton.js';
import { ConvertToMasterPropertyButton } from '../components/ConvertToMasterPropertyButton.js';

const ConvertPropertyButtonsWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ConvertSupplierLabel = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  align-items: center;
  color: ${getThemeColor(['primaryLighter2'])};
`;

const ConvertSupplierText = styled(ConvertSupplierLabel)`
  color: ${getThemeColor(['black'])};

  > div {
    padding: 0 4px;
  }
`;

export const remarksFormField = {
  field: {
    name: 'remarks',
    withFormData: true,
    withChangeFieldValue: true,
    Component: PropertyRemarksInput,
  },
};

function ConvertPropertyButtons(props) {
  return (
    <ConvertPropertyButtonsWrapperS>
      <ConvertSupplierLabel>Convert to:</ConvertSupplierLabel>
      <ConvertSupplierText>
        <ConvertToMasterPropertyButton {...props} />
        or
        <ConvertToSubPropertyButton {...props} />
      </ConvertSupplierText>
    </ConvertPropertyButtonsWrapperS>
  );
}

// getConvertPropertyFormField :: (Boolean, Boolean) -> FormField
export const getConvertPropertyFormField = (isEdit, isCard) => ({
  field: {
    withFormData: true,
    additionalProps: { isCard },
    name: 'convertPropertyButtons',
    Component: ConvertPropertyButtons,
  },
  renderIf: R.allPass([
    R.always(isEdit),
    R.complement(R.prop('isMaster')),
    R.complement(R.path(['masterProperty', '_id'])),
  ]),
});
