import * as R from 'ramda';

// getSupplierPhoneByType :: String -> [Object] -> String
export const getSupplierPhoneByType = R.curry((type, phones) =>
  R.pipe(
    R.find(R.both(R.propEq('type', type), R.propEq('legacyFormat', false))),
    R.defaultTo({}),
    R.propOr(null, 'phone'),
  )(phones),
);
