import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { FieldLayout, TextButton, Input, Checkbox } from 'poly-book-admin';
import { ClientBidRulesTypes } from 'poly-constants';
import { MoneyInput } from 'poly-admin-ui';

import {
  Line,
  LinesWrapper,
} from '../../../../modules/accounting/enterSupplierInvoice/enterSupplierInvoiceForm/styled.js';
import { DeleteLineIcon } from '../../../../components/DeleteLineIcon.js';

const TextButtonS = styled(TextButton)`
  margin-left: 64%;
`;

const CheckboxS = styled(Checkbox)`
  margin-top: 10px;
`;

function Placeholder() {
  return <div />;
}

function PercentInput(props) {
  return <Input {...props} floatValue format="##%" type="numberMask" />;
}

function RuleTypeCheckbox({ onChange, value, ...props }) {
  const preparedValue = value === ClientBidRulesTypes.jobCostPercentage;

  const handleOnChange = (checked) =>
    onChange(
      checked
        ? ClientBidRulesTypes.jobCostPercentage
        : ClientBidRulesTypes.flatFee,
    );

  return (
    <CheckboxS
      {...props}
      value={preparedValue}
      onChange={handleOnChange}
      label="Value is percentage"
    />
  );
}

RuleTypeCheckbox.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
};

export function ClientBidRulesLineComponent({
  name,
  index,
  fields: { value, remove, push },
}) {
  const isLastLine = index === value.length - 1;
  const isFirstTwoLines = index === 0 || index === 1;
  const isPercentAmountLine =
    value[index].type === ClientBidRulesTypes.jobCostPercentage;

  const onAddNewLine = () =>
    push({ type: ClientBidRulesTypes.flatFee, isRuleActive: false });

  return (
    <LinesWrapper>
      <Line>
        <FieldLayout
          layout={{ width: '5%' }}
          field={{
            Component: CheckboxS,
            name: `${name}.isRuleActive`,
          }}
        />
        <FieldLayout
          required
          layout={{ width: '30%' }}
          disabled={isFirstTwoLines}
          field={{
            Component: Input,
            name: `${name}.label`,
          }}
          validators={[[R.identity, 'Rule Label is required']]}
        />
        <FieldLayout
          required
          layout={{ width: '30%' }}
          field={{
            name: `${name}.amount`,
            Component: isPercentAmountLine ? PercentInput : MoneyInput,
            additionalProps: {
              placeholder: `Enter ${
                isPercentAmountLine ? 'percentage' : 'value'
              }`,
            },
          }}
          validators={[[R.complement(R.isNil), 'Rule Amount is required']]}
        />
        <FieldLayout
          layout={{ width: '28%' }}
          field={{
            name: `${name}.type`,
            Component: isFirstTwoLines ? Placeholder : RuleTypeCheckbox,
          }}
        />
        {!isFirstTwoLines && (
          <DeleteLineIcon
            size={13}
            name="delete"
            onClick={() => remove(index)}
          />
        )}
      </Line>
      {isLastLine && (
        <Line>
          <TextButtonS onClick={onAddNewLine}>Add</TextButtonS>
        </Line>
      )}
    </LinesWrapper>
  );
}

ClientBidRulesLineComponent.displayName = 'ClientBidRulesLineComponent';

ClientBidRulesLineComponent.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    push: func.isRequired,
    remove: func.isRequired,
    value: arrayOf(shape({ type: string.isRequired })).isRequired,
  }),
};
