import * as R from 'ramda';
import React, { useEffect } from 'react';
import { func, bool } from 'prop-types';
import {
  DatePicker,
  Select,
  NumberInputHTML5,
  MainHeader,
} from 'poly-book-admin';
import { SectionWithBackBtn, BackBtn } from 'poly-admin-ui';
import { AdminCheckTypes, SystemAccountTypes } from 'poly-constants';

import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import {
  checksSearchFilters,
  defaultSearchFilters,
} from './printChecksConstants.js';
import { routesNames } from '../../routes/index.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { JustifyFlexEnd } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { AdminCheckTypeSelect } from '../../components/CheckTypeSelect.js';

const printingStatusOptions = [
  { value: true, label: 'Is Printed - Yes' },
  { value: false, label: 'Is Printed - No' },
];

const printChecksHeaderConfig = [
  { name: checksSearchFilters.bankAccountId },
  {
    name: checksSearchFilters.paymentDateFrom,
    defaultValue: '',
  },
  {
    name: checksSearchFilters.paymentDateTo,
    defaultValue: '',
  },
  {
    name: checksSearchFilters.checkNumber,
    defaultValue: null,
  },
  {
    name: checksSearchFilters.isPrinted,
    defaultValue: false,
  },
  {
    name: checksSearchFilters.checkType,
    defaultValue: AdminCheckTypes.manual,
  },
];

function PrintChecksTitle() {
  return (
    <SectionWithBackBtn>
      <BackBtn fallbackLink={routesNames.FINANCIAL_DIRECTORY} />
      <MainHeader>Print Checks</MainHeader>
    </SectionWithBackBtn>
  );
}

export function PrintChecksPageHeader({
  setFilters,
  loading,
  setSelectedChecks,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    printChecksHeaderConfig,
  );

  const onResetSearch = () => {
    onReset();
    setFilters(defaultSearchFilters);
  };
  const onSearch = () => {
    setSelectedChecks([]);
    setFilters(searchFilters);
  };

  useEffect(() => {
    onSearch();
  }, []);

  const printingStatusSelectProps = {
    options: printingStatusOptions,
    value: searchFilters.isPrinted,
    onChange: handlers.isPrinted,
    width: '100%',
    name: 'PrintingStatus',
    required: true,
  };

  return (
    <SearchPageHeaderContainer TitleComp={PrintChecksTitle}>
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn title="Bank Name" titleWidth="160px">
            <AccountsSelect
              placeholder="Select Bank Name"
              extractValue={R.prop('_id')}
              value={searchFilters.bankAccountId}
              onChange={handlers.bankAccountId}
              filterAccounts={filterAccountsBySystemType(
                SystemAccountTypes.BANK_ACCOUNT,
              )}
              width="100%"
              isClearable
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Check #">
            <NumberInputHTML5
              placeholder="Enter Check Number"
              width="100%"
              maxLength={7}
              onChange={handlers.checkNumber}
              value={searchFilters.checkNumber || ''}
              name="checkNumber"
              changeOnBlur
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Check Type">
            <AdminCheckTypeSelect
              width="100%"
              required
              onChange={handlers.checkType}
              value={searchFilters.checkType}
              name="checkType"
            />
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn title="Payment Date From" titleWidth="160px">
            <DatePicker
              value={searchFilters.paymentDateFrom}
              onChange={handlers.paymentDateFrom}
              placeholder="No Date"
              width="100%"
              name="PaymentDateFrom"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Payment Date To">
            <DatePicker
              value={searchFilters.paymentDateTo}
              onChange={handlers.paymentDateTo}
              placeholder="No Date"
              width="100%"
              name="PaymentDateTo"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Printing Status">
            <Select {...printingStatusSelectProps} />
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <JustifyFlexEnd>
          <SearchHeaderColumn filterWidth="375px">
            <JustifyFlexEnd>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </JustifyFlexEnd>
          </SearchHeaderColumn>
        </JustifyFlexEnd>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

PrintChecksPageHeader.propTypes = {
  setFilters: func.isRequired,
  loading: bool,
  setSelectedChecks: func,
};
