import React from 'react';
import { format } from 'date-fns';
import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import downloadFile from 'downloadjs';
import {
  Button,
  PageHeaderContainer,
  MainHeader,
  DefaultBodyWrapper,
  FormCreator,
  InputHTML5,
} from 'poly-book-admin';
import { useProcessState, useOnSubmitSetStopSubmitting } from 'poly-admin-ui';

const generateAssetQRCodesQuery = gql`
  mutation GENERATE_ASSET_QR_CODES($input: GenerateAssetQRCodesInput!) {
    generateAssetQRCodeIds(input: $input) {
      assetQRCodes {
        code
        url
      }
    }
  }
`;

const ContentWrapper = styled.div`
  max-width: 1424px;
  width: 100%;
  margin: 10px 30px 10px 50px;
  display: flex;
`;

const generateAssetQRCodesFormId = 'generate-qr-codes-form';

function NumberInput(props) {
  return <InputHTML5 {...props} type="number" />;
}

const validateCount = (countStr) => {
  const num = parseInt(countStr, 10);
  return num > 0 && num <= 10e3;
};

const generateAssetQRCodesFormSections = [
  {
    id: generateAssetQRCodesFormId,
    order: 1,
    fields: [
      {
        label: 'Number Of Codes To Generate',
        layout: { row: 1, width: '200px' },
        field: {
          name: 'codesToGenerateCount',
          Component: NumberInput,
        },
        required: true,
        validators: [[validateCount, 'Count should be between 1 and 10,000']],
      },
    ],
  },
];

const GenerateCodesFormCreator = styled(FormCreator)`
  width: 220px;
`;

const GenerateBtn = styled(Button)`
  margin-top: 43px;
`;

const generateCodesFormCreatorProps = {
  id: generateAssetQRCodesFormId,
  layout: { overflow: 'visible', card: false },
  sections: generateAssetQRCodesFormSections,
  initialValues: {
    codesToGenerateCount: 100,
  },
};

// generateMutationResToCSV :: {generateAssetQRCodeIds: GenerateAssetQRCodesResult} -> String
const generateMutationResToCSV = R.compose(
  R.join('\n'),
  R.map(R.join(',')),
  R.prepend(['URL', 'Asset ID']),
  // no need to escape because both URL and code don't contain commas:
  R.map(R.juxt([R.prop('url'), R.prop('code')])),
  R.pathOr([], ['generateAssetQRCodeIds', 'assetQRCodes']),
);

export function GenerateAssetQRCodesPage() {
  const [generateCodesMutation] = useMutation(generateAssetQRCodesQuery);

  const onSubmitHandler = async ({ codesToGenerateCount }) => {
    const countToGenerate = parseInt(codesToGenerateCount, 10);
    const { data } = await generateCodesMutation({
      variables: { input: { countToGenerate } },
    });
    const csv = generateMutationResToCSV(data);
    downloadFile(csv, `QR Codes - ${format(new Date(), 'yyyy-MM-dd')}.csv`);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    generateAssetQRCodesFormId,
    onSubmitHandler,
  );

  const { process: isSubmitting } = useProcessState(generateAssetQRCodesFormId);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Generate Asset QR Codes</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <ContentWrapper>
          <GenerateCodesFormCreator
            {...generateCodesFormCreatorProps}
            onSubmit={onSubmit}
          />
          <GenerateBtn
            size="small"
            type="submit"
            form={generateAssetQRCodesFormId}
            loader={isSubmitting}
            disabled={isSubmitting}
          >
            Generate
          </GenerateBtn>
        </ContentWrapper>
      </DefaultBodyWrapper>
    </>
  );
}
