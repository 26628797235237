import { NumberFormatDisplay } from 'poly-book-admin';
import React, { useState, useEffect, useMemo } from 'react';
import { internationalPhoneUtils } from 'poly-client-utils';

import { string } from 'prop-types';

const {
  getCountryByIso,
  isPhoneNumberNotHasCountryCode,
  findIsoCountryByNumber,
} = internationalPhoneUtils;

export function InternationalPhoneDisplay({ phone }) {
  const [isoCountry, setIsoCountry] = useState('US');

  const country = useMemo(() => getCountryByIso(isoCountry), [isoCountry]);

  const phoneValue = isPhoneNumberNotHasCountryCode(phone)
    ? `+${country.code}${phone}`
    : phone;

  useEffect(() => {
    if (!isPhoneNumberNotHasCountryCode(phone) && phone) {
      const isoByNumber = findIsoCountryByNumber(phone);
      setIsoCountry(isoByNumber);
    }
  }, []);

  if (!phone) {
    return <span>–</span>;
  }

  return <NumberFormatDisplay value={phoneValue} format={country.format} />;
}

InternationalPhoneDisplay.propTypes = {
  phone: string,
};
