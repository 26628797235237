import * as R from 'ramda';
import { AacContractMGRField } from 'poly-admin-ui';

export const aacManagerFormField = {
  label: 'Assigned CSR',
  field: {
    name: 'aacContractMGR',
    Component: AacContractMGRField,
  },
};

export const branchManagerFormField = {
  label: 'Branch Manager',
  labelSwitchAddNewMode: "Didn't find the manager?",
  labelSwitchFindMode:
    'New manager will be added when Branch Manager is created.',
  labelNotBelongProperty:
    'This manager is associated with another property. Would you like to move them to this property?',
  name: 'branchManager',
  placeholder: 'Start typing to find managers',
  getClientId: R.pathOr('', ['client', '_id']),
  getPropertyId: R.pathOr('', ['_id']),
};

export const districtFacilityManagerFormField = {
  label: 'District Facility Manager',
  labelSwitchAddNewMode: "Didn't find the manager?",
  labelSwitchFindMode:
    'New manager will be added when Branch Manager is created.',
  labelNotBelongProperty:
    'This manager is associated with another property. Would you like to move them to this property?',
  name: 'districtFacilityManager',
  placeholder: 'Start typing to find managers',
  getClientId: R.pathOr('', ['client', '_id']),
  getPropertyId: R.pathOr('', ['_id']),
  renderIf: R.path([
    'client',
    'configs',
    'propertyFields',
    'districtFacilityManager',
  ]),
};
