import * as R from 'ramda';
import { useState, useEffect } from 'react';
import { ELASTIC_SCORE_FIELD } from 'poly-constants';

// modifySortingScoreWithSearch :: [Sorting] -> [Sorting]
const modifySortingScoreWithSearch = R.when(
  R.is(Array),
  R.compose(
    R.append(ELASTIC_SCORE_FIELD),
    R.reject(R.equals(ELASTIC_SCORE_FIELD)),
  ),
);

export const useScoredSortingWithSearchText = ({ sort, searchText }) => {
  const [modifiedSort, setModifiedSort] = useState(sort);
  const [staticSearch, setStaticSearch] = useState(searchText);

  useEffect(() => {
    if (!R.equals(sort, modifiedSort)) {
      if (searchText && R.equals(searchText, staticSearch)) {
        const sortWithSearch = modifySortingScoreWithSearch(sort);
        setModifiedSort(sortWithSearch);
      } else {
        setModifiedSort(sort);
      }
    }

    if (!R.equals(searchText, staticSearch)) {
      setStaticSearch(searchText);
    }
  }, [sort, searchText]);

  return modifiedSort;
};
