import { gql } from '@apollo/client';

export const PROJECT_EMAILS = gql`
  query PROJECT_EMAILS($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        clientManager {
          _id
          email
        }
        client {
          _id
          clientManagers {
            _id
            fullName
            email
          }
        }
        contact {
          _id
          email
          fullName
        }
        siteContact {
          _id
          email
          fullName
        }
        suppliers {
          _id
          rating {
            average
            stars
          }
          company {
            name
            emails {
              type
              email
            }
          }
        }
      }
    }
    searchRecurringProjects(input: $input) {
      hits {
        _id
        clientManager {
          _id
          email
        }
        client {
          _id
          clientManagers {
            _id
            fullName
            email
          }
        }
        siteContact {
          _id
          email
          fullName
        }
        suppliers {
          _id
          rating {
            average
            stars
          }
          company {
            name
            emails {
              type
              email
            }
          }
        }
      }
    }
  }
`;

export const PROJECT_SUPPLIERS = gql`
  query PROJECT_SUPPLIERS($projectId: ID!, $tasksInput: TasksInput!) {
    project(id: $projectId) {
      _id
      type
      noSupplierRequired
      tasks(input: $tasksInput) {
        hits {
          _id
          supplier {
            _id
          }
          supplierInvoice {
            _id
            status
          }
        }
      }
      invoices {
        _id
        status
        supplier {
          _id
        }
      }
      suppliers {
        _id
        statusInProject
        isRatedOnComplete
        rating {
          average
          stars
        }
        company {
          name
          emails {
            type
            email
          }
        }
      }
      listing {
        bpoListPrice
        bpoLow
        bpoHigh
        bpoDate
        salesAddendumDate
        listPrice
        listDate
        underContractDate
        dueDiligenceExp
        underContractPrice
        listAgreementExp
        expectedCloseDate
        referralFee
      }
    }
  }
`;

export const RECURRING_PROJECT_SUPPLIERS = gql`
  query RECURRING_PROJECT_SUPPLIERS($projectId: ID!) {
    recurringProject(id: $projectId) {
      _id
      suppliers {
        _id
        rating {
          average
          stars
        }
        company {
          name
        }
      }
    }
  }
`;

export const DEFAULT_PROJECT_QUERY = gql`
  query DEFAULT_PROJECT_QUERY($id: ID!) {
    project(id: $id) {
      _id
      projectId
      manager {
        _id
      }
    }
  }
`;

export const DEFAULT_RECURRING_PROJECT_QUERY = gql`
  query DEFAULT_RECURRING_PROJECT_QUERY($id: ID!) {
    recurringProject(id: $id) {
      _id
      projectId
    }
  }
`;

export const DEFAULT_PREVENTIVE_MAINTENANCE_QUERY = gql`
  query DEFAULT_PREVENTIVE_MAINTENANCE_QUERY($id: ID!) {
    maintenancePlan(id: $id) {
      _id
      maintenancePlanId
    }
  }
`;

export const DEFAULT_ADMIN_PO_QUERY = gql`
  query DEFAULT_ADMIN_PO_QUERY($id: ID!) {
    adminPurchaseOrder(id: $id) {
      _id
      displayName
    }
  }
`;
