import * as R from 'ramda';

import { createAction, handleActions } from 'redux-actions';

export const setInvoices = createAction('SET_INVOICES');
export const setAllInvoices = createAction('SET_ALL_INVOICES');
export const setUpdatedInvoice = createAction('SET_UPDATED_INVOICE');

// mergeNewInvoicesWithOld :: [InvoicePayment] -> [InvoicePayment] -> [InvoicePayment]
// After loading next page or sorting we must "merge" existing invoices
// (where paid amount already set) with new ones,
// so new documents and their order are in tact.
const mergeNewInvoicesWithOld = (stateInvoices) =>
  R.map(
    R.converge(R.mergeRight, [
      R.compose(
        R.defaultTo({}),
        R.find(R.__, stateInvoices),
        R.propEq('_id'),
        R.prop('_id'),
      ),
      R.identity,
    ]),
  );

export const invoicesReducer = handleActions(
  {
    [setInvoices]: (state, { payload }) =>
      mergeNewInvoicesWithOld(state)(payload),
    [setAllInvoices]: (_, { payload }) => payload,
    [setUpdatedInvoice]: (state, { payload }) =>
      R.over(R.lensIndex(payload.index), R.always(payload.item))(state),
  },
  [],
);
