import * as R from 'ramda';
import { string, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { gql, useMutation } from '@apollo/client';
import React, { useMemo, useEffect } from 'react';
import { usePristineSubscribe } from 'poly-client-routing';
import { useOutSidebarContext } from 'poly-client-utils';
import { Loader } from 'poly-book-admin';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  useNotificationState,
} from 'poly-admin-ui';

import { addUserGroupFormId } from '../constants.js';
import { userGroupFormSections } from './userGroupFormSections.js';
import { UserGroupFormCreatorS } from './components/userGroupFormComponents.js';
import { setUserGroupVariables } from '../../../redux/userGroupVariablesReducer.js';
import { useUserGroupSchemaQuery } from './useUserGroupSchemaQuery.js';
import { useUserGroupByIdQuery } from './useUserGroupByIdQuery.js';
import {
  prepareUserGroupFormForMutation,
  prepareUserGroupForFormValues,
  checkIfHasLoginPermission,
  checkIfLineInEditMode,
} from '../userGroupManagementUtils.js';

const createUserGroupMutation = gql`
  mutation createUserGroupMutation($input: CreateUserGroupInput!) {
    createUserGroup(input: $input) {
      _id
    }
  }
`;

// prepareInitialValues :: UserGroupPermissionSchemaQueryResult
// -> UserGroup -> FormInitialValues
const prepareInitialValues = (schema) =>
  R.compose(R.dissoc('name'), prepareUserGroupForFormValues(schema));

export function AddUserGroupForm({ userGroupId, refetch }) {
  const dispatch = useDispatch();
  const { closeOutSidebar } = useOutSidebarContext();
  const { showSuccessNotification } = useNotificationState();
  const pristineSubscribeProps = usePristineSubscribe({
    id: addUserGroupFormId,
  });

  const { schema, loading } = useUserGroupSchemaQuery();
  const { userGroup, loading: userGroupLoading } =
    useUserGroupByIdQuery(userGroupId);
  const [createUserGroup] = useMutation(createUserGroupMutation);

  const initialValues = useMemo(
    () =>
      userGroupId
        ? prepareInitialValues(schema)(userGroup)
        : {
            isTwoFactorAuthRequired: false,
            accessItems: [{ scopes: [] }],
          },
    [userGroup, schema],
  );

  const onSubmitHandler = async (formData) => {
    const hasLoginPermission = checkIfHasLoginPermission(formData);
    const isLineInEditMode = checkIfLineInEditMode(formData);

    if (!hasLoginPermission || isLineInEditMode) return;

    const input = prepareUserGroupFormForMutation(formData);

    await createUserGroup({ variables: { input } });

    if (R.is(Function, refetch)) {
      await refetch();
    }

    showSuccessNotification('User Group was successfully created');
    closeOutSidebar(addUserGroupFormId);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addUserGroupFormId,
    onSubmitHandler,
  );

  useEffect(
    () => () => {
      dispatch(setUserGroupVariables({}));
    },
    [],
  );

  if (loading || userGroupLoading) return <Loader />;

  return (
    <UserGroupFormCreatorS
      onSubmit={onSubmit}
      id={addUserGroupFormId}
      mutators={arrayMutators}
      initialValues={initialValues}
      layout={commonSidebarFormLayout}
      sections={userGroupFormSections}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      {...pristineSubscribeProps}
    />
  );
}

AddUserGroupForm.propTypes = { userGroupId: string, refetch: func };
