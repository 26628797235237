import React from 'react';
import * as R from 'ramda';
import { oneOf, string, shape, object, func, oneOfType } from 'prop-types';
import { UpdateCollections } from 'poly-constants';
import {
  TableToolBarWrapper,
  ToolBarBtnDivider,
  TextButton,
  TableCard,
  Loader,
} from 'poly-book-admin';

import { TabsViews } from '../../../constants/tabs.js';
import { TabTableWrapper } from '../commonTabComponents.js';
import { UpdatesTable } from '../../tables/updatesTable/UpdatesTable.js';
import { CopyMagicEmailButton } from '../../../sidebar/tabs/updatesTab/CopyMagicEmailButton.js';
import { PrintUpdatesTabButton } from '../../../sidebar/tabs/updatesTab/PrintUpdatesTabButton.js';
import { useUpdatesTabLogic } from '../../../sidebar/tabs/updatesTab/useUpdatesTabLogic.js';

export function UpdatesTab({
  entity,
  className,
  collection,
  PrintLayout,
  AddUpdateBtn,
  updatesQuery,
}) {
  const {
    loading,
    updates,
    showAudit,
    printInfo,
    toggleAudit,
    updateCacheParams,
  } = useUpdatesTabLogic({
    entity,
    collection,
    updatesQuery,
    tabView: TabsViews.card,
  });

  return (
    <TableCard className={className}>
      {loading ? (
        <Loader />
      ) : (
        <TabTableWrapper>
          <TableToolBarWrapper>
            <AddUpdateBtn
              {...{ collection, updatesQuery, entity, updateCacheParams }}
            />
            <ToolBarBtnDivider />
            <CopyMagicEmailButton {...{ collection, entity }} />
            <ToolBarBtnDivider />
            <TextButton
              active={showAudit}
              onClick={() => toggleAudit(!showAudit)}
            >
              Audit
            </TextButton>
            <ToolBarBtnDivider />
            <PrintUpdatesTabButton
              {...{ updates, PrintLayout, entity, printInfo }}
            />
          </TableToolBarWrapper>
          <UpdatesTable updates={updates} tabView={TabsViews.card} />
        </TabTableWrapper>
      )}
    </TableCard>
  );
}

UpdatesTab.displayName = 'UpdateTab';
UpdatesTab.propTypes = {
  className: string,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
    humanReadableId: string,
  }).isRequired,
  PrintLayout: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  updatesQuery: object.isRequired,
  AddUpdateBtn: oneOfType([func, object]),
  collection: oneOf(R.values(UpdateCollections)).isRequired,
};
