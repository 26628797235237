import React from 'react';
import * as R from 'ramda';
import { string, shape, arrayOf } from 'prop-types';
import { entities } from 'poly-admin-ui';

import { SidebarAssetsTab } from '../../components/commonTabs/SidebarAssetsTab.js';

export function MasterRecurringProjectSidebarAssetsTab({
  _id,
  property,
  projectId,
  subProperties,
}) {
  const entity = {
    _id,
    projectId,
    preview: 'project',
    name: entities.RECURRING_PROJECT,
    propertyId: property ? property._id : null,
    subPropertiesIds: subProperties ? R.pluck('_id', subProperties) : null,
  };

  return <SidebarAssetsTab entity={entity} />;
}

MasterRecurringProjectSidebarAssetsTab.propTypes = {
  _id: string.isRequired,
  projectId: string.isRequired,
  property: shape({ _id: string.isRequired }),
  subProperties: arrayOf(shape({ _id: string.isRequired })),
};
