import * as R from 'ramda';
import React from 'react';
import { string, func } from 'prop-types';
import { commonFileValidators, validateFilesFunc } from 'poly-client-utils';
import { FormCreator, Checkbox, FilePicker } from 'poly-book-admin';
import { gql, useMutation } from '@apollo/client';
import {
  thirdWidth,
  twoThirdWidth,
  useModalContext,
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from 'poly-admin-ui';

import { TextAreaS } from '../../../modules/forms/formsStyles.js';
import { reviseWOCNote } from '../../../modules/forms/sendWOCForm/constants.js';
import { commonModalLayout } from '../../../modules/forms/common.js';
import { updateAddedSupplierFormId } from '../constants.js';

function SupplierInstructionsTextArea(props) {
  return (
    <TextAreaS {...props} rows={4} placeholder="Enter supplier instructions" />
  );
}

function SendSupplierUpdateCheckbox({ onChange, changeFieldValue, ...props }) {
  const onCheckboxChange = (value) => {
    onChange(value);

    if (value) {
      changeFieldValue('isFreeQuote', false);
    }
  };

  return (
    <Checkbox
      {...props}
      onChange={onCheckboxChange}
      label="Send Supplier Update"
    />
  );
}

SendSupplierUpdateCheckbox.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};

function FreeQuoteCheckbox({ onChange, changeFieldValue, ...props }) {
  const onCheckboxChange = (value) => {
    onChange(value);

    if (value) {
      changeFieldValue('isSendSupplierUpdate', false);
      changeFieldValue('supplierInstructions', reviseWOCNote);
    }
  };

  return <Checkbox {...props} onChange={onCheckboxChange} label="Free Quote" />;
}

FreeQuoteCheckbox.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};

function FileAttachmentComponent(props) {
  return <FilePicker {...props} multiple={false} />;
}

const sendWOCFormSections = [
  {
    id: updateAddedSupplierFormId,
    layout: {
      column: 1,
      margin: 0,
    },
    order: 1,
    fields: [
      {
        order: 1,
        layout: { row: 1, width: thirdWidth, margin: '10px 0' },
        field: {
          withFormData: true,
          name: 'isSendSupplierUpdate',
          withChangeFieldValue: true,
          Component: SendSupplierUpdateCheckbox,
        },
      },
      {
        order: 2,
        layout: { row: 1, width: twoThirdWidth, margin: '10px 0' },
        field: {
          withFormData: true,
          name: 'isFreeQuote',
          withChangeFieldValue: true,
          Component: FreeQuoteCheckbox,
        },
      },
      {
        order: 3,
        label: 'Supplier Instructions',
        layout: { row: 2 },
        validators: [[R.identity, 'Supplier Instructions is required']],
        field: {
          name: 'supplierInstructions',
          Component: SupplierInstructionsTextArea,
        },
      },
      {
        label: '',
        order: 4,
        layout: { row: 3, padding: '0', margin: '0 0 20px 0' },
        validators: commonFileValidators,
        validateFunction: validateFilesFunc,
        field: {
          name: 'attachment',
          additionalProps: { label: 'Attach File' },
          Component: FileAttachmentComponent,
        },
      },
    ],
  },
];

const sendProjectAddedSupplierUpdateMutation = gql`
  mutation sendProjectAddedSupplierUpdate(
    $input: SendProjectAddedSupplierUpdateInput!
  ) {
    sendProjectAddedSupplierUpdate(input: $input) {
      _id
    }
  }
`;

// prepareFormDataToMutation :: Input -> SendProjectAddedSupplierUpdateInput
// Input = {
//    ...FormData
//    projectId: ID
//    supplierId: ID
// }
const prepareFormDataToMutation = R.compose(
  R.mergeAll,
  R.juxt([
    R.pick([
      'projectId',
      'supplierId',
      'supplierInstructions',
      'isSendSupplierUpdate',
      'isFreeQuote',
    ]),
    R.ifElse(
      R.propSatisfies(R.isEmpty, 'attachment'),
      R.always({}),
      R.applySpec({
        attachment: {
          upload: R.path(['attachment', 0, 'upload']),
          fileName: R.path(['attachment', 0, 'fileName']),
        },
      }),
    ),
  ]),
);

export function SendAddedSupplierUpdateForm({
  supplierId,
  projectId,
  onCancel,
}) {
  const { closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [sendProjectAddedSupplierUpdate] = useMutation(
    sendProjectAddedSupplierUpdateMutation,
  );

  const onSubmitHandler = async (formData) => {
    await sendProjectAddedSupplierUpdate({
      variables: {
        input: prepareFormDataToMutation({
          ...formData,
          supplierId,
          projectId,
        }),
      },
    });
    showSuccessNotification('Added Supplier update was sent');
    closeModal(updateAddedSupplierFormId);

    if (onCancel) {
      onCancel();
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    updateAddedSupplierFormId,
    onSubmitHandler,
  );

  const formProps = {
    onSubmit,
    id: updateAddedSupplierFormId,
    formId: updateAddedSupplierFormId,
    layout: commonModalLayout,
    sections: sendWOCFormSections,
    initialValues: {
      isFreeQuote: false,
      isSendSupplierUpdate: false,
      supplierInstructions: '',
      attachment: [],
    },
  };

  return <FormCreator {...formProps} />;
}

SendAddedSupplierUpdateForm.propTypes = {
  supplierId: string.isRequired,
  projectId: string.isRequired,
  onCancel: func,
};
