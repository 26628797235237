import React from 'react';
import { number, string, bool, shape } from 'prop-types';
import { entities } from 'poly-admin-ui';

import { ToolbarButton } from '../../components/commonSidebarComponents.js';
import { ProjectOccurrence } from '../../../modules/core/constants/projects.js';
import { SidebarMastersTab } from '../../components/commonTabs/SidebarMastersTab.js';
import { useAddProjectSidebar } from '../../ProjectSidebar/forms/add/useAddProjectSidebar.js';
import {
  isAllowProjectCreationOnAdminByClient,
  isClientInactive,
} from '../helpers.js';

function AddClientMasterProjectButton({ clientId, isCard, client }) {
  const openAddProjectSidebar = useAddProjectSidebar(
    isCard,
    entities.CLIENT,
    clientId,
  );

  if (!isAllowProjectCreationOnAdminByClient(client)) {
    return null;
  }

  const onAddProject = () => {
    openAddProjectSidebar({
      clientId,
      type: ProjectOccurrence.RECURRING_OCCURRENCE,
    });
  };

  return (
    <ToolbarButton disabled={isClientInactive(client)} onClick={onAddProject}>
      Add Project
    </ToolbarButton>
  );
}

AddClientMasterProjectButton.propTypes = {
  isCard: bool,
  clientId: string.isRequired,
  client: shape({}),
};

export function ClientSidebarMastersTab({
  doubleTabWidth,
  clientId,
  isCard,
  client,
}) {
  return (
    <SidebarMastersTab
      isCard={isCard}
      tabWidth={doubleTabWidth}
      entity={{ _id: clientId, name: entities.CLIENT }}
      toolBar={
        <AddClientMasterProjectButton
          clientId={clientId}
          isCard={isCard}
          client={client}
        />
      }
    />
  );
}

ClientSidebarMastersTab.propTypes = {
  isCard: bool,
  clientId: string.isRequired,
  doubleTabWidth: number.isRequired,
  client: shape({}),
};
