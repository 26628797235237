import { gql } from '@apollo/client';
import { useMemo, useState, useEffect } from 'react';
import { useRouterQuery } from 'poly-client-routing';
import {
  useLazyQueryWithSubscription,
  useReactiveQuery,
} from 'poly-client-utils';

const BILLING_PROFILES_QUERY = gql`
  query BILLING_PROFILES_QUERY($input: BillingProfilesInput) {
    billingProfiles(input: $input) {
      _id
      name
    }
  }
`;

const BILLING_PROFILE_SUBSCRIPTION = gql`
  subscription BILLING_PROFILE_SUBSCRIPTION($input: CollectionSearchParams) {
    searchBillingProfileChanged(input: $input) {
      id
      type
    }
  }
`;

export const useBillingProfilesQueryByInput = (
  input,
  skipByProp,
  isLazyQuery,
) => {
  const [skip, setSkip] = useState(!!skipByProp);
  const { clientId } = useRouterQuery(['clientId']);

  useEffect(() => {
    if (!clientId) {
      setSkip(true);
    }

    if (clientId && !skipByProp) {
      setSkip(false);
    }
  }, [clientId]);

  useEffect(() => () => setSkip(true), []);

  const queryOptions = useMemo(
    () => ({
      variables: { input: { clientId, ...(input || {}) } },
      skip,
    }),
    [input, skip, clientId],
  );

  const subscriptionOptions = useMemo(
    () => ({
      variables: { input: { query: clientId ? { term: { clientId } } : null } },
      skip: skip || !clientId,
    }),
    [clientId, skip],
  );

  const useQueryHook = isLazyQuery
    ? useLazyQueryWithSubscription
    : useReactiveQuery;

  const queryResult = useQueryHook(
    BILLING_PROFILES_QUERY,
    BILLING_PROFILE_SUBSCRIPTION,
    {
      queryOptions,
      subscriptionOptions,
    },
  );

  return { ...queryResult, clientId };
};
