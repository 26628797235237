import * as R from 'ramda';
import { gql, useApolloClient } from '@apollo/client';
import { getExtensionByFileName } from 'poly-utils/src/files.js';

import { compressImage } from './compressImage.js';

// prepareFileMetadata :: (File, String) -> FileMetadataInput
const prepareFileMetadata = (file, fileName) =>
  R.applySpec({
    filename: R.ifElse(
      R.always(fileName),
      R.compose(
        R.join('.'),
        R.juxt([
          R.always(fileName),
          R.compose(getExtensionByFileName, R.prop('name')),
        ]),
      ),
      R.prop('name'),
    ),
    type: R.prop('type'),
    size: R.prop('size'),
  })(file);

const CREATE_S3_PRESIGNED_URL_MUTATION = gql`
  mutation CREATE_S3_PRESIGNED_URL_MUTATION($input: FileMetadataInput!) {
    createS3PresignedUrl(input: $input) {
      fileId
      uploadURL
      url
    }
  }
`;
// eslint-disable-next-line import/no-unused-modules
export const uploadFileToS3P = async (client, file, fileName = null) => {
  const input = prepareFileMetadata(file, fileName);
  const { data } = await client.mutate({
    mutation: CREATE_S3_PRESIGNED_URL_MUTATION,
    variables: { input },
  });

  await fetch(data.createS3PresignedUrl.uploadURL, {
    method: 'PUT',
    body: file,
  });

  const res = await fetch(R.path(['createS3PresignedUrl', 'url'], data), {
    method: 'HEAD',
  });

  const contentLength = parseInt(res.headers.get('content-length'), 10);

  if (!contentLength) {
    throw new Error('Failed to upload file');
  }
  return R.path(['createS3PresignedUrl', 'fileId'], data);
};

// eslint-disable-next-line import/no-unused-modules
export const useUploadAttachment = (skipCompress = false) => {
  const apolloClient = useApolloClient();

  return async (image, fileName = null) => {
    let compressedImage = null;
    if (!skipCompress && R.test(/$image/, image.type)) {
      compressedImage = await compressImage(image);
    }

    return uploadFileToS3P(apolloClient, compressedImage || image, fileName);
  };
};
