import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { updateCacheHandler } from '../../../utils/apollo-cache.js';

const TOGGLE_UPDATE_PIN_MUTATION = gql`
  mutation TOGGLE_UPDATE_PIN_MUTATION($id: ID!) {
    toggleUpdatePin(id: $id) {
      _id
    }
  }
`;

// toggleFilePinInCache :: (ID, String) -> EntityUpdatesCacheData -> EntityUpdatesCacheData
const toggleUpdatePinInCache = (id, entity) =>
  R.over(
    R.lensPath([entity, 'documentUpdates', 'hits']),
    R.converge(
      R.over(
        R.__,
        R.compose(
          R.evolve({
            pinned: R.compose(R.not, R.defaultTo(false)),
          }),
        ),
      ),
      [R.compose(R.lensIndex, R.findIndex(R.propEq('_id', id))), R.identity],
    ),
  );

export const useToggleUpdatePin = ({ _id, updateCacheParams }) => {
  const [toggleUpdatePinMutation, data] = useMutation(
    TOGGLE_UPDATE_PIN_MUTATION,
  );

  const mutate = async () => {
    await toggleUpdatePinMutation({
      variables: { id: _id },
      ...(updateCacheHandler
        ? {
            update: updateCacheHandler(
              updateCacheParams.query,
              updateCacheParams.variables,
              toggleUpdatePinInCache(_id, updateCacheParams.entity),
            ),
          }
        : {}),
    });
  };

  return [mutate, data.loading];
};
