import React, { useEffect } from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { Loader } from 'poly-book-admin';
import { JournalTypes } from 'poly-constants/src/journal.js';
import { number, oneOfType, string } from 'prop-types';
import { useReactiveQuery } from 'poly-client-utils/src/hooks/useReactiveQuery.js';
import { isNilOrEmpty } from 'poly-utils/src/general.js';
import { useOutSidebarContext } from 'poly-client-utils/src/slideOutSidebar.js';

import { GeneralTransactionDetailsSidebar } from './GeneralTransactionDetailsSidebar.js';
import { PaymentTransactionDetailsSidebar } from './PaymentTransactionDetailsSidebar.js';
import { ClientPaymentTransactionDetailsSidebar } from './ClientPaymentTransactionDetailsSidebar.js';
import { PayrollPaymentTransactionDetailsSidebar } from './PayrollTransactionDetailsSidebar.js';
import { transactionsDetailsSidebarId } from './helpers.js';

// eslint-disable-next-line import/no-unused-modules
export const journalsByTransactionNumberQuery = gql`
  query journalsByTransactionNumber($input: JournalsByTransactionNumberInput!) {
    journalsByTransactionNumber(input: $input) {
      _id
      type
      collection
      journal_number
      date
      voided_at
      check_number
      transaction_number
      payment_mode
      description
      lines {
        account {
          name
          code
          accountType {
            _id
            system_type
          }
        }
        cash_amount
        accrual_amount
        payment_status
        receiptFile {
          url
        }
        bank_cleared_date
      }
      invoice {
        _id
        invoiceNumber
        invoiceId
        total
        invoiceDate
        project {
          _id
          projectId
        }
        invoiceFile {
          fileName
          url
        }
        supplier {
          _id
          company {
            name
          }
        }
        glAccount {
          code
          name
        }
      }
      clientInvoice {
        _id
        number
        amount
        balance
        createdAt
        invoiceDate
        client {
          _id
          name
        }
        project {
          _id
          projectId
        }
        glAccount {
          code
          name
        }
      }
      original_journal {
        _id
        type
      }
      created_by {
        _id
        profile {
          fullName
        }
      }
    }
  }
`;

export const JOURNAL_CHANGED_SUBSCRIPTION = gql`
  subscription JOURNAL_CHANGED_SUBSCRIPTION($input: CollectionSearchParams) {
    searchJournalChanged(input: $input) {
      id
      type
    }
  }
`;

// journalIs :: JournalType -> Journal -> Boolean
const journalIs = (type) => R.compose(R.equals(type), R.prop('type'));

// isPaymentJournal :: Journal -> Boolean
const isPaymentJournal = journalIs(JournalTypes.ACC_PAYABLE_PAYMENT);

// isPayrollJournal :: Journal -> Boolean
const isPayrollJournal = journalIs(JournalTypes.PAYROLL);

// isClientPaymentJournal :: Journal -> Boolean
const isClientPaymentJournal = journalIs(JournalTypes.ACC_RECEIVABLE_PAYMENT);

export function TransactionsDetailsSidebar({ transactionNumber }) {
  const queryInput = { transactionNumber: parseInt(transactionNumber, 10) };

  const { data, loading } = useReactiveQuery(
    journalsByTransactionNumberQuery,
    JOURNAL_CHANGED_SUBSCRIPTION,
    {
      queryOptions: {
        variables: { input: queryInput },
        fetchPolicy: 'network-only',
      },
      subscriptionOptions: {
        variables: {
          input: {
            query: {
              term: { transaction_number: parseInt(transactionNumber, 10) },
            },
          },
        },
      },
    },
  );

  const { closeOutSidebar } = useOutSidebarContext();
  const journals = R.propOr([], 'journalsByTransactionNumber', data);

  useEffect(() => {
    if (isNilOrEmpty(journals) && !loading) {
      closeOutSidebar(transactionsDetailsSidebarId);
    }
  }, [journals]);

  if (loading) {
    return <Loader />;
  }
  if (isNilOrEmpty(journals) && !loading) {
    return null;
  }

  if (isPaymentJournal(journals[0])) {
    return (
      <PaymentTransactionDetailsSidebar journals={journals} loading={loading} />
    );
  }
  if (isClientPaymentJournal(journals[0])) {
    return <ClientPaymentTransactionDetailsSidebar journals={journals} />;
  }
  if (isPayrollJournal(journals[0])) {
    return (
      <PayrollPaymentTransactionDetailsSidebar
        journals={journals}
        loading={loading}
      />
    );
  }
  return <GeneralTransactionDetailsSidebar journals={journals} />;
}

TransactionsDetailsSidebar.propTypes = {
  transactionNumber: oneOfType([string, number]).isRequired,
};
