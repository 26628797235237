import React from 'react';
import { Editor, isEditorEmpty } from 'poly-book-admin';
import styled from 'styled-components';
import { useDecoratePersistenceForOnChange } from 'poly-client-utils';
import { func } from 'prop-types';

const EditorS = styled(Editor)`
  .ql-editor {
    max-height: none;
  }
`;

export function ClientRemarksInput({
  onChange,
  onChangePersistentValue,
  ...props
}) {
  const onChangePersistent = useDecoratePersistenceForOnChange(
    onChangePersistentValue,
    onChange,
    isEditorEmpty,
  );
  return (
    <EditorS
      {...props}
      id="client-remarks-input"
      placeholder="Enter Remarks"
      onChange={onChangePersistent}
    />
  );
}

ClientRemarksInput.propTypes = {
  onChange: func.isRequired,
  onChangePersistentValue: func.isRequired,
};
ClientRemarksInput.displayName = 'ClientRemarksInput';
