import { defaultTheme } from 'poly-book-admin';
import { peopleStatuses } from 'poly-constants';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

export const peopleStatusesColors = {
  [peopleStatuses.ACTIVE]: active,
  [peopleStatuses.INACTIVE]: inactive,
};
