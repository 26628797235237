import React from 'react';
import { useRedirectIfHasNoPermissions } from 'poly-client-utils';
import { CREATE_CLIENT_BATCH_PERMISSION } from 'poly-security';

import { BatchInvoicingTMPage } from '../BatchInvoicingTM/BatchInvoicingTMPage.js';
import { bidBassProBatchInvoicingQuery } from '../BatchInvoicingTM/client-filters-presets.js';

export function BatchInvoicingBidBassProPage() {
  useRedirectIfHasNoPermissions([CREATE_CLIENT_BATCH_PERMISSION]);

  return (
    <BatchInvoicingTMPage
      title="Bass Pro Bid Batch Invoice"
      query={bidBassProBatchInvoicingQuery}
    />
  );
}
