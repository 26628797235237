import * as R from 'ramda';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { bool, func, shape, string } from 'prop-types';
import {
  ProjectType,
  supplierInvoiceRequestOptions,
  taskActionTypes,
  taskCollections,
  WorkOrderStatus,
} from 'poly-constants';
import { Checkbox, DatePicker, Textarea } from 'poly-book-admin';
import { alwaysNewDate, isDocumentProjectRelated } from 'poly-utils';
import {
  commonFileValidators,
  dNdTextAreaFileValidators,
  useDecoratePersistenceForOnChange,
  validateFilesFunc,
} from 'poly-client-utils';

import { NextTaskDueDateField } from './components/NextTaskDueDate.js';
import { DnDAreaWithMentions } from '../../../../components/DnDAreaWithMentions.js';
import { EntityInfoView } from './components/EntityInfoView.js';
import {
  MailTo,
  mailToValidator,
  validateMailToField,
} from '../../fields/MailTo/index.js';
import { SupplierRatings } from './components/SupplierRatings.js';
import { TaskAssignUsersSelect } from '../../fields/TaskAssignUsersSelect.js';
import { halfWidth } from '../../supplierForm/form/common.js';
import { TaskPrioritySelect } from './components/TaskPrioritySelect.js';

const CheckboxS = styled(Checkbox)`
  color: #333;
`;

const isNotDefaultTask = R.anyPass([
  R.propEq('isDefaultTask', false),
  R.propSatisfies(R.isNil, 'isDefaultTask'),
]);

const isAddAction = R.prop('isAdd');

const isEditAction = R.prop('isEdit');

const isCompleteAction = R.prop('isComplete');

// getDatePickerProps :: Props -> Props
const getDatePickerProps = R.compose(
  R.mergeRight({
    leftMove: '-10px',
    position: 'top',
    width: '100%',
  }),
  R.when(
    R.prop('isCreateAction'),
    R.mergeRight({ disabledDays: { before: alwaysNewDate() } }),
  ),
);

// eslint-disable-next-line import/no-unused-modules
export const MAX_TASK_DESCRIPTION_LENGTH = 200;

// isValidTaskDescriptionLength :: String -> Boolean
// eslint-disable-next-line import/no-unused-modules
export const isValidTaskDescriptionLength = R.compose(
  R.gt(MAX_TASK_DESCRIPTION_LENGTH),
  R.length,
  R.defaultTo(''),
);

// checkShowTaskTitleInput :: FormValues -> Boolean
const checkShowTaskTitleInput = R.both(
  isNotDefaultTask,
  R.anyPass([isAddAction, isEditAction]),
);

// eslint-disable-next-line import/no-unused-modules
export function DatePickerWrapper(props) {
  const datePickerProps = getDatePickerProps(props);
  return <DatePicker {...datePickerProps} />;
}

// eslint-disable-next-line import/no-unused-modules
export function DnDAreaWithMentionsWrapper(props) {
  const {
    formData: { disableDescriptionEdit, isUpdateRequired, ...formValues },
    onChange,
    onChangePersistentValue,
    disableMentions,
    value,
    ...rest
  } = props;

  const onChangePersistent = useDecoratePersistenceForOnChange(
    onChangePersistentValue,
    onChange,
    R.equals({ text: '', attachments: [], mentions: {} }),
  );

  const mentionsProps = {
    placeholder: R.propOr('', 'placeholder', value),
    readOnly: disableDescriptionEdit,
    required: isUpdateRequired || false,
    autoFocus: !checkShowTaskTitleInput(formValues),
    disableMentions,
    value,
    ...rest,
    onChange: onChangePersistent,
  };

  return <DnDAreaWithMentions {...mentionsProps} />;
}

DnDAreaWithMentionsWrapper.propTypes = {
  formData: shape({
    disableDescriptionEdit: bool,
    isUpdateRequired: bool,
  }),
  value: shape({
    placeholder: string,
  }),
  disableMentions: bool,
  onChange: func,
  onChangePersistentValue: func,
};

// eslint-disable-next-line import/no-unused-modules
export const dateValidators = [[R.identity, 'Date is required']];

// isTaskManagerNotRequired :: Payload -> Boolean
const isTaskManagerRequired = R.complement(
  R.both(
    R.propEq('collection', taskCollections.PROJECTS),
    R.complement(R.path(['additional', 'project', 'managerId'])),
  ),
);

function SendAutomaticEmailCheckbox({ setIsSendAutomaticEmail, ...props }) {
  const { value } = props;

  useEffect(() => {
    if (setIsSendAutomaticEmail) {
      setIsSendAutomaticEmail(value);
    }
  }, [value, setIsSendAutomaticEmail]);

  return <CheckboxS {...props} />;
}

SendAutomaticEmailCheckbox.propTypes = {
  value: bool,
  setIsSendAutomaticEmail: func,
};

// validateMailTo :: Boolean -> Object -> Boolean
const validateMailTo = (isSendAutomaticEmail) =>
  R.compose(
    R.both(R.always(isSendAutomaticEmail), R.complement(R.isEmpty)),
    R.flatten,
    R.map(R.propOr([], 'emails')),
    R.filter(R.prop('shouldSend')),
    R.values,
  );

export const taskFormSections = ({
  projectDbId,
  isCreateAction,
  disableMentions,
  isTaskUpdateMessageOptional,
  customFormConfig,
  task,
  payload,
  onChangePersistentValue,
  isSendAutomaticEmail,
  setIsSendAutomaticEmail,
  projectPropertyId,
}) => {
  const isManagerRequired = isTaskManagerRequired(payload);
  const isProjectWithProperty = R.is(String, projectPropertyId);
  const shouldValidateRootMailTo =
    isProjectWithProperty && isSendAutomaticEmail;

  return [
    {
      id: 'main',
      layout: { column: 1, margin: 0 },
      order: 1,
      fields: [
        {
          order: 1,
          layout: { row: 1, width: 'calc(75% - 10px)', margin: '0 10px 0 0' },
          label: 'Task Name',
          field: {
            name: 'description',
            Component: (props) => (
              <Textarea
                style={{ resize: 'none' }}
                extendable
                autoFocus
                placeholder="Enter task name"
                {...props}
              />
            ),
          },
          required: true,
          renderIf: checkShowTaskTitleInput,
          validators: [
            [R.identity, 'Task name is required'],
            [
              isValidTaskDescriptionLength,
              `Maximal task name's length is ${MAX_TASK_DESCRIPTION_LENGTH} characters`,
            ],
          ],
        },
        {
          order: 2,
          layout: { row: 1, width: '25%' },
          label: 'Priority',
          field: {
            name: 'priority',
            Component: TaskPrioritySelect,
          },
          renderIf: R.anyPass([isAddAction, isEditAction]),
        },
        {
          order: 2,
          layout: { row: 2 },
          field: {
            name: 'entity',
            Component: (props) => <EntityInfoView section {...props} />,
          },
          renderIf: R.prop('entity'),
        },
        {
          order: 3,
          layout: { row: 3 },
          field: {
            name: 'isSendAutomaticEmail',
            withChangeFieldValue: true,
            Component: SendAutomaticEmailCheckbox,
            additionalProps: {
              label: 'Send Automatic Email',
              setIsSendAutomaticEmail,
            },
          },
          renderIf: R.both(
            R.propEq('taskType', taskActionTypes.CONFIRM_SATISFACTION),
            R.always(isProjectWithProperty),
          ),
        },
        {
          label: 'Update',
          order: 3,
          layout: { row: 4 },
          field: {
            name: 'details',
            withFormData: true,
            Component: (props) => (
              <DnDAreaWithMentionsWrapper
                disableMentions={disableMentions}
                onChangePersistentValue={onChangePersistentValue}
                {...props}
              />
            ),
          },
          validators: !isTaskUpdateMessageOptional
            ? dNdTextAreaFileValidators
            : commonFileValidators,

          validateFunction: validateFilesFunc,
          renderIf: R.complement(
            R.both(
              R.propOr(false, 'isSendAutomaticEmail'),
              R.always(isProjectWithProperty),
            ),
          ),
        },
        ...(customFormConfig || []),
        {
          order: 4,
          layout: { row: 8, width: halfWidth, isWrapped: true },
          label: 'Due Date',
          field: {
            name: 'dueDate',
            Component: (props) => (
              <DatePickerWrapper isCreateAction={isCreateAction} {...props} />
            ),
          },
          renderIf: R.anyPass([isAddAction, isEditAction]),
          validators: dateValidators,
        },
        {
          order: 5,
          layout: { row: 8, width: halfWidth, isWrapped: true },
          field: {
            name: 'nextTask',
            Component: NextTaskDueDateField,
          },
          renderIf: R.allPass([
            R.propEq('isDefaultTask', true),
            R.prop('nextTask'),
            isCompleteAction,
          ]),
          validators: [[R.prop('dueDate'), 'Date is required']],
        },
        {
          label: 'Assigned to',
          order: 6,
          layout: { row: 8, width: halfWidth },
          field: {
            name: 'managerId',
            Component: TaskAssignUsersSelect,
            required: isManagerRequired,
          },
          validators: isManagerRequired
            ? [[R.identity, 'Manager is required']]
            : [],
          renderIf: R.anyPass([
            isAddAction,
            isEditAction,
            R.allPass([
              isCompleteAction,
              R.propEq('isDefaultTask', true),
              R.complement(R.propEq('taskType', taskActionTypes.CLOSE_PROJECT)),
            ]),
          ]),
        },
        {
          label: 'Schedule Date*',
          order: 7,
          layout: { row: 9, width: halfWidth },
          field: {
            name: 'scheduleDate',
            Component: DatePickerWrapper,
            required: true,
          },
          validators: [[R.identity, 'Schedule Date is required']],
          renderIf: R.allPass([
            () =>
              R.pathSatisfies(
                R.is(String),
                ['document', 'project', 'parent', '_id'],
                task,
              ),
            isCompleteAction,
            R.propEq('isDefaultTask', true),
            R.propEq('taskType', taskActionTypes.SUPPLIER_SCHEDULING),
          ]),
        },
        {
          order: 7,
          layout: { row: 9, width: halfWidth },
          label: 'Work Completion Date',
          field: {
            name: 'workCompletionDate',
            Component: DatePickerWrapper,
          },
          validators: dateValidators,
          renderIf: R.either(
            R.both(
              () =>
                R.pathEq(
                  ['project', 'status'],
                  WorkOrderStatus.COMPLETED,
                  payload,
                ),
              () => R.propEq('action', taskActionTypes.PROJECT_COMPLETE, task),
            ),
            R.both(
              R.either(
                R.propEq('taskType', taskActionTypes.PROJECT_COMPLETE),
                R.both(
                  R.propEq('taskType', taskActionTypes.CLOSE_PROJECT),
                  R.propEq('projectType', ProjectType.FEE),
                ),
              ),
              isCompleteAction,
            ),
          ),
        },
        {
          order: 9,
          layout: { row: 11 },
          field: {
            name: 'mailTo',
            Component: (props) => (
              <MailTo projectDbId={projectDbId} {...props} />
            ),
            additionalProps: { isCollapseOpened: isSendAutomaticEmail },
          },
          validators: [
            ...(shouldValidateRootMailTo
              ? [
                  [
                    null,
                    [
                      [
                        validateMailTo(isSendAutomaticEmail),
                        'At least one email address should be selected',
                      ],
                    ],
                  ],
                ]
              : []),
            ...mailToValidator,
          ],
          validateFunction: validateMailToField,
          renderIf: R.both(
            isDocumentProjectRelated,
            R.complement(R.propEq('isHQProject', true)),
          ),
        },
        {
          order: 10,
          layout: { row: 12 },
          field: {
            name: 'supplierRatings',
            Component: SupplierRatings,
          },
          validators: [
            [
              R.compose(
                R.all(R.propSatisfies(R.gt(R.__, 0), 'rating')),
                R.reject(
                  R.either(
                    R.prop('isRatedOnComplete'),
                    R.propEq(
                      'supplierRequest',
                      supplierInvoiceRequestOptions.sendLater,
                    ),
                  ),
                ),
              ),
              'You must rate all suppliers',
            ],
          ],
          renderIf: R.allPass([
            R.propSatisfies(R.complement(R.isEmpty), 'supplierRatings'),
            R.propEq('taskType', taskActionTypes.PROJECT_COMPLETE),
            isCompleteAction,
          ]),
        },
      ],
    },
  ];
};
