import React from 'react';
import { shape } from 'prop-types';
import { formatDateTime } from 'poly-utils';

import {
  FlexColumn,
  FlexFullWidthJustifyStart,
  SearchResultItemContainer,
} from './common/wrappers.js';
import {
  SearchResultItemLinked,
  SearchResultItemTitle,
} from './common/components.js';
import { highlightedTextPropType } from './common/propTypes.js';

export function FilesSearchResultItem({ fileName, createdBy, createdAt }) {
  return (
    <SearchResultItemContainer>
      <FlexColumn>
        <SearchResultItemLinked>{fileName}</SearchResultItemLinked>
        <FlexFullWidthJustifyStart>
          <SearchResultItemTitle>
            {createdBy?.fullName},{formatDateTime(createdAt)}
          </SearchResultItemTitle>
        </FlexFullWidthJustifyStart>
      </FlexColumn>
    </SearchResultItemContainer>
  );
}

FilesSearchResultItem.propTypes = {
  fileName: highlightedTextPropType.isRequired,
  createdBy: shape({
    fullName: highlightedTextPropType.isRequired,
  }),
  createdAt: highlightedTextPropType.isRequired,
};
