import * as R from 'ramda';
import { calculateTotal, roundTo, getClientInvoiceDueDate } from 'poly-utils';
import { recalculatePaymentByDiscount } from '../../components/PaymentsFromComponents/helpers.js';

// transformClientInvoicesForPayment :: [ClientInvoice] -> [InvoicePayment]
export const transformClientInvoicesForPayment = R.map(
  R.applySpec({
    _id: R.prop('_id'),
    invoiceNumber: R.prop('number'),
    projectId: R.path(['project', 'projectId']),
    consolidatedClientInvoiceNumber: R.path([
      'consolidatedClientInvoice',
      'invoiceNumber',
    ]),
    invoiceDate: R.prop('invoiceDate'),
    dueDate: R.converge(getClientInvoiceDueDate, [
      R.compose(R.constructN(1, Date), R.prop('createdAt')),
      R.prop('client'),
    ]),
    total: R.prop('amount'),
    balanceAmount: R.prop('balance'),
    currentBalance: R.prop('balance'),
  }),
);

// roundAmount :: Number -> Number
const roundAmount = roundTo(0);

// generatePaymentsInput :: [InvoicePayment] -> [PayClientInvoiceInput]
export const generatePaymentsInput = R.compose(
  R.map(
    R.converge(R.mergeRight, [
      R.applySpec({
        invoiceId: R.prop('_id'),
        paidAmount: R.compose(roundAmount, R.prop('paidAmount')),
      }),
      R.ifElse(
        R.prop('deductionsAmount'),
        R.applySpec({
          deductionsAmount: R.prop('deductionsAmount'),
          discountAccountId: R.prop('discountAccountId'),
        }),
        R.always({}),
      ),
    ]),
  ),
  R.filter(R.prop('isSelected')),
);

const getInvoiceAmount = R.prop('total');
const getDeductionsAmount = R.propOr(0, 'deductionsAmount');

// calculateSelectedInvoicesTotal :: [InvoicePayment] -> [InvoicePayment]
const calculateSelectedInvoicesTotal = R.compose(
  calculateTotal(getInvoiceAmount),
  R.filter(R.prop('isSelected')),
);

// calculateSelectedInvoicesDeductionsTotal :: [InvoicePayment] -> [InvoicePayment]
export const calculateSelectedInvoicesDeductionsTotal = R.compose(
  calculateTotal(getDeductionsAmount),
  R.filter(R.prop('isSelected')),
);

// distributeInvoicesDiscount :: Number -> [InvoicePayment] -> [InvoicePayment]
export const distributeInvoicesDiscount = (
  discount,
  invoices,
  discountAccountId,
) => {
  const selectedInvoicesTotal = calculateSelectedInvoicesTotal(invoices);
  const discountFraction = discount / selectedInvoicesTotal;

  return invoices.map((invoice) => {
    if (!invoice.isSelected) {
      return invoice;
    }
    const { total, paidAmount = 0, currentBalance } = invoice;
    const invoiceDiscount = roundAmount(total * discountFraction);
    const newPaidAmount = recalculatePaymentByDiscount(
      currentBalance,
      invoiceDiscount,
      paidAmount,
    );
    return {
      ...invoice,
      paidAmount: newPaidAmount,
      deductionsAmount: invoiceDiscount,
      discountAccountId,
    };
  });
};

// adjustDiscount :: Number -> [Invoice]
export const adjustDiscount = (discountFraction, invoices) =>
  R.compose(
    R.adjust(
      R.__,
      R.compose(
        R.over(
          R.lensProp('paidAmount'),
          R.compose(roundAmount, R.subtract(R.__, discountFraction)),
        ),
        R.over(
          R.lensProp('deductionsAmount'),
          R.compose(roundAmount, R.add(discountFraction)),
        ),
      ),
      invoices,
    ),
    R.findIndex(
      R.allPass([
        R.prop('isSelected'),
        R.compose(
          R.gte(R.__, Math.abs(discountFraction)),
          R.prop('deductionsAmount'),
        ),
        R.compose(
          R.gte(R.__, Math.abs(discountFraction)),
          R.prop('paidAmount'),
        ),
      ]),
    ),
  )(invoices);
