import styled from 'styled-components';
import { getThemeColor, MultiselectDropdown } from 'poly-book-admin';

export const MultiSelect = styled(MultiselectDropdown)`
  .multiselect__indicators {
    display: none;
  }

  .multiselect__control {
    padding-right: 10px;
  }

  .multiselect__multi-value__label {
    font-size: 14px;
  }

  .multiselect__control:hover,
  .multiselect__control:focus,
  .multiselect__control--is-focused {
    border: 1px solid ${getThemeColor(['primaryLight'])};
    box-shadow: unset;
  }

  min-width: 282px;
  margin: 7px 0 0 20px;
`;
