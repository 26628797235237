import * as R from 'ramda';
import { applySpecWithFields, ensureIsDate } from 'poly-utils';
import { getDate, getDay } from 'date-fns';

import {
  repeatsByArray,
  scheduleDaysArray,
  scheduleRepeatsTextUI,
  scheduleRepeatsSingularText,
} from '../../modules/core/constants/schedule.js';

// getRepeatsEvery :: Project -> String
const getRepeatsEvery = R.pipe(
  R.prop('schedule'),
  applySpecWithFields({
    repeats: R.ifElse(
      R.propSatisfies(R.equals(1), 'every'),
      R.compose(R.prop(R.__, scheduleRepeatsSingularText), R.prop('repeats')),
      R.compose(R.prop(R.__, scheduleRepeatsTextUI), R.prop('repeats')),
    ),
  }),
  R.props(['every', 'repeats']),
  R.join(' '),
);

// mapDaysToUIText :: [Days] -> String
const mapDaysToUIText = R.pipe(
  R.defaultTo([]),
  R.ifElse(
    R.isEmpty,
    R.always(''),
    R.pipe(
      R.map(
        R.pipe(
          (day) => R.find(R.propEq('day', day), scheduleDaysArray),
          R.prop('fullName'),
        ),
      ),
      R.join(', '),
      (str) => R.concat(' on ', str),
    ),
  ),
);

// getDays :: Project -> String
const getDays = R.pipe(R.path(['schedule', 'days']), mapDaysToUIText);

// getRepeatsBy :: Project -> String
const getRepeatsBy = R.ifElse(
  R.pipe(
    R.path(['schedule', 'repeats']),
    R.equals,
    R.any(R.__, repeatsByArray),
  ),
  R.ifElse(
    R.path(['schedule', 'weekDay']),
    R.pipe(R.prop('startDate'), ensureIsDate, getDay, R.of, mapDaysToUIText),
    R.pipe(
      R.prop('startDate'),
      ensureIsDate,
      getDate,
      R.toString,
      R.concat(' on day '),
    ),
  ),
  R.always(''),
);

// getNeverEnds :: Project -> String
const getNeverEnds = R.ifElse(
  R.either(R.prop('never'), R.complement(R.prop('endDate'))),
  R.always(' Never Ends'),
  R.always(''),
);

// getRecurringSummary :: Project -> String
export const getRecurringSummary = R.converge(R.compose(R.join(''), Array.of), [
  getRepeatsEvery,
  getRepeatsBy,
  getDays,
  getNeverEnds,
]);
