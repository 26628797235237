import * as R from 'ramda';
import { Checkbox, Input, InputHTML5 } from 'poly-book-admin';
import { optionalAddressValidators } from 'poly-client-utils';
import { PercentInput } from 'poly-admin-ui';
import { CLIENT_VA_ACCOUNT_LENGTH_LIMIT } from 'poly-constants';
import { commonEmailValidators } from 'poly-client-utils/src/formValidators.js';

import { ClientInvoiceToSelect } from '../components/ClientInvoiceToSelect.js';
import { ClientTermsSelect } from '../components/ClientTermsSelect.js';
import { DivisionSelect } from '../../../../../components/DivisionSelect.js';
import { ClientSpentReportSelect } from '../components/ClientSpendReportSelect.js';
import { ImageLogoInput } from '../components/ImageLogoInput.js';
import { InvoicingAddressInput } from '../components/InvoicingAddressInput.js';

export const ourVendorFormField = {
  label: 'Our Vendor #',
  field: {
    name: 'financial.ourvendorno',
    Component: Input,
    additionalProps: {
      maxLength: 50,
      charactersLimit: 50,
      showCharactersLeft: true,
    },
  },
  validators: [[R.test(/^[a-zA-Z0-9]*$/), 'Symbols are not allowed']],
};

export const divisionFormField = {
  label: 'Division',
  field: {
    name: 'financial.divisionAccountId',
    Component: DivisionSelect,
  },
};

export const invoiceToFormField = {
  label: 'Invoice To',
  field: {
    name: 'financial.invoiceto',
    Component: ClientInvoiceToSelect,
  },
};

export const termsFormField = {
  label: 'Terms',
  field: {
    name: 'financial.terms',
    Component: ClientTermsSelect,
  },
  required: true,
};

export const invoiceAttentionToFormField = {
  label: 'Invoice Attention To',
  field: {
    name: 'financial.invoiceAttentionTo',
    Component: Input,
  },
};

export const costCenterFormField = {
  label: 'Cost Center',
  field: {
    name: 'financial.costCenter',
    Component: Input,
  },
};

export const accountingEmailFormField = {
  label: 'Accounting Email',
  field: {
    name: 'financial.accountingEmail',
    Component: Input,
  },
  validators: commonEmailValidators,
};

export const clientSpendReportTypeField = {
  label: 'Spend Report Type',
  field: {
    name: 'financial.spendReportType',
    Component: ClientSpentReportSelect,
  },
  required: true,
};

export const clientInvoicingLogoFormField = {
  label: 'Invoicing Logo',
  field: {
    name: 'configs.clientInvoicing.companyLogo',
    Component: ImageLogoInput,
  },
};

export const clientInvoicingAddressFormField = {
  field: {
    name: 'configs.clientInvoicing.address',
    Component: InvoicingAddressInput,
  },
  validators: optionalAddressValidators,
};

export const invoiceAttentionToProjectRequestorFormField = {
  label: 'Project Requestor',
  field: {
    name: 'financial.invoiceAttentionToProjectRequestor',
    Component: Checkbox,
  },
};

export const sapGLCodeFormField = {
  label: 'SAP GL Code',
  field: {
    name: 'sapGLCode',
    Component: InputHTML5,
    additionalProps: {
      maxLength: 40,
      charactersLimit: 40,
      showCharactersLeft: true,
    },
  },
};

export const vaAccountNumberFormField = {
  label: 'VA Account #',
  field: {
    name: 'financial.vaAccountNumber',
    Component: Input,
    additionalProps: {
      maxLength: CLIENT_VA_ACCOUNT_LENGTH_LIMIT,
      charactersLimit: CLIENT_VA_ACCOUNT_LENGTH_LIMIT,
      showCharactersLeft: true,
    },
  },
};

export const vaSplitPercentFormField = {
  label: 'VA Split %',
  field: {
    name: 'financial.vaSplitPercent',
    Component: PercentInput,
  },
  required: true,
};
