import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, func, string, bool, shape } from 'prop-types';
import {
  Row,
  Body,
  Cell,
  Head,
  TableS,
  HeaderRow,
  HeaderCell,
  getThemeColor,
} from 'poly-book-admin';

function TableHeader({ headers }) {
  return (
    <Head>
      <HeaderRow>
        {headers.map((title) => (
          <HeaderCell key={title}>
            <span>{title}</span>
          </HeaderCell>
        ))}
      </HeaderRow>
    </Head>
  );
}

TableHeader.propTypes = { headers: arrayOf(string).isRequired };

const InnerRow = styled(Row)`
  margin: 0;
  width: 100%;

  &:last-child {
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  &:nth-child(odd) {
    background-color: ${getThemeColor(['table', 'rowBg'])};
  }

  &:nth-child(even) {
    background-color: ${getThemeColor(['white'])};
  }
`;

const CellS = styled(Cell)`
  height: 40px;
`;

const FirstBlock = styled(CellS)`
  background-color: ${getThemeColor(['white'])} !important;
  vertical-align: top;

  :not(:last-child) {
    border-bottom: 1px solid ${getThemeColor(['midLight'])};
  }
`;

function TableBlock({
  rows,
  columns,
  headers,
  SummaryBlock,
  enableRowHover,
  enableInnerRowHover,
}) {
  const filteredColumns = R.remove(0, 1, columns);
  const sortedRows = R.sortBy(R.prop('date'), rows);
  return (
    <Row enableHoverEffect={enableRowHover}>
      <FirstBlock>{columns[0](rows[0])}</FirstBlock>
      <td>
        <table>
          <tbody>
            {sortedRows.map((row) => (
              <InnerRow key={row._id} enableHoverEffect={enableInnerRowHover}>
                {filteredColumns.map((colFunc, colNum) => (
                  <CellS key={`${row._id}_${headers[colNum]}`}>
                    {colFunc(row)}
                  </CellS>
                ))}
              </InnerRow>
            ))}
            {!!SummaryBlock && <SummaryBlock myTimesheets={rows} />}
          </tbody>
        </table>
      </td>
    </Row>
  );
}

TableBlock.propTypes = {
  SummaryBlock: func,
  enableRowHover: bool,
  enableInnerRowHover: bool,
  columns: arrayOf(func).isRequired,
  headers: arrayOf(string).isRequired,
  rows: arrayOf(shape({ _id: string.isRequired })).isRequired,
};

export function TableWrapper({
  headers,
  columns,
  tableList,
  className,
  SummaryBlock,
  enableRowHover,
  enableInnerRowHover,
}) {
  return (
    <TableS {...{ className }}>
      <TableHeader {...{ headers }} />
      <Body>
        {tableList.map((rows) => (
          <TableBlock
            key={rows[0]._id}
            {...{
              rows,
              columns,
              headers,
              SummaryBlock,
              enableRowHover,
              enableInnerRowHover,
            }}
          />
        ))}
      </Body>
    </TableS>
  );
}

TableWrapper.propTypes = {
  className: string,
  SummaryBlock: func,
  columns: arrayOf(func).isRequired,
  headers: arrayOf(string).isRequired,
  tableList: arrayOf(
    arrayOf(
      shape({
        _id: string.isRequired,
      }),
    ),
  ).isRequired,
  enableRowHover: bool,
  enableInnerRowHover: bool,
};

TableWrapper.defaultProps = { className: '' };
