import React from 'react';
import { DESC_SORT_ORDER } from 'poly-constants';
import { useRouterParams } from 'poly-client-routing';
import { object } from 'prop-types';
import { Loader } from 'poly-book-admin';
import { commonProjectTableConfig } from '../../tables/projectsTable/ProjectsTable.js';
import { ProjectsTableBase } from './ProjectsTableBase.js';
import { useProjectsBySearch } from './useProjectsBySearch.js';
import { ProjectsTabContainer } from './ProjectsTabContainer.js';
import { PROJECTS_BY_SEARCH } from '../../core/hooks/projects/queries.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../core/hooks/projects/subscriptions.js';
import { useSaveQueryForXLSExport } from '../../core/tableToolbar/TableExportToXLSBtn.js';

export const useSupplierProjectsQuery = (searchQuery) => {
  const { supplierId } = useRouterParams(['supplierId']);
  const { projects, total, sortTableProps, loading } = useProjectsBySearch({
    gqlQuery: PROJECTS_BY_SEARCH,
    gqlSubscription: PROJECTS_BY_SEARCH_SUB,
    additionalVars: { supplierId },
    projectsPath: ['searchProjects'],
    tableConfig: commonProjectTableConfig,
    initialSorting: { order: DESC_SORT_ORDER, column: 8 },
    searchQuery,
  });
  return { projects, total, sortTableProps, loading };
};

function SupplierProjectsSubTabBase({ query }) {
  const { projects, total, sortTableProps, loading } =
    useSupplierProjectsQuery(query);

  return (
    <ProjectsTabContainer total={total} paginationVisible>
      {loading ? (
        <Loader />
      ) : (
        <ProjectsTableBase
          projects={projects}
          sortProps={sortTableProps}
          tableConfig={commonProjectTableConfig}
          total={total}
        />
      )}
    </ProjectsTabContainer>
  );
}

SupplierProjectsSubTabBase.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  query: object,
};

export function SupplierProjectsSubTab(props) {
  useSaveQueryForXLSExport(props);
  return <SupplierProjectsSubTabBase {...props} />;
}
