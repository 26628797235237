import React from 'react';
import { Checkbox } from 'poly-book-admin';
import { bool, func, string } from 'prop-types';

export function SelectPaymentCheckBox({ _id, isSelected, onSelectPayment }) {
  const componentName = `select-payment-checkbox-${_id}`;

  return (
    <Checkbox
      name={componentName}
      value={isSelected}
      onChange={onSelectPayment}
      dataTestId={componentName}
    />
  );
}

SelectPaymentCheckBox.propTypes = {
  _id: string,
  isSelected: bool,
  onSelectPayment: func,
};
