import {
  UPDATE_PROJECT_PERMISSION,
  UPDATE_RECURRING_PROJECT_PERMISSION,
} from 'poly-security';
import { useHasUserAccessWithPermission } from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';

export const useHasUserAccessToUpdateProject = (isRecurringProject = false) => {
  const permission = isRecurringProject
    ? UPDATE_RECURRING_PROJECT_PERMISSION
    : UPDATE_PROJECT_PERMISSION;

  const hasUpdateProjectPermission = useHasUserAccessWithPermission(permission);

  return hasUpdateProjectPermission;
};
