import {
  bool,
  shape,
  string,
  number,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { READ_SENSITIVE_FIELD_USER_PERMISSION } from 'poly-security';
import { formatDate, formatCurrency } from 'poly-utils';
import { SidebarRow } from 'poly-admin-ui';

import {
  SectionLabel,
  BlockWithLabel,
  threeBlocksProps,
  SectionWrapperWithoutBorder,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import {
  getSectionEmail,
  getSectionPhoneLink,
  getSectionText,
} from '../components/commonSidebarSectionFormatters.js';
import {
  getUserEmailOrNothingUI,
  getUserFormattedValue,
  titleCaseCurried,
  getUserRateType,
} from './userSidebarUtils.js';

export function UserSidebarEmploymentInfo({ user }) {
  const hasViewPermission = useHasUserAccessWithPermission(
    READ_SENSITIVE_FIELD_USER_PERMISSION,
  );

  const label = `${
    user.isContractor ? 'Contractor' : 'Employment'
  } Information`;

  return (
    <SectionWrapperWithoutBorder>
      <SidebarRow>
        <SectionLabel>{label}</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          label="Status"
          id="employee-status"
          {...threeBlocksProps}
          Component={getSectionText(
            getUserFormattedValue(
              R.path(['employeeInfo', 'status'], user),
              titleCaseCurried,
            ),
          )}
        />
        <BlockWithLabel
          id="title"
          label="Title"
          {...threeBlocksProps}
          Component={getSectionText(
            getUserFormattedValue(R.prop('title', user), titleCaseCurried),
          )}
        />
      </SidebarRow>
      {hasViewPermission && (
        <SidebarRow {...threeBlocksWrapperProps}>
          <BlockWithLabel
            id="hire-date"
            label="Hire Date"
            {...threeBlocksProps}
            Component={getSectionText(
              getUserFormattedValue(
                R.path(['employeeInfo', 'startDate'], user),
                formatDate,
              ),
            )}
          />
          <BlockWithLabel
            id="termination"
            label="Termination"
            {...threeBlocksProps}
            Component={getSectionText(
              getUserFormattedValue(
                R.path(['employeeInfo', 'terminationDate'], user),
                formatDate,
              ),
            )}
          />
        </SidebarRow>
      )}
      <SidebarRow justify>
        <BlockWithLabel
          id="rate-type"
          label="Rate Type"
          {...threeBlocksProps}
          Component={getSectionText(
            getUserFormattedValue(
              R.path(['employeeInfo', 'payRateType'], user),
              getUserRateType,
            ),
          )}
        />
        <BlockWithLabel
          id="rate"
          label="Rate"
          {...threeBlocksProps}
          Component={getSectionText(
            getUserFormattedValue(
              R.path(['employeeInfo', 'payRate'], user),
              formatCurrency,
            ),
          )}
        />
        <BlockWithLabel
          id="location"
          label="Location"
          {...threeBlocksProps}
          Component={getSectionText(
            getUserFormattedValue(
              R.prop('companyLocation', user),
              titleCaseCurried,
            ),
          )}
        />
      </SidebarRow>
      <SidebarRow justify>
        <BlockWithLabel
          id="phone"
          label="Phone"
          {...threeBlocksProps}
          Component={getSectionPhoneLink(
            user.profile.workPhoneNumber,
            user.profile.workPhoneNumberExt,
          )}
        />
        <BlockWithLabel
          id="mobile"
          label="Mobile"
          {...threeBlocksProps}
          Component={getSectionPhoneLink(user.profile.cellPhoneNumber)}
        />
        <BlockWithLabel
          label="Email"
          id="employee-email"
          {...threeBlocksProps}
          Component={getSectionEmail(getUserEmailOrNothingUI(user))}
        />
      </SidebarRow>
    </SectionWrapperWithoutBorder>
  );
}

UserSidebarEmploymentInfo.propTypes = {
  user: shape({
    title: string,
    isContractor: bool,
    companyLocation: string,
    emails: arrayOf(shape({ address: string })),
    employeeInfo: shape({
      terminationDate: oneOfType([instanceOf(Date), string]),
      startDate: oneOfType([instanceOf(Date), string]),
      payRateType: string,
      payRate: number,
      status: string,
    }),
    profile: shape({
      workPhoneNumberExt: string,
      cellPhoneNumber: string,
      workPhoneNumber: string,
    }),
  }).isRequired,
};
