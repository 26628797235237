import React from 'react';
import {
  DefaultBodyWrapper,
  PageHeaderContainer,
  MainHeader,
} from 'poly-book-admin';

export function TimeSheetReportsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Time Sheet Reports</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper />
    </>
  );
}
