import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { func, string, node, oneOfType } from 'prop-types';
import { Button, ModalActions, Text } from 'poly-book-admin';
import { useLocation } from 'poly-client-routing';

import { useProcessState } from '../../../hooks/useProcessState.js';
import {
  CancelBtn,
  confirmCancelFormPayload,
  SubmitBtn as SubmitBtnC,
} from '../../common.js';

const ModalWrapper = styled.div`
  min-width: 600px;
  width: ${R.prop('width')};

  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
  }

  @media (max-width: 1279px) {
    margin-bottom: 40px;
  }
`;

function SubmitBtn({ formId, ...props }) {
  const { process } = useProcessState(formId);
  return (
    <SubmitBtnC loader={process} disabled={process} form={formId} {...props} />
  );
}

SubmitBtn.propTypes = {
  formId: string.isRequired,
};

function CancelModalFormBtn({ openConfirmModal, closeCurrentModal, formId }) {
  const { process } = useProcessState(formId);
  const { state } = useLocation();

  const onClick = () =>
    state?.pristine
      ? closeCurrentModal()
      : openConfirmModal({
          ...confirmCancelFormPayload,
          content: confirmCancelFormPayload.message,
          onConfirm: (closeConfirmModal) =>
            R.compose(closeConfirmModal, closeCurrentModal),
        });

  return <CancelBtn onClick={onClick} disabled={process} />;
}

CancelModalFormBtn.propTypes = {
  openConfirmModal: func.isRequired,
  closeCurrentModal: func.isRequired,
  formId: string.isRequired,
};

const RejectBtn = styled(Button).attrs(() => ({
  size: 'small',
  styleType: 'basicSecondary',
  type: 'button',
  children: 'Reject',
}))``;

export function ModalFormLayout({
  title,
  subTitle,
  btnCaption,
  formId,
  width,
  children,
  closeCurrentModal,
  openConfirmModal,
  submitDataTestId,
  onClickReject,
  additionalFooter,
  submitMessage,
}) {
  return (
    <ModalWrapper {...{ width }}>
      <Text size="20px">{title}</Text>
      {!!subTitle && (
        <Text size="14px" lineHeight="40px">
          {subTitle}
        </Text>
      )}
      {children}
      <ModalActions>
        <CancelModalFormBtn
          formId={formId}
          closeCurrentModal={closeCurrentModal}
          openConfirmModal={openConfirmModal}
        />
        {onClickReject && <RejectBtn onClick={onClickReject} />}
        <SubmitBtn formId={formId} data-testid={submitDataTestId}>
          {btnCaption}
        </SubmitBtn>
        {submitMessage}
      </ModalActions>
      {additionalFooter}
    </ModalWrapper>
  );
}

ModalFormLayout.propTypes = {
  title: string,
  subTitle: oneOfType([string, node]),
  btnCaption: string.isRequired,
  formId: string.isRequired,
  closeCurrentModal: func.isRequired,
  openConfirmModal: func.isRequired,
  children: node.isRequired,
  submitDataTestId: string,
  width: string,
  onClickReject: func,
  additionalFooter: node,
  submitMessage: node,
};

ModalFormLayout.defaultProps = {
  submitDataTestId: 'submit-modal-test-id',
  submitMessage: '',
};
