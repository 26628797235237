import * as R from 'ramda';

export const TimesheetTypes = {
  STRAIGHT_TIME: 'straightTime',
  OVER_TIME: 'overTime',
  DOUBLE_TIME: 'doubleTime',
  PTO: 'pto',
  HOLIDAY: 'holiday',
};

// isManualTotalTimesheet :: Timesheet -> Boolean
export const isManualTotalTimesheet = R.anyPass([
  R.prop('isManual'),
  R.has('projectId'),
  R.propSatisfies(R.isNil, 'entries'),
  R.propEq('type', TimesheetTypes.PTO),
  R.propEq('type', TimesheetTypes.HOLIDAY),
]);
