import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, shape } from 'prop-types';
import { useMapConfigToTableProps } from 'poly-admin-ui/src/hooks/useMapConfigToTableProps.js';
import { pathOrNothingUI } from 'poly-client-utils/src/objects.js';
import { Table } from 'poly-book-admin/src/Table/Table.js';
import {
  keywordNestedSortQuery,
  commonSortQuery,
  keywordSortQuery,
} from 'poly-client-utils/src/sorting.js';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { suppliersTableItemConfig } from '../WorkInProgressQCReport/WorkInProgressQCTable.js';
import { getProjectDaysOpen } from '../../utils/project.js';

const OpenWorkOrdersReportTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 350px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 100px;
  }
`;

export const getOpenWorkOrdersReportTable = (isPrintPDF) => [
  [
    'Project #',
    (project) => <ProjectLink {...project} isPrintPDF={isPrintPDF} />,
    commonSortQuery(['projectId']),
  ],
  [
    ...suppliersTableItemConfig,
    keywordNestedSortQuery(['suppliers', 'company', 'name']),
  ],
  [
    'Service Type',
    pathOrNothingUI(['serviceType', 'name']),
    commonSortQuery(['serviceType', 'name']),
  ],
  ['Description', pathOrNothingUI(['description'])],
  ['Age(in days)', getProjectDaysOpen, commonSortQuery(['startDate'])],
  [
    'Assigned CSR',
    pathOrNothingUI(['manager', 'fullName']),
    keywordSortQuery(['manager', 'profile', 'fullName']),
  ],
];

export function OpenWorkOrdersReportTable({
  data,
  isPrint,
  pagination,
  ...restTableProps
}) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    getOpenWorkOrdersReportTable(isPrint),
    R.pathOr([], ['searchProjects', 'hits'], data),
  );

  return (
    <OpenWorkOrdersReportTableS
      pagination={pagination}
      {...restTableProps}
      {...tableProps}
    />
  );
}

OpenWorkOrdersReportTable.propTypes = {
  data: shape({}),
  isPrint: bool,
  pagination: shape({}),
};
