import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useEntityMultipleSubscription } from 'poly-client-utils';

import { recurringProjectDetailsFields } from '../../modules/core/hooks/projects/fragments.js';
import { purchaseOrderDetailSubs } from '../PurchaseOrderSidebar/usePurchaseOrderDetail.js';

const SUPPLIER_COMPANY_NAME_SUB = gql`
  subscription SUPPLIER_COMPANY_NAME_SUB($input: SingleDocSubInput!) {
    supplierChanged(input: $input) {
      id
      document {
        _id
        company {
          name
        }
      }
    }
  }
`;

const PROPERTY_NAME_SUB = gql`
  subscription PROPERTY_NAME_SUB($input: SingleDocSubInput!) {
    propertyChanged(input: $input) {
      id
      document {
        _id
        name
      }
    }
  }
`;

export const CLIENT_NAME_SUB = gql`
  subscription CLIENT_NICK_NAME_SUB($input: SingleDocSubInput!) {
    clientChanged(input: $input) {
      id
      document {
        _id
        nickName
        name
        configs {
          enablePurchaseOrder
        }
      }
    }
  }
`;

const RECURRING_PROJECT_DETAILS = gql`
  query RECURRING_PROJECT_DETAILS($projectId: ID, $id: ID) {
    recurringProject(projectId: $projectId, id: $id) {
      ...recurringProjectDetailsFields
    }
  }

  ${recurringProjectDetailsFields}
`;

const RECURRING_PROJECT_DETAILS_SUB = gql`
  subscription RECURRING_PROJECT_DETAILS_SUB($input: SingleDocSubInput!) {
    recurringProjectChanged(input: $input) {
      id
      document {
        ...recurringProjectDetailsFields
      }
    }
  }
  ${recurringProjectDetailsFields}
`;

// getRecurringProjectSubOptions :: Boolean -> MasterRecurringProjectResult -> SubscriptionOptions
const getRecurringProjectSubOptions = (skipRecurringProjectDetailsSub) =>
  R.applySpec({
    skip: R.either(
      R.compose(R.isNil, R.path(['recurringProject', '_id'])),
      R.always(skipRecurringProjectDetailsSub),
    ),
    variables: R.compose(
      R.objOf('input'),
      R.objOf('id'),
      R.path(['recurringProject', '_id']),
    ),
  });

// getSubscriptionOptionsByPath :: [String], MasterRecurringProjectResult -> SubscriptionOptions
export const getSubscriptionOptionsByPath = (path) =>
  R.applySpec({
    skip: R.compose(R.isNil, R.path(path)),
    variables: R.compose(R.objOf('input'), R.objOf('id'), R.path(path)),
  });

// getPropertySubOptions :: MasterRecurringProjectResult -> SubscriptionOptions
const getPropertySubOptions = R.applySpec({
  skip: R.compose(R.isNil, R.path(['recurringProject', 'property', '_id'])),
  variables: R.compose(
    R.objOf('input'),
    R.objOf('id'),
    R.path(['recurringProject', 'property', '_id']),
  ),
});

// getSuppliersSubOptions :: MasterRecurringProjectResult -> SubscriptionOptions
const getSuppliersSubOptions = R.applySpec({
  skip: R.compose(R.isEmpty, R.pathOr([], ['recurringProject', 'suppliers'])),
  variables: R.compose(
    R.objOf('input'),
    R.objOf('id'),
    R.path(['recurringProject', 'suppliers', 0, '_id']),
  ),
});

export const useMasterRecurringProjectSidebarData = (projectId) => {
  // this flag used on converting Recurring project to PM
  const skipRecurringProjectDetailsSub = useSelector(
    R.prop('conversionRecurringProject'),
  );

  const { data, loading } = useEntityMultipleSubscription(
    RECURRING_PROJECT_DETAILS,
    [
      RECURRING_PROJECT_DETAILS_SUB,
      CLIENT_NAME_SUB,
      PROPERTY_NAME_SUB,
      SUPPLIER_COMPANY_NAME_SUB,
      purchaseOrderDetailSubs,
    ],
    {
      queryOptions: { variables: { projectId }, fetchPolicy: 'network-only' },
      getSubscriptionOptions: [
        getRecurringProjectSubOptions(skipRecurringProjectDetailsSub),
        getSubscriptionOptionsByPath(['recurringProject', 'client', '_id']),
        getPropertySubOptions,
        getSuppliersSubOptions,
        getSubscriptionOptionsByPath([
          'recurringProject',
          'adminPurchaseOrder',
          '_id',
        ]),
      ],
    },
  );

  const project = R.propOr({}, 'recurringProject', data);

  return { loading, project };
};
