import * as R from 'ramda';
import React from 'react';
import { string, bool, shape, func } from 'prop-types';
import { Loader } from 'poly-book-admin';
import { DEFAULT_TASK_PRIORITY, taskCollections } from 'poly-constants';

import { TaskFormWrapper } from '../form/index.js';
import { initialCommonValues } from '../form/constants.js';
import { prepareCreateTaskDataToMutation } from '../form/taskFormUtils.js';
import { mailToInitValues } from '../../fields/MailTo/index.js';
import { useModalContext } from '../../../../components/Modal/ModalProvider.js';
import { confirmCancelFormPayload } from '../../../../components/common.js';
import { useDetailsByCollection } from './hooks.js';
import { useCreateTask } from '../../../../hooks/tasks/index.js';
import { useProcessState } from '../../../../hooks/useProcessState.js';

// getTaskManagerId :: Payload -> ID
// Payload = {collection: String data: Object user: User }
const getTaskManagerId = R.ifElse(
  R.propEq('collection', taskCollections.PROJECTS),
  R.path(['data', 'project', 'manager', '_id']),
  R.path(['user', '_id']),
);

export function AddTaskForm({ taskTemplate, ...props }) {
  const {
    formId,
    onCancel,
    payload: { collection, defaultQuery, _id: documentId },
    isHQProject,
  } = props;

  const { process } = useProcessState(formId);

  const { data, title, entityInfo, loading, user } = useDetailsByCollection({
    collection,
    defaultQuery,
    documentId,
  });

  const { createTask } = useCreateTask();

  const { closeModal, openConfirmModal } = useModalContext();

  const onCancelHandler = () => {
    closeModal(formId);

    if (onCancel) {
      onCancel();
    }
  };

  const openConfirmModalHandler = () =>
    openConfirmModal({
      ...confirmCancelFormPayload,
      onConfirm: (closeConfirmModal) =>
        R.compose(closeConfirmModal, onCancelHandler),
    });

  const mutate = (input) =>
    createTask(prepareCreateTaskDataToMutation({ documentId, ...input }));

  const initialValues = {
    managerId: getTaskManagerId({ collection, user, data }),
    collection,
    isAdd: true,
    dueDate: new Date(),
    details: {
      ...initialCommonValues,
      text: taskTemplate?.description || '',
    },
    mailTo: mailToInitValues,
    isHQProject,
    priority: taskTemplate?.priority || DEFAULT_TASK_PRIORITY,
    description: taskTemplate?.name || '',
  };

  if (loading) return <Loader />;
  if (!documentId || !collection) return null;

  return (
    <TaskFormWrapper
      {...props}
      {...data}
      isCreateAction
      error=""
      submitCaption="Create"
      successMessage="Task was created"
      mutate={mutate}
      initialValues={initialValues}
      projectDbId={documentId}
      actionInProgress={process}
      entityInfo={entityInfo}
      onCancel={onCancelHandler}
      openConfirmModal={openConfirmModalHandler}
      title={title}
      isTaskTemplate={!!taskTemplate?._id}
    />
  );
}

AddTaskForm.displayName = 'AddTaskForm';

AddTaskForm.propTypes = {
  formId: string.isRequired,
  isTaskUpdateMessageOptional: bool,
  onCancel: func,
  isHQProject: bool,
  payload: shape({
    _id: string,
    defaultQuery: shape({
      kind: string,
    }),
    collection: string,
  }),
  taskTemplate: shape({}),
};
