import * as R from 'ramda';
import { alwaysNewDate } from 'poly-utils';

import { projectTasksTitleMapByProjectType } from '../modules/forms/taskForm/form/constants.js';

export const temporalUpdateId = 'temporalUpdateId';

const mentionsDefault = {
  users: [],
  groups: [],
};

// formatTaskUpdateTitleBase :: String -> String
export const formatTaskUpdateTitleBase = R.concat('Task Updated: ');

// mapCreateUpdateWithActionInputToUpdate :: String -> CreateUpdateWithActionInput -> Update
export const mapCreateUpdateWithActionInputToUpdate = (projectType) =>
  R.applySpec({
    _id: R.always(temporalUpdateId),
    createdBy: R.prop('user'),
    title: R.compose(
      formatTaskUpdateTitleBase,
      R.prop(R.__, projectTasksTitleMapByProjectType(projectType)),
      R.propOr('', 'updateType'),
    ),
    message: R.path(['update', 'message']),
    mentions: R.always(mentionsDefault),
    type: R.prop('updateType'),
    createdAt: alwaysNewDate,
    attachments: R.always([]),
    updateComments: R.always([]),
    emailData: R.always(null),
    pinned: R.F,
    __typename: R.always('Update'),
  });

// mapCreateUpdateInputToUpdate :: CreateUpdateInput -> Update
export const mapCreateUpdateInputToUpdate = R.applySpec({
  _id: R.always(temporalUpdateId),
  createdBy: R.prop('user'),
  title: R.always('Internal Update'),
  message: R.prop('message'),
  mentions: R.always(mentionsDefault),
  type: R.prop('type'),
  attachments: R.always([]),
  updateComments: R.always([]),
  createdAt: alwaysNewDate,
  emailData: R.always(null),
  pinned: R.F,
  __typename: R.always('Update'),
});

// prependNewEntityUpdate :: String -> Update -> Entity -> Entity
// Entity = Project | Client | Supplier | MasterSupplier | Property
export const prependNewEntityUpdate = (entity) => (newUpdate) =>
  R.over(R.lensPath([entity, 'documentUpdates', 'hits']), R.prepend(newUpdate));
