import * as R from 'ramda';
import parseReactHtml from 'html-react-parser';
import { escapeSpecialChars, ramdaUseWith } from 'poly-utils';

const htmlSpecialCharsMap = {
  '&': '&amp;',
  "'": '&#39;',
  '"': '&quot;',
  '<': '&lt;',
  '>': '&gt;',
};

// encodeHtmlSpecialChars :: String -> String
// eslint-disable-next-line import/no-unused-modules
export const encodeHtmlSpecialChars = R.when(
  R.is(String),
  R.replace(/['"&<>]/g, R.prop(R.__, htmlSpecialCharsMap)),
);

// encodeHtmlSpecialCharsInHighlightedText :: String -> String
// eslint-disable-next-line import/no-unused-modules
export const encodeHtmlSpecialCharsInHighlightedText = R.compose(
  R.join('<em>'),
  R.map(R.when(R.compose(R.gt(R.__, 1), R.length), R.join('</em>'))),
  R.map(R.map(encodeHtmlSpecialChars)),
  R.map(R.split('</em>')),
  R.split('<em>'),
);

// searchStringToTokenizedRegExpString :: String -> String
const searchStringToTokenizedRegExpString = R.compose(
  R.join('|'),
  R.sort((a, b) => b.length - a.length),
  R.reject(R.isEmpty),
  R.split(' '),
  escapeSpecialChars,
  R.trim,
);

// constructRegExp :: String -> String -> RegExp
const constructRegExp = R.constructN(2, RegExp);

// highlightText :: String -> String -> String
// eslint-disable-next-line import/no-unused-modules
export const highlightText = (search) => (value) =>
  R.ifElse(
    R.either(R.isNil, R.compose(R.isNil, R.always(value))),
    R.always(value),
    R.compose(
      encodeHtmlSpecialCharsInHighlightedText,
      R.ifElse(
        R.isEmpty,
        R.compose(R.construct(String), R.always(value)),
        R.converge(R.replace, [
          constructRegExp(R.__, 'gi'),
          R.always('<em>$&</em>'),
          R.compose(R.construct(String), R.always(value)),
        ]),
      ),
      searchStringToTokenizedRegExpString,
    ),
  )(search);

// highlightTextToReactElement :: String -> String -> ReactNode
export const highlightTextToReactElement = R.curry((search, value) =>
  R.compose(parseReactHtml, R.defaultTo(''), highlightText(search))(value),
);

// boolToYesNo :: Bool -> String
export const boolToYesNo = (bool) => (bool ? 'Yes' : 'No');

// capitalizeFirstLetter :: String -> String
export const capitalizeFirstLetter = (string) =>
  string && string.charAt(0).toUpperCase() + string.slice(1);

// isSearchTextMatchedAtPaths :: ([Path], String) -> Object -> Boolean
//   Path = [String]
const isSearchTextMatchedAtPaths = ramdaUseWith(
  (paths, searchText) =>
    R.anyPass(
      paths.map((path) =>
        R.compose(
          R.includes(searchText),
          R.toLower,
          R.unless(R.is(String), R.toString),
          R.pathOr('', path),
        ),
      ),
    ),
  [R.identity, R.compose(R.toLower, R.defaultTo(''))],
);

// filterDocumentsMatchedByPaths :: ([Path], String) -> [Object] -> [Object]
export const filterDocumentsMatchedByPaths = (paths, searchText = '') =>
  R.when(
    R.compose(
      R.both(R.is(String), R.complement(R.isEmpty)),
      R.always(searchText),
    ),
    R.filter(isSearchTextMatchedAtPaths(paths, searchText)),
  );

// getFirstLetterOfNames :: (String, String) -> String
export const getFirstLetterOfNames = (firstName, lastName) =>
  R.converge(R.concat, [
    R.pathOr('', ['firstName', '0']),
    R.pathOr('', ['lastName', '0']),
  ])({ firstName, lastName });
