import React from 'react';
import { CommonSidebarFormWidth } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';

import { addSupplierInvoicesFormId } from '../constants.js';
import { AddSupplierInvoicesForm } from './AddSupplierInvoicesForm.js';
import { SidebarFormLayout } from '../../../sidebars/components/SidebarFormLayout.js';

export const useAddSupplierInvoicesSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return () =>
    openOutSidebar({
      alwaysFirst: true,
      id: addSupplierInvoicesFormId,
      width: CommonSidebarFormWidth,
      content: (
        <SidebarFormLayout
          submitButtonCaption="Create"
          title="Add Supplier Invoice"
          formId={addSupplierInvoicesFormId}
        >
          <AddSupplierInvoicesForm />
        </SidebarFormLayout>
      ),
    });
};
