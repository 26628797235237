import React from 'react';
import * as R from 'ramda';
import { FormCreator, LinkButton, Textarea } from 'poly-book-admin';
import { WeeklyServiceTicketStatuses } from 'poly-constants';
import { useMutation, useQuery } from '@apollo/client';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
  useModalContext,
} from 'poly-admin-ui';

import { PROJECT_DETAILS } from '../../../modules/core/hooks/projects/queries.js';
import { SendSupplierServiceTicketRequestMutation } from './WeekServiceTicketRequestedLink.js';
import { editWeeklyServiceTicketMutation } from '../../../sidebars/ProjectSidebar/forms/WeeklyServiceTicketForm.js';
import {
  getWeeklyServiceTicketFormSections,
  notCompletedIsNotChecked,
} from '../../../sidebars/ProjectSidebar/forms/form/getWeeklyServiceTicketFormSections.js';

const approveSupplierServiceTicketFormId = 'approveSupplierServiceTicketFormId';
const rejectSupplierServiceTicketFormId = 'rejectSupplierServiceTicketFormId';

// getProjectWeeklyTicket :: Number -> { project: Project } -> Result
// Result = {
//    weeklyServiceTicket: WeeklyServiceTicket
//    weeklyPorterHours: Number
// }
const getProjectWeeklyTicket = (week) =>
  R.compose(
    R.mergeAll,
    R.juxt([
      R.pick(['weeklyPorterHours']),
      R.compose(
        R.objOf('weeklyServiceTicket'),
        R.defaultTo({}),
        R.find(R.propEq('week', week)),
        R.propOr([], 'weeklyServiceTickets'),
      ),
    ]),
    R.propOr({}, 'project'),
  );

const ViewTicketSectionPart = (ticket) => ({
  order: 6,
  layout: { row: 3 },
  label: '',
  field: {
    name: 'ticket',
    Component: () => {
      const url = R.path(['attachment', 'url'], ticket);
      const onClick = () => (url ? window.open(url, '_blank') : null);

      return (
        <LinkButton type="button" onClick={onClick}>
          View Ticket
        </LinkButton>
      );
    },
  },
});

// getApproveWeeklyServiceTicketFormSections :: (Number, WeeklyServiceTicket) -> [Object]
const getApproveWeeklyServiceTicketFormSections = (
  weeklyPorterHours,
  weeklyServiceTicket,
) =>
  R.compose(
    R.of,
    R.mergeAll,
    R.juxt([
      R.omit(['fields']),
      R.compose(
        R.objOf('fields'),
        R.append(ViewTicketSectionPart(weeklyServiceTicket)),
        R.tail,
        R.reverse,
        R.prop('fields'),
      ),
    ]),
    R.head,
    getWeeklyServiceTicketFormSections,
  )(weeklyPorterHours);

export function WeekServiceTicketApproveLink(project, week, refetchTableData) {
  const { openModalForm, closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [editWeeklyServiceTicket] = useMutation(
    editWeeklyServiceTicketMutation,
  );
  const [sendSupplierServiceTicketRequest] = useMutation(
    SendSupplierServiceTicketRequestMutation,
  );

  const { data } = useQuery(PROJECT_DETAILS, {
    variables: { id: project._id },
  });

  const { weeklyPorterHours, weeklyServiceTicket } =
    getProjectWeeklyTicket(week)(data);

  const onApproveHandler = async (values) => {
    const isTicketCompleted = notCompletedIsNotChecked(values);

    await editWeeklyServiceTicket({
      variables: {
        input: {
          week,
          projectId: project._id,
          ticket: {
            hours: isTicketCompleted ? values.hours : 0,
            extraHours: isTicketCompleted ? values.extraHours : 0,
            status: isTicketCompleted
              ? WeeklyServiceTicketStatuses.approved
              : WeeklyServiceTicketStatuses.notCompleted,
          },
        },
      },
    });

    closeModal(approveSupplierServiceTicketFormId);

    showSuccessNotification('Ticket Was Approved.');

    await refetchTableData();
  };

  const { onSubmit: onApprove } = useOnSubmitSetStopSubmitting(
    approveSupplierServiceTicketFormId,
    onApproveHandler,
  );

  const onRejectHandler = async ({ rejectionReason }) => {
    await sendSupplierServiceTicketRequest({
      variables: {
        input: {
          projectIds: [project._id],
          week,
          rejectionReason,
        },
      },
    });
    closeModal(rejectSupplierServiceTicketFormId);
    closeModal(approveSupplierServiceTicketFormId);
    showSuccessNotification('Ticket was Rejected. Supplier request was sent.');

    await refetchTableData();
  };

  const { onSubmit: onReject } = useOnSubmitSetStopSubmitting(
    rejectSupplierServiceTicketFormId,
    onRejectHandler,
  );

  const onClickReject = () => {
    openModalForm({
      id: rejectSupplierServiceTicketFormId,
      title: 'Reject Supplier Service Ticket',
      formId: rejectSupplierServiceTicketFormId,
      btnCaption: 'Reject',
      content: (
        <FormCreator
          id={rejectSupplierServiceTicketFormId}
          formId={rejectSupplierServiceTicketFormId}
          sections={[
            {
              id: 'main',
              layout: { column: 1, margin: ' 25px 0 25px 0' },
              order: 1,
              fields: [
                {
                  order: 1,
                  layout: { row: 1 },
                  label: 'Rejection reason',
                  required: true,
                  field: {
                    name: 'rejectionReason',
                    Component: Textarea,
                  },
                },
              ],
            },
          ]}
          initialValues={{}}
          onSubmit={onReject}
        />
      ),
    });
  };

  const onClick = () => {
    openModalForm({
      id: approveSupplierServiceTicketFormId,
      title: 'Approve Supplier Service Ticket',
      formId: approveSupplierServiceTicketFormId,
      btnCaption: 'Approve',
      onClickReject,
      content: (
        <FormCreator
          id={approveSupplierServiceTicketFormId}
          formId={approveSupplierServiceTicketFormId}
          sections={getApproveWeeklyServiceTicketFormSections(
            weeklyPorterHours,
            weeklyServiceTicket,
          )}
          initialValues={{
            hours: weeklyServiceTicket.hours,
            extraHours: weeklyServiceTicket.extraHours,
          }}
          onSubmit={onApprove}
        />
      ),
    });
  };

  return <LinkButton onClick={onClick}>Approve</LinkButton>;
}
