import * as R from 'ramda';
import { useState, useCallback } from 'react';
import { useRouterQuery, useUpdateQueryParams } from 'poly-client-routing';
import { debounce, forceTitleCase } from 'poly-utils';

import { useEntitiesByReactiveSearch } from './entitiesSearch.js';

const defaultPagination = {
  allItemsCount: 0,
  itemsPerPage: 25,
  currentPage: 1,
};

// getAppendDefaultEntity :: (entityPath, allEntitiesPath, properties) -> [UpdatedEntity]
export const getAppendDefaultEntity = ({
  entityPath,
  allEntitiesPath,
  props,
}) =>
  R.compose(
    R.objOf(allEntitiesPath),
    R.objOf('hits'),
    R.ifElse(
      R.path(entityPath),
      R.converge(R.prepend, [
        R.path(entityPath),
        R.pathOr([], [allEntitiesPath, 'hits']),
      ]),
      R.pathOr([], [allEntitiesPath, 'hits']),
    ),
  )(props);

export function useQueryWithSearch({
  gqlSearchQuery,
  gqlSearchChangedQuery,
  sort = {},
  query = null,
  fetchPolicy = 'network-only',
  propsOfComponent = {},
  skipQuery = false,
  pagination = defaultPagination,
  withoutSkip = false,
  fetchSize = 25,
  additionalVars = {},
  error: validationError = '',
  alias = 'useQueryWithSearch',
}) {
  const [searchTextForQueryState, setSearchTextForQueryState] = useState('');

  const paginationValue = { ...pagination, itemsPerPage: fetchSize };

  const skipQueryValue =
    skipQuery || (!searchTextForQueryState.length && !withoutSkip);

  const { loading, refetch, result } = useEntitiesByReactiveSearch({
    gqlSearchQuery,
    gqlSearchChangedQuery,
    sort,
    query,
    fetchPolicy,
    propsOfComponent,
    skipQuery: skipQueryValue,
    pagination: paginationValue,
    searchTextForQuery: searchTextForQueryState,
    additionalVars,
    alias,
  });

  const debouncedSetSearchQuery = useCallback(
    debounce(400)(setSearchTextForQueryState),
    [],
  );

  const setSearchTextForQuery = (searchValue) =>
    debouncedSetSearchQuery(searchValue);

  const onSearchChange = R.tap(setSearchTextForQuery);

  return {
    loading,
    result,
    refetch,
    onSearchChange,
    error: validationError,
  };
}

export const useMapPropsForDropdown = ({ mapper, onChange, restProps }) => {
  const [currentSelectedOption, setCurrentSelectedOption] = useState(null);

  const options = mapper(restProps);

  const optionsValue = R.pipe(
    R.ifElse(
      R.always(currentSelectedOption),
      R.prepend(currentSelectedOption),
      R.identity,
    ),
    R.uniqBy(R.prop('value')),
  )(options);

  const onChangeHandler = (val, selectedOption) => {
    onChange(val, selectedOption);

    if (selectedOption?.value && selectedOption?.label) {
      setCurrentSelectedOption(selectedOption);
    } else {
      setCurrentSelectedOption(null);
    }
  };

  return {
    options: optionsValue,
    onChange: onChangeHandler,
  };
};

export const useSetDefaultValueToURL = (key) => {
  const updateQueryParams = useUpdateQueryParams();

  const routerQuery = useRouterQuery([key]);

  return {
    updateQueryParams,
    routerQuery,
  };
};

// formatSelectOptionsByConstants :: Object -> [SelectOption]
// SelectOption = { value: String, label: String }
export const formatSelectOptionsByConstants = R.compose(
  R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
  R.toPairs,
);

// formatSelectOptionsByConstants :: Object -> [SelectOption]
// SelectOption = { value: String, label: String }
export const formatSelectOptionsWithTitleCased = R.compose(
  R.map(R.over(R.lensProp('label'), forceTitleCase)),
  formatSelectOptionsByConstants,
);

// formatSelectOptionsByConstantsValue :: Object -> [SelectOption]
export const formatSelectOptionsByConstantsValue = R.compose(
  formatSelectOptionsWithTitleCased,
  R.fromPairs,
  R.map(R.converge(R.pair, [R.identity, R.identity])),
  R.values,
);

// entityToOptionByLabelPath :: [String] -> Object -> Option
export const entityToOptionByLabelPath = (labelPath) =>
  R.applySpec({
    value: R.prop('_id'),
    label: R.path(labelPath),
  });
