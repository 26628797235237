import * as R from 'ramda';
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { assocBy, convertDollarsToCents, isNilOrEmpty } from 'poly-utils';
import { arrayOf, number, shape, string } from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { UPDATE_PROPERTY_PERMISSION } from 'poly-security';

import { TMRulesTab } from '../ClientSidebar/financialTabs/TMRulesTab.js';
import { PropertyFieldInheritanceMap } from './constants.js';

const updatePropertyMarkupMutation = gql`
  mutation updatePropertyMarkupMutation($input: UpdatePropertyMarkupInput!) {
    updatePropertyMarkup(input: $input) {
      _id
    }
  }
`;

// convertValues :: FormValues -> UpdateClientMarkupInput
const convertValues = R.over(R.lensProp('woCharge'), convertDollarsToCents);

// fieldExistsByPath :: [String] -> Object -> Boolean
const fieldExistsByPath = (path) =>
  R.pathSatisfies(R.complement(isNilOrEmpty), path);

// getInheritedFormFieldBy :: (String, String) -> Property -> Any
const getInheritedFormFieldBy = (inheritedField, fieldProp) =>
  R.cond([
    [
      R.propEq(inheritedField, PropertyFieldInheritanceMap.SELF),
      R.prop(fieldProp),
    ],
    [
      R.propEq(inheritedField, PropertyFieldInheritanceMap.MASTER_PROPERTY),
      R.path(['masterProperty', fieldProp]),
    ],
    [R.T, R.path(['client', fieldProp])],
  ]);

// getInheritedFromByProp :: String -> Property -> Any
const getInheritedFromByProp = (prop) =>
  R.cond([
    [fieldExistsByPath([prop]), R.always(PropertyFieldInheritanceMap.SELF)],
    [
      fieldExistsByPath(['masterProperty', prop]),
      R.always(PropertyFieldInheritanceMap.MASTER_PROPERTY),
    ],
    [R.T, R.always(PropertyFieldInheritanceMap.CLIENT)],
  ]);

// getPropertyTMMarkupRules :: Property -> Result
// Result = {
//    isClientMarkupRules: Boolean
//    isClientWOCharge: Boolean
//    tmMarkupRules: [ClientMarkupRule]
//    woCharge: Int
// }
const getPropertyTMMarkupRules = R.compose(
  R.applySpec({
    woChargeInheritedFrom: R.prop('woChargeInheritedFrom'),
    markupRulesInheritedFrom: R.prop('markupRulesInheritedFrom'),
    woCharge: getInheritedFormFieldBy('woChargeInheritedFrom', 'woCharge'),
    tmMarkupRules: getInheritedFormFieldBy(
      'markupRulesInheritedFrom',
      'tmMarkupRules',
    ),
  }),
  assocBy('woChargeInheritedFrom', getInheritedFromByProp('woCharge')),
  assocBy('markupRulesInheritedFrom', getInheritedFromByProp('tmMarkupRules')),
);

export function PropertyTMRulesTab({ property }) {
  const [updatePropertyMarkup] = useMutation(updatePropertyMarkupMutation);
  const hasAccess = useHasUserAccessWithPermission(UPDATE_PROPERTY_PERMISSION);
  const onSubmit = async (formData) => {
    await updatePropertyMarkup({
      variables: {
        input: {
          ...convertValues(formData),
          propertyId: property._id,
        },
      },
    });
  };

  const {
    woCharge,
    tmMarkupRules,
    woChargeInheritedFrom,
    markupRulesInheritedFrom,
  } = getPropertyTMMarkupRules(property);

  return (
    <TMRulesTab
      hasAccess={hasAccess}
      onSubmit={onSubmit}
      woCharge={woCharge}
      tmMarkupRules={tmMarkupRules}
      woChargeInheritedFrom={woChargeInheritedFrom}
      markupRulesInheritedFrom={markupRulesInheritedFrom}
      successMsgPrefix="Property"
      isChildProperty={!!property?.masterProperty?._id}
    />
  );
}

PropertyTMRulesTab.propTypes = {
  property: shape({
    _id: string.isRequired,
    tmMarkupRules: arrayOf(
      shape({
        upTo: number,
        percent: number,
      }),
    ),
  }),
};
