import * as R from 'ramda';
import { assocByPath, applySpecWithFields, trimString } from 'poly-utils';
import { supplierEmailsTypes, supplierPhoneTypes } from 'poly-constants';

import { prepareAddressDeepBeforeMutate } from '../../../../utils/address.js';
import { formattingInternationalPhone } from '../../../../components/InternationalAddressAndPhone/InternationalPhone/internationalPhoneUtils.js';

const { SECONDARY_SERVICE, ACCOUNT } = supplierEmailsTypes;

// prepareEmailForMutationInput :: String -> Object -> [SupplierEmail]
const prepareEmailForMutationInput = (type) =>
  R.compose(
    R.map(R.assoc('email', R.__, { type })),
    R.map(R.prop('value')),
    R.defaultTo([]),
    R.prop(type),
  );

// prepareSupplierEmailsInput :: FormData -> [SupplierEmailInput]
export const prepareSupplierEmailsInput = R.compose(
  R.filter(R.prop('email')),
  R.unnest,
  R.juxt([
    R.compose(
      R.map(([type, email]) => ({ type, email: trimString(email) })),
      R.toPairs,
      R.omit([SECONDARY_SERVICE, ACCOUNT]),
    ),
    prepareEmailForMutationInput(SECONDARY_SERVICE),
    prepareEmailForMutationInput(ACCOUNT),
  ]),
  R.defaultTo({}),
  R.path(['company', 'emails']),
);

// convertPhoneByType :: Object -> [Object]
const convertPhoneByType = (type, fieldName) =>
  R.compose(
    R.when(
      R.identity,
      R.compose(
        R.assoc('phone', R.__, {
          type,
          isPrimary: true,
        }),
      ),
    ),
    R.defaultTo(null),
    R.path(['company', fieldName]),
  );

// convertMobilePhone :: FormValues -> SupplierPhoneInput
const convertMobilePhone = R.compose(
  R.when(
    R.identity,
    R.compose(
      R.assoc('phone', R.__, {
        type: supplierPhoneTypes.MOBILE,
        isPrimary: true,
      }),
      formattingInternationalPhone,
    ),
  ),
  R.defaultTo(null),
  R.path(['company', 'mobile']),
);

// convertPhone :: FormValues -> [SupplierPhoneInput]
export const convertPhone = R.compose(
  R.when(R.isEmpty, R.always(null)),
  R.reject(R.isNil),
  R.juxt([
    convertPhoneByType(supplierPhoneTypes.GENERAL, 'phone'),
    convertMobilePhone,
    convertPhoneByType(supplierPhoneTypes.AFTER_HOURS, 'afterHoursPhone'),
  ]),
);

// convertServiceTypes :: Object -> [String]
export const convertServiceTypes = R.compose(
  R.map(R.prop('value')),
  R.defaultTo([]),
  R.path(['company', 'servicesIds']),
);

const notFile = R.compose(R.not, R.is(window.File));

const omitNotFile = (path) =>
  R.when(
    R.compose(R.both(R.complement(R.isNil), notFile), R.path(path)),
    R.dissocPath(path),
  );

const checkW9File = omitNotFile(['tax', 'w9File']);
const checkWCFile = omitNotFile(['documents', 'wcFile']);
const checkGLFile = omitNotFile(['documents', 'glFile']);
const checkSCAFile = omitNotFile(['documents', 'scaFile']);
const checkSQQFile = omitNotFile(['documents', 'sqqFile']);

// when it is fetched from the server it is not File.
// So if we do not upload new file we not need to have this prop
export const checkFiles = R.compose(
  R.ifElse(
    R.path(['tax', 'exempt']),
    R.assocPath(['tax', 'w9File'], null),
    checkW9File,
  ),
  checkWCFile,
  checkGLFile,
  checkSCAFile,
  checkSQQFile,
  omitNotFile(['documents', 'mbeCertificationFile']),
  omitNotFile(['documents', 'wbeCertificationFile']),
  omitNotFile(['documents', 'veteranCertificationFile']),
);

// prepareSupplierContactsAndServices :: FormData -> FormData
export const prepareSupplierContactsAndServices = R.compose(
  assocByPath(['company', 'servicesIds'], convertServiceTypes),
  assocByPath(['company', 'emails'], prepareSupplierEmailsInput),
  assocByPath(['company', 'phones'], convertPhone),
);

// prepareCommonSupplierDataForMutation :: FormData -> MutationInput
export const prepareCommonSupplierDataForMutation = R.compose(
  applySpecWithFields({
    tax: {
      id: R.pathOr(null, ['tax', 'id']),
      classification: R.pathOr(null, ['tax', 'classification']),
      socialSecurityNumber: R.pathOr(null, ['tax', 'socialSecurityNumber']),
    },
  }),
  prepareAddressDeepBeforeMutate('company'),
  prepareAddressDeepBeforeMutate('remit'),
  prepareAddressDeepBeforeMutate('tax'),
  R.omit([
    '_id',
    'cardNumber',
    'disableTypeInput',
    'openingInvoicesTotal',
    'isWBE',
    'allowToConvert',
    'supplierSource',
  ]),
  R.dissocPath(['company', 'phone']),
  R.dissocPath(['company', 'mobile']),
  R.dissocPath(['mwbe', 'isMBE']),
  R.when(
    R.pathSatisfies(R.isNil, ['documents', 'exempt']),
    R.dissocPath(['documents', 'exempt']),
  ),
  R.unless(
    R.compose(R.isEmpty, R.pathOr([], ['bank', 'remittanceEmails'])),
    R.over(
      R.lensPath(['bank', 'remittanceEmails']),
      R.map(R.unless(R.is(String), R.prop('value'))),
    ),
  ),
  checkFiles,
  R.when(
    R.propSatisfies(R.complement(R.isNil), 'isWBE'),
    assocByPath(['mwbe', 'isWBE'], R.prop('isWBE')),
  ),
  R.when(R.propEq('service_24_7', true), R.dissoc('officeHours')),
  prepareSupplierContactsAndServices,
);

/**
 * prepareSupplierFormDataForMutation :: Object -> Object
 */
export const prepareSupplierFormDataForMutation = R.compose(
  prepareCommonSupplierDataForMutation,
);
