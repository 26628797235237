import React from 'react';
import arrayMutators from 'final-form-arrays';
import { array, func, string, bool } from 'prop-types';
import { FormCreator } from 'poly-book-admin';
import { usePristineSubscribe } from 'poly-client-routing';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
} from 'poly-admin-ui';

import { commonModalLayout } from '../common.js';

export function TimesheetForm(props) {
  const { formId, showTimeList, mutate, onCancel, successMessage } = props;
  const pristineSubscribeProps = usePristineSubscribe();
  const { showSuccessNotification } = useNotificationState();

  const handleSubmit = async (input) => {
    await mutate(input);

    showSuccessNotification(successMessage);

    if (!showTimeList) {
      onCancel();
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, handleSubmit);

  const formProps = {
    ...props,
    onSubmit,
    layout: commonModalLayout,
    mutators: { ...arrayMutators },
    ...(!!showTimeList && {
      resetFormOnSubmit: true,
      keepDirtyOnReinitialize: false,
    }),
    ...pristineSubscribeProps,
  };

  return <FormCreator {...formProps} />;
}

TimesheetForm.propTypes = {
  formId: string,
  onCancel: func.isRequired,
  showTimeList: bool,
  mutate: func.isRequired,
  successMessage: string.isRequired,
  validate: func,
  // eslint-disable-next-line react/forbid-prop-types
  sections: array.isRequired,
};

TimesheetForm.displayName = 'TimesheetForm';
