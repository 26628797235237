import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { string, node, func } from 'prop-types';
import { useOutSidebarContext } from 'poly-client-utils';
import { Button } from 'poly-book-admin';

import { debounce } from 'poly-utils/src/general.js';
import {
  CloseSidebarFormButton,
  CloseSidebarFormIcon,
  FormButtonsContainer,
  SideBarFormWrapper,
  SideBarFormHeader,
} from './commonSidebarFormComponents.js';
import { useClearPristineState } from '../useClearPristineOnSidebarMount.js';

function FormButton({ formId, caption }) {
  const { [formId]: loading } = useSelector(R.prop('processes'));

  return (
    <Button loader={loading} disabled={loading} form={formId}>
      {caption}
    </Button>
  );
}

FormButton.propTypes = {
  formId: string.isRequired,
  caption: string.isRequired,
};

export function SidebarFormLayout({
  title,
  formId,
  onClose,
  children,
  className,
  justifyContent,
  submitButtonCaption,
}) {
  const { closeOutSidebar } = useOutSidebarContext();
  const clearPristine = useClearPristineState();
  const onCancel = () => {
    closeOutSidebar(formId);
    if (onClose) {
      onClose();
    }
    debounce(100)(() => clearPristine());
  };

  return (
    <SideBarFormWrapper justifyContent={justifyContent} className={className}>
      <SideBarFormHeader>
        {title}
        <CloseSidebarFormIcon onCancel={onCancel} />
      </SideBarFormHeader>
      {children}
      <FormButtonsContainer>
        <FormButton formId={formId} caption={submitButtonCaption} />
        <CloseSidebarFormButton onCancel={onCancel} />
      </FormButtonsContainer>
    </SideBarFormWrapper>
  );
}

SidebarFormLayout.propTypes = {
  onClose: func,
  className: string,
  justifyContent: string,
  title: string.isRequired,
  formId: string.isRequired,
  children: node.isRequired,
  submitButtonCaption: string.isRequired,
};
