import React from 'react';
import * as R from 'ramda';
import { arrayOf, bool, shape, string } from 'prop-types';
import { pathOrNothingUI } from 'poly-client-utils';
import { SidebarRow } from 'poly-admin-ui';

import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapper,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import { useSidebarLogicContext } from '../SidebarLogicContext.js';
import {
  getSectionClientManagers,
  getSectionSidebarLink,
  getSectionContact,
  getSectionText,
  getSectionPhoneLink,
} from '../components/commonSidebarSectionFormatters.js';
import {
  isDistrictFacilityManager,
  isPropertyFieldActive,
  formatManagerToUser,
  showPropertyInfoRow,
  isDistrictsEnabled,
} from './propertySidebarUtils.js';
import {
  HelpTooltip,
  ShortExplanationKeys,
} from '../../components/HelpTooltip.js';
import { getToggleSettingSectionText } from '../ClientSidebar/helpers.js';

export function PropertySidebarGeneralInfo({ property }) {
  const { ClientLink, PropertyLink } = useSidebarLogicContext();

  const { client, phone, fax } = property;

  const requesterSurveyOnClientEnabled =
    client?.configs?.requesterSurvey?.enabled;

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>General Information</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          id="phone"
          label="Phone"
          {...threeBlocksProps}
          Component={getSectionPhoneLink(phone)}
        />
        <BlockWithLabel
          id="fax"
          label="Fax"
          {...threeBlocksProps}
          Component={getSectionPhoneLink(fax)}
        />
        <BlockWithLabel
          id="main-account"
          label="Main Account"
          {...threeBlocksProps}
          Component={getSectionSidebarLink(
            R.pick(['_id', 'name'])(client),
            ClientLink,
          )}
        />
        {property?.masterProperty && (
          <BlockWithLabel
            id="main-account"
            label="Master Property"
            {...threeBlocksProps}
            Component={getSectionSidebarLink(
              R.prop('masterProperty')(property),
              PropertyLink,
            )}
          />
        )}
        {showPropertyInfoRow(client) && (
          <>
            {isPropertyFieldActive(['storeNumber'])(client) && (
              <BlockWithLabel
                id="store"
                label="Store #"
                {...threeBlocksProps}
                Component={getSectionText(
                  pathOrNothingUI(['storeNumber'])(property),
                )}
              />
            )}
            {isPropertyFieldActive(['division'])(client) && (
              <BlockWithLabel
                id="division"
                label="Division"
                {...threeBlocksProps}
                Component={getSectionText(
                  pathOrNothingUI(['division'])(property),
                )}
              />
            )}
            {isDistrictsEnabled(client) && (
              <BlockWithLabel
                id="district"
                label="District"
                {...threeBlocksProps}
                Component={getSectionText(
                  pathOrNothingUI(['clientDistrict', 'name'])(property),
                )}
              />
            )}
          </>
        )}
        <BlockWithLabel
          id="manager"
          label="Assigned CSR"
          {...threeBlocksProps}
          Component={getSectionText(
            pathOrNothingUI(['aacContractMGR', 'fullName'])(property),
          )}
        />
        <BlockWithLabel
          id="branch-manager"
          label="Branch Manager"
          {...threeBlocksProps}
          Component={getSectionContact(
            formatManagerToUser('branchManager')(property),
          )}
        />
        <BlockWithLabel
          id="client-managers"
          label="Client Managers"
          {...threeBlocksProps}
          Component={getSectionClientManagers(client.clientManagers)}
        />
        {isDistrictFacilityManager(client) && (
          <BlockWithLabel
            id="district-manager"
            label="District Facility Manager"
            {...threeBlocksProps}
            Component={getSectionContact(
              formatManagerToUser('districtFacilityManager')(property),
            )}
          />
        )}
        {requesterSurveyOnClientEnabled && (
          <BlockWithLabel
            justify
            id="enableRequesterSurvey"
            label="Requester Survey"
            ActionBtn={
              <HelpTooltip
                position="top"
                shortExplanation={ShortExplanationKeys.requesterSurveyEnabled}
              />
            }
            {...threeBlocksProps}
            Component={getToggleSettingSectionText(
              property?.requesterSurveyEnabled,
            )}
          />
        )}
      </SidebarRow>
    </SectionWrapper>
  );
}

PropertySidebarGeneralInfo.propTypes = {
  property: shape({
    client: shape({
      _id: string.isRequired,
      name: string.isRequired,
      configs: shape({ enableDistricts: bool }),
      clientManagers: arrayOf(shape({ _id: string.isRequired })),
    }),
  }),
};
