import * as R from 'ramda';
import React, { useMemo } from 'react';
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { FieldLayout, Input, TextButton } from 'poly-book-admin';
import { centsToDollarsWithFormat } from 'poly-utils';
import { MoneyInputAsCents } from 'poly-admin-ui';
import { AccountTypeCategories } from 'poly-constants';

import {
  Line,
  LineBottom,
  TotalAmount,
  LinesWrapper,
  TotalFieldErrorMsg,
} from './styled.js';
import {
  GLCodeSelect,
  prepareAccountsToSelectBase,
} from '../../GLCodeSelect/GLCodeSelect.js';
import { DeleteLineIcon } from '../../../../components/DeleteLineIcon.js';
import { isGLCodeOptionDisabled } from '../../../../utils/account.js';
import { onKeyDownToPreventFormSubmit } from '../../../../utils/form.js';

// filterAccounts :: [AccountPostgre] -> [AccountPostgre]
const filterAccounts = R.filter(
  R.pathEq(['accountType', 'category'], AccountTypeCategories.expense),
);

const formatAccountsOptions = prepareAccountsToSelectBase(R.prop('_id'));

function MoneyInputWithNegativeAllowed(props) {
  return (
    <MoneyInputAsCents
      {...props}
      allowNegative
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

export function SupplierInvoiceLineEntry({
  name,
  index,
  fields: { value, remove, push },
}) {
  const isFirstLine = index === 0;
  const isLastLine = index === value.length - 1;
  const memoizedTotal = useMemo(
    () =>
      R.compose(
        centsToDollarsWithFormat,
        R.reduce(R.add, 0),
        R.reject(R.isNil),
        R.map(R.prop('amount')),
        R.defaultTo([]),
      )(value),
    [value],
  );

  return (
    <LinesWrapper>
      <Line>
        <FieldLayout
          required
          {...(isFirstLine && { label: 'GL Code' })}
          layout={{ width: 'calc(40% - 20px)' }}
          field={{
            name: `${name}.accountId`,
            additionalProps: {
              filterAccounts,
              formatAccountsOptions,
              isOptionDisabled: isGLCodeOptionDisabled(
                value,
                ['accountId'],
                ['value'],
              ),
            },
            Component: GLCodeSelect,
          }}
          validators={[[R.identity, 'GL Code is required']]}
        />
        <FieldLayout
          {...(isFirstLine && { label: 'Description' })}
          layout={{ width: 'calc(40% - 20px)' }}
          field={{
            name: `${name}.description`,
            additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
            Component: Input,
          }}
        />
        <FieldLayout
          required
          allowNegative
          {...(isFirstLine && { label: 'Amount' })}
          layout={{ width: 'calc(20% - 10px)' }}
          field={{
            name: `${name}.amount`,
            Component: MoneyInputWithNegativeAllowed,
          }}
          validators={[[R.identity, 'Amount is required']]}
        />
        {!isFirstLine && (
          <DeleteLineIcon
            name="delete"
            size={14}
            onClick={() => remove(index)}
          />
        )}
      </Line>
      {isLastLine && (
        <LineBottom>
          <TextButton onClick={() => push({})}>Add New Line</TextButton>
          <TotalAmount>
            Total:
            {memoizedTotal}
          </TotalAmount>
          <Field name="total">
            {({ meta }) =>
              meta.error ? (
                <TotalFieldErrorMsg>{meta.error}</TotalFieldErrorMsg>
              ) : null
            }
          </Field>
        </LineBottom>
      )}
    </LinesWrapper>
  );
}

SupplierInvoiceLineEntry.displayName = 'SupplierInvoiceLineEntry';

SupplierInvoiceLineEntry.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    push: func.isRequired,
    remove: func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: arrayOf(object).isRequired,
  }),
};
