import * as R from 'ramda';
import { forceTitleCase } from 'poly-utils/src/strings.js';
import { useHasUserAccessWithPermission } from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';
import {
  CREATE_ADMIN_PURCHASE_ORDER_PERMISSION,
  EDIT_ADMIN_PURCHASE_ORDER_PERMISSION,
  READ_ADMIN_PURCHASE_ORDER_PERMISSION,
} from 'poly-security/src/permissions.js';

import { purchaseOrderStatusUI, purchaseOrderTypesUI } from './constants.js';

// getPurchaseOrdersTypeUI :: PurchaseOrder -> String
export const getPurchaseOrdersTypeUI = R.compose(
  R.prop(R.__, purchaseOrderTypesUI),
  R.prop('type'),
);

// getPurchaseOrdersTypeUI :: String -> String
export const getPurchaseOrdersStatusUI = R.compose(
  forceTitleCase,
  R.prop(R.__, purchaseOrderStatusUI),
);

// preparePurchaseOrderBeforeMutation :: FormData -> CreateAdminPurchaseOrderInput
export const preparePurchaseOrderBeforeMutation = R.compose(
  R.evolve({
    notificationContactsEmails: R.map(R.prop('value')),
  }),
  R.unless(R.prop('propertyIds'), R.dissoc('propertyIds')),
);

export const useHasUserAccessToReadAdminPO = () => {
  const hasAccess = useHasUserAccessWithPermission(
    READ_ADMIN_PURCHASE_ORDER_PERMISSION,
  );

  return hasAccess;
};

export const useHasUserAccessToEditAdminPO = () => {
  const hasAccess = useHasUserAccessWithPermission(
    EDIT_ADMIN_PURCHASE_ORDER_PERMISSION,
  );

  return hasAccess;
};

export const useHasUserAccessToCreateAdminPO = () => {
  const hasAccess = useHasUserAccessWithPermission(
    CREATE_ADMIN_PURCHASE_ORDER_PERMISSION,
  );

  return hasAccess;
};
