import React from 'react';
import styled from 'styled-components';
import { string, func, shape, instanceOf, oneOfType } from 'prop-types';

import { DatePicker, Text, getThemeColor } from 'poly-book-admin';

const Label = styled(Text)`
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 18px;
  color: ${getThemeColor(['primary'])};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export function NextTaskDueDateField({ value, onChange, ...props }) {
  const label = `Schedule Next Task (${value.nextTaskDescription})`;
  return (
    <>
      <Label>{label}</Label>
      <DatePicker
        {...props}
        width="100%"
        position="top"
        name="dueDate"
        leftMove="-10px"
        value={value.dueDate}
        onChange={(dueDate) => onChange({ ...value, dueDate })}
      />
    </>
  );
}

NextTaskDueDateField.propTypes = {
  value: shape({
    nextTaskDescription: string,
    dueDate: oneOfType([instanceOf(Date), string]),
  }),
  onChange: func.isRequired,
};
