import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, object, string, func } from 'prop-types';
import { getThemeColor, Loader, Table, WindowedTable } from 'poly-book-admin';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import { NoRecordsHeader } from '../../../modules/core/hocs/general.js';
import { UserAlertTableEntry } from './userAlertTableEntry/UserAlertTableEntry.js';
import { useAlertsInfiniteScrollQuery } from './useAlertsInfiniteScrollQuery.js';

const TableWithoutHeaderS = styled(WindowedTable)`
  tbody {
    overflow-y: scroll;
    max-height: 500px;
  }

  thead {
    display: none;
  }

  tr {
    border-top: none;
    border-bottom: 1px solid ${getThemeColor(['midLight'])};
  }

  td {
    padding: 18px;

    &:first-child {
      padding-left: 24px;
    }
  }
`;

const TableWithoutHeader = TableWithoutHeaderS.withComponent(Table);

// isAlertsEmpty :: SearchUserAlerts -> Boolean
const isAlertsEmpty = R.pathEq(['searchUserAlerts', 'total'], 0);

// prepareAlertsTableData :: (SearchUserAlerts, User) -> [Alert]
// Alert = Object
const prepareAlertsTableRows = R.curry((searchAlerts, user) =>
  R.compose(
    R.map(
      R.compose(
        R.mergeRight({ selfId: user._id }),
        R.over(R.lensProp('createdAt'), (date) => new Date(date)),
      ),
    ),
    R.pathOr([], ['searchUserAlerts', 'hits']),
  )(searchAlerts),
);

function AlertsTable({ tableRows, searchText, tableProps, refetch }) {
  const tableConfig = [
    [
      '',
      (props) => (
        <UserAlertTableEntry
          searchText={searchText}
          refetch={refetch}
          {...props}
        />
      ),
    ],
  ];

  const tableConfigProps = useMapConfigToTableProps(
    R.identity,
    tableConfig,
    tableRows,
  );

  return <TableWithoutHeader {...{ ...tableConfigProps, ...tableProps }} />;
}

AlertsTable.propTypes = {
  searchText: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableRows: arrayOf(object),
  // eslint-disable-next-line react/forbid-prop-types
  tableProps: object,
  refetch: func.isRequired,
};

export function UserAlertsTable({ status, searchTerm }) {
  const { user } = useCurrentUserByStoreOrQuery();
  const { data, loading, tableProps, refetch } = useAlertsInfiniteScrollQuery(
    status,
    searchTerm,
  );

  if (loading || !user) {
    return <Loader />;
  }

  if (isAlertsEmpty(data)) {
    return <NoRecordsHeader>You have no alerts</NoRecordsHeader>;
  }

  const tableRows = prepareAlertsTableRows(data, user);

  return (
    <AlertsTable
      tableRows={tableRows}
      searchText={searchTerm}
      tableProps={tableProps}
      refetch={refetch}
    />
  );
}

UserAlertsTable.propTypes = {
  status: string.isRequired,
  searchTerm: string,
};
