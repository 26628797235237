import React from 'react';
import styled from 'styled-components';
import { Icon, getThemeColor } from 'poly-book-admin';

const WarningIconS = styled(Icon)`
  margin: 0 10px;
`;

export function SupplierDocumentWarningIconC() {
  return <WarningIconS size={15} name="yellowWarning" />;
}

export const SupplierDocumentPopoverContentWrapper = styled.div`
  padding: 10px;
  width: 230px;
  color: ${getThemeColor(['primary'])};
`;

export const SupplierDocumentWarningTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

export const SupplierDocumentTitleDivider = styled.div`
  width: 100%;
  height: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

export const SupplierDocumentWarningMessage = styled.div`
  font-size: 12px;
`;
