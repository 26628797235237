import React from 'react';
import { DefaultBodyWrapper, Loader } from 'poly-book-admin';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import { UserSettingsContent } from './UserSettingsContent.js';
import { UserSettingsPageHeader } from './components/UserSettingsPageHeader.js';

export function UserSettingsPage() {
  const { user, loading } = useCurrentUserByStoreOrQuery();

  return (
    <>
      <UserSettingsPageHeader />
      <DefaultBodyWrapper>
        {loading ? <Loader /> : <UserSettingsContent user={user} />}
      </DefaultBodyWrapper>
    </>
  );
}
