import * as R from 'ramda';
import {
  UPDATE_TAX_SECTION_SUPPLIER_PERMISSION,
  UPDATE_BANK_SECTION_SUPPLIER_PERMISSION,
  UPDATE_FINANCIAL_SECTION_SUPPLIER_PERMISSION,
  UPDATE_REMIT_SECTION_SUPPLIER_PERMISSION,
  UPDATE_DOCUMENTS_SECTION_SUPPLIER_PERMISSION,
  PRIVATE_SUPPLIER_AIT,
} from 'poly-security';
import { useHasUserAccessWithPermissionBase } from '../hooks/useHasUserAccessWithPermission.js';

export const useHasUserAccessToUpdateDocuments = () => {
  const [hasUserAccessToUpdateDocuments, docAccessLoading] =
    useHasUserAccessWithPermissionBase(
      UPDATE_DOCUMENTS_SECTION_SUPPLIER_PERMISSION,
    );

  return {
    hasUserAccessToUpdateDocuments,
    docAccessLoading,
  };
};

export const useHasUserAccessToUpdateSupplierProtected = () => {
  const [hasUserAccessToUpdateTax, taxAccessLoading] =
    useHasUserAccessWithPermissionBase(UPDATE_TAX_SECTION_SUPPLIER_PERMISSION);

  const [hasUserAccessToUpdateBank, bankAccessLoading] =
    useHasUserAccessWithPermissionBase(UPDATE_BANK_SECTION_SUPPLIER_PERMISSION);

  const [hasUserAccessToUpdateFinance, financeAccessLoading] =
    useHasUserAccessWithPermissionBase(
      UPDATE_FINANCIAL_SECTION_SUPPLIER_PERMISSION,
    );

  const [hasUserAccessToUpdateRemit, remitAccessLoading] =
    useHasUserAccessWithPermissionBase(
      UPDATE_REMIT_SECTION_SUPPLIER_PERMISSION,
    );

  const { hasUserAccessToUpdateDocuments, docAccessLoading } =
    useHasUserAccessToUpdateDocuments();

  return {
    protectedFieldsAccess: {
      hasUserAccessToUpdateTax,
      hasUserAccessToUpdateBank,
      hasUserAccessToUpdateFinance,
      hasUserAccessToUpdateRemit,
      hasUserAccessToUpdateDocuments,
    },
    accessLoading: R.any(R.equals(true), [
      taxAccessLoading,
      bankAccessLoading,
      financeAccessLoading,
      remitAccessLoading,
      docAccessLoading,
    ]),
  };
};

// eslint-disable-next-line import/no-unused-modules
export const useHasUserAccessToProtectedSupplier = (permission) => {
  const [hasAccess, loading] = useHasUserAccessWithPermissionBase(permission, {
    [PRIVATE_SUPPLIER_AIT]: true,
  });

  return loading ? false : hasAccess;
};
