import styled from 'styled-components';
import {
  Button,
  LinkButton,
  Table,
  moneyColumnStyles,
  getThemeColor,
} from 'poly-book-admin';
import * as R from 'ramda';

import { FlexColumn, JustifyFlexEnd } from '../../components/FlexContainer.js';

export const CustomerFiltersContainerS = styled(FlexColumn)`
  width: 100%;
`;

export const HeaderToolbarRow = styled(JustifyFlexEnd)`
  align-items: center;
  padding: ${R.propOr('7px 15px 0 0', 'padding')};
`;

export const CustomerSearchHeaderButton = styled(Button)`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  padding: 5px 10px;
  line-height: 12px;
  min-width: 90px;
`;

export const CustomerHistoryLinkButtonS = styled(LinkButton)`
  text-align: right;
`;

export const CustomerHistoryTableS = styled(Table)`
  tr {
    margin: 0 30px;
    width: calc(100% - 60px) !important;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 70px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 70px;
  }
  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};
  ${moneyColumnStyles(7)};

  @media print {
    tbody > tr {
      border: none;
      border-bottom: 1px solid ${getThemeColor(['secondary'])};
    }
  }
`;

export const SubTitleS = styled.p`
  font-size: 16px;
`;

export const CustomerNameS = styled.p`
  font-size: 16px;
  text-align: left;
  margin: 0 0 -20px 10px;
`;

export const CustomerHistoryFooterContainer = styled.tr`
  padding: 20px 0;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  td:nth-child(1) {
    padding-left: 10px;
    width: 390px;
  }
  ${moneyColumnStyles(2)};
  ${moneyColumnStyles(3)};
  ${moneyColumnStyles(4)};
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    padding-right: 20px;
  }
`;
