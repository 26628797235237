import React from 'react';
import * as R from 'ramda';
import {
  shape,
  arrayOf,
  string,
  oneOfType,
  object,
  func,
  bool,
  node,
} from 'prop-types';
import {
  useRouterQuery,
  useLocation,
  matchRoutes,
  useParams,
} from 'poly-client-routing';

import { MenuPopUp } from './MenuPopUp.js';
import { MenuLinkWrapper } from './hooks.js';
import { AppBarMenuItem, ItemIconS } from './styles.js';
import { prepareMenuItems, getRoutesByMenuItems } from './utils.js';
import { getThemeColor } from '../utils.js';
import { menuItemWithSubItemsPropTypes } from './propTypes.js';

const menuPayloadPropType = object;

function MenuItemWithProps({ isSub, title, Bubble, menuPayload, ...props }) {
  return (
    <AppBarMenuItem key={title} isSub={isSub} {...props}>
      {title}
      {isSub && (
        <ItemIconS
          name="arrow-down"
          color={props.isOpen ? getThemeColor(['white'])(props) : '#506fa9'}
          size={12}
        />
      )}
      {!!Bubble && <Bubble menuPayload={menuPayload} />}
    </AppBarMenuItem>
  );
}

MenuItemWithProps.propTypes = {
  menuPayload: menuPayloadPropType,
  title: string.isRequired,
  Bubble: oneOfType([node, func, object]),
  isSub: bool,
  isOpen: bool,
};

function RegularMenuItem({
  link,
  title,
  Bubble,
  isActive,
  inNewTab,
  permissions,
  menuPayload,
}) {
  return (
    <MenuLinkWrapper
      href={link}
      {...{ permissions }}
      {...(inNewTab && { target: '_blank' })}
      title={title}
    >
      <AppBarMenuItem {...{ isActive }}>
        {title}
        {!!Bubble && <Bubble menuPayload={menuPayload} />}
      </AppBarMenuItem>
    </MenuLinkWrapper>
  );
}

RegularMenuItem.defaultProps = { isActive: false };

const regularItemPropTypes = {
  isActive: bool,
  title: string.isRequired,
  link: oneOfType([string, object]).isRequired,
  permissions: arrayOf(string),
  inNewTab: bool,
  Bubble: node,
  menuPayload: menuPayloadPropType,
};

RegularMenuItem.propTypes = regularItemPropTypes;

export function MenuGenerator({ menuItems, menuPayload }) {
  const location = useLocation();
  const routesMatch = matchRoutes(getRoutesByMenuItems(menuItems), location);
  const currentRoute = R.pathOr('', [0, 'route', 'path'], routesMatch);

  const params = useParams();
  const query = useRouterQuery([]);

  const preparedMenuItems = prepareMenuItems({
    router: { params, query },
    menuItems,
    currentRoute,
  });

  return preparedMenuItems.map((item) =>
    item.subItems ? (
      <MenuPopUp
        key={item.title}
        {...item}
        Trigger={MenuItemWithProps}
        menuPayload={menuPayload}
      />
    ) : (
      <RegularMenuItem key={item.title} {...item} menuPayload={menuPayload} />
    ),
  );
}

MenuGenerator.propTypes = {
  menuPayload: menuPayloadPropType,
  menuItems: arrayOf(
    oneOfType([
      shape(regularItemPropTypes),
      shape(menuItemWithSubItemsPropTypes),
    ]),
  ).isRequired,
};
