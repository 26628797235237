import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { Input } from 'poly-book-admin';
import { number, string } from 'prop-types';

import { isRequiredValidation } from './validation.js';
import { ClientRequesterFormFieldLabel } from './common.js';

const QuestionFormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 75%;
`;

export function QuestionFormField({ index, name }) {
  const labelText = `Question ${index + 1}`;

  return (
    <QuestionFormFieldWrapper>
      <ClientRequesterFormFieldLabel>{labelText}</ClientRequesterFormFieldLabel>
      <Field
        name={`${name}.question`}
        validate={isRequiredValidation('Question is required')}
      >
        {({ input, meta }) => (
          <Input
            {...input}
            required
            showCharactersLeft
            maxLength={300}
            charactersLimit={300}
            meta={meta}
            error={meta.touched ? meta.error : undefined}
            dataTestId={`${name}.question`}
          />
        )}
      </Field>
    </QuestionFormFieldWrapper>
  );
}

QuestionFormField.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
};
