import {
  DEFAULT_SUPPLIER_FINANCIAL_TERMS,
  SupplierSources,
  supplierTypes,
} from 'poly-constants';

import { supplierStatuses } from '../../../../constants/suppliers.js';
import { supplierInitialOfficeHours } from '../form/utils.js';

const getSupplierTypeBySource = (source) =>
  source === SupplierSources.TIAA
    ? supplierTypes.ADMINISTRATIVE
    : supplierTypes.SUBCONTRACTOR;

export const initialValuesBySupplierSource = (source, forcedSupplierType) => {
  const type = forcedSupplierType || getSupplierTypeBySource(source);
  return {
    type,
    disableTypeInput: !!forcedSupplierType,
    status: supplierStatuses.ACTIVE,
    finance: {
      acceptsCreditCard: false,
      ...(type === supplierTypes.SUBCONTRACTOR
        ? {
            terms: DEFAULT_SUPPLIER_FINANCIAL_TERMS,
          }
        : {}),
    },
    mwbe: {
      minority: undefined,
      isMBE: false,
    },
    isWBE: false,
    bank: {
      payByACH: false,
    },
    tax: {
      is1099: false,
      exempt: false,
    },
    misc: {
      source,
    },
    service_24_7: false,
    company: { servicesIds: [], name: null },
    remarks: { ops: [] },
    supplierSource: source,
    foodBuy: { vaPercentage: 0 },
    officeHours: supplierInitialOfficeHours,
  };
};
