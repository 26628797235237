import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string, bool } from 'prop-types';
import { isChildRecurringProject, pathOrNothingUI } from 'poly-client-utils';
import { EntityStatus, LinkButton } from 'poly-book-admin';
import { FlexContainer, SidebarRow } from 'poly-admin-ui';

import {
  SidebarLabel,
  BlockWithLabel,
  SectionWrapper,
  headerTextProps,
  threeBlocksProps,
} from '../components/commonSidebarComponents.js';
import {
  projectStatusesUI,
  projectStatusesColors,
} from '../../modules/core/constants/projects.js';
import { SidebarIDs } from '../constants.js';
import { projectSidebarTabsId } from './constants.js';
import { OpenFullInfoSidebarButton } from '../components/OpenFullInfoSidebarButton.js';
import { useOpenProjectTabsSidebar } from './tabs/useOpenProjectTabsSidebar.js';
import { useEditProjectSidebar } from './forms/edit/useEditProjectSidebar.js';
import { getUserFriendlyProjectTypeName } from './projectSidebarUtils.js';
import { getUIProjectStatus } from '../../modules/core/utils/projects.js';
import { ProjectPrintToPDFButton } from '../../components/ProjectPrintToPDF/ProjectPrintToPDFButton.js';
import {
  getStartEndDateSection,
  getSectionText,
} from '../components/commonSidebarSectionFormatters.js';
import {
  checkProjectExpired,
  getDateByProp,
} from '../components/commonSidebarUtils.js';
import {
  formatStatusInfo,
  formatPriority,
} from '../../modules/core/formatters/index.js';
import {
  isHousekeepingProjectType,
  isNotFeeProjectType,
} from './forms/form/utils/sections.js';
import { useHasUserAccessToUpdateProject } from './useHasUserAccessToUpdateProject.js';

const EditButtonS = styled(LinkButton)`
  align-self: flex-start;
  position: relative;
  height: 2px;
  align-self: end;
  margin-right: 24px;
`;

function EditProjectButton({ project }) {
  const openEditProjectSidebar = useEditProjectSidebar(true);
  return (
    <EditButtonS
      disabled={!project}
      onClick={() => openEditProjectSidebar({ project })}
    >
      Edit
    </EditButtonS>
  );
}

EditProjectButton.propTypes = {
  project: shape({
    _id: string.isRequired,
  }),
};

// getProjectPriority :: Project -> String
const getProjectPriority = R.ifElse(
  isChildRecurringProject,
  R.always(null),
  R.compose(formatPriority, R.prop('priority')),
);

// isChildProject :: Project -> Boolean
const isChildProject = R.pathSatisfies(R.is(String), ['parent', '_id']);

// isShouldRenderScheduleDate :: Project -> Boolean
const isShouldRenderScheduleDate = R.allPass([
  isChildProject,
  isNotFeeProjectType,
  R.complement(isHousekeepingProjectType),
]);

export function ProjectStatusComp({ project }) {
  return (
    <EntityStatus
      title={getUserFriendlyProjectTypeName(project.type)}
      status={{
        color: projectStatusesColors[getUIProjectStatus(project)],
        text: projectStatusesUI[getUIProjectStatus(project)],
      }}
    />
  );
}

const projectPropTypes = shape({
  _id: string.isRequired,
  projectId: string.isRequired,
  type: string.isRequired,
});

ProjectStatusComp.propTypes = {
  project: projectPropTypes,
};

export function ProjectHeaderInfoComp({ project }) {
  const priority = getProjectPriority(project);
  const statusInfo = formatStatusInfo(project);
  const shouldRenderScheduleDate = isShouldRenderScheduleDate(project);

  return (
    <SidebarRow>
      {priority && (
        <BlockWithLabel
          id="priority"
          {...threeBlocksProps}
          width="calc(29% + 22px)"
          label="Priority"
          Component={getSectionText(priority, headerTextProps)}
        />
      )}
      <BlockWithLabel
        id="start-end-date"
        {...threeBlocksProps}
        width="calc(29% + 28px)"
        label="Start - End Date"
        Component={getStartEndDateSection(
          {
            startDate: getDateByProp('startDate')(project),
            endDate: getDateByProp('endDate')(project),
            isProjectExpired: checkProjectExpired(project),
          },
          headerTextProps,
        )}
      />
      {shouldRenderScheduleDate && (
        <BlockWithLabel
          id="schedule-date"
          {...threeBlocksProps}
          label="Schedule Date"
          Component={getSectionText(
            getDateByProp('scheduleDate')(project),
            headerTextProps,
          )}
        />
      )}
      {statusInfo && (
        <BlockWithLabel
          id="status-info"
          width="20%"
          label={statusInfo.label}
          Component={getSectionText(statusInfo.message, headerTextProps)}
        />
      )}
    </SidebarRow>
  );
}

ProjectHeaderInfoComp.propTypes = {
  project: projectPropTypes,
};

export function ProjectDescriptionComp({ project }) {
  return (
    <BlockWithLabel
      id="description"
      label="Description"
      Component={getSectionText(
        pathOrNothingUI(['description'])(project),
        headerTextProps,
      )}
    />
  );
}

ProjectDescriptionComp.propTypes = {
  project: projectPropTypes,
};

export function ProjectSidebarHeader({ project, isCard = false }) {
  const openFullProject = useOpenProjectTabsSidebar({ project });
  const hasAccessToUpdateProject = useHasUserAccessToUpdateProject();

  return (
    <SectionWrapper>
      <SidebarRow justify align>
        <FlexContainer>
          <SidebarLabel>{project.projectId}</SidebarLabel>
          <ProjectStatusComp project={project} />
        </FlexContainer>
        <FlexContainer gap="15px">
          <ProjectPrintToPDFButton project={project} />
          <OpenFullInfoSidebarButton
            isCard={isCard}
            sidebarId={SidebarIDs.project}
            fullSidebarId={projectSidebarTabsId}
            openSidebarHandler={openFullProject}
          />
        </FlexContainer>
      </SidebarRow>
      {hasAccessToUpdateProject && <EditProjectButton project={project} />}
      <ProjectHeaderInfoComp project={project} />
      <SidebarRow>
        <ProjectDescriptionComp project={project} />
      </SidebarRow>
    </SectionWrapper>
  );
}

ProjectSidebarHeader.propTypes = {
  project: shape({
    _id: string.isRequired,
    projectId: string.isRequired,
    type: string.isRequired,
  }),
  isCard: bool,
};
