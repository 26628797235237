import React from 'react';
import { useDispatch } from 'react-redux';
import { CREATE_MENTION_GROUP } from 'poly-security';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { TextButton, HeaderLinksWrapper } from 'poly-book-admin';
import { openModal } from 'poly-admin-ui/src/index.js';
import { modalNames } from '../../modals/index.js';

function AddMentionGroupButton(props) {
  const dispatch = useDispatch();

  const ifHasPermission = useHasUserAccessWithPermission(CREATE_MENTION_GROUP);

  const onClick = () => {
    dispatch(
      openModal({
        name: modalNames.ADD_MENTION_GROUP,
        payload: {},
      }),
    );
  };

  const additionalProps = {
    onClick,
    children: 'Add New',
  };

  return ifHasPermission ? (
    <TextButton {...props} {...additionalProps} />
  ) : null;
}

AddMentionGroupButton.displayName = 'AddMentionGroupButton';

export function MentionGroupsTableToolbar() {
  return (
    <HeaderLinksWrapper>
      <AddMentionGroupButton />
    </HeaderLinksWrapper>
  );
}
