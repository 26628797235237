import React from 'react';
import { EditMasterSupplierForm } from 'poly-admin-ui';
import { Loader } from 'poly-book-admin';
import { useHasUserAccessToUpdateSupplierProtected } from 'poly-client-utils';
import { string } from 'prop-types';

import { routesNames } from '../../routes/index.js';

const editMasterSupplierFormId = 'editMasterSupplierFormId';

export function EditMasterSupplier({ supplierId }) {
  const { protectedFieldsAccess, accessLoading } =
    useHasUserAccessToUpdateSupplierProtected();

  if (accessLoading) {
    return <Loader />;
  }

  return (
    <EditMasterSupplierForm
      masterSupplierRootUrl={routesNames.MASTER_SUPPLIER}
      supplierRootUrl={routesNames.SUPPLIER}
      formId={editMasterSupplierFormId}
      supplierId={supplierId}
      protectedFieldsAccess={protectedFieldsAccess}
      isSupplierTypeEditable
    />
  );
}

EditMasterSupplier.propTypes = {
  supplierId: string.isRequired,
};
