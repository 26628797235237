import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useReactiveQuery } from 'poly-client-utils/src/hooks/useReactiveQuery.js';
import { usePaginationParams } from 'poly-admin-ui/src/hooks/usePaginationParams.js';
import { useSetItemsCount } from 'poly-admin-ui/src/hooks/useSetItemsCount.js';

const SEARCH_CLIENT_PO_QUERY = gql`
  query SEARCH_CLIENT_PO_QUERY($searchInput: CollectionSearchParams) {
    searchAdminPurchaseOrders(input: $searchInput) {
      hits {
        _id
        poNumber
        displayName
        status
        startDate
        endDate
        type
        initialBalance
        currentBalance
        lowBalancePercent
        authorizedBy {
          _id
          fullName
        }
      }
      total
    }
  }
`;

export const SEARCH_CLIENT_PO_SUBSCRIPTION = gql`
  subscription SEARCH_CLIENT_PO_SUBSCRIPTION(
    $searchInput: CollectionSearchParams
  ) {
    searchAdminPurchaseOrderChanged(input: $searchInput) {
      id
      type
    }
  }
`;

export const useClientPurchaseOrders = (filter) => {
  const paginationParams = usePaginationParams();

  const queryOptions = {
    variables: {
      searchInput: {
        query: { term: filter },
        ...paginationParams,
      },
    },
    skip: !filter,
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_CLIENT_PO_QUERY,
    SEARCH_CLIENT_PO_SUBSCRIPTION,
    {
      queryOptions: {
        ...queryOptions,
        fetchPolicy: 'network-only',
      },
      subscriptionOptions: queryOptions,
    },
  );

  useSetItemsCount(R.pathOr(0, ['searchAdminPurchaseOrders', 'total']), data);

  return {
    purchaseOrders: R.pathOr([], ['searchAdminPurchaseOrders', 'hits'], data),
    loading,
  };
};
