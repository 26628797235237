import * as R from 'ramda';
import { useLocation, parseQuery } from 'poly-client-routing';

// getPreviousQueryParams :: Location -> PreviousQueryParams
// PreviousQueryParams = { sidebarTab: String, sidebarSubTab: String }
const getPreviousQueryParams = R.compose(
  R.pickAll(['sidebarTab', 'sidebarSubTab']),
  parseQuery,
  R.pathOr({}, ['state', 'previous', 'search']),
);

export const usePreviousSidebarParams = () => {
  const location = useLocation();

  return getPreviousQueryParams(location);
};
