import { useEffect, useCallback } from 'react';
import { debounceWithDefaultThreshold } from 'poly-client-utils';
import { useRouterQuery } from 'poly-client-routing';

import { SidebarIDs } from './constants.js';

export const DEFAULT_DOCUMENT_TITLE = 'Poly Admin';

export const useReactiveSidebarDocumentTitle = (
  title,
  isProjectSidebar = false,
) => {
  const { projectSidebarId } = useRouterQuery([SidebarIDs.project]);

  const titleOnMount = title || DEFAULT_DOCUMENT_TITLE;

  const titleOnOnMount =
    !isProjectSidebar && !!projectSidebarId
      ? projectSidebarId
      : DEFAULT_DOCUMENT_TITLE;

  const setDocumentTitleDebounced = useCallback(
    debounceWithDefaultThreshold((docTitle) => {
      document.title = docTitle;
    }),
    [],
  );

  useEffect(() => {
    setDocumentTitleDebounced(titleOnMount);

    return () => {
      document.title = titleOnOnMount;
    };
  }, [title]);
};
