import React, { useState } from 'react';
import { copyToClipBoard } from 'poly-client-utils';
import { shape, string } from 'prop-types';
import { useNotificationState } from 'poly-admin-ui';
import { Icon, Button } from 'poly-book-admin';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';

const ButtonWrapper = styled.div`
  display: flex;
`;

const UrlStyle = styled.span`
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 250px;
  white-space: nowrap;
`;

const IconS = styled(Icon)`
  cursor: pointer;
`;

function CopyUrlButton({ url }) {
  const { showSuccessNotification } = useNotificationState();

  const onCopyUrlClick = () => {
    copyToClipBoard(url);
    showSuccessNotification(`Copied ${url}`);
  };

  return (
    <ButtonWrapper>
      <UrlStyle>{url}</UrlStyle> <IconS name="copy" onClick={onCopyUrlClick} />
    </ButtonWrapper>
  );
}

CopyUrlButton.propTypes = {
  url: string.isRequired,
};

const CREATE_REQUEST_APP_ACCESS_MUTATION = gql`
  mutation createRequestAppAccess($propertyId: ID!) {
    createRequestAppAccess(propertyId: $propertyId)
  }
`;

function RequestAppAccessButton({ propertyId }) {
  const [isLoading, setIsLoading] = useState(false);

  const [createAccess] = useMutation(CREATE_REQUEST_APP_ACCESS_MUTATION, {
    variables: {
      propertyId,
    },
  });

  const handleClick = async () => {
    setIsLoading(true);
    await createAccess();
    setIsLoading(false);
  };

  return (
    <Button loader={isLoading} onClick={handleClick} size="tiny">
      Request
    </Button>
  );
}

RequestAppAccessButton.propTypes = {
  propertyId: string.isRequired,
};

export function SubmitProjectWebFormUrl({ property }) {
  const { _id, submitProjectWebFormUrl } = property;

  if (submitProjectWebFormUrl) {
    return <CopyUrlButton url={submitProjectWebFormUrl} />;
  }

  return <RequestAppAccessButton propertyId={_id} />;
}

SubmitProjectWebFormUrl.propTypes = {
  property: shape({
    _id: string.isRequired,
    submitProjectWebFormUrl: string,
  }).isRequired,
};
