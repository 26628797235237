import styled from 'styled-components';
import { Table } from 'poly-book-admin';

export const ClientsTableComp = styled(Table)`
  th:last-child,
  td:last-child {
    width: 10%;
    text-align: right;
    padding-right: 20px;
  }

  td:last-child {
    padding-right: 40px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 15%;
  }
`;
