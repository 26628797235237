import React from 'react';
import { element, func, object, oneOfType, string } from 'prop-types';
import styled from 'styled-components';

import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  MainHeader,
} from 'poly-book-admin/src/Header/index.js';
import { LinkButton } from 'poly-book-admin/src/LinkButton/index.js';
import { TableSearchInput } from 'poly-admin-ui/src/components/TableSearchInput.js';
import { TaskPrioritySelect } from 'poly-admin-ui/src/modules/forms/taskForm/form/components/TaskPrioritySelect.js';

import { useCreateTaskTemplateSidebar } from './sidebar/useCreateTaskTemplateSidebar.js';

const FiltersWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const TableSearchInputS = styled(TableSearchInput)`
  div {
    & > input {
      height: 31px;
    }
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 5px;
`;

const Label = styled.label`
  margin: 0;
  font-size: 11px;
  color: #bcbcbc;
`;

function HeaderField({ Component, label, ...props }) {
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <Component {...props} />
    </FieldWrapper>
  );
}

HeaderField.propTypes = {
  Component: oneOfType([element, func, object]),
  label: string,
};

function AddNewTaskTemplateBtn() {
  const openCreateTaskTemplateSidebar = useCreateTaskTemplateSidebar();

  return (
    <LinkButton onClick={() => openCreateTaskTemplateSidebar()}>
      Create Template
    </LinkButton>
  );
}

export function TaskTemplatesHeader({ setPriority, priority }) {
  return (
    <PageHeaderContainer padding="0px">
      <MainHeader>Task templates</MainHeader>
      <FiltersWrapper>
        <HeaderField
          Component={TableSearchInputS}
          label="Search"
          placeholder="Search by name & description"
          wrapperWidth="216px"
          inputWidth="97%"
        />
        <HeaderField
          Component={TaskPrioritySelect}
          width="160px"
          required={false}
          onChange={setPriority}
          value={priority}
          label="Priority"
          withAllOption
        />
        <HeaderLinksWrapper>
          <AddNewTaskTemplateBtn />
        </HeaderLinksWrapper>
      </FiltersWrapper>
    </PageHeaderContainer>
  );
}

TaskTemplatesHeader.propTypes = {
  setPriority: func.isRequired,
  priority: string,
};
