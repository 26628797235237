import { defaultTheme } from 'poly-book-admin';
import { MasterSupplierStatus } from 'poly-constants';

const {
  colors: {
    statuses: { active, blocked },
  },
} = defaultTheme;

const { ACTIVE, BLOCKED } = MasterSupplierStatus;

export const materSupplierStatusLabelUI = {
  [ACTIVE]: 'Active',
  [BLOCKED]: 'Blocked',
};

export const masterSupplierStatusColorUI = {
  [ACTIVE]: active,
  [BLOCKED]: blocked,
};
