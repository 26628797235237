import * as R from 'ramda';
import { formatFromToDateTitle } from 'poly-client-utils';
import { NOTHING_UI_STRING, ProjectSupplierStatus } from 'poly-constants';
import {
  ExcelExportCellType,
  convertCentsToDollars,
  createExcelExportCell,
} from 'poly-utils';

import { prepareClientQuoteDiscrepanciesFooterData } from './helpers.js';

// getExportRow :: QuoteDiscrepanciesReportRow -> [ExcelExportDataCell]
const getExportRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 2),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
    ExcelExportCellType.default,
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
  ]),
  R.juxt([
    R.pathOr(NOTHING_UI_STRING, ['project', 'projectId']),
    R.propOr(NOTHING_UI_STRING, 'number'),
    R.pathOr(0, ['project', 'nte']),
    R.compose(convertCentsToDollars, R.pathOr(0, ['amount'])),
    R.compose(
      R.when(R.isEmpty, R.always(NOTHING_UI_STRING)),
      R.join(', '),
      R.map(R.path(['company', 'name'])),
      R.filter(R.propEq('statusInProject', ProjectSupplierStatus.assigned)),
      R.pathOr([], ['project', 'suppliers']),
    ),
    R.propOr(0, 'discrepancyPercentage'),
    R.compose(convertCentsToDollars, R.propOr(0, 'discrepancy')),
  ]),
);

// getQuoteDiscrepanciesTotalRow :: {averageDiscrepancy: Number, totalDiscrepancy: Number} -> [ExcelExportDataCell]
export const getQuoteDiscrepanciesTotalRow = ({
  averageDiscrepancy,
  totalDiscrepancy,
}) =>
  R.zipWith(
    createExcelExportCell,
    [
      ...R.repeat(ExcelExportCellType.tableStringFooter, 4),
      ExcelExportCellType.tableCurrencyFooter,
      ExcelExportCellType.tableStringFooter,
      ExcelExportCellType.tableCurrencyFooter,
    ],
    ['', '', '', '', '', averageDiscrepancy, totalDiscrepancy],
  );

// getQuoteDiscrepanciesHeaderRow :: [String] -> [ExcelExportDataCell]
export const getQuoteDiscrepanciesHeaderRow = R.zipWith(createExcelExportCell, [
  ...R.repeat(ExcelExportCellType.tableHeader, 2),
  ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 2),
  ExcelExportCellType.tableHeader,
  ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 2),
]);

export const getQuoteDiscrepanciesXlsConfig = ({
  tableRows,
  startDate,
  endDate,
}) => {
  const { averageDiscrepancy, totalDiscrepancy } =
    prepareClientQuoteDiscrepanciesFooterData(tableRows);

  return {
    exportFileName: 'quote-discrepancies-report.xlsx',
    columnWidths: [15, 15, 15, 15, 25, 15, 15],
    rows: [
      [{ value: 'Spend Report', cellType: ExcelExportCellType.title }],
      [
        {
          value: formatFromToDateTitle(startDate, endDate),
          cellType: ExcelExportCellType.subTitle,
        },
      ],
      getQuoteDiscrepanciesHeaderRow([
        'Project #',
        'Client Invoice #',
        'Client NTE',
        'Client Invoice',
        'Suppliers',
        'Discrepancy in %',
        'Discrepancy',
      ]),
      ...tableRows.map((tableRow) => getExportRow(tableRow)),
      getQuoteDiscrepanciesTotalRow({ averageDiscrepancy, totalDiscrepancy }),
    ],
  };
};
