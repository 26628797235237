import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { string, oneOfType, arrayOf, node, func, object } from 'prop-types';
import { RecurringProjectStatuses, AccountingStatus } from 'poly-constants';
import { Input, Checkbox, getThemeColor } from 'poly-book-admin';
import {
  ServiceTypesSelect,
  ClientSelect,
  StateSelect,
  CityInput,
} from 'poly-admin-ui';

import {
  ProjectFilterStatuses,
  ProjectFilterTypes,
} from '../../modules/pagesHeaders/ProjectSearchPageHeader/filter/constants.js';
import {
  PropertySelect,
  DatePicker,
} from '../../modules/pagesHeaders/ProjectSearchPageHeader/filter/components.js';
import { ProjectTypeSelect } from '../../components/ProjectTypeSelect.js';
import { ManagerSelect } from '../../components/AACManagerSelect.js';
import { ALL } from '../../modules/core/constants/general.js';
import { AssetTypeSelect } from '../../sidebars/AssetSidebar/forms/form/components/AssetTypeSelect.js';

const defaultOrChildProjectTypes = [
  ProjectFilterTypes.REACTIVE,
  ProjectFilterTypes.CHILD_RECURRING,
  ProjectFilterTypes.CHILD_PREVENTIVE_MAINTENANCE,
];

// isRecurringProjectSelected :: SearchFilters -> Boolean
const isRecurringProjectSelected = R.prop(ProjectFilterTypes.MASTER_RECURRING);

// isPMProjectSelected :: SearchFilters -> Boolean
const isPMProjectSelected = R.prop(ProjectFilterTypes.PREVENTIVE_MAINTENANCE);

// isRecurringOrPMProjectSelected :: SearchFilters -> Boolean
const isRecurringOrPMProjectSelected = R.either(
  isPMProjectSelected,
  isRecurringProjectSelected,
);

// recurringOrPMProjectNotSelected :: SearchFilters -> Boolean
const recurringOrPMProjectNotSelected = R.complement(
  isRecurringOrPMProjectSelected,
);

// defaultOrChildProjectNotSelected :: SearchFilters -> Boolean
const defaultOrChildProjectNotSelected = R.compose(
  R.none(R.equals(true)),
  R.props(defaultOrChildProjectTypes),
);

const projectTypeStyle = css`
  & div:first-child {
    flex-shrink: 0;
  }
`;

const rangeStyles = css`
  top: 35px;
  left: -65px;
`;

const dropDownsTypeStyle = css`
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 14px 0 0 0;
  padding: 0;
`;

const commonInlineStyles = {
  width: '165px',
};

const ServiceTypesSelectS = styled(ServiceTypesSelect)`
  .select__menu {
    width: 161%;
    right: 0;
  }
`;

const PropertySelectS = styled(PropertySelect)`
  .select__menu {
    width: 140%;
    right: 0;
  }
`;

const ClientSelectS = styled(ClientSelect).attrs(
  R.always({
    withoutSkip: true,
    includeAllOption: false,
  }),
)`
  .select__menu {
    width: 140%;
    right: 0;
  }
`;

const WrapperInner = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 14px;

  &:nth-child(odd) {
    padding-right: 15px;
  }

  &:nth-child(even) {
    padding-left: 15px;
  }
`;

const DateRowWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
  position: relative;

  > div:last-child {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
      margin: 0;
    }
  }
`;

const FilterProjectsCheckbox = styled(Checkbox)`
  flex-shrink: 0;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }
`;

export const RowTitle = styled.div`
  flex-shrink: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 100px;
  color: ${getThemeColor(['primary'])};

  ${({ titleWidth }) => !!titleWidth && `width: ${titleWidth}`};
`;

const SecondRowTitle = styled(RowTitle)`
  position: absolute;
  right: 165px;
`;

function Wrapper({ title, Component, ...props }) {
  return (
    <WrapperInner>
      <RowTitle>{title}</RowTitle>
      <Component {...props} />
    </WrapperInner>
  );
}

const accountingRowCss = css`
  flex-wrap: wrap;
`;

const CheckBoxWrapper = styled.div`
  margin-top: 5px;
`;

Wrapper.propTypes = {
  title: oneOfType([string, arrayOf(string)]),
  Component: oneOfType([node, func, object]).isRequired,
};

const searchProjectsFiltersNames = {
  clientId: 'clientId',
  managerId: 'managerId',
  propertyId: 'propertyId',
  state: 'state',
  city: 'city',
  date: 'date',
  keywords: 'keywords',
  type: 'type',
  serviceTypeId: 'serviceTypeId',
  assetTypeId: 'assetTypeId',
};

export const filterProjectsConfig = [
  {
    key: 'statusRow',
    rowTitle: 'Status',
    columns: [
      {
        name: RecurringProjectStatuses.ACTIVE,
        filterProps: { label: 'Active' },
        defaultValue: false,
        renderIf: isRecurringOrPMProjectSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: RecurringProjectStatuses.CLOSED,
        filterProps: { label: 'Closed' },
        defaultValue: false,
        renderIf: isRecurringOrPMProjectSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: ProjectFilterStatuses.ACTIVE,
        filterProps: { label: 'Active' },
        defaultValue: false,
        renderIf: recurringOrPMProjectNotSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: ProjectFilterStatuses.PENDING,
        filterProps: { label: 'Pending' },
        defaultValue: false,
        renderIf: recurringOrPMProjectNotSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: ProjectFilterStatuses.COMPLETED,
        filterProps: { label: 'Completed' },
        defaultValue: false,
        renderIf: recurringOrPMProjectNotSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: ProjectFilterStatuses.ON_HOLD,
        filterProps: { label: 'On Hold' },
        defaultValue: false,
        renderIf: recurringOrPMProjectNotSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: ProjectFilterStatuses.CANCELLED,
        filterProps: { label: 'Blocked' },
        defaultValue: false,
        renderIf: recurringOrPMProjectNotSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: ALL,
        filterProps: { label: 'All' },
        defaultValue: false,
        renderIf: recurringOrPMProjectNotSelected,
        subscribers: [
          [ProjectFilterStatuses.ACTIVE, R.identity],
          [ProjectFilterStatuses.PENDING, R.identity],
          [ProjectFilterStatuses.COMPLETED, R.identity],
          [ProjectFilterStatuses.ON_HOLD, R.identity],
          [ProjectFilterStatuses.CANCELLED, R.identity],
        ],
        Component: FilterProjectsCheckbox,
      },
    ],
  },
  {
    key: 'accountingRow',
    rowTitle: 'Accounting',
    rowLayout: accountingRowCss,
    columns: [
      {
        name: AccountingStatus.READY_TO_INVOICE,
        filterProps: { label: 'Ready To Invoice' },
        defaultValue: false,
        Component: FilterProjectsCheckbox,
        renderIf: recurringOrPMProjectNotSelected,
      },
      {
        name: AccountingStatus.PENDING,
        filterProps: { label: 'Pending' },
        defaultValue: false,
        Component: FilterProjectsCheckbox,
        renderIf: recurringOrPMProjectNotSelected,
      },
      {
        name: AccountingStatus.INVOICED,
        filterProps: { label: 'Invoiced' },
        defaultValue: false,
        Component: FilterProjectsCheckbox,
        renderIf: recurringOrPMProjectNotSelected,
      },
      {
        name: AccountingStatus.PAID,
        filterProps: { label: 'Paid' },
        defaultValue: false,
        Component: FilterProjectsCheckbox,
        renderIf: recurringOrPMProjectNotSelected,
      },
      {
        name: AccountingStatus.NON_BILLABLE,
        filterProps: { label: 'Non Billable' },
        defaultValue: false,
        Component: FilterProjectsCheckbox,
        renderIf: recurringOrPMProjectNotSelected,
      },
      {
        name: AccountingStatus.REPORT_ONLY,
        filterProps: { label: 'Report Only' },
        defaultValue: false,
        Component: (props) => (
          <CheckBoxWrapper>
            <FilterProjectsCheckbox {...props} />
          </CheckBoxWrapper>
        ),
        renderIf: recurringOrPMProjectNotSelected,
      },
    ],
  },
  {
    key: 'projectType',
    rowTitle: 'Project Type',
    rowLayout: projectTypeStyle,
    columns: [
      {
        name: ProjectFilterTypes.REACTIVE,
        filterProps: { label: 'Project' },
        defaultValue: false,
        renderIf: recurringOrPMProjectNotSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: ProjectFilterTypes.CHILD_RECURRING,
        renderIf: recurringOrPMProjectNotSelected,
        defaultValue: false,
        filterProps: { label: 'Child Recurring' },
        Component: FilterProjectsCheckbox,
      },
      {
        name: ProjectFilterTypes.MASTER_RECURRING,
        filterProps: { label: 'Master Recurring' },
        defaultValue: false,
        renderIf: defaultOrChildProjectNotSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: ProjectFilterTypes.CHILD_PREVENTIVE_MAINTENANCE,
        filterProps: { label: 'Child PM' },
        defaultValue: false,
        renderIf: recurringOrPMProjectNotSelected,
        Component: FilterProjectsCheckbox,
      },
      {
        name: ProjectFilterTypes.PREVENTIVE_MAINTENANCE,
        filterProps: { label: 'PM' },
        defaultValue: false,
        renderIf: defaultOrChildProjectNotSelected,
        Component: FilterProjectsCheckbox,
      },
    ],
  },
  {
    key: 'detailsRow',
    rowLayout: dropDownsTypeStyle,
    columns: [
      {
        name: searchProjectsFiltersNames.type,
        filterProps: commonInlineStyles,
        Component: (props) => (
          <Wrapper title="Cost Type" Component={ProjectTypeSelect} {...props} />
        ),
        defaultValue: ALL,
      },
      {
        name: searchProjectsFiltersNames.clientId,
        subscribers: [['propertyId', R.always(null)]],
        filterProps: { ...commonInlineStyles, placeholder: 'Start typing...' },
        Component: (props) => (
          <Wrapper title="Client Name" Component={ClientSelectS} {...props} />
        ),
      },
      {
        name: searchProjectsFiltersNames.managerId,
        filterProps: { ...commonInlineStyles, placeholder: 'Start typing...' },
        Component: (props) => (
          <Wrapper title="Assigned CSR" Component={ManagerSelect} {...props} />
        ),
      },
      {
        name: searchProjectsFiltersNames.propertyId,
        filterProps: {
          ...commonInlineStyles,
          includeMainAccountOption: true,
          placeholder: 'Start typing...',
        },
        Component: (props) => (
          <Wrapper
            title="Property Name"
            Component={PropertySelectS}
            {...props}
          />
        ),
      },
      {
        name: searchProjectsFiltersNames.serviceTypeId,
        filterProps: { ...commonInlineStyles, placeholder: 'Start typing...' },
        Component: (props) => (
          <Wrapper
            title="Service Type"
            Component={ServiceTypesSelectS}
            {...props}
            isClearable
          />
        ),
      },
      {
        name: searchProjectsFiltersNames.assetTypeId,
        filterProps: { ...commonInlineStyles, placeholder: 'Start typing...' },
        Component: (props) => (
          <Wrapper
            title="Asset Type"
            Component={AssetTypeSelect}
            {...props}
            isClearable
          />
        ),
      },
      {
        name: searchProjectsFiltersNames.keywords,
        defaultValue: '',
        filterProps: { ...commonInlineStyles, placeholder: 'Start typing...' },
        Component: (props) => (
          <Wrapper title="Keyword" Component={Input} {...props} />
        ),
      },
      {
        name: 'empty-component',
        Component: () => <Wrapper Component={() => null} />,
      },
      {
        name: searchProjectsFiltersNames.state,
        filterProps: { ...commonInlineStyles, placeholder: 'Start typing...' },
        Component: (props) => (
          <Wrapper title="State" Component={StateSelect} {...props} />
        ),
      },
      {
        name: searchProjectsFiltersNames.city,
        defaultValue: '',
        filterProps: { ...commonInlineStyles, placeholder: 'Start typing...' },
        Component: (props) => (
          <Wrapper title="City" Component={CityInput} {...props} />
        ),
      },
      {
        name: searchProjectsFiltersNames.date,
        filterProps: {
          leftMove: '-145px',
          styleType: 'column',
          rangeStyles,
          ...commonInlineStyles,
          isMultiple: true,
          placeholder: 'Date',
        },
        nestedFields: ['startDate', 'endDate'],
        defaultValue: {},
        Component: (props) => (
          <DateRowWrapper>
            <RowTitle>Start</RowTitle>
            <SecondRowTitle>End</SecondRowTitle>
            <DatePicker {...props} />
          </DateRowWrapper>
        ),
      },
    ],
  },
];
