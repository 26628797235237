import { RWOTaskListType } from './recurring.js';
import { ProjectType } from './constants.js';
import { taskActionTypes } from '../tasks.js';
import { defaultRecurringProjectTasksConfig } from './recurring-tasks.js';

export const CommonTasksActionsTypesUIMap = {
  [taskActionTypes.CONFIRM_SCOPE]: 'Confirm Job Scope',
  [taskActionTypes.SUPPLIER_ASSIGN]: 'Assign Supplier',
  [taskActionTypes.SUPPLIER_SCHEDULING]: 'Confirm Scheduling',
  [taskActionTypes.SUPPLIER_FOLLOWUP]: 'Supplier Followup',
  [taskActionTypes.CONFIRM_SATISFACTION]: 'Confirm Satisfaction',
  [taskActionTypes.PROJECT_COMPLETE]: 'Work Completed',
  [taskActionTypes.CLOSE_PROJECT]: 'Close Project',
};

const feeProjectTaskConfigs = [
  {
    description: 'Complete/Close Project',
    action: taskActionTypes.CLOSE_PROJECT,
    dueDateConfig: {
      default: 0,
    },
  },
];

// eslint-disable-next-line import/no-unused-modules
export const FeeProjectTasksConfig = {
  type: ProjectType.FEE,
  taskLists: {
    default: feeProjectTaskConfigs,
    [RWOTaskListType.LONG_LIST]: feeProjectTaskConfigs,
    [RWOTaskListType.SHORT_LIST]: feeProjectTaskConfigs,
  },
};

// eslint-disable-next-line import/no-unused-modules
export const TimeAndMaterialProjectTasksConfig = {
  type: ProjectType.WORK_ORDER,
  taskLists: {
    default: [
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.CONFIRM_SCOPE],
        action: taskActionTypes.CONFIRM_SCOPE,
        dueDateConfig: {
          default: 0,
        },
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.SUPPLIER_ASSIGN],
        action: taskActionTypes.SUPPLIER_ASSIGN,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.SUPPLIER_SCHEDULING],
        action: taskActionTypes.SUPPLIER_SCHEDULING,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.SUPPLIER_FOLLOWUP],
        action: taskActionTypes.SUPPLIER_FOLLOWUP,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.CONFIRM_SATISFACTION],
        action: taskActionTypes.CONFIRM_SATISFACTION,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.PROJECT_COMPLETE],
        action: taskActionTypes.PROJECT_COMPLETE,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.CLOSE_PROJECT],
        action: taskActionTypes.CLOSE_PROJECT,
      },
    ],
    ...defaultRecurringProjectTasksConfig,
  },
};

const BidProjectTaskConfig = {
  type: ProjectType.BID,
  taskLists: TimeAndMaterialProjectTasksConfig.taskLists,
};

const PassThroughProjectTaskConfig = {
  type: ProjectType.PASS_THROUGH,
  taskLists: TimeAndMaterialProjectTasksConfig.taskLists,
};

// eslint-disable-next-line import/no-unused-modules
export const ReportOnlyProjectTasksConfig = {
  type: ProjectType.REPORT_ONLY,
  taskLists: TimeAndMaterialProjectTasksConfig.taskLists,
};

const HousekeepingProjectTaskConfig = {
  type: ProjectType.HOUSEKEEPING,
  taskLists: TimeAndMaterialProjectTasksConfig.taskLists,
};

// applicable to PMs at Admin
const PreventiveMaintenanceProjectTasksConfig = {
  type: ProjectType.PREVENTIVE_MAINTENANCE,
  taskLists: {
    default: [
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.CONFIRM_SCOPE],
        action: taskActionTypes.CONFIRM_SCOPE,
        dueDateConfig: {
          default: 0,
        },
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.SUPPLIER_ASSIGN],
        action: taskActionTypes.SUPPLIER_ASSIGN,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.SUPPLIER_SCHEDULING],
        action: taskActionTypes.SUPPLIER_SCHEDULING,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.SUPPLIER_FOLLOWUP],
        action: taskActionTypes.SUPPLIER_FOLLOWUP,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.CONFIRM_SATISFACTION],
        action: taskActionTypes.CONFIRM_SATISFACTION,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.PROJECT_COMPLETE],
        action: taskActionTypes.PROJECT_COMPLETE,
      },
      {
        description:
          CommonTasksActionsTypesUIMap[taskActionTypes.CLOSE_PROJECT],
        action: taskActionTypes.CLOSE_PROJECT,
      },
    ],
  },
};

export const ListingTasksActionsTypes = {
  TAKE_OVER_AND_LOCKBOX_COMPLETION: 'takeOverAndLockboxCompletion',
  BROKER_PRICE_OPINION: 'brokerPriceOpinion',
  COMPLETE_SALES_ADDENDUM: 'completeSalesAddendum',
  LIST_FOR_SALE: 'listForSale',
  BROKER_UPDATES: 'brokerUpdates',
  UNDER_CONTRACT: 'underContract',
};

export const ListingProjectActionsSpecificFields = {
  [ListingTasksActionsTypes.BROKER_PRICE_OPINION]: [
    'bpoListPrice',
    'bpoLow',
    'bpoHigh',
    'bpoDate',
  ],
  [ListingTasksActionsTypes.COMPLETE_SALES_ADDENDUM]: ['salesAddendumDate'],
  [ListingTasksActionsTypes.LIST_FOR_SALE]: ['listPrice', 'listDate'],
  [ListingTasksActionsTypes.UNDER_CONTRACT]: [
    'underContractDate',
    'dueDiligenceExp',
    'underContractPrice',
    'expectedCloseDate',
  ],
  [taskActionTypes.PROJECT_COMPLETE]: ['referralFee'],
};

export const ListingTasksActionsTypesUIMap = {
  [ListingTasksActionsTypes.TAKE_OVER_AND_LOCKBOX_COMPLETION]:
    'Take Over & Lockbox Completion',
  [ListingTasksActionsTypes.BROKER_PRICE_OPINION]: 'Broker Price Opinion',
  [ListingTasksActionsTypes.COMPLETE_SALES_ADDENDUM]: 'Complete Sales Addendum',
  [ListingTasksActionsTypes.LIST_FOR_SALE]: 'List For Sale',
  [ListingTasksActionsTypes.BROKER_UPDATES]: 'Broker Updates',
  [ListingTasksActionsTypes.UNDER_CONTRACT]: 'Under Contract',
  [taskActionTypes.PROJECT_COMPLETE]: 'Complete Sale',
  [taskActionTypes.CLOSE_PROJECT]: 'Close Project',
};

const ListingProjectTasksConfig = {
  type: ProjectType.LISTING,
  taskLists: {
    default: [
      {
        description: 'Take Over & Lockbox Completion',
        action: ListingTasksActionsTypes.TAKE_OVER_AND_LOCKBOX_COMPLETION,
        dueDateConfig: {
          default: 0,
        },
      },
      {
        description: 'Broker Price Opinion',
        action: ListingTasksActionsTypes.BROKER_PRICE_OPINION,
      },
      {
        description: 'Complete Sales Addendum',
        action: ListingTasksActionsTypes.COMPLETE_SALES_ADDENDUM,
      },
      {
        description: 'List For Sale',
        action: ListingTasksActionsTypes.LIST_FOR_SALE,
      },
      {
        description: 'Broker Updates',
        action: ListingTasksActionsTypes.BROKER_UPDATES,
      },
      {
        description: 'Under Contract',
        action: ListingTasksActionsTypes.UNDER_CONTRACT,
      },
      {
        description: 'Complete Sale',
        action: taskActionTypes.PROJECT_COMPLETE,
      },
      {
        description: 'Close Project',
        action: taskActionTypes.CLOSE_PROJECT,
      },
    ],
  },
};

export const taskConfigsForAllProjectTypes = [
  TimeAndMaterialProjectTasksConfig,
  BidProjectTaskConfig,
  ReportOnlyProjectTasksConfig,
  PassThroughProjectTaskConfig,
  FeeProjectTasksConfig,
  HousekeepingProjectTaskConfig,
  PreventiveMaintenanceProjectTasksConfig,
  ListingProjectTasksConfig,
];
