import * as R from 'ramda';
import { addDays } from 'date-fns';
import {
  DEFAULT_SUPPLIER_FINANCIAL_TERMS,
  InvoicesStatuses,
  supplierFinTerms,
  VirtualInvoiceStatuses,
  ProjectType,
} from 'poly-constants';

// addDaysCurried :: Date -> Number -> Date
const addDaysCurried = R.curryN(2, addDays);

const invoiceDueDaysMap = {
  [supplierFinTerms.ON_RECEIPT]: 0,
  [supplierFinTerms.DAYS_5]: 5,
  [supplierFinTerms.DAYS_10]: 10,
  [supplierFinTerms.DAYS_15]: 15,
  [supplierFinTerms.DAYS_20]: 20,
  [supplierFinTerms.DAYS_30]: 30,
  [supplierFinTerms.DAYS_45]: 45,
  [supplierFinTerms.DAYS_60]: 60,
};

// getInvoiceDueDaysByTerms :: String -> Number
export const getInvoiceDueDaysByTerms = R.compose(
  R.prop(R.__, invoiceDueDaysMap),
  R.defaultTo(DEFAULT_SUPPLIER_FINANCIAL_TERMS),
);

// getInvoiceDueDateByTerms :: String -> Invoice -> Date
export const getInvoiceDueDateByTerms = R.curry((terms, invoice) =>
  R.converge(addDaysCurried, [
    R.compose(R.constructN(1, Date), R.prop('invoiceDate')),
    R.compose(getInvoiceDueDaysByTerms, R.always(terms)),
  ])(invoice),
);

// isVirtualInvoice :: Invoice -> Boolean
export const isVirtualInvoice = R.propSatisfies(
  R.includes(R.__, VirtualInvoiceStatuses),
  'status',
);

// isAllowedToDeleteInvoice :: InvoiceWithProject -> Boolean
// InvoiceWithProject = {
//    ...Invoice
//    project: ProjectWithInvoices
// }
// ProjectWithInvoices = {
//    ...Project
//    invoices: [Invoice]
// }
export const isAllowedToDeleteInvoice = R.either(
  R.propEq('status', InvoicesStatuses.REJECTED),
  R.pathEq(['project', 'type'], ProjectType.REPORT_ONLY),
);
