import React from 'react';
import * as R from 'ramda';
import { isDocumentProjectRelated } from 'poly-utils';
import {
  dNdTextAreaFileValidators,
  equalsOmitFields,
  validateFilesFunc,
  useDecoratePersistenceForOnChange,
} from 'poly-client-utils';
import { func } from 'prop-types';

import {
  UpdateTypesSelect,
  SupplierUpdateDetails,
} from './components/index.js';
import { MailTo } from '../fields/MailTo/index.js';
import { mailToValidator } from '../fields/MailTo/validators.js';
import { DnDAreaWithMentions } from '../../../components/DnDAreaWithMentions.js';
import { validateMailToField } from '../fields/MailTo/mailToUtils.js';

function DnDAreaWithPersist({ onChange, onChangePersistentValue, ...props }) {
  const onChangePersistent = useDecoratePersistenceForOnChange(
    onChangePersistentValue,
    onChange,
    R.equals({ text: '', attachments: [], mentions: {} }),
  );

  return <DnDAreaWithMentions {...props} onChange={onChangePersistent} />;
}

DnDAreaWithPersist.propTypes = {
  onChange: func.isRequired,
  onChangePersistentValue: func.isRequired,
};

export const updateSections = (
  entity,
  disableMentions,
  onChangePersistentValue,
) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Update Type',
        order: 1,
        layout: {
          row: 1,
          width: '282px',
        },
        required: true,
        field: {
          name: 'updateType',
          Component: UpdateTypesSelect,
        },
      },
      {
        order: 2,
        field: {
          name: 'updateDetails',
          Component: (props) => (
            <DnDAreaWithPersist
              disableMentions={disableMentions}
              onChangePersistentValue={onChangePersistentValue}
              autoFocus
              {...props}
            />
          ),
          isEqual: equalsOmitFields([['mentions']]),
        },
        validators: dNdTextAreaFileValidators,
        validateFunction: validateFilesFunc,
      },
      {
        order: 3,
        field: {
          name: 'mailTo',
          Component: (props) => <MailTo projectDbId={entity._id} {...props} />,
        },
        validators: mailToValidator,
        validateFunction: validateMailToField,
        renderIf: R.both(
          R.compose(
            R.complement(R.propEq('isHQProject', true)),
            R.always(entity),
          ),
          isDocumentProjectRelated,
        ),
      },
      {
        order: 4,
        field: {
          name: 'supplierUpdateDetails',
          Component: SupplierUpdateDetails,
        },
        renderIf: R.allPass([
          R.prop('isRecallMode'),
          R.path(['mailTo', 'supplier', 'shouldSend']),
          R.pipe(
            R.path(['mailTo', 'supplier', 'emails']),
            R.complement(R.isEmpty),
          ),
        ]),
      },
    ],
  },
];
