import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { ProjectSupplierStatus } from 'poly-constants';
import { useEntityMultipleSubscription } from 'poly-client-utils/src/hooks/useEntityMultipleSubscriptions.js';

import { PROJECT_DETAILS } from '../../modules/core/hooks/projects/queries.js';
import { projectDetailsFields } from '../../modules/core/hooks/projects/fragments.js';
import {
  CLIENT_NAME_SUB,
  getSubscriptionOptionsByPath,
} from '../MasterRecurringProjectSidebar/useMasterRecurringProjectSidebarData.js';
import { purchaseOrderDetailSubs } from '../PurchaseOrderSidebar/usePurchaseOrderDetail.js';

export const PROJECT_DETAILS_SUB = gql`
  subscription PROJECT_DETAILS_SUB($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      document {
        ...projectDetailsFields
      }
    }
  }
  ${projectDetailsFields}
`;

// eslint-disable-next-line import/no-unused-modules
export const PROJECT_ESTIMATE_SUB = gql`
  subscription PROJECT_ESTIMATE_SUB($input: ProjectEstimateChangedSubInput!) {
    projectEstimateChanged(input: $input) {
      id
      type
    }
  }
`;

// prepareProjectSuppliers :: { project: Project } -> { project: Project }
const prepareProjectSuppliers = R.over(
  R.lensProp('project'),
  R.unless(
    R.isNil,
    R.compose(
      R.mergeAll,
      R.juxt([
        R.identity,
        R.applySpec({
          suppliers: R.compose(
            R.filter(
              R.propEq('statusInProject', ProjectSupplierStatus.assigned),
            ),
            R.propOr([], 'suppliers'),
          ),
          suppliersAdded: R.compose(
            R.filter(R.propEq('statusInProject', ProjectSupplierStatus.added)),
            R.propOr([], 'suppliers'),
          ),
        }),
      ]),
    ),
  ),
);

// getProjectEstimateSubOptions :: ProjectQueryResult -> SubscriptionOptions
const getProjectEstimateSubOptions = R.applySpec({
  skip: R.compose(R.isNil, R.path(['project', '_id'])),
  variables: R.compose(
    R.objOf('input'),
    R.objOf('projectId'),
    R.path(['project', '_id']),
  ),
});

export const useProjectSidebarData = (projectId) => {
  const skipRecurringProjectDetailsSub = useSelector(
    R.prop('conversionRecurringProject'),
  );
  const skipIsPreventativeRepairChangedSub = useSelector(
    R.prop('preventativeRepairChanged'),
  );

  const queryOptions = {
    variables: { projectId },
    skip: skipRecurringProjectDetailsSub,
    fetchPolicy: 'network-only',
  };

  const subscriptionOptions = {
    variables: { input: { projectId } },
    skip: skipRecurringProjectDetailsSub || skipIsPreventativeRepairChangedSub,
  };

  const { data, loading } = useEntityMultipleSubscription(
    PROJECT_DETAILS,
    [
      PROJECT_DETAILS_SUB,
      CLIENT_NAME_SUB,
      PROJECT_ESTIMATE_SUB,
      purchaseOrderDetailSubs,
    ],
    {
      queryOptions,
      getSubscriptionOptions: [
        R.always(subscriptionOptions),
        getSubscriptionOptionsByPath(['project', 'client', '_id']),
        getProjectEstimateSubOptions,
        getSubscriptionOptionsByPath(['project', 'adminPurchaseOrder', '_id']),
      ],
    },
  );

  return {
    loading,
    ...(data ? prepareProjectSuppliers(data) : {}),
  };
};
