import * as R from 'ramda';
import React, { useState } from 'react';
import arrayMutators from 'final-form-arrays';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { ClientBidRulesTypes } from 'poly-constants';
import { FormCreator, Text } from 'poly-book-admin';
import {
  CommonSidebarFormWrapper,
  useNotificationState,
  SidebarRow,
} from 'poly-admin-ui';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  isNilOrEmpty,
  formatTotal,
} from 'poly-utils';

import {
  ColumnList,
  TabSectionWrapper,
  EditClientTabButton,
} from '../commonComponents.js';
import {
  editClientBidRulesFormId,
  ClientBidRulePerAssetFeeLabel,
  ClientBidRuleManagementFeeLabel,
} from '../../constants.js';
import { commonModalLayout } from '../../../../modules/forms/common.js';
import { ClientBidRulesLineComponent } from './ClientBidRulesLineComponent.js';
import { BlockWithLabel } from '../../../components/commonSidebarComponents.js';

// isPercentageTypeValue :: ClientBidRule -> Boolean
const isPercentageTypeValue = R.propEq(
  'type',
  ClientBidRulesTypes.jobCostPercentage,
);

// getRulesAmount :: [ClientBidRule] -> [String]
const getRulesAmount = R.compose(
  R.map(
    R.ifElse(
      isPercentageTypeValue,
      R.compose(R.concat(R.__, '%'), R.toString, R.prop('amount')),
      R.compose(formatTotal, convertCentsToDollars, R.prop('amount')),
    ),
  ),
  R.defaultTo([]),
);

// getRulesLabel :: [ClientBidRule] -> [String]
const getRulesLabel = R.compose(R.map(R.prop('label')), R.defaultTo([]));

// getRulesStatus :: [ClientBidRule] -> [String]
const getRulesStatus = R.compose(
  R.map(R.ifElse(R.prop('isRuleActive'), R.always('On'), R.always('Off'))),
  R.defaultTo([]),
);

// prepareClientBidRulesInitialValues :: [ClientBidRule] -> FormValues
const prepareClientBidRulesInitialValues = R.compose(
  R.objOf('bidRules'),
  R.ifElse(
    R.isEmpty,
    R.always([
      {
        isRuleActive: false,
        type: ClientBidRulesTypes.perAsset,
        label: ClientBidRulePerAssetFeeLabel,
      },
      {
        isRuleActive: false,
        label: ClientBidRuleManagementFeeLabel,
        type: ClientBidRulesTypes.jobCostPercentage,
      },
    ]),
    R.map(
      R.converge(R.mergeLeft, [
        R.pick(['type', 'label', 'isRuleActive']),
        R.ifElse(
          isPercentageTypeValue,
          R.pick(['amount']),
          R.compose(R.objOf('amount'), convertCentsToDollars, R.prop('amount')),
        ),
      ]),
    ),
  ),
);

// prepareFormDataToMutationInput :: FormValues -> UpdateClientBidRulesInput
const prepareFormDataToMutationInput = R.over(
  R.lensProp('bidRules'),
  R.compose(
    R.map(
      R.converge(R.mergeLeft, [
        R.pick(['type', 'label', 'isRuleActive']),
        R.ifElse(
          isPercentageTypeValue,
          R.pick(['amount']),
          R.compose(R.objOf('amount'), convertDollarsToCents, R.prop('amount')),
        ),
      ]),
    ),
    R.reject(
      R.either(
        R.propSatisfies(isNilOrEmpty, 'amount'),
        R.propSatisfies(isNilOrEmpty, 'label'),
      ),
    ),
  ),
);

function WarningText({ children }) {
  return (
    <Text size="12px" color={['notificator', 'warning', 'text']}>
      *{children}
    </Text>
  );
}

WarningText.propTypes = { children: string.isRequired };

export function BidRulesTab({
  bidRules,
  onSubmit,
  hasAccess,
  rulesInheritedWarning,
}) {
  const [showEditForm, setShowEditForm] = useState(false);
  const { showSuccessNotification } = useNotificationState();

  const onCancel = () => setShowEditForm(false);

  const onSubmitHandler = async (formData) => {
    const input = prepareFormDataToMutationInput(formData);

    await onSubmit(input);

    showSuccessNotification('Bid Rules successfully updated');
    onCancel();
  };

  return (
    <TabSectionWrapper>
      {rulesInheritedWarning && (
        <SidebarRow justify>
          <WarningText>Bid Rules Inherited from Client</WarningText>
          {hasAccess && (
            <EditClientTabButton onClick={() => setShowEditForm(true)}>
              edit
            </EditClientTabButton>
          )}
        </SidebarRow>
      )}
      <SidebarRow justify>
        <BlockWithLabel
          width="50%"
          id="rule-label"
          label="Rule Label"
          Component={ColumnList}
          list={getRulesLabel(bidRules)}
        />
        <BlockWithLabel
          width="25%"
          id="rule-amount"
          label="Rule Amount"
          Component={ColumnList}
          list={getRulesAmount(bidRules)}
        />
        <BlockWithLabel
          width="20%"
          id="rule-status"
          label="Rule Status"
          Component={ColumnList}
          list={getRulesStatus(bidRules)}
        />
        {!rulesInheritedWarning && hasAccess && (
          <EditClientTabButton onClick={() => setShowEditForm(true)}>
            edit
          </EditClientTabButton>
        )}
      </SidebarRow>
      {showEditForm && (
        <CommonSidebarFormWrapper
          onCancel={onCancel}
          title="Edit Bid Rules"
          formId={editClientBidRulesFormId}
        >
          <FormCreator
            validateOnBlur
            layout={commonModalLayout}
            onSubmit={onSubmitHandler}
            id={editClientBidRulesFormId}
            mutators={{ ...arrayMutators }}
            initialValues={prepareClientBidRulesInitialValues(bidRules)}
            sections={[
              {
                order: 1,
                layout: { column: 1 },
                fields: [
                  {
                    order: 1,
                    layout: { row: 1 },
                    field: {
                      name: 'bidRules',
                      isArrayField: true,
                      Component: ClientBidRulesLineComponent,
                    },
                  },
                ],
              },
            ]}
          />
        </CommonSidebarFormWrapper>
      )}
    </TabSectionWrapper>
  );
}

BidRulesTab.propTypes = {
  onSubmit: func.isRequired,
  hasAccess: bool.isRequired,
  rulesInheritedWarning: bool,
  bidRules: arrayOf(
    shape({
      type: string.isRequired,
      label: string.isRequired,
      amount: number.isRequired,
    }),
  ).isRequired,
};
