import React, { memo, useContext, useEffect, useCallback } from 'react';
import * as R from 'ramda';
import { Select, FieldLayout, Icon } from 'poly-book-admin';
import { func, number, shape, string } from 'prop-types';
import { ProjectTimeTypeUIMap } from 'poly-constants';
import { calculateTimeEntryTotal } from 'poly-utils';

import {
  LabelColumn,
  LineContainer,
  HeaderLineContainer,
  LineTotalAmount,
} from '../common.js';
import { TimesheetTotalInput } from '../../../../modules/forms/timesheetForm/components/TimesheetTotalInput.js';
import { ClientConfigsContext } from '../ClientConfigsContext.js';

// timeTypeOptions :: [SelectOption]
// SelectOption = {
//   label: String
//    value: String
// }
export const timeTypeOptions = R.compose(
  R.map(R.applySpec({ value: R.head, label: R.last })),
  R.toPairs,
)(ProjectTimeTypeUIMap);

// getDescriptionOptions :: EstimateConfig -> [SelectOption]
const getDescriptionOptions = R.compose(
  R.map(R.applySpec({ label: R.identity, value: R.identity })),
  R.keys,
  R.prop('rates'),
);

function DescriptionSelect(props) {
  const clientConfigs = useContext(ClientConfigsContext);
  const options = getDescriptionOptions(clientConfigs);
  return (
    <Select
      {...props}
      options={options}
      menuPortalTarget={null}
      placeholder="Select Description"
    />
  );
}

function TypeSelect(props) {
  return (
    <Select
      options={timeTypeOptions}
      menuPortalTarget={null}
      placeholder="Select Type"
      {...props}
    />
  );
}

// getTimeEntryRateByConfig :: { [String]: ClientCustomStaffRate } -> TimeEntryInput -> Number
const getTimeEntryRateByConfig = (clientRates) =>
  R.compose(
    R.path(R.__, clientRates),
    R.juxt([R.prop('description'), R.prop('type')]),
  );

function TimeEntriesLabel() {
  return (
    <HeaderLineContainer>
      <LabelColumn width="29%">Type</LabelColumn>
      <LabelColumn width="29%">Description</LabelColumn>
      <LabelColumn width="20%">Total Time</LabelColumn>
      <LabelColumn width="15%" align="end">
        Cost
      </LabelColumn>
      <LabelColumn width="15px" />
    </HeaderLineContainer>
  );
}

export const TimeEntryField = memo(
  ({ name, index, fields: { value, update, remove } }) => {
    const { rates } = useContext(ClientConfigsContext);

    const { description, type, rate, totalMinutes } = value[index];

    const getTimeEntryRate = useCallback(getTimeEntryRateByConfig(rates), []);

    useEffect(() => {
      if (description && type) {
        update(index, {
          ...value[index],
          rate: getTimeEntryRate({ description, type }),
        });
      }
    }, [description, type]);

    const total = calculateTimeEntryTotal({ rate, totalMinutes });

    return (
      <>
        {index === 0 && <TimeEntriesLabel />}
        <LineContainer>
          <FieldLayout
            required
            layout={{ width: '29%', padding: 0 }}
            field={{
              name: `${name}.type`,
              Component: TypeSelect,
            }}
            validators={[[R.identity, 'Type is required']]}
          />
          <FieldLayout
            required
            layout={{ width: '29%', padding: 0 }}
            field={{
              name: `${name}.description`,
              Component: DescriptionSelect,
            }}
            validators={[[R.identity, 'Description is required']]}
          />
          <FieldLayout
            layout={{ width: '20%', padding: 0 }}
            field={{
              name: `${name}.totalMinutes`,
              Component: TimesheetTotalInput,
            }}
            validators={[[R.identity, 'Time is required']]}
          />
          <LineTotalAmount value={total} width="14%" />
          <Icon
            name="delete"
            size={12}
            color="red"
            onClick={() => remove(index)}
          />
        </LineContainer>
      </>
    );
  },
);

TimeEntryField.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  fields: shape({
    update: func.isRequired,
    remove: func.isRequired,
  }),
};
