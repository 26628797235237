import React, { useEffect } from 'react';
import { func, shape, oneOfType, string, instanceOf } from 'prop-types';
import { DatePicker, LinkButton, HeaderLinksWrapper } from 'poly-book-admin';
import {
  useNotificationState,
  CommonPrintLayout,
  ClientSelect,
} from 'poly-admin-ui';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import { openPrintWindowWithData } from 'poly-client-utils';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { accountsReceivableEntriesPropTypes } from './prop-types.js';
import { AccountsReceivableReportTable } from './AccountsReceivableReportTable.js';
import { AccountsReceivableReportExcelExportButton } from './AccountsReceivableReportExcelExportButton.js';
import { prepareFiltersToQuery } from '../AccountsPayableReport/accountsReportUtils.js';

const defaultDateValue = new Date().toISOString();

const accountsReceivableReportFilterConfig = [
  { name: 'clientId' },
  { name: 'date', defaultValue: defaultDateValue },
];

export function AccountsReceivableReportHeader({
  setFilter,
  reportEntries,
  filter,
}) {
  const { showErrorNotification } = useNotificationState();
  const { searchFilters, handlers, onReset } = useSearchFilters(
    accountsReceivableReportFilterConfig,
  );

  const onSearch = () => {
    setFilter(prepareFiltersToQuery(searchFilters));
  };

  const onResetSearch = () => {
    onReset();
    setFilter({});
  };

  const onPrintPDFClick = async () => {
    if (reportEntries.length === 0) {
      showErrorNotification('No records to export');
      return;
    }

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: AccountsReceivableReportTable,
      metaData: {
        title: 'Accounts Receivable Report',
      },
      fileName: 'accounts_receivable_report',
      data: reportEntries,
    });
  };

  useEffect(onSearch, []);

  return (
    <SearchPageHeaderContainer title="Accounts Receivable Report">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Client"
            titleWidth="100px"
            filterWidth="260px"
          >
            <ClientSelect
              width="100%"
              isClearable
              includeAllOption={false}
              onChange={handlers.clientId}
              value={searchFilters.clientId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date"
            titleWidth="100px"
            filterWidth="260px"
          >
            <DatePicker
              value={searchFilters.date}
              onChange={handlers.date}
              width="100%"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="345px">
            <FlexCenterAlign>
              <HeaderLinksWrapper>
                <LinkButton onClick={onPrintPDFClick}>
                  {PRINT_PDF_CAPTION}
                </LinkButton>
                <AccountsReceivableReportExcelExportButton
                  {...{ reportEntries, filter, defaultDateValue }}
                />
              </HeaderLinksWrapper>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton size="small" onClick={onSearch}>
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

AccountsReceivableReportHeader.propTypes = {
  setFilter: func.isRequired,
  reportEntries: accountsReceivableEntriesPropTypes.isRequired,
  filter: shape({
    date: oneOfType([string, instanceOf(Date)]),
  }),
};
