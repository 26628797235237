import React from 'react';
import styled from 'styled-components';
import { Button } from 'poly-book-admin/src/Button/index.js';
import { LinkButton } from 'poly-book-admin/src/LinkButton/index.js';
import { bool, func } from 'prop-types';

import { FlexContainer } from '../../components/FlexContainer.js';

const FlexContainerS = styled(FlexContainer)`
  gap: 10px;
`;

export function TransactionDetailsButtons({
  isEditMode,
  handleEditJournalPostingDate,
  toggleEditMode,
  closeOutSidebar,
}) {
  if (isEditMode) {
    return (
      <FlexContainerS>
        <Button
          size="tiny"
          styleType="accentDark"
          onClick={handleEditJournalPostingDate}
        >
          Save
        </Button>
        <Button size="tiny" styleType="primaryLighter" onClick={toggleEditMode}>
          Cancel
        </Button>
      </FlexContainerS>
    );
  }

  return (
    <FlexContainerS>
      <LinkButton onClick={toggleEditMode}>Edit</LinkButton>
      <Button size="tiny" styleType="accentDark" onClick={closeOutSidebar}>
        Close
      </Button>
    </FlexContainerS>
  );
}

TransactionDetailsButtons.propTypes = {
  isEditMode: bool.isRequired,
  handleEditJournalPostingDate: func.isRequired,
  toggleEditMode: func.isRequired,
  closeOutSidebar: func.isRequired,
};
