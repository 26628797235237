import React from 'react';
import {
  SidebarTabHeader,
  SidebarTabWrapper,
  useMapConfigToTableProps,
} from 'poly-admin-ui';
import styled from 'styled-components';
import { arrayOf, shape } from 'prop-types';

import {
  InvoicesTable,
  commonInvoicesTableConfig,
  prepareInvoicesData,
} from '../../../sidebars/ProjectSidebar/tabs/ProjectSidebarInvoicesTab.js';
import { SectionLabel } from '../../../sidebars/components/commonSidebarComponents.js';

const InvoicesTableS = styled(InvoicesTable)`
  th:last-child,
  td:last-child {
    width: 85px;
  }
`;

export function AdditionalInvoicesTab({ invoices }) {
  const tableProps = useMapConfigToTableProps(
    prepareInvoicesData,
    commonInvoicesTableConfig,
    invoices,
  );

  return (
    <SidebarTabWrapper>
      <SidebarTabHeader skipMargin>
        <SectionLabel>Project Invoices</SectionLabel>
      </SidebarTabHeader>
      <InvoicesTableS {...tableProps} />
    </SidebarTabWrapper>
  );
}

AdditionalInvoicesTab.propTypes = {
  invoices: arrayOf(shape({})),
};
