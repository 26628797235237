import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeaderContainer, MainHeader } from 'poly-book-admin';

import { PageTabs } from '../../modules/tabs/TabsWithRouter.js';
import { TABS_NAMES, MasterProjectsTabsStatuses } from './constants.js';
import { SearchMasterProjectsTable } from './SearchMasterProjectsTable.js';
import { projectUIStatuses } from '../../modules/core/constants/projects.js';
import { SearchMasterProjectsHeader } from './SearchMasterProjectsHeader.js';
import { MasterProjectsDirectoryToolbar } from './MasterProjectsDirectoryToolbar.js';
import { MasterProjectsTabTitle } from '../../modules/tabs/projectsTab/ProjectsTabTitle.js';
import { RecurringProjectsPageProjectsTab } from '../../modules/tabs/projectsTab/ProjectsTab.js';
import { setProjectsFilter } from '../../redux/searchFilters/projectsFilter.js';
import { recurringQueriesByEndDate } from '../../modules/tabs/common.js';

// tabFromName :: String -> [MasterProjectTabTitle, String, MasterProjectTab]
const tabFromName = (tabName) => [
  <MasterProjectsTabTitle
    name={tabName}
    key={`${tabName}-title`}
    text={MasterProjectsTabsStatuses[tabName]}
    query={recurringQueriesByEndDate[tabName]}
  />,
  tabName,
  <RecurringProjectsPageProjectsTab
    key={tabName}
    query={recurringQueriesByEndDate[tabName]}
  />,
];

const tabs = TABS_NAMES.map(tabFromName);

export function MasterProjectsPage() {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.searchFilters.projectsFilter);

  const headerProps = filter
    ? {
        height: '106px',
        headerStyles: 'padding-bottom: 8px',
      }
    : {};

  useEffect(() => () => dispatch(setProjectsFilter(null)), []);

  return (
    <>
      <PageHeaderContainer {...headerProps}>
        <MainHeader>
          {filter ? 'Search Master Projects' : 'Master Projects Directory'}
        </MainHeader>
        {filter && <SearchMasterProjectsHeader />}
      </PageHeaderContainer>
      {filter ? (
        <SearchMasterProjectsTable />
      ) : (
        <PageTabs
          toolBar={<MasterProjectsDirectoryToolbar />}
          defaultValue={projectUIStatuses.ACTIVE}
          tabs={tabs}
          withPips
        />
      )}
    </>
  );
}
