import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { string, func, shape, bool, object } from 'prop-types';
import { ASC_SORT_ORDER, MasterSupplierStatus } from 'poly-constants';
import { isNilOrEmpty } from 'poly-utils';
import { Select } from 'poly-book-admin';
import {
  entityToOptionByLabelPath,
  keywordSortQuery,
  useReactiveQuery,
} from 'poly-client-utils';

const DEFAULT_MASTER_SUPPLIER_QUERY = gql`
  query DEFAULT_MASTER_SUPPLIER_QUERY($id: ID!) {
    masterSupplier(id: $id) {
      _id
      company {
        name
      }
    }
  }
`;

export const MASTER_SUPPLIERS_SELECT_QUERY = gql`
  query MASTER_SUPPLIERS_SELECT_QUERY($searchInput: CollectionSearchParams!) {
    searchMasterSuppliers(input: $searchInput) {
      hits {
        _id
        company {
          name
        }
      }
    }
  }
`;

const MASTER_SUPPLIERS_SEARCH_CHANGED = gql`
  subscription MASTER_SUPPLIERS_SEARCH_CHANGED(
    $input: CollectionSearchParams!
  ) {
    searchMasterSupplierChanged(input: $input) {
      id
      type
    }
  }
`;

// mapDataToMasterSupplierSelectOptions :: MasterSuppliersSearchResult -> [Option]
const mapDataToMasterSupplierSelectOptions = R.compose(
  R.uniqBy(R.prop('value')),
  R.map(entityToOptionByLabelPath(['company', 'name'])),
  R.defaultTo([]),
);

// prepareMasterSuppliersBeforeOptions :: CommonMasterSupplierObj -> [MasterSupplier]
// CommonMasterSupplierObj = { defaultMasterSupplierData: MasterSupplier,  masterSuppliersData: MasterSuppliersSearchResult }
const prepareMasterSuppliersBeforeOptions = R.ifElse(
  R.pipe(R.prop('defaultMasterSupplierData'), isNilOrEmpty),
  R.pathOr([], ['masterSuppliersData', 'searchMasterSuppliers', 'hits']),
  R.converge(R.append, [
    R.path(['defaultMasterSupplierData', 'masterSupplier']),
    R.pathOr([], ['masterSuppliersData', 'searchMasterSuppliers', 'hits']),
  ]),
);

export function MasterSupplierSelect({
  meta,
  value,
  error,
  query,
  onBlur,
  onChange,
  ...props
}) {
  const [searchText, setSearchText] = useState('');
  const [selectError, setSelectError] = useState(null);

  useEffect(() => {
    setSelectError(
      searchText ? 'Please choose Master Supplier from the list' : error,
    );
  }, [meta]);

  const queryOptions = {
    variables: {
      searchInput: {
        size: 25,
        searchTerm: searchText,
        query: query || { term: { status: MasterSupplierStatus.ACTIVE } },
        sort: keywordSortQuery(['company', 'name'])(ASC_SORT_ORDER),
      },
    },
    skip: R.isEmpty(searchText),
  };

  const { data: masterSuppliersData, loading } = useReactiveQuery(
    MASTER_SUPPLIERS_SELECT_QUERY,
    MASTER_SUPPLIERS_SEARCH_CHANGED,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const { data: defaultMasterSupplierData } = useQuery(
    DEFAULT_MASTER_SUPPLIER_QUERY,
    {
      variables: { id: value },
      skip: isNilOrEmpty(value),
    },
  );

  const preparedMasterSuppliers = prepareMasterSuppliersBeforeOptions({
    masterSuppliersData,
    defaultMasterSupplierData,
  });

  const onSelect = (e, option) => {
    onChange(e, option);
    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  const options = mapDataToMasterSupplierSelectOptions(preparedMasterSuppliers);

  const selectOptions = {
    ...props,
    value,
    loading,
    options,
    searchText,
    error: selectError,
    onChange: onSelect,
    name: 'MasterSupplierSelect',
    onInputChange: setSearchText,
    placeholder: 'Start typing supplier',
    filterOption: () => true,
  };

  return <Select {...selectOptions} />;
}

MasterSupplierSelect.propTypes = {
  meta: shape({
    touched: bool,
  }),
  value: string,
  error: string,
  // eslint-disable-next-line
  query: object,
  onChange: func.isRequired,
  onBlur: func,
};
