import React from 'react';
import { usePagination } from 'poly-admin-ui';
import { bool, func, object, oneOfType } from 'prop-types';
import {
  ProjectsPageTable,
  RecurringProjectsPageTable,
  SupplierRecurringProjectsTable,
} from '../../tables/projectsTable/ProjectsTable.js';
import { ProjectsTabComp } from './ProjectsTabComp.js';
import {
  MyProjectsTable,
  MyRecurringProjectsTable,
} from '../../tables/myProjectsTable/MyProjectsTable.js';
import { RecentProjectsTable } from '../../tables/recentProjectsTable/RecentProjectsTable.js';
import { useSaveQueryForXLSExport } from '../../core/tableToolbar/TableExportToXLSBtn.js';

function ProjectsTab({ table, isGeneral, ...restProps }) {
  const { pagination } = usePagination();

  useSaveQueryForXLSExport(restProps);

  return (
    <ProjectsTabComp
      {...restProps}
      Table={table}
      isGeneral={isGeneral}
      pagination={pagination}
      paginationVisible
    />
  );
}

ProjectsTab.displayName = 'ProjectsTab';

ProjectsTab.propTypes = {
  isGeneral: bool,
  table: oneOfType([func, object]),
};

export function ProjectsPageProjectsTab(props) {
  return <ProjectsTab {...props} table={ProjectsPageTable} isGeneral />;
}

export function RecurringProjectsPageProjectsTab(props) {
  return (
    <ProjectsTab {...props} table={RecurringProjectsPageTable} isGeneral />
  );
}

export function MyProjectsTab(props) {
  return <ProjectsTab {...props} table={MyProjectsTable} isGeneral />;
}

export function MyRecurringProjectsTab(props) {
  return <ProjectsTab {...props} table={MyRecurringProjectsTable} isGeneral />;
}

export function SupplierRecurringProjectsSubTab(props) {
  return <ProjectsTab {...props} table={SupplierRecurringProjectsTable} />;
}

export function RecentProjectsCommonTab(props) {
  return <ProjectsTab {...props} table={RecentProjectsTable} isGeneral />;
}
