import React, { useMemo, useContext, useEffect } from 'react';
import * as R from 'ramda';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import {
  Checkbox,
  Editor,
  FieldLayout,
  FilePicker,
  FormField,
  getThemeColor,
  LinkButton,
  Text,
  ToolBarBtnDivider,
  useFormSubscription,
} from 'poly-book-admin';
import arrayMutators from 'final-form-arrays';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import {
  FlexContainer,
  MultiselectDropDownWithManualOption,
} from 'poly-admin-ui';
import {
  assocBy,
  calculateEntriesTotal,
  calculateProjectEstimateTotals,
  convertCentsToDollars,
  formatTotal,
  isNilOrEmpty,
  validateEmail,
} from 'poly-utils';
import { usePristineSubscribe } from 'poly-client-routing';
import {
  commonFileValidators,
  useDecoratePersistenceForOnChange,
  validateFilesFunc,
} from 'poly-client-utils';

import { FlexSpaceBetween } from '../../../../modules/forms/assignSupplierForm/styles.js';
import { TimeEntryField, timeTypeOptions } from './TimeEntryField.js';
import { MarkupEntryField } from './MarkupEntryField.js';
import { formatEntriesInput, getMarkupPercent } from '../helpers.js';
import { ManualEntryField } from './ManualEntryField.js';
import { ClientConfigsContext } from '../ClientConfigsContext.js';
import { YellowWarningIcon } from '../../../../components/Icons.js';

const FormS = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex-grow: 1;
`;

const FormSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding: 24px;
`;

const SectionHeaderContainer = styled(FlexSpaceBetween)`
  align-items: flex-end;
  margin-bottom: 24px;
`;

const TotalLineContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  font-size: ${R.prop('size')}px;
  margin-top: ${R.prop('margin')}px;
`;

const TotalTitle = styled.div`
  color: ${getThemeColor(['mid'])};
  margin-right: 10px;
  font-size: ${R.prop('size')}px;
`;

const TotalValue = styled.div`
  color: ${getThemeColor(['darkest'])};
`;

const YellowWarningIconS = styled(YellowWarningIcon)`
  margin-right: 5px;

  > div > div {
    max-width: 230px;
  }
`;

function SubTotalLine({
  title,
  value,
  size,
  margin,
  isDisplayIcon,
  existingEstimateTotal,
}) {
  const amount = convertCentsToDollars(value);
  const valueFormatted = formatTotal(amount);
  const iconTitle = `The estimate total has changed from the original value of ${formatTotal(
    convertCentsToDollars(existingEstimateTotal),
  )}.`;

  return (
    <TotalLineContainer margin={margin}>
      {isDisplayIcon && (
        <YellowWarningIconS
          popoverText={iconTitle}
          popoverTextSize="12px"
          isClickable={false}
        />
      )}
      <TotalTitle size={size}>{title}:</TotalTitle>
      <TotalValue size={size}>{valueFormatted}</TotalValue>
    </TotalLineContainer>
  );
}

SubTotalLine.propTypes = {
  title: string.isRequired,
  value: number,
  size: number,
  margin: number,
  isDisplayIcon: bool,
  existingEstimateTotal: number,
};

SubTotalLine.defaultProps = {
  size: 14,
  margin: 5,
  value: 0,
  isDisplayIcon: false,
  existingEstimateTotal: 0,
};

// calculateEstimateFormTotals :: Boolean -> ProjectEstimateInput -> TotalsObject
// TotalsObject = {
//   total : Number
//   subTotal : Number
//   tax : Number
//   markupEntriesTotal : Number
//   timeEntriesTotal : Number
//   manualEntriesTotal : Number
// }
const calculateEstimateFormTotals = (isManualMode) =>
  R.compose(
    assocBy('markupEntriesTotal', calculateEntriesTotal('markupEntries')),
    assocBy('timeEntriesTotal', calculateEntriesTotal('timeEntries')),
    assocBy('manualEntriesTotal', calculateEntriesTotal('manualEntries')),
    calculateProjectEstimateTotals,
    formatEntriesInput(isManualMode),
  );

export const projectEstimateFormId = 'projectEstimateFormId';

// isEntryNotEmpty :: [Object] -> Boolean
const isEntryNotEmpty = R.compose(
  R.complement(R.isEmpty),
  R.head,
  R.defaultTo([{}]),
);

// checkIsGrandTotalChanged :: { existingEstimateTotal: Int, total: Int } -> Boolean
const checkIsGrandTotalChanged = R.both(
  R.compose(R.complement(R.equals(0)), R.prop('existingEstimateTotal')),
  R.converge(R.complement(R.equals), [
    R.prop('total'),
    R.prop('existingEstimateTotal'),
  ]),
);

const EditorS = styled(Editor)`
  .ql-editor {
    max-height: none;
  }
`;

function EditorComponent({ onChange, onChangePersistentValue, ...props }) {
  const onChangePersistent = useDecoratePersistenceForOnChange(
    onChangePersistentValue,
    onChange,
    R.equals({ text: '', attachments: [], mentions: {} }),
  );

  const editorProps = {
    ...props,
    label: 'Scope of work',
    width: '100%',
    placeholder: 'Enter scope of work',
    onChange: onChangePersistent,
  };

  return <EditorS {...editorProps} />;
}

EditorComponent.propTypes = {
  onChange: func,
  onChangePersistentValue: func,
};

function AddProjectEstimateFormRendered({
  form,
  values,
  isEditMode,
  handleSubmit,
  isManualMode,
  setIsManualMode,
  onChangePersistentValue,
}) {
  const { tmMarkupRules, rates, taxPercent, isMarkupsDisabled } =
    useContext(ClientConfigsContext);

  const { formSubscription } = usePristineSubscribe();
  useFormSubscription(form.subscribe, formSubscription);

  const {
    markupEntries,
    manualEntries,
    timeEntries,
    isAfterHours,
    afterHoursCall,
    woCharge,
    existingEstimateTotal,
  } = values;

  const markupPercent = useMemo(
    () => getMarkupPercent(tmMarkupRules)(markupEntries),
    [markupEntries],
  );

  const totals = useMemo(
    () => calculateEstimateFormTotals(isManualMode)({ ...values, taxPercent }),
    [
      markupEntries,
      manualEntries,
      timeEntries,
      isAfterHours,
      afterHoursCall,
      isManualMode,
      woCharge,
    ],
  );

  const isGrandTotalChanged = checkIsGrandTotalChanged({
    existingEstimateTotal,
    ...totals,
  });

  useEffect(() => {
    if (isEditMode && isEntryNotEmpty(manualEntries)) {
      setIsManualMode(true);
    } else if (isEditMode && isEntryNotEmpty(markupEntries)) {
      setIsManualMode(false);
    }
  }, [isEditMode]);

  const hideTimeSection = isNilOrEmpty(rates);
  return (
    <FormS id={projectEstimateFormId} onSubmit={handleSubmit}>
      <FormSectionContainer>
        <FormField
          name="description"
          Component={EditorComponent}
          additionalProps={{ onChangePersistentValue }}
        />
      </FormSectionContainer>
      <FormSectionContainer>
        <SectionHeaderContainer>
          <Text size={18}>{isManualMode ? 'Line Items' : 'Suppliers'}</Text>
          {isManualMode ? (
            <FlexContainer>
              {!isMarkupsDisabled && (
                <>
                  <LinkButton
                    type="button"
                    onClick={() => setIsManualMode(false)}
                  >
                    Switch to Calculate
                  </LinkButton>
                  <ToolBarBtnDivider />
                </>
              )}
              <LinkButton
                type="button"
                onClick={() => form.mutators.push('manualEntries', {})}
              >
                Add Item
              </LinkButton>
            </FlexContainer>
          ) : (
            <LinkButton type="button" onClick={() => setIsManualMode(true)}>
              Switch to Breakdown
            </LinkButton>
          )}
        </SectionHeaderContainer>
        {isManualMode ? (
          <>
            <FieldLayout
              key="manualEntries"
              layout={{ padding: 0 }}
              field={{
                name: 'manualEntries',
                isArrayField: true,
                Component: ManualEntryField,
              }}
              changeFieldValue={form.change}
            />
            <SubTotalLine
              title="Total"
              margin={24}
              value={totals.manualEntriesTotal}
            />
          </>
        ) : (
          <>
            <FieldLayout
              key="markupEntries"
              layout={{ padding: 0 }}
              field={{
                name: 'markupEntries',
                isArrayField: true,
                Component: MarkupEntryField,
                withFormData: true,
              }}
              values={{ markupPercent }}
              changeFieldValue={form.change}
            />
            <SubTotalLine
              title="Total"
              value={totals.markupEntriesTotal}
              margin={24}
            />
          </>
        )}
      </FormSectionContainer>
      {!hideTimeSection && (
        <>
          <FormSectionContainer>
            <SectionHeaderContainer>
              <Text size={18}>Time</Text>
              <LinkButton
                type="button"
                onClick={() =>
                  form.mutators.push('timeEntries', {
                    type: timeTypeOptions[0],
                  })
                }
              >
                Add Time
              </LinkButton>
            </SectionHeaderContainer>
            <FieldLayout
              key="timeEntries"
              layout={{ padding: 0 }}
              field={{
                name: 'timeEntries',
                isArrayField: true,
                Component: TimeEntryField,
              }}
            />
          </FormSectionContainer>
          <FormSectionContainer>
            <FlexSpaceBetween>
              <FieldLayout
                key="isAfterHours"
                layout={{ padding: 0 }}
                field={{
                  name: 'isAfterHours',
                  Component: Checkbox,
                  additionalProps: {
                    label: 'After hours',
                  },
                }}
              />

              <SubTotalLine
                title="Total"
                value={
                  values.isAfterHours
                    ? totals.timeEntriesTotal + afterHoursCall
                    : totals.timeEntriesTotal
                }
                size={16}
              />
            </FlexSpaceBetween>
            {isAfterHours && (
              <SubTotalLine title="Incl. After hours" value={afterHoursCall} />
            )}
          </FormSectionContainer>
        </>
      )}
      <FormSectionContainer>
        {!isManualMode && (
          <SubTotalLine title="Markup" value={totals.markupTotal} fromCents />
        )}
        <SubTotalLine title="Subtotal" value={totals.subTotal} fromCents />
        <SubTotalLine
          title="Work Order Charge"
          value={totals.woCharge}
          fromCents
        />
        <SubTotalLine title="Tax" value={totals.tax} fromCents />
        <SubTotalLine
          title="Total"
          value={totals.total}
          size={18}
          margin={10}
          isDisplayIcon={isGrandTotalChanged}
          existingEstimateTotal={existingEstimateTotal}
          fromCents
        />
      </FormSectionContainer>
      <FormSectionContainer>
        <FieldLayout
          layout={{ padding: 0 }}
          field={{
            name: 'isBreakdown',
            Component: Checkbox,
            additionalProps: {
              label: 'Send Breakdown Estimate',
            },
          }}
        />
      </FormSectionContainer>
      <FormSectionContainer>
        <SectionHeaderContainer>
          <Text size={18}>Attachments</Text>
        </SectionHeaderContainer>
        <FieldLayout
          layout={{ padding: 0 }}
          field={{
            name: 'attachments',
            Component: FilePicker,
            additionalProps: { multiple: true },
          }}
          validators={commonFileValidators}
          validateFunction={validateFilesFunc}
        />
        <FieldLayout
          label="Email To"
          layout={{ padding: '15px 0 0 0' }}
          field={{
            name: 'emailTo',
            Component: MultiselectDropDownWithManualOption,
            additionalProps: {
              placeholder: 'Start typing emails',
              additionalOptions: values.clientContactOptions,
            },
          }}
          validators={[
            [R.complement(R.isEmpty), 'Email To is required'],
            [
              R.compose(
                R.all(validateEmail),
                R.map(R.prop('value')),
                R.defaultTo([]),
              ),
              'Incorrect email address',
            ],
          ]}
        />
      </FormSectionContainer>
    </FormS>
  );
}

AddProjectEstimateFormRendered.propTypes = {
  form: shape({
    mutators: shape({
      add: func.isRequired,
    }),
  }),
  values: shape({
    afterHoursCall: number,
  }),
  handleSubmit: func.isRequired,
  onChangePersistentValue: func,
  isManualMode: bool,
  isEditMode: bool,
  setIsManualMode: func,
};

export function ProjectEstimateForm({
  onSubmit,
  isEditMode,
  isManualMode,
  initialValues,
  setIsManualMode,
  onChangePersistentValue,
}) {
  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      isEditMode={isEditMode}
      mutators={arrayMutators}
      isManualMode={isManualMode}
      initialValues={initialValues}
      setIsManualMode={setIsManualMode}
      render={AddProjectEstimateFormRendered}
      onChangePersistentValue={onChangePersistentValue}
    />
  );
}

ProjectEstimateForm.propTypes = {
  isEditMode: bool,
  isManualMode: bool,
  setIsManualMode: func,
  onSubmit: func.isRequired,
  onChangePersistentValue: func,
  initialValues: shape({
    // eslint-disable-next-line react/forbid-prop-types
    description: oneOfType([string, object]),
    markupEntries: arrayOf(
      shape({
        amount: number,
        markup: number,
        supplierId: shape({ _id: string.isRequired, name: string.isRequired }),
      }),
    ),
    manualEntries: arrayOf(
      shape({
        rate: number,
        quantity: number,
        description: string,
      }),
    ),
    timeEntries: arrayOf(
      shape({
        type: string,
        rate: number,
        description: string,
        totalMinutes: number,
      }),
    ),
    afterHoursCall: number,
  }),
};
