import React from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { shape, func, string, bool, arrayOf } from 'prop-types';
import { Select, SelectComponents, getThemeColor } from 'poly-book-admin';
import { useReactiveQuery } from 'poly-client-utils';
import { MAX_ITEMS } from 'poly-admin-ui';

const warningOptionId = 'warning-option-id';
const warningOptionLabel =
  'If you change the procedure, then the assets might be cleared if the asset types differ';

const SEARCH_PROCEDURES_QUERY = gql`
  query SEARCH_PROCEDURES_QUERY($input: CollectionSearchParams) {
    searchProcedures(input: $input) {
      hits {
        _id
        name
        assetTypes {
          _id
        }
      }
    }
  }
`;

const SEARCH_PROCEDURE_CHANGED_SUBSCRIPTION = gql`
  subscription SEARCH_PROCEDURE_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchProcedureChanged(input: $input) {
      id
      type
    }
  }
`;

// getProcedureSelectOptions :: (Boolean, [ID]) -> ProceduresQuerySearchResult -> [Option]
const getProcedureSelectOptions = (withWarningOption, attachedProcedures) =>
  R.compose(
    R.when(
      () => !!withWarningOption,
      R.prepend({ value: warningOptionId, label: warningOptionLabel }),
    ),
    R.map(R.applySpec({ value: R.prop('_id'), label: R.prop('name') })),
    R.reject(R.propSatisfies(R.includes(R.__, attachedProcedures), '_id')),
    R.pathOr([], ['searchProcedures', 'hits']),
  );

const DEFAULT_QUERY_OPTIONS = {
  fetchPolicy: 'network-only',
  variables: { input: { size: MAX_ITEMS, query: null } },
};

const DEFAULT_SUBSCRIPTION_OPTIONS = {};

// getProcedureTypes :: ID -> ProceduresQuerySearchResult -> [ID]
const getProcedureTypes = (procedureId) =>
  R.compose(
    R.map(R.prop('_id')),
    R.defaultTo([]),
    R.prop('assetTypes'),
    R.find(R.propEq('_id', procedureId)),
    R.pathOr([], ['searchProcedures', 'hits']),
  );

const WarningOptionS = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  color: ${getThemeColor(['notificator', 'warning', 'text'])};
  background-color: ${getThemeColor(['notificator', 'warning', 'background'])};
`;

function CustomOption(props) {
  const { value, label } = props;

  if (value === warningOptionId) {
    return <WarningOptionS>{label}</WarningOptionS>;
  }

  return <SelectComponents.Option {...props} />;
}

CustomOption.propTypes = {
  value: string.isRequired,
  label: string.isRequired,
};

export function ProcedureSelect({
  onChange,
  queryOptions,
  setProcedureLine,
  subscriptionOptions,
  attachedProcedures = [],
  withWarningOption = false,
  ...props
}) {
  const { data, loading } = useReactiveQuery(
    SEARCH_PROCEDURES_QUERY,
    SEARCH_PROCEDURE_CHANGED_SUBSCRIPTION,
    {
      queryOptions: queryOptions || DEFAULT_QUERY_OPTIONS,
      subscriptionOptions: subscriptionOptions || DEFAULT_SUBSCRIPTION_OPTIONS,
    },
  );

  const options = getProcedureSelectOptions(
    withWarningOption,
    attachedProcedures,
  )(data);

  const onChangeHandler = (e) => {
    if (R.is(Function, setProcedureLine)) {
      const types = getProcedureTypes(e)(data);
      setProcedureLine({ types, procedureId: e, assetIds: [] });
    } else {
      onChange(e);
    }
  };

  const selectProps = {
    ...props,
    options,
    loading,
    onChange: onChangeHandler,
    components: { Option: CustomOption },
    placeholder: 'Start typing procedure',
  };

  return <Select {...selectProps} />;
}

ProcedureSelect.propTypes = {
  setProcedureLine: func,
  queryOptions: shape({}),
  withWarningOption: bool,
  onChange: func.isRequired,
  subscriptionOptions: shape({}),
  attachedProcedures: arrayOf(string),
};
