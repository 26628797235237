import * as R from 'ramda';
import React from 'react';
import { supplierEmailsTypes } from 'poly-constants';
import { applyProp, formatTotal } from 'poly-utils';
import {
  oneOfType,
  string,
  shape,
  object,
  objectOf,
  arrayOf,
  number,
  bool,
} from 'prop-types';
import { getEmailByType } from '../../../../../../../core/utils/suppliers.js';
import { SuppliersListItemComp } from './SuppliersListItemComp.js';
import { getGeneralPhone } from '../../../../../utils.js';

// applyFormatTotal :: String -> Object -> String
const applyFormatTotal = applyProp(formatTotal);

export function SupplierListItem({
  _id,
  status,
  company: { address, phones, name, emails },
  rates,
  type,
  rating,
  propertyCoordinates,
  tax,
  documents,
  mwbe,
  isVeteran,
}) {
  const props = {
    _id,
    address: R.prop('formatted_address', address),
    phone: getGeneralPhone(phones),
    email: getEmailByType(emails, supplierEmailsTypes.GENERAL),
    hourly: applyFormatTotal('hourly')(rates),
    overTime: applyFormatTotal('overTime')(rates),
    trip: applyFormatTotal('trip')(rates),
    emergency: applyFormatTotal('emergency')(rates),
    coordinates: R.path(['geo', 'coordinates'], address),
    rating,
    name,
    status,
    type,
    propertyCoordinates,
    tax,
    documents,
    mwbe,
    isVeteran,
  };

  return <SuppliersListItemComp {...props} />;
}

SupplierListItem.propTypes = {
  _id: string,
  status: string,
  company: shape({
    name: string,
    address: shape({
      formatted_address: string,
    }),
    emails: arrayOf(objectOf(string)),
    phones: arrayOf(objectOf(string)),
  }),
  type: string,
  rates: shape({
    hourly: number,
    overTime: number,
    trip: number,
    weekend: number,
  }),
  rating: shape({
    average: number,
    stars: arrayOf(number),
  }),
  tax: oneOfType([string, object]),
  documents: oneOfType([string, object]),
  propertyCoordinates: objectOf(number),
  mwbe: shape({ isMBE: bool }),
  isVeteran: bool,
};
