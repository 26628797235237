import * as R from 'ramda';
import {
  WISCONSIN_STATE_SHORTNAME,
  NEW_YORK_STATE_SHORTNAME,
} from 'poly-constants';

import { assocBy } from '../ramda.js';
import { roundTo } from '../numbers.js';

// isWIState :: { taxAccount: Account } -> Boolean
const isWIState = R.pathEq(['taxAccount', 'state'], WISCONSIN_STATE_SHORTNAME);

// isNYState :: { taxAccount: Account } -> Boolean
const isNYState = R.pathEq(['taxAccount', 'state'], NEW_YORK_STATE_SHORTNAME);

// getCustomTaxAmount :: ClientInvoiceFinancialInfo -> Int
const getCustomTaxAmount = R.converge(R.add, [
  R.prop('taxRawAmount'),
  R.prop('freightRawAmount'),
]);

// isCustomTax :: ClientInvoiceFinancialInfo -> Boolean
const isCustomTax = R.both(
  // Custom tax is not available for the NY and WI states
  R.both(R.complement(isWIState), R.complement(isNYState)),
  R.compose(R.gt(R.__, 0), getCustomTaxAmount),
);

// we expect amount and calculate amounts always in CENTS!
/**
 * getClientInvoiceTotalWithTax :: ClientInvoiceFinancialInfo -> ClientInvoiceFinancialInfoWithTax
 * ClientInvoiceFinancialInfo = { amount: Int, taxPercent: Float, markupAmount: Int, taxAccount: Account }
 * ClientInvoiceFinancialInfoWithTax = { ...ClientInvoiceFinancialInfo, taxAmount: Int }
 */
export const getClientInvoiceTotalWithTax = R.compose(
  assocBy(
    'amount',
    R.compose(
      roundTo(0),
      R.ifElse(
        isWIState,
        R.prop('amount'),
        R.converge(R.add, [R.prop('amount'), R.prop('taxAmount')]),
      ),
    ),
  ),
  assocBy(
    'taxAmount',
    R.ifElse(
      isCustomTax,
      getCustomTaxAmount,
      R.compose(
        roundTo(0),
        R.converge(R.multiply, [
          R.ifElse(isWIState, R.propOr(0, 'markupAmount'), R.prop('amount')),
          R.compose(R.divide(R.__, 100), R.prop('taxPercent')),
        ]),
      ),
    ),
  ),
  assocBy('freightRawAmount', R.propOr(0, 'freightRawAmount')),
  assocBy('taxRawAmount', R.propOr(0, 'taxRawAmount')),
  assocBy('taxPercent', R.propOr(0, 'taxPercent')),
);
