import * as R from 'ramda';
import { formatDateOrNothingUI } from 'poly-client-utils';
import {
  ProjectPrioritiesUIMap,
  NOTHING_UI_STRING,
  WorkOrderStatus,
} from 'poly-constants';

import { isFeeProjectType } from '../../../sidebars/ProjectSidebar/forms/form/utils/sections.js';
import { getProjectDaysOpen } from '../../../utils/project.js';

/**
 * formatPriority :: String -> String
 */
export const formatPriority = R.ifElse(
  R.identity,
  (priority) => R.prop(priority, ProjectPrioritiesUIMap),
  R.always(NOTHING_UI_STRING),
);

// formatStatusInfo :: Project -> StatusInfo
// StatusInfo = { label: String, message: String }
export const formatStatusInfo = R.cond([
  [
    R.propEq('status', WorkOrderStatus.BLOCKED),
    R.compose(
      R.assoc('label', 'Blocked'),
      R.objOf('message'),
      formatDateOrNothingUI,
      R.prop('blockedAt'),
    ),
  ],
  [
    R.propEq('status', WorkOrderStatus.COMPLETED),
    R.compose(
      R.assoc('label', 'Completed'),
      R.objOf('message'),
      formatDateOrNothingUI,
      R.prop('workCompletionDate'),
    ),
  ],
  [isFeeProjectType, R.always(null)],
  [
    R.T,
    R.compose(
      R.assoc('label', 'Open'),
      R.objOf('message'),
      R.concat(R.__, ' Days'),
      R.toString,
      getProjectDaysOpen,
    ),
  ],
]);
