import { useDispatch } from 'react-redux';
import { openConfirmModal } from 'poly-admin-ui';

import { confirmButtonNames } from '../../../modals/confirmButtons/constants.js';
import { isOutsideOfCurrentPayWeek } from '../helpers/dates.js';

export const useCommonDeleteTimeButtonLogic = ({ _id, date }) => {
  const dispatch = useDispatch();

  const renderNothing = isOutsideOfCurrentPayWeek(date);

  const onClick = () => {
    dispatch(
      openConfirmModal({
        payload: {
          confirmBtnPayload: {
            _id,
          },
          message: 'Are you sure you want to delete this time record?',
          icon: 'confirm',
          cancelBtnCaption: 'No',
          isOpen: true,
          confirmButtonName: confirmButtonNames.DELETE_TIME,
        },
      }),
    );
  };

  return {
    renderNothing,
    onClick,
  };
};
