import React from 'react';
import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import { bool, string, func } from 'prop-types';
import { ProjectType, AccountingStatus } from 'poly-constants';
import { entities } from 'poly-admin-ui';
import { Select } from 'poly-book-admin';
import {
  entityToOptionByLabelPath,
  useQueryWithSearch,
} from 'poly-client-utils';

import { PROJECTS_BY_SEARCH_SUB } from '../modules/core/hooks/projects/subscriptions.js';

export const PROJECTS_BY_SEARCH_SELECT = gql`
  query PROJECTS_BY_SEARCH_SELECT($searchInput: CollectionSearchParams!) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        projectId
      }
      total
    }
  }
`;

const PROJECT_DEFAULT_OPTION_QUERY = gql`
  query PROJECT_ASSIGN_SUPPLIERS_QUERY($id: ID!) {
    project(id: $id) {
      _id
      projectId
    }
  }
`;

// prepareOptions :: { searchProjects: { hits: [Project] } } -> [Option]
const prepareOptions = R.compose(
  R.map(entityToOptionByLabelPath(['projectId'])),
  R.reject(R.isNil),
  R.flatten,
  R.juxt([R.prop('project'), R.pathOr([], ['searchProjects', 'hits'])]),
);

export function ProjectSelect({
  getQuery,
  skipQuery = false,
  isClearable = true,
  withoutSkip = false,
  name = 'ProjectSelect',
  includeAllOption = true,
  withoutReportOnly = true,
  filterOption = () => true,
  withoutNonBillable = false,
  placeholder = 'Start typing projects',
  ...restProps
}) {
  const { value, onChange } = restProps;

  const query = withoutReportOnly
    ? {
        bool: {
          must_not: [
            { match: { type: ProjectType.REPORT_ONLY } },
            ...(withoutNonBillable
              ? [
                  {
                    terms: {
                      accountingStatus: [
                        AccountingStatus.NON_BILLABLE,
                        AccountingStatus.NON_BILLABLE_CLOSED,
                      ],
                    },
                  },
                ]
              : []),
          ],
        },
      }
    : null;

  const {
    result,
    error,
    onSearchChange,
    loading: projectsLoading,
  } = useQueryWithSearch({
    skipQuery,
    withoutSkip,
    gqlSearchQuery: PROJECTS_BY_SEARCH_SELECT,
    gqlSearchChangedQuery: PROJECTS_BY_SEARCH_SUB,
    query: getQuery ? getQuery(restProps) : query,
    entity: entities.PROJECT,
    propsOfComponent: {
      name,
      placeholder,
      isClearable,
      filterOption,
      includeAllOption,
      withoutReportOnly,
      ...restProps,
    },
  });

  const { data, loading: defaultProjectLoading } = useQuery(
    PROJECT_DEFAULT_OPTION_QUERY,
    {
      variables: { id: value },
      skip: !value,
    },
  );

  const entityProps = { ...data, ...result };

  const selectProps = {
    ...restProps,
    ...entityProps,
    error,
    loading: projectsLoading || defaultProjectLoading,
    onChange,
    onInputChange: onSearchChange,
    options: prepareOptions(entityProps),
  };

  return <Select {...selectProps} />;
}

ProjectSelect.displayName = 'ProjectSelect';

ProjectSelect.propTypes = {
  name: string,
  value: string,
  getQuery: func,
  onChange: func,
  skipQuery: bool,
  withoutSkip: bool,
  isClearable: bool,
  filterOption: func,
  placeholder: string,
  includeAllOption: bool,
  withoutReportOnly: bool,
  withoutNonBillable: bool,
};
