import React, { useState } from 'react';
import styled from 'styled-components';
import { arrayOf, func, shape, string } from 'prop-types';
import { useSelectableTableRowsProps } from 'poly-admin-ui/src/hooks/useSelectableTableRowsProps.js';
import { WhiteCard } from 'poly-book-admin/src/Card.js';

import { AccountingPeriodsTableComp } from '../ReopenAccountingPeriod/AccountingPeriodsTable.js';

const TableWrapper = styled(WhiteCard)`
  padding: 15px;
`;

export function LockAccountingMonthTable({
  accountingPeriods,
  onSubmit,
  ...props
}) {
  const [selectedIds, setSelectedIds] = useState([]);

  const { toggleRow, toggleSelectAll } = useSelectableTableRowsProps(
    selectedIds,
    setSelectedIds,
    accountingPeriods,
  );

  const handleSubmit = async () => {
    await onSubmit(selectedIds);
    setSelectedIds([]);
  };

  return (
    <TableWrapper>
      <AccountingPeriodsTableComp
        accountingPeriods={accountingPeriods}
        toggleSelectAll={toggleSelectAll}
        selectedRows={selectedIds}
        toggleRow={toggleRow}
        onSubmit={handleSubmit}
        showAllSelector={accountingPeriods.length > 0}
        {...props}
      />
    </TableWrapper>
  );
}

LockAccountingMonthTable.propTypes = {
  accountingPeriods: arrayOf(
    shape({
      _id: string.isRequired,
      startDate: string.isRequired,
      endDate: string.isRequired,
      status: string.isRequired,
    }),
  ),
  onSubmit: func.isRequired,
};
