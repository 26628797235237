import React from 'react';
import * as R from 'ramda';
import { bool } from 'prop-types';
import styled from 'styled-components';
import { SUPPLIERS_INSURANCE_ROUTE, ESFM_INFO } from 'poly-constants';
import { getThemeColor } from 'poly-book-admin';

const Text = styled.p`
  margin: 0;
  padding: ${R.propOr(0, 'padding')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const VendorInfoSectionWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${getThemeColor(['midLight'])};
  align-items: center;
`;

const LinkS = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: ${getThemeColor(['primaryLight'])};
  text-decoration: underline;
`;

const PhoneLink = styled(LinkS)`
  padding-left: 3px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
`;

export function VendorInfoSection({ withInsuranceLink }) {
  const { telephone } = ESFM_INFO;

  return (
    <VendorInfoSectionWrapperS>
      {withInsuranceLink && (
        <Text padding="20px 0 24px 0">
          You can find our{' '}
          <LinkS target="_blank" href={SUPPLIERS_INSURANCE_ROUTE}>
            Insurance Requirements here
          </LinkS>
        </Text>
      )}

      <Text>
        If you have any questions, you can reach Client Support at
        <PhoneLink href={`tel:${telephone}`}>{telephone}</PhoneLink>
      </Text>
    </VendorInfoSectionWrapperS>
  );
}

VendorInfoSection.propTypes = {
  withInsuranceLink: bool,
};

VendorInfoSection.defaultProps = {
  withInsuranceLink: true,
};
