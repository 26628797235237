import * as R from 'ramda';
import {
  collectionNames,
  FEE_PROJECT_SUPPLIER_NAME_PREFIX,
  FeeTypes,
  FeeTypesUI,
  InvoiceTypes,
  NOTHING_UI_STRING,
} from 'poly-constants';

import { ensureIsDate, formatDate } from '../dates.js';

/**
 * isDocumentProjectRelated :: Object -> Boolean
 */
export const isDocumentProjectRelated = R.either(
  R.pathEq(['collection'], collectionNames.projects),
  R.pathEq(['collection'], collectionNames.recurringProjects),
);

// isRMProject :: Project -> Boolean
export const isRMProject = R.complement(R.prop('notRM'));

// getUIInvoiceNumber :: Invoice -> String
export const getUIInvoiceNumber = R.either(
  R.prop('invoiceNumber'),
  R.cond([
    [
      R.propEq('type', InvoiceTypes.creditCardExpense),
      R.always('Paid By Credit Card'),
    ],
    [R.propEq('type', InvoiceTypes.bankExpense), R.always('Paid By Bank')],
    [R.T, R.always(NOTHING_UI_STRING)],
  ]),
);

// getFeeProjectSupplierName :: BatchInvoiceSpendReportResult -> String
// eslint-disable-next-line import/no-unused-modules
export const getFeeProjectSupplierName = R.compose(
  R.concat(FEE_PROJECT_SUPPLIER_NAME_PREFIX),
  R.ifElse(
    R.propEq('projectFeeType', FeeTypes.CUSTOM_FEE),
    R.propOr('', 'projectCustomFeeDescription'),
    R.compose(R.propOr('', R.__, FeeTypesUI), R.prop('projectFeeType')),
  ),
);

// getProjectStartEndDatesUI :: Project -> String
// eslint-disable-next-line import/no-unused-modules
export const getProjectStartEndDatesUI = R.compose(
  R.join(' - '),
  R.juxt([
    R.compose(formatDate, R.prop('startDate')),
    R.compose(formatDate, R.prop('endDate')),
  ]),
);

// getProjectDateCompletedUI :: Project -> String
// eslint-disable-next-line import/no-unused-modules
export const getProjectDateCompletedUI = R.compose(
  R.ifElse(
    R.isNil,
    R.always(NOTHING_UI_STRING),
    R.compose(formatDate, ensureIsDate),
  ),
  R.prop('workCompletionDate'),
);
