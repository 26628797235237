import { JournalPaymentMode } from 'poly-constants';

export const searchInvoicesFilters = {
  terms: 'terms',
  paymentType: 'paymentType',
  projectId: 'projectId',
  supplierId: 'supplierId',
  masterSupplierId: 'masterSupplierId',
  clientId: 'clientId',
  serviceTypeId: 'serviceTypeId',
  invoiceId: 'invoiceId',
  startDate: 'startDate',
  endDate: 'endDate',
  invoiceDate: 'invoiceDate',
  paymentDueDate: 'paymentDueDate',
  pastDueDate: 'pastDueDate',
  showOnlyWarnings: 'showOnlyWarnings',
  w9Absent: 'w9Absent',
};

export const paymentTypesOptions = [
  { value: JournalPaymentMode.CHECK, label: 'Check' },
  { value: JournalPaymentMode.CREDIT_CARD, label: 'Credit Card' },
  { value: JournalPaymentMode.ACH, label: 'ACH' },
  { value: JournalPaymentMode.WIRE, label: 'Wire' },
];
