import React from 'react';
import { CommonSidebarFormWidth, entities } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';

import { editClientFormId } from '../../constants.js';
import { EditClientSidebarForm } from './EditClientSidebarForm.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';

export const useEditClientSidebar = (isCard) => {
  const { openOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar(isCard);

  const openEditClientSidebar = (formProps) =>
    openOutSidebar({
      alwaysFirst: true,
      id: editClientFormId,
      width: CommonSidebarFormWidth,
      content: (
        <EditClientSidebarForm
          {...formProps}
          isCard={isCard}
          onClose={() => onClose(entities.CLIENT, formProps.client._id)}
        />
      ),
    });
  return openEditClientSidebar;
};
