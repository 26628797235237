import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { JournalPaymentStatus, EXPORT_XLS_CAPTION } from 'poly-constants';
import { bool, string } from 'prop-types';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
  formatFromToDateTitle,
} from 'poly-client-utils';
import { formatMoneyTransactionPayeeNameColumn, formatDate } from 'poly-utils';
import { Button } from 'poly-book-admin';

import {
  bankRegistersQueryPropType,
  bankRegistersQueryDataPropType,
} from './commonPropTypes.js';
import { convertQueryDataToTransactions } from './bankRegistersQuery.js';
import {
  isMoneyTransactionReconciled,
  getMoneyTransactionAmount,
} from './helpers.js';

const ExportButton = styled(Button)`
  padding: 3px 14px;
  opacity: inherit;
`;

const RECONCILED_CHECK_MARK = '✓';

// getExcelExportFileNameByTitle :: String -> String
export const getExcelExportFileNameByTitle = R.compose(
  R.concat(R.__, '.xlsx'),
  R.toLower,
  R.replace(/\s+/g, '-'),
);

// getAccountNameByData :: Object -> String
const getAccountNameByData = R.path(['getSingleAccount', 'name']);

const columnWidths = [5, 15, 15, 40, 15, 15, 15];

// adjustForCheckNumberColumn :: Bool -> [a] -> [a]
//   a = Any
const adjustForCheckNumberColumn = (hideCheckNumber) =>
  R.when(R.always(hideCheckNumber), R.remove(2, 1));

const getColumnNames = (isCCRegister) => [
  'Clr',
  'Date',
  'Check Number',
  'Payee',
  ...(isCCRegister ? ['Charge', 'Payment'] : ['Payment', 'Deposit']),
  'Balance',
];

// getTableHeaderRow :: Bool -> [ExcelExportDataCell]
const getTableHeaderRow = (hideCheckNumber) =>
  R.compose(
    adjustForCheckNumberColumn(hideCheckNumber),
    R.zipWith(createExcelExportCell, [
      ...R.repeat(ExcelExportCellType.tableHeader, 4),
      ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 3),
    ]),
    getColumnNames,
  )(hideCheckNumber);

// getMoneyTransactionAmountXls :: Bool -> MoneyTransaction -> Number
const getMoneyTransactionAmountXls = (isPayment) =>
  R.compose(
    R.when(R.equals(0), R.always(undefined)),
    getMoneyTransactionAmount(isPayment),
  );

// getMoneyTransactionPaymentAmountXls :: MoneyTransaction -> Number|String
const getMoneyTransactionPaymentAmountXls = getMoneyTransactionAmountXls(true);

// getMoneyTransactionDepositAmountXls :: MoneyTransaction -> Number
const getMoneyTransactionDepositAmountXls = getMoneyTransactionAmountXls(false);

const voidedCellStyle = {
  alignment: { horizontal: 'right' },
  /* eslint-disable-next-line @cspell/spellchecker */
  font: { color: { argb: 'FFFF0000' } },
};

// getRowByMoneyTransaction ::
//   Bool -> MoneyTransaction -> [ExcelExportDataCell]
const getRowByMoneyTransaction = (hideCheckNumber) => (transaction) =>
  R.compose(
    adjustForCheckNumberColumn(hideCheckNumber),
    R.over(
      R.lensIndex(4),
      R.when(
        R.always(
          R.propEq('payment_status', JournalPaymentStatus.VOIDED, transaction),
        ),
        R.assoc('style', voidedCellStyle),
      ),
    ),
    R.zipWith(createExcelExportCell, [
      ...R.repeat(ExcelExportCellType.default, 4),
      ...R.repeat(ExcelExportCellType.defaultCurrency, 3),
    ]),
    R.juxt([
      R.ifElse(
        isMoneyTransactionReconciled,
        R.always(RECONCILED_CHECK_MARK),
        R.always(''),
      ),
      R.compose(formatDate, R.prop('date')),
      R.prop('reference_column'),
      formatMoneyTransactionPayeeNameColumn,
      getMoneyTransactionPaymentAmountXls,
      getMoneyTransactionDepositAmountXls,
      R.prop('currentBalance'),
    ]),
  )(transaction);

// getTransactionsRows ::
//   (Bool, ID) -> QueryData -> [[ExcelExportDataCell]]
const getTransactionsRows = (hideCheckNumber, accountId) =>
  R.compose(
    R.map(getRowByMoneyTransaction(hideCheckNumber)),
    convertQueryDataToTransactions(accountId),
  );

// eslint-disable-next-line import/no-unused-modules
export const getAccountRegisterXlsExportConfig = (
  data,
  query,
  exportTitle,
  hideCheckNumber,
) => ({
  exportFileName: getExcelExportFileNameByTitle(exportTitle),
  columnWidths: adjustForCheckNumberColumn(hideCheckNumber)(columnWidths),
  rows: [
    [{ value: exportTitle, cellType: ExcelExportCellType.title }],
    [
      {
        value: getAccountNameByData(data),
        cellType: ExcelExportCellType.subTitle,
      },
    ],
    [
      {
        value: formatFromToDateTitle(query.fromDate, query.toDate),
        cellType: ExcelExportCellType.subTitle,
      },
    ],

    getTableHeaderRow(hideCheckNumber),

    ...getTransactionsRows(hideCheckNumber, query.accountId)(data),
  ],
});

export function BankRegistersExportXlsButton({
  query,
  exportTitle,
  hideCheckNumber,
  data,
  disabled,
}) {
  const exportXls = async () => {
    const config = getAccountRegisterXlsExportConfig(
      data,
      query,
      exportTitle,
      hideCheckNumber,
    );
    await performExcelExport(config);
  };

  return (
    <ExportButton
      disabled={disabled}
      size="tiny"
      styleType="accentDark"
      onClick={exportXls}
    >
      {EXPORT_XLS_CAPTION}
    </ExportButton>
  );
}

BankRegistersExportXlsButton.propTypes = {
  query: bankRegistersQueryPropType,
  hideCheckNumber: bool,
  exportTitle: string.isRequired,
  disabled: bool,
  data: bankRegistersQueryDataPropType,
};
