import React from 'react';
import { UpdateServiceTypeForm as UpdateServiceTypeFormImp } from 'poly-admin-ui';
import { SupplierSources } from 'poly-constants';
import { ModalFormWrapper } from '../../modals/ModalFormWrapper.js';

const updateServiceTypeFormId = 'updateServiceTypeFormId';

export function UpdateServiceTypeForm(props) {
  return (
    <ModalFormWrapper
      {...props}
      title="Edit Service Type"
      buttonLabel="Save Changes"
      formId={updateServiceTypeFormId}
      supplierSource={SupplierSources.AAC}
      Form={UpdateServiceTypeFormImp}
    />
  );
}
