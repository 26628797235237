import {
  bool,
  func,
  shape,
  number,
  string,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useSubscription } from '@apollo/client';
import { Loader, Table, WindowedTable, BodyRow } from 'poly-book-admin';
import { highlightMatchesInObjectCurried } from 'poly-client-utils';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { assocBy, renameProp } from 'poly-utils';

import { SupplierLink } from '../../components/Links.js';
import { propertyEntityColumn } from '../../modules/tables/columns/property.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { SUPPLIER_ACCOUNT_SUB } from '../../modules/core/hooks/suppliers/subscriptions.js';
import { PROPERTY_DETAILS_SUB } from '../../modules/core/hooks/properties/subscriptions.js';

const SupplierDocumentExceptionsPrintTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 300px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 300px;
  }
`;

const SupplierDocumentExceptionsTableS = styled(WindowedTable)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 300px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 300px;
  }
`;

const supplierDocumentExceptionsPageTableConfig = [
  ['Project #', (props) => <ProjectLink {...props} inNewTab />],
  propertyEntityColumn,
  [
    'Supplier',
    R.compose(
      SupplierLink,
      R.assoc('target', '_blank'),
      assocBy('name', R.path(['company', 'name'])),
      R.omit(['isVeteran']),
      R.prop('suppliers'),
    ),
  ],
  ['Exceptions', R.prop('supplierExceptions')],
];

const searchTextPaths = [
  ['projectId'],
  ['property', 'name'],
  ['suppliers', 'company', 'name'],
];

// prepareToDisplay :: (Boolean, String) -> [Project] -> [Project]
const prepareToDisplay = (isPrint, searchTerm) =>
  R.ifElse(
    () => isPrint,
    R.identity,
    R.map(
      R.compose(
        highlightMatchesInObjectCurried(searchTextPaths, searchTerm),
        assocBy('projectLinkId', R.prop('projectId')),
      ),
    ),
  );

// getSubscriptionOptions :: { row: { property: Property, suppliers: Supplier } }
// -> { property: Property, supplier: Supplier }
const getSubscriptionOptions = R.compose(
  renameProp('suppliers', 'supplier'),
  R.prop('row'),
);

function CustomPageRowComponent({ debouncedRefetch, ...props }) {
  const { property, supplier } = getSubscriptionOptions(props);

  useSubscription(PROPERTY_DETAILS_SUB, {
    shouldResubscribe: property,
    onData: debouncedRefetch,
    variables: { input: { id: property._id } },
  });

  useSubscription(SUPPLIER_ACCOUNT_SUB, {
    shouldResubscribe: supplier,
    onData: debouncedRefetch,
    variables: { input: { id: supplier._id } },
  });

  return <BodyRow {...props} />;
}

CustomPageRowComponent.propTypes = {
  debouncedRefetch: func,
};

export function SupplierDocumentExceptionsPageTable({
  loading,
  projectSuppliers,
  tableProps,
  isPrint,
  allItemsFetched,
  searchTerm,
  debouncedRefetch,
}) {
  const PageTable = isPrint
    ? SupplierDocumentExceptionsPrintTableS
    : SupplierDocumentExceptionsTableS;

  const CurrentPageRowComponent = isPrint ? BodyRow : CustomPageRowComponent;

  const mapConfigTableProps = useMapConfigToTableProps(
    prepareToDisplay(isPrint, searchTerm),
    supplierDocumentExceptionsPageTableConfig,
    projectSuppliers,
  );

  // Note: this bodge makes sure all items are loaded considering
  // custom rows generation logic (`getProjectSuppliers`).
  // It may break in future considering
  // `useTableInfiniteScrollQuery`'s complexity
  const itemCount = allItemsFetched
    ? projectSuppliers.length
    : projectSuppliers.length + 10;

  return loading ? (
    <Loader />
  ) : (
    <PageTable
      {...mapConfigTableProps}
      {...tableProps}
      enableHoverEffect
      itemSize={53}
      moreScrollPosition="0px"
      RowComponent={CurrentPageRowComponent}
      tableRowProps={{ debouncedRefetch }}
      itemCount={itemCount}
      showScrollBar
    />
  );
}

const commonFileFields = {
  fileName: string,
  fileType: string,
  url: string,
};

export const supplierDocumentExceptionsProjectSupplier = shape({
  _id: string,
  projectId: string,
  property: shape({
    _id: string,
    name: string,
  }),
  suppliers: shape({
    _id: string,
    company: shape({
      name: string,
    }),
    documents: shape({
      exempt: bool,
      wcExp: oneOfType([instanceOf(Date), string]),
      glExp: oneOfType([instanceOf(Date), string]),
      wcFile: shape(commonFileFields),
      glFile: shape(commonFileFields),
      scaFile: shape(commonFileFields),
      sqqFile: shape(commonFileFields),
    }),
  }),
});

SupplierDocumentExceptionsPageTable.propTypes = {
  loading: bool.isRequired,
  isPrint: bool.isRequired,
  allItemsFetched: bool.isRequired,
  searchTerm: string,
  debouncedRefetch: func,
  tableProps: shape({
    isLoading: bool,
    itemCount: number,
    loadMoreItems: func,
    loadedCount: number,
    onScroll: func,
  }),
  projectSuppliers: arrayOf(supplierDocumentExceptionsProjectSupplier),
};
