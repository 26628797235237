import React from 'react';
import { string } from 'prop-types';
import { InvoiceTypes, InvoicesStatuses } from 'poly-constants';
import { Popover, PopoverLink } from 'poly-book-admin';
import { useModalContext } from 'poly-admin-ui';
import { IconMore } from '../../components/Icons.js';

import { EditSupplierInvoiceButton } from './EditSupplierInvoiceButton.js';
import { VoidSupplierInvoiceButton } from './VoidSupplierInvoiceButton.js';
import { supplierInvoiceFormId } from '../../modules/forms/supplierInvoiceForm/constants.js';
import { SupplierInvoiceModalLayout } from '../../modules/forms/supplierInvoiceForm/supplierInvoiceForm.js';
import { ApproveSupplierInvoiceForm } from '../../sidebars/ProjectSidebar/forms/ApproveSupplierInvoiceForm.js';
import { ApproveStandaloneSupplierInvoiceForm } from '../ApproveSupplierInvoices/ApproveSupplierInvoiceSidebar/ApproveStandaloneSupplierInvoiceSidebarTabs.js';

function ApproveSupplierInvoiceButton({ _id, status, type }) {
  const { openModal, closeModal } = useModalContext();

  const ApproveFormComponent =
    type === InvoiceTypes.supplier
      ? ApproveStandaloneSupplierInvoiceForm
      : ApproveSupplierInvoiceForm;

  const onCancel = () => closeModal(supplierInvoiceFormId);

  const onClick = () =>
    openModal({
      id: supplierInvoiceFormId,
      formId: supplierInvoiceFormId,
      Layout: SupplierInvoiceModalLayout,
      content: (
        <ApproveFormComponent
          formPadding="0"
          invoiceId={_id}
          onCancel={onCancel}
          closeSidebar={onCancel}
        />
      ),
    });

  return (
    status === InvoicesStatuses.RECEIVED && (
      <PopoverLink onClick={onClick}>Approve Invoice</PopoverLink>
    )
  );
}

ApproveSupplierInvoiceButton.propTypes = {
  _id: string,
  status: string,
  type: string,
};

export function InvoicePopoverButton(props) {
  const { status } = props;

  return (
    status !== InvoicesStatuses.VOIDED && (
      <Popover
        Icon={IconMore}
        position="left"
        withPortalAnchor
        content={
          <>
            <EditSupplierInvoiceButton {...props} />
            <VoidSupplierInvoiceButton {...props} />
            <ApproveSupplierInvoiceButton {...props} />
          </>
        }
      />
    )
  );
}

InvoicePopoverButton.propTypes = { status: string.isRequired };
