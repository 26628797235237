import { useEntitiesByReactiveSearch } from 'poly-client-utils';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { NON_CLIENT_SELECT_OPTION } from 'poly-constants';
import { CLIENTS_SEARCH_FOR_SELECT, DEFAULT_CLIENT_QUERY } from './queries.js';
import { CLIENTS_BY_SEARCH_SUB } from './subscription.js';
import { ALL } from '../../constants/general.js';

export const useClientsSearch = (pagination) => {
  const searchText = useSelector((state) => state.searchText);

  const { loading, result } = useEntitiesByReactiveSearch({
    gqlSearchQuery: CLIENTS_SEARCH_FOR_SELECT,
    gqlSearchChangedQuery: CLIENTS_BY_SEARCH_SUB,
    pagination,
    alias: 'useClientsSearch',
    searchText,
  });

  return {
    loading,
    result,
  };
};

export const useDefaultClient = (value) => {
  const { data, loading } = useQuery(DEFAULT_CLIENT_QUERY, {
    variables: { id: value },
    skip: !value || value === ALL || value === NON_CLIENT_SELECT_OPTION,
  });

  return {
    data,
    loading,
  };
};
