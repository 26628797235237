import React from 'react';
import * as R from 'ramda';
import { shape, string, bool } from 'prop-types';
import { SidebarRow, systemAccessStatusesColors } from 'poly-admin-ui';
import { useUpdateQueryParams } from 'poly-client-routing';
import { EntityStatus, ToolBarBtnDivider } from 'poly-book-admin';
import { UserStatuses } from 'poly-constants';
import styled from 'styled-components';

import {
  SidebarLabel,
  BlockWithLabel,
  SectionWrapper,
  headerTextProps,
} from '../components/commonSidebarComponents.js';
import { SidebarIDs } from '../constants.js';
import { FlexContainer } from '../../components/FlexContainer.js';
import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';
import { EditContactButton } from './forms/edit/EditContactButton.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import { getEntityAddressPreview } from '../utils/address.js';
import { SendPasswordResetButton } from '../ClientSidebar/forms/ClientUserFormBase.js';

const FlexContainerWrapper = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
`;

const ToolBarBtnDividerS = styled(ToolBarBtnDivider)`
  margin: 0 0 0 5px;
`;

export function ContactSidebarHeader({ contactUser, showPrivateFields }) {
  const updateQueryParams = useUpdateQueryParams();

  const userStatus = contactUser.status || UserStatuses.INACTIVE;

  return (
    <SectionWrapper>
      <SidebarRow justify align>
        <FlexContainer>
          <SidebarLabel margin={10}>{contactUser.fullName}</SidebarLabel>
          <EntityStatus
            title="Contact"
            status={{
              text: R.toLower(userStatus),
              color: systemAccessStatusesColors[userStatus],
            }}
          />
        </FlexContainer>
        <SidebarCloseIcon
          onClick={() => updateQueryParams({ [SidebarIDs.contact]: undefined })}
        />
      </SidebarRow>
      <SidebarRow justify>
        {showPrivateFields && (
          <BlockWithLabel
            margin="0"
            id="address"
            label="Address"
            Component={getSectionText(
              getEntityAddressPreview(contactUser),
              headerTextProps,
            )}
            width="62%"
          />
        )}
        <FlexContainerWrapper>
          {contactUser?.isClientPortalUser && (
            <>
              <SendPasswordResetButton email={contactUser?.email} />
              <ToolBarBtnDividerS />
            </>
          )}

          <EditContactButton
            contactId={contactUser._id}
            contactUser={contactUser}
          />
        </FlexContainerWrapper>
      </SidebarRow>
    </SectionWrapper>
  );
}

ContactSidebarHeader.propTypes = {
  contactUser: shape({
    isStaff: bool,
    status: string,
    _id: string.isRequired,
    fullName: string.isRequired,
    isClientPortalUser: bool,
  }),
  showPrivateFields: bool.isRequired,
};
