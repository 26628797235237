import React from 'react';
import * as R from 'ramda';
import { Textarea, Input } from 'poly-book-admin';

function HoursInput(props) {
  return <Input {...props} type="numberMask" />;
}

function ReasonArea(props) {
  return <Textarea rows={3} {...props} />;
}

export const editPorterHoursFormSections = (ticketsTotalHours) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Hours',
        order: 1,
        layout: { row: 1, width: 'calc(30% - 10px)' },
        required: true,
        validators: [
          [R.identity, 'Hours is required'],
          [
            R.gte(ticketsTotalHours),
            `You can't credit more than ${ticketsTotalHours} hours`,
          ],
        ],
        field: {
          name: 'hours',
          Component: HoursInput,
        },
      },
      {
        label: 'Reason for Credit',
        order: 2,
        layout: { row: 1, width: 'calc(70% - 10px)' },
        required: true,
        validators: [[R.identity, 'Reason is required']],
        field: {
          name: 'reason',
          Component: ReasonArea,
        },
      },
    ],
  },
];
