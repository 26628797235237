import React from 'react';
import styled from 'styled-components';
import { isBefore } from 'date-fns';
import { bool, string } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';
import { ensureIsDate, formatDate } from 'poly-utils';
import { Popover, Icon, getThemeColor } from 'poly-book-admin';

import { RedSpan } from '../../tablesStyles.js';

const InvoiceDueDateWithChangeReasonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const WarningIconS = styled(Icon)`
  margin: 0 0 0 10px;
`;

const ChangeReasonWrapper = styled.div`
  padding: 10px;
`;

const ChangeReasonTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const ChangeReasonTitleDivider = styled.div`
  width: 100%;
  height: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

function YellowWarningIcon() {
  return <WarningIconS size={15} name="yellowWarning" />;
}

function InvoiceDueDateWithChangeReason({
  value,
  isOverdue,
  paymentDueDateChangeReason,
}) {
  return (
    <InvoiceDueDateWithChangeReasonWrapper>
      {isOverdue ? <RedSpan>{value}</RedSpan> : value}
      <Popover
        Icon={YellowWarningIcon}
        content={
          <ChangeReasonWrapper>
            <ChangeReasonTitle>Due Date change reason</ChangeReasonTitle>
            <ChangeReasonTitleDivider />
            {paymentDueDateChangeReason}
          </ChangeReasonWrapper>
        }
      />
    </InvoiceDueDateWithChangeReasonWrapper>
  );
}

InvoiceDueDateWithChangeReason.propTypes = {
  value: string,
  isOverdue: bool,
  paymentDueDateChangeReason: string,
};

// checkIsOverdue :: (MaybeDate, MaybeDate) -> Boolean
// MaybeDate = String || DateTime
const checkIsOverdue = (paidAt, paymentDueDate) =>
  isBefore(ensureIsDate(paymentDueDate), ensureIsDate(paidAt));

export function InvoiceDueDateComponent({
  paidAt,
  paymentDueDate,
  paymentDueDateChangeReason,
}) {
  if (!paymentDueDate) {
    return NOTHING_UI_STRING;
  }

  const value = formatDate(paymentDueDate);

  const isOverdue = checkIsOverdue(paidAt, paymentDueDate);

  if (paymentDueDate && paymentDueDateChangeReason) {
    return (
      <InvoiceDueDateWithChangeReason
        isOverdue={isOverdue}
        value={value}
        paymentDueDateChangeReason={paymentDueDateChangeReason}
      />
    );
  }

  return isOverdue ? <RedSpan>{value}</RedSpan> : value;
}

InvoiceDueDateComponent.propTypes = {
  paidAt: string,
  paymentDueDate: string,
  paymentDueDateChangeReason: string,
};
