import { FlexContainer } from 'poly-admin-ui';
import { getThemeColor } from 'poly-book-admin';

import styled from 'styled-components';

export const SectionTitleContainer = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 65px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${getThemeColor(['midLight'])}
  width: 100%;
  flex-shrink: 0;
  position: relative;
`;
