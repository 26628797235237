import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { PopoverLink } from 'poly-book-admin';
import { func, string } from 'prop-types';
import {
  useModalContext,
  ThreeDotsPopover,
  useNotificationState,
} from 'poly-admin-ui';

import { editProcedureFormId } from './constants.js';
import { useDeleteProcedureMutation } from './hooks/useDeleteProcedureMutation.js';
import { useOpenAddEditProcedureSidebar } from './hooks/useOpenAddEditProcedureSidebar.js';

const PopoverLinkS = styled(PopoverLink)`
  text-align: left;
`;

function ProcedureActionsButtonComponent({ _id, refetch, ...procedure }) {
  const onAddEditProcedure =
    useOpenAddEditProcedureSidebar(editProcedureFormId);

  const { openConfirmModal } = useModalContext();
  const deleteProcedure = useDeleteProcedureMutation();
  const { showSuccessNotification } = useNotificationState();

  const handleDeleteProcedure = async () => {
    await deleteProcedure(_id);

    if (R.is(Function, refetch)) {
      await refetch();
    }

    showSuccessNotification('Procedure was successfully deleted');
  };

  const onDelete = () =>
    openConfirmModal({
      btnCaption: 'Delete',
      cancelBtnCaption: 'Cancel',
      id: 'confirmDeleteProcedureModal',
      content:
        'This procedure will be deleted from all future projects. Are you sure you want to delete this procedure?',
      onConfirm: (closeConfirmModal) => () => {
        handleDeleteProcedure();
        closeConfirmModal();
      },
    });

  const onEdit = () => onAddEditProcedure({ _id, ...procedure });

  return (
    <>
      <PopoverLinkS onClick={onEdit}>Edit</PopoverLinkS>
      <PopoverLinkS onClick={onDelete}>Delete</PopoverLinkS>
    </>
  );
}

ProcedureActionsButtonComponent.propTypes = {
  _id: string,
  refetch: func,
};

export function ProcedureActionsButton(props) {
  return (
    <ThreeDotsPopover
      contentProps={props}
      content={ProcedureActionsButtonComponent}
    />
  );
}
