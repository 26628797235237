import React from 'react';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';
import { useNotificationState } from 'poly-admin-ui/src/hooks/useNotificationState.js';
import { LinkButton } from 'poly-book-admin/src/LinkButton/index.js';
import { ClientInvoiceStatuses, ProjectType } from 'poly-constants';
import { WRITEOFF_CLIENT_INVOICE_PERMISSION } from 'poly-security';
import { Popover } from 'poly-book-admin/src/Popover/index.js';
import { useNavigate } from 'poly-client-routing';
import { insertParamIntoURL } from 'poly-utils';

import { routesNames } from '../../routes/index.js';
import { IconMore } from '../../components/Icons.js';
import { WriteOffClientInvoiceButton } from './WriteOffClientInvoiceButton.js';
import { ReverseWrittenOffClientInvoiceButton } from './ReverseWrittenOffClientInvoiceButton.js';

const PopoverS = styled(Popover)`
  button {
    padding: 10px;
  }
`;

function EditClientInvoiceButton({ _id, project }) {
  const navigate = useNavigate();
  const { showWarningNotification } = useNotificationState();

  const onClick = () => {
    if (project && project.type === ProjectType.HOUSEKEEPING) {
      showWarningNotification(
        'Invoice edit must be done from the project card',
      );
    } else {
      navigate(
        insertParamIntoURL('invoiceId', routesNames.EDIT_CLIENT_INVOICE, _id),
      );
    }
  };
  return <LinkButton onClick={onClick}>Edit Invoice</LinkButton>;
}

EditClientInvoiceButton.propTypes = {
  _id: string.isRequired,
  project: shape({ type: string.isRequired }),
};

export function InvoicePopoverButton(invoice) {
  const hasWriteOffPermission = useHasUserAccessWithPermission(
    WRITEOFF_CLIENT_INVOICE_PERMISSION,
  );

  const { status } = invoice;

  const isVoided = status === ClientInvoiceStatuses.VOIDED;
  const isPending = status === ClientInvoiceStatuses.PENDING;
  const isWrittenOff = status === ClientInvoiceStatuses.WRITTEN_OFF;

  const hideBtn = isVoided || (isWrittenOff && !hasWriteOffPermission);

  if (hideBtn) return null;

  const content = isWrittenOff ? (
    <ReverseWrittenOffClientInvoiceButton {...invoice} />
  ) : (
    <>
      <EditClientInvoiceButton {...invoice} />
      {isPending && hasWriteOffPermission && (
        <WriteOffClientInvoiceButton {...invoice} />
      )}
    </>
  );

  return <PopoverS Icon={IconMore} position="left" content={content} />;
}

InvoicePopoverButton.propTypes = { status: string.isRequired };
