import React, { useState } from 'react';
import * as R from 'ramda';
import { func, node, string, oneOfType, object, arrayOf } from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';

import { MenuItemLink } from './styles.js';
import { LinkButton } from '../LinkButton/index.js';

export function MenuLinkWrapper({
  title,
  href,
  target,
  children,
  onClick,
  dataTestId,
  permissions = [],
}) {
  const hasAccess = R.compose(
    R.all(R.equals(true)),
    R.map(useHasUserAccessWithPermission),
  )(permissions);

  if (!hasAccess && permissions.length) {
    return null;
  }

  return href ? (
    <MenuItemLink
      key={title}
      href={href}
      target={target}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {children}
    </MenuItemLink>
  ) : (
    <LinkButton key={title} onClick={onClick} data-testid={dataTestId}>
      {children}
    </LinkButton>
  );
}

MenuLinkWrapper.propTypes = {
  title: string,
  href: oneOfType([string, object]),
  target: string,
  children: node,
  onClick: func,
  dataTestId: string,
  permissions: arrayOf(string),
};

MenuLinkWrapper.displayName = 'MenuLinkWrapper';

export const useMenuPopUpLogic = (closeParent) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOutsideClick = () => isOpen && setIsOpen(false);

  const handleParentClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
    setIsOpen(false);
    if (closeParent) {
      closeParent();
    }
  };

  return {
    isOpen,
    setIsOpen,
    onOutsideClick,
    handleParentClick,
    handleChildClick,
  };
};
