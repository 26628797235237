import styled from 'styled-components';
import { getThemeColor, getThemeProp, Icon, LinkButton } from 'poly-book-admin';

export const HistoryWrapper = styled.div`
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: ${getThemeColor(['white'])};
  top: 35px;
  left: -150px;
  width: 320px;
  max-height: 600px;
  overflow: hidden;
`;

export const HistoryIcon = styled(Icon)`
  padding: 7px;
  margin-right: 9px;
  background-color: ${getThemeColor(['primaryRegular'])};
  border-radius: 5px;
  cursor: pointer;

  :hover,
  :focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;

export const HistoryRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  padding: 13px;
`;

export const HistoryRecordsWrapper = styled(FlexColumn)`
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: auto;
`;

export const HistoryItem = styled(HistoryRow)`
  border-top: 1px solid ${getThemeColor(['midLight'])};
  align-items: flex-start;

  :hover {
    box-shadow: ${getThemeProp(['defaultBoxShadow'])};
  }
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

export const ClearButton = styled(LinkButton)`
  margin-right: 7px;
  text-decoration: underline;
`;

export const DescriptionText = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${getThemeColor(['midDark'])};
  word-break: break-word;
`;

export const LinkWrapper = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const DeleteIcon = styled(Icon).attrs((props) => ({
  size: 16,
  name: 'delete',
  color: getThemeColor(['mid'])(props),
}))`
  padding: 3px;
  margin-top: 2px;
  cursor: pointer;
  align-self: end;
  flex-shrink: 0;
`;

export const HistoryTime = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: ${getThemeColor(['mid'])};
  white-space: nowrap;
`;
