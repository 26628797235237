import { gql } from '@apollo/client';
import { commonContactPopoverFields } from 'poly-admin-ui';
import { supplierDocumentsFields } from '../suppliers/fragments.js';

const basicProjectFields = gql`
  fragment basicProjectFields on Project {
    _id
    blockedAt
    ext
    expiresAt
    projectId
    status
    createdAt
    description
    startDate
    endDate
    type
    invoiceDescription
    priority
    workCompletionDate
    parent {
      _id
    }
    exemptSalesTax
  }
`;

const basicRecurringProjectFields = gql`
  fragment basicRecurringProjectFields on RecurringProject {
    _id
    type
    projectId
    status
    createdAt
    description
    startDate
    endDate
    invoiceDescription
    notRM
  }
`;

export const commonProjectFields = gql`
  fragment commonProjectFields on Project {
    ...basicProjectFields
    type
    property {
      _id
      name
    }
    client {
      _id
      name
      nickName
    }
    manager {
      _id
      fullName
    }
    blockedAt
  }

  ${basicProjectFields}
`;

export const commonRecurringProjectFields = gql`
  fragment commonRecurringProjectFields on RecurringProject {
    ...basicRecurringProjectFields
    property {
      _id
      name
    }
    client {
      _id
      name
      nickName
    }
    manager {
      _id
      fullName
    }
  }

  ${basicRecurringProjectFields}
`;

export const projectDetailsFields = gql`
  fragment projectDetailsFields on Project {
    ...basicProjectFields
    noSupplierRequired
    scheduleDate
    notRM
    isRecalled
    assetIds
    preventativeRepair {
      childProject {
        _id
        projectId
        type
      }
      relatedAsset {
        _id
      }
    }
    client {
      _id
      name
      nickName
      enableReportOnlyProjectType
      enablePropertyLocationHierarchy
      enableGlCodes
      enableCostCenter
      status
      configs {
        propertyFields {
          alarmInfo
          financialCoding
        }
        notifications {
          serviceRequestConfirmationEmail {
            toSiteContact
            toRequester
            toClientManager
          }
        }
        enableProjectSubCategory
        enablePurchaseOrder
        isPORequiredForProject
      }
      apps
      financial {
        division {
          _id
          code
          name
        }
        costCenter
      }
      tmMarkupRules {
        upTo
        percent
      }
    }
    property {
      _id
      name
      phone
      isMaster
      costCenter
      status
      address {
        address_parts {
          street_number
          route
          locality
          administrative_area_level_2
          administrative_area_level_1
          country
          postal_code
          postal_code_suffix
          neighborhood
        }
      }
      alarmInfo {
        alarmCode
        gateCode
      }
      buildingInfo {
        lockBoxCode
      }
      financialCoding
      assets {
        _id
      }
      tmMarkupRules {
        upTo
        percent
      }
    }
    subProperties {
      _id
      name
      assets {
        _id
      }
    }
    suppliers {
      _id
      type
      nte
      status
      statusInProject
      disableWOCEmail
      rating {
        average
        stars
      }
      assetScannerPinAccess {
        isEnabled
        code
      }
      company {
        name
        emails {
          email
          type
        }
        phones {
          phone
          type
        }
      }
      tax {
        w9File {
          url
        }
      }
      documents {
        ...supplierDocumentsFields
      }
    }
    serviceType {
      _id
      name
    }
    contact {
      ...commonContactPopoverFields
    }
    manager {
      ...commonContactPopoverFields
    }
    clientManager {
      ...commonContactPopoverFields
    }
    siteContact {
      ...commonContactPopoverFields
    }
    accountingStatus
    invoices {
      _id
      status
      total
      invoiceNumber
      supplier {
        _id
      }
    }
    customerWoNumber
    nte
    clientReferenceNumber
    feeAmount
    feeType
    customFeeDescription
    bidAmount
    contractLink
    taskListType
    parent {
      _id
      type
      projectId
      clientReferenceNumber
      adminPurchaseOrder {
        _id
        lowBalancePercent
        initialBalance
        currentBalance
        poNumber
        displayName
      }
    }
    total
    clientMonthlyFee
    clientHourlyRate
    supplierMonthlyFee
    supplierHourlyRate
    weeklyPorterHours
    creditedPorterHours
    weeklyServiceTickets {
      week
      hours
      extraHours
      status
      attachment {
        url
        fileName
        createdBy {
          _id
          fullName
        }
        createdAt
      }
    }
    clientInvoices {
      _id
      amount
      number
      createdAt
      paidAt
      status
    }
    isAfterHoursCall
    cloneFromProject {
      projectId
      type
    }
    division {
      _id
      code
      name
    }
    notificationConfig {
      toSiteContact
      toRequester
      toClientManager
    }
    listing {
      bpoListPrice
      bpoLow
      bpoHigh
      bpoDate
      salesAddendumDate
      listPrice
      listDate
      underContractDate
      dueDiligenceExp
      referralFee
      underContractPrice
      listAgreementExp
      expectedCloseDate
    }
    type
    searchAssets {
      total
      hits {
        _id
      }
    }
    propertyLocation {
      id
      name
      path
    }
    subCategory {
      _id
      name
    }
    clientGLCode {
      _id
      name
      code
    }
    costCenter
    estimates {
      _id
      total
    }
    nonBillable
    nonBillableReason
    spendType
    adminPurchaseOrder {
      _id
      lowBalancePercent
      initialBalance
      currentBalance
      poNumber
      displayName
    }
  }

  ${basicProjectFields}
  ${commonContactPopoverFields}
  ${supplierDocumentsFields}
`;

export const recurringProjectDetailsFields = gql`
  fragment recurringProjectDetailsFields on RecurringProject {
    ...basicRecurringProjectFields
    client {
      _id
      name
      nickName
      status
      enableReportOnlyProjectType
      enablePropertyLocationHierarchy
      enableGlCodes
      configs {
        defaultProjectType
        enableHousekeepingProjectType
        enableProjectSubCategory
        enablePurchaseOrder
      }
      financial {
        division {
          _id
          code
          name
        }
      }
    }
    property {
      _id
      name
      phone
      isMaster
      status
    }
    subProperties {
      _id
      name
    }
    suppliers {
      _id
      type
      nte
      status
      statusInProject
      supplierNote
      disableWOCEmail
      additionalSupplierWOCEmails
      company {
        name
        emails {
          email
          type
        }
        phones {
          phone
          type
        }
      }
      attachments {
        _id
        fileName
        fileType
        url
      }
    }
    addedSuppliers {
      _id
      type
      nte
      status
      statusInProject
      disableWOCEmail
      rating {
        average
        stars
      }
      company {
        name
        emails {
          email
          type
        }
        phones {
          phone
          type
        }
      }
      tax {
        w9File {
          url
        }
      }
      documents {
        ...supplierDocumentsFields
      }
    }
    serviceType {
      _id
      name
    }
    manager {
      ...commonContactPopoverFields
    }
    clientManager {
      ...commonContactPopoverFields
    }
    siteContact {
      ...commonContactPopoverFields
    }
    invoiceDescription
    nte
    schedule {
      repeats
      every
      days
      weekDay
    }
    childType
    contractLink
    taskListType
    clientReferenceNumber
    parentClone {
      _id
      projectId
    }
    cloneFromProject {
      projectId
      type
    }
    payload {
      passThrough {
        assetIds
      }
      tm {
        assetIds
      }
      feeProjectType {
        feeAmount
        feeType
        customFeeDescription
        invoiceDescription
      }

      bidProjectType {
        bidAmount
        assetIds
      }

      housekeepingProjectType {
        clientMonthlyFee
        clientHourlyRate
        supplierMonthlyFee
        supplierHourlyRate
        weeklyPorterHours
      }
    }
    division {
      _id
      code
      name
    }
    searchAssets {
      total
      hits {
        _id
      }
    }
    disableChildrenGeneration
    propertyLocation {
      id
      name
      path
    }
    subCategory {
      _id
      name
    }
    clientGLCode {
      _id
      name
      code
    }
    childType
    spendType
    adminPurchaseOrder {
      _id
      lowBalancePercent
      initialBalance
      currentBalance
      poNumber
      displayName
    }
  }

  ${basicRecurringProjectFields}
  ${commonContactPopoverFields}
  ${supplierDocumentsFields}
`;
