import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'poly-book-admin';
import { isNilOrEmpty } from 'poly-utils';
import { gql, useQuery } from '@apollo/client';
import { arrayOf, func, oneOfType, string } from 'prop-types';

import { MultiSelectDropDown } from './MultiSelectDropDown.js';

const MultiSelectDropDownS = styled(MultiSelectDropDown)`
  .multiselect__control,
  .multiselect__control:hover,
  .multiselect__control:focus,
  .multiselect__control--is-focused {
    min-height: 32px;
  }

  .multiselect__control {
    padding: 0;
  }

  .multiselect__value-container {
    min-height: 30px;
    max-height: ${R.propOr('85px', 'maxHeight')};
    overflow-y: auto;

    > div:last-child {
      height: 22px;
    }
  }
`;

const LIST_USER_GROUPS_QUERY = gql`
  query LIST_USER_GROUPS_QUERY($input: ListUserGroupsInput) {
    listUserGroups(input: $input) {
      _id
      name
      defaultLoginApp
    }
  }
`;

// prepareUserGroupSelectOptions :: ([UserGroup] -> [UserGroup]) -> { listUserGroups: [UserGroup] } -> [SelectOption]
const prepareUserGroupSelectOptions = (filterUserGroups) =>
  R.compose(
    R.map(R.applySpec({ value: R.prop('_id'), label: R.prop('name') })),
    filterUserGroups,
    R.propOr([], 'listUserGroups'),
  );

export function UserGroupSelect({ filterUserGroups = R.identity, ...props }) {
  const [searchText, setSearchText] = useState('');

  const { data, loading } = useQuery(LIST_USER_GROUPS_QUERY, {
    variables: { input: null },
  });

  const options = prepareUserGroupSelectOptions(filterUserGroups)(data);

  const selectProps = {
    options,
    loading,
    searchText,
    setSearchText,
    name: 'UserGroupSelect',
    onInputChange: setSearchText,
    ...props,
  };

  return <Select {...selectProps} />;
}

UserGroupSelect.propTypes = {
  filterUserGroups: func,
};

// prepareSelectValue :: [ID] -> [Option] -> [Option]
const prepareSelectValue = (userGroupIds, options) => {
  const selectedValues = R.cond([
    [R.is(String), R.of],
    [isNilOrEmpty, R.always([])],
    [R.T, R.identity],
  ])(userGroupIds);

  return R.filter(
    R.compose((id) => R.any(R.equals(id))(selectedValues), R.prop('value')),
  )(options);
};

export function UserGroupMultiSelect({
  value,
  onChange,
  filterUserGroups = R.identity,
  ...props
}) {
  const { data } = useQuery(LIST_USER_GROUPS_QUERY, {
    variables: { input: null },
  });

  const options = prepareUserGroupSelectOptions(filterUserGroups)(data);

  const preparedValue = useMemo(
    () => prepareSelectValue(value, options),
    [value, options],
  );

  const handleChange = (userGroups) => {
    const preparedCodes = userGroups ? R.map(R.prop('value'), userGroups) : '';
    onChange(preparedCodes);
  };

  const selectProps = {
    ...props,
    options,
    handleChange,
    value: preparedValue,
    placeholder: 'Start typing User Group',
  };

  return <MultiSelectDropDownS {...selectProps} />;
}

UserGroupMultiSelect.propTypes = {
  onChange: func,
  filterUserGroups: func,
  value: oneOfType([string, arrayOf(string)]),
};
