import * as R from 'ramda';
import React from 'react';
import { defaultTheme, Status } from 'poly-book-admin';
import { SupplierStatuses, SupplierTypes } from 'poly-constants';
import { string, object } from 'prop-types';

import { forceTitleCase } from 'poly-utils';
import { useMasterSupplierBranchesQuery } from './hooks/useMasterSupplierBranchesQuery.js';
import { useSearchMasterSuppliersQuery } from './hooks/useSearchMasterSuppliersQuery.js';
import {
  masterSupplierStatusColorUI,
  materSupplierStatusLabelUI,
} from '../../constants/masterSuppliers.js';

// getBranchesTotal :: MasterSupplier -> Number
const getBranchesTotal = R.pathOr(0, [
  'masterSupplier',
  'searchSupplierBranches',
  'total',
]);

const {
  colors: {
    statuses: { active, blocked },
  },
} = defaultTheme;

const { ACTIVE, BLOCKED } = SupplierStatuses;

const supplierStatusColorUI = {
  [ACTIVE]: active,
  [BLOCKED]: blocked,
};

export function MasterSupplierBranchesSubTabTitle({ supplierId, status }) {
  const { data } = useMasterSupplierBranchesQuery(supplierId, status);

  const total = getBranchesTotal(data);
  const text = `${forceTitleCase(status)} (${total})`;
  const color = supplierStatusColorUI[status];

  return <Status color={color} text={text} />;
}

MasterSupplierBranchesSubTabTitle.propTypes = {
  status: string.isRequired,
  supplierId: string.isRequired,
};

export function MasterSupplierTabTitle({ status, sorting }) {
  const { data } = useSearchMasterSuppliersQuery(
    status,
    SupplierTypes.ADMINISTRATIVE,
    sorting,
  );

  const count = R.pathOr(0, ['searchMasterSuppliers', 'total'], data);
  const color = masterSupplierStatusColorUI[status];
  const text = materSupplierStatusLabelUI[status];
  return <Status text={`${text} (${count})`} {...{ color }} />;
}

MasterSupplierTabTitle.propTypes = {
  status: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sorting: object,
};
