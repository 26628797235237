import * as R from 'ramda';
import { gql } from '@apollo/client';
import { MAX_ITEMS } from 'poly-admin-ui';
import { useSelector } from 'react-redux';
import { useReactiveQuery } from 'poly-client-utils';

const ASSET_MANUFACTURERS_QUERY = gql`
  query ASSET_MANUFACTURERS_QUERY($input: CollectionSearchParams) {
    searchAssetManufactures(input: $input) {
      hits {
        _id
        name
        replacementCost
      }
    }
  }
`;

const ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION = gql`
  subscription ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAssetManufacturerChanged(input: $input) {
      id
      type
    }
  }
`;

// prepareDataForTableProps :: (() => Promise _ _) -> SearchAssetManufacturersResult -> [AssetManufacturer]
const prepareDataForTableProps = (refetch) =>
  R.compose(
    R.map(R.assoc('refetch', refetch)),
    R.pathOr([], ['searchAssetManufactures', 'hits']),
  );

export const useAssetManufacturersQuery = () => {
  const searchTerm = useSelector((state) => state.searchText);

  const queryOptions = {
    variables: { input: { size: MAX_ITEMS, searchTerm } },
  };

  const { data, loading, refetch } = useReactiveQuery(
    ASSET_MANUFACTURERS_QUERY,
    ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const assetManufacturers = prepareDataForTableProps(refetch)(data);

  return { assetManufacturers, loading };
};
