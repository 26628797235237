import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import { commonAddressFields } from 'poly-admin-ui';

const supplierDocumentsRequestInfoByTemporalAccessQuery = gql`
  query supplierDocumentsRequestInfoByTemporalAccess {
    supplierDocumentsRequestInfoByTemporalAccess {
      documentTypes
      supplierInfo {
        _id
        service_24_7
        isVeteran
        company {
          name
          dba
          fax
          website
          emails {
            email
            type
          }
          phones {
            phone
            type
            legacyFormat
          }
          services {
            _id
            name
          }
          addressTwo
          address {
            ...commonAddressFields
          }
        }
        rates {
          hourly
          overTime
          trip
          weekend
        }
        tax {
          classification
          name
          exempt
        }
        mwbe {
          isWBE
          minority
        }
      }
    }
  }

  ${commonAddressFields}
`;

export const useSupplierDocumentsRequestInfo = (isAuthorized) => {
  const { data, loading } = useQuery(
    supplierDocumentsRequestInfoByTemporalAccessQuery,
    {
      fetchPolicy: 'network-only',
      skip: !isAuthorized,
    },
  );

  const documentsRequestInfo = R.propOr(
    { documentTypes: [] },
    'supplierDocumentsRequestInfoByTemporalAccess',
    data,
  );
  return {
    ...documentsRequestInfo,
    loading,
  };
};
