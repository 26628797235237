import React from 'react';
import { useModalContext } from 'poly-admin-ui';
import {
  MainHeader,
  PageHeaderContainer,
  HeaderLinksWrapper,
} from 'poly-book-admin';

import { addCCUserFormId } from './constants.js';
import { CreditCardUsersForm } from './CreditCardUsersForm.js';
import { CCUserTextButtonS } from './components.js';

function AddCCUserButton() {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: addCCUserFormId,
      title: 'Create New Credit Card User',
      formId: addCCUserFormId,
      btnCaption: 'Create',
      content: <CreditCardUsersForm isNewCCUser />,
    });

  return <CCUserTextButtonS onClick={onClick}>Add New</CCUserTextButtonS>;
}

export function CreditCardUsersPageHeader() {
  return (
    <PageHeaderContainer>
      <MainHeader>Credit Card Users</MainHeader>
      <HeaderLinksWrapper>
        <AddCCUserButton />
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}
