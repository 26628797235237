import styled from 'styled-components';
import { MultiSelectDropDown } from 'poly-admin-ui';

export const BillingProfileConfigMultiSelect = styled(MultiSelectDropDown)`
  .multiselect__control,
  .multiselect__control:hover,
  .multiselect__control:focus,
  .multiselect__control--is-focused {
    min-height: 26px;
  }

  .multiselect__control {
    padding: 0;
  }

  .multiselect__placeholder {
    font-size: 10px;
    margin-left: 10px;
  }

  .multiselect__multi-value {
    max-width: 145px;
  }

  .multiselect__value-container {
    min-height: 26px;
    max-height: 85px;
    overflow-y: auto;
    padding: 2px;

    > div:last-child {
      height: 20px;
      margin: 0 4px;
      padding: 0 0 4px 0;
    }
  }
`;
