import React from 'react';
import { CommonSidebarFormWidth, entities } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';

import { editPropertyFormId } from '../../constants.js';
import { EditPropertySidebarForm } from './EditPropertySidebarForm.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';

export const useEditPropertySidebar = (isCard) => {
  const { openOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar(isCard);

  const openEditPropertySidebar = (formProps) =>
    openOutSidebar({
      alwaysFirst: true,
      id: editPropertyFormId,
      width: CommonSidebarFormWidth,
      content: (
        <EditPropertySidebarForm
          {...formProps}
          isCard={isCard}
          onClose={() => onClose(entities.PROPERTY, formProps.property._id)}
        />
      ),
    });
  return openEditPropertySidebar;
};
