import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { halfWidth } from 'poly-admin-ui/src/modules/forms/common.js';
import { gql, useMutation } from '@apollo/client';
import { FormCreator, Input, LinkButton } from 'poly-book-admin';
import {
  useModalContext,
  useNotificationState,
  useOnSubmitSetStopSubmitting,
} from 'poly-admin-ui';

const sendSupplierServiceTicketRequestFormId =
  'sendSupplierServiceTicketRequestFormId';

const DisabledInputS = styled(Input).attrs(() => ({
  disabled: true,
}))``;

const getSendRequestInitialValues = R.applySpec({
  projectId: R.pathOr('', ['project', 'originalProjectId']),
  week: R.propOr('', 'week'),
});

export const SendSupplierServiceTicketRequestMutation = gql`
  mutation SendSupplierServiceTicketRequestMutation(
    $input: SendServiceTicketRequestInput!
  ) {
    sendServiceTicketRequest(input: $input) {
      projectIds
    }
  }
`;

const sendRequestFormCreatorSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Project',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'projectId',
          Component: DisabledInputS,
        },
      },
      {
        label: 'Week',
        order: 2,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'week',
          Component: DisabledInputS,
        },
      },
    ],
  },
];

export function WeekServiceTicketRequestedLink(
  project,
  week,
  refetchTableData,
) {
  const { openModalForm, closeModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [sendSupplierServiceTicketRequest] = useMutation(
    SendSupplierServiceTicketRequestMutation,
  );

  const onSubmitHandler = async () => {
    await sendSupplierServiceTicketRequest({
      variables: {
        input: {
          projectIds: [project._id],
          week,
        },
      },
    });
    closeModal(sendSupplierServiceTicketRequestFormId);
    showSuccessNotification('Supplier request was sent.');

    await refetchTableData();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    sendSupplierServiceTicketRequestFormId,
    onSubmitHandler,
  );

  const onClick = () => {
    openModalForm({
      id: sendSupplierServiceTicketRequestFormId,
      title: 'Send Supplier Service Ticket Request',
      formId: sendSupplierServiceTicketRequestFormId,
      btnCaption: 'Send',
      content: (
        <FormCreator
          id={sendSupplierServiceTicketRequestFormId}
          formId={sendSupplierServiceTicketRequestFormId}
          sections={sendRequestFormCreatorSections}
          initialValues={getSendRequestInitialValues({ project, week })}
          onSubmit={onSubmit}
        />
      ),
    });
  };

  return <LinkButton onClick={onClick}>Requested</LinkButton>;
}
