import React from 'react';
import { AddMasterSupplierForm, FormPageHeader } from 'poly-admin-ui';
import { DefaultBodyWrapper } from 'poly-book-admin';
import { SupplierSources, supplierTypes } from 'poly-constants';

import { routesNames } from '../../routes/index.js';

const addMasterSupplierFormId = 'addMasterSupplierFormId';

export function AddMasterSupplierPage() {
  return (
    <>
      <FormPageHeader
        title="Create New Master Supplier"
        formId={addMasterSupplierFormId}
        redirectUrl={routesNames.ACCOUNT_INDEX}
      />
      <DefaultBodyWrapper>
        <AddMasterSupplierForm
          masterSupplierRootUrl={routesNames.MASTER_SUPPLIER}
          supplierRootUrl={routesNames.SUPPLIER}
          supplierSource={SupplierSources.AAC}
          initialValues={{
            type: supplierTypes.SUBCONTRACTOR,
            foodBuy: { vaPercentage: 0 },
          }}
          formId={addMasterSupplierFormId}
          isSupplierTypeEditable
        />
      </DefaultBodyWrapper>
    </>
  );
}
