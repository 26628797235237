import * as R from 'ramda';
import styled from 'styled-components';
import { string } from 'prop-types';
import { getThemeColor, PageHeaderContainer } from 'poly-book-admin';
import { FlexColumn } from 'poly-admin-ui';
import React, { useState } from 'react';
import {
  useClearQueryParams,
  useRouterQuery,
  useUpdateQueryParams,
} from 'poly-client-routing';
import {
  ReconciliationPageBody,
  sectionConfigShape,
} from './ReconciliationPageBody.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { BankRegistersSearchInput } from '../BankRegistersPage/BankRegistersSearchInput.js';

const HeaderText = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: ${getThemeColor(['darkest'])};
`;

const PageContainer = styled(FlexColumn)`
  height: calc(100% - 60px);
  align-items: center;
  background: ${getThemeColor(['lightest'])};
`;

const PageHeaderContainerS = styled(PageHeaderContainer)`
  justify-content: flex-start;
`;

export function BankReconciliationPageRedesignedBase({
  accountTypeLabel,
  systemAccountType,
  paymentsSection,
  depositsSection,
  confirmationMessage,
}) {
  const updateQueryParams = useUpdateQueryParams();
  const onReset = useClearQueryParams();
  const { accountId, searchTerm = '' } = useRouterQuery([
    'accountId',
    'searchTerm',
  ]);
  const [query, setQuery] = useState({ accountId, searchTerm });

  const onAccountIdChange = (value) => {
    const updatedParams = { accountId: value };
    if (value) {
      setQuery(updatedParams);
      updateQueryParams(updatedParams);
    } else {
      setQuery({});
      onReset();
    }
  };

  const onSearchTermChange = (value) => {
    const updatedParams = { ...query, searchTerm: value };
    setQuery(updatedParams);
  };

  return (
    <>
      <PageHeaderContainerS width="1150px" height="60px" padding="0">
        <HeaderText>{accountTypeLabel} Account Reconciliation</HeaderText>
        <AccountsSelect
          isClearable
          width="200px"
          value={query.accountId}
          onChange={onAccountIdChange}
          extractValue={R.prop('_id')}
          placeholder={`Select ${accountTypeLabel} Name`}
          filterAccounts={filterAccountsBySystemType(systemAccountType)}
        />
        <BankRegistersSearchInput
          name="search_term"
          value={query.searchTerm}
          onChange={onSearchTermChange}
        />
      </PageHeaderContainerS>
      <PageContainer>
        <ReconciliationPageBody
          paymentsSection={paymentsSection}
          depositsSection={depositsSection}
          query={query}
          confirmationMessage={confirmationMessage}
        />
      </PageContainer>
    </>
  );
}

BankReconciliationPageRedesignedBase.propTypes = {
  accountTypeLabel: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  systemAccountType: string.isRequired,
  paymentsSection: sectionConfigShape,
  depositsSection: sectionConfigShape,
  confirmationMessage: string.isRequired,
};
