import React from 'react';
import { func } from 'prop-types';
import { CommonSidebarFormWidth, entities } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';

import { EditAssetForm } from './EditAssetForm.js';
import { editAssetFormId } from '../../constants.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';

function EditAssetSidebarContent({ onClose, ...formProps }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Edit Asset"
      formId={editAssetFormId}
      submitButtonCaption="Save"
    >
      <EditAssetForm {...formProps} />
    </SidebarFormLayout>
  );
}

EditAssetSidebarContent.propTypes = { onClose: func.isRequired };

export const useEditAssetSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();
  const onClose = useOnCloseFormSidebar();

  return (asset) =>
    openOutSidebar({
      alwaysFirst: true,
      id: editAssetFormId,
      width: CommonSidebarFormWidth,
      content: (
        <EditAssetSidebarContent
          asset={asset}
          onClose={() => onClose(entities.ASSET, asset._id)}
        />
      ),
    });
};
