import React from 'react';
import * as R from 'ramda';
import { element, string } from 'prop-types';
import { documentUrlGenerator, Link } from 'poly-client-routing';
import {
  RecurringProjectsSearchResult,
  MasterSuppliersSearchResult,
  PropertiesSearchResult,
  SuppliersSearchResult,
  UsersSearchResultItem,
  FilesSearchResultItem,
  ProjectsSearchResult,
  ClientsSearchResult,
  AssetsSearchResult,
} from 'poly-admin-ui';

import { routesNames } from '../../../routes/constants.js';
import { AssetLink } from '../../../sidebars/AssetSidebar/useOpenAssetSidebar.js';
import { ContactLink } from '../../../sidebars/ContactSidebar/useOpenContactSidebar.js';

// getGlobalSearchLinkByRoute :: (Route, Boolean) -> _ -> ReactElement
const getGlobalSearchLinkByRoute = (route, isProject) =>
  function (props) {
    const documentUrlGeneratorByProps = documentUrlGenerator(
      R.prop(isProject ? 'projectId' : '_id'),
    );

    const linkView = R.prop('children', props);

    return (
      <Link href={documentUrlGeneratorByProps(route)(props)}>{linkView}</Link>
    );
  };

function FileSidebarLink({ url, children }) {
  return (
    <a href={url} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
}

FileSidebarLink.propTypes = {
  url: string,
  children: element,
};

function ContactSidebarLink(props) {
  return <ContactLink {...props} alwaysFirst />;
}

export const globalSearchTabsConfig = [
  {
    index: 'searchUsers',
    title: 'Contacts',
    Component: UsersSearchResultItem,
    highlightPath: [['fullName'], ['email']],
    LinkComponent: ContactSidebarLink,
  },
  {
    index: 'searchFiles',
    title: 'Files',
    Component: FilesSearchResultItem,
    highlightPath: [['fileName']],
    LinkComponent: FileSidebarLink,
  },
  {
    index: 'searchClients',
    title: 'Clients',
    Component: ClientsSearchResult,
    highlightPath: [['name'], ['address', 'formatted_address']],
    LinkComponent: getGlobalSearchLinkByRoute(routesNames.CLIENT_CARD),
  },
  {
    index: 'searchProperties',
    title: 'Properties',
    Component: PropertiesSearchResult,
    highlightPath: [['name'], ['address', 'formatted_address']],
    LinkComponent: getGlobalSearchLinkByRoute(routesNames.PROPERTY_CARD),
  },
  {
    index: 'searchSuppliers',
    title: 'Suppliers',
    Component: SuppliersSearchResult,
    highlightPath: [
      ['company', 'name'],
      ['company', 'dba'],
      ['remit', 'name'],
      ['tax', 'name'],
      ['company', 'address', 'formatted_address'],
    ],
    LinkComponent: getGlobalSearchLinkByRoute(routesNames.SUPPLIER),
  },
  {
    index: 'searchMasterSuppliers',
    title: 'Master Suppliers',
    Component: MasterSuppliersSearchResult,
    highlightPath: [
      ['company', 'name'],
      ['company', 'dba'],
      ['tax', 'name'],
      ['remit', 'name'],
      ['company', 'address', 'formatted_address'],
    ],
    LinkComponent: getGlobalSearchLinkByRoute(routesNames.MASTER_SUPPLIER),
  },
  {
    index: 'searchAssets',
    title: 'Assets',
    Component: AssetsSearchResult,
    highlightPath: [
      ['description'],
      ['equipmentType'],
      ['manufacturerDoc', 'name'],
      ['qrCodeId'],
      ['name'],
    ],
    LinkComponent: AssetLink,
  },
  {
    index: 'searchProjects',
    title: 'Projects',
    Component: ProjectsSearchResult,
    highlightPath: [
      ['description'],
      ['client', 'name'],
      ['property', 'name'],
      ['projectId'],
    ],
    LinkComponent: getGlobalSearchLinkByRoute(routesNames.PROJECT_CARD, true),
  },
  {
    index: 'searchRecurringProjects',
    title: 'Recurring Projects',
    Component: RecurringProjectsSearchResult,
    highlightPath: [
      ['description'],
      ['projectId'],
      ['property', 'name'],
      ['client', 'name'],
    ],
    LinkComponent: getGlobalSearchLinkByRoute(
      routesNames.MASTER_PROJECT_CARD,
      true,
    ),
  },
];
