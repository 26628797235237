import React from 'react';
import { string } from 'prop-types';
import { entityToOptionByLabelPath } from 'poly-client-utils';

import {
  USERS_BY_SEARCH,
  DEFAULT_USER_QUERY,
} from '../../../../../hocs/users/queries.js';
import { projectContactsByClient } from '../../../../../searchQueries/clients.js';
import { AsyncSearchSelect } from '../../multiSearchSelect/createAsyncSearchSelect.js';

export function ProjectContactsByClientSelect({
  clientId,
  placeholder,
  ...props
}) {
  const searchSelectProps = {
    isClearable: true,
    valueEndpointName: 'user',
    gqlOptionsQuery: USERS_BY_SEARCH,
    optionsEndpointName: 'searchUsers',
    gqlValueQuery: DEFAULT_USER_QUERY,
    searchQuery: projectContactsByClient(clientId),
    placeholder: placeholder || 'Start typing contacts',
    hitToOption: entityToOptionByLabelPath(['fullName']),
    ...props,
  };

  return <AsyncSearchSelect {...searchSelectProps} />;
}

ProjectContactsByClientSelect.propTypes = {
  placeholder: string,
  clientId: string.isRequired,
};
