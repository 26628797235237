import * as R from 'ramda';
import React from 'react';
import { format, isValid } from 'date-fns';
import { DatePicker } from 'poly-book-admin';
import { shape, bool, func } from 'prop-types';
import { MONDAY_DAY_NUMBER } from '../constants.js';
import { isOutsideOfCurrentPayWeek } from '../helpers/dates.js';
import { changeFormEntries } from '../helpers/mutators.js';

// formatTimesheetDate :: Date -> String
export const formatTimesheetDate = R.compose(
  R.ifElse(isValid, (date) => format(date, 'EEE, MM/dd/yyyy'), R.always('')),
  R.constructN(1, Date),
);

export function TimesheetDatePicker({
  formData: { isEdit, type, timeSheets },
  changeFieldValue,
  onChange,
  ...restProps
}) {
  const modifiers = { disabled: isOutsideOfCurrentPayWeek };

  const onChangeHandler = (value) => {
    onChange(value);
    changeFormEntries({ type, date: value }, timeSheets, changeFieldValue);
  };

  return (
    <DatePicker
      {...restProps}
      width="100%"
      formatDate={formatTimesheetDate}
      firstDayOfWeek={MONDAY_DAY_NUMBER}
      modifiers={modifiers}
      disabled={!!isEdit}
      leftMove="-10px"
      onChange={onChangeHandler}
    />
  );
}

TimesheetDatePicker.displayName = 'DayOfWeekPicker';

TimesheetDatePicker.propTypes = {
  formData: shape({
    isEdit: bool,
  }),
  changeFieldValue: func,
  onChange: func,
};
