import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import {
  isNilOrEmpty,
  convertCentsToDollars,
  convertDollarsToCents,
} from 'poly-utils';

import {
  paymentTypeOptions,
  EDITABLE_PAYMENTS_SELECTOR_PROP,
} from '../constants.js';
import {
  setAllEditablePayments,
  setUpdatedEditablePayment,
} from '../../../redux/editablePayments.js';

// getPreviousPayments :: [EditablePaymentRows] -> [EditablePaymentRows]
const getPreviousPayments = ({ transactionNumber, clientInvoiceNumber }) =>
  R.filter(
    R.both(
      R.propEq('clientInvoiceNumber', clientInvoiceNumber),
      R.propSatisfies(R.gt(transactionNumber), 'transactionNumber'),
    ),
  );

// getPreviousPaymentsTotals :: [EditablePaymentRows] -> [Float]
const getPreviousPaymentsTotals = R.juxt([
  R.compose(R.sum, R.map(R.propOr(0, 'receivedAmount'))),
  R.compose(R.sum, R.map(R.propOr(0, 'deductionAmount'))),
]);

// getBalance :: EditablePaymentRows -> [Float] -> Float
const getBalance = ({ clientInvoiceAmount = 0 }) =>
  R.compose(
    convertCentsToDollars,
    R.subtract(convertDollarsToCents(clientInvoiceAmount)),
    R.sum,
    R.map(convertDollarsToCents),
  );

// getDueAmount :: EditablePaymentRows -> [EditablePaymentRows] -> Float
const getDueAmount = ({
  receivedAmount = 0,
  deductionAmount = 0,
  ...currentPayment
}) =>
  R.compose(
    getBalance(currentPayment),
    R.concat([receivedAmount, deductionAmount]),
    getPreviousPaymentsTotals,
    getPreviousPayments(currentPayment),
  );

// getCurrentBalance :: EditablePaymentRows -> [EditablePaymentRows] -> Float
const getCurrentBalance = (currentPayment) =>
  R.compose(
    getBalance(currentPayment),
    getPreviousPaymentsTotals,
    getPreviousPayments(currentPayment),
  );

// isDeductionGLExists :: EditablePaymentRows -> Boolean
const isDeductionGLExists = R.propSatisfies(
  R.both(R.is(String), R.complement(R.isEmpty)),
  'deductionGL',
);

// isCurrentBalanceLessThanDeduction :: EditablePaymentRows -> Boolean
const isCurrentBalanceLessThanDeduction = R.ifElse(
  R.propSatisfies(R.gt(R.__, 0), 'currentBalance'),
  R.converge(R.gt, [
    R.propOr(0, 'deductionAmount'),
    R.propOr(0, 'currentBalance'),
  ]),
  R.converge(R.lt, [
    R.propOr(0, 'deductionAmount'),
    R.propOr(0, 'currentBalance'),
  ]),
);

// validateDeductionAmount :: EditablePaymentRows -> String
const validateDeductionAmount = R.cond([
  [
    R.both(
      isDeductionGLExists,
      R.propSatisfies(R.either(R.isNil, R.equals(0)), 'deductionAmount'),
    ),
    R.always('Deductions is required'),
  ],
  [
    R.both(
      R.propSatisfies(R.both(R.is(Number), R.gt(R.__, 0)), 'deductionAmount'),
      isCurrentBalanceLessThanDeduction,
    ),
    R.always('Deductions can`t exceed balance'),
  ],
  [R.T, R.always('')],
]);

// validateDeductionGL :: EditablePaymentRows -> String
const validateDeductionGL = R.ifElse(
  R.both(
    R.propSatisfies(isNilOrEmpty, 'deductionGL'),
    R.propSatisfies(R.both(R.is(String), R.gt(R.__, 0)), 'deductionAmount'),
  ),
  R.always('Gl Disc Code is required'),
  R.always(''),
);

// validateReceivedAmount :: EditablePaymentRows -> String
const validateReceivedAmount = R.cond([
  [
    R.propSatisfies(R.either(isNilOrEmpty, R.equals(0)), 'receivedAmount'),
    R.always('Payment is required'),
  ],
  [
    R.ifElse(
      R.propSatisfies(R.gt(R.__, 0), 'currentBalance'),
      R.propSatisfies(R.lt(R.__, 0), 'receivedAmount'),
      R.propSatisfies(R.gt(R.__, 0), 'receivedAmount'),
    ),
    R.always('Invalid payment amount'),
  ],
  [
    R.ifElse(
      R.propSatisfies(R.gt(R.__, 0), 'currentBalance'),
      R.converge(R.gt, [R.prop('receivedAmount'), R.prop('currentBalance')]),
      R.converge(R.lt, [R.prop('receivedAmount'), R.prop('currentBalance')]),
    ),
    R.always('Payment can`t exceed balance'),
  ],
  [R.T, R.always('')],
]);

// validateDueAmount :: EditablePaymentRows -> String
const validateDueAmount = R.cond([
  [
    R.both(
      R.propSatisfies(R.gt(R.__, 0), 'currentBalance'),
      R.propSatisfies(R.lt(R.__, 0), 'dueAmount'),
    ),
    R.always('Balance can`t be negative'),
  ],
  [
    R.both(
      R.propSatisfies(R.lt(R.__, 0), 'currentBalance'),
      R.propSatisfies(R.gt(R.__, 0), 'dueAmount'),
    ),
    R.always('Balance can`t exceed zero'),
  ],
  [R.T, R.always('')],
]);

// validateEditablePayment :: EditablePaymentRows -> RowError
// RowError = {
//    deductionAmount: String
//    deductionGL: String
//    receivedAmount: String
//    dueAmount: String
// }
const validateEditablePayment = R.compose(
  R.reject(R.isEmpty),
  R.applySpec({
    deductionAmount: validateDeductionAmount,
    deductionGL: validateDeductionGL,
    receivedAmount: validateReceivedAmount,
    dueAmount: validateDueAmount,
  }),
);

// isAllPaymentsSelected :: [EditablePaymentRows] -> Boolean
const isAllPaymentsSelected = R.ifElse(
  R.isEmpty,
  R.F,
  R.compose(R.isEmpty, R.reject(R.prop('isSelected'))),
);

// preparePaymentType :: { dueAmount: Float } -> String
const preparePaymentType = R.ifElse(
  R.propEq('dueAmount', 0),
  R.always(paymentTypeOptions.FINAL),
  R.always(paymentTypeOptions.PART),
);

export const useEditablePaymentsHandlers = (submitting) => {
  const dispatch = useDispatch();
  const editablePayments = useSelector(R.prop(EDITABLE_PAYMENTS_SELECTOR_PROP));

  const isAllSelected = isAllPaymentsSelected(editablePayments);

  const onSelectAllPayment = () => {
    let isSelected = true;

    if (isAllSelected) isSelected = false;

    dispatch(
      setAllEditablePayments(
        editablePayments.map((editablePayment) => ({
          ...editablePayment,
          isSelected,
        })),
      ),
    );
  };

  const rows = editablePayments.map((editablePayment, index) => {
    const onSelectPayment = () =>
      dispatch(
        setUpdatedEditablePayment({
          index,
          item: {
            ...editablePayment,
            isSelected: !editablePayment.isSelected,
          },
        }),
      );

    const onChangeRowField = (field) => (value) => {
      const changedEditablePayment = {
        ...editablePayment,
        [field]: value,
      };
      const item = {
        ...changedEditablePayment,
        dueAmount: getDueAmount(changedEditablePayment)(editablePayments),
        currentBalance: getCurrentBalance(changedEditablePayment)(
          editablePayments,
        ),
      };

      dispatch(setUpdatedEditablePayment({ index, item }));
    };

    const onDeductionChange = onChangeRowField('deductionAmount');
    const onDeductionGLChange = onChangeRowField('deductionGL');
    const onAmountReceivedChange = onChangeRowField('receivedAmount');
    const onRefNumberChange = onChangeRowField('refNumber');
    const onDepositAccountChange = onChangeRowField('depositAccount');
    const onReceivedDateChange = onChangeRowField('receivedDate');

    const newEditablePayment = {
      ...editablePayment,
      dueAmount: getDueAmount(editablePayment)(editablePayments),
      currentBalance: getCurrentBalance(editablePayment)(editablePayments),
    };

    return {
      ...newEditablePayment,
      paymentType: preparePaymentType(newEditablePayment),
      submitting,
      errors: validateEditablePayment(newEditablePayment),

      onSelectPayment,
      onDeductionChange,
      onDeductionGLChange,
      onAmountReceivedChange,
      onRefNumberChange,
      onDepositAccountChange,
      onReceivedDateChange,
    };
  });

  return { rows, isAllSelected, onSelectAllPayment };
};
