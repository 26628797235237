import * as R from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { gql, useMutation } from '@apollo/client';
import { usePristineSubscribe } from 'poly-client-routing';
import { openPrintWindowWithData } from 'poly-client-utils';
import { alwaysNewDate } from 'poly-utils';
import { FormPage } from 'poly-book-admin';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
  CommonPrintLayout,
} from 'poly-admin-ui';

import {
  JournalOccurrence,
  createJournalEntryFormId,
  createJournalEntryInitialValues,
} from '../constants.js';
import { journalEntryFromSections } from './journalEntryFormSections.js';
import { JournalEntryReportTablePDF } from '../JournalEntryReportTablePDF.js';
import {
  formatRecurringJournalEntryMutationData,
  formatJournalEntryMutationData,
  validateJournalEntryForm,
} from '../journalEntryUtils.js';

export const createJournalEntryMutation = gql`
  mutation createJournalEntryMutation($input: JournalEntryInput!) {
    createJournalEntry(input: $input) {
      _id
      transaction_number
    }
  }
`;

const createRecurringJournalEntryMutation = gql`
  mutation createRecurringJournalEntryMutation(
    $input: CreateRecurringJournalInput!
  ) {
    createRecurringJournal(input: $input) {
      _id
    }
  }
`;

// commonJournalEntryFormProps :: FormProps
const commonJournalEntryFormProps = {
  validateOnBlur: true,
  initialValuesEqual: R.T,
  resetFormOnSubmit: true,
  mutators: { ...arrayMutators },
  sections: journalEntryFromSections,
  validate: validateJournalEntryForm,
};

export function CreateJournalEntryForm() {
  const { showSuccessNotification } = useNotificationState();
  const [createJournalEntry] = useMutation(createJournalEntryMutation);
  const [createRecurringJournalEntry] = useMutation(
    createRecurringJournalEntryMutation,
  );

  const journalEntry = useSelector((store) => store.journalEntry);
  const isPrint =
    !!journalEntry &&
    !!journalEntry.showPrint &&
    !!journalEntry.printJournalEntry;

  const [leaveFormDirty, setLeaveFormDirty] = useState(false);

  const printJournalEntry = async (data) => {
    await openPrintWindowWithData({
      data,
      Layout: CommonPrintLayout,
      fileName: 'journal_entry_report',
      Table: JournalEntryReportTablePDF,
      metaData: { title: 'Journal Entry Report' },
    });
  };

  const onSubmitHandler = async (formData) => {
    const formElement = document.getElementById(createJournalEntryFormId);
    const isDirty = formElement.hasAttribute('dirty');

    setLeaveFormDirty(isDirty);
    formElement.removeAttribute('dirty');

    const isRecurringJournal =
      formData.occurrence === JournalOccurrence.RECURRING_OCCURRENCE;

    const mutate = isRecurringJournal
      ? createRecurringJournalEntry
      : createJournalEntry;

    const mutationData = isRecurringJournal
      ? formatRecurringJournalEntryMutationData(formData)
      : formatJournalEntryMutationData(formData);

    const response = await mutate({ variables: { input: mutationData } });

    if (!isRecurringJournal && isPrint) {
      const additionalData = R.path(['data', 'createJournalEntry'], response);
      await printJournalEntry({ ...formData, ...additionalData });
    }

    showSuccessNotification('Journal Entry has successfully posted');
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    createJournalEntryFormId,
    onSubmitHandler,
  );

  const pristineSubscribeProps = usePristineSubscribe();

  const formProps = {
    onSubmit,
    id: createJournalEntryFormId,
    keepDirtyOnReinitialize: leaveFormDirty,
    initialValues: createJournalEntryInitialValues(alwaysNewDate()),
    ...commonJournalEntryFormProps,
    ...pristineSubscribeProps,
  };

  return <FormPage {...formProps} />;
}
