import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { WindowedTable } from 'poly-book-admin/src/Table/WindowedTable.js';
import { useMapConfigToTableProps } from 'poly-admin-ui/src/hooks/useMapConfigToTableProps.js';
import {
  keywordSortQuery,
  commonSortQuery,
} from 'poly-client-utils/src/sorting.js';
import { TableCard } from 'poly-book-admin/src/Card.js';
import { ThreeDotsPopover } from 'poly-admin-ui/src/sidebar/sidebarComponents.js';
import { PopoverLink } from 'poly-book-admin/src/Popover/index.js';
import { useNotificationContext } from 'poly-book-admin/src/Notificator/NotificatorProvider.js';
import { useModalContext } from 'poly-admin-ui/src/components/Modal/ModalProvider.js';

import { getTaskPriorityUI } from '../MyTasksPage/MyTasksTable.js';
import { useUpdateTaskTemplateSidebar } from './sidebar/useUpdateTaskTemplateSidebar.js';
import { useCreateTaskTemplateSidebar } from './sidebar/useCreateTaskTemplateSidebar.js';

const TaskTemplatesTableS = styled(WindowedTable)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 300px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 100px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    width: 150px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 50px;
  }
`;

const DescriptionS = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

function TaskTemplateDescription({ description }) {
  return <DescriptionS>{description}</DescriptionS>;
}

TaskTemplateDescription.propTypes = {
  description: string.isRequired,
};

const PopoverLinkS = styled(PopoverLink)`
  border-bottom: ${(border) => (border ? '1px solid #ebebeb' : 'none')};
`;

const removeTaskTemplateMutation = gql`
  mutation removeTaskTemplate($id: ID!) {
    removeTaskTemplate(id: $id)
  }
`;

function TaskTemplateActionsBtn({ _id }) {
  const { showSuccessNotification } = useNotificationContext();
  const { openConfirmModal } = useModalContext();
  const [removeTaskTaskTemplate] = useMutation(removeTaskTemplateMutation);

  const openUpdateTaskTemplateSidebar = useUpdateTaskTemplateSidebar();

  const openCreateTaskTemplateSidebar = useCreateTaskTemplateSidebar();

  const handleRemove = async () => {
    const confirmModalProps = {
      cancelBtnCaption: 'Cancel',
      btnCaption: 'Delete',
      id: 'removeTaskTemplate',
      content: 'Are you sure you want to delete this task template?',
      title: 'Delete task template',
      iconName: null,
      textStyleProps: {
        justify: true,
      },
    };

    openConfirmModal({
      ...confirmModalProps,
      onConfirm: (closeConfirmModal) => async () => {
        await removeTaskTaskTemplate({ variables: { id: _id } });
        closeConfirmModal();
        showSuccessNotification('The task template was successfully deleted');
      },
    });
  };

  const handleUpdate = () => openUpdateTaskTemplateSidebar(_id);

  const handleDuplicate = () => openCreateTaskTemplateSidebar(_id);

  return (
    <ThreeDotsPopover
      content={
        <>
          <PopoverLinkS onClick={handleUpdate} border>
            Edit
          </PopoverLinkS>
          <PopoverLinkS onClick={handleDuplicate} border>
            Duplicate
          </PopoverLinkS>
          <PopoverLinkS onClick={handleRemove}>Delete</PopoverLinkS>
        </>
      }
    />
  );
}

TaskTemplateActionsBtn.propTypes = {
  _id: string.isRequired,
};

export const taskTemplatesTableConfig = [
  ['Task', R.prop('name'), keywordSortQuery(['name'])],
  ['Priority', getTaskPriorityUI, commonSortQuery(['priority'])],
  ['Description', TaskTemplateDescription, keywordSortQuery(['description'])],
  ['Created', R.path(['createdBy', 'fullName'])],
  ['', TaskTemplateActionsBtn],
];

export function TaskTemplatesTable({ taskTemplates, ...tableProps }) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    taskTemplatesTableConfig,
    taskTemplates,
  );

  return (
    <TableCard>
      <TaskTemplatesTableS
        headers={headers}
        rows={rows}
        columns={columns}
        sortQueries={sortQueries}
        {...tableProps}
      />
    </TableCard>
  );
}

TaskTemplatesTable.propTypes = {
  taskTemplates: arrayOf(
    shape({
      name: string.isRequired,
      priority: string.isRequired,
      description: string.isRequired,
    }),
  ),
};
