import React from 'react';
import { string } from 'prop-types';
import { entities, SidebarTasksTab } from 'poly-admin-ui';
import { taskCollections } from 'poly-constants';

export function MasterRecurringProjectSidebarTasksTab({ _id, projectId }) {
  const tasksProps = {
    entity: {
      _id,
      preview: 'project',
      humanReadableId: projectId,
      name: entities.RECURRING_PROJECT,
    },
    collection: taskCollections.RECURRING_PROJECTS,
  };

  return <SidebarTasksTab {...tasksProps} />;
}

MasterRecurringProjectSidebarTasksTab.propTypes = {
  _id: string.isRequired,
  projectId: string.isRequired,
};
