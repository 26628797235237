import React from 'react';
import { func, string } from 'prop-types';
import { entities } from 'poly-admin-ui';
import { useOutSidebarContext } from 'poly-client-utils';
import { useUpdateQueryParams } from 'poly-client-routing';
import {
  clientSidebarTabs,
  propertySidebarTabs,
} from '../../../../routes/constants.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { useOnCloseFormSidebar } from '../../../useOnCloseFormSidebar.js';
import { ImportAssetsForm } from './ImportAssetsForm.js';
import { importAssetsFormId } from '../../constants.js';
import { CustomSidebarLayout } from '../../../components/CustomSidebarLayout.js';

function ImportAssetSidebarContent({ onClose, propertyId, clientId }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Import Asset"
      formId={importAssetsFormId}
      submitButtonCaption="Import"
    >
      <ImportAssetsForm
        propertyId={propertyId}
        clientId={clientId}
        onCancel={onClose}
      />
    </SidebarFormLayout>
  );
}

ImportAssetSidebarContent.propTypes = {
  onClose: func.isRequired,
  clientId: string,
  propertyId: string,
};

export const useOnImportAssetsSidebar = () => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();
  const updateQueryParams = useUpdateQueryParams();
  const onClose = useOnCloseFormSidebar();

  const onCloseHandler = (propertyId) => () => {
    closeOutSidebar(importAssetsFormId);

    updateQueryParams({ sidebarTab: undefined });

    setTimeout(() => {
      const sidebarTab = propertyId
        ? propertySidebarTabs.propertyAssets
        : clientSidebarTabs.clientProperties;

      updateQueryParams({ sidebarTab });
    }, 100);

    if (propertyId) {
      onClose(entities.PROPERTY, propertyId);
    }
  };

  return ({ propertyId, clientId }) =>
    openOutSidebar({
      id: importAssetsFormId,
      Layout: CustomSidebarLayout,
      isCustom: true,
      width: 600,
      content: (
        <ImportAssetSidebarContent
          clientId={clientId}
          propertyId={propertyId}
          onClose={onCloseHandler(propertyId)}
        />
      ),
    });
};
