import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string, number } from 'prop-types';
import { pathOrNothingUI, formatDateOrNothingUI } from 'poly-client-utils';
import {
  centsToDollarsWithFormat,
  formatCurrencyForViewPath,
} from 'poly-utils';
import { NOTHING_UI_STRING } from 'poly-constants';
import { SidebarRow } from 'poly-admin-ui';
import { Text } from 'poly-book-admin';

import {
  SectionLabel,
  BlockWithLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';
import { ApproveNewEquipmentPopup } from '../../components/ApproveNewEquipmentPopup/ApproveNewEquipmentPopup.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import { AssetLifeExpectancyWarning } from './AssetLifeExpectancyWarning.js';
import {
  getAssetLifeExpectancy,
  getMonthsFromCommissioningDate,
} from './utils.js';
import { SupplierPopoverLink } from '../ProjectSidebar/ProjectSidebarSuppliers.js';

// formatWarrantyEndDate :: String -> Asset -> String
const formatAssetDateByProp = (propName) =>
  R.compose(formatDateOrNothingUI, R.prop(propName));

const BlockContainer = styled.div`
  margin-top: ${({ withMargin }) => withMargin && '30px'};
  margin-right: 30px;
  width: calc(50% - 30px);
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
`;

// getEquipmentByPaths :: ([String], [String]) -> Asset -> String
const getEquipmentByPaths = (newEquipPath, docPath) =>
  R.ifElse(
    R.path(newEquipPath),
    pathOrNothingUI(newEquipPath),
    pathOrNothingUI(docPath),
  );

const getAssetLifeExpectancyOrNothingUI = R.compose(
  R.defaultTo(NOTHING_UI_STRING),
  getAssetLifeExpectancy,
);

const TextS = styled(Text)`
  font-size: 12px;
`;
// getYearsByMonth :: Number -> Number
const getYearsByMonth = R.compose(Math.floor, R.divide(R.__, 12));

// getRestOfMonthForNoFullYear :: Number -> Number
const getRestOfMonthForNoFullYear = R.compose(Math.floor, R.mathMod(R.__, 12));

// getAssetReplacementCost :: Asset -> String
const getAssetReplacementCost = R.compose(
  centsToDollarsWithFormat,
  R.prop('replacementCost'),
);

function getAssetCommissionDateComp(asset) {
  const monthsLife = getMonthsFromCommissioningDate(asset);

  return function () {
    return (
      <FlexContainer>
        <AssetLifeExpectancyWarning asset={asset} position="left" />
        <TextS>{formatAssetDateByProp('commissioningDate')(asset)}</TextS>
        {monthsLife !== null && (
          <TextS>{`(${getYearsByMonth(
            monthsLife,
          )} years ${getRestOfMonthForNoFullYear(monthsLife)} months)`}</TextS>
        )}
      </FlexContainer>
    );
  };
}

export function AssetSidebarDetails({ asset }) {
  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Asset Details</SectionLabel>
      </SidebarRow>
      <SidebarRow justify wrap="true">
        {asset.name && (
          <BlockWithLabel
            width="calc(50% - 30px)"
            id="display-name"
            label="Display Name"
            Component={getSectionText(pathOrNothingUI(['name'])(asset))}
          />
        )}
        {asset.qrCodeId && (
          <BlockWithLabel
            width="calc(50% - 30px)"
            id="poly-asset-code"
            label="Poly Asset Code"
            Component={getSectionText(pathOrNothingUI(['qrCodeId'])(asset))}
          />
        )}
        <BlockContainer withMargin={asset.qrCodeId && asset.name}>
          <BlockWithLabel
            id="client-asset-code"
            label="Client Asset Code"
            Component={getSectionText(
              pathOrNothingUI(['equipmentType'])(asset),
            )}
          />
        </BlockContainer>
      </SidebarRow>
      <SidebarRow justify>
        <BlockWithLabel
          width="50%"
          id="manufacturer"
          label="Manufacturer"
          Component={getSectionText(
            getEquipmentByPaths(
              ['newManufacturerName'],
              ['manufacturerDoc', 'name'],
            )(asset),
          )}
          ActionBtn={
            asset?.newManufacturerName ? (
              <ApproveNewEquipmentPopup
                assetId={asset?._id}
                entity="manufacturer"
                equipmentName={asset?.newManufacturerName}
              />
            ) : null
          }
        />
        <BlockWithLabel
          width="50%"
          id="model"
          label="Model"
          Component={getSectionText(
            asset?.manufacturerDoc?._id || asset?.newManufacturerName
              ? getEquipmentByPaths(
                  ['newModelName'],
                  ['modelDoc', 'name'],
                )(asset)
              : NOTHING_UI_STRING,
          )}
          ActionBtn={
            asset?.newModelName && asset?.manufacturerDoc?._id ? (
              <ApproveNewEquipmentPopup
                entity="model"
                assetId={asset?._id}
                equipmentName={asset?.newModelName}
                manufacturerId={asset?.manufacturerDoc?._id}
              />
            ) : null
          }
        />
      </SidebarRow>
      <SidebarRow justify>
        <BlockWithLabel
          width="50%"
          id="serial"
          label="Serial Number"
          Component={getSectionText(pathOrNothingUI(['serial'])(asset))}
        />
        <BlockWithLabel
          width="50%"
          id="typeId"
          label="Asset Type"
          Component={getSectionText(pathOrNothingUI(['type', 'name'])(asset))}
        />
      </SidebarRow>
      <SidebarRow justify>
        <BlockWithLabel
          width="50%"
          id="location"
          label="Location"
          Component={getSectionText(pathOrNothingUI(['location'])(asset))}
        />
        <BlockWithLabel
          width="50%"
          id="warranty-end"
          label="Warranty Expiration"
          Component={getSectionText(
            formatAssetDateByProp('warrantyEnd')(asset),
          )}
        />
      </SidebarRow>
      <SidebarRow justify>
        <BlockWithLabel
          width="50%"
          id="location"
          label="Purchase Price"
          Component={getSectionText(
            formatCurrencyForViewPath(['purchasePrice'])(asset),
          )}
        />
        <BlockWithLabel
          width="50%"
          id="commissioning-date"
          label="Commissioning Date"
          Component={getAssetCommissionDateComp(asset)}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          width="50%"
          id="replacementCostId"
          label="Asset Replacement Cost"
          Component={getSectionText(getAssetReplacementCost(asset))}
        />
        <BlockWithLabel
          width="50%"
          id="defaultSupplierId"
          label="Default Supplier"
          Component={SupplierPopoverLink}
          {...(asset?.defaultSupplier || {})}
        />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          width="50%"
          id="lifeExpectancyId"
          label="Life Expectancy (years)"
          Component={getSectionText(getAssetLifeExpectancyOrNothingUI(asset))}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

AssetSidebarDetails.propTypes = {
  asset: shape({
    type: shape({
      _id: string,
      name: string,
    }),
    modelDoc: shape({
      _id: string,
      name: string,
    }),
    serial: string,
    qrCodeId: string,
    location: string,
    warrantyEnd: string,
    purchasePrice: number,
    equipmentType: string,
    commissioningDate: string,
    manufacturerDoc: shape({
      _id: string,
      name: string,
    }),
  }),
};
