import {
  bool,
  func,
  shape,
  string,
  number,
  arrayOf,
  instanceOf,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  FormField,
  getThemeColor,
  Input,
} from 'poly-book-admin';
import { alwaysNewDate, isNilOrEmpty } from 'poly-utils';
import { validateFormData } from 'poly-client-utils';
import { SystemAccountTypes, REF_NUMBER_LENGTH_LIMIT } from 'poly-constants';
import { useNavigate } from 'poly-client-routing';
import {
  useOnSubmitSetStopSubmitting,
  useNotificationState,
  useProcessState,
  FlexContainer,
  FlexColumn,
} from 'poly-admin-ui';

import {
  UnpinnedBottomPanelBody,
  unpinnedBottomPanelStyles,
} from '../../components/BottomPanel.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { invoiceClientPropTypes } from './receiveClientPaymentsFromPropTypes.js';
import { generatePaymentsInput } from './clientPaymentsHelpers.js';
import {
  calculateDeductionsTotal,
  calculatePaidTotal,
} from '../PaySuppliersPage/paySuppliersUtils/payInvoicesUtils.js';
import {
  checkIsAllPaymentsValid,
  validateRequiredField,
} from '../PaySuppliersPage/paySuppliersUtils/validationUtils.js';
import { DisabledMoneyInputAsCents } from '../../components/PaymentsFromComponents/BalanceInput.js';

const defaultValues = (currentDate) => ({
  date: currentDate,
});

const successPaymentsMsg = 'Payments successfully generated';

const PayClientInvoicesFormS = styled.form`
  ${unpinnedBottomPanelStyles};
  height: 150px;
`;

const FormWrapper = styled.td`
  width: 100%;
`;

const payClientInvoicesMutation = gql`
  mutation payClientInvoicesMutation($input: PayClientInvoicesInput!) {
    payClientInvoices(input: $input) {
      payments
    }
  }
`;

const payClientsFormId = 'payClientsFormId';

const FormInputsContainer = styled(FlexContainer)`
  & > div {
    margin-right: 15px;
  }
`;

const SubmitFormBtn = styled(Button)`
  background: ${getThemeColor(['secondaryRegular'])};
  margin-bottom: 5px;
`;

const commonInputProps = {
  labelProps: {
    color: 'midDark',
    size: 10,
  },
  width: '200px',
};

const FlexColumnS = styled(FlexColumn)`
  width: auto;
  justify-content: flex-end;
`;

const BottomFormContainer = styled(UnpinnedBottomPanelBody)`
  padding: 25px 0;
  flex-direction: row;
  align-items: flex-end;
`;

function PayClientInvoiceBottomFormRendered({
  values,
  handleSubmit,
  hasValidationErrors,
  changeField,
  deductionsTotal,
  paidTotal,
  isAllPaymentsValid,
  invoices,
  process,
  loading,
}) {
  const position = invoices.length < 6 ? 'bottom' : 'top';

  useEffect(() => {
    if (invoices && !loading && invoices.length === 0) {
      changeField('memo', '');
      changeField('accountId', '');
      changeField('date', alwaysNewDate());
    }
  }, [invoices]);
  return (
    <PayClientInvoicesFormS id={payClientsFormId} onSubmit={handleSubmit}>
      <BottomFormContainer>
        <FormInputsContainer>
          <FlexColumnS>
            <FormField
              name="refNumber"
              Component={Input}
              additionalProps={{
                label: 'Ref #',
                showCharactersLeft: true,
                charactersLimit: REF_NUMBER_LENGTH_LIMIT,
                ...commonInputProps,
              }}
            />
            <FormField
              name="memo"
              Component={Input}
              additionalProps={{
                label: 'Memo',
                ...commonInputProps,
              }}
            />
          </FlexColumnS>
          <FlexColumnS>
            <DisabledMoneyInputAsCents
              value={deductionsTotal}
              dataTestId="deductions-total-amount-input"
              name="deductionsAmount"
              label="Deductions"
              disabled
              allowNegative
              {...commonInputProps}
            />
          </FlexColumnS>
          <FlexColumnS>
            <DisabledMoneyInputAsCents
              value={paidTotal}
              name="totalAmount"
              dataTestId="total-amount-input"
              label="Received Amount"
              disabled
              allowNegative
              {...commonInputProps}
            />
          </FlexColumnS>
          <FlexColumnS>
            <FormField
              name="accountId"
              Component={AccountsSelect}
              validate={validateRequiredField('Account is required')}
              additionalProps={{
                paymentMode: values.paymentMode,
                direction: 'up',
                label: 'Deposit To',
                filterAccounts: filterAccountsBySystemType(
                  SystemAccountTypes.BANK_ACCOUNT,
                ),
                required: true,
                extractValue: R.prop('_id'),
                ...commonInputProps,
              }}
            />
          </FlexColumnS>
          <FlexColumnS>
            <FormField
              name="date"
              Component={DatePicker}
              validate={validateRequiredField('Date is required')}
              additionalProps={{
                label: 'Received Date',
                position,
                ...commonInputProps,
              }}
            />
          </FlexColumnS>
        </FormInputsContainer>
        <FlexColumnS>
          <SubmitFormBtn
            type="submit"
            size="tiny"
            data-testid="submit-pay-invoices-btn"
            form={payClientsFormId}
            disabled={hasValidationErrors || process || !isAllPaymentsValid}
            loader={process}
          >
            Post Payments
          </SubmitFormBtn>
        </FlexColumnS>
      </BottomFormContainer>
    </PayClientInvoicesFormS>
  );
}

PayClientInvoiceBottomFormRendered.propTypes = {
  values: shape({
    date: instanceOf(Date),
    memo: string,
    accountCode: string,
  }),
  handleSubmit: func.isRequired,
  hasValidationErrors: bool.isRequired,
  changeField: func.isRequired,
  deductionsTotal: number,
  paidTotal: number,
  isAllPaymentsValid: bool.isRequired,
  invoices: arrayOf(invoiceClientPropTypes),
  process: bool,
  loading: bool,
};

const validatePaySuppliersFormConfig = [
  {
    path: ['invoices'],
    validators: [
      [R.any(R.prop('isSelected')), 'Payments not selected'],
      [
        R.complement(
          R.both(
            R.compose(
              R.gt(R.__, 0),
              R.prop('length'),
              R.filter(R.prop('isSelected')),
            ),
            R.compose(
              R.any(R.propSatisfies(isNilOrEmpty, 'paidAmount')),
              R.filter(R.prop('isSelected')),
            ),
          ),
        ),
        'Payment amount is required',
      ],
    ],
  },
];

// validateClientPaymentsForm :: [InvoicePayment] -> FormValues -> Errors
const validateClientPaymentsForm = (invoices) =>
  R.compose(
    validateFormData(validatePaySuppliersFormConfig),
    R.mergeRight({ invoices }),
  );

// dissocPropIfEmpty :: String -> FormData -> FormData
const dissocPropIfEmpty = (prop) =>
  R.when(
    R.both(R.propIs(String, prop), R.compose(R.isEmpty, R.propOr('', prop))),
    R.dissoc(prop),
  );

// prepareFormData :: FormData -> FormData
const prepareFormData = R.compose(
  dissocPropIfEmpty('memo'),
  dissocPropIfEmpty('refNumber'),
);

export function ReceiveClientPaymentsBottomForm({ rows, loading }) {
  const invoices = useSelector(R.prop('invoices'));
  const [paidTotal, setPaidTotal] = useState(0);
  const [deductionsTotal, setDeductionsTotal] = useState(0);

  const [paySupplierInvoices] = useMutation(payClientInvoicesMutation);
  const { showSuccessNotification } = useNotificationState();
  const { process } = useProcessState(payClientsFormId);
  const navigate = useNavigate();

  useEffect(() => {
    setPaidTotal(calculatePaidTotal(invoices));
    setDeductionsTotal(calculateDeductionsTotal(invoices));
  }, [invoices]);

  const isAllPaymentsValid = checkIsAllPaymentsValid(rows);

  const submitHandler = async (values) => {
    await paySupplierInvoices({
      variables: {
        input: {
          ...prepareFormData(values),
          payments: generatePaymentsInput(invoices),
        },
      },
    });
    showSuccessNotification(successPaymentsMsg);
    navigate(0);
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    payClientsFormId,
    submitHandler,
  );

  return invoices.length === 0 ? null : (
    <tr>
      <FormWrapper>
        <Form
          onSubmit={onSubmit}
          initialValues={defaultValues(alwaysNewDate())}
          validate={validateClientPaymentsForm(invoices)}
          validateOnBlur={false}
          keepDirtyOnReinitialize
        >
          {({
            values,
            handleSubmit,
            errors,
            hasValidationErrors,
            form: { change },
          }) => (
            <PayClientInvoiceBottomFormRendered
              values={values}
              handleSubmit={handleSubmit}
              errors={errors}
              hasValidationErrors={hasValidationErrors}
              changeField={change}
              deductionsTotal={deductionsTotal}
              paidTotal={paidTotal}
              isAllPaymentsValid={isAllPaymentsValid}
              invoices={invoices}
              process={process}
              loading={loading}
            />
          )}
        </Form>
      </FormWrapper>
    </tr>
  );
}

ReceiveClientPaymentsBottomForm.propTypes = {
  loading: bool,
  rows: arrayOf(shape({ _id: string.isRequired })),
};
