import styled from 'styled-components';
import { Button, Input } from 'poly-book-admin';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const CancelBtn = styled(Button).attrs(() => ({
  size: 'small',
  styleType: 'basicSecondary',
  type: 'button',
  children: 'Cancel',
}))``;

export const SubmitBtn = styled(Button).attrs(() => ({
  size: 'small',
  type: 'submit',
}))``;

export const commonModalLayout = {
  overflow: 'visible',
  card: false,
};

export const halfWidth = 'calc(50% - 10px)';

export const thirdWidth = 'calc(33.33% - 7px)';

export const twoThirdWidth = 'calc(66.66% - 7px)';

export const quarterWidth = 'calc(25% - 20px)';

export const BasicInput = styled(Input)`
  width: 100%;
`;

/**
 *  Use it to set width for form input Component
 *  applyWidth :: Component -> InputSizes -> Component
 *  @example
 *        import { withWidth, inputSizes } from '.../formStyle';
 *        applyWidth(YourComponent, inputSizes.big)
 * */
export const applyWidth = (Component, width = '100%') => styled(Component)`
  width: ${width};
`;

export const modalFormLayout = {
  overflow: 'visible',
  card: false,
};
