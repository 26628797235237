import * as R from 'ramda';
import React, { useState } from 'react';
import { DefaultBodyWrapper, TableCardWithoutTabs } from 'poly-book-admin';

import styled from 'styled-components';
import { endOfWeek, startOfWeek } from 'date-fns';
import { ensureIsDate } from 'poly-utils';

import { useMyTimesheetsQuery } from '../../modules/core/hooks/timesheets/index.js';
import { TimesheetPageHeader } from './TimesheetPageHeader.js';
import { TimesheetsTable } from '../../modules/tables/timesheetsTable/index.js';

import { TimesheetSummaryWidget } from '../../modules/tables/timesheetsTable/TimesheetSummaryWidget.js';
import { timesheetWeekEdgeOptions } from '../../modules/forms/timesheetForm/constants.js';

const getWeekRangeByDate = (date) => ({
  date,
  startDate: startOfWeek(ensureIsDate(date), timesheetWeekEdgeOptions),
  endDate: endOfWeek(ensureIsDate(date), timesheetWeekEdgeOptions),
});

const BodyWrapperS = styled(DefaultBodyWrapper)`
  height: calc(100% - 115px);
`;

const TableCardWithoutTabsS = styled(TableCardWithoutTabs)`
  flex-direction: column;
  justify-content: space-between;
`;

// getMyTimesheetsWithDefault :: { myTimesheets: [Timesheet] } -> [Timesheet]
const getMyTimesheetsWithDefault = R.compose(
  R.defaultTo([]),
  R.prop('myTimesheets'),
);

function TimesheetPageC(props) {
  const pageProps = {
    ...props,
    myTimesheets: getMyTimesheetsWithDefault(props),
  };

  return (
    <>
      <TimesheetPageHeader {...pageProps} />
      <BodyWrapperS>
        <TableCardWithoutTabsS>
          <TimesheetsTable {...pageProps} />
          <TimesheetSummaryWidget {...pageProps} />
        </TableCardWithoutTabsS>
      </BodyWrapperS>
    </>
  );
}

export function TimesheetPage() {
  const [timesheetDateRange, setTimesheetDateRange] = useState(
    getWeekRangeByDate(new Date()),
  );

  const { data, loading } = useMyTimesheetsQuery({ timesheetDateRange });

  return (
    <TimesheetPageC
      {...data}
      loading={loading}
      timesheetDateRange={timesheetDateRange}
      setTimesheetDateRange={setTimesheetDateRange}
    />
  );
}
