import React from 'react';
import { ServiceTypeStatuses } from 'poly-constants';
import { useMutation } from '@apollo/client';
import { string } from 'prop-types';

import { CREATE_SERVICE_TYPE_MUTATION } from '../../../hocs/serviceTypes/mutations.js';
import { useCloseCurrentModal } from '../../../redux/modal.js';
import { ServiceTypeForm } from './form/ServiceTypeForm.js';

export function AddServiceTypeForm({ supplierSource, ...props }) {
  const [createServiceType] = useMutation(CREATE_SERVICE_TYPE_MUTATION);

  const closeCurrentModal = useCloseCurrentModal();
  const mutate = async ({ name, description, status }) => {
    await createServiceType({
      variables: {
        input: { name, status, description, supplierSource },
      },
    });
  };

  return (
    <ServiceTypeForm
      successMessage="Service type was created"
      initialValues={{ status: ServiceTypeStatuses.active }}
      onCancel={closeCurrentModal}
      mutate={mutate}
      {...props}
    />
  );
}

AddServiceTypeForm.propTypes = {
  supplierSource: string.isRequired,
};
