import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import { openPrintWindowWithData } from 'poly-client-utils';
import { CommonPrintLayout } from 'poly-admin-ui';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import { LinkButton } from 'poly-book-admin';
import { alwaysNewDate } from 'poly-utils';

import { MasterSupplierAPReportList } from './MasterSupplierAPReportList.js';

const SubTitleS = styled.p`
  font-size: 16px;
  margin-top: 10px;
`;

function MasterSupplierAPReportHeader() {
  return (
    <>
      <p>AP Outstanding Details</p>
      <SubTitleS>{`As of ${format(
        alwaysNewDate(),
        'MMMM dd, yyyy',
      )}`}</SubTitleS>
    </>
  );
}

export function PrintMasterSupplierAPReportBtn({
  masterSupplierName,
  listConfig,
}) {
  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: MasterSupplierAPReportList,
      fileName: 'ap_report',
      metaData: { title: <MasterSupplierAPReportHeader /> },
      masterSupplierName,
      isPrintPDF: true,
      listConfig,
    });
  return <LinkButton onClick={onClick}>{PRINT_PDF_CAPTION}</LinkButton>;
}

PrintMasterSupplierAPReportBtn.propTypes = {
  masterSupplierName: string,
  listConfig: arrayOf(
    shape({
      terms: string,
      cardNumber: string,
      supplierName: string,
      invoices: arrayOf(shape({ _id: string.isRequired })),
    }),
  ),
};
