import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Select, Loader, HeaderLinksWrapper } from 'poly-book-admin';
import * as R from 'ramda';
import styled from 'styled-components';
import { ALL, ClientSelect } from 'poly-admin-ui';
import { string, number, oneOfType } from 'prop-types';
import { AccountingMethods } from 'poly-constants';
import { useRouterQuery } from 'poly-client-routing';

import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
  SearchPageHeaderContainer,
} from '../../components/PageWithSearchHeader.js';
import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';
import { AccountingMethodSelect } from '../../components/AccountingMethodSelect.js';
import {
  formats,
  generateSalesReportsRows,
  getCurrentYear,
  getYearIntervalDate,
} from './clientSalesReportHelpers.js';
import { SalesReportsTable } from './ClientSalesReportTable.js';
import { ClientSalesReportPrintBtn } from './ClientSalesReportPrintBtn.js';
import { ClientSalesReportExportXlBtn } from './ClientSalesReportExportXLBtn.js';
import { salesReportRowsTypeProps } from './prop-types.js';

function YearSelector({ value, ...props }) {
  const currentYear = getCurrentYear();

  const selectProps = {
    options: [
      { value: currentYear - 1, label: 'Previous Year' },
      { value: currentYear, label: 'This Year' },
    ],
    value: +value,
    ...props,
  };

  return <Select {...selectProps} />;
}

YearSelector.propTypes = {
  value: oneOfType([number, string]).isRequired,
};

function FormatSelector({ value, ...props }) {
  const selectProps = {
    options: [
      { value: formats.sales, label: 'Sales' },
      { value: formats.wip, label: 'WIP' },
      { value: formats.total, label: 'Total' },
    ],
    value,
    ...props,
  };

  return <Select {...selectProps} />;
}

FormatSelector.propTypes = {
  value: string,
};

const clientSalesReportQuery = gql`
  query clientSalesReportQuery($input: ClientsSalesReportInput!) {
    clientsSalesReport(input: $input) {
      sales_reports {
        client {
          _id
          nickName
        }
        month_sales_reports {
          month
          sales_amount
          wip_amount
        }
      }
      wip_reports {
        wip_amount
        month
      }
    }
  }
`;

const HeaderLinksWrapperS = styled(HeaderLinksWrapper)`
  justify-content: flex-end;
  width: 100%;
`;

function ExportTablesContainerS({ salesReportRows, accountingMethod, year }) {
  return (
    <HeaderLinksWrapperS withoutMargin>
      <ClientSalesReportPrintBtn
        salesReportRows={salesReportRows}
        year={year}
        accountingMethod={accountingMethod}
      />
      <ClientSalesReportExportXlBtn
        salesReportRows={salesReportRows}
        year={year}
        accountingMethod={accountingMethod}
      />
    </HeaderLinksWrapperS>
  );
}

ExportTablesContainerS.propTypes = {
  salesReportRows: salesReportRowsTypeProps,
  accountingMethod: string,
  year: number,
};

const searchHeaderConfig = (salesReportRows, year, accountingMethod) => [
  {
    order: 1,
    key: 'mainRow',
    columns: [
      {
        name: 'clientId',
        title: 'Client Name',
        filterProps: { width: '100%' },
        column: 1,
        Component: ClientSelect,
      },
      {
        name: 'accountingMethod',
        title: 'Accounting Method',
        filterProps: { width: '100%' },
        column: 1,
        Component: AccountingMethodSelect,
        defaultValue: AccountingMethods.ACCRUAL_BASIS,
      },
      {
        name: 'year',
        title: 'Year',
        filterProps: { width: '100%', required: true },
        columnLayout: { titleWidth: '50px' },
        column: 2,
        Component: YearSelector,
        defaultValue: getCurrentYear(),
      },
      {
        name: 'format',
        title: 'Format',
        filterProps: { width: '100%', required: true },
        columnLayout: { titleWidth: '50px' },
        column: 2,
        Component: FormatSelector,
        defaultValue: formats.total,
      },
      {
        name: 'clientSalesReport',
        filterProps: { width: '100%', salesReportRows, year, accountingMethod },
        columnLayout: { titleWidth: '50px' },
        column: 3,
        Component: ExportTablesContainerS,
      },
    ],
  },
];

const generateClientSalesReportInput = ({
  year,
  clientId,
  format,
  ...filters
}) => {
  const [fromDate, toDate] = getYearIntervalDate(year);

  return {
    ...filters,
    fromDate,
    toDate,
    clientId: clientId === ALL ? null : clientId,
  };
};

const defaultFilters = {
  accountingMethod: AccountingMethods.ACCRUAL_BASIS,
  format: formats.total,
  year: getCurrentYear(),
};

export function ClientSalesReportPage() {
  const [filters, setFilters] = useState(defaultFilters);

  const { data, loading } = useQuery(clientSalesReportQuery, {
    variables: { input: generateClientSalesReportInput(filters) },
    skip: R.isEmpty(filters),
  });

  const { format } = useRouterQuery(['format']);

  const salesReportRows = generateSalesReportsRows(data, format);

  return (
    <PageWithSearchHeader headerHeight="150px">
      <SearchPageHeaderContainer title="Client Sales Report">
        <SearchHeaderCreator
          onSearch={setFilters}
          config={searchHeaderConfig(
            salesReportRows,
            +filters.year,
            filters.accountingMethod,
          )}
          loading={loading}
        />
      </SearchPageHeaderContainer>
      <SearchPageContentWrapper>
        {loading ? (
          <Loader />
        ) : (
          <SalesReportsTable salesReportRows={salesReportRows} />
        )}
      </SearchPageContentWrapper>
    </PageWithSearchHeader>
  );
}
