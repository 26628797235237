import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { getThemeColor, Text } from 'poly-book-admin';
import { arrayOf, shape, string } from 'prop-types';
import { FULL_ACCESS_PERMISSION } from 'poly-security';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { CommonSidebarFormWrapper, SidebarRow } from 'poly-admin-ui';
import {
  NOTHING_UI_STRING,
  RequesterSurveyQuestionTypesUI,
} from 'poly-constants';

import { EditClientRequesterSurveyForm } from './EditClientRequesterSurveyForm.js';
import { EditClientTabButton, TabSectionWrapper } from '../commonComponents.js';

const clientRequesterSurveyFormId = 'clientRequesterSurveyFormId';

const FlexEndSidebarRow = styled(SidebarRow)`
  justify-content: flex-end;
`;

const HeaderLabel = styled.label`
  display: flex;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  margin-bottom: 7px;
  color: ${getThemeColor(['midDark'])};
  width: ${R.prop('width')};
`;

const TextS = styled(Text)`
  width: ${R.prop('width')};
`;

const SidebarRowS = styled(SidebarRow)`
  margin: 5px 0;
  margin-bottom: none;
`;

// getClientRequesterSurveyQuestions :: Client -> [RequesterSurveyQuestion]
const getClientRequesterSurveyQuestions = R.pathOr(
  [],
  ['configs', 'requesterSurvey', 'questions'],
);

// getUIQuestionType :: RequesterSurveyQuestionTypes -> String
const getUIQuestionType = R.compose(
  R.defaultTo(NOTHING_UI_STRING),
  R.prop(R.__, RequesterSurveyQuestionTypesUI),
);

export function ClientRequesterSurveySubTab({ client }) {
  const [editFormOpened, setEditFormOpened] = useState(false);
  const hasAccess = useHasUserAccessWithPermission(FULL_ACCESS_PERMISSION);

  const requesterSurveyQuestions = getClientRequesterSurveyQuestions(client);

  const onCancel = () => setEditFormOpened(false);

  return (
    <TabSectionWrapper>
      {hasAccess && (
        <FlexEndSidebarRow>
          <EditClientTabButton onClick={() => setEditFormOpened(true)}>
            Edit
          </EditClientTabButton>
        </FlexEndSidebarRow>
      )}
      {editFormOpened && (
        <CommonSidebarFormWrapper
          onCancel={onCancel}
          title="Edit Requester Survey"
          formId={clientRequesterSurveyFormId}
        >
          <EditClientRequesterSurveyForm
            formId={clientRequesterSurveyFormId}
            onCancel={onCancel}
            clientId={client._id}
            requesterSurveyQuestions={requesterSurveyQuestions}
          />
        </CommonSidebarFormWrapper>
      )}
      <SidebarRow skipMargin>
        <HeaderLabel width="80%">Question</HeaderLabel>
        <HeaderLabel width="20%">Type</HeaderLabel>
      </SidebarRow>
      {requesterSurveyQuestions.map(({ type, question }) => (
        <SidebarRowS key={`${type}-${question}`}>
          <TextS width="80%" size="12px">
            {question}
          </TextS>
          <TextS width="20%" size="12px">
            {getUIQuestionType(type)}
          </TextS>
        </SidebarRowS>
      ))}
    </TabSectionWrapper>
  );
}

ClientRequesterSurveySubTab.propTypes = {
  client: shape({
    _id: string,
    configs: shape({
      requesterSurvey: shape({
        questions: arrayOf(
          shape({
            type: string,
            question: string,
          }),
        ),
      }),
    }),
  }),
};
