import React, { useState, useContext, useMemo } from 'react';
import {
  oneOfType,
  object,
  func,
  arrayOf,
  shape,
  any,
  string,
  number,
} from 'prop-types';
import { withReactContext } from 'poly-client-utils';

import { Notificator } from './Notificator.js';
import { createNotificationByType } from './create-notification.js';
import { notificationTypes } from './constants.js';

const NotificatorContext = React.createContext(null);
NotificatorContext.displayName = 'NotificatorContext';

export function NotificatorProvider({
  notifications,
  onClose,
  children,
  root,
}) {
  const [stateNotifications, setStateNotifications] = useState([]);

  const closeNotificationById = (notificationId) => {
    setStateNotifications(
      stateNotifications.filter(
        (notification) => notification.id !== notificationId,
      ),
    );
    // should support both redux-based and context-based data sources
    // for compatibility reasons
    onClose(notificationId);
  };

  const createShowNotificationForType = (type) => (notificationOrMessage) => {
    const notification = createNotificationByType(type)(notificationOrMessage);
    // prevent notifications with same ID rendering twice
    // this allows "replace" existing notification content using constant ID
    closeNotificationById(notification.id);
    setStateNotifications([...stateNotifications, notification]);
  };

  const providerValue = useMemo(
    () => ({
      showSuccessNotification: createShowNotificationForType(
        notificationTypes.SUCCESS,
      ),
      showWarningNotification: createShowNotificationForType(
        notificationTypes.WARNING,
      ),
      showErrorNotification: createShowNotificationForType(
        notificationTypes.ERROR,
      ),
    }),
    [],
  );

  const ensureNotificationHasMessage = ({
    payload,
    component: Component,
    ...restNotification
  }) => ({
    ...restNotification,
    ...(Component && payload
      ? {
          message: (
            <Component
              {...{
                payload,
                ...restNotification,
                closeNotificationById,
              }}
            />
          ),
        }
      : {}),
  });

  const allNotifications = [
    ...notifications,
    ...stateNotifications.map(ensureNotificationHasMessage),
  ];

  return (
    <NotificatorContext.Provider value={providerValue}>
      {children}
      <Notificator
        notifications={allNotifications}
        onClose={closeNotificationById}
        root={root}
      />
    </NotificatorContext.Provider>
  );
}

NotificatorProvider.displayName = 'NotificatorProvider';

const notificationShape = shape({
  type: string.isRequired,
  id: string.isRequired,
  time: number.isRequired,
  message: string,
  component: oneOfType([func, object]),
  // eslint-disable-next-line react/forbid-prop-types
  payload: object,
});

NotificatorProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: any,
  notifications: arrayOf(notificationShape).isRequired,
  onClose: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  root: object,
};

export const withNotificator = withReactContext(
  'notificator',
  NotificatorContext,
);

export const useNotificationContext = () => {
  const notificationContext = useContext(NotificatorContext);
  return notificationContext;
};
