import * as R from 'ramda';
import { func } from 'prop-types';
import React, { useCallback } from 'react';
import { thirdWidth, SupplierSelect } from 'poly-admin-ui';
import { DatePicker, Select } from 'poly-book-admin';
import { InvoiceTypes } from 'poly-constants';
import {
  formatSelectOptionsWithTitleCased,
  commonFileValidators,
  validateFilesFunc,
} from 'poly-client-utils';

import { InvoiceDescriptionEditorS } from './components/componentsStyled.js';
import { AddSupplierInvoiceLine } from './components/AddSupplierInvoiceLine.js';
import { AttachDocumentField } from '../../../components/AttachDocumentField.js';
import { InvoiceNumberInput } from '../../../modules/forms/supplierInvoiceForm/components/InvoiceNumberInput.js';

const partWidth = 'calc(21% - 7px)';

// getInvoiceTypeSelectOptions :: Object -> [Option]
const getInvoiceTypeSelectOptions = R.compose(
  formatSelectOptionsWithTitleCased,
  R.pick([InvoiceTypes.fixed, InvoiceTypes.breakdown]),
);

function InvoiceTypeSelect(props) {
  const invoiceTypeSelectOptions = getInvoiceTypeSelectOptions(InvoiceTypes);

  return <Select {...props} options={invoiceTypeSelectOptions} />;
}

function InvoiceDatePicker(props) {
  return <DatePicker {...props} width="100%" />;
}

function InvoiceFilePicker(props) {
  return <AttachDocumentField {...props} showLabel={false} />;
}

function InvoiceSupplierSelect({ onChange, changeFieldValue, ...props }) {
  const handleChange = useCallback((value) => {
    changeFieldValue('invoices', [{}]);
    onChange(value);
  }, []);

  return <SupplierSelect {...props} onChange={handleChange} />;
}

InvoiceSupplierSelect.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};

function InvoiceDescriptionEditor(props) {
  return (
    <InvoiceDescriptionEditorS
      {...props}
      id="single-description-editor-id"
      placeholder="Enter Invoice Description"
    />
  );
}

export const addSupplierInvoicesFormSections = [
  {
    order: 1,
    layout: { padding: 0 },
    fields: [
      {
        order: 1,
        required: true,
        label: 'Supplier',
        layout: { row: 1, width: thirdWidth },
        field: {
          name: 'supplierId',
          withChangeFieldValue: true,
          Component: InvoiceSupplierSelect,
        },
        validators: [[R.identity, 'Supplier is required']],
      },
      {
        order: 2,
        required: true,
        label: 'Invoice Type',
        layout: { row: 1, width: partWidth },
        field: {
          name: 'type',
          Component: InvoiceTypeSelect,
        },
        validators: [[R.identity, 'Invoice Type is required']],
      },
      {
        order: 3,
        label: 'Invoice Date',
        layout: { row: 1, width: partWidth },
        field: {
          name: 'invoiceDate',
          Component: InvoiceDatePicker,
        },
      },
      {
        order: 4,
        required: true,
        label: 'Invoice #',
        layout: { row: 1, width: partWidth },
        field: {
          withFormData: true,
          name: 'invoiceNumber',
          Component: InvoiceNumberInput,
        },
        validators: [[R.identity, 'Invoice is required']],
      },
      {
        order: 5,
        layout: { row: 2, width: '100%', padding: '16px 0' },
        field: {
          name: 'invoices',
          withFormData: true,
          isArrayField: true,
          withChangeFieldValue: true,
          Component: AddSupplierInvoiceLine,
        },
      },
      {
        order: 6,
        label: 'Description',
        layout: { row: 3, width: '100%' },
        field: {
          name: 'projectInvoiceDescription',
          Component: InvoiceDescriptionEditor,
        },
        renderIf: R.prop('isSingleDescription'),
      },
      {
        order: 7,
        label: 'Attach Invoice',
        layout: { row: 4, width: '100%', padding: '8px 0' },
        field: {
          name: 'invoiceFile',
          Component: InvoiceFilePicker,
        },
        validators: commonFileValidators,
        validateFunction: validateFilesFunc,
      },
    ],
  },
];
