import * as R from 'ramda';
import { isNilOrEmpty } from 'poly-utils';
import { addressConfig, internationalPhoneUtils } from 'poly-client-utils';

const { getCountryFieldsByIso } = internationalPhoneUtils;

// getCountryOptions :: Object -> [[String, String]]
export const getCountryOptions = R.compose(
  R.map(R.applySpec({ value: R.prop('iso'), label: R.prop('label') })),
  R.propOr([], 'options'),
);

// getAdministrativeAreaOptions :: AdministrativeArea -> [Option]
// AdministrativeArea = {label: String, options: [Object]}
export const getAdministrativeAreaOptions = R.compose(
  R.map(R.applySpec({ value: R.head, label: R.last })),
  R.unnest,
  R.map(R.toPairs),
  R.defaultTo([]),
  R.propOr([], 'options'),
);

// getPostalCodePlaceholder :: PostalCode -> String
// PostalCode ={label: String, format: String, eg: String}
export const getPostalCodePlaceholder = R.ifElse(
  R.prop('eg'),
  R.compose(R.concat('e.g '), R.prop('eg')),
  R.always(''),
);

// getCountryCodeByAddress :: Object -> String
export const getCountryCodeByAddress = R.pathOr('US', [
  'address_parts',
  'country',
]);

// isEmptyZipByAddress :: Object -> Boolean
export const isEmptyZipByAddress = R.compose(
  isNilOrEmpty,
  R.path(['address_parts', 'postal_code']),
);

// isCountryConfigNotHasZipCode :: Address -> Boolean
export const isCountryConfigNotHasZipCode = R.compose(
  isNilOrEmpty,
  R.prop('postalCode'),
  getCountryFieldsByIso(R.__, addressConfig),
  R.pathOr(null, ['address_parts', 'country']),
);
