import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { useUpdateQueryParams } from 'poly-client-routing';
import { undefinedAsNullDeep } from 'poly-client-utils';

import { prepareCommonSupplierDataForMutation } from '../../forms/supplierForm/form/prepareFormDataForMutation.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';
import {
  omitSupplierSubcontractorAgreement,
  emptyStringAsNullForSupplierInput,
  omitRemitIfNeed,
  omitSupplierProtectedFieldsIfNoPermission,
  prepareEmptySupplierField,
  prepareSupplierFinancial,
} from '../../forms/supplierForm/edit/formDataToMutation.js';

const UPDATE_MASTER_SUPPLIER_MUTATION = gql`
  mutation UPDATE_MASTER_SUPPLIER_MUTATION(
    $id: ID!
    $input: UpdateMasterSupplierInput!
  ) {
    updateMasterSupplier(id: $id, input: $input) {
      masterSupplier {
        _id
      }
    }
  }
`;

/**
 * prepareSupplierToUpdate :: FormData -> FieldsAccess -> FormData
 * FieldsAccess = {
 *  hasUserAccessToUpdateTax: Boolean,
 *  hasUserAccessToUpdateBank: Boolean
 *  hasUserAccessToUpdateFinance: Boolean,
 *  hasUserAccessToUpdateRemit: Boolean,
 *  hasUserAccessToUpdateDocuments: Boolean,
 * }
 */
const prepareSupplierToUpdate = R.curry((formData, protectedFieldsAccess) =>
  R.compose(
    omitSupplierSubcontractorAgreement,
    omitSupplierProtectedFieldsIfNoPermission(protectedFieldsAccess),
    prepareSupplierFinancial,
    omitRemitIfNeed,
    R.omit(['searchInvoices']),
    prepareEmptySupplierField,
    prepareCommonSupplierDataForMutation,
    undefinedAsNullDeep,
    emptyStringAsNullForSupplierInput,
  )(formData),
);

export const useUpdateMasterSupplierMutation = (
  supplierId,
  protectedFieldsAccess,
) => {
  const { showSuccessNotification } = useNotificationState();
  const updateQueryParams = useUpdateQueryParams();
  const [updateMasterSupplier] = useMutation(UPDATE_MASTER_SUPPLIER_MUTATION);
  return async (formData) => {
    await updateMasterSupplier({
      variables: {
        id: supplierId,
        input: prepareSupplierToUpdate(formData, protectedFieldsAccess),
      },
    });

    showSuccessNotification('Master Supplier Successfully Edited');
    return updateQueryParams({ isEdit: false });
  };
};
