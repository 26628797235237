import * as R from 'ramda';
import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useInfiniteScrollQueryWithSubscription } from 'poly-client-utils';
import { InvoicesStatuses } from 'poly-constants';
import { assocBy } from 'poly-utils';

import { INVOICES_BY_SEARCH_SUB } from '../../modules/core/hooks/invoices/subscriptions.js';

const searchInvoicesQuery = gql`
  query searchApproveInvoicesQuery($searchInput: CollectionSearchParams!) {
    searchInvoices(input: $searchInput) {
      total
      hits {
        _id
        total
        status
        createdBy {
          _id
        }
        updatedBy {
          _id
        }
        receivedBy {
          _id
        }
        client {
          _id
          name
        }
        project {
          _id
          type
          projectId
          property {
            _id
            name
          }
          suppliers {
            _id
            nte
          }
        }
        supplier {
          _id
          company {
            name
          }
        }
      }
    }
  }
`;

const queryPartByUserId = (userId) => [
  { match: { createdBy: userId } },
  { match: { updatedBy: userId } },
  { match: { receivedBy: userId } },
];

export const getApproveInvoiceQuery = (includeUserId, excludeUserId) => ({
  bool: {
    must: [
      { match: { status: InvoicesStatuses.RECEIVED } },
      ...(!includeUserId
        ? []
        : [{ bool: { should: queryPartByUserId(includeUserId) } }]),
    ],
    ...(!excludeUserId ? {} : { must_not: queryPartByUserId(excludeUserId) }),
  },
});

// getInvoices :: ID -> { searchInvoices: { hits: [Invoice], total: Int } } -> { invoices: [Invoice], total: Int }
const getInvoices = (userId) =>
  R.applySpec({
    total: R.pathOr(0, ['searchInvoices', 'total']),
    invoices: R.compose(
      R.unless(
        () => R.isNil(userId),
        R.map(
          assocBy(
            'isOwnInvoice',
            R.anyPass([
              R.pathEq(['createdBy', '_id'], userId),
              R.pathEq(['updatedBy', '_id'], userId),
              R.pathEq(['receivedBy', '_id'], userId),
            ]),
          ),
        ),
      ),
      R.pathOr([], ['searchInvoices', 'hits']),
    ),
  });

export const useApproveInvoices = ({
  userId,
  includeUserId,
  excludeUserId,
  sort = {},
}) => {
  const input = useMemo(
    () => ({
      sort,
      query: getApproveInvoiceQuery(includeUserId, excludeUserId),
    }),
    [includeUserId, excludeUserId, sort],
  );

  const { data, loading, refetch, tableProps } =
    useInfiniteScrollQueryWithSubscription(
      searchInvoicesQuery,
      input,
      {
        endpointName: 'searchInvoices',
        inputName: 'searchInput',
        pageSize: 1000,
      },
      INVOICES_BY_SEARCH_SUB,
      { searchInput: input },
    );

  const { invoices, total } = getInvoices(userId)(data);

  return {
    total,
    invoices,
    loading,
    refetch,
    tableProps,
  };
};
