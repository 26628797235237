import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func, string, shape } from 'prop-types';
import { halfWidth, thirdWidth, StateSelect } from 'poly-admin-ui';
import { InputHTML5, RadioButton } from 'poly-book-admin';

import { glCodeTypes } from '../constants.js';
import {
  TypeDropDown,
  StatusDropDown,
  DisabledInputS,
  DivisionDropDown,
  AccountGLCodeInputS,
  ParentAccountDropDown,
  DisabledStatusDropDown,
} from '../components.js';
import { onKeyDownToPreventFormSubmit } from '../../../utils/form.js';

const prepareToValidation = R.replace(/[\\~#%&*{}/:<>?|"-]/g, '');

// validateCode :: String -> Boolean
const validateCode = R.compose(
  R.propEq('length', 5),
  prepareToValidation,
  R.defaultTo(''),
);

// validateAccountName :: String -> Boolean
const validateAccountName = R.compose(
  R.gt(R.__, 0),
  R.prop('length'),
  prepareToValidation,
  R.defaultTo(''),
);

const parentAccountSection = (id, parentAccounts, isNewAccount, disabled) => ({
  label: 'Parent Account',
  order: 7,
  layout: { row: 1, width: halfWidth },
  required: true,
  renderIf: ({ glCodeType }) => glCodeType === glCodeTypes.child,
  field: {
    name: 'parentAccountId',
    withChangeFieldValue: true,
    Component: (props) =>
      ParentAccountDropDown({
        accountId: id,
        parentAccounts,
        disabled,
        onKeyDown: onKeyDownToPreventFormSubmit,
        ...props,
      }),
  },
  validators: [[R.identity, 'Parent Account is required']],
});

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const RadioButtonsWrapper = styled(Row)`
  height: 38px;
  flex-direction: row;
  margin: 0;
  align-items: center;
  justify-content: flex-start;

  label:not(:last-child) {
    margin-right: 20px;
  }
`;

function GLCodeTypeRadioButtonSelect({
  value,
  onChange,
  isNewAccount,
  changeFieldValue,
}) {
  const onParentChange = (val) => {
    onChange(val);
    changeFieldValue('isTaxAccount', false);
    changeFieldValue('parentAccountId', null);
  };
  return (
    <RadioButtonsWrapper>
      <RadioButton
        name={glCodeTypes.parent}
        label="Parent"
        value={value === glCodeTypes.parent}
        onChange={onParentChange}
        disabled={!isNewAccount}
      />
      <RadioButton
        name={glCodeTypes.child}
        label="Child"
        value={value === glCodeTypes.child}
        onChange={onChange}
        disabled={!isNewAccount}
      />
    </RadioButtonsWrapper>
  );
}

GLCodeTypeRadioButtonSelect.propTypes = {
  value: string,
  onChange: func,
  isNewAccount: bool,
  changeFieldValue: func.isRequired,
};

function InputChangeOnBlur(props) {
  return <InputHTML5 changeOnBlur {...props} />;
}

// getParentAccountTypeId :: ({parentAccounts: [Account]}, ID) -> ID
const getParentAccountTypeId = (data, id) =>
  R.compose(
    R.path(['accountType', '_id']),
    R.find(R.propEq('_id', id)),
    R.propOr([], 'parentAccounts'),
  )(data);

function AccountTypeDropDown({
  formData,
  isNewAccount,
  isParentWithTransactions,
  onChange,
  ...props
}) {
  const isChildAccount = formData?.glCodeType === glCodeTypes.child;

  useEffect(() => {
    if (formData?.parentAccountId) {
      const accountTypeId = getParentAccountTypeId(
        props,
        formData?.parentAccountId,
      );
      onChange(accountTypeId);
    }
  }, [isChildAccount, formData?.parentAccountId]);

  const disabled = isChildAccount || isParentWithTransactions;
  return (
    <TypeDropDown
      onKeyDown={onKeyDownToPreventFormSubmit}
      {...props}
      disabled={disabled}
      onChange={onChange}
    />
  );
}

AccountTypeDropDown.propTypes = {
  formData: shape({
    glCodeType: string,
  }),
  isNewAccount: bool,
  isParentWithTransactions: bool,
  onChange: func.isRequired,
};

export const getFormCreatorSections = ({
  _id,
  isNewAccount,
  hasChild,
  statusChangeDisabled,
  parentAccounts,
  sub_account,
  isParentWithTransactions,
}) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Select GL Code Type',
        order: 1,
        layout: { row: 1, width: halfWidth },
        required: true,
        field: {
          name: 'glCodeType',
          withChangeFieldValue: true,
          Component: (props) =>
            GLCodeTypeRadioButtonSelect({ ...props, isNewAccount }),
        },
      },
      {
        label: 'Account Name',
        order: 2,
        layout: { row: 2 },
        required: true,
        field: {
          name: 'name',
          additionalProps: {
            onKeyDown: onKeyDownToPreventFormSubmit,
            showCharactersLeft: true,
          },
          Component: InputChangeOnBlur,
        },
        validators: [[validateAccountName, 'Please provide account name']],
      },
      {
        label: 'GL Code',
        order: 3,
        layout: {
          row: 3,
          width: isNewAccount ? thirdWidth : halfWidth,
        },
        required: true,
        field: {
          name: 'code',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: isNewAccount ? AccountGLCodeInputS : DisabledInputS,
        },
        validators: [[validateCode, 'Please provide 4 digits']],
      },
      {
        label: 'Account Type',
        order: 4,
        layout: {
          row: 3,
          width: isNewAccount ? thirdWidth : halfWidth,
        },
        required: true,
        validators: [[R.identity, 'Account type is required']],
        field: {
          name: 'accountTypeId',
          withFormData: true,
          Component: AccountTypeDropDown,
          additionalProps: {
            isNewAccount,
            isParentWithTransactions,
            parentAccounts,
          },
        },
      },
      {
        label: 'Division',
        order: 5,
        layout: {
          row: isNewAccount ? 3 : 4,
          width: isNewAccount ? thirdWidth : halfWidth,
        },
        required: true,
        validators: [[R.identity, 'Division is required']],
        field: {
          name: 'divisionId',
          Component: (props) =>
            DivisionDropDown({
              disabled: !isNewAccount,
              onKeyDown: onKeyDownToPreventFormSubmit,
              ...props,
            }),
        },
      },
      {
        label: 'Status',
        order: 6,
        layout: { row: 4, width: halfWidth },
        renderIf: () => !isNewAccount,
        field: {
          name: 'status',
          Component: !statusChangeDisabled
            ? StatusDropDown
            : DisabledStatusDropDown,
        },
      },
      {
        label: 'State',
        order: 7,
        layout: { row: 5, width: isNewAccount ? thirdWidth : halfWidth },
        renderIf: R.prop('isTaxAccount'),
        field: {
          name: 'state',
          Component: StateSelect,
        },
      },
      {
        label: 'Compass GL Code',
        order: 8,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'compass_gl_code',
          Component: InputHTML5,
          additionalProps: {
            maxLength: 40,
            charactersLimit: 40,
            showCharactersLeft: true,
          },
        },
      },
      {
        label: 'Compass GL Code Description',
        order: 9,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'compass_gl_code_description',
          Component: InputHTML5,
          additionalProps: {
            maxLength: 200,
            charactersLimit: 200,
            showCharactersLeft: true,
          },
        },
      },
      parentAccountSection(
        _id,
        parentAccounts,
        isNewAccount,
        !!(hasChild || sub_account),
      ),
    ],
  },
];
