import React from 'react';
import { arrayOf, node, number, object, shape, string } from 'prop-types';
import styled from 'styled-components';
import {
  Cell,
  getThemeColor,
  getThemeFont,
  HeaderCell,
  HeaderRow,
  MainHeader,
} from 'poly-book-admin';

import { FlexColumn, SpaceBetween } from '../../components/FlexContainer.js';

const Wrapper = styled(FlexColumn)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const HeaderWrapper = styled(SpaceBetween)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 30px;
`;

const MainHeaderS = styled(MainHeader)`
  font-size: 18px;
  line-height: 18px;
`;

export function SearchPageTableComp({ counts, ToolBar, children }) {
  return (
    <Wrapper>
      <HeaderWrapper>
        <MainHeaderS>Results ({counts})</MainHeaderS>
        {ToolBar}
      </HeaderWrapper>
      {children}
    </Wrapper>
  );
}

SearchPageTableComp.propTypes = {
  counts: number.isRequired,
  // eslint-disable-next-line
  ToolBar: object,
  children: node,
};

SearchPageTableComp.defaultProps = {
  ToolBar: null,
  children: null,
};

export function TableHeaderC({ headers }) {
  return (
    <thead>
      <HeaderRow>
        {headers.map(({ title }) => (
          <HeaderCell key={title}>
            <span>{title}</span>
          </HeaderCell>
        ))}
      </HeaderRow>
    </thead>
  );
}

TableHeaderC.propTypes = {
  headers: arrayOf(
    shape({
      title: string.isRequired,
    }),
  ),
};

export const CellTitleS = styled(Cell)`
  vertical-align: middle;
  font-weight: ${getThemeFont(['medium'])};
  background-color: ${getThemeColor(['accent2Lighter5'])} !important;
  font-size: 16px;
`;
