import React from 'react';
import * as R from 'ramda';
import { arrayOf, string, shape, number, bool } from 'prop-types';
import styled from 'styled-components';
import { getThemeColor, getThemeFont, Loader, Popover } from 'poly-book-admin';
import { pathOrNothingUI } from 'poly-client-utils';
import { formatTotal, formatDate, centsToDollarsWithFormat } from 'poly-utils';
import { JournalPaymentStatus } from 'poly-constants';
import { gql, useQuery } from '@apollo/client';

import {
  FlexColumn,
  FlexContainer,
} from '../../../components/FlexContainer.js';

const PaymentsListContainerS = styled(FlexColumn)`
  width: 390px;
  max-height: 300px;
  min-height: 50px;
  padding: 10px;
  overflow-y: auto;
`;

const TitleS = styled.div`
  font-weight: ${getThemeFont(['bold'])};
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

const TableS = styled.table`
  width: 100%;

  & > tbody > tr {
    > td {
      padding: 5px 5px 10px 5px;
    }

    > td:nth-child(1) {
      width: 80px;
      padding-left: 0;
    }

    > td:nth-child(2) {
      width: auto;
      text-align: left;
    }

    > td:nth-child(3) {
      width: auto;
      font-weight: ${getThemeFont(['semibold'])};
    }

    > td:nth-child(4) {
      width: 110px;
      font-family: 'TTNormsMedium', sans-serif;
      font-size: 12px;
      padding: 5px 5px 10px 5px;
    }
  }
`;

const BalanceContainerS = styled(FlexContainer)`
  border-top: 1px solid ${getThemeColor(['midLight'])};
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  font-size: 12px;
`;

const BalanceText = styled.div`
  font-weight: ${getThemeFont(['semibold'])};
  margin-left: 5px;
`;

const PopoverS = styled(Popover)`
  button {
    padding: 0;
    font-size: 12px;
  }
`;

const PaymentTotalTableCell = styled.td`
  width: 250px;
  color: ${({ paymentStatus, ...props }) =>
    paymentStatus === JournalPaymentStatus.VOIDED
      ? getThemeColor(['secondaryMid'])(props)
      : getThemeColor(['black'])(props)};
`;

function InvoicePaymentsList({ payments, balance }) {
  return (
    <PaymentsListContainerS>
      <TitleS>Payments</TitleS>
      <TableS>
        <tbody>
          {payments?.map(
            ({ _id, date, created_by, check_number, payment_lines }, key) =>
              payment_lines?.map(
                ({ account, cash_amount, accrual_amount, payment_status }) => {
                  const amount = cash_amount || accrual_amount;

                  return (
                    <tr key={_id} data-testid={`payments-row-${key}`}>
                      <td>{formatDate(date)}</td>
                      <td>
                        {!check_number
                          ? account?.name
                          : `Check #${check_number}`}
                      </td>
                      <PaymentTotalTableCell paymentStatus={payment_status}>
                        {formatTotal(amount)}
                        {payment_status === JournalPaymentStatus.VOIDED
                          ? ' Voided'
                          : ''}
                      </PaymentTotalTableCell>
                      <td>
                        {pathOrNothingUI(['profile', 'fullName'], created_by)}
                      </td>
                    </tr>
                  );
                },
              ),
          )}
        </tbody>
      </TableS>
      <BalanceContainerS>
        Balance: <BalanceText>{centsToDollarsWithFormat(balance)}</BalanceText>
      </BalanceContainerS>
    </PaymentsListContainerS>
  );
}

const paymentPropTypes = shape({
  check_number: string,
  date: string.isRequired,
  payment_lines: arrayOf(
    shape({
      cash_amount: number,
      accrual_amount: number,
      payment_status: string,
      account: shape({ name: string.isRequired }),
    }),
  ),
  created_by: shape({ fullName: string }).isRequired,
});

InvoicePaymentsList.propTypes = {
  balance: number.isRequired,
  payments: arrayOf(paymentPropTypes),
};

export const INVOICE_PAYMENTS_QUERY = gql`
  query INVOICE_PAYMENTS_QUERY($id: ID!) {
    invoice(id: $id) {
      _id
      payments {
        _id
        date
        check_number
        created_by {
          _id
          profile {
            fullName
          }
        }
        payment_lines {
          cash_amount
          accrual_amount
          account {
            _id
            code
            name
          }
          payment_status
        }
      }
      balance
    }
  }
`;

// getInvoicePaymentsAndBalance :: {invoice:Invoice} -> {payments: [Payment], balance: Number }
const getInvoicePaymentsAndBalance = R.applySpec({
  payments: R.pathOr([], ['invoice', 'payments']),
  balance: R.pathOr(0, ['invoice', 'balance']),
});

function InvoicePaymentsListComp({ isActive, invoiceId }) {
  const { data, loading } = useQuery(INVOICE_PAYMENTS_QUERY, {
    variables: { id: invoiceId },
    skip: !isActive || !invoiceId,
    fetchPolicy: 'network-only',
  });

  const { payments, balance } = getInvoicePaymentsAndBalance(data);

  if (loading) {
    return (
      <PaymentsListContainerS>
        <Loader />
      </PaymentsListContainerS>
    );
  }
  return <InvoicePaymentsList payments={payments} balance={balance} />;
}

InvoicePaymentsListComp.propTypes = {
  isActive: bool,
  invoiceId: string,
};

export function InvoicePaymentsPopover({ status, ...props }) {
  return (
    <PopoverS
      position="left"
      title={status}
      withPortalAnchor
      content={InvoicePaymentsListComp}
      contentProps={props}
    />
  );
}

InvoicePaymentsPopover.propTypes = {
  status: string.isRequired,
};
