import React from 'react';
import { LinkButton } from 'poly-book-admin';
import {
  formatFromToDateTitle,
  openPrintWindowWithData,
} from 'poly-client-utils';
import { CommonPrintLayout } from 'poly-admin-ui';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import styled from 'styled-components';
import { string, bool, instanceOf, shape, oneOfType } from 'prop-types';

import { forceTitleCase } from 'poly-utils';
import {
  generalLedgerFiltersPropTypes,
  generalLedgerReportPropType,
} from './generalLedgerPropTypes.js';
import { GeneralLedgerTable } from './GeneralLedgerTable.js';

const SubTitleS = styled.p`
  font-size: 16px;
`;

export function GeneralLedgerPrintTitle({ title, date, accountingMethod }) {
  return (
    <>
      <p>{title}</p>
      <SubTitleS>
        {formatFromToDateTitle(date.startDate, date.endDate)}
      </SubTitleS>
      <SubTitleS>{forceTitleCase(accountingMethod)}</SubTitleS>
    </>
  );
}

GeneralLedgerPrintTitle.propTypes = {
  title: string.isRequired,
  date: shape({
    startDate: oneOfType([instanceOf(Date), string]),
    endDate: oneOfType([instanceOf(Date), string]),
  }),
  accountingMethod: string,
};

export function GeneralLedgerPrintBtn({
  reportData,
  generalLedgerFilters,
  disabled,
}) {
  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: GeneralLedgerTable,
      fileName: 'general_ledger',
      metaData: {
        title: (
          <GeneralLedgerPrintTitle
            title="General Ledger Summary"
            date={generalLedgerFilters.date}
            accountingMethod={generalLedgerFilters.accountingMethod}
          />
        ),
      },
      reportData,
      generalLedgerFilters,
    });
  return (
    <LinkButton disabled={disabled} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

GeneralLedgerPrintBtn.propTypes = {
  reportData: generalLedgerReportPropType,
  disabled: bool,
  generalLedgerFilters: generalLedgerFiltersPropTypes,
};
