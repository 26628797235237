import React from 'react';
import * as R from 'ramda';
import { arrayOf, shape, string } from 'prop-types';
import styled from 'styled-components';
import { useMapConfigToTableProps } from 'poly-admin-ui/src/hooks/useMapConfigToTableProps.js';
import { applyPathOrNothingUI, pathOrNothingUI } from 'poly-client-utils';
import { applyProp, centsToDollarsWithFormat, formatDate } from 'poly-utils';
import { Table } from 'poly-book-admin/src/Table/Table.js';
import { Dot } from 'poly-book-admin/src/Status/index.js';

import { purchaseOrderStatusColors } from '../../sidebars/PurchaseOrderSidebar/constants.js';
import {
  getPurchaseOrdersStatusUI,
  getPurchaseOrdersTypeUI,
} from '../../sidebars/PurchaseOrderSidebar/utils.js';
import { PurchaseOrderLink } from '../../sidebars/PurchaseOrderSidebar/PurchaseOrderLink.js';
import { CurrentBalanceComp } from './CurrentBalanceComp.js';

const PurchaseOrdersTableComp = styled(Table)`
  td:last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const DocumentTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  p {
    margin: 0;
    padding: 0;
  }
`;

function PurchaseOrderStatusComp({ status }) {
  const color = purchaseOrderStatusColors[status];

  const statusUI = getPurchaseOrdersStatusUI(status);

  return (
    <DocumentTypeWrapper>
      <Dot color={color} />
      <p>{statusUI}</p>
    </DocumentTypeWrapper>
  );
}

PurchaseOrderStatusComp.propTypes = {
  status: string.isRequired,
};

const purchaseOrdersTableTableConfig = [
  ['PO number', PurchaseOrderLink],
  ['PO Display Name', R.prop('displayName')],
  ['Status', PurchaseOrderStatusComp],
  ['Start Date', applyPathOrNothingUI(['startDate'], formatDate)],
  ['End Date', applyPathOrNothingUI(['endDate'], formatDate)],
  ['PO Type', getPurchaseOrdersTypeUI],
  ['Authorized By', pathOrNothingUI(['authorizedBy', 'fullName'])],
  ['Initial Balance', applyProp(centsToDollarsWithFormat)('initialBalance')],
  ['Current Balance', CurrentBalanceComp],
];

export function PurchaseOrdersTable({ purchaseOrders }) {
  const { columns, headers, rows } = useMapConfigToTableProps(
    R.identity,
    purchaseOrdersTableTableConfig,
    purchaseOrders,
  );

  return (
    <PurchaseOrdersTableComp columns={columns} headers={headers} rows={rows} />
  );
}

PurchaseOrdersTable.propTypes = {
  purchaseOrders: arrayOf(shape({})),
};
