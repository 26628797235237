import React from 'react';
import * as R from 'ramda';
import { format } from 'date-fns';
import styled from 'styled-components';
import { bool, string, shape, arrayOf } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';
import { SectionWithCard } from 'poly-book-admin';

const officeHourPropTypes = {
  day: string.isRequired,
  endTime: string.isRequired,
  startTime: string.isRequired,
};

const OfficeHourItemWrapperS = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 5px;

  > span:first-child {
    width: 100px;
  }
`;

// getTimeFormatted :: String -> String
const getTimeFormatted = (date) => format(new Date(date), 'hh:mm a');

function OfficeHourItem({ day, endTime, startTime }) {
  const hoursLine = `${getTimeFormatted(startTime)} - ${getTimeFormatted(
    endTime,
  )}`;

  return (
    <OfficeHourItemWrapperS>
      <span>{day}</span>
      <span>{hoursLine}</span>
    </OfficeHourItemWrapperS>
  );
}

OfficeHourItem.propTypes = officeHourPropTypes;

// checkShouldRenderOfficeHours :: Supplier -> Boolean
const checkShouldRenderOfficeHours = R.both(
  R.complement(R.propEq('service_24_7', true)),
  R.propSatisfies(R.complement(R.isNil), 'officeHours'),
);

export function SupplierOfficeHoursSection(props) {
  const { officeHours } = props;
  const shouldRender = checkShouldRenderOfficeHours(props);

  return shouldRender ? (
    <SectionWithCard
      title="Office Hours"
      list={[
        {
          width: 100,
          key: 'office-hours',
          elem: officeHours
            ? officeHours.map((item) => (
                <OfficeHourItem key={item.day} {...item} />
              ))
            : NOTHING_UI_STRING,
        },
      ]}
    />
  ) : null;
}

SupplierOfficeHoursSection.propTypes = {
  service_24_7: bool,
  officeHours: arrayOf(shape(officeHourPropTypes)),
};
