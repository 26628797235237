import React from 'react';
import * as R from 'ramda';
import { number, string } from 'prop-types';
import {
  MAX_TASK_DESCRIPTION_LENGTH,
  isValidTaskDescriptionLength,
} from 'poly-admin-ui/src/modules/forms/taskForm/form/sections.js';
import { TaskPrioritySelect } from 'poly-admin-ui/src/modules/forms/taskForm/form/components/TaskPrioritySelect.js';
import { Input } from 'poly-book-admin/src/Input/index.js';
import { Textarea } from 'poly-book-admin/src/Textarea/index.js';
import { CharacterCount } from 'poly-book-admin/src/CharacterCount/CharacterCount.js';

// trimString :: String -> String
const trimString = R.when(R.is(String), R.trim);

function TextareaWithLimit({ value, maxLength, ...props }) {
  const length = typeof value === 'string' ? value.length : 0;
  return (
    <div>
      <CharacterCount limit={maxLength} length={length} />
      <Textarea maxLength={maxLength} value={value} {...props} />
    </div>
  );
}

TextareaWithLimit.propTypes = {
  value: string.isRequired,
  maxLength: number.isRequired,
};

export const taskTemplateFromSections = [
  {
    id: 'main',
    layout: { column: 1, margin: 0 },
    order: 1,
    fields: [
      {
        order: 1,
        layout: { row: 1, width: 'calc(75% - 10px)', margin: '0 10px 0 0' },
        label: 'Task Name',
        field: {
          name: 'name',
          Component: Input,
        },

        validators: [
          [trimString, 'Task name is required'],
          [
            isValidTaskDescriptionLength,
            `Maximal task name's length is ${MAX_TASK_DESCRIPTION_LENGTH} characters`,
          ],
        ],
      },
      {
        order: 2,
        layout: { row: 1, width: '25%' },
        label: 'Priority',
        field: {
          name: 'priority',
          Component: TaskPrioritySelect,
        },
      },
      {
        label: 'Update',
        order: 3,
        layout: { row: 3, width: '100%' },
        field: {
          name: 'description',
          Component: TextareaWithLimit,
          additionalProps: {
            rows: 5,
            maxLength: 5000,
          },
          required: true,
        },
        validators: [[trimString, 'Update description is required']],
      },
    ],
  },
];
