import React from 'react';
import { ClientSpendReportTypes } from 'poly-constants';
import { Select } from 'poly-book-admin';

export const SPEND_REPORT_TRANSPARENT_LABEL = 'Transparent';

export const SPEND_REPORT_NON_TRANSPARENT_LABEL = 'Non Transparent';

export function ClientSpentReportSelect(props) {
  const options = [
    {
      label: SPEND_REPORT_TRANSPARENT_LABEL,
      value: ClientSpendReportTypes.TRANSPARENT,
    },
    {
      label: SPEND_REPORT_NON_TRANSPARENT_LABEL,
      value: ClientSpendReportTypes.NON_TRANSPARENT,
    },
  ];

  return <Select {...props} options={options} />;
}
