import React from 'react';
import { func } from 'prop-types';
import { useOutSidebarContext } from 'poly-client-utils';

import { SidebarFormLayout } from '../../components/SidebarFormLayout.js';
import { addPurchaseOrderFormId } from '../constants.js';
import { AddPurchaseOrderForm } from './AddPurchaseOrderForm.js';

function AddPurchaseOrderSidebarContent({ onClose, ...formProps }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      formId={addPurchaseOrderFormId}
      title="Add New PO"
      submitButtonCaption="Save"
    >
      <AddPurchaseOrderForm {...formProps} />
    </SidebarFormLayout>
  );
}

AddPurchaseOrderSidebarContent.propTypes = {
  onClose: func.isRequired,
};

export const useAddPurchaseOrderSidebar = () => {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  const onCloseHandler = () => {
    closeOutSidebar(addPurchaseOrderFormId);
  };

  return (clientId) =>
    openOutSidebar({
      alwaysFirst: true,
      id: addPurchaseOrderFormId,
      width: 555,
      content: (
        <AddPurchaseOrderSidebarContent
          clientId={clientId}
          onClose={onCloseHandler}
        />
      ),
    });
};
