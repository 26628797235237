import React from 'react';
import { string } from 'prop-types';
import { Field } from 'react-final-form';
import { MoneyInput } from 'poly-admin-ui';

export function RateInputField({ name, requiredName, dataTestId }) {
  return (
    <Field
      name={name}
      validate={(value) => !value && `${requiredName} is required`}
    >
      {({ input, meta }) => (
        <MoneyInput
          {...input}
          meta={meta}
          maxValue={99999}
          required
          dataTestId={dataTestId}
          error={meta.touched ? meta.error : undefined}
        />
      )}
    </Field>
  );
}

RateInputField.propTypes = {
  name: string.isRequired,
  dataTestId: string,
  requiredName: string.isRequired,
};
