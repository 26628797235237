import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { getAssetModelsQuery, useReactiveQuery } from 'poly-client-utils';
import { usePaginationParams, useSetItemsCount } from 'poly-admin-ui';
import { ASSETS_BY_SEARCH_SUB } from '../../components/AssetSelect.js';

const SEARCH_ASSET_MODELS_QUERY = gql`
  query ASSET_MODELS_QUERY($searchInput: CollectionSearchParams) {
    searchAssetManufacturesModels(input: $searchInput) {
      hits {
        _id
        name
        lifeExpectancy
        isInUseByAsset
        replacementCost
      }
      total
    }
  }
`;

const ASSET_MODELS_SUBSCRIPTION = gql`
  subscription ASSET_MODELS_SUBSCRIPTION($searchInput: CollectionSearchParams) {
    searchAssetModelChanged(input: $searchInput) {
      id
      type
    }
  }
`;

export const useAssetManufactureModels = (manufacturerId, sort) => {
  const paginationParams = usePaginationParams();
  const searchText = useSelector(R.prop('searchText'));

  const searchInput = {
    searchTerm: searchText,
    query: getAssetModelsQuery(manufacturerId),
    sort,
    ...paginationParams,
  };

  const assetSubscriptionOptions = {
    variables: { input: { query: { term: { manufacturerId } } } },
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_ASSET_MODELS_QUERY,
    [ASSET_MODELS_SUBSCRIPTION, ASSETS_BY_SEARCH_SUB],
    {
      queryOptions: {
        variables: { searchInput },
        fetchPolicy: 'network-only',
      },
      subscriptionOptions: [{ searchInput }, assetSubscriptionOptions],
    },
  );

  useSetItemsCount(
    R.pathOr(0, ['searchAssetManufacturesModels', 'total']),
    data,
  );

  const assetModels = R.pathOr(
    [],
    ['searchAssetManufacturesModels', 'hits'],
    data,
  );

  return {
    assetModels,
    loading,
  };
};
