import * as R from 'ramda';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { bool, func, shape, string } from 'prop-types';
import { DatePicker, FormField, Input, Button } from 'poly-book-admin';
import { useModalContext } from 'poly-admin-ui';

import { FormFieldWrapper } from './components.js';
import { subcontractorAgreementFormId } from './constants.js';
import { SubcontractorAgreementDocument } from './SubcontractorAgreementDocument.js';
import { SubcontractorAgreementDownloadBtn } from './SubcontractorAgreementDownloadBtn.js';

const FlexColumn = styled.div`
  display: flex;
  gap: 10px;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  padding: 0;
  margin: 0;
`;

const FormSection = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const InputS = styled(Input)`
  margin: 0;
`;

const FormWrapper = styled.div`
  background: #f7f7f7;
  padding: 12px;
`;

const AgreeBtnS = styled(Button)`
  font-size: 10px;
`;

const RejectBtnS = styled(Button)`
  font-size: 12px;
  background: #ebebeb;
  color: #575757;
`;

const HeaderWrapper = styled(FlexColumn)`
  padding-bottom: 15px;
  border-bottom: 2px solid #f1f1f1;
  margin-right: 2px;
`;

const Wrapper = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    margin: 0 auto;
    width: 100vw;
    padding: 0 20px;
  }
`;

const FormContainer = styled.div`
  height: 80vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #636360;
  }
`;

// validateRequiredInputField :: String -> String -> String Boolean
const validateRequiredInputField = (errMsg) =>
  R.ifElse(R.identity, R.F, R.always(errMsg));

function SubcontractorAgreementFormComp({
  form,
  values,
  handleSubmit,
  handleReject,
}) {
  const bottomFormRef = useRef(null);

  const handleScroll = (e) => {
    // +2 pixels to scrolled height to prevent js miscalculations
    const scrolledHeight = e.target.scrollHeight - e.target.scrollTop - 2;
    const isBottom = scrolledHeight <= e.target.clientHeight;

    if (isBottom) {
      form.change('isScrolledToBottom', true);
    }
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title>Subcontractor Agreement</Title>
        <SubcontractorAgreementDownloadBtn
          values={values}
          saveAgreementDownloadTime
        />
      </HeaderWrapper>
      <FormContainer onScroll={handleScroll}>
        <SubcontractorAgreementDocument
          {...values}
          bottomFormRef={bottomFormRef}
        />
        <FormWrapper>
          <form id={subcontractorAgreementFormId} onSubmit={handleSubmit}>
            <FormSection>
              <FormFieldWrapper>
                <FormField
                  name="contactName"
                  additionalProps={{
                    label: 'Your name',
                    height: '34px',
                    required: true,
                  }}
                  Component={InputS}
                  validate={validateRequiredInputField('Name is required')}
                />
              </FormFieldWrapper>
              <FormFieldWrapper>
                <FormField
                  name="date"
                  disabled
                  additionalProps={{
                    width: '207px',
                    size: 'big',
                    disabled: true,
                    label: 'Agreement date',
                  }}
                  Component={DatePicker}
                />
              </FormFieldWrapper>
            </FormSection>
            <FormSection>
              <FormFieldWrapper>
                <FormField
                  name="title"
                  additionalProps={{
                    label: 'Your title',
                    height: '34px',
                    required: true,
                  }}
                  Component={InputS}
                  validate={validateRequiredInputField('Title is required')}
                />
              </FormFieldWrapper>
              <FormFieldWrapper>
                <FormField
                  name="companyName"
                  additionalProps={{
                    label: 'Company name',
                    disabled: true,
                    height: '34px',
                  }}
                  Component={InputS}
                />
              </FormFieldWrapper>
            </FormSection>
            <FormSection ref={bottomFormRef}>
              <RejectBtnS onClick={handleReject} size="middle">
                Reject
              </RejectBtnS>
              <AgreeBtnS size="middle" form={subcontractorAgreementFormId}>
                I Agree
              </AgreeBtnS>
            </FormSection>
          </form>
        </FormWrapper>
      </FormContainer>
    </Wrapper>
  );
}

SubcontractorAgreementFormComp.propTypes = {
  values: shape({
    isAgree: bool,
    title: string,
    contactName: string,
    companyName: string,
    isScrolledToBottom: bool,
  }),
  handleSubmit: func.isRequired,
  handleReject: func.isRequired,
  form: shape({ change: func.isRequired }),
};

export function SubcontractorAgreementForm({
  agreementInfo,
  setAgreementInfo,
}) {
  const { closeModal } = useModalContext();

  const handleSubmit = async (values) => {
    setAgreementInfo({ ...values, isAgree: true });
    closeModal(subcontractorAgreementFormId);
  };

  const handleReject = () => closeModal(subcontractorAgreementFormId);

  return (
    <Form
      onSubmit={handleSubmit}
      handleReject={handleReject}
      initialValues={agreementInfo}
      formId={subcontractorAgreementFormId}
      render={SubcontractorAgreementFormComp}
    />
  );
}

SubcontractorAgreementForm.propTypes = {
  agreementInfo: shape({
    title: string,
    isAgree: bool,
    contactName: string,
    companyName: string,
    companyAddress: string,
    isScrolledToBottom: bool,
  }),
  setAgreementInfo: func.isRequired,
};
