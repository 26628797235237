import React from 'react';
import * as R from 'ramda';
import { getYear } from 'date-fns';
import styled from 'styled-components';
import { bool, func, number, oneOfType, string } from 'prop-types';
import { moneyColumnStyles, Select, Table } from 'poly-book-admin';

export const Supplier1099ReportTableComp = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }

  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(5),
  td:nth-child(5),
  th:nth-child(6),
  td:nth-child(6) {
    width: 110px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 150px;
  }

  th:nth-child(3),
  td:nth-child(3),
  th:nth-child(7),
  td:nth-child(7),
  th:nth-child(8),
  td:nth-child(8),
  th:nth-child(9),
  td:nth-child(9) {
    width: 55px;
  }

  th:nth-child(10),
  td:nth-child(10) {
    width: 75px;
  }

  ${moneyColumnStyles(11)};
  ${moneyColumnStyles(12)};
  ${moneyColumnStyles(13)};
`;

export function Supplier1099Select({ name, value, onChange }) {
  const supplier1099SelectOptions = [
    { value: 'all', label: 'All Suppliers' },
    { value: true, label: '1099 Suppliers' },
    { value: false, label: 'Not 1099 Suppliers' },
  ];

  const supplier1099SelectProps = {
    name,
    value,
    onChange,
    width: '170px',
    options: supplier1099SelectOptions,
    required: true,
  };

  return <Select {...supplier1099SelectProps} />;
}

Supplier1099Select.propTypes = {
  value: oneOfType([string, number, bool]).isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
};

export function ThresholdSelect({ name, value, onChange }) {
  const thresholdSelectOptions = [
    { value: 0, label: 'Full Spend' },
    { value: 600, label: '$600 and Greater' },
  ];

  const thresholdSelectProps = {
    name,
    onChange,
    width: '170px',
    value: Number(value),
    options: thresholdSelectOptions,
    required: true,
  };

  return <Select {...thresholdSelectProps} />;
}

ThresholdSelect.propTypes = {
  value: oneOfType([string, number]).isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
};

export function YearSelect({ name, value, onChange }) {
  const yearSelectOptions = R.compose(
    R.map((year) => ({ value: year, label: year })),
    R.range(2020),
    R.inc,
    getYear,
  )(new Date());

  const yearSelectProps = {
    name,
    onChange,
    width: '130px',
    value: Number(value),
    options: yearSelectOptions,
    required: true,
  };

  return <Select {...yearSelectProps} />;
}

YearSelect.propTypes = {
  value: oneOfType([string, number]).isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
};
