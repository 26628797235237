import React, { useCallback } from 'react';
import * as R from 'ramda';
import { Checkbox, Input } from 'poly-book-admin';
import {
  optionalAddressValidators,
  ifNotEmpty,
  validatePhone,
} from 'poly-client-utils';
import { commonEmailValidators } from 'poly-client-utils/src/formValidators.js';
import { debounce } from 'poly-utils/src/general.js';
import { func } from 'prop-types';

import { halfWidth } from '../../common.js';
import { EditorS, StatusDropDown } from './components/index.js';
import { AddressSection, PhoneNumberInput } from '../../fields/index.js';
import { DuplicateContactMsg } from './components/DuplicateContactMsg.js';
import { InternationalAddressSection } from '../../../../components/InternationalAddressAndPhone/InternationalAddressSection/InternationalAddressSection.js';
import { InternationalPhoneInput } from '../../../../components/InternationalAddressAndPhone/InternationalPhone/InternationalPhoneInput.js';
import { ExtInput } from '../../../../components/ExtInput.js';
import { internationalAddressNotRequiredValidators } from '../../../../components/InternationalAddressAndPhone/InternationalAddressSection/validators.js';

function EmailInput({ onChange, ...props }) {
  const onChangeDebounced = useCallback(debounce(500)(onChange), []);
  return <Input placeholder="Email" {...props} onChange={onChangeDebounced} />;
}

EmailInput.propTypes = {
  onChange: func.isRequired,
};

const infoSection = (isClientContact = false) => [
  {
    label: 'Status',
    order: 1,
    layout: { row: 1, width: halfWidth },
    field: {
      name: 'status',
      Component: StatusDropDown,
    },
    required: true,
  },
  {
    label: 'Client Manager',
    order: 2,
    layout: { row: 1, width: halfWidth },
    field: {
      name: 'isClientManager',
      Component: Checkbox,
    },
    renderIf: R.always(isClientContact),
  },
  {
    label: 'Email',
    order: 2,
    layout: { row: 2, width: halfWidth, padding: '0 0 5px 0' },
    field: {
      name: 'email',
      Component: EmailInput,
    },
    validators: commonEmailValidators,
  },
  {
    order: 3,
    layout: { row: 3 },
    field: {
      name: 'contact',
      withFormData: true,
      withChangeFieldValue: true,
      Component: DuplicateContactMsg,
    },
    renderIf: R.prop('isCreateMode'),
  },
  {
    label: 'First Name',
    order: 4,
    layout: { row: 4, width: halfWidth },
    field: {
      name: 'firstName',
      Component: (props) => <Input placeholder="First Name" {...props} />,
    },
    validators: [[R.identity, 'You must enter a name']],
  },
  {
    label: 'Last Name',
    order: 5,
    layout: { row: 4, width: halfWidth },
    field: {
      name: 'lastName',
      Component: (props) => <Input placeholder="Last Name" {...props} />,
    },
  },
  {
    label: 'Title',
    order: 6,
    layout: { row: 5, width: halfWidth, padding: '0 0 5px 0' },
    field: {
      name: 'title',
      Component: (props) => <Input placeholder="Title" {...props} />,
    },
  },
];

const remarksSection = [
  {
    label: 'Remarks',
    order: 12,
    field: {
      name: 'remarks',
      Component: EditorS,
    },
    layout: {
      width: '100%',
    },
  },
];

export const peopleFormSections = (isClientContact) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      ...infoSection(isClientContact),
      {
        label: 'Work Phone',
        order: 7,
        layout: {
          row: 6,
          width: 'calc(30% - 10px)',
        },
        field: {
          name: 'workPhone',
          Component: (props) => (
            <PhoneNumberInput placeholder="Work Phone" {...props} />
          ),
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
      {
        label: 'Ext',
        order: 8,
        layout: {
          row: 6,
          width: 'calc(20% - 20px)',
        },
        field: {
          name: 'ext',
          Component: ExtInput,
        },
      },
      {
        label: 'Mobile Phone',
        order: 9,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'mobilePhone',
          Component: (props) => (
            <PhoneNumberInput placeholder="Mobile Phone" {...props} />
          ),
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
      {
        label: 'Fax',
        order: 10,
        layout: { row: 7, width: halfWidth },
        field: {
          name: 'fax',
          Component: (props) => (
            <PhoneNumberInput placeholder="Fax" {...props} />
          ),
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect fax number']],
      },
      {
        order: 11,
        layout: { row: 8, width: '100%' },
        field: {
          name: 'profile.address',
          Component: (props) => (
            <AddressSection required={false} isClearable {...props} />
          ),
        },
        validators: optionalAddressValidators,
      },
      ...remarksSection,
    ],
  },
];

export const internationalPeopleFormSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      ...infoSection(),
      {
        label: 'Work Phone',
        order: 7,
        layout: {
          row: 6,
          width: 'calc(50% - 10px)',
        },
        field: {
          name: 'workPhone',
          withFormData: true,
          Component: InternationalPhoneInput,
        },
        validators: [],
      },
      {
        label: 'Ext',
        order: 8,
        layout: {
          row: 7,
          width: 'calc(50% - 10px)',
        },
        field: {
          name: 'ext',
          Component: ExtInput,
        },
      },
      {
        label: 'Mobile Phone',
        order: 9,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'mobilePhone',
          withFormData: true,
          Component: InternationalPhoneInput,
        },
        validators: [],
      },

      {
        order: 11,
        layout: { row: 8 },
        field: {
          name: 'profile.address',
          Component: (props) => (
            <InternationalAddressSection isClearable {...props} />
          ),
        },
        validators: internationalAddressNotRequiredValidators,
      },
      ...remarksSection,
    ],
  },
];
