import * as R from 'ramda';
import { useEffect, useRef } from 'react';
import { useLocation, parseQuery } from 'poly-client-routing';
import { useOutSidebarContext } from 'poly-client-utils';

import { SidebarIDs } from '../sidebars/constants.js';

// checkQueryIsEmpty :: String -> Boolean
const checkQueryIsEmpty = R.compose(
  R.isEmpty,
  R.reject((key) =>
    R.compose(R.not, R.any(R.equals(key)), R.values)(SidebarIDs),
  ),
  R.keys,
  parseQuery,
);

export const useOpenSidebarByProps = ({
  id,
  name,
  skipSteady,
  sidebarProps,
  hasPermission,
  openSidebarHandler,
}) => {
  const { isSidebarOpened } = useOutSidebarContext();
  const sidebarID = useRef(null);

  useEffect(() => {
    sidebarID.current = id;
  }, []);

  const isSidebarIDChanged = !!sidebarID.current && id !== sidebarID.current;

  useEffect(() => {
    if (hasPermission && id && (!isSidebarOpened(name) || isSidebarIDChanged)) {
      openSidebarHandler(sidebarProps || id, skipSteady);
      sidebarID.current = id;
    }
  }, [id, hasPermission]);
};

export const useCloseSidebarByProps = ({ id, name }) => {
  const { search } = useLocation();
  const { closeOutSidebar, isSidebarOpened, closeAllOutSidebars } =
    useOutSidebarContext();
  const isClosed = checkQueryIsEmpty(search);

  useEffect(() => {
    if (!id && isSidebarOpened(name)) {
      if (isSidebarOpened(SidebarIDs.project) || isClosed) {
        closeAllOutSidebars(isSidebarOpened(SidebarIDs.project));
      }

      if (name === SidebarIDs.contact) {
        closeOutSidebar(SidebarIDs.contact);
      }
    }
  }, [id]);
};
