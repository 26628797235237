import React from 'react';
import { InputHTML5 } from 'poly-book-admin';

// should allow to enter both numbers and letters + space, because
// of Canadian zip codes
export function ZipCodeInput(props) {
  return <InputHTML5 placeholder="Enter Zip" maxLength={10} {...props} />;
}

ZipCodeInput.displayName = 'ZipCodeInput';

export function ZipCodeSuffixInput(props) {
  return <InputHTML5 {...props} placeholder="Enter Suffix" maxLength={4} />;
}
