import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import React, { useMemo } from 'react';
import { string, func } from 'prop-types';
import { useReactiveQuery } from 'poly-client-utils';
import { collectionNames } from 'poly-constants';
import { Loader, Text } from 'poly-book-admin';
import { isNilOrEmpty } from 'poly-utils';

import { MAX_ITEMS } from '../../../constants/general.js';
import { commonUpdateFields } from '../../../hocs/updates/fragments.js';
import { CommonTableFormWrapper } from './useSidebarTasksTableHandlers.js';
import { FILES_SEARCH_CHANGED } from '../../../hooks/files/subscriptions.js';
import { UPDATES_SEARCH_CHANGED } from '../../../hooks/updates/subscriptions.js';
import { UpdatesTable } from '../../../modules/tables/updatesTable/UpdatesTable.js';
import { useSidebarTableFormContext } from '../../SidebarWrapperForTableForm.js';

// eslint-disable-next-line import/no-unused-modules
export const COMPLETED_TASK_UPDATES_LIST_QUERY = gql`
  query COMPLETED_TASK_UPDATES_LIST_QUERY(
    $id: ID!
    $input: DocumentUpdatesInput!
  ) {
    task(id: $id) {
      _id
      description
      documentUpdates(input: $input) {
        hits {
          ...commonUpdateFields
        }
      }
    }
  }

  ${commonUpdateFields}
`;

const CommonTableFormWrapperS = styled(CommonTableFormWrapper)`
  padding: 24px 0 0 0 !important;

  table {
    min-height: unset;

    tr {
      padding: 0 24px;
    }

    td:not(:first-child) {
      width: unset;
    }
  }
`;

const CompletedTaskUpdatesTitleS = styled(Text)`
  padding: 0 0 24px 24px;
`;

// getCompletedTaskUpdatesTitle :: TaskQueryResult -> String
const getCompletedTaskUpdatesTitle = R.pathOr('', ['task', 'description']);

// prepareTaskUpdates :: Object -> TaskQueryResult -> [Update]
const prepareTaskUpdates = (additionalRowInfo) =>
  R.compose(
    R.map(R.mergeRight(additionalRowInfo)),
    R.reject(isNilOrEmpty),
    R.pathOr([], ['task', 'documentUpdates', 'hits']),
  );

function CompletedTaskUpdates({ taskId, onCancel }) {
  const queryOptions = {
    variables: {
      id: taskId,
      input: {
        from: 0,
        size: MAX_ITEMS,
        includeAudit: false,
        sort: { createdAt: -1 },
      },
    },
    skip: !taskId,
  };

  const { data, loading, debouncedRefetch } = useReactiveQuery(
    COMPLETED_TASK_UPDATES_LIST_QUERY,
    [UPDATES_SEARCH_CHANGED, FILES_SEARCH_CHANGED],
    {
      queryOptions,
      subscriptionOptions: [
        { variables: { searchInput: { documentId: taskId } }, skip: !taskId },
        { variables: { searchInput: { documentId: taskId } }, skip: !taskId },
      ],
    },
  );

  const additionalRowInfo = useMemo(
    () => ({
      debouncedRefetch,
      withoutIcons: true,
      setActiveReply: () => null,
      collection: collectionNames.tasks,
    }),
    [debouncedRefetch],
  );

  const title = useMemo(() => getCompletedTaskUpdatesTitle(data), [data]);

  const updates = useMemo(
    () => prepareTaskUpdates(additionalRowInfo)(data),
    [data, additionalRowInfo],
  );

  return (
    <CommonTableFormWrapperS skipMargin onCancel={onCancel}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <CompletedTaskUpdatesTitleS>{title}</CompletedTaskUpdatesTitleS>
          {!R.isEmpty(updates) && <UpdatesTable updates={updates} />}
        </>
      )}
    </CommonTableFormWrapperS>
  );
}

CompletedTaskUpdates.propTypes = {
  taskId: string.isRequired,
  onCancel: func.isRequired,
};

export const useCompletedTaskUpdatesPreview = () => {
  const { formSetter } = useSidebarTableFormContext();

  const onCancel = () => formSetter(null);

  return ({ _id, taskId }) =>
    formSetter({
      elementId: _id,
      Content: <CompletedTaskUpdates {...{ taskId, onCancel }} />,
    });
};
