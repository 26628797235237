import * as R from 'ramda';
import { taskActionTypes } from 'poly-constants';

export const getProjectTasksInput = (projectId, onlyDefault) => ({
  collection: 'projects',
  documentId: projectId,
  ...(!!onlyDefault && {
    actions: R.values(taskActionTypes),
  }),
});
