import React from 'react';
import { shape, string } from 'prop-types';
import { copyToClipBoard } from 'poly-client-utils';
import { Popover, TextButton } from 'poly-book-admin';
import { getMagicEmailForDocument } from 'poly-utils';
import styled from 'styled-components';

import { useNotificationState } from '../../../hooks/useNotificationState.js';

const PopoverS = styled(Popover)`
  button {
    font-size: 12px;
  }
`;

const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

// getMagicEmailWithAddress :: Object -> Email
const getMagicEmailWithAddress = getMagicEmailForDocument(
  process.env.MAGIC_EMAIL,
);

export function CopyMagicEmailButton({ collection, entity }) {
  const { showSuccessNotification } = useNotificationState();

  const onCopyEmailClick = (isClientUpdate) => () => {
    const magicEmail = getMagicEmailWithAddress({
      collection,
      documentId: entity._id,
      isClientUpdate,
    });

    copyToClipBoard(magicEmail);
    showSuccessNotification(`Copied ${magicEmail}`);
  };

  return (
    <PopoverS
      title="Magic Email"
      position="bottom"
      content={
        <PopoverContentWrapper>
          <TextButton onClick={onCopyEmailClick(false)}>Normal</TextButton>
          <TextButton onClick={onCopyEmailClick(true)}>
            Client Update
          </TextButton>
        </PopoverContentWrapper>
      }
    />
  );
}

CopyMagicEmailButton.displayName = 'CopyMagicEmailButton';
CopyMagicEmailButton.propTypes = {
  collection: string.isRequired,
  entity: shape({
    _id: string.isRequired,
  }).isRequired,
};
