import * as R from 'ramda';
import { NEW_YORK_STATE_SHORTNAME, ProjectType } from 'poly-constants';
import {
  assocBy,
  calculateTotal,
  calculateTimeEntryCost,
  convertCentsToDollars,
  getUIInvoiceNumber,
  convertDollarsToCents,
} from 'poly-utils';

import { formatAccountOptionText } from '../../components/AccountsSelect.js';

// getProjectTMMarkupRules :: Project -> [ClientMarkupRule]
const getProjectTMMarkupRules = R.ifElse(
  R.pathSatisfies(R.either(R.isNil, R.isEmpty), ['property', 'tmMarkupRules']),
  R.pathOr([], ['client', 'tmMarkupRules']),
  R.path(['property', 'tmMarkupRules']),
);

// getWoChargeAmount :: Project -> Number
export const getWoChargeAmount = R.either(
  R.path(['property', 'woCharge']),
  R.path(['client', 'woCharge']),
);

// formatProjectInfo :: Project -> Object
export const formatProjectInfo = R.compose(
  assocBy(
    'isTaxRateDisabled',
    R.either(
      R.prop('exemptSalesTax'),
      R.propEq('state', NEW_YORK_STATE_SHORTNAME),
    ),
  ),
  R.applySpec({
    _id: R.prop('_id'),
    type: R.prop('type'),
    feeAmount: R.compose(convertDollarsToCents, R.prop('feeAmount')),
    referralFee: R.compose(
      convertDollarsToCents,
      R.path(['listing', 'referralFee']),
    ),
    customFeeDescription: R.prop('customFeeDescription'),
    projectId: R.prop('projectId'),
    clientId: R.path(['client', '_id']),
    clientName: R.path(['client', 'name']),
    isClientInvoicingEmailEnabled: R.pathOr(false, [
      'client',
      'configs',
      'clientInvoicing',
      'email',
      'enabled',
    ]),
    description: R.prop('description'),
    invoiceDescription: R.prop('invoiceDescription'),
    clientAddress: R.path(['client', 'address', 'formatted_address']),
    clientReferenceNumber: R.either(
      R.prop('clientReferenceNumber'),
      R.path(['adminPurchaseOrder', 'poNumber']),
    ),
    terms: R.path(['client', 'financial', 'terms']),
    serviceAddress: R.compose(
      R.join('\n'),
      R.juxt([
        R.path(['property', 'name']),
        R.path(['property', 'address', 'formatted_address']),
      ]),
    ),
    state: R.ifElse(
      R.prop('property'),
      R.path([
        'property',
        'address',
        'address_parts',
        'administrative_area_level_1',
      ]),
      R.path([
        'client',
        'address',
        'address_parts',
        'administrative_area_level_1',
      ]),
    ),
    rate: R.ifElse(
      R.prop('exemptSalesTax'),
      R.always(null),
      R.ifElse(
        R.path(['property', 'taxRate']),
        R.path(['property', 'taxRate']),
        R.path(['client', 'taxRate']),
      ),
    ),
    exemptSalesTax: R.propOr(false, 'exemptSalesTax'),
    jobCosts: R.compose(
      R.map(
        R.applySpec({
          _id: R.prop('_id'),
          amount: R.prop('total'),
          invoiceDate: R.prop('invoiceDate'),
          invoiceNumber: getUIInvoiceNumber,
          supplierName: R.path(['supplier', 'company', 'name']),
          glCode: R.path(['glAccount', 'name']),
        }),
      ),
      R.pathOr([], ['searchInvoices', 'hits']),
    ),
    woCharge: getWoChargeAmount,
    timeTracking: R.compose(
      R.map(
        R.applySpec({
          _id: R.prop('_id'),
          date: R.prop('date'),
          user: R.path(['owner', 'fullName']),
          hours: R.compose(
            R.divide(R.__, 60),
            R.propOr(0, 'totalTimeInMinutes'),
          ),
          rate: R.compose(convertDollarsToCents, R.propOr(0, 'rate')),
          notes: R.prop('notes'),
          rateDescription: R.prop('rateDescription'),
          isInvoiced: R.propOr(false, 'isInvoiced'),
          total: R.compose(convertDollarsToCents, calculateTimeEntryCost),
        }),
      ),
      R.pathOr([], ['timeEntries']),
    ),
    isAfterHoursCall: R.prop('isAfterHoursCall'),
    afterHoursCallRate: R.compose(
      convertDollarsToCents,
      R.pathOr(0, ['client', 'staffRates', 'afterHoursCall']),
    ),
    tmMarkupRules: getProjectTMMarkupRules,
    divisionAccountCode: R.compose(
      formatAccountOptionText,
      R.ifElse(
        R.hasPath(['division', '_id']),
        R.prop('division'),
        R.pathOr({}, ['client', 'financial', 'division']),
      ),
    ),
    costCenter: R.prop('costCenter'),
    enableCostCenter: R.path(['client', 'enableCostCenter']),
  }),
);

// calculateLineTotal :: Object -> Number
export const calculateLineTotal = R.converge(R.multiply, [
  R.propOr(0, 'rate'),
  R.propOr(0, 'quantity'),
]);

// calculateLinesTotal :: [Object] -> Number
export const calculateLinesTotal = calculateTotal(calculateLineTotal);

// getProjectWoCharge :: Project -> Float
export const getProjectWoCharge = R.ifElse(
  R.both(
    R.propEq('type', ProjectType.WORK_ORDER),
    R.either(
      R.pathSatisfies(R.is(Number), ['client', 'woCharge']),
      R.pathSatisfies(R.is(Number), ['property', 'woCharge']),
    ),
  ),
  R.compose(convertCentsToDollars, getWoChargeAmount),
  R.always(0),
);
