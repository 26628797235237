import * as R from 'ramda';
import { Checkbox } from 'poly-book-admin';

import { halfWidth } from '../../../forms/common.js';
import { FinancialTermsSelect } from '../../../forms/supplierForm/form/components/FinancialTermsSelect.js';
import { SupplierFormCheckbox } from '../../../forms/supplierForm/index.js';
import { ChooseFileField } from '../../../forms/fields/ChooseFileField.js';

export const renderIfPayByBranch = R.propSatisfies(R.not, 'payByMaster');

export const financialSection = [
  {
    label: 'Financial',
    order: 4,
    layout: { column: 2 },
    fields: [
      {
        label: 'Pay By Master',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'payByMaster',
          Component: SupplierFormCheckbox,
        },
      },
      {
        label: 'Accepts Credit Card',
        order: 2,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'finance.acceptsCreditCard',
          Component: Checkbox,
        },
        renderIf: renderIfPayByBranch,
      },
      {
        label: 'Terms',
        order: 3,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'finance.terms',
          Component: FinancialTermsSelect,
        },
        renderIf: renderIfPayByBranch,
      },
      {
        label: 'W9',
        order: 8,
        layout: { row: 1, width: '50%' },
        field: {
          name: 'tax.w9File',
          withFormData: true,
          Component: ChooseFileField,
        },
      },
    ],
  },
];
