import * as R from 'ramda';
import { calculateTotal, isNilOrEmpty, isValidDate } from 'poly-utils';
import { JournalTypes } from 'poly-constants';

// getBatchPaymentsByData :: Any -> [MoneyTransaction]
export const getBatchPaymentsByData = R.compose(
  R.map(
    R.converge(R.mergeRight, [
      R.identity,
      R.compose(
        R.over(R.lensProp('amount'), R.multiply(-1)),
        R.pathOr({}, ['lines', 0]),
      ),
    ]),
  ),
  R.pathOr([], ['searchMoneyTransactions', 'hits']),
);

// convertPaymentsIdsToBatchNumbers :: [ID] -> [Int]
export const convertPaymentsIdsToBatchNumbers = R.map((id) => parseInt(id, 10));

// filterSelectedPayments :: [Payment] -> [String] -> [Payment]
const filterSelectedPayments = R.curry((payments, selectedPayments) =>
  R.filter(
    R.propSatisfies(R.includes(R.__, selectedPayments), '_id'),
    payments,
  ),
);

// calculateSelectedPaymentsTotal :: [MoneyTransaction] -> [ID] -> Number
export const calculateSelectedPaymentsTotal = (
  batchPayments,
  selectedPayments,
) =>
  R.compose(
    calculateTotal(R.propOr(0, 'amount')),
    filterSelectedPayments(R.__, selectedPayments),
  )(batchPayments);

// getVoidSupplierPaymentsSearchQueryByFilters :: VoidSupplierPaymentsFilters -> ElasticQuery
// VoidSupplierPaymentsFilters = {
//   checkNumber: Int,
//   supplierIds: [ID],
//   checkToDate: Date,
//   checkFromDate: Date,
// }
export const getVoidSupplierPaymentsSearchQueryByFilters = R.compose(
  R.objOf('bool'),
  R.objOf('filter'),
  R.reject(isNilOrEmpty),
  R.juxt([
    R.compose(
      R.ifElse(
        R.isEmpty,
        R.always(null),
        R.compose(R.objOf('range'), R.objOf('date')),
      ),
      R.converge(R.mergeLeft, [
        R.compose(
          R.ifElse(isValidDate, R.objOf('gte'), R.always({})),
          R.prop('checkFromDate'),
        ),
        R.compose(
          R.ifElse(isValidDate, R.objOf('lte'), R.always({})),
          R.prop('checkToDate'),
        ),
      ]),
    ),
    R.compose(
      R.ifElse(
        isNilOrEmpty,
        R.always(null),
        R.compose(R.objOf('terms'), R.objOf('supplier_id')),
      ),
      R.prop('supplierIds'),
    ),
    R.compose(
      R.ifElse(
        R.is(Number),
        R.compose(R.objOf('term'), R.objOf('check_number')),
        R.always(null),
      ),
      R.prop('checkNumber'),
    ),
    R.always({ term: { type: JournalTypes.ACC_PAYABLE_PAYMENT } }),
  ]),
);
