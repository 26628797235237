import copyToClipboard from 'copy-to-clipboard';
import { useUpdateHash } from 'poly-client-routing';
import { forceTitleCase } from 'poly-utils';

import { useNotificationState } from '../../../hooks/useNotificationState.js';

export const useCopyLinkHook = (entity, id) => {
  const updateHash = useUpdateHash(entity);
  const { showSuccessNotification } = useNotificationState();

  return () => {
    updateHash(id);
    copyToClipboard(window.location.href);
    showSuccessNotification(`${forceTitleCase(entity)} link copied!`);
  };
};
