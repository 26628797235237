import React from 'react';
import { propertyOwnershipTypes } from 'poly-constants';
import { formatSelectOptions } from 'poly-admin-ui';
import { Select } from 'poly-book-admin';

export function OwnerShipSelect(props) {
  const options = formatSelectOptions(propertyOwnershipTypes);

  return <Select {...props} options={options} />;
}
