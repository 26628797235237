import React from 'react';
import { number, string, bool } from 'prop-types';
import { entities } from 'poly-admin-ui';

import { SidebarContactsTab } from '../../components/commonTabs/SidebarContactsTab.js';

export function ClientSidebarContactsTab({ doubleTabWidth, clientId, isCard }) {
  return (
    <SidebarContactsTab
      isCard={isCard}
      tabWidth={doubleTabWidth}
      entity={{ _id: clientId, name: entities.CLIENT }}
    />
  );
}

ClientSidebarContactsTab.propTypes = {
  isCard: bool,
  clientId: string.isRequired,
  doubleTabWidth: number.isRequired,
};
