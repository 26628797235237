import React from 'react';
import * as R from 'ramda';
import { arrayOf } from 'prop-types';
import { openPrintWindowWithData } from 'poly-client-utils';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  LinkButton,
  MainHeader,
} from 'poly-book-admin';

import { batchInvoicesPropType } from './propTypes.js';
import { PrintLayoutProvider } from '../../providers.js';
import { BatchInvoiceReportsDetailsTable } from './BatchInvoiceReportsDetailsTable.js';

function BatchInvoiceReportsDetailsPrintPDF({ batchInvoices }) {
  const hidden = R.isEmpty(batchInvoices);

  const onClick = () =>
    openPrintWindowWithData({
      batchInvoices,
      Layout: PrintLayoutProvider,
      fileName: 'batch_invoice_report',
      Table: BatchInvoiceReportsDetailsTable,
      metaData: { title: 'Batch Invoice Report Details' },
    });

  return (
    <LinkButton onClick={onClick} hidden={hidden}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

BatchInvoiceReportsDetailsPrintPDF.propTypes = {
  batchInvoices: arrayOf(batchInvoicesPropType),
};

export function BatchInvoiceReportsDetailsHeader({ batchInvoices }) {
  return (
    <PageHeaderContainer>
      <MainHeader>Batch Invoice Report Details</MainHeader>
      <HeaderLinksWrapper>
        <BatchInvoiceReportsDetailsPrintPDF batchInvoices={batchInvoices} />
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}

BatchInvoiceReportsDetailsHeader.propTypes = {
  batchInvoices: arrayOf(batchInvoicesPropType),
};
