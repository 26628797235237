import React from 'react';
import * as R from 'ramda';
import { func, number, shape, string } from 'prop-types';
import { TableWithPaginationContainer, WhiteCard } from 'poly-book-admin';
import {
  TablePagination,
  TitleWithoutPip,
  TitleWithPip,
  PageSubTabs,
} from 'poly-admin-ui';

import { duplicateField } from 'poly-client-utils';
import {
  projectStatusesUI,
  projectUIStatuses,
  projectStatusesColors,
} from '../../../modules/core/constants/projects.js';
import { AddEntityProjectBtn } from '../../../modules/tabs/entityProjectsTab/ToolBar.js';
import { setDefaultProjectManager } from '../../../modules/tables/projectsTable/projectsTableUtils.js';

const { ALL, ACTIVE } = projectUIStatuses;

const withPipStatus = (status) => R.none(R.equals(status))([ALL, ACTIVE]);

export function ProjectsTabTitleComp({ count, status }) {
  const TitleComponent = withPipStatus(status) ? TitleWithPip : TitleWithoutPip;

  return (
    <TitleComponent
      count={count}
      text={projectStatusesUI[status]}
      color={projectStatusesColors[status]}
    />
  );
}

ProjectsTabTitleComp.propTypes = {
  count: number.isRequired,
  status: string.isRequired,
};

export function MasterSupplierSubTabComp({ Table, status }) {
  return (
    <>
      <TableWithPaginationContainer paginationVisible>
        <Table status={status} />
      </TableWithPaginationContainer>
      <TablePagination />
    </>
  );
}

MasterSupplierSubTabComp.propTypes = {
  Table: func.isRequired,
  status: string.isRequired,
};

export function MasterSupplierProjectsSubTabs({ newItemProps, ...props }) {
  return (
    <WhiteCard>
      <PageSubTabs
        toolBar={<AddEntityProjectBtn addEntityProjectQueries={newItemProps} />}
        {...props}
        defaultValue={R.toLower(ALL)}
      />
    </WhiteCard>
  );
}

MasterSupplierProjectsSubTabs.propTypes = {
  newItemProps: shape({
    type: string,
    projectType: string,
    supplierId: string,
  }),
};

// prepareMasterSupplierRecurringToTable :: [String] -> MasterSupplier -> [RecurringProject]
export const prepareMasterSupplierProjectsToTableByPath = (path) =>
  R.compose(
    R.map(duplicateField('projectId', 'projectLinkId')),
    setDefaultProjectManager,
    R.pathOr([], path),
  );
