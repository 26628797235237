import { bool, node } from 'prop-types';
import React, { Fragment } from 'react';
import { TableCard, TableWithPaginationContainer } from 'poly-book-admin';
import { TablePagination } from 'poly-admin-ui/src/components/Pagination.js';

export function ProjectsTabContainer({
  isGeneral,
  paginationVisible,
  children,
}) {
  const Container = isGeneral ? TableCard : Fragment;
  return (
    <Container>
      <TableWithPaginationContainer paginationVisible={paginationVisible}>
        {children}
      </TableWithPaginationContainer>
      {paginationVisible && <TablePagination />}
    </Container>
  );
}

ProjectsTabContainer.propTypes = {
  isGeneral: bool,
  paginationVisible: bool,
  children: node,
};
