import React from 'react';
import styled from 'styled-components';
import { number, shape, string } from 'prop-types';
import { Icon, Popover, Text } from 'poly-book-admin';
import { getAssetLifeExpectancy, getAssetLifeExpectancyInfo } from './utils.js';

function WarningIcon() {
  return <Icon size={15} name="yellowWarning" />;
}

const Wrapper = styled.div`
  padding: 10px;
  width: 250px;
  word-break: break-word;
`;

const TextS = styled(Text)`
  font-size: 12px;
`;

const ASSET_LIFE_THRESHOLD_PERCENT = 25;

export function AssetLifeExpectancyWarning({ asset, position }) {
  const lifeExpectancy = getAssetLifeExpectancy(asset);

  const lifeInfo = getAssetLifeExpectancyInfo(asset);

  if (!lifeInfo || lifeInfo?.lifePercent > ASSET_LIFE_THRESHOLD_PERCENT) {
    return null;
  }

  const text = `This asset is ${lifeInfo?.restOfMonths} months away from its ${lifeExpectancy} years life expectancy`;

  return (
    <Popover
      Icon={WarningIcon}
      position={position}
      content={
        <Wrapper>
          <TextS>{text}</TextS>
        </Wrapper>
      }
    />
  );
}

AssetLifeExpectancyWarning.propTypes = {
  asset: shape({
    commissioningDate: string,
    modelDoc: shape({
      modelDoc: number,
    }),
  }),
  position: string,
};

AssetLifeExpectancyWarning.defaultProps = {
  position: 'right',
};
