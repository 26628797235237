import React from 'react';
import { MasterSupplierBranchesTab as MasterSupplierBranchesTabImp } from 'poly-admin-ui';
import { useRouterParams } from 'poly-client-routing';

import { routesNames } from '../../../routes/index.js';
import { getTableConfig } from '../../../modules/tables/suppliersTable/SuppliersTable.js';

export function MasterSupplierBranchesTab() {
  const { supplierId } = useRouterParams(['supplierId']);

  return (
    <MasterSupplierBranchesTabImp
      tableConfig={getTableConfig(false)}
      addBranchRoute={routesNames.ADD_BRANCH_SUPPLIER}
      supplierId={supplierId}
    />
  );
}
