import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { string, shape, bool, oneOfType, instanceOf } from 'prop-types';
import { insertQueryParamsIntoURL, isNilOrEmpty } from 'poly-utils';
import { Button, Icon } from 'poly-book-admin';

import { signedAgreementValues } from './constants.js';
import { routesNames } from '../../routes/constants.js';
import { useLogTokenFromSession } from './useLogTokenFromSession.js';

const ButtonS = styled(Button)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  background-color: #ebebeb;
  color: #1a1514;
  padding: 4px 12px;
  margin-left: 14px;
  > svg {
    margin-right: 0px;
    width: 30px;
  }
`;

const IconS = styled(Icon)`
  margin: 0;
  padding: 0;
`;

export const ownIPQuery = gql`
  query OWN_IP_QUERY {
    ownIP
  }
`;

export const saveTimestampDownloadingSubcontractorAgreementMutation = gql`
  mutation saveAgreementDownloadingTimestamp {
    saveTimestampDownloadingSubcontractorAgreement
  }
`;

// prepareUrlParams :: Object -> Object
const prepareUrlParams = (values, skipDate) =>
  R.ifElse(
    R.prop('isAgree'),
    R.compose(
      // skipping date prevent empty page for Safari iOS
      // it doesn't make sense pass date , it always today
      R.when(R.always(skipDate), R.dissoc('date')),
      R.reject(isNilOrEmpty),
      R.pick(signedAgreementValues),
    ),
    R.always({ isPrint: true }),
  )(values);

export function SubcontractorAgreementDownloadBtn({
  values,
  skipDate,
  saveAgreementDownloadTime,
  ...props
}) {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [saveTimestampDownloading] = useMutation(
    saveTimestampDownloadingSubcontractorAgreementMutation,
  );
  const logSessionToken = useLogTokenFromSession();

  const handleFileDownload = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (saveAgreementDownloadTime) {
      logSessionToken();
      await saveTimestampDownloading();
    }

    const queryResp = await apolloClient.query({ query: ownIPQuery });

    const ip = R.path(['data', 'ownIP'])(queryResp);

    const urlParams = prepareUrlParams({ ip, ...values }, skipDate);

    // we use setTimeout for Safari iOS
    setTimeout(() => {
      window.open(
        insertQueryParamsIntoURL(
          urlParams,
          routesNames.SUBCONTRACTOR_AGREEMENT,
        ),
        '_blank',
      );
    }, 0);
    setLoading(false);
  };

  return (
    <ButtonS
      disabled={loading}
      {...props}
      size="small"
      onClick={handleFileDownload}
    >
      Download
      <IconS name="download" />
    </ButtonS>
  );
}

SubcontractorAgreementDownloadBtn.propTypes = {
  onlyAgree: bool,
  saveAgreementDownloadTime: bool,
  skipDate: bool,
  values: shape({
    ip: string,
    date: oneOfType([instanceOf(Date), string]),
    isAgree: bool,
    title: string,
    contactName: string,
    companyName: string,
  }).isRequired,
};
