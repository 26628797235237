import { bool, object, oneOfType, shape, string } from 'prop-types';
import { highlightedTextPropType } from 'poly-admin-ui';

export const preparedOSIProject = shape({
  _id: string,
  id: string,
  projectId: highlightedTextPropType,
  type: string,
  workCompletionDate: oneOfType([string, object]),
  description: highlightedTextPropType,
  supplierId: highlightedTextPropType,
  supplierName: highlightedTextPropType,
  clientName: highlightedTextPropType,
  managerName: highlightedTextPropType,
  invoiceStatus: string,
  supplierHaveEmail: bool,
});
