import React from 'react';
import { object, oneOfType, shape, string } from 'prop-types';
import { SidebarRow } from 'poly-admin-ui';

import {
  SectionLabel,
  SectionWrapperWithoutBorder,
} from '../components/commonSidebarComponents.js';
import { getSectionEditor } from '../components/commonSidebarSectionFormatters.js';

export function ContactSidebarRemarks({ contactUser }) {
  return (
    <SectionWrapperWithoutBorder>
      <SidebarRow>
        <SectionLabel>Remarks</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        {getSectionEditor(contactUser.remarks)({ id: 'remarks' })}
      </SidebarRow>
    </SectionWrapperWithoutBorder>
  );
}

ContactSidebarRemarks.propTypes = {
  contactUser: shape({ remarks: oneOfType([object, string]) }).isRequired,
};
