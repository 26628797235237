import {
  bool,
  func,
  shape,
  number,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';
import React, { useEffect } from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import {
  PAYMENT_DUE_DATE_CHANGE_REASON_LENGTH_LIMIT,
  REPORT_ONLY_NEGATIVE_INVOICE_TOTAL_ERROR,
  collectionNames,
  taskActionTypes,
} from 'poly-constants';
import {
  validationFuncWithAllValues,
  convertCentsToDollars,
  convertDollarsToCents,
} from 'poly-utils';
import {
  paginationToQueryParams,
  commonFileValidators,
  validateFilesFunc,
  initialPagination,
  useReactiveQuery,
} from 'poly-client-utils';
import {
  TASK_SEARCH_CHANGED,
  MoneyInputAsCents,
  MoneyInput,
  SubmitBtn,
  CancelBtn,
} from 'poly-admin-ui';
import {
  ModalActions,
  Textarea,
  Checkbox,
  Button,
  Rating,
  Editor,
  Input,
} from 'poly-book-admin';

import {
  NO_SUPPLIER_INVOICE_TYPE,
  SupplierInvoiceFormActions,
  SupplierInvoiceFormSubmitType,
} from './constants.js';
import {
  InvoiceDateField,
  InvoiceDueDateField,
} from './components/InvoiceDateField.js';
import {
  InvoiceFormTitle,
  NonBillableProjectInvoiceWarning,
} from './components/InvoiceFormTitle.js';
import { halfWidth } from '../common.js';
import { LabourCostInput } from './components/LabourCostInput.js';
import { InvoiceTaxField } from './components/InvoiceTaxField.js';
import { RadioButtons } from '../../../components/RadioButtons.js';
import { MaterialCostInput } from './components/MaterialCostInput.js';
import { InvoiceTypeSelect } from './components/InvoiceTypeSelect.js';
import { InvoiceNumberInput } from './components/InvoiceNumberInput.js';
import { InvoiceSupplierField } from './components/InvoiceSupplierField.js';
import { AttachDocumentField } from '../../../components/AttachDocumentField.js';
import { ProjectAlreadyInvoicedText } from './components/ProjectAlreadyInvoicedText.js';
import { useCancelInvoiceRequest } from '../../../sidebars/ProjectSidebar/tabs/useCancelInvoiceRequest.js';
import { useFormButtonProps } from '../../core/hooks/users/form.js';
import {
  isTotalPositiveForReportOnlyProject,
  isTotalValidAgainstPaidAmount,
  totalAmountValidator,
  isBreakdown,
} from './supplierInvoiceFormUtils.js';
import {
  isProjectWithClientInvoice,
  placeholder,
} from './components/common.js';
import { useProjectSupplierNTEWarning } from './useProjectSupplierNTEWarning.js';
import { isNotCreditCardOrBankExpenseInvType } from '../../../pages/SearchSupplierInvoices/VoidSupplierInvoiceButton.js';

// hasNTE :: FormData -> Boolean
const hasNTE = R.propSatisfies(R.gt(R.__, 0), 'supplierNTE');

// isInvoiceRequired :: FormData -> Boolean
const isInvoiceRequired = R.propSatisfies(
  R.complement(R.equals(NO_SUPPLIER_INVOICE_TYPE)),
  'type',
);

// isNotTaskInvoice :: FormData -> Boolean
const isNotTaskInvoice = R.propSatisfies(R.isNil, 'taskSupplier');

// getDefaultSupplierNTE :: Input -> Float
// Input = {
//    supplierId: ID
//    suppliers: [Supplier]
// }
const getDefaultSupplierNTE = ({ supplierId, suppliers }) =>
  R.compose(
    convertDollarsToCents,
    R.prop('nte'),
    R.find(R.propEq('_id', supplierId)),
  )(suppliers);

const isSupplierNTE = (formData, getSupplierNTE) =>
  formData ? getSupplierNTE(formData) : null;

// getBreakdownTotal :: { labourCost: Float, materialCost: Float } -> Float
const getBreakdownTotal = R.compose(
  R.sum,
  R.juxt([
    R.propOr(0, 'labourCost'),
    R.propOr(0, 'materialCost'),
    R.propOr(0, 'taxAmount'),
  ]),
);

const totalInputLabel = 'Total Invoice';

// getTotalInputLabel :: Number -> String
const getTotalInputLabel = (supplierNTE) =>
  supplierNTE
    ? `${totalInputLabel} (NTE: $${convertCentsToDollars(supplierNTE)}) *`
    : `${totalInputLabel} *`;

export function InvoiceTotalInput({
  value,
  disabled,
  formData,
  onChange,
  skipInvoicesQuery,
  allowNegative = true,
  getSupplierNTE = getDefaultSupplierNTE,
  ...props
}) {
  const supplierNTE = isSupplierNTE(formData, getSupplierNTE);
  const supplierInvoiceId = R.path(['invoice', '_id'], formData);
  const projectId = R.path(['project', '_id'], formData);

  const warning = useProjectSupplierNTEWarning({
    supplierInvoiceId,
    projectId,
    supplierNTE,
    value,
    skipInvoicesQuery,
    supplierInvoicesTotal: formData?.supplierInvoicesTotal,
    supplierId: formData.supplierId,
  });

  const breakdown = isBreakdown(formData);
  const label = getTotalInputLabel(supplierNTE);
  const labelId = 'total_invoice';

  useEffect(() => {
    if (isBreakdown(formData)) {
      onChange(getBreakdownTotal(formData));
    }
  }, [formData]);

  return (
    <MoneyInputAsCents
      {...props}
      placeholder={breakdown ? null : placeholder}
      allowNegative={allowNegative}
      disabled={breakdown}
      warning={warning}
      name={labelId}
      value={value}
      label={label}
      id={labelId}
      onChange={onChange}
    />
  );
}

InvoiceTotalInput.propTypes = {
  disabled: bool,
  onChange: func,
  allowNegative: bool,
  getSupplierNTE: func,
  value: oneOfType([string, number]).isRequired,
  formData: shape({ suppliers: arrayOf(shape({ nte: number })) }),
  skipInvoicesQuery: bool,
};

export function SubmitButton({ formId, ...props }) {
  const formProps = useFormButtonProps(formId);
  return <SubmitBtn {...props} {...formProps} />;
}

SubmitButton.propTypes = { formId: string };

function CancelButton({ formId, ...props }) {
  const formProps = useFormButtonProps(formId, false);
  return <CancelBtn {...props} {...formProps} />;
}

CancelButton.propTypes = { formId: string };

const ApproveSubmitButton = styled(SubmitButton).attrs(() => ({
  styleType: 'accentDark',
}))`
  min-width: 180px;
`;

export const RejectButton = styled(Button).attrs(() => ({
  size: 'small',
  type: 'button',
  children: 'Reject',
}))``;

// formActionIs :: String -> { formAction: String } -> Boolean
const formActionIs = (formAction) => R.propEq('formAction', formAction);

// isFormActionApprove :: { formAction: String } -> Boolean
const isFormActionApprove = formActionIs(SupplierInvoiceFormActions.approve);

// isFormActionReject :: { formAction: String } -> Boolean
const isFormActionReject = formActionIs(SupplierInvoiceFormActions.reject);

function SubmitButtons({
  formId,
  formData,
  onCancel,
  submitCaption,
  changeFieldValue,
  withRemoveInvoice,
}) {
  const isApprove = isFormActionApprove(formData);
  const isReject = isFormActionReject(formData);

  const SubmitButtonComponent = isApprove ? ApproveSubmitButton : SubmitButton;

  const onRejectClick = () => {
    changeFieldValue('formAction', SupplierInvoiceFormActions.reject);
  };

  const cancelInvoice = useCancelInvoiceRequest(
    {
      invoiceId: formData?.invoice?._id,
    },
    onCancel,
  );

  const handleCancelInvoice = (e) => {
    e.preventDefault();
    cancelInvoice();
  };

  return (
    <ModalActions style={{ justifyContent: 'flex-end' }}>
      <CancelButton onClick={onCancel} />
      {isApprove && <RejectButton onClick={onRejectClick} />}
      {withRemoveInvoice && !isReject && (
        <Button onClick={handleCancelInvoice} size="small">
          Reject & Remove Invoice
        </Button>
      )}
      {isReject ? (
        <SubmitButton data-testid="submit-button" formId={formId}>
          Submit
        </SubmitButton>
      ) : (
        <SubmitButtonComponent data-testid="submit-button" formId={formId}>
          {submitCaption}
        </SubmitButtonComponent>
      )}
    </ModalActions>
  );
}

SubmitButtons.propTypes = {
  onCancel: func,
  withRemoveInvoice: bool,
  formId: string.isRequired,
  submitCaption: string.isRequired,
  changeFieldValue: func.isRequired,
  formData: shape({ formAction: string }),
};

function DisabledInput(props) {
  return <MoneyInput {...props} disabled />;
}

const rejectActionRadioButtonOptions = [
  {
    value: SupplierInvoiceFormSubmitType.requestNewInvoice,
    label: 'Request New Invoice',
  },
  {
    value: SupplierInvoiceFormSubmitType.rejectAndComplete,
    label: 'Reject & Complete Task',
  },
];

function RejectActionRadioButtons({ onChange, ...props }) {
  const onModeChange = (mode) => () => onChange(mode);

  return (
    <RadioButtons
      {...props}
      onChange={onModeChange}
      options={rejectActionRadioButtonOptions}
    />
  );
}

RejectActionRadioButtons.propTypes = { onChange: func };

function InvoiceDescriptionEditor({ formData: { status }, ...props }) {
  return (
    <Editor {...props} id={status} placeholder="Enter Invoice Description" />
  );
}

InvoiceDescriptionEditor.propTypes = {
  formData: shape({ status: string }).isRequired,
};

const projectTasksQuery = gql`
  query projectTasksQuery($input: TasksInput!) {
    tasks(input: $input) {
      hits {
        _id
        complete
        action
        supplierInvoice {
          _id
        }
      }
    }
  }
`;

const getTasksFromQuery = R.pathOr([], ['tasks', 'hits']);

// // getInvoiceProjectId :: Invoice -> String
const getInvoiceProjectId = R.path(['project', '_id']);

const useInvoiceProjectTasks = (invoice) => {
  const projectId = getInvoiceProjectId(invoice);

  const commonQueryOption = {
    skip: !projectId,
    fetchPolicy: 'network-only',
  };

  const { data, loading } = useReactiveQuery(
    projectTasksQuery,
    TASK_SEARCH_CHANGED,
    {
      queryOptions: {
        ...commonQueryOption,
        variables: {
          input: {
            collection: collectionNames.projects,
            documentId: projectId,
            ...paginationToQueryParams(initialPagination),
          },
        },
      },
      subscriptionOptions: {
        variables: {
          searchInput: {
            query: projectId ? { term: { documentId: projectId } } : null,
          },
        },
        ...commonQueryOption,
      },
    },
  );

  return {
    loading,
    projectTasks: getTasksFromQuery(data),
  };
};

// isProjectNotClosed :: [Task] -> Boolean
const isProjectNotClosed = R.compose(
  R.propEq('complete', false),
  R.find(R.propEq('action', taskActionTypes.CLOSE_PROJECT)),
);

// isCloseProjectAllowed :: ID -> [Invoice] -> [Invoice]
const isCloseProjectAllowed = (invoiceId) =>
  R.both(
    isProjectNotClosed,
    R.compose(
      R.all(R.prop('complete')),
      R.reject(
        R.either(
          R.pathEq(['supplierInvoice', '_id'], invoiceId),
          R.propEq('action', taskActionTypes.CLOSE_PROJECT),
        ),
      ),
    ),
  );

const CloseProjectCheckboxS = styled(Checkbox)`
  label {
    font-size: 12px;
  }

  margin: 20px 0;
`;

function CloseProjectCheckbox({ formData, ...props }) {
  const invoice = formData?.invoice;
  const { projectTasks, loading } = useInvoiceProjectTasks(invoice);

  const allowCloseProject =
    !loading && isCloseProjectAllowed(invoice?._id)(projectTasks);
  return allowCloseProject ? (
    <CloseProjectCheckboxS {...props} label="Close Project" position="left" />
  ) : null;
}

CloseProjectCheckbox.propTypes = {
  formData: shape({ invoice: shape({ _id: string }) }),
};

export const supplierInvoiceFormSections = ({
  formId,
  onCancel,
  suppliers,
  showButtons,
  withoutTitle,
  submitCaption,
  invoiceType,
  withCloseAction,
  withDueDate,
  withRemoveInvoice,
  isNonBillableProject,
}) => {
  const isNotProjectExpenseInvoice =
    isNotCreditCardOrBankExpenseInvType(invoiceType);

  const buttonsLayout = withRemoveInvoice
    ? { row: 16, width: '95%' }
    : { row: 15, width: 'calc(60% - 10px)' };

  return [
    {
      id: 'main',
      layout: { column: 1, margin: 0 },
      order: 1,
      fields: [
        {
          order: 1,
          layout: { row: 1 },
          field: {
            name: 'formTitle',
            withFormData: true,
            Component: InvoiceFormTitle,
          },
          renderIf: R.complement(R.always(withoutTitle)),
        },
        {
          order: 2,
          layout: { row: 2 },
          field: {
            name: 'nonBillableProjectWarning',
            Component: NonBillableProjectInvoiceWarning,
          },
          renderIf: R.both(
            () => isNonBillableProject,
            R.complement(
              R.propEq('formAction', SupplierInvoiceFormActions.add),
            ),
          ),
        },
        ...(isNotProjectExpenseInvoice
          ? [
              {
                order: 2,
                label: 'Supplier',
                leaveValues: true,
                layout: { row: 3, width: halfWidth, isWrapped: true },
                field: {
                  name: 'supplierId',
                  withFormData: true,
                  Component: (props) => (
                    <InvoiceSupplierField {...{ suppliers, ...props }} />
                  ),
                },
                required: true,
                validators: [[R.identity, 'Supplier is required']],
                renderIf: R.both(isInvoiceRequired, isNotTaskInvoice),
              },
            ]
          : []),
        {
          label: 'Invoice Type',
          order: 3,
          layout: { row: 3, width: halfWidth, isWrapped: true },
          required: true,
          field: {
            name: 'type',
            withFormData: true,
            withChangeFieldValue: true,
            Component: InvoiceTypeSelect,
          },
        },
        {
          order: 4,
          leaveValues: true,
          label: 'Invoice Number',
          layout: { row: 3, width: halfWidth },
          field: {
            withFormData: true,
            name: 'invoiceNumber',
            Component: InvoiceNumberInput,
            additionalProps: { tabIndex: '1', autoFocus: true },
          },
          renderIf: isInvoiceRequired,
          required: isNotProjectExpenseInvoice,
          ...(isNotProjectExpenseInvoice && {
            validators: [[R.identity, 'Invoice is required']],
          }),
        },

        {
          order: 5,
          layout: { row: 3, width: halfWidth },
          field: {
            withFormData: true,
            name: 'invoiceDate',
            Component: InvoiceDateField,
            additionalProps: { tabIndex: '5' },
          },
          renderIf: isInvoiceRequired,
        },
        {
          order: 6,
          label: 'Labor',
          leaveValues: true,
          layout: { row: 3, width: halfWidth, position: 'relative' },
          field: {
            name: 'labourCost',
            withFormData: true,
            Component: LabourCostInput,
            additionalProps: { tabIndex: '2', allowNegative: true },
          },
          renderIf: R.both(isInvoiceRequired, isBreakdown),
          required: true,
        },
        {
          label: 'Hourly Rate',
          order: 7,
          layout: { row: 3, width: halfWidth },
          field: {
            name: 'hourlyRate',
            Component: MoneyInputAsCents,
          },
          renderIf: R.both(isBreakdown, isInvoiceRequired),
        },
        {
          order: 8,
          label: 'Material',
          leaveValues: true,
          layout: { row: 3, width: halfWidth },
          field: {
            name: 'materialCost',
            withFormData: true,
            Component: MaterialCostInput,
            additionalProps: { tabIndex: '3', allowNegative: true },
          },
          renderIf: R.both(isInvoiceRequired, isBreakdown),
          required: true,
        },
        {
          order: 8,
          label: 'Tax',
          leaveValues: true,
          layout: { row: 3, width: halfWidth },
          field: {
            name: 'taxAmount',
            withFormData: true,
            Component: InvoiceTaxField,
            additionalProps: { tabIndex: '4', allowNegative: true },
          },
          renderIf: R.allPass([isInvoiceRequired, isBreakdown]),
        },
        {
          order: 9,
          layout: { row: 3, width: halfWidth },
          field: { name: 'placeholder', Component: () => null },
          renderIf: R.allPass([
            isInvoiceRequired,
            isNotTaskInvoice,
            R.complement(isBreakdown),
            R.always(!withDueDate),
          ]),
        },
        {
          order: 9,
          layout: { row: 3, width: halfWidth },
          field: {
            withChangeFieldValue: true,
            withFormData: true,
            name: 'paymentDueDate',
            Component: InvoiceDueDateField,
            additionalProps: { tabIndex: '5' },
          },
          renderIf: R.allPass([isInvoiceRequired, R.always(withDueDate)]),
        },

        {
          order: 10,
          leaveValues: true,
          layout: { row: 3, width: halfWidth },
          field: {
            name: 'total',
            withFormData: true,
            Component: InvoiceTotalInput,
            additionalProps: { tabIndex: '2' },
          },
          required: true,
          validators: [
            [R.is(Number), 'Total Invoice is required'],
            [totalAmountValidator, 'Total must equal Labor plus Material'],
            [
              isTotalValidAgainstPaidAmount,
              'Total should be greater or equal to paid amount',
            ],
            [
              isTotalPositiveForReportOnlyProject,
              REPORT_ONLY_NEGATIVE_INVOICE_TOTAL_ERROR,
            ],
          ],
          renderIf: isInvoiceRequired,
          validateFunction: validationFuncWithAllValues,
        },

        {
          order: 11,
          label: 'Supplier Not to Exceed',
          layout: { row: 7, width: halfWidth },
          field: {
            name: 'supplierNTE',
            Component: DisabledInput,
          },
          renderIf: R.both(hasNTE, isInvoiceRequired),
        },

        {
          order: 13,
          layout: { row: 8, width: halfWidth },
          label: 'Reason for Change',
          field: {
            withFormData: true,
            name: 'paymentDueDateChangeReason',
            Component: Input,
            additionalProps: {
              charactersLimit: PAYMENT_DUE_DATE_CHANGE_REASON_LENGTH_LIMIT,
              showCharactersLeft: true,
            },
          },
          required: true,
          renderIf: R.allPass([
            isInvoiceRequired,
            R.always(withDueDate),
            R.prop('isDueDateChanged'),
          ]),
          validators: [[R.identity, 'Reason for Change is required']],
        },
        {
          order: 13,
          layout: { row: 9, padding: '9px 0' },
          label: 'Invoice Description',
          field: {
            withFormData: true,
            name: 'projectInvoiceDescription',
            Component: InvoiceDescriptionEditor,
          },
          renderIf: isInvoiceRequired,
        },
        {
          order: 14,
          layout: { row: 10, padding: '9px 0' },
          field: {
            withFormData: true,
            name: 'invoiceFile',
            Component: AttachDocumentField,
          },
          validators: commonFileValidators,
          validateFunction: validateFilesFunc,
          renderIf: isInvoiceRequired,
        },
        {
          order: 15,
          layout: { row: 11 },
          renderIf: R.both(isInvoiceRequired, isFormActionReject),
          field: {
            withFormData: true,
            withChangeFieldValue: true,
            name: 'rejectAction',
            Component: RejectActionRadioButtons,
          },
        },
        {
          order: 16,
          layout: { row: 12 },
          label: 'Rejection Reason',
          required: true,
          field: {
            withFormData: true,
            name: 'rejectionReason',
            Component: Textarea,
          },
          renderIf: R.both(isInvoiceRequired, isFormActionReject),
        },
        {
          order: 17,
          layout: { row: 13, width: '1%', padding: '0' },
          field: {
            name: 'warningSpace',
            Component: () => null,
          },
          renderIf: isProjectWithClientInvoice,
        },
        {
          order: 18,
          layout: { row: 14, width: '98%', padding: '0' },
          field: {
            name: 'warning',
            withFormData: true,
            Component: ProjectAlreadyInvoicedText,
          },
          renderIf: isProjectWithClientInvoice,
        },
        ...(withCloseAction
          ? [
              {
                order: 1,
                layout: { row: 14, width: '98%', padding: '0' },
                field: {
                  name: 'closeProject',
                  withFormData: true,
                  Component: CloseProjectCheckbox,
                },
              },
            ]
          : []),
        {
          label: 'Rate Supplier Invoicing',
          order: 17,
          layout: { row: 15, width: 'calc(40% - 10px)' },
          field: { name: 'rating', Component: Rating },
          validators: [
            [R.propSatisfies(R.lt(0), 'average'), 'Rating is required'],
          ],
          renderIf: R.both(isInvoiceRequired, isFormActionApprove),
        },
        {
          order: 18,
          layout: { row: 15, width: 'calc(40% - 10px)' },
          field: {
            name: 'space',
            Component: () => null,
          },
          renderIf: R.both(
            R.always(showButtons),
            R.anyPass([
              R.complement(isInvoiceRequired),
              R.propEq('formAction', SupplierInvoiceFormActions.add),
              R.propEq('formAction', SupplierInvoiceFormActions.edit),
              R.propEq('formAction', SupplierInvoiceFormActions.reject),
            ]),
          ),
        },
        {
          order: 19,
          layout: buttonsLayout,
          field: {
            name: 'buttons',
            withFormData: true,
            withChangeFieldValue: true,
            Component: (props) => (
              <SubmitButtons
                {...props}
                formId={formId}
                onCancel={onCancel}
                submitCaption={submitCaption}
                withRemoveInvoice={withRemoveInvoice}
              />
            ),
          },
          renderIf: R.always(showButtons),
        },
      ],
    },
  ];
};
