import React from 'react';

import { ModalFormWrapper as ModalFormWrapperImp } from 'poly-admin-ui';
import { confirmButtonNames } from './confirmButtons/constants.js';

export function ModalFormWrapper(props) {
  return (
    <ModalFormWrapperImp
      {...props}
      confirmButtonName={confirmButtonNames.CONFIRM_CLOSE_MODAL}
    />
  );
}
