import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useState, useEffect, useCallback, useRef } from 'react';
import {
  debounceWithDefaultThreshold,
  getAssetModelsQuery,
  useReactiveQuery,
} from 'poly-client-utils';

import { useApproveNewEquipmentModal } from './useApproveNewEquipmentModal.js';

const ASSET_MANUFACTURERS_QUERY = gql`
  query ASSET_MANUFACTURERS_QUERY($input: CollectionSearchParams) {
    searchAssetManufactures(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

const ASSET_MANUFACTURERS_SUBSCRIPTION = gql`
  subscription ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAssetManufacturerChanged(input: $input) {
      id
      type
    }
  }
`;

const ASSET_MODELS_QUERY = gql`
  query ASSET_MODELS_QUERY($input: CollectionSearchParams) {
    searchAssetManufacturesModels(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

const ASSET_MODELS_SUBSCRIPTION = gql`
  subscription ASSET_MODELS_SUBSCRIPTION($input: CollectionSearchParams) {
    searchAssetModelChanged(input: $input) {
      id
      type
    }
  }
`;

export const useApproveNewEquipmentPopup = ({
  entity,
  assetId,
  equipmentName,
  manufacturerId,
}) => {
  const selectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState(equipmentName);
  const [inputValue, setInputValue] = useState(equipmentName);

  const openApproveNewEquipmentModal = useApproveNewEquipmentModal();

  const queryOptions = {
    variables: {
      input: {
        size: 25,
        searchTerm,
        query: getAssetModelsQuery(manufacturerId),
      },
    },
  };

  const { data, loading } = useReactiveQuery(
    manufacturerId ? ASSET_MODELS_QUERY : ASSET_MANUFACTURERS_QUERY,
    manufacturerId
      ? ASSET_MODELS_SUBSCRIPTION
      : ASSET_MANUFACTURERS_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const items = R.pathOr(
    [],
    manufacturerId
      ? ['searchAssetManufacturesModels', 'hits']
      : ['searchAssetManufactures', 'hits'],
    data,
  );

  const callSetSearchTermDebounced = useCallback(
    debounceWithDefaultThreshold((text) => setSearchTerm(text)),
    [],
  );

  const onFocus = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const onBlur = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const onChange = (e) => {
    setSelectedItem(null);
    setInputValue(e.target.value);
    callSetSearchTermDebounced(e.target.value);
    onFocus();
  };

  const onItemSelect = ({ _id, name }) => {
    setSelectedItem(_id);
    setInputValue(name);
    callSetSearchTermDebounced(name);
    onBlur();
  };

  const onSelect = (existedId, name) => {
    openApproveNewEquipmentModal({ entity, assetId, existedId, name });
    onBlur();
  };

  useEffect(() => {
    if (!suggestions && !loading) {
      setSuggestions(R.slice(0, 3, items));
    }
  }, [suggestions, items, loading]);

  useEffect(() => {
    const onOutsideClick = (e) => {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        onBlur();
      }
    };

    document.addEventListener('mousedown', onOutsideClick);

    return () => {
      document.removeEventListener('mousedown', onOutsideClick);
    };
  }, [selectRef, isOpen]);

  return {
    items,
    isOpen,
    onFocus,
    onChange,
    onSelect,
    selectRef,
    inputValue,
    suggestions,
    selectedItem,
    onItemSelect,
  };
};
