import { LinkButton } from 'poly-book-admin';
import React from 'react';
import { openPrintWindowWithData } from 'poly-client-utils';
import { CommonPrintLayout } from 'poly-admin-ui';
import { AccountingMethods, PRINT_PDF_CAPTION } from 'poly-constants';
import { arrayOf, instanceOf, oneOfType, shape, string } from 'prop-types';

import { profitLossPropTypes } from './propTypes.js';
import { ProfitLossReportPrintTable } from './ProfitLossPrintTable.js';

export function GenerateProfitLossReportBtn({
  profitLossReport,
  filters,
  cogsAccountCodes,
}) {
  const { accountingMethod, startDate, endDate } = filters;

  const onClick = () =>
    openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: ProfitLossReportPrintTable,
      fileName: 'profit_loss_report',
      metaData: {
        title: `Profit and Loss (${
          accountingMethod === AccountingMethods.CASH_BASIS ? 'Cash' : 'Accrual'
        } Basis)`,
      },
      profitLossReport,
      startDate,
      endDate,
      cogsAccountCodes,
    });

  return <LinkButton onClick={onClick}>{PRINT_PDF_CAPTION}</LinkButton>;
}

GenerateProfitLossReportBtn.propTypes = {
  profitLossReport: profitLossPropTypes,
  cogsAccountCodes: arrayOf(string),
  filters: shape({
    startDate: oneOfType([string, instanceOf(Date)]).isRequired,
    endDate: oneOfType([string, instanceOf(Date)]).isRequired,
    accountingMethod: string.isRequired,
  }),
};
