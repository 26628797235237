import React from 'react';
import { Editor, isEditorEmpty } from 'poly-book-admin';
import styled from 'styled-components';
import { useDecoratePersistenceForOnChange } from 'poly-client-utils';
import { func } from 'prop-types';

const EditorS = styled(Editor)`
  .ql-editor {
    max-height: none;
  }
`;

function EditorWithPersist({ onChange, onChangePersistentValue, ...props }) {
  const onChangePersistent = useDecoratePersistenceForOnChange(
    onChangePersistentValue,
    onChange,
    isEditorEmpty,
  );
  return <EditorS {...props} onChange={onChangePersistent} />;
}

EditorWithPersist.propTypes = {
  onChange: func.isRequired,
  onChangePersistentValue: func.isRequired,
};

export const invoiceDescriptionFormFieldConfig = (onChangePersistentValue) => ({
  order: 1,
  field: {
    name: 'invoiceDescription',
    Component: (props) => (
      <EditorWithPersist
        {...props}
        onChangePersistentValue={onChangePersistentValue}
      />
    ),
  },
  required: true,
});
