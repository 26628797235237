import React from 'react';
import arrayMutators from 'final-form-arrays';
import { shape, string, bool } from 'prop-types';
import { useHasUserAccessToProtectedSupplier } from 'poly-client-utils/src/suppliers/useHasUserAccessToUpdateSupplierProtected.js';
import { CREATE_MASTER_SUPPLIER_PERMISSION } from 'poly-security/src/permissions.js';
import { useHasUserAccessToUpdateSupplierProtected } from 'poly-client-utils';
import { usePristineSubscribe } from 'poly-client-routing';
import { FormPage, Loader } from 'poly-book-admin';
import {
  DEFAULT_SUPPLIER_FINANCIAL_TERMS,
  MasterSupplierStatus,
  SupplierSources,
  supplierTypes,
} from 'poly-constants';

import { masterSupplierFormSections } from './formSections/masterSupplierFormSections.js';
import { useCreateMasterSupplierMutation } from '../hooks/useCreateMasterSupplierMutation.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { supplierInitialOfficeHours } from '../../forms/supplierForm/form/utils.js';
import { useSupplierTaxValidation } from '../../forms/supplierForm/index.js';

export function AddMasterSupplierForm({
  formId,
  initialValues,
  supplierSource,
  supplierRootUrl,
  masterSupplierRootUrl,
  isSupplierTypeEditable,
}) {
  const { protectedFieldsAccess, accessLoading } =
    useHasUserAccessToUpdateSupplierProtected();

  const hasAccessToCreatePrivateSupplier = useHasUserAccessToProtectedSupplier(
    CREATE_MASTER_SUPPLIER_PERMISSION,
  );

  const onSubmitHandler = useCreateMasterSupplierMutation(
    masterSupplierRootUrl,
    supplierSource,
    protectedFieldsAccess,
  );

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const pristineSubscribeProps = usePristineSubscribe();

  const validate = useSupplierTaxValidation(
    masterSupplierRootUrl,
    supplierRootUrl,
  );

  const formProps = {
    id: formId,
    onSubmit,
    validate,
    mutators: arrayMutators,
    sections: masterSupplierFormSections(
      masterSupplierRootUrl,
      {},
      isSupplierTypeEditable,
      protectedFieldsAccess,
      supplierSource,
      hasAccessToCreatePrivateSupplier,
    ),
    initialValues: {
      type: supplierTypes.SUBCONTRACTOR,
      status: MasterSupplierStatus.ACTIVE,
      finance: {
        acceptsCreditCard: false,
        terms: DEFAULT_SUPPLIER_FINANCIAL_TERMS,
      },
      mwbe: {
        minority: undefined,
        isMBE: false,
      },
      isWBE: false,
      bank: {
        payByACH: false,
      },
      tax: {
        is1099: false,
        exempt: false,
      },
      service_24_7: false,
      company: { servicesIds: [], name: null },
      remarks: { ops: [] },
      supplierSource,
      officeHours: supplierInitialOfficeHours,
      ...initialValues,
    },
    ...pristineSubscribeProps,
  };

  return accessLoading ? <Loader /> : <FormPage {...formProps} />;
}

AddMasterSupplierForm.propTypes = {
  supplierRootUrl: string.isRequired,
  masterSupplierRootUrl: string.isRequired,
  initialValues: shape({
    type: string,
  }),
  formId: string.isRequired,
  supplierSource: string,
  isSupplierTypeEditable: bool,
};

AddMasterSupplierForm.defaultProps = {
  supplierSource: SupplierSources.AAC,
};
