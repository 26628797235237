import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { WorkOrderStatus } from 'poly-constants';
import * as R from 'ramda';
import { isNilOrEmpty } from 'poly-utils/src/general.js';
import { subDaysToTodayByProp } from 'poly-utils/src/dates.js';
import { usePaginationParams } from 'poly-admin-ui/src/hooks/usePaginationParams.js';
import { useSetItemsCount } from 'poly-admin-ui/src/hooks/useSetItemsCount.js';
import { MAX_ITEMS } from 'poly-admin-ui/src/constants/general.js';

const openWorkOrdersReportQuery = gql`
  query openWorkOrdersReportQuery($input: CollectionSearchParams) {
    searchProjects(input: $input) {
      hits {
        _id
        projectId
        description
        type
        startDate
        suppliers {
          _id
          company {
            name
          }
        }
        serviceType {
          _id
          name
        }
        manager {
          _id
          fullName
        }
      }
      total
    }
  }
`;

// subDaysToTodayByPropToISO :: String -> Object -> Date
const subDaysToTodayByPropToISO = (propName) =>
  R.compose(
    R.when(R.identity, (date) => date.toISOString()),
    subDaysToTodayByProp(propName),
  );

// getOpenWorkOrdersReportQuery :: Filter -> ElasticQuery
const getOpenWorkOrdersReportQuery = R.compose(
  R.assocPath(['bool', 'filter'], R.__, {}),
  R.reject(isNilOrEmpty),
  R.juxt([
    R.applySpec({ term: { clientId: R.prop('clientId') } }),
    R.always({
      terms: {
        status: [WorkOrderStatus.ACTIVE, WorkOrderStatus.PENDING],
      },
    }),
    R.compose(
      R.unless(isNilOrEmpty, R.assocPath(['range', 'startDate'], R.__, {})),
      R.reject(isNilOrEmpty),
      R.applySpec({
        gte: subDaysToTodayByPropToISO('ageTo'),
        lte: subDaysToTodayByPropToISO('ageFrom'),
      }),
    ),
  ]),
);

export const useOpenWorkOrdersReport = (filter, sort) => {
  const paginationParams = usePaginationParams();

  const { data, loading } = useQuery(openWorkOrdersReportQuery, {
    variables: {
      input: {
        query: getOpenWorkOrdersReportQuery(filter),
        sort,
        ...paginationParams,
      },
    },
    skip: !filter?.clientId,
  });

  useSetItemsCount(R.pathOr(0, ['searchProjects', 'total']), data);

  return { data, loading };
};

export const useOpenWorkOrdersReportLazyQuery = () => {
  const [queryHandler] = useLazyQuery(openWorkOrdersReportQuery);

  const handler = async ({ filter, sort }) => {
    const response = await queryHandler({
      variables: {
        input: {
          query: getOpenWorkOrdersReportQuery(filter),
          size: MAX_ITEMS,
          sort,
        },
      },
      fetchPolicy: 'network-only',
    });

    return response;
  };

  return handler;
};
