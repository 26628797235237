import styled from 'styled-components';
import { Editor } from 'poly-book-admin';

const EditorS = styled(Editor)`
  height: 142px;
`;

export const supplierRemarksSection = [
  {
    label: 'Remarks',
    order: 2,
    layout: {
      column: 2,
    },
    fields: [
      {
        field: {
          name: 'remarks',
          Component: EditorS,
        },
      },
    ],
  },
];
