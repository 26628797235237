import * as R from 'ramda';
import React from 'react';
import { bool, arrayOf, shape, string } from 'prop-types';
import {
  ExcelExportCellType,
  performExcelExport,
  createExcelExportCell,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';

import { DivisionsExportTextButton } from './common.js';

// getDivisionsXLSRowConfig :: Division -> [ExcelExportDataCell]
const getDivisionsXLSRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 2),
  ]),
  R.juxt([R.propOr('', 'name'), R.propOr('', 'status')]),
);

// getDivisionsXLSExcelConfig :: [Division] -> Object
export const getDivisionsXLSExcelConfig = (divisions) => ({
  exportFileName: 'divisions.xlsx',
  columnWidths: [20, 10],
  rows: [
    [createExcelExportCell(ExcelExportCellType.title, 'Divisions')],
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 2)],
      ['Name', 'Status'],
    ),
    ...R.map(getDivisionsXLSRowConfig, divisions),
  ],
});

export function DivisionsExportToExcelButton({ divisions, loading }) {
  const handleClick = () => {
    const excelConfig = getDivisionsXLSExcelConfig(divisions);
    performExcelExport(excelConfig);
  };

  return (
    <DivisionsExportTextButton disabled={loading} onClick={handleClick}>
      {EXPORT_XLS_CAPTION}
    </DivisionsExportTextButton>
  );
}

DivisionsExportToExcelButton.propTypes = {
  loading: bool.isRequired,
  divisions: arrayOf(
    shape({
      _id: string.isRequired,
      name: string.isRequired,
      status: string.isRequired,
    }),
  ),
};
