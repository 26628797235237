import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape, bool, arrayOf, number } from 'prop-types';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { Loader, Table } from 'poly-book-admin';

import { userGroupManagementTableConfig } from './useUserGroupListQuery.js';

const UserGroupManagementTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 200px;
  }

  th:nth-child(4),
  td:nth-child(4),
  th:nth-child(5),
  td:nth-child(5) {
    width: 40px;
    text-align: center;
  }
`;

export function UserGroupManagementTable({
  loading,
  userGroupList,
  tableSortingProps,
}) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    userGroupManagementTableConfig,
    userGroupList,
  );

  if (loading) {
    return <Loader />;
  }

  return <UserGroupManagementTableS {...tableSortingProps} {...tableProps} />;
}

UserGroupManagementTable.propTypes = {
  loading: bool,
  tableSortingProps: shape({ sorting: shape({ dir: number }) }),
  userGroupList: arrayOf(shape({ _id: string.isRequired })).isRequired,
};
