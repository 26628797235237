import React from 'react';
import styled from 'styled-components';
import { LinkButton } from 'poly-book-admin';
import { bool, string } from 'prop-types';

import { NOTHING_UI_STRING } from 'poly-constants';
import { useNotificationState } from '../../hooks/useNotificationState.js';
import { FileLink } from '../../components/Links.js';

const LinkButtonS = styled(LinkButton)`
  font-size: 14px;
`;

export function W9FileLink({
  w9FileUrl,
  w9FileName,
  hasUserTaxPrivateFieldsAccess,
}) {
  const { showWarningNotification } = useNotificationState();
  if (w9FileName === NOTHING_UI_STRING) {
    return NOTHING_UI_STRING;
  }
  if (!hasUserTaxPrivateFieldsAccess) {
    return (
      <LinkButtonS
        onClick={() =>
          showWarningNotification('You are not authorized to view this file')
        }
      >
        {w9FileName}
      </LinkButtonS>
    );
  }

  return <FileLink url={w9FileUrl} fileName={w9FileName} />;
}

W9FileLink.propTypes = {
  w9FileName: string.isRequired,
  w9FileUrl: string,
  hasUserTaxPrivateFieldsAccess: bool.isRequired,
};
