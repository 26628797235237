import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { highlightMatchesInObject } from '../objects.js';

// formatPaths :: [[String], String] -> [[String]]
const formatPaths = R.map(R.when(R.is(String), R.of));

export const useHighlightMatchesBySearch = (
  dataModifyFunc,
  propsToHighlight = [],
  rows = [],
) => {
  const search = useSelector(R.prop('searchText'));

  const modifiedRows = useMemo(
    () => (dataModifyFunc ? dataModifyFunc(rows) : rows),
    [rows],
  );

  const useMemoizedHighlighting = (rowsToHighlight) =>
    useMemo(
      () =>
        search
          ? R.map(
              (object) =>
                highlightMatchesInObject({
                  object,
                  search,
                  paths: formatPaths(propsToHighlight),
                }),
              rowsToHighlight,
            )
          : rowsToHighlight,
      [search, rowsToHighlight],
    );

  const highlightedRows = useMemoizedHighlighting(modifiedRows);

  return { highlightedRows };
};
