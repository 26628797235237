import * as R from 'ramda';
import {
  EQUALS_AIT_OPERATOR,
  FLIPPED_CONTAINS_AIT_OPERATOR,
  NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
} from '../operators.js';

/**
 * Special constant used at AITs which means user's own ID
 */
export const USER_SELF_ID = '_user_self';
export const USER_SELF_ID_LABEL = '[Current User]';

// hasKeyWithSelfUserIdInArray :: String -> Object -> Boolean
const hasKeyWithSelfUserIdInArray = (key) =>
  R.both(R.has(key), R.compose(R.includes(USER_SELF_ID), R.propOr([], key)));

// replaceSelfUserIdInArray :: (ID, String) -> Object -> Object
const replaceSelfUserIdInArray = (userId, key) =>
  R.over(
    R.lensProp(key),
    R.map(R.when(R.equals(USER_SELF_ID), R.always(userId))),
  );

// replaceSelfWithIdAtAit :: ID -> AccessItemTypes -> AccessItemTypes
const replaceSelfWithIdAtAit = (userId) =>
  R.map(
    R.cond([
      [
        R.propEq(EQUALS_AIT_OPERATOR, USER_SELF_ID),
        R.assoc(EQUALS_AIT_OPERATOR, userId),
      ],
      [
        hasKeyWithSelfUserIdInArray(FLIPPED_CONTAINS_AIT_OPERATOR),
        replaceSelfUserIdInArray(userId, FLIPPED_CONTAINS_AIT_OPERATOR),
      ],
      [
        hasKeyWithSelfUserIdInArray(NOT_FLIPPED_CONTAINS_AIT_OPERATOR),
        replaceSelfUserIdInArray(userId, NOT_FLIPPED_CONTAINS_AIT_OPERATOR),
      ],
      [R.T, R.identity],
    ]),
  );

// replaceSelfIdAtUserAit :: (ID, User) -> User
const replaceSelfIdAtUserAit = (userId, user) =>
  R.when(
    R.propIs(Array, 'accessItems'),
    R.over(
      R.lensProp('accessItems'),
      R.map(
        R.over(
          R.lensProp('types'),
          R.compose(replaceSelfWithIdAtAit(userId), R.defaultTo({})),
        ),
      ),
    ),
  )(user);

/**
 * replaceSelfWithUserId :: User -> User
 */
export const replaceSelfWithUserId = R.converge(replaceSelfIdAtUserAit, [
  R.prop('_id'),
  R.identity,
]);
