import * as R from 'ramda';
import { keysToTitleCase } from 'poly-utils';
import { SETUP_2FA_REQUIRED_ERROR_MESSAGE } from 'poly-constants';

// toDropDownOptions :: Object -> [[String, String]]
export const toDropDownOptions = R.pipe(
  keysToTitleCase,
  R.invertObj,
  R.toPairs,
);

const { NODE_ENV, GRAPHQL_HTTP_URL } = process.env;

export const isBeta =
  NODE_ENV === 'development' || R.test(/beta/, GRAPHQL_HTTP_URL);

// is2FASetupError :: [Error] -> Boolean
export const is2FASetupError = R.includes(SETUP_2FA_REQUIRED_ERROR_MESSAGE);
