import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useOutSidebarContext } from 'poly-client-utils';
import { LinkButton, Icon, defaultTheme, getThemeColor } from 'poly-book-admin';

import { BillingProfileConfigurationSidebarId } from './constants.js';
import { BillingProfileConfigurationSidebar } from './BillingProfileConfigurationSidebar/BillingProfileConfigurationSidebar.js';

const ConfigurationLinkButtonS = styled(LinkButton)`
  display: flex;
  align-items: center;
  margin-right: 100px;
  color: ${getThemeColor(['midDark'])};
  padding: 2px;

  > svg {
    margin-right: 5px;
  }
`;

export function BillingProfileConfigurationBtn({ clearSelectedProjects }) {
  const { openOutSidebar } = useOutSidebarContext();

  const onBtnClick = () =>
    openOutSidebar({
      width: 650,
      alwaysFirst: true,
      content: (
        <BillingProfileConfigurationSidebar
          clearSelectedProjects={clearSelectedProjects}
        />
      ),
      id: BillingProfileConfigurationSidebarId,
    });

  return (
    <ConfigurationLinkButtonS onClick={onBtnClick}>
      <Icon size={12} name="config" color={defaultTheme.colors.midDark} />
      Configurations
    </ConfigurationLinkButtonS>
  );
}

BillingProfileConfigurationBtn.propTypes = {
  clearSelectedProjects: func.isRequired,
};
