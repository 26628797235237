import { useRouterParams } from 'poly-client-routing';
import { usePagination } from 'poly-admin-ui';
import {
  commonSortQuery,
  useEntitiesByReactiveReduxSearch,
} from 'poly-client-utils';
import { ASC_SORT_ORDER } from 'poly-constants';
import { INVOICES_BY_SEARCH_SUB } from './subscriptions.js';
import { INVOICES_BY_SEARCH } from './queries.js';

export const useSupplierInvoices = ({ sort }) => {
  const { supplierId } = useRouterParams(['supplierId']);

  const { pagination } = usePagination();

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: INVOICES_BY_SEARCH,
    gqlSearchChangedQuery: INVOICES_BY_SEARCH_SUB,
    pagination,
    sort: sort || commonSortQuery(['invoiceNumber'])(ASC_SORT_ORDER),
    query: { match: { supplierId } },
  });

  return { loading, refetch, result };
};
