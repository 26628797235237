import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getThemeColor, Button, Checkbox } from 'poly-book-admin';
import { useOutSidebarContext } from 'poly-client-utils';
import {
  ServiceTypesSelect,
  SidebarWrapper,
  ClientSelect,
} from 'poly-admin-ui';

import {
  MasterProjectsFilterId,
  MasterProjectsTabsStatuses,
} from './constants.js';
import { ALL } from '../../modules/core/constants/general.js';
import { ManagerSelect } from '../../components/AACManagerSelect.js';
import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';
import { ProjectTypeSelect } from '../../components/ProjectTypeSelect.js';
import { setProjectsFilter } from '../../redux/searchFilters/projectsFilter.js';
import { useLocalSearchFilters } from '../../hooks/useSearchFilters.js';
import { AssetTypeSelect } from '../../sidebars/AssetSidebar/forms/form/components/AssetTypeSelect.js';

// MasterProjectsFilterStatuses :: Object -> [{ value: String, label: String }]
const MasterProjectsFilterStatuses = R.compose(
  R.map(R.applySpec({ value: R.head, label: R.last })),
  R.toPairs,
)(MasterProjectsTabsStatuses);

// masterProjectFilterParams :: [String] -> SearchFilters
const masterProjectFilterParams = R.map(
  R.compose(
    R.when(R.propEq('name', 'type'), R.assoc('defaultValue', ALL)),
    R.assoc('defaultValue', null),
    R.objOf('name'),
  ),
)(['clientId', 'managerId', 'type', 'serviceTypeId', 'assetTypeId']);

// masterProjectsFilterConfig :: Object -> [{ name: String, defaultValue: ID || Boolean }]
const masterProjectsFilterConfig = R.compose(
  R.concat(masterProjectFilterParams),
  R.map(
    R.compose(R.assoc('defaultValue', false), R.objOf('name'), R.prop('value')),
  ),
)(MasterProjectsFilterStatuses);

// isFilterInitial :: SearchFilters -> [FilterRowConfig] -> Object
const isFilterInitial = (searchFilters) =>
  R.compose(
    R.equals(searchFilters),
    R.fromPairs,
    R.map(R.juxt([R.prop('name'), R.prop('defaultValue')])),
  );

const SidebarHeaderS = styled.div`
  display: flex;
  padding: 15px 24px;
  align-items: center;
  justify-content: space-between;
`;

const SidebarTitleS = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${getThemeColor(['primary'])};
`;

const FilterButtonsWrapperS = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: row;

  > button {
    margin-right: 10px;
  }
`;

const FilterRowS = styled.div`
  display: flex;
  padding: 14px 24px;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid ${getThemeColor(['lighter'])};
`;

const FilterColumnBlockS = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  &:first-child {
    padding-right: 14px;
  }

  &:last-child {
    padding-left: 14px;

    .select__menu {
      width: 140%;
      right: 0;
    }
  }
`;

const FilterComponentWrapperS = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  &:not(:last-child) {
    padding-bottom: 14px;
  }
`;

const FilterComponentTitleS = styled.div`
  display: flex;
  width: 100px;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: ${getThemeColor(['primary'])};
`;

const FilterCheckboxS = styled(Checkbox)`
  margin-right: 15px;
`;

export function MasterProjectsFilter() {
  const dispatch = useDispatch();
  const projectsFilters = useSelector(
    (state) => state.searchFilters.projectsFilter,
  );
  const { closeOutSidebar } = useOutSidebarContext();

  const { searchFilters, handlers, onReset } = useLocalSearchFilters(
    masterProjectsFilterConfig,
    projectsFilters,
  );

  const selectPropsByName = (name) => ({
    name,
    size: 'small',
    width: '164px',
    onChange: handlers[name],
    value: searchFilters[name],
    placeholder: 'Start typing...',
  });

  const onSearch = () => {
    const result = isFilterInitial(searchFilters)(masterProjectsFilterConfig)
      ? null
      : searchFilters;
    dispatch(setProjectsFilter(result));
  };

  const onClose = () => closeOutSidebar(MasterProjectsFilterId);

  return (
    <SidebarWrapper>
      <SidebarHeaderS>
        <SidebarTitleS>Master Project Search</SidebarTitleS>
        <SidebarCloseIcon onClick={onClose} />
      </SidebarHeaderS>
      <FilterRowS>
        <FilterComponentTitleS>Status</FilterComponentTitleS>
        {MasterProjectsFilterStatuses.map(({ label, value }) => (
          <FilterCheckboxS
            key={value}
            name={value}
            label={label}
            onChange={handlers[value]}
            value={searchFilters[value]}
          />
        ))}
      </FilterRowS>
      <FilterRowS>
        <FilterColumnBlockS>
          <FilterComponentWrapperS>
            <FilterComponentTitleS>Cost Type</FilterComponentTitleS>
            <ProjectTypeSelect {...selectPropsByName('type')} />
          </FilterComponentWrapperS>
          <FilterComponentWrapperS>
            <FilterComponentTitleS>Assigned CSR</FilterComponentTitleS>
            <ManagerSelect {...selectPropsByName('managerId')} />
          </FilterComponentWrapperS>
          <FilterComponentWrapperS>
            <FilterComponentTitleS>Asset Type</FilterComponentTitleS>
            <AssetTypeSelect {...selectPropsByName('assetTypeId')} />
          </FilterComponentWrapperS>
        </FilterColumnBlockS>
        <FilterColumnBlockS>
          <FilterComponentWrapperS>
            <FilterComponentTitleS>Client</FilterComponentTitleS>
            <ClientSelect
              withoutSkip
              includeAllOption={false}
              {...selectPropsByName('clientId')}
            />
          </FilterComponentWrapperS>
          <FilterComponentWrapperS>
            <FilterComponentTitleS>Service Type</FilterComponentTitleS>
            <ServiceTypesSelect {...selectPropsByName('serviceTypeId')} />
          </FilterComponentWrapperS>
        </FilterColumnBlockS>
      </FilterRowS>

      <FilterButtonsWrapperS>
        <Button size="tiny" onClick={onSearch} styleType="default">
          Search
        </Button>
        <Button size="tiny" onClick={onReset} styleType="primaryLighter">
          Reset
        </Button>
      </FilterButtonsWrapperS>
    </SidebarWrapper>
  );
}
