import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { string, shape } from 'prop-types';
import { assocByPath, assocBy } from 'poly-utils';
import { Loader } from 'poly-book-admin';
import {
  TableCardWithPaginationContainer,
  useMapConfigToTableProps,
  useSetItemsCount,
  useTableSorting,
} from 'poly-admin-ui';
import { DESC_SORT_ORDER } from 'poly-constants';
import { commonSortQuery } from 'poly-client-utils';

import {
  invoicesTableConfig,
  SupplierInvoicesTableS,
  printInvoicesTableConfig,
} from '../../../modules/tables/supplierInvoicesTable/SupplierInvoicesTable.js';
import { useMasterSupplierInvoicesQuery } from '../hooks/useMasterSupplierInvoicesQuery.js';
import { invoiceStatusToStringMap } from '../../../modules/tables/columns/invoices.js';
import { setEntitySortAction } from '../../../redux/entitySort/entitySort.js';

// prepareMasterSupplierInvoicesForTable :: MasterSupplier -> [Invoice]
const prepareMasterSupplierInvoicesForTable = R.compose(
  R.map(
    R.compose(
      assocByPath(['project', 'urlParam'], R.path(['project', 'projectId'])),
      assocBy(
        'statusUI',
        R.compose(R.prop(R.__, invoiceStatusToStringMap), R.prop('status')),
      ),
      assocBy('invoiceId', R.prop('_id')),
    ),
  ),
  R.pathOr([], ['masterSupplier', 'searchInvoices', 'hits']),
);

function MasterSupplierInvoicesTable() {
  const dispatch = useDispatch();

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: invoicesTableConfig,
    column: 1,
    defaultSort: commonSortQuery(['createdAt'])(DESC_SORT_ORDER),
  });

  useEffect(() => {
    if (sort) dispatch(setEntitySortAction(sort));
  }, [sort]);

  const { data, loading } = useMasterSupplierInvoicesQuery(sort);

  useSetItemsCount(
    R.pathOr(0, ['masterSupplier', 'searchInvoices', 'total']),
    data,
  );

  const tableProps = useMapConfigToTableProps(
    prepareMasterSupplierInvoicesForTable,
    invoicesTableConfig,
    data,
  );

  return loading ? (
    <Loader />
  ) : (
    <SupplierInvoicesTableS {...tableSortingProps} {...tableProps} />
  );
}

export function PrintMasterSupplierInvoicesTable({ data }) {
  const tableProps = useMapConfigToTableProps(
    prepareMasterSupplierInvoicesForTable,
    printInvoicesTableConfig,
    data,
  );

  return <SupplierInvoicesTableS {...tableProps} />;
}

PrintMasterSupplierInvoicesTable.propTypes = {
  data: shape({
    masterSupplier: shape({
      _id: string,
    }),
  }),
};

export function MasterSupplierInvoicesTab() {
  return (
    <TableCardWithPaginationContainer>
      <MasterSupplierInvoicesTable />
    </TableCardWithPaginationContainer>
  );
}
