import * as R from 'ramda';
import { useMutation } from '@apollo/client';
import { WorkOrderStatus, ProjectSupplierStatus } from 'poly-constants';
import { useOutSidebarContext } from 'poly-client-utils';
import { useNotificationState } from 'poly-admin-ui';
import { useNavigate } from 'poly-client-routing';
import { insertParamsIntoURL } from 'poly-utils';

import { addProjectFormId } from '../../constants.js';
import { routesNames } from '../../../../routes/constants.js';
import {
  CREATE_PROJECT_MUTATION,
  CREATE_RWO_MUTATION,
} from '../../../../modules/core/hooks/projects/mutations.js';
import {
  recurringWOFormDataToMutationData,
  getParamsByCreatedProject,
  woFormDataToMutationData,
} from './formDataToMutationData.js';
import {
  isRecurringOrPMFormData,
  isRecurringOrPMProject,
} from '../form/utils/sections.js';

// prepareFormData :: Boolean -> FormData -> FormData
const prepareFormData = (cloneWithoutSuppliers) =>
  R.compose(
    R.when(R.prop('cloneWithoutReference'), R.omit(['cloneFromProjectId'])),
    R.when(
      () => cloneWithoutSuppliers,
      R.omit(['suppliers', 'supplierId', 'supplierNTE']),
    ),
    // omit clientId if property provided
    R.when(R.prop('propertyId'), R.omit(['clientId'])),
    R.omit(['division', 'workCompletionDate']),
  );

// checkUniqueSuppliers :: FormData -> Boolean
export const checkUniqueSuppliers = R.converge(R.equals, [
  R.compose(R.length, R.propOr([], 'masterProjectSuppliers')),
  R.compose(
    R.length,
    R.uniqBy(R.prop('_id')),
    R.propOr([], 'masterProjectSuppliers'),
  ),
]);

// prepareProjectSuppliers :: [ID] -> [ProjectSupplier]
const prepareProjectSuppliers = R.map(
  R.compose(
    R.mergeLeft({ statusInProject: ProjectSupplierStatus.added }),
    R.objOf('_id'),
  ),
);

export const useOnSubmitProjectFrom = (
  type,
  saveContacts,
  cloneWithoutSuppliers,
  clonedProject,
  supplierIds,
  cleanupRetainedValue,
) => {
  const { showSuccessNotification, showErrorNotification } =
    useNotificationState();
  const { closeOutSidebar } = useOutSidebarContext();
  const navigate = useNavigate();

  const [crateProjectMutation] = useMutation(CREATE_PROJECT_MUTATION);
  const [crateMasterProjectMutation] = useMutation(CREATE_RWO_MUTATION);

  return async (formData) => {
    const { clientId, cloneWithoutReference } = formData;
    const propertyId = R.path(['property', '_id'], formData);

    const isRecurring = isRecurringOrPMFormData(formData);

    if (!checkUniqueSuppliers(formData)) {
      return showErrorNotification('Not allow duplicate suppliers');
    }

    // first save contacts
    const [siteContactResult, contactResult] = await saveContacts(
      formData,
      propertyId,
      clientId,
      ['siteContact', 'contact'],
    );

    const [toMutation, mutate] = isRecurring
      ? [recurringWOFormDataToMutationData, crateMasterProjectMutation]
      : [woFormDataToMutationData, crateProjectMutation];

    const withParentCloneId =
      !!clonedProject &&
      !!type &&
      isRecurringOrPMProject({ type }) &&
      !cloneWithoutReference;

    const withChildCloneId =
      !!clonedProject && clonedProject?.parent && !cloneWithoutReference;

    const withSuppliers = !!supplierIds && !isRecurringOrPMProject({ type });

    // then save project
    const toMutationObject = toMutation({
      ...prepareFormData(cloneWithoutSuppliers)(formData),
      ...(withParentCloneId && {
        parentCloneId: clonedProject._id,
        cloneFromProjectId: undefined,
      }),
      ...(withChildCloneId && {
        cloneFromProjectId: clonedProject._id,
      }),
      ...(R.complement(R.isNil(cloneWithoutSuppliers)) && {
        skipSupplierNotify: true,
      }),
      status: WorkOrderStatus.ACTIVE,
      siteContactId: siteContactResult.contactId,
      contactId: contactResult.contactId,
      ...(withSuppliers
        ? {
            suppliers: prepareProjectSuppliers(supplierIds),
          }
        : {}),
    });

    const mutationResult = await mutate({
      variables: { input: toMutationObject },
    });

    showSuccessNotification('The project was successfully created');

    await new Promise((resolve) => {
      // #937 fix for projects tasks reactivity bug
      setTimeout(() => {
        resolve();
        cleanupRetainedValue();
        closeOutSidebar(addProjectFormId);

        const urlParams = getParamsByCreatedProject(mutationResult);
        const route = isRecurring
          ? routesNames.MASTER_PROJECT_CARD
          : routesNames.PROJECT_CARD;

        navigate(insertParamsIntoURL(urlParams)(route));
      }, 1000);
    });

    return null;
  };
};
