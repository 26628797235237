import React from 'react';
import styled from 'styled-components';
import { Text, Button } from 'poly-book-admin';
import { ClientSelect } from 'poly-admin-ui';

import { FlexContainer } from '../../../components/FlexContainer.js';

export const ColumnWrapper = styled(FlexContainer)`
  min-width: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > div {
    margin-bottom: 10px;
  }
`;

export const ColumnWrapperRight = styled(ColumnWrapper)`
  align-items: flex-end;
  justify-content: flex-start;

  & > div:first-child {
    margin-top: 15px;
  }
`;

export const FilterWrapper = styled(FlexContainer)`
  min-width: 0;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div:not(:last-child) {
    margin-right: 30px;
  }
`;

export const RowWrapper = styled(FlexContainer)`
  min-width: 0;
  align-items: center;
`;

export const Label = styled(Text)`
  font-size: 12px;
  font-weight: medium;
  margin-right: 20px;
  display: flex;
  flex-shrink: 0;
`;

export const ClientSelectS = styled(ClientSelect)`
  width: 200px;
`;

export function PreviewButton(props) {
  return (
    <Button {...props} size="small">
      Search
    </Button>
  );
}
