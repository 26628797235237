import { Cell, Row } from 'poly-book-admin';
import React from 'react';
import {
  arrayOf,
  func,
  string,
  object,
  bool,
  number,
  oneOfType,
  shape,
} from 'prop-types';

export function TableLinkRow({
  columns,
  index,
  row,
  style,
  className,
  onClick,
  hasPermission,
  headers,
}) {
  const onClickHandler = () => {
    if (hasPermission) {
      onClick(row);
    }
  };
  return (
    <Row
      key={`${row._id}${index}`}
      data-testid="table-body-row"
      isClickable={hasPermission}
      onClick={onClickHandler}
      style={style}
      className={className}
      enableHoverEffect
    >
      {columns.map((getColumnText, i) => (
        <Cell key={`${index}-${headers[i].title}`}>{getColumnText(row)}</Cell>
      ))}
    </Row>
  );
}

TableLinkRow.propTypes = {
  columns: arrayOf(func).isRequired,
  index: number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: object.isRequired,
  style: oneOfType([string, object]),
  className: string,
  hasPermission: bool,
  onClick: func.isRequired,
  headers: arrayOf(shape({ title: string })),
};

TableLinkRow.defaultProps = {
  hasPermission: true,
};
