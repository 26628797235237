import * as R from 'ramda';
import React, { Fragment } from 'react';
import { func, string, bool, number } from 'prop-types';
import styled from 'styled-components';
import { MAX_FILE_NAME_CHARS_LIMIT } from 'poly-constants';

import { attachmentsPropTypes } from './commonPropTypes.js';
import { InputErrorMsg } from '../Input/index.js';
import {
  FileListWrapper,
  FileListItem,
  FileNameInput,
  IconClose,
} from './styles.js';
import { fileErrorPropTypes } from './UploadFileLogic.js';
import { CharacterCount } from '../CharacterCount/CharacterCount.js';

function FileLink({ url, fileName }) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {fileName}
    </a>
  );
}

FileLink.propTypes = {
  url: string.isRequired,
  fileName: string.isRequired,
};

export const FileItemErrorMsg = styled(InputErrorMsg)`
  display: block;
  padding: 0;
  margin-bottom: 5px !important;
`;

// getFileItemError :: ID -> [FileError] ->  File -> Boolean
// FileError = {fileId: String, error: String, commonError: String}
export const getFileItemErrors = R.curry((fileId, fileErrors) =>
  R.compose(R.pluck('error'), R.filter(R.propEq('fileId', fileId)))(fileErrors),
);

// getCommonFilesError :: [FileError] -> String
export const getCommonFilesError = R.compose(
  R.head,
  R.reject(R.isNil),
  R.pluck('commonError'),
);

// getFileNameLength :: String -> Int
export const getFileNameLength = R.ifElse(R.is(String), R.length, R.always(0));

export function FileList({
  attachments,
  onFileNameChange,
  onFileRemove,
  error,
  hasError,
  readOnly,
  fileNameLimit,
  isFileNameLimited,
  isFileNameChangeable,
  ...props
}) {
  const commonError = getCommonFilesError(error);

  if (!Array.isArray(attachments)) {
    return null;
  }

  return (
    <>
      <FileListWrapper isEmpty={attachments.length === 0}>
        {attachments.map(({ fileName, _id, upload, url }) => {
          const errors = getFileItemErrors(_id, error);
          const fileNameInputProps = {
            autoFocus: true,
            value: fileName,
            onChange: onFileNameChange(_id),
            placeholder: 'Enter file description',
            ...(isFileNameLimited ? { maxLength: fileNameLimit } : {}),
            ...R.omit(['onFileUpload', 'onDropRejected'], props),
          };

          return (
            <Fragment key={`fragment-${_id}`}>
              {isFileNameLimited && !readOnly && (
                <CharacterCount
                  key={`count-${_id}`}
                  length={getFileNameLength(fileName)}
                  limit={fileNameLimit}
                />
              )}
              <FileListItem
                key={`file-list-item-${_id}`}
                readOnly={readOnly}
                isFileNameChangeable={isFileNameChangeable}
                hasError={hasError && errors.length}
              >
                {!readOnly && isFileNameChangeable && (
                  <FileNameInput
                    {...fileNameInputProps}
                    key={`file-name-input-${_id}`}
                  />
                )}
                <p key={`paragraph-${_id}`}>
                  <FileLink
                    fileName={
                      fileName || upload?.fileName || 'Invalid Attachment'
                    }
                    url={url || (upload && window.URL.createObjectURL(upload))}
                    key={`file-link-${_id}`}
                  />
                  {!readOnly && (
                    <IconClose
                      onClick={onFileRemove(_id)}
                      name="close"
                      key={`icon-close-${_id}`}
                    />
                  )}
                </p>
              </FileListItem>
              {errors.map((err) => (
                <FileItemErrorMsg key={`error-${_id}-${err}`}>
                  {err}
                </FileItemErrorMsg>
              ))}
            </Fragment>
          );
        })}
      </FileListWrapper>
      {commonError && <InputErrorMsg>{commonError}</InputErrorMsg>}
    </>
  );
}

FileList.propTypes = {
  attachments: attachmentsPropTypes,
  onFileNameChange: func.isRequired,
  onFileRemove: func.isRequired,
  error: fileErrorPropTypes,
  readOnly: bool,
  hasError: bool,
  isFileNameLimited: bool,
  fileNameLimit: number,
  isFileNameChangeable: bool,
};

FileList.defaultProps = {
  attachments: [],
  fileNameLimit: MAX_FILE_NAME_CHARS_LIMIT,
  isFileNameLimited: true,
  isFileNameChangeable: true,
};
