import React from 'react';
import styled from 'styled-components';
import { string, shape, oneOfType, arrayOf } from 'prop-types';
import { taskActionTypes } from 'poly-constants';

import { InputErrorMsg } from 'poly-book-admin';

const InputErrorMsgS = styled(InputErrorMsg)`
  padding: 5px 0;
`;

function CloseProjectError({ payload }) {
  return (
    <InputErrorMsgS>
      You can not close this project until the following tasks are
      complete:&nbsp;
      {payload.map((task) => `"${task}"`).join(', ')}.
    </InputErrorMsgS>
  );
}

CloseProjectError.propTypes = {
  payload: arrayOf(string),
};

export function TaskFormError({ error }) {
  if (error.type === taskActionTypes.CLOSE_PROJECT) {
    return <CloseProjectError {...error} />;
  }
  return <InputErrorMsgS>{error}</InputErrorMsgS>;
}

TaskFormError.propTypes = {
  error: oneOfType([
    string,
    shape({
      type: string,
      message: string,
    }),
  ]),
};

TaskFormError.defaultProps = {
  error: '',
};
