import * as R from 'ramda';
import React, { useContext } from 'react';
import { bool, string } from 'prop-types';
import { WISCONSIN_STATE_SHORTNAME } from 'poly-constants';
import { formatDateOrNothingUI } from 'poly-client-utils';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import { Loader } from 'poly-book-admin';
import {
  applyPath,
  applyProp,
  calculateTotal,
  centsToDollarsWithFormat,
  getFullZipCodeFromAddress,
} from 'poly-utils';

import {
  SalesTaxReportFooterContainer,
  SalesTaxReportPrintTableComp,
  SalesTaxReportTableComp,
} from './salesTaxReportComponents.js';
import {
  PayInvoicesTableCard,
  WhiteBodyWrapper,
} from '../PaySuppliersPage/PaySuppliersComponents.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { calculateSubTotal } from './salesTaxReportUtils.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { taxReportProps } from './prop-types.js';

// isWIState :: String -> Boolean
const isWIState = R.equals(WISCONSIN_STATE_SHORTNAME);

// calculateJobCost :: (a -> Number) -> [a] -> String
const calculateTotalByGetter = (getter) =>
  R.compose(centsToDollarsWithFormat, calculateTotal(getter));

// getSalesTaxReportNYTotal :: ClientInvoice -> Number
export const getSalesTaxReportNYTotal = R.compose(
  R.sum,
  R.juxt([R.propOr(0, 'taxAmount'), calculateSubTotal]),
);

const salesTaxReportTableConfigByState = (state, isPrint) => [
  ['Invoice Date', applyProp(formatDateOrNothingUI)('invoiceDate')],
  ['Invoice #', R.prop('number')],
  [
    'Project #',
    R.compose(ProjectLink, R.assoc('isPrintPDF', isPrint), R.prop('project')),
  ],
  ['Customer', R.path(['client', 'nickName'])],
  [
    'Service Zip Code',
    applyPath(getFullZipCodeFromAddress)(['project', 'property', 'address']),
  ],
  ...(isWIState(state)
    ? [
        [
          'Job Cost',
          applyProp(calculateTotalByGetter(R.prop('total')))('lines'),
        ],
        ['Invoice Amount', applyProp(centsToDollarsWithFormat)('amount')],
        ['Markup', applyProp(centsToDollarsWithFormat)('markupAmount')],
      ]
    : [['Sub Total', R.compose(centsToDollarsWithFormat, calculateSubTotal)]]),
  ['Sales Tax', applyProp(centsToDollarsWithFormat)('taxAmount')],
  ...(isWIState(state)
    ? []
    : [
        [
          'Total',
          R.compose(centsToDollarsWithFormat, getSalesTaxReportNYTotal),
        ],
      ]),
];

function TotalFooter({ rows, state }) {
  return (
    <SalesTaxReportFooterContainer>
      <td>Total: </td>
      {isWIState(state) ? (
        <>
          <td>
            {calculateTotalByGetter(
              applyProp(calculateTotal(R.prop('total')))('lines'),
            )(rows)}
          </td>
          <td>{calculateTotalByGetter(R.prop('amount'))(rows)}</td>
          <td>{calculateTotalByGetter(R.prop('markupAmount'))(rows)}</td>
        </>
      ) : (
        <td>{calculateTotalByGetter(calculateSubTotal)(rows)}</td>
      )}
      <td>{calculateTotalByGetter(R.prop('taxAmount'))(rows)}</td>
      {!isWIState(state) && (
        <td>{calculateTotalByGetter(getSalesTaxReportNYTotal)(rows)}</td>
      )}
    </SalesTaxReportFooterContainer>
  );
}

TotalFooter.propTypes = {
  state: string.isRequired,
  rows: taxReportProps,
};

function SalesTaxReportTable({ state, isPrint, report, ...restTableProps }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    salesTaxReportTableConfigByState(state, isPrint),
    report,
  );

  const TableComp = isPrint
    ? SalesTaxReportPrintTableComp
    : SalesTaxReportTableComp;

  return (
    <TableComp
      {...tableProps}
      {...restTableProps}
      footerHeight={50}
      itemSize={55}
      moreScrollPosition="35px"
      TableFooter={TotalFooter}
      footerProps={{ state }}
      showScrollBar
      overflow="auto"
    />
  );
}

SalesTaxReportTable.propTypes = {
  isPrint: bool,
  state: string.isRequired,
  report: taxReportProps,
};

export function SalesTaxReportTableContainer({
  report,
  loading,
  ...restTableProps
}) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  return loading ? (
    <Loader />
  ) : (
    <WhiteBodyWrapper height={contentHeight}>
      <PayInvoicesTableCard>
        {R.isEmpty(report) ? (
          <EmptyListMessage />
        ) : (
          <SalesTaxReportTable
            report={report}
            {...restTableProps}
            state={report[0].state}
          />
        )}
      </PayInvoicesTableCard>
    </WhiteBodyWrapper>
  );
}

SalesTaxReportTableContainer.propTypes = {
  loading: bool,
  report: taxReportProps,
};
