import React from 'react';
import * as R from 'ramda';
import { PRINT_TYPES } from 'poly-constants';

import { ITEMS_LIMIT } from '../../core/constants/print.js';
import { activeStatusFilter } from '../../core/constants/projects.js';
import { TablePrintBtn } from '../../core/tableToolbar/TablePrintPDFBtn.js';
import { useMyProjectsBySearch } from '../../core/hooks/projects/index.js';

// getManagerProjects :: SearchManagerProjectsQueryResult -> [Project]
const getManagerProjects = R.pathOr([], ['searchManagerProjects', 'hits']);

// showWarningModal :: SearchManagerProjectsQueryResult -> Bool
const showWarningModal = R.pathSatisfies(R.gt(R.__, ITEMS_LIMIT), [
  'searchManagerProjects',
  'hits',
  'length',
]);

export function MyProjectsPrintPDFBtn() {
  const { result } = useMyProjectsBySearch({
    query: activeStatusFilter,
  });

  const data = getManagerProjects(result.me);

  const isWarningModalShowing = showWarningModal(result.me);

  return (
    <TablePrintBtn
      {...result}
      printType={PRINT_TYPES.MY_PROJECTS}
      style={{ marginLeft: 30 }}
      data={data}
      showWarningModal={isWarningModalShowing}
      metaData={{ title: 'My Projects' }}
    />
  );
}

MyProjectsPrintPDFBtn.displayName = 'MyProjectsPrintPDFBtn';
