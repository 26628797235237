import React from 'react';
import { useModalContext } from 'poly-admin-ui';
import { Icon } from 'poly-book-admin';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import { arrayOf, number, shape, string, instanceOf } from 'prop-types';
import { useCommonDeleteTimeButtonLogic } from '../../forms/timesheetForm/components/TimeOptionsBtns.js';
import {
  EditTimesheetForm,
  editTimeSheetFormId,
} from '../../forms/timesheetForm/edit.js';
import { isOutsideOfCurrentPayWeek } from '../../forms/timesheetForm/helpers/dates.js';
import { checkTimeRecordIsManual } from '../../forms/timesheetForm/helpers/common.js';

const defaultTableIconProps = {
  size: 13,
  color: '#4676b4',
};

export function EditTimeSheetBtn({ date, myTimesheets, ...timesheet }) {
  const { user } = useCurrentUserByStoreOrQuery();
  const { openModalForm, closeModal } = useModalContext();

  if (isOutsideOfCurrentPayWeek(date)) {
    return null;
  }

  const isManual = checkTimeRecordIsManual(timesheet);

  const onCancel = () => closeModal(editTimeSheetFormId);
  const onClick = () =>
    openModalForm({
      id: editTimeSheetFormId,
      title: 'Edit Time',
      subTitle: user?.fullName,
      formId: editTimeSheetFormId,
      btnCaption: 'Save',
      width: '740px',
      content: (
        <EditTimesheetForm
          onCancel={onCancel}
          isManual={isManual}
          timesheet={{ ...timesheet, date }}
          myTimesheets={myTimesheets}
        />
      ),
    });

  return <Icon onClick={onClick} name="edit" {...defaultTableIconProps} />;
}

EditTimeSheetBtn.propTypes = {
  date: string.isRequired,
  myTimesheets: arrayOf(
    shape({
      totalTimeInMinutes: number,
      type: string,
      date: string,
      notes: string,
      owner: shape({ _id: string }),
      entries: arrayOf(
        shape({
          timeIn: string,
          timeOut: string,
        }),
      ),
    }),
  ),
};

export function DeleteTimesheetButton({ _id, date }) {
  const { renderNothing, onClick } = useCommonDeleteTimeButtonLogic({
    _id,
    date,
  });

  if (renderNothing) return null;

  return <Icon {...defaultTableIconProps} name="delete" onClick={onClick} />;
}

DeleteTimesheetButton.displayName = 'DeleteTimesheetButton';

DeleteTimesheetButton.propTypes = {
  _id: string,
  date: instanceOf(Date),
};

export function EditTimeSheetBtnComponent(props) {
  return <EditTimeSheetBtn {...props} withoutUser />;
}
