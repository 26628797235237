import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { func, oneOf, shape, string, object, oneOfType } from 'prop-types';
import {
  Table,
  TableToolBarWrapper,
  Loader,
  getThemeColor,
  BodyRow,
} from 'poly-book-admin';
import { filesCollections } from 'poly-constants';

import { TabsViews } from '../../../constants/tabs.js';
import { TabTableWrapper } from '../commonTabComponents.js';
import { TableCardWithPaginationContainer } from '../../../components/index.js';
import { useFilesTabLogic } from '../../../sidebar/tabs/filesTab/useFilesTabLogic.js';

const FilesTable = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 500px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 40px;
    padding-top: 15px;
  }

  td {
    vertical-align: top;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const FileRow = styled(BodyRow)`
  background: ${({ row, ...props }) =>
    row.pinned && getThemeColor(['secondaryLighter5'])(props)};
`;

export function FilesTab({ AddFileBtn, collection, entity }) {
  const { loading, tableProps } = useFilesTabLogic({
    entity,
    collection,
    tabView: TabsViews.card,
  });

  return (
    <TableCardWithPaginationContainer>
      {loading ? (
        <Loader />
      ) : (
        <TabTableWrapper>
          <TableToolBarWrapper>
            <AddFileBtn {...{ collection, entity }} />
          </TableToolBarWrapper>
          <FilesTable {...tableProps} RowComponent={FileRow} />
        </TabTableWrapper>
      )}
    </TableCardWithPaginationContainer>
  );
}

FilesTab.propTypes = {
  AddFileBtn: oneOfType([func, object]).isRequired,
  collection: oneOf(R.values(filesCollections)).isRequired,
  entity: shape({
    _id: string.isRequired,
    humanReadableId: string,
  }).isRequired,
};
