import React from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { func, instanceOf, oneOfType, shape, string } from 'prop-types';
import { PAYMENT_DUE_DATE_CHANGE_REASON_LENGTH_LIMIT } from 'poly-constants';
import { commonFileValidators, validateFilesFunc } from 'poly-client-utils';
import { DatePicker, Input } from 'poly-book-admin';
import { SupplierSelect } from 'poly-admin-ui';

import { SupplierInvoiceLineEntry } from './SupplierInvoiceLineEntry.js';
import { AttachDocumentField } from '../../../../components/AttachDocumentField.js';
import { InvoiceDueDateField } from '../../../forms/supplierInvoiceForm/components/InvoiceDateField.js';
import { InvoiceNumberInput } from '../../../forms/supplierInvoiceForm/components/InvoiceNumberInput.js';
import { onKeyDownToPreventFormSubmit } from '../../../../utils/form.js';

const partWidth = 'calc(27% - 20px)';

const SUPPLIER_TERMS_QUERY = gql`
  query DEFAULT_SUPPLIER_QUERY($id: ID!) {
    supplier(id: $id) {
      _id
      finance {
        terms
      }
    }
  }
`;
function SupplierInvoiceDueDateField({ formData, value, ...props }) {
  const { data } = useQuery(SUPPLIER_TERMS_QUERY, {
    variables: {
      id: formData?.supplierId,
    },
    skip: !formData?.supplierId,
  });

  return (
    <InvoiceDueDateField
      value={value || formData?.invoiceDate}
      formData={{ ...formData, invoice: data }}
      {...props}
    />
  );
}

SupplierInvoiceDueDateField.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
  formData: shape({
    supplierId: string,
  }),
  value: oneOfType([instanceOf(Date), string]),
};

const generalSection = [
  {
    order: 1,
    layout: {
      column: 1,
    },
    fields: [
      {
        label: 'Supplier',
        order: 1,
        layout: { row: 1, width: 'calc(46% - 10px)' },
        field: {
          name: 'supplierId',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: SupplierSelect,
        },
        validators: [[R.identity, 'Supplier is required']],
        required: true,
      },
      {
        order: 2,
        required: true,
        label: 'Invoice #',
        layout: { row: 1, width: partWidth },
        field: {
          withFormData: true,
          name: 'invoiceNumber',
          Component: InvoiceNumberInput,
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
        },
        validators: [[R.identity, 'Invoice is required']],
      },
      {
        label: 'Invoice Date',
        order: 3,
        layout: { row: 1, width: partWidth },
        field: {
          name: 'invoiceDate',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: (props) => <DatePicker {...props} width="100%" />,
        },
        validators: [[R.identity, 'Invoice Date is required']],
      },
      {
        order: 4,
        layout: { width: partWidth },
        field: {
          withFormData: true,
          name: 'paymentDueDate',
          withChangeFieldValue: true,
          Component: SupplierInvoiceDueDateField,
        },
      },
      {
        order: 5,

        label: 'Reason for Change',
        field: {
          Component: Input,
          withFormData: true,
          name: 'paymentDueDateChangeReason',
          additionalProps: {
            charactersLimit: PAYMENT_DUE_DATE_CHANGE_REASON_LENGTH_LIMIT,
            showCharactersLeft: true,
          },
        },
        required: true,
        renderIf: R.either(
          R.prop('isDueDateChanged'),
          R.prop('paymentDueDateChangeReason'),
        ),
        validators: [[R.identity, 'Reason for Change is required']],
      },
      {
        label: 'Invoice File',
        order: 6,
        field: {
          name: 'invoiceFile',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: AttachDocumentField,
        },
        validators: commonFileValidators,
        validateFunction: validateFilesFunc,
      },
    ],
  },
];

const linesSection = [
  {
    order: 2,
    layout: {
      column: 2,
    },
    fields: [
      {
        order: 1,
        field: {
          name: 'lines',
          isArrayField: true,
          Component: SupplierInvoiceLineEntry,
        },
      },
    ],
  },
];

export const enterSupplierInvoiceSections = [
  ...generalSection,
  ...linesSection,
];
