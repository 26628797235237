import React from 'react';
import styled from 'styled-components';
import { func, node, object, oneOfType, bool } from 'prop-types';
import { defaultTheme, getThemeColor, Icon, Popover } from 'poly-book-admin';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const SidebarRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${({ skipPadding }) => !skipPadding && 'padding: 0 24px'};
  ${({ skipMargin }) => !skipMargin && 'margin-bottom: 14px'};
  ${({ justify }) => justify && 'justify-content: space-between'};
  ${({ align }) => align && 'align-items: center'};
  ${({ wrap }) => wrap && 'flex-wrap: wrap'};
  ${({ gap }) => gap && `gap: ${gap}`};
`;

const CloseFormBtn = styled(Icon)`
  position: absolute;
  right: 24px !important;
  top: 24px !important;
  cursor: pointer;
  padding: 3px;
`;

const CommonFormWrapper = React.memo(
  React.forwardRef(({ Wrapper, onCancel, children, ...props }, ref) => (
    <Wrapper ref={ref} {...props}>
      {children}
      <CloseFormBtn name="close" size={16} onClick={onCancel} />
    </Wrapper>
  )),
);

CommonFormWrapper.propTypes = {
  children: node,
  onCancel: func.isRequired,
  Wrapper: oneOfType([node, object]).isRequired,
};

const FormWrapperS = styled(SidebarRow)`
  position: relative;
  flex-direction: column;
  padding: 24px 22px 24px 24px !important;
  background-color: ${getThemeColor(['lighter'])};
`;

export const SidebarFormWrapper = React.forwardRef((props, ref) => (
  <CommonFormWrapper {...props} Wrapper={FormWrapperS} ref={ref} />
));

const TableFormWrapper = styled(FormWrapperS.withComponent('td'))`
  height: auto !important;
  padding-bottom: 24px !important;
  background-color: ${getThemeColor(['lighter'])};

  form {
    padding: ${({ skipMargin }) => (skipMargin ? 0 : '15px 0 10px 0')};
  }
`;

export const SidebarTableFormColumn = React.forwardRef((props, ref) => (
  <CommonFormWrapper {...props} Wrapper={TableFormWrapper} ref={ref} />
));

const {
  colors: { mid, primaryRegular },
} = defaultTheme;

function MoreIcon({ isActive }) {
  return (
    <Icon
      size={14}
      name="three-dots"
      data-testid="three-dots-icon"
      style={{ transform: 'rotate(90deg)' }}
      color={isActive ? primaryRegular : mid}
    />
  );
}

MoreIcon.propTypes = { isActive: bool };

export const ThreeDotsPopover = styled(Popover).attrs(() => ({
  position: 'left',
  Icon: MoreIcon,
}))``;
