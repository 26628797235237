import * as R from 'ramda';
import React from 'react';
import { bool, func, string } from 'prop-types';
import { ClientSelect, UserSelect } from 'poly-admin-ui';
import { InputHTML5 } from 'poly-book-admin';
import { AccountingStatus, ProjectType, WorkOrderStatus } from 'poly-constants';

import { SearchProjectsHeaderCheckbox } from '../../modules/pagesHeaders/ProjectSearchPageHeader/filter/styles.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';
import {
  PropertySelect,
  MAIN_ACCOUNT_OPTION_VALUE,
} from '../../modules/selects/PropertySelect/PropertySelect.js';
import { ManagerSelect } from '../../components/AACManagerSelect.js';
import {
  generateTermsQueries,
  matchQueryGenerator,
  generateSearchQuery,
} from '../../utils/generateSearchQuery.js';
import { SearchHeaderColumnTitle } from '../../components/SearchHeaderColumn.js';
import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';

function SearchProjectsHeaderStatusRowTitle({ title }) {
  return (
    <SearchHeaderColumnTitle width="130px">{title}</SearchHeaderColumnTitle>
  );
}

function ClientManagerSelect(props) {
  return (
    <UserSelect
      {...props}
      withoutSkip
      query={{ bool: { must: [{ term: { isClientManager: true } }] } }}
      placeholder="Start typing client managers"
    />
  );
}

ManagerSelect.defaultProps = {
  placeholder: 'Start typing managers',
};

SearchProjectsHeaderStatusRowTitle.propTypes = {
  title: string.isRequired,
};

const statusesFilters = {
  active: WorkOrderStatus.ACTIVE,
  completed: WorkOrderStatus.COMPLETED,
  blocked: WorkOrderStatus.BLOCKED,
  pending: 'pendingProjectStatus',
  onHold: 'onHold',
};

const accountingStatusesFilters = {
  pending: AccountingStatus.PENDING,
  readyToInvoice: AccountingStatus.READY_TO_INVOICE,
  invoiced: AccountingStatus.INVOICED,
  paid: AccountingStatus.PAID,
};

const defaultFilters = {
  [statusesFilters.completed]: true,
  [accountingStatusesFilters.readyToInvoice]: true,
};

const searchFiltersNames = {
  statuses: statusesFilters,
  accountingStatuses: accountingStatusesFilters,
  searchTerm: 'searchTerm',
  projectId: 'projectId',
  clientId: 'clientId',
  propertyId: 'propertyId',
  managerId: 'managerId',
  clientManagerId: 'clientManagerId',
};

const searchProjectsHeaderConfig = [
  {
    key: 'statusRow',
    rowTitle: <SearchProjectsHeaderStatusRowTitle title="Status" />,
    rowLayout: {
      justifyContent: 'flex-start',
      columnWidth: '150px',
    },
    columns: [
      {
        name: statusesFilters.active,
        filterProps: { label: 'Active' },
        Component: SearchProjectsHeaderCheckbox,
        column: 1,
      },
      {
        name: statusesFilters.pending,
        filterProps: { label: 'Pending' },
        Component: SearchProjectsHeaderCheckbox,
        column: 2,
      },
      {
        name: statusesFilters.completed,
        filterProps: { label: 'Completed' },
        Component: SearchProjectsHeaderCheckbox,
        defaultValue: true,
        column: 3,
      },
      {
        name: statusesFilters.onHold,
        filterProps: { label: 'On Hold' },
        Component: SearchProjectsHeaderCheckbox,
        column: 4,
      },
      {
        name: statusesFilters.blocked,
        filterProps: { label: 'Blocked' },
        Component: SearchProjectsHeaderCheckbox,
        column: 5,
      },
    ],
  },
  {
    key: 'accountingStatusRow',
    rowTitle: <SearchProjectsHeaderStatusRowTitle title="Accounting Status" />,
    rowLayout: {
      justifyContent: 'flex-start',
      columnWidth: '150px',
    },
    columns: [
      {
        name: accountingStatusesFilters.pending,
        filterProps: { label: 'Pending' },
        Component: SearchProjectsHeaderCheckbox,
        column: 1,
      },
      {
        name: accountingStatusesFilters.readyToInvoice,
        filterProps: { label: 'Ready To Invoice' },
        Component: SearchProjectsHeaderCheckbox,
        defaultValue: true,
        column: 2,
      },
      {
        name: accountingStatusesFilters.invoiced,
        filterProps: { label: 'Invoiced' },
        Component: SearchProjectsHeaderCheckbox,
        column: 3,
      },
      {
        name: accountingStatusesFilters.paid,
        filterProps: { label: 'Paid' },
        Component: SearchProjectsHeaderCheckbox,
        column: 4,
      },
    ],
  },
  {
    key: 'projectInfoRow',
    columns: [
      {
        name: searchFiltersNames.searchTerm,
        title: 'Project Description',
        filterProps: { width: '100%' },
        Component: InputHTML5,
        column: 1,
      },
      {
        name: searchFiltersNames.projectId,
        title: 'Project #',
        filterProps: { width: '100%', withoutNonBillable: true },
        Component: ProjectSelect,
        column: 1,
      },
      {
        name: searchFiltersNames.clientId,
        title: 'Account',
        filterProps: { width: '100%' },
        Component: ClientSelect,
        column: 2,
      },
      {
        name: searchFiltersNames.propertyId,
        title: 'Branch',
        filterProps: { width: '100%', includeMainAccountOption: true },
        Component: PropertySelect,
        column: 2,
      },
      {
        name: searchFiltersNames.managerId,
        title: 'Assigned CSR',
        filterProps: { width: '100%' },
        Component: ManagerSelect,
        column: 3,
      },
      {
        name: searchFiltersNames.clientManagerId,
        title: 'Client Manager',
        filterProps: { width: '100%' },
        Component: ClientManagerSelect,
        column: 3,
      },
    ],
  },
];

const propertyQueryGenerator = R.compose(
  R.ifElse(
    R.equals(MAIN_ACCOUNT_OPTION_VALUE),
    R.always({
      bool: {
        must_not: [
          {
            exists: {
              field: 'propertyId',
            },
          },
        ],
      },
    }),
    matchQueryGenerator('propertyId'),
  ),
);

const queryGenerators = {
  [searchFiltersNames.clientId]: matchQueryGenerator('clientId'),
  [searchFiltersNames.projectId]: matchQueryGenerator('_id'),
  [searchFiltersNames.propertyId]: propertyQueryGenerator,
  [searchFiltersNames.managerId]: matchQueryGenerator('managerId'),
  [searchFiltersNames.clientManagerId]: matchQueryGenerator('clientManagerId'),
};

const excludeProjectsTerms = [
  {
    terms: {
      type: [ProjectType.REPORT_ONLY, ProjectType.HOUSEKEEPING],
    },
  },
  {
    terms: {
      accountingStatus: [
        AccountingStatus.WRITTEN_OFF,
        AccountingStatus.NON_BILLABLE,
        AccountingStatus.NON_BILLABLE_CLOSED,
      ],
    },
  },
];

// generateProjectsSearchQuery :: SearchFilters -> ElasticQuery
// SearchFilters, ElasticQuery = Object
const generateProjectsSearchQuery = R.compose(
  R.assocPath(['bool', 'must_not'], excludeProjectsTerms),
  R.converge(
    (terms, query) =>
      R.over(R.lensPath(['bool', 'must']), R.concat(terms), query),
    [
      generateTermsQueries([
        [
          'status',
          {
            [statusesFilters.active]: [WorkOrderStatus.ACTIVE],
            [statusesFilters.pending]: [WorkOrderStatus.PENDING],
            [statusesFilters.completed]: [WorkOrderStatus.COMPLETED],
            [statusesFilters.blocked]: [WorkOrderStatus.BLOCKED],
            [statusesFilters.onHold]: [
              WorkOrderStatus.ON_HOLD_PARTS,
              WorkOrderStatus.ON_HOLD_CLIENT,
              WorkOrderStatus.ON_HOLD_PROJECT,
            ],
          },
        ],
        [
          'accountingStatus',
          {
            [accountingStatusesFilters.pending]: [AccountingStatus.PENDING],
            [accountingStatusesFilters.readyToInvoice]: [
              AccountingStatus.READY_TO_INVOICE,
            ],
            [accountingStatusesFilters.invoiced]: [AccountingStatus.INVOICED],
            [accountingStatusesFilters.paid]: [AccountingStatus.PAID],
          },
        ],
      ]),
      generateSearchQuery(queryGenerators),
    ],
  ),
);

export function CreateInvoiceSearchHeader({ setSearchInput, loading }) {
  return (
    <SearchHeaderCreator
      onSearch={(filters) =>
        setSearchInput({
          query: generateProjectsSearchQuery(filters),
          searchTerm: filters.searchTerm,
        })
      }
      loading={loading}
      config={searchProjectsHeaderConfig}
      onReset={() =>
        setSearchInput({
          query: generateProjectsSearchQuery(defaultFilters),
        })
      }
      autoSearch
    />
  );
}

CreateInvoiceSearchHeader.propTypes = {
  setSearchInput: func,
  loading: bool,
};
