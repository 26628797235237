import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';
import { useOutSidebarContext, undefinedAsNullDeep } from 'poly-client-utils';
import { useNotificationState } from 'poly-admin-ui';
import { AssetStatuses } from 'poly-constants';
import { isNilOrEmpty } from 'poly-utils';

import { addAssetFormId } from '../../constants.js';
import { selectSingleFileNameWithUpload } from '../../../../utils/files.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';

const CREATE_ASSET_MUTATION = gql`
  mutation CREATE_ASSET_MUTATION($input: CreateAssetInput!) {
    createAsset(input: $input) {
      asset {
        _id
      }
    }
  }
`;

// isFormWithNewEquipment :: FormData -> Boolean
const isFormWithNewEquipment = R.both(
  R.propSatisfies(R.complement(R.equals(AssetStatuses.PENDING)), 'status'),
  R.compose(
    R.complement(R.isEmpty),
    R.reject(isNilOrEmpty),
    R.props(['newManufacturerName', 'newModelName']),
  ),
);

// prepareNewEquipmentToMutation :: FormData -> AssetMutationInput
export const prepareNewEquipmentToMutation = R.compose(
  R.when(isFormWithNewEquipment, R.assoc('status', AssetStatuses.PENDING)),
  R.when(R.prop('newModelName'), R.assoc('modelId', null)),
  R.when(
    R.prop('newManufacturerName'),
    R.compose(R.assoc('modelId', null), R.assoc('manufacturerId', null)),
  ),
  R.omit([
    'isNewModel',
    'searchedModel',
    'isNewManufacturer',
    'searchedManufacturer',
    'modelDoc',
    'type',
    'manufacturerDoc',
    'isReplacementCostChanged',
  ]),
);

// prepareFormDataToMutation :: FormData -> CreateAssetInput
// eslint-disable-next-line import/no-unused-modules
export const prepareFormDataToMutation = R.compose(
  prepareNewEquipmentToMutation,
  R.omit(['status', 'clientId', 'modelDoc', 'showClient']),
  R.ifElse(
    R.propSatisfies(R.isEmpty, 'photo'),
    R.dissoc('photo'),
    R.over(R.lensProp('photo'), selectSingleFileNameWithUpload),
  ),
  undefinedAsNullDeep,
);

export const useOnSubmitAddAssetForm = () => {
  const { showSuccessNotification } = useNotificationState();
  const { openAssetSidebar } = useSidebarLogicContext();
  const { closeOutSidebar } = useOutSidebarContext();

  const [createAssetMutation] = useMutation(CREATE_ASSET_MUTATION);

  return async (formData) => {
    const input = prepareFormDataToMutation(formData);

    const mutationResult = await createAssetMutation({
      variables: { input },
    });

    const assetId = R.path(
      ['data', 'createAsset', 'asset', '_id'],
      mutationResult,
    );

    showSuccessNotification('Asset was successfully created');
    closeOutSidebar(addAssetFormId);
    openAssetSidebar(assetId);
  };
};
