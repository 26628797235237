import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { string, func, shape, arrayOf } from 'prop-types';
import { Input, Button, Icon } from 'poly-book-admin';
import { forceTitleCase } from 'poly-utils';

import { UserGroupVariableLineS } from './userGroupFormComponents.js';
import { setUserGroupVariables } from '../../../../redux/userGroupVariablesReducer.js';

// findIdIndexByOptions :: [Option] -> String
const findIdIndexByOptions = R.compose(
  R.toString,
  R.ifElse(
    R.isNil,
    R.always(1),
    R.compose(
      R.inc,
      (prev) => parseInt(prev, 10),
      R.drop(2),
      R.last,
      R.split('_'),
      R.prop('value'),
    ),
  ),
  R.last,
  R.sortBy(R.prop('value')),
  R.defaultTo([]),
);

// generateNewVariableIdByName :: [Option] -> String -> String
const generateNewVariableIdByName = (options) =>
  R.compose(
    R.converge(R.concat, [R.identity, () => findIdIndexByOptions(options)]),
    R.join('_'),
    R.append('id'),
    R.split(' '),
    R.toLower,
    R.replace(/[^\w\s]/gi, ''),
    R.defaultTo(''),
  );

// prepareNextSuffixForLabel :: String -> [String]
const prepareNextSuffixForLabel = (previousSuffix) => {
  const nextSuffix = parseInt(previousSuffix, 10) + 1;
  return [`(${nextSuffix})`];
};

// generateNewVariableLabelByName :: [Option] -> String -> String
const generateNewVariableLabelByName = (options) => (name) =>
  R.compose(
    R.ifElse(
      R.isEmpty,
      R.always(name),
      R.compose(
        R.join(' '),
        R.prepend(name),
        R.ifElse(
          R.isEmpty,
          R.always(['(1)']),
          R.compose(prepareNextSuffixForLabel, R.nth(1)),
        ),
        R.match(/\((\d+)\)$/),
        R.prop('label'),
        R.last,
        R.sortBy(R.prop('label')),
      ),
    ),
    R.filter(R.propSatisfies(R.includes(name), 'label')),
    R.defaultTo([]),
  )(options);

// prepareNewVariableToOptions :: (String, [Option]) -> [Option]
const prepareNewVariableToOptions = (label, options) =>
  R.append({
    label: generateNewVariableLabelByName(options)(label),
    value: generateNewVariableIdByName(options)(label),
  })(options);

// prepareChangedVariableToOptions :: (String, ID) -> [Option] -> [Option]
const prepareChangedVariableToOptions = (label, selectedOptionId) =>
  R.map(R.when(R.propEq('value', selectedOptionId), R.assoc('label', label)));

// getChangedVariableOption :: ID -> [Option] -> Option
const getChangedVariableOption = (selectedOptionId) =>
  R.find(R.propEq('value', selectedOptionId));

// getVariableById :: ID -> [Option] -> String
const getVariableById = (id) =>
  R.compose(R.defaultTo(''), R.prop('label'), R.find(R.propEq('value', id)));

// checkIfVariableNameAlreadyExists :: String -> UserGroupVariables -> Boolean
const checkIfVariableNameAlreadyExists = (name) =>
  R.compose(R.includes(name), R.map(R.prop('label')), R.flatten, R.values);

export function UserGroupScopeVariableInput({
  value,
  scope,
  options,
  onChange,
  inputMode,
  setInputMode,
}) {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const userGroupVariables =
    useSelector((store) => store.userGroupVariables) || {};

  const defaultVariable = useMemo(
    () => (inputMode === 'add' ? '' : getVariableById(value)(options)),
    [value, options, inputMode],
  );

  const [error, setError] = useState(null);
  const [inputValue, setInputValue] = useState(defaultVariable);

  const onInputChange = (e) => setInputValue(e.target.value);

  const onClose = () => setInputMode(null);

  const trimmedInputValue = useMemo(() => R.trim(inputValue), [inputValue]);

  useEffect(() => {
    const nameAlreadyExists =
      checkIfVariableNameAlreadyExists(trimmedInputValue)(userGroupVariables);

    if (nameAlreadyExists && !error) {
      setError('Variable with this name already exists');
    }

    if (!nameAlreadyExists && !!error) {
      setError(null);
    }
  }, [trimmedInputValue, userGroupVariables]);

  const onSubmitVariable = () => {
    if (!trimmedInputValue || !!error) return;

    const updatedVariables =
      inputMode === 'add'
        ? prepareNewVariableToOptions(trimmedInputValue, options)
        : prepareChangedVariableToOptions(trimmedInputValue, value)(options);

    dispatch(
      setUserGroupVariables({
        ...userGroupVariables,
        [scope]: updatedVariables,
      }),
    );

    const changedVariable =
      inputMode === 'save'
        ? getChangedVariableOption(value)(updatedVariables)
        : {
            value: generateNewVariableIdByName(options)(trimmedInputValue),
            label: generateNewVariableLabelByName(options)(trimmedInputValue),
          };

    onChange(changedVariable);

    onClose();
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const buttonProps = {
    size: 'small',
    type: 'button',
    styleType: 'basicSecondary',
  };

  return (
    <UserGroupVariableLineS>
      <Input
        required
        width="100%"
        error={error}
        ref={inputRef}
        value={inputValue}
        onChange={onInputChange}
        name="user-group-variable-input"
      />
      <Button {...buttonProps} onClick={onSubmitVariable}>
        {forceTitleCase(inputMode)}
      </Button>
      <Button {...buttonProps} onClick={onClose}>
        <Icon name="close" size={10} color="#575757" />
      </Button>
    </UserGroupVariableLineS>
  );
}

UserGroupScopeVariableInput.propTypes = {
  value: string,
  inputMode: string,
  scope: string.isRequired,
  onChange: func.isRequired,
  setInputMode: func.isRequired,
  options: arrayOf(shape({ value: string.isRequired })),
};
