import React from 'react';
import { LinkButton } from 'poly-book-admin';
import { arrayOf, shape, string, oneOfType, instanceOf } from 'prop-types';
import * as R from 'ramda';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';

import {
  checkTicketReceivedColumn,
  formatExportHeader,
} from './houseKeepingServiceTicketsHelpers.js';

// getHouseKeepingServiceTicketsReportRowConfig :: Project -> [ExcelExportDataCell]
const getHouseKeepingServiceTicketsReportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 9),
  ]),
  R.juxt([
    R.prop('projectId'),
    R.path(['property', 'name']),
    R.path(['suppliers', 0, 'company', 'name']),
    checkTicketReceivedColumn(1, true),
    checkTicketReceivedColumn(2, true),
    checkTicketReceivedColumn(3, true),
    checkTicketReceivedColumn(4, true),
    checkTicketReceivedColumn(5, true),
    checkTicketReceivedColumn(6, true),
  ]),
);

// getHouseKeepingServiceTicketsReportExportExcelConfig :: Object -> ExcelConfig
export const getHouseKeepingServiceTicketsReportExportExcelConfig = ({
  projects,
  startDate,
  endDate,
}) => ({
  exportFileName: 'house-keeping-service-tickets-report.xlsx',
  columnWidths: [20, 30, 30, 15, 15, 15, 15, 15, 15],
  rows: [
    [
      createExcelExportCell(
        ExcelExportCellType.title,
        formatExportHeader(startDate, endDate),
      ),
    ],
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 9)],
      [
        'Project #',
        'Property',
        'Supplier',
        'Week 1',
        'Week 2',
        'Week 3',
        'Week 4',
        'Week 5',
        'Week 6',
      ],
    ),
    ...R.map(getHouseKeepingServiceTicketsReportRowConfig, projects),
  ],
});

export function HouseKeepingServiceTicketsReportExportXLBtn({
  projects,
  startDate,
  endDate,
}) {
  const handleClick = () => {
    const excelConfig = getHouseKeepingServiceTicketsReportExportExcelConfig({
      projects,
      startDate,
      endDate,
    });
    performExcelExport(excelConfig);
  };
  return (
    <LinkButton disabled={projects.length === 0} onClick={handleClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

HouseKeepingServiceTicketsReportExportXLBtn.propTypes = {
  projects: arrayOf(
    shape({
      projectId: string.isRequired,
      property: shape({ name: string.isRequired }),
    }),
  ),
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
};
