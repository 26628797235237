import { Select } from 'poly-book-admin';
import React from 'react';

import { filterClientInvoiceByOptions } from './constants.js';

const searchInvoiceByOptions = [
  { value: filterClientInvoiceByOptions.one, label: 'One' },
  { value: filterClientInvoiceByOptions.range, label: 'Range' },
];

export function SearchBySelector(props) {
  const selectProps = {
    options: searchInvoiceByOptions,
    ...props,
  };

  return <Select {...selectProps} />;
}
