import React, { useState } from 'react';
import * as R from 'ramda';
import { ClientSelect, SupplierSelect } from 'poly-admin-ui';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import {
  Button,
  MainHeader,
  DateRangePicker,
  ToolBarBtnDivider,
  PageHeaderContainer,
} from 'poly-book-admin';
import styled, { css } from 'styled-components';

import { ClientPropertiesSelect } from '../../modules/selects/PropertySelect/PropertySelect.js';
import { DiscrepanciesPercentageSelect } from './components/DiscrepanciesPercentageSelect.js';

import {
  FilterContainerS,
  FlexCenterAlignS,
  SelectContainerS,
  ButtonContainerS,
  FlexColumnFullWidth,
} from '../SpendReport/SpendDetailsPageHeader/SpendDetailsPageHeaderC.js';
import { QuoteDiscrepanciesPdfExportButton } from './QuoteDiscrepanciesPdfExportButton.js';
import { QuoteDiscrepanciesXlsExportButton } from './QuoteDiscrepanciesXlsExportButton.js';
import { ALL } from '../../modules/core/constants/general.js';

const defaultFilters = {
  endDate: endOfDay(new Date()),
  startDate: startOfDay(subMonths(new Date(), 1)),
};

const headerStyles = css`
  height: 150px;
  @media (max-width: 1400px) {
    height: 150px;
  }
`;

const ResponsiveSelectsWrapper = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  gap: 25px;
  @media (max-width: 1440px) {
    width: 100%;
    justify-content: ${R.propOr('flex-start', 'justifyContent')};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const ResponsiveFlexCenterAlign = styled(FlexCenterAlignS)`
  @media (max-width: 1440px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
    & > div {
      margin-left: 0;
    }
  }
`;

// preparePropertyFilterValue :: String -> String
const preparePropertyFilterValue = R.when(R.equals(ALL), R.always(''));

const useQuoteDiscrepanciesFilter = () => {
  const [filters, setFilters] = useState(defaultFilters);

  const onClientChange = (value) => {
    setFilters({
      ...filters,
      clientId: value,
      propertyId: null,
    });
  };

  const onClientPropertyChange = (value) => {
    setFilters({ ...filters, propertyId: preparePropertyFilterValue(value) });
  };

  const onDiscrepanciesPercentageChange = (value) => {
    setFilters({ ...filters, percentage: value });
  };

  const onDatesChange = (dates) => setFilters({ ...filters, ...dates });

  const onSupplierChange = (supplierId) =>
    setFilters({ ...filters, supplierId });

  return {
    filters,
    onClientChange,
    onClientPropertyChange,
    onDiscrepanciesPercentageChange,
    onDatesChange,
    onSupplierChange,
  };
};

export function QuoteDiscrepanciesPageHeader({
  setQueryInput,
  tableRows,
  title,
  withSupplierSelect,
  getTableConfig,
  prepareFooterData,
  getXLSConfig,
}) {
  const {
    filters,
    onClientChange,
    onDatesChange,
    onSupplierChange,
    onClientPropertyChange,
    onDiscrepanciesPercentageChange,
  } = useQuoteDiscrepanciesFilter();

  const { clientId, propertyId, percentage, startDate, endDate, supplierId } =
    filters;

  const onPreview = () => setQueryInput(filters);

  const isPreviewDisabled = !startDate || !endDate;

  return (
    <PageHeaderContainer height="150px" headerStyles={headerStyles}>
      <FlexColumnFullWidth>
        <MainHeader>{title}</MainHeader>
        <FilterContainerS>
          <ResponsiveFlexCenterAlign>
            <ResponsiveSelectsWrapper>
              <SelectContainerS>
                <ClientSelect
                  withoutSkip
                  selectedOptionAtOnChange
                  includeAllOption={false}
                  value={clientId}
                  onChange={onClientChange}
                />
              </SelectContainerS>
              <SelectContainerS>
                <ClientPropertiesSelect
                  withoutSkip
                  disabled={!clientId}
                  clientId={clientId}
                  value={propertyId}
                  onChange={onClientPropertyChange}
                />
              </SelectContainerS>
              {withSupplierSelect && (
                <SelectContainerS>
                  <SupplierSelect
                    value={supplierId}
                    onChange={onSupplierChange}
                    isClearable
                  />
                </SelectContainerS>
              )}
              <SelectContainerS>
                <DiscrepanciesPercentageSelect
                  value={percentage}
                  onChange={onDiscrepanciesPercentageChange}
                />
              </SelectContainerS>
            </ResponsiveSelectsWrapper>
            <ResponsiveSelectsWrapper justifyContent="space-between">
              <DateRangePicker
                width="140px"
                value={{ startDate, endDate }}
                onChange={onDatesChange}
              />
              <ButtonsWrapper>
                <Button
                  size="small"
                  onClick={onPreview}
                  disabled={isPreviewDisabled}
                >
                  Preview
                </Button>
                {tableRows.length > 0 && (
                  <ButtonContainerS>
                    <QuoteDiscrepanciesPdfExportButton
                      tableRows={tableRows}
                      getTableConfig={getTableConfig}
                      prepareFooterData={prepareFooterData}
                      title={title}
                    />
                    <ToolBarBtnDivider />
                    <QuoteDiscrepanciesXlsExportButton
                      tableRows={tableRows}
                      getXLSConfig={getXLSConfig}
                      title={title}
                      {...filters}
                    />
                  </ButtonContainerS>
                )}
              </ButtonsWrapper>
            </ResponsiveSelectsWrapper>
          </ResponsiveFlexCenterAlign>
        </FilterContainerS>
      </FlexColumnFullWidth>
    </PageHeaderContainer>
  );
}

QuoteDiscrepanciesPageHeader.propTypes = {
  setQueryInput: func.isRequired,
  tableRows: arrayOf(shape({})),
  title: string.isRequired,
  withSupplierSelect: bool,
  getTableConfig: func.isRequired,
  prepareFooterData: func.isRequired,
  getXLSConfig: func.isRequired,
};
