import React from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import { Checkbox, Icon, Popover, PopoverLink } from 'poly-book-admin';
import { WorkOrderStatus } from 'poly-constants';

import { useTasksContext } from './tasksTableHocs.js';

// isRowDisabled :: Task -> Boolean
export const isRowDisabled = R.either(
  R.prop('complete'),
  R.propEq('projectStatus', WorkOrderStatus.BLOCKED),
);

export function TaskCompleteBtn(task) {
  const { onCompleteTask } = useTasksContext();

  const onChange = () => (task.complete ? null : onCompleteTask(task));

  return (
    <Checkbox
      name={`taskId-${task._id}`}
      value={task.complete}
      onChange={onChange}
      {...task}
    />
  );
}

TaskCompleteBtn.propTypes = {
  complete: bool,
  _id: string.isRequired,
};

function IconMore({ isActive, ...props }) {
  return (
    <Icon
      size={18}
      name="three-dots"
      color={isActive ? '#4676b4' : 'rgba(30, 36, 44, 0.4)'}
      {...props}
    />
  );
}

IconMore.propTypes = {
  isActive: bool.isRequired,
};

function TaskEditBtn(task) {
  const { onEditTask } = useTasksContext();

  const onClick = () => onEditTask(task);

  return isRowDisabled(task) ? null : (
    <PopoverLink onClick={onClick} {...task}>
      Edit Task
    </PopoverLink>
  );
}

function TaskUpdateBtn(task) {
  const { onUpdateTask } = useTasksContext();

  const onClick = () => onUpdateTask(task);

  return (
    <PopoverLink onClick={onClick} {...task}>
      Add Update
    </PopoverLink>
  );
}

export function TaskMoreBtn({ position, ...props }) {
  return (
    <Popover
      {...{ position }}
      Icon={IconMore}
      content={
        <>
          <TaskEditBtn {...props} />
          <TaskUpdateBtn {...props} />
        </>
      }
    />
  );
}

TaskMoreBtn.propTypes = { position: string };
TaskMoreBtn.defaultProps = { position: null };
