import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePicker } from 'poly-book-admin';
import {
  Label,
  RowWrapper,
  PreviewButton,
  FilterWrapper,
  ClientSelectS,
} from './styles.js';
import { setRecentProjects } from '../../tables/recentProjectsTable/recentProjectsTableReducer.js';

export function RecentProjectsFilter() {
  const dispatch = useDispatch();

  const { isDisabled } = useSelector((state) => state.recentProjects);

  const [clientId, setClientId] = useState('');

  const [dateRangeState, setDateRangeState] = useState({
    startDate: '',
    endDate: '',
  });

  const { startDate, endDate } = dateRangeState;

  const selectClient = (clientIdValue) => setClientId(clientIdValue);
  const setDateRange = (dateRangeValue) => setDateRangeState(dateRangeValue);

  useEffect(() => {
    if (!clientId || !startDate || !endDate) {
      dispatch(setRecentProjects({ isDisabled: true }));
    } else {
      dispatch(setRecentProjects({ isDisabled: false }));
    }
  }, [clientId, startDate, endDate]);

  const onPreviewClick = () =>
    dispatch(setRecentProjects({ clientId, startDate, endDate }));

  const defaultDateProps = {
    placeholder: 'Select date range',
    labelPosition: 'left',
    showResetBtn: true,
    isMultiple: true,
  };

  return (
    <FilterWrapper>
      <RowWrapper>
        <Label>Client:</Label>
        <ClientSelectS size="small" value={clientId} onChange={selectClient} />
      </RowWrapper>
      <RowWrapper>
        <Label>Date Selector:</Label>
        <DateRangePicker
          value={dateRangeState}
          {...defaultDateProps}
          onChange={setDateRange}
          width="165px"
          size="small"
        />
      </RowWrapper>
      <PreviewButton onClick={onPreviewClick} disabled={isDisabled} />
    </FilterWrapper>
  );
}

RecentProjectsFilter.displayName = 'RecentProjectsFilter';
