import React from 'react';
import * as R from 'ramda';
import { arrayOf, string } from 'prop-types';
import { NOTHING_UI_STRING, EXPORT_XLS_CAPTION } from 'poly-constants';
import { LinkButton } from 'poly-book-admin';
import { calculateTotal, formatDate } from 'poly-utils';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
  formatFromToDateTitle,
} from 'poly-client-utils';

import {
  getTransactionUserFriendlyNumber,
  getTransactionUserFriendlyName,
} from '../VendorLedgerReport/vendorLedgerReportUtils.js';
import {
  supplierPaymentHistoryTransactionPropType,
  supplierPaymentHistoryFilterPropType,
} from './propTypes.js';
import { getSupplierTransactionUserFriendlyType } from './SupplierPaymentHistoryTable.js';

// getSupplierPaymentRow :: SupplierPaymentTransaction -> [ExcelExportDataCell]
const getSupplierPaymentRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 6),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
  ]),
  R.juxt([
    getTransactionUserFriendlyName,
    R.compose(formatDate, R.prop('date')),
    getTransactionUserFriendlyNumber,
    getSupplierTransactionUserFriendlyType,
    R.pathOr(NOTHING_UI_STRING, ['project', 'projectId']),
    R.path(['invoice', 'invoiceNumber']),
    R.prop('invoiceAmount'),
    R.prop('paymentAmount'),
  ]),
);

// getTotalByProp :: String -> [SupplierPaymentTransaction] -> Number
const getTotalByProp = (prop) => calculateTotal(R.propOr(0, prop));

// eslint-disable-next-line import/no-unused-modules
export const getSupplierPaymentHistoryExcelExportConfig = (
  transactions,
  { invoiceDate },
  supplierName,
) => ({
  exportFileName: 'supplier-payment-history.xlsx',
  columnWidths: [30, 20, 20, 40, 20, 20, 15, 15],
  rows: [
    [
      {
        value: 'Supplier Payment History',
        cellType: ExcelExportCellType.title,
      },
    ],
    [{ value: supplierName, cellType: ExcelExportCellType.subTitle }],
    [
      {
        value: formatFromToDateTitle(
          invoiceDate.startDate,
          invoiceDate.endDate,
        ),
        cellType: ExcelExportCellType.subTitle,
      },
    ],

    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 6),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 2),
      ],
      [
        'Transaction',
        'Date',
        'Check Num',
        'Type',
        'Project #',
        'Invoice Number',
        'Invoice',
        'Payment',
      ],
    ),

    ...transactions.map(getSupplierPaymentRow),

    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableStringFooter, 6),
        ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 2),
      ],
      [
        'Total',
        ...R.repeat('', 5),
        getTotalByProp('invoiceAmount')(transactions),
        getTotalByProp('paymentAmount')(transactions),
      ],
    ),
  ],
});

export function SupplierPaymentHistoryExportXLSBtn({
  transactions,
  filters,
  supplierName,
}) {
  const onClick = () => {
    const config = getSupplierPaymentHistoryExcelExportConfig(
      transactions,
      filters,
      supplierName,
    );
    performExcelExport(config);
  };

  return (
    <LinkButton disabled={transactions.length === 0} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

SupplierPaymentHistoryExportXLSBtn.propTypes = {
  transactions: arrayOf(supplierPaymentHistoryTransactionPropType),
  filters: supplierPaymentHistoryFilterPropType,
  supplierName: string.isRequired,
};
