import * as R from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { useRouterQuery } from 'poly-client-routing';
import { ClientStatuses, EXPORT_XLS_CAPTION } from 'poly-constants';
import { arrayOf, object, oneOfType, string } from 'prop-types';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from 'poly-client-utils';
import {
  usePagination,
  useTableSearch,
  CommonOuterButton,
} from 'poly-admin-ui';

import { PageTabMap } from './constants.js';
import { CLIENTS_BY_SEARCH } from '../../modules/core/hooks/clients/queries.js';

const { ACTIVE, INACTIVE } = ClientStatuses;

const PageTabToStatusMap = {
  [PageTabMap.ACTIVE_CLIENTS]: ACTIVE,
  [PageTabMap.INACTIVE_CLIENTS]: INACTIVE,
};

const CommonOuterButtonS = styled(CommonOuterButton)`
  margin-right: 10px;
`;

const ClientStatusesUi = {
  [ClientStatuses.ACTIVE]: 'Active',
  [ClientStatuses.INACTIVE]: 'Inactive',
};

// getClientRowConfig :: Client -> [ExcelExportDataCell]
const getClientRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
  ]),
  R.juxt([
    R.ifElse(
      R.propIs(String, 'status'),
      R.compose(R.prop(R.__, ClientStatusesUi), R.prop('status')),
      R.always(''),
    ),
    R.propOr('', 'name'),
    R.pathOr('', ['address', 'formatted_address']),
    R.pathOr('', ['address', 'address_parts', 'street_number']),
    R.pathOr('', ['address', 'address_parts', 'route']),
    R.pathOr('', ['address', 'address_parts', 'locality']),
    R.pathOr('', ['address', 'address_parts', 'administrative_area_level_1']),
    R.pathOr('', ['address', 'address_parts', 'postal_code']),
  ]),
);

export const getXlsClientsConfig = (status, clients) => ({
  exportFileName: `${status}_clients_export.xlsx`,
  columnWidths: [10, 25, 45, 25, 25, 25, 10, 10],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      [
        'Status',
        'Name',
        'Address (full)',
        'Street Number',
        'Street Name',
        'City',
        'State',
        'Zip',
      ],
    ),
    ...R.map(getClientRowConfig, clients),
  ],
});

// getQueryByTab :: String -> String
const getStatusByTab = R.compose(
  R.defaultTo(ACTIVE),
  R.prop(R.__, PageTabToStatusMap),
);

export function ClientsExportToXLSBtn({ sort }) {
  const totalCount = useSelector(R.path(['pagination', 'allItemsCount']));
  const [isLoading, setIsLoading] = useState(false);
  const { tab } = useRouterQuery(['tab']);

  const status = getStatusByTab(tab);

  const [queryHandler] = useLazyQuery(CLIENTS_BY_SEARCH);

  const { searchText } = useTableSearch();
  const { pagination } = usePagination();

  const onClick = async () => {
    setIsLoading(true);

    const { data } = await queryHandler({
      variables: {
        searchInput: {
          sort,
          searchTerm: searchText,
          size: pagination.allItemsCount,
          query: { match: { status } },
        },
      },
    });

    const foundClients = R.pathOr([], ['searchClients', 'hits'], data);

    if (foundClients.length > 0) {
      const exportConfig = getXlsClientsConfig(status, foundClients);
      performExcelExport(exportConfig);
    }

    setIsLoading(false);
  };

  const exportToXLSProps = {
    setIsLoading,
    searchText,
    onClick,
    loading: isLoading,
    disabled: totalCount === 0,
  };

  return (
    <CommonOuterButtonS {...exportToXLSProps}>
      {EXPORT_XLS_CAPTION}
    </CommonOuterButtonS>
  );
}

ClientsExportToXLSBtn.propTypes = {
  sort: arrayOf(oneOfType([string, object])),
};
