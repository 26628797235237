import {
  func,
  node,
  shape,
  number,
  string,
  arrayOf,
  instanceOf,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { extractTablePropsFromConfig, MoneyInputAsCents } from 'poly-admin-ui';
import {
  WISCONSIN_STATE_SHORTNAME,
  NEW_YORK_STATE_SHORTNAME,
  NOTHING_UI_STRING,
  ProjectType,
} from 'poly-constants';
import {
  centsToDollarsWithFormat,
  getClientInvoiceDueDate,
  convertCentsToDollars,
  getMarkupPercentBase,
  forceTitleCase,
  formatDate,
} from 'poly-utils';
import {
  getThemeColor,
  FieldLayout,
  LinkButton,
  DatePicker,
  FormField,
  Checkbox,
  Editor,
  Table,
} from 'poly-book-admin';

import {
  FieldTitle,
  HalfSection,
  FormFieldContainer,
  FieldValueContainer,
  FieldTitleWithBorder,
  FormSectionContainer,
  QuarterWidthContainer,
  InvoiceLinesSectionTitle,
  HalfQuarterWidthContainer,
} from './styled.js';
import { projectInfoPropTypes } from './prop-types.js';
import { InvoiceLineField } from './InvoiceLinefield.js';
import { PercentInput } from './ClientInvoicePercentInput.js';
import { FlexSpaceBetweenFullWidth } from '../../modules/forms/assignSupplierForm/styles.js';
import { useClientInvoiceAmountsCalculation } from './useClientInvoiceAmountsCalculation.js';
import { propOrNothingUI } from '../../modules/core/utils/general.js';
import {
  FlexCenterAlign,
  FlexContainer,
  FlexColumn,
} from '../../components/FlexContainer.js';

const jobCostsTableProps = extractTablePropsFromConfig([
  ['Invoice Date', R.compose(formatDate, R.prop('invoiceDate'))],
  ['Due Date', R.compose(formatDate, R.prop('invoiceDate'))],
  ['Invoice #', R.prop('invoiceNumber')],
  ['Supplier Name', propOrNothingUI('supplierName')],
  ['Gl Code', R.prop('glCode')],
  ['Amount', R.compose(centsToDollarsWithFormat, R.prop('amount'))],
]);

const timeTrackingTableProps = extractTablePropsFromConfig([
  ['Date', R.compose(formatDate, R.prop('date'))],
  ['User', R.prop('user')],
  ['Hours', R.prop('hours')],
  ['Rate', R.compose(centsToDollarsWithFormat, R.prop('rate'))],
  ['Description', R.prop('rateDescription')],
  ['Notes', R.prop('notes')],
  [
    'Invoiced',
    R.ifElse(R.propEq('isInvoiced', true), R.always('Yes'), R.always('')),
  ],
  ['Line total', R.compose(centsToDollarsWithFormat, R.prop('total'))],
]);

const TableWithoutPaddings = styled(Table)`
  tr {
    width: 100%;
    margin: 0;
  }
`;

const RawTaxFieldTitleS = styled(FieldTitle)`
  margin-right: 15px;
`;

const RawFreightFieldTitleS = styled(RawTaxFieldTitleS)`
  margin-left: 36px;
`;

function FormFieldReadOnly({ title, value, width }) {
  return (
    <FormFieldContainer width={width}>
      <FieldTitle>{title}</FieldTitle>
      <FieldValueContainer aria-label={title}>{value}</FieldValueContainer>
    </FormFieldContainer>
  );
}

FormFieldReadOnly.propTypes = {
  title: string.isRequired,
  value: node.isRequired,
  width: string,
};

const AddBtnContainer = styled(FlexContainer)`
  width: 100px;
  align-self: flex-start;
`;

const TotalsContainer = styled(FlexColumn)`
  width: 100%;
  align-items: flex-end;
`;

const TotalInput = styled(MoneyInputAsCents)`
  margin-left: 15px;
  width: 200px;
`;

const TotalLineContainer = styled(FlexCenterAlign)`
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
`;

const valueOrNothingUI = R.defaultTo(NOTHING_UI_STRING);

export const IncludeInvoicesCheckboxS = styled(Checkbox)`
  label {
    font-size: 14px;
    color: ${getThemeColor(['primary'])};
  }
`;

const CheckboxS = styled(IncludeInvoicesCheckboxS)`
  margin: 10px 0 0 10px;
`;

// isProjectWithSupplierInvoices :: Project -> Boolean
const isProjectWithSupplierInvoices = R.compose(
  R.includes(R.__, [
    ProjectType.WORK_ORDER,
    ProjectType.PASS_THROUGH,
    ProjectType.BID,
  ]),
  R.prop('type'),
);

// prepareMarkupPercent :: Number -> { tmMarkupRules: [ProjectMarkupRule] } -> Number
const prepareMarkupPercent = (linesTotal) =>
  R.ifElse(
    R.propEq('type', ProjectType.WORK_ORDER),
    R.compose(
      getMarkupPercentBase(convertCentsToDollars(linesTotal)),
      R.prop('tmMarkupRules'),
    ),
    R.always(0),
  );

export function ClientInvoiceForm({
  form,
  values,
  formId,
  onSubmit,
  projectInfo,
}) {
  const { subTotal, markupAmount, taxAmount, amount, linesTotal } =
    useClientInvoiceAmountsCalculation(values, projectInfo.state);

  const [internalAmount, setInternalAmount] = useState(0);

  useEffect(() => {
    setInternalAmount(amount);
  }, [amount]);

  useEffect(() => {
    setInternalAmount(R.propOr(amount, 'suggestedAmount', values));
  }, []);

  // check if the property state is Wisconsin
  const isWIState = R.propEq('state', WISCONSIN_STATE_SHORTNAME, projectInfo);

  // check if the property state is New York
  const isNYState = R.propEq('state', NEW_YORK_STATE_SHORTNAME, projectInfo);

  const isCommonState = !isWIState && !isNYState;

  const withSupplierInvoices = isProjectWithSupplierInvoices(projectInfo);

  const markupPercent = prepareMarkupPercent(linesTotal)(projectInfo);

  return (
    <form onSubmit={onSubmit} id={formId}>
      <FlexSpaceBetweenFullWidth>
        <HalfSection>
          <FlexContainer>
            <FormFieldReadOnly
              title="Project Number"
              width="200px"
              value={projectInfo.projectId}
            />
            <FormFieldReadOnly
              title="Client Name"
              width="200px"
              value={projectInfo.clientName}
            />
          </FlexContainer>
          <FormFieldReadOnly
            title="Project Name"
            value={projectInfo.description}
          />
          <FormFieldReadOnly
            title="Invoice To"
            value={projectInfo.clientAddress}
          />
          <FormFieldReadOnly
            title="Invoice Description"
            value={<FormField Component={Editor} name="description" />}
          />
        </HalfSection>
        <HalfSection>
          <FormFieldContainer>
            <FieldTitle>
              <label htmlFor="invoiceDate">Invoice Date</label>
              <span> / </span>
              <label htmlFor="dueDate">Due Date</label>
            </FieldTitle>
            <FlexContainer>
              <FormField
                name="invoiceDate"
                labelId="invoiceDate"
                Component={DatePicker}
              />
              &nbsp; &nbsp; &nbsp;
              <DatePicker
                id="dueDate"
                value={getClientInvoiceDueDate(values.invoiceDate, {
                  financial: { terms: projectInfo.terms },
                })}
                disabled
              />
            </FlexContainer>
          </FormFieldContainer>
          <FlexContainer>
            <FormFieldReadOnly
              title="GL Code"
              width="50%"
              value={projectInfo.divisionAccountCode}
            />
            {projectInfo.enableCostCenter && (
              <FormFieldReadOnly
                title="Cost Center"
                value={valueOrNothingUI(projectInfo.costCenter)}
              />
            )}
          </FlexContainer>
          <FlexContainer>
            <FormFieldReadOnly
              title="Your Ref #"
              width="50%"
              value={valueOrNothingUI(projectInfo.clientReferenceNumber)}
            />
            <FormFieldReadOnly
              title="Terms"
              value={valueOrNothingUI(forceTitleCase(projectInfo.terms))}
            />
          </FlexContainer>
          <FormFieldReadOnly
            title="Service Address"
            value={projectInfo.serviceAddress}
          />
        </HalfSection>
      </FlexSpaceBetweenFullWidth>
      <FlexColumn>
        <FormSectionContainer>
          <FieldTitleWithBorder>Job Costs</FieldTitleWithBorder>
          <TableWithoutPaddings
            {...jobCostsTableProps}
            rows={projectInfo.jobCosts}
            minHeightLess
          />
        </FormSectionContainer>
        <FormSectionContainer>
          <FieldTitleWithBorder>Time Tracking</FieldTitleWithBorder>
          <TableWithoutPaddings
            {...timeTrackingTableProps}
            rows={projectInfo.timeTracking}
            minHeightLess
          />
        </FormSectionContainer>
        <FormSectionContainer>
          <InvoiceLinesSectionTitle>
            <QuarterWidthContainer>
              <FieldTitle>Invoice Description</FieldTitle>
            </QuarterWidthContainer>
            <HalfQuarterWidthContainer>
              <FieldTitle>No Markup</FieldTitle>
            </HalfQuarterWidthContainer>
            <HalfQuarterWidthContainer>
              <FieldTitle>Qty</FieldTitle>
            </HalfQuarterWidthContainer>
            <QuarterWidthContainer>
              <FieldTitle>Rate</FieldTitle>
            </QuarterWidthContainer>
            <QuarterWidthContainer>
              <FieldTitle>Total</FieldTitle>
            </QuarterWidthContainer>
          </InvoiceLinesSectionTitle>
          <FieldLayout
            layout={{ padding: 0 }}
            field={{
              name: 'lines',
              isArrayField: true,
              Component: InvoiceLineField,
            }}
          />
          <AddBtnContainer>
            <LinkButton
              type="button"
              data-testid="add-new-line"
              onClick={() => form.mutators.push('lines', {})}
            >
              Add New
            </LinkButton>
          </AddBtnContainer>
          <TotalsContainer>
            <FlexColumn>
              <TotalLineContainer>
                <FieldTitle htmlFor="markupPercent">Markup</FieldTitle>
                <FormField
                  name="markupPercent"
                  labelId="markupPercent"
                  Component={PercentInput}
                  defaultValue={markupPercent}
                  additionalProps={{ markupPercent }}
                />
                <TotalInput
                  disabled
                  allowNegative
                  value={markupAmount}
                  name="markupTotal"
                  dataTestId="markupTotal"
                />
              </TotalLineContainer>
              {!isWIState && (
                <>
                  <TotalLineContainer>
                    <FieldTitle htmlFor="subtotal">Sub Total</FieldTitle>
                    <TotalInput
                      disabled
                      allowNegative
                      value={subTotal}
                      name="subtotal"
                      id="subtotal"
                    />
                  </TotalLineContainer>
                  {values.customTax ? (
                    <TotalLineContainer>
                      <RawTaxFieldTitleS htmlFor="taxRawAmount">
                        Tax
                      </RawTaxFieldTitleS>
                      <FormField
                        name="taxRawAmount"
                        labelId="taxRawAmount"
                        Component={MoneyInputAsCents}
                        additionalProps={{
                          width: '100px',
                          disabled: projectInfo.isTaxRateDisabled,
                        }}
                      />
                      <RawFreightFieldTitleS htmlFor="freightRawAmount">
                        Freight
                      </RawFreightFieldTitleS>
                      <FormField
                        defaultValue={0}
                        name="freightRawAmount"
                        labelId="freightRawAmount"
                        Component={MoneyInputAsCents}
                        additionalProps={{
                          width: '136px',
                          disabled: projectInfo.isTaxRateDisabled,
                          required: !projectInfo.isTaxRateDisabled,
                        }}
                      />
                    </TotalLineContainer>
                  ) : (
                    <TotalLineContainer>
                      <FieldTitle htmlFor="taxPercent">Sales Tax</FieldTitle>
                      <FormField
                        name="taxPercent"
                        labelId="taxPercent"
                        additionalProps={{
                          disabled: projectInfo.isTaxRateDisabled,
                        }}
                        Component={PercentInput}
                      />
                      <TotalInput
                        disabled
                        allowNegative
                        name="taxTotal"
                        value={taxAmount}
                        dataTestId="taxTotal"
                      />
                    </TotalLineContainer>
                  )}
                </>
              )}
              <TotalLineContainer>
                <FieldTitle>Total</FieldTitle>
                <TotalInput
                  disabled
                  allowNegative
                  value={internalAmount}
                  name="total"
                  dataTestId="total"
                />
              </TotalLineContainer>
            </FlexColumn>
            {(withSupplierInvoices || isCommonState) && (
              <TotalLineContainer>
                {isCommonState && (
                  <FormField
                    name="customTax"
                    defaultValue={false}
                    Component={CheckboxS}
                    additionalProps={{ label: 'Custom Tax' }}
                  />
                )}
                {withSupplierInvoices && (
                  <FormField
                    Component={CheckboxS}
                    name="includeSupplierInvoices"
                    defaultValue={!projectInfo.isClientInvoicingEmailEnabled}
                    additionalProps={{
                      label: 'Include Supplier Invoices',
                      required: true,
                    }}
                  />
                )}
              </TotalLineContainer>
            )}
          </TotalsContainer>
        </FormSectionContainer>
      </FlexColumn>
    </form>
  );
}

ClientInvoiceForm.propTypes = {
  projectInfo: projectInfoPropTypes,
  onSubmit: func.isRequired,
  form: shape({
    mutators: shape({
      push: func.isRequired,
    }),
  }).isRequired,
  values: shape({
    date: instanceOf(Date),
    markupPercent: number,
    taxPercent: number,
    lines: arrayOf(
      shape({
        name: string,
        quantity: number,
        rate: number,
      }),
    ),
  }).isRequired,
  formId: string.isRequired,
};
