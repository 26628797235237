import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useRouterQuery } from 'poly-client-routing';
import { getThemeColor, Icon } from 'poly-book-admin';

import { ESFMFullLogo } from '../../components/Logo.js';
import { VENDOR_INFO_TYPES } from '../../constants/vendor.js';
import { VendorInfoSection } from '../../components/VendorInfoSection.js';

const mapVendorTypeToPageInfo = {
  [VENDOR_INFO_TYPES.TOKEN_EXPIRED]: {
    title: 'Oops!',
    icon: 'warning',
    content:
      'It looks like the link you have clicked is expired. Please contact our support center for additional information.',
  },
  [VENDOR_INFO_TYPES.INVOICE_SUBMITTED]: {
    title: 'Hello!',
    icon: 'checkMark',
    content:
      "Thank you for your efforts, but it's already done. We have already received this invoice.",
  },
  [VENDOR_INFO_TYPES.DOCUMENTS_RECEIVED]: {
    title: 'Hello!',
    icon: 'checkMark',
    content:
      "Thank you for your efforts, but it's already done. We have already received your documents.",
  },

  [VENDOR_INFO_TYPES.DOCUMENTS_SUBMITTED]: {
    title: 'Hello!',
    icon: 'checkMark',
    content:
      'Thank you for submitting this information. We will review it and reach out if we have any questions or concerns.',
  },
};

const VendorInfoPageWrapperS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${getThemeColor(['light'])};
  font-family: Lato, sans-serif;
  line-height: normal;
  letter-spacing: normal;
`;

const VendorInfoCardS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 650px;
  height: auto;
  padding: 32px 25px;
  background-color: ${getThemeColor(['white'])};
`;

const VendorContentWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0 16px 0;
`;

// getIconColor :: { icon: String } -> String
const getIconColor = R.ifElse(
  R.propEq('icon', 'warning'),
  getThemeColor(['amber']),
  getThemeColor(['inspiniaBlueLighter']),
);

const VendorIconWrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: ${getIconColor};
`;

const VendorInfoContentS = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px;
`;

const VendorInfoTextS = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const VendorInfoTitleS = styled(VendorInfoTextS)`
  font-size: 22px;
  padding-bottom: 16px;
`;

// getIconProps :: String -> Object
const getIconProps = (icon) =>
  icon === 'warning'
    ? {
        size: 45,
        style: { marginLeft: '-3px' },
      }
    : { size: 28 };

export function VendorInfoPage() {
  const { type } = useRouterQuery(['type']);
  const { title, content, icon } = mapVendorTypeToPageInfo[type];

  return (
    <VendorInfoPageWrapperS>
      <VendorInfoCardS>
        <ESFMFullLogo />
        <VendorContentWrapperS>
          <VendorIconWrapperS icon={icon}>
            <Icon name={icon} color="white" {...getIconProps(icon)} />
          </VendorIconWrapperS>
          <VendorInfoContentS>
            <VendorInfoTitleS>{title}</VendorInfoTitleS>
            <VendorInfoTextS>{content}</VendorInfoTextS>
          </VendorInfoContentS>
        </VendorContentWrapperS>
        <VendorInfoSection />
      </VendorInfoCardS>
    </VendorInfoPageWrapperS>
  );
}

VendorInfoPage.displayName = 'VendorInfoPage';
