import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';
import {
  parseFieldToNumberIfExists,
  useTableInfiniteScrollQuery,
} from 'poly-client-utils';
import { ClientInvoiceStatuses } from 'poly-constants';
import { ALL } from 'poly-admin-ui';

import { setGlobalTrigger } from '../../redux/globalTriger.js';
import { transformClientInvoicesForPayment } from './clientPaymentsHelpers.js';
import { setInvoices } from '../../redux/invoices.js';

const clientInvoicesQuery = gql`
  query CLIENT_INVOICES($input: ClientInvoicesInput!) {
    clientInvoices(input: $input) {
      total
      hits {
        _id
        number
        createdAt
        invoiceDate
        amount
        balance
        project {
          _id
          projectId
        }
        client {
          _id
          financial {
            terms
          }
        }
        consolidatedClientInvoice {
          _id
          invoiceNumber
        }
      }
    }
  }
`;

// filterQueryInput :: Object -> ClientInvoicesInput
const filterQueryInput = R.compose(
  R.reject(R.anyPass([R.isEmpty, R.isNil, R.equals(ALL)])),
  parseFieldToNumberIfExists('invoiceNumber'),
  parseFieldToNumberIfExists('invoiceNumberFrom'),
  parseFieldToNumberIfExists('invoiceNumberTo'),
  R.mergeRight({
    sort: { number: 1 },
    status: [
      ClientInvoiceStatuses.PENDING,
      ClientInvoiceStatuses.PARTIALLY_PAID,
    ],
  }),
  R.omit(['discount']),
);

export const useFetchClientInvoices = (filters) => {
  const dispatch = useDispatch();

  const input = useMemo(() => filters && filterQueryInput(filters), [filters]);

  const pageSize = 500;

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    clientInvoicesQuery,
    input,
    {
      endpointName: 'clientInvoices',
      pageSize,
      skip: !filters,
    },
  );

  const invoices = useMemo(
    () =>
      R.compose(
        transformClientInvoicesForPayment,
        R.pathOr([], ['clientInvoices', 'hits']),
      )(data),
    [data],
  );

  const setGlobalTriggerDebounce = useCallback(
    (isGlobalTrigger) => dispatch(setGlobalTrigger(isGlobalTrigger)),
    [],
  );

  useEffect(() => {
    dispatch(setInvoices(invoices));
    setGlobalTriggerDebounce(false);
  }, [invoices]);

  return {
    invoices,
    loading,
    tableProps,
    pageSize,
  };
};
