import * as R from 'ramda';
import styled, { css } from 'styled-components';
import React, { useEffect, useCallback } from 'react';
import { bool, func, object, shape } from 'prop-types';
import { Button, Checkbox, FormField, getThemeColor } from 'poly-book-admin';
import { Form } from 'react-final-form';
import {
  BillingProfileConsolidateBy,
  BillingProfileProjectType,
} from 'poly-constants';

import {
  FieldWrapper,
  ComponentTitle,
  BillingProfileConfigCheckbox,
} from './components/common.js';
import { MultiplePOSelect } from './components/MultiplePOSelect.js';
import { ConsolidateBySelect } from './components/ConsolidateBySelect.js';
import { BillingProfilePOSelect } from './components/BillingProfilePOSelect.js';
import { MultiplePropertySelect } from './components/MultiplePropertySelect.js';
import { BillingProfileNameInput } from './components/BillingProfileNameInput.js';
import { MultipleCostCenterSelect } from './components/MultipleCostCenterSelect.js';
import { MultipleProjectTypeSelect } from './components/MultipleProjectTypeSelect.js';
import { MultipleServiceTypeSelect } from './components/MultipleServiceTypeSelect.js';
import { BillingProfileLogicButton } from './components/BillingProfileLogicButton.js';
import { useHasUserAccessToReadAdminPO } from '../../../sidebars/PurchaseOrderSidebar/utils.js';

const FilterButtonsContainer = styled.div`
  display: flex;
  padding: 24px;

  > * {
    margin-right: 10px;
  }
`;

const verticalStyles = css`
  flex-direction: column;
  align-items: flex-start;
  & > div:first-child {
    margin-bottom: 10px;
  }
`;

const BillingProfileConfigRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 24px;
  min-height: 50px;

  ${({ rowHeight }) => !!rowHeight && `height: ${rowHeight}px`};
  ${({ skipBorder, ...props }) =>
    !skipBorder &&
    `border-bottom: 1px solid ${getThemeColor(['lighter'])(props)}`};
  ${({ vertical }) => vertical && verticalStyles}
`;

const BillingProfileConfigRowContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

const FormS = styled.form`
  display: flex;
  flex-direction: column;
`;

// showNonPOField :: FormValues -> Boolean
const showNonPOField = R.either(
  R.propEq('batch', true),
  R.pathEq(['consolidateBy', 0], BillingProfileConsolidateBy.COST_CENTER),
);

// showCostCenter :: FormValues -> Boolean
const showCostCenter = R.pathEq(
  ['consolidateBy', 0],
  BillingProfileConsolidateBy.COST_CENTER,
);

// showPos :: FormValues -> Boolean
const showPos = R.either(
  R.pathEq(['consolidateBy', 0], BillingProfileConsolidateBy.REFERENCE_NUMBER),
  R.pathEq(['consolidateBy', 0], BillingProfileConsolidateBy.ADMIN_PO),
);

// showBillingProfileNameField :: FormValues -> Boolean
const showBillingProfileNameField = R.either(
  R.propEq('batch', true),
  R.propSatisfies(R.complement(R.isNil), 'consolidateBy'),
);

const SubItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-top: 10px;
  padding-left: 20px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  & > div:nth-child(even) {
    margin-top: 10px;
  }
`;

const CoverSheetConfigContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const useBillingConfigFormLogic = (values, dirty, changeFormField) => {
  const { consolidation, batch, consolidateBy } = values;

  const resetConsolidationCustomFields = useCallback(() => {
    changeFormField('costCenterNames', null);
    changeFormField('pos', null);
  }, []);

  useEffect(() => {
    if (dirty && consolidation) {
      changeFormField('batch', false);
      resetConsolidationCustomFields();
    }
  }, [consolidation]);

  useEffect(() => {
    if (dirty && batch) {
      changeFormField('consolidation', false);
      changeFormField('consolidateBy', null);
    }
  }, [batch]);

  useEffect(() => {
    if (dirty && consolidateBy) {
      changeFormField('nonPO', false);
      resetConsolidationCustomFields();
    }
  }, [consolidateBy]);
};

// validateConsolidateByField :: (String, Object) -> Boolean
const validateConsolidateByField = (value, allValues = {}) =>
  R.ifElse(
    R.both(() => !value, R.propEq('consolidation', true)),
    R.always('Consolidate by is required'),
    R.F,
  )(allValues);

function BillingProfileFormRendered({
  values,
  handleSubmit,
  form,
  dirty,
  enablePurchaseOrder,
}) {
  useBillingConfigFormLogic(values, dirty, form.change);

  const hasAccessReadAdminPO = useHasUserAccessToReadAdminPO();

  const showAdminPOSelect =
    showPos(values) && enablePurchaseOrder && hasAccessReadAdminPO;

  const onReset = () => form.reset({});

  return (
    <FormS onSubmit={handleSubmit}>
      <BillingProfileConfigRow key="typeRow" rowHeight={60}>
        <ComponentTitle>Type</ComponentTitle>
        <BillingProfileConfigRowContent>
          <FormField
            name="batch"
            Component={BillingProfileConfigCheckbox}
            additionalProps={{ values, label: 'Batch' }}
          />
          <FormField
            name="consolidation"
            Component={BillingProfileConfigCheckbox}
            additionalProps={{ values, label: 'Consolidation', margin: 45 }}
          />
          {values.consolidation && (
            <FormField
              name="consolidateBy"
              Component={FieldWrapper}
              additionalProps={{
                values,
                title: 'Consolidate By',
                Component: ConsolidateBySelect,
              }}
              validate={validateConsolidateByField}
            />
          )}
        </BillingProfileConfigRowContent>
      </BillingProfileConfigRow>
      <BillingProfileConfigRow key="attachmentRow">
        <BillingProfileConfigRowContent>
          {!values.batch && (
            <FormField
              name="attachBackupInvoices"
              Component={BillingProfileConfigCheckbox}
              additionalProps={{ label: 'Attach Backup Invoices', margin: 45 }}
            />
          )}
          <FormField
            name="attachSupplierInvoices"
            Component={BillingProfileConfigCheckbox}
            additionalProps={{ label: 'Attach Supplier Invoices', margin: 45 }}
          />
          <FormField
            name="isTransparent"
            Component={BillingProfileConfigCheckbox}
            additionalProps={{
              label: 'Transparent',
              margin: 45,
            }}
          />
          {showNonPOField(values) && (
            <FormField
              name="nonPO"
              Component={BillingProfileConfigCheckbox}
              additionalProps={{
                label: 'Non PO',
              }}
            />
          )}
        </BillingProfileConfigRowContent>
      </BillingProfileConfigRow>
      <BillingProfileConfigRow key="serviceRow">
        <BillingProfileConfigRowContent>
          <FormField
            name="costTypes"
            Component={FieldWrapper}
            additionalProps={{
              title: 'Cost Type',
              Component: MultipleProjectTypeSelect,
            }}
          />
          <FormField
            name="serviceTypeIds"
            Component={FieldWrapper}
            additionalProps={{
              title: 'Service Type',
              Component: MultipleServiceTypeSelect,
            }}
          />
        </BillingProfileConfigRowContent>
      </BillingProfileConfigRow>
      <BillingProfileConfigRow key="detailsRow">
        <BillingProfileConfigRowContent>
          <FormField
            name="propertyIds"
            Component={FieldWrapper}
            additionalProps={{
              title: 'Property Name',
              Component: MultiplePropertySelect,
            }}
          />
          {showCostCenter(values) && (
            <FormField
              name="costCenterNames"
              Component={FieldWrapper}
              additionalProps={{
                title: 'Cost Center',
                Component: MultipleCostCenterSelect,
                values,
              }}
            />
          )}
          {showPos(values) && !enablePurchaseOrder && (
            <FormField
              name="pos"
              Component={FieldWrapper}
              additionalProps={{
                title: 'Purchase Order',
                Component: MultiplePOSelect,
                values,
              }}
            />
          )}
          {showAdminPOSelect && (
            <FormField
              name="adminPOIds"
              Component={FieldWrapper}
              additionalProps={{
                title: 'Purchase Order',
                Component: BillingProfilePOSelect,
                values,
              }}
            />
          )}
        </BillingProfileConfigRowContent>
      </BillingProfileConfigRow>
      <BillingProfileConfigRow key="projectTypeRow">
        <ComponentTitle>Project Type</ComponentTitle>
        <BillingProfileConfigRowContent>
          <FormField
            name={BillingProfileProjectType.DEFAULT}
            Component={BillingProfileConfigCheckbox}
            additionalProps={{
              label: 'Project',
            }}
          />
          <FormField
            name={BillingProfileProjectType.CHILD_RECURRING}
            Component={BillingProfileConfigCheckbox}
            additionalProps={{
              label: 'Child Recurring',
            }}
          />
          <FormField
            name={BillingProfileProjectType.CHILD_PM}
            Component={BillingProfileConfigCheckbox}
            additionalProps={{
              label: 'Child PM',
            }}
          />
        </BillingProfileConfigRowContent>
      </BillingProfileConfigRow>
      {showBillingProfileNameField(values) && (
        <BillingProfileConfigRow key="billingProfileRow">
          <BillingProfileConfigRowContent>
            <FormField
              name="billingProfileName"
              Component={FieldWrapper}
              additionalProps={{
                title: 'Billing Profile Name',
                Component: BillingProfileNameInput,
                values,
              }}
            />
            <FormField
              name="saveBillingProfile"
              Component={BillingProfileLogicButton}
              additionalProps={{
                values,
              }}
            />
          </BillingProfileConfigRowContent>
        </BillingProfileConfigRow>
      )}
      <BillingProfileConfigRow key="apReportingEnabled">
        <BillingProfileConfigRowContent>
          <FormField
            name="apReportingEnabled"
            Component={FieldWrapper}
            additionalProps={{
              label: 'Accounts Payable Report',
              Component: Checkbox,
            }}
          />
          <CoverSheetConfigContainer>
            <FormField
              name="coverPageAndDetailsReportingEnabled"
              Component={Checkbox}
              additionalProps={{
                width: '100%',
                size: 14,
                // eslint-disable-next-line @cspell/spellchecker
                label: 'Coverpage and Details Report',
              }}
            />
            {values.coverPageAndDetailsReportingEnabled && (
              <SubItemsContainer>
                <FormField
                  name="isPassThroughFeeEnabled"
                  Component={Checkbox}
                  additionalProps={{
                    size: 'small',
                    label: 'Show Pass Through Fee Amount',
                  }}
                />
                <FormField
                  name="isFinancialCodingEnabled"
                  Component={Checkbox}
                  additionalProps={{
                    size: 'small',
                    label: 'Show Property Financial Coding',
                  }}
                />
              </SubItemsContainer>
            )}
          </CoverSheetConfigContainer>
        </BillingProfileConfigRowContent>
      </BillingProfileConfigRow>
      <FilterButtonsContainer>
        <Button
          size="tiny"
          styleType="default"
          onClick={handleSubmit}
          data-testid="show-result-button"
        >
          Show filter result
        </Button>
        <Button
          size="tiny"
          type="button"
          onClick={onReset}
          styleType="primaryLighter"
          data-testid="reset-button"
        >
          Reset
        </Button>
      </FilterButtonsContainer>
    </FormS>
  );
}

BillingProfileFormRendered.propTypes = {
  handleSubmit: func.isRequired,
  values: shape({
    consolidation: bool.isRequired,
  }),
  form: shape({
    reset: func.isRequired,
  }),
  dirty: bool.isRequired,
  enablePurchaseOrder: bool,
};

export function BillingProfileConfigurationForm({
  onSubmit,
  initialValues,
  enablePurchaseOrder,
}) {
  return (
    <Form
      render={BillingProfileFormRendered}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enablePurchaseOrder={enablePurchaseOrder}
    />
  );
}

BillingProfileConfigurationForm.propTypes = {
  onSubmit: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: object,
  enablePurchaseOrder: bool,
};
