import React from 'react';
import { useOutSidebarContext } from 'poly-client-utils';
import { SidebarWidth } from 'poly-admin-ui';

import { SidebarIDs } from '../constants.js';
import { PurchaseOrderSidebar } from './PurchaseOrderSidebar.js';

export const useOpenPurchaseOrderSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (purchaseOrderId, skipSteady) =>
    openOutSidebar(
      {
        alwaysFirst: true,
        width: SidebarWidth,
        id: SidebarIDs.purchaseOrder,
        content: purchaseOrderId ? (
          <PurchaseOrderSidebar purchaseOrderId={purchaseOrderId} />
        ) : null,
      },
      skipSteady,
    );
};
