import React from 'react';
import * as R from 'ramda';
import { DatePicker, Textarea, Input, CharacterCount } from 'poly-book-admin';
import { SystemAccountTypes } from 'poly-constants';
import { MoneyInput } from 'poly-admin-ui';

import { AccountsSelect } from '../../components/AccountsSelect.js';
import { halfWidth, quarterWidth } from '../../modules/forms/common.js';
import { BankDepositsTypeComponent } from './BankDepositsTypeComponent.js';
import { BankDepositsPayeeNameComponent } from './BankDepositsPayeeNameComponent.js';
import {
  GLCodeSelect,
  prepareAccountsToSelectBase,
} from '../../modules/accounting/GLCodeSelect/GLCodeSelect.js';
import { rejectReceivableAndPayableAccounts } from '../../modules/accounting/GLCodeSelect/glCodeSelectUtils.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { filterBankAndCCAccounts } from './bankDepositsUtils.js';
import { onKeyDownToPreventFormSubmit } from '../../utils/form.js';

function BankAccountSelect(props) {
  return (
    <AccountsSelect
      {...props}
      width="100%"
      isClearable
      placeholder="Select Bank Name"
      filterAccounts={filterAccountsBySystemType(
        SystemAccountTypes.BANK_ACCOUNT,
      )}
      onKeyDown={onKeyDownToPreventFormSubmit}
      extractValue={R.prop('_id')}
    />
  );
}

function BankDepositsDatePicker(props) {
  return (
    <DatePicker
      {...props}
      width="100%"
      leftMove="-20px"
      onKeyDown={onKeyDownToPreventFormSubmit}
    />
  );
}

function BankMoneyInput(props) {
  return <MoneyInput {...props} onKeyDown={onKeyDownToPreventFormSubmit} />;
}

// getValueLengthFromProps :: { value: String } -> Int
const getValueLengthFromProps = R.compose(R.length, R.propOr('', 'value'));

function BankDepositTextarea(props) {
  const valueLength = getValueLengthFromProps(props);

  return (
    <>
      <Textarea
        style={{ resize: 'none' }}
        {...props}
        height="37px"
        maxLength="200"
        rows={3}
        onKeyDown={onKeyDownToPreventFormSubmit}
      />
      <CharacterCount limit={200} length={valueLength} />
    </>
  );
}

function BankDepositsPayeeNameInput(props) {
  return (
    <BankDepositsPayeeNameComponent width={halfWidth} {...props} rows={3} />
  );
}

function BankDepositsGLCodeSelect(props) {
  const formatAccountsOptions = prepareAccountsToSelectBase(R.prop('_id'));

  return (
    <GLCodeSelect
      {...props}
      filterAccounts={R.compose(
        filterBankAndCCAccounts,
        rejectReceivableAndPayableAccounts,
      )}
      onKeyDown={onKeyDownToPreventFormSubmit}
      formatAccountsOptions={formatAccountsOptions}
    />
  );
}

function BankReference(props) {
  return <Input {...props} onKeyDown={onKeyDownToPreventFormSubmit} />;
}

export const bankDepositsFromSections = [
  {
    order: 1,
    layout: { column: 1, margin: 0 },
    fields: [
      {
        label: 'Type',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'type',
          withChangeFieldValue: true,
          Component: BankDepositsTypeComponent,
        },
        validators: [[R.identity, 'Type is required']],
      },
      {
        label: 'Name',
        order: 2,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'documentId',
          withFormData: true,
          withChangeFieldValue: true,
          Component: BankDepositsPayeeNameInput,
        },
        validators: [[R.identity, 'Name is required']],
        renderIf: R.propSatisfies(R.complement(R.isNil), 'type'),
        required: true,
      },
      {
        label: 'Date',
        order: 3,
        layout: { row: 2, width: quarterWidth },
        field: {
          name: 'date',
          Component: BankDepositsDatePicker,
        },
        validators: [[R.identity, 'Date is required']],
      },
      {
        label: 'Bank Account',
        order: 4,
        layout: { row: 2, width: quarterWidth },
        field: {
          name: 'bankAccountId',
          Component: BankAccountSelect,
        },
        validators: [[R.identity, 'Bank Account is required']],
        required: true,
      },
      {
        label: 'Amount',
        order: 5,
        layout: { row: 2, width: quarterWidth },
        field: {
          name: 'netAmount',
          Component: BankMoneyInput,
        },
        validators: [[R.identity, 'Amount is required']],
        required: true,
      },
      {
        label: 'GL Code',
        order: 6,
        layout: { row: 2, width: quarterWidth },
        field: {
          name: 'glAccount',
          Component: BankDepositsGLCodeSelect,
        },
        validators: [[R.identity, 'GL Code is required']],
        required: true,
      },
      {
        label: 'Reference #',
        order: 8,
        layout: { row: 3, width: quarterWidth },
        field: {
          name: 'depositNumber',
          additionalProps: {
            showCharactersLeft: true,
            charactersLimit: 40,
          },
          Component: BankReference,
        },
      },
      {
        label: 'Deposit Remarks',
        order: 9,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'description',
          Component: BankDepositTextarea,
        },
      },
      {
        order: 10,
        layout: { row: 3, width: quarterWidth },
        field: {
          name: 'holder',
          Component: () => null,
        },
      },
    ],
  },
];
