import React from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { string, shape, func } from 'prop-types';
import { Input } from 'poly-book-admin';
import {
  INVALID_SUPPLIER_NAME_MESSAGE,
  supplierEmailsTypes,
} from 'poly-constants';
import {
  hasOnlyUSKeyboardCharacters,
  insertParamsIntoURL,
  validationFuncWithAllValues,
} from 'poly-utils';
import {
  addressValidators,
  validatePhone,
  ifNotEmpty,
} from 'poly-client-utils';
import { commonEmailValidators } from 'poly-client-utils/src/formValidators.js';
import { validateURL } from 'poly-utils/src/validators.js';

import { halfWidth } from '../../../forms/common.js';
import {
  useEntitySimilarItems,
  AddressSection,
  PhoneNumberInput,
} from '../../../forms/index.js';
import { WebsiteFormInput } from '../../../../components/WebsiteFormInput.js';
import { BaseServiceTypesMultiSelectField } from '../../../forms/fields/BaseServiceTypesMultiSelectField.js';
import { isAddressesEquals } from '../../../forms/supplierForm/form/utils.js';
import { ToggleDBAInput } from '../../../forms/supplierForm/form/components/ToggleDBAInput.js';
import { supplierGeneralSectionMWBEFields } from '../../../forms/supplierForm/form/sections/supplierGeneralSectionMWBEFields.js';
import { serviceTypeValidator } from '../../../forms/supplierForm/form/sections/generalSection.js';
import { InputWithSimilarItemsComp } from '../../../forms/fields/InputWithDuplicateSearch/InputWithSimilarItemsComp.js';
import { MultiEmailsInput } from '../../../../components/MultiEmailsInput.js';

const { ACCOUNT, SERVICE, GENERAL, SECONDARY_SERVICE } = supplierEmailsTypes;

const MASTER_SUPPLIERS_SEARCH_NAME = gql`
  query MASTER_SUPPLIERS_SEARCH_NAME($searchInput: CollectionSearchParams!) {
    searchMasterSuppliers(input: $searchInput) {
      hits {
        _id
        company {
          name
        }
      }
      total
    }
  }
`;

// itemsFromProps :: InputProps -> InputDuplicationList
// InputProps = { data: [MasterSupplier], ownProps: Object }
// InputDuplicationList = { items: [MasterSupplierLink] }
// MasterSupplierLink = { url: String, name: String }
const itemsFromProps = ({ data, ownProps }) => ({
  items: R.pipe(
    R.pathOr([], ['searchMasterSuppliers', 'hits']),
    R.reject(R.propEq('_id', ownProps.activeSupplierId)),
    R.map(
      R.applySpec({
        url: ({ _id }) =>
          insertParamsIntoURL({ supplierId: _id }, ownProps.masterSupplierUrl),
        name: R.path(['company', 'name']),
      }),
    ),
  )(data),
});

// queryVariablesFromProps :: SearchProps -> CollectionSearchParams
// SearchProps = { nameSearch: String }
const queryVariablesFromProps = ({ nameSearch }) => ({
  searchInput: {
    size: 3,
    query: {
      bool: {
        should: [
          {
            term: {
              'company.name.keyword': { value: nameSearch, boost: 3 },
            },
          },
          {
            match: {
              'company.name': {
                query: nameSearch,
                operator: 'and',
                fuzziness: 'AUTO',
              },
            },
          },
        ],
      },
    },
  },
});

function MasterSupplierNameInputComp(props) {
  const entityProps = useEntitySimilarItems({
    query: MASTER_SUPPLIERS_SEARCH_NAME,
    itemsFromProps,
    variablesFromProps: queryVariablesFromProps,
    onChange: props.onChange,
    propsOfComponent: props,
  });

  return <InputWithSimilarItemsComp {...entityProps} />;
}

MasterSupplierNameInputComp.propTypes = {
  onChange: func,
};

function MasterSupplierNameInput({ formData, masterSupplierUrl, ...props }) {
  return (
    <MasterSupplierNameInputComp
      activeSupplierId={formData._id}
      masterSupplierUrl={masterSupplierUrl}
      {...props}
    />
  );
}

MasterSupplierNameInput.propTypes = {
  formData: shape({ _id: string }),
  masterSupplierUrl: string.isRequired,
};

export const generalSection = (masterSupplierUrl) => [
  {
    label: 'General Information',
    order: 3,
    layout: { column: 1 },
    fields: [
      {
        label: 'Company Name',
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'company.name',
          withFormData: true,
          Component: (props) => (
            <MasterSupplierNameInput
              showCharactersLeft
              masterSupplierUrl={masterSupplierUrl}
              {...props}
            />
          ),
        },
        required: true,
        validators: [
          [R.identity, 'Supplier name is required'],
          [hasOnlyUSKeyboardCharacters, INVALID_SUPPLIER_NAME_MESSAGE],
        ],
      },
      {
        label: 'Add DBA',
        order: 2,
        layout: { row: 2 },
        field: {
          name: 'company.dba',
          Component: ToggleDBAInput,
        },
      },
      {
        order: 2,
        layout: { row: 3 },
        field: {
          name: 'company.address',
          Component: AddressSection,
          isEqual: isAddressesEquals,
        },
        required: true,
        validators: addressValidators,
      },
      {
        label: 'Office Phone',
        order: 3,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'company.phone',
          Component: PhoneNumberInput,
        },
        validators: [
          [
            R.anyPass([validatePhone, R.isNil]),
            'Please provide correct phone number',
          ],
        ],
      },
      {
        label: 'Fax',
        order: 4,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'company.fax',
          Component: PhoneNumberInput,
        },
        validators: [[ifNotEmpty(validatePhone), 'Fax is not correct']],
      },
      {
        label: 'Office Email',
        order: 5,
        layout: { row: 5, width: halfWidth },
        field: {
          name: `company.emails.${GENERAL}`,
          Component: Input,
        },
        validators: commonEmailValidators,
      },
      {
        label: 'Accounting Emails',
        order: 6,
        layout: { row: 5, width: halfWidth },
        field: {
          name: `company.emails.${ACCOUNT}`,
          Component: MultiEmailsInput,
        },
      },
      {
        order: 7,
        layout: { row: 7, width: halfWidth },
        field: {
          name: 'company.servicesIds',
          withFormData: true,
          Component: BaseServiceTypesMultiSelectField,
        },
        validators: [serviceTypeValidator],
        validateFunction: validationFuncWithAllValues,
      },
      {
        label: 'Primary Service Email',
        order: 8,
        layout: { row: 6, width: halfWidth },
        field: {
          name: `company.emails.${SERVICE}`,
          Component: Input,
        },
        validators: commonEmailValidators,
      },
      {
        label: 'Website',
        order: 9,
        layout: { row: 7, width: halfWidth },
        field: {
          name: 'company.website',
          Component: WebsiteFormInput,
        },
        validators: [
          [
            ifNotEmpty(validateURL),
            'URL should be in form http(s)://example.com',
          ],
        ],
      },
      {
        label: 'Secondary Service Email',
        order: 10,
        layout: { row: 6, width: halfWidth },
        field: {
          name: `company.emails.${SECONDARY_SERVICE}`,
          Component: MultiEmailsInput,
        },
      },
      ...supplierGeneralSectionMWBEFields,
    ],
  },
];
