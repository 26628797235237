import React from 'react';
import * as R from 'ramda';
import { InvoicesStatuses, taskActionTypes } from 'poly-constants';
import { useOutSidebarContext } from 'poly-client-utils';
import {
  getCompleteTaskValidation,
  getTaskTypeByCollection,
  useNotificationState,
  useModalContext,
  SidebarWidth,
} from 'poly-admin-ui';

import {
  addTaskFormId,
  editTaskFormId,
  completeTaskFormId,
  postTaskUpdateFormId,
} from '../../forms/taskForm/constants.js';
import { TaskUpdates } from './TaskUpdates.js';
import { TaskSidebarContainer } from './TaskSidebarContainer.js';
import { AddTaskForm } from '../../forms/taskForm/AddTaskForm.js';
import { EditTaskForm } from '../../forms/taskForm/EditTaskForm.js';
import { CompleteTaskForm } from '../../forms/taskForm/CompleteTaskForm.js';
import { PostTaskUpdateForm } from '../../forms/taskForm/PostTaskUpdateForm.js';
import { assignSupplierFormId } from '../../forms/assignSupplierForm/constants.js';
import { supplierInvoiceFormId } from '../../forms/supplierInvoiceForm/constants.js';
import { AddSupplierInvoiceForm } from '../../../sidebars/ProjectSidebar/forms/AddSupplierInvoiceForm.js';
import { EditProjectInvoiceForm } from '../../../sidebars/ProjectSidebar/forms/EditProjectInvoiceForm.js';
import { ApproveSupplierInvoiceForm } from '../../../sidebars/ProjectSidebar/forms/ApproveSupplierInvoiceForm.js';
import { CompleteAssignSupplierForm } from '../../forms/assignSupplierForm/CompleteAssignSupplierForm.js';

// getFormBySupplierInvoice :: Invoice -> String
const getFormBySupplierInvoice = R.cond([
  [R.isNil, R.always(AddSupplierInvoiceForm)],
  [
    R.propEq('status', InvoicesStatuses.RECEIVED),
    R.always(ApproveSupplierInvoiceForm),
  ],
  [
    R.anyPass([
      R.propEq('status', InvoicesStatuses.REJECTED),
      R.propEq('status', InvoicesStatuses.MANUAL_FOLLOW_UP),
      R.propEq('status', InvoicesStatuses.REQUESTED),
    ]),
    R.always(EditProjectInvoiceForm),
  ],
  [R.T, R.always(AddSupplierInvoiceForm)],
]);

// getSupplierInvoice :: Supplier -> [Task] -> Invoice
const getSupplierInvoice = ({ _id }) =>
  R.compose(
    R.find(R.pathEq(['supplier', '_id'], _id)),
    R.sortBy(R.prop('status')),
    R.pathOr([], ['project', 'invoices']),
    R.find(
      R.allPass([
        R.propEq('action', taskActionTypes.SUPPLIER_INVOICE),
        R.pathEq(['supplier', '_id'], _id),
      ]),
    ),
  );

export const useTasksPageTableHandlers = ({
  entity,
  readFromCacheParams,
  collection: entityCollection,
}) => {
  const { openModal } = useModalContext();
  const { showWarningNotification } = useNotificationState();

  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  const onCompleteTask = ({
    _id,
    action,
    project,
    supplier,
    documentId,
    collection,
    description,
    projectTasks,
    supplierInvoice,
  }) => {
    const {
      isCloseProjectTask,
      isSupplierAssignTask,
      isSupplierInvoiceTask,
      validateBeforeComplete,
    } = getCompleteTaskValidation(action, project, showWarningNotification);
    const taskType = getTaskTypeByCollection(collection);

    const commonPayload = {
      _id,
      taskType,
      collection,
      taskId: _id,
      readFromCacheParams,
      entity: { _id: documentId },
    };

    validateBeforeComplete();

    if (isSupplierInvoiceTask) {
      const currentSupplierInvoice =
        !supplierInvoice && !supplier
          ? null
          : supplierInvoice || getSupplierInvoice(supplier)(projectTasks);

      const SupplierInvoiceForm = getFormBySupplierInvoice(
        currentSupplierInvoice,
      );

      return openOutSidebar({
        id: supplierInvoiceFormId,
        width: SidebarWidth,
        content: (
          <TaskSidebarContainer project={project} id={supplierInvoiceFormId}>
            <SupplierInvoiceForm
              {...commonPayload}
              title={description}
              submitCaption="Complete"
              UpdatesListComponent={TaskUpdates}
              invoiceId={currentSupplierInvoice._id}
              onCancel={() => closeOutSidebar(supplierInvoiceFormId)}
            />
          </TaskSidebarContainer>
        ),
      });
    }

    if (isSupplierAssignTask) {
      return openOutSidebar({
        isSteady: true,
        id: assignSupplierFormId,
        content: <CompleteAssignSupplierForm taskId={_id} />,
      });
    }

    return openOutSidebar({
      id: completeTaskFormId,
      order: 2,
      width: 600,
      content: (
        <TaskSidebarContainer project={project} id={completeTaskFormId}>
          <CompleteTaskForm
            payload={commonPayload}
            UpdatesListComponent={TaskUpdates}
            isTaskUpdateMessageOptional={isCloseProjectTask}
            onCancel={() => closeOutSidebar(completeTaskFormId)}
          />
        </TaskSidebarContainer>
      ),
    });
  };

  const onEditTask = ({ _id, documentId, collection }) =>
    openOutSidebar({
      id: editTaskFormId,
      width: SidebarWidth,
      content: (
        <TaskSidebarContainer id={editTaskFormId}>
          <EditTaskForm
            UpdatesListComponent={TaskUpdates}
            onCancel={() => closeOutSidebar(editTaskFormId)}
            payload={{
              taskType: getTaskTypeByCollection(collection),
              entity: { _id: documentId },
              collection,
              _id,
            }}
          />
        </TaskSidebarContainer>
      ),
    });

  const onUpdateTask = ({ _id, documentId, collection }) =>
    openOutSidebar({
      id: postTaskUpdateFormId,
      width: SidebarWidth,
      content: (
        <TaskSidebarContainer id={postTaskUpdateFormId}>
          <PostTaskUpdateForm
            UpdatesListComponent={TaskUpdates}
            onCancel={() => closeOutSidebar(postTaskUpdateFormId)}
            payload={{
              taskType: getTaskTypeByCollection(collection),
              entity: { _id: documentId },
              collection,
              _id,
            }}
          />
        </TaskSidebarContainer>
      ),
    });

  const onAddTask = () =>
    openModal({
      id: addTaskFormId,
      formId: addTaskFormId,
      content: (
        <AddTaskForm
          payload={{
            entity,
            _id: entity._id,
            collection: entityCollection,
          }}
        />
      ),
    });

  return { onCompleteTask, onEditTask, onUpdateTask, onAddTask };
};
