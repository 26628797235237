import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { bool, func, shape } from 'prop-types';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSortableTable } from 'poly-client-utils';
import { debounce } from 'poly-utils';
import { ASC_SORT_ORDER } from 'poly-constants';
import { MAX_ITEMS } from 'poly-admin-ui';
import { useQuery } from '@apollo/client';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { PaySuppliersPageHeader } from './PaySuppliersPageHeader.js';
import { PaySupplierInvoicesForm } from './PaySupplierInvoicesForm.js';
import { paySuppliersTableConfig } from './PaySupplierInvoicesTable.js';
import { transformInvoicesForPayment } from './paySuppliersUtils/payInvoicesUtils.js';
import { setGlobalTrigger } from '../../redux/globalTriger.js';
import { setInvoices } from '../../redux/invoices.js';
import { paySupplierInvoicesQuery } from './paySupplierInvoivesHOCs.js';
import { generateSearchInvoicesQuery } from './paySuppliersUtils/generateSearchInvoicesQuery.js';
import { paySupplierInvoiceFiltersPropTypes } from './propTypes.js';

// eslint-disable-next-line import/no-unused-modules
export function PaySupplierInvoicesFormWithSearchHeader({
  loading,
  setFilters,
  filters,
  virtualized,
  tableSortProps,
}) {
  return (
    <PageWithSearchHeader headerHeight="210px">
      <>
        <PaySuppliersPageHeader
          loading={loading}
          filters={filters}
          setFilters={setFilters}
        />
        <PaySupplierInvoicesForm
          loading={loading}
          virtualized={virtualized}
          tableSortProps={tableSortProps}
        />
      </>
    </PageWithSearchHeader>
  );
}

PaySupplierInvoicesFormWithSearchHeader.propTypes = {
  loading: bool,
  virtualized: bool,
  setFilters: func.isRequired,
  tableSortProps: shape({
    onHeaderCellClick: func.isRequired,
    setSorting: func.isRequired,
  }),
  filters: paySupplierInvoiceFiltersPropTypes,
};

export function PaySuppliersPage() {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(null);

  const { sort, ...tableSortProps } = useSortableTable({
    column: 3,
    tableConfig: paySuppliersTableConfig(),
  });

  const searchInput = useMemo(
    () => ({
      query: filters && generateSearchInvoicesQuery(filters),
      sort:
        tableSortProps.sorting.columnKey === 2
          ? [...sort, { invoiceDate: { order: ASC_SORT_ORDER } }]
          : sort,
      size: MAX_ITEMS,
    }),
    [filters, sort],
  );

  const { data, loading } = useQuery(paySupplierInvoicesQuery, {
    variables: { searchInput },
    fetchPolicy: 'network-only',
    skip: !searchInput.query,
  });

  const invoices = useMemo(
    () =>
      R.compose(
        transformInvoicesForPayment,
        R.pathOr([], ['searchInvoices', 'hits']),
      )(data),
    [data],
  );

  const setGlobalTriggerDebounce = useCallback(
    debounce(100)((isGlobalTrigger) =>
      dispatch(setGlobalTrigger(isGlobalTrigger)),
    ),
    [],
  );

  useEffect(() => {
    dispatch(setInvoices(invoices));
    setGlobalTriggerDebounce(false);
  }, [invoices]);

  return (
    <PaySupplierInvoicesFormWithSearchHeader
      loading={loading}
      setFilters={setFilters}
      filters={filters}
      tableSortProps={tableSortProps}
      virtualized
    />
  );
}
