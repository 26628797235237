import * as R from 'ramda';
import { TaskDueDateFilterPreset } from 'poly-constants';
import { getThemeColor } from 'poly-book-admin';
import { css } from 'styled-components';

import { taskStatusesUI } from '../constants/tasks.js';

const counterStyles = css`
  color: ${getThemeColor(['accent'])};
`;

const taskCountByStatusGetters = {
  [TaskDueDateFilterPreset.PAST_DUE]: R.pathOr(0, ['ownTaskCounts', 'overdue']),
  [TaskDueDateFilterPreset.DUE_TODAY]: R.pathOr(0, [
    'ownTaskCounts',
    'dueToday',
  ]),
  [TaskDueDateFilterPreset.DUE_TOMORROW]: R.pathOr(0, [
    'ownTaskCounts',
    'dueTomorrow',
  ]),
  [TaskDueDateFilterPreset.READY_TO_CLOSE]: R.pathOr(0, [
    'ownTaskCounts',
    'readyToClose',
  ]),
  [TaskDueDateFilterPreset.ALL]: R.propOr(0, 'ownAllTasksCount'),
};

// getTaskMenuSubItemsByStatusAndRoute :: (String, String, Object) -> [MenuSubItem]
export const getTaskMenuSubItemsByStatusAndRoute = (
  status,
  route,
  additionalProps = {},
) => [
  {
    route,
    tab: status,
    title: taskStatusesUI[status],
    getCountByMenuPayload: taskCountByStatusGetters[status],
    counterStyles:
      status === TaskDueDateFilterPreset.PAST_DUE ? counterStyles : '',
    ...additionalProps,
  },
];
