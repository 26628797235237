import React from 'react';
import styled from 'styled-components';
import { bool, func, shape, string } from 'prop-types';
import { useModalContext } from 'poly-admin-ui';
import { InputErrorMsg } from 'poly-book-admin';
import {
  supplierDocumentRequestTypes,
  supplierDocumentNames,
} from 'poly-constants';

import { subcontractorAgreementFormId } from './constants.js';
import { ForSectionWrapper, ForSectionTitle, Text } from './components.js';
import { SubcontractorAgreementForm } from './SubcontractorAgreementForm.js';
import { SubcontractorAgreementDownloadBtn } from './SubcontractorAgreementDownloadBtn.js';

const TextS = styled(Text)`
  font-weight: 500;
`;

const DecoratedText = styled.span`
  color: #436cbe;
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  background: rgba(71, 119, 216, 0.08);
  margin-top: 16px;
  padding: 8px 12px;
  position: relative;
`;

const FlexColumn = styled.div`
  display: flex;
  gap: 10px;
`;

const AgreementLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  margin: 0;
  padding: 4px 0;
`;

const AgreementText = styled(AgreementLabel)`
  color: #1a1514;
`;

const ForSectionWrapperS = styled(ForSectionWrapper)`
  > button {
    margin: 16px 0 0 0;
  }
`;

const SubcontractorAgreementDownloadBtnS = styled(
  SubcontractorAgreementDownloadBtn,
)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export function SubcontractorAgreement({ error, value, onChange, hasError }) {
  const { openModal } = useModalContext();

  const handleOpenAgreement = () =>
    openModal({
      id: subcontractorAgreementFormId,
      maxWidth: '495px',
      minWidth: '445px',
      content: (
        <SubcontractorAgreementForm
          agreementInfo={value}
          setAgreementInfo={onChange}
        />
      ),
    });

  const { isAgree } = value;

  const documentName = supplierDocumentNames[supplierDocumentRequestTypes.SA];

  const msgText = isAgree
    ? 'You’ve read and agreed to the'
    : 'Read and agree to the';

  return (
    <ForSectionWrapperS>
      <ForSectionTitle>{documentName}</ForSectionTitle>
      <TextS>
        {msgText}
        <DecoratedText onClick={handleOpenAgreement}>
          {documentName}
        </DecoratedText>
      </TextS>
      {isAgree && (
        <Wrapper>
          <SubcontractorAgreementDownloadBtnS
            saveAgreementDownloadTime
            skipDate
            values={value}
          />
          <FlexColumn>
            <AgreementLabel>Contact name:</AgreementLabel>
            <AgreementText>{value?.contactName}</AgreementText>
          </FlexColumn>
          <FlexColumn>
            <AgreementLabel>Company name:</AgreementLabel>
            <AgreementText>{value?.companyName}</AgreementText>
          </FlexColumn>
          <FlexColumn>
            <AgreementLabel>Title:</AgreementLabel>
            <AgreementText>{value?.title}</AgreementText>
          </FlexColumn>
        </Wrapper>
      )}
      {hasError && !!error && <InputErrorMsg>{error}</InputErrorMsg>}
    </ForSectionWrapperS>
  );
}

SubcontractorAgreement.propTypes = {
  error: string,
  value: shape({
    title: string,
    isAgree: bool,
    contactName: string,
    companyName: string,
    companyAddress: string,
  }),
  onChange: func.isRequired,
  hasError: bool.isRequired,
};
