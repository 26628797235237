import React from 'react';
import { LinkButton } from 'poly-book-admin';
import * as R from 'ramda';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
  formatFromToDateTitle,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import { string } from 'prop-types';

import { forceTitleCase } from 'poly-utils';
import {
  accountTransactionsFiltersPropTypes,
  accountTransactionsResultPropType,
} from './accountTransactionsPropTypes.js';

import {
  formatTransactionDate,
  formatTransactionType,
  getDescription,
  formatTransactionNumber,
  getSplitAccount,
  formatTransactionName,
  getTransactionBalance,
} from './accountTransactionsHelpers.js';

// isDebit :: Number -> Boolean
const isDebit = R.gt(R.__, 0);

// isCredit :: Number -> Boolean
const isCredit = R.lt(R.__, 0);

const toFixed2Signs = (num) => Number(num.toFixed(2));

// formatTransactionAmountIf :: (Number -> Boolean) -> Number -> Number
const formatTransactionAmountIf = (predicate) =>
  R.compose(
    R.ifElse(predicate, Math.abs, R.always(toFixed2Signs(0))),
    R.prop('amount'),
  );

// getAccountingReportHeaderRowConfig :: [CellValue] -> [[ExcelExportDataCell]]
const getAccountingReportHeaderRowConfig = R.map((title) => [
  createExcelExportCell(ExcelExportCellType.title, title),
]);

// getTransactionRowConfig :: Transaction -> [ExcelExportDataCell]
const getTransactionRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 6),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 3),
  ]),
  R.juxt([
    formatTransactionType,
    formatTransactionDate,
    formatTransactionNumber,
    formatTransactionName,
    getDescription,
    getSplitAccount,
    formatTransactionAmountIf(isDebit),
    formatTransactionAmountIf(isCredit),
    getTransactionBalance,
  ]),
);

// getAccountTransactionsReportExportExcelConfig :: Object -> Object
export const getAccountTransactionsReportExportExcelConfig = ({
  filters: { date, accountingMethod },
  result: {
    hits: transactions = [],
    forward_balance = 0,
    debits = 0,
    credits = 0,
  },
  accountName,
}) => ({
  exportFileName: 'account-transactions.xlsx',
  columnWidths: [20, 20, 20, 40, 40, 30, 15, 15, 15],
  rows: [
    ...getAccountingReportHeaderRowConfig([
      accountName,
      formatFromToDateTitle(date.startDate, date.endDate),
      forceTitleCase(accountingMethod),
    ]),
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 9)],
      [
        'Type',
        'Date',
        'Num',
        'Name',
        'Memo',
        'Split',
        'Debit',
        'Credit',
        'Balance',
      ],
    ),
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableStringFooter, 8),
        ExcelExportCellType.tableCurrencyFooter,
      ],
      ['Opening Balance', ...R.repeat('', 7), toFixed2Signs(forward_balance)],
    ),
    ...R.map(getTransactionRowConfig, transactions),
    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableStringFooter, 6),
        ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 3),
      ],
      [
        'Total',
        ...R.repeat('', 5),
        debits,
        credits,
        getTransactionBalance(R.last(transactions)),
      ],
    ),
  ],
});

export function AccountTransactionsExportXlBtn({
  result,
  accountName,
  filters,
}) {
  const transactionsCount = R.pathOr(0, ['hits', 'length'], result);

  const handleClick = async () => {
    const excelConfig = getAccountTransactionsReportExportExcelConfig({
      filters,
      result,
      accountName,
    });
    performExcelExport(excelConfig);
  };

  return (
    <LinkButton disabled={transactionsCount === 0} onClick={handleClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

AccountTransactionsExportXlBtn.propTypes = {
  filters: accountTransactionsFiltersPropTypes,
  result: accountTransactionsResultPropType,
  accountName: string,
};
