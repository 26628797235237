import React from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import { oneRowSidebarTabTableStyles } from 'poly-admin-ui';
import { getThemeColor, Table } from 'poly-book-admin';
import { useReactiveQuery } from 'poly-client-utils';

import { ProjectType } from 'poly-constants/src/projects/constants.js';
import { ProjectClientInvoicesList } from './ClientInvoiceList.js';

export const CLIENT_INVOICES_QUERY = gql`
  query CLIENT_INVOICES_QUERY($input: ClientInvoicesInput) {
    clientInvoices(input: $input) {
      hits {
        _id
        amount
        number
        createdAt
        invoiceDate
        paidAt
        status
      }
    }
  }
`;

export const CLIENT_INVOICES_CHANGED_SUBSCRIPTION = gql`
  subscription CLIENT_INVOICES_CHANGED($searchInput: CollectionSearchParams!) {
    searchClientInvoiceChanged(input: $searchInput) {
      id
      type
    }
  }
`;

const REPORT_ONLY_CLIENT_INVOICES_QUERY = gql`
  query REPORT_ONLY_CLIENT_INVOICES_QUERY(
    $input: ReportOnlyClientInvoicesInput
  ) {
    reportOnlyClientInvoices(input: $input) {
      hits {
        _id
        amount
        status
        number
        createdAt
        invoiceFile {
          url
        }
      }
    }
  }
`;

const REPORT_ONLY_CLIENT_INVOICES_CHANGED_SUBSCRIPTION = gql`
  subscription REPORT_ONLY_CLIENT_INVOICES_CHANGED_SUBSCRIPTION(
    $searchInput: CollectionSearchParams!
  ) {
    searchReportOnlyClientInvoiceChanged(input: $searchInput) {
      id
      type
    }
  }
`;

// isReportOnlyProjectWithClientInvoices :: Project -> Boolean
const isReportOnlyProjectWithClientInvoices = R.propEq(
  'type',
  ProjectType.REPORT_ONLY,
);

// getClientInvoices :: QueriesResult -> [ClientInvoice]
// QueriesResult = {
//    clientInvoicesResult: { data: { clientInvoices: ClientInvoicesResult } }
//    reportOnlyClientInvoicesResult: { data: { reportOnlyClientInvoices: ReportOnlyClientInvoicesResult } }
// }
const getClientInvoices = R.compose(
  R.flatten,
  R.juxt([
    R.pathOr([], ['clientInvoicesResult', 'data', 'clientInvoices', 'hits']),
    R.compose(
      R.map(R.assoc('isReportOnlyClientInvoice', true)),
      R.pathOr(
        [],
        [
          'reportOnlyClientInvoicesResult',
          'data',
          'reportOnlyClientInvoices',
          'hits',
        ],
      ),
    ),
  ]),
);

export function ProjectClientInvoicesBlock({ project, ...props }) {
  const variables = { input: { projectId: project._id } };
  const subscriptionOptions = {
    variables: { searchInput: { query: { term: { projectId: project._id } } } },
  };

  const clientInvoicesResult = useReactiveQuery(
    CLIENT_INVOICES_QUERY,
    CLIENT_INVOICES_CHANGED_SUBSCRIPTION,
    {
      subscriptionOptions,
      queryOptions: {
        variables,
        skip: isReportOnlyProjectWithClientInvoices(project),
      },
    },
  );

  const reportOnlyClientInvoicesResult = useReactiveQuery(
    REPORT_ONLY_CLIENT_INVOICES_QUERY,
    REPORT_ONLY_CLIENT_INVOICES_CHANGED_SUBSCRIPTION,
    {
      subscriptionOptions,
      queryOptions: {
        variables,
        skip: !isReportOnlyProjectWithClientInvoices(project),
      },
    },
  );

  const clientInvoices = getClientInvoices({
    clientInvoicesResult,
    reportOnlyClientInvoicesResult,
  });

  return <ProjectClientInvoicesList {...{ clientInvoices, ...props }} />;
}

ProjectClientInvoicesBlock.propTypes = {
  project: shape({
    _id: string,
  }),
};

export const SidebarSuppliers = styled(Table)`
  ${oneRowSidebarTabTableStyles};

  td:nth-child(2),
  th:nth-child(2),
  td:nth-child(3),
  th:nth-child(3) {
    width: 105px;
  }

  td:nth-child(4),
  th:nth-child(4) {
    width: 25px;
  }
  td:nth-child(3),
  th:nth-child(3) {
    width: ${({ isNteHidden }) => isNteHidden && '25px'};
  }
`;

export const SidebarTasks = styled(Table)`
  ${oneRowSidebarTabTableStyles};

  thead {
    display: none;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 145px;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 100px;
  }

  td:nth-child(4),
  th:nth-child(4) {
    width: 25px;
  }
`;

export const SidebarWeeklyServiceTickets = styled(Table)`
  ${oneRowSidebarTabTableStyles};

  tfoot > tr {
    border-top: 1px solid ${getThemeColor(['midLight'])};
  }

  td:nth-child(1),
  th:nth-child(1) {
    width: 180px;
  }

  td:nth-child(5),
  th:nth-child(5) {
    width: 25px;
  }
`;
