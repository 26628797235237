import React, { useEffect } from 'react';
import { func, string, bool } from 'prop-types';
import styled from 'styled-components';
import { LinkButton, HeaderLinksWrapper, InputOutForm } from 'poly-book-admin';
import { SystemAccountTypes, PRINT_PDF_CAPTION } from 'poly-constants';
import { CommonPrintLayout } from 'poly-admin-ui';
import { formatDate } from 'poly-utils';
import {
  generateFileNameByTitle,
  openPrintWindowWithData,
} from 'poly-client-utils';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import {
  reconciliationReportDataPropType,
  reconciliationReportSectionsPropType,
} from './prop-types.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { ReconciliationStatementSelect } from './ReconciliationStatementSelect.js';
import { ReconciliationReportTable } from './ReconciliationReportTable.js';
import { ReconciliationReportXlsBtn } from './ReconciliationReportXlsBtn.js';

const ReportSubtitleItem = styled.div`
  font-weight: normal;
  text-align: center;
  margin-bottom: 5px;
`;

function ReconciliationReportPDFBtn({
  title,
  reportSections,
  outstandingReportSections,
  isCreditCard,
  data,
}) {
  const onClick = async () => {
    const { account, endingDate } = data.reconciliationReport;

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: ReconciliationReportTable,
      fileName: generateFileNameByTitle(title),
      metaData: {
        title,
        additional: (
          <>
            <ReportSubtitleItem>
              Bank Name:&nbsp;
              {account.name}
            </ReportSubtitleItem>
            <ReportSubtitleItem>
              Statement Ending Date:&nbsp;
              {formatDate(endingDate)}
            </ReportSubtitleItem>
          </>
        ),
      },
      data,
      reportSections,
      outstandingReportSections,
      isCreditCard,
    });
  };

  return (
    <LinkButton onClick={onClick} disabled={!data?.reconciliationReport}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

ReconciliationReportPDFBtn.propTypes = {
  data: reconciliationReportDataPropType,
  title: string.isRequired,
  reportSections: reconciliationReportSectionsPropType.isRequired,
  outstandingReportSections: reconciliationReportSectionsPropType.isRequired,
  isCreditCard: bool,
};

const reconciliationReportFiltersConfig = [
  { name: 'accountCode' },
  { name: 'reconciliationStatementId' },
];

export function ReconciliationReportHeader({
  title,
  setFilters,
  loading,
  data,
  systemAccountType,
  reportSections,
  outstandingReportSections,
  isCreditCard,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    reconciliationReportFiltersConfig,
  );

  const isBankAccount = systemAccountType === SystemAccountTypes.BANK_ACCOUNT;

  const onSearch = () => {
    if (searchFilters.accountCode && searchFilters.reconciliationStatementId) {
      setFilters(searchFilters);
    }
  };

  const onResetSearch = () => {
    onReset();
    setFilters(null); // apply default values to table too
  };

  useEffect(onSearch, []);

  return (
    <SearchPageHeaderContainer title={title}>
      <FiltersContainer>
        <FlexSpaceBetween withError>
          <SearchHeaderColumn
            title={isBankAccount ? 'Bank Account' : 'Credit Card'}
            titleWidth="120px"
            filterWidth="200px"
            withError
          >
            <InputOutForm
              isOutForm
              Component={AccountsSelect}
              requiredErr={`${
                isBankAccount ? 'Bank Account' : 'Credit Card'
              } is required`}
              name="accountCode"
              value={searchFilters.accountCode}
              onChange={handlers.accountCode}
              filterAccounts={filterAccountsBySystemType(systemAccountType)}
              width="100%"
              size="small"
              required
              selectedOptionAtOnChange
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Statement Ending Date"
            titleWidth="180px"
            filterWidth="200px"
            withError
          >
            <InputOutForm
              isOutForm
              Component={ReconciliationStatementSelect}
              requiredErr="Statement Ending Date is required"
              name="reconciliationStatementId"
              value={searchFilters.reconciliationStatementId}
              onChange={handlers.reconciliationStatementId}
              accountCode={searchFilters.accountCode}
              required
              width="100%"
              size="small"
            />
          </SearchHeaderColumn>
          <FlexCenterAlign style={{ marginBottom: 10 }}>
            <HeaderLinksWrapper>
              <ReconciliationReportPDFBtn
                title={title}
                data={data}
                reportSections={reportSections}
                outstandingReportSections={outstandingReportSections}
                isCreditCard={isCreditCard}
              />
              <ReconciliationReportXlsBtn
                title={title}
                data={data}
                reportSections={reportSections}
                outstandingReportSections={outstandingReportSections}
                isCreditCard={isCreditCard}
              />
            </HeaderLinksWrapper>
            <SearchHeaderButton
              size="small"
              styleType="basicPrimary"
              onClick={onResetSearch}
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              onClick={onSearch}
              loader={loading}
            >
              Search
            </SearchHeaderButton>
          </FlexCenterAlign>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

ReconciliationReportHeader.propTypes = {
  title: string.isRequired,
  setFilters: func.isRequired,
  loading: bool,
  data: reconciliationReportDataPropType,
  systemAccountType: string.isRequired,
  reportSections: reconciliationReportSectionsPropType.isRequired,
  outstandingReportSections: reconciliationReportSectionsPropType.isRequired,
  isCreditCard: bool,
};
