import React from 'react';
import * as R from 'ramda';
import { Select, Input } from 'poly-book-admin';
import { halfWidth } from 'poly-admin-ui/src/modules/forms/common.js';
import { NormalBalanceTypes, AccountTypeCategories } from 'poly-constants';

import { StatusDropDown } from '../components.js';
import { onKeyDownToPreventFormSubmit } from '../../../utils/form.js';

const getNameFieldLayout = (isNewAccountType) =>
  isNewAccountType ? { row: 1 } : { row: 1, width: halfWidth };

const prepareNameToValidation = R.replace(/[\\~#%&*{}/:<>?|"-]/g, '');

// validateAccountTypeName :: String -> Boolean
const validateAccountTypeName = R.compose(
  R.gt(R.__, 0),
  R.length,
  prepareNameToValidation,
  R.defaultTo(''),
);

export const accountTypeNormalBalanceOptions = [
  { value: NormalBalanceTypes.DEBIT, label: 'Debit' },
  { value: NormalBalanceTypes.CREDIT, label: 'Credit' },
];

function NormalBalanceSelect(props) {
  const selectProps = {
    ...props,
    dataTestId: 'normal-balance-select',
    options: accountTypeNormalBalanceOptions,
  };

  return <Select {...selectProps} />;
}

export const accountTypeCategoryOptions = [
  { value: AccountTypeCategories.income, label: 'Income' },
  { value: AccountTypeCategories.expense, label: 'Expense' },
  { value: AccountTypeCategories.asset, label: 'Asset' },
  { value: AccountTypeCategories.liability, label: 'Liability' },
  { value: AccountTypeCategories.equity, label: 'Equity' },
];

function AccountTypeCategorySelect(props) {
  const selectProps = {
    ...props,
    allowModalOverflow: true,
    dataTestId: 'account-type-category-select',
    options: accountTypeCategoryOptions,
  };

  return <Select {...selectProps} />;
}

export const getAccountTypeFormSections = (isNewAccountType) => [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: 'Name',
        order: 1,
        layout: getNameFieldLayout(isNewAccountType),
        required: true,
        validators: [[validateAccountTypeName, 'Account-type is required']],
        field: {
          name: 'name',
          additionalProps: {
            onKeyDown: onKeyDownToPreventFormSubmit,
            showCharactersLeft: true,
          },
          Component: Input,
        },
      },
      {
        label: 'Status',
        order: 2,
        layout: { row: 1, width: halfWidth },
        renderIf: () => !isNewAccountType,
        required: true,
        field: {
          name: 'status',
          additionalProps: { onKeyDown: onKeyDownToPreventFormSubmit },
          Component: StatusDropDown,
        },
      },
      {
        label: 'Normal Balance',
        order: 3,
        layout: { row: 2, width: halfWidth },
        required: true,
        field: {
          name: 'normalBalance',
          Component: (props) =>
            NormalBalanceSelect({
              ...props,
              disabled: !isNewAccountType,
              onKeyDown: onKeyDownToPreventFormSubmit,
            }),
        },
      },
      {
        label: 'Category',
        order: 3,
        layout: { row: 2, width: halfWidth },
        required: true,
        field: {
          name: 'category',
          Component: (props) =>
            AccountTypeCategorySelect({
              ...props,
              disabled: !isNewAccountType,
              onKeyDown: onKeyDownToPreventFormSubmit,
            }),
        },
      },
    ],
  },
];
