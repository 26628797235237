import React from 'react';
import * as R from 'ramda';
import { bool, shape } from 'prop-types';
import { DatePicker, Select, Input } from 'poly-book-admin';
import {
  formatSelectOptionsByConstantsValue,
  optionalAddressValidators,
  validateSocialSecurityNum,
  TAX_SOCIAL_SECURITY_MASK,
  equalsOmitFields,
  validatePhone,
  ifNotEmpty,
} from 'poly-client-utils';
import { commonEmailValidators } from 'poly-client-utils/src/formValidators.js';

import { halfWidth } from '../../../forms/common.js';
import { PhoneNumberInput } from '../../../forms/fields/index.js';
import { genders, maritalStatuses } from '../../../../constants/users.js';
import { AddressSection } from '../../../forms/fields/AddressSection/index.js';

function SocialSecurityInput(props) {
  return (
    <Input
      {...props}
      mask="_"
      type="numberMask"
      format={TAX_SOCIAL_SECURITY_MASK}
      placeholder="Enter Security Number"
    />
  );
}

const isStaffForm = R.complement(R.prop('isContractor'));

function UserAddress({ formData, ...props }) {
  return <AddressSection {...props} required={false} isClearable />;
}

UserAddress.propTypes = {
  formData: shape({
    isContractor: bool,
  }),
};

function InputWithLimitation(props) {
  return <Input {...props} showCharactersLeft />;
}

function GenderDropDown(props) {
  const options = formatSelectOptionsByConstantsValue(genders);

  return <Select {...props} options={options} />;
}

function MaritalStatusDropDown(props) {
  const options = formatSelectOptionsByConstantsValue(maritalStatuses);

  return <Select {...props} options={options} />;
}

export const getPersonalInfoSection = (column) => [
  {
    label: 'Personal Information',
    order: 1,
    layout: { column },
    fields: [
      {
        label: 'First Name',
        order: 1,
        field: {
          name: 'general.firstName',
          additionalProps: { charactersLimit: 99 },
          Component: InputWithLimitation,
        },
        layout: {
          row: 1,
          width: halfWidth,
        },
        required: true,
        validators: [[R.identity, 'First name is required']],
      },
      {
        label: 'Middle Name',
        order: 2,
        field: {
          name: 'general.middleName',
          Component: Input,
        },
        layout: {
          row: 1,
          width: halfWidth,
        },
      },
      {
        label: 'Last Name',
        order: 3,
        field: {
          name: 'general.lastName',
          additionalProps: { charactersLimit: 99 },
          Component: InputWithLimitation,
        },
        layout: {
          row: 2,
          width: halfWidth,
        },
        required: true,
        validators: [[R.identity, 'Last name is required']],
      },
      {
        label: 'Social Security #',
        order: 4,
        field: {
          name: 'general.socialSecurityNumber',
          Component: SocialSecurityInput,
        },
        layout: {
          row: 2,
          width: halfWidth,
        },
        validators: [
          [R.either(validateSocialSecurityNum, R.isNil), 'Incorrect Number'],
        ],
        renderIf: isStaffForm,
      },
      {
        label: 'Date Of Birth',
        order: 5,
        field: {
          name: 'general.dateOfBirth',
          Component: (props) => <DatePicker {...props} leftMove="0" />,
        },
        layout: {
          row: 3,
          width: halfWidth,
        },
        renderIf: isStaffForm,
      },
      {
        label: 'Gender',
        order: 6,
        field: {
          name: 'general.gender',
          Component: GenderDropDown,
        },
        layout: {
          row: 3,
          width: halfWidth,
        },
        renderIf: isStaffForm,
      },
      {
        label: 'Marital Status',
        order: 7,
        field: {
          name: 'general.maritalStatus',
          Component: MaritalStatusDropDown,
        },
        layout: {
          row: 4,
          width: halfWidth,
        },
        renderIf: isStaffForm,
      },
      {
        order: 8,
        layout: { row: 5, width: '100%' },
        field: {
          name: 'contacts.address',
          withFormData: true,
          Component: UserAddress,
          isEqual: equalsOmitFields([['formatted_address']]),
        },
        validators: optionalAddressValidators,
      },
      {
        label: 'Personal Phone',
        order: 9,
        field: {
          name: 'contacts.personalPhone',
          Component: PhoneNumberInput,
        },
        layout: {
          row: 6,
          width: halfWidth,
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
      {
        label: 'Mobile Phone',
        order: 10,
        field: {
          name: 'contacts.personalMobile',
          Component: PhoneNumberInput,
        },
        layout: {
          row: 6,
          width: halfWidth,
        },
        validators: [[ifNotEmpty(validatePhone), 'Incorrect phone']],
      },
      {
        label: 'Personal Email',
        order: 11,
        field: {
          name: 'contacts.personalEmail',
          Component: Input,
        },
        layout: {
          row: 7,
          width: halfWidth,
        },
        validators: commonEmailValidators,
      },
    ],
  },
];
