import React from 'react';
import { func } from 'prop-types';
import { ClientSelect } from 'poly-admin-ui';

export function AssetClientSelect({
  changeFieldValue,
  onChange: onChangeBase,
  ...props
}) {
  const onChange = (value) => {
    changeFieldValue('propertyId', null);
    onChangeBase(value);
  };

  return (
    <ClientSelect
      {...props}
      withoutSkip
      onChange={onChange}
      includeAllOption={false}
    />
  );
}

AssetClientSelect.propTypes = {
  onChange: func.isRequired,
  changeFieldValue: func.isRequired,
};
