import { useState } from 'react';

import { ASC_SORT_ORDER } from 'poly-constants';
import { path } from 'ramda';
import { updateGraphSorting } from 'poly-book-admin/src/Table/utils.js';

export const useTableSorting = ({
  tableConfig,
  column,
  defaultSort,
  order = ASC_SORT_ORDER,
  isSortable = true,
}) => {
  const defaultColumnKey = column - 1;
  const defaultSortQuery = path([defaultColumnKey, 2], tableConfig);

  const commonInitialSorting =
    typeof defaultSortQuery !== 'function'
      ? {}
      : {
          dir: order === ASC_SORT_ORDER ? 1 : -1,
          query: defaultSortQuery(order),
          columnKey: defaultColumnKey,
        };

  const initialSorting = defaultSort
    ? { query: defaultSort }
    : commonInitialSorting;

  const [sorting, setSorting] = useState(initialSorting);

  return {
    onHeaderCellClick: (columnKey, getSortQuery) => {
      if (typeof getSortQuery === 'function') {
        const newSorting = updateGraphSorting(getSortQuery, columnKey, sorting);
        setSorting(newSorting);
      }
    },
    sort: sorting.query,
    isServerSorting: true,
    isSortable,
    sorting,
  };
};
