import * as R from 'ramda';
import { useMutation, gql } from '@apollo/client';
import { useNotificationState, useModalContext, entities } from 'poly-admin-ui';

const UPDATE_PROJECT_ASSET_MUTATION = gql`
  mutation UPDATE_PROJECT_ASSET_MUTATION($id: ID!, $update: ProjectUpdate!) {
    updateProject(id: $id, update: $update) {
      _id
    }
  }
`;

const UPDATE_RECURRING_PROJECT_ASSET_MUTATION = gql`
  mutation UPDATE_RECURRING_PROJECT_ASSET_MUTATION($id: ID!, $assetIds: [ID]) {
    updateRecurringProjectAssets(id: $id, assetIds: $assetIds) {
      _id
    }
  }
`;

// prepareVariablesToAssetsUnlink :: (ID, Entity) -> [ID] -> UpdateProjectVariables
const prepareVariablesToAssetsUnlink = (id, entity) =>
  R.compose(
    R.mergeLeft({ id: entity._id }),
    R.when(() => entity.name === entities.PROJECT, R.objOf('update')),
    R.objOf('assetIds'),
    R.reject(R.equals(id)),
    R.defaultTo([]),
  );

export const assetsUpdateMutationByEntityMap = {
  [entities.PROJECT]: UPDATE_PROJECT_ASSET_MUTATION,
  [entities.RECURRING_PROJECT]: UPDATE_RECURRING_PROJECT_ASSET_MUTATION,
};

export const useUnlinkAssetMutation = ({ entity, assetIds, _id }) => {
  const { openConfirmSubmitFormModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();

  const MUTATION = assetsUpdateMutationByEntityMap[entity.name];

  const [unlinkAsset] = useMutation(MUTATION);

  return () =>
    openConfirmSubmitFormModal({
      btnCaption: 'Yes, Unlink',
      processId: 'unlinkAssetProcessId',
      content: 'Are you sure you want to unlink asset from this card?',
      onConfirm: (closeConfirmModal) => async () => {
        await unlinkAsset({
          variables: prepareVariablesToAssetsUnlink(_id, entity)(assetIds),
        });
        showSuccessNotification('Asset was unlinked from this card');
        closeConfirmModal();
      },
    });
};
