import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { useCurrentUserByStoreOrQuery } from 'poly-client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';
import { taskCollections } from 'poly-constants';

import {
  DEFAULT_SUPPLIER_QUERY,
  DEFAULT_MASTER_SUPPLIER_QUERY,
} from '../../../../hocs/suppliers/queries.js';
import {
  DEFAULT_PROJECT_QUERY,
  DEFAULT_RECURRING_PROJECT_QUERY,
  DEFAULT_PREVENTIVE_MAINTENANCE_QUERY,
  DEFAULT_ADMIN_PO_QUERY,
} from '../../../../hocs/projects/queries.js';
import { DEFAULT_CLIENT_QUERY } from '../../../../hooks/clients/queries.js';
import { DEFAULT_PROPERTY_QUERY } from '../../../../hocs/properties/queries.js';

const {
  CLIENTS,
  PROJECTS,
  SUPPLIERS,
  PROPERTIES,
  OCR_INVOICES,
  MASTER_SUPPLIERS,
  RECURRING_PROJECTS,
  PREVENTIVE_MAINTENANCE,
  ADMIN_PURCHASE_ORDERS,
} = taskCollections;

// collectionEqTo :: String -> Boolean
const collectionEqTo = (name) => R.propSatisfies(R.equals(name), 'collection');

// taskQueryPropsByCollectionConfig :: TaskFormProps -> AdditionalTaskFormPropsByCollection
// TaskFormProps = { collection: oneOf(taskCollections), defaultQuery: GraphQLQuery }
// AdditionalTaskFormPropsByCollection = { query: GraphQLQuery, title: String, entityInfoPath: Function }
const taskQueryPropsByCollectionConfig = R.cond([
  [
    collectionEqTo(SUPPLIERS),
    R.always({
      query: DEFAULT_SUPPLIER_QUERY,
      title: 'Create Supplier Task',
      entityInfoPath: ['supplier', 'company', 'name'],
    }),
  ],
  [
    collectionEqTo(PROPERTIES),
    R.always({
      query: DEFAULT_PROPERTY_QUERY,
      title: 'Create Property Task',
      entityInfoPath: ['property', 'name'],
    }),
  ],
  [
    collectionEqTo(CLIENTS),
    R.always({
      query: DEFAULT_CLIENT_QUERY,
      title: 'Create Client Task',
      entityInfoPath: ['client', 'name'],
    }),
  ],
  [
    collectionEqTo(PROJECTS),
    R.always({
      query: DEFAULT_PROJECT_QUERY,
      title: 'Create Project Task',
      entityInfoPath: ['project', 'projectId'],
    }),
  ],
  [
    collectionEqTo(RECURRING_PROJECTS),
    R.always({
      query: DEFAULT_RECURRING_PROJECT_QUERY,
      title: 'Create Project Task',
      entityInfoPath: ['recurringProject', 'projectId'],
    }),
  ],
  [
    collectionEqTo(PREVENTIVE_MAINTENANCE),
    R.always({
      query: DEFAULT_PREVENTIVE_MAINTENANCE_QUERY,
      title: 'Create Project Task',
      entityInfoPath: ['maintenancePlan', 'maintenancePlanId'],
    }),
  ],
  [
    collectionEqTo(OCR_INVOICES),
    R.applySpec({
      query: R.prop('defaultQuery'),
      title: R.always('Create Invoice Task'),
      entityInfoPath: R.always(['ocrInvoice', 'invoiceNumber']),
    }),
  ],
  [
    collectionEqTo(MASTER_SUPPLIERS),
    R.always({
      query: DEFAULT_MASTER_SUPPLIER_QUERY,
      title: 'Create Master Supplier Task',
      entityInfoPath: ['masterSupplier', 'company', 'name'],
    }),
  ],
  [
    collectionEqTo(ADMIN_PURCHASE_ORDERS),
    R.always({
      query: DEFAULT_ADMIN_PO_QUERY,
      title: 'Create PO Task',
      entityInfoPath: ['adminPurchaseOrder', 'displayName'],
    }),
  ],
  [R.T, R.always({})],
]);

const useCollectionPropsToTask = (query, entityInfoPath, _id) => {
  const { data, loading } = useQuery(query, {
    variables: { id: _id },
    skip: !_id,
  });

  return {
    data,
    entityInfo: R.path(entityInfoPath, data),
    loading,
  };
};

export const useDetailsByCollection = ({
  collection,
  defaultQuery,
  documentId,
}) => {
  const { user } = useCurrentUserByStoreOrQuery();

  const { query, title, entityInfoPath } = taskQueryPropsByCollectionConfig({
    collection,
    defaultQuery,
  });

  const { data, entityInfo, loading } = useCollectionPropsToTask(
    query,
    entityInfoPath,
    documentId,
  );

  return {
    data,
    user,
    title,
    loading,
    entityInfo,
  };
};
