import * as R from 'ramda';
import React from 'react';
import { useMutation } from '@apollo/client';
import { assocBy } from 'poly-utils';
import { closeCurrentModal } from 'poly-admin-ui';
import { CREATE_MENTION_GROUP_MUTATION } from '../../core/hooks/mentionGroups/mutations.js';
import { MentionGroupFromWithButtons } from './MentionGroupFromWithButtons.js';

// mentionGroupToUpdateInput :: MentionGroup -> {input: CreateMentionGroupInput}
const mentionGroupToUpdateInput = R.compose(
  R.objOf('input'),
  assocBy(
    'usersIds',
    R.compose(R.map(R.prop('value')), R.defaultTo([]), R.prop('usersIds')),
  ),
);

export function CreateMentionGroupForm(props) {
  const [mutate] = useMutation(CREATE_MENTION_GROUP_MUTATION, {
    alias: 'CreateMentionGroupMutation',
  });

  return (
    <MentionGroupFromWithButtons
      {...props}
      mutate={mutate}
      title="Add Mention Group"
      submitCaption="Add Group"
      successMessage="Mention group successfully created"
      formDataToMutationVariables={mentionGroupToUpdateInput}
      closeCurrentModal={closeCurrentModal}
    />
  );
}
