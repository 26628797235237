import { TimesheetTypes } from 'poly-constants';

export const timesheetTypesMap = {
  [TimesheetTypes.STRAIGHT_TIME]: 'Straight Time',
  [TimesheetTypes.OVER_TIME]: 'Over Time',
  [TimesheetTypes.DOUBLE_TIME]: 'Double Time',
  [TimesheetTypes.PTO]: 'PTO',
  [TimesheetTypes.HOLIDAY]: 'Holiday',
};

export const timesheetInitialValues = {
  date: new Date(),
  type: TimesheetTypes.STRAIGHT_TIME,
  entries: [{ timeIn: new Date() }],
};

export const MONDAY_DAY_NUMBER = 1;

export const timesheetWeekEdgeOptions = {
  weekStartsOn: MONDAY_DAY_NUMBER,
};
