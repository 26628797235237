import * as R from 'ramda';
import {
  collectionNames,
  supplierInvoiceRequestOptions,
  taskActionTypes,
} from 'poly-constants';
import {
  initialPagination,
  paginationToQueryParams,
  useReactiveQuery,
} from 'poly-client-utils';
import { useQuery } from '@apollo/client';
import { useSubscriptionByChanges } from 'poly-client-utils/src/entitiesSearch.js';
import {
  getTasksSubscriptionOptionsByQueryInput,
  TASK_SEARCH_CHANGED,
} from '../../../../hocs/tasks/subscriptions.js';
import {
  ENTITY_TASKS,
  PROJECT_PO_QUERY,
  PROJECT_SUB,
} from '../../../../hocs/tasks/queries.js';
import { getProjectTasksInput } from '../../../../searchQueries/tasks.js';
import {
  useProjectSuppliers,
  useRecurringProjectSuppliers,
} from '../../../../hooks/projects/index.js';
import {
  useCreateUpdateWithAction,
  useUpdateTask,
} from '../../../../hooks/tasks/index.js';
import { updateCacheHandler } from '../../../../utils/apollo-cache.js';
import {
  completeTaskByPath,
  prepareCompleteTaskDataToMutation,
  prepareUpdateTaskDataToMutation,
  prepareUpdateWithActionData,
  getCloseProjectError,
  getProjectCompleteError,
} from './taskFormUtils.js';
import { isDefaultTask as checkDefaultTask } from '../../../../utils/tasks.js';
import { useAddNewUpdate } from '../../../../hooks/updates/index.js';
import { getEmailsToSendTo } from '../../fields/MailTo/index.js';
import { formatTaskUpdateTitleBase } from '../../../../utils/updates.js';
import { projectTasksTitleMapByProjectType } from './constants.js';
import { mapAttachmentsBeforeUpdate } from '../../addUpdateForm/index.js';

// isDefaultTaskCanBeCompleted :: { input: CreateUpdateWithActionInput } -> Boolean
const isDefaultTaskCanBeCompleted = R.compose(
  R.ifElse(
    R.complement(R.has('projectComplete')),
    R.T,
    R.compose(
      R.all(R.equals(false)),
      R.map(
        R.propEq('supplierRequest', supplierInvoiceRequestOptions.sendLater),
      ),
      R.path(['projectComplete', 'supplierRatingsAndRequests']),
    ),
  ),
  R.prop('input'),
);

export const useUpdateTaskHelpers = ({
  task,
  readFromCacheParams = {},
  customTasksFields = [],
}) => {
  const isDefaultTask = checkDefaultTask(task);

  const projectId = R.path(['document', 'project', '_id'], task);

  const { updateTask } = useUpdateTask();

  const { createUpdateWithAction } = useCreateUpdateWithAction();

  const updateTaskMutation = (input) => {
    if (!task?._id) return null;

    return updateTask(
      prepareUpdateTaskDataToMutation(task?._id, customTasksFields)(input),
    );
  };

  const completeTask = (input) => {
    if (!task?._id) return null;

    const preparedInputData = prepareCompleteTaskDataToMutation(task?._id)(
      input,
    );

    const update = updateCacheHandler(
      readFromCacheParams.query,
      readFromCacheParams.variables,
      completeTaskByPath(readFromCacheParams.path, task?._id),
    );

    return !readFromCacheParams.query
      ? updateTask(preparedInputData)
      : updateTask({ ...update, ...preparedInputData });
  };

  const completeDefaultTask = (input) => {
    const preparedInputData = prepareUpdateWithActionData(task?.action)({
      ...input,
      projectId,
    });

    const update = updateCacheHandler(
      readFromCacheParams.query,
      readFromCacheParams.variables,
      completeTaskByPath(readFromCacheParams.path, task?._id),
    );

    return !isDefaultTaskCanBeCompleted(preparedInputData) ||
      !readFromCacheParams.query
      ? createUpdateWithAction(preparedInputData)
      : createUpdateWithAction({ ...update, ...preparedInputData });
  };

  return {
    updateTaskMutation,
    completeTask,
    completeDefaultTask,
    isDefaultTask,
    updateTask,
    createUpdateWithAction,
  };
};

export const useProjectTaskInfo = ({ collection, task }) => {
  const projectId = R.path(['document', 'project', '_id'], task);

  const recurringProjectId = R.path(
    ['document', 'recurringProject', '_id'],
    task,
  );

  const skipProjectQuery =
    !projectId || collection !== collectionNames.projects;

  const { data: projectSuppliers } = useProjectSuppliers(
    projectId,
    skipProjectQuery,
  );

  const { data: recurringProjectSuppliers } =
    useRecurringProjectSuppliers(recurringProjectId);

  return {
    data:
      collection === collectionNames.projects
        ? projectSuppliers
        : recurringProjectSuppliers,
  };
};

// getProjectAdminPO :: ProjectQueryResult -> AdminPurchaseOrder
const getProjectAdminPO = R.either(
  R.path(['project', 'adminPurchaseOrder']),
  R.path(['project', 'parent', 'adminPurchaseOrder']),
);

export const useCloseProjectTaskErrorHelpers = (task, payload) => {
  const projectId = R.path(['document', 'project', '_id'], task);

  const pagination = { ...initialPagination, itemsPerPage: 20 };
  const tasksInput = getProjectTasksInput(projectId);

  const skipQuery =
    !projectId || task?.action !== taskActionTypes.CLOSE_PROJECT;

  const { data, loading, refetch } = useQuery(ENTITY_TASKS, {
    variables: {
      input: {
        ...tasksInput,
        ...paginationToQueryParams(pagination),
      },
    },
    skip: skipQuery,
    fetchPolicy: 'network-only',
  });

  const queryOptions = {
    variables: { projectId },
    skip: skipQuery,
    fetchPolicy: 'network-only',
  };

  const { data: projectPOData } = useReactiveQuery(
    PROJECT_PO_QUERY,
    PROJECT_SUB,
    { queryOptions, getSubscriptionOptions: queryOptions },
  );

  const adminPurchaseOrder = getProjectAdminPO(projectPOData);

  useSubscriptionByChanges({
    gqlQueryChanged: TASK_SEARCH_CHANGED,
    extractQueryParamsFromProps: R.compose(
      R.mergeLeft({ skip: skipQuery }),
      getTasksSubscriptionOptionsByQueryInput,
      R.prop('tasksInput'),
    ),
    refetch,
    result: { ...data, tasksInput },
    skipQuery,
  });

  return {
    data,
    loading,
    taskError: data
      ? getCloseProjectError({
          tasks: data?.tasks?.hits || [],
          client: payload?.project?.client,
          adminPurchaseOrder,
        })
      : '',
  };
};

export const useProjectCompleteTaskErrorHelpers = (task, payload) => {
  const projectId = R.path(['document', 'project', '_id'], task);

  const skipQuery =
    !projectId || task?.action !== taskActionTypes.PROJECT_COMPLETE;

  const { data, loading } = useProjectSuppliers(projectId, skipQuery);

  const taskError = data
    ? getProjectCompleteError({
        project: data?.project,
        payload,
        task,
      })
    : '';

  return {
    data,
    loading,
    taskError,
  };
};

// getTaskTitleByType :: TaskTypeData -> String
// TaskTypeData = { taskType: String, task: Task, project: Project }
const getTaskTitleByType = R.compose(
  formatTaskUpdateTitleBase,
  R.defaultTo('Default Task'),
  R.ifElse(
    R.prop('taskType'),
    R.converge(R.prop, [
      R.prop('taskType'),
      R.compose(projectTasksTitleMapByProjectType, R.path(['project', 'type'])),
    ]),
    R.path(['task', 'description']),
  ),
);

export const useAddTaskUpdateHandler = ({ task, project = {} }) => {
  const { addNewUpdate } = useAddNewUpdate();

  const addTaskUpdate = ({
    mailTo,
    details,
    taskType,
    documentId,
    workCompletionDate,
    projectId,
  }) => {
    const emailsToSendUpdate = getEmailsToSendTo(mailTo);

    return addNewUpdate({
      variables: {
        input: {
          documentId,
          collection: collectionNames.tasks,
          message: details.text,
          mentions: details.mentions,
          title: getTaskTitleByType({ taskType, task, project }),
          filesAttachments: mapAttachmentsBeforeUpdate(details.attachments),
          emailTo: emailsToSendUpdate,
          ...(workCompletionDate ? { workCompletionDate, projectId } : {}),
        },
      },
    });
  };

  return {
    addTaskUpdate,
  };
};
