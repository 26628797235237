import React from 'react';
import { PeopleTab as PeopleTabImp } from 'poly-admin-ui';

import { useSidebarLogicContext } from '../../../sidebars/SidebarLogicContext.js';

export function PeopleTab(props) {
  const { ContactLink } = useSidebarLogicContext();

  return <PeopleTabImp {...props} SidebarLink={ContactLink} />;
}
