import * as R from 'ramda';
import { CLIENT_APP_AIT } from 'poly-security/src/access-item-types.js';
import {
  FLIPPED_CONTAINS_AIT_OPERATOR,
  EQUALS_AIT_OPERATOR,
} from 'poly-security/src/operators.js';
import { MOBILE_APP_NAME } from 'poly-security/src/app-names.js';
import { getTimezoneByDate, formatDateMongo } from './dates.js';

/*
  ElasticDateQueryMatcher = Object
  f.e. { gt: '01/01/2017', time_zone: '+0300' }

  ElasticRangeQuery = Object
  f.e. { range: { endDate: ElasticDateQueryMatcher } }
*/

// buildDateMatcher :: [String] -> Date -> ElasticDateQueryMatcher
const buildDateMatcher = (comparators) =>
  R.converge(R.reduce, [
    R.compose(
      (dateStr) => (acc, field) => R.assoc(field, dateStr, acc),
      formatDateMongo,
    ),
    R.compose(R.objOf('time_zone'), getTimezoneByDate),
    R.always(comparators),
  ]);

// dateEq :: Date -> ElasticDateQueryMatcher
const dateEq = buildDateMatcher(['gte', 'lte']);

// dateGte :: Date -> ElasticDateQueryMatcher
const dateGte = buildDateMatcher(['gte']);

// dateLt :: Date -> ElasticDateQueryMatcher
const dateLt = buildDateMatcher(['lt']);

// buildQueryForDateField :: (Date -> ElasticDateQueryMatcher) -> String -> Date -> ElasticRangeQuery
const buildQueryForDateField = (dateMatcher) =>
  R.curry((field, date) => ({
    range: {
      [field]: dateMatcher(date),
    },
  }));

// dateFieldEq :: String -> Date -> ElasticRangeQuery
export const dateFieldEq = buildQueryForDateField(dateEq);

// dateFieldGte :: String -> Date -> ElasticRangeQuery
export const dateFieldGte = buildQueryForDateField(dateGte);

// dateFieldLt :: String -> Date -> ElasticRangeQuery
export const dateFieldLt = buildQueryForDateField(dateLt);

export const getTechnicianUsersElasticQuery = () => ({
  nested: {
    path: 'accessItems',
    query: {
      bool: {
        should: [
          {
            term: {
              [`accessItems.types.${CLIENT_APP_AIT}.${FLIPPED_CONTAINS_AIT_OPERATOR}`]:
                MOBILE_APP_NAME,
            },
          },
          {
            term: {
              [`accessItems.types.${CLIENT_APP_AIT}.${EQUALS_AIT_OPERATOR}`]:
                MOBILE_APP_NAME,
            },
          },
        ],
      },
    },
  },
});
