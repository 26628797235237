import * as R from 'ramda';
import { assocBy } from 'poly-utils/src/ramda.js';
import { isNotNillOrEmptyPath } from 'poly-utils/src/general.js';

// getAddressByPropName :: String -> Supplier -> Address
const getAddressByPropName = (propName) =>
  R.compose(
    R.mergeAll,
    R.juxt([
      R.path([propName, 'address']),
      R.compose(R.pick(['addressTwo']), R.prop(propName)),
    ]),
  );

// getSupplier1099ReportAddress :: Supplier -> Address
const getSupplier1099ReportAddress = R.cond([
  [isNotNillOrEmptyPath(['tax', 'address']), getAddressByPropName('tax')],
  [isNotNillOrEmptyPath(['remit', 'address']), getAddressByPropName('remit')],
  [
    isNotNillOrEmptyPath(['company', 'address']),
    getAddressByPropName('company'),
  ],
  [R.T, R.always({})],
]);

// prepare1099ReportSupplier -> [SuppliersAAC1099Report] -> [SuppliersAAC1099Report]
export const prepare1099ReportSupplier = R.compose(
  assocBy(
    'supplier',
    R.compose(
      assocBy('address', getSupplier1099ReportAddress),
      R.either(R.prop('masterSupplier'), R.prop('supplier')),
    ),
  ),
  assocBy('isMasterSupplier', isNotNillOrEmptyPath(['masterSupplier'])),
);
