import * as R from 'ramda';
import { getErrorMessagesByApolloError } from 'poly-apollo-client';
import { insertQueryParamsIntoURL } from 'poly-utils';
import { history } from 'poly-client-routing';
import {
  SERVICE_TICKET_ALREADY_RECEIVED_ERROR_MESSAGE,
  SUPPLIER_DOCUMENT_NOT_EXPIRED_ERROR_MESSAGE,
  INVOICE_ALREADY_UPDATED_ERROR_MESSAGE,
  EXPIRED_ACCESS_LINK_ERROR_MESSAGE,
  MISSING_TOKEN_ERROR_MESSAGE,
  VENDOR_INFO_PAGE_ROUTE,
  PolyMultiLineError,
  ADMIN_SETUP_2FA_PAGE_PATH,
  SETUP_2FA_REQUIRED_ERROR_MESSAGE,
} from 'poly-constants';
import { is2FASetupError } from 'poly-client-utils/src/helpers.js';

import { showError } from '../redux/notifications.js';
import { VENDOR_INFO_TYPES } from '../constants/vendor.js';

const isNotMultiLineError = R.complement(R.test(PolyMultiLineError.REG_EXP));

// isTokenExpiredError :: [Error] -> Boolean
const isTokenExpiredError = R.compose(
  R.includes(true),
  R.map(
    R.includes(R.__, [
      SERVICE_TICKET_ALREADY_RECEIVED_ERROR_MESSAGE,
      EXPIRED_ACCESS_LINK_ERROR_MESSAGE,
      MISSING_TOKEN_ERROR_MESSAGE,
    ]),
  ),
  R.defaultTo([]),
);

// getVendorInfoLinkByErrors :: [Error] -> String
const getVendorInfoLinkByErrors = R.cond([
  [isTokenExpiredError, R.always(VENDOR_INFO_TYPES.TOKEN_EXPIRED)],
  [
    R.includes(INVOICE_ALREADY_UPDATED_ERROR_MESSAGE),
    R.always(VENDOR_INFO_TYPES.INVOICE_SUBMITTED),
  ],
  [
    R.includes(SUPPLIER_DOCUMENT_NOT_EXPIRED_ERROR_MESSAGE),
    R.always(VENDOR_INFO_TYPES.DOCUMENTS_RECEIVED),
  ],
  [R.T, R.always(null)],
]);

export function createApolloErrorHandlerForAdminUI({
  store,
  useVendorInfo = false,
  with2FASetup = false,
  logOutHandler,
}) {
  return (error) => {
    const errorsArr = getErrorMessagesByApolloError(error);

    if (is2FASetupError(errorsArr) && with2FASetup) {
      return history.push(
        insertQueryParamsIntoURL(
          { previousUrl: window.location.pathname },
          ADMIN_SETUP_2FA_PAGE_PATH,
        ),
      );
    }

    if (is2FASetupError(errorsArr)) {
      logOutHandler();
      return store.dispatch(showError(SETUP_2FA_REQUIRED_ERROR_MESSAGE));
    }

    const vendorInfoType = getVendorInfoLinkByErrors(errorsArr);

    if (!!vendorInfoType && useVendorInfo)
      return history.push(
        insertQueryParamsIntoURL(
          { type: vendorInfoType },
          VENDOR_INFO_PAGE_ROUTE,
        ),
      );

    return errorsArr.forEach((errorMessage) => {
      if (isNotMultiLineError(errorMessage)) {
        store.dispatch(showError(errorMessage));
      }
    });
  };
}
