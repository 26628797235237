import * as R from 'ramda';
import React from 'react';
import { useRedirectIfHasNoPermissions } from 'poly-client-utils';
import { CREATE_CLIENT_BATCH_PERMISSION } from 'poly-security';

import { BatchInvoicingTMPage } from './BatchInvoicingTMPage.js';
import {
  regionalManagementBatchInvoicingQuery,
  _REGIONAL_MANAGEMENT_JANITORIAL_SERVICE_TYPE_ID,
} from './client-filters-presets.js';

const serviceTypeIdTerm = {
  term: { serviceTypeId: _REGIONAL_MANAGEMENT_JANITORIAL_SERVICE_TYPE_ID },
};

const parentIdNotExistsBool = {
  bool: {
    must_not: {
      exists: {
        field: 'parentId',
      },
    },
  },
};

// prepareQuery :: SearchQuery -> SearchQuery
const prepareQuery = R.over(
  R.lensPath(['bool', 'filter']),
  R.append({
    bool: {
      should: [
        { bool: { must_not: serviceTypeIdTerm } },
        { bool: { must: [serviceTypeIdTerm, parentIdNotExistsBool] } },
      ],
    },
  }),
);

export function RegionalManagementBatchInvoicingPage() {
  useRedirectIfHasNoPermissions([CREATE_CLIENT_BATCH_PERMISSION]);

  return (
    <BatchInvoicingTMPage
      query={prepareQuery(regionalManagementBatchInvoicingQuery)}
      title="Regional Management Batch Invoice"
      withSupplierInvoices={false}
      showSupplierInvoicesCheckbox={false}
    />
  );
}
