import React from 'react';
import * as R from 'ramda';
import { shape } from 'prop-types';
import { performExcelExport } from 'poly-client-utils/src/excel-export/export.js';
import { EXPORT_XLS_CAPTION } from 'poly-constants/src/print.js';
import { ExcelExportCellType } from 'poly-utils/src/excel-export/constants.js';
import { createExcelExportCell } from 'poly-utils/src/excel-export/helpers.js';

import { WorkInProgressExportXLSBtnS } from '../WorkInProgressReport/WorkInProgressExportXLSBtn.js';
import { getProjectDaysOpen } from '../../utils/project.js';
import { useOpenWorkOrdersReportLazyQuery } from './useOpenWorkOrdersReport.js';

// getOpenWorkOrdersReportRowConfig :: [Project] -> [ExcelExportDataCell]
const getOpenWorkOrdersReportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 6)),
  R.juxt([
    R.prop('projectId'),
    R.compose(
      R.join(', '),
      R.map(R.pathOr('', ['company', 'name'])),
      R.propOr([], 'suppliers'),
    ),
    R.path(['serviceType', 'name']),
    R.prop('description'),
    getProjectDaysOpen,
    R.path(['manager', 'fullName']),
  ]),
);

export const getOpenWorkOrdersReportExportExcelConfig = (data) => ({
  exportFileName: 'open_work_orders_report.xlsx',
  columnWidths: [15, 20, 15, 45, 15, 20],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: 'Open Work Orders Report',
      },
    ],
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 6),
      [
        'Project #',
        'Suppliers',
        'Service Type',
        'Description',
        'Age(in days)',
        'Assigned CSR',
      ],
    ),
    ...R.map(
      getOpenWorkOrdersReportRowConfig,
      R.pathOr([], ['searchProjects', 'hits'], data),
    ),
  ],
});

export function OpenWorkOrdersXLSBtn({ filter, sort, ...props }) {
  const queryHandler = useOpenWorkOrdersReportLazyQuery();
  const onExportClick = async () => {
    const { data } = await queryHandler({ filter, sort });

    const excelConfig = getOpenWorkOrdersReportExportExcelConfig(data);
    performExcelExport(excelConfig);
  };

  return (
    <WorkInProgressExportXLSBtnS {...props} onClick={onExportClick}>
      {EXPORT_XLS_CAPTION}
    </WorkInProgressExportXLSBtnS>
  );
}

OpenWorkOrdersXLSBtn.propTypes = { filter: shape({}), sort: shape({}) };
