import * as R from 'ramda';
import React, { useMemo, useEffect, useCallback } from 'react';
import { number, shape, func, arrayOf, string, bool } from 'prop-types';
import { LinkButton, FieldLayout } from 'poly-book-admin';
import { calculateTotal, convertDollarsToCents, debounce } from 'poly-utils';
import { MoneyInputAsCents } from 'poly-admin-ui';
import { InvoiceTypes } from 'poly-constants';

import {
  DeleteIconS,
  ButtonsWrapperS,
  SupplierInvoiceFormLineS,
  SupplierInvoiceFlexLineS,
  InvoiceDescriptionEditorS,
  SupplierInvoiceLineWrapperS,
} from './componentsStyled.js';
import { ProjectSelect } from '../../../../components/ProjectsSelect.js';
import { LabourCostInput } from '../../../../modules/forms/supplierInvoiceForm/components/LabourCostInput.js';
import { useProjectInfoByIdQuery } from './useProjectInfoByIdQuery.js';
import {
  generateProjectSelectQuery,
  calculateTotalByLine,
  getSelectedProjects,
} from './addSupplierInvoiceLineUtils.js';

const zeroPlaceholder = '$0.00';
const moneyPlaceholder = '$1,000,000.00';
const commonFieldLayout = {
  width: 'calc(19% - 7px)',
  label: { color: 'midDark' },
};

// getSupplierNteWarning :: { supplierNTE: Float, value: Int } -> String
const getSupplierNteWarning = R.ifElse(
  R.both(
    R.propIs(Number, 'supplierNTE'),
    R.converge(R.lt, [
      R.compose(convertDollarsToCents, R.prop('supplierNTE')),
      R.compose(
        // in case of empty string
        R.when(R.isEmpty, R.always(0)),
        R.propOr(0, 'value'),
      ),
    ]),
  ),
  R.always('Total exceeds Supplier NTE'),
  R.always(null),
);

function SupplierInvoiceTotalAmountInput({
  onChange,
  formLine,
  isBreakdown,
  supplierNTE,
  ...props
}) {
  const updatedAmountByLine = useMemo(
    () => calculateTotalByLine(formLine),
    [formLine],
  );

  const warning = getSupplierNteWarning({ supplierNTE, ...props });

  const onChangeAmountDebounced = useCallback(debounce(100)(onChange), []);

  useEffect(() => {
    if (isBreakdown) {
      onChangeAmountDebounced(updatedAmountByLine);
    }
  }, [isBreakdown, updatedAmountByLine]);

  return (
    <MoneyInputAsCents
      {...props}
      warning={warning}
      onChange={onChange}
      placeholder={!isBreakdown ? moneyPlaceholder : zeroPlaceholder}
    />
  );
}

SupplierInvoiceTotalAmountInput.propTypes = {
  onChange: func.isRequired,
  isBreakdown: bool.isRequired,
  supplierNTE: number,
  formLine: shape({ total: number }),
};

function TotalAmountInput({ lines, onChange, fieldName, ...props }) {
  const totalAmount = useMemo(
    () => calculateTotal(R.prop(fieldName))(lines),
    [lines],
  );

  const onChangeAmountDebounced = useCallback(debounce(100)(onChange), []);

  useEffect(() => {
    onChangeAmountDebounced(totalAmount);
  }, [totalAmount]);

  return (
    <MoneyInputAsCents
      {...props}
      onChange={onChange}
      placeholder={zeroPlaceholder}
    />
  );
}

TotalAmountInput.propTypes = {
  onChange: func.isRequired,
  fieldName: string.isRequired,
  lines: arrayOf(shape({ total: number })).isRequired,
};

function InvoiceDescriptionEditor({ index, ...props }) {
  const id = `invoice-description-${index}`;

  return (
    <InvoiceDescriptionEditorS
      {...props}
      id={id}
      placeholder="Enter Invoice Description"
    />
  );
}

InvoiceDescriptionEditor.propTypes = { index: number.isRequired };

export function AddSupplierInvoiceLine({
  name,
  index,
  formData,
  changeFieldValue,
  fields: { push, value, remove },
}) {
  const isFirstLine = index === 0;
  const isSingleLine = value.length === 1;
  const isLastLine = index === value.length - 1;

  const selectedProjects = useMemo(() => getSelectedProjects(value), [value]);
  const supplierId = useMemo(() => R.prop('supplierId')(formData), [formData]);
  const formLine = useMemo(() => value[index], [value]);
  const isSingleDescription = useMemo(
    () => R.prop('isSingleDescription')(formData),
    [formData],
  );
  const isBreakdown = useMemo(
    () => R.propEq('type', InvoiceTypes.breakdown)(formData),
    [formData],
  );
  const projectId = useMemo(() => R.prop('projectId')(formLine), [formLine]);

  const projectSelectQuery = useMemo(
    () => generateProjectSelectQuery({ supplierId, selectedProjects }),
    [supplierId, selectedProjects],
  );

  const { exemptSalesTax, supplierNTE } = useProjectInfoByIdQuery(
    projectId,
    supplierId,
  );

  const supplierNTELabel = supplierNTE ? `(NTE $${supplierNTE})` : '';

  const totalFieldLabel = useMemo(
    () => `Total Invoice ${supplierNTELabel}`,
    [supplierNTELabel],
  );

  const onAddProject = (e) => {
    e.preventDefault();
    push({});
  };

  const onRemoveProject = (e) => {
    e.preventDefault();
    remove(index);
  };

  const onDescriptionChange = (e) => {
    e.preventDefault();
    changeFieldValue('isSingleDescription', !isSingleDescription);
  };

  return (
    <SupplierInvoiceLineWrapperS>
      {isFirstLine && (
        <SupplierInvoiceFlexLineS topBorder>
          Projects
          <ButtonsWrapperS>
            <LinkButton onClick={onDescriptionChange}>
              {isSingleDescription
                ? 'Description per Project'
                : 'Single Description'}
            </LinkButton>
            <LinkButton onClick={onAddProject}>Add Project</LinkButton>
          </ButtonsWrapperS>
        </SupplierInvoiceFlexLineS>
      )}
      <SupplierInvoiceFormLineS>
        <FieldLayout
          required
          label="Project #"
          layout={commonFieldLayout}
          field={{
            name: `${name}.projectId`,
            Component: ProjectSelect,
            additionalProps: {
              withoutSkip: true,
              skipQuery: !supplierId,
              getQuery: () => projectSelectQuery,
            },
          }}
        />
        {isBreakdown && (
          <>
            <FieldLayout
              required
              label="Labor"
              layout={{ ...commonFieldLayout, position: 'relative' }}
              field={{
                name: `${name}.labourCost`,
                Component: LabourCostInput,
                additionalProps: {
                  formData: { supplierId },
                  placeholder: moneyPlaceholder,
                },
              }}
            />
            <FieldLayout
              required
              label="Material"
              layout={commonFieldLayout}
              field={{
                name: `${name}.materialCost`,
                Component: MoneyInputAsCents,
                additionalProps: { placeholder: moneyPlaceholder },
              }}
            />
            <FieldLayout
              label="Tax"
              disabled={exemptSalesTax}
              layout={commonFieldLayout}
              field={{
                name: `${name}.taxAmount`,
                Component: MoneyInputAsCents,
                additionalProps: { placeholder: moneyPlaceholder },
              }}
            />
          </>
        )}
        <FieldLayout
          disabled={isBreakdown}
          label={totalFieldLabel}
          required={!isBreakdown}
          layout={commonFieldLayout}
          field={{
            name: `${name}.total`,
            Component: SupplierInvoiceTotalAmountInput,
            additionalProps: { isBreakdown, formLine, supplierNTE },
          }}
        />
        {!isFirstLine && <DeleteIconS onClick={onRemoveProject} />}
      </SupplierInvoiceFormLineS>
      {!isSingleDescription && (
        <SupplierInvoiceFormLineS>
          <FieldLayout
            label="Invoice Description"
            layout={{ ...commonFieldLayout, width: '100%' }}
            field={{
              name: `${name}.projectInvoiceDescription`,
              Component: InvoiceDescriptionEditor,
              additionalProps: { index },
            }}
          />
        </SupplierInvoiceFormLineS>
      )}
      {isLastLine && !isSingleLine && (
        <SupplierInvoiceFlexLineS isLastLine>
          <FieldLayout
            layout={commonFieldLayout}
            field={{
              name: `${name}.holder`,
              Component: () => null,
            }}
          />
          {isBreakdown && (
            <>
              <FieldLayout
                disabled
                label="Total Labor"
                layout={commonFieldLayout}
                field={{
                  name: `${name}.totalLabor`,
                  Component: TotalAmountInput,
                  additionalProps: { lines: value, fieldName: 'labourCost' },
                }}
              />
              <FieldLayout
                disabled
                label="Total Material"
                layout={commonFieldLayout}
                field={{
                  name: `${name}.totalMaterial`,
                  Component: TotalAmountInput,
                  additionalProps: { lines: value, fieldName: 'materialCost' },
                }}
              />
              <FieldLayout
                disabled
                label="Total Tax"
                layout={commonFieldLayout}
                field={{
                  name: `${name}.totalTax`,
                  Component: TotalAmountInput,
                  additionalProps: { lines: value, fieldName: 'taxAmount' },
                }}
              />
            </>
          )}
          <FieldLayout
            disabled
            label="Total Invoice"
            layout={commonFieldLayout}
            field={{
              name: `${name}.totalInvoice`,
              Component: TotalAmountInput,
              additionalProps: { lines: value, fieldName: 'total' },
            }}
          />
        </SupplierInvoiceFlexLineS>
      )}
    </SupplierInvoiceLineWrapperS>
  );
}

AddSupplierInvoiceLine.displayName = 'AddSupplierInvoiceLine';

AddSupplierInvoiceLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  changeFieldValue: func.isRequired,
  formData: shape({ type: string }).isRequired,
  fields: shape({
    push: func.isRequired,
    remove: func.isRequired,
    value: arrayOf(shape({ entity: string })).isRequired,
  }),
};
