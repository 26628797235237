import React from 'react';
import { useOutSidebarContext } from 'poly-client-utils';
import { SidebarWidth } from 'poly-admin-ui';

import { assetTabsSidebarId } from '../constants.js';
import { AssetSidebarTabs } from './AssetSidebarTabs.js';
import { SecondSidebarLayout } from '../../../components/SidebarLayouts.js';

export const useOpenAssetTabsSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (asset) => {
    openOutSidebar({
      width: SidebarWidth,
      id: assetTabsSidebarId,
      Layout: SecondSidebarLayout,
      content: <AssetSidebarTabs asset={asset} />,
    });
  };
};
