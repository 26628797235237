import {
  bool,
  func,
  node,
  shape,
  object,
  string,
  element,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { getThemeColor, LinkButton, Table } from 'poly-book-admin';
import {
  ProjectSidebarWidth,
  SidebarTabWrapper,
  SidebarWidth,
} from 'poly-admin-ui';
import { Link } from 'poly-client-routing';
import { isNilOrEmpty } from 'poly-utils';

import { ProjectsPageProjectsTableComp } from '../../modules/tables/projectsTable/ProjectsTableComp.js';
import { useSidebarLogicContext } from '../SidebarLogicContext.js';
import {
  descriptionColumnStyles,
  propertyColumnStyles,
} from '../../modules/tables/tablesStyles.js';

export const headerTextProps = { size: '14px', lineHeight: '20px' };
export const threeBlocksProps = { width: 'calc(29% + 4px)', margin: '0' };
export const threeBlocksWrapperProps = { wrap: 'wrap', gap: '24px 14px' };

export const SectionWrapperWithoutBorder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px 0 10px 0;

  ${({ skipMargin }) => skipMargin && 'padding-bottom: 0'};
`;

export const SectionWrapper = styled(SectionWrapperWithoutBorder)`
  border-bottom: 1px solid ${getThemeColor(['secondaryLighter2'])};
`;

export const SidebarLabel = styled.div`
  font-size: 20px;
  line-height: 24px;
  word-break: break-word;
  max-width: 300px;
  margin-right: ${({ margin }) => (margin ? `${margin}px` : '5px')};
`;

export const SectionLabel = styled.div`
  font-size: ${({ size }) => size || '18px'};
  line-height: 22px;
  ${R.pick(['width'])};
`;

const linkStyles = css`
  font-size: 12px;
  line-height: 18px;
`;

export const UnderlinedLink = styled(Link)`
  ${linkStyles};
  text-decoration: underline;
`;

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${R.pick(['width'])};
  max-width: ${R.propOr('unset', 'maxWidth')};
  overflow-wrap: anywhere;
  margin-right: ${R.propOr('30px', 'margin')};

  a {
    ${linkStyles};
  }
`;

const BlockLabel = styled.label`
  display: flex;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  margin-bottom: 7px;
  color: ${getThemeColor(['midDark'])};
  ${({ justify }) => (justify ? 'justify-content: space-between;' : '')}

  button {
    font-size: 12px;
    line-height: 14px;
    padding-left: 10px;
  }
`;

export function BlockWithLabel({
  id,
  label,
  width,
  margin,
  maxWidth,
  position,
  Component,
  ActionBtn,
  className,
  justify,
  ...props
}) {
  return (
    <BlockWrapper {...{ width, maxWidth, margin, className }}>
      <BlockLabel justify={justify} htmlFor={id}>
        {label}
        {ActionBtn}
      </BlockLabel>
      <Component
        id={id}
        title={label}
        position={position}
        aria-label={label}
        {...props}
      />
    </BlockWrapper>
  );
}

BlockWithLabel.propTypes = {
  width: string,
  margin: string,
  ActionBtn: node,
  maxWidth: string,
  position: string,
  className: string,
  justify: bool,
  id: string.isRequired,
  label: oneOfType([string, element]).isRequired,
  Component: oneOfType([func, object]).isRequired,
};

export const CommonLinksWrapper = styled.div`
  width: ${({ width }) => width || '50%'};
  display: flex;
  font-size: 12px;
  line-height: 18px;
  flex-direction: row;
  align-items: flex-start;

  > a:first-child {
    white-space: nowrap;
  }
`;

const CommonLinksDivider = styled.div.attrs(() => ({ children: '/' }))`
  display: flex;
  margin: 0 5px;
  color: ${getThemeColor(['primaryLight'])};
`;

export function SidebarDetailsLinks({ client, property, ...props }) {
  const { ClientLink, PropertyLink } = useSidebarLogicContext();

  return (
    <CommonLinksWrapper {...props}>
      <ClientLink _id={client._id} name={client.nickName || client.name} />
      {!isNilOrEmpty(property) && (
        <>
          <CommonLinksDivider />
          <PropertyLink {...property} />
        </>
      )}
    </CommonLinksWrapper>
  );
}

SidebarDetailsLinks.displayName = 'SidebarDetailsLinks';
SidebarDetailsLinks.propTypes = {
  client: shape({ _id: string.isRequired }),
  property: shape({ _id: string }),
};

const blurredBackgroundStyles = css`
  :after {
    display: flex;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${SidebarWidth}px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    cursor: not-allowed;
  }
`;

const isProjectCardWidth = R.equals(ProjectSidebarWidth + SidebarWidth);

const DoubleSidebarTabsWrapper = styled(SidebarTabWrapper)`
  width: ${R.prop('width')}px;
  margin-left: -${({ width }) => (isProjectCardWidth(width) ? ProjectSidebarWidth : SidebarWidth)}px;
  background-color: ${getThemeColor(['lightest'])};
  position: relative;

  ${({ isBlurred }) => isBlurred && blurredBackgroundStyles};
`;

const DoubleCardSidebarWrapperS = styled(DoubleSidebarTabsWrapper)`
  margin-left: -${({ width }) => width / 2}px;

  table {
    height: calc(100vh - 270px);
  }
`;

function DoubleCardSidebarWrapper(props) {
  const [cardWidth, setCardWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const pageWrapper = document.getElementById('page-card-id');

      setCardWidth(pageWrapper ? pageWrapper.clientWidth : 0);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <DoubleCardSidebarWrapperS {...props} width={cardWidth} />;
}

export function DoubleTabsWrapper({ isCard = false, ...props }) {
  const Wrapper = isCard ? DoubleCardSidebarWrapper : DoubleSidebarTabsWrapper;

  return <Wrapper {...props} />;
}

DoubleTabsWrapper.propTypes = { isCard: bool };

export const ToolbarButton = styled(LinkButton)`
  position: absolute;
  right: ${({ right }) => right || '24px'};
`;

export const CommonProjectsTabTable = styled(ProjectsPageProjectsTableComp)`
  height: calc(100vh - 220px);
  ${descriptionColumnStyles(3)};
  ${propertyColumnStyles(4)}
`;

const editBtnColumnStyles = css`
  td:last-child,
  th:last-child {
    width: 50px;
  }
`;

export const CommonSidebarSubEntityTable = styled(Table)`
  height: calc(100% - 40px);

  ${({ showEditBtn }) => showEditBtn && editBtnColumnStyles}
  tr {
    padding: 0 14px;
  }
`;
