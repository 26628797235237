import React from 'react';
import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { string, func, shape } from 'prop-types';
import { FormCreator } from 'poly-book-admin';
import { getFileUploadErrorMessageByResult } from 'poly-client-utils';
import { removeFileExtension } from 'poly-utils';

import { commonModalLayout } from '../../../modules/forms/common.js';
import { filesSections } from '../../../modules/forms/addFilesForm/sections.js';
import { ADD_FILES_MUTATION } from '../../../hocs/files/mutations.js';
import { commonFileFields } from '../../../hocs/files/fragments.js';
import { mapAttachmentsBeforeUpdate } from '../../../modules/forms/addUpdateForm/addUpdateFormUtils.js';

const UPDATE_FILE_MUTATION = gql`
  mutation UPDATE_FILE_MUTATION($id: ID!, $input: UpdateFileInput!) {
    updateFile(id: $id, input: $input) {
      file {
        ...commonFileFields
      }
    }
  }
  ${commonFileFields}
`;

export function UpdateFilesForm({
  formId,
  file,
  deleteFileMutation,
  closeModal,
  showSuccess,
  showError,
}) {
  const [updateFileName] = useMutation(UPDATE_FILE_MUTATION);
  const [addFiles] = useMutation(ADD_FILES_MUTATION);

  const {
    fileName: originFileName,
    _id: fileId,
    collection,
    documentId,
  } = file;
  const fileNameWithoutExtension = removeFileExtension(originFileName);

  const modifiedFile = {
    ...file,
    fileName: fileNameWithoutExtension,
  };

  const formProps = {
    id: formId,
    sections: filesSections,
    layout: commonModalLayout,
    initialValues: { files: [modifiedFile] },
  };

  const successMessage = 'File was successfully updated';

  const onSubmit = async ({ files }) => {
    const updatedFileId = R.pathOr(null, ['0', '_id'], files);

    if (updatedFileId !== fileId) {
      try {
        const result = await addFiles({
          variables: {
            addFilesInput: {
              collection,
              documentId,
              files: mapAttachmentsBeforeUpdate(files),
            },
          },
        });
        const uploadError = getFileUploadErrorMessageByResult(result);
        if (uploadError) {
          showError(uploadError);
        } else {
          await deleteFileMutation(file);
          showSuccess(successMessage);
        }
        closeModal(formId);
      } catch (err) {
        showError(err.message);
      }
    } else {
      const updatedFileName = R.pathOr(null, ['0', 'fileName'], files);

      await updateFileName({
        variables: {
          id: fileId,
          input: { fileName: `${updatedFileName}` },
        },
      });

      closeModal(formId);
      showSuccess(successMessage);
    }
  };

  return <FormCreator {...formProps} onSubmit={onSubmit} />;
}

UpdateFilesForm.propTypes = {
  file: shape({
    _id: string.isRequired,
    fileName: string.isRequired,
    collection: string.isRequired,
    documentId: string.isRequired,
  }),
  formId: string.isRequired,
  deleteFileMutation: func.isRequired,
  closeModal: func.isRequired,
  showSuccess: func.isRequired,
  showError: func.isRequired,
};
