import * as R from 'ramda';
import React, { useState } from 'react';
import { arrayOf, func, oneOfType, shape, string } from 'prop-types';
import { DEBOUNCE_USER_INPUT_THRESHOLD } from 'poly-client-utils';
import { supplierTypes } from 'poly-constants';
import { debounce } from 'poly-utils';
import styled from 'styled-components';
import { getThemeColor, Text } from 'poly-book-admin';

import { MultiSelectDropDown } from '../../../components/MultiSelectDropDown.js';
import { useQueryServiceTypesBySearch } from '../../../hooks/useQueryServiceTypesBySearch.js';

const Label = styled(Text)`
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 18px;
  color: ${getThemeColor(['primary'])};
`;

// isSubcontractorServiceType :: FormData -> Boolean
export const isSubcontractorServiceType = R.propEq(
  'type',
  supplierTypes.SUBCONTRACTOR,
);

export function BaseServiceTypesMultiSelectField({
  name,
  value,
  error,
  fieldError,
  onChange,
  styleType,
  onBlur,
  formData,
  label,
}) {
  const [searchTermState, setSearchTermState] = useState('');

  const { data = {} } = useQueryServiceTypesBySearch(searchTermState);

  const { searchServiceTypes } = data;

  const setSearchTerm = debounce(DEBOUNCE_USER_INPUT_THRESHOLD)(
    setSearchTermState,
  );

  const onInputChange = (term) => setSearchTerm(term.trim());

  const isRequired = isSubcontractorServiceType(formData);

  const options = R.map(
    ({ _id, name: optionLabel }) => ({ value: _id, label: optionLabel }),
    R.propOr([], 'hits', searchServiceTypes),
  );

  return (
    <>
      <Label>
        {label || 'Service Types'} {isRequired && '*'}{' '}
      </Label>
      <MultiSelectDropDown
        onInputChange={onInputChange}
        onBlur={onBlur}
        filterOption={() => true}
        onSearchChange={setSearchTermState}
        handleChange={onChange}
        placeholder="Start typing service types"
        required={isRequired}
        maxMenuHeight={250}
        styleType={styleType}
        options={options}
        error={error}
        fieldError={fieldError}
        name={name}
        value={value}
      />
    </>
  );
}

BaseServiceTypesMultiSelectField.propTypes = {
  error: string,
  styleType: string,
  fieldError: string,
  name: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  formData: shape({ type: string }).isRequired,
  value: oneOfType([
    arrayOf(
      shape({
        value: string.isRequired,
        label: string.isRequired,
      }),
    ),
    string,
  ]).isRequired,
  label: string,
};
