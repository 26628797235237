import { gql, useMutation } from '@apollo/client';
import { useModalContext, useNotificationState } from 'poly-admin-ui';

const cancelInvoiceRequestMutation = gql`
  mutation CANCEL__INVOICE_REQUEST($id: ID!) {
    cancelInvoiceRequest(id: $id) {
      id
    }
  }
`;

const confirmModalPayload = {
  cancelBtnCaption: 'No',
  btnCaption: 'Yes, Cancel',
  id: 'confirm_cancel_invoice_request_id',
  content: 'Are you sure you want to cancel the supplier invoice request?',
  processId: 'cancelSupplierInvoiceRequest',
};

export const useCancelInvoiceRequest = (invoice, afterCancel = null) => {
  const [cancelInvoiceRequest] = useMutation(cancelInvoiceRequestMutation);

  const { showSuccessNotification } = useNotificationState();

  const { openConfirmSubmitFormModal } = useModalContext();

  return () =>
    openConfirmSubmitFormModal({
      ...confirmModalPayload,
      onConfirm: (closeConfirmModal) => async () => {
        await cancelInvoiceRequest({
          variables: { id: invoice.invoiceId },
        });
        showSuccessNotification(
          'The invoice request was successfully cancelled',
        );
        closeConfirmModal();
        if (afterCancel) {
          afterCancel(null, true);
        }
      },
    });
};
