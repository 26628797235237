import { gql } from '@apollo/client';
import { commonAddressFields } from 'poly-admin-ui';
import { useEntityMultipleSubscription } from 'poly-client-utils';
import {
  getAssetManufacturerSubscriptionOptions,
  getAssetSubscriptionOptions,
} from 'poly-client-utils/src/asset.js';

const assetDetailsFields = gql`
  fragment assetDetailsFields on Asset {
    _id
    type {
      _id
      name
      lifeExpectancy
      replacementCost
    }
    name
    displayName
    photo {
      url
      fileName
    }
    uploadPhotoUrl
    status
    qrCodeId
    description
    newModelName
    newManufacturerName
    manufacturerDoc {
      _id
      name
      replacementCost
    }
    modelDoc {
      _id
      name
      lifeExpectancy
      replacementCost
    }
    serial
    location
    warrantyEnd
    equipmentType
    purchasePrice
    commissioningDate
    remarks
    replacementCost
    client {
      _id
      name
      status
      enableReportOnlyProjectType
      configs {
        adminApp {
          allowProjectCreation
        }
      }
    }
    property {
      _id
      addressTwo
      status
      address {
        ...commonAddressFields
      }
      name
    }
    suppliers {
      _id
      type
      company {
        name
        phones {
          phone
          type
          legacyFormat
        }
        emails {
          email
          type
        }
      }
    }
    defaultSupplier {
      _id
      type
      company {
        name
        emails {
          type
          email
        }
        phones {
          type
          phone
        }
      }
    }
  }
  ${commonAddressFields}
`;

const ASSET_DETAILS = gql`
  query ASSET_DETAILS($assetId: ID!) {
    asset(id: $assetId) {
      ...assetDetailsFields
    }
  }
  ${assetDetailsFields}
`;

const ASSET_DETAILS_SUB = gql`
  subscription ASSET_DETAILS_SUB($input: SingleDocSubInput!) {
    assetChanged(input: $input) {
      id
      document {
        ...assetDetailsFields
      }
    }
  }
  ${assetDetailsFields}
`;

const ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION = gql`
  subscription ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAssetManufacturerChanged(input: $input) {
      id
      type
    }
  }
`;

export const useAssetSidebarData = (assetId) => {
  const queryOptions = { variables: { assetId }, fetchPolicy: 'network-only' };

  const { data, loading } = useEntityMultipleSubscription(
    ASSET_DETAILS,
    [ASSET_DETAILS_SUB, ASSET_MANUFACTURERS_CHANGED_SUBSCRIPTION],
    {
      queryOptions,
      getSubscriptionOptions: [
        getAssetSubscriptionOptions,
        getAssetManufacturerSubscriptionOptions,
      ],
    },
  );

  return { loading, ...data };
};
