import {
  DO_NOT_HIDE_NOTIFICATION_TIME,
  useNotificationContext,
} from 'poly-book-admin';

const BROWSER_NOT_SUPPORTED_ERROR_MSG =
  'Current browser of the current version is not supported';

export const useUnsupportedWorkerMessage = () => {
  const { showWarningNotification } = useNotificationContext();

  return () =>
    showWarningNotification({
      message: BROWSER_NOT_SUPPORTED_ERROR_MSG,
      time: DO_NOT_HIDE_NOTIFICATION_TIME,
    });
};
