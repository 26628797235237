import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { Button, FormField, Input, Select, DatePicker } from 'poly-book-admin';
import styled from 'styled-components';
import {
  UserSelect,
  getAdminUsersBySystemStatusQuery,
  prepareDetailsString,
  CancelBtn,
  useModalContext,
} from 'poly-admin-ui';
import { UserStatuses } from 'poly-constants';
import {
  bool,
  func,
  number,
  object,
  shape,
  string,
  instanceOf,
  oneOfType,
} from 'prop-types';

import { AddTimeEntityInfo } from '../../../../modules/forms/timesheetForm/components/AddTimeEntityInfo.js';
import { TimesheetTotalInput } from '../../../../modules/forms/timesheetForm/components/TimesheetTotalInput.js';
import { formatTimesheetDate } from '../../../../modules/forms/timesheetForm/components/TimesheetDatePicker.js';
import { isOutsideOfCurrentPayWeek } from '../../../../modules/forms/timesheetForm/helpers/dates.js';
import { MONDAY_DAY_NUMBER } from '../../../../modules/forms/timesheetForm/constants.js';
import { projectPropTypes } from './projectTimePropTypes.js';

const Row = styled.div`
  margin: 20px 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  gap: 10px;
`;

const TwoColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const clientStaffRate = {
  RATE: 'rate',
  OVERTIME: 'overtime',
  DOUBLE_TIME: 'doubleTime',
};

function ClientTypeRateSelect({
  formData,
  changeFieldValue,
  onChange,
  ...props
}) {
  const clientStaffRateOptions = [
    { value: clientStaffRate.RATE, label: 'Straight Time' },
    { value: clientStaffRate.OVERTIME, label: 'Over Time' },
    { value: clientStaffRate.DOUBLE_TIME, label: 'Double Time' },
  ];

  const handleChange = (value) => {
    const rate = R.propOr(null, value, formData.clientStaffRate);
    changeFieldValue('rate', rate);
    onChange(value);
  };

  const selectProps = {
    options: clientStaffRateOptions,
    onChange: handleChange,
    ...props,
  };

  return <Select {...selectProps} />;
}

ClientTypeRateSelect.propTypes = {
  changeFieldValue: func.isRequired,
  onChange: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formData: object.isRequired,
};

// geClientRatesOptions :: FormData -> [[String, String]]
const geClientRatesOptions = R.compose(
  R.map(R.applySpec({ value: R.identity, label: R.prop('description') })),
  R.pathOr([], ['project', 'client', 'staffRates', 'customRates']),
);

function ClientDescriptionRateSelect({
  formData,
  changeFieldValue,
  onChange,
  ...props
}) {
  const options = geClientRatesOptions(formData);

  const handleChange = (value) => {
    const rate = R.propOr(null, formData.type, value);
    changeFieldValue('rate', rate);
    onChange(value);
  };

  const warning = options.length ? null : 'No rates at client card found';

  const selectProps = {
    options,
    onChange: handleChange,
    ...props,
    warning,
  };

  return <Select {...selectProps} />;
}

ClientDescriptionRateSelect.propTypes = {
  changeFieldValue: func.isRequired,
  onChange: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formData: object.isRequired,
};

// getProjectInfo :: Project -> String
const getProjectInfo = R.compose(
  prepareDetailsString,
  R.dissocPath(['client', 'staffRates']),
  R.pick(['client', 'property']),
  R.defaultTo({}),
);

function ProjectInfo({ project }) {
  const projectId = R.prop('projectId', project);

  const projectInfo = getProjectInfo(project);

  return <AddTimeEntityInfo projectId={projectId} entityInfo={projectInfo} />;
}

ProjectInfo.propTypes = {
  project: shape(projectPropTypes).isRequired,
};

function CloseProjectTimeModalBtn({ formId, handleCancel }) {
  const { closeModal } = useModalContext();

  const handleClick = () => {
    if (typeof handleCancel === 'function') {
      handleCancel();
    } else {
      closeModal(formId);
    }
  };

  return <CancelBtn onClick={handleClick} />;
}

CloseProjectTimeModalBtn.propTypes = {
  formId: string.isRequired,
  handleCancel: func,
};

function ProjectTimeDatePicker(props) {
  const additionalProps = {
    formatDate: formatTimesheetDate,
    firstDayOfWeek: MONDAY_DAY_NUMBER,
    modifiers: { disabled: isOutsideOfCurrentPayWeek },
  };

  return (
    <DatePicker leftMove="-10px" width="100%" {...props} {...additionalProps} />
  );
}

export function ProjectTimeForm({
  formId,
  handleSubmit,
  values,
  form,
  handleCancel,
  valid,
}) {
  const { [formId]: loading } = useSelector(R.prop('processes'));

  const onSubmit = async (props) => {
    const { restart } = form;

    await handleSubmit(props);
    if (valid) {
      restart();
    }
  };

  return (
    <div>
      <ProjectInfo project={values.project} />
      <form onSubmit={onSubmit} id={formId}>
        <Row>
          <FormField
            name="ownerId"
            Component={UserSelect}
            additionalProps={{
              label: 'ESFM Staff',
              withoutSkip: true,
              query: getAdminUsersBySystemStatusQuery(UserStatuses.ACTIVE),
            }}
          />
        </Row>
        <Row>
          <TwoColumnWrapper>
            <FormField
              name="date"
              Component={ProjectTimeDatePicker}
              additionalProps={{
                label: 'Day, Date',
                required: true,
              }}
            />
            <FormField
              name="type"
              Component={ClientTypeRateSelect}
              additionalProps={{
                label: 'Type',
                formData: values,
                changeFieldValue: form.change,
                required: true,
              }}
            />
          </TwoColumnWrapper>
        </Row>
        <Row>
          <TwoColumnWrapper>
            <FormField
              name="clientStaffRate"
              Component={ClientDescriptionRateSelect}
              additionalProps={{
                formData: values,
                label: 'Description',
                changeFieldValue: form.change,
              }}
            />

            <FormField
              name="totalTimeInMinutes"
              Component={TimesheetTotalInput}
              additionalProps={{
                label: 'Total Time',
                formData: values,
              }}
            />
          </TwoColumnWrapper>
        </Row>

        <Row>
          <FormField
            name="notes"
            Component={Input}
            additionalProps={{
              label: 'Notes',
            }}
          />
        </Row>

        <ButtonsWrapper>
          <CloseProjectTimeModalBtn
            formId={formId}
            handleCancel={handleCancel}
          />
          <Button loader={loading} size="small" type="submit">
            Save
          </Button>
        </ButtonsWrapper>
      </form>
    </div>
  );
}

ProjectTimeForm.propTypes = {
  handleSubmit: func.isRequired,
  form: shape({
    change: func.isRequired,
  }),
  formId: string.isRequired,
  project: shape(projectPropTypes).isRequired,
  values: shape({
    clientStaffRate: shape({
      description: string,
      doubleTime: number,
      overtime: number,
      rate: number,
    }),
    isManual: bool,
    note: string,
    rate: number,
    type: string,
    totalTimeMinutes: number,
    date: oneOfType([string, instanceOf(Date)]),
  }),
  handleCancel: func,
  valid: bool,
};
