import React from 'react';
import * as R from 'ramda';
import { shape, string } from 'prop-types';
import { defaultTheme, Status } from 'poly-book-admin';
import { useReactiveQuery } from 'poly-client-utils';
import { SupplierStatuses } from 'poly-constants';
import { formatCount, forceTitleCase } from 'poly-utils';

import { SUPPLIERS_BY_SEARCH_TABLE } from '../../core/hooks/suppliers/queries.js';
import { SUPPLIER_SEARCH_CHANGED } from '../../core/hooks/suppliers/subscriptions.js';

const {
  colors: {
    statuses: { active, blocked },
  },
} = defaultTheme;

const { ACTIVE, BLOCKED } = SupplierStatuses;

const supplierStatusesColorMap = {
  [ACTIVE]: active,
  [BLOCKED]: blocked,
};

// getSuppliersCount :: SearchSuppliers -> String
const getSuppliersCount = R.compose(
  formatCount,
  R.pathOr(0, ['searchSuppliers', 'total']),
);

export function SuppliersTabTitle({ query, status }) {
  const queryOptions = {
    variables: {
      searchInput: { query, size: 0 },
    },
    fetchPolicy: 'network-only',
  };

  const { data } = useReactiveQuery(
    SUPPLIERS_BY_SEARCH_TABLE,
    SUPPLIER_SEARCH_CHANGED,
    {
      queryOptions,
      subscriptionOptions: queryOptions,
    },
  );

  const color = supplierStatusesColorMap[status];

  return (
    <Status
      text={`${forceTitleCase(status)} (${getSuppliersCount(data)})`}
      color={color}
    />
  );
}

SuppliersTabTitle.propTypes = {
  query: shape({}).isRequired,
  status: string.isRequired,
};
