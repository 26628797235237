import {
  DefaultBodyWrapper,
  Loader,
  MainHeader,
  PageHeaderContainer,
  WhiteCard,
} from 'poly-book-admin';
import { useRouterQuery } from 'poly-client-routing';
import React from 'react';
import { InvoicesStatuses } from 'poly-constants';
import { MAX_ITEMS } from 'poly-admin-ui';
import { useQuery, gql } from '@apollo/client';
import { useSortableTable } from 'poly-client-utils';
import * as R from 'ramda';
import { format } from 'date-fns';
import { alwaysNewDate } from 'poly-utils';

import { ApReportTable, getApReportTableConfig } from './ApReportTable.js';
import { PrintSupplierApReportBtn } from './PrintSupplierApReportBtn.js';

const getOpeningInvoicesQuery = gql`
  query supplier($supplierId: ID!, $searchInput: CollectionSearchParams!) {
    supplier(id: $supplierId) {
      _id
      company {
        name
      }
      finance {
        terms
      }
      cardNumber
      searchInvoices(input: $searchInput) {
        hits {
          _id
          total
          invoiceDate
          invoiceNumber
          paymentDueDate
          paymentDueDateChangeReason
          project {
            _id
            type
            projectId
          }
          balance
        }
      }
    }
  }
`;

const getApReportSearchInput = (supplierId, sort) => ({
  query: {
    bool: {
      must: [
        {
          match: {
            supplierId,
          },
        },
        {
          terms: {
            status: [InvoicesStatuses.PARTIALLY_PAID, InvoicesStatuses.PENDING],
          },
        },
      ],
    },
  },
  sort,
  from: 0,
  size: MAX_ITEMS,
});

export function SupplierApReportPage() {
  const { supplierId } = useRouterQuery(['supplierId']);

  const { sort, ...tableSortProps } = useSortableTable({
    column: 3,
    tableConfig: getApReportTableConfig(),
  });

  const { data, loading } = useQuery(getOpeningInvoicesQuery, {
    skip: !supplierId,
    variables: {
      supplierId,
      searchInput: getApReportSearchInput(supplierId, sort),
    },
  });

  const invoices = R.pathOr([], ['supplier', 'searchInvoices', 'hits'], data);
  const supplierName = R.path(['supplier', 'company', 'name'], data);
  const terms = R.path(['supplier', 'financial', 'terms'], data);
  const cardNumber = R.path(['supplier', 'cardNumber'], data);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>AP Report</MainHeader>
        {`As of ${format(alwaysNewDate(), 'MMMM dd, yyyy')}`}
        <PrintSupplierApReportBtn
          {...{ invoices, supplierName, cardNumber, terms }}
        />
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <WhiteCard>
          {loading ? (
            <Loader />
          ) : (
            <ApReportTable
              invoices={invoices}
              supplierName={supplierName}
              cardNumber={cardNumber}
              {...tableSortProps}
            />
          )}
        </WhiteCard>
      </DefaultBodyWrapper>
    </>
  );
}
