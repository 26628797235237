import React, { useMemo } from 'react';
import * as R from 'ramda';
import { Form } from 'react-final-form';
import { func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { removePropDeep } from 'poly-utils';
import { prepareAddressObjectToForm } from 'poly-admin-ui';

import { DocumentRequestOnboardingFormSection } from '../../DocumentsRequest/DocumentRequestOnboardingFormSection.js';
import { prepareOnboardingInitialValues } from '../../DocumentsRequest/DocumentsRequestForm.js';
import { useReviewSupplierOnboardingValidation } from './useReviewSupplierOnboardingValidation.js';

const FormS = styled.form`
  box-sizing: border-box;
  height: calc(100% - 82px);
  width: 530px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #cfcece;
  }
`;

export const approveSupplierOnboardingFormId =
  'approveSupplierOnboardingFormId';

function ReviewSupplierOnboardingSidebarFormComp({
  form,
  values,
  handleSubmit,
}) {
  return (
    <FormS id={approveSupplierOnboardingFormId} onSubmit={handleSubmit}>
      <DocumentRequestOnboardingFormSection
        form={form}
        values={values}
        isReview
      />
    </FormS>
  );
}

ReviewSupplierOnboardingSidebarFormComp.propTypes = {
  form: shape({
    change: func.isRequired,
  }),
  values: shape({
    type: string.isRequired,
  }),
  handleSubmit: func.isRequired,
};

// prepareReviewOnboardingInitialValues :: (Supplier -> PartialSupplier) -> PartialSupplier
const prepareReviewOnboardingInitialValues = (supplier, onboarding) =>
  R.compose(
    prepareAddressObjectToForm('company'),
    removePropDeep('__typename'),
    R.assocPath(['company', 'address'], supplier?.company?.address),
    R.assocPath(['company', 'addressTwo'], supplier?.company?.addressTwo),
    R.assoc('masterSupplier', supplier?.masterSupplier),
    prepareOnboardingInitialValues,
  )(onboarding);

export function ReviewSupplierOnboardingSidebarForm({
  onboarding,
  handleApproveDocument,
  supplier,
}) {
  const initialValues = useMemo(
    () => prepareReviewOnboardingInitialValues(supplier, onboarding),
    [supplier, onboarding],
  );

  const validate = useReviewSupplierOnboardingValidation(initialValues);

  return (
    <Form
      initialValues={initialValues}
      render={ReviewSupplierOnboardingSidebarFormComp}
      onSubmit={handleApproveDocument}
      validate={validate}
      keepDirtyOnReinitialize
    />
  );
}

ReviewSupplierOnboardingSidebarForm.propTypes = {
  onboarding: shape({}),
  handleApproveDocument: func.isRequired,
  supplier: shape({
    company: shape({
      addressTwo: string,
      address: shape({}),
    }),
  }),
};
