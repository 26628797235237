import styled from 'styled-components';
import { Checkbox, Textarea } from 'poly-book-admin';

export const CheckBoxS = styled(Checkbox)`
  padding-top: 6px;
  input {
    top: 6px;
  }
  .state-re-presenter {
    top: 6px;
  }
`;

export const TextAreaS = styled(Textarea)`
  textarea {
    min-height: 75px;
  }
`;

const inputSizes = {
  small: '200px',
  middle: '250px',
  big: '340px',
  full: '100%',
};

/**
 *  Use it to set width for form input Component
 *  applyWidth :: Component -> InputSizes -> Component
 *  @example
 *        import { withWidth, inputSizes } from '.../formStyle';
 *        applyWidth(YourComponent, inputSizes.big)
 * */
export const applyWidth = (Component, width = inputSizes.full) => styled(
  Component,
)`
  width: ${width};
`;
