import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { useHasUserAccessToProtectedSupplier } from 'poly-client-utils/src/suppliers/useHasUserAccessToUpdateSupplierProtected.js';
import { useHasUserAccessToUpdateSupplierProtected } from 'poly-client-utils';
import { CREATE_SUPPLIER_PERMISSION } from 'poly-security/src/permissions.js';
import { supplierFinTerms, SupplierStatuses } from 'poly-constants';
import { usePristineSubscribe } from 'poly-client-routing';
import { FormPage, Loader } from 'poly-book-admin';

import { branchSupplierFormSections } from './formSections/branchSupplierFormSections.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useCreateBranchSupplierMutation } from './hooks/useCreateBranchSupplierMutation.js';
import { supplierInitialOfficeHours } from '../../forms/supplierForm/form/utils.js';
import { useMasterSupplierInfoQuery } from './hooks/useMasterSupplierInfoQuery.js';

export function AddBranchSupplierForm({
  formId,
  supplierUrl,
  masterSupplierId,
}) {
  const { data } = useMasterSupplierInfoQuery(masterSupplierId);

  const { protectedFieldsAccess, accessLoading } =
    useHasUserAccessToUpdateSupplierProtected();

  const hasAccessToCreatePrivateSupplier = useHasUserAccessToProtectedSupplier(
    CREATE_SUPPLIER_PERMISSION,
  );

  const servicesIds = R.compose(
    R.map(({ _id, name }) => ({ value: _id, label: name })),
    R.pathOr([], ['masterSupplier', 'company', 'services']),
  )(data);

  const supplierSource = R.path(['masterSupplier', 'supplierSource'], data);

  const onSubmitHandler = useCreateBranchSupplierMutation(
    supplierUrl,
    supplierSource,
    protectedFieldsAccess,
  );

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const pristineSubscribeProps = usePristineSubscribe();

  const formProps = {
    id: formId,
    onSubmit,
    mutators: arrayMutators,
    sections: branchSupplierFormSections(
      supplierUrl,
      protectedFieldsAccess,
      hasAccessToCreatePrivateSupplier,
    ),
    initialValues: {
      masterSupplierId,
      status: R.toUpper(SupplierStatuses.ACTIVE),
      type: R.path(['masterSupplier', 'type'], data),
      finance: {
        acceptsCreditCard: false,
        terms: supplierFinTerms.DAYS_45,
      },
      bank: {
        payByACH: false,
      },
      service_24_7: false,
      company: { servicesIds, name: '' },
      remarks: { ops: [] },
      applyMasterRates: false,
      payByMaster: false,
      foodBuy: R.pathOr({}, ['masterSupplier', 'foodBuy'], data),
      officeHours: supplierInitialOfficeHours,
    },
    ...pristineSubscribeProps,
  };

  return !accessLoading ? <FormPage {...formProps} /> : <Loader />;
}

AddBranchSupplierForm.propTypes = {
  formId: string.isRequired,
  supplierUrl: string.isRequired,
  masterSupplierId: string.isRequired,
};
