import * as R from 'ramda';
import { assocBy, calculateTotal } from 'poly-utils';
import { WorkOrderStatus } from 'poly-constants';

import { isClientInvoiceVoided } from '../../ProjectSidebar/components/ClientInvoiceList.js';

// getPOClientInvoiceAmount :: Project -> Number
export const getPOClientInvoiceAmount = R.either(
  R.prop('clientInvoicesTotal'),
  R.prop('suggestedClientInvoiceAmount'),
);

// getPORemainingBalance :: Number -> Project -> Number
const getPORemainingBalance = (remainingBalance) =>
  R.compose(R.subtract(remainingBalance), getPOClientInvoiceAmount);

// assocPORemainingBalance :: (Number, Project) -> [Number, Project]
const assocPORemainingBalance = (remainingBalance, project) =>
  R.ifElse(
    R.propEq('status', WorkOrderStatus.BLOCKED),
    R.always([remainingBalance, { ...project, remainingBalance }]),
    R.juxt([
      getPORemainingBalance(remainingBalance),
      assocBy('remainingBalance', getPORemainingBalance(remainingBalance)),
    ]),
  )(project);

// preparePurchaseOrderProjectsTableData :: QueryData -> [Project]
export const preparePurchaseOrderProjectsTableData = (initialBalance, data) =>
  R.compose(
    R.reverse,
    R.last,
    R.mapAccum(assocPORemainingBalance, initialBalance),
    R.map(
      assocBy(
        'clientInvoicesTotal',
        R.compose(
          R.ifElse(R.isEmpty, R.always(null), calculateTotal(R.prop('amount'))),
          R.reject(isClientInvoiceVoided),
          R.unnest,
          R.juxt([
            R.propOr([], 'clientInvoices'),
            R.propOr([], 'reportOnlyClientInvoices'),
          ]),
        ),
      ),
    ),
    R.pathOr([], ['searchProjects', 'hits']),
  )(data);
