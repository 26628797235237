import styled from 'styled-components';
import { getThemeFont, getThemeColor } from 'poly-book-admin';

export const FormS = styled.form`
  width: 550px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  & > div {
    flex: 1;
    flex-wrap: nowrap;
    flex-direction: column;
    & > label {
      margin-bottom: 10px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  button:last-child {
    margin-left: 15px;
  }
  justify-content: flex-end;
`;

export const FieldsWrapper = styled.div`
  padding: 15px;
  & > div {
    margin-top: 15px;
  }
`;

export const FieldLabel = styled.span`
  display: flex;
  width: 190px;
  font-weight: ${getThemeFont(['regular'])};
  font-size: 14px;
  color: ${getThemeColor(['primaryLighter2'])};
`;
