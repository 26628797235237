import { gql, useMutation } from '@apollo/client';
import { useNotificationState } from 'poly-admin-ui';

const deleteInvoiceMutation = gql`
  mutation DELETE_INVOICE_MUTATION($id: ID!) {
    deleteInvoice(id: $id) {
      _id
    }
  }
`;

export const useDeleteInvoiceMutation = (invoice) => {
  const { showSuccessNotification } = useNotificationState();
  const [deleteInvoice] = useMutation(deleteInvoiceMutation);

  return async () => {
    await deleteInvoice({ variables: { id: invoice.invoiceId } });
    showSuccessNotification('Invoice was successfully deleted');
  };
};
