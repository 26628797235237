import React from 'react';
import { Input } from 'poly-book-admin';

export function CityInput(props) {
  return <Input {...props} />;
}

CityInput.displayName = 'CityInput';

CityInput.defaultProps = {
  placeholder: 'Enter city',
};
